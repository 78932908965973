import { Component } from '@angular/core';
declare var $: any;
declare var jQuery:any;
declare var window:any;
@Component({
  selector: 'vt-login-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class LoginLayoutComponent {
  ngAfterViewInit() {
      $('body').addClass('body-login');

    }
   
}