import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import 'src/assets/css/styles.css';

declare var $: any;
declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'template-TextCompare',
  templateUrl: 'TextCompare.component.html',
  styleUrls: ['./TextCompare.component.scss']
})

export class TextCompareComponent {
  @ViewChild('editor1') editor1: ElementRef;
  @ViewChild('editor2') editor2: ElementRef;
  @ViewChild('diffeditor') diffeditor: ElementRef;

  public ifCompared = true;
  public text1 = " ";
  public text2 = "";
  public isCompared = false;

  srcheight: number;
  diffHeight: number;


  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";

  @Input()
  languages = [
    "bat",
    "c",
    "coffeescript",
    "cpp",
    "csharp",
    "csp",
    "css",
    "dockerfile",
    "fsharp",
    "go",
    "handlebars",
    "html",
    "ini",
    "java",
    "javascript",
    "json",
    "less",
    "lua",
    "markdown",
    "msdax",
    "mysql",
    "objective-c",
    "pgsql",
    "php",
    "plaintext",
    "postiats",
    "powershell",
    "pug",
    "python",
    "r",
    "razor",
    "redis",
    "redshift",
    "ruby",
    "rust",
    "sb",
    "scss",
    "sol",
    "sql",
    "st",
    "swift",
    "typescript",
    "vb",
    "xml",
    "yaml"
  ];

  @Input()
  themes = [
    {
      value: "vs",
      name: "Light"
    },
    {
      value: "vs-dark",
      name: "Dark"
    },
    {
      value: "hc-black",
      name: "High Contrast Dark"
    }
  ];

  // input
  inputOptions = { theme: "vs", language: 'plaintext' };
  // compare, output
  diffOptions = {
    theme: "vs",
    language: "plaintext",
    readOnly: true,
    renderSideBySide: true
  };
  originalModel: DiffEditorModel = {
    code: '',
    language: 'plaintext'
  };

  modifiedModel: DiffEditorModel = {
    code: '',
    language: 'plaintext'
  };

  ngOnInit() {
    this.srcheight = window.innerHeight - 30;
    this.diffHeight = window.innerHeight - 240;
  }

  constructor(public fb: FormBuilder, private ngxService: NgxUiLoaderService) { }

  closeModal() {
    $('#myModal').modal('hide');
    // this.ifTextCompare = false;
    this.isCompared = false;
    this.ifCompared = true;
    this.text1 = "";
    this.text2 = "";
  }

  onChangeLanguage(language) {
    this.inputOptions = Object.assign({}, this.inputOptions, { language: language });
    this.originalModel = Object.assign({}, this.originalModel, { language: language });
    this.modifiedModel = Object.assign({}, this.modifiedModel, { language: language });
  }
  onChangeTheme(theme) {
    this.inputOptions = Object.assign({}, this.inputOptions, { theme: theme });
    this.diffOptions = Object.assign({}, this.diffOptions, { theme: theme });
  }

  onChangeInline(checked) {
    this.diffOptions = Object.assign({}, this.diffOptions, { renderSideBySide: !checked });
  }

  onCompare() {
    this.originalModel = Object.assign({}, this.originalModel, { code: this.text1 });
    this.modifiedModel = Object.assign({}, this.originalModel, { code: this.text2 });
    this.isCompared = true;
    this.ifCompared = false;
  }

  offCompare() {
    this.isCompared = false;
    this.ifCompared = true;
  }


}
