import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserSession } from './user.session';
import { VitalHttpServices } from './VitalHttpServices';


@Injectable()//{ providedIn: 'root' }
export class AuthGuard  {

  constructor(private router: Router, public ussersession: UserSession, public vitalHttpServices: VitalHttpServices) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let val = "-1"
    if (val && val != "") {
      // logged in so return true
      return true;
    }
    else{
      this.vitalHttpServices.logout();
      return false;
    }

  }

}
