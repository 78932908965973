<div class="col-sm-12 p-0">
  <div *ngIf="gridDisplay" class="col-sm-12 p-1">
      <div class="modal-header admin-model-header mt-3">
          <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>{{templateData.menuURL}}s </strong>
          </h3>
        </div>
    <div class="mt-3">
      <wj-flex-grid #grid [isReadOnly]="true" [headersVisibility]="'Column'" (initialized)="initGrid(grid)"
        [itemsSource]="gridData" (click)="onClick(grid, $event)">
        <!-- <div *ngFor="let columnname of gridheader"> -->
        <wj-flex-grid-column [width]="200" [minWidth]="40" *ngFor="let columnname of gridheader" [binding]="columnname"
          [header]="columnname.split('_').join(' ')" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
        </wj-flex-grid-column>
        <!-- </div> -->
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
      <br />
      <div class="align-center">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
      </div>
      <div class="col-1 backbtnmaindiv pl-0">
        <!-- <div class="row" style="justify-content: flex-end;">
          <button
            type="button"
            class="btn btn-sm admin-btn-success ExportButtonSize"
            (click)="ExportToExcel(grid)"
          >
            Export
          </button>
        </div> -->
        <div class="SubButton">
          <button type="button" mat-raised-button class="admin-btn-success" [disabled]="hideExportBtn " (click)="ExportToExcel()">
            Export
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="col-1 backbtnmaindiv">
      <div class="row" style="justify-content: flex-end;">
        <button type="button" class="btn-primary mt-2 btnAll" (click)="back()">
          Export
        </button>
      </div>
    </div> -->
  </div>
  <div *ngIf="gridEmpty" class="col-sm-12 mt-5 nodata-wrap mt-4">
    <div class="nodata-design">No Data Found</div>
  </div>
  <div *ngIf="this.detailsDisplay" class="col-sm-12">
    <div class="container-fluid mt-2 modal-header admin-model-header">
      <div class="modal-title w-100 main-title">
        <span> Edit {{ this.Interface_Name }}</span>
      </div>
    </div>
    <!-- <div class="row"> -->
    <div class="col-sm-12 p-0">
      <mat-tab-group class="com-border-left com-border-right tabGroup" selectedIndex="0"
        (selectedTabChange)="changeTab($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>Master Configuration</span>
          </ng-template>
          <div class="row">
            <div class="col-sm-12 horizontal-line">
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Handler</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" (click)="copy(this.Handler)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Handler">{{
                    this.Handler
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Description</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Description">{{
                    this.Description
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align textAlign ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Message Direction</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span scope="row" class="adjust" [value]="this.Direction">{{
                    this.Direction
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align textAlign ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Vendor</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span scope="row" class="adjust" [value]="this.Vendor">{{
                    this.Vendor
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Type</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Interface_Engine">{{
                    this.Interface_Engine
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Pipeline</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust " [value]="this.Group_ID">{{
                    this.Group_ID
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Outbound Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy"
                    *ngIf="this.Local_Outbound_Folder.toString().toLowerCase() != 'not specified'"
                    (click)="copy(this.Local_Outbound_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Outbound_Folder">{{ this.Local_Outbound_Folder }}
                  </span>

                  &nbsp;
                  <!-- <button class="fa-thin fa-copy" (click)="copy(inputValue)">copy</button> -->
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Archive Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="this.Local_Outbound_Bck_Folder != 'Not Specified'"
                    (click)="copy(this.Local_Outbound_Bck_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Outbound_Bck_Folder">{{ this.Local_Outbound_Bck_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Source Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy"
                    *ngIf="this.Local_Source_Folder.toString().toLowerCase() != 'not specified'"
                    (click)="copy(this.Local_Source_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust" [value]="this.Local_Source_Folder">{{
                    this.Local_Source_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Processing Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img *ngIf="
                      this.Local_Processing_Folder.toString().toLowerCase() != 'not specified'
                    " class="copyIcon cursor" (click)="copy(this.Local_Processing_Folder)" alt="copy"
                    src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Processing_Folder">{{ this.Local_Processing_Folder }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Success Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Success_Folder.toString().toLowerCase() != 'not specified'
                    " (click)="copy(this.Local_Success_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Success_Folder">{{
                    this.Local_Success_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Failed Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Failed_Folder.toString().toLowerCase() != 'not specified'
                    " (click)="copy(this.Local_Failed_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Failed_Folder">{{
                    this.Local_Failed_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Ignored Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy"
                    *ngIf="this.Local_Ignored_Folder.toString().toLowerCase() != 'not specified'"
                    (click)="copy(this.Local_Ignored_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Ignored_Folder">{{
                    this.Local_Ignored_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Status</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Status">{{
                    this.Status
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Go Live Date</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Go_Live_Date">{{
                    this.Go_Live_Date
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>System</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.System">{{
                    this.System
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Folder Date Format</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.Folder_Date_Format">{{
                    this.Folder_Date_Format
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Message Subtype</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.MessageSubtype">{{
                    this.MessageSubtype
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Test Mode Enabled</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.In_Testing">{{
                    this.In_Testing
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>File Type</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust" [value]="this.FileType">{{
                    this.FileType
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align  ">
                <div class="col-sm-3">
                  <span scope="row"><strong>MSH</strong></span>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-8">
                  <span class="adjust " [value]="this.MSH">{{ this.MSH }}</span>
                </div>
              </div>
              <!-- <div class="mt-2 "></div> -->
              <!-- <mat-card matRipple class="wideCardHover matCardWidth mb-2">
                                    <div class="row">
                                        <div class="col-sm-4 direc-align horizontal-line ">
                                            <div class="col-sm-12 d-flex">
                                                <div class="mt-2 col-sm-8">
                                                    <span scope="row"><strong>Show Message</strong></span>
                                                </div>
                                                <div class="mt-2 col-sm-4">
                                                    <span class="adjust" [value]="this.Show_Message">{{this.Show_Message}}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-sm-4 direc-align horizontal-line ">
                                            <div class="col-sm-12 d-flex">
                                                <div class="mt-2 col-sm-6">
                                                    <span scope="row"><strong>Archive Path</strong></span>
                                                </div>
                                                <div class="mt-2 col-sm-6">
                                                    <span class="adjust" id="archivePath" [value]="this.Archive_Path">{{this.Archive_Path}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 direc-align">
                                            <div class="col-sm-12 d-flex">
                                                <div class="mt-2 col-sm-5">
                                                    <span scope="row"><strong>Find File In</strong></span>
                                                </div>
                                                <div class="mt-2 col-sm-8">
                                                    <span class="adjust" id="findFile" [value]="this.FindFileIn">{{this.FindFileIn}}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </mat-card> -->
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Show Message</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center"></div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust" [value]="this.Show_Message">{{
                    this.Show_Message
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Archive Path</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center"></div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust" [value]="this.Archive_Path">{{
                    this.Archive_Path
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align ">
                <div class="col-sm-3">
                  <span scope="row"><strong>Find File In</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center"></div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust" [value]="this.FindFileIn">{{
                    this.FindFileIn
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Outbound Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Outbound_Folder != 'Not Specified' &&
                      this.Local_Outbound_Folder != 'na'
                    " (click)="copy(this.Local_Outbound_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Outbound_Folder">{{ this.Local_Outbound_Folder }}
                  </span>

                  &nbsp;
                  <!-- <button class="fa-thin fa-copy" (click)="copy(inputValue)">copy</button> -->
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="!this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Archive Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Outbound_Bck_Folder != 'Not Specified' &&
                      this.Local_Outbound_Bck_Folder != 'na'
                    " (click)="copy(this.Local_Outbound_Bck_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Outbound_Bck_Folder">{{ this.Local_Outbound_Bck_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Source Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Source_Folder != 'Not Specified' &&
                      this.Local_Source_Folder != 'na'
                    " (click)="copy(this.Local_Source_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust" [value]="this.Local_Source_Folder">{{
                    this.Local_Source_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Processing Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Processing_Folder != 'Not Specified' &&
                      this.Local_Processing_Folder != 'na'
                    " (click)="copy(this.Local_Processing_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Processing_Folder">{{ this.Local_Processing_Folder }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Success Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Success_Folder != 'Not Specified' &&
                      this.Local_Success_Folder != 'na'
                    " (click)="copy(this.Local_Success_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Success_Folder">{{
                    this.Local_Success_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Failed Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Failed_Folder != 'Not Specified' &&
                      this.Local_Failed_Folder != 'na'
                    " (click)="copy(this.Local_Failed_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Failed_Folder">{{
                    this.Local_Failed_Folder
                    }}</span>
                </div>
              </div>
              <div class="row mt-2 direc-align " *ngIf="this.isOutbound">
                <div class="col-sm-3">
                  <span scope="row"><strong>Ignored Folder</strong></span>
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                  <img class="copyIcon cursor" alt="copy" *ngIf="
                      this.Local_Ignored_Folder != 'Not Specified' &&
                      this.Local_Ignored_Folder != 'na'
                    " (click)="copy(this.Local_Ignored_Folder)" src="/assets/images/copy.svg" />
                </div>
                <div class="col-sm-8 d-flex align-items-center">
                  <span class="adjust " [value]="this.Local_Ignored_Folder">{{
                    this.Local_Ignored_Folder
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span>Distribution Rules</span>
          </ng-template>
          <div *ngIf="this.showRulesData">
            <mat-accordion multi="true" class="mt-6 example-headers-align">
              <mat-expansion-panel [expanded]="value.isExpanded" *ngFor="let value of rulesDetailsData; let i = index"
                hideToggle="true" #expansionpanel (click)="expandedcard(value)">
                <mat-expansion-panel-header style="width:100%">
                  <div class="container-fluid mat-btn-wrap m-0 p-0">
                    <div class="row m-0 p-0">
                      <div class="col-11 m-0 p-0">
                        <div *ngIf="
                            value.Status == 'Active' &&
                            value.Test_Mode != 'In testing'
                          " class="listCard d-grid gap-3 activeShow">
                          <div class="row  p-2 bg-light border direc-align ">
                            <div class="col-sm-1 horizontal-line">
                              <span><strong>Rule ID </strong></span>
                              <span scope="row">{{ value.Rule_ID }}</span>
                            </div>
                            <div class="col-sm-8">
                              <span class="adjust">{{
                                value.Rule_Description
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="value.Status != 'Active'" class="listCard d-grid gap-3 inactiveShow">
                          <div class="row  p-2 bg-light border direc-align ">
                            <div class="col-sm-1 horizontal-line">
                              <span><strong>Rule ID </strong></span>
                              <span scope="row">{{ value.Rule_ID }}</span>
                            </div>
                            <div class="col-sm-8">
                              <span class="adjust">{{
                                value.Rule_Description
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="
                            value.Status == 'Active' &&
                            value.Test_Mode == 'In testing'
                          " class="listCard d-grid gap-3 intestingShow">
                          <div class="row  p-2 bg-light border direc-align ">
                            <div class="col-sm-1 horizontal-line">
                              <span><strong>Rule ID </strong></span>
                              <span scope="row">{{ value.Rule_ID }}</span>
                            </div>
                            <div class="col-sm-8">
                              <span class="adjust">{{
                                value.Rule_Description
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1 p-0 m-0">
                        <mat-icon *ngIf="!expansionpanel.expanded">add</mat-icon>
                        <mat-icon *ngIf="expansionpanel.expanded">remove</mat-icon>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="example-accordion-item-body">
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Case Type</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Case_Type == 'Not Specified' ||
                        value.Case_Type == 'All Case Types'
                      ">
                      <span class="adjust" [value]="value.Case_Type">{{
                        value.Case_Type
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Case_Type != 'Not Specified' &&
                        value.Case_Type != 'All Case Types'
                      ">
                      <span class="adjust" [value]="value.Case_Type"> {{ value.Case_Type }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Service</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Service">{{
                        value.Service
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Rule Type</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Rule_Config">{{
                        value.Rule_Config
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Status</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <td class="adjust" [value]="value.Status">
                        {{ value.Status }}
                      </td>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Effective Date</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <td class="adjust" [value]="value.Effective_Date">
                        {{ value.Effective_Date }}
                      </td>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Expiry Date</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <td class="adjust" [value]="value.Expiry_Date">
                        {{ value.Expiry_Date }}
                      </td>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Lab Group</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Lab_Org_Id == 'Not Specified' ||
                        value.Lab_Org_Id == 'All Lab Organizations'
                      ">
                      <span class="adjust" [value]="value.Lab_Org_Id">
                        {{ value.Lab_Org_Id }}
                      </span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Lab_Org_Id != 'Not Specified' &&
                        value.Lab_Org_Id != 'All Lab Organizations'
                      ">
                      <span class="adjust" [value]="value.Lab_Org_Id">{{ value.organizationname }} (
                        {{ value.Lab_Org_Id }} )</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Labs</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Lab_ID == 'Not Specified' ||
                        value.Lab_ID == 'All Labs'
                      ">
                      <span class="adjust" [value]="value.Lab_ID">
                        {{ value.Lab_ID }}
                      </span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Lab_ID != 'Not Specified' &&
                        value.Lab_ID != 'All Labs'
                      ">
                      <span class="adjust" [value]="value.Lab_ID">{{ value.accountname }} ( {{ value.Lab_ID }} )</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Ordering Facility Group</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Fac_Org_Id == 'Not Specified' ||
                        value.Ord_Fac_Org_Id ==
                          'All Ordering Facility Organizations'
                      ">
                      <span class="adjust" [value]="value.Ord_Fac_Org_Id">{{
                        value.Ord_Fac_Org_Id
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Fac_Org_Id != 'Not Specified' &&
                        value.Ord_Fac_Org_Id !=
                          'All Ordering Facility Organizations'
                      ">
                      <span class="adjust" [value]="value.Ord_Facility_Id">{{ value.Ord_Facility_Org_Name }} ({{
                        value.Ord_Fac_Org_Id
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Ordering Facility</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Facility_Id == 'Not Specified' ||
                        value.Ord_Facility_Id == 'All Ordering Facilities'
                      ">
                      <span class="adjust" [value]="value.Ord_Facility_Id">
                        {{ value.Ord_Facility_Id }}
                      </span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Facility_Id != 'Not Specified' &&
                        value.Ord_Facility_Id != 'All Ordering Facilities'
                      ">
                      <span class="adjust" [value]="value.Ord_Facility_Id">{{ value.Ord_Facility_Name }} ({{
                        value.Ord_Facility_Id
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Path Group</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Path_Org_Id == 'Not Specified' ||
                        value.Path_Org_Id == 'All Pathologist Organizations'
                      ">
                      <span class="adjust" [value]="value.Path_Org_Id">{{
                        value.Path_Org_Id
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Path_Org_Id != 'Not Specified' &&
                        value.Path_Org_Id != 'All Pathologist Organizations'
                      ">
                      <span class="adjust" [value]="value.Path_Org_Id">{{ value.pathorgname }} ({{ value.Path_Org_Id
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Path Location</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Path_Account_Id == 'Not Specified' ||
                        value.Path_Account_Id == 'All Pathologist Accounts'
                      ">
                      <span class="adjust" [value]="value.Path_Account_Id">{{
                        value.Path_Account_Id
                        }}</span>
                    </div>

                    <div class="col-sm-6" *ngIf="
                        value.Path_Account_Id != 'Not Specified' &&
                        value.Path_Account_Id != 'All Pathologist Accounts'
                      ">
                      <span class="adjust" [value]="value.Path_Account_Id">{{ value.pathsaccountname }}
                        ({{ value.Path_Account_Id }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Pathologist</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Pathologist_Id == 'Not Specified' ||
                        value.Pathologist_Id == 'All Pathologists'
                      ">
                      <span class="adjust" [value]="value.Pathologist_Id">{{
                        value.Pathologist_Id
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Pathologist_Id != 'Not Specified' &&
                        value.Pathologist_Id != 'All Pathologists'
                      ">
                      <span class="adjust" [value]="value.Ord_Facility_Id">{{ value.Pathologist_Name }} ({{
                        value.Pathologist_Id
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Ordering Physician</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Physician == 'Not Specified' ||
                        value.Ord_Physician == 'All Ordering Physicians'
                      ">
                      <span class="adjust" [value]="value.Ord_Physician">{{
                        value.Ord_Physician
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ord_Physician != 'Not Specified' &&
                        value.Ord_Physician != 'All Ordering Physicians'
                      ">
                      <span class="adjust" [value]="value.Ord_Physician">{{ value.OrdPhyuser }} ({{
                        value.Ord_Physician
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Refering Physician</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ref_Physician == 'Not Specified' ||
                        value.Ref_Physician == 'All Refering Physicians'
                      ">
                      <span class="adjust" [value]="value.Ref_Physician">{{
                        value.Ref_Physician
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ref_Physician != 'Not Specified' &&
                        value.Ref_Physician != 'All Refering Physicians'
                      ">
                      <span class="adjust" [value]="value.Ref_Physician">{{ value.RefPhyuser }} ({{
                        value.Ref_Physician
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Entity Name</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.EntityName">{{
                        value.EntityName
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Mode</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Mode">{{
                        value.Mode
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Test Mode Enabled</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Test_Mode">{{
                        value.Test_Mode
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Surgical Center</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Surgical_Center">{{
                        value.Surgical_Center
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Parent Rule ID</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.parent_rule_id">{{
                        value.parent_rule_id
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Excluded Ordering Facilities</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="value.Ex_Ord_Facility_Id == 'Not Specified'">
                      <span class="adjust" [value]="value.Ex_Ord_Facility_Id">{{
                        value.Ex_Ord_Facility_Id
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="value.Ex_Ord_Facility_Id != 'Not Specified'">
                      <span class="adjust" [value]="value.Ex_Ord_Facility_Id">{{ value.exaccountname }} ({{
                        value.Ex_Ord_Facility_Id
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Case Status</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Case_Status == 'Not Specified' ||
                        value.Case_Status == 'All Cases'
                      ">
                      <span class="adjust" [value]="value.Case_Status">{{
                        value.Case_Status
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Case_Status != 'Not Specified' &&
                        value.Case_Status != 'All Cases'
                      ">
                      <span class="adjust" [value]="value.Case_Status">{{
                        value.Case_Status
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Excluded Services</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Ex_Services">{{
                        value.Ex_Services
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Excluded Case Types</strong></span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ex_Case_Types == 'Not Specified' ||
                        value.Ex_Case_Types == 'No Exclusions'
                      ">
                      <span class="adjust" [value]="value.Ex_Case_Types">{{
                        value.Ex_Case_Types
                        }}</span>
                    </div>
                    <div class="col-sm-6" *ngIf="
                        value.Ex_Case_Types != 'Not Specified' &&
                        value.Ex_Case_Types != 'No Exclusions'
                      ">
                      <span class="adjust" [value]="value.Ex_Case_Types">{{ value.excasetypename }} ({{
                        value.Ex_Case_Types
                        }})</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Primary PGP</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Primary_PGP">{{
                        value.Primary_PGP
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Cases</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Case_Id">{{
                        value.Case_Id
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Modified By</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Modified_By">{{
                        value.Modified_By
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Modified Date</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.ModifiedDate">{{
                        value.ModifiedDate
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Created By</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Created_By">{{
                        value.Created_By
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-2 direc-align ">
                    <div class="col-sm-3">
                      <span scope="row"><strong>Created Date</strong></span>
                    </div>
                    <div class="col-sm-6">
                      <span class="adjust" [value]="value.Created_Date">{{
                        value.Created_Date
                        }}</span>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div *ngIf="!this.showRulesData" class="mt-4 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span>Mapping Data</span>
          </ng-template>
          <div *ngIf="this.displayGrid" class="gridshow">
            <div class="d-block m-auto">
              <div id="hoverRow">
                <wj-flex-grid [allowResizing]="'*'" [isReadOnly]="true" [headersVisibility]="'Column'"
                  (initialized)="initGrid(intgrid)" [itemsSource]="intMapGridData" class=" grid-width" #intgrid>
                  <div *ngFor="let columnname of intmapheaders">
                    <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')"
                      [allowDragging]="false" [width]="200" [allowResizing]="true" [format]="'d'"></wj-flex-grid-column>
                  </div>
                  <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <div class="navigator">
                  <wj-collection-view-navigator *ngIf="showPaginationIntMapGrid"
                    headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="intMapGridData">
                  </wj-collection-view-navigator>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div *ngIf="!this.displayGrid" class="mt-4 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="sub-button-space mt-4">
      <!-- <button
        (click)="ExportDetailsToExcel()"
        class="btn btn-sm admin-btn-success ExportButtonSize"
      >
        Export
      </button> -->
      <button type="button" mat-raised-button class="admin-btn-success mr-4" [disabled]="hideExportBtn " (click)="ExportDetailsToExcel()">
        Export
      </button>
      <button (click)="back()" mat-raised-button class="admin-btn-success">Back</button>
    </div>
  </div>

</div>
