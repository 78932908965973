import { Component } from '@angular/core';

@Component({
  selector: 'app-infoattachment',
  templateUrl: './infoattachment.component.html',
  styleUrls: ['./infoattachment.component.scss']
})
export class InfoattachmentComponent {


  attachmentList=[
    {name:'agreement_client_pdf',date:'10-27-2023 10:26 PM'},
    {name:'agreement_client_pdf',date:'10-27-2023 10:26 PM'},
    {name:'agreement_client_pdf',date:'10-27-2023 10:26 PM'},
    {name:'agreement_client_pdf',date:'10-27-2023 10:26 PM'}
  ]
}
