import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import * as JsonToXML from "js2xmlparser";
//import { CardQueries } from 'src/app/model/card-query';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import xml2js from 'xml2js';
import { InjectorService } from '../../../core/services/Injectorservices';
//import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalAdminTabService } from '../../tab.service';

@Component({
  selector: 'app-vdgenric',
  templateUrl: './vdgenric.component.html',
  styleUrls: ['./vdgenric.component.scss']
})
export class VdgenricComponent implements OnChanges {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input() mainCard: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  gridHeader = [];
  UserList = [];
  editScreenGrey: boolean = false;
  documentPrinter: boolean = false;
  deviceEdit: boolean = false;
  gridViewShow: boolean = true;
  editTitle: string;

  //**********Jar-Casette-Slider variables****************
  printermodel: any[];
  printmode: any = [];
  printerarry: any = [];
  casemetatype: any = [];
  casetype: any = [];
  priorityarry: any = [];
  tbdServiceType: any = [];
  technicalServiceType: any = [];
  globalServiceType: any = [];
  profServiceType: any = [];
  caseLabelTemplate: any = [];
  jarcasetteslidergroup = this.frombuilder.group({
    printerModel: ['', Validators.required],
    printMode: ['', Validators.required],
    isDefaultPrinter: [''],
    printerName: ['', Validators.required],
    printer: ['', Validators.required],
    caseMetaType: [''],
    caseType: [''],
    tbdservicetype: ['', Validators.required],
    technicalservicetype: ['', Validators.required],
    globalservicetype: ['', Validators.required],
    profservicetype: ['', Validators.required],
    //caseLabelTemplate: ['', Validators.required],
    priority: [''],
    numberOfSet: [''],
    isShowOption: [''],
    caseMetaTypeSpecific: [''],
    caseTypeSpecific: [''],
    prioritySpecific: [''],
    caseMetaTypePrioritySpecific: [''],
    caseMetaTypeCaseTypePrioritySpecific: [''],
    isPrintCaseLabel: [''],
    caselabeltemplate: [''],
    numOfCaseLabel: [''],
    allCases: [''],
    robinRange: [''],
    isByCase: [''],
    isBySlide: [''],
    isByBlock: [''],
    isUnstainedSlides: [''],
    slideRobinRange: [''],
    slideByCase: [''],
    slideBySlide: [''],
    sitePerFile: [''],
    folderpath: ['', Validators.required]
  });
  vdSettingsID;
  SelectedprinterModel;
  OriginalTemplateData: any = [];
  OriginalCaseMetadata: any = [];
  OriginalCasePriority: any = [];
  public xmlItems: any;
  public SelectedRowData;

  //**********END Jar-Casette-Slider variables****************
  //********************medical device printer code*********************
  //public showEditMedicalDevicePrinter = false;
  medicalprintergroup = this.frombuilder.group({
    DeviceName: ['', Validators.required],
    CommunicationMode: ['', Validators.required],
    DefaultValueMed: [''],
    CommunicationPort: ['', Validators.required],
    BaudRate: ['', Validators.required],
    Parity: ['', Validators.required],
    Alias: ['', Validators.required],
    ShouldShowDetailsDuringTransfer: [''],
    DataBits: ['', Validators.required],
    StopBits: ['', Validators.required],
    StartBits: ['', Validators.required],
    LineTerminator: ['']
  });
  public DeviceName: any[];
  public CommunicationMode: any[];
  // public DefaultValueMed: any;
  public CommunicationPort: any[];
  public BaudRate: any[];
  public Parity: any[];
  // public Alias: any;
  // public ShouldShowDetailsDuringTransfer: any;
  // public DataBits: any;
  // public StopBits: any;
  // public StartBits: any;
  public LineTerminator: any[];
  //*****************technical and documnet printer********************
  public PrinterName: any[];
  //public DefaultValuedoc: any;
  //public ReqFormNum: any;
  public resData: any;
  doctechgroup = this.frombuilder.group({
    PrinterName: [''],
    DefaultValueDocTech: [''],
    ReqFormNum: ['', Validators.required],
    Alias: ['', Validators.required]
  });
  constructor(private injectorServiceGridContainer: InjectorService, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar, private frombuilder: UntypedFormBuilder) {
    //super(injectorServiceGridContainer);
  }


  ngOnChanges() {
    this.editScreenGrey = false;
    this.documentPrinter = false;
    this.deviceEdit = false;
    this.gridViewShow = true;
    this.gridHeader = [];
    if (this.templateData.labels !== undefined) {
      let labels = JSON.parse(JSON.stringify(this.templateData.labels))
      for (let i = 0; i < labels.length; i++) {
        let column: any;
        // label = this.templateData.labels[i];
        this.gridHeader.unshift(labels[i]);
      }
      this.AddGridData()
    }
    //this.savePrinterDetails("xx")
  }

  AddGridData() {
    if (this.templateData.submenuData != null) {
      let keyword = this.templateData.Keyword;
      switch (keyword) {
        case "vdjarprinter":
        case "vdcassetteprinter":
          this.gridHeader = ["Default_Value", "Case_Type", "Location", "Printer_Model", "Printer_Name", 'VDSettingsID']
          break;
        case "vdslideprinter":
          this.gridHeader = ["Default_Value", "Case_Type", "Printer_Model", "Printer_Name", 'VDSettingsID']
          break;
        case "vddocumentprinter":
        case "vdtechnicalsheetprinter":
          //initializes grid header
          this.gridHeader = ['Default_Value', 'Alias', 'Printer_Name', 'VDSettingsID'];
          break;
        case "vdmedicaldeviceprinter":
          //initializes grid header
          this.gridHeader = ['Default_Value', 'Communication_Mode', 'Device_Name', 'VDSettingsID'];
          break;
        default:
          break;
      }
    }
    let gridarray = []
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        var arrayUniqueByKey;
        if (this.templateData.Keyword == 'vdjarprinter' || this.templateData.Keyword == 'vdcassetteprinter') {
          arrayUniqueByKey = [...new Map(gridarray.map(item =>
            [item["Case_Type"], item] && [item["Default_Value"], item] && [item["Location"], item] && [item["Printer_Model"], item] && [item["Printer_Name"], item] && [item["VDSettingsID"], item])).values()];
          gridarray = arrayUniqueByKey;
        } else if (this.templateData.Keyword == 'vdslideprinter') {
          arrayUniqueByKey = [...new Map(gridarray.map(item =>
            [item["Case_Type"], item] && [item["Default_Value"], item] && [item["Printer_Model"], item] && [item["Printer_Name"], item] && [item["VDSettingsID"], item])).values()];
          gridarray = arrayUniqueByKey;
        }
      }
      else {
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 0;
        this.gridViewShow = false;
      }

      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * (this.gridHeader.length - 1)) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
    } else {
      this.gridwidth = 0;
      this.gridData = new CollectionView(gridarray, { pageSize: 10 });
      // this.gridwidth = 0;
      this.gridViewShow = false;
    }

  }
  onClick(gridData) {
    let keyword = this.templateData.Keyword;
    if (gridData.currentItem) {
      var item = gridData.currentItem;
      let val: any;
      if (keyword === "vdjarprinter" || keyword === "vdcassetteprinter" || keyword === "vdslideprinter") {
       // val = this.templateData.submenuData.filter(va => va.VDSettingsID === item.VDSettingsID && va.PrinterModel === item.PrinterModel)
        val = this.templateData.submenuData.filter(va => va.VDSettingsID === item.VDSettingsID && va.Printer_Model === item.Printer_Model && va.Default_Value === item.Default_Value)
        if (val.length > 0) {
          this.SelectedRowData = val[0]
        }
      }
      else if (keyword === "vddocumentprinter" || keyword === "vdtechnicalsheetprinter") {
        val = this.templateData.submenuData.filter(va => va.VDSettingsID === item.VDSettingsID && va.Printer_Name === item.Printer_Name && va.Alias === item.Alias)
        if (val.length > 0) {
          this.SelectedRowData = val[0]
        }
      }
      else if (keyword === "vdmedicaldeviceprinter") {
        val = this.templateData.submenuData.filter(va => va.VDSettingsID === item.VDSettingsID)
        if (val.length > 0) {
          this.SelectedRowData = val[0]
        }
      }
    }
    this.vdSettingsID = item.VDSettingsID;

    if (keyword === "vdjarprinter" || keyword === "vdcassetteprinter" || keyword === "vdslideprinter")
      this.SelectedprinterModel = item.PrinterModel;

    if (this.SelectedRowData) {
      this.showCondition(this.SelectedRowData)
    }
  }


  OnChangeOfdrpdwn(event, type) {
    switch (type) {
      case "drpprintermodel":
        this.LoadTemplateData(event.target.value);
        this.jarcasetteslidergroup.controls.tbdservicetype.patchValue('');
        this.jarcasetteslidergroup.controls.technicalservicetype.patchValue('');
        this.jarcasetteslidergroup.controls.globalservicetype.patchValue('');
        this.jarcasetteslidergroup.controls.profservicetype.patchValue('');
        break;
      case "drpCaseMetatype":
        let casetpy = this.OriginalCaseMetadata.filter(x => x.caseMetaType === event.target.value);
        this.casetype = this.unique(casetpy, "CaseType");
      default:
        break;
    }
  }
  showCondition(Getdata) {
    let keyword = this.templateData.Keyword;
    this.gridViewShow = false;
    this.editScreenGrey = false;
    this.documentPrinter = false;
    this.deviceEdit = false;
    switch (keyword) {
      case "vdjarprinter":
        this.editScreenGrey = true;
        this.editTitle = "Edit Jar Printer Settings";
        this.getDropDownData(Getdata, keyword, "Jar");
        break;
      case "vdcassetteprinter":
        this.editScreenGrey = true;
        this.editTitle = "Edit Cassette Printer ";
        this.getDropDownData(Getdata, keyword, "Cassette");
        break;
      case "vdslideprinter":
        this.editScreenGrey = true;
        this.editTitle = "Edit Slide Printer ";
        this.getDropDownData(Getdata, keyword, "Slide");
        break;
      case "vddocumentprinter":
        this.AssignDataToDocumentAndTechnical(Getdata, "doc");
        break;
      case "vdtechnicalsheetprinter":
        this.AssignDataToDocumentAndTechnical(Getdata, "tech");
        break;
      case "vdmedicaldeviceprinter":
        this.AssignDataToMedicalPrinter(Getdata);
        break;
      default:
        break;
    }
  }

  backToGrid() {
    this.editScreenGrey = false;
    this.documentPrinter = false;
    this.deviceEdit = false;
    this.gridViewShow = true;
  }
  LoadTemplateData(printmodel) {
    let templateData = this.OriginalTemplateData.filter(x => x.context === printmodel);
    this.tbdServiceType = templateData;
    this.technicalServiceType = templateData;
    this.globalServiceType = templateData;
    this.profServiceType = templateData;
  }
  AssignDataToJar(data, type) {
    this.LoadTemplateData(data.Printer_Model);
    this.jarcasetteslidergroup.controls.printerModel.patchValue(data.Printer_Model);
    this.jarcasetteslidergroup.controls.printMode.patchValue(data.PrinterMode);
    this.jarcasetteslidergroup.controls.printerName.patchValue(data.Alias);
    this.jarcasetteslidergroup.controls.isDefaultPrinter.patchValue(data.Default_Value === "true" ? true : false);
    this.jarcasetteslidergroup.controls.printer.patchValue(data.Printer);
    this.jarcasetteslidergroup.controls.caseMetaType.patchValue(data.CaseMetaType);
    this.jarcasetteslidergroup.controls.caseType.patchValue(data.CaseType);
    this.jarcasetteslidergroup.controls.priority.patchValue(data.Priority);
    this.jarcasetteslidergroup.controls.tbdservicetype.patchValue(Number(data.RequsitionTemplateId));
    this.jarcasetteslidergroup.controls.technicalservicetype.patchValue(Number(data.TechTemplateId))

    this.jarcasetteslidergroup.controls.globalservicetype.patchValue(Number(data.GlblTemplateId));
    this.jarcasetteslidergroup.controls.profservicetype.patchValue(Number(data.ProfTemplateId));
    this.jarcasetteslidergroup.controls.numberOfSet.patchValue(data.NumberofSets);
    this.jarcasetteslidergroup.controls.isShowOption.patchValue(data.ShowOptionsonPrint === "true" ? true : false);
    this.jarcasetteslidergroup.controls.allCases.patchValue(data.Allcases);
    this.jarcasetteslidergroup.controls.caseMetaTypeSpecific.patchValue(data.CaseMetaTypeSpecific);
    this.jarcasetteslidergroup.controls.caseTypeSpecific.patchValue(data.CaseTypeSpecific);
    this.jarcasetteslidergroup.controls.prioritySpecific.patchValue(data.PrioritySpecific);
    this.jarcasetteslidergroup.controls.caseMetaTypePrioritySpecific.patchValue(data.CaseMetaTypePrioritySpecific);
    this.jarcasetteslidergroup.controls.caseMetaTypeCaseTypePrioritySpecific.patchValue(data.CaseMetaTypeCaseTypePrioritySpecific);
    if (type === "vdjarprinter") {
      this.caseLabelTemplate.push({ "Name": data.CaseLabelTemplate, "Value": data.CaseLabelTemplate });
      this.jarcasetteslidergroup.controls.isPrintCaseLabel.patchValue(data.Printcaselabels === "true" ? true : false);
      this.jarcasetteslidergroup.controls.caselabeltemplate.patchValue(data.CaseLabelTemplate);
      this.jarcasetteslidergroup.controls.numOfCaseLabel.patchValue(data.Numberofcaselabels);
    }
    if (type === "vdcassetteprinter") {
      this.jarcasetteslidergroup.controls.robinRange.patchValue(data.Magazinerange);
      this.jarcasetteslidergroup.controls.isByCase.patchValue((data.IsMagazineCaseLevel == "true") ? "bycase" : "");
      this.jarcasetteslidergroup.controls.isByBlock.patchValue((data.IsStatMagazineCaseLevel == "true") ? "byblock" : "");
    }
    if (type === "vdslideprinter") {
      this.jarcasetteslidergroup.controls.isUnstainedSlides.patchValue(data.IncludeUnstained === "true" ? true : false);
      this.jarcasetteslidergroup.controls.slideRobinRange.patchValue(data.Magazinerange);
      this.jarcasetteslidergroup.controls.slideByCase.patchValue((data.IsMagazineCaseLevel == "true") ? "bycase" : "");
      this.jarcasetteslidergroup.controls.slideBySlide.patchValue((data.IsStatMagazineCaseLevel == "true") ? "byslide" : "");
      this.jarcasetteslidergroup.controls.sitePerFile.patchValue(data.PrintEachSitePerFile === "true" ? true : false);
      this.jarcasetteslidergroup.controls.folderpath.patchValue(data.FolderPath);
    }
  }
  AssignDataToDocumentAndTechnical(data: any, type: any) {
    this.PrinterName = [];
    this.PrinterName.push({ "Name": data.Printer_Name, "Value": data.Printer_Name });
    this.doctechgroup.controls.PrinterName.patchValue(data.Printer_Name);
    this.doctechgroup.controls.Alias.patchValue(data.Alias);
    this.doctechgroup.patchValue({ DefaultValueDocTech: data.Default_Value === "true" ? true : false });
    if (type === "doc")
      this.doctechgroup.controls.ReqFormNum.patchValue(data.NumberOfRequsitionFormsToPrint);
    else if (type === "tech")
      this.doctechgroup.controls.ReqFormNum.patchValue(data.NumberOfTechnicalSheetToPrint);

    this.documentPrinter = true;
  }
  AssignDataToMedicalPrinter(data: any) {
    //initializes UI data
    this.resData = this.templateData.submenuData[0];
    this.DeviceName = [];
    this.CommunicationMode = [];
    this.CommunicationPort = [];
    this.BaudRate = [];
    this.Parity = [];
    this.LineTerminator = [];
    this.DeviceName.push({ "Name": data.Device_Name, "Value": data.Device_Name });
    this.CommunicationMode.push({ "Name": data.Communication_Mode, "Value": data.Communication_Mode });
    this.CommunicationPort.push({ "Name": data.CommunicationPort, "Value": data.CommunicationPort });
    this.BaudRate.push({ "Name": data.BaudRate, "Value": data.BaudRate });
    this.Parity.push({ "Name": data.Parity, "Value": data.Parity });
    this.LineTerminator.push({ "Name": data.LineTerminator, "Value": data.LineTerminator });
    this.medicalprintergroup.controls.DeviceName.patchValue(data.Device_Name);
    this.medicalprintergroup.controls.CommunicationMode.patchValue(data.Communication_Mode);
    this.medicalprintergroup.controls.DefaultValueMed.patchValue(data.Default_Value === "true" ? true : false);
    this.medicalprintergroup.controls.CommunicationPort.patchValue(data.CommunicationPort);
    this.medicalprintergroup.controls.BaudRate.patchValue(data.BaudRate);
    this.medicalprintergroup.controls.Parity.patchValue(data.Parity);
    this.medicalprintergroup.controls.Alias.patchValue(data.Alias);
    this.medicalprintergroup.controls.ShouldShowDetailsDuringTransfer.patchValue(data.ShouldShowDetailsDuringTransfer === "true" ? true : false);
    this.medicalprintergroup.controls.DataBits.patchValue(data.DataBits);
    this.medicalprintergroup.controls.StopBits.patchValue(data.StopBits);
    this.medicalprintergroup.controls.StartBits.patchValue(data.StartBits);
    this.medicalprintergroup.controls.LineTerminator.patchValue(data.LineTerminator);

    // this.DeviceName = this.resData.Device_Name;
    // this.CommunicationMode = this.resData.Communication_Mode;
    // this.DefaultValueMed = this.resData.Default_Value;
    // this.CommunicationPort = this.resData.CommunicationPort;
    // this.BaudRate = this.resData.BaudRate;
    // this.Parity = this.resData.Parity;
    // this.Alias = this.resData.Alias;
    // this.ShouldShowDetailsDuringTransfer = this.resData.ShouldShowDetailsDuringTransfer;
    // this.DataBits = this.resData.DataBits;
    // this.StopBits = this.resData.StopBits;
    // this.StartBits = this.resData.StartBits;
    // this.LineTerminator = this.resData.LineTerminator;
    //this.templateData = this.resData.TemplateFolder;
    this.deviceEdit = true;
  }

  getDropDownData(Getdata, keyword, type) {
    var query = this.VitalHttpServices.mainQueryList.filter(x => x.Keyword == "vddevicesettingsdropdown");
    let orgID = this.mainCard.Org_ID;
    sessionStorage.setItem( 'deploymentKey', this.VitalHttpServices.deploymentKey);
    let queryRequest: any = {
      "OperationName": null,
      "Query": query[0].Query,
      "Variables": { "orgId": orgID.toString(), "contexttype": type }
    };
    this.VitalHttpServices.GetData(queryRequest).subscribe(result => {
      if (!result.errors) {
        this.OriginalTemplateData = result.data.templatedropdown;
        this.OriginalCaseMetadata = result.data.casesTypeCaseMetaTypesCaseProfile;
        this.OriginalCasePriority = result.data.casePriority;
        this.printermodel = this.unique(this.OriginalTemplateData, "context");
        this.printmode = this.unique(this.OriginalTemplateData, "mode");
        this.casemetatype = this.unique(this.OriginalCaseMetadata, "caseMetaType");
        this.priorityarry = this.OriginalCasePriority;
        this.AssignDataToJar(Getdata, keyword);
      } else {

        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      //  this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });

  }
  unique(array: any, propertyName: string) {
    if (array) {
      return array.filter((e: any, i: any) => array.findIndex((a: { [x: string]: any; }) => a[propertyName] === e[propertyName]) === i);
    }
  }
  savePrinterDetails(value) {
    // let SettiingContent = this.SelectedRowData.SettingsContent;
    // this.parseXML(SettiingContent,value)
    //   .then((data) => {
    //     this.xmlItems = data;
    //   });
  }
  // xml2json(srcDOM) {
  //   let children = [...srcDOM.children];

  //   // base case for recursion.
  //   if (!children.length) {
  //     return srcDOM.innerHTML
  //   }

  //   // initializing object to be returned.
  //   let jsonResult = {};

  //   for (let child of children) {

  //     // checking is child has siblings of same name.
  //     let childIsArray = children.filter(eachChild => eachChild.nodeName === child.nodeName).length > 1;

  //     // if child is array, save the values as array, else as strings.
  //     if (childIsArray) {
  //       if (jsonResult[child.nodeName] === undefined) {
  //         jsonResult[child.nodeName] = [this.xml2json(child)];
  //       } else {
  //         jsonResult[child.nodeName].push(this.xml2json(child));
  //       }
  //     } else {
  //       jsonResult[child.nodeName] = this.xml2json(child);
  //     }
  //   }

  //   return jsonResult;
  // }
  getSaveType() {
    let type;
    let keyword = this.templateData.Keyword;
    if (keyword === "vdjarprinter")
      type = "Jar";
    if (keyword === "vdcassetteprinter")
      type = "Cassette";
    if (keyword === "vdslideprinter")
      type = "Slide";
    return type;
  }
  parseXML(data, value) {
    return new Promise(resolve => {
      let type = this.getSaveType();
      let selprintmodel = this.SelectedprinterModel,

        parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: false
          });
      parser.parseString(data, function (err, result) {
        let updateobjindex = result.DeviceSettings.Printer.findIndex(x => x.PrinterType.toLowerCase() === type.toLowerCase()
          && x.Manufacturer === selprintmodel)
        let updateObj = result.DeviceSettings.Printer.find(x => x.PrinterType.toLowerCase() === type.toLowerCase()
          && x.Manufacturer === selprintmodel);
        updateObj.Manufacturer = value.printerModel;
        updateObj.PrintingMode = value.printMode;
        updateObj.Alias = value.printerName;
        updateObj.IsDefault = value.isDefaultPrinter;
        updateObj.Location = value.printer  /*******condition != slider screen */
        updateObj.PrinterTemplates.CaseMetaType = value.caseMetaType;
        updateObj.PrinterTemplates.CaseType = value.caseType;
        updateObj.PrinterTemplates.CaseType = value.caseType;
        updateObj.PrinterTemplates.Priority = value.priority;
        updateObj.PrinterTemplates.RequsitionTemplateId = value.tbdservicetype;
        updateObj.PrinterTemplates.TechTemplateId = value.technicalservicetype;
        updateObj.PrinterTemplates.GlblTemplateId = value.globalservicetype;
        updateObj.PrinterTemplates.ProfTemplateId = value.profservicetype;
        updateObj.PrinterTemplates.PrintCount = value.numberOfSet;
        updateObj.PrinterTemplates.ShouldShow = value.isShowOption;
        //updateObj.PrinterTemplates.Allcases  = value.allCases; //*****need to check sp */
        if (type == "Jar") {
          updateObj.PrinterTemplates.ShouldLabels = value.isPrintCaseLabel;
          updateObj.PrinterTemplates.LabelCount = value.numOfCaseLabel;
        }
        if (type == "Cassette") {
          //updateObj.PrinterTemplates.Magazinerange  = value.robinRange; //*****need to check sp */
          updateObj.PrinterTemplates.IsMagazineCaseLevel = (value.isByCase == "bycase") ? "true" : "false";
          updateObj.PrinterTemplates.IsStatMagazineCaseLevel = (value.isByCase == "byblock") ? "true" : "false";
        }
        if (type == "Slide") {
          updateObj.PrinterTemplates.ShouldUnstained = value.isUnstainedSlides;
          //updateObj.PrinterTemplates.Magazinerange  = value.slideRobinRang;//*****need to check sp */
          updateObj.PrinterTemplates.IsMagazineCaseLevel = (value.slideByCase == "bycase") ? "true" : "false";
          updateObj.PrinterTemplates.IsStatMagazineCaseLevel = (value.slideByCase == "byslide") ? "true" : "false";
          updateObj.PrinterTemplates.EnablePrintSitePerFile = value.sitePerFile;
          //updateObj.PrinterTemplates.floderpath  = value.folderpath; //*****need to check sp */
        }
        result.DeviceSettings.Printer[updateobjindex] = updateObj;
        let ModifyXml = JSON.stringify(result).replace(/\$/g, "@");
        let xml = JsonToXML.parse("RemoveTag", JSON.parse(ModifyXml));
        let ReplaceXml = xml.replace(/\'/gi, "\"");
        ReplaceXml = ReplaceXml.replace(/<RemoveTag>/gi, "");
        ReplaceXml = ReplaceXml.replace(/<\/RemoveTag>/gi, "");
        resolve(ReplaceXml);
      });
    });
  }

}
