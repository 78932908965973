
<p mat-dialog-title class="header"><b>{{data.dailogHeading}}</b></p>
<div mat-dialog-content class="mt-3 p-3">
    <b> <span style="color : black" [innerText]="data.message"></span></b>
    <p class="alert">{{data.alert}}</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-center align-self-center mb-2 gap">

    <!-- <button type="button"  class="btn cancel" ></button>
    <button type="button" class="btn general" > </button> -->

    <app-button buttonclass="secondary" class="edit-page-button" *ngIf="data.cancel !== 'dontshow'"
        (click)="onNoClick(false)">{{data.cancel}}</app-button>
    <app-button buttonclass="primary" class="edit-page-button"  (click)="onNoClick(true)">{{data.continue}}</app-button>

</div>
