import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-mandatory-fields',
  templateUrl: './mandatory-fields.component.html',
  styleUrls: ['./mandatory-fields.component.scss'],
})

export class MandatoryFieldsComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @Input() templateData: any;
  dbName: any = sessionStorage.getItem('deploymentKey').toUpperCase();
  accid: any;
  casetype: any;
  mandatefields: any = {};
  AccessionMandateFields: any = {};
  SendoutMandateFields: any = {};
  caseinfoobjectval: any = {};
  caseinfoflag: boolean = false;
  mandatflag: any;
  PatientDetailsinfoflag: boolean = false;
  patientobjectval: any = {};
  supplimentinfoflag: boolean = false;
  supplimentobjectval: any = {};
  attachmentinfoflag: boolean = false;
  attachmentmandobjectval: any = {};
  primaryinsuranceflag: boolean = false;
  primaryinsobjectval: any = {};
  secondaryinsuranceflag: boolean = false;
  secondaryinsobjectval: any = {};
  ternaryinsuranceflag: boolean = false;
  ternaryinsobjectval: any = {};
  gurantorinsobjectval: any = {};
  gurantorinsuranceflag: boolean = false;
  insurancedetailsflag: boolean = false;
  hideSaveBtn: boolean;
  Allfields: any;
  selectapplicable: any = '';
  vaservices: any;
  _snackbar: any;
  resetFields: any;
  saveedata: any = {}
  saveMandatefieldstring: any;
  newcaseinfoobjectval: any[] = [];
  newpatientobjectval: any[] = [];
  primInsurancepatientobjectval: any[] = [];
  secInsurancepatientobjectval: any[] = [];
  ternaryInsurancepatientobjectval: any[] = [];
  GaurantInsurancepatientobjectval: any[] = [];
  newsupplimentobjectval: any[] = [];
  newattachmentobjectval: any[] = [];
  checkBoxValue: boolean = false;
  anyCheckboxChecked = false;
  finalmandate: any = {};
  // allCaseType: boolean = false;
  selected:any;
  forallcasetype: string = '';
  selectedcasetype: boolean = false;
  flag: number = 1;
  defaultval:boolean=true;
  changedtoAccessionORsendout: boolean=false;
  showModal: boolean;
  previousPage: any;
  isSaved: boolean=false;
  mandateObject:any=[{name:'Accession Page',value:'AccessionMandateFields'},
                     {name:'Sendout Page',value:'SendoutMandateFields'},
                     {name:'Requisition Page',value:'RequisitionMandateFields'}];
  caseList: any;

  constructor(private VitalHttpServices: VitalHttpServices, public _snackBar: MatSnackBar, private ngxService: NgxUiLoaderService, public commonService: CommonService) { }

  ngOnInit(): void {
    this.getMandatefield();
    this.selected= this.mandateObject[0].value;
    this.GetButtondetails();
    this.checkCasetypeStatus();
  }
  checkData(allfields: any, mandateObject: any): any {
    for (let i = 0; i < mandateObject.length; i++) {
      if (!(mandateObject[i].value in allfields)) {
        mandateObject.splice(i, 1);
        i--;
      }
    }
    return mandateObject;
  }
  
  checkCasetypeStatus() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('casetypeDetails');
    let queryVariable = { "accid": sessionStorage.getItem("AccountID"), "casetype": this.templateData.GroupData.casetype.toString().trim() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.dbName).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.caseList = Resdata.data.submenuData;
        if(!this.hideSaveBtn)
        {
          this.hideSaveBtn=this.caseList[0]["Status"].toLowerCase() =='inactive'?true:false
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  closePopup() {
    this.showModal = false;
  }
  getMandatefieldforcasetype() {
    let query = this.VitalHttpServices.GetQuery('getmandatoryfields');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.dbName).subscribe(
      data => {
        if (!data.errors) {
          var json = JSON.parse(data.data.submenuData[0].ExtAttrValue);
          this.Allfields = json;
          this.mandateObject=this.checkData(this.Allfields,this.mandateObject);
          if(this.defaultval){
            this.getData("AccessionMandateFields")
            this.changedtoAccessionORsendout=false;
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  compareEditData(submenuData: any, currentEditItem: any): any {
    throw new Error("Method not implemented.");
  }

  getMandatefield() {
    this.accid = sessionStorage.getItem("AccountID");
    if (this.casetype == 'All') {
      this.casetype = 'All';
    } else {
      this.casetype = this.templateData.secondarykeys.casetype;
    }
    const getobj={
      "accid": sessionStorage.getItem("AccountID"),
      "casetype": this.casetype
    }
    return this.VitalHttpServices.GetMandatoryfieldsOnCaseType(getobj, this.dbName).subscribe(data => {
      this.ngxService.stop();
      this.mandatefields = data;
      this.getMandatefieldforcasetype();
    },
    error => {
      this.ngxService.stop();
      console.log(error)
    });
  }

  mapData(allfields, mandatefields) {
    if (mandatefields && mandatefields[this.selectapplicable]) {
      for (let [key1, value1] of Object.entries(mandatefields[this.selectapplicable])) {
        for (let [key2, value2] of Object.entries(allfields[this.selectapplicable])) {
          if (key1 == key2) {
            for (let [key3, value3] of Object.entries(value1)) {
              for (let [key4, value4] of Object.entries(value2)) {
                if (key3 == key4) {
                  if (Array.isArray(value3)) {
                    value4.forEach(element => {
                      value3.forEach(value => {
                        if (value.text == element.text) {
                          element.isSelected = value.isSelected
                        }
                      });
                    });
                  }
                  else {
                    for (let [key5, value5] of Object.entries(value3)) {
                      for (let [key6, value6] of Object.entries(value4)) {
                        if (key6 == key5) {
                          if (Array.isArray(value6)) {
                            let arrayAllVal: any = value6
                            let arrayMandVal: any = value5
                            arrayMandVal.forEach(va => {
                              arrayAllVal.forEach(val => {
                                if (val.text == va.text) {
                                  val.isSelected = va.isSelected
                                }
                              })
                            })
                          }
                          else {
                            let objectAllVal: any = value6;
                            let objectMandVal: any = value5;
                            if (objectMandVal.text == objectAllVal.text) {
                              objectAllVal.isSelected = objectMandVal.isSelected
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  switchPage(event :any){
    let page = event.value;
    if(!this.isSaved && this.anyCheckboxChecked && !this.hideSaveBtn){
      if(page !== this.selectapplicable ){
        this.showModal = true;
        this.previousPage =this.selectapplicable
        this.selectapplicable =page;
      }
    }else{
      this.showModal=false;
      this.selectedcasetype=false;
      this.previousPage =this.selectapplicable
      this.selectapplicable =page;  
      this.getData(this.selectapplicable)
      
    }
  }
  switchYesorNo(val?:boolean,selectapplicable?,isSave?){
    if(val !== true){
      this.showModal = false;
      this.selectapplicable=this.previousPage;
      this.selected = (this.selectapplicable === 'AccessionMandateFields') 
      ? this.mandateObject[0].value : (this.selectapplicable === 'SendoutMandateFields') 
        ? this.mandateObject[1].value 
        : this.mandateObject[2].value;
    }else{
      this.showModal = false;
      if(this.anyCheckboxChecked && isSave) {
        this.onSave('SaveAndProceed');
        this.selectedcasetype = false;
        this.getData(selectapplicable)
      }else{
        this.selectedcasetype = false;
        this.getData(selectapplicable)
      }
    }
  }
  getData(applicablepage: string) {
    // this.allCaseType = true;
    this.changedtoAccessionORsendout=true;
    this.anyCheckboxChecked=false;
    this.isSaved=false;
    this.defaultval=false;
    this.getMandatefield();
    this.selectapplicable = applicablepage;
    if (this.Allfields && Object.keys(this.Allfields).length > 0 && this.mandatefields && Object.keys(this.mandatefields).length > 0) {   
      this.mapData(this.Allfields, this.mandatefields);
    }
    this.finalmandate = this.Allfields;
    this.flag= this.flag===0?1:0;

    if (applicablepage) {
      Object.entries(this.finalmandate).forEach(([key, value]) => {
        if (key == applicablepage) {
          Object.entries(value).forEach(([key, value]) => {
            if (key == "CaseInfoDetails") {
              this.caseinfoflag = true;
              this.caseinfoobjectval = value;
              this.caseinfoobjectval = this.caseinfoobjectval.CaseInformationProperties;
            } else if (key == "PatientDetails") {
              this.PatientDetailsinfoflag = true;
              this.patientobjectval = value;
              this.patientobjectval = this.patientobjectval.PatientProperties;
              Object.entries(value).forEach(([key, value1]) => {
                if (key == "InsuranceDetails") {
                  this.insurancedetailsflag = true;
                  Object.entries(value1).forEach(([inskey, insvalue]) => {
                    if (inskey == "PrimaryInsuranceProperties") {
                      this.primaryinsuranceflag = true;
                      this.primaryinsobjectval = insvalue;
                    } else if (inskey == "SecondaryInsuranceProperties") {
                      this.secondaryinsuranceflag = true;
                      this.secondaryinsobjectval = insvalue;
                    } else if (inskey == "TertiaryInsuranceProperties") {
                      this.ternaryinsuranceflag = true;
                      this.ternaryinsobjectval = insvalue;
                    } else if (inskey == "GurantorsProperties") {
                      this.gurantorinsuranceflag = true;
                      this.gurantorinsobjectval = insvalue;
                    }
                  })
                }

              })

            } else if (key == "SupplementalInformationDetails") {
              this.supplimentinfoflag = true;
              this.supplimentobjectval = value;
              this.supplimentobjectval = this.supplimentobjectval.SupplementalInfoProperties;
            } else if (key == "AttachmentMandateFields") {
              this.attachmentinfoflag = true;
              this.attachmentmandobjectval = value
              this.attachmentmandobjectval = this.attachmentmandobjectval.SubCategoryProperties;
            }
          })
        }
      });
    }
  }

  updateaccessiondmandatefield: any = this.mandatefields;
  savemandatefield: any = {
    AccessionMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },
    SendoutMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },
    RequisitionMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },

  }


  updateCheckboxValue(caseInfoProperty?: any, event?: MatCheckbox,isAllSelected?:any) {
    if (caseInfoProperty || isAllSelected) {
      this.anyCheckboxChecked=true;
      this.isSaved=false;
      if(caseInfoProperty){
        caseInfoProperty.isSelected = event.checked.toString();
      }
      this.newcaseinfoobjectval = [];
      this.newpatientobjectval = [];
      this.primInsurancepatientobjectval = [];
      this.secInsurancepatientobjectval = [];
      this.ternaryInsurancepatientobjectval = [];
      this.GaurantInsurancepatientobjectval = [];
      this.newsupplimentobjectval = [];
      this.newattachmentobjectval = [];

      if (Array.isArray(this.caseinfoobjectval)) {
        for (let val of this.caseinfoobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newcaseinfoobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.patientobjectval)) {
        for (let val of this.patientobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newpatientobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.primaryinsobjectval)) {
        for (let val of this.primaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.primInsurancepatientobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.secondaryinsobjectval)) {
        for (let val of this.secondaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.secInsurancepatientobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.ternaryinsobjectval)) {
        for (let val of this.ternaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.ternaryInsurancepatientobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.gurantorinsobjectval)) {
        for (let val of this.gurantorinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.GaurantInsurancepatientobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.supplimentobjectval)) {
        for (let val of this.supplimentobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newsupplimentobjectval.push(val);
            }
          });
        }
      }

      if (Array.isArray(this.attachmentmandobjectval)) {
        for (let val of this.attachmentmandobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newattachmentobjectval.push(val);
            }
          });
        }
      }
    }

    let caseInfo = Array.from(new Set(this.newcaseinfoobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let patientInfo = Array.from(new Set(this.newpatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let primInsurance = Array.from(new Set(this.primInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let secInsurance = Array.from(new Set(this.secInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let terInsurance = Array.from(new Set(this.ternaryInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let GaurantInsurance = Array.from(new Set(this.GaurantInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let newsuppliment = Array.from(new Set(this.newsupplimentobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let newattachment = Array.from(new Set(this.newattachmentobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));

    if (this.selectapplicable == "AccessionMandateFields") {
      this.savemandatefield.AccessionMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.AccessionMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.AccessionMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.AccessionMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    } else if (this.selectapplicable == "SendoutMandateFields") {
      this.savemandatefield.SendoutMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.SendoutMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.SendoutMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.SendoutMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    }else if (this.selectapplicable == "RequisitionMandateFields") {
      this.savemandatefield.RequisitionMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.RequisitionMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.RequisitionMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    }

  }

  onReset() {
    // Reset the data
    // Clear the selected values
    this.caseinfoobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.patientobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.primaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.secondaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.ternaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.gurantorinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.supplimentobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.attachmentmandobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.anyCheckboxChecked=false;
    this.getData(this.selectapplicable);
  }


  onSave(data?:string) {
    this.ngxService.start();
    if(data ==='SaveAndProceed'){
      this.selectapplicable=this.previousPage;
    }
    this.saveMandatefieldstring = JSON.stringify(this.savemandatefield);
    const obj = {
      "accid": sessionStorage.getItem("AccountID"),
      "casetype": this.casetype,
      "mandatefields": this.saveMandatefieldstring,
      "applicaplepage": this.selectapplicable,
      "forall": this.forallcasetype
    }
    this.VitalHttpServices.UpdateMandatoryfieldsOnCaseType(obj, this.dbName).subscribe(res => {
    this.ngxService.stop();
      this._snackBar.open('Saved Successfully.', 'Close');
      this.isSaved=true;
      this.anyCheckboxChecked=false;
      this.getMandatefield();
      this.commonService.createActivityTracker('Created', -1, 'Case Flag Saved Successfully', 'Audit', obj, {});
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }



  ApplyToAll(event: MatCheckbox) {
    this.selectedcasetype = event.checked;
    if (this.selectedcasetype) {
      this.forallcasetype = "All";
      this.updateCheckboxValue(undefined,undefined,this.selectedcasetype)
    } else {
      this.forallcasetype = "";
      this.updateCheckboxValue(undefined,undefined,this.selectedcasetype)
    }
  }

  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    //   this.datashare.SubmenuAction.subscribe(data => {
    //     if (data) {
    //       // this.actionButtonDetails = data;
    //       this.GetButtonAccess(data);
    //     }
    //   },
    //     error => console.error(error));
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Save":
          this.hideSaveBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          // this.anyCheckboxChecked=false;
          break;
      }
    }
  }

  isAnyCheckboxChecked(): boolean {
    // Check if any checkbox is selected in primary, secondary, tertiary, and guarantor tabs
    return this.primaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.secondaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.ternaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.gurantorinsobjectval.some(property => property.isSelected === 'true') ||
      this.caseinfoobjectval.some(property => property.isSelected === 'true') ||
      this.patientobjectval.some(property => property.isSelected === 'true') ||
      this.attachmentmandobjectval.some(property => property.isSelected === 'true') ||
      this.supplimentobjectval.some(property => property.isSelected === 'true');
  }


  
}
