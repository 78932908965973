import { Component, OnInit } from '@angular/core';
import { CommonService } from './core/services/commonservices';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private commonService: CommonService) {}
  title = 'VitalAdmin-v3';


  ngOnInit(): void {
    this.commonService.setTitle('VitalAdmin', '../../assets/images/va_favicon.png');
  }
}