import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<ViewAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ){}

  documentType : string = ''
  url: string = '';
  txtFileContent : string | ArrayBuffer | null = '';
  heading : string = ''
  
  ngOnInit(): void {
    this.url = window.URL.createObjectURL(this.config.file);
    this.heading = this.config.file.name
    this.documentType = this.getDocumentType(this.config.file.type);
    if (this.documentType === 'text') {
        var txtFIle = this.config.file as File;
        let fileReader: FileReader = new FileReader();
        let self = this;
        fileReader.onloadend = function (x) {
          self.txtFileContent = fileReader.result;
        };
        fileReader.readAsText(txtFIle);
      }
  }

  private getDocumentType(contextType: string) {
    if (contextType === 'application/pdf') return 'pdf';
    else if (
      contextType === 'image/jpeg' ||
      contextType === 'image/png' ||
      contextType === 'image/jpg'
    )
      return 'image';
    return 'text';
  }
}
