// import { element } from 'protractor';
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { SessionComponent } from 'src/app/base/session/session.component';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { DataShareService } from 'src/app/core/services/datashare.service';
// import { DomSanitizer } from "@angular/platform-browser";
// import { MatSnackBar, MatNativeDateModule } from '@angular/material';
// import { Subject } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
// import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { SetupNewFacilityComponent } from '../setup-new-facility/setup-new-facility.component';
import { SetupNewEntitiesComponent } from './../setup-new-entities/setup-new-entities.component';
import { HomeLayoutComponent } from '../../../common/layout/home-layout.component';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'template-AddUserToOrg',
  templateUrl: 'AddUserToOrg.component.html',
  styleUrls: ['./AddUserToOrg.component.scss'],
  providers: [DatePipe]
})

export class AddUserToOrgComponent implements OnInit {
  @Input() selecteddefaultdeploymentkey: any;
  @Input () createLiveUser:any ; // variable for checking role has access to create live users
  //#region Setup new facility
  @Input() fromComponent: any;
  @Input() OrgValue: any;
  @Input() AccValue: any;
  @Input() fromDepKey: string;
  @Input() Component: any;
  //#endregion

  public templateData;
  public associationData;
  public rolesdata;

  showOrgData = false;
  NoDeploymentKeyAlert = false;
  NoOrgData = false;
  ShowFrmAddUserDetail = false;
  showAccounts = false;
  showRoles = false;
  associatedAccountNames: string[];

  public holdReleaseDuration = "0";
  public DiagICDcodeReminder: boolean;
  public isICDdisplay: boolean;
  public UserType = "";
  public IsOrderingFacility: boolean;
  public EPGorgType = "";
  public ArrEPGOrgType;
  public userID = 0;
  public ArrOrgType = [];
  // public associationtype;
  public ArrThresholdDuration = [];
  public ArrSavedRoles = [];
  // public ArrIsMedicalRolePresent = [];
  public IsMedicalDirector: boolean = false;
  public IsMedDirectorExistsForCommon: boolean = false;
  public hidIsCytoSelected: boolean = false;
  public hidIsCytoSupervisiorSelected = false;
  public hidIsChecked = false;
  public hidPrimaryRole = "";
  public hidIsPathlogistSelected: boolean = false;
  public hidIsMedicalDirector = false;
  public hidIsPhysicianSelected = false;
  public CommonKey = "";
  public DeploymentKeys = [];
  private deploymentvalue = "";
  ShoHoldReleaseDuration = false;
  ShowMedicalDirector = false;

  Licensure_count: number = 1;
  Licensure_array = [1];

  StateList: any[];
  StateDiscription = "";
  HRD_KValue: any = "";
  MD_KValue: any = "";
  ShowLicensureDetails: any = false;
  loginNameExists: boolean = false;
  loginNamesList: any;
  alertOnSave1: boolean = false;
  alertOnSave2: boolean = false;
  //alertOnSave3: boolean = false;
  alertOnSave4: boolean = false;
  //alertOnSave5: boolean = false;
  // alertOnSave6: boolean = false;
  alertOnSaveCommon: boolean = false;

  // RoleCheckboxValidator: boolean = false;

  @ViewChild('chkrole') role_Chk_click: ElementRef<HTMLElement>;
  showfrmInp_AllowReviewPathologist: boolean = false;
  showfrmInp_AllowtoeditAccession: boolean = false;
  showfrmInp_EditSite: boolean = false;
  showfrmInp_AllowPathologistDiagnosisTemplate: boolean = false;
  showfrmInp_Notifyusers: boolean = false;
  showfrmInp_DisplayFormatReports: boolean = true;
  SelectedOrgID: any = "";
  hidlicensure: string;
  showfrmInp_Threshold: boolean = false;
  showfrmDrp_ThresholdDuration: boolean = false;
  showfrmInp_PopulateDiag: boolean = false;
  ClickFrom: string = "UI";
  alertOnSave7: boolean = false;
  CommonAlertOnSave: string = "";
  zip_pattern = "/^\d{5}([\-]\d{4})?$/";

  frm_AddUsergroup = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmOrganization: [{ value: '', disabled: true }, Validators.required],
    frmAccountName: '',
    // frmDrp_Works: [''],
    // frmDrp_Manages: [''],
    // frmDrp_SendforReview: [''],
    // frmchk_Location: [{ value: false }],
    frmInp_title: 'Dr.',
    frmInp_Address1: '',
    frmInp_FirstName: ['', Validators.required],
    frmInp_Address2: '',
    frmInp_LastName: ['', Validators.required],
    frmInp_Street: '',
    frmInp_MiddleInitial: '',
    frmInp_City: '',
    frmInp_Qualification: '',
    frmInp_State: 'Select',
    frmInp_LoginName: ['', Validators.required],
    frmInp_Zip: ['', [Validators.required, Validators.pattern(this.zip_pattern)]],
    frmInp_Gender: "1",
    frmInp_Country: '',
    frmInp_NPI: [ 'UNKNOWN' ],
    frmInp_Phone: '',
    frmInp_DisplayFormatWebpage: "F L D",
    frmInp_Fax: '',
    frmInp_DisplayFormatReports: "S. F L D",
    frmInp_Email: ['', Validators.required],
    frmInp_SSN: '',
    frmInp_Status: "active",
    frmInp_UserCode: '',
    frmInp_UPIN: '',
    frmInp_ShowMyCaseOnly: false,
    frmInp_ExternalUserGUID: '',
    //frmInp_Region: ['', Validators.required],
    frmInp_US: false,
    frmInp_ST: false,
    frmInp_AllowReviewPathologist: false,
    // frmInp_Licensure: ['', Validators.required],
    frmInp_AllowtoeditAccession: false,
    frmInp_Threshold: '',
    frmInp_EditSite: false,
    frmDrp_ThresholdDuration: '',
    frmInp_AllowPathologistDiagnosisTemplate: false,
    frmInp_PopulateDiag: false,
    frmInp_Notifyusers: false,
    // frmDrp_LicsenceStates: '',
    frmArruserRoles: this._fb.array([]),
    frmArruserAccounts: this._fb.array([]),
    frmDrp_OrgSendforReview: 'true',
    frmDrp_OrgManages: 'true',
    frmDrp_OrgWorks: 'true',
    frmchk_OrgLocation: false,
    formLicensure: this._fb.array([]),
    frmLblchk_OrgLocation: '',
    frmChk_RoleCheckboxValidator: false,
    frmInp_AllowLogin: true
  });

  InitialDeployKey: string = sessionStorage.getItem('deploymentKey') ? sessionStorage.getItem('deploymentKey').toString() : '';
  rolelistcount: any;
  rolelablistcytcount: any;
  rolelablistcyscount: any;
  LoginTypesflag: boolean;
  valueloginname: any;
  passwordvalue: string;
  alertOnSave10: boolean = false;
  addloginname: any;
  alertOnSave6: boolean = false;
  rolelablistcount: any;
  passworddata: string;
  iflogindeatils: boolean = false;
  count =-1;
  HRD_value: number=0;
  saveandcreateflag: boolean=false;
  disabledloginname: boolean=false;
  organizationLoginType: string = '';
  defaultdeploymentkey: string;
  isselectorganization: boolean=false;
  saveandcloseflag: boolean = false;
organizationID: any
  deploymentKey: string;
  constructor(private ngxService: NgxUiLoaderService, private clipboard: Clipboard, private _snackbar: MatSnackBar, public vaservices: VitalHttpServices, private _fb: FormBuilder,private homeLayoutComponent : HomeLayoutComponent,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router, private dialog: MatDialog, private SetupNewFacilityComponent: SetupNewFacilityComponent,
    public _snackBar: MatSnackBar, public datepipe: DatePipe, public SetupNewEntitiesComponent: SetupNewEntitiesComponent) {
    this.InitialDeployKey = sessionStorage.getItem('deploymentKey') ? sessionStorage.getItem('deploymentKey').toString() : '';
    this.vaservices.deploymentKey = this.InitialDeployKey;
    //if the request is from Component SetupNewFacility
    if (this.fromComponent == "SetupNewFacility") {
      this.frm_AddUsergroup.patchValue({
        frmDeploymentKey: this.vaservices.deploymentKey
      });
      this.deploymentvalue = this.vaservices.deploymentKey;
      this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
      setTimeout(() => {
        this.frm_AddUsergroup.patchValue({
          frmchk_OrgLocation: true
        });
        this.onChangefrmchk_OrgLocation(true)
      }, 3000);
    }
    else {
      this.frm_AddUsergroup.patchValue({
        frmDeploymentKey: "none"
      });
      this.DeploymentKeys = this.vaservices.DeploymentKeys;
      this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
    }
  }

  // initializeFromHome() {
  // }

  // ngOnInit() {
  //   this.InitialDeployKey = sessionStorage.getItem('deploymentKey') ? sessionStorage.getItem('deploymentKey').toString() : '';
  //   this.vaservices.deploymentKey = this.InitialDeployKey;
  //   if (this.fromComponent == "SetupNewFacility") {
  //     this.frm_AddUsergroup.patchValue({
  //       frmDeploymentKey: this.vaservices.deploymentKey
  //     });
  //     this.deploymentvalue=this.vaservices.deploymentKey;
  //     this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
  //     // this.showAddUserDetailsFunc(this.OrgValue)
  //     this.setOrganization(this.OrgValue);
  //     //this.context = 'facility'
  //     setTimeout(() => {
  //       this.frm_AddUsergroup.patchValue({
  //         frmchk_OrgLocation: true
  //       });
  //       this.onChangefrmchk_OrgLocation(true)
  //     }, 3000);
  //     this.searchOrgType(this.OrgValue);
  //   }
  //   else {
  //     this.DeploymentKeys = this.vaservices.DeploymentKeys;
  //     this.frm_AddUsergroup.patchValue({
  //       frmDeploymentKey: "none"
  //     });
  //     this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
  //   }
  // }

  ngOnInit() {
    // this.deploymentKey = sessionStorage.getItem('deploymentKey');
    if (this.fromComponent) {
      this.InitialDeployKey = sessionStorage.getItem('deploymentKey') ? sessionStorage.getItem('deploymentKey').toString() : '';
    }

    // this.InitialDeployKey = this.defaultdeploymentkey;
    // this.vaservices.deploymentKey = this.InitialDeployKey;
    if (this.fromComponent == "SetupNewFacility") {
      this.frm_AddUsergroup.patchValue({
        frmDeploymentKey: this.vaservices.deploymentKey
      });
      this.deploymentvalue = this.vaservices.deploymentKey;
      this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
      // this.showAddUserDetailsFunc(this.OrgValue)
      this.setOrganization(this.OrgValue);
      //this.context = 'facility'
      setTimeout(() => {
        this.frm_AddUsergroup.patchValue({
          frmchk_OrgLocation: true
        });
        this.onChangefrmchk_OrgLocation(true)
      }, 3000);
      this.searchOrgType(this.OrgValue);
    }
    else {
      this.DeploymentKeys = this.vaservices.DeploymentKeys;
      this.frm_AddUsergroup.patchValue({
        frmDeploymentKey: !this.fromComponent ? (this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase().trim() != 'all' ? this.selecteddefaultdeploymentkey.toString().toUpperCase() : 'none') : this.InitialDeployKey
      });
      this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
    }

    !this.fromComponent ? this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase().trim() != 'all' ? this.funcShowPopOrg() : null : null;
  }

  // ngOnChanges(){
  //   if (!this.fromComponent) {
  //     let defaultdeployment = localStorage.getItem('defaultdeployment');
  //     if (defaultdeployment === undefined || defaultdeployment === null || defaultdeployment === '' || defaultdeployment === 'none') {
  //       this.defaultdeploymentkey = "LIS";
  //       this.InitialDeployKey = this.defaultdeploymentkey;
  //       this.vaservices.deploymentKey = this.InitialDeployKey;
  //     } else {
  //       this.defaultdeploymentkey = defaultdeployment.toUpperCase();
  //       this.InitialDeployKey = this.defaultdeploymentkey;
  //       this.vaservices.deploymentKey = this.InitialDeployKey;
  //     }
  //   }
  //   else {
  //     this.InitialDeployKey = sessionStorage.getItem('deploymentKey') ? sessionStorage.getItem('deploymentKey').toString() : '';
  //   }
  //   if (this.fromComponent == "SetupNewFacility") {
  //     this.frm_AddUsergroup.patchValue({
  //       frmDeploymentKey: this.vaservices.deploymentKey
  //     });
  //     this.deploymentvalue = this.vaservices.deploymentKey;
  //     this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
  //     // this.showAddUserDetailsFunc(this.OrgValue)
  //     this.setOrganization(this.OrgValue);
  //     //this.context = 'facility'
  //     setTimeout(() => {
  //       this.frm_AddUsergroup.patchValue({
  //         frmchk_OrgLocation: true
  //       });
  //       this.onChangefrmchk_OrgLocation(true)
  //     }, 3000);
  //     this.searchOrgType(this.OrgValue);
  //   }
  //   else {
  //     this.DeploymentKeys = this.vaservices.DeploymentKeys;
  //     this.frm_AddUsergroup.patchValue({
  //       frmDeploymentKey: this.vaservices.deploymentKey ? this.vaservices.deploymentKey.toString().toUpperCase() : 'none'
  //     });
  //     this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
  //   }
  // }


  searchOrgType(OrgValue) {
    // let searchValue = (<HTMLInputElement>document.getElementById('inpOrgSearch')).value;
    let searchDrpValue = "Group ID"
    let searchInpValue =  OrgValue.organizationid;
    let depKey: string = this.frm_AddUsergroup.controls.frmDeploymentKey.value;

    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('adduserfilterorg');
    let queryString: string = "";
    switch (searchDrpValue) {
      case 'Group ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Group Name':
        queryString = "organizationid >= \"1\":decimal and organizationname like \"%" + searchInpValue + "%\"";
        queryVariable = { "filterParam": queryString }
        break;
      default:
        queryVariable = '';
        break;
    }

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult, depKey).subscribe(res => {
      // sessionStorage.setItem('deploymentKey', this.deploymentKey);
      // sessionStorage.setItem('DeploymentKey', this.deploymentKey);
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        this.templateData = res.data.Organizations_list;
        this.organizationLoginType = (this.templateData[0].LoginType == null || this.templateData[0].LoginType == '' || this.templateData[0].LoginType.toLowerCase() == 'loginname') ? 'LoginName' : 'Email';
        if (this.templateData[0].LoginType != null) {
          this.LoginTypesflag = this.templateData[0].LoginType.toLowerCase() != 'email' ? true : false;
          this.disabledloginname = true;
        }
        else {
          this.LoginTypesflag = true;
          this.disabledloginname = false;
        }
        this.ShowFrmAddUserDetail = true;

        //   if (this.templateData.length > 0) {
        //     // this.templateData = this.templateData;
        //     this.showOrgData = true;
        //     this.NoOrgData = false;
        //   }
        //   else {
        //     this.templateData = []
        //     this.showOrgData = false;
        //     this.NoOrgData = true;
        //   }
        // }
        // else {
        //   console.error(res.error);
        //   this.templateData = [];
        //   this.showOrgData = true;
        //   this.NoOrgData = false;
      }
    }, error => {
      console.error(error);
      this.templateData = [];
      this.showOrgData = true;
      this.NoOrgData = false;
    });
  }
  AddUsergroupClose(CloseFlag) {
    // sessionStorage.setItem('DeploymentKey',this.deploymentKey);
    //   sessionStorage.setItem('deploymentKey',this.deploymentKey);
    //   this.vaservices.DeploymentKeys = this.deploymentKey
    this.homeLayoutComponent.SelectedMenuModel = false;
    this.iflogindeatils = false;
    this.count = -1;
    this.closePopOrg();
    this.frm_AddUsergroup.reset();
    this.onChangeDep("VPLABS");
    this.frm_AddUsergroup.patchValue({
      frmDeploymentKey: "none"
    });
    //reset the deployment key
    // if (this.deploymentKey != 'NULL' && this.deploymentKey) {
    //   sessionStorage.setItem('deploymentKey', this.deploymentKey);
    //   this.vaservices.deploymentKey =  this.deploymentKey
    // }
    // $('#AddUserModel').modal('hide');

    // this.deploymentKey
    if (this.fromComponent != 'SetupNewFacility') {
      if (CloseFlag)
      {
        this.saveandcreateflag=false;
        this.saveandcloseflag = false;
      }
      if(!this.saveandcreateflag )
      {
      $('#genericModal').modal('hide');
      }
      else {
        this.frm_AddUsergroup.patchValue({
          frmDeploymentKey: this.vaservices.deploymentKey
        });
        this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()

      }
    }
    else if (this.fromComponent == 'SetupNewFacility') {
      //reset the deployment key
      // sessionStorage.setItem('deploymentKey', this.InitialDeployKey);
      this.AddUserPopUpClose();
    }
    // this.frm_AddUsergroup.value.frmDeploymentKey = "";
    this.saveandcreateflag=true;
    this.saveandcloseflag = true;
  }

  //   }
  // );
  frm_AddUserSelectOrgGroup = this._fb.group({
    frmDrpOrgSearch: ['', Validators.required],
    frmOrgSearchInpTxt: ['', Validators.required]
  });

  funcShowPopOrg() {
    this.ShowFrmAddUserDetail = false;
    this.frm_AddUsergroup.patchValue({
      frmOrganization: ""
    });
    this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()

    this.frm_AddUserSelectOrgGroup.patchValue({
      frmDrpOrgSearch: "Group ID"
    });
    if (this.frm_AddUsergroup.value.frmDeploymentKey != "" && this.frm_AddUsergroup.value.frmDeploymentKey != undefined) {
     $('.collapse').collapse('show');
      this.NoDeploymentKeyAlert = false;
    }
    else {
      $('.collapse').collapse('hide');
      this.NoDeploymentKeyAlert = true;
    }
  }

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.vaservices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  btnSearchOrg(frm_AddUserSelectOrgGroupValue) {
    // let searchValue = (<HTMLInputElement>document.getElementById('inpOrgSearch')).value;
    let searchDrpValue = frm_AddUserSelectOrgGroupValue.frmDrpOrgSearch;
    let searchInpValue = frm_AddUserSelectOrgGroupValue.frmOrgSearchInpTxt.trim();
    let depKey: string = this.frm_AddUsergroup.value.frmDeploymentKey.trim();
    if (depKey == "none") {
      depKey = this.selecteddefaultdeploymentkey;
    }
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('GetLiveAndTestOrgs');

    let queryString: string = "";
    this.deploymentvalue = depKey;
    //this.vaservices.deploymentKey = depKey.toLowerCase();
    //sessionStorage.setItem('deploymentKey', depKey.toLowerCase());

    switch (searchDrpValue) {
      case 'Group ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Group Name':
        queryString = "organizationid >= \"1\":decimal and organizationname like \"%" + searchInpValue + "%\"";
        queryVariable = { "filterParam": queryString }
        break;
      default:
        queryVariable = '';
        break;
    }

    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        this.templateData = res.data.Admin3_vwGetLiveAndTestOrgs_list;
        // if(!this.createLiveUser){
        //   this.templateData=this.templateData.filter(va=>va.isLive !=true)
        // }

        this.organizationLoginType = (this.templateData[0]?.LoginType == null || this.templateData[0]?.LoginType == '' || this.templateData[0]?.LoginType.toLowerCase() == 'loginname') ? 'LoginName' : 'Email';
        if(this.templateData[0]?.LoginType!=null){
        this.LoginTypesflag=this.templateData[0]?.LoginType.toLowerCase()!='email'? true : false ;
        this.disabledloginname=true;
        }
        else {
          this.LoginTypesflag = true;
          this.disabledloginname = true;
        }
        if (this.templateData.length > 0) {
          // this.templateData = this.templateData;
          this.showOrgData = true;
          this.NoOrgData = false;
        }
        else {
          this.templateData = []
          this.showOrgData = false;
          this.NoOrgData = true;
        }
      }
      else {
        console.error(res.error);
        this.templateData = [];
        this.showOrgData = true;
        this.NoOrgData = false;
      }
    }, error => {
      console.error(error);
      this.templateData = [];
      this.showOrgData = true;
      this.NoOrgData = false;
    });
  }

  setOrganization(OrgValue) {
    let displayorg: string = "(" + OrgValue.organizationid + ") " + OrgValue.organizationname;
    if (displayorg && this.selecteddefaultdeploymentkey == undefined) {
      this.isselectorganization = true
    }
    this.frm_AddUsergroup.patchValue({
      frmOrganization: displayorg.trim()
    });
    this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
        // frm_AddUsergroup.patchValue.frmOrganization = '{{data.organizationname}}'
    this.closePopOrg();
    // this.NoDeploymentKeyAlert = false;
    // this.resetAddUserDetails();

    this.SelectedOrgID = OrgValue.organizationid;
    this.organizationID = OrgValue.organizationid
    this.searchOrgType(OrgValue);

    // this.showAddUserDetailsFunc(OrgValue.organizationid);

  }

  resetAddUserDetails() {
    this.templateData = "";
    this.associationData = "";
    this.rolesdata = "";

    this.showAccounts = false;
    this.showRoles = false;
    this.associatedAccountNames = [];

    this.holdReleaseDuration = "0";
    this.DiagICDcodeReminder = false;
    this.isICDdisplay = false;
    this.UserType = "";
    this.IsOrderingFacility = false;
    this.EPGorgType = "";
    this.ArrEPGOrgType = "";
    this.userID = 0;
    this.ArrOrgType = [];
    // this.associationtype;
    this.ArrThresholdDuration = [];
    this.ArrSavedRoles = [];
    // public ArrIsMedicalRolePresent = [];
    this.IsMedicalDirector = false;
    this.IsMedDirectorExistsForCommon = false;
    this.hidIsCytoSelected = false;
    this.hidIsCytoSupervisiorSelected = false;
    this.hidIsChecked = false;
    this.hidPrimaryRole = "";
    this.hidIsPathlogistSelected = false;
    this.hidIsMedicalDirector = false;

    this.ShowMedicalDirector = false;
    this.ShoHoldReleaseDuration = false;

    this.hidIsPhysicianSelected = false;
    this.CommonKey = "";

    this.StateDiscription = "";

    this.StateList = [];
    this.HRD_KValue = "";
    this.MD_KValue = "";
    this.ShowLicensureDetails = false;
    this.alertOnSave1 = false;
    this.alertOnSave2 = false;
    //this.alertOnSave3 = false;
    this.alertOnSave4 = false;
    //this.alertOnSave5 = false;
    this.alertOnSave10 = false;
    this.alertOnSave6 = false;
    this.alertOnSaveCommon = false;
    this.CommonAlertOnSave = "";
    this.ClickFrom = "UI";

    // this.RoleCheckboxValidator = false;

    this.SelectedOrgID = "";

    this.showfrmInp_AllowReviewPathologist = false;
    this.showfrmInp_AllowtoeditAccession = false;
    this.showfrmInp_EditSite = false;
    this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
    this.showfrmInp_Notifyusers = false;
    this.showfrmInp_DisplayFormatReports = true;
    this.hidlicensure = "";
    this.showfrmInp_Threshold = false;
    this.showfrmDrp_ThresholdDuration = false;
    this.showfrmInp_PopulateDiag = false;

    // this.frm_AddUsergroup.reset();
    //do not reset here as org and deployment values will be lost
    this.frm_AddUsergroup.patchValue(
      {
        frmInp_title: 'Dr.',
        frmInp_Gender: "1",
        frmInp_DisplayFormatWebpage: "F L D",
        frmInp_DisplayFormatReports: "S. F L D",
        frmInp_Status: "active",

        frmInp_ShowMyCaseOnly: false,
        frmInp_AllowtoeditAccession: false,
        frmInp_EditSite: false,
        frmInp_AllowPathologistDiagnosisTemplate: false,
        frmInp_Notifyusers: false,
        frmInp_LoginName: '',

        frmArruserAccounts: [],
        frmArruserRoles: [],

        //rest form controls are reset here
        frmAccountName: '',
        frmInp_Address1: '',
        frmInp_FirstName: '',
        frmInp_Address2: '',
        frmInp_LastName: '',
        frmInp_Street: '',
        frmInp_MiddleInitial: '',
        frmInp_City: '',
        frmInp_Qualification: '',
        frmInp_State: 'Select',
        frmInp_Zip: '',
        frmInp_Country: '',
        frmInp_NPI: 'UNKNOWN',
        frmInp_Phone: '',
        frmInp_Fax: '',
        frmInp_Email: '',
        frmInp_SSN: '',
        frmInp_UserCode: '',
        frmInp_UPIN: '',
        frmInp_ExternalUserGUID: '',
        //frmInp_Region: '',
        frmInp_US: false,
        frmInp_ST: false,
        frmInp_AllowReviewPathologist: false,
        frmInp_Threshold: '',
        frmDrp_ThresholdDuration: '',
        frmInp_PopulateDiag: false,
        frmDrp_OrgSendforReview: 'true',
        frmDrp_OrgManages: 'true',
        frmDrp_OrgWorks: 'true',
        frmchk_OrgLocation: false,
        // formLicensure: this._fb.array([]),
        frmLblchk_OrgLocation: '',
        frmChk_RoleCheckboxValidator: false,
        frmInp_AllowLogin: true
      });
  }

  closePopOrg() {
    this.closeManualPopOrg();
    // this.resetAddUserDetails();
  }

  closeManualPopOrg() {
    $('.collapse').collapse('hide');
    this.frm_AddUserSelectOrgGroup.reset();
    this.NoOrgData = false;
    this.showOrgData = false;
  }

  onChangeDep(deviceValue) {
   // this.closePopOrg();
   this.frm_AddUserSelectOrgGroup.reset();
   this.NoOrgData = false;
   this.showOrgData = false;
    this.frm_AddUsergroup.patchValue({
      frmOrganization: "",
      frmDeploymentKey: deviceValue
    });
    // $('.collapse').collapse('hide');
    // this.NoDeploymentKeyAlert = false;
    // this.ShowFrmAddUserDetail = false;
    this.funcShowPopOrg();
  }

  getAssociationTypes(accIDs, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    for (i = 0; i < accIDs.length; i++) {
      if (i == accIDs.length - 1) {
        Qstr += "accountid = \"" + accIDs[i] + "\"";
      }
      else {
        Qstr += "accountid = \"" + accIDs[i] + "\" or ";
      }
    }
    queryVariable = { "accid": Qstr };

    let associationTypes: any[];
    // {"associationtype": "Associationtype in \"both\",\"laboratory\""}
    // {"accid": "accountid = \"5\" or accountid = \"3\""}

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        // this.ShowFrmAddUserDetails = true;
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          // this.templateData = this.templateData;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              //to get unique associations from arrayF
              associationTypes.push(element.associationtype)
            }
          });
          this.getRoles(associationTypes, OrgId);
        }
        else {
          this.associationData = "";
          // this.showAccounts = false;
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }

  getRoles(AssociationTypes, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }
    queryVariable = { "associationtype": Qstr };

    // let associationTypes: any[];

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        // this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list;
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata = [];
          this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted

          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.rolesdata = rolesarray;

          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();

          let k;
          for (k = 0; k < this.rolesdata.length; k++) {
            let frm;
            frm as FormGroup;
            frm = "userRole_" + k;

            let IsPathologistActive: boolean = false;
            if (this.rolesdata[k].rolename == "Medical Staff")
              this.rolesdata[k].rolename = "Medical Assistant";
            else if (this.rolesdata[k].rolename == "Transcriptionist")
              this.rolesdata[k].rolename = "Pathologist Assistant";
            if (this.rolesdata[k].rolename == "Pathologist") {
              this.ShowMedicalDirector = true;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: false }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: '0', disabled: true })
              });
            }
            else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = true;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: '0', disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: true })
              });
            }
            else {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: '0', disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: true })
              });
            }

            this.ArrSavedRoles.forEach((e, row_index) => {
              if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
                frm.controls.frmChk_UserRoles_RoleName.value = true;
                frm.controls.frmChk_UserRoles_RoleName.disable();
                frm.controls.frmRd_UserRoles_RoleName.value = '';

                frm.controls.frmRd_UserRoles_RoleName.enable();

                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist") {
                  this.hidIsCytoSelected = true
                }
                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor") {
                  this.hidIsCytoSupervisiorSelected = true
                }
                if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                  if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                    if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager" || frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist") {
                      frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                    }
                  }
                }
                if (e.IsPrimary == true) {
                  this.hidPrimaryRole = e.rolename;
                  frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
                }
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  frm.controls.frmLbl_userID.value = e.userid;
                  if (e.Status == true) {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                  }
                  else {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                  }
                }
              }
              if (e.rolename == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                    frmInp_EditSite: e.ModifyTechnicalData as boolean,
                    frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                  });

                if (e.DiagICDcodeReminder == null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if (e.rolename == "Pathologist") {
                frm.controls.IsPathologistActive.value = e.STATUS as boolean;
                frm.controls.frmChk_IsMedicalDirector.value = e.isMedicalDirector as boolean;
              }
            });

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSupervisiorSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            let isPatholigistEnabled = false;

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsPathlogistSelected = true;
              isPatholigistEnabled = true;
              this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
              this.showfrmInp_AllowReviewPathologist = true;
              this.ShowLicensureDetails = true;

              this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
              this.showfrmInp_AllowtoeditAccession = true;
              this.showfrmInp_Threshold = true;

              this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
              this.showfrmInp_EditSite = true;
              this.showfrmDrp_ThresholdDuration = true;

              this.frm_AddUsergroup.controls['frmChk_IsMedicalDirector'].enable();

              this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
              this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
              this.showfrmInp_PopulateDiag = true;

              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            /*Flag as Medical Director*/
            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist") {
              if (this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false)
                this.hidIsMedicalDirector = true;
              if ((this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false) || !IsPathologistActive) {
                frm.controls.frmChk_IsMedicalDirector.disable();
              }
              else {
                frm.controls.frmChk_IsMedicalDirector.enable();
              }
            }
            // if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            //   this.hidIsCytoSelected = true;
            //   // this.frm_AddUsergroup['frmInp_Notifyusers'].style({
            //   //   display: 'block'
            //   // });
            //   this.frm_AddUsergroup.patchValue({
            //     frmInp_Notifyusers: { disabled: false }
            //   });
            // }
            // if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
            //   this.hidIsCytoSupervisiorSelected = true;
            //   // this.frm_AddUsergroup['frmInp_Notifyusers'].style({
            //   //   display: 'block'
            //   // });
            //   this.frm_AddUsergroup.patchValue({
            //     frmInp_Notifyusers: { value: , disabled: false }
            //   });
            // }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {
              this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
            }

            if (isPatholigistEnabled) {
              let k;
              for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
              }
              this.ShowSendforReview(true);
            }
            (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
          }
          this.ShowSendforReview(false);

          //to display hold release duration and  medical director
          this.MD_KValue = -1;
          this.HRD_KValue = -1;
          for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
            if (this.HRD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
              this.HRD_KValue = k;
            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
              this.rolelablistcount = k

            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
              this.rolelablistcytcount = k
            }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
              this.rolelablistcyscount = k
            }
            // else if (this.HRD_KValue == "") {
            //   this.HRD_KValue = -1;
            // }
            if (this.MD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_IsMedicalDirector').enabled == true) {
              this.MD_KValue = k;
            }
            // else if (this.MD_KValue == "") {
            //   this.MD_KValue = -1;
            // }
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
              this.rolelistcount = k;
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
                this.MD_KValue = k;
                if (this.EPGorgType == 'Pathologists') {
                  this.ShowLicensureDetails = true;
                }
                else {
                  this.ShowLicensureDetails = false;
                }
              } else {
                this.ShowLicensureDetails = false;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          }
          this.showRoles = true;
        }
      }
      else {
        console.error(res.error);
        this.rolesdata = [];
        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          this.ShowMedicalDirector = false;
          this.ShoHoldReleaseDuration = false;
          let frm;
          frm as FormGroup;
          frm = "userRole_" + k;
          frm = this._fb.group({
            frmChk_UserRoles_RoleName: [{ value: false, disabled: false }],
            frmLblChk_UserRoles_RoleName: new FormControl(''),
            frmRd_UserRoles_RoleName: [{ value: '', disabled: false }],
            frmlbl_DisplayName: ''
            // frmInp_RoleHoldreleaseDuration: new FormControl({ value: '0', disabled: true }),
            // frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: true })
          });
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();
          (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
        }
        // this.showAccounts = false;
        this.showRoles = false;
      }
      //this.getIsMedicalRolePresent(OrgId, 0);
      this.getIsMedicalDirectorvalue();
    }, error => {
      console.error(error);
    });
  }

  ShowSendforReview(show: boolean) {
    if (show) {
      // this.frm_AddUsergroup.patchValue(
      //   {
      //     frmDrp_OrgSendforReview: { value: true, disabled: show }
      //   });
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].enable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).patchValue(
        //   {
        //     frmDrp_SendforReview: { value: true, disabled: show }
        //   });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
      }
    }
    else {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].disable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].disable();
      }
    }
  }

  ToggleMainChkBxForRoleName(event) {
    let k;
    for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
          {
            frmChk_UserRoles_RoleName: event.target.checked,
            frmRd_UserRoles_RoleName: ''
          });
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
        && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
          {
            frmRd_UserRoles_RoleName: ''
          });
      }

      if (event.target.checked) {
        //when checked =  true
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
      }
      else {
        //when checked =  false
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
          && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
        else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].disable();
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
    }
  }

  TogglePrimaryRole(event, index) {
    let k;
    for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
        event.target.checked = true;
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
      event.target.checked = true;
    }
    event.target.checked = true;
  }

  ActivateOrInactivate(obj, UserID_value, UserStatus_value) {
    // var userID = UserID_value;
    // var usrstatus = UserStatus_value;
    // var status;
    // var isUpdated;
    // var MedicalDirectorToUpdate = "";
    // if (usrstatus == "Active")
    //   status = "inactive";
    // else if (usrstatus == "InActive") {
    //   if (this.hidIsPathlogistSelected == true)
    //     MedicalDirectorToUpdate = "0";
    //   status = "active";
    // }
  }

  showAddUserDetailsFunc(OrgId) {
    if (this.fromComponent != 'SetupNewFacility') {
      OrgId = JSON.stringify(OrgId);
    }


    // this.getHoldReleaseDuration(OrgId);
  }

  getHoldReleaseDuration(OrgID) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserholdreleaseduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "filterparameter": "organizationid > \"1\" and organizationid = \"" + OrgID + "\"" }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.holdReleaseDuration = result.data.submenudata[0].HoldReleaseDuration;
            this.DiagICDcodeReminder = result.data.submenudata[0].DiagICDcodeReminder;
            if (this.holdReleaseDuration == null) {
              this.holdReleaseDuration = "0";
            }
            if (this.DiagICDcodeReminder == null) {
              this.DiagICDcodeReminder = false;
            }
            this.isICDdisplay = this.DiagICDcodeReminder;
          }
          else {
            this.holdReleaseDuration = "0";
            this.DiagICDcodeReminder = false;
          }
        }
        else {
          this.holdReleaseDuration = "0";
          this.DiagICDcodeReminder = false;
        }
      }
      this.getGetOrganizationType(OrgID);
    }, error => {
      console.info(error);
    });
  }

  getGetOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrOrgType = result.data.submenudata;
            this.UserType = this.ArrOrgType[0].associationtype;
            // this.IsOrderingFacility = this.ArrOrgType[0].IsListedAsOF as boolean;
            if (this.ArrOrgType[0].IsListedAsOF.toLowerCase == "true") {
              this.IsOrderingFacility = true;
            }
            else {
              this.IsOrderingFacility = false;
            }
          }
          else {
            this.UserType = "";
            this.IsOrderingFacility = false;
          }
        }
        else {
          this.UserType = "";
          this.IsOrderingFacility = false;
        }
      }
      if (this.UserType == "Laboratories" && this.IsOrderingFacility != true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      else {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: false,
          frmInp_EditSite: false,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      this.getGetEPGOrganizationType(OrgId);

    }, error => {
      console.info(error);
    });
  }

  getGetEPGOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetepgorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrEPGOrgType = result.data.submenudata[0].associationtype;
            if (this.ArrEPGOrgType != "") {
              this.EPGorgType = this.ArrEPGOrgType;
            }
            else {
              this.EPGorgType = "";
            }
          } else {
            this.EPGorgType = "";
          }
        } else {
          this.EPGorgType = "";
        }
      }
      this.getThresholdDuration(OrgId);
    }, error => {
      console.info(error);
    });
  }

  getThresholdDuration(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserthresholdduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != null && result.data.submenudata != undefined) {
          if (result.data.submenudata.length > 0) {
            this.ArrThresholdDuration = result.data.submenudata;
            this.frm_AddUsergroup.patchValue({
              frmDrp_ThresholdDuration: "Select"
            });
          }
          else {
            this.ArrThresholdDuration = [];
          }
        }
        else {
          this.ArrThresholdDuration = [];
        }
      }
      this.getSavedRoleList(0, OrgId); // userid for add user is 0 by default
    }, error => {
      console.info(error);
    });
  }

  getSavedRoleList(userid, OrgId) {
    // let commonkey = this.getCommonKey(userid);
    //get commonkey
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.CommonKey = result.data.submenudata[0].CommonKey;
            if (this.CommonKey == null) {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
        }
        else {
          this.CommonKey = "";
        }
        //get getSavedRoleList
        drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetsavedrole");
        drpqueryRequest = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "Commonkey": this.CommonKey }
        };
        this.vaservices.GetData(drpqueryRequest).subscribe(result => {
          if (!result.errors) {
            if (result.data.submenudata != undefined && result.data.submenudata != null) {
              if (result.data.submenudata.length > 0) {
                this.ArrSavedRoles = result.data.submenudata
                this.ArrSavedRoles.forEach(element => {
                  element.IsPrimary = element.IsPrimary == null ? false : element.IsPrimary;
                  element.status = element.status == 1 ? true : false;
                  element.ModifyTechnicalData = element.ModifyTechnicalData == null ? false : element.ModifyTechnicalData;
                  element.IsModifyTechnicalDataAccession = element.IsModifyTechnicalDataAccession == null ? false : element.IsModifyTechnicalDataAccession;
                  element.ReviewPathCanSignout = element.ReviewPathCanSignout == null ? false : element.ReviewPathCanSignout;
                  element.isMedicalDirector = element.isMedicalDirector == null ? false : element.isMedicalDirector;
                  element.IsUserLevelDiagnosisTemplateEnabled = element.IsUserLevelDiagnosisTemplateEnabled == null ? false : element.IsUserLevelDiagnosisTemplateEnabled;
                  element.ShowMyCaseOnly = element.ShowMyCaseOnly == null ? false : element.ShowMyCaseOnly;
                  element.DiagICDcodeReminder = element.DiagICDcodeReminder == null ? false : element.DiagICDcodeReminder;
                });
                let i;
                for (i = 0; i < this.ArrSavedRoles.length; i++) {
                  if (this.ArrSavedRoles[i].rolename === 'Pathologist') {
                    this.frm_AddUsergroup.patchValue(
                      {
                        frmInp_AllowPathologistDiagnosisTemplate: this.ArrSavedRoles[i].IsUserLevelDiagnosisTemplateEnabled,
                        frmInp_ShowMyCaseOnly: this.ArrSavedRoles[i].ShowMyCaseOnly
                      });
                    break;
                  }
                }
              }
            }
          }
          this.getLocationDetails(OrgId);
        }, error => {
          console.info(error);
        });
      }
    }, error => {
      console.info(error);
    });
  }

  getLocationDetails(OrgIDValue: string) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('adduserlocations');
    queryVariable = { "orgid": OrgIDValue };

    let accIds: any[];

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        this.templateData = res.data.vwGetAccountAssociationForUser_list;
        if (this.templateData.length > 0) {
          // this.templateData = this.templateData;
          this.showAccounts = true;
          (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).clear();

          let m;
          for (m = 0; m < this.templateData.length; m++) {
            let frmAccounts;
            frmAccounts as FormGroup;
            frmAccounts = "userAccounts_" + m;
            if (this.templateData.length === 1) {
              frmAccounts = this._fb.group({
                frmchk_Location: new FormControl(true),
                frmLblchk_Location: new FormControl(this.templateData[m].Account_ID),
                frmDrp_Works: new FormControl('true'),
                frmDrp_Manages: new FormControl('true'),
                frmDrp_SendforReview: new FormControl('true'),
                frmLbl_Location: new FormControl(this.templateData[m].Display_Name)
              });
            }
            else {
              frmAccounts = this._fb.group({
                frmchk_Location: new FormControl(false),
                frmLblchk_Location: new FormControl(this.templateData[m].Account_ID),
                frmDrp_Works: new FormControl('true'),
                frmDrp_Manages: new FormControl('true'),
                frmDrp_SendforReview: new FormControl('true'),
                frmLbl_Location: new FormControl(this.templateData[m].Display_Name)
              });
            }
            (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);
          }

          if (this.templateData.length === 1) {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: true
            });
          }
          else {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: false
            });
          }

          accIds = [];
          this.templateData.forEach(element => {
            accIds.push(element.Account_ID);
          });
          this.getAssociationTypes(accIds, OrgIDValue);
        }
        else {
          this.templateData = "";
          this.showAccounts = false;
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }


  getLicensureDetails() {
    // if (this.templateData.data.submenuData.length > 0) {
    // call set change status dropdown
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "licensurestates");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        this.frm_AddUsergroup.patchValue(
          {
            frmInp_State: "Select"
          });

        this.ShowLicensureDetails = true;

        this.StateList = [];
        this.StateList = result.data.submenudata;
        this.StateDiscription = this.StateList[0].LookUpValue + " (" + this.StateList[0].Description + ")";

        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).clear();

        let frm;
        frm as FormGroup;
        frm = this._fb.group({
          frmDrp_LicsenceStates: new FormControl("Select"),
          frmDrp_LicsenceDate: ""
        });

        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
        this.ShowLicensureDetails = false;
      }
    }, error => {
      console.info(error);
    });
  }

  Licensure_array_Func() {
    let flag = false;
    if (this.ShowLicensureDetails == false) {
      flag = true;
      this.ShowLicensureDetails = true;
    }
    if ((<FormArray>this.frm_AddUsergroup.get('formLicensure')) == undefined || (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length <= 0) {
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frmDrp_LicsenceStates: new FormControl(this.StateDiscription),
        frmDrp_LicsenceDate: new Date()
      });

      (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
      if (flag == true) {
        this.ShowLicensureDetails = false;
        flag = false;
      }
      return this.frm_AddUsergroup.controls.formLicensure.value;
    }
    if (flag == true) {
      this.ShowLicensureDetails = false;
      flag = false;
    }
    return this.frm_AddUsergroup.controls.formLicensure.value;

    // *ngFor="let userLicensure of frm_AddUsergroup.controls.formLicensure.controls; let userLicensure_i = index"
    // return this.Licensure_array;
  }

  licensure(value: string, index: number) {
    if (this.ShowLicensureDetails == true) {
      if (value == "add") {
        const frm = this._fb.group({
          frmDrp_LicsenceStates: new FormControl(this.StateDiscription),
          frmDrp_LicsenceDate: new Date()
        });

        (<FormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
      }
      else if (value == "delete") {
        if ((<FormArray>this.frm_AddUsergroup.get('formLicensure')).length > 1) {
          (<FormArray>this.frm_AddUsergroup.get('formLicensure')).removeAt(index);
        }
      }
    }
  }

  generateNewGuid() {
    var d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  onChangefrmDrp_OrgWorks(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_Works: eventValue
        });
    }
  }

  onChangefrmDrp_OrgManages(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_Manages: eventValue
        });
    }
  }

  onChangefrmDrp_OrgSendforReview(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = 'true';
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_SendforReview: eventValue
        });
    }
  }

  onChangefrmchk_OrgLocation(eventValue) {
    this.ngxService.start("AddUserToOrg1")
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    if (this.fromComponent == "SetupNewFacility") {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmchk_Location: eventValue
        });
    }
    this.ngxService.stop("AddUserToOrg1")
  }


  getCommonKey(userid) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            return result.data.submenudata[0].CommonKey
          }
          return "";
        }
        return "";
      }
    }, error => {
      console.info(error);
    });
  }

  MedicalDirectorCheck(event, index) {
    if (event.target.checked == true)
      this.hidIsChecked = true;
    else
      this.hidIsChecked = false;
  }

  getIsMedicalRolePresent(OrgId, UserID) {
    // List < MedicalDirectorAttributes > IsMedicalRolePresent = oUser.CheckIsMedicalDirectorRolePresent(_OrganizationID, _UserID);
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetismedicaldirector");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.IsMedicalDirector = true;
          }
          else {
            this.IsMedicalDirector = false;
          }
          // IsMedDirectorExistsForCommon
        }
        else {
          this.IsMedicalDirector = false;
        }
      }
      // let commonkey = this.getCommonKey(0);
      var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
      let drpqueryRequest: any = {
        "OperationName": null,
        "Query": drpquery[0].Query,
        "Variables": { "userid": "0" }
      };
      this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
        if (!result.errors) {

          if (result.data.submenudata != undefined && result.data.submenudata != null) {
            if (result.data.submenudata.length > 0) {
              this.CommonKey = result.data.submenudata[0].CommonKey
            }
            else {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
          let commonkey = this.CommonKey;
          if (commonkey != undefined && commonkey != null) {
            this.getIsMedDirectorForUserID(0);
          }
          else {
            this.getIsMedDirectorForCommonKey(commonkey);
          }
          this.getLicensureDetails();
        }
      }, error => {
        console.info(error);
      });
    }, error => {
      console.info(error);
    });

  }

  doEnableRadioBtnForRoleNameChk(userRl, i, event) {
    if (this.count == -1) {
      if (event.target.checked == true) {
        this.count = i;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value,
            frmChk_UserRoles_RoleName: event.target.checked
          });
      }
    }
    else if (event.target.checked == true) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
    if (this.count == i) {
      if (event.target.checked == false) {
        this.count = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: event.target.checked
          });

      }
      else if (event.target.checked == false) {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: event.target.checked
          });
      }
    }
    else if (event.target.checked == false) {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
  }

  enableRadioBtnForRoleNameChk(userRl, i, event) {
    this.doEnableRadioBtnForRoleNameChk(userRl, i, event);
    this.RoleCheckboxValidation();
    if (this.ClickFrom === 'UI') {
      this.funcToggle(i);
    }
  }

  funcToggle(index) {
    let m;
    for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; m++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Pathologist') {
          this.hidIsPathlogistSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist') {
          this.hidIsCytoSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist Supervisor') {
          this.hidIsCytoSupervisiorSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Physician') {
          this.hidIsPhysicianSelected = true;
        }
        else {
          this.hidIsPhysicianSelected = false;
        }
      }
    }
    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'lab manager' &&
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
      this.HRD_KValue = this.rolelablistcount;
      this.HRD_value = -1;
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
        {
          frmInp_RoleHoldreleaseDuration: '0'
        });
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
    }
    else {
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
    }

    if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if (this.EPGorgType == 'Pathologists') {
          this.ShowLicensureDetails = true;
        }
        else {
          this.ShowLicensureDetails = false;
        }
        this.hidIsPathlogistSelected = true;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
        if (this.hidIsMedicalDirector == true) {
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        }
        else {
          this.MD_KValue = this.rolelistcount;
          this.HRD_KValue = this.rolelistcount;
          this.HRD_value = -1;
          if (this.hidIsChecked == true) {
            this.hidIsChecked = false;
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
          }
          else {
            (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
          }
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
          //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
          // //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          // // this.ClickFrom = "TS";
          // // let el: HTMLElement = this.role_Chk_click.nativeElement;
          // // el.click();
          // // this.ClickFrom = "UI";

          // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].enable();
          (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
            {
              //frmRd_UserRoles_RoleName: 'Pathologists',
              // frmChk_UserRoles_RoleName: true,
              frmChk_IsMedicalDirector: this.hidIsChecked,
              frmInp_RoleHoldreleaseDuration: '0'
            });

          this.RoleCheckboxValidation();
        }
      }
      else {
        this.ShowLicensureDetails = false;
        this.hidIsPathlogistSelected = false;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();

        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
        this.ShowLicensureDetails = false;
        this.showfrmInp_AllowReviewPathologist = false;

        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
        this.showfrmInp_AllowtoeditAccession = false;
        this.showfrmInp_Threshold = false;

        this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
        this.showfrmInp_EditSite = false;
        this.showfrmDrp_ThresholdDuration = false;

        this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
        this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
        this.showfrmInp_PopulateDiag = false;

        if (this.hidIsCytoSelected == false || this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }

        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].disable();
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: false
          });

        return;
      }
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
      this.showfrmInp_AllowReviewPathologist = true;
      this.ShowLicensureDetails = true;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
      this.showfrmInp_AllowtoeditAccession = true;
      this.showfrmInp_Threshold = true;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
      this.showfrmInp_EditSite = true;
      this.showfrmDrp_ThresholdDuration = true;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
      this.showfrmInp_Notifyusers = true;
      if (this.UserType == "Laboratories" && this.IsOrderingFacility == true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true
        });
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcytcount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist supervisor') {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcyscount;
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSupervisiorSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSupervisiorSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else {
      if (((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'physician'
        || ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
        this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
      }
      // this.HRD_value=0;
      // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
      this.showfrmInp_AllowReviewPathologist = false;
      this.ShowLicensureDetails = false;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
      this.showfrmInp_AllowtoeditAccession = false;
      this.showfrmInp_Threshold = false;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
      this.showfrmInp_EditSite = false;
      this.showfrmDrp_ThresholdDuration = false;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
      this.showfrmInp_Notifyusers = false;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
      this.showfrmInp_PopulateDiag = false;
      if (this.hidIsPathlogistSelected == true) {
        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
        this.showfrmInp_AllowReviewPathologist = true;
        this.ShowLicensureDetails = true;
        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
        this.showfrmInp_AllowtoeditAccession = true;
        this.showfrmInp_Threshold = true;
        this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
        this.showfrmInp_EditSite = true;
        this.showfrmDrp_ThresholdDuration = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      this.showfrmInp_Notifyusers = true;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
      this.showfrmInp_PopulateDiag = true;
    }
    if (this.hidIsPathlogistSelected == true) {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].enable();
    }
    else {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].disable();
    }
  }

  RoleCheckboxValidation() {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length;

    for (m = 0; m < count; m++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == false) {
        count_false++;
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        count_true++;
      }
    }
    if (count_true === count) {
      // this.RoleCheckboxValidator = true;
      this.frm_AddUsergroup.patchValue({

        frmChk_RoleCheckboxValidator: true
      });
    }
    else if (count_false === count) {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
    else {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
  }

  getIsMedDirectorForUserID(userid) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserismeddirectorexistsforuserid");
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.IsMedDirectorExistsForCommon = true;
          }
          else {
            this.IsMedDirectorExistsForCommon = false;
          }
        }
        else {
          this.IsMedDirectorExistsForCommon = false;
        }
      }
    }, error => {
      console.info(error);
    });
  }

  getIsMedDirectorForCommonKey(CommonKey) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserismeddirectorexistsforcommonkey");
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "CommonKey": CommonKey }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.IsMedDirectorExistsForCommon = true;
          }
          else {
            this.IsMedDirectorExistsForCommon = false;
          }
        }
        else {
          this.IsMedDirectorExistsForCommon = false;
        }
      }
    }, error => {
      console.info(error);
    });
  }

  isValidDate(dtvalue) {
    dtvalue = this.datepipe.transform(dtvalue, 'MM/dd/yyyy');
    var RegExPattern = /^(?=\d)(?:(?:(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2}))($|\ (?=\d)))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\ [AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    var validDate = false;
    if ((RegExPattern.test(dtvalue)) && (dtvalue.value != '')) {
      var index = dtvalue.lastIndexOf("/");
      var temp = dtvalue.substring(index + 1);
      if (temp.length == 4)
        validDate = true;
      else
        validDate = false;
    }
    else if (dtvalue == '') {
      validDate = true;
    }
    else
      validDate = false;
    return validDate;
  }

  fnValidate() {
    this.clearAlertsOnSave();
    let form = this.frm_AddUsergroup.value;

    //*Mandatory fields: First Name, Last Name, Login Name, Region, Email !!
    if (this.organizationLoginType === 'LoginName') {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || form.frmInp_LoginName == "" || (form.frmInp_ST == false && form.frmInp_US == false) || form.frmInp_NPI == "") {
        this.alertOnSave1 = true;
        return false;
      }
      else {
        this.alertOnSave1 = false;
      }


      if (form.frmInp_Email == "") {
        this.alertOnSave1 = true;
        return;
      }
    }
    //*Please Enter valid Region!!



    //LoginName Availability check
    // this.CheckLoginNameAvailability(this.frm_AddUsergroup.value.frmInp_LoginName);

    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusercheckloginname");
    this.addloginname = form.frmInp_LoginName;
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "LoginName": form.frmInp_LoginName }
    };
    this.vaservices.GetData(drpqueryRequest, this.deploymentvalue.toLowerCase()).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenuData != undefined && result.data.submenuData != null) {
          if (result.data.submenuData.length > 0) {
            this.loginNamesList = result.data.submenuData;
            this.loginNameExists = false;
            this.alertOnSave7 = false;

            let num: number;
            for (num = 0; num < this.loginNamesList.length; num++) {
              if (this.loginNamesList[num].userid != null && this.loginNamesList[num].userid != "" && this.loginNamesList[num].userid != 0) {
                this.loginNameExists = true;
                this.alertOnSave7 = true;
                // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Login Name Exists! Enter new Login Name!!";
                break;
              }
            }
          }
          else {
            this.loginNameExists = false;

            this.alertOnSave7 = false;
            // return false;
          }
        }
        else {
          this.loginNameExists = false;
          this.alertOnSave7 = false;
          // return false;
        }
        if (!this.alertOnSave7) {
          //further validations

          //*Please select at least one Location!!
          let m;
          let count: number = 0;
          for (m = 0; m < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
            if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value) {
              count++;
            }
          }

          if (count == 0) {
            this.alertOnSave2 = true;
            return false;
          }
          else if (count > 0) {
            this.alertOnSave2 = false;
          }

          //*Please Enter valid Zip!!
          // this.alertOnSave3 = false;
          // if (form.frmInp_Zip != "") {
          //   var zip = /^\d{5}([\-]\d{4})?$/;
          //   if (zip.test(form.frmInp_Zip) == false) {
          //     this.alertOnSave3 = true;
          //     this.frm_AddUsergroup.patchValue(
          //       {
          //         frmInp_Zip: ""
          //       });
          //     return false;
          //   }
          //   else {
          //     this.alertOnSave3 = false;
          //   }
          // }

          //*Please Enter valid Email!!
          this.alertOnSave4 = false;
          //this.emailcheck(form.frmInp_Email);
          if (form.frmInp_Email != "") {
            var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (re.test(form.frmInp_Email) == false) {
              this.alertOnSave4 = true;
              this.frm_AddUsergroup.patchValue(
                {
                  frmInp_Email: ""
                });
              return false;
            }
            else {
              this.alertOnSave4 = false;
            }
          }
          // this.emailcheck(form.frmInp_Email);

          //validate fax number
          // this.alertOnSave5 = true;
          // this.alertOnSave5 = false;
          // if (form.frmInp_Fax != "") {
          //   var faxvalue = form.frmInp_Fax.split(',');
          //   for (let i = 0; i < faxvalue.length; i++) {
          //     if (!this.validateFax(faxvalue[i])) {
          //       this.alertOnSave5 = true;
          //       return false;
          //     }
          //   }
          // }
          //validate npi number
          this.alertOnSave6 = false;
          if (form.frmInp_NPI != "" && form.frmInp_NPI.toLowerCase() != "unknown") {
            // var value = {
            //   "npi": form.frmInp_NPI,
            // }
           // this.vaservices.NpiValidation(value).subscribe((Info) => {
              if (form.frmInp_NPI.length != 10) {
                this.alertOnSave6 = true;
                return false;
              }
              else {
                this.alertOnSave6 = false;
                var fnValidate_RoleSelected = false;
                var fnValidate_primarySelected = false;
                var fnValidate_HoldDurationTime = "0";
                var fnValidate_isPrimarybelongsToSelectedRole = false;
                var fnValidate_isLabManager = false;
                var fnValidate_iscytotechnologist = false;
                var fnValidate_iscyt = false;

                var fnValidate_isPathologist = false;

                let mn;
                for (mn = 0; mn < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; mn++) {
                  var fnValidate_chkrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
                  var fnValidate_rdnPrimaryRole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
                  var fnValidate_txtHoldduration = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

                  if (fnValidate_chkrole == true) {
                    fnValidate_RoleSelected = true;
                  }
                  if (fnValidate_rdnPrimaryRole != "") {
                    fnValidate_primarySelected = true;
                  }
                  if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                    fnValidate_isPrimarybelongsToSelectedRole = true;
                  }
                  if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                    && fnValidate_chkrole == true) {
                    fnValidate_isLabManager = true;
                    fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  }
                  if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                    && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                    fnValidate_isPathologist = true;
                    fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  }
                  if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                    && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                    fnValidate_iscytotechnologist = true;
                    fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  }
                  if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                    && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                    fnValidate_iscyt = true;
                    fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  }
                }

                if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
                  || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
                  this.alertOnSaveCommon = true;
                  // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Please enter Lab Manager Hold duration time in minutes (0-99)!!";
                  this.CommonAlertOnSave = "*Please enter hold duration time in minutes (0-60)";
                  return false;
                }

                if (fnValidate_RoleSelected == false) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = '*Please select at least one Role!!';
                  return false;
                }

                if (fnValidate_primarySelected == false) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = '*Please select primary role!!';
                  return false;
                }

                if (fnValidate_isPrimarybelongsToSelectedRole == false) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Primary role selected is invalid!!";
                  return false;
                }

                var fnValidate_chkAccountcount: number = 0;
                var fnValidate_cnt: number = 0;
                let k;
                for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                  if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                    fnValidate_chkAccountcount++;
                    var fnValidate_drpwrktext = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                    if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                      fnValidate_cnt++;
                    }
                  }
                }
                if (this.hidIsPathlogistSelected == true) {


                  var fnValidate_drpWorksSelected = 'false';
                  for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                    if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                      fnValidate_drpWorksSelected = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                    }
                    if (fnValidate_drpWorksSelected == 'true') {
                      break;
                    }
                  }

                  if (fnValidate_drpWorksSelected == 'false') {
                    // && fnValidate_drpOrgWorksSelected == 'false'
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                    return false;
                  }
                }
                if (fnValidate_chkAccountcount == 0) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Please select at least one Location!!";
                  return false;
                }
                if (fnValidate_cnt > 1) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Only one location can be marked as true!!";
                  return false;
                }
                if (this.hidIsPathlogistSelected == true) {
                  var IsMedicalDirectorExists_isMedicalDirector = false;
                  var IsMedicalDirectorExists_status;
                  var IsMedicalDirectorExists_userName;
                  var IsMedicalDirectorExists_commonKey;
                  var IsMedicalDirectorExists_userID;

                  //IsMedicalDirectorExists
                  this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
                    // var jsonResult: any = JSON.stringify(data);
                    // jsonResult = JSON.parse(jsonResult);
                    var jsonResult: any = data;
                    if (jsonResult != undefined && jsonResult != null) {
                      if (jsonResult.length > 0) {
                        IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                        IsMedicalDirectorExists_status = jsonResult[0].Status;
                        IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                        IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                        IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                      }
                      else {
                        IsMedicalDirectorExists_isMedicalDirector = false;
                        IsMedicalDirectorExists_status = "";
                        IsMedicalDirectorExists_userID = "";
                        IsMedicalDirectorExists_userName = "";
                        IsMedicalDirectorExists_commonKey = "";
                      }
                    }
                    else {
                      IsMedicalDirectorExists_isMedicalDirector = false;
                      IsMedicalDirectorExists_status = "";
                      IsMedicalDirectorExists_userID = "";
                      IsMedicalDirectorExists_userName = "";
                      IsMedicalDirectorExists_commonKey = "";
                    }

                    if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*Medical Director already exist!!";
                      return false;
                    }
                    if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                      && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                      if (IsMedicalDirectorExists_status == "active") {
                        this.alertOnSaveCommon = true;
                        this.CommonAlertOnSave = "*Medical Director already exist!!";
                        return false;
                      }
                      else if (IsMedicalDirectorExists_status == "inactive") {
                        this.alertOnSaveCommon = true;
                        this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                        return false;
                      }
                    }

                    var stateID;
                    // var licensure1;
                    var confirmexpdate;
                    var alldata = [];
                    var StateLists = [];
                    var DuplicateStateCount = 0;
                    var i = 0;
                    // var stateval = "";
                    var DateValueCount = 0;
                    // var Datetextbox;

                    if (this.hidIsPathlogistSelected == true) {
                      for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; k++) {
                        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                        if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                          return true;
                        }
                        if (alldata.length == i) {
                          // alldata[i] = this.pathLicesData();
                          alldata[i] = {
                            id: "",
                            State: "",
                            Expirydate: "",
                            Statefullname: "",
                          };
                          alldata[i].id = JSON.stringify(i + 1);
                          alldata[i].State = PathLic_State;
                          alldata[i].Expirydate = PathLic_Expirydate;
                          alldata[i].Statefullname = PathLic_Statefullname;

                          if (alldata[i].Expirydate == "") {
                            confirmexpdate = true;
                          }
                          if (alldata[i].State != "") {
                            if (StateLists.indexOf(alldata[i].State) === -1) {
                              StateLists.push(alldata[i].State);
                            }
                            else {
                              DuplicateStateCount++;
                              return false;
                            }
                          }
                        }
                        i++;
                      }
                      this.hidlicensure = JSON.stringify(alldata);
                      if (DuplicateStateCount > 0) {
                        alert("The state has been selected multiple times. Please select some other state or delete the state.");
                        document.getElementById(stateID).focus();
                        return false;
                      }
                      if (DateValueCount > 0) {
                        this.alertOnSaveCommon = true;
                        this.CommonAlertOnSave = "Please enter a valid Expirydate!!";
                        return false;
                      }
                      //licensure details in json object format
                      this.hidlicensure = JSON.stringify(alldata);

                      //begin save by forming save json to send to API
                      let queryVariable = { "commonkey": null, "Orgid": this.SelectedOrgID, "Email": form.frmInp_Email };
                      let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                      this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
                        if (!res.errors) {
                          if (form.frmInp_Email && res.data.submenuData.length > 0) {
                            let dialogRef = this.dialog.open(ConfirmComponent, {
                              disableClose: true,
                              width: '500px',
                              data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                            });
                          }
                          else {
                            var usersData_Jsonformat = this.generateSaveJson();
                            this.alertOnSave10 = false;
                            this.SaveUserData(usersData_Jsonformat);
                          }
                        }
                      }, error => {
                        console.error(error);
                      })
                    }
                  }, error => {
                    console.error(error)
                  });
                }
                else {
                  //begin save by forming save json to send to API
                  let queryVariable = { "commonkey": null, "Orgid": this.SelectedOrgID, "Email": form.frmInp_Email };
                  let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                  let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                  this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
                    if (!res.errors) {
                      if (form.frmInp_Email && res.data.submenuData.length > 0) {
                        let dialogRef = this.dialog.open(ConfirmComponent, {
                          disableClose: true,
                          width: '500px',
                          data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                        });
                      }
                      else {
                        this.alertOnSave10 = false;
                        var usersData_Jsonformat = this.generateSaveJson();
                        this.SaveUserData(usersData_Jsonformat);
                      }
                    }
                  }, error => {
                    console.error(error);
                  })
                }
              }
            // }, error => {
            //   console.info(error);
            //   this.alertOnSave6 = true;
            // });
          }
          else {
            this.alertOnSave6 = false;
            var fnValidate_RoleSelected = false;
            var fnValidate_primarySelected = false;
            var fnValidate_HoldDurationTime = "0";
            var fnValidate_isPrimarybelongsToSelectedRole = false;
            var fnValidate_isLabManager = false;
            var fnValidate_iscytotechnologist = false;
            var fnValidate_iscyt = false;
            var fnValidate_isPathologist = false;

            let mn;
            for (mn = 0; mn < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; mn++) {
              var fnValidate_chkrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
              var fnValidate_rdnPrimaryRole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
              var fnValidate_txtHoldduration = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

              if (fnValidate_chkrole == true) {
                fnValidate_RoleSelected = true;
              }
              if (fnValidate_rdnPrimaryRole != "") {
                fnValidate_primarySelected = true;
              }
              if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                fnValidate_isPrimarybelongsToSelectedRole = true;
              }
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                && fnValidate_chkrole == true) {
                fnValidate_isLabManager = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_isPathologist = true;
              }
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscytotechnologist = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                && (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscyt = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
            }

            if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
              || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
              this.alertOnSaveCommon = true;
              // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Please enter Lab Manager Hold duration time in minutes (0-99)!!";
              this.CommonAlertOnSave = "*Please enter hold duration time in minutes (0-60)";
              return false;
            }

            if (fnValidate_RoleSelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = '*Please select at least one Role!!';
              return false;
            }

            if (fnValidate_primarySelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = '*Please select primary role!!';
              return false;
            }

            if (fnValidate_isPrimarybelongsToSelectedRole == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Primary role selected is invalid!!";
              return false;
            }

            var fnValidate_chkAccountcount: number = 0;
            var fnValidate_cnt: number = 0;
            let k;
            for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
              if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                fnValidate_chkAccountcount++;
                var fnValidate_drpwrktext = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                  fnValidate_cnt++;
                }
              }
            }
            if (this.hidIsPathlogistSelected == true) {


              var fnValidate_drpWorksSelected = 'false';
              for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                  fnValidate_drpWorksSelected = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                }
                if (fnValidate_drpWorksSelected == 'true') {
                  break;
                }
              }

              if (fnValidate_drpWorksSelected == 'false') {
                // && fnValidate_drpOrgWorksSelected == 'false'
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                return false;
              }
            }

            if (fnValidate_chkAccountcount == 0) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Please select at least one Location!!";
              return false;
            }
            if (fnValidate_cnt > 1) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Only one location can be marked as true!!";
              return false;
            }
            if (this.hidIsPathlogistSelected == true) {
              var IsMedicalDirectorExists_isMedicalDirector = false;
              var IsMedicalDirectorExists_status;
              var IsMedicalDirectorExists_userName;
              var IsMedicalDirectorExists_commonKey;
              var IsMedicalDirectorExists_userID;

              //IsMedicalDirectorExists
              this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
                // var jsonResult: any = JSON.stringify(data);
                // jsonResult = JSON.parse(jsonResult);
                var jsonResult: any = data;
                if (jsonResult != undefined && jsonResult != null) {
                  if (jsonResult.length > 0) {
                    IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                    IsMedicalDirectorExists_status = jsonResult[0].Status;
                    IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                    IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                    IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                  }
                  else {
                    IsMedicalDirectorExists_isMedicalDirector = false;
                    IsMedicalDirectorExists_status = "";
                    IsMedicalDirectorExists_userID = "";
                    IsMedicalDirectorExists_userName = "";
                    IsMedicalDirectorExists_commonKey = "";
                  }
                }
                else {
                  IsMedicalDirectorExists_isMedicalDirector = false;
                  IsMedicalDirectorExists_status = "";
                  IsMedicalDirectorExists_userID = "";
                  IsMedicalDirectorExists_userName = "";
                  IsMedicalDirectorExists_commonKey = "";
                }

                if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Medical Director already exist!!";
                  return false;
                }
                if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                  && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                  if (IsMedicalDirectorExists_status == "active") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Medical Director already exist!!";
                    return false;
                  }
                  else if (IsMedicalDirectorExists_status == "inactive") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                    return false;
                  }
                }

                var stateID;
                // var licensure1;
                var confirmexpdate;
                var alldata = [];
                var StateLists = [];
                var DuplicateStateCount = 0;
                var i = 0;
                // var stateval = "";
                var DateValueCount = 0;
                // var Datetextbox;

                if (this.hidIsPathlogistSelected == true) {
                  for (k = 0; k < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; k++) {
                    var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                    var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                    var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                    if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                      return true;
                    }
                    if (alldata.length == i) {
                      // alldata[i] = this.pathLicesData();
                      alldata[i] = {
                        id: "",
                        State: "",
                        Expirydate: "",
                        Statefullname: "",
                      };
                      alldata[i].id = JSON.stringify(i + 1);
                      alldata[i].State = PathLic_State;
                      alldata[i].Expirydate = PathLic_Expirydate;
                      alldata[i].Statefullname = PathLic_Statefullname;

                      if (alldata[i].Expirydate == "") {
                        confirmexpdate = true;
                      }
                      if (alldata[i].State != "") {
                        if (StateLists.indexOf(alldata[i].State) === -1) {
                          StateLists.push(alldata[i].State);
                        }
                        else {
                          DuplicateStateCount++;
                          return false;
                        }
                      }
                    }
                    i++;
                  }
                  this.hidlicensure = JSON.stringify(alldata);
                  if (DuplicateStateCount > 0) {
                    alert("The state has been selected multiple times. Please select some other state or delete the state.");
                    document.getElementById(stateID).focus();
                    return false;
                  }
                  if (DateValueCount > 0) {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "Please enter a valid Expirydate!!";
                    return false;
                  }
                  //licensure details in json object format
                  this.hidlicensure = JSON.stringify(alldata);

                  //begin save by forming save json to send to API
                  let queryVariable = { "commonkey": null, "Orgid": this.SelectedOrgID, "Email": form.frmInp_Email };
                  let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                  let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                  this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
                    if (!res.errors) {
                      if (form.frmInp_Email && res.data.submenuData.length > 0) {
                        let dialogRef = this.dialog.open(ConfirmComponent, {
                          disableClose: true,
                          width: '500px',
                          data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                        });
                      }
                      else {
                        var usersData_Jsonformat = this.generateSaveJson();
                        this.alertOnSave10 = false;
                        this.SaveUserData(usersData_Jsonformat);
                      }
                    }
                  }, error => {
                    console.error(error);
                  })
                }
              }, error => {
                console.error(error)
              });
            }
            else {
              //begin save by forming save json to send to API
              let queryVariable = { "commonkey": null, "Orgid": this.SelectedOrgID, "Email": form.frmInp_Email };
              let query = this.vaservices.GetQuery("checkUserDuplicateValues");
              let queryResult = this.commonService.GetCardRequest(queryVariable, query);
              this.vaservices.GetData(queryResult, this.deploymentvalue.toLowerCase()).subscribe(res => {
                if (!res.errors) {
                  if (form.frmInp_Email && res.data.submenuData.length > 0) {
                    let dialogRef = this.dialog.open(ConfirmComponent, {
                      disableClose: true,
                      width: '500px',
                      data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                    });
                  }
                  else {
                    this.alertOnSave10 = false;
                    var usersData_Jsonformat = this.generateSaveJson();
                    this.SaveUserData(usersData_Jsonformat);
                  }
                }
              }, error => {
                console.error(error);
              })
            }
          }
        }
      }
    }, error => {
      console.info(error);
      this.loginNameExists = false;
      this.alertOnSave7 = false;
    });
  }

  clearAlertsOnSave() {
    this.alertOnSave1 = false;
    this.alertOnSave2 = false;
    //this.alertOnSave3 = false;
    this.alertOnSave4 = false;
    //this.alertOnSave5 = false;
    this.alertOnSave7 = false;
    this.alertOnSave10 = false;
    this.alertOnSaveCommon = false;
  }

  validateFax(checkField) {
    if (checkField.length > 0) {
      var faxRegEx = /\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$/;
      if (!faxRegEx.test(checkField)) {
        return false;
      }
    }
    return true;
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  // CheckLoginNameAvailability(loginName) {
  // }

  GenerateLoginID(event) {
    var loginID = "";
    var firstname: string = this.frm_AddUsergroup.value.frmInp_FirstName;
    var lastname: string = this.frm_AddUsergroup.value.frmInp_LastName;

    if (firstname.trim() != "")
      loginID = firstname.substring(0, 1);

    if (lastname.trim() != "")
      loginID += lastname;
    //to make sure loginID length should not exceed 25
    if (loginID.length > 20)
      loginID = loginID.substring(0, 20);


    if (loginID != '')
      loginID += Math.floor((Math.random() * 1000) + 1)

    if (this.frm_AddUsergroup.value.frmInp_LoginName == '') {
      this.frm_AddUsergroup.patchValue({
        frmInp_LoginName: loginID
      });
    }
  }

  emailcheck(str) {
    var at = "@";
    var dot = ".";
    var lat = str.indexOf(at);
    var lstr = str.length;
    var ldot = str.indexOf(dot);

    if (str.indexOf(at) == -1 || str.indexOf(at) == 0 || str.indexOf(at) == lstr) {
      this.alertOnSave4 = true;
    }
    if (str.indexOf(dot) == -1 || str.indexOf(dot) == 0 || str.indexOf(dot) == lstr) {
      this.alertOnSave4 = true;
    }
    if (str.indexOf(at, (lat + 1)) != -1) {
      this.alertOnSave4 = true;
    }
    if (str.substring(lat - 1, lat) == dot || str.substring(lat + 1, lat + 2) == dot) {
      this.alertOnSave4 = true;
    }
    if (str.indexOf(dot, (lat + 2)) == -1) {
      this.alertOnSave4 = true;
    }
    if (str.indexOf(" ") != -1) {
      this.alertOnSave4 = true;
    }
  }

  onChangefrmchk_Location(event_checked) {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length;

    for (m = 0; m < count; m++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value == false) {
        count_false++;
      }
      else if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value == true) {
        count_true++;
      }
    }

    if (count_true === count) {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: true
      });
    }
    else if (count_false === count) {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: false
      });
    }
    else {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: false
      });
    }
  }

  SaveAndAddUsers(adduserData) {
    this.saveandcreateflag=true;
    this.saveandcloseflag=false;
    this.fnValidate();
  }

  SaveAndClose(adduserData) {
    this.saveandcreateflag=false;
    this.saveandcloseflag=true;
    this.fnValidate();
  }

  Save(adduserData) {
    this.fnValidate();
  }

  generateSaveJson() {
    let form = this.frm_AddUsergroup.value;
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    // for (let m = 0; m < environment.Vitaldxuserid.length; m++) {
    //   let env_depKey = environment.Vitaldxuserid[m];
    //   let env_depKeyName = env_depKey.substring(0, env_depKey.indexOf('-'));
    //   if (form.frmDeploymentKey.toLowerCase() == env_depKeyName.toLowerCase()) {
    //     loggedIn_userID = env_depKey.substring(env_depKey.indexOf('-') + 1);
    //     break;
    //   }
    // }


    let roleList = "";
    let selected_primaryrole = "";
    let holdReleaseDurationvalue = "";
    let holdselected_primaryrole = "";
    //"Lab Manager,Claim tracker";
    for (let i = 0; i < (<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; i++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        roleList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
        holdReleaseDurationvalue += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
      }
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].value != "") {
        selected_primaryrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
        holdselected_primaryrole = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
      }
    }
    //holdduration list
    let arraylist1: string[];
    arraylist1 = holdReleaseDurationvalue.split(',');
    let listrole1: string[];
    listrole1 = arraylist1.filter(item => item !== holdselected_primaryrole)
    let role1 = String(listrole1);
    holdReleaseDurationvalue = holdselected_primaryrole + ',' + role1;
    holdReleaseDurationvalue = holdReleaseDurationvalue.substring(0, holdReleaseDurationvalue.length - 1);
    //role list
    let arrayrole: string[];
    arrayrole = roleList.split(',');
    let listrole: string[];
    listrole = arrayrole.filter(item => item !== selected_primaryrole)
    let role = String(listrole);
    roleList = selected_primaryrole + ',' + role;
    roleList = roleList.substring(0, roleList.length - 1);
    let review_data = [];
    let accountList = "";
    // "5:1,6:1,20029:0,26112:0,26275:0";
    for (let j = 0; j < (<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; j++) {
      if ((<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value as boolean == true) {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":1,";
      }
      else {
        accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":0,";
      }
      // accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":" +
      //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value + ",";

      //review data
      let obj = {
        "userID": loggedIn_userID != "" ? loggedIn_userID : "-100",
        "AccountID": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value,
        "sendforreview": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_SendforReview'].value == "true" ? true : false,
        "works": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Works'].value == "true" ? true : false,
        "manages": (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Manages'].value == "true" ? true : false,
        "RoleName": selected_primaryrole,
        "primaryrole": selected_primaryrole != "" ? true : false,
        "CanPathologistModifyAccession": form.frmInp_AllowtoeditAccession ? true : false,
        "CanPathologistModify": form.frmInp_EditSite ? true : false,
        "ModifiedBy": loggedIn_userID != "" ? loggedIn_userID : "-100"
      };
      review_data.push(obj);
    }
    accountList = accountList.substring(0, accountList.length - 1);

    //check if user is medical director
    let MD_val = false;
    if (this.MD_KValue != -1 &&
      (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.MD_KValue)).controls['frmChk_IsMedicalDirector'].value == true) {
      MD_val = true;
    }

    //check for hold release duration value
    let HD_val: number = 0;
    if (this.HRD_KValue != -1) {
      let user_hd_val = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.HRD_KValue)).controls['frmInp_RoleHoldreleaseDuration'].value;
      if (user_hd_val != null && user_hd_val != "") {
        HD_val = parseInt(user_hd_val);
      }
    }

    //convert threshold value to number
    let threshold_value: number = 0;
    if (form.frmInp_Threshold != null && form.frmInp_Threshold != "") {
      threshold_value = parseInt(form.frmInp_Threshold);
    }

    //check if primary role has changed
    let isPrimaryRoleChanged = false;
    if (this.hidPrimaryRole != "" && selected_primaryrole != "" && selected_primaryrole != this.hidPrimaryRole) {
      isPrimaryRoleChanged = true;
    }

    //get licensure details
    let lic_details: string = "";
    if (this.hidIsPathlogistSelected) {
      lic_details = "<ArrayOfPathologistLicensure>";
      for (let a = 0; a < (<FormArray>this.frm_AddUsergroup.get('formLicensure')).length; a++) {
        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value;
        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
        PathLic_State = PathLic_State === 'Select' ? 'null' : PathLic_State;
        PathLic_Expirydate = this.datepipe.transform(PathLic_Expirydate, 'MM/dd/yyyy');

        //build xml string
        lic_details += "  <PathologistLicensure>";
        lic_details += "  <id>" + (a + 1) + "</id>";
        lic_details += "  <state>" + PathLic_State + "</state>";
        lic_details += "  <Expirydate>" + PathLic_Expirydate + "</Expirydate>";
        lic_details += "  <Statefullname>" + PathLic_Statefullname + "</Statefullname>";
        lic_details += "  </PathologistLicensure>";
      }
      lic_details += "  </ArrayOfPathologistLicensure>";
    }

    var reg = null;
    if (form.frmInp_US && form.frmInp_ST) {
      reg = 'US,ST'
    }
    else if (form.frmInp_ST) {
      reg = 'ST'
    }
    else if (form.frmInp_US) {
      reg = 'US'
    }
    this.valueloginname = form.frmInp_LoginName;
    this.passwordvalue = "va2010";
    var sendJson = {
      "title": form.frmInp_title,
      "fname": form.frmInp_FirstName,
      "lname": form.frmInp_LastName,
      "midInitial": form.frmInp_MiddleInitial,
      "loginName": form.frmInp_LoginName,
      "eduQual": form.frmInp_Qualification,
      "gender": form.frmInp_Gender == "1" ? true : false,
      "npi": form.frmInp_NPI,
      "address1": form.frmInp_Address1,
      "address2": form.frmInp_Address2,
      "street": form.frmInp_Street,
      "city": form.frmInp_City,
      "state": form.frmInp_State === "Select" ? "null" : form.frmInp_State,
      "zip": form.frmInp_Zip,
      "country": form.frmInp_Country,
      "phone": form.frmInp_Phone,
      "fax": form.frmInp_Fax,
      "email": form.frmInp_Email,
      "status": form.frmInp_Status,
      "rolename": "",
      "userNamedisplayFormat": form.frmInp_DisplayFormatWebpage,
      "usernameReportformat": form.frmInp_DisplayFormatReports == null ? form.frmInp_DisplayFormatReports = 'S. F L D' : form.frmInp_DisplayFormatReports = 'S. F L D',
      "password": "8clpNzfFAebd6l/nd2R0BQ==",
      "defaultPage": "VAPreferences.aspx",
      "ssn": form.frmInp_SSN,
      "upin": form.frmInp_UPIN,
      "userCode": form.frmInp_UserCode,
      "sign": "",
      "commonKey": form.frmInp_ExternalUserGUID,
      "isAccLockedOut": false,
      "allowPathologistReview": form.frmInp_AllowReviewPathologist == null ? false : form.frmInp_AllowReviewPathologist as boolean,
      "isModifyTechnicalDataAccession": form.frmInp_AllowtoeditAccession == null ? false : form.frmInp_AllowtoeditAccession as boolean,
      "modifyTechnicalData": form.frmInp_EditSite == null ? false : form.frmInp_EditSite as boolean,
      "roleList": roleList,
      "isPrimaryRoleChanged": isPrimaryRoleChanged,
      "works": form.frmDrp_OrgWorks == 'true' ? true : false,
      "manages": form.frmDrp_OrgManages == 'true' ? true : false,
      "sendForreview": form.frmDrp_OrgSendforReview == 'true' ? true : false,
      "accountList": accountList,
      "pathReadingLoc": "5",
      "newRolesAdded": false,
      "medicalDirector": MD_val as boolean,
      "associateAccID": null,
      "deassociateAccID": null,
      "organizationID": this.SelectedOrgID,
      "primaryLocation": 0,
      "showMyCaseOnly": form.frmInp_ShowMyCaseOnly == null ? false : form.frmInp_ShowMyCaseOnly as boolean,
      "alowPathologistDiagnosisTemplate": form.frmInp_AllowPathologistDiagnosisTemplate == null ? false : form.frmInp_AllowPathologistDiagnosisTemplate as boolean,
      "diagICDcodeReminder": form.frmInp_Notifyusers == null ? false : form.frmInp_Notifyusers as boolean,
      "autoPopulateDiagICD9Codes": form.frmInp_PopulateDiag == null ? false : form.frmInp_PopulateDiag as boolean,
      "region": reg,
      "associationType": null,
      "externalUserGUID": form.frmInp_ExternalUserGUID,
      "userlicensure": lic_details,
      "holdReleaseDuration": HD_val,
      "threshold": threshold_value,
      "HoldReleaseDurationInfo": holdReleaseDurationvalue,
      "thresholdDuration": form.frmDrp_ThresholdDuration,
      "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
      "userid": "0",
      "modifiedby": "0",
      "loggedInUserId": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
      "entityType": "AddNewUser",
      "entityID": "",
      "actionperformed": "New User Created",
      "actiondetails": "",
      "auditDate": new Date(),
      "EntityData": null,
      "reviewData": review_data,
      "allowLogin": form.frmInp_AllowLogin == null ? true : form.frmInp_AllowLogin,
    };
    return sendJson;
  }
  SaveUserData(data) {
    this.ngxService.start("AddUserToOrg2")
    let deploykey = this.vaservices.deploymentKey
    //this.vaservices.deploymentKey=this.deploymentvalue
    // sessionStorage.setItem('deploymentKey', this.deploymentvalue.toLowerCase());
    this.vaservices.SaveAddUserToOrg(data).subscribe(
      result => {
        //sessionStorage.setItem('deploymentKey', deploykey.toLowerCase());
        if (!result.errors) {
          this.count = -1;
          if (result.Success) {
            if (this.LoginTypesflag == true) {
              if (this.saveandcreateflag == true) {
                sessionStorage.setItem('deploymentKey', deploykey.toLowerCase());
                this.saveandcreateflag = false;
                this.resetAddUserDetails();
                this.DeploymentKeys = this.vaservices.DeploymentKeys;
                if (this.fromComponent == "SetupNewFacility") {
                  this.frm_AddUsergroup.patchValue({
                    frmDeploymentKey: this.vaservices.deploymentKey
                  });
                  this.deploymentvalue = this.vaservices.deploymentKey;
                  this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
                  // this.showAddUserDetailsFunc(this.OrgValue)
                  this.setOrganization(this.OrgValue);
                  //this.context = 'facility'
                  setTimeout(() => {
                    this.frm_AddUsergroup.patchValue({
                      frmchk_OrgLocation: true
                    });
                    this.onChangefrmchk_OrgLocation(true)
                  }, 3000);
                  this.searchOrgType(this.OrgValue);
                }
                else {

                  this.closePopOrg()
                  this.frm_AddUsergroup.reset();
                  this.DeploymentKeys = this.vaservices.DeploymentKeys;
                  this.frm_AddUsergroup.patchValue({
                    frmDeploymentKey: "none"
                  });
                  this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
                  this.ShowFrmAddUserDetail = false;
                }
                // this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()

                this.ngxService.stop("AddUserToOrg2");
                this.disabledloginname = false;
                this._snackBar.open(result.Message, 'Success');
              }
              else {
                // this._snackBar.open(result.Message, 'Success');
                this.loginDeatilsData();
                this.ngxService.stop("AddUserToOrg2");
              }


            }
            else {
              if (this.saveandcreateflag == true) {
                this.saveandcreateflag = false;
                this.DeploymentKeys = this.vaservices.DeploymentKeys;
                if (this.fromComponent == "SetupNewFacility") {
                  this.frm_AddUsergroup.patchValue({
                    frmDeploymentKey: this.vaservices.deploymentKey
                  });
                  this.deploymentvalue = this.vaservices.deploymentKey;
                  this.frm_AddUsergroup.controls['frmDeploymentKey'].disable()
                  // this.showAddUserDetailsFunc(this.OrgValue)
                  this.setOrganization(this.OrgValue);
                  //this.context = 'facility'
                  setTimeout(() => {
                    this.frm_AddUsergroup.patchValue({
                      frmchk_OrgLocation: true
                    });
                    this.onChangefrmchk_OrgLocation(true)
                  }, 3000);
                  this.searchOrgType(this.OrgValue);
                }
                else {
                  this.saveandcreateflag = false;
                  this.closePopOrg()
                  this.frm_AddUsergroup.reset();
                  this.DeploymentKeys = this.vaservices.DeploymentKeys;
                  this.frm_AddUsergroup.patchValue({
                    frmDeploymentKey: "none"
                  });
                  this.frm_AddUsergroup.controls['frmDeploymentKey'].enable()
                  this.ShowFrmAddUserDetail = false;
                }
                this.ngxService.stop("AddUserToOrg2");
                this.disabledloginname = false;
                this._snackBar.open(result.Message, 'Success');
              }
              else {
                if (this.fromComponent == "SetupNewFacility") {
                  sessionStorage.setItem('deploymentKey', deploykey.toLowerCase());
                  this._snackBar.open("Invitation Sent Successfully", 'Success');
                  this.AddUserPopUpClose();
                  this.ngxService.stop("AddUserToOrg2");
                }
                else {
                  this._snackBar.open("Invitation Sent Successfully", 'Success');
                  this.AddUsergroupClose(true);
                  this.ngxService.stop("AddUserToOrg2");
                }
              }

            }
          }
          else {
            this._snackbar.open("An error occurred while processing your request", 'Close');
            this.ngxService.stop("AddUserToOrg2");
          }
        }

      }, error => {
        this._snackBar.open("Failed!", 'Failed');
        console.info(error);
        this.ngxService.stop("AddUserToOrg2")
      });
  }
  loginDeatilsData() {
    this.ngxService.stop("1");
    let deploykey=this.vaservices.deploymentKey;
    let modifiedBy=sessionStorage.getItem('Userid')?sessionStorage.getItem('Userid'):-100
    //this.vaservices.deploymentKey=this.deploymentvalue
      this.vaservices.GetResetPassword(this.valueloginname,modifiedBy,deploykey).subscribe(data => {
        sessionStorage.setItem('deploymentKey', deploykey.toLowerCase());

      if (data != 'null') {
        //this.AddUsergroupClose();
        this.passworddata = data[0];
        this.iflogindeatils = true;
        this.count = -1;
        this.ngxService.stop("1");

      }
      else {
        this.resetAddUserDetails();
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.resetAddUserDetails();
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error)
    });
  }
  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("", "Copied");
  }

  //when form component is setupnewFacility
  AddUserPopUpClose() {
    if (this.fromComponent != 'SetupNewEntities') {
      this.SetupNewFacilityComponent.closeSummaryPopUp();
    }
    else {
      this.SetupNewEntitiesComponent.closeSummaryPopUp();
    }
  }

  //getMedicalvalue

  getIsMedicalDirectorvalue() {
    var IsMedicalDirectorExists_isMedicalDirector = false;
    var IsMedicalDirectorExists_status;
    var IsMedicalDirectorExists_userName;
    var IsMedicalDirectorExists_commonKey;
    var IsMedicalDirectorExists_userID;
    this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }

      if (IsMedicalDirectorExists_isMedicalDirector == true) {
        this.hidIsChecked = true;
      }
      else {
        this.hidIsChecked = false;
      }
      this.getLicensureDetails();
    }, error => {
      console.error(error);
    });
  }
}