<div  [ngClass]="viewType =='small'?'modal-backdrop-class row m-0 absolute':'modal-backdrop-class row m-0 relative'">
<div class="col-sm-5" *ngIf="viewType =='small'" ></div>
<div  [ngClass]="viewType =='small'?'col-sm-7 info-card relative px-0':'col-sm-12 info-card relative px-0'">
  <span class="card close_btn" (click)="CloseView()" *ngIf="viewType == 'small'">
    <mat-icon class="close_icon">close</mat-icon>
  </span>

  <app-userdetails *ngIf="fromLocation !=='location'" [userDetials]="userDetails" [ViewType]="viewType" (EditUser)="redirectoEdit($event)"></app-userdetails>
  <app-client-location-details *ngIf="fromLocation =='location'" [locationDetails]="locationDetails" (editLocation)="editLocation($event)"></app-client-location-details>
  <app-detailstabs [tabData]="tabList" (selectedTab)="getTabData($event)"></app-detailstabs>

<div class="common-tab-wrap">
  <app-infodetails *ngIf="selectedTab =='details' && fromLocation != 'location'" [userDetials]="userDetails" [userRoles]="savedRoles" ></app-infodetails>
  <app-location-info-details *ngIf="selectedTab =='details' && fromLocation == 'location'" [locationDetails]="locationDetails" ></app-location-info-details>

  <app-infonpi  *ngIf="selectedTab =='npi'" [userDetials]="userDetails" [fromLocation] = "fromLocation" ></app-infonpi>
  <app-infoassociation [userDetails]="userDetails" [userRoles]="savedRoles" *ngIf="selectedTab =='associations'"></app-infoassociation>

  <app-infopreference [userDetails]="userDetails" [userRoles]="savedRoles" *ngIf="selectedTab =='preferences' && fromLocation != 'location'"></app-infopreference>
  <app-location-preferences [locationInfo] ="locationDetails"  *ngIf="selectedTab =='preferences' && fromLocation == 'location'" ></app-location-preferences>

  <app-infoattachment *ngIf="selectedTab =='attachments & notes'"></app-infoattachment>
  <app-infofax [userDetials]="userDetails" *ngIf="selectedTab =='fax'"></app-infofax>
</div>
</div>

</div>


