import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { CellEditEndingEventArgs, DataMap, FlexGrid, HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrgTableComponent } from 'src/app/base/common/org-table/org-table.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { cloneDeep } from 'lodash';
declare var $: any;

@Component({
  selector: 'app-diagnosis-templates',
  templateUrl: './diagnosis-templates.component.html',
  styleUrls: ['./diagnosis-templates.component.scss']
})


export class DiagnosisTemplatesComponent implements OnInit {
  [x: string]: any;

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;
  @ViewChild('txtInput') txtInput: ElementRef;
  copyGridData: CollectionView;
  gridData: CollectionView;
  public gridDisplay = false;
  public gridarray;
  public userNames = [];
  public detailsDisplay: boolean = false;
  public OrgLevelDisplay = true;
  public uploadClicked: boolean = false;
  hideCopyToOrgBtn: boolean = false;
  hideExportBtn: boolean = false;
  hideuploadBtn: boolean = false;
  hideEditBtn = true;
  gridHeader = [];
  userlevel = true;
  headerName: String
  accidvalue: any
  casetypevalue: any
  uploadbutton: boolean = false
  public DeploymentKeys = [];
  searchInput: String = '';
  searchUserInput: String = '';
  copyDataClicked: boolean = false;
  usertoggle: boolean = false;
  destDeployment: string;
  searchuserlist: any[];
  srcDeployment: any;
  orgList: any[];
  public searchResult: Array<any> = [];
  srcOrgid: any;
  srcUserid: string;
  srcOrgName: any;
  srcUserName: any;
  selector: Selector;
  selectedItems: any = [];
  checkStatus = false;
  copyData: any = [];
  caseTypeArray: any[];
  repeatedNames: string;
  invalidCasetypeFlag: boolean = false;
  backBtn: boolean = true;
  userchanged: any;
  displaycolor: any = [];
  Flagdisplaycolor = false;
  srcOrgType: any;
  orgFilterList: any[];
  userCardFlag: boolean = false;
  sheetHeader = [];
  inData: any;
  uploaduserid: any;
  orgUservaluelevel: boolean = false;
  orguserdatalevel: any;
  excelDataArray: any = [];
  postUpload: Boolean = false;
  selectedOption = 'Group Templates';
  showtemplated: boolean = false;
  showPaginationMainGrid: boolean = false;
  userRoles: any = []
  ReportToCancerRegistrySettingsvalue = new DataMap(this.getReportToCancerRegistrySettings(), 'id', 'name');
  Reporttoregistryvalue = new DataMap(this.getReporttoregistry(), 'id', 'name');
  Abnormalvalue = new DataMap(this.getAbnormal(), 'id', 'name');
  Statusvalue = new DataMap(this.getStatus(), 'id', 'name');
  userEvent = new EventEmitter();
  selectedRole: string;
  searchCaseInput: String = '';
  organizationId: any;
  activityEntity : any;
  copyHeaders = ["diagtemplateid", "casetype", "diagnosis", "diagnosiscode", "diagnosticsummary", "microscopicnotes", "icdcodes1"
    , "icdcodes2", "icdcodes3", "isgroup", "ishidden", "groupname", "siteid"
    , "orgparentid", "searchcode", "displayorder", "severityorder", "reporttoregistry", "schematicdisplay"
    , "displaycolor", "displaypattern", "displayshape", "createdby", "rptcancerregistrysettings", "reference"
    , "referencesummary", "commentcode", "commentsummary", "isactive"
    , "isbillable", "isbenign", "abnormal", "sitegrouporder", "sitedescription"
    , "defaultorgdiagtemplateid", "reforgdiagtemplateid", "isuserleveltemplateenabled", "userleveltemplateuserid"
    , "specimencomments", "diagnosisgroupname", "allowcytotechnologisttosignoutcases", "allowcytotechsupervisortosignoutcases"
    , "issitenotsurvivedtemplate", "issitenotsubmittedtemplate", "diagnosistags"];

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: UntypedFormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }
  frm_testForm = this._fb.group({
    frm_panel: ''
  });

  private getReportToCancerRegistrySettings() {
    return [
      { id: 0, name: 'Not Specified' },
      { id: 1, name: '0,0' },
      { id: 2, name: '1,0' },
      { id: 3, name: '1,1' }
    ];
  }

  private getReporttoregistry() {
    return [
      { id: 0, name: 'Yes' },
      { id: 1, name: 'No' }
    ];
  }


  private getAbnormal() {
    return [
      { id: 0, name: 'Yes' },
      { id: 1, name: 'No' }
    ];
  }

  private getStatus() {
    return [
      { id: 0, name: 'Yes' },
      { id: 1, name: 'No' }
    ];
  }

  copycollectionMethodForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmUser: [""],
    frmcasetype: [""],
    frmDepKey: ["", Validators.required],
  })

  ngOnInit(): void {
    this.loadInitialData()
    this.getUsers(null);
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
  }

  loadInitialData() {
    if ((this.templateData.cardtype.toLowerCase() == 'users') || sessionStorage.getItem("search_context").toLowerCase() == "userid" || (this.templateData.cardtype.toLowerCase() == 'pathologist') || (this.templateData.cardtype.toLowerCase() == 'physician')) {
      this.OrgLevelDisplay = false;
      this.uploadbutton = false;
      this.showtemplated = true;
      this.userCardFlag = true;
      this.userRoles = this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, ''))
      if (this.userRoles && this.userRoles.length > 0) {
        this.inData = this.userRoles[0].userid.toString();
        this.selectedRole = this.userRoles[0].RoleName.toString();
        this.getOrgCasetype();
      }
      else {
        this.noRole = true;
        return
      }
    }
    else {
      this.accidvalue = sessionStorage.getItem('AccountID');
      this.casetypevalue = this.templateData.cardtype;
      this.OrgLevelDisplay = true;
      this.uploadbutton = true;
      this.showtemplated = false;
      this.getListUsers();
    }
    this.gridDisplay = true;
    this.GetButtondetails();
    this.AddGridData();
    this.getDisplayColor();
  }

  getDisplayColor() {
    let orgid = sessionStorage.getItem('org_id').toString();
    let query = this.SubMenuCardModel.GetQuery('diagnosistemplatecolorlist');
    let queryVariable;
    if ((this.templateData.cardtype.toString().toLowerCase() == 'users') || (this.templateData.cardtype.toString().toLowerCase() == 'pathologist')) {
      queryVariable = { "orgid": orgid.toString(), "case": null };
    }
    else {
      queryVariable = { "orgid": orgid.toString(), "case": this.templateData.secondarykeys.casetype };
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        for (var i = 0; i < res.data.submenuData.length; i++) {
          this.displaycolor.push(res.data.submenuData[i].displaycolor);
        }
        this.Flagdisplaycolor = this.displaycolor.filter((value, index, array) => array.indexOf(value) === index);
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();
  }

  setUserRole(event, data) {
    if (event.source.selected) {
      this.inData = data.userid.toString();
      this.selectedRole = data.RoleName.toString();
      this.getOrgCasetype();
    }
  }

  // warning model close
  closeCasetypeModel() {
    this.invalidCasetypeFlag = false;
  }

  clearOrganization() {
    this.copycollectionMethodForm.patchValue({
      frmOrganization: '',
      frmcasetype: '',
      // frmuser:''   //Naming convention used is wrong and I didn't find its useage
    })
  }

  clearUser() {
    this.copycollectionMethodForm.patchValue({
      frmUser: ''
    })
  }

  //***** View Grid *****/
  //#region
  AddGridData() {
    this.uploaduserid = null;
    this.OrgLevelDisplay = true;
    this.orgUservaluelevel = false;
    this.uploadbutton = true;
    this.uploadClicked = false;
    this.headerName = "Group Level Diagnosis Templates"
    this.userlevel = true;
    this.frm_testForm = this._fb.group({
      frm_panel: "Group Templates",
    });
    this.gridarray = [];
    if ((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist')) {
      this.gridHeader = ['CreatedBy','ModifiedBy','CreatedDate','ModifiedDate','Site_Name', 'Severity_Order', 'Abnormal', 'Report_To_Cancer_Registry_Settings', 'Reporttoregistry', 'ICD_Codes', 'GroupName', 'Group', 'Diagnosis_Code', 'Intrepretation', 'DiagnosisTags', 'NotBillable', 'DisplayColor', 'MicroscopicNotes', 'ishidden', 'Diagnosis', 'Case_Type', 'Org_Diag_Template_ID'];
    }
    else {
      this.gridHeader = ['CreatedBy', 'ModifiedBy', 'CreatedDate', 'ModifiedDate', 'Case_Type', 'Site_Name', 'Severity_Order', 'Abnormal', 'Report_To_Cancer_Registry_Settings', 'Reporttoregistry', 'ICD_Codes', 'GroupName', 'Group', 'ishidden', 'Intrepretation', 'DiagnosisTags', 'NotBillable', 'DisplayColor', 'MicroscopicNotes', 'Diagnosis_Code', 'Diagnosis', 'Org_Diag_Template_ID'];
    }
    let primary = {};
    if (this.templateData.submenuData.submenuData2 != undefined) {
      if (this.templateData.submenuData.submenuData2.length > 0) {
        this.UserListData = this.templateData.submenuData.submenuData2
      }
    } else {
      this.headerName = "User Level Diagnosis Templates"
      this.UserListData = []
    }
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            this.gridHeader.find((e): any => {
              if (key === e) {
                if (value == null || value == '') {
                  value = 'Not Specified'
                }
                primary[key] = value;
              }
            });
          }
          this.gridarray.push(primary);
        }
        this.gridarray.sort((a, b) => a.Org_Diag_Template_ID < b.Org_Diag_Template_ID ? -1 : 1);

        this.gridWritevalue(this.gridarray);
        //this.gridData = new CollectionView(this.gridarray, { pageSize: 10 })
      } else {
        this.gridwidth = 0;
      }
    } else {
      this.gridwidth = 0;
    }
  }
  //#endregion

  gridWritevalue(value) {
    this.gridData = new CollectionView(value, { groupDescriptions: ['GroupName'], pageSize: 10 })
    this.gridwidth = 190 * (this.gridHeader.length - 1);
    this.showPaginationMainGrid = value.length > 10 ? true : false;
    if (this.gridwidth > 1300) this.gridwidth = 1300;
    if (this.gridwidth != 0) {
      this.gridwidth = this.getpercentage(this.gridwidth);
    }
  }

  //#region popup for copy org
  copyOrg() {
    let dialogRef = this.dialog.open(OrgTableComponent, {
      disableClose: true,
    });
    let orgid, casetypeData;
    if (this.templateData.cardtype.toString().toLowerCase() == 'users' || this.templateData.cardtype.toString().toLowerCase() == 'pathologist') {
      orgid = sessionStorage.getItem('org_id');
      casetypeData = '';
    }
    else {
      this.OrgLevelDisplay = true;
      orgid = this.commonService.orgid.toString();
      casetypeData = this.templateData.secondarykeys.casetype;
    }
    (<OrgTableComponent>dialogRef.componentInstance).dataToTakeAsInput = {
      OrganizationID: orgid,
      CaseType: casetypeData,
      accountid: this.templateData.cardIdentifier,
      TableName: 'OrganizationDiagTemplates',
      ColumnNames: ['casetype', 'organizationid', 'isgroup', 'orgparentid',
        'diagnosis', 'diagnosiscode', 'diagnosticsummary', 'microscopicnotes', 'icd9codes', 'searchcode', 'severityorder',
        'reporttoregistry', 'schematicdisplay', 'displayorder', 'displaycolor', 'displaypattern', 'displayshape',
        'createdby', 'modifiedby', 'RptCancerRegistrySettings', 'commentcode', 'commentsummary',
        'ICD9Codes1', 'ICD9Codes2', 'ICD9Codes3', 'isactive', 'isBillable', 'Abnormal', 'IsUserLevelTemplateEnabled',
        'UserLevelTemplateUserId', 'DiagnosisGroupName', 'siteid', 'ishidden', 'Reference', 'referencesummary',
        'isBenign', 'SiteDescription', 'SpecimenComments', 'AllowCytotechnologistToSignoutCases',
        'AllowCytotechSupervisorToSignoutCases', 'IsSiteNotSurvivedTemplate', 'IsSiteNotSubmittedTemplate', 'DiagnosisTags'],
      columnDefs: ['Organization_ID', 'SiteName', 'IsUserLevelTemplateEnabled', 'UserLevelTemplateUserId', 'DiagnosisTags', 'IsSiteNotSubmittedTemplate', 'IsSiteNotSurvivedTemplate',
        'AllowCytotechSupervisorToSignoutCases', 'AllowCytotechnologistToSignoutCases', 'DiagnosisGroupName', 'SpecimenComments',
        'SiteDescription', 'Site_Group_Order', 'ICD9Codes3', 'ICD9Codes2', 'ICD9Codes1', 'Comment_Summary', 'Comment_Code',
        'Reference_Summary', 'Reference', 'Report_To_Cancer_Registry_Settings',
        'Display_Shape', 'Display_Pattern', 'Schematic_Display', 'Severity_Order',
        'Display_Order', 'Search_Code', 'icd9codes', 'Microscopic_Notes',
        'Diagnostic_Summary', 'Org_Parent_ID', 'Site_ID', 'isGroup',
        'Hidden', 'Benign', 'Report_to_Registry', 'Abnormal', 'Billable', 'Active',
        'Display_Color', 'Diagnosis_Code', 'Diagnosis', 'Casetype', 'Org_Diag_Template_ID']
    };
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.getUpdatedGrid();
      }
    })
  }
  //#endregion

  copyOrgData() {
    if (!this.hideCopyToOrgBtn) {
      this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.srcDeployment = this.destDeployment
      this.getListOrg();
      this.gridWidth = -1
      this.uploadbutton = false;
      this.detailsDisplay = false;
      this.gridDisplay = false;
      this.copyDataClicked = true;
      this.editEnabled = false;
      this.gridPage = false;
      this.uploadClicked = false;
      this.addEditScreen = false;
      this.postUpload = false;
      this.backBtn = true;
      this.srcOrgid = ''
      this.copycollectionMethodForm.reset()
      this.copycollectionMethodForm.patchValue({
        frmOrganization: "",
        frmcasetype: "",
        frmUser: "",
        frmDepKey: this.destDeployment,
      })
    }
  }

  getCaseList() {
    let caseTypeSelect = this.srcOrgid
    let queryVariable = { "id": caseTypeSelect.toString() };
    let query = this.SubMenuCardModel.GetQuery('CaseTypeforAddendumNotes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let casetypelists = [];
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      if (!data.errors) {
        let casesnamelist = data.data.submenuData
        this.Casetypelist = [...new Map(casesnamelist.map((item) => [item['CaseType'], item['CaseType']])).values(),]
        casetypelists.push(...this.Casetypelist);
        this.userListArray = casetypelists;
      }
    }, error => {
      console.error(error);
    });
    this.getUsers(this.srcOrgid);
  }

  //fetch based on the orgid selected the siteid will show
  fetchCaseSeries(value: string) {
    // this.searchCaselist = []
    if (value === '') {
      return (this.searchCaselist = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.searchCaselist.find(r => {
          if (r.toString().toLowerCase() === value.toLowerCase()) {
            return r
          } else {
            this.usertoggle = true
          }
        })
        this.searchCaselist = this.userListArray.filter(function (series) {
          if (series != null) {
            return series.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchCaselist = this.searchCaselist.splice(0, 25);
      }

    }
    else {
      this.searchCaselist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchCaselist = this.searchCaselist.splice(0, 25);

      // this.getUsers(this.searchCaselist);
    }
  }

  //#region Tooltip for Grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
    grid.beginningEdit.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellRangeEventArgs) => {
      let col = s.columns[e.col];
      if (col.binding == 'Diagnosis') {
        this.item = s.rows[e.row].dataItem;
      }
      if (col.binding == 'Diagnosis_Code') {
        this.item = s.rows[e.row].dataItem;
      }
    });
    grid.cellEditEnded.addHandler((s: FlexGrid, e: CellEditEndingEventArgs) => {
      let oldValue = e.data;
      let newValue = s.getCellData(e.row, e.col, true);
      if (oldValue !== newValue) {
        if (!this.hideEditBtn) {
          let col = s.columns[e.col];
          if (col.binding == 'Diagnosis') {
            if (this.item.Group === 'Yes') {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: 'Diagnosiswithgroup'
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    // this.getUpdatedGrid();
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }

                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
          }
          if (col.binding == 'Diagnosis_Code') {
            if (this.item.Group === 'Yes') {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: 'Diagnosiswithgroup'
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'DiagnosisTemplate', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
          }
          if (col.binding == 'Severity_Order') {
            if (!isNaN(newValue)) {
              let value = newValue === '' ? null : newValue;
              newValue = value
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  this.ngxService.stop();
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }
          }
          if (col.binding == 'Report_To_Cancer_Registry_Settings') {
            if (newValue === '0,0' || newValue === '1,0' || newValue === '1,1' || newValue === 'Not Specified') {
              let value = newValue
              if (newValue === 'Not Specified') {
                value = null
              }
              else {
                newValue = value
              }
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }

          }
          if (col.binding == 'Abnormal') {
            if (newValue.toLowerCase() === 'yes' || newValue.toLowerCase() === 'no') {
              let value = newValue.toLowerCase() === 'yes' ? 1 : 0;
              newValue = value
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }
          }
          if (col.binding == 'Reporttoregistry') {
            if (newValue.toLowerCase() === 'yes' || newValue.toLowerCase() === 'no') {
              let value = newValue.toLowerCase() === 'yes' ? 1 : 0;
              newValue = value
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }
          }
          if (col.binding == 'ICD_Codes') {
            // if(!isNaN(newValue))
            // {
            let value = newValue.split(',');;
            let icdvalue1 = value[0] != " " ? value[0] : "null";
            let icdvalue2 = value[1] != " " ? value[1] : "null";
            let icdvalue3 = value[2] != " " ? value[2] : "null";
            let icdvalue4: any = [];
            if (value.length > 3) {
              for (var i = 3; i < value.length; i++) {
                if (icdvalue4.length > 0) {
                  icdvalue4 = icdvalue4 + ',' + value[i];
                }
                else {
                  icdvalue4 = icdvalue3 + ',' + value[i];
                }
              }
            }
            icdvalue4 = icdvalue4 != "" ? icdvalue4 : icdvalue3
            let obj = {
              Id: sessionStorage.getItem('org_id'),
              OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
              ICDCOdes1: icdvalue1,
              ICDCOdes2: icdvalue2,
              ICDCOdes3: icdvalue4
            }
            this.ngxService.start();
            this.VitalHttpServices.EditDiagnosisTemplatesICDcodeData(obj)
              .subscribe((res) => {
                if (res.content && res.content.Success == true) {
                  let validatecardtype = sessionStorage.getItem('contextdata');
                  if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                    this.refreshGrid();
                  }
                  else {
                    this.refreshBackData();
                  }
                  this.ngxService.stop();
                  this._snackbar.open(res.content.Message, 'Close');
                  this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                }
              }, error => {
                // Change position in view level if update fail
                this.ngxService.stop();
                this._snackbar.open("An error occurred while processing your request", "Failed");
              });
            //}
            // else{
            //   this.ngxService.stop();
            //   let validatecardtype= sessionStorage.getItem('contextdata');
            //       if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
            //         this.refreshGrid();
            //    }
            //    else{
            //     this.refreshBackData();
            //    }
            //   this._snackbar.open("Please provide a valid data", "Failed");
            // }
          }
          if (col.binding == 'ishidden') {
            if (newValue.toLowerCase() === 'yes' || newValue.toLowerCase() === 'no') {
              let value = newValue.toLowerCase() === 'no' ? 0 : 1;
              newValue = value
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              if (newValue === 0) {
                let dupliactecheck =
                {
                  OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                  diagnosiscode: s.rows[e.row].dataItem.Diagnosis_Code,
                  Id: Number(sessionStorage.getItem('org_id')),
                  casetype: s.rows[e.row].dataItem.Case_Type
                }
                this.ngxService.start();
                this.VitalHttpServices.ValidateDiagStatus(dupliactecheck)
                  .subscribe((res) => {
                    if (res.content && res.content.Message.toLowerCase() == 'duplicate') {
                      this.ngxService.stop();
                      let dialogRef = this.dialog.open(ConfirmComponent, {
                        width: '485px',
                        data: {
                          header: 'Duplicate Template',
                          message: '',
                          alert: 'There is an Active Diagnosis Templates with the same name. Do you still want make this duplicate template Active?  If you proceed there will be duplicate templates for the casetype.',
                          continue: 'yes',
                          cancel: 'no',
                        },
                      });
                      return dialogRef
                        .afterClosed()
                        .toPromise()
                        .then((result) => {
                          if (result) {
                            this.ngxService.start();
                            this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                              .subscribe((res) => {
                                if (res.content && res.content.Success) {
                                  let validatecardtype = sessionStorage.getItem('contextdata');
                                  if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                                    this.refreshGrid();
                                  }
                                  else {
                                    this.refreshBackData();
                                  }
                                  this.ngxService.stop();
                                  this._snackbar.open(res.content.Message, 'Close');
                                  this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                                }
                              }, error => {
                                // Change position in view level if update fail
                                this.ngxService.stop();
                                this._snackbar.open("An error occurred while processing your request", "Failed");
                              });
                          }
                          else {
                            this.ngxService.stop();
                            let validatecardtype = sessionStorage.getItem('contextdata');
                            if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                              this.refreshGrid();
                            }
                            else {
                              this.refreshBackData();
                            }
                          }
                        });
                    }
                    else {
                      this.ngxService.start();
                      this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                        .subscribe((res) => {
                          if (res.content && res.content.Success) {
                            let validatecardtype = sessionStorage.getItem('contextdata');
                            if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                              this.refreshGrid();
                            }
                            else {
                              this.refreshBackData();
                            }
                            this.ngxService.stop();
                            this._snackbar.open(res.content.Message, 'Close');
                            this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                          }
                        }, error => {
                          // Change position in view level if update fail
                          this.ngxService.stop();
                          this._snackbar.open("An error occurred while processing your request", "Failed");
                        });

                    }
                  }, error => {
                    // Change position in view level if update fail
                    this.ngxService.stop();
                    this._snackbar.open("An error occurred while processing your request", "Failed");
                  });
              }
              else {
                this.ngxService.start();
                this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                  .subscribe((res) => {
                    if (res.content && res.content.Success) {
                      let validatecardtype = sessionStorage.getItem('contextdata');
                      if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                        this.refreshGrid();
                      }
                      else {
                        this.refreshBackData();
                      }
                      this.ngxService.stop();
                      this._snackbar.open(res.content.Message, 'Close');
                      this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                    }
                  }, error => {
                    // Change position in view level if update fail
                    this.ngxService.stop();
                    this._snackbar.open("An error occurred while processing your request", "Failed");
                  });

              }
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }
          }
          if (col.binding == 'DiagnosisTags') {
            if (newValue === s.rows[e.row].dataItem.DiagnosisTags) {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
            }
          }
          if (col.binding == 'MicroscopicNotes') {
            if (newValue === s.rows[e.row].dataItem.MicroscopicNotes) {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
            }
          }
          if (col.binding == 'Intrepretation') {
            if (newValue === s.rows[e.row].dataItem.Intrepretation) {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
            }
          }
          if (col.binding == 'NotBillable') {
            if (newValue.toLowerCase() === 'yes' || newValue.toLowerCase() === 'no') {
              let value = newValue.toLowerCase() === 'yes' ? 1 : 0;
              newValue = value
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              this.ngxService.stop();
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
              this._snackbar.open("Please provide a valid data", "Failed");
            }
          }
          if (col.binding == 'DisplayColor') {
            if (newValue === s.rows[e.row].dataItem.DisplayColor) {
              let obj = {
                diagnosis: newValue,
                OrgDiagTemplateID: s.rows[e.row].dataItem.Org_Diag_Template_ID,
                userid: !sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                actionParam: col.binding
              }
              this.ngxService.start();
              this.VitalHttpServices.EditDiagnosisTemplatesData(obj)
                .subscribe((res) => {
                  if (res.content && res.content.Success) {
                    let validatecardtype = sessionStorage.getItem('contextdata');
                    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                      this.refreshGrid();
                    }
                    else {
                      this.refreshBackData();
                    }
                    this.ngxService.stop();
                    this._snackbar.open(res.content.Message, 'Close');
                    this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Diag Template', 'Audit', obj, { isactive: oldValue })
                  }
                }, error => {
                  // Change position in view level if update fail
                  this.ngxService.stop();
                  this._snackbar.open("An error occurred while processing your request", "Failed");
                });
            }
            else {
              let validatecardtype = sessionStorage.getItem('contextdata');
              if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
                this.refreshGrid();
              }
              else {
                this.refreshBackData();
              }
            }
          }
        }
        else {
          this._snackbar.open('User is not authorized to perform this action.', 'Close');
          this.getUpdatedGrid();
        }
      }

    });
  }
  //#endregion

  //#region Export Grid data
  ExportCopyStatus(flex) {
    let excel = [];
    let grid = cloneDeep(flex.rows)
    grid.forEach(e => {
      delete e._data["notemessage"];
      delete e._data["slno"];
      delete e._data["slno1"];
      delete e._data["modifiedby"];
      delete e._data["createdby"];
      delete e._data["orgdiagtemplateid"];
      delete e._data["organizationid"];
      delete e._data["_path"];
      delete e._data["_items"];
      delete e._data["_groups"];
      delete e._data["_isBottomLevel"];
      delete e._data["_level"];
      delete e._data["_name"];
      delete e._data["_gd"];
      excel.push(e._data);
    });
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Diagnosis Templates_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel.reverse());
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'DiagTemplates_');
    XLSX.writeFile(wb, filename);
  }
  //#region Export Grid data
  ExportExcel(flex) {
    if(!this.hideExportBtn){
    if(!this.templateData.secondarykeys){
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else{
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Diagnosis Templates_' +this.organizationId + '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize, oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      filename,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
    }
  }
  //#endregion

  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    this.VitalHttpServices.ValidateBulkUploadDiagnosisTemplatesData(excelArr).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.content.length > 0) {
          result = result.content;
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          excelArr.filter(e => {
            result.find(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.ngxService.stop();
          this.excelGridData(excelArr);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //#endregion

  //#region to get users whose userid has been enabled for orgdiagtemplates

  getListUsers() {
    this.userNames = [];
    let orgid = sessionStorage.getItem('org_id').toString();
    let query = this.SubMenuCardModel.GetQuery('getuserenableddiagusers');
    let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    var rolesArray = ['pathologist', 'pathologist assistant', 'transcriptionist', 'cytotechnologist supervisors']
    this.VitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        for (var i = 0; i < res.data.Card.length; i++) {
          if (rolesArray.includes(res.data['Card'][i].RoleName.toLowerCase())) {
            {
              this.userNames.push(res.data.Card[i]);
            }
          }
        }
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);

      this.ngxService.stop();
    });
    this.ngxService.stop();
  }
  //#endregion

  getpercentage(pixel: number) {
    var screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }

  //#region get updated grid data
  getUpdatedGrid() {
    this.ngxService.start();
    let queryVariable;
    this.showtemplated = true;
    let query;
    if ((this.templateData.cardtype.toString().toLowerCase() == 'users') || (this.templateData.cardtype.toString().toLowerCase() == 'pathologist')) {
      queryVariable = { "accid": this.inData ? this.inData.toString() : sessionStorage.getItem('DxUserId').toString() };
      query = this.SubMenuCardModel.GetQuery("userleveldiagnosistemplate");
    }
    else {
      queryVariable = { "casetype": this.templateData.secondarykeys.casetype, "orgid": this.commonService.orgid.toString() };
      query = this.SubMenuCardModel.GetQuery("orgleveldiagnosistemplate");
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData['submenuData1'] = data.data.submenuData1
          this.templateData.submenuData['submenuData2'] = data.data.submenuData2
          this.UserListData = this.userCardFlag ? this.templateData.submenuData : this.templateData.submenuData.submenuData2
        }
        else {
          this.templateData.submenuData = data.data.submenuData;
        }
        this.OrgLevelDisplay ? this.AddGridData() : (this.userCardFlag ? this.AddGridData() : this.callUserGrid({ userid: this.inData }));
      }
    }, error => {

      this.ngxService.stop();
      console.log(error);
    });
    this.ngxService.stop();
  }
  //#endregion

  GetButtondetails() {
    if ((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist')) {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "CopyToOrg":
          this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Upload":
          this.hideuploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  callUserGrid(item) {
    this.ngxService.start();
    this.headerName = "User Level Diagnosis Templates"
    try {
      this.userlevel = false;
      this.OrgLevelDisplay = false;
      this.uploadbutton = false;
      this.inData = item.userid;
      this.uploaduserid = item.userid;
      this.UserListData != undefined ? this.selectedUserData = this.UserListData.filter(va => va.UserId == item.userid) : null;
      this.gridHeader = ['Site_Name', 'Severity_Order', 'Abnormal', 'Report_To_Cancer_Registry_Settings', 'Reporttoregistry', 'ICD_Codes', 'Diagnosis_Code', 'ishidden', 'Case_Type', 'Intrepretation', 'DiagnosisTags', 'NotBillable', 'DisplayColor', 'MicroscopicNotes', 'GroupName', 'Diagnosis', 'Org_Diag_Template_ID'];
      this.tableGrid = true;
      this.gridarray = [];
      let primary = {}
      if (this.selectedUserData) {
        if (this.selectedUserData.length > 0) {
          for (let i = 0; i < this.selectedUserData.length; i++) {
            primary = {}
            for (let [key, value] of Object.entries(this.selectedUserData[i])) {
              this.gridHeader.find(e => {
                if (key === e) {
                  if (value == null || value == '') {
                    value = 'Not Specified'
                  }
                  primary[key] = value;
                }
              });
            }
            this.gridarray.push(primary)
          }
          this.gridarray.sort((a, b) => a.Org_Diag_Template_ID < b.Org_Diag_Template_ID ? -1 : 1);
          this.gridData = new CollectionView(this.gridarray, { groupDescriptions: ['GroupName'], pageSize: 10 })
          this.gridwidth = (170 * this.gridHeader.length) + 37;
          this.showPaginationMainGrid = this.gridarray.length > 10 ? true : false;
          if (this.gridwidth > 1300)
            this.gridwidth = 1300;
          if (this.gridwidth != 0) {
            this.gridwidth = this.getpercentage(this.gridwidth)
          }
        } else {
          this.gridwidth = 0;
        }
      } else {
        this.gridwidth = 0;
      }
    }
    catch (e) {
      this.ngxService.stop();
    }
    this.ngxService.stop();
  }

  selectedUserGridData(e, item) {
    if (e.source.selected) {
      this.orguserdatalevel = item;
      this.orgUservaluelevel = true
      this.callUserGrid(item);
    }
  }

  //Bulk upload
  UploadMethod() {
    if (!this.hideuploadBtn) {
      this.gridwidth = 1;
      this.userlevel = false;
      this.uploadbutton = false;
      this.uploadClicked = true;
      this.detailsDisplay = true;
      this.gridDisplay = false;
    }
  }

  //refresh Back data
  refreshBackData() {
    this.uploadbutton = true
    this.showtemplated = false
    // this.userNames = [];
    //this.userList = [];
    this.getListUsers();
    let orgid = this.commonService.orgid.toString();
    let query = this.SubMenuCardModel.GetQuery('orgleveldiagnosistemplate');
    let queryVariable = { "orgid": orgid.toString(), "accid": this.accidvalue, "casetype": this.casetypevalue.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        this.templateData.submenuData = res.data.submenuData
        this.templateData.submenuData.submenuData2 = res.data.submenuData2
        this.UserListData = this.templateData.submenuData.submenuData2;
        this.templateData.cardtype = this.casetypevalue;
        this.selectedUserData = res.data.submenuData2;
        if (this.orgUservaluelevel == true) {
          this.callUserGrid(this.orguserdatalevel);
        }
        else {
          this.AddGridData();
        }
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();
  }

  //#region to change the deployment
  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.getListOrg();
      this.gridWidth = -1
      this.searchInput = '';
      this.searchUserInput = '';
      this.srcUserid = '';
      this.usertoggle = false;
      this.searchResult = []
      this.searchuserlist = []
      this.srcOrgType = "Laboratory"
      this.removeGrid();
      this.resetCopyForm();
    }
  }
  //#endregion


  //#region copy-from-entity: orglist
  fetchOrgSeries(value: string) {
    this.searchCaselist = []
    this.searchResult = []
    this.searchuserlist = [];
    this.searchUserInput = '';
    this.srcUserid = '';
    if (value === '') {
      this.searchuserlist = [];
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
      // this.getCaseList()
    }
  }
  //#endregion

  disableGetDataBtn() {
    if ((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist')) {
      return this.searchInput == '' ? true : (this.srcOrgid) != '' && this.searchCaseInput != '' ? false : true
    }
    else {
      return this.searchInput == '' ? true : (this.srcOrgid) != '' ? false : true

    }
  }

  //#region copy-from-entity: userlist
  fetchUserSeries(value: string) {
    this.searchuserlist = []
    this.srcUserid = '';
    if (value === '') {
      return (this.searchuserlist = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.userList.find(r => {
          if (r.username.toString().toLowerCase().trim() === value.toString().toLowerCase().trim() && r.username.toString().toLowerCase().trim() != 'organization') {
            this.srcUserid = r.userid
            this.usertoggle = false;
            return r
          } else {
            this.srcUserid = ''
            this.usertoggle = true
          }
        })
        this.searchuserlist = this.userList.filter(function (series) {
          if (series && series.username != null) {
            return series.username.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.searchuserlist = this.userList.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }
  //#endregion

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getCaseList();
      this.getUsers(this.srcOrgid);
    }
    if((this.templateData.cardtype.toLowerCase() == 'users') || (this.templateData.cardtype.toLowerCase() == 'pathologist'))
    {
      this.copycollectionMethodForm.patchValue({
        frmcasetype: "",
        frmUser: "",
      });
    }

  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcUserid = data.userid;
      this.srcUserName = data.username;
      this.searchUserInput = data.username;
    }
  }

  selectedCopyCase(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.getUserListOnCasetype(this.srcOrgid, data);
    }
  }


  resetCopyForm() {
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmcasetype: "",
      frmUser: "",
      frmDepKey: this.srcDeployment,
    })
  }


  //Get the userlist for based on the casetype

  async getUserListOnCasetype(orgid, casetype) {
    var userscaselist = [];
    const screeningEnabled = await this.getOrgScreeningEnabled(this.srcOrgid,casetype);
    let query = this.SubMenuCardModel.GetQuery('copyDigtemplateUsers');
    let queryVariable = { "Orgid": this.srcOrgid.toString(), "Casetype": casetype.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(userListBasedCasetype => {
      if (!userListBasedCasetype.errors) {
        this.copyUserListBasedOnCasetype = userListBasedCasetype.data.submenuData;
        for (var i = 0; i <= this.copyUserListBasedOnCasetype.length - 1; i++) {
          for (var j = 0; j <= this.userList.length; j++) {
            if (this.copyUserListBasedOnCasetype[i].Userid == this.userList[j].userid) {
              userscaselist.push(this.userList[j]);
              break;
            }
          }
        }
        this.userList = []
        userscaselist = !screeningEnabled ? this.removeScreeningTech(userscaselist) : userscaselist;
        this.userList = userscaselist
        // console.log(this.userList);
      }
    }, error => {
      console.log(error)
    })
  }

  async getOrgCasetype() {
    let query = this.SubMenuCardModel.GetQuery('getorgcasetypes');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id') };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (!res.errors) {
        this.caseTypeArray = [];
        this.caseTypeArray = res.data.submenuData;
        this.refreshGrid();
      }
    }, error => {
      console.log(error)
    })
  }

  //#region User/Pathologist Cardtype: casetype validation
  validateCasetype() {
    let temp1 = []
    for (let i = 0; i < this.caseTypeArray.length; i++) {
      temp1.push(this.caseTypeArray[i].casetype)
    }
    let configuredCasetype = [];
    configuredCasetype = this.excelDataArray.filter(item => temp1.includes(item.casetype))
    let invalidCasetype = [];
    let temp = [];
    invalidCasetype = this.excelDataArray.filter(item => !temp1.includes(item.casetype))
    for (let i = 0; i < invalidCasetype.length; i++) {
      temp.push(invalidCasetype[i].casetype)
    }
    let displayInvalidCasetype = [...new Set(temp)];
    if (displayInvalidCasetype.length > 0) {
      this.repeatedNames = '';
      let str = '';
      for (let i = 0; i < displayInvalidCasetype.length; i++) {
        str += displayInvalidCasetype[i] + ', '
      }
      if (configuredCasetype.length > 0) {
        this.excelDataArray = configuredCasetype;
        this.uploadData();
      }
      this.repeatedNames = str.substring(0, str.length - 2);
      this.invalidCasetypeFlag = true;
    }
    else {
      if (configuredCasetype.length > 0) {
        this.excelDataArray = configuredCasetype;
        this.uploadData();
      }
    }
  }
  //#endregion

  //#region Get data to copy-from-other-entity
  getDataToCopy() {
    this.selectedItems.length == 0
    this.detailsDisplay = false;
    this.gridDisplay = false;
    this.postUpload = false;
    this.uploadClicked = false;
    this.invalidCasetypeFlag = false;
    this.backBtn = true
    let queryVariable;
    if (this.userCardFlag) {
      queryVariable = { casetype: this.copycollectionMethodForm.value.frmcasetype, orgid: this.srcOrgid.toString() };
    }
    else {
      queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.srcOrgid.toString() };
    }
    let query = this.SubMenuCardModel.GetQuery("getalldiagtemplates");
    this.ngxService.start();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (this.srcUserid == '' && (this.searchUserInput == null || this.searchUserInput == '')) {
          if (data.data.submenuData.length > 0) {
            for (let i = 0; i < data.data.submenuData.length; i++) {
              data.data.submenuData[i]['siteid'] = null
            }
            this.sheetHeader = []
            this.sheetHeader = Object.keys(data.data.submenuData[0]);
            this.sheetHeader.unshift('Notes');
            this.sheetHeader.reverse();
            // this.sheetHeader.unshift('Notes');
            this.backBtn = false
            this.manageDataColumn(data.data.submenuData);
          } else {
            this.backBtn = true
            this.excelGridData([]);
          }
        }
        else {
          if (!this.usertoggle) {
            if (data.data.submenuData1.length > 0) {
              this.sheetHeader = []
              this.sheetHeader = Object.keys(data.data.submenuData1[0]);
              this.sheetHeader.unshift('Notes');
              this.sheetHeader.reverse();
              this.backBtn = false
              let userSpecificData = [];
              userSpecificData = data.data.submenuData1.filter(u => u.UserLevelTemplateUserId == Number(this.srcUserid))
              if (userSpecificData.length > 0) {
                this.manageDataColumn(userSpecificData);
              }
              else {
                this.backBtn = true
                this.excelGridData([]);
              }
            }
            else {
              this.backBtn = true
              this.excelGridData([]);
            }
          } else {
            this._snackbar.open("Please enter valid user", "Close");
            return
          }
        }
      }
    }, error => {
      this.ngxService.stop();
      console.log(error);
    })
  }
  //#endregion

  manageDataColumn(data) {
    var i = 1;
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    if (this.userCardFlag) {
      this.excelDataArray.find(d => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'organizationdiagtemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: d.casetype, userleveltemplateuserid: !this.inData ? null : Number(this.inData)
        });
        i++;
      });
    }
    else {
      this.excelDataArray.find(d => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'organizationdiagtemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData.cardtype.toString(), userleveltemplateuserid: !this.inData ? null : Number(this.inData)
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }

  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selectedItems = []
    let selectedrows = flexgrid.rows.filter(r => r.isSelected);

    selectedrows.forEach(r => {
      this.selectedItems.push(r._data)
    })


    let groupsSelected = []
    for (let i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i].isgroup) {
        groupsSelected.push(this.selectedItems[i])
      }
    }
    let data = this.selectedItems.filter(va => !va.isgroup)
   this.selectedItems=[];
   // this.selectedItems = data.filter(val => !groupsSelected.some(va => va.orgdiagtemplateid == val.orgparentid))
   this.selectedItems.push(...data);
    this.selectedItems.push(...groupsSelected)
    for (let i = 0; i < this.selectedItems.length; i++) {
      if(this.selectedItems[i].groupname=='Not Specified')
      {
        this.selectedItems[i].groupname=null;
      }
      this.excelDataArray.push(this.selectedItems[i]);
    }

    if (this.OrgLevelDisplay) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        delete this.excelDataArray[i].isuserleveltemplateenabled;
        delete this.excelDataArray[i].isactive;
      }
    }
    if (this.userCardFlag) {
      this.validateCasetype()
    } else {
      this.uploadData();
    }
  }

  //#region Upload ExcelData to Insert API
  uploadData() {
    let dataArray = [];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          if (this.copyDataClicked) {
            this._snackbar.open('Copying data failed!', 'Close');
          }
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Diagnosis template already exists for this Entity.", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null  ) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            // delete this.excelDataArray[i]["slno"]
            // delete this.excelDataArray[i]["tablename"]
            // delete this.excelDataArray[i]["notes"]
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["casetype"] = !this.userCardFlag ? this.templateData.cardtype.toString() : this.excelDataArray[i]["casetype"];
            this.excelDataArray[i]["ishidden"] = (this.copyDataClicked) ? (this.checkStatus ? true : false) : false
          }
        }
        if (!this.userCardFlag) {
          dataArray = (!this.OrgLevelDisplay) ? this.excelDataArray.map(v => ({ ...v, isuserleveltemplateenabled: true, userid: this.inData })) : this.excelDataArray;
        }
        else {
          dataArray = this.excelDataArray.map(v => ({ ...v, isuserleveltemplateenabled: true, userid: this.inData }))
        }
      }
      let srcDB, destDB
      if (this.copyDataClicked) {
        srcDB = this.srcDeployment
        destDB = this.destDeployment
      } else {
        srcDB = null
        destDB = null
      }

      let jsonArray = {
        "srcDB": srcDB,
        "destDB": destDB,
        "srcOrgid": this.srcOrgid,
        "destOrgid": sessionStorage.getItem('org_id'),
        "dataRows": dataArray
      };
      this.ngxService.start();
      this.VitalHttpServices.CopyDiagnosisTemplate(jsonArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.content.errors) {
          if (result.content.length > 0) {
            result = result.content;
            this.checkStatus = false;
            this.postUpload = true;
            this.postDownload = true;
            this.showDelete = false;
            for (let i = 0; i < jsonArray.dataRows.length; i++) {
              for (let k = 0; k < result.length; k++) {
                if (jsonArray.dataRows[i].diagnosiscode
                  == result[k].diagnosiscode && jsonArray.dataRows[i].diagnosticsummary
                  == result[k].diagnosticsummary
                ) {
                  result[k]["icd9codes1"] = jsonArray.dataRows[i].icd9codes1
                  result[k]["icd9codes2"] = jsonArray.dataRows[i].icd9codes2
                  result[k]["icd9codes3"] = jsonArray.dataRows[i].icd9codes3
                  result[k]["SiteName"] = jsonArray.dataRows[i].sitename
                }
              }
            }
            this.excelDataArray = [];
            this.excelDataArray = this.convertObjKeysToLower(result);
            this.commonService.createActivityTracker('Created', -1, 'Copy from other entity -Diagnosis Templates', 'Audit', dataArray, {});
            this.ngxService.start();
            this.bulkUpload = false;
            for (let i = 0; i < result.length; i++) {
              this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
              if (result[i]["NoteMessage"].match(/Failed/)) {
                this.excelDataArray[i]["Status"] = "Ignored"
              }
              else {
                this.excelDataArray[i]["Status"] = result[i]["Status"]
              }
            }
            this.excelGridData(this.excelDataArray);
            this.ngxService.stop();
            // this._snackbar.open('Data copied successfully', 'Close');
            this.getListUsers();
          }
          else {
            this._snackbar.open('Data copying failed! Please try try again later', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
  }
  //#endregion

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridArray = [];
    this.copyGridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          for (let [key, value] of Object.entries(data[i])) {
            let flag = false;
            if (key.toString().toLowerCase() != 'name' && key.toString().toLowerCase() != 'displayname' && key.toString().toLowerCase() != 'description') {
              if (value != null) {
                if (value.toString().toLowerCase().trim() == 'null') {
                  flag = true;
                }
                primary[key] = value;
              }
            }
            if (key.toLowerCase() == 'isgroup' || key.toLowerCase() == 'ishidden' || key.toLowerCase() == 'reporttoregistry'
              || key.toLowerCase() == 'isuserleveltemplateenabled' || key.toLowerCase() == 'isbillable' || key.toLowerCase() == 'isbenign'
              || key.toLowerCase() == 'abnormal'
              || key.toLowerCase() == 'allowcytotechnologisttosignoutcases'
              || key.toLowerCase() == 'allowcytotechsupervisortosignoutcases'
              || key.toLowerCase() == 'issitenotsurvivedtemplate'
              || key.toLowerCase() == 'issitenotsubmittedtemplate') {
              if (value == 'true' || value == true) {
                value = true
              } else if (value == 'false' || value == false || !value) {
                value = false
              }
              primary[key] = value;
            }
            flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null
            if (key.toString().toLowerCase() == 'name' || key.toString().toLowerCase() == 'displayname' || key.toString().toLowerCase() == 'description') {
              if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
              }
              primary[key] = value;
            }
            if (key.toString().toLowerCase() == 'groupname') {

              if (value == null || value.toString().toLocaleLowerCase().trim() == 'null' || value == '') {
                value = 'Not Specified'
              }
              primary[key] = value;

            }
            if (mandatoryFieldEmpty) {
              value = 'Mandatory field is missing';
              this.excelDataArray[i]["notes"] = value;
              primary['notes'] = value;
            }
          }
          this.gridArray.push(primary);
        }

        if (this.copyDataClicked && this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.excelDataArray = this.gridArray;
        this.copyGridData = new CollectionView(this.gridArray, { groupDescriptions: ['groupname'] })
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  //Fetch screening enabled for organization and casetype
  async getOrgScreeningEnabled(orgid,casetype){
    let query = this.SubMenuCardModel.GetQuery('getorgscreeningenabled');
    let queryVariable = { "orgid": orgid.toString(),casetype : casetype };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let screeningEnabled : Boolean = false; 
    try{
      this.ngxService.start();
      let response = await this.VitalHttpServices.GetData(queryResult, this.srcDeployment).toPromise();
      if(!response.errors)
      screeningEnabled = response.ScreeningData[0]?.isScreeningEnabled;
      else 
      console.error("Failure in fetching orgScreeningEnabledFlag" );
    }
    catch (error) {
      console.error("Failure in fetching orgScreeningEnabledFlag: " + error);
    }
    finally{
      this.ngxService.stop();
      return screeningEnabled;
    }
  }

  removeScreeningTech(userList){
    return userList.filter(va=> va.rolename != 'Screening Technologist');
  }

  //#region grapghqc call to get the data in the select User Dropdown
  async getUsers(org_id) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    const screeningEnabled = await this.getOrgScreeningEnabled(orgid,this.templateData.secondarykeys.casetype)
    let query = this.SubMenuCardModel.GetQuery('getusersdiagnosistemplate');
    let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(res => {
      if (!res.errors) {
        this.userList = res.data.Card;
        this.userList = !screeningEnabled ? this.removeScreeningTech(this.userList) : this.userList; 
        this.userList.sort((a, b) => a.username.toLowerCase() < b.username.toLowerCase() ? -1 : a.username.toLowerCase() > b.username.toLowerCase() ? 1 : 0)
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }
  //#endregion

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }
      , error => {
        console.error(error);
        this.ngxService.stop();
      });
  }

  //#region
  removeGrid() {
    this.gridWidth = -1;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  //#region Filter users
  filterUsers(value: string) {
    this.userNamesList = [];
    this.showOrgInDrop = true;
    this.userNamesList = this.userList //this.userNames
    if (value) {
      if (!value.replace(/\s/g, '').length) {
        this.showOrgInDrop = true;
        return
      }
      else {
        this.userNamesList = this.userNamesList.filter(va => va.username.toString().toLowerCase().includes(value.toString().toLowerCase()))
        this.showOrgInDrop = 'organization templates'.includes(value.toString().toLowerCase()) ? true : false;
      }
    }
  }
  //#endregion

  refreshBackcopy() {
    this.searchCaselist = [];
    this.gridDisplay = true;
    this.copyDataClicked = false;
    this.uploadClicked = false;
    if (this.uploadbutton == false) {
      this.uploadbutton = false;
    }
    else {
      this.uploadbutton = true;
    }
    let validatecardtype = sessionStorage.getItem('contextdata');
    if ((validatecardtype.toLowerCase() == 'users') || (validatecardtype.toLowerCase() == 'pathologist')) {
      this.refreshGrid();
    }
    else {
      this.refreshBackData();
    }
  }

  refreshGrid() {
    this.gridDisplay = true;
    this.copyDataClicked = false;
    this.uploadClicked = false;
    if (this.uploadbutton == false) {
      this.uploadbutton = false;
    }
    else {
      this.uploadbutton = true;
    }
    this.getUpdatedGrid();
  }
  //pop for ishidden check
  ishiddenPopcheck() {
  }

  fnToggleFilter() {
    this.txtInput.nativeElement.focus();
    // this.configname.reset();
    this.frm_testForm.reset();
  }


  //#regin to show notes
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null' || value == 'Diagnosis template already exists for this Entity.' || value.toString().includes('Diagnosis Templates already exist!')
          || value.toString().includes('Invalid') || value.toString().includes('accepts only')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('New template added') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('exist')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }
  //#endregion
}
