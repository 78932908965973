import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCountOfGroupItems]'
})
export class CountOfGroupItemsDirective {
  @Input('appCountOfGroupItems') count : number
  @HostBinding('style.color') color : string = '#9ca9b6'

  constructor(private elementRef : ElementRef, private render : Renderer2) { }

  ngOnChanges(): void {
    this.count > 0 && this.render.setProperty(this.elementRef.nativeElement, 'innerText', `(${this.count} ${this.count > 1 ?'Items' : 'Item'})`)
  }

}
