<div *ngIf="hideGrid">
  <!-- <div class="modal-header admin-model-header m-1 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>VitalDesktop Templates</strong>
    </h3>
  </div> -->
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">VitalDesktop Templates</strong>
    </h3>
  </div>
  <div *ngIf="dataFound">
    <app-templates-listing2 [gridHeader]="listGridheaders" [gridData]="gridData" [groupBy]="groupBy"
      (createEmitter)="createVDTemplate($event)" (copyEmitter)="onBulkOrCopyClick(true)"
      (editEmitter)="editVDTemplate($event)" (deleteEmitter)="deleteVDTemplate($event)"
      (downloadEmitter)="downloadVDTemplate($event)" [context]="'VD Template'" [hideEdit]="editBtn"
      [hideDownload]="hideDownloadBtn" [isEdit]="true" [hideCreate]="hideCreateBtn" [hideDelete]="hideDeleteBtn"
      [hideUpload]="hideUploadBtn" [hideExport]="hideExportBtn" [hideCopy]="hideCopyToOrgBtn"
      [templateData]="templateData" [dragDrop]="false" [hideDragDrop]="true">
    </app-templates-listing2>
  </div>
  <div *ngIf="!dataFound">
    <app-templates-listing2 [gridHeader]="listGridheaders" [gridData]="{_view : []}" [groupBy]="groupBy"
      (createEmitter)="createVDTemplate($event)" (copyEmitter)="onBulkOrCopyClick(true)"
      (editEmitter)="editVDTemplate($event)" (deleteEmitter)="deleteVDTemplate($event)"
      (downloadEmitter)="downloadVDTemplate($event)" [context]="'VD Template'" [hideEdit]="editBtn"
      [hideDownload]="hideDownloadBtn" [isEdit]="true" [hideCreate]="hideCreateBtn" [hideDelete]="hideDeleteBtn"
      [hideUpload]="hideUploadBtn" [hideExport]="hideExportBtn" [hideCopy]="hideCopyToOrgBtn"
      [templateData]="templateData" [dragDrop]="false" [hideDragDrop]="true">
    </app-templates-listing2>
  </div>
</div>

<div *ngIf="showAddTemplatePage" class="modal-content bordernone">
  <!-- <div class="modal-header admin-model-header mt-3 mb-3">
    <h3 *ngIf="addTemplatePage" class="modal-title w-100 admin-model-header-txt">
      <strong>Create Template</strong>
    </h3>
    <h3 *ngIf="editTemplatePage" class="modal-title w-100 admin-model-header-txt">
      <strong>Edit Template</strong>
    </h3>
  </div> -->
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 *ngIf="addTemplatePage" class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">Create Template</strong>
    </h3>
    <h3 *ngIf="editTemplatePage" class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">Edit Template</strong>
    </h3>
  </div>

  <div class="row ml-1 mr-1 mb-1">
    <div class="col-sm-6 m-0 p-0">
    </div>
    <!-- <div class="col-sm-6 pull-right align-center button-wrap m-0 p-0">
      <a (click)="ResetData()" *ngIf="resetFlag" [ngClass]="disableReset() ? 'disabled-reset-btn' : 'reset-btn'">
        Reset
      </a>
      <button mat-raised-button class="ml-2 mr-1 ct-admin-btn-success-outline background-border"
        (click)="goBack()">Return</button>
        <button mat-raised-button class="ml-2 admin-btn" *ngIf="addTemplatePage" [disabled]="(addVDTemplateData.invalid) || disableSaveButton()" (click)="ValidateVDTemplate()">Save</button>
        <button mat-raised-button class="ml-2 admin-btn" *ngIf="editTemplatePage" [disabled]="addVDTemplateData.invalid" (click)="ValidateVDTemplate()">Update</button>
      </div> -->
      <!-- <button mat-raised-button class="ml-2 mr-1 admin-btn" *ngIf="resetFlag" [disabled]="disableReset()" (click)="ResetData()">Reset</button> -->

    <div class="col-sm-6 pull-right align-center button-wrap m-0 p-0">
      <a class="reset-btn mr-3" (click)="ResetData()" *ngIf="resetFlag" [ngClass]="disableReset() ? 'disabled-reset-btn' : 'reset-btn'">Reset</a>
      <button class="mr-3 align-center" (click)="goBack()">Return</button>
      <button class="align-center primaryButtonColor" *ngIf="addTemplatePage" [disabled]="(addVDTemplateData.invalid) || disableSaveButton()" (click)="ValidateVDTemplate()">Save</button>
      <button class="align-center primaryButtonColor" *ngIf="editTemplatePage" [disabled]="addVDTemplateData.invalid" (click)="ValidateVDTemplate()">Update</button>
    </div>

  </div>

  <form [formGroup]="addVDTemplateData" autocomplete="off">

    <hr />
    <div class="col-sm-12 mr-4" class="first">
      <div class="col-sm-2  mr-4 p-0 mb-1 ">
        <mat-form-field class="col-md-7 p-0 m-0  label-font-size mode-property" appearance="outline">
          <mat-label>Product Name</mat-label>
          <input type="text" matInput #productType formControlName="ProductName" [matAutocomplete]="productname">
          <mat-autocomplete #productname="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(productType.value,TemplatetypeOptionsArray.ProductName,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>

      </div>

      <div class="col-sm-2 mr-4 p-0 mb-1">
        <mat-form-field class="col-md-7 p-0 m-0  label-font-size mode-property" appearance="outline">
          <mat-label>Display Name</mat-label>
          <input type="text" matInput #displaytype formControlName="DisplayName" [matAutocomplete]="displayname">
          <mat-autocomplete #displayname="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(displaytype.value,TemplatetypeOptionsArray.DisplayName,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>


      <div class="col-sm-2 mr-4 p-0 mb-1">
        <mat-form-field class="col-md-7 p-0 m-0  label-font-size mode-property" appearance="outline">
          <mat-label>Template Type</mat-label>
          <input type="text" matInput #tempType formControlName="TemplateType" [matAutocomplete]="template">
          <mat-autocomplete #template="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(tempType.value,TemplatetypeOptionsArray.TemplateType,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>

      <div class="col-sm-2 mr-4 p-0 mb-1">
        <mat-form-field class="col-md-7 p-0 m-0  label-font-size mode-property" appearance="outline">
          <mat-label>Template</mat-label>
          <input type="text" matInput formControlName="Template" #templates>
        </mat-form-field>
      </div>


      <div class="col-sm-3 p-0 " *ngIf="addVDTemplateData.controls['TemplateType'].value != 'barcode'">
        <div class="d-flex align-items-center">
          <input #takeInput class="form-control label-font-size pt-2 uploadFile" type="file"
            formControlName="TemplateFile" (change)="onFileSelect($event)" />
          <!-- <button mat-raised-button *ngIf="ifUpload" id="file" (click)="removeTemplate()"
              class="admin-btn ml-1">Reset</button> -->
          <a (click)="removeTemplate()" class="ml-2 reset-btn" *ngIf="ifUpload">
            Reset
          </a>
        </div>
        <div class="mt-2" *ngIf="addVDTemplateData.controls['TemplateType'].value != 'barcode'">
          <span class="label-font-size">
            <mat-icon class="mat-icon-info">info</mat-icon> You can either enter a value for the Template field or
            upload a Template File.
          </span>
        </div>
      </div>




    </div>
    <div class="col-sm-12" class="first">
      <div class="col-sm-2 mr-4 p-0 mb-1 ">
        <mat-form-field class="col-md-7 p-0 m-0 mb-1 label-font-size mode-property" appearance="outline">
          <mat-label>Device</mat-label>
          <input type="text" matInput #context formControlName="Context" [matAutocomplete]="contexts">
          <mat-autocomplete #contexts="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(context.value,TemplatetypeOptionsArray.Context,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>

      <div class="col-sm-2 mr-4 p-0 mb-1">
        <mat-form-field class="col-md-7 p-0 m-0 mb-1 label-font-size mode-property" appearance="outline">
          <mat-label>Kit Type</mat-label>
          <input type="text" matInput #contexttype formControlName="ContextType" [matAutocomplete]="contexttypes">
          <mat-autocomplete #contexttypes="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(contexttype.value,TemplatetypeOptionsArray.ContextType,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>

      <div class="col-sm-2 mr-4 p-0 mb-1 ">
        <mat-form-field class="col-md-7 p-0 m-0 mb-1 label-font-size mode-property" appearance="outline">
          <mat-label>Mode</mat-label>
          <input type="text" matInput #mode formControlName="Mode" [matAutocomplete]="modes">
          <mat-autocomplete #modes="matAutocomplete">
            <mat-option class="mat-opt-align"
              *ngFor="let vdtemplate of filterLists(mode.value,TemplatetypeOptionsArray.Modes,'Item')"
              [value]="vdtemplate.Item">
              {{vdtemplate.Item}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>

      <div class="col-sm-2 mr-4 p-0 mb-1 ">
        <mat-form-field class="col-md-7 p-0 m-0 mb-1 label-font-size mode-property" appearance="outline">
          <mat-label>Location</mat-label>
          <input type="text" matInput formControlName="Account" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option class="mat-opt-align" *ngFor="let vdtemplate of filterAccounts(addVDTemplateData.value.Account)"
              [value]="vdtemplate.Location">
              {{vdtemplate.Location}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>

      </div>

    </div>

  </form>
</div>
<div *ngIf="isCopyOrBulk" class="col-sm-12">
  <!-- <div class="modal-header admin-model-header m-1 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>{{isCopy ? 'Copy' : 'Upload'}} VitalDesktop Templates</strong>
    </h3>
  </div> -->
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">{{isCopy ? 'Copy' : 'Upload'}} VitalDesktop Templates</strong>
    </h3>
  </div>
  <app-manage-vd-template [templateData]="templateData" [isCopy]="isCopy">
  </app-manage-vd-template>
</div>