import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appNotAvailable]'
})
export class NotAvailableDirective implements OnChanges {
  @Input('appNotAvailable') NotAvailable : string
  @HostBinding('style.color') color : string = 'black'

  constructor(private elementRef : ElementRef, private render : Renderer2) { }

  ngOnChanges(): void {
    if((this.NotAvailable ?? "") === ""){
      this.color =  '#7F878F'
      this.render.setProperty(this.elementRef.nativeElement, 'innerText', 'N/A')
    }
    else{
      this.color =  'black'
      this.render.setProperty(this.elementRef.nativeElement, 'innerText', this.NotAvailable)
    }
  }


}
