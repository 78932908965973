import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';


@Component({
  selector: 'customrules-grid',
  templateUrl: './customrulesgrid.component.html',
  styleUrls: ['./customrulesgrid.component.scss']
})
export class CustomRulesGridContainerComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  // gridheader = ['RuleID', 'Rule_Name', 'Speciality', 'Casetype', 'Status','Service_Type','TriggerOn']
  gridheader = ['TriggerOn', 'Service_Type', 'Status', 'Casetype', 'Speciality', 'Rule_Name', 'RuleID']
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  columnHeader: any[];
  constructor(private injectorServiceGridContainer: InjectorService, public _snackbar: MatSnackBar, public ngxService: NgxUiLoaderService, public vitalHttpServices: VitalHttpServices, public VitalMenuComponent: VitalMenuComponent) {
    super(injectorServiceGridContainer);
  }

  AddGridData() {
    let gridarray = []
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
    }
    this.gridData = new CollectionView(gridarray, { pageSize: 10 })
    this.gridwidth = (170 * this.gridheader.length) + 37;
    if (this.gridwidth > 1300)
      this.gridwidth = 1300;
  }

  back() {
    this.hideGrid = true;
    this.showDescription = false;
  }
  public ruleid;
  public ruleValue;

  onClick(grid, e) {
    var hti = grid.hitTest(e);
    var col = hti.col;
    var row = hti.row;
    if (hti.panel === grid.cells) {
      var item = hti.panel.rows[row].dataItem;
      if (item) {
        let val = this.templateData.submenuData.filter(va => va.RuleID === item.RuleID)
        if (val.length > 0) {
          item = val[0]
        }
      }
      this.ruleid = item.RuleID;
      this.description = item.Description;
      this.createdBy = item.CreatedBy;
      this.createdDate = item.CreatedDate;
      this.expiryDate = item.ExpirationDate;
      this.effectiveDate = item.EffectiveDate;
      this.evaluationCriteriaCode = item.EvaluationCriteriaCode;
      this.hideGrid = false;
      this.showDescription = true;
    }
    if (hti.panel === grid.columnHeaders) {
      var header = hti.panel.columns[col].header;
    }
  }

  saveChanges() {
    var json = {
      "Description": this.description,

    }
    var finalQuery = JSON.stringify(json);
    let insertJSON = {

      "inputdata":
      {
        "ColumnName": "RuleID",
        "ColumnValue": this.ruleid.toString()
      },
      "dynamic":
      {
        "Dynamic": finalQuery
      }
    }
    let query = {
      "OperationName": null,
      "Query": this.templateData.mutationQuery,
      "Variables": insertJSON
    }
    this.ngxService.start();
    this.vitalHttpServices.GetData(query).subscribe(res => {
      if (!res.errors) {

        this.ngxService.stop();
        this._snackbar.open("Successfully updated!", "Success");
        let secondaryKeys = {
          "casetype": this.templateData.GroupData.casetype,
          "orgid": this.templateData.GroupData.OrganizationId
        }
        this.VitalMenuComponent.GetSubmenuCardData(this.templateData.cardIdentifier, this.templateData.Keyword, secondaryKeys, "");
        this.VitalMenuComponent.GetMutationQuery(this.templateData.mutationKeyword);
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });


  }

}
