import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ExcelService } from 'src/app/core/services/excel.service';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import ru from 'date-fns/esm/locale/ru/index.js';
import { P } from '@angular/cdk/keycodes';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { cloneDeep } from 'lodash';

export interface Task {
  color: ThemePalette;
}

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-manage-sites',
  templateUrl: './manage-sites.component.html',
  styleUrls: ['./manage-sites.component.scss']
})
export class ManageSitesComponent implements OnInit {
  task: Task = {
    color: 'primary'
  };

  @Input()
  public templateData: any;
  public buttonData: any;
  SubMenuCardModel: any;
  noDataFound: boolean = false;
  gridPage: boolean = false;
  gridheader: any[];
  showPaginationMainGrid: boolean;
  sitesGridData: wjcCore.CollectionView<any>;
  uploadClicked: boolean = false;
  gridWidth: number;
  sheetsToSelect: any[];
  excelDataArray: any[];
  sheetHeader: any[];
  addEditScreen: boolean = false;
  showInvalidColumns: boolean;
  invalidColumns: string = '';
  gridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean;
  showDelete: boolean = false;
  siteFormat: number = 2;
  postUpload: boolean = false;
  entityData: boolean = false;
  approveData: boolean = false;
  postDownload: boolean;
  action: string;
  currentData: any;
  bodySiteDropdown: any;
  typeData: string[] = [];
  mappingSite: string[] = [];
  showSiteOrder: boolean = true;
  allFields = [{}];
  mandatoryFields = [{}];
  warningMessage: string = '';
  workBook: any;
  DeploymentKeys: string[] = [];
  copyDataClicked: boolean;
  destDeployment: string = '';
  organizationList: any;
  orgList: any = [];
  searchResult: any[];
  selectedItems: any[];
  copyFlag: any;
  selectedData: any = [];
  selectedCopyData: any = [];
  noData: boolean = false;
  checkStatus: boolean = false;
  siteOrderRefersh :boolean = false;
  trackerKey :any ='';
  sitetype:any;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('flexgrid') siteGrid: WjFlexGrid;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  bodySiteReadOnly: boolean = false;
  copyData: any;
  selector: Selector;
  srcDeployment: string;
  siteTypeList = [];
  customGridWidth: any = 111;
  workbookData: any;
  openmangesiteorders: boolean = false;
  ruleout: boolean=false;
  public ruleList =[];
  // ruleouts: boolean;
  ruleoutcopy: boolean;
  auditableColumns: any;
  activityEntity: any;  
  editedData: any;
  checkRuleoutStatus: boolean=false;
  copyruleoutflag: boolean=false;
  accountID:any;
  showConfirmPopupFlag:any;
  locations:String;
  caseProfileAlert:boolean=false;
  constructor(
    public _snackbar: MatSnackBar,
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    public ngxService: NgxUiLoaderService,
    private excelService: ExcelService, 
    public VitalMenuComponent: VitalMenuComponent, 
    public activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare
    );
  }
  //The numbers will be assigned to showConfirmPopupFlag to show the message in popup
  PopUpmsgs = (locations) => {
    return {
      1: { message: "There are default stains available for the other sites in the case type. Please configure default stain for this site." },
      2: { message: "There are default stains available for the other sites in the case type for Location "+ locations + " Please configure default stain for this site." },
      3: { message: "Rule outs are configured for the other sites in this case type. Please configure rule outs for this site"},
      4: { message: "This site is mapped to a case profile.Please check on the impacts."}
    }
  }

  sitesForm = this._fb.group({
    frmbodysite: '',
    frmsubsite: '',
    frmsitelocation: '',
    frmtype: '',
    frmsiteorder: '',
    frmactive: true,
    frmmappingsite: '',
    frmsitetype: '',
    frmsitename: '',
    frmatlength: false,
    frmdefault: false,
    frmshortname: ''
  });
  copycollectionMethodForm = this._fb.group({
    frmDepKey: [
      sessionStorage.getItem('deploymentKey').toUpperCase(),
      Validators.required
    ],
    frmOrganization: [''],
    frmOrgID: [''],
    frmcpyasinactive: false,
    frmUser:['']
  });

  ngOnInit(): void {
    this.gridPage = true;
    //#region Get SiteFormat
      this.getSiteFormat();
      this.getAuditableDetails(this.templateData.menuURL);
      this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
      this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
      this.accountID = sessionStorage.getItem('Locationid');
    //#endregion
  }

  OpenMangeSite(){
    this.openmangesiteorders=true;
    this.gridPage=false;
    this.siteOrderRefersh=false;
    this.uploadClicked=false;
    this.addEditScreen=false;
    this.copyDataClicked=false;
  }
  //#region Create a wijmo grid and add values
  showSitesList(templateData: any[]): void {
    let sitedata = [];
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    sitedata = templateData.filter(
      va =>
        !(
          va.BodySite == null &&
          va.SubSite == null &&
          va.SiteLocation == null
        )
    );
    templateData =
      this.siteFormat == 1
        ? sitedata.filter(
          va =>
            va.ParentID != null ||
            (va.Type && va.Type.toString().includes('Sub Site')) ||
            (va.Type && va.Type.toString().includes('Site Location'))
        )
        : sitedata;
    if (this.siteFormat == 3) {
      templateData = templateData.filter(va => va.SubSite != null);
    }
    if((this.siteFormat == 2) && (templateData && templateData.length>0))
    {
      templateData.forEach(ele =>
         {
          if(!ele.ParentID)
          {
            ele.EntityName = 'All Body Sites'
            ele.Orderid = 1
          } else {
            ele.Orderid = 2
          }
        })
    }
    this.gridPage = true;
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.gridheader = [
      'Orderid',
      'EntityName',
      'EntityType',
      'BodySite',
      'SubSite',
      'MappingSite',
      'ParentID',
      'Siteid',
      'Type',
      'SiteLocation',
      'SiteOrder',
      'IsActive',
      'SiteShortname',
      'CreatedBy',
      'CreatedDate',
      'ModifiedBy',
      'ModifiedDate',
      'OrganizationSiteID'
    ];
    let gridarray = [];
    let primary = {};
    this.ngxService.start();
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            if (this.siteFormat == 1 && key == 'Type') {
              value = !value ? 'Body Site' : value;
            }
            else {
              if (value == null) {
                value = 'Not Specified';
              }
            }
            if(key =='SiteOrder')
            {
              value = (value == 'Not Specified') ? '' : value;
            }
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    this.noDataFound = gridarray.length > 0 ? false : true;
    if (this.siteFormat == 3) {
    gridarray
      .sort((a, b) => (a.SubSite < b.SubSite ? -1 : 1));
      this.sitesGridData = new CollectionView(gridarray, { pageSize: 10 });
    }
    else if (this.siteFormat == 2) {
      gridarray.sort((a,b)=> (a.Orderid - b.Orderid || a.EntityName.localeCompare(b.EntityName)
      || a.BodySite.localeCompare(b.BodySite)
      ));
      this.sitesGridData = new CollectionView(gridarray, { groupDescriptions: ['EntityName'], pageSize: 10 });
    }
    else if (this.siteFormat == 1) {
      this.sitesGridData = new CollectionView(gridarray, { groupDescriptions: ['EntityType'], pageSize: 10 });
    }
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    this.ngxService.stop();
  }
  //#endregion

  getSiteFormat() {
    if(!this.siteOrderRefersh){
    let site = '';
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery('casetypesiteformat');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices
      .GetData(queryResult)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              this.siteFormat = data.data.submenuData[0].SiteFormat ? data.data.submenuData[0].SiteFormat : 2;
              if((this.siteFormat == 2) && (this.templateData.submenuData && this.templateData.submenuData.length>0))
              {
                this.templateData.submenuData.forEach(ele =>
                   {
                    if(!ele.ParentID)
                    {
                      ele.EntityName = 'All Body Sites'
                      ele.Orderid = 1
                    } else {
                      ele.Orderid = 2
                    }
                  })
              }
              this.templateData.submenuData &&
                this.templateData.submenuData.length > 0
                ? this.showSitesList(this.templateData.submenuData)
                : (this.noDataFound = true);
            }
          }
        },
        error => {
          this.templateData.submenuData &&
            this.templateData.submenuData.length > 0
            ? this.showSitesList(this.templateData.submenuData)
            : (this.noDataFound = true);
          console.error(error);
        }
      );
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
      }
      else{
        this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
      }
  }

  initGrid(flexgrid) {
    if(flexgrid)
    {
      this.selectedItems = [];
      this.copyruleoutflag=false;
      // flexgrid.collapseGroupsToLevel(0);
      setTimeout(function () {
        if (this.flexgrid) this.flexgrid.refresh(true);
      });
      if (this.copyDataClicked && !this.postUpload) {
        this.selector = new Selector(flexgrid, {
          itemChecked: () => {
            var va = flexgrid.rows.filter(r => r.isSelected);
            this.selectedItems = va;
            let tempFlag = this.selectedItems.some(item => item._data.notes.includes("Copy Rule out"));
            this.copyruleoutflag = tempFlag;           
          }
        });
      }
      const tt = new wjcCore.Tooltip();
      flexgrid.formatItem.addHandler((s, e) => {
        if (e.panel.cellType !== wjcGrid.CellType.Cell) {
          return;
        }
        if (s.getCellData(e.row, e.col) != null)
          tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      });

      // if (this.siteFormat == 2 && this.gridPage) {
      //   /*********row coloring********/
      //   flexgrid.itemFormatter = (panel, r, c, cell) => {
      //     if (panel.cellType === wjcGrid.CellType.Cell) {
      //       if ((!panel.rows[r].dataItem.SubSite) ||
      //         (panel.rows[r].dataItem.SubSite && panel.rows[r].dataItem.SubSite.toString().toLowerCase() == 'not specified')) {
      //         cell.style.backgroundColor = '#ccccff'
      //       } else {
      //         cell.style.backgroundColor = null;
      //       }
      //     }
      //   }
      // }
    }
  }

  //#region Open upload screen with prerequisite data fetch
  loadUploadScreen() {
    this.uploadClicked = true;
    this.gridPage = false;
    this.copyDataClicked = false;
    // this.getTemplates()
    this.gridWidth = 0;
    let queryVariable = {
      tablename: 'Organizationsites',
      identity: 'false',
      nullable: 'false'
    };
    let query = this.SubMenuCardModel.GetQuery('getTableColumns');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          if (data) {
            let siteFormatFields = [];
            siteFormatFields =
              this.siteFormat == 1
                ? ['BodySite', 'SubSite', 'SiteLocation','BodySiteOrder','SubSiteOrder']
                : this.siteFormat == 2
                  ? ['BodySite', 'SubSite','BodySiteOrder','SubSiteOrder']
                  : ['BodySite'];
            for (let item of siteFormatFields) {
              this.allFields[0][item] = '';
              this.mandatoryFields[0][item] = '';
            }
            if (
              this.templateData.secondarykeys.casetype.match(
                /GI Histology_PLUS/i
              )
            ) {
              this.mandatoryFields[0]['MappingSite'] = '';
              delete this.allFields[0]['SiteOrder']
            }

            for (let item of data.data.allFields) {
              this.allFields[0][item.Column] = '';
            }
            if(this.siteFormat != 3)
            {
              delete this.allFields[0]['SiteOrder'];
            }
            delete this.allFields[0]['OrganizationID'];
            delete this.allFields[0]['CaseType'];
            delete this.allFields[0]['SiteID'];
            delete this.allFields[0]['SiteName'];
            delete this.allFields[0]['CreatedBy'];
            delete this.allFields[0]['CreatedDate'];
            delete this.allFields[0]['ModifiedDate'];
            delete this.allFields[0]['ModifiedBy'];
            delete this.allFields[0]['OrganizationSiteGUID'];
            delete this.allFields[0]['ParentID'];
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  //#endregion

  //#region Refreshing the main grid
  refreshGrid(list?) {
    this.excelDataArray = [];
    this.uploadClicked = false;
    this.showInvalidColumns = false;
    this.addEditScreen = false;
    this.gridDisplay = false;
    this.copyDataClicked = false;
    this.gridPage = true;
    this.postUpload = false;
    this.bodySiteReadOnly = false;
    this.typeData = [];
    this.mappingSite = [];
    this.checkStatus = false;
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.srcDeployment,
    })

    this.fetchSiteList(list);
  }
  //#endregion

  selectedCopyDataOrganization(event, data) {
    this.postUpload = false;
    this.entityData = false;
    this.copyDataClicked = true;
      if (event.source.selected) {
      this.copycollectionMethodForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      });
    }
    this.fetchSiteList('', true);
  }

  fetchSiteList(context: string, copyEnabled?) {
    // this.templateData.submenuData = [];
    let query;
    let queryVariable = !copyEnabled
      ? {
        casetype: this.templateData.secondarykeys.casetype.toString(),
        orgid: this.templateData.secondarykeys.OrganizationId.toString()
      }
      : {
        casetype: this.templateData.secondarykeys.casetype.toString(),
        orgid: this.copycollectionMethodForm.value.frmOrgID.toString()
      };
    if (copyEnabled) {
      query = this.SubMenuCardModel.GetQuery('casetypesiteslistcopy');
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.vitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(
        data => {
          if (!data.errors) {
            if (!copyEnabled) {
              this.templateData.submenuData = [];
              this.templateData.submenuData = data.data.submenuData;
              this.bodySiteDropdown =
                this.templateData.submenuData &&
                  this.templateData.submenuData.length > 0
                  ? [
                    ...new Map(
                      this.templateData.submenuData.map(item => [
                        item['BodySite'],
                        item
                      ])
                    ).values()
                  ]
                  : [];
              this.bodySiteDropdown = this.bodySiteDropdown.filter(function (va) {
                return va.BodySite != null;
              });
              context == 'list'
                ? this.showSitesList(this.templateData.submenuData)
                : null;
            } else {
              let sitedata = [];
              sitedata = data.data.submenuData.filter(
                va =>
                  !(
                    va.bodysite == null &&
                    va.subsite == null &&
                    va.sitelocation == null
                  )
              );
              data.data.submenuData =
                this.siteFormat == 1
                  ? sitedata.filter(
                    va =>
                      va.parentid != null ||
                      (va.type && va.type.toString().includes('Sub Site')) ||
                      (va.type && va.type.toString().includes('Site Location'))
                  )
                  : sitedata;               
              if (this.siteFormat == 3) {
                data.data.submenuData = data.data.submenuData.filter(va => va.subsite != null);
              }
              this.excelDataArray = data.data.submenuData;
              data.data.submenuData.sort((a, b) => (a.bodysite && a.bodysite.toString().toLowerCase() < b.bodysite && a.bodysite.toString().toLowerCase() ? -1 : 1))
                .sort((a, b) => (a.siteorder < b.siteorder ? -1 : 1));
              this.showCopyList(data.data.submenuData);
            }
           
          }
          this.ngxService.stop();
        },
        error => {
          this.ngxService.stop();
          console.error(error);
        }
      );
      
    } else {
      query = this.SubMenuCardModel.GetQuery('casetypesiteslist');
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.vitalHttpServices.GetData(queryResult).subscribe(
        data => {
          this.ngxService.stop();
          if (!data.errors) {
            if (!copyEnabled) {
              this.templateData.submenuData = data.data.submenuData;
              this.bodySiteDropdown =
                this.templateData.submenuData &&
                  this.templateData.submenuData.length > 0
                  ? [
                    ...new Map(
                      this.templateData.submenuData.map(item => [
                        item['BodySite'],
                        item
                      ])
                    ).values()
                  ]
                  : [];
              this.bodySiteDropdown = this.bodySiteDropdown.filter(function (va) {
                return va.BodySite != null;
              });
              context == 'list'
                ? this.showSitesList(this.templateData.submenuData)
                : null;
            } else {
              let sitedata = [];
              sitedata = data.data.submenuData.filter(va =>!(va.bodysite == null &&
                    va.subsite == null &&
                    va.sitelocation == null));
              data.data.submenuData =
                this.siteFormat == 1
                  ? sitedata.filter(
                    va =>
                      va.parentid != null ||
                      (va.type && va.type.toString().includes('Sub Site')) ||
                      (va.type && va.type.toString().includes('Site Location'))
                  )
                  : sitedata;
              if (this.siteFormat == 3) {
                data.data.submenuData = data.data.submenuData.filter(va => va.subsite != null);
              }
              this.excelDataArray = data.data.submenuData;
              data.data.submenuData.sort((a, b) => (a.bodysite && a.bodysite.toString().toLowerCase() < b.bodysite && a.bodysite.toString().toLowerCase() ? -1 : 1))
                .sort((a, b) => (a.siteorder < b.siteorder ? -1 : 1));
              this.showCopyList(data.data.submenuData);
            }
          }
        },
        error => {
          console.error(error);
          this.ngxService.stop();
        }
      );
    }
  }

  showCopyList(copyData) {
    if(this.siteFormat == 1)
    {this.sheetHeader = [
      "status",
      "notes",
      "sitetype",
      "sitename",
      "siteorder",
      "isactive"
    ];}
    else {
      this.sheetHeader = [
        "status",
        "notes",
        "sitetype",
        "sitename",
        "bodysite",
        "subsite",
        "sitelocation",
        "mappingsite",
        "type",
        "siteorder",
        "isactive"
      ];
    }
    this.sheetHeader.reverse();
    this.manageDataColumn(copyData);
  }

  //#region Method to handle file drop or selection
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (
      splitarry[1].toUpperCase() != 'XLSX' &&
      splitarry[1].toUpperCase() != 'XLS'
    ) {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    } else {
      reader.onload = event => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sample data');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(
              va => va.toLowerCase() == 'organizationsites'
            );
            this.sheetsToSelect.length == 1? this.convertToJson(validSheet[0]) : this.openModal();
          } else {
            this.convertToJson(this.sheetsToSelect[0]);
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = '';
  }
  //#endregion

  //#region convert excel data to json data
  convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open('Please upload valid data', 'Close');
      tempExcelArr = [];
      return;
    }

    tempExcelArr = tempExcelArr.filter(obj => {
      for (let key in obj) {
          if ((obj[key] !== null) && (obj[key] != 'null') && (obj[key] !== '')) {
            return true; // If any non-null value is found, keep the object
          }
      }
      return false; // If all values are null, discard the object
    });
    let primary = {};
    let tempArray = [];
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (key.toString().match(/isactive/i)) {
            switch(value){
              case 0: value = false; break;
              case 1: value = true; break;
              case true: value = true; break;
              case false: value = false; break;
              case '': value = true; break;
              case null: value = true; break;
              case 'active': value = true; break;
              case 'inactive': value = false; break;
              case 'yes': value = true; break;
              case 'no': value = false; break;
              case 'Yes': value = true; break;
              case 'No': value = false; break;
              default: value = true; break;
            }
          } else {
            if (!value) {
              value = '';
            } else if (value.toString().toLowerCase() == 'null') {
              value = null;
            }
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary);
      primary = {};
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].unshift('Notes');
    temp[0].unshift('Status');
    temp[0].reverse();
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, '');
      }
    }
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      if (this.siteFormat == 3) {
        Object.assign(d, {
          ParentSite:
            this.templateData.cardtype.split(' ').length > 1
              ? this.templateData.cardtype.split(' ')[0]
              : this.templateData.cardtype
        });
      }
      Object.assign(d, {
        slno: i,
        notes: '',
        siteFormat: this.siteFormat,
        tablename: 'organizationsites',
        organizationid: this.templateData.secondarykeys.OrganizationId.toString(),
        casetype: this.templateData.cardtype.toString(),
        accountid:this.accountID
      });
      i++;
    });
    this.validateExcel(this.excelDataArray, this.siteFormat);
  }
  //#endregion

  //#region Convert data keys to lowercase
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      delete inputArr[i]['orderid']
      var key,
        keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        let value = '';
        value =
          typeof inputArr[i][key] == 'string'
            ? inputArr[i][key].replace(/\s+/g, ' ')
            : inputArr[i][key];
        newobj[key.replace(/\s/g, '').toLowerCase()] = value;
      }
      array.push(newobj);
    }
    return array;
  }
  //#endregion

  //#region Sending the data for validation
  validateExcel(excelArr, siteFormat) {
    this.ngxService.start();
    let tempArray = [];
    let dataArray = [];
    tempArray = this.convertObjKeysToLower(excelArr);
    dataArray = this.getFormatBasedData(tempArray);
    if(this.copyDataClicked)
    {
      dataArray = dataArray.map((va) => ({...va,isCopy:this.copyDataClicked,SrcOrgid:this.copycollectionMethodForm.controls.frmOrgID.value}))
    }
    this.vitalHttpServices.ValidateSiteData(dataArray).subscribe(
      result => {
        if (!result.errors) {
          if (result.content.length > 0) {
            this.ngxService.stop();
            if (result.content[0]['InvalidColumns'] &&
              result.content[0]['InvalidColumns'].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result.content[0].InvalidColumns;
            }
          
            dataArray.filter(e => {
              result.content.filter(r => {
                if (r.slNo === e.slno) {
                  e.notes = r.noteMessage;
                  e.copyRuleOut=false;
                }
                
                
                if(e.notes.toString().toLowerCase().includes('rule out'))
                {                
                  e.copyRuleOut = true;
                } 
              });
            });
            this.AddGridData(dataArray, siteFormat, 1);
          }
           else {
            if(this.copyDataClicked)
            {
              this.noDataFound = true;
            }
            this.gridDisplay = false;
            if (!this.copyDataClicked) {
              this._snackbar.open('Please provide valid data', 'Failed');
            }
          }
        }
      },
      error => {
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
        console.error(error);
      }
    );
    this.ngxService.stop();
  }
  //#endregion

  getFormatBasedData(inputArray)
  {
    let dataArray = []
    if(this.siteFormat == 1)
    {
      dataArray = inputArray.map(va => ({ ...va,
        bodysite: (va.sitetype && va.sitetype.toString().toLowerCase().trim() == 'body site' ? va.sitename : null),
        subsite: (va.sitetype && va.sitetype.toString().toLowerCase().trim() == 'sub site' ? va.sitename : null),
        sitelocation: (va.sitetype && va.sitetype.toString().toLowerCase().trim() == 'site location' ? va.sitename : null),
        siteorder: ((va.default && va.default.toString().toLowerCase().trim() == 'yes') ? -1 : va.siteorder),
        siteshortname: !va.siteshortname ? (va.sitename ? va.sitename.toString().substring(0, 100) : null) : va.siteshortname
      }));
    }
    else if(this.siteFormat == 2)
    {
      dataArray = [...inputArray]
      dataArray = inputArray.map(va => ({ ...va,
        siteorder: ((va.default && va.default.toString().toLowerCase().trim() == 'yes') ? -1 : va.siteorder),
        type: ((va.supportatlength && va.supportatlength.toString().toLowerCase().trim() == 'yes') ? 'At Length' : null),
        subsitetype: ((va.supportatlength && va.supportatlength.toString().toLowerCase().trim() == 'yes') ? 'At Length' : null),
        subsitedescription:
          ((va.supportatlength && va.supportatlength.toString().toLowerCase().trim() == 'yes') &&
            va.subsite &&
            !va.subsite.match(/at length/i))
            ? va.subsite + ' at {{length}}'
            : va.subsite == ''
              ? null
              : va.subsite,
        bodysitedescription: va.bodysite,
        siteshortname: !va.siteshortname ? ((!va.subsite && va.bodysite) ? va.bodysite.toString().substring(0, 100) : ((va.subsite && va.bodysite) ? va.subsite.toString().substring(0, 100) : null)) : va.siteshortname
      }));
    } else {
      dataArray = [...inputArray]
      // dataArray = inputArray.map(va => ({ ...va,
      // siteshortname: !va.siteshortname ? (va.bodysite ? va.bodysite.substring(0, 100) : null) : va.siteshortname }));
      if(this.copyDataClicked)
      {
        dataArray = inputArray.map(va => ({ ...va,
          parentsite: (va.bodysite) ? va.bodysite : null,
          bodysite: (va.subsite) ? va.subsite : null,
          siteshortname: !va.siteshortname ? (va.subsite ? va.subsite.toString().substring(0, 100) : null) : va.siteshortname
        }))
      } else{
        dataArray = inputArray.map(va => ({ ...va,
          siteorder: ((va.default && va.default.toString().toLowerCase().trim() == 'yes') ? -1 : va.siteorder),
          siteshortname: !va.siteshortname ? (va.bodysite ? va.bodysite.toString().substring(0, 100) : null) : va.siteshortname
        }))
      }
      dataArray = this.getParentSite(dataArray);
    }
    return dataArray
  }

  //#region Function to add data to Bulk upload & Copy from other entity grid
  AddGridData(data, siteFormat, hitCount) {
    this.ngxService.stop();  
    this.copyFlag = this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    if (data) {
      if (data.length > 0) {
        if(this.copyDataClicked)
        {
          /*Get sorted data in grid*/
          let sitedata = [];
          sitedata = data.filter(
            va =>
              !(
                va.bodysite == null &&
                va.subsite == null &&
                va.sitelocation == null
              )
          );

          if(this.siteFormat == 1 && sitedata.length > 0)
          {
            sitedata = sitedata.filter(
              va =>
                va.parentid != null ||
                (va.sitetype && va.sitetype.toString().includes('Sub Site')) ||
                (va.sitetype && va.sitetype.toString().includes('Site Location'))
            ) .map(ele => ({...ele, orderid: ele.sitetype && ((ele.sitetype.toString().includes('Site Location')) ? 1 :
            (ele.sitetype.toString().includes('Sub Site')) ? 2 : 3)}))
            .sort((a,b)=> (a.orderid - b.orderid || a.sitename.localeCompare(b.sitename)
            ))
          }
          data =
            (this.siteFormat == 1 && sitedata.length > 0)
              ? sitedata.filter(
                va =>
                  va.parentid != null ||
                  (va.sitetype && va.sitetype.toString().includes('Sub Site')) ||
                  (va.sitetype && va.sitetype.toString().includes('Site Location'))
              ) : sitedata;
          if (this.siteFormat == 3 && data.length > 0) {
            data = data.filter(va => va.subsite != null);
          }
          if((this.siteFormat == 2) && (data && data.length > 0))
          {
            data = data.filter(va => va.bodysite && va.subsite);
          }
        /*End data*/
        }
        for (let i = 0; i < data.length; i++) {
          let primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (
              !key
                .toString()
                .toLowerCase()
                .match(/empty/i) &&
              hitCount == 1
            ) {
              if (siteFormat == 1) {
                if (!this.copyDataClicked &&
                  data[i]['sitetype'] == '' &&
                  data[i]['sitename'] == '')
                {
                  primary['notes'] = 'Mandatory field(s) missing!';
                }
                if(this.copyDataClicked && (data[i]['entitytype'] == '' &&
                data[i]['entityname'] == ''))
                {
                  primary['notes'] = 'Mandatory field(s) missing!';
                }
              } else if (siteFormat == 2) {
                if (data[i]['bodysite'] == '') {
                  primary['notes'] = 'Mandatory field(s) missing!';
                }
              } else if (siteFormat == 3) {
                if (data[i]['bodysite'] == '') {
                  primary['notes'] = 'Mandatory field(s) missing!';
                }
              }
              if (
                this.templateData.secondarykeys.casetype.match(
                  /gi histology_plus/i
                ) && !data[i]['subsite'] &&
                !data[i]['mappingsite']
              ) {
                if (
                  !data[i]['notes'].match(/Mandatory field(s) missing!/i) &&
                  !data[i]['notes'].match(/Null values exist!/i)
                ) {
                  primary['notes'] = 'Mapping site missing!';
                }
              }
              else if (
                this.templateData.secondarykeys.casetype.match(
                  /gi histology_plus/i
                ) && data[i]['subsite'] && !data[i]['bodysite'] &&
                !data[i]['mappingsite']
              ) {
                if (
                  !data[i]['notes'].match(/Mandatory field(s) missing!/i)
                ) {
                  primary['notes'] = 'Mandatory field(s) missing!';
                }
              }
              else if (
                this.templateData.secondarykeys.casetype.match(
                  /gi histology_plus/i
                ) && data[i]['subsite'] && data[i]['bodysite'] &&
                !data[i]['mappingsite']
              ) {
                if (
                  !data[i]['notes'].match(/Mandatory field(s) missing!/i) &&
                  !data[i]['notes'].match(/Null values exist!/i)
                ) {
                  primary['notes'] = 'Mapping site missing!';
                }
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = hitCount == 1 ? true : false;

        if (this.copyDataClicked && this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.noDataFound = this.excelDataArray.length == 0 ? true : false
        if(this.siteFormat == 1)
        {
          this.gridData = this.copyDataClicked ? new CollectionView(gridArray, { groupDescriptions: ['sitetype'] }) : new CollectionView(gridArray);
        }
        else if(this.siteFormat == 2)
        {
          this.gridData = this.copyDataClicked ? new CollectionView(gridArray, { groupDescriptions: ['sitename'] }) : new CollectionView(gridArray);
        }
        else
        {
          this.gridData = new CollectionView(gridArray);
        }
        if(this.copyDataClicked)
        {
          this.noDataFound = (gridArray.length > 0) ? false: true;
          this.gridDisplay = !this.noDataFound;
        }
        this.gridWidth = 170 * this.sheetHeader.length + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      } else {
        this._snackbar.open('Please upload valid data','Close');
        return
      }
    }
  }
  //#endregion

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/already exists/i) ||
      this.excelDataArray[i]['notes'].toString().match(/Mandatory field is missing/i) ||
      this.excelDataArray[i]['notes'].toString().match(/Mapping site missing/i))) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(
      va => va.slno != row.dataItem.slno
    );
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
    this.commonService.auditDetails('SiteId','BodySite',[row.dataItem], [{}], 'Delete',this.templateData,this.auditableColumns);
  }
  //#endregion

  //#region  Function to cancel the data selection
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.noDataFound = false;
    this.gridDisplay = false;
    if(this.copyDataClicked)
    {
      this.copycollectionMethodForm.reset();
      this.copycollectionMethodForm.patchValue({
        frmDepKey: this.destDeployment
      });
    }
    this.searchResult = [];
    this.postUpload = false;
    this.showDelete = false;
    this.typeData = [];
    this.mappingSite = [];
  }
  //#endregion

  backSelect() {
    this.ngxService.start();
    this.gridDisplay = false;
    this.copyDataClicked = true;
    this.copycollectionMethodForm.patchValue({
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false
    });
    this.gridWidth = 0;
    this.ngxService.stop();
  }

  gridRemove() {
    this.gridDisplay = false;
    this.noData = false;
    this.noDataFound = false;
    this.searchResult = [];
    this.gridWidth = 0;
    this.copycollectionMethodForm.patchValue({
      frmOrganization: ''
    });
    this.sitesGridData = new CollectionView([]);
  }

  //#region Function to upload Sites Data
  uploadSitesData() {
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]['notes'] == '') {
          this._snackbar.open(
            'Data upload failed! Please check the data for datatype mismatch.',
            'Close'
          );
          return;
        } else if (
          !this.excelDataArray[i]['notes'].toString().match(/approval/i) &&
          !this.excelDataArray[i]['notes']
            .toString()
            .match(/already exists/i) &&
          !this.excelDataArray[i]['notes'].toString().match(/exists/i) &&
          !this.excelDataArray[i]['notes'].toString().match(/exist/i) &&
          !this.excelDataArray[i]['notes'].toString().match(/stain for this site/i) &&
          !this.excelDataArray[i]['notes'].toString().match(/for other location/i) &&
          !this.excelDataArray[i]['notes'].toString().match(/"Copy Rule out" checkbox/i)
        ) {
          errorcount++;
        }
      }
      if (errorcount > 0) {
        this._snackbar.open(
          'Some of the data in the rows have issues. Please fix them and try again',
          'Close'
        );
        return;
      }
      if (this.showInvalidColumns) {
        this._snackbar.open(
          'Invalid columns found! Please upload valid data.',
          'Close'
        );
        return;
      } else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (key != 'isactive' && (value == 'null' || value == '' || value == null)) {
              this.excelDataArray[i][key] = null;
            } else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.addDescAndType(this.excelDataArray[i]);
            this.excelDataArray[i]['createdby'] =
              sessionStorage.getItem('Userid') == null ||
                sessionStorage.getItem('Userid') == ''
                ? -100
                : sessionStorage.getItem('Userid');
            if (this.excelDataArray[i]['isactive']) {
              this.excelDataArray[i]['isactive'] =
                this.excelDataArray[i]['isactive'] == null
                  ? true
                  : this.excelDataArray[i]['isactive'] == 0 ||
                    this.excelDataArray[i]['isactive']
                      .toString()
                      .toLowerCase() == 'false'
                    ? false
                    : true;
            }else if(this.excelDataArray[i]['isactive'] === undefined){
              this.excelDataArray[i]['isactive']=true
            }
          }
        }
      }
      let dataArray = [], tempArray = [];
      tempArray = this.convertObjKeysToLower(this.excelDataArray);
      dataArray = this.getFormatBasedData(tempArray);
      let sendjson = dataArray.filter(
        va =>
          va.notes.toString().toLowerCase() !=
          'site configuration already exists!'
      );
      sendjson.length > 0
        ? this.addSites(sendjson, true, '')
        : this._snackbar.open('Site(s) already exist!', 'Close');
    }
  }
  //#endregion

  //#region
  addDescAndType(data: object) {
    data['bodysitedescription'] =
      data['bodysite'] && data['bodysite'].toString().match(/at length/i)
        ? data['bodysite'].toString().replace('at length', 'at {{length}}')
        : data['bodysite'];
    data['bodysitetype'] =
      data['bodysitedescription'] &&
        data['bodysitedescription'].toString().match(/at {{length}}/i)
        ? 'at length'
        : null;
    data['subsitedescription'] =
      data['subsite'] && data['subsite'].toString().match(/at length/i)
        ? data['subsite'].toString().replace('at length', 'at {{length}}')
        : data['subsite'];
    data['subsitetype'] =
      data['subsitedescription'] &&
        data['subsitedescription'].toString().match(/at {{length}}/i)
        ? 'at length'
        : null;
    data['sitelocationdescription'] =
      data['sitelocation'] &&
        data['sitelocation'].toString().match(/at length/i)
        ? data['sitelocation'].toString().replace('at length', 'at {{length}}')
        : data['sitelocation'];
    data['sitelocationtype'] =
      data['sitelocationdescription'] &&
        data['sitelocationdescription'].toString().match(/at {{length}}/i)
        ? 'at length'
        : null;
  }
  //#endregion

  getParentSite(dataArray)
  {
    /*
    ========== Note: SiteFormat 3: While creating bodysite, we have to take casetype's
    ========== first word as bodysite. Before doing so, we've to check
    ========== if the previous bodysites are different from Casetype's first word.
    ========== If yes, then instead of first word of casetype, we have to consider the existing bodysite name.
    */
    var casetypeFirstWord = this.templateData.secondarykeys.casetype.toString().split(" ");
      let bodySiteExists
      if (casetypeFirstWord && this.templateData.submenuData && this.templateData.submenuData.length > 0)
      {
        bodySiteExists = this.templateData.submenuData.find(data =>
          data.BodySite && data.BodySite.toString().toLowerCase().trim() != casetypeFirstWord[0].toString().toLowerCase().trim())
      }
      if (!bodySiteExists) {
        dataArray = dataArray.map(va => ({...va, parentsite: casetypeFirstWord[0]}));
      } else {
        dataArray = dataArray.map(va => ({...va, parentsite: bodySiteExists["BodySite"]}));
      }
      return dataArray;
  }

  //#region
  addSites(dataArray: object[], bulkUpload: boolean = false, context?: string) {
    this.ngxService.start();
    if (this.siteFormat == 3) {
      dataArray = this.getParentSite(dataArray)
    }
    let ignoredData = [];
    for(let i = 0; i < dataArray.length; i++)
    {
      if(dataArray[i]['notes'].includes("Copy Rule out")){
        if (this.checkRuleoutStatus) {
          dataArray[i]['copyRuleOut'] = true
        } else {
          dataArray[i]['copyRuleOut'] = false
        }
      }
    }
    ignoredData = (dataArray.length>0) ? (dataArray.filter(va => va['notes'] == 'Site configuration already exists!' || va['notes']?.toString().includes('There is already a subsite configured as Default') )) : [];
    dataArray = (dataArray.length>0) ? (dataArray.filter(va => !(ignoredData.some(x => x['notes'] == va['notes'] || x['notes'] == va['notes'])))) : [];
    
    ignoredData = (ignoredData.length>0) ? (ignoredData.map(va => ({...va, Status:'Ignored'}))) : [];
      if(dataArray.length > 0) {
        if(this.copyDataClicked)
        {
          dataArray = dataArray.map((va) => ({...va,srcDeployment: this.srcDeployment,isCopy:this.copyDataClicked,SrcOrgid:this.copycollectionMethodForm.controls.frmOrgID.value}))
        }
        this.vitalHttpServices.bulkUploadSites(dataArray).subscribe(
        result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.content.length > 0) {
              this.checkRuleoutStatus=false;
              this.copyruleoutflag=false;
              if(!this.addEditScreen)
              {
                this.checkStatus = false;
                this.postUpload = true;
                this.postDownload = true;
              }
              result = [...result.content, ...ignoredData]
              let uploadresult = this.convertObjKeysToLower(result);
              for (let i = 0; i < this.excelDataArray.length; i++) {
                for (let j = 0; j < uploadresult.length; j++) {
                  this.sitetype = this.excelDataArray[i]['sitelocation'] ? 'site location' : this.excelDataArray[i]['bodysite'] ? 'body site' : 'sub site';
                  if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                    if(result[j]['DefaultStainAlert']==1 && this.copyDataClicked){
                      if(this.sitetype=="body site" && (this.siteFormat==1||this.siteFormat==3)){
                        this.excelDataArray[i]['notes'] = (result[j]['Notes'].toString().match(/Site copied/i)||result[j]['Notes'].toString().match(/Site and rule out copied/i)) ? result[j]['Notes'].toString().replace('Site copied successfully', 'Sites added, no impact on default stains') : result[j]['Notes'];
                        this.excelDataArray[i]['status'] = result[j]['Status']
                      }else if(this.sitetype=="sub site" && (this.siteFormat==2)){
                        this.excelDataArray[i]['notes'] = (result[j]['Notes'].toString().match(/Site copied/i)||result[j]['Notes'].toString().match(/Site and rule out copied/i)) ? result[j]['Notes'].toString().replace('Site copied successfully', 'Sites added, no impact on default stains') : result[j]['Notes'];
                        this.excelDataArray[i]['status'] = result[j]['Status'] 
                      }else{
                        this.excelDataArray[i]['notes'] = result[j]['Notes'];
                        this.excelDataArray[i]['status'] = result[j]['Status'];
                      }
                    }else if (result[j]['DefaultStainAlert']==1) {
                      if(this.sitetype.toString().toLowerCase()=="body site" && (this.siteFormat==1||this.siteFormat==3)){
                      this.excelDataArray[i]['notes'] = result[j]['Notes'].toString().match(/Body Site/i) ? result[j]['Notes'].toString().replace('Body Site added', 'Sites added, no impact on default stains') : result[j]['Notes'];
                      this.excelDataArray[i]['status'] = result[j]['Status']
                      }else if(this.sitetype.toString().toLowerCase()=="sub site" && this.siteFormat==2){
                        this.excelDataArray[i]['notes'] = result[j]['Notes'].toString().match(/Sub Site/i) ? result[j]['Notes'].toString().replace('Body Site added', 'Sites added, no impact on default stains') : result[j]['Notes'];
                        this.excelDataArray[i]['status'] = result[j]['Status']
                      }
                      else{
                        this.excelDataArray[i]['notes'] = result[j]['Notes'];
                        this.excelDataArray[i]['status'] = result[j]['Status'];
                      }
                    } 
                    else{
                      this.excelDataArray[i]['notes'] = result[j]['Notes'];
                      this.excelDataArray[i]['status'] = result[j]['Status'];
                    }
                  }
                  if (this.excelDataArray[i]['notes'].toString() .match(/already exists/i)) {
                    this.excelDataArray[i]['status'] = 'Ignored';
                  }
                }
              }

              bulkUpload
                ? this.AddGridData(this.excelDataArray, this.siteFormat, 2)
                : null;
              let dataExistsCount = 0;
              for (let i = 0; i < result.length; i++) {
                if (
                  Object.values(result).every(function (item: any) {
                    return item.Status == 'Ignored' ? true : false;
                  })
                ) {
                  dataExistsCount++;
                }
              }
              if (dataExistsCount == result.length) {
                if (bulkUpload) {
                  this._snackbar.open('Site(s) already exist!', 'Close');
                } else {
                  if (this.copyDataClicked) {
                    this.AddGridData(this.excelDataArray, this.siteFormat, 2);
                    return;
                  }
                  this._snackbar.open('Site already exists!', 'Close');
                  this.ruleout=false;
                  return;
                }
              } else {
                bulkUpload
                  ? this._snackbar.open('Data uploaded successfully', 'Close')
                  : this._snackbar.open('Site added successfully', 'Close');
                  bulkUpload ? '':dataArray[0]['isactive']=this.commonService.getStatus(dataArray[0]['isactive']);
                  bulkUpload ? '':dataArray[0]['siteorder']=result[0]['SiteOrder'];
                  if(!this.copyDataClicked) {
                    let key = result[0]['Notes'] == "Site Location added" ? 'sitelocation':result[0]['Notes'] == "Sub Site added" ? 'subsite' : 'bodysite';
                    this.commonService.auditDetails('SiteId',key,bulkUpload ? [] : result,bulkUpload ? this.excelDataArray : dataArray, bulkUpload ? 'Upload' :'Create',this.templateData,this.auditableColumns);
                    this.excelDataArray = [...result];
                    this.checkRuleoutandDefaultStain();
                    if(result[0]['DefaultStainAlert']==1&& bulkUpload==false){
                      this._snackbar.open("Sites configured successfully. No impact on default stains", 'Close');
                    }
                  }
              }
              if (context != null) {
                this.typeData = [];
                if (!this.templateData.secondarykeys.casetype.match(/gi histology_plus/i)) {
                  this.mappingSite = [];
                }
                this.sitesForm.reset();
                this.sitesForm.patchValue({
                  frmbodysite: '',
                  frmsubsite: '',
                  frmsitelocation: '',
                  frmtype: '',
                  frmsiteorder: '',
                  frmactive: true,
                  frmmappingsite: '',
                  frmatlength:false,
                  frmdefault: false,
                  frmshortname: ''
                });
                Object.keys(this.sitesForm.controls).forEach(key => {
                  this.sitesForm.controls[key].setErrors(null);
                });
                this.bodySiteReadOnly = false;
                this.fetchSiteList(context);
              } else if (!this.copyDataClicked) {
                this.getInputData('', this.templateData.submenuData);
              }
              if (this.copyDataClicked) {
                this.AddGridData(this.excelDataArray, this.siteFormat, 2);
                this.commonService.auditDetails('SiteId','bodysite',[],this.excelDataArray, 'Copy',this.templateData,this.auditableColumns);
                this.addEditScreen = false;
                this.excelDataArray = [...result];
                this.checkRuleoutandDefaultStain();
              }
            } else {
              bulkUpload
                ? this._snackbar.open(
                  'Data upload failed! Please check the data for type mismatch.',
                  'Close'
                )
                : this._snackbar.open(
                  'Site addition failed! Please try again.',
                  'Close'
                );
            }
          }
        },
        error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error);
        }
      );
    } else {
      this.ngxService.stop();

      if(!this.addEditScreen)
      {
        this.checkStatus = false;
        this.postUpload = true;
        this.postDownload = true;
      }
      for (let i = 0; i < this.excelDataArray.length; i++) {
        for (let j = 0; j < ignoredData.length; j++) {
          if (this.excelDataArray[i]['slno'] == ignoredData[j]['SlNo']) {
            this.excelDataArray[i]['notes'] = ignoredData[j]['Notes'];
            this.excelDataArray[i]['status'] = ignoredData[j]['Status'];
          }
          if (
            this.excelDataArray[i]['notes']
              .toString()
              .match(/already exists/i)
          ) {
            this.excelDataArray[i]['status'] = 'Ignored';
          }
        }
      }

      bulkUpload
        ? this.AddGridData(this.excelDataArray, this.siteFormat, 2)
        : null;
      let dataExistsCount = 0;
      for (let i = 0; i < ignoredData.length; i++) {
        if (
          Object.values(ignoredData).every(function (item: any) {
            return item.Status == 'Ignored' ? true : false;
          })
        ) {
          dataExistsCount++;
        }
      }
      if (dataExistsCount == ignoredData.length) {
        if (bulkUpload) {
          this._snackbar.open('Site(s) already exist!', 'Close');
        } else {
          if (this.copyDataClicked) {
            this.AddGridData(this.excelDataArray, this.siteFormat, 2);
            return;
          }
          this._snackbar.open('Site already exists!', 'Close');
          return;
        }
      } else {
        bulkUpload
          ? this._snackbar.open('Data uploaded successfully', 'Close')
          : this._snackbar.open('Site added successfully', 'Close');
          // this.commonService.auditDetails('SiteId','bodysite',result, dataArray, bulkUpload ? 'Upload' :'Create',this.templateData,this.auditableColumns);
          this.checkRuleoutandDefaultStain();

      }
      if (context != null) {
        this.typeData = [];
        if (!this.templateData.secondarykeys.casetype.match(/gi histology_plus/i)) {
          this.mappingSite = [];
        }
        this.sitesForm.reset();
        this.sitesForm.patchValue({
          frmbodysite: '',
          frmsubsite: '',
          frmsitelocation: '',
          frmtype: '',
          frmsiteorder: '',
          frmactive: true,
          frmmappingsite: '',
          frmatlength:false,
          frmdefault: false,
          frmshortname: ''
        });
        Object.keys(this.sitesForm.controls).forEach(key => {
          this.sitesForm.controls[key].setErrors(null);
        });
        this.bodySiteReadOnly = false;
        this.fetchSiteList(context);
      } else if (!this.copyDataClicked) {
        this.getInputData('', this.templateData.submenuData);
      }
      if (this.copyDataClicked) {
        this.AddGridData(this.excelDataArray, this.siteFormat, 2);
        this.addEditScreen = false;
      }
    }
  }
  //#endregion

  getInputData(action, data) {
    this.bodySiteDropdown =
      this.templateData.submenuData && this.templateData.submenuData.length > 0
        ? [
          ...new Map(
            this.templateData.submenuData.map(item => [
              item['BodySite'],
              item
            ])
          ).values()
        ]
        : [];

    this.bodySiteDropdown = this.bodySiteDropdown.filter(function (va) {
      return va.BodySite != null;
    });

    this.typeData.length == 0 ? this.typeData.push('at length') : null;
    if (
      this.templateData.secondarykeys.casetype.match(/gi histology_plus/i) &&
      this.mappingSite.length == 0
    ) {
      this.mappingSite.push('Upper Gastrointestinal');
      this.mappingSite.push('Lower Gastrointestinal');
    }
    if (action.toLowerCase() == 'edit') {
      this.action = 'Edit Site';
      this.currentData = data;
      this.activityEntity.entityId = this.currentData.Siteid;
      this.activityService.setActivitySession(this.activityEntity);
      if (this.siteFormat == 1) {
        data.Type = data.Type.toString().toLowerCase() == 'body site' ? 'Not Specified' : data.Type
        if (
          !data.BodySite.match(/not specified/i) &&
          !data.SubSite.match(/not specified/i)
        ) {
          this.sitesForm.patchValue({
            frmbodysite: !data.SubSite.match(/not specified/i)
              ? data.SubSite
              : null,
            frmsubsite: null,
            frmsitelocation: null,
            frmtype: !data.Type.match(/not specified/i) ? data.Type : null,
            frmsiteorder: !data.SiteOrder.toString().match(/not specified/i)
              ? (data.SiteOrder == -1 ? null : data.SiteOrder)
              : null,
            frmactive: data.IsActive == 'Active' ? true : false,
            frmmappingsite: !data.MappingSite.match(/not specified/i)
              ? data.MappingSite
              : null,
            frmsitetype: !data.EntityType.match(/not specified/i) ? data.Type : null,
            frmsitename: !data.EntityName.match(/not specified/i) ? data.Type : null,
            frmdefault: (data.SiteOrder && data.SiteOrder == -1) ? true : false,
            frmshortname: !data.SiteShortname.match(/not specified/i)
            ? data.SiteShortname
            : null
          });
          this.getEditedData(this.sitesForm.value, this.currentData, this.siteFormat);
        } else {
          this.sitesForm.patchValue({
            frmbodysite: !data.BodySite.match(/not specified/i)
              ? data.BodySite
              : null,
            frmsubsite: !data.SubSite.match(/not specified/i)
              ? data.SubSite
              : null,
            frmsitelocation: !data.SiteLocation.match(/not specified/i)
              ? data.SiteLocation
              : null,
            frmtype: !data.Type.match(/not specified/i) ? data.Type : null,
            frmsiteorder: !data.SiteOrder.toString().match(/not specified/i)
              ? (data.SiteOrder == -1 ? null : data.SiteOrder)
              : null,
            frmactive: data.IsActive == 'Active' ? true : false,
            frmmappingsite: !data.MappingSite.match(/not specified/i)
              ? data.MappingSite
              : null,
            frmsitetype: !data.EntityType.match(/not specified/i) ? data.EntityType : null,
            frmsitename: !data.EntityName.match(/not specified/i) ? data.EntityName : null,
            frmatlength: !data.Type.match(/not specified/i) ? true : false,
            frmdefault: (data.SiteOrder && data.SiteOrder == -1) ? true : false,
            frmshortname: !data.SiteShortname.match(/not specified/i)
            ? data.SiteShortname
            : null
          });
          this.getEditedData(this.sitesForm.value, this.currentData, this.siteFormat);
        }
      } else if (this.siteFormat == 2) {
          this.sitesForm.patchValue({
          frmbodysite: !data.BodySite.match(/not specified/i)
            ? data.BodySite
            : null,
          frmsubsite: !data.SubSite.match(/not specified/i)
            ? data.SubSite
            : null,
          frmsitelocation: null,
          frmtype: !data.Type.match(/not specified/i) ? data.Type : null,
          frmsiteorder: !data.SiteOrder.toString().match(/not specified/i)
            ? (data.SiteOrder == -1 ? null : data.SiteOrder)
            : null,
          frmactive: data.IsActive == 'Active' ? true : false,
          frmmappingsite: !data.MappingSite.match(/not specified/i)
            ? data.MappingSite
            : null,
          frmsitetype: !data.SubSite.match(/not specified/i) ? 'Sub Site' : 'Body Site',
          frmsitename: !data.SubSite.match(/not specified/i) ? data.SubSite : data.BodySite,
          frmatlength: !data.Type.match(/not specified/i) ? true : false,
          frmdefault: (data.SiteOrder && data.SiteOrder == -1) ? true : false,
          frmshortname: !data.SiteShortname.match(/not specified/i)
            ? data.SiteShortname
            : null
        });
        this.sitesForm.value.frmsubsite ? (this.bodySiteReadOnly = true) : null;
        this.showSiteOrder = this.bodySiteReadOnly == false ? false : true;
        this.getEditedData(this.sitesForm.value, this.currentData, this.siteFormat);
      } else if (this.siteFormat == 3) {
        this.sitesForm.patchValue({
          frmbodysite: !data.SubSite.match(/not specified/i)
            ? data.SubSite
            : null,
          frmsubsite: null,
          frmsitelocation: null,
          frmtype: !data.Type.match(/not specified/i) ? data.Type : null,
          frmsiteorder: !data.SiteOrder.toString().match(/not specified/i)
            ? (data.SiteOrder == -1 ? null : data.SiteOrder)
            : null,
          frmactive: data.IsActive == 'Active' ? true : false,
          frmmappingsite: !data.MappingSite.match(/not specified/i)
            ? data.MappingSite
            : null,
          frmdefault: (data.SiteOrder && data.SiteOrder == -1) ? true : false,
          frmshortname: !data.SiteShortname.match(/not specified/i)
            ? data.SiteShortname
            : null
        });
        this.getEditedData(this.sitesForm.value, this.currentData, this.siteFormat);
      }
    } else {
      this.sitesForm.reset();
      if (this.siteFormat == 1) {
        this.sitesForm.patchValue({
          frmbodysite: '',
          frmsubsite: '',
          frmsitelocation: '',
          frmtype: '',
          frmsiteorder: '',
          frmactive: true,
          frmmappingsite: '',
          frmdefault: false,
          frmshortname:''
        });
        this.warningMessage =
          'Body site,sub site and site location are configured independently for this case type.';
      } else if (this.siteFormat == 2) {
        this.sitesForm.patchValue({
          frmbodysite: '',
          frmsubsite: '',
          frmsitelocation: null,
          frmtype: '',
          frmsiteorder: '',
          frmactive: true,
          frmmappingsite: '',
          frmatlength: false,
          frmdefault: false,
          frmshortname: ''
        });
      } else if (this.siteFormat == 3) {
        this.sitesForm.patchValue({
          frmbodysite: '',
          frmsubsite: null,
          frmsitelocation: null,
          frmtype: '',
          frmsiteorder: '',
          frmactive: true,
          frmmappingsite: '',
          frmdefault: false,
          frmshortname: ''
        });
        this.warningMessage =
          'Body site will be displayed as Sub site in the grid.';
      }
      this.action = 'Create Site';
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }

  openEditScreen(grid, event) {
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    this.getInputData('edit', selectedrowData);
  }

  filterBodySites(value: string) {
    if (value == '') {
      return this.bodySiteDropdown;
    }
    let bodysites: any = this.bodySiteDropdown;
    return bodysites.filter(va =>
      va.BodySite.toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }

  async saveSite(action: string, data: any) {
    const {
      frmbodysite,
      frmsubsite,
      frmsitelocation,
      frmtype,
      frmsiteorder,
      frmactive,
      frmmappingsite,
      frmsitetype,
      frmsitename,
      frmdefault,
      frmshortname
    } = data;
    if (this.siteFormat != 3) {
      if (!frmsitetype || !frmsitename) {
        this._snackbar.open('Please enter all the required fields!', 'Close');
        this.ruleout=false;
        return;
      }
      if (frmsitename && !frmsitename.trim() ) {
        this._snackbar.open('Please enter valid data!', 'Close');
        return;
      }
      if (frmsitetype && frmsitetype == 'Body Site' &&
        (frmmappingsite == '' || !frmmappingsite) &&
        this.templateData.secondarykeys.casetype.match(/GI Histology_PLUS/i)
      ) {
        this._snackbar.open('Please select a mapping site!', 'Close');
        return;
      }
    } else if (this.siteFormat == 3 && frmbodysite == '') {
      this._snackbar.open('Please enter the required field!', 'Close');
      return;
    }
    else if (this.siteFormat == 3 && frmbodysite && !frmbodysite.trim()) {
      this._snackbar.open('Please enter the required field!', 'Close');
      return;
    }
    let dataJson = {};
    let siteorder = frmsiteorder;
    if (
      (frmbodysite && frmbodysite.toString().toLowerCase() == 'select') ||
      (frmsubsite && frmsubsite.toString().toLowerCase() == 'select') ||
      (frmsitelocation && frmsitelocation.toString().toLowerCase() == 'select')
    ) {
      siteorder = !frmsiteorder ? null : -1;
    } else if (
      (frmbodysite && frmbodysite.toString().toLowerCase() == 'others') ||
      (frmsubsite && frmsubsite.toString().toLowerCase() == 'others') ||
      (frmsitelocation && frmsitelocation.toString().toLowerCase() == 'others')
    ) {
      siteorder = !frmsiteorder ? null : 800;
    } else if (
      (frmbodysite && frmbodysite.toString().toLowerCase() == 'unspecified') ||
      (frmsubsite && frmsubsite.toString().toLowerCase() == 'unspecified') ||
      (frmsitelocation &&
        frmsitelocation.toString().toLowerCase() == 'unspecified')
    ) {
      siteorder = !frmsiteorder ? null : 801;
    }
    if (this.siteFormat == 1) {
      dataJson = {
        modifiedby: !sessionStorage.getItem('Userid')
          ? -100
          : sessionStorage.getItem('Userid'),
        createdby: !sessionStorage.getItem('Userid')
          ? -100
          : sessionStorage.getItem('Userid'),
        sitelocationtype: 'Site Location',
        sitelocationdescription: frmsitelocation ? frmsitelocation : null,
        subsitetype: 'Sub Site',
        subsitedescription: frmsubsite ? frmsubsite : null,
        bodysitetype: !frmtype ? null : frmtype,
        bodysitedescription:
          frmtype &&
            frmtype.match(/at length/i) &&
            frmbodysite != '' &&
            !frmbodysite.match(/at length/i)
            ? frmbodysite + ' at {{length}}'
            : frmbodysite == ''
              ? null
              : frmbodysite,
        type: !frmtype ? null : frmtype,
        // "bodysitedescription": !frmbodysite ? null : frmbodysite,
        isactive: frmactive == true ? true : false,
        sitelocation: (frmsitetype == 'Site Location') ? frmsitename : null, //!frmsitelocation ? null : frmsitelocation,
        subsite: (frmsitetype == 'Sub Site') ? frmsitename : null,
        bodysite: (frmsitetype == 'Body Site') ? frmsitename : null,
        slno: '',
        notes: '',
        siteformat: this.siteFormat,
        tablename: 'organizationsites',
        organizationid: this.templateData.secondarykeys.OrganizationId.toString(),
        casetype: this.templateData.secondarykeys.casetype.toString(),
        parentsite:
          this.templateData.cardtype.split(' ').length > 1
            ? this.templateData.cardtype.split(' ')[0]
            : this.templateData.cardtype,
        orgsiteid:
          this.currentData && this.currentData.OrganizationSiteID
            ? this.currentData.OrganizationSiteID
            : null,
        bodysiteid:
          this.currentData && frmbodysite ? this.currentData.Siteid : null,
        subsiteid:
          this.currentData && frmsubsite ? this.currentData.Siteid : null,
        sitelocationid:
          this.currentData && frmsitelocation ? this.currentData.Siteid : null,
        parentsiteid:
          this.currentData &&
            this.currentData.ParentID &&
            !this.currentData.ParentID.toString().match(/not specified/i)
            ? this.currentData.ParentID
            : null,
        mappingsite: frmmappingsite ? frmmappingsite : null,
        siteorder: (this.sitesForm.value.frmdefault) ? -1 : (siteorder == '' ? null : siteorder),
        siteshortname : !frmshortname ? null : frmshortname,
        accountid:this.accountID
      };
    } else if (this.siteFormat == 2) {
      this.sitesForm.patchValue({
        frmtype: this.sitesForm.value.frmatlength ? 'at length' : null
      })
      dataJson = {
        modifiedby: !sessionStorage.getItem('Userid')
          ? -100
          : Number(sessionStorage.getItem('Userid')),
        createdby: !sessionStorage.getItem('Userid')
          ? -100
          : Number(sessionStorage.getItem('Userid')),
        subsitetype: !this.sitesForm.value.frmtype ? null : this.sitesForm.value.frmtype,
        subsitedescription:
          (this.sitesForm.value.frmtype &&
            this.sitesForm.value.frmtype.match(/at length/i) &&
            frmsubsite &&
            !frmsubsite.match(/at length/i))
            ? frmsubsite + ' at {{length}}'
            : frmsubsite == ''
              ? null
              : frmsubsite,
        bodysitetype: null,
        type: !this.sitesForm.value.frmtype ? null : this.sitesForm.value.frmtype,
        bodysitedescription: !frmbodysite ? null : frmbodysite,
        isactive: frmactive == true ? true : false,
        subsite: frmsitetype == 'Sub Site' ? frmsitename : null,
        bodysite: frmsitetype == 'Body Site' ? frmsitename : frmbodysite,
        slno: null,
        notes: '',
        siteformat: Number(this.siteFormat),
        tablename: 'organizationsites',
        organizationid: Number(this.templateData.secondarykeys.OrganizationId),
        casetype: this.templateData.secondarykeys.casetype.toString(),
        orgsiteid:
          this.currentData && this.currentData.OrganizationSiteID
            ? Number(this.currentData.OrganizationSiteID)
            : null,
        bodysiteid: this.currentData
          ? this.currentData.ParentID &&
            !this.currentData.ParentID.toString().match(/not specified/i)
            ? Number(this.currentData.ParentID)
            : (this.currentData.Siteid ? Number(this.currentData.Siteid) : null)
          : null,
        subsiteid:
          this.currentData && frmsubsite ? Number(this.currentData.Siteid) : null,
        mappingsite: frmmappingsite ? frmmappingsite : null,
        siteorder: (this.sitesForm.value.frmdefault) ? -1 : (siteorder == '' ? null : Number(siteorder)),
        siteshortname : !frmshortname ? null : frmshortname,
        accountid: this.accountID ? Number(this.accountID) : null
      };
    } else if (this.siteFormat == 3) {
      dataJson = {
        modifiedby: !sessionStorage.getItem('Userid')
          ? -100
          : sessionStorage.getItem('Userid'),
        createdby: !sessionStorage.getItem('Userid')
          ? -100
          : sessionStorage.getItem('Userid'),
        bodysitetype: !frmtype ? null : frmtype,
        type: !frmtype ? null : frmtype,
        bodysitedescription: !frmbodysite ? null : frmbodysite,
        isactive: frmactive == true ? true : false,
        bodysite: !frmbodysite ? null : frmbodysite,
        slno: '',
        notes: '',
        siteformat: this.siteFormat,
        tablename: 'organizationsites',
        organizationid: this.templateData.secondarykeys.OrganizationId.toString(),
        casetype: this.templateData.secondarykeys.casetype.toString(),
        parentsite:
          this.templateData.cardtype.split(' ').length > 1
            ? this.templateData.cardtype.split(' ')[0]
            : this.templateData.cardtype,
        orgsiteid:
          this.currentData && this.currentData.OrganizationSiteID
            ? this.currentData.OrganizationSiteID
            : null,
        bodysiteid:
          frmbodysite && this.currentData ? this.currentData.Siteid : null,
        parentsiteid:
          this.currentData && this.currentData.ParentID
            ? this.currentData.ParentID
            : null,
        mappingsite: frmmappingsite ? frmmappingsite : null,
        siteorder: (this.sitesForm.value.frmdefault) ? -1 : (siteorder == '' ? null : siteorder),
        siteshortname : !frmshortname ? null : frmshortname,
        accountid:this.accountID
      };
    }
    if (this.action.match(/edit/i)) {
      if(this.templateData.submenuData && this.templateData.submenuData.length > 0)
      {
        this.checkRepeated(dataJson) ? this.editSite([dataJson]) : null;
      } else {
        this.editSite([dataJson]);
      }
    } else {
      this.excelDataArray = [];
      let context = action != 'new' ? 'list' : '';
      if(this.siteFormat == 2)
      {
          if(this.sitesForm.value.frmsitetype == 'Sub Site'
          && (!dataJson['bodysite'] || (dataJson['bodysite'] && !dataJson['bodysite'].toString().trim())))
          {
            this._snackbar.open('Please enter all the required fields!','Close');
            this.ruleout=false;
            return
          }
      }
      if(this.templateData.submenuData && this.templateData.submenuData.length > 0)
      {
        if(this.siteFormat == 2)
        {
          if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Body Site'
          && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && !(obj.ParentID)))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Sub Site'
          && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID) && (obj.BodySite == dataJson['bodysite'])))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          }
        } else if(this.siteFormat == 1) {
          if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Body Site'
          && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID) && (obj.EntityType == this.sitesForm.value.frmsitetype)))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Sub Site'
          && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (!obj.ParentID) && (!obj.BodySite)))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Site Location'
          && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.EntityType == this.sitesForm.value.frmsitetype)))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          }
        } else {
          if(this.sitesForm.value.frmdefault && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID)))
          {
            this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
            return
          }
        }
      }
      this.excelDataArray = cloneDeep([dataJson]);
      var i =0;
      this.excelDataArray.find(d => {
        Object.assign(d, {
          slno: Number(i),
          notes: '',
          tablename: 'organizationsites',
          organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData.cardtype.toString(),
          siteformat: this.siteFormat,
          accountid:this.accountID
        });
        i++;
      });
      this.excelDataArray = this.excelDataArray.map(({parentsiteid,bodysiteid,orgsiteid,bodysitetype,...rest}) => ({...rest}))
      
      await this.vitalHttpServices.ValidateSiteData(this.excelDataArray).toPromise().then(
      result => {
        if (!result.errors) {
          if (result.content.length > 0) {

            if(result.content.some(va => va['noteMessage'] == 'Site configuration already exists!' || va['notes']?.toString().includes('There is already a subsite configured as Default')))
            {
                this._snackbar.open("Site already exists","Close");
                return
            } else {
                this.addSites([dataJson], false, context);
            }
          }
        }
      })
    }
  }

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = '';
    if (allFieldsCheck) {
      filename = 'Sites_'+ 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    } else {
      filename = 'Sites_'+'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    }
    var ws = XLSX.utils.json_to_sheet(
      allFieldsCheck ? this.allFields : this.mandatoryFields
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Sites');
    XLSX.writeFile(wb, filename);
  }

  checkRepeated(data) {
    let message = '';
    if(this.siteFormat == 2)
    {
      if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Body Site'
      && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && !(obj.ParentID) && !(obj.Siteid == data.bodysiteid)))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Sub Site'
      && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID) && (obj.BodySite == data['bodysite']) && !(obj.Siteid == data.subsiteid)))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      }
    } else if(this.siteFormat == 1)
    {
      if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Body Site'
      && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID) && (obj['EntityType'] == this.sitesForm.value.frmsitetype) && !(obj.Siteid == data.bodysiteid)))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Sub Site'
      && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && !(obj.ParentID) && (obj['EntityType'] == this.sitesForm.value.frmsitetype)
      && (obj.BodySite == data['bodysite']) && !(obj.Siteid == data.subsiteid)))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      } else if(this.sitesForm.value.frmdefault && this.sitesForm.value.frmsitetype == 'Site Location'
      && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj['EntityType'] == this.sitesForm.value.frmsitetype)))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      }
    } else {
      if(this.sitesForm.value.frmdefault && this.templateData.submenuData.some(obj => (obj.SiteOrder == -1) && (obj.ParentID && obj.SubSite) &&
      (obj.SubSite != data['bodysite'] && obj.Siteid != data['bodysiteid'])))
      {
        this._snackbar.open('There is already a site configured as Default! Please change that to set this site as default.','Close');
        return
      }
    }
    if(this.isStatusChanged(data))
    {
      if (data.siteformat == 1) {
          data.bodysite &&
            this.templateData.submenuData.some(
              va =>
                va.BodySite?.toString().toLowerCase() == data.bodysite.toString().toLowerCase() &&
                va.SubSite == null &&
                va.SiteLocation == null &&
                va.Siteid != data.bodysiteid
                && va.IsActive == 'Active'
            )
            ? (message = 'Body site already exists.')
            : null;
          data.subsite &&
            this.templateData.submenuData.some(
              va =>
                va.SubSite?.toString().toLowerCase() == data.subsite.toString().toLowerCase() &&
                va.BodySite == null &&
                va.SiteLocation == null &&
                // va.Type == null &&
                // va.Siteid != data.subsiteid
                va.IsActive == "Active" &&
                va.OrganizationSiteID != data.orgsiteid 
            )
            ? (message == '' ? (message = 'Sub site already exists.') : (message = 'Body site and sub site already exist.')) : null;
          data.sitelocation &&
            this.templateData.submenuData.some(
              va =>
                va.SiteLocation?.toString().toLowerCase() == data.sitelocation.toString().toLowerCase() &&
                va.BodySite == null &&
                va.SubSite == null &&
                va.Siteid != data.sitelocationid
                && va.IsActive == 'Active'
            )
            ? message == 'Body site already exists.'
              ? (message = 'Body site and site location already exists.')
              : message == 'Sub site already exists.'
                ? (message = 'Sub site and Site location already exists.')
                : message != ''
                  ? (message = 'Body site, Sub site and Site location already exists.')
                  : (message = 'Site Location already exists.')
            : null;
      } else if (data.siteformat == 2) {
          data.bodysite &&
            this.templateData.submenuData.some(
              va =>
                va.BodySite?.toString().toLowerCase() == data.bodysite.toString().toLowerCase() &&
                va.Siteid != data.bodysiteid &&
                !va.ParentID
                && va.IsActive == 'Active'
            )
            ? (message = 'Body site already exists.')
            : null;
          data.subsite &&
            this.templateData.submenuData.some(
              va =>
                va.SubSite?.toString().toLowerCase() == data.subsite.toString().toLowerCase() &&
                va.BodySite == data.bodysite &&
                va.Siteid != data.subsiteid
                && va.IsActive == 'Active'
            )
            ? (message = 'Sub site already exists.')
            : null;
      } else if (data.siteformat == 3) {
        data.bodysite && this.templateData.submenuData.some(
          va => va.SubSite?.toString().toLowerCase() == data.bodysite.toString().toLowerCase() && va.Siteid != data.bodysiteid && va.IsActive == 'Active'
        )
          ? (message = 'Body site already exists.')
          : null;
      }
    }

    if (message != '') {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '230px',
        data: {
          header: 'Alert',
          message: '',
          alert: message,
          continue: 'OK',
          cancel: 'dontshow'
        }
      });
      return false;
    }
    return true;
  }

  async editSite(siteData) {
    this.ngxService.start();
    await this.vitalHttpServices
      .updateSites(siteData)
      .toPromise()
      .then(response => {
        if (response.content[0].Notes != '') {
          this._snackbar.open('Site updated successfully', 'Close');
          siteData[0]['isactive']=this.commonService.getStatus(siteData[0]['isactive']);
          siteData[0]['siteorder']=response.content[0]['SiteOrder'];
          this.commonService.auditDetails('Siteid',this.trackerKey,[this.editedData], siteData,'Edit',this.templateData,this.auditableColumns);
          if(response.content[0].DefaultStainAlert==1){
            this._snackbar.open("Sites updated successfully. No impact on default stains", 'Close');
          }
          if(this.siteFormat == 1 || this.siteFormat==3) {
            if(siteData.some(va => va.isactive && !va.type) && response.content[0].result == true) {
              this.ruleout=true;
              this.showConfirmPopupFlag=5;
          }
          else {
            this.ruleout=false;
          }
          if(siteData[0]["isactive"]=="Active" && siteData[0]["type"]==null && 
          (response.content[0].StainAlert==1 ||response.content[0].StainAlert==2 )){
            this.showConfirmPopupFlag=response.content[0].StainAlert;
            this.locations=response.content[0].Locations;
          }
          if(siteData[0]["isactive"].toLowerCase()=="inactive" && siteData[0]["type"]==null && 
          (response.content[0].CaseProfileAlert==1))
            {
              this.caseProfileAlert=true;
              this.showConfirmPopupFlag=5;
            }else {
              this.caseProfileAlert=false;
            }
          
        }
          if(this.siteFormat == 2) {
          if(siteData.some(va => va.isactive && va.subsite) && response.content[0].result == true) {
            this.ruleout=true;
            this.showConfirmPopupFlag=5;
          } else {
            this.ruleout=false;
          }
          if(siteData[0]["isactive"].toLowerCase()=="inactive" && siteData[0]["subsiteid"]!=null 
           && (response.content[0].CaseProfileAlert==1))
          {
              this.caseProfileAlert=true;
              this.showConfirmPopupFlag=5;
          }else {
            this.caseProfileAlert=false;
          }

           if(siteData[0]["isactive"]=="Active" && siteData[0]["subsiteid"]!=null  && 
            (response.content[0].StainAlert==1 ||response.content[0].StainAlert==2 )){
            this.showConfirmPopupFlag=response.content[0].StainAlert;
            this.locations=response.content[0].Locations;
          }
         
        }
          // this.currentData.Procedure_Name = objArr[0].procedurename
          // this.currentData.Panel = objArr[0].testname
          // this.currentData.testid = objArr[0].testid
          // this.currentData.CPT_Code = objArr[0].cptcodes
          // this.currentData.Sequence = objArr[0].sequence
          // this.currentData.Procedure_Sequence = objArr[0].tsequence
          // this.currentData.Procedure_Name = objArr[0].procedurename
          // this.currentData.Status = objArr[0].isactive == 1 ? 'Active' : 'Inactive'
          this.typeData = [];
          this.mappingSite = [];
          this.bodySiteReadOnly = false;
          this.fetchSiteList('list');
        } else {
          this._snackbar.open('Site update failed!', 'Close');
        }
      })
      .catch(error =>
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        )
      );
    this.ngxService.stop();
  }

  //#region
  //Export grid data as excel
  ExportExcel(flex) {
    let tempHeader = flex.columns[0]._hdr;
    let filename = 'Sites_' + this.templateData.secondarykeys.OrganizationId.toString() +
    '('+this.templateData.secondarykeys.casetype+')'+
    '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
      flex.beginUpdate();
    view.pageSize = 0;
    flex.columns.forEach(element => {
      if(element._hdr == 'Action')
      {
        element._hdr = ''
      }
    });
    let wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true,
        includeColumns: this.siteFormat == 2 ? function(column)
        {
            return (column.binding !== 'EntityName' && column.binding !== 'SiteShortname')
        } : function(column)
        {
            return (column.binding !== 'SiteShortname')
        }
      },
      null,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
    flex.columns[0]._hdr = tempHeader
    wb.sheets[0].name = 'Sites';
    wb.saveAsync(filename);
  }
  //#endregion

  

  ExportResult(flex) {
      const view = flex.collectionView;
      const grid = flex;
      let oldPgSize = view.pageSize, oldPgIndex = view.pageIndex;
      grid.beginUpdate();
      view.pageSize = 0;
      var wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(grid,
        {
          includeCellStyles: false,
          includeColumnHeaders: true
        },
        null,
        saved => {
          view.pageSize = oldPgSize;
          view.sheetName = 'Sites'
          view.moveToPage(oldPgIndex);
          grid.endUpdate();
        },
        null
      );
      wb.sheets[0].name = 'Sites';
      wb.saveAsync("Sites_" + this.templateData.secondarykeys.OrganizationId.toString() + ".xlsx");
  }



  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if(result){
      this.convertToJson(result)
    }
  }


  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions = CasemenuAction.find(
      e => e.Htext == this.templateData.headerText
    )['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)[
      'ActionButton'
    ];
 this.buttonData=seletedMenuPermissions;
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Upload':
          this.uploadBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Create':
          this.createBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Edit':
          this.editBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Export':
          this.exportBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'CopyToOrg':
          this.copyBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  ngOnDestroy() {
    $('selectSheetModal').remove();
  }

  loadCopyScreen() {
    this.copyDataClicked = true;
    this.gridPage = false;
    this.uploadClicked = false;
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.srcDeployment = this.destDeployment;
    this.gridWidth = 0;
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.destDeployment,
    })
    this.searchResult = [];
    this.getListOrg();
  }

  getListOrg() {
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery('adduserfilterorg');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(
      data => {
        if (!data.errors) {
          // this.resetFormErrors();
          if (
            data.data.Organizations_list &&
            data.data.Organizations_list.length > 0
          ) {
            this.organizationList = [...data.data.Organizations_list];
          }
        }
      },
      error => {
        console.log(error);
      }
    );
    this.ngxService.stop();
  }

  onChangeDeployment(e) {
      this.srcDeployment = e.value;
      this.copycollectionMethodForm.patchValue({
        frmDepKey: e.value,
        frmOrganization: '',
        frmOrgID: '',
        frmcpyasinactive: false
      });
      this.postUpload = false;
      this.gridRemove();
      this.searchResult = [];
      this.organizationList = [];
      this.getListOrg();
  }

  fetchOrgSeries(value: string) {
    this.searchResult = [];
    this.copycollectionMethodForm.value.frmOrgID = '';
    if (value === '') {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/;

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copycollectionMethodForm.value.frmOrgID = this.organizationList.filter(
      function (series: any) {
        if (series && series.organizationname != null) {
          return series.organizationid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      }
    );
    // this.resetFormErrors()
  }

  manageDataColumn(data) {
    var i;
    for (i = 0; i < data.length; i++) {
      if (data[i].isactive.toLowerCase() == 'active') {
        data[i].isactive = true;
      } else {
        data[i].isactive = false;
      }
    }
    // this.sheetHeader = ["Notes", "isactive", "isuserleveladequacycodeenabled", "abnormal", "sequence", "description", "displayname", "name", "id", "casetype"]//Object.keys(data[0]);
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);

    this.excelDataArray.find(d => {
      Object.assign(d, {
        slno: Number(i),
        notes: '',
        tablename: 'organizationsites',
        organizationid: Number(sessionStorage.getItem('org_id')),
        casetype: this.templateData.cardtype.toString(),
        siteformat: this.siteFormat,
        accountid:this.accountID
      });
      i++;
    });
    this.validateExcel(this.excelDataArray, this.siteFormat);
  }

  uploadCopyData(flexgrid) {
    this.postUpload = false;
    this.addEditScreen = false;
    let tempData = [];
    this.excelDataArray = [];
    let errorcount = 0;
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.selectedItems[i]._data.isactive = this.checkStatus ? false : this.selectedItems[i]._data.isactive
      this.selectedItems[i]._data["createdby"] = (sessionStorage.getItem('Userid') == null ||
        sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    let showpopup=0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] == '') {
        this._snackbar.open(
          'Data upload failed! Please check the data for datatype mismatch.',
          'Close'
        );
        return;
      } else if (
        !this.excelDataArray[i]['notes'].toString().match(/approval/i) &&
        !this.excelDataArray[i]['notes']
          .toString()
          .match(/already exists/i) &&
        !this.excelDataArray[i]['notes'].toString().match(/exists/i) &&
        !this.excelDataArray[i]['notes'].toString().match(/exist/i) &&
        !this.excelDataArray[i]['notes'].toString().match(/stain for this site/i) &&
        !this.excelDataArray[i]['notes'].toString().match(/for other location/i) &&
        !this.excelDataArray[i]['notes'].toString().match(/"Copy Rule out" checkbox/i)  
      ) {
        errorcount++;
      }
      if(this.siteFormat==1 && this.excelDataArray[i]['notes'].toString().toLowerCase() =='body site will be added on approval') {
        showpopup++;
       }      
       if((this.siteFormat==2) && (this.templateData.secondarykeys.casetype.toLowerCase()=='gi histology_plus') && (this.excelDataArray[i]['notes'].toString().toLowerCase() =='body site & sub site will be added on approval' || this.excelDataArray[i]['notes'].toString().toLowerCase() =='sub site will be added on approval')) {
        showpopup++;
      }
      }
      
      this.ruleout=showpopup >0 ? true:false;   
    if (errorcount > 0) {
      this._snackbar.open(
        'Some of the data in the rows have issues. Please fix them and try again',
        'Close'
      );
      return;
    }
    tempData = this.getFormatBasedData(this.excelDataArray)
    this.addSites(tempData);
  }

  checkCopyData() {
    return this.excelDataArray.length == 0 || this.excelDataArray.every(va => va.notes.toString().match(/already exists/i))
  }

  triggerStatus(event,statusval) {
    if(statusval == 'inactive'){
      if (event.checked) {
        this.checkStatus = true;
      } else {
        this.checkStatus = false;
      }
    }else{
      this.checkRuleoutStatus = event.checked;
    }
  }

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {

     var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().match(/Mapping site missing/) || value.toString() == 'Mandatory field(s) missing!' || value.toString().match(/Site configuration already exists!/) || value.toString().match(/Null values exist!/) || value.toString().match(/already exist/) || value.toString().match(/not matching/) ||
                            value.toString().match(/check the role/) || value.toString().match(/selected User/) || value.toString().match(/failure/) ||
                            value.toString().match(/Configuration already exists/) || value.toString().match(/site missing/) ||
                            value.toString() == 'Failed' || value.toString() == 'There is already a subsite configured as Default! Please change that to set this site as default'
                            || value.toString() == 'There is already a bodysite configured as Default! Please change that to set this site as default') {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().match(/added/) || value.toString().match(/approval/) || value == 'Success' || value == 'Site copied successfully' || value.toString().match(/rule out/)
        || value.toString().match(/Successfully/)  || value.toString().match(/On approval/) || value == 'Created Successfully' || value == 'Updated successfully'
         || value.toString().match(/added/) || value.toString().match(/approval/)|| value.toString().match(/stain for this site/)
         ||value.toString().match(/for other location/)||value.toString().match(/impact on default stains/)) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  transform(data) {
    let noOfRowaToGenerate = 200;
    return data.map(({ name, values }) => {
      if(name=='Sample Data'){
        noOfRowaToGenerate=1;
      }
      const headers = values.reduce((prev, next) =>
        ({
          ...prev, [next.header]: Array.isArray
            (next.value) ? next.value.map(({ name }) => name) : next.value
        }), {})
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers)
      }
    })
  }

  downloadFormatFields(all?) {
    let bigData = [];
    let sampleData=[];
    if(this.siteFormat == 1)
    {
      if(all)
      {
        bigData.push({ header: 'SiteType', value: [{ name: "Site Location" }, { name: "Sub Site" }, { name: "Body Site" }] })
        bigData.push({ header: 'SiteName', value: "" }, { header: 'SiteOrder', value: "" }, { header: 'SiteShortName', value: "" }, { header: 'IsActive', value: "" })
        bigData.push({ header: 'Default', value: [{ name: "Yes" }, { name: "No" }] });
        sampleData.push({ header: 'SiteType', value:  "Body Site/Sub Site/Site Location" })
        sampleData.push({ header: 'SiteName', value: "Left Lateral Base" }, { header: 'SiteOrder', value: "9" }, { header: 'SiteShortName', value: "Lt Lat Base" }, { header: 'IsActive', value: "1" })
        sampleData.push({ header: 'Default', value:  "Yes" });

      }
      else {
        bigData.push({ header: 'SiteType', value: [{ name: "Site Location" }, { name: "Sub Site" }, { name: "Body Site" }] })
        bigData.push({ header: 'SiteName', value: "" })
        sampleData.push({ header: 'SiteType', value: "Body Site/Sub Site/Site Location" })
        sampleData.push({ header: 'SiteName', value: "Left Lateral Mid" })
      }
    }
    else if (this.siteFormat == 3)
    {
      if(all)
      {
        bigData.push({ header: 'BodySite', value: "" }, { header: 'SiteOrder', value: "" }, { header: 'SiteShortName', value: "" }, { header: 'IsActive', value: "" })
        bigData.push({ header: 'Default', value: [{ name: "Yes" }, { name: "No" }] });
        sampleData.push({ header: 'BodySite', value: "Esophagus" }, { header: 'SiteOrder', value: "1" }, { header: 'SiteShortName', value: "Esophagus" }, { header: 'IsActive', value: "1" })
        sampleData.push({ header: 'Default',  value:"Yes"  });
      }
      else {
        bigData.push({ header: 'BodySite', value: "" })
        sampleData.push({ header: 'BodySite', value: "Esophagus" })

      }
    }
    else if(this.siteFormat == 2) {
      if(all)
      {
          bigData.push({ header: 'BodySite', value: "" },{ header: 'SubSite', value: "" },{ header: 'SiteOrder', value: "" },
          { header: "IsActive", value: "" }, { header: 'SiteShortName', value: "" });
          bigData.push({ header: 'Default', value: [{ name: "Yes" }, { name: "No" }] });
          sampleData.push({ header: 'BodySite', value: "ANAL" },{ header: 'SubSite', value: "UNSPECIFIED" },{ header: 'SiteOrder', value: "1" },
          { header: "IsActive", value: "1" }, { header: 'SiteShortName', value: "UNSPECIFIED" });
          sampleData.push({ header: 'Default', value: "Yes" });
      } else {
          bigData.push({ header: 'BodySite', value: "" },{ header: 'SubSite', value: "" })
          sampleData.push({ header: 'BodySite', value: "ANAL" },{ header: 'SubSite', value: "UNSPECIFIED" })

      }
      if(this.templateData.secondarykeys.casetype == 'GI Histology_PLUS')
      {
        if(all)
        {
            bigData.push({ header: 'SupportAtLength', value: [{ name: "Yes" }, { name: "No" }] });
            sampleData.push({ header: 'SupportAtLength', value:"Yes"  });
        }
        bigData.push({ header: 'MappingSite', value: [{ name: "Upper Gastrointestinal" }, { name: "Lower Gastrointestinal" }] })
        sampleData.push({ header: 'MappingSite', value:  "Upper Gastrointestinal" })

      }
    }

    var finalData = {
      "name": "Sites",
      "values": bigData
    }
    var FinalSampleData = {
      "name": "Sample Data",
      "values": sampleData
    }
    let finalArrData = [];
    finalArrData.push(finalData);
    finalArrData.push(FinalSampleData)
    let fname = all ? 'Sites_All Fields_' : 'Sites_Minimal Fields_';
    this.workbookData = this.transform(finalArrData);
    let resName = fname +'_' + sessionStorage.getItem('org_id');
    this.exportAsXLSX(resName);
  }

  exportAsXLSX(name): void {
    this.excelService.exportAsExcelFile(this.workbookData, name);
  }

  onChangeBodySite(event, data)
  {
    if(event.source._selected)
    {
      this.sitesForm.patchValue({
        frmmappingsite: data.MappingSite
      })
    }
  }


  closeCasetypeModel() {
    this.ruleout = false;
  }

  //Redirect to other Tabs
  redirectToOtherTabOnSave(val){
    this.VitalMenuComponent.changeComponent(val,{changeTab: val=='Case Profiles',tabIndex: 0});
  }


  //checkruleout for Create/Bulkupload
  checkRuleoutandDefaultStain() {
        if (this.excelDataArray && this.excelDataArray.length > 0) {
          for (let i = 0; i < this.excelDataArray.length; i++) {
        if(this.excelDataArray[i]['IsActive'] == "")
        {
          this.excelDataArray[i]['IsActive'] = true
        }
        if ((this.siteFormat == 1 || this.siteFormat==3) && (this.excelDataArray[i]['Notes'].toString().toLowerCase()  == 'body site added' || 
        ((this.excelDataArray[i]['Notes'].toString().toLowerCase()  == "site copied successfully" || this.excelDataArray[i]['Notes'].toString().toLowerCase()  == "site and rule out copied successfully")
              && this.sitetype=="body site"))
            && this.excelDataArray[i]['IsActive'].toString().toLowerCase() == "true"  ) {
              if ((this.excelDataArray[i]['RuleOut'] == true || this.excelDataArray[i]['RuleOut'].toString()== "1")) {
                this.ruleout = true;
                this.showConfirmPopupFlag=5;
                } 
              if(this.excelDataArray[i]['StainAlert']==1 || this.excelDataArray[i]['StainAlert']==2){
                  this.showConfirmPopupFlag = this.excelDataArray[0]['StainAlert'];
                  this.locations=this.excelDataArray[0]['Locations'];
                }
                // break;
            }
             else if (this.siteFormat == 2  && (this.excelDataArray[i]['Notes'].toString().toLowerCase() == 'sub site added' || 
             this.excelDataArray[i]['Notes'].toString().toLowerCase() == 'body site and sub site added'||((this.excelDataArray[i]['Notes'].toString().toLowerCase()  == "site copied successfully" || 
             this.excelDataArray[i]['Notes'].toString().toLowerCase()  == "site and rule out copied successfully") && this.sitetype=="body site"))
              && this.excelDataArray[i]['IsActive'].toString().toLowerCase() == 'true' )
               {
                if ((this.excelDataArray[i]['RuleOut'] == true || this.excelDataArray[i]['RuleOut'].toString()== "1")) {
                  this.ruleout = true;
                  this.showConfirmPopupFlag=5;
                  } 
                if(this.excelDataArray[i]['StainAlert']==1 || this.excelDataArray[i]['StainAlert']==2){
                    this.showConfirmPopupFlag = this.excelDataArray[0]['StainAlert'];
                    this.locations=this.excelDataArray[0]['Locations'];
                  }
                  //  break;
                } 
              }         
        }
      }

      getAuditableDetails(location: any) {
        this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
          this.auditableColumns =  JSON.parse(res.content.JsonData);
        })
      }

      getEditedData(formValue: any, currentData: any, siteFormat:any) {
        Object.keys(currentData).forEach(function(key) {
          if(currentData[key] === "Not Specified") {
            currentData[key] = null;
          }
      })
        currentData['SubSite']= formValue['frmsubsite'];
        currentData['SiteShortname']= formValue['frmshortname'];
        if(siteFormat == 3){
          currentData['BodySite']  = formValue['frmbodysite'];
          this.trackerKey = 'BodySite';
        }else if(siteFormat == 2){
          if(formValue['frmsitetype'] ! = 'Sub Site'){
          currentData['BodySite'] = formValue['frmsitename'];
          }
          this.trackerKey = 'BodySite';
        }else{
          this.trackerKey = 'EntityName';
        }
        this.editedData = currentData;
      }
    
      //#region Patching the Shortname
      patchShortName(value: string)
      {
        this.sitesForm.patchValue({
          frmshortname: (value || "")
        })
      }
      //#endregion

      //#region check if the user is updating IsActive status or except that other fields
      isStatusChanged(formData)
      {
        let isStatus = false;
        let prevStatus = (this.currentData.IsActive != 'Inactive' && this.currentData.IsActive) ? true : false;
        let updatedStatus = (formData.isactive != 'Inactive' && formData.isactive) ? true : false;
        if(prevStatus != updatedStatus)
        {
          isStatus = true;
        }
        return isStatus;
      }
      //#endregion
  }