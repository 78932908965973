import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerService {

  private activityTrackerData = new BehaviorSubject({});
  getActivitySession = this.activityTrackerData.asObservable();

  constructor() { }

  setActivitySession(activityTabData:any){
    this.activityTrackerData.next(activityTabData)
  }
}
