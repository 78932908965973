
export class OrgCompare {
    OrgID: string;
    AccID: string;
    CaseType: string;
    TableName: string | null;

    constructor(orgID: string, accID: string, caseType: string, tableName: string | null) {
        this.OrgID = orgID;
        this.AccID = accID;
        this.CaseType = caseType;
        this.TableName = tableName;
    }
}
