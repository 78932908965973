<div *ngIf="templateData && templateData.labels && gridwidth > 0 && gridViewShow" style="margin-top:20px;">
  <wj-flex-grid [ngStyle]="{'width.px': gridwidth}" [isReadOnly]="true"
    [itemsSource]="gridData"  #grid >
    <!-- (click)="onClick(gridData)" -->
    <div *ngFor="let columnname of gridHeader">
      <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
        [allowResizing]="true" [allowDragging]="false">
      </wj-flex-grid-column>
    </div>
    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
  </wj-flex-grid>
  <br />
  <div class="align-center">
    <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" style="margin-top:20px;"
      [cv]="gridData"> </wj-collection-view-navigator>
  </div>
</div>
<div *ngIf="gridwidth == 0" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found</div>
</div>

<!-- <div style="background-color: #a5cedc!important; font-weight: 600!important; padding-block: 1px!important;
 margin-left: -13px!important; padding-left: 10px!important;">
  <span style="padding-left:10px ;">
    Licensure
  </span>
</div>
<form [formGroup]="formgrp_userInfo">
  <div class="container-fluid m-0 p-0 mt-3 c_shadow" style="width: 98%;">
    <br/>
    <div class="row m-0 p-0 ">
      <div class="col-md-1"></div>
      <div class="col-md-5">
        <div class="row m-0 p-0">
          <div class="col-md-4 text-right p-0" style="display: table; width: 100%;">
            <div style="display: table-cell; vertical-align: middle">
            <label for="Title"><strong>Title:&nbsp;&nbsp;</strong></label>
          </div>
          </div>
          <div class="col-md-6 mt-1">
            <input type="text" id="Title" class="form-control" name="Title" formControlName="frm_Title"
              [readonly]="true">
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 ">
      <div class="col-md-1"></div>
      <div class="col-md-5">
        <div class="row m-0 p-0">
          <div class="col-md-4 text-right p-0" style="display: table; width: 100%;">
             <div style="display: table-cell; vertical-align: middle">
            <label for="firstname"><strong>First Name:&nbsp;&nbsp;</strong></label>
          </div>
          </div>
          <div class="col-md-6 mt-1">
            <input type="text" id="firstname" class="form-control" name="firstname" formControlName="frm_firstname"
              [readonly]="true">
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row m-0 p-0">
          <div class="col-md-4 text-right p-0" style="display: table; width: 100%;">
             <div style="display: table-cell; vertical-align: middle">
            <label for="lastname"><strong>Last Name:&nbsp;&nbsp;</strong></label>
          </div>
          </div>
          <div class="col-md-6 mt-1">
            <input type="text" id="lastname" class="form-control" name="lastname" formControlName="frm_lastname"
              [readonly]="true">
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 ">
      <div class="col-md-1"></div>
      <div class="col-md-5">
        <div class="row m-0 p-0">
          <div class="col-md-4 text-right p-0" style="display: table; width: 100%;">
            <label for="Email"><strong>Email:&nbsp;&nbsp;</strong></label>
          </div>
          <div class="col-md-6 mt-1">
            <input type="text" id="Email" class="form-control" name="Email" formControlName="frm_Email"
              [readonly]="true">
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row m-0 p-0">
        <div class="col-md-6">
          <label for="fname"><strong>First Name:&nbsp;&nbsp;</strong></label>
        </div>
        <div class="col-md-6">
          <input type="text" id="fname" class="form-control" name="fname" formControlName="frmfname" [readonly]="true">
        </div>
      </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  </div>
</form> -->
