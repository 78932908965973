<div class="container scroll-align col-sm-12 p-0" *ngFor="let group of groupedItems">
    <ul class="list-group scroll-container mb-2" (scroll)="onScrollLoadData()" #uiElement>
      <li class="list-group-item card-body p-0">
        <div class="avatarbox col-sm-12 p-0" *ngIf="hasOperationValues(group.values)">
          <div class="avatar col-sm-2 mt-1">
            <div class="initials">{{ getInitials(group.key.username) }}</div>
          </div>
          <div class="title col-sm-10 p-0">
            <p class="ptitle p-0 mt-1" *ngIf="group.key.username">{{ group.key.username }}</p>
          </div>
        </div>
        <div class="txt-box col-12 p-0 scroll-align">
          <div class="databox col-12" *ngFor="let item of group.values">
            <ng-container *ngIf="(item.operation.info || item.operation.extendedattributes.operation) && searchstring !== 'Comments'">
              <div class="vl ptitle2 row col-sm-12 d-flex">
                <div class="col-sm-8" *ngIf="searchstring !== 'Comments'">
                  {{ item.operation.info || item.operation.extendedattributes.operation }}
                </div>
                <div class="col-sm-4" style="display: flex; align-items: flex-end; justify-content: flex-end;">
                  {{ formatDate(item.createddate) }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="searchstring === 'Comments' && searchstring === 'All' ">
              <div class="vl ptitle2 row col-sm-12 d-flex">
                <div class="col-sm-8">
                  {{item.operation.extendedattributes.operation }}
                </div>
                <div class="col-sm-4" style="display: flex; align-items: flex-end; justify-content: flex-end;">
                  {{ formatDate(item.createddate) }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.operation.extendedattributes">
              <div *ngFor="let item1 of getObjectValues(item.operation.extendedattributes)">
                <div class="vl ptitle2 row col-sm-12 d-flex">
                  <div class="col-sm-8" style="word-wrap: break-word;">{{ item1 }}</div>
                  <div class="col-sm-4 button-wrap">{{ formatDate(item.createddate) }}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <br>
        </div>
      </li>
    </ul>
  </div>


  