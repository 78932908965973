<div class=" row container-fluid py-2 px-3">
  <div class="col-sm-2 pr-0" *ngIf=" ViewType !== 'small'">
    <div class="brand_logo">
      <img src="../../../../assets/images/idealsLabs.png" alt="" width="90" height="60">

      <div class="action_btns">
        <div class="actions_icon"> <i class="fa fa-download" aria-hidden="true"></i> </div>
        <div class="actions_icon"> <i class="fa fa-exchange" aria-hidden="true"></i> </div>
        <div class="actions_icon"> <i class="fa fa-trash" aria-hidden="true"></i> </div>
      </div>

    </div>
  </div>
  <div [ngClass]="  ViewType !== 'small' ?'col-sm-10':'col-sm-12'">
    <div class="row">

      <div class="col-sm-8">
        <!-- <span class="text-sm"> Provider</span>  -->
        <span class="roleborder mx-2 "></span>
        <strong class="username"> {{userDetials['FormattedDisplayName']}}</strong>
      </div>
      <div class="col-sm-4 d-flex justify-content-end">

        <!-- Edit -->
        <!-- [disabled]="!isEditDisabled" [ngClass]="{'action-button-enabled ': isEditDisabled, 'action-button-disabled': !isEditDisabled}" -->
        <button [disabled]="isEditDisabled" [ngClass]="{'action-button-enabled ': !isEditDisabled, 'action-button-disabled': isEditDisabled}" class="Vdm-btn mr-2 cursor" (click)="editUser()">
          <span> <i class="fa fa-pencil mr-2" aria-hidden="true"></i></span> Edit Users</button>

        <!-- Email Sent -->
        <span class="cursor mr-2 mt-1" (click)="mailSent(userDetials)"
          *ngIf="!emailType(userDetials) && userDetials?.Email?.length > 0"><img class="email-invite"
            [ngClass]="{'action-button-disabled': isEmailSent}"
            src="../../../../../assets/icons/lab_users_icons/email.svg" matTooltip={{tooltip}}></span>

        <!-- Lock User -->
        <img style="width: 11px;margin-right: 8px;cursor: pointer;"
          [ngClass]="{'action-button-disabled': isLockDisabled}"
          src="../../../../../assets/icons/lab_users_icons/lock_user.svg" *ngIf="islocked"
          (click)="openDialog(userDetials)" matTooltip="Unlock User">

        <!-- UnLock User -->
        <img style="width: 14px;margin-right: 8px;cursor: pointer;"
          [ngClass]="{'action-button-disabled':isUnlockDisabled}" *ngIf="!islocked" (click)="openDialog(userDetials)"
          src="../../../../../assets/icons/lab_users_icons/Unlock.svg" matTooltip="Lock User">

        <!-- Generate Password -->
        <img src="../../../../../assets/icons/lab_users_icons/generate_password.svg" style="cursor: pointer;"
          [ngClass]="{'action-button-disabled': isGeneratePwd}" (click)="passwordGenerateDialog(userDetials)"
          matTooltip="Generate Password">
      </div>
    </div>
    <div class="mb-1 ml-2">
      <strong class="text-uppercase _600" style="font-size: 11px;">{{userDetials['Address']}}</strong>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="row px-4">
          <div class="icon_label">
            <mat-icon class="vdm_icon">mail</mat-icon>
            {{userDetials['Email'] || 'NA'}}
          </div>
        </div>

        <div class="row d-flex justify-content-between px-4 mt-1">
          <div class="icon_label">
            <mat-icon class="vdm_icon">phone</mat-icon>
            {{userDetials['Phone'] || 'NA'}}
          </div>
          <div class="icon_label">
            <mat-icon class="vdm_icon">contact_phone</mat-icon>
            {{userDetials['Fax'] || 'NA'}}
          </div>
        </div>

      </div>

      <div class="col-sm-6 text-right" style="font-size: 10px;">
        <!-- <div>
          <span> Verified By</span>
        </div>
        <div>
          <span class="text-muted"> 10-27-2023 10:38 PM</span>
        </div> -->
      </div>

    </div>
  </div>


</div>