export class ResultTabArray {
    GUID:String;
    TabName:String;
    Removable : boolean;
    Count : number;
    disabled:boolean;
    active:boolean;
    Content:object;
    ResultContent:object;
    FacetsContent:object;
}

export class ClickedTabData {
    TabName: String;
    IsClick: boolean;
    Index: Number;
    Tab: Object
}

export class OrgCompareArray {
    GUID:String;
    ModalName:String;
    Removable : boolean;
    Count : number;
    disabled:boolean;
    active:boolean;
    Content:object;
    ResultContent:object;
    FacetsContent:object;
}