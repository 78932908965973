<h1 mat-dialog-title>{{sheetData.header}}</h1>
<div class="row col-12 modal-body modal-btn-wraps m-0">
    <div *ngFor="let sheetName of sheetData.continue" class="p-1" >
        <button (click)="onNoClick(sheetName)" mat-raised-button class="admin-btn-success mt-2">
            {{sheetName}}
        </button>
    </div>
</div>

<div mat-dialog-actions class="confirm_dia" [ngStyle]="{'justify-content': sheetData.cancel === 'dontshow' ? 'center':''}">
  <button (click)="onNoClick('')" *ngIf="sheetData.cancel !== 'dontshow'"  cdkFocusInitial
  class="btn admin-btn-success"><strong>{{sheetData.cancel}}</strong></button>
</div>

