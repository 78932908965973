<div class="card" [ngStyle]="{'background-color': count < 5 ? 'red':'darkturquoise'}"
  style="color: white;padding-inline: 16px;border-radius:0%;">
  <h1 style=" margin-block: 2%;" mat-dialog-title>{{data.header}}</h1>
</div>


<div mat-dialog-content
  style="padding-inline: 12%;padding-block: 4%;text-align: center;font-weight: 800;font-size: 15px;">
  <em class="fa fa-clock-o" aria-hidden="true" [ngStyle]="{'color': count < 5 ? 'red':'darkturquoise'}"
    style="font-size: 35px;margin-right: 5%;"></em>
  <span >Your session will expries in </span>
  <p style="font-size: 24px;">{{count}} secs</p>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <button type="submit" class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn"
    style=" margin-left: 48px;" (click)="onNoClick()">
    {{data.continue}}
  </button>
  <button *ngIf="data.cancel !== 'dontshow'" [mat-dialog-close]="data.animal" (click)="close()"
    class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn" cdkFocusInitial>{{data.cancel}} </button>
</div>
