<!-- <p class="card-id" *ngIf="mainCard">  <span > ID:</span>  <span > {{mainCard.id}}</span>
  <span style="padding-left:15px;"> Deployment Key:</span>  <span > {{vitalHttpServices.deploymentKey}}</span>
  <input type="button" value="Export" (click)="ExportCaseType()" style="margin-left:25px;float:right;margin-right:25px" *ngIf="secondarykeys && secondarykeys.casetype">
  <input type="button" value="Import" (click)="ImportCaseType()" style="margin-left:25px;float:right;margin-right:25px;display:none" *ngIf="secondarykeys && secondarykeys.casetype">
<input type="file" id="file"  (change)="handleFileInput($event.target.files)" style="float:right;display:none" *ngIf="secondarykeys && secondarykeys.casetype">
</p>
<div class="generalCardContainer" *ngIf="mainCard && !ifUserType">
<div class="general maincard" >
<span class="cardRow"   *ngFor="let label of labels;let i = index">
<span *ngIf="i%2==1"><span class="maincardLabel">{{label.split('_').join(' ')}}</span> <span class="maincardValue"> {{mainCard[label]}}</span> </span>
<span *ngIf="i%2==0" ><span class ="evencardLabel maincardLabel">{{label.split('_').join(' ')}}</span> <span class="maincardValue"> {{mainCard[label]}}</span> <br /> </span>
</span>
</div>
<div class="verticalLine"></div>
<div class="radiobuttonlist">
<mat-radio-group [name]="mainCard.id + secondayKeysString"  fxLayout="column" [(ngModel)]="caseTypeRadio" style="margin-top:calc(30%)">
<mat-radio-button *ngFor="let action of actions;let ai=index" class="matradio" [ngClass]="{'casetypeRadio': (ai%3 === 2 || ai%3 === 1 )}"
name="action.actiontype"  aria-checked="action.checked" [value]="action.id"  >{{ action.actiontype}}</mat-radio-button>
</mat-radio-group>
</div>
</div> -->

<div class="row" style="background-color: #f4f4f4; overflow: hidden;" id="container_maincard">
  <div class="col-sm-10">
    <div class="row">
      <div class="col-sm-2 border">
        <div class="logodiv" style="display:grid">
          <div style="margin: auto;margin-bottom: 10px;margin-top: 5px;">
            <span *ngIf="!ifUserType"> {{mainCardNew.id}}</span>
            <span *ngIf="ifUserType"> {{accountid}}</span>
          </div>
          <div style="display: contents;align-items: center;justify-content: center;">
            <!-- <span> VitalAxis Code:</span> -->
            <span class="align-center"> {{!GroupCode ? 'Not Specified' : GroupCode}}</span>
          </div>
        </div>
        <div class="row" style="height: 5.4em;justify-content: center;">
          <div style=" margin: auto;  ">
            <span> Deployment Key:</span>
            <span> {{vitalHttpServices.deploymentKey}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-10 border" *ngIf="mainCardNew && ifUserType">
        <div class="row">
          <div class="col-sm-2 text-center">
            <em class="fa fa-user text-muted" style="font-size: 9pc;" aria-hidden="true"></em>
          </div>
          <div class="col-sm-10 p-0 pt-1">
            <div *ngFor="let label of labels;let i = index">
              <div class="row col-sm-6 " *ngIf="i%2==1" style="float: left;margin-bottom: 5px;">
                <div class="col-sm-6 text-muted text-sm text-right">{{label.split('_').join(' ')}}</div>
                <div class="col-sm-6 text-muted _600 pl-0 text-ellipsis text-left" title="{{mainCardNew[label]}}">
                  {{mainCardNew[label]}}
                </div>
              </div>
              <div class="row col-sm-6 p-0" *ngIf="i%2==0" style="float: left;margin-bottom: 5px;">
                <div class="col-sm-6 text-muted text-sm  text-right">{{label.split('_').join(' ')}}</div>
                <div class="col-sm-6 text-muted _600 pl-0 text-ellipsis text-left" title="{{mainCardNew[label]}}">
                  {{mainCardNew[label]}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 border pt-1" *ngIf="mainCardNew && !ifUserType">
        <div class="row" *ngFor="let label of labels   | nrow:ncols">
          <div [ngClass]="'col-sm-'+ (12/ncols)" *ngFor="let element of label">
            <!-- pointing GUID -->
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-sm-6 text-muted text-sm text-right">{{element.split('_').join(' ')}}</div>
              <div class="col-sm-6 text-muted _600 pl-0 text-ellipsis text-left" title="{{mainCardNew[element]}}"
                [ngStyle]="{'color': mainCardNew[label] ==='Inactive'?'red':'' }" (click)="copytoGUID(element.split('_').join(' '),mainCardNew[element])">
                {{mainCardNew[element]}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-2 border mt-2">
    <app-actionbtn [cardtype]="cardtype" [actions]="actions" [actionbutton]="rolepermission" [MenuJson]="rolemenu"
      [roleMenuPermissions]="roleMenuPermissions" (filesAction)="exportLabData($event)" [userId]="userId" [secondary]="secondarykeys"
      [mainCard]="mainCard" [UserType]="ifUserType"></app-actionbtn>
    <!-- <div class="row" *ngIf="ifUserType">
      <button class="md-fab md-btn  m-2 blue" (click)="ifUnlockClicked=true" style="font-size: 20px;"
        [disabled]="!mainCard.Locked">
        <em class="fa fa-unlock " title="Unlock user" aria-hidden="true"></em>
      </button>
      <button class="md-fab md-btn  m-2 blue" (click)="ifPasswordClicked=true" style="font-size: 20px;">
        <em class="fa fa-undo" title="Regenerate password" aria-hidden="true"></em>
      </button>
    </div> -->
  </div>
  <div style="width: 100%;background-color: white;">
    <vital-menu (scrollUpMain)="ScrolltoUpCard($event)" [redirectDest]="redirectDest" [(menuJson)]="rolemenu" [(roledatamenu)]="menuJson" [(genericCardIdentifier)]="accountid" *ngIf="rolemenu"
      [(cardtype)]="cardtype" [mainCard]="mainCard" [secondarykeys]="secondarykeys" (changeTab)="changeRenderTab($event)"></vital-menu>
  </div>

  <!-- && !ifUserType  -->
