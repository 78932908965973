<div class="col-sm-12 mt-3" *ngIf="!showLoader" style="padding-bottom: 10px;">
  <span class="col-sm-12 _600 configuration">Configurations</span>
  <div class="d-flex mt-2">
    <div class="col-sm-9 _600 text-muted">
      Diagnosis Reporting done using VitalDX(VitalOffice)
    </div>
    <div class="col-sm-3"><app-toggle-switch [isDisable]="true" [isChecked] = "isPcEnabled"></app-toggle-switch></div>
  </div>
  <small class="col-sm-9 text-muted">
    (Select this option if the ordering facility uses VitalDX to Sign-out
    patient diagnostic reports)
  </small>
  <div class="col-sm-12 mt-3" *ngIf="additionalSettings?.length > 0 && showAdditionalSettings">
    <span class="_600 additional-setting" >Additional Settings</span>
    <span class="d-flex mt-2" *ngFor="let item of additionalSettings">
      <div class="text-muted wrap-text" style="width:30%">{{item.Attribute}}</div>
      <div style="width:1%">: </div>
      <strong> {{item.Value}}</strong>
    </span>
  </div>
</div>

<mat-spinner *ngIf="showLoader" class="labadmin-spinner loader-aligned" [diameter]="50">
</mat-spinner>
