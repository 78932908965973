export class qualityAttributeAndFlags {
    public static get Ascending(): string { return 'ASC'; }
    public static get Descending(): string { return 'DESC'; }
    public static get Quality_Attributes(): string { return 'qualityAttribute'; }
    public static get Flags(): string { return 'flag'; }    
    public static get Stains(): string { return 'stains'; }

    public static get Swap_sequence_QA_Attribute(): string { return 'qualityAttribute_attr'; }
    public static get Swap_sequence_QA_Group(): string { return 'qualityAttribute_group'; }

    
    public static get Swap_sequence_Stains_panels(): string { return 'stains_group'; }
    public static get Swap_sequence_Stains_procedures(): string { return 'stains_procedures'; }

    public static get Swap_CaseProfiles(): string { return 'caseprofiles'; }
}