<div class="align-center ml-2 common-btn">
    <!-- Displaying the first item as a button -->
    <button mat-raised-button *ngIf="optionArray && optionArray.length >= 1" class="ct-admin-btn-success-outline mt-2" type="button" (click)="ActionItem(optionArray[0])">{{optionArray[0].btnName}}</button>

    <!-- Dropdown menu for the remaining items -->
    <div class="dropdown cursor p-0" *ngIf="optionArray && optionArray.length > 1">
        <button class="menu-wrap p-0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disableIcon(optionArray.slice(1))">
            <img src="../../../../assets/icons/menu_icon.svg" alt="menu" class="material-icons md-24 cursor menu-color">
        </button>

        <div class="dropdown-menu list-view" aria-labelledby="dropdownMenuButton" [ngStyle]="{ 'height': optionArray.length === 2 ? '12vh!important' : '14vh!important'}">
            <span *ngFor="let item of optionArray.slice(1)">
                <div class="col-sm-12 m-0 pr-2 pl-2 cursor">
                    <button class="col-sm-12 list-items" (click)="ActionItem(item)">
                        <img src="{{item.imgsrc}}" alt="Filter" class="material-icons md-18 cursor mr-2">
                        <span>{{item.btnName}}</span>
                    </button>
                </div>
            </span>
        </div>
    </div>
</div>


<!-- 
<div class="align-center ml-2" *ngIf="optionArray && optionArray.length === 1">
    <div *ngFor="let item of optionArray">
        <button mat-raised-button class="ct-admin-btn-success-outline mt-2" type="button"><span (click)=ActionItem(item)>{{item.btnName}}</span></button>
    </div>
</div> -->

