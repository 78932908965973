import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlfilter'
})
export class HtmlFilterPipe implements PipeTransform {

  transform(items: any[], criteria: any): any {
    if (!items || !criteria) {
      return items;
    }
    return items.filter(item => {
      return Object.keys(criteria).every(key => {
        const criteriaValue = criteria[key];
        if (Array.isArray(criteriaValue)) {
          return criteriaValue.includes(item[key]);
        }
        return item[key] === criteriaValue;
      });
    });
  }
}
