<!-- <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
            <div>{{action}}</div>
        </strong>
    </h3>
</div> -->
<!-- <div *ngIf="!userFlag && !ViewScreen && action=='Microscopic Notes'" class="button-field-wrap mt-2">
    <button mat-raised-button
        *ngIf="!isP4Depl && (userlist.value == 'Group Level' || userlist.value == '' || !selectedUser)"
        class="ManCommtBtn mr-4" [disabled]="hideManagemicrobtn" (click)="openUserToggleDialog()">Manage Microscopic
        Notes</button>
    <button mat-raised-button
        *ngIf="(( userlist.value != 'Group Level' && selectedUser && userlist.value != 'All Users')) && templateData.submenuData.length==0 && migrateenable"
        (click)="MigrateGLdata()" [disabled]="hideMigratebtn" class="ManCommtBtn mr-2"
        title="Migrate group level to User level">Migrate</button>

    <mat-form-field class="col-sm-12" appearance="outline" class=" frmMatSelect w-25">
        <mat-label><strong>Users (Roles)</strong></mat-label>
        <div class="flex-display">
            <div class="col-sm-7">
                <input type="text" input (input)="userSelection('')" aria-label="Assignee" placeholder="Search..."
                    matInput [formControl]="userlist" [matAutocomplete]="auto" style="width:95% !important;">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngIf="!isP4Depl" class="mat-opt-align" (click)="userSelection('Group')"
                        value="Group Level">
                        <span>Group Level</span>
                    </mat-option>
                    <mat-option class="mat-opt-align" (click)="userSelection('All')" value="All Users">
                        <span>All Users</span>
                    </mat-option>
                    <mat-option class="mat-opt-align" id="frmMatOption" *ngFor="let option of   filteredOptions | async"
                        (onSelectionChange)="userSelection(option)" value="{{option.UserName}} ({{option.RoleName}})"
                        autoActiveFirstOption title="{{option.UserName}} ({{option.RoleName}})">
                        {{option.UserName}} ({{option.RoleName}})
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="col-sm-5 flex-display">
                <button matSuffix mat-icon-button>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button *ngIf="userlist.value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                    (click)="fnToggleFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </mat-form-field>

</div> -->

<div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noRole">
    <div class="col-sm-10 nodata-wrap">
        <div class="nodata-design">Eligible role not found for user</div>
    </div>
</div>
<!-- <div *ngIf="!userFlag && action=='Microscopic Notes'" class="button-field-wrap p-0 m-0 row col-sm-12">
    <div class="column instr-size mb-1 col-sm-12 pr-0 info-wrap">
        <img aria-hidden="true" src="/assets/icons/help.svg" alt="help" width="15px" height="auto"
            class="icon-align" />
        <span class="instr-size mb-1 col-sm-3 pr-0">
            You can select the location or user level templates to view the details in the Grid.
        </span>
    </div>
</div> -->
<div *ngIf="gridScreen">
    <!-- <div *ngIf="!ViewScreen" class="row button-wrap">
        <button mat-raised-button *ngIf="userFlag && templateData.submenuData.length==0 && migrateenable"
            (click)="MigrateGLdata()" [disabled]="hideMigratebtn" class="ManCommtBtn  mr-2 mt-3"
            title="Migrate group level to User level">Migrate</button>
        <div *ngIf="userFlag" class="col-sm-3">
            <mat-form-field appearance="outline" class="button-wrap mr-0 pr-0 mt-2">
                <mat-label>Role</mat-label>
                <mat-select disableOptionCentering [(ngModel)]="selectedRole">
                    <mat-option class="mat-opt-align" (onSelectionChange)="setUserRole($event,user)"
                        value="{{user.RoleName}}" *ngFor="let user of userRoles">
                        {{user.RoleName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->
    <!-- <wj-flex-grid class="mt-2" [isReadOnly]="true" [itemsSource]="gridData" [headersVisibility]="'Column'"
            (initialized)="initGrid(grid)" #grid>
            <wj-flex-grid-column [header]="'Actions'" align="center" [width]="'*'" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button [disabled]="hideUpdateBtn" class="edit-delete-btn"><em id="editButton" [title]="'Update'"
                            class="edit-template important-text p-0 fa fa-edit"
                            (click)="editMicroCodes(row)"></em></button>
                    <button class="edit-delete-btn"><em id="editButton" [title]="'Delete'"
                            class="edit-template important-text pl-2 fa fa-trash"
                            (click)="deleteMicroCodes(row)"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <ng-container *ngIf="userFlag">
                <wj-flex-grid-column [binding]="columnname" *ngFor="let columnname of gridheader"
                    [allowDragging]="false"
                    [visible]="!(columnname == 'Preference_Id') && !(columnname == 'User_Id') && !(columnname == 'createdby')&& !(columnname == 'modifiedby') && !(columnname == 'createddate')&& !(columnname == 'modifieddate')"
                    [header]="columnname.split('_').join(' ')" [width]="'*'" [allowResizing]="true" [format]="'d'">
                </wj-flex-grid-column>
            </ng-container> -->
    <!-- <div > -->
    <!-- <ng-container *ngIf="!userFlag">
                <wj-flex-grid-column [binding]="columnname" *ngFor="let columnname of gridheader"
                    [allowDragging]="false"
                    [visible]="!(columnname == 'Preference_Id') && !(columnname == 'Case_Type')&& !(columnname == 'User_Id') && !(columnname == 'createdby')&& !(columnname == 'modifiedby') && !(columnname == 'createddate')&& !(columnname == 'modifieddate')"
                    [header]="columnname.split('_').join(' ')" [width]="'*'" [allowResizing]="true" [format]="'d'">
                </wj-flex-grid-column>
            </ng-container> -->
    <!-- </div> -->
    <!-- <wj-flex-grid-column [binding]="User_Id" *ngIf="!userFlag" [allowDragging]="false"
                 [header]="'Userd Id'" [width]="'*'" [allowResizing]="true" [format]="'d'">
            </wj-flex-grid-column> -->

    <!-- <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="page-align">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div> -->

    <app-templates-listing *ngIf="templateData" [gridHeader]="listedGridHeader" [gridData]="sendGridData"
        [context]="'Microscopic Notes'" [hideEdit]="hideUpdateBtn || userFlag" [hideCreate]="hideCreateBtn"
        [hideUpload]="hideUploadbtn || userFlag" [hideExport]="hideExportBtn" [hideCopy]="hideCopyToOrgBtn"
        [hideDelete]="true" (closingListTemplate)="handleListingScreen($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [showTopButtons]="true" [isUserLevel]="userFlag"
        [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true" [hideManage]="false"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
        (deleteTemplateClicked)="newDeleteMethod($event)" (copyTemplateEventTriggered)="copyFromOrg()"
        (uploadTemplateEventTriggered)="uploadMicroNotesExcelData()" (emitFilters)="emitFilters($event)"
        (manageEventTriggered)="openUserToggleDialog()" [isUserLevel]="userFlag" [hideMigrate]="hideMigratebtn"
        [enableMigrate]="migrateenable" [caseTypeUserRoles]="userRoles" [selectedRole]="selectedRole"
        (roleChangedEventTriggered)="roleChanged($event)" [gridwidth]="templateData.submenuData.length==0"
        [isAdmin3]="sourceApp =='VitalDx'? false : true">
    </app-templates-listing>

    <!-- <app-templates-listing [gridHeader]="ListedGridHeader" [gridData]="SendGridData"
            [context]="'Microscopic Codes'" [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="uploadBtn"
            [hideExport]="exportBtn" [hideCopy]="copyBtn" (closingListTemplate)="handleListingScreen($event)"
            (editTemplateClicked)="newEditMethod($event)" [hideDelete]="true" [templateData]="templateData"
            [subMenuCardModel]="SubMenuCardModel" [showTopButtons]="true"
            [userAutoCompleteValue]="inData" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)">
        </app-templates-listing> -->


    <!-- <span *ngIf="!ViewScreen" class="col-sm-12 align-center mt-3">
            <div class="col-sm-4 button-align">
                <button mat-raised-button class="admin-btn-success" [disabled]="hideExportBtn"
                    (click)="exportMicroscopicNotes(grid)">
                    Export
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideCreateBtn"
                    (click)="openCreateScreen()">
                    Create
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideUploadbtn"
                    (click)="uploadMicroNotesExcelData()">
                    Upload
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideCopyToOrgBtn"
                    (click)="copyFromOrg()" title="Copy from other entity">
                    Copy
                </button>
            </div>
        </span> -->

    <!-- <div *ngIf="!templateData.submenuData || templateData.submenuData.length <= 0" class="col-sm-12 mt-4">
        <div class="nodata-wrap mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>

        <span class="col-sm-12 align-center">
            <div class="col-sm-4 button-align mt-3">
                <button mat-raised-button class="admin-btn-success" (click)="openCreateScreen()"
                    [disabled]="hideCreateBtn">
                    Create
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideUploadbtn"
                    (click)="uploadMicroNotesExcelData()">
                    Upload
                </button>
                <button mat-raised-button class="admin-btn-success" (click)="copyFromOrg()"
                    title="Copy from other entity" [disabled]="hideCopyToOrgBtn">
                    Copy
                </button>
            </div>
        </span>

    </div> -->
</div>

<!-- <div *ngIf="createScreen">
    <form [formGroup]="microScopicfrm" class="row col-sm-12 m-0 pb-3 text-wrap">

        <mat-form-field appearance="outline" class="mt-3 w-100 col-sm-4">
            <mat-label>
                <strong>Name</strong>
            </mat-label>
            <mat-error>{{getErrorMessage(microScopicfrm.value.frmName,'')}}</mat-error>
            <input trim type="text" matInput maxlength="250" formControlName="frmName" [errorStateMatcher]="matcher">
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-3 w-100 col-sm-4">
            <mat-label>
                <strong>Description</strong>
            </mat-label>
            <mat-error>{{getErrorMessage(microScopicfrm.value.frmDescription,'')}}</mat-error>
            <input trim type="text" matInput maxlength="4000" formControlName="frmDescription"
                [errorStateMatcher]="matcher">
        </mat-form-field>

        <mat-form-field *ngIf="userFlag" class=" w-100 mt-3 col-sm-4" appearance="outline">
            <mat-label> <strong><span class="text-danger">*</span> Case type </strong></mat-label>
            <i class="fa fa-chevron-down chevron-align"></i>
            <input title="{{microScopicfrm.value.frmCaseType}}" type="text" aria-label="Assignee"
                placeholder="Search..." matInput formControlName="frmCaseType" [matAutocomplete]="auto2">
            <mat-error>{{getErrorMessage(microScopicfrm.value.frmCaseType,'casetype')}}</mat-error>
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option class="mat-opt-align" [disabled]="action == 'Edit Microscopic Note'" id="frmMatOption"
                    *ngFor="let option of  filterCaseType | async" value="{{option.CaseType}}" autoActiveFirstOption
                    title="{{ option.CaseType }} ">
                    {{ option.CaseType }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="mt-2 col-sm-4 mb-2 pl-0" *ngIf="!userFlag" style="margin-top: 12px;">
            <div *ngIf="action == 'Create Microscopic Note' && !this.userSelected">
                <span *ngIf="isP4Depl" class="text-danger">*</span>
                <mat-label class="font-size"><strong>User (Role) </strong>
                </mat-label>
                <ng-multiselect-dropdown [disabled]="action != 'Create Microscopic Note'" class="text-xs"
                    [placeholder]="'Search ...'" #matSelectOption formControlName="frmUsers"
                    [settings]="dropdownSettings" [data]="userDropdownList2" (onSelect)="onCheckChange($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelectAll)="onDeSelectAll()">
                </ng-multiselect-dropdown>
            </div>
            <mat-form-field class="col-sm-12 example-full-width" appearance="outline"
                *ngIf="action != 'Create Microscopic Note' || this.userSelected" (click)="disabled=false"
                (mouseout)="disabled=true">
                <mat-label class="font-size"><strong> User (Role) </strong>
                </mat-label>
                <input formControlName="frmUsers" matInput />
            </mat-form-field>
        </div>
    </form>

    <div class="row">
        <div class="col-sm-3 mt-4">
            <button mat-raised-button class="admin-btn-success" (click)="refreshGrid()">
                Back
            </button>
        </div>
        <div class="col-sm-9 button-wrap mt-4">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="saveMicroNotes('',microScopicfrm.value)">
                Save & Close
            </button>
            <button mat-raised-button *ngIf="action == 'Create Microscopic Note'" class="admin-btn-success"
                (click)="saveMicroNotes('new',microScopicfrm.value)">
                Save & Create
            </button>
        </div>
    </div>
</div> -->

<div *ngIf="uploadClicked" class="my-micro-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Microscopic Notes</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex header-View">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option *ngIf="!userFlag && !userSelected && !isP4Depl " class="temp-font-size"
                    (click)="downloadTemplate('min')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields(Upload)
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('all')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields (Upload)
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('update')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields (Update)
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Microscopic notes.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-3" (click)="userSelected = undefined; refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" (initialized)="initGrid(grid)" [itemsSource]="gridData" #grid
            [headersVisibility]="'Column'" [frozenColumns]="2" (formatItem)="formatItem(grid, $event)">

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            <!-- <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator> -->
        </wj-flex-grid>
        <div class="page-align">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <div class="row pl-3">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <!-- -->
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        [disabled]="disableApprovebtn()" (click)="uploadMicroscopicData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download Result</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
<div *ngIf="copyfromScreen">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Microscopic Notes</strong>
        </h3>
    </div>
    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label><span class="error-msg">*</span>Deployment</mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDeplyment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label><span class="error-msg">*</span>Entity</mat-label>
                <em class="fa fa-chevron-down chevron-align"></em>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                            title="{{ show.organizationname }} ({{show.organizationid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                            value="{{ show.organizationname }}">
                            <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-sm-4  example-additional-selection" appearance="outline">
                <mat-label><span *ngIf="isP4Depl" class="error-msg">*</span>User</mat-label>
                <em class="fa fa-chevron-down chevron-align"></em>
                <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput" #searchuserbar
                    (keyup)="fetchUserSeries(searchuserbar.value)" [matAutocomplete]="userauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #userauto="matAutocomplete">
                    <div *ngFor="let user of searchuserlist">
                        <mat-option class="mat-opt-align"
                            *ngIf="user.UserName.toLowerCase().includes(searchUserInput.toLowerCase())"
                            (onSelectionChange)="selectedCopyDataUser($event, user)"
                            value="{{ user.UserName }} ({{user.RoleName}})"
                            title="{{ user.UserName }} ({{user.RoleName}})">
                            <span>{{ user.UserName }} ({{user.RoleName}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="gridWidth == 0 && !noDataFound" class="col-sm-12 row align-center mt-2">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="userSelected = undefined; refreshGrid('list')">Back</button>
                <div class="dml-btn">
                    <button mat-raised-button class="admin-btn-success" (click)="getDataToCopy(copyFromOrgFrm)">Get
                        Data</button>
                </div>
            </div>
            <div *ngIf="gridWidth > 0" class="col-sm-12">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formatItem(flexgrid, $event)">
                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column
                            [visible]="!(columnname == 'accountid') && !(columnname == 'UserId') && !(columnname == 'preferencetype')&& !(columnname=='preferenceid')"
                            [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <div class="col-sm-12 pl-0 mt-4">

                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid('back')">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="uploadMicroscopicData(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 mt-4" *ngIf="gridWidth == 0 && noDataFound">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="userSelected = undefined; refreshGrid('list')">Back</button>
                <div class="container create-btn col-sm-12 align-center">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <!-- <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">Back</button> -->
            </div>
        </div>
    </form>
</div>