<div class="modal-header admin-model-header mt-3">
  <h3 class="modal-title w-100 admin-model-header-txt">
    <strong>
        <div *ngIf="!isHomeHeader">Templates</div>
      <div *ngIf="isHomeHeader">{{previewHeader}} {{headerName}}</div>
    </strong>
  </h3>
</div>
<div class="col-sm-12 p-0 casetype-wrap" *ngIf="templateGroupedDataKeys && subHeader">
  <div *ngIf="!ShowPreviewUploadTemplate" class="row col-sm-12 mt-4 ml-0 mr-0 p-0">
    <wj-flex-grid  #flexgrid (initialized)="initializeGrid(flexgrid)"
      [isReadOnly]="true" [itemsSource]="caseTemplateinformation" [headersVisibility]="'Column'" 
      (click)="caseTypeTemplateInformation(flexgrid, $event)">
      <div *ngFor="let columnname of columns">
        <wj-flex-grid-column [binding]="columnname.binding" [fixed]="true" [width]="'*'"
        [header]="columnname.header" [format]="'d'" aria-expanded="false" [allowResizing]="true"
        [allowDragging]="true" [visible]="!(columnname.header == 'Template Category')">
        </wj-flex-grid-column>
      </div>
    </wj-flex-grid>
  </div>

  <div id="PreviewUploadTemplate" class="col-sm-12 p-0 border" *ngIf="NotBlankTemplate">
    <form [formGroup]="frm_Report">
      <div class="container-fluid border d-block mx-auto mt-3 preview-wrap p-0">
        <div class="col-sm-12">
          <div class="row col-sm-12 modal-wrap p-0 m-0 mt-2">
            <div class="col-sm-5 mb-2">
              <mat-form-field class="col-sm-12" appearance="outline">
                <!-- <mat-label>Search</mat-label> -->
                <mat-select disableOptionCentering [(value)]="selected" 
                  (selectionChange)="resetSearch()" formControlName="selectSearch">
                  <mat-option class="mat-opt-align" value="caseid">Case ID</mat-option>
                  <mat-option class="mat-opt-align" value="acc">Accession Number</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-5 d-flex">
              <input type="text" (input)="onInputChange($event.target.value)" style="padding: 5px;" 
              class="input-wrap font-size example-form-field form-group select-wrap form-control mb-2 search-box"
                formControlName="frm_InputValue" [(ngModel)]="valueData" (keydown.enter)="onGoButtonClick()" />


              
              <button *ngIf="valueData" class="button-wrap" matSuffix mat-icon-button aria-label="Clear" 
              (click)="this.showGrid = false;this.valueData = ''; selectedItems = []">
                <mat-icon class="icon-wrap">close</mat-icon>
              </button>
            </div>

            <div class="col-sm-1 mb-2">
                <button mat-raised-button class="admin-btn-success" 
                [disabled]="!isGoButtonEnabled()"
                (click)="onGoButtonClick()">
                Go
              </button>

            </div>
            
          </div>
          <div *ngIf="showGrid" class="select-wrap">
            <span *ngIf="gridwidth !== 0">
              <wj-flex-grid class="casetype-grid" #gridcheckbox [isReadOnly]="true" [itemsSource]="gridData" 
                [headersVisibility]="'Column'" #grid>
                <div *ngFor="let columnname of gridheader">
                  <wj-flex-grid-column [format]="'d'" [binding]="columnname" [header]="columnname.split('_').join(' ')"
                    [width]="'*'" [fixed]="true"  [allowResizing]="true" aria-expanded="false"
                    [allowResizing]="true">
                  </wj-flex-grid-column>
                </div>
                <!-- <wj-flex-grid-filter #filter></wj-flex-grid-filter> -->
              </wj-flex-grid>

              <!-- <div>
                <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
                  [cv]="gridData"> </wj-collection-view-navigator>
              </div> -->
            </span>
          </div>
          <div *ngIf="dataUnavailable" class="nodata-wrap mt-4">
            <div class="nodata-design">No Data Found</div>
          </div>

          <div class="col-12 form-field-height">
            <mat-tab-group (selectedIndexChange)="selected.setValue($event)">
              <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
                <!-- <div *ngIf="tab === 'Existing Template'">
                  <div class="report-wrap">
                    <div class="buttonalignleft">
                      <button mat-raised-button class=" example-delete-tab-button select-wrap"
                        (click)="DownloadGitFile(previewVariable)">
                        Download Template
                      </button>
                    </div>
                    <div class="buttonalignright">
                      <button mat-raised-button class="buttonalignright example-delete-tab-button select-wrap"
                        [disabled]="!showGrid" (click)="PreviewExistingTemp(previewVariable)">
                        Preview
                      </button>
                    </div>

                  </div>
                </div> -->
                <div *ngIf="tab === 'Existing Template'">
                  <div class="row col-12 mt-2 mb-2 p-0">
                    <div class="col-6 buttonalignleft">
                      <button  mat-raised-button class="admin-btn-success"
                      (click)="DownloadGitFile(previewVariable)" [disabled]="download">
                        Download Template
                      </button>
                    </div>
                    <div class="col-6">
                      <button mat-raised-button class="admin-btn-success" *ngIf="showGrid"
                      [disabled]="preview" (click)="PreviewExistingTemp(previewVariable)">
                        Preview
                      </button>
                    </div>
                  </div>
                  <div class="row col-sm-12 alert-text mt-3" *ngIf="frm_Report.value.frm_InputValue ==''">
                    <span class="error-msg">
                      Please search up a case to preview template!
                    </span>
                  </div>
                </div>
                <form [formGroup]="frm_UploadReport">
                  <div *ngIf="tab === 'Try New Template'">
                    <div class="row col-12 mt-2 mb-2 p-0">
                      <div class="col-3 buttonalignright mt-2">
                        Upload Template
                      </div>
                      <div class="col-5 file-upload outlinecontainer">
                        <input type="file" accept=".xml,.flxr" id="myFileInputID" #fileUploadCtrl 
                        class="input inputarea buttonalignleft no-outline mt-2 mb-2" formControlName="filename"
                        (change)="fileChange($event)" />
                      </div>
                      <div class="col-3">
                        <button mat-raised-button class="admin-btn-success mt-2" 
                        [disabled]="this.filename == '' || frm_Report.value.frm_InputValue =='' || preview " (click)="
                            PreviewNewTemp(
                              frm_UploadReport.value.filename,
                              frm_Report.value
                            )
                          ">
                          Preview
                        </button>
                      </div>
                    </div>
                    <div class="row col-sm-12 alert-text" *ngIf="frm_Report.value.frm_InputValue ==''">
                      <span class="error-msg">
                        Please search up a case to try a template!
                      </span>
                    </div>
                  </div>
                </form>

              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <div class="w-75 mx-auto mt-4 back-button">
        <button mat-raised-button class="admin-btn-success" (click)="backToGrid()">
          Back
        </button>
      </div>
    </form>
  </div>
  <div id="PreviewUploadTemplate" class="col-sm-12 p-0 border" *ngIf="BlankTemplate">
    <form [formGroup]="frm_Report">
      <div class="container-fluid border d-block mx-auto mt-3 preview-wrap">
        <!-- <div class="row text-center border modal-header admin-model-header preview-heading">
          <div class="col-md-12">
            <h2>{{ previewHeader }}</h2>
          </div>
        </div> -->
        <div class="col-12 text-center">
          <div class="col-12">
            <div class="report-wrap">
              <div>
                <button mat-raised-button class="admin-btn-success" 
                (click)="DownloadGitFile(previewVariable)" [disabled]="download">
                  Download Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-75 mx-auto mt-4 back-button">
        <button mat-raised-button class="admin-btn-success" (click)="backToGrid()">
          Back
        </button>
      </div>
    </form>
  </div>
</div>
<div class="nodata-wrap mt-4" *ngIf="!templateGroupedDataKeys">
  <div class="nodata-design">No Data Found</div>
</div>
