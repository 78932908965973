<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header admin-model-header">
      <h3 class="modal-title w-100 admin-model-header-txt">Launch Compendium Admin as Master Admin</h3>
      <span type="button" data-dismiss="modal" (click)="SetupNewCustomerClose()" class="mr-2">
        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
          title="Close">
      </span>
    </div>

    <div class="modal-body">
      <div>
        <div class="col-md-4 p-0 m-0 pr-1 centeralign float-left">
          <label for="DKeys3" class="centeralign">
            <strong><span class="text-danger pr-1">*</span>
              Select Deployment:&nbsp;&nbsp;</strong></label>
        </div>
        <div class="col-md-6 p-0 m-0 float-right">
          <!-- <select name="DeploymentKey" [formControl]="Deployment" placeholder="Select" class="form-control radius-change"> -->
          <select name="DeploymentKey" placeholder="Select" [(ngModel)]="DeploymentKey" class="form-control radius-change">
            <option value="none" *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'" enabled
              selected>---Select---</option>
            <option value="none" *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL'" enabled
              selected>{{selecteddefaultdeploymentkey}}</option>
            <ng-container *ngFor="let i of DeploymentKeys">
              <option [value]="i" *ngIf="i !== selecteddefaultdeploymentkey">{{ i }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="mt-5 centeralign">
        <!-- <button [disabled]="Deployment.value==''" mat-raised-button class="admin-btn-success"
      (click)="launchAdmin()"> -->
        <button *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'" mat-raised-button
          class="admin-btn-success" (click)="launchAdmin()">
          Launch
        </button>

        <button *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL'" mat-raised-button
          class="admin-btn-success" (click)="launchAdmin()">
          Launch
        </button>
      </div>
    </div>
  </div>
</div>