<div>
    <div class="bulk-user-container-header p-3">
        <div class="vdm-heading">
            <b>Bulk Upload Users</b>
        </div>
        <span class="ml-auto"></span>
        <span class="ml-auto cusor-pointer re-upload" (click)="onReupload()">Re-upload</span>
        <app-button buttonclass="secondary" class="bulk-upload-button" (click)="returnToList()">Return</app-button>
        <app-button buttonclass="primary" class="bulk-upload-button" [isDisabled]="isDataInvalid"
            (click)="onSave()">Create Users</app-button>
    </div>
    <div>
        <div class="d-flex">
            <div class="bulk-upload-status-table">
                <div class="upload-status-border-color">
                    <span class="bulk-user-content">{{headings[0]}}</span>
                </div>
                <div *ngFor="let user of bulkUploadData">
                    <span class="bulk-user-content-body" matTooltipClass="mat-display-error"
                        [matTooltip]="user['filteredValidationMessage']"
                        [class]="user[dataProperties[0]].toLowerCase()">{{user[dataProperties[0]]}}
                        <span *ngIf="user['errorCount']">({{user['errorCount']}})</span>
                    </span>
                </div>
            </div>
            <div class="bulk-user-table" [class.bulk-upload-over-flow-hidden]="bulkUploadData.length === 0">
                <div [class]="headerClass">
                    <span class="bulk-user-content" *ngFor="let heading of headings.slice(1)">{{heading}}</span>
                </div>
                <ng-container *ngIf="bulkUploadData.length > 0">
                    <div [class]="bodyClass" *ngFor="let user of bulkUploadData">
                        <span class="bulk-user-content-body" appTooltipEllipsis [class]="user['is'+prop]"
                            *ngFor="let prop of dataProperties.slice(1)">{{user[prop]}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="bulk-user-no-data-found" *ngIf="bulkUploadData.length === 0">
            <span>{{warningMessages}}</span>
        </div>
    </div>
</div>