<div class="modal-content">
  <!-- Modal Header -->
  <div class="modal-header admin-model-header">
    <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
      User Access Control
    </h3>

    <span type="button" data-dismiss="modal" (click)="SetConfigClose()" class="mr-2">
      <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor" title="Close">
    </span>
  </div>
  <div class="modal-body">
    <div class="role-height">
      <div class="popup-wrap" *ngIf="UserEnteredDataDiv">
        <form [formGroup]="UserRoleMgmtform" autocomplete="off">

          <span class="col-sm-12 align-center mt-3 p-0" style="border-bottom: 1px solid #00bcd4;">
            <h4><strong>Assign Role</strong></h4>
          </span>

          <div class="m-0 p-0">
            <div class="col-sm-12 mt-2">
              <div class="row mt-2">
                <mat-form-field appearance="outline" class="col-sm-2 pr-0">
                  <mat-label>Deployment</mat-label>
                  <mat-select disableOptionCentering formControlName="frmDeploymentKey">
                    <mat-option class="mat-opt-align" (onSelectionChange)="deploymentSelected(i)"
                      *ngFor="let i of DeploymentKeys" [value]="i.value">
                      {{i.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-sm-3 pr-0">
                  <mat-label>User</mat-label>
                  <input matInput disableOptionCentering formControlName="UserEmail" [matAutocomplete]="auto"
                    [email]="true" type="email">
                  <mat-autocomplete #auto="matAutocomplete">
                    <div *ngFor="let item of filterLabEmails(UserRoleMgmtform.value.UserEmail)">
                      <mat-option class="mat-opt-align" value="{{item}}" title="{{item}}">
                        <span>{{item}}</span>
                      </mat-option>
                    </div>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" [isRequired]="true" class="col-sm-3 pr-0">
                  <mat-label>Laboratory</mat-label>
                  <input matInput disableOptionCentering formControlName="LabId" [matAutocomplete]="auto1">
                  <mat-autocomplete #auto1="matAutocomplete">
                    <div *ngFor="let item of filterLabDetails(UserRoleMgmtform.value.LabId)">
                      <mat-option class="mat-opt-align" value="{{item.OrganizationName}} ({{ item.OrganizationId }})"
                        title="{{item.OrganizationName}} ({{ item.OrganizationId }})">
                        <span>{{item.OrganizationName}} ({{ item.OrganizationId }}) </span>
                      </mat-option>
                    </div>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" [isRequired]="true" class="col-sm-2 pr-0">
                  <mat-label>Role</mat-label>
                  <!-- <input matInput formControlName="RoleName">  -->
                  <mat-select disableOptionCentering formControlName="RoleName" title="{{UserRoleMgmtform.value.RoleName}}">
                    <mat-option class="mat-opt-align" *ngFor="let i of RoleList" [value]="i" title="{{i}}">
                      {{i}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button mat-raised-button class="col-sm-1 crt-btn admin-btn-success" (click)="getDetails()">
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>


        <div *ngIf="enableInsert">
          <div class="col-sm-12 m-0 p-0 mt-4">
            <form [formGroup]="AddUserForm" autocomplete="off">
              <mat-form-field class="col-sm-3 pr-0" appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select disableOptionCentering formControlName="Title">
                  <mat-option class="mat-opt-align" value="Dr.">Dr.</mat-option>
                  <mat-option class="mat-opt-align" value="Miss.">Miss.</mat-option>
                  <mat-option class="mat-opt-align" value="Mr.">Mr.</mat-option>
                  <mat-option class="mat-opt-align" value="Ms.">Mrs.</mat-option>
                  <mat-option class="mat-opt-align" value="Mrs.">Ms.</mat-option>
                  <mat-option class="mat-opt-align" value="Prof.">Prof.</mat-option>
                </mat-select>
                <mat-error>{{setErrorMessage(AddUserForm.value.Title)}}</mat-error>
              </mat-form-field>

              <mat-form-field class="col-sm-3 pr-0" appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select disableOptionCentering formControlName="gender">
                  <mat-option class="mat-opt-align" value="Male">Male</mat-option>
                  <mat-option class="mat-opt-align" value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-sm-3 pr-0">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="FirstName">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-sm-3 pr-0">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="LastName">
              </mat-form-field>
              <!-- <button mat-raised-button class="admin-btn-success mr-4" (click)="InsertNewUser()">
                Create
              </button> -->
            </form>
          </div>
        </div>

        <!-- associate grid  -->
        <div class="col-lg-12 p-0" *ngIf="showGrid">
          <div class="col-lg-12" *ngIf="(templateData && templateData.length>0)">
            <!-- the grid -->
            <wj-flex-grid #flex [allowDragging]="false" [headersVisibility]="'Column'"
              [(itemsSource)]="gridDataAssociate" (initialized)="initializeGrid(flex)">
              <wj-flex-grid-column [binding]="'UserEmail'" [header]="'User'" [width]="238" [minWidth]="40">
              </wj-flex-grid-column>
              <wj-flex-grid-column [binding]="'LabId'" [header]="'Laboratory'" [isRequired]="true" [width]="238"
                [minWidth]="40"></wj-flex-grid-column>
              <wj-flex-grid-column [dataMap]="rolenameRead" [binding]="'RoleName'" [selectionMode]="'None'"
                [header]="'Role'" [width]="238" [minWidth]="40" [format]="'n2'"></wj-flex-grid-column>
              <wj-flex-grid-column [binding]="'buttons'" class="cursor" [header]="'Action'" [width]="175"
                [minWidth]="40" align="center"></wj-flex-grid-column>
              <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            <div class="align-center">
              <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridDataAssociate">
              </wj-collection-view-navigator>
              <wj-menu class="ml-2" [(value)]="gridDataAssociate.pageSize" [header]="'Page Size'"
                *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
              </wj-menu>
            </div>
            <!-- <div class="col-sm-12 mt-4 mb-2 align-center">
              <div class="row p-0 m-0">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload" (click)="uploadUserRole()">Upload</button>
                <button mat-raised-button class="admin-btn-success" (click)="ExportExcel(flex)">Export</button>
              </div>
            </div> -->
            <div class="col-sm-12 mt-4 mb-2 align-center">
              <!-- template for buttons on items in view mode -->
              <div id="tplBtnViewMode" style="display:none">
                <button id="btnDelete" class="btn btn-sm background-trans cursor">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="noData" class="col-sm-12 align-center mt-2">
          <div class="nodata-design">
            No Data Found
          </div>
        </div>
        <div class="col-sm-12 mt-2 mb-2 align-center">
          <div class="row p-0 m-0">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">
              Back
            </button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="btnexportdisabled && postUpload"
              (click)="ExportExcel()">
              Export
            </button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="postUpload" (click)="uploadUserRole()">
              Save
            </button>
            <!-- <button mat-raised-button class="admin-btn-success" (click)="getDetails()">
              Create
            </button> -->
          </div>
        </div>
      </div>
      <br />
      <!-- Modal body -->
      <div *ngIf="!FormDataEntry">
        <form [formGroup]="UserRoleMgmtform" autocomplete="off">
          <div class="container-fluid p-0 m-0 popup-wrap">
            <div class="row mb-1 mx-0">
              <!-- <a (click)="UserCreationForm()" class="col-12 cursor create-usr">
          <span class="link">Create Audit User</span></a> -->
              <div class="row col-md-12 m-0 p-0">
                <!-- Entity -->
                <div class="col-md-2 pr-0 pl-0">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>Entity</mat-label>
                    <mat-select disableOptionCentering formControlName="frmUsersKey">
                      <!-- <mat-option class="mat-opt-align" [value]="" disabled  selected></mat-option> -->
                      <mat-option class="mat-opt-align" (onSelectionChange)="getSearching($event, i)"
                        *ngFor="let i of Users" [value]="i">
                        {{i}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- Deployment -->
                <div class="col-md-2 pr-0 pl-0"
                  *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'">
                  <mat-form-field appearance="outline" class="col-sm-12 pl-0">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering formControlName="frmDeploymentKey" type="text"
                      >
                      <!-- <mat-option class="mat-opt-align" [value]="" disabled  selected></mat-option> -->
                      <mat-option class="mat-opt-align" (onSelectionChange)="deploymentSelected(i)"
                        *ngFor="let i of DeploymentKeys" [value]="i.value">
                        {{i.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- Default Deployment -->
                <div class="col-md-2 pr-0 pl-0"
                  *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL'">
                  <mat-form-field appearance="outline" class="col-sm-12 pl-0">
                    <mat-label>{{selecteddefaultdeploymentkey}}</mat-label>
                    <mat-select disableOptionCentering formControlName="frmDeploymentKey" type="text"
                      [(value)]="selecteddefaultdeploymentkey">
                      <!-- <mat-option class="mat-opt-align" [value]="" disabled  selected></mat-option> -->
                      <mat-option class="mat-opt-align" (onSelectionChange)="deploymentSelected(i)"
                        *ngFor="let i of DeploymentKeys" [value]="i.value">
                        {{i.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4 pr-0 pl-0">
                  <form class="example-form">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{Searching}}</mat-label>
                      <input #txtInput [attr.disabled]="UserRoleMgmtform.value.frmDeploymentKey == 'none' ? true : null"
                        name="search" type="tel" matInput #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
                        (click)="searchGrid($event, trigger)" [(ngModel)]="search">
                      <!-- matInput placeholder="Search" [matAutocomplete]="auto" name="search" [(ngModel)]="search" (click)="getUserData()"> -->
                      <mat-autocomplete #auto="matAutocomplete">
                        <div *ngFor="let user of filterOptions(search)">
                          <mat-option class="mat-opt-align" disableOptionCentering
                            value="{{ user }}">
                            <span>{{ user }} </span>
                          </mat-option>
                        </div>
                      </mat-autocomplete>
                      <span (click)="searchGrid($event, trigger)" class="cursor">
                        <em class="material-icons" (click)="getUserData()">search</em>
                      </span>
                      <span *ngIf="search" class="cursor">
                        <em class="material-icons" (click)="fnToggleFilter()">close</em>
                      </span>
                    </mat-form-field>
                  </form>
                </div>
                <!-- Associate button -->
                <div class="col-md-4 mt-2">
                  <!-- <mat-icon (click)="associateSetUpForm()" class="cursor mr-2">
                  <i class="fa fa-users" title="Associate User" aria-hidden="true"></i></mat-icon> -->
                  <button mat-raised-button class="admin-btn-success mr-4" (click)="associateSetUpForm()">
                    Assign Role
                  </button>
                  <button mat-raised-button class="admin-btn-success" (click)="UserCreationForm()">
                    Create Audit User
                  </button>
                  <!-- <mat-icon (click)="UserCreationForm()" class="cursor">
                  <i class="fa fa-user-plus" title="Create User" aria-hidden="true"></i></mat-icon> -->
                </div>
                <!-- Entity search grid -->
                <!-- users -->
                <div class="col-lg-12 p-0" *ngIf="selectedType == 'User' ">
                  <div class="col-lg-12" *ngIf="showGrid">
                    <!-- the grid -->
                    <wj-flex-grid #flex [allowDragging]="false" [ngStyle]="{ 'width.px': gridwidth }"
                      [headersVisibility]="'Column'" [(itemsSource)]="gridData" (initialized)="initializeGrid(flex)">
                      <wj-flex-grid-column [binding]="'Id'" [visible]="false" [header]="'Id'" [isRequired]="true">
                      </wj-flex-grid-column>
                      <wj-flex-grid-column [isReadOnly]="true" [binding]="'OrganizationName'" [header]="'Laboratory'"
                        [isRequired]="true" [format]="'n2'" [width]="200" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [width]="200" [minWidth]="40" [binding]="'UserEmail'" [header]="'User'"
                        [isRequired]="true" [isReadOnly]="true" [selectionMode]="'None'"></wj-flex-grid-column>
                      <wj-flex-grid-column [width]="200" [minWidth]="40" [dataMap]="rolenameRead" [binding]="'RoleName'"
                        [header]="'Role'" [selectionMode]="'None'" [format]="'n2'"></wj-flex-grid-column>
                      <wj-flex-grid-column [binding]="'status'" [header]="'Status'" class="align-center"
                        [isReadOnly]="statusread" [width]="140" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [binding]="'buttons'" class="cursor" [header]="'Action'" align="center"
                        [width]="143" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <div class="align-center">
                      <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                      </wj-collection-view-navigator>
                      <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'"
                        *ngIf="showPaginationMainGrid">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                      </wj-menu>
                    </div>


                    <!-- template for buttons on items in view mode -->
                    <div id="tplBtnViewMode" style="display:none">
                      <button id="btnEdit" class="btn btn-sm background-trans cursor">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </button>
                    </div>

                    <!-- template for buttons on items in edit mode -->
                    <div id="tplBtnEditMode" style="display:none">
                      <button id="btnOK" class="btn btn-success btn-sm mr-3 cursor"><i class="fa fa-check" id="btnOK"
                          aria-hidden="true"></i></button>
                      <button id="btnCancel" class="btn btn-danger btn-sm cursor"><i class="fa fa-times" id="btnCancel"
                          aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
                <!-- role -->
                <div class="col-lg-12 p-0" *ngIf="selectedType == 'Role'">
                  <div class="col-lg-12 " *ngIf="showGrid">
                    <!-- the grid -->
                    <wj-flex-grid #flex [allowDragging]="false" [ngStyle]="{ 'width.px': gridwidth }"
                      [headersVisibility]="'Column'" [(itemsSource)]="gridData" (initialized)="initializeGrid(flex)">
                      <wj-flex-grid-column [binding]="'Id'" [header]="'Id'" [visible]="false" [width]="200"
                        [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [isReadOnly]="true" [binding]="'OrganizationName'" [header]="'Laboratory'"
                        [isRequired]="true" [format]="'n2'" [width]="200" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [isReadOnly]="true" [binding]="'UserEmail'" [header]="'User'"
                        [isRequired]="true" [width]="200" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [width]="200" [minWidth]="40" [dataMap]="rolenameRead" [binding]="'RoleName'"
                        [header]="'Role'" [selectionMode]="'None'" [format]="'n2'"></wj-flex-grid-column>
                      <wj-flex-grid-column [binding]="'status'" [header]="'Status'" class="align-center"
                        [isReadOnly]="statusread" [width]="140" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-column [binding]="'buttons'" class="cursor" [header]="'Action'" align="center"
                        [width]="143" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <div class="align-center">
                      <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                      </wj-collection-view-navigator>
                      <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'"
                        *ngIf="showPaginationMainGrid">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                      </wj-menu>
                    </div>

                    <!-- template for buttons on items in view mode -->
                    <div id="tplBtnViewMode" style="display:none">
                      <button id="btnEdit" class="btn btn-sm background-trans cursor">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </button>
                    </div>

                    <!-- template for buttons on items in edit mode -->
                    <div id="tplBtnEditMode" style="display:none">
                      <button id="btnOK" class="btn btn-success btn-sm mr-3 cursor"><i class="fa fa-check" id="btnOK"
                          aria-hidden="true"></i></button>
                      <button id="btnCancel" class="btn btn-danger btn-sm cursor"><i class="fa fa-times" id="btnCancel"
                          aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
                <!-- lab -->
                <div class="col-lg-12 p-0" *ngIf="selectedType == 'Laboratory'">
                  <div class="col-lg-12 " *ngIf="showGrid">
                    <!-- the grid -->
                    <wj-flex-grid #flex [allowDragging]="false" [ngStyle]="{ 'width.px': gridwidth }"
                      [headersVisibility]="'Column'" [(itemsSource)]="gridData" (initialized)="initializeGrid(flex)">
                      <wj-flex-grid-column [binding]="'Id'" [header]="'Id'" [visible]="false" [isRequired]="true">
                      </wj-flex-grid-column>
                      <wj-flex-grid-column [width]="200" [minWidth]="40" [binding]="'OrganizationName'"
                        [header]="'Laboratory'" [isReadOnly]="true"></wj-flex-grid-column>
                      <wj-flex-grid-column [isReadOnly]="true" [width]="200" [minWidth]="40" [binding]="'UserEmail'"
                        [header]="'User'" [isRequired]="true"></wj-flex-grid-column>
                      <wj-flex-grid-column [width]="200" [minWidth]="40" [dataMap]="rolenameRead" [binding]="'RoleName'"
                        [header]="'Role'" [selectionMode]="'None'" [format]="'n2'"></wj-flex-grid-column>
                      <wj-flex-grid-column [width]="140" [minWidth]="40" [binding]="'status'" [isReadOnly]="statusread"
                        class="align-center" [header]="'Status'" [format]="'n2'"></wj-flex-grid-column>
                      <wj-flex-grid-column [binding]="'buttons'" class="cursor" [header]="'Action'" align="center"
                        [width]="143" [minWidth]="40"></wj-flex-grid-column>
                      <wj-flex-grid-filter #filter></wj-flex-grid-filter>

                    </wj-flex-grid>
                    <div class="align-center">
                      <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                      </wj-collection-view-navigator>
                      <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'"
                        *ngIf="showPaginationMainGrid">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                      </wj-menu>
                    </div>

                    <!-- template for buttons on items in view mode -->
                    <div id="tplBtnViewMode" style="display:none">
                      <button id="btnEdit" class="btn btn-sm background-trans cursor">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </button>
                    </div>

                    <!-- template for buttons on items in edit mode -->
                    <div id="tplBtnEditMode" style="display:none">
                      <button id="btnOK" class="btn btn-success btn-sm mr-3 cursor"><i class="fa fa-check" id="btnOK"
                          aria-hidden="true"></i></button>
                      <button id="btnCancel" class="btn btn-danger btn-sm cursor"><i class="fa fa-times" id="btnCancel"
                          aria-hidden="true"></i></button>
                    </div>
                    <!-- <div id="tplBtnEditMode" style="display:none">
                    <button id="btnOK" class="btn btn-success btn-sm mr-3"><i class="fa fa-check" aria-hidden="true"></i></button>
                    <button id="btnCancel" class="btn btn-danger btn-sm"><i class="fa fa-times" aria-hidden="true"></i></button>
                  </div> -->
                  </div>
                </div>
                <div *ngIf="noData" class="col-sm-12 align-center mt-4">
                  <div class="nodata-design">
                    No Data Found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- User inserting form -->
      <div class="pt-0 popup-wrap" *ngIf="FormUserEntryDiv">
        <form [formGroup]="AddUserForm" autocomplete="off">
          <span class="col-sm-12 align-center p-0" style="border-bottom: 1px solid #00bcd4;">
            <h4><strong>User Creation</strong></h4>
          </span>
          <div class="row col-md-12 m-0 p-0">
            <div class="m-0 border-1 box-wrap">
              <div class="row col-sm-12 mt-2">
                <mat-checkbox [color]="task.color" class="box-wrap mr-2" [checked]="checkAllDeps()"
                  (change)="toggleAllSelections($event.checked)"></mat-checkbox>
                <span><strong>All Deployment</strong></span>
              </div>
              <div class="col-sm-12">
                <mat-checkbox [color]="task.color" class="m-2 p-2 box-wrap"
                  (change)="deploymentSelected(i,$event.checked)" *ngFor="let i of DeploymentKeys" [value]="i.value"
                  [checked]="i.checked">
                  {{i.value}}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="row m-0 p-0">
            <div class="col-sm-12 mt-2 p-0">
              <mat-form-field class="col-sm-2 pr-0" appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select disableOptionCentering formControlName="Title">
                  <mat-option class="mat-opt-align" value="Dr.">Dr.</mat-option>
                  <mat-option class="mat-opt-align" value="Miss.">Miss.</mat-option>
                  <mat-option class="mat-opt-align" value="Mr.">Mr.</mat-option>
                  <mat-option class="mat-opt-align" value="Ms.">Ms.</mat-option>
                  <mat-option class="mat-opt-align" value="Mrs.">Mrs.</mat-option>
                  <mat-option class="mat-opt-align" value="Prof.">Prof.</mat-option>
                </mat-select>
                <mat-error>{{setErrorMessage(AddUserForm.value.Title)}}</mat-error>
              </mat-form-field>

              <mat-form-field class="col-sm-2 pr-0" appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select disableOptionCentering formControlName="gender">
                  <mat-option class="mat-opt-align" value="Male">Male</mat-option>
                  <mat-option class="mat-opt-align" value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-sm-3 pr-0" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="FirstName">
              </mat-form-field>

              <mat-form-field class="col-sm-2 pr-0" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="LastName">
              </mat-form-field>

              <mat-form-field class="col-sm-3" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="AddEmail" [email]="true" type="email">
              </mat-form-field>

            </div>
          </div>
        </form>
        <div class="col-sm-12 mt-4 mb-2 align-center" forControlName="backbutton">
          <div class="row p-0 m-0">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
          </div>
          <div class="row p-0 m-0">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="InsertNewUser()">Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
  </div>
</div>
