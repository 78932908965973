<div class="row">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Mandatory Fields</strong>
            </h3>
        </div>
    </div>

    <div class="row col-sm-12 p-0 button-wrap">
        <div class="col-sm-3 p-0 mt-3">
            <mat-checkbox [color]="task.color" [checked]= "selectedcasetype" [disabled]="hideSaveBtn" class="float-right ml-2 mt-2" (change)="ApplyToAll($event)">
                <span><strong>All Case Types</strong></span>
            </mat-checkbox>
        </div>
        
        <mat-form-field class="col-sm-3 mt-2 pr-0" appearance="outline">
            <mat-label>Applicable Page</mat-label>
            <mat-select disableOptionCentering [(value)]="selected" (selectionChange)="switchPage($event)">
              <mat-option *ngFor="let item of mandateObject" value="{{item.value}}">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>

    <div class=" col-12 search-wrap p-0 m-0">
        <div class="col-12 p-2 m-1" *ngIf="caseinfoflag">
            <section class="setupcard-wrap mb-4 mat-elevation-z4">
                <span class="case-details">
                    <span class="m-1">Case Information</span>
                </span>

                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of caseinfoobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <!-- <span class="text-danger pr-1" *ngIf="caseInfoProperty.isSelected=='true'">*</span> -->
                            {{ caseInfoProperty.text}}
                            <span class="text-danger pr-1" *ngIf="caseInfoProperty.Hint">
                                <img src="../../../assets/icons/infor.svg" alt="Help" class="material-icons cursor"
                                    title="If you are making this field non-mandatory ensure that the changes related to reports are handled as well.">
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-12 p-2 m-1" *ngIf="PatientDetailsinfoflag">
            <section class="setupcard-wrap mb-4 mat-elevation-z4">
                <span class="case-details">
                    <span class="m-1">Patient Information</span>
                </span>

                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of patientobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <!-- <span class="text-danger pr-1" *ngIf="caseInfoProperty.isSelected=='true'">*</span> -->
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-12 p-2 m-1" *ngIf="supplimentinfoflag">
            <section class="setupcard-wrap mb-4 mat-elevation-z4">
                <span class="case-details">
                    <span class="m-1">Supplemental Information</span>
                </span>

                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of supplimentobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <!-- <span class="text-danger pr-1" *ngIf="caseInfoProperty.isSelected=='true'">*</span> -->
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-12 p-2 m-1" *ngIf="attachmentinfoflag">
            <section class="setupcard-wrap mb-4 mat-elevation-z4">
                <span class="case-details">
                    <span class="m-1">Attachments</span>
                </span>

                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of attachmentmandobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <!-- <span class="text-danger pr-1" *ngIf="caseInfoProperty.isSelected=='true'">*</span> -->
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- // flag for mat-tab refresh -->
    <div *ngIf="flag==0">
        <div class="col-12 pr-2" *ngIf="insurancedetailsflag">
            <span class="ins-info"><strong>Insurance Information</strong></span>
            <mat-tab-group mat-stretch-tabs [selectedIndex]="0"
                class="example-stretched-tabs mat-elevation-z4 p-2 setupcard-wrap mb-4 pr-0 mb-2">
                <mat-tab label="Primary" *ngIf="primaryinsuranceflag">
                    <div class="d-flex flex-wrap option-wrap p-2">
                        <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of primaryinsobjectval">
                            <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                                [checked]="caseInfoProperty.isSelected=='true'">
                                <span>{{ caseInfoProperty.text}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="Secondary" *ngIf="secondaryinsuranceflag">
                    <div class="d-flex flex-wrap option-wrap p-2">
                        <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of secondaryinsobjectval">
                            <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                                [checked]="caseInfoProperty.isSelected=='true'">
                                <span>{{ caseInfoProperty.text}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="Tertiary" *ngIf="ternaryinsuranceflag">
                    <div class="d-flex flex-wrap option-wrap p-2">
                        <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of ternaryinsobjectval">
                            <mat-checkbox [color]="task.color"  [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                                [checked]="caseInfoProperty.isSelected=='true'">
                                <span>{{ caseInfoProperty.text}}
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="Guarantor Properties" *ngIf="gurantorinsuranceflag">
                    <div class="d-flex flex-wrap option-wrap p-2">
                        <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of gurantorinsobjectval">
                            <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                                [checked]="caseInfoProperty.isSelected=='true'">
                                <span>{{ caseInfoProperty.text}}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="col-12 pr-2" *ngIf="insurancedetailsflag && flag!=0">
        <span class="ins-info"><strong>Insurance Information</strong></span>
        <mat-tab-group mat-stretch-tabs
            class="example-stretched-tabs mat-elevation-z4 p-2 setupcard-wrap mb-4 pr-0 mb-2">
            <mat-tab label="Primary" *ngIf="primaryinsuranceflag">
                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of primaryinsobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Secondary" *ngIf="secondaryinsuranceflag">
                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of secondaryinsobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Tertiary" *ngIf="ternaryinsuranceflag">
                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of ternaryinsobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <span>{{ caseInfoProperty.text}}
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Guarantor Properties" *ngIf="gurantorinsuranceflag">
                <div class="d-flex flex-wrap option-wrap p-2">
                    <div class="pr-2 mr-2" *ngFor="let caseInfoProperty of gurantorinsobjectval">
                        <mat-checkbox [color]="task.color" [disabled]="hideSaveBtn" (change)="updateCheckboxValue(caseInfoProperty,$event)"
                            [checked]="caseInfoProperty.isSelected=='true'">
                            <span>{{ caseInfoProperty.text}}</span>
                        </mat-checkbox>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="row col-sm-12 button-wrap mb-2 mt-2 p-0">
        <!-- <button mat-raised-button class="admin-btn-success mr-4" (click)="onReset()">Reset</button> -->
        <!-- <button mat-raised-button class="admin-btn-success" (click)="onSave()"  [disabled]="!anyCheckboxChecked" >Save</button> -->
        <button mat-raised-button class="admin-btn-success" (click)="onSave()"  *ngIf="!hideSaveBtn"  [disabled]="!anyCheckboxChecked">Save</button>
        <button mat-raised-button class="admin-btn-success" (click)="onSave()"  *ngIf="hideSaveBtn" [disabled]="hideSaveBtn">Save</button>

    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" aria-hidden="true" [ngClass]="{ 'd-block': showModal } ">
    <div class="modal-dialog modal-dialog-centered default-stain">
        <div class="modal-content">
            <div class="modal-header admin-model-header">
                <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
                        Confirmation
                </h3>
                <!-- <span type="button" data-dismiss="modal" (click)="closePopup()" class="mr-2">
                    <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                        title="Close">
                </span> -->
            </div>

            <div class="modal-body" style="text-align: center;font-size:16px">
                <span class="title-card">
                  Save Changes ?
                </span><br><span class="title-card">
                  If not saved, any modifications made will be lost.
                </span><br>
              </div>
              <!-- Modal footer -->
              <div class="modal-footer mt-4" style="display: block;margin: auto;">
                
                  <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="switchYesorNo(true,selectapplicable,true)">Save and
                    Proceed</button>
                <button type="button" mat-raised-button class="admin-btn-success mr-4"
                  (click)="switchYesorNo(true,selectapplicable)">Proceed
                  without Saving</button>
                <button type="button" mat-raised-button class="admin-btn-success" (click)="switchYesorNo(false,selectapplicable)">Stay in this
                  Page</button>
              </div>
        </div>
    </div>
</div>