import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { SubMenuCardModel } from 'src/app/client/DbModel/SubMenuCard/Submenucardmodel';
// import { MatSnackBar, MatNativeDateModule } from '@angular/material';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'template-GenerateVDId',
  templateUrl: 'GenerateVdId.component.html',
  styleUrls: ['./GenerateVdId.component.scss'],
  providers: [DatePipe]
})

export class GenerateVdIdComponent {

  //public DeploymentKeys = environment.DeploymentKeys;
  public templateData = [];
  public templateChangedTime = true;
  public DeploymentKeys = [];
  subscription: Subscription;

  public URL;
  public subMenuCardModel;
  public ifaddVDID = false;
  public uniqueGUID;
  public selDepl;
  public SearchVDId_value;
  validateVDID = false;

  addVDIDModal = new FormGroup({
    frmDeploymentKey: new FormControl('', Validators.required),
    SearchVDId_value: new FormControl('')
  });

  // FrmGrp_SearchVDId = new FormGroup(
  //   {
  //     frm_SearchVDId: new FormControl('', Validators.required)
  //   });

  constructor(private ngxService: NgxUiLoaderService, public vitalHttpServices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router, private datashare: DataShareService,
    public _snackBar: MatSnackBar, public datepipe: DatePipe ) {
      this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys ,
    this.subscription = this.vitalHttpServices.getConflictData().subscribe(data => {
      var tempRuleDataInput: any = data;
      this.method("");
    });
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
    this.addVDIDModal.patchValue({
      frmDeploymentKey: "none"
    });
    this.validateVDID = false;
    this.ifaddVDID = false;
  }
 
  getURL() {
    //sessionStorage.setItem('deploymentKey', "configdb");
    let queryVariable = { "keys": this.selDepl };
    let query = this.vitalHttpServices.GetQuery("vitalaxisdeploymentURL");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, "configdb").subscribe(data => {
      //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      let res;
      if (data.data.submenuData.length > 0) {
        res = data.data.submenuData[0].URL;
        this.URL = res;
      } else {
        this.URL = "";
      }
      this.generateUUID();
    }, error => {
      //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      console.error(error)
    });
  }

  openVDID(val) {
    this.ifaddVDID = false;
    this.validateVDID = false;
    this.selDepl = val.frmDeploymentKey;
    this.templateData = [];
    this.getURL();
    //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
  }

  public templateArr: any = [];
  getTemplateData() {
    // "TemplateFolder" : "Nice Engine 5" ,
    //    "TemplateFolder" : "Nice Engine 6"
    let drpdwn: any = [];
    let val = {};
    val["TemplateFolder"] = "Nice Engine 5";
    let val1 = {};
    val1["TemplateFolder"] = "Nice Engine 6";
    drpdwn.push(val);
    drpdwn.push(val1);
    let submenuDatas = {};
    let submenuData: any = [];
    submenuDatas["VitalDesktopIdentifier"] = this.uniqueGUID;
    submenuDatas["VitalAxisDataURL"] = this.URL;
    submenuDatas["VitalAxisURL"] = this.URL;
    submenuDatas["IsActive"] = false;
    submenuDatas["LoginPage"] = "VALogin.aspx";

    submenuData.push(submenuDatas);
    this.templateArr["submenuData"] = submenuData;
    this.templateArr["templatedropdown"] = drpdwn;
    this.templateData = this.templateArr;
    this.ifaddVDID = true;
  }

  addVDIDModalClose() {
    $('#genericModal').modal('hide');
    this.ifaddVDID = false;
    this.addVDIDModal.reset();
    this.addVDIDModal.patchValue({
      frmDeploymentKey: "none"
    });
    this.validateVDID = false;
  }

  generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    this.uniqueGUID = uuid;
    this.getTemplateData();
  }
  method(eve) {
    $('#genericModal').modal('hide');
    this.ifaddVDID = false;
    this.templateData = [];
    this.addVDIDModal.reset();
    this.addVDIDModal.patchValue({
      frmDeploymentKey: "none"
    });
  }

  btnSearchVDId() {
    this.ifaddVDID = false;
    this.validateVDID = false;
    this.templateData = [];
    this.SearchVDId_value = this.addVDIDModal.value.SearchVDId_value;
    if (this.SearchVDId_value != null && this.SearchVDId_value != "") {
      //sessionStorage.setItem('deploymentKey', "configdb");
      let queryVariable = { "SearchText": this.SearchVDId_value };
      let query = this.vitalHttpServices.GetQuery('searchvdid');
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vitalHttpServices.GetData(queryResult, "configdb").subscribe(res => {
        //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (!res.errors) {
          if (res.data.submenuData != undefined && res.data.submenuData != null) {
            if (res.data.submenuData.length > 0) {
              this.ifaddVDID = true;
              this.validateVDID = false;
              let tempArr = [];
              let drpdwn: any = [];
              let val = {};
              val["TemplateFolder"] = "Nice Engine 5";
              let val1 = {};
              val1["TemplateFolder"] = "Nice Engine 6";
              drpdwn.push(val);
              drpdwn.push(val1);
              let submenuDatas = {};
              let submenuData: any = [];
              let tempResultData = res.data.submenuData[0];
              submenuDatas["VitalDesktopIdentifier"] = tempResultData.VitalDesktopID;
              submenuDatas["VitalAxisDataURL"] = tempResultData.VitalAxisDataURL;
              submenuDatas["VitalAxisURL"] = tempResultData.VitalAxisURL;
              submenuDatas["IsActive"] = tempResultData.IsActive;
              submenuDatas["apiKey"] = tempResultData.APIKey;
              submenuDatas["RequestedBy"] = tempResultData.RequestedBy;
              submenuDatas["SpecificConfig"] = tempResultData.SpecificConfig;
              submenuDatas["AdminLoginName"] = tempResultData.AdminLoginName;
              submenuDatas["senderagentid"] = tempResultData.senderagentid;
              submenuDatas["SystemIP"] = tempResultData.SystemIP;
              submenuDatas["Comments"] = tempResultData.Comments;
              submenuDatas["CustomerName"] = tempResultData.CustomerName;
              submenuDatas["ContactNumber"] = tempResultData.ContactNumber;
              submenuDatas["CustomerType"] = tempResultData.CustomerType;
              submenuDatas["ComputerLocation"] = tempResultData.ComputerLocation;
              submenuDatas["InstallationStatus"] = tempResultData.InstallationStatus;
              submenuDatas["IsFacility"] = tempResultData.IsFacility;
              submenuDatas["LabelsUsedOnThisComputer"] = tempResultData.LabelsUsedOnThisComputer;
              submenuDatas["LatestVersionAvailable"] = tempResultData.LatestVersionAvailable;
              submenuDatas["LoginPage"] = tempResultData.LoginPage;
              submenuDatas["OnTimeTicket"] = tempResultData.OnTimeTicket;
              submenuDatas["OrganizationID"] = tempResultData.OrganizationID;
              submenuDatas["PointOfContact"] = tempResultData.PointOfContact;
              submenuDatas["PrinterType"] = tempResultData.PrinterType;
              submenuDatas["TemplateFolder"] = tempResultData.TemplateFolder;
              submenuDatas["TimeConsumedForInstallation"] = tempResultData.TimeConsumedForInstallation;
              submenuDatas["VDVersion"] = tempResultData.VDVersion;
              submenuDatas["environmentKey"] = tempResultData.environmentKey;
              submenuDatas["AutoUpgradeCompleted"] = tempResultData.AutoUpgradeCompleted;
              // submenuDatas["templateVersion"] = tempResultData.TemplateFolder;
              //  // submenuDatas["AutoUpgradeCompletedDateTime"] = tempResultData.ActivatedDate;
              submenuDatas["AutoUpgradeCompletedDateTime"] = this.datepipe.transform(tempResultData.AutoUpgradeCompletedDateTime, 'dd-MM-yyyy');;
              submenuDatas["ComputerName"] = tempResultData.ComputerName;
              submenuDatas["SystemOS"] = tempResultData.SystemOS;

              submenuData.push(submenuDatas);
              tempArr["submenuData"] = submenuData;
              tempArr["templatedropdown"] = drpdwn;
              this.templateData = tempArr;
            } else {
              this.ifaddVDID = false;
              this.validateVDID = true;
              this.templateData = [];
            }
          }
          else {
            this.ifaddVDID = false;
            this.validateVDID = true;
            this.templateData = [];
          }
        } else {
          this.ifaddVDID = false;
          this.validateVDID = true;
          this.templateData = [];
        }
      }, error => {
        this.ifaddVDID = false;
        this.validateVDID = true;
        this.templateData = [];
        console.error(error);
        //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      });
    }
    
  }


}
