import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { VitalAdminTabService } from 'src/app/client/tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../client/DbModel/SubMenuCard/Submenucardmodel';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { QualityAttributes } from 'src/app/model/quality-attributes';

declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-export-upload-copy',
  templateUrl: './export-upload-copy.component.html',
  styleUrls: ['./export-upload-copy.component.scss']
})

export class ExportUploadCopyComponent implements OnInit, OnChanges {
  task: Task = {
    color: 'primary',
  };

  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');

 @Input() templateData: any;

 @Input() copyDataClicked: any;
 @Input() uploadClicked: any;
 @Output() uploadBack = new EventEmitter();
  gridarray: any[];
  columns: ColDef[];
  gridwidth: number;
  gridData: CollectionView;
  gridPage: boolean = true;
  gridDisplay: boolean = false;
  public DeploymentKeys = [];
  showPaginationMainGrid: boolean = false;
  workBook: any;
  sheetsToSelect: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetHeader = [];
  excelDataArray: any = [];
  addEditClicked: boolean = false;
  gridWidth: number = 0;
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  gridArray = [];
  selector: Selector;
  inData: any;
  AllFields = [{}]
  MandatoryFields = [{}]
  BulkUpdate = [{}]
  sampleDataAttrAll = [{
    Roles : 'Claim Tracker',
    Category : 'Accessioning',
    GroupOrder : '0-9',
    AttrName : 'KNOW ERROR',
    AttrDescription : 'KNOW ERROR DESCRIPTION',
    AttrOrder : '0-9',
    AttrCode : '123',
    AttrType : 'checkbox',
    AttrHoverName : 'Attribute Hover Name',
    Diagnosis : '0-9',
    Processing : '0-9',
    Grossing : '0-9',
    Accession : '0-9',
    Reports : '0-9',
    Requisition : '0-9',
    DefaultValue : 'checkbox',
    PossibleValues : 'Possible Values',
    IsMIRP : '0/1',
    IsHippa : '0/1',
    UsedforQC : '0/1',
    IsComment : '0/1',
    AllowExternalSync : '0/1',
    Stage : '0/1',
    Scope : 'Facility,Lab,Billing',
    UIElement : 'DOB'
  }]
  sampleDataAttrMin = [{
    Roles : 'Claim Tracker',
    Category : 'Accessioning',
    AttrName : 'KNOW ERROR',
    AttrDescription : 'KNOW ERROR DESCRIPTION'
  }]
  sampleDataFlagMin = [{
    AttrName : 'Malignancy'
  }]
  sampleDataFlagAll = [{
    Category : 'Flags',
    GroupOrder : '0-9',
    AttrName : 'Malignancy',
    AttrDescription : 'Malignancy',
    AttrOrder : '0-9',
    AttrCode : '123',
    AttrType : 'Checked',
    AttrHoverName : 'Attribute Hover Name',
    Diagnosis : '0-9',
    Processing : '0-9',
    Grossing : '0-9',
    Accession : '0-9',
    Reports : '0-9',
    Requisition : '0-9',
    DefaultValue : 'Default Value',
    PossibleValues : 'Possible Values',
    IsMIRP : '0/1',
    IsHippa : '0/1',
    UsedforQC : '0/1',
    IsComment : '0/1',
    AllowExternalSync : '0/1',
    Stage : '0/1',
    Scope : 'Facility,Lab,Billing',
    UIElement : 'DOB'
  }]
  sampleDataFlagUpdate=[{
    AttrName:"Primary  Insurance info Missing",
    AttrId:1234
  }]
  uploadBtn: boolean = false;
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  Orgtype: any;
  backBtn: boolean = false;

  showDelete: boolean = true;
  postDownload: boolean = false;
  postUpload: boolean = false;
  destDeployment: string = "";
  RoleList: any = [];
  allSelected = false;
  editEnabled: boolean = false;
  categoryList: any = [];
  selectedrowData: any = {};
  action: string;
  userRoleList: any = [];
  RoleListname: any = [];
  srcOrgid: string;
  srcDeployment: string;
  searchInput: String = '';
  searchUserInput: String = '';
  usertoggle: boolean = false;
  public searchResult: Array<any> = [];
  searchRoleList: any[];
  orgList: any[];
  srcRole: string;
  selectedItems: any = [];
  checkStatus: boolean = false;
  destOrgid: string;
  editabled: boolean = false;
  @Input()
  templateChangedTime: string;
  bulkUpdateFieldsEmpty: boolean = false;
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  userDropdownList2: any;
  parentID: any;
  excelDownloadArray: any[];
  headerName: boolean = false;
  name: any;
  public SubMenuCardModel;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService) {
      this.SubMenuCardModel = new SubMenuCardModel(
        commonService,
        VitalHttpServices,
        datashare
      );
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;

    this.columns = [
      //************* Grid Customized Column names *********/
      new ColDef('category', 'Group Name'),
      new ColDef('attrname', 'Name'),
      new ColDef('attrdescription', 'Description'),
      new ColDef('attrorder', 'Sequence'),
      new ColDef('grouporder', 'Group Sequence'),
      new ColDef('rolename', 'Role'),
      new ColDef('Status', 'Status')
    ];
  }


  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  selectAll = new FormControl();
  formGroupQA = this._fb.group({
    frmGsequence: [""],
    frmAsequenece: [""],
    frmActive: true,
    frmGname: ["", Validators.required],
    frmAname: ["", Validators.required],
    frmAdescription: ["", Validators.required],
    frmRole: [this._fb.array([]), Validators.required],
    frmRequisition: false,
    frmAccession: false,
    frmGrossing: false,
    frmProcessing: false,
    frmDiagnosis: false,
    frmFinalized: false,
    frmComments: false,
    frmIshippa: false,
    frmIsmirp: true,
    frmUsedforqc: false,
    frmStage: false,
    frmAllowSync: false,
    // frmFlagRole: [""]
  });

  copyFormGroup = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmRole: ["", Validators.required],
    frmDepKey: ["", Validators.required],
  })

  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  childPage: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      this.headerName = true;
    }
    else {
      this.headerName = false;
    }
    this.gridPage = false;
    this.editEnabled = false;
    // this.uploadClicked = false;
    this.addEditClicked = false;
    // this.copyDataClicked = false;
    //this.addGridData(this.templateData.submenuData);
    // this.refreshGrid();
    this.getAccountType();
    this.GetButtondetails();
  }

  ngOnInit(): void {
    this.childPage = true;
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      this.headerName = true;
    }
    else {
      this.headerName = false;
    }
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.destOrgid = (!sessionStorage.getItem('org_id')) ? this.templateData.secondarykeys.OrganizationId : sessionStorage.getItem('org_id');
    this.addGridData(this.templateData.submenuData);
    this.getAccountType();
    this.GetButtondetails();
    this.copyDataClicked ? this.copyOrg() : null;
    this.uploadClicked ? this.loadUploadScreen() : null;
  }

  clearOrganization() {
    this.copyFormGroup.patchValue({
      frmOrganization: ''
    })
  }

  clearRole() {
    this.copyFormGroup.patchValue({
      frmRole: ''
    })
  }

  //#region
  capitalize(str) {
    var i, val = str.split('_');
    for (i = 0; i < val.length; i++) {
      val[i] = val[i].charAt(0).toUpperCase() + val[i].slice(1);
    }
    return val.join(' ');
  }
  //#endregion

  //#region Sow Main grid in the component
  addGridData(inArray) {
    this.gridPage = true;
    this.gridarray = [];
    let primary = {};
    if (inArray) {
      if (inArray.length > 0) {
        if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
          inArray = inArray.filter(r => (r.parentid));
          //QC member want sorting in based of Group Sequence(praveen )
          // inArray.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0)
          //inArray.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0)
        }
        else {
          inArray = inArray.filter(r => (r.attrid));
        }
        this.gridData = new CollectionView([]);
        for (let i = 0; i < inArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(inArray[i])) {
            if (!value) {
              value = 'Not Specified';
            }
            if (key == 'isactive') {
              value = value == 'Active' ? 'Active' : 'Inactive';
              primary["Status"] = value;
            }
            else {
              primary[key] = value;
            }
          }
          this.gridarray.push(primary);
        }
        var grid = this.gridarray.sort((a, b) => a.grouporder < b.grouporder ? -1 : 1)
        if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
          this.gridData = new CollectionView(grid,
            { groupDescriptions: ['category'], pageSize: 10 });
        }
        else {
          this.gridData = new CollectionView(grid,
            { pageSize: 10 });
        }
        this.gridwidth = (186 * Object.keys(inArray[0]).length) + 37;
        this.showPaginationMainGrid = grid.length > 10 ? true : false;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    }
    else {
      this.gridwidth = 0;
    }
  }
  //#endregion




  //#endregion

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if(result){
      this.convertToJson(result)
    }
  }
  //#endregion


  onFileDropped($event) {
    this.onFileChange($event);
  }
 //#region Method to hit once a file is dragged to or uploaded
 onFileChange(ev) {
  this.workBook = {};
  this.sheetsToSelect = [];
  const reader = new FileReader();
  let file = ev.target ? ev.target.files[0] : ev[0];
  let filename = file.name;
  let splitarry = filename.split('.');
  if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
    this._snackbar.open('Please upload an excel file only.', 'Close');
  }
  else {
    reader.onload = (event) => {
      const data = reader.result;
      this.workBook = XLSX.read(data, { type: 'binary' });
      this.sheetsToSelect = this.workBook.SheetNames;
      if (this.sheetsToSelect) {
        this.sheetsToSelect = this.sheetsToSelect.filter(va=> va.toLowerCase() != 'sample data');
        if (this.sheetsToSelect.length > 1) {
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'qualityattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() != 'qualityattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
        }
        else {
          this.convertToJson(this.sheetsToSelect[0])
        }
      }
    };
    reader.readAsBinaryString(file);
  }
  this.fileDropRef.nativeElement.value = "";
}
//#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    });
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse();
    temp[0].push("Notes");
    temp[0].push("Status");
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDownloadArray = [...this.excelDataArray]

    this.excelDataArray.find(d => {
      Object.assign(d, {
        slno: Number(i), notes: '', attrid: (d.attrid) ? d.attrid : null, isactive: (d.isactive) ? 1 : 0, tablename: 'qualityattributes', organizationid: Number(sessionStorage.getItem('org_id')), associationtype: this.Orgtype[0].associationtype
      });
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion

 
  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion
  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      excelArr.map(e => { e.category = 'Flags', e.roles = '' })
    }
    this.VitalHttpServices.ValidateQualityAttributes(excelArr, this.destDeployment).subscribe(data => {
      this.ngxService.stop();
      if(data.status.toLowerCase()=='success'){
        // if (!result.errors) {
          let result=data.content
          if (result.length > 0) {
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            excelArr.filter(e => {
              result.find(r => {
                if (r.slno === e.slno) {
                  e.notes = r.notemessage;
                }
                if (this.uploadClicked) {
                  if (((r.category && r.category.toLowerCase()) === (e.category && e.category.toLowerCase()) && (r.attrname && r.attrname.toLowerCase()) === (e.attrname && e.attrname.toLowerCase()) && r.slno != e.slno) || ((r.attrname && r.attrname.toLowerCase()) === (e.attrname && e.attrname.toLowerCase()) && r.slno != e.slno)) {
                    e.notes = 'Duplicate Attribute name found in the excel sheet';
                  }
                }
              });
            });
            this.ngxService.stop();
            if (!this.addEditClicked) {
              this.excelGridData(excelArr);
            } else {
              this.applyValidation(excelArr);
            }
          }
          else {
            if (!this.addEditClicked) {
              this._snackbar.open("Please provide valid data", 'Failed');
            } else {
              this.applyValidation(excelArr);
            }
          }
        // }
      }
     
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
  }
  //#endregion

 //#region Grid Data : uploaded data with Notes column
 excelGridData(data) {
  this.ngxService.stop();
  this.gridDisplay = true;
  this.gridArray = [];
  this.gridData = new CollectionView([]);
  let primary = {};
  if (data) {
    if (data.length > 0) {
      data.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0);

      for (let i = 0; i < data.length; i++) {
        primary = {};
        let mandatoryFieldEmpty = false;
        let checkDataType = false;
        let dataTypeCol;
        for (let [key, value] of Object.entries(data[i])) {
          let flag = false;
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            if (key.toString().toLowerCase() != 'attrname' && key.toString().toLowerCase() != 'roles'
              && key.toString().toLowerCase() != 'attrdescription' && key.toString().toLowerCase() != 'category') {
              if (value != null) {
                if (value.toString().toLowerCase().trim() == 'null') {
                  flag = true;
                }
                primary[key] = value;
              }
            }
          }
          else {
            if (key.toString().toLowerCase() != 'attrname' && key.toString().toLowerCase() != 'roles'
            ) {
              if (value != null) {
                if (value.toString().toLowerCase().trim() == 'null') {
                  flag = true;
                }
                primary[key] = value;
              }
            }
          }
          if (key.toLowerCase() == 'diagnosis' || key.toLowerCase() == 'processing'
            || key.toLowerCase() == 'grossing' || key.toLowerCase() == 'accession' || key.toLowerCase() == 'reports'
            || key.toLowerCase() == 'requisition' || key.toLowerCase() == 'ismirp' || key.toLowerCase() == 'usedforqc'
            || key.toLowerCase() == 'iscomment' || key.toLowerCase() == 'allowexternalsync' || key.toLowerCase() == 'stage') {
            if ((value && value.toString() == 'true') || value == 1) {
              value = true;
            } else if ((value && value.toString() == 'false') || value == 0) {
              value = false;
            } else if (!value) {
              value = '';
            }
            primary[key] = value;
          }
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            // if (key.toLowerCase() == data[i]['grouporder']) {
            if (data[i]['grouporder']) {
              if (data[i]['grouporder'] && (isNaN(data[i]['grouporder']))) {
                if (checkDataType) {
                  dataTypeCol = 'AttrOrder and GroupOrder fields accepts only number'
                }
                else {
                  checkDataType = true;
                  dataTypeCol = 'GroupOrder field accepts only number'
                }
              }
            }
            // }
            // if (key.toLowerCase() == data[i]['attrorder']) {
            if (data[i]['attrorder']) {
              if (data[i]['attrorder'] && (isNaN(data[i]['attrorder']))) {
                if (checkDataType) {
                  dataTypeCol = 'AttrOrder and GroupOrder field accepts only number';
                }
                else {
                  checkDataType = true;
                  dataTypeCol = 'AttrOrder field accepts only number';
                }
              }
            }
            // }
          }
          else {
            if (data[i]['attrorder'] && (isNaN(data[i]['attrorder']))) {
              checkDataType = true;
              dataTypeCol = 'AttrOrder field accepts only number';
            }
            // if (key.toLowerCase() == 'attrorder') {
            //   if (value) {
            //     if (isNaN(+value)) {
            //         checkDataType = true;
            //         dataTypeCol = 'AttrOrder field accepts only number';
            //     }
            //   }
            // }
          }

          flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            if (key.toString().toLowerCase() == 'attrname' || key.toString().toLowerCase() == 'roles'
              || key.toString().toLowerCase() == 'attrdescription' || key.toString().toLowerCase() == 'category') {
              if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
                // this.bulkUpdateFieldsEmpty = true;
              }
              primary[key] = value;
            }
          }
          else {
            if (key.toString().toLowerCase() == 'attrname'
            ) {
              if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
                this.bulkUpdateFieldsEmpty = true;
              }
              primary[key] = value;
            }
          }
          if (checkDataType) {
            value = dataTypeCol;
            this.excelDataArray[i]["notes"] = value;
            primary['notes'] = value;
          }

          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            if (mandatoryFieldEmpty) {
              value = 'Mandatory field(s) missing!';
              this.excelDataArray[i]["notes"] = value;
              primary['notes'] = value;
            }
          } else {
            if (mandatoryFieldEmpty || this.bulkUpdateFieldsEmpty) {
              value = 'Mandatory field(s) missing!';
              this.excelDataArray[i]["notes"] = value;
              primary['notes'] = value;
            }
          }
        }
        this.gridArray.push(primary);
      }
      if (this.copyDataClicked && this.postUpload) {
        this.selector.column.grid.headersVisibility = HeadersVisibility.Column;
      }
      this.excelDataArray = this.gridArray;

      if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
        if (this.copyDataClicked) {
          this.sheetHeader = ["IsActive", "DefaultValue", "AttrType", "AttrName", "AttrDescription", "AttrOrder", "AttrId", "OrganizationId", "Status", "Notes"];
        }
        this.gridData = new CollectionView(this.gridArray)
      }
      else {
        this.gridData = new CollectionView(this.gridArray, { groupDescriptions: ['category'] });
      }
      this.gridWidth = (120 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  else {
    this.gridWidth = 0;
  }
}
//#endregion
checkBool(stringValue) {
    switch (stringValue?.toLowerCase()?.trim()) {
      case "true":
      case "1":
      case "false":
      case "0":
      case 0:
      case 1:
      case null:
      case undefined:
        return true;
      default:
        return false;
    }
  }

  //#region Upload screen
  loadUploadScreen() {
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      this.headerName = true;
    }
    else {
      this.headerName = false;
    }
    this.gridPage = false;
    this.uploadClicked = true;
    this.addEditClicked = false;
    this.gridDisplay = false;
    this.AllFields[0]['Roles'] = null
    this.AllFields[0]['Category'] = null
    this.AllFields[0]['GroupOrder'] = null
    this.AllFields[0]['AttrName'] = null
    this.AllFields[0]['AttrDescription'] = null
    this.AllFields[0]['AttrOrder'] = null
    this.AllFields[0]['AttrCode'] = null
    this.AllFields[0]['AttrType'] = null
    this.AllFields[0]['AttrHoverName'] = null
    this.AllFields[0]['Diagnosis'] = null
    this.AllFields[0]['Processing'] = null
    this.AllFields[0]['Grossing'] = null
    this.AllFields[0]['Accession'] = null
    this.AllFields[0]['Reports'] = null
    this.AllFields[0]['Requisition'] = null
    this.AllFields[0]['DefaultValue'] = ''
    this.AllFields[0]['PossibleValues'] = null
    this.AllFields[0]['IsMIRP'] = null
    this.AllFields[0]['IsHippa'] = null
    this.AllFields[0]['UsedforQC'] = null
    this.AllFields[0]['IsComment'] = null
    this.AllFields[0]['AllowExternalSync'] = null
    this.AllFields[0]['Stage'] = null
    this.AllFields[0]['Scope'] = null
    this.AllFields[0]['UIElement'] = null

    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      delete this.AllFields[0]['Roles']
    }

    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      this.MandatoryFields[0]['Roles'] = null
      this.MandatoryFields[0]['Category'] = null
      this.MandatoryFields[0]['AttrName'] = null
      this.MandatoryFields[0]['AttrDescription'] = null
    }
    else {
      // this.MandatoryFields[0]['Roles'] = ''
      this.MandatoryFields[0]['AttrName'] = null
    }
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      this.BulkUpdate[0]['Roles'] = null
      this.BulkUpdate[0]['Category'] = null
      this.BulkUpdate[0]['AttrName'] = null
      this.BulkUpdate[0]['AttrDescription'] = null
      this.BulkUpdate[0]['AttrId'] = null
    }
    else {
      // this.BulkUpdate[0]['Roles'] = ''
      this.BulkUpdate[0]['AttrName'] = null
      this.BulkUpdate[0]['AttrId'] = null
    }
  }
  //#endregion
  downloadTemplate(type) {
    let filename = ""
    let sample
    let excel = [{}];
    let sampleExcel = [{}];
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      if (type == 'allFieldsCheck') {
        filename = 'Quality Attributes_' + 'All Fields_' + this.destOrgid.toString() + '.xlsx';
        excel = this.AllFields
        sample = true;
        sampleExcel = this.sampleDataAttrAll
      }
      else if (type == 'mandatoryFieldsCheck') {
        filename = 'Quality Attributes_' + 'Minimal Fields_' + this.destOrgid.toString() + '.xlsx';
        excel = this.MandatoryFields
        sample = true;
        sampleExcel = this.sampleDataAttrMin
      }
      else if (type == 'updateCheck') {
        filename = 'Quality Attributes_' + 'Bulk Update Fields_' + this.destOrgid.toString() + '.xlsx'
        excel = this.BulkUpdate
      }
    } else {
      if (type == 'allFieldsCheck') {
        filename = 'Flags_' + 'All Fields_' + this.destOrgid.toString() + '.xlsx';
        excel = this.AllFields
        sample = true;
        sampleExcel = this.sampleDataFlagAll
      }
      else if (type == 'mandatoryFieldsCheck') {
        filename = 'Flags_' + 'Minimal Fields_' + this.destOrgid.toString() + '.xlsx';
        excel = this.MandatoryFields
        sample = true;
        sampleExcel = this.sampleDataFlagMin
      }
      else if (type == 'updateCheck') {
        filename = 'Flags_' + 'Bulk Update Fields_' + this.destOrgid.toString() + '.xlsx'
        excel = this.BulkUpdate
        sample = true;
        sampleExcel = this.sampleDataFlagUpdate;
      }
    }
    excel.reverse();
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    let sheetName=this.templateData.menuURL.toString().toLowerCase() == 'quality attributes' ? 'quality attributes' : 'Flags'
    XLSX.utils.book_append_sheet(wb, ws, "" + sheetName);
    sample ? ws = XLSX.utils.json_to_sheet(sampleExcel) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'Sample Data') : null;
    XLSX.writeFile(wb, filename);
  }


  disableApprovebtn() {
    let existsCount = 0;
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if ((this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/accepts only/i))) || (this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/mandatory/i))) {
          existsCount++;
        }
      }
    }
    else {
      // if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
      //   this._snackbar.open("All selected records already exist!", "Close");
      //   this.postUpload = true;
      //   return;
      // }
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray.some(e => !e.attrid)) {
          if ((this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/accepts only/i))) || (this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/mandatory/i))) {
            existsCount++;
          }
          else if (((this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/accepts only/i))) || (this.gridArray[i]['notes'] && this.gridArray[i]['notes'].toString().match(/mandatory/i))) && (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/already exists/i)))) {
            existsCount++;
          }
          else if ((this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/already exists/i))) || (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/Duplicate Attribute name/i))) || (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/Invalid role/i)))) {
            existsCount++;
          }
        }
        else{
          if((this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/already exists/i))) || (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/not found/i)))){
            existsCount++;
          }
        }
      }
    }
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      if (existsCount > 0) {
        return true
      }
    }
    else {
        if ((existsCount > 0 && existsCount == this.excelDataArray.length)) {
          return true;
        }
        else if (existsCount == this.excelDataArray.length) {
          return true;
        }
    }
    return false;
  }
   //#region Tooltip for Grid
   initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
        // tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col) != true && s.getCellData(e.row, e.col) != false ? s.getCellData(e.row, e.col) : s.getCellData(e.row, e.col) == true ? 'Active' : 'InActive'}`);
      }
    });
  }
  //#endregion
//#region
getAccountType() {
  let queryResult: any;
  let queryVariable;
  let query = this.VitalHttpServices.GetQuery('getassociationtypeforaccid');
  let Qstr = "";
  Qstr += "accountid = \"" + this.templateData.cardIdentifier.toString() + "\"";
  queryVariable = { "accid": Qstr };
  queryResult = this.commonService.GetCardRequest(queryVariable, query);
  this.ngxService.start();
  this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(res => {
    this.ngxService.stop();
    if (!res.errors) {
      if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
        this.Orgtype = res.data.vwGetAssociationtypeByOrgID_list;
        this.getUserRoles(this.destDeployment);
      }
    }
  }, error => {
    this.ngxService.stop();
    console.error(error);
  })
}
//#endregion

  //#region
  removeGrid() {
    // this.gridWidth = this.copyDataClicked ? -1 : 0;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  //#region Delete Row (soft-delete)
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region Upload ExcelData to Insert API
  uploadData() {
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      if (!this.addEditClicked) {
        let errorcount = 0;
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
            this.excelDataArray.map(e => { e.category = 'Flags' })
          }
          if (!this.excelDataArray[i]["notes"]) {
            if (this.copyDataClicked) {
              this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            }
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/will be added/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i)
            && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/Duplicate/i) && !this.excelDataArray[i]["notes"].toString().match(/will be updated/i) && !this.excelDataArray[i]["notes"].toString().match(/not found in Flags/i)) {
            errorcount++;
          }
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            if (this.excelDataArray[i]["notes"] && (this.excelDataArray[i]["notes"].toString().match(/Invalid role/i))) {
              errorcount++
            }
          }
          else {
            if (this.excelDataArray[i]["notes"] && (this.excelDataArray[i]["notes"].toString().match(/Invalid role/i))) {
              errorcount++
            }
          }
        }
        if (errorcount > 0) {
          this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
          return
        }
        if ((this.invalidColumns != "")) {
          this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
          return
        }
        else {
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let [key, value] of Object.entries(this.excelDataArray[i])) {
              if (value === "" || value === null) {
                if (value.toString().toLowerCase() === "null") { this.excelDataArray[i][key] = null; }
              }
              else {
                this.excelDataArray[i][key] = value.toString();
              }
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
              delete this.excelDataArray[i]["slno"]
              delete this.excelDataArray[i]["tablename"]
              delete this.excelDataArray[i]["notes"]
            }
            if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
              if (this.copyDataClicked) {
               // delete this.excelDataArray[i]["grouporder"]
                delete this.excelDataArray[i]["attrorder"]
              }
            }
            else {
              if (this.copyDataClicked) {
                delete this.excelDataArray[i]["attrid"]
                delete this.excelDataArray[i]["attrorder"]
              }
            }
           // this.excelDataArray[i]["grouporder"] = 0
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["diagnosis"] = ((this.excelDataArray[i]["diagnosis"] == 'false')) ? 0 : (this.excelDataArray[i]["diagnosis"] == '') ? 1 : 1;
            this.excelDataArray[i]["processing"] = ((this.excelDataArray[i]["processing"] == 'false')) ? 0 : (this.excelDataArray[i]["processing"] == '') ? 1 : 1;
            this.excelDataArray[i]["grossing"] = ((this.excelDataArray[i]["grossing"] == 'false')) ? 0 : (this.excelDataArray[i]["grossing"] == '') ? 1 : 1;
            this.excelDataArray[i]["accession"] = ((this.excelDataArray[i]["accession"] == 'false')) ? 0 : (this.excelDataArray[i]["accession"] == '') ? 1 : 1;
            this.excelDataArray[i]["reports"] = ((this.excelDataArray[i]["reports"] == 'false')) ? 0 : (this.excelDataArray[i]["reports"] == '') ? 1 : 1;
            this.excelDataArray[i]["requisition"] = ((this.excelDataArray[i]["requisition"] == 'false')) ? 0 : (this.excelDataArray[i]["requisition"] == '') ? 1 : 1;
            this.excelDataArray[i]["ismirp"] = ((this.excelDataArray[i]["ismirp"] == 'false')) ? 0 : (this.excelDataArray[i]["ismirp"] == '') ? 1 : 1;
            this.excelDataArray[i]["ishippa"] = ((this.excelDataArray[i]["ishippa"] == 'false')) ? 0 : (this.excelDataArray[i]["ishippa"] == '') ? 1 : 1;
            this.excelDataArray[i]["usedforqc"] = ((this.excelDataArray[i]["usedforqc"] == 'false')) ? 0 : (this.excelDataArray[i]["usedforqc"] == '') ? 1 : 1;
            this.excelDataArray[i]["iscomment"] = ((this.excelDataArray[i]["iscomment"] == 'false')) ? 0 : (this.excelDataArray[i]["iscomment"] == '') ? 1 : 1;
            this.excelDataArray[i]["allowexternalsync"] = ((this.excelDataArray[i]["allowexternalsync"] == 'false')) ? 0 : (this.excelDataArray[i]["allowexternalsync"] == '') ? 1 : 1;
            this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1

          }
        }
      }
      if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
        this.ngxService.start();
        this.VitalHttpServices.BulkUploadQualityAttributes(this.excelDataArray, this.destDeployment).subscribe(data => {
          this.ngxService.stop();
          if (!data.errors) {
            if(data.status.toLowerCase()=='success'){
              let result=data.content;
              if (result.Result) {
                if (result.Result.length > 0) {
                  this.postUpload = true;
                  this.postDownload = true;
                  this.showDelete = false;
                  this.excelDataArray = [];
                  this.excelDataArray = this.convertObjKeysToLower(result.Result);
                  this.excelGridData(this.excelDataArray);
                  let msg = '';
                  msg = (this.addEditClicked) ? 'saved' : (this.copyDataClicked) ? 'copied' : 'uploaded';
                  // this._snackbar.open('Data ' + msg + ' successfully', 'Close');
                  if (this.addEditClicked) {
                    if (this.action == 'new') {
                      setTimeout(() => {
                        this.refreshGrid();
                      }, 20);
                      this.formGroupDirective.resetForm();
                      this.callResetForm();
                    }
                    else {
                      this.onBackBtnClick();
                      this.formGroupDirective.resetForm();
                      this.callResetForm();
                    }
                  }
                  this.auditDetails({}, result.Result, msg);
                }
              }
              else {
  
                this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
              }
            }
            
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error)
        })
      } else {
        let msg = '';
        if (this.excelDataArray.some(va => va.attrid)) {
          this.ngxService.start();
          this.VitalHttpServices.BulkUpdateFlags(this.excelDataArray, this.destDeployment).subscribe(result => {
            this.ngxService.stop();
            if (!result.errors) {
              if (result[0].Data) {
                if (result[0].Data.length > 0) {
                  this.postUpload = true;
                  this.postDownload = true;
                  this.showDelete = false;
                  this.excelDataArray = [];
                  this.excelDataArray = this.convertObjKeysToLower(result[0].Data);
                  this.excelGridData(this.excelDataArray);

                  if (this.addEditClicked) {
                    if (this.action == 'new') {
                      setTimeout(() => {
                        this.refreshGrid();
                      }, 5);
                      this.formGroupDirective.resetForm();
                      this.callResetForm();
                    }
                    else {
                      this.onBackBtnClick();
                      this.formGroupDirective.resetForm();
                      this.callResetForm();
                    }
                  }
                  this.auditDetails(this.selectedrowData, this.excelDataArray, 'updated');
                }
                else {
                  this._snackbar.open('Update failed!', 'Close');
                }
              }
            }

          }, error => {
            this.ngxService.stop();
            this._snackbar.open('Something went wrong. Please try again', 'Close');
            console.error(error)
          })
        }
        else {
          let Status = this.excelDataArray[0]["isactive"].toString() == "0" ? "Inactive" : "Active";
          if (!this.copyDataClicked && this.templateData.submenuData && this.templateData.submenuData.some(va => va.attrname.toString().toLowerCase() == this.excelDataArray[0]["attrname"].toString().toLowerCase() && va.isactive.toString() == Status)) {
            if (this.addEditClicked) {
              this.formcontrol["frmAname"].setErrors(null);
              this._snackbar.open('Flags already existed', 'Close');
              return;
            }
          }
          let data = this.excelDataArray.filter(e => e.category = 'Flags')
          if (data && data.length > 0) {
            this.ngxService.start();
            this.VitalHttpServices.BulkUploadFlags(data, this.destDeployment).subscribe(result => {
              this.ngxService.stop();
              if (!result.errors) {
                if (result[0].Data) {
                  if (result[0].Data.length > 0) {
                    this.postUpload = true;
                    this.postDownload = true;
                    this.showDelete = false;
                    this.excelDataArray = [];
                    if (!this.addEditClicked && this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
                    this.excelDataArray = this.convertObjKeysToLower(result[0].Data);
                    this.excelGridData(this.excelDataArray);
                    }
                    if (this.excelDataArray) {
                      this.formcontrol["frmAname"].setErrors(null);
                      msg = (this.addEditClicked) ? 'saved' : (this.copyDataClicked) ? 'copied' : 'uploaded';
                      result[0].Data.every(va => va.Notes.toString().match(/already exists/i)) ? this._snackbar.open('Flags already exist', 'Close') : this._snackbar.open('Flags ' + msg + ' successfully', 'Close');
                    }
                    if (this.addEditClicked) {
                      if (this.action == 'new') {
                        setTimeout(() => {
                          this.refreshGrid();
                        }, 5);
                        this.formGroupDirective.resetForm();
                        this.formcontrol['frmAname'].setErrors(null);
                        // this.formGroupQA.patchValue({
                        //   frmFlagRole: this.userDropdownList2
                        // });
                        this.callResetForm();
                      }
                      else {
                        this.onBackBtnClick();
                        this.formGroupDirective.resetForm();
                        this.formcontrol['frmAname'].setErrors(null);
                        // this.formGroupQA.patchValue({
                        //   frmFlagRole: this.userDropdownList2
                        // });
                        this.callResetForm();
                      }
                    }
                    this.auditDetails({}, result[0].Data, msg);
                  }
                }
                else {
                  this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
                }
              }
            }, error => {
              this.ngxService.stop();
              this._snackbar.open('Something went wrong. Please try again', 'Close');
              console.error(error)
            })
          }
        }
      }
    }
  }
  //#endregion


  onBackBtnClick() {
    // if (this.addEditClicked) {
    //   this.formGroupDirective.resetForm();
    //   this.callResetForm();
    // }
    this.gridPage = true;
    this.editEnabled = false;
    this.uploadClicked = false;
    this.addEditClicked = false;
    this.copyDataClicked = false;
    this.removeGrid();
    this.refreshGrid()
  }

 //#region Back to upload
 refreshGrid() {
  let queryVariable = { orgid: this.destOrgid.toString() };
  let queryResult;
  if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
    let query = this.SubMenuCardModel.GetQuery("getqualityattribute");
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
  }
  else {
    let query1 = this.SubMenuCardModel.GetQuery("getflag");
    queryResult = this.commonService.GetCardRequest(queryVariable, query1);
  }
  this.ngxService.start();
  this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(data => {
    this.ngxService.stop();
    if (!data.errors) {
      this.templateData['submenuData'] = data.data.submenuData;
      if (this.addEditClicked) {
        if (this.action === 'new') {
          let res = [];
          res = this.templateData.submenuData
          if ((res) && (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes')) {
            if (res.length > 0) {
              // Create an object to store filtered results
              const filteredData = {};
              res.forEach(item => {
                // If the item category is a duplicate and Isactive is 1, add it to the filtered data
                if (filteredData[item.category] === undefined && (item.groupisactive === 1 || item.groupisactive === true)) {
                  filteredData[item.category] = item;
                }
                // If the item category is a duplicate and Isactive is 0, update the existing entry
                else if (filteredData[item.category] !== undefined && (item.groupisactive === 0 || item.groupisactive === false)) {
                  filteredData[item.category].groupisactive = false;
                }
              });

              // Convert the filteredData object back to an array
              this.categoryList = Object.values(filteredData);

              // this.categoryList = filteredData.map((item) => item.category)
              //   .filter((value, index, self) => (self.indexOf(value) === index) && (value.trim() != ''));
            }
          }
          this.editEnabled = false;
          this.gridPage = false;
          this.uploadClicked = false;
          this.addEditClicked = true;
          this.copyDataClicked = false;
          this.action = '';
        }
      }
      else {
        this.gridPage = true;
        this.editEnabled = false;
        this.uploadClicked = false;
        this.addEditClicked = false;
        this.copyDataClicked = false;
        this.removeGrid();
      }
      if (this.gridPage) {
        if (data.data.submenuData.length > 0) {
          this.addGridData(data.data.submenuData);
        } else {
          this.addGridData([]);
        }
      }
    }
    else {
      this._snackbar.open('Something went wrong! Please refresh the menu', 'Close');
    }
  }, error => {
    this.ngxService.stop();
    console.error(error);
  });
}
//#endregion

  //#region Export Grid data
  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      if (!e._data._gd) {
        delete e._data["associationtype"];
        delete e._data["organizationid"];
        delete e._data["qualityattributeguid"];
        delete e._data["attrid"];
        delete e._data["parentid"];
        delete e._data["id"];
        let booleanDataArray = [
          "diagnosis",
          "ishippa",
          "isactive",
          "processing",
          "grossing",
          "accession",
          "reports",
          "requisition",
          "ismirp",
          "usedforqc",
          "iscomment",
          "allowexternalsync",
          "stage"
        ]
        let excelData = {}
        let gridData = cloneDeep(e._data)

        delete gridData["_gd"]
        delete gridData["_name"]
        delete gridData["_level"]
        delete gridData["_isBottomLevel"]
        delete gridData["_groups"]
        delete gridData["_items"]
        delete gridData["_path"]
        let ignoreKeys = ['slno', 'attrid', 'tablename', 'organizationid', 'associationtype'];

        for (let key of Object.keys(this.excelDownloadArray[0])) {
          if (!ignoreKeys.some(va => va.toString().toLowerCase().trim() == key.toString().toLowerCase().trim())) {
            if (booleanDataArray.some(va => va.toString().toLowerCase().trim() == key.toString().toLowerCase().trim())) {
              excelData[key] = (((gridData[key]) && gridData[key].toString().toLowerCase() == 'true') || (gridData[key] == 1)) ? 1 : 0;
            }
            else {
              excelData[key] = gridData[key];
            }
          }
        }
        if (this.copyDataClicked) {
          delete e._data["roles"];
        }
        excel.push(excelData);
      }

    });

    for (let i = 0; i < excel.length; i++) {
      delete excel[i].roles;
    }
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      let filename = 'Quality Attributes_' + this.destOrgid.toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(excel.reverse());
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'quality attributes');
      XLSX.writeFile(wb, filename);
    }
    else {
      let filename = 'Flags_' + this.destOrgid.toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(excel.reverse());
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'flags');
      XLSX.writeFile(wb, filename);
    }
  }
  //#endregion

  //#region
  ExportGridToExcel(flex) {
    //this.headerName = false;
    let grid;
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      grid = new wjcGrid.FlexGrid(wjcCore.createElement(`<div></div>`), {
        autoGenerateColumns: false,
        itemsSource: this.gridData,
        columns: [
          { binding: 'category', header: 'Group Name' },
          { binding: 'attrname', header: 'Name' },
          { binding: 'attrdescription', header: 'Description' },
          { binding: 'attrorder', header: 'Sequence' },
          { binding: 'grouporder', header: 'Group Sequence' },
          { binding: 'rolename', header: 'Role' },
          { binding: 'Status', header: 'Status' },
          { binding: 'attrid', header: 'attrid'}
        ]
      })
    } else {
      grid = new wjcGrid.FlexGrid(wjcCore.createElement(`<div></div>`), {
        autoGenerateColumns: false,
        itemsSource: this.gridData,
        columns: [
          { binding: 'attrname', header: 'Name' },
          { binding: 'attrorder', header: 'Sequence' },
          // { binding: 'rolename', header: 'Role' },
          { binding: 'Status', header: 'Status' },
          { binding: 'attrid', header: 'attrid'}
        ]
      })
    }
    const view = flex.collectionView;
    let oldPgSize = view.pageSize, oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(grid,
        {
          includeCellStyles: false,
          includeColumnHeaders: true,
          sheetName: "QualityAttributes"
        },
        "Quality Attributes_" + this.destOrgid + ".xlsx",
        saved => {
          view.pageSize = oldPgSize;
          view.moveToPage(oldPgIndex);
          flex.endUpdate();
        },
        null
      );
    } else {
      wjcGridXlsx.FlexGridXlsxConverter.saveAsync(grid,
        {
          includeCellStyles: false,
          includeColumnHeaders: true,
          sheetName: "Flags"
        },
        "Flags_" + this.destOrgid + ".xlsx",
        saved => {
          view.pageSize = oldPgSize;
          view.moveToPage(oldPgIndex);
          flex.endUpdate();
        },
        null
      );
    }
  }
  //#endregion


  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field(s) missing!' || value == 'Null values exist!' || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('already exists')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('added') || value == 'Success' || value.toString().includes('updated') || value.toString().includes('created')) {
          wjCore.toggleClass(e.cell, 'high-value', true);

        }
        else if ((value.toString().includes(this.templateData.menuURL + 'already exists')) || value.toString().includes('Duplicate Attribute name') || value == 'Ignored' || value.toString().includes('not found in Flags')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  // goEditQA()
  addQA() {
    let res = [];
    this.name = this.templateData.menuURL.toString();
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      this.name = this.name.substring(0, this.name.length - 1);
      this.headerName = true;
    }
    else {
      this.name = this.name.substring(0, this.name.length - 1);
      this.headerName = false;
    }
    res = this.templateData.submenuData
    if ((res) && (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes')) {
      if (res.length > 0) {
        const filteredData = {};
        res.forEach(item => {
          // If the item name is a duplicate and Isactive is 1, add it to the filtered data
          if (filteredData[item.category] === undefined && (item.groupisactive === 1 || item.groupisactive === true)) {
            filteredData[item.category] = item;
          }
          // If the item category is a duplicate and Isactive is 0, update the existing entry
          else if (filteredData[item.category] !== undefined && (item.groupisactive === 0 || item.groupisactive === false)) {
            filteredData[item.category].groupisactive = false;
          }
        });

        // Convert the filteredData object back to an array
        this.categoryList = Object.values(filteredData);

        // this.categoryList = filteredData.map((item) => item.category)
        //   .filter((value, index, self) => ((self.indexOf(value) === index) && (value.trim() != '')));
      }
    }
    this.gridPage = false;
    this.uploadClicked = false;
    this.addEditClicked = true;
    this.callResetForm();
    this.getUserRoles(this.destDeployment);
  }

  //#region checks if the form is dirty
  isFormModified() {
    let formControls = this.formGroupQA.controls;
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      if (formControls.frmGname.dirty || formControls.frmAname.dirty
        || formControls.frmAdescription.dirty || formControls.frmRole.dirty
        || formControls.frmActive.dirty
        || formControls.frmAccession.dirty
        || formControls.frmAllowSync.dirty
        || formControls.frmComments.dirty
        || formControls.frmDiagnosis.dirty
        || formControls.frmFinalized.dirty
        || formControls.frmGrossing.dirty
        || formControls.frmIshippa.dirty
        || formControls.frmIsmirp.dirty
        || formControls.frmProcessing.dirty
        || formControls.frmRequisition.dirty
        || formControls.frmStage.dirty
        || formControls.frmUsedforqc.dirty
        || formControls.frmAsequenece.dirty
        || formControls.frmGsequence.dirty
        || this.selectAll.dirty
      ) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (formControls.frmAname.dirty
        // || formControls.frmFlagRole.dirty
        || formControls.frmActive.dirty
        || formControls.frmAdescription.dirty
        || formControls.frmAsequenece.dirty
      ) {
        return true
      }
      else {
        return false
      }
    }
  }
  //#endregion

  validateForm(action: string, data: any) {
    const { frmAsequenece, frmGsequence } = data;
    if (this.editEnabled) {
      let flag = this.isFormModified();
      if (flag) {
        if (this.checkEmpties(data)) {
          this._snackbar.open('Please enter all the required fields before saving!', 'Close');
          return;
        }
        if (frmAsequenece && !frmAsequenece.toString().match(/^[0-9]+$/g)) {
          this._snackbar.open('Attribute Sequence accepts only numbers as input', 'Close');
          return;
        }
        if (frmGsequence && !frmGsequence.toString().match(/^[0-9]+$/g)) {
          this._snackbar.open('Group Sequence accepts only numbers as input', 'Close');
          return;
        }
        this.saveQA(action, data);
      }
      else {
        this.formcontrol["frmAname"].setErrors(null);
        this._snackbar.open('Please change at least one field before updating', 'Close');
        return;
      }
    } else {
      if (this.checkEmpties(data)) {
        this._snackbar.open('Please enter all the required fields before saving!', 'Close');
        return;
      }
      if (frmAsequenece && !frmAsequenece.toString().match(/^[0-9]+$/g)) {
        this._snackbar.open('Attribute Sequence accepts only numbers as input', 'Close');
        return;
      }
      if (frmGsequence && !frmGsequence.toString().match(/^[0-9]+$/g)) {
        this._snackbar.open('Group Sequence accepts only numbers as input', 'Close');
        return;
      }
      this.saveQA(action, data);
    }
  }
  ConvertToList(ObjectArray) {
    const plainObject = {};
    for (const [key, value] of Object.entries(ObjectArray)) {
      plainObject[key] = value;
    }
    return plainObject;
  }
  //#region insert/update individual data

  saveQA(action, data) {
    let roles=[];
    let flag = true;
    let ObjectArray:any=[];

    const isQualityAttributes = this.templateData.menuURL.toString().toLowerCase() === 'quality attributes';

    if (isQualityAttributes) {
      if (data.frmRole.length === 0) {
        this._snackbar.open("Please select at least one Role", "Close");
        return;
      }
      else{
        if (flag == true) {
          //roles = '';
          if (data.frmRole.length > 0) {
            for (let i = 0; i < data.frmRole.length; i++) {
              // roles += data.frmRole[i].Role_Name + ','
              if (data.frmRole[i].Role_Name != undefined) {
                roles.push(data.frmRole[i].Role_Name)
              }else if(data.frmRole[i] != undefined){
                roles.push(data.frmRole[i])
              }

              if (data.frmRole[i].Role_Name != undefined) {
                flag = false;
              }else if(data.frmRole[i] != undefined){
                flag = false;
              }
            }
          }
        }
      }
    }

    class QualityAttributesConstructor extends QualityAttributes {
      constructor() {
        super();
        this.organizationid = sessionStorage.getItem('org_id');
        this.tablename = "qualityattributes";
        this.slno = 1;
      }
    }

    if (this.editEnabled) {
      //let role = flag ? '' : roles.toString();
      ObjectArray = new QualityAttributesConstructor();
      ObjectArray.roles = flag ? '' :roles.toString();
      ObjectArray.associationtype = this.Orgtype[0].associationtype
      ObjectArray.diagnosis = data.frmDiagnosis ? 1 : 0
      ObjectArray.processing = data.frmProcessing ? 1 : 0
      ObjectArray.grossing = data.frmGrossing ? 1 : 0
      ObjectArray.accession = data.frmAccession ? 1 : 0
      ObjectArray.reports = data.frmFinalized ? 1 : 0
      ObjectArray.category = data.frmGname
      ObjectArray.attrid = (this.selectedrowData) ? this.selectedrowData.attrid : null
      ObjectArray.attrdescription = data.frmAdescription
      ObjectArray.attrorder = data.frmAsequenece ? data.frmAsequenece : 0;
      ObjectArray.attrname = data.frmAname
      ObjectArray.ismirp = data.frmIsmirp ? 1 : 0
      ObjectArray.ishippa = data.frmIshippa ? 1 : 0
      ObjectArray.requisition = data.frmRequisition ? 1 : 0
      ObjectArray.isactive = ((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      ObjectArray.usedforqc = data.frmUsedforqc ? 1 : 0
      ObjectArray.grouporder = data.frmGsequence ? data.frmGsequence :null
      ObjectArray.iscomment = data.frmComments ? 1 : 0
      ObjectArray.allowexternalsync = data.frmAllowSync ? 1 : 0
      ObjectArray.stage = data.frmStage ? 1 : 0
      ObjectArray.parentid = this.parentID

    } else {
      //let roles = flag ? '' :roles.toString();
      ObjectArray = new QualityAttributesConstructor();
      ObjectArray.roles = flag ? '' :roles.toString();
      ObjectArray.associationtype = this.Orgtype[0].associationtype
      ObjectArray.organizationid = sessionStorage.getItem('org_id')
      ObjectArray.diagnosis = data.frmDiagnosis ? 1 : 0
      ObjectArray.processing = data.frmProcessing ? 1 : 0
      ObjectArray.grossing = data.frmGrossing ? 1 : 0
      ObjectArray.accession = data.frmAccession ? 1 : 0
      ObjectArray.reports = data.frmFinalized ? 1 : 0
      ObjectArray.category = data.frmGname
      ObjectArray.attrid = (this.selectedrowData) ? this.selectedrowData.attrid : null
      ObjectArray.attrdescription = data.frmAdescription
      ObjectArray.attrorder = data.frmAsequenece ? data.frmAsequenece : 0;
      ObjectArray.attrname = data.frmAname
      ObjectArray.ismirp = data.frmIsmirp ? 1 : 0
      ObjectArray.ishippa = data.frmIshippa ? 1 : 0
      ObjectArray.requisition = data.frmRequisition ? 1 : 0
      ObjectArray.isactive = ((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      ObjectArray.usedforqc = data.frmUsedforqc ? 1 : 0
      ObjectArray.grouporder = data.frmGsequence ? data.frmGsequence :null
      ObjectArray.iscomment = data.frmComments ? 1 : 0
      ObjectArray.allowexternalsync = data.frmAllowSync ? 1 : 0
      ObjectArray.stage = data.frmStage ? 1 : 0
    }

    if (!isQualityAttributes && !this.editEnabled) {
      class FlagObjectArrayConstructor extends QualityAttributes {
        constructor() {
          super();
          this.organizationid = sessionStorage.getItem('org_id');
          this.category = "Flags";
          this.tablename = "qualityattributes";
          this.slno = 1;
        }
      }

      ObjectArray = new FlagObjectArrayConstructor();
      ObjectArray.associationtype = this.Orgtype[0].associationtype
      ObjectArray.organizationid = sessionStorage.getItem('org_id')
      ObjectArray.attrdescription = data.frmAname
      ObjectArray.attrorder = data.frmAsequenece ? data.frmAsequenece : 0;
      ObjectArray.attrname = data.frmAname
      ObjectArray.isactive = ((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      ObjectArray.entitytype = "Role"
      ObjectArray.attrtype = "checkbox"
      ObjectArray.defaultvalue = "Checked"
    }

    if (action === 'new' || !this.editEnabled) {
      this.action = action;
      this.excelDataArray=[]
      this.excelDataArray.push(this.ConvertToList(ObjectArray));
      if (isQualityAttributes) {
        this.validateDuplicates(this.excelDataArray);
      } else {
        this.uploadData();
      }
    } else {
      this.excelDataArray=[]
      this.excelDataArray.push(this.ConvertToList(ObjectArray));
      if (isQualityAttributes) {
        this.validateDuplicates(this.excelDataArray);
      } else {
        this.updateQA(this.excelDataArray);
      }
    }
  }

  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue && fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName == 'sequence') {
      if (fieldValue && fieldValue.toString().replace(/\s+/g, '').length > 0) {
        if (!fieldValue.toString().match(/^[0-9]+$/g)) {
          return 'Enter only numbers';
        }
      }
    }
    else if (fieldName.match(/Gsequence/i)) {
      if (fieldValue && fieldValue.toString().replace(/\s+/g, '').length > 0) {
        if (!fieldValue.toString().match(/^[0-9]+$/g)) {
          return 'Enter only numbers';
        }
      }
    }
  }
  //#region

  getUserRoles(dbName) {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getuserrolesforassociationtype');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0].associationtype + "\"";
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.RoleList = res.data.vwGetRolesBasedOnAccountAssociation_list;
          if (this.editEnabled) {
            this.userRoleList = [];
            this.userRoleList = res.data.vwGetRolesBasedOnAccountAssociation_list;
            this.getInputData();
          }
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            this.RoleList.push({ displayname: "ALL" })
            this.RoleList.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0);
          }
          if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
            this.dropdownSettings = {
              singleSelection: false,
              idField: 'Role_Name',
              textField: 'Role_Name',
              enableCheckAll: true,
              allowSearchFilter: true,
              limitSelection: -1,
              clearSearchFilter: true,
              maxHeight: 150,
              itemsShowLimit: 3,
              closeDropDownOnSelection: false,
              showSelectedItemsAtTop: false,
              defaultOpen: false
            };
          }
          else {
            this.dropdownSettings = {
              singleSelection: false,
              idField: 'Role_Name',
              textField: 'Role_Name',
              enableCheckAll: true,
              allowSearchFilter: true,
              limitSelection: -1,
              clearSearchFilter: true,
              maxHeight: 150,
              itemsShowLimit: 44,
              closeDropDownOnSelection: false,
              showSelectedItemsAtTop: false,
              defaultOpen: false
            };
          }

          this.userDropdownList2 = [];
          this.RoleList.forEach(element => {
            if (element.displayname && element.displayname.toString().toLowerCase().trim() != 'all') {
              const temp = {
                'Role_Name': element.displayname
              }
              this.userDropdownList2.push(temp);
            }
          });
          // if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
          //   this.formGroupQA.patchValue({
          //     frmRole: [{ displayname: "ALL" }]
          //   });
          // }
          // if (!this.editEnabled) {
          //   this.formGroupQA.patchValue({
          //     frmFlagRole: this.userDropdownList2
          //   });
          // }
          this.selectingAll(this.formGroupQA.value)
        }
      }
    }, error => {
      this.ngxService.stop();
      console.log(error)
    })
  }

  //#endregion

  onCheckChange(item: any) {
    this.RoleListname.push(item.Role_Name);
  }

  onSelectAll(items: any) {
    this.RoleListname = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.RoleListname.push(items[itr].Role_Name);
    }
  }

  onItemDeSelect(items: any) {
    const index: number = this.RoleListname.indexOf(items.Role_Name);
    if (index !== -1) {
      this.RoleListname.splice(index, 1);
    }
  }
  public onDeSelectAll() {
    this.RoleListname = [];
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.matSelectOption.options.forEach((item: MatOption) => item.select());
    } else {
      this.matSelectOption.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus2 = true;
    this.matSelectOption.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus2 = false;
      }
    });
    this.allSelected = newStatus2;
  }

  get formcontrol() {
    return this.formGroupQA.controls;
  }

  callResetForm() {
    this.allSelected = false
    this.formGroupQA.reset();
    this.formcontrol['frmAname'].setErrors(null);
    this.parentID = null;
    this.formGroupQA.patchValue({
      frmGsequence: "",
      frmAsequenece: "",
      frmActive: true,
      frmGname: "",
      frmAname: "",
      frmAdescription: "",
      frmRole: [""],
      // frmFlagRole: this.userDropdownList2,
      frmRequisition: false,
      frmAccession: false,
      frmGrossing: false,
      frmProcessing: false,
      frmDiagnosis: false,
      frmFinalized: false,
      frmComments: false,
      frmIshippa: false,
      frmIsmirp: true,
      frmUsedforqc: false,
      frmStage: false,
      frmAllowSync: false,
    })
  }

  filterCategories(value) {
    this.editabled = true;
    if (!value) {
      return this.categoryList
    }
    let catgoryData = [];
    catgoryData = this.categoryList
    if (catgoryData && catgoryData.length > 0) {
      return catgoryData.filter(va => va.category && va.category.toString().toLowerCase().includes(value.toLowerCase()));
    }
    else {
      return [];
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  applyValidation(arr) {
    let alertMessage = '';
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      arr.map(e => { e.category = 'Flags' });
    }
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      if (arr[0].notes.includes('Flags already exists')) {
        alertMessage = "Flags with the same name already exists under selected Group. Do you still want to continue adding the same?"
      } else if (arr[0].notes === 'New Group will be added on approval') {
        alertMessage = "New Group will be added on approval. Do you still want to continue?";
      }
    }
    else {
      if (arr[0].notes.includes('Quality attribute already exists')) {
        alertMessage = "Quality Attributes with the same name already exists under selected Group. Do you still want to continue adding the same?"
      } else if (arr[0].notes === 'New Group will be added on approval') {
        alertMessage = "New Group will be added on approval. Do you still want to continue?";
      }
    }
    if (alertMessage) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "", message: "", alert: alertMessage, continue: "Yes", cancel: "No" }
      });

      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.excelDataArray = [];
          this.excelDataArray = arr;
          this.uploadData();
        }
        else {
          return
        }
      }, error => {
        console.error(error);
      });
    }
    else {
      this.excelDataArray = [];
      this.excelDataArray = arr;
      this.uploadData();
    }
  }

  //#region validate empty data
  checkEmpties(data) {
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      if (//!(/\s/g.test(data.frmGname)) || !(/\s/g.test(data.frmAname)) || !(/\s/g.test(data.frmAdescription)) ||
        data.frmGname === "" || data.frmAname === "" || data.frmAdescription === "" || data.frmRole.length === 0) {
        return true;
      }
      return false;
    } else {
      if (//!(/\s/g.test(data.frmGname)) || !(/\s/g.test(data.frmAname)) || !(/\s/g.test(data.frmAdescription)) ||
        data.frmAname === "") {
        return true;
      }
      return false;
    }

  }
  //#endregion

  updateQA(dataArray) {
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      this.ngxService.start();
      this.VitalHttpServices.UpdateQualityAttributes(dataArray, this.destDeployment).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.Success) {
            this._snackbar.open('Data updated successfully', 'Close');
            this.onBackBtnClick();
            this.formGroupDirective.resetForm();
            this.callResetForm();
            this.auditDetails(this.selectedrowData, dataArray, 'updated');
          }
          else {
            this._snackbar.open('Update failed!', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
    else {
      let Status = dataArray[0]["isactive"] == 0 ? "Inactive" : "Active";
      if (this.editEnabled && this.templateData.submenuData && this.templateData.submenuData.some(va =>
        va.attrname.toString().toLowerCase() == dataArray[0]["attrname"].toString().toLowerCase() && va.attrid.toString() != dataArray[0]["attrid"].toString() && va.isactive.toString() == Status)) {
        this.formcontrol["frmAname"].setErrors(null);
        this._snackbar.open('Flag already existed', 'Close');
        return;
      } else {
        // if (this.templateData.submenuData && this.templateData.submenuData.some(va => va.attrname.toString().toLowerCase() != dataArray[0]["attrname"].toString().toLowerCase())) {
        this.ngxService.start();
        this.VitalHttpServices.UpdateFlags(dataArray, this.destDeployment).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result[0].Data) {
              if (result[0].Data.length > 0) {
                this._snackbar.open('Flag updated successfully', 'Close');
                this.onBackBtnClick();
                this.formGroupDirective.resetForm();
                this.callResetForm();
                this.auditDetails(this.selectedrowData, dataArray, 'updated');
              }
              else {
                this._snackbar.open('Update failed!', 'Close');
              }
            }
          }

        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error)
        })
        // }
      }
    }
  }

  //#region set the data for edit form
  openEditScreen(grid, event) {
    this.editEnabled = true;
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.selectedrowData = this.templateData.submenuData.find(e => e.attrid == selectedrowData.attrid)
    }
    this.addQA();
  }
  //#endregion

  //#region patch the data in edit form
  getInputData() {
    let user = []
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      user = this.selectedrowData.rolename.split(', ');
    }
    else {
      user = this.selectedrowData.rolename ? this.selectedrowData.rolename.split(',') : null;
    }
    // this.userRoleList.filter(r => {
    //   this.templateData.submenuData.find(e => {
    //     if ((r.rolename.toLowerCase() == e.rolename.toLowerCase()) && e.attrid == this.selectedrowData.attrid) { user.push(r.displayname); }
    //   });
    // });
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      this.formGroupQA.patchValue({
        frmGsequence: this.selectedrowData.grouporder,
        frmAsequenece: this.selectedrowData.attrorder,
        frmActive: ((this.selectedrowData.isactive) && (this.selectedrowData.isactive.toString().toLowerCase() == 'active')) ? true : false,
        frmGname: this.selectedrowData.category,
        frmAname: this.selectedrowData.attrname,
        frmAdescription: this.selectedrowData.attrdescription,
        frmRole: user,
        frmRequisition: (this.selectedrowData.requisition) ? true : false,
        frmAccession: (this.selectedrowData.accession) ? true : false,
        frmGrossing: (this.selectedrowData.grossing) ? true : false,
        frmProcessing: (this.selectedrowData.processing) ? true : false,
        frmDiagnosis: (this.selectedrowData.diagnosis) ? true : false,
        frmFinalized: (this.selectedrowData.reports) ? true : false,
        frmComments: (this.selectedrowData.iscomment) ? true : false,
        frmIshippa: (this.selectedrowData.ishippa) ? true : false,
        frmIsmirp: (this.selectedrowData.ismirp) ? true : false,
        frmUsedforqc: (this.selectedrowData.usedforqc) ? true : false,
        frmStage: (this.selectedrowData.stage && this.selectedrowData.stage != "0") ? true : false,
        frmAllowSync: (this.selectedrowData.allowexternalsync) ? true : false,
      })
      this.parentID = this.selectedrowData.parentid;
    }
    else {
      this.formGroupQA.patchValue({
        frmGsequence: this.selectedrowData.grouporder,
        frmAsequenece: this.selectedrowData.attrorder,
        frmActive: ((this.selectedrowData.isactive) && (this.selectedrowData.isactive.toString().toLowerCase() == 'active')) ? true : false,
        frmGname: this.selectedrowData.category,
        frmAname: this.selectedrowData.attrname,
        frmAdescription: this.selectedrowData.attrdescription,
        // frmFlagRole: user,
        frmRequisition: (this.selectedrowData.requisition) ? true : false,
        frmAccession: (this.selectedrowData.accession) ? true : false,
        frmGrossing: (this.selectedrowData.grossing) ? true : false,
        frmProcessing: (this.selectedrowData.processing) ? true : false,
        frmDiagnosis: (this.selectedrowData.diagnosis) ? true : false,
        frmFinalized: (this.selectedrowData.reports) ? true : false,
        frmComments: (this.selectedrowData.iscomment) ? true : false,
        frmIshippa: (this.selectedrowData.ishippa) ? true : false,
        frmIsmirp: (this.selectedrowData.ismirp) ? true : false,
        frmUsedforqc: (this.selectedrowData.usedforqc) ? true : false,
        frmStage: (this.selectedrowData.stage && this.selectedrowData.stage != "0") ? true : false,
        frmAllowSync: (this.selectedrowData.allowexternalsync) ? true : false,
      })

    }
    this.gridPage = false;
    this.addEditClicked = true;
    this.selectingAll(this.formGroupQA.value)
  }
  //#endregion

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLowerCase() == keyword.toLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  //#region on copy button click
  copyOrg() {
    if (this.templateData.menuURL.toString().toLowerCase() != 'quality attributes') {
      this.headerName = true;
    }
    else {
      this.headerName = false;
    }
    this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
    this.srcDeployment = this.destDeployment
    this.copyDataClicked = true;
    this.editEnabled = false;
    this.gridPage = false;
    this.uploadClicked = false;
    this.addEditClicked = false;
    this.postUpload = false;
    this.backBtn = true;
    this.srcOrgid = ''
    this.copyFormGroup.reset()
    this.copyFormGroup.patchValue({
      frmOrganization: "",
      frmRole: "",
      frmDepKey: this.destDeployment,
    })
    this.getListOrg()
  }
  //#endregion

  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.searchInput = '';
      this.searchUserInput = '';
      this.usertoggle = false;
      this.searchResult = []
      this.searchRoleList = []
      this.removeGrid();
      this.gridWidth = -1
      this.getListOrg();
    }
  }

  //#region copy-from-entity: orglist
  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.searchRoleList = [];
    this.searchUserInput = '';
    if (value === '') {
      this.searchRoleList = [];
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }
  //#endregion

  //#region copy-from-entity: userlist
  fetchRoleSeries(value: string) {
    this.searchRoleList = []
    if (value === '') {
      return (this.searchRoleList = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.RoleList.find(r => {
          if (r.displayname.toString().toLowerCase() === value.toLowerCase()) {
            this.srcRole = r.displayname
            this.usertoggle = false;
            return r
          } else {
            this.srcRole = ''
            this.usertoggle = true
          }
        })
        this.searchRoleList = this.RoleList.filter(function (series) {
          if (series && series.displayname != null) {
            return series.displayname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchRoleList = this.searchRoleList.splice(0, 25);
      }
    }
    else {
      this.searchRoleList = this.RoleList.filter(function (series) {
        if (series && series.roleid != null) {
          return series.roleid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchRoleList = this.searchRoleList.splice(0, 25);
    }
  }
  //#endregion

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.srcRole = '';
      this.searchUserInput = '';
      this.searchRoleList = [];
      this.srcOrgid = data.organizationid;
      this.searchInput = data.organizationname;
      this.getUserRoles(this.srcOrgid);
      if (this.templateData.menuURL.toString().toLowerCase() !== 'quality attributes') {
        this.getDataToCopy();
      }
    }
  }

  disableGetDataBtn() {
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      return (!this.searchInput || !this.searchUserInput) ? true : ((this.srcOrgid) || (this.srcRole)) != '' ? false : true;
    }
    else {
      return (!this.searchInput) ? true : (this.srcOrgid) != '' ? false : true;
    }
  }

  //#region Get data to copy-from-other-entity
  getDataToCopy() {
    this.checkStatus = false;
    let queryVariable;
    let queryResult;
    let query;
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {

      if (this.srcRole.toString().toLowerCase() == 'all') {
        queryVariable = { orgid: this.srcOrgid.toString() };
        query = this.SubMenuCardModel.GetQuery("getqualityattribute");
      }
      else {
        queryVariable = { orgid: this.srcOrgid.toString(), role: this.srcRole };
        query = this.SubMenuCardModel.GetQuery("getqualityattributecopyorg");
        // queryResult = this.commonService.GetCardRequest(queryVariable, query);
      }
      queryResult = this.commonService.GetCardRequest(queryVariable, query);
    }

    else {
      queryVariable = { orgid: this.srcOrgid.toString() };
      let query1 = this.SubMenuCardModel.GetQuery("getflagcopyorg");
      queryResult = this.commonService.GetCardRequest(queryVariable, query1);
    }

    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.removeGrid()
        this.gridWidth = -1;
        this.editEnabled = false;
        this.gridPage = false;
        this.uploadClicked = false;
        this.addEditClicked = false;
        this.postUpload = false;
        this.backBtn = true;
        this.uploadClicked = false
        if (!this.usertoggle) {
          if (data.data.submenuData.length > 0) {
            this.backBtn = false
            let userSpecificData = [];
            if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
              userSpecificData = data.data.submenuData.filter(res => res.parentid && res.parentid != null)
              if (this.copyDataClicked) {
                userSpecificData = userSpecificData.map(va => ({ ...va, rolename: 'ALL' }));
                userSpecificData = userSpecificData.filter(data => data.isactive == true || data.isactive == 1 || data.isactive == 'Active')
                  .map(va => ({ ...va, isactive: true }))
              }
            } else {
              userSpecificData = [...data.data.submenuData]
            }
            if (userSpecificData.length > 0) {
              this.manageDataColumn(userSpecificData);
            }
            else {
              this.backBtn = true
              this.excelGridData([]);
            }
          }
          else {
            this.backBtn = true
            this.excelGridData([]);
          }
        } else {
          this._snackbar.open("Please enter valid user", "Close");
          return
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error)
    })
  }
  //#endregion

  manageDataColumn(data) {
    var i;
    for (i = 0; i < data.length; i++) {
      delete data[i].organizationid
      delete data[i].roleid
      delete data[i].rolename
      delete data[i].displayname;
      delete data[i]['groupisactive'];
      if (this.copyDataClicked) {
        data[i]['attrid'] = null
      }
    }

    this.sheetHeader = Object.keys(data[0]);
    this.sheetHeader.reverse();
    this.sheetHeader.push("Notes")
    this.sheetHeader.push("Status");

    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    this.excelDownloadArray = [...this.excelDataArray]
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      this.excelDataArray.find(d => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'qualityattributes',
          roles: this.srcRole,
          // isactive: (d.isactive) ? d.isactive : null,
          organizationid: Number(sessionStorage.getItem('org_id')), associationtype: this.Orgtype[0].associationtype
        });
        i++;
      });
    }
    else {
      this.excelDataArray.find(d => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'qualityattributes',
          roles: this.srcRole,
          // isactive: (d.isactive) ? 1 : 0,
          organizationid: Number(sessionStorage.getItem('org_id')), associationtype: this.Orgtype[0].associationtype
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcRole = data.displayname;
      // this.srcUserName = data.username;
      this.searchUserInput = data.displayname;
    }
  }

  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  uploadCopyData(flexgrid) {
    let tempData = [];
    tempData = [...this.excelDataArray];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    this.excelDataArray = [];
    // if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
    //   for (let i = 0; i < this.selectedItems.length; i++) {
    //     if (!this.selectedItems[i]._data.parentid) {
    //       if (tempData && tempData.length > 0) {
    //         for (let j = 0; j < tempData.length; j++) {
    //           if (tempData[j].parentid && tempData[j].parentid == this.selectedItems[i]._data.attrid) {
    //             this.excelDataArray.push(tempData[j])
    //           }
    //           else{
    //             this.excelDataArray.push(this.selectedItems[i]._data);
    //           }
    //         }
    //       }
    //       else {
    //         this.excelDataArray.push(this.selectedItems[i]._data);
    //       }
    //     }
    //     else {
    //       this.excelDataArray.push(this.selectedItems[i]._data);
    //     }
    //   }
    // } else {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    // }
    this.uploadData();
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = ''
    msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';

    let oldObject = {}
    if (this.templateData.menuURL.toString().toLowerCase() == 'quality attributes') {
      if (Object.keys(oldObj).length > 0) {
        /*Update record*/
        oldObject = {
          "organizationid": sessionStorage.getItem('org_id')
          , "diagnosis": oldObj.diagnosis ? 1 : 0
          , "processing": oldObj.processing ? 1 : 0
          , "grossing": oldObj.grossing ? 1 : 0
          , "accession": oldObj.accession ? 1 : 0
          , "reports": oldObj.reports ? 1 : 0
          , "category": oldObj.category
          , "attrid": (this.selectedrowData) ? this.selectedrowData.attrid : null
          , "attrdescription": oldObj.attrdescription
          , "attrorder": oldObj.attrorder
          , "attrname": oldObj.attrname
          , "ismirp": oldObj.ismirp ? 1 : 0
          , "ishippa": oldObj.ishippa ? 1 : 0
          , "requisition": oldObj.requisition ? 1 : 0
          , "isactive": ((oldObj.isactive) || (oldObj.isactive.toString().toLowerCase() == 'active')) ? 1 : 0
          , "usedforqc": oldObj.usedforqc ? 1 : 0
          , "grouporder": oldObj.grouporder
          , "iscomment": oldObj.iscomment ? 1 : 0
          , "allowexternalsync": oldObj.allowexternalsync ? 1 : 0
          , "stage": oldObj.stage ? 1 : 0
        }
        this.ngxService.start();
        this.commonService.createActivityTracker(msgActivityTracker, oldObj.attrid, 'Quality Attributes', 'Audit', newObjArray[0], oldObject);
        this.ngxService.stop();
      } else {
        /*New records*/
        this.ngxService.start();
        for (let i = 0; i < newObjArray.length; i++) {
          this.commonService.createActivityTracker(msgActivityTracker, newObjArray[i].ID, 'Quality Attributes', 'Audit', newObjArray[i], {});
        }
        this.ngxService.stop();
      }
    }
    else {
      if (Object.keys(oldObj).length > 0) {
        /*Update record*/
        oldObject = {
          "organizationid": sessionStorage.getItem('org_id')
          , "diagnosis": oldObj.diagnosis ? 1 : 0
          , "processing": oldObj.processing ? 1 : 0
          , "grossing": oldObj.grossing ? 1 : 0
          , "accession": oldObj.accession ? 1 : 0
          , "reports": oldObj.reports ? 1 : 0
          , "category": oldObj.category
          , "attrid": (this.selectedrowData) ? this.selectedrowData.attrid : null
          , "attrdescription": oldObj.attrdescription
          , "attrorder": oldObj.attrorder
          , "attrname": oldObj.attrname
          , "ismirp": oldObj.ismirp ? 1 : 0
          , "ishippa": oldObj.ishippa ? 1 : 0
          , "requisition": oldObj.requisition ? 1 : 0
          , "isactive": ((oldObj.isactive) || (oldObj.isactive.toString().toLowerCase() == 'active')) ? 1 : 0
          , "usedforqc": oldObj.usedforqc ? 1 : 0
          , "iscomment": oldObj.iscomment ? 1 : 0
          , "allowexternalsync": oldObj.allowexternalsync ? 1 : 0
          , "stage": oldObj.stage ? 1 : 0
        }
        this.ngxService.start();

        this.commonService.createActivityTracker(msgActivityTracker, oldObj.attrid, 'Flags', 'Audit', newObjArray[0], oldObject);
        this.ngxService.stop();
      } else {
        /*New records*/
        this.ngxService.start();
        for (let i = 0; i < newObjArray[0].length; i++) {
          this.commonService.createActivityTracker(msgActivityTracker, newObjArray[i].ID, 'Flags', 'Audit', newObjArray[i], {});
        }
        this.ngxService.stop();
      }
    }
  }
  //#endregion

  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
  }

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.formGroupQA.patchValue({
      frmGname: "",
      frmGsequence: ""
    })
    this.inputAutoComplete?.nativeElement.focus();
    this.parentID = null;
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    this.editabled = true;
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  selectAllDisplay(event: any) {
    this.editabled = true;
    if (event.checked) {
      this.formGroupQA.patchValue({
        frmRequisition: true,
        frmAccession: true,
        frmGrossing: true,
        frmProcessing: true,
        frmDiagnosis: true,
        frmFinalized: true
      })
    }
    else {
      this.formGroupQA.patchValue({
        frmRequisition: false,
        frmAccession: false,
        frmGrossing: false,
        frmProcessing: false,
        frmDiagnosis: false,
        frmFinalized: false
      })
    }
  }

  selectingAll(value: any) {
    this.editabled = true;
    if (value.frmRequisition && value.frmAccession && value.frmGrossing && value.frmProcessing && value.frmDiagnosis && value.frmFinalized) {
      this.selectAll.setValue(true);
    }
    else {
      this.selectAll.setValue(false);
    }
  }

  // //     //Called once, before the instance is destroyed.
  // //       //Add 'implements OnDestroy' to the class.
  ngOnDestroy(): void {
    this.gridData = new CollectionView([]);
  }

  selectedCategory(event, data) {
    if (event.source.selected && Object.keys(data).length > 0) {
      this.formGroupQA.patchValue({
        frmGname: data.category,
        frmGsequence: data.grouporder
      })
      this.parentID = data.parentid;
    }
  }

  validateDuplicates(objArray) {
    let message = '';
    // let objArray=[];
    // objArray.push(arr)
    if (this.editEnabled) {
      (this.templateData.submenuData && (this.templateData.submenuData.length > 0) && (this.templateData.submenuData.some(
        va =>
          //va.category.toString().trim().toLowerCase() == objArray[0].category.toString().trim().toLowerCase() &&
          va.isactive == ((objArray[0].isactive == 1 || objArray[0].isactive == true) ? 'Active' : 'Inactive') &&
          va.attrname.toString().trim().toLowerCase() == objArray[0].attrname.toString().trim().toLowerCase() &&
          va.attrid != objArray[0].attrid &&
          va.parentid != null
      )))
        ? (message = 'Quality Attribute already exists.')
        : null;
      if (message) {
        this._snackbar.open(message, 'Close');
        return
      }
      else {
        this.updateQA(objArray);
      }
    }
    else {
      ((this.templateData.submenuData && this.templateData.submenuData.length > 0) && (this.templateData.submenuData.some(
        va =>
          va.category.toString().trim().toLowerCase() == objArray[0].category.toString().trim().toLowerCase() &&
          va.isactive == ((objArray[0].isactive == 1 || objArray[0].isactive == true) ? 'Active' : 'Inactive') &&
          va.attrname.toString().trim().toLowerCase() == objArray[0].attrname.toString().trim().toLowerCase()
      )))
        ? (message = 'Quality Attribute already exists.')
        : null;

      if (message) {
        this._snackbar.open(message, 'Close');
        return
      } else {
        this.uploadData();
      }
    }
  }

  backToParent(){
    this.uploadBack.emit(true);
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
