// import { DatePipe } from '@angular/common'
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { Tab } from 'src/app/base/Tab/tab.directive';
// import { Tabset } from 'src/app/base/Tab/tabset.component';
// import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
// import { CardQueries } from '../../../model/card-query';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { MainCardModel } from '../../DbModel/MainCardModel';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';

@Component({
  selector: 'app-licensureinfo',
  templateUrl: './LicensureInfo.component.html',
  styleUrls: ['./LicensureInfo.component.scss']
  // providers: [DatePipe]
})
export class LicensureInfoComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  columnHeader: any[];
  @Input() mainCard: any;

  gridHeader = [];
  UserList = [];
  gridViewShow: boolean = true;

  constructor(private injectorServiceGridContainer: InjectorService, public _snackbar: MatSnackBar,
    public ngxService: NgxUiLoaderService, public vitalHttpServices: VitalHttpServices,
    public VitalMenuComponent: VitalMenuComponent, 
    private commonService: CommonService, public MainCardModel: MainCardModel) {
    super(injectorServiceGridContainer);
  }

  ngOnInit() {
  }
  ngOnChanges() {
    this.gridViewShow = true;
    let primaryRole: string = this.mainCard.Primary_Role;
    if (primaryRole.toLowerCase().trim() === "pathologist") {
      this.gridHeader = [];
      if (this.templateData.labels !== undefined) {
        let labels = JSON.parse(JSON.stringify(this.templateData.labels))
        for (let i = 0; i < labels.length; i++) {
          let column: any;
          // label = this.templateData.labels[i];
          this.gridHeader.unshift(labels[i]);
        }
        this.AddGridData()
      }
    }
  }

  AddGridData() {
    if (this.templateData.submenuData != null) {
      let keyword = this.templateData.Keyword;
      // this.gridHeader = ['Statefullname', 'Expirydate', 'state', 'id'];
      this.gridHeader = ['State_Name', 'Expiry_date', 'State_Code', 'Licensure_ID'];
    }
    let gridarray = []
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      else {
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 0;
        this.gridViewShow = false;
      }

      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * (this.gridHeader.length)) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
    } else {
      this.gridwidth = 0;
      this.gridData = new CollectionView(gridarray, { pageSize: 10 });
      // this.gridwidth = 0;
      this.gridViewShow = false;
    }

  }
  onClick(gridData) {

  }
}
