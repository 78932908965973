import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-bulk-upload-pop-up',
  templateUrl: './bulk-upload-pop-up.component.html',
  styleUrls: ['./bulk-upload-pop-up.component.scss']
})
export class BulkUploadPopUpComponent {
  errorMessage: string = ''
  uploadedFile : any

  constructor(
    private _labAdminService: LabadminService,
    private _dialogRef: MatDialogRef<BulkUploadPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) { }

  onDrop(files: any) {
    if(files.length > 1){
      this.errorMessage = 'Error: Please Upload a Single File.'
    }
    else this.validateFile(files[0]);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    this.validateFile(input.files[0]);
  }

  validateFile(file){
   this.clearSelection()
    const regexp = new RegExp('^xlsm$')      
    var ext = /[^\.]*$/.exec(file.name);
    if ((ext && !regexp.test(ext[0]))) {  
      this.errorMessage = 'Error: File Format Not Supported'
    }
    this.uploadedFile = file
    this.uploadedFile.calculatedSize = `${Math.round(file.size / 1024)}KB`
  }

  downloadSampleTemplate(){
    return this._labAdminService.GetSampleTemplates(this.config.type, this._labAdminService.deploymentKey).subscribe({
      next: (res) => {
        let filename = 'UploadBulkFacilityUsers.xlsm'
        if(this.config.type === 'labusers') filename = 'UploadBulkLabUsers.xlsm'

        importedSaveAs(res, filename)
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  clearSelection(){
    this.uploadedFile = null
    this.errorMessage = ''
  }

  onUploadClick(){
    this._dialogRef.close(this.uploadedFile)
  }
}
