import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Tab } from 'src/app/base/Tab/tab.directive';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';


@Component({
  selector: 'app-vdinformation',
  templateUrl: './vdinformation.component.html',
  styleUrls: ['./vdinformation.component.scss'],
  providers: [DatePipe]
})
export class VDInformationComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: any;


  public vitalDesktopIdentifier;
  public vitalaxisUrl;
  public isActive = false;
  public vitalaxisHandlerUrl;
  public vitalaxisLoginPg;
  public templateVersion: any = "";
  public isAutoUpgradeCompleted = false;
  public autoUpgradedDateTime: any = "";
  public latestVersion: any = "";
  public customerName: any = "";
  public printerType: any = "";
  public pointofContact: any = "";
  public contactNumber: any = "";
  public RequestedBy: any = "";
  public isFacility = false;
  public onTimeTicket: any = "";
  public organizationiD: any = "";
  public customerType: any = "";
  public computerLocation: any = "";
  public systemIP: any = "";
  public adminLoginName: any = "";
  public vdVersion: any = "";
  public labesUsedonComp: any = "";
  public timeConsumedforInstallation: any = "";
  public installationStatus: any = "";
  public environmentKey: any = "";
  public APIKey: any = "";
  public senderAgentId: any = "";
  public SpecificConfgn: any = "";
  public ifSave = true;
  public comments: any = "";
  public resData: any = "";
  public VitalAxisDataURL: any = "";
  public computerName: any = "";
  public systemOS: any = "";
  public AutoUpgradeCompletedDateTime: any = "";
  public subMenuCardModel: any = "";
  public templatesetData: any = "";
  public templatedata: any = "";
  public isDisabled = false;
  public tabs: Array<Tab> = [];
  constructor(private injectorServiceGridContainer: InjectorService, private ngxService: NgxUiLoaderService,
    public _snackbar: MatSnackBar, private tabService: VitalAdminTabService,
    public vitalHttpServices: VitalHttpServices, private commonService: CommonService, public datepipe: DatePipe, private Datahshare: DataShareService) {
    super(injectorServiceGridContainer);
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, Datahshare);
  }

  ngOnInit() {
    if (this.templateData != undefined) {
      this.resData = this.templateData.submenuData[0];
      this.drpdwnValues = this.templateData.templatedropdown;
      this.APIKey = this.resData.apiKey;
      this.RequestedBy = this.resData.RequestedBy;
      this.SpecificConfgn = this.resData.SpecificConfig;
      this.vitalDesktopIdentifier = this.resData.VitalDesktopIdentifier;
      this.VitalAxisDataURL = this.resData.VitalAxisDataURL;
      this.adminLoginName = this.resData.AdminLoginName;
      this.senderAgentId = this.resData.senderagentid;
      this.systemIP = this.resData.SystemIP;
      this.comments = this.resData.Comments;
      this.customerName = this.resData.CustomerName;
      this.contactNumber = this.resData.ContactNumber;
      this.customerType = this.resData.CustomerType;
      // this.contactNumber = this.resData.ContactNumber;
      this.computerLocation = this.resData.ComputerLocation;
      this.installationStatus = this.resData.InstallationStatus;
      this.isFacility = this.resData.IsFacility;
      this.labesUsedonComp = this.resData.LabelsUsedOnThisComputer;
      this.latestVersion = this.resData.LatestVersionAvailable;
      this.vitalaxisLoginPg = this.resData.LoginPage;
      this.onTimeTicket = this.resData.OnTimeTicket;
      this.vitalaxisUrl = this.resData.VitalAxisURL;
      this.organizationiD = this.resData.OrganizationID;
      this.pointofContact = this.resData.PointOfContact;
      this.printerType = this.resData.PrinterType;
      this.RequestedBy = this.resData.RequestedBy;
      this.SpecificConfgn = this.resData.SpecificConfig;
      this.templatesetData = this.resData.TemplateFolder;
      this.timeConsumedforInstallation = this.resData.TimeConsumedForInstallation;
      this.vdVersion = this.resData.VDVersion;
      // this.APIKey = this.resData.apiKey;
      this.environmentKey = this.resData.environmentKey;
      this.senderAgentId = this.resData.senderagentid;
      this.isActive = this.resData.IsActive;
      this.isAutoUpgradeCompleted = this.resData.AutoUpgradeCompleted;
      this.templateVersion = this.resData.TemplateFolder;
      this.AutoUpgradeCompletedDateTime = this.resData.AutoUpgradeCompletedDateTime;
      this.AutoUpgradeCompletedDateTime = this.datepipe.transform(this.AutoUpgradeCompletedDateTime, 'dd-MM-yyyy');
      this.computerName = this.resData.ComputerName;
      this.systemOS = this.resData.SystemOS;
      if (this.templateChangedTime == true) {
        this.isDisabled = true;
        this.ifSave = false;
      }
    }

  }
  removeTab(): void {
    let index = this.tabService.tabs.findIndex(x => x.title == "VDInstallations")
    if (index != -1) {
      this.tabService.removeTab(index);
    }

  }
  public drpdwnValues;

  getDrop() {
    let queryVariable = { "orgId": sessionStorage.getItem("orgid").toString() };
    let query = this.vitalHttpServices.GetQuery("vdinstallationdropdown");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, "configdb").subscribe(data => {
    });

  }

  Method(eve) {
    this.templatedata = eve.target.value;
  }


  addVDID() {

    var obj = {
      "jsonstring": {
        "ID": 0,
        "VitalDesktopID": this.vitalDesktopIdentifier,
        "VitalAxisURL": this.vitalaxisUrl,
        "isActive": this.isActive,
        "LoginPage": this.vitalaxisLoginPg,
        "VitalAxisDataURL": this.VitalAxisDataURL,
        "LatestVersionAvailable": this.latestVersion,
        "AutoUpgradedCompletedDateTime": this.AutoUpgradeCompletedDateTime,
        "AutoUpgradedCompleted": this.isAutoUpgradeCompleted,
        "TemplateFolder": this.templatedata,
        "CustomerName": this.customerName,
        "PointOfContact": this.pointofContact,
        "ContactNumber": this.contactNumber,
        "IsFacility": this.isFacility,
        "RequestedBy": this.RequestedBy,
        "OnTimeTicket": this.onTimeTicket,
        "OrganizationID": this.organizationiD,
        "CustomerType": this.customerType,
        "ComputerLocation": this.computerLocation,
        "ComputerName": this.computerName,
        "SystemOS": this.systemOS,
        "SystemIP": this.systemIP,
        "AdminLoginName": this.adminLoginName,
        "VDVersion": this.vdVersion,
        "LabelsUsedOnThisComputer": this.labesUsedonComp,
        "SpecificConfig": this.SpecificConfgn,
        "Comments": this.comments,
        "PrinterType": this.printerType,
        "TimeConsumedForInstallation": this.timeConsumedforInstallation,
        "InstallationStatus": this.installationStatus,
        "ApiKey": this.APIKey,
        "SenderAgentId": this.senderAgentId,
        "EnvironmentKey": this.environmentKey
      }
    }
    this.ngxService.start();
    //sessionStorage.setItem('deploymentKey', "configdb");
    this.vitalHttpServices.ConfigureVitalDesktop(obj, "configdb").subscribe(data => {
      //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.ngxService.stop();
      let res: any = data;
      if (res.Success) {

        this.vitalHttpServices.setConflictData("success");

        this._snackbar.open("Inserted record successfully!", "Success");
      } else {
        this._snackbar.open(res.Message, 'Failed');
        console.info("error");
      }


    }, error => {
      //sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      console.error(error);
    })

  }
}
