<div *ngIf="templateGroupedDataKeys && subHeader" style="line-height: 25px;">

   <div *ngFor="let template of templateGroupedDataKeys;let i=index" class="card vt-card my-2"
      style="border: 1px solid #0000008f !important;margin-right: 1%;">
      <div>
         <span *ngIf="template" class="_600 ml-2 pull-left">{{template}}</span>
      </div>
      <!-- <a class="templateSection" *ngIf="template">{{template}}</a> -->
      <div *ngFor="let rowsubHeader of templateGroupedData[template];let j=index" class="rowsubHeader">
         <div style="float: left;">
            <p *ngIf="subHeader" class="m-0 ml-3 _500 text-sm"> {{rowsubHeader[subHeader]}}</p>
            <span class="ml-5">
               <span class="label blue mr-2 "> <em class="fa fa-info"></em></span>
               <span *ngIf="subHeader">{{rowsubHeader[templateLables[0]]}} </span>
            </span>

         </div>

         <label class="switch sliderproperties" *ngIf="rowsubHeader.FieldType =='togglebutton'">
            <input type="checkbox"
               *ngIf="(subHeader && subDataResultList && IsValidSubDataResultListItem(rowsubHeader,subHeader))"
               [checked]="IsCheckedSubDataResultListItem(rowsubHeader,subHeader)">
            <span class="slider round"></span>
         </label>
         <span *ngIf="rowsubHeader.FieldType =='textbox'" class="sliderproperties">
            <input type="text" class="mr-2" [disabled]="!rowsubHeader.edit" [ngModel]="GetSubDataListItem(template)"
               #textInput />
            <button class="btn btn-icon btn-rounded blue btn-sm text-white mr-1" (click)="rowsubHeader.edit=true"
               [disabled]="true">
               <!-- [disabled]="textInput.value == 0 ? true : false" -->
               <em class="fa fa-pencil-square-o" aria-hidden="true"></em>
            </button>
            <button class="btn btn-icon btn-rounded blue btn-sm text-white"
               (click)="rowsubHeader.edit=false;UpdateSaveButton(rowsubHeader,rowsubHeader[subHeader],textInput.value)"
               [disabled]="true">
               <!-- [disabled]="textInput.value == 0 ? true : false" -->
               <em class="fa fa-save"></em>
            </button>
         </span>

      </div>
   </div>
</div>
<div class="nodata-wrap mt-4" *ngIf="!templateGroupedDataKeys">
   <div class="nodata-design">No Data Found</div>
</div>