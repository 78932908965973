<div class="submenu-tab bg-white" >
  <ul class="nav flex-row flex-wrap">
    <li class="nav-item px-3  " *ngFor="let item of tabList;let i=index;" (click)="changeTabNav(item)">
      <a
      [ngClass]="getLocation(item.routerLink) ? 'nav-link pb-0 active':'nav-link pb-0'"
      [routerLinkActive]="'active'">{{item.URL}}
      </a>
      <div *ngIf="getLocation(item.routerLink)" class="nav-bar-bottom-menu"></div>
    </li>
  </ul>
</div>


<!-- <mat-tab-group  (selectedTabChange)="tabChanged($event)" >
  <mat-tab *ngFor="let item of tabList; let i = index; let row" >
    <ng-template    mat-tab-label class="cursor_pointer" style="height: 20px!important">
      <span style="font-size:12px;font-weight:600">{{item.URL}}</span>
    </ng-template>
      </mat-tab>
      </mat-tab-group> -->
