import { Component, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent  {

  [x: string]: any;
  isDirty: any;
count =30
  constructor(public dialogRef: MatDialogRef<SessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
     this.clearinter = setInterval(()=>{
          this.count = this.count -1
          if(this.count === 0){
            this.close()
            clearInterval(this.clearinter);
          }
     },1000)
    }

  onNoClick() {
    this.isDirty = false;
    this.dialogRef.close(true);
    this.canDeactivate()
  }

  close() {
    this.isDirty = false;
    this.dialogRef.close(false);
    this.canDeactivate()
  }

  canDeactivate() : boolean {
        return true
  }

}
