<!-- Header -->
<div *ngIf="!sourceApp" class="modal-header admin-model-header mt-3" style="width: calc(100vw - 19vw);">
    <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
            <div
                *ngIf="!createClicked && !editClicked">
                {{templateData.menuURL}}</div>
            <div 
                *ngIf="createClicked || editClicked">
                {{editClicked? 'Edit ' : 'Create '}} {{templateData.menuURL}}</div>
        </strong>
    </h3>
</div>
<!-- <div class="row" *ngIf="sourceApp">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong *ngIf="!createClicked && !editClicked" class="header-View"> {{ templateData.menuURL }} </strong>
                <strong *ngIf="createClicked || editClicked" class="header-View"> 
                    {{editClicked? 'Edit ' : 'Create '}} {{templateData.menuURL}} </strong>
            </h3>
        </div>
    </div>
</div> -->

<!-- tabs and options -->
<div class="container mt-1 ml-0 p-0 manage-auto" *ngIf="!editClicked && !createClicked" style="width: calc(100vw - 19vw);">
    <div class="row m-0 p-0 d-flex align-items-center">
        <div class="col-5 m-0 mt-0 p-0">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item switchingtab" *ngFor="let tab of tabList; let i = index"
                    [ngClass]="{'active': activeTab === i}">
                    <a class="nav-link" (click)="changeTab(tab,i)">{{tab}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Main grid page -->
<div class="col-sm-12 p-0 m-0" *ngIf="!createClicked && !editClicked">
    <app-templates-listing [gridHeader]="gridheader" [gridData]="gridData" [context]="'Auto Assignment Rule'"
        [hideEdit]="false" [hideCreate]="false" [hideUpload]="true" [hideExport]="false" [hideCopy]="true"
        [hideDelete]="false" (CreateTemplateClicked)="createRuleFn($event)" (editTemplateClicked)="editRuleFn($event)"
        [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted"
        [showHeaderModule]="false" (deleteTemplateClicked)="deleteData($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true" 
        (emitFilters)="emitFilters($event)" [isAutoCompleteList]="true">
    </app-templates-listing>
</div>

<div *ngIf="isDelete" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header admin-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                    <!-- <span type="button" id="button_size" data-dismiss="modal" (click)="close()" class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                            title="Close">
                    </span> -->
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message align-center">
                        <div class="popup-title">Are you sure you want to delete this Auto Assignment Rule ?<br>
                            <!-- <span class="popup-subtitle mt-2">This action will erase all data,you cant undo this action</span> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button mat-raised-button class="ct-admin-btn-success-outline" (click)="close()"
                        type="button">Cancel</button>
                    <button mat-raised-button class="admin-btn ct-admin-btn-success" (click)="deleteRuleList()"
                        type="button">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- --Calling Upload Component--- -->
<div
    *ngIf="uplaodOrCopyData && uplaodOrCopyData.length > 0 && uplaodOrCopyData[0]?.isUploadOrCopyFlag && uplaodOrCopyData[0]?.key ==='upload'">
    <!-- <app-common-upload-component></app-common-upload-component> -->

    <div class="col-sm-12 p-0">
        <span class="col-sm-12 align-center">
            <button mat-raised-button class="col-sm-1 ct-admin-btn-success mt-4" (click)="back()">
                Return
            </button>
        </span>
    </div>
</div>

<div
    *ngIf="uplaodOrCopyData && uplaodOrCopyData.length > 0 && uplaodOrCopyData[0]?.isUploadOrCopyFlag && uplaodOrCopyData[0]?.key ==='copy'">
    <!-- <h1>Copy Screen Comes Here</h1> -->
    <div class="col-sm-12 p-0">
        <span class="col-sm-12 align-center">
            <button mat-raised-button class="col-sm-1 ct-admin-btn-success mt-4" (click)="back()">
                Return
            </button>
        </span>
    </div>
</div>

<mat-spinner class="labadmin-spinner"
    *ngIf="sourceApp =='VitalDx' && !stopLoaderDX && (createClicked || editClicked)"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"> </mat-spinner>
<!-- Create/Edit Form -->
<div class="col-sm-12 p-0 m-0"
*ngIf="stopLoaderDX && (createClicked || editClicked)">
    <form class="form-bg" [formGroup]="AARFormGrp" autocapitalize="true" autocomplete="off">
        <div class="col-sm-12 header align-status">
            <span>Status : </span><span [ngClass]="frmStatus.value == 'Inactive' ? 'grey-color' : 'success-color'"> {{
                frmStatus.value}} </span>
        </div>
        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong><span class="text-danger">*</span>Lab Locations</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0" [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedLabs"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Lab')" [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmLabLocations')" matInput
                            [matAutocomplete]="auto" formControlName="frmLabLocations" required>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <div *ngFor="let lab of filteredLabLocation | async">
                                    <mat-option (mousedown)="optionClicked($event, lab, 'Lab'); $event.preventDefault()" [value]="selectedLabLocations">
                                        <mat-checkbox class="mat-checkbox-color-bg" [checked]="lab.selected" (change)="toggleSelection(lab, 'Lab', true)"
                                            (click)="$event.stopPropagation()">
                                            {{ lab.LabName }}
                                        </mat-checkbox>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedLabLocations"
                            [removable]="removable" (removed)="removeChip(location, 'Lab')" [removable]="true">
                            {{ location.LabName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8"><strong><span class="text-danger">*
                    </span>Ordering Facility Locations</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0" [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedOFs"
                        [disabled]="selectedLabLocations.length == 0" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection('OF')" [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmOFLocations')" matInput
                            [matAutocomplete]="autoOFLoc" formControlName="frmOFLocations" required>
                    <mat-autocomplete #autoOFLoc="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let ofLoc of filteredOFLocation | async">
                            <mat-option (mousedown)="optionClicked($event, ofLoc, 'OF')" [value]="selectedOFLocations"
                            [disabled]="selectedLabLocations.length == 0">
                                <mat-checkbox class="mat-checkbox-color-bg"  [checked]="ofLoc.selected" (change)="toggleSelection(ofLoc, 'OF')"
                                    (click)="$event.stopPropagation()">
                                    {{ ofLoc.OrderingFacilityName }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
                </div>
                <div class="row maxHight">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedOFLocations"
                            (removed)="removeChip(location, 'OF')" [removable]="true">
                            {{ location.OrderingFacilityName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>

            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8">
                        <strong><span class="text-danger">*</span>Ordering Facility Physicians</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0" 
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedOFPhysicians"
                        [disabled]="selectedOFLocations.length == 0 || selectedLabLocations.length == 0"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Physician')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmOFPhysicians')" matInput
                            [matAutocomplete]="autoOFPhyc" formControlName="frmOFPhysicians" required>
                    <!-- </mat-form-field> -->

                    <mat-autocomplete #autoOFPhyc="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let Physician of filteredOFPhysician | async">
                            <mat-option (mousedown)="optionClicked($event, Physician, 'Physician')" [value]="selectedOFPhysicians"
                            [disabled]="selectedOFLocations.length == 0 || selectedLabLocations.length == 0">
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="Physician.selected"
                                    (change)="toggleSelection(Physician, 'Physician')"
                                    (click)="$event.stopPropagation()">
                                    {{ Physician.OrderingPhysicianname }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedOFPhysicians"
                            (removed)="removeChip(location, 'Physician')" [removable]="true">
                            {{ location.OrderingPhysicianname }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8">
                        <strong><span class="text-danger">*</span>Case Type</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0" 
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedCasetype"
                        [disabled]="selectedLabLocations.length == 0" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection('Casetype')" [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " matInput [matAutocomplete]="autoC"
                            (click)="triggerEvent('frmCasetypes')" #caseTypeSearch formControlName="frmCasetypes" required>
                    <mat-autocomplete [displayWith]="displayFn" #autoC="matAutocomplete">
                        <mat-optgroup [disabled]="selectedLabLocations.length == 0"
                            *ngFor="let group of filteredCasetypeList">
                            <div>
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="group.selected" [indeterminate]="checkPartialSelection(group)"
                                (mousedown)="toggleParent(group)" (click)="$event.stopPropagation()">
                                    {{group.Item}}
                                </mat-checkbox>
                                <button mat-button (click)="expandDocumentTypes(group)">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                            </div>
                            <div *ngFor="let name of group.Children">
                                <mat-option (mousedown)="toggleCasetype(name)" (click)="$event.stopPropagation()" [disabled]="selectedLabLocations.length == 0"
                                     [value]="name"
                                    [ngClass]="isExpandCategory[group.Item] ? 'list-show' : 'list-hide'">
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="name.selected" (change)="toggleCasetype(name)"
                                        (click)="$event.stopPropagation()">
                                        {{name.CaseType}}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list">
                        <mat-chip class="dropdown-chip-color" *ngFor="let casetype of selectedCasetypes"
                            (removed)="removeChip(casetype, 'Casetype')" [removable]="true">
                            {{ casetype.CaseType }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row" [ngClass]="{'pb-2':sourceApp =='VitalDx'}">
                    <mat-label class="label-style col-sm-3"><strong>Insurance</strong></mat-label>
                    <mat-radio-group formControlName="frmIsPayerFamily" class="col-sm-6 p-0" (change)="onPayerChange($event)">
                        <mat-radio-button value="PayerFamily" class="label-style col-sm-3 m-0" checked>
                            Payer Family
                        </mat-radio-button>
                        <mat-radio-button value="Payer" class="label-style col-sm-2 m-0 p-0">
                            Payer
                        </mat-radio-button>
                    </mat-radio-group>

                    <mat-checkbox class="label-style selectALL col-sm-3 mr-0 p-0" 
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedInsurance"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Insurance')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmInsurance')" matInput
                            [matAutocomplete]="autoInsurance" formControlName="frmInsurance">
                            
                            <mat-autocomplete #autoInsurance="matAutocomplete" [displayWith]="displayFn" multiple>
                                <div *ngFor="let Insurance of filteredInsurance | async">
                                    <mat-option (mousedown)="optionClicked($event,Insurance, 'Insurance')" [value]="selectedInsurance">
                                        <mat-checkbox class="mat-checkbox-color-bg" [checked]="Insurance.selected"
                                        (change)="toggleSelection(Insurance, 'Insurance')"
                                        (click)="$event.stopPropagation()">
                                        {{ isPayerFamily ? Insurance.PayerFamily : Insurance.PayerName }}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let payer of selectedInsurance"
                            (removed)="removeChip(payer, 'Insurance')" [removable]="true">
                            {{ isPayerFamily ? payer.PayerFamily : payer.PayerName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Insurance Plan Type</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0" 
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedInsurancePlanType"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('InsurancePlanType')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmInsurancePlanType')" matInput
                            [matAutocomplete]="autoInsurancePlanType" formControlName="frmInsurancePlanType">
                    </mat-form-field>

                    <mat-autocomplete #autoInsurancePlanType="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let plantype of filteredInsurancePlanType | async">
                            <mat-option (mousedown)="toggleSelection(plantype, 'InsurancePlanType')"
                            [value]="selectedInsurancePlanType">
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="plantype.selected"
                                    (change)="toggleSelection(plantype, 'InsurancePlanType')"
                                    (click)="$event.stopPropagation()">
                                    {{ plantype.AttributeName }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let type of selectedInsurancePlanType"
                            (removed)="removeChip(type, 'PlanType')" [removable]="true">
                            {{ type.AttributeName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong><span class="text-danger">*</span>Services</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0" [(ngModel)]="allSelectedServices"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Services')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12" appearance="outline">
                        <mat-chip-list class="col-sm-12 p-0 m-0">
                            <mat-chip *ngIf="!selected[i]" class="chip-color" (click)="onServiceChange(true,i)">
                                {{servicesList[i]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i]" class="selected-chip-color" highlighted (click)="onServiceChange(false,i)">
                                {{servicesList[i]}}
                            </mat-chip>
                            <mat-chip *ngIf="!selected[i+1]" class="chip-color" (click)="onServiceChange(true,i+1)">
                                {{servicesList[i+1]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i+1]" class="selected-chip-color" highlighted (click)="onServiceChange(false,i+1)">
                                {{servicesList[i+1]}}
                            </mat-chip>
                            <mat-chip *ngIf="!selected[i+2]" class="chip-color" (click)="onServiceChange(true,i+2)">
                                {{servicesList[i+2]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i+2]" class="selected-chip-color" highlighted (click)="onServiceChange(false,i+2)">
                                {{servicesList[i+2]}}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Bill To</strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0" 
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedBillTo"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('BillTo')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmBillto')" matInput
                            [matAutocomplete]="autoBillTo" formControlName="frmBillto">
                    </mat-form-field>

                    <mat-autocomplete #autoBillTo="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let billto of filteredBillTo | async">
                            <mat-option (mousedown)="optionClicked($event, billto, 'BillTo')" [value]="selectedBillTo">
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="billto.selected"
                                    (change)="toggleSelection(billto, 'BillTo')"
                                    (click)="$event.stopPropagation()">
                                    {{ billto.AttributeName }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list ">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedBillTo"
                            (removed)="removeChip(location, 'BillTo')" [removable]="true">
                            {{ location.AttributeName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>

            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6 ml-1">
                        <strong><span class="text-danger">*</span>Effective Date</strong></mat-label>
                    <mat-label class="label-style col-sm-6 ml-1">
                        <strong><span class="text-danger">*</span>Expiry Date</strong></mat-label>
                </div>
                <div class="row mt-3" [ngClass]="{'mt-4':sourceApp =='VitalDx'}">
                    <mat-form-field class="example-full-width col-sm-6" [ngClass]="{'lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input placeholder="mm/dd/yyyy" matInput 
                            [min]="minFromDate" [max]="maxToDate" 
                            [matDatepicker]="fromPicker" formControlName="frmEffectiveDate">
                        <mat-datepicker-toggle class="p-0 m-0" matSuffix [for]="fromPicker" (click)="datePickerOnClick()"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="example-full-width col-sm-6" [ngClass]="{'lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input placeholder="mm/dd/yyyy" matInput
                            [min]="minToDate" [max]="maxToDate"
                            [matDatepicker]="toPicker" formControlName="frmExpiryDate">
                        <mat-datepicker-toggle class="p-0 m-0" matSuffix [for]="toPicker" (click)="datePickerOnClick()"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <hr class="gradient">
        <div class="col-sm-12 d-flex">
            <div class="field-container mt-1 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="col-sm-12 header-font">
                        <strong><span class="text-danger">*</span>Assignee</strong></mat-label>
                </div>
            </div>
        </div>
        <div class="col-sm-12 d-flex mb-2 mt-2">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                        <strong><span class="text-danger">*</span>Primary Reading Location</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" formControlName="frmPrimaryReadingLocation" [displayWith]="displayFn"
                            (selectionChange)="onReadingLocChange($event.value,1,null,$event)">
                            <mat-option [value]="" (click)="AARFormGrp.get('frmPrimaryReadingLocation').setValue(''); validateAssigneeSection()">-- Select --</mat-option>
                            <mat-option *ngFor="let loc of readingLocationList" [value]="loc.AccountId"
                                (click)="isPrimaryAccountEPG = loc.IsEPGAccount">
                                {{ loc.LocationName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                        <strong>Secondary Reading Location</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" [displayWith]="displayFn" [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value"
                            formControlName="frmSecondaryReadingLocation"
                            (selectionChange)="onReadingLocChange($event.value,2,null,$event)">
                            <mat-option [value]="" [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value"
                                (onSelectionChange)="AARFormGrp.get('frmSecondaryAssignee').setValue(''); validateAssigneeSection()">-- Select
                                --</mat-option>
                            <mat-option *ngFor="let loc of readingLocationList"
                                (click)="isSecondaryAccountEPG = loc.IsEPGAccount" [value]="loc.AccountId"
                                [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value">
                                {{loc.LocationName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                        <strong>Tertiary Reading Location</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" [displayWith]="displayFn"
                            [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value"
                            formControlName="frmTertiaryReadingLocation"
                            (selectionChange)="onReadingLocChange($event.value,3,null,$event)">
                            <mat-option [value]="" [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value"
                                (onSelectionChange)="AARFormGrp.get('frmTertiaryAssignee').setValue(''); validateAssigneeSection()">-- Select
                                --</mat-option>
                            <mat-option *ngFor="let loc of readingLocationList"
                                (click)="isTertiaryAccountEPG = loc.IsEPGAccount" [value]="loc.AccountId"
                                [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value">
                                {{loc.LocationName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-sm-12 d-flex mb-2">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                        <strong><span class="text-danger">*</span>Primary Assignee {{ isPathologistTab ?
                            'Pathologist' : 'Cytotechnologist'}}</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" [displayWith]="displayFn"
                            [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value"
                            formControlName="frmPrimaryAssignee" (selectionChange)="onAssigneeSelection(0)">
                            <mat-option [value]="" (click)="validateAssigneeSection()" [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">--
                                Select
                                --</mat-option>
                                <span *ngIf="isPathologistTab">
                                    <span *ngFor="let group of primaryAssigneeList">
                                        <div *ngFor="let child of group.UserDetailsList">
                                            <mat-option *ngIf="isPathologistTab && isPrimaryAccountEPG && child.UserId == -100"
                                                [value]="child">Available to all Pathologist</mat-option>
                                            <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">
                                                {{child.FormattedDisplayName}}
                                            </mat-option>
                                        </div>
                                    </span>
                                </span>
                                <span *ngIf="!isPathologistTab">
                                    <mat-optgroup *ngFor="let group of primaryAssigneeList" [label]="group.Parent"
                                        [disabled]="group.disabled">
                                        <div *ngFor="let child of group.UserDetailsList">
                                            <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">
                                                {{child.FormattedDisplayName}}
                                            </mat-option>
                                        </div>
                                    </mat-optgroup>
                                </span>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                            <span class="text-danger" *ngIf="AARFormGrp.controls.frmSecondaryReadingLocation.value">*</span>
                        <strong>Secondary Assignee {{ isPathologistTab ? 'Pathologist' :
                            'Cytotechnologist'}}</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value"
                            formControlName="frmSecondaryAssignee" (selectionChange)="onAssigneeSelection(1)">
                            <mat-option [value]="" (click)="validateAssigneeSection()"
                                [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">-- Select
                                --</mat-option>
                                <span *ngIf="isPathologistTab">
                                    <span *ngFor="let group of secondaryAssigneeList">
                                        <div *ngFor="let child of group.UserDetailsList">
                                            <mat-option
                                                *ngIf="isPathologistTab && isSecondaryAccountEPG && child.UserId == -100"
                                                [value]="child">Available to all Pathologist</mat-option>
                                            <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">
                                                {{child.FormattedDisplayName}}
                                            </mat-option>
                                        </div>
                                    </span>
                                </span>
                                <span *ngIf="!isPathologistTab">
                                    <mat-optgroup *ngFor="let group of secondaryAssigneeList" [label]="group.Parent == 'Pathologist' ? '' : group.Parent"
                                        [disabled]="group.disabled">
                                        <div *ngFor="let child of group.UserDetailsList">
                                            <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">
                                                {{child.FormattedDisplayName}}
                                            </mat-option>
                                        </div>
                                    </mat-optgroup>
                                </span>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-12">
                            <span class="text-danger" *ngIf="AARFormGrp.controls.frmTertiaryReadingLocation.value">*</span>
                        <strong>Tertiary Assignee {{ isPathologistTab ? 'Pathologist' :
                            'Cytotechnologist'}}</strong></mat-label>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12" [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <mat-select class="font-size" [displayWith]="displayFn"
                            [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value"
                            formControlName="frmTertiaryAssignee" (selectionChange)="onAssigneeSelection(2)">
                            <mat-option [value]="" [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">--
                                Select
                                --</mat-option>
                            <span *ngIf="isPathologistTab">
                                <span *ngFor="let group of tertiaryAssigneeList">
                                    <div *ngFor="let child of group.UserDetailsList">
                                        <mat-option *ngIf="isPathologistTab && isTertiaryAccountEPG && child.UserId == -100"
                                            [value]="child">Available to all Pathologist</mat-option>
                                        <mat-option *ngIf="child.UserId != -100" [value]="child"
                                            [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">
                                            {{child.FormattedDisplayName}}
                                        </mat-option>
                                    </div>
                                </span>
                            </span>
                            <span *ngIf="!isPathologistTab">
                                <mat-optgroup *ngFor="let group of tertiaryAssigneeList" [label]="group.Parent"
                                    [disabled]="group.disabled">
                                    <div *ngFor="let child of group.UserDetailsList">
                                        <mat-option *ngIf="child.UserId != -100" [value]="child"
                                            [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">
                                            {{child.FormattedDisplayName}}
                                        </mat-option>
                                    </div>
                                </mat-optgroup>
                            </span>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    
    <div class="col-sm-12 mt-4 align-center mb-3">
        <div class="sub-button mb-3">
            <button mat-raised-button class="admin-btn mr-4" (click)="back()">Return</button>
            <button *ngIf="editClicked" mat-raised-button class="admin-btn mr-4"
                (click)="showPop(true)">{{statusBtnName}}</button>
            <button mat-raised-button type="submit" (click)="validateRule()" class="admin-btn mr-4">Save</button>
        </div>
    </div>
</form>
</div>

<!-- Activate status pop-up-->
<div *ngIf="activateOrDeactivate" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header admin-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message align-center">
                        <div class="popup-title">{{confirmationMessage}}<br>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button mat-raised-button class="ct-admin-btn-success-outline" (click)="close()"
                        type="button">No</button>
                    <button mat-raised-button class="admin-btn ct-admin-btn-success" (click)="validateRule(true)"
                        type="button">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Pathologist License Details Alert -->
<ng-template #widgetEditorModal>
    <div class="mat-dialog-header border-bottom license-alert-header">
        <div class="col-sm-12">
            <div class="row">
                <span class="col-sm-6 mb-0 mt-2">
                    <h3 class="p-0 m-0">
                        <strong class="header-View header-font">License Information</strong>
                    </h3>
                </span>
                <span class="col-sm-6 selectALL align-status p-0">
                    <button mat-icon-button matDialogClose tabindex="-1">
                        <mat-icon aria-label="Close dialog">close</mat-icon>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="font-size">
                <em class="fa fa-info-circle" style="cursor: pointer;" aria-hidden="true"></em>
                The selected Pathologist is licensed to work only for the following states:
            </div>
        </div>
    </div>
    <mat-dialog-content class="p-3">
        <div class="col-sm-12 p-0 m-0 licensed-info">
            <div class="row licensed-info-height m-0 p-0">
                <div *ngIf="primaryPathLicenseInfo?.length>0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +primaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of primaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class="p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>                
                        </span>
                    </div>
                </div>
                <div *ngIf="secondaryPathLicenseInfo?.length>0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +secondaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of secondaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class=" p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>                
                        </span>
                    </div>
                </div>
                <div *ngIf="tertiaryPathLicenseInfo?.length > 0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +tertiaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of tertiaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class=" p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 pt-1 pl-0 pr-0 m-0">
            <h3 class="p-0 m-0">
                <strong class="header-font">Do you wish to create this Auto Assignment Rule for these facilities from Pathologist not licensed state(s)? </strong>
            </h3>
        </div>
        <div class="col-sm-12 p-0 m-0 non-licensed-info">
            <div class="non-licensed-info-height">
                <div *ngIf="primaryPathLicenseInfo?.length>0">
                    <div class="path-name p-0 m-0"><b>{{"Dr. " +primaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span class="p-0 m-0" *ngFor="let pathData of primaryPathLicenseInfo">
                        <span class="p-0 m-0" *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' : ''}}</div>
                        </span>
                    </span>
                </div>

                <div class="pt-4" *ngIf="secondaryPathLicenseInfo?.length>0">
                    <div class="path-name"><b>{{"Dr. " +secondaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span *ngFor="let pathData of secondaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' : ''}}</div>
                        </span>
                    </span>
                </div>

                <div class="pt-4" *ngIf="tertiaryPathLicenseInfo?.length>0">
                    <div class="path-name"><b>{{"Dr. " +tertiaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span *ngFor="let pathData of tertiaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' : ''}}</div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="border-top footer-btn">
        <app-button buttonclass="secondary" class = "edit-page-button" mat-button matDialogClose>No</app-button>
        <app-button buttonclass="primary" class = "edit-page-button ml-2" mat-button matDialogClose (click)="saveData()" >Yes</app-button>
    </mat-dialog-actions>
</ng-template>