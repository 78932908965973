import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../core/services/commonservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../DbModel/SubMenuCard/Submenucardmodel';
import { ActivityTrackerComponent } from '../Templates/activity-tracker/activity-tracker.component';
import { environment } from 'src/environments/environment';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { th } from 'date-fns/esm/locale';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'vital-menu',
  templateUrl: './vital-menu.component.html',
  styleUrls: ['./vital-menu.component.scss'],
})
export class VitalMenuComponent {
  isExpanded = false;
  @Output()scrollUpMain = new EventEmitter();
  public vitalUrl: boolean = true;
  @Input() public menuJson: any = [];
  @Input() genericCardIdentifier: number = -1;
  @Input() cardtype: string = '';
  @Input() mainCard: any;
  @Input() secondaryId: string = '';
  @Input() secondarykeys: any = {};
  @Input() roledatamenu: any = [];

  @Output() changeTab : EventEmitter<any> = new EventEmitter();
  @Input() redirectDest : string = null

  subMenuCardModel: SubMenuCardModel;
  submenuData: any;
  template: string = '';
  subMenuLabels: string[] = [];
  menuIndex: { mainMenuIndex: number; subMenuIndex: number } = {
    mainMenuIndex: -1,
    subMenuIndex: -1,
  };
  submentSubscrib: Subscription;
  templateData: any = {};
  templateChangedTime: string;
  srcheight: number;
  public mutationQuery;
  public mutationKeyword: string = '';
  public keyword: string = '';
  public templatedropdown;
  public orgguidArray: any = [];
  vdguidinstallationsData: any;
  tempSubmenudata: any[];
  changeToTrue: boolean;
  // activityTracker: boolean = true;
  activityTracker: any;
  public associationData;
  menuURL: any;
  headerText: any;
  public openMenu: boolean = false;
  isOver = false;
  openActivityTkr = false;
  showCollapse: boolean;
  trackerInput: any = {}
  activityPath: any[];
  trackerApiUrl:any;

  tempid: number;
  expandbyDefault: any = []
  tempExpandMenu: any = [];
  SelectedMenuModel: boolean;
  ngOnChanges() {
    this.getExpandMainMenu();
    sessionStorage.setItem('OrganizationGUID', this.mainCard.OrganizationGUID);
    let labname = this.mainCard.Organization;
    sessionStorage.setItem('LabName', labname);
    sessionStorage.setItem('FlabName', this.mainCard.Facility_Location_Name);
    sessionStorage.setItem('AccountID', this.mainCard.id);
    let obj = {
      cardtype: this.cardtype,
      genericCard: this.genericCardIdentifier,
      mainMenuIndex: 0,
      subMenuIndex: 0,
    };
    if (this.menuJson) {
      this.setSubMenuVisibility();
      // removing case profile from Compendium menu inside thx and thxvar deplyment
      if (sessionStorage.getItem('deploymentKey')?.toString().match(/thx/i)) {
        this.changeMenuJson(this.menuJson, "Compendium", "Case profiles")
        //removing sendout reasons for p4 Deployments
        this.changeMenuJson(this.menuJson, "Sequester Reasons", "Sendout Reasons")
        //  this.changeMenuJson(this.menuJson, "Statistics", "Scheduled Reports")
        this.changeMenuJson(this.menuJson, "General", "Disclaimers")
        /*Removing Casetags from thx & thxvar its not available under these deployment*/
        // this.changeMenuJson(this.menuJson, "Settings", "Case Tags");
        this.changeMenuJson(this.menuJson, "Categories", "Categories")
        /*Removing categories from thx and thxvar its not available under these deployment*/
        this.changeMenuJson(this.menuJson, "Masters", "New Diagnosis Templates") // removing New diagnosis templates menu for nonp4 deployments
      }

      // removing Statistics and its child menu Scheduled and Custom reports from menu if the context is Send in or Send out lab
      if (sessionStorage.getItem('context')?.toString().toLowerCase() == 'send out' || sessionStorage.getItem('context')?.toString().toLowerCase() == 'send in') {
        this.changeMenuJsonforSendLabs(this.menuJson, "Statistics");
        this.changeMenuJson(this.menuJson, "Settings", "Sequence Scheme");
      }
      //removing sumbenu from this userrole
      if (sessionStorage.getItem('search_context')?.toString().toLowerCase() == 'users' || sessionStorage.getItem('search_context')?.toString().toLowerCase() == 'physician' || sessionStorage.getItem('search_context')?.toString().toLowerCase() == 'pathologist' || sessionStorage.getItem('search_context')?.toString().toLowerCase() == 'userid') {
        this.removeUserMenu(this.menuJson);
      }
      // removing Default Stain in case of ordering facility
      if (sessionStorage.getItem('context')?.toString().toLowerCase() != 'laboratory') {
        this.changeMenuJson(this.menuJson, "Masters", "Default Stains");
        // this.changeMenuJson(this.menuJson, "Settings", "Case Tags");
      }

      this.menuJson[0].SubMenu[0];
      this.SubMenuClick(this.menuJson[0].Htext, this.menuJson[0].SubMenu[0], obj);
    }
    for (var i = 0; i < this.menuJson.length; i++) {
      if (i == 0) {
        this.menuJson[i].expand = this.menuJson[i].expand;
      } else {
        this.menuJson[i].expand = true;
      }
    }
    if(this.redirectDest){
      this.changeComponent(this.redirectDest);
    }
  }

  async getOrganizationLoginType() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id') };
    let query = this.vitalHttpServices.GetQuery("getorganizationname");
    let queryResult = this.commonservice.GetCardRequest(queryVariable, query);
    let organizationLoginType
    await this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (res) {
        organizationLoginType = res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' || res.data.submenuData[0].LoginType.toString().toLowerCase().trim() == 'loginname' ? 'LoginName' : 'Email';
        if (organizationLoginType.toString().toLowerCase().trim() == 'loginname') {
          this.changeMenuJson(this.menuJson, "Users", "Migrate Users");
        }
      }
    },
      error => {
        console.error(error);
      })
  }

  setSubMenuVisibility() {
    for (let i = 0; i < this.menuJson.length; i++) {
      let menu = this.menuJson[i];
      for (let j = 0; j < menu.SubMenu.length; j++) {
        let subMenu = menu.SubMenu[j];
        let allowedRoles = subMenu.AllowedRoles;
        let allowedDeployments = subMenu.AllowedDeployments;
        let allowedEntities = subMenu.AllowedEntities;
        let allowedCaseTypes = subMenu.AllowedCasetypes;
        if (allowedRoles) {
          if (allowedRoles.length === 0) //no value set = not visible
            subMenu['visible'] = false;
          else {
            let roleName = sessionStorage.getItem('RoleName')?.toString().toLowerCase();
            let found = allowedRoles.find(item => roleName?.toString().toLowerCase().endsWith(item?.toString().toLowerCase()));
            if (found)
              subMenu['visible'] = true;
            else
              subMenu['visible'] = false;
          }
        } else if (allowedEntities) {
          if (allowedEntities.length === 0) //no value set = not visible
            subMenu['visible'] = false;
          else {
            let entityName = sessionStorage.getItem('context')?.toString().toLowerCase();
            let found = allowedEntities.some(item => entityName?.toString().toLowerCase().includes(item?.toString().toLowerCase()));
            if (found)
              subMenu['visible'] = true;
            else
              subMenu['visible'] = false;
          }
        }
        else {
          subMenu['visible'] = true;
        }
      }
    }
  }

  removeUserMenu(menuJson) {
    let removeMenuList = [];
    for (let i = 0; i < this.menuJson[0].SubMenu.length; i++) {
      let roles = this.menuJson[0].SubMenu[i].Rolename;
      if (roles && roles.toLowerCase() != 'none') {
        let rolesList = roles.split(',');
        let flag = false;
        for (let j = 0; j < rolesList.length; j++) {
          if (!flag) {
            let tempValue = false;
            tempValue = this.mainCard.Primary_Role.toLowerCase() == rolesList[j].toLowerCase() ? true : false;

            if (tempValue) {
              flag = true;
            }
          }
          else {
            break;
          }
        };
        // if (!flag) {
        //   removeMenuList.push(this.menuJson[0].SubMenu[i].URL);
        // }
      }
    }
    for (let k = 0; k < removeMenuList.length; k++) {
      this.changeMenuJson(this.menuJson, menuJson[0].Htext, removeMenuList[k]);
    }
  }
  collapse() {
    this.isExpanded = false;
  }

  collapseExpand(index: number) {
    if (!this.menuJson[index].expand) {
      this.menuJson[index].expand = true;
    } else {
      this.menuJson[index].expand = !this.menuJson[index].expand;
    }
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(
    private commonservice: CommonService,
    private dataservice: DataShareService,
    public vitalHttpServices: VitalHttpServices,
    private ref: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private _snackbar: MatSnackBar,
    private actCompRef: ActivityTrackerComponent,
    private activitySession:ActivityTrackerService,
    private router :Router,
    public modalService: NgbModal
  ) {
    this.subMenuCardModel = new SubMenuCardModel(
      commonservice,
      vitalHttpServices,
      dataservice
    );
  }

  SubMenuClick(headerText: any, childMenu: any, mainmenu: any) {
      this.scrollUpMain.emit(true)

    if (!localStorage.getItem('UserEmail') || localStorage.getItem('UserEmail') === '') {
      this.vitalHttpServices.logout();
    }
    else {
      this.activityTracker = childMenu.URL
      this.menuIndex.mainMenuIndex = mainmenu.mainMenuIndex;
      this.menuIndex.subMenuIndex = mainmenu.subMenuIndex;
      let id: number = -1;
      let removelabel: string = '';
      let secondarykeys: any = {};
      if (childMenu.Keyword !== 'vitalbridge') {
        localStorage.removeItem('vbCredential');
      }
      if (childMenu.Template) {
        if (childMenu.URL === 'Result Section') {
          this.template = 'resultsection';
        } else {
          this.template = childMenu.Template.toLowerCase();
        }
      }
     // this code is not required it's used for demo for the lab administrator
      // if(childMenu.Template =="LabAdmin"){
      //   this.router.navigate(['/labadmin'])
      //   // window.location.href.split("#")[0]
      //   // window.open(`${window.location.href.split("#")[0]}#/labadmin`,'')
      //   this.vitalHttpServices.labTemplateData = this.templateData
      // }
      if (childMenu.URL)
        this.menuURL = childMenu.URL;
      if (headerText)
        this.headerText = headerText;
      if (childMenu.URL === 'Interface') {
        this.template = 'interface';
        if (childMenu.MutationKeyword)
          this.mutationKeyword = childMenu.MutationKeyword.toString();
      }

      if (childMenu.MutationKeyword) {
        this.mutationKeyword = childMenu.MutationKeyword.toString().toLowerCase();
      }
      if (childMenu.Keyword === 'labvdtemplates') {
        this.template = 'vdtemplate';
      }
      if (childMenu.Keyword === 'vdinformation') {
        this.template = 'vdinformation';
        // this.templateData.template = this.template;
        sessionStorage.setItem('deploymentKey', 'configdb');
      }
      if (
        childMenu.Keyword === 'vdjarprinter' || childMenu.Keyword === 'vdcassetteprinter' ||
        childMenu.Keyword === 'vdslideprinter' || childMenu.Keyword === 'vddocumentprinter' ||
        childMenu.Keyword === 'vdtechnicalsheetprinter' || childMenu.Keyword === 'vdmedicaldeviceprinter' ||
        childMenu.Keyword === 'vdprinteractions') {
        this.templateData.template = 'vdgenric';
        this.templateData.Keyword = childMenu.Keyword;
        this.template = 'vdgenric';
        sessionStorage.setItem('deploymentKey', 'configdb');
      }
      if (childMenu.Keyword === 'vitalbridge') {
        this.template = 'vtbridge';
      }

      if (childMenu.Keyword === 'usergetsignfile') {
        this.template = 'pathologysignupload';
      }

      if (childMenu.Keyword === 'licensureinfo') {
        this.template = 'licensureinfo';
      }
      id = this.genericCardIdentifier;
      this.keyword = childMenu.Keyword;

      switch (this.template) {


        case 'vtbridge':
          this.templateData.template = 'vtbridge';
          return; //  i  am return here for  only  for vitalbridge integration --aleem
        case 'searchpanel':
          this.keyword = childMenu.Keyword;
          // this.keyword = this.cardtype + childMenu.URL;
          secondarykeys.casetype = this.cardtype + childMenu.URL;
          removelabel = 'accountid';
          break;
        case 'cases':
          this.templateData.template = 'cases';
          this.templateData.menuURL = this.menuURL;
          this.templateData.headerText = this.headerText;
          return;

        case 'app-accounts-association':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          if (childMenu.Groupby) {
            secondarykeys.GroupBy = childMenu.Groupby;
            if (this.secondarykeys == undefined) {
              this.secondarykeys = {};
              this.secondarykeys.GroupBy = childMenu.Groupby;
            } else {
              this.secondarykeys.GroupBy = childMenu.Groupby;
            }
          }
          break;
        case 'hgrid':
        case 'readonlygrid':
        case 'resultsection':
        // case 'interface':
        case 'userpersonalinfo':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          break;
        case 'app-organization-tags':
        case 'pathologysignupload':
        case 'licensureinfo':
        case 'vdinformation':
        case 'vdjarprinter':
        case 'vdcassetteprinter':
        case 'vdslideprinter':
        case 'vddocumentprinter':
        case 'vdtechnicalsheetprinter':
        case 'vdmedicaldeviceprinter':
        case 'app-bulk-upload-tests':
        case 'app-adequacy-codes':
        case 'vdprinteractions':
        case 'vdgenric':
        case 'locgrid':
        // case "gridguid":
        //case "customgrid":
        case 'grosstemplates':
        case 'app-disclaimers':
        case 'app-case-comments-component':
        case 'diagnosistemplates':
        case 'app-manage-sites':
        case 'app-grossing-attributes':
        case 'app-manage-collection-methods':
        case 'qagrid':
        case 'ngrid':
        case 'app-mandatory-fields':
        case 'app-default-stains':
        case 'app-casetype-details':
        case 'app-user-defined-fields':
        case 'app-microscopic-codes':
        case 'app-manage-icd9codes':
        case 'app-requisition-profiles':
        case 'app-manage-screening-types':
        case 'app-diagnosis-references':
        case 'app-corelation-templates':
        case 'app-tubetype-templates':
        case 'app-nomenclature-templates':
        case 'app-custom-abbreviations':
        case 'app-sendout-reasons':

        case 'grid':
          this.tempid = id;
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          //this.mutationKeyword = childMenu.MutationKeyword.toString();
          break;
        case 'app-revise-templates':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          secondarykeys.type = 'AddendumReason'
          break;
        case 'app-revise-notes-templates':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          // secondarykeys.type = 'AddendumReason'
          break;
        case 'app-manage-sequence-wheel':
          this.tempid = id;
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          // if (this.secondarykeys && this.secondarykeys.casetype)
          //   secondarykeys.casetype = sessionStorage.getItem("search_context");
          break;
        case 'accountlogoupload':
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          break;
        case 'customrulesgrid':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          break;
        case 'vdtemplate':
          secondarykeys.orgid = Number(this.commonservice.orgid);
        case 'interface':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          break;
        case 'app-case-profiles':
        case 'managescheduledreportgrid':
        case 'app-manage-password-policy':
        case 'app-manage-reflex-rules':
        case 'app-manage-custom-listing':
        case 'app-manage-pathologist-privilege':
        case 'customreportgrid':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          break;
        case 'templatematerial':
        case 'templatesettings':
        case 'casetypetemplate':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          if (childMenu.SubGroupBy) {
            secondarykeys.SubHeader = childMenu.SubGroupBy;
            this.secondarykeys.SubHeader = childMenu.SubGroupBy;
          }
          if (childMenu.Groupby) {
            secondarykeys.GroupBy = childMenu.Groupby;
            this.secondarykeys.GroupBy = childMenu.Groupby;
          }
          break;
        case 'newmanageruleouts':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          if (childMenu.Groupby) {
            if (this.secondarykeys == undefined)
              this.secondarykeys = {};
            this.secondarykeys.GroupBy = childMenu.Groupby;
          }
          if (childMenu.AllowedEntities) {
            if (this.secondarykeys == undefined)
              this.secondarykeys = {};
            secondarykeys.AllowedEntities = childMenu.AllowedEntities;
          }
          if (childMenu.AllowedCasetypes) {
            if (this.secondarykeys == undefined)
              this.secondarykeys = {};
            secondarykeys.AllowedCasetypes = childMenu.AllowedCasetypes;
          }
          break;
        case 'app-screening-comments':
          this.tempid = id;
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          if (childMenu.Keyword) this.keyword = childMenu.Keyword.toString();
          if (this.secondarykeys && this.secondarykeys.casetype)
            secondarykeys.casetype = this.secondarykeys.casetype;
          if (childMenu.AllowedCasetypes) {
            if (this.secondarykeys == undefined)
              this.secondarykeys = {};
            secondarykeys.AllowedCasetypes = childMenu.AllowedCasetypes;
          }
          break;
          case 'app-manage-categories':
          case 'app-deletion-templates':
          case 'app-sequester-templates':
          if (this.secondarykeys && this.secondarykeys.OrganizationId)
            secondarykeys.orgid = Number(this.secondarykeys.OrganizationId);
          break;
      }
      if (childMenu.Keyword === 'vdguidinstallations') {
        this.GetVDInformation(childMenu.Keyword, id);
      } else if (childMenu.Keyword === 'userslist') {
        this.getAssociationTypes(this.mainCard.id);
      }
      else if (childMenu.Keyword === 'locationkey') {
        this.GetOrgLocation(id)
      }
      else if (childMenu.Keyword === 'migrateusers') {
        let arr = [];
        this.SetSubMenuDataWithLabels(arr, removelabel);
      }
      else if (childMenu.Keyword === 'bulkuploadtests') {
        let arr = [];
        this.SetSubMenuDataWithLabels(arr, removelabel);
      }
      else if (childMenu.Keyword == 'vaEntityextAttributes') {
        this.SetSubMenuDataWithLabels([], removelabel);
      }
      // else if (childMenu.Keyword == 'getGrossingAttributes') {
      //   this.SetSubMenuDataWithLabels([], removelabel);
      // }
      else if (childMenu.Keyword == 'getOrganizationAttibutes') {
        this.SetSubMenuDataWithLabels([], removelabel);
      }
      else if (childMenu.Keyword == 'getAttributeName') {
        this.SetSubMenuDataWithLabels([], removelabel);
      }

      else if (childMenu.Keyword == 'report-builder') {   
         this.SetSubMenuDataWithLabels([], removelabel);
      }
      else if (childMenu.Keyword == 'getmanagepasswordpolicy' || childMenu.Keyword == 'reflexrules' || childMenu.Keyword == 'getCustomListUsers' || childMenu.Keyword == 'pathologistprivilege') {
        this.SetSubMenuDataWithLabels([], removelabel);
      }
      else if (childMenu.Keyword == 'Diagnosis') {
        this.SetSubMenuDataWithLabels([], removelabel);
      }
      else {
        this.GetSubmenuCardData(id, this.keyword, secondarykeys, removelabel);

        this.GetMutationQuery(this.mutationKeyword);
      }
    }
  }
  //get the users data to show in grid
  //get users Association Types

  getAssociationTypes(accIDs) {
    let queryResult: any;
    let queryVariable;
    let query = this.vitalHttpServices.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    Qstr += "accountid = \"" + accIDs + "\"";

    queryVariable = { "accid": Qstr };
    let associationTypes: any[];
    queryResult = this.commonservice.GetCardRequest(queryVariable, query);
    let cardtype;
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              //to get unique associations from arrayF
              associationTypes.push(element.associationtype)
            }
          });

          for (i = 0; i < associationTypes.length; i++) {
            if (associationTypes[i].toLowerCase() === 'laboratory' || associationTypes[i].toLowerCase() === 'pathologists') {
              let card = 'Lab';
              sessionStorage.setItem('Contexttype', card);
            }
            else if (associationTypes[i].toLowerCase() === 'ordering facility') {
              let card = 'facility';
              sessionStorage.setItem('Contexttype', card);
            }
            else if (associationTypes[i] === 'Both') {
              let card = 'Both';
              sessionStorage.setItem('Contexttype', card);
            }
          }
          cardtype = sessionStorage.getItem('Contexttype');
          this.getUserList(cardtype);
        }
        else {
          this.associationData = "";
          this.getUserList(cardtype);
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }
  //get the users data to show in grid
  getUserList(card) {
    let id;
    let removelabel: string = '';
    if (this.cardtype.toLowerCase() == 'Lab') {
      id = sessionStorage.getItem('AccountID');//send Account id for the org we are there
      card = card;
    }
    else {
      card = sessionStorage.getItem('search_context');//checking the card type for location type
      if (card == 'Lab') {
        id = sessionStorage.getItem('AccountID');//send Account id for the org we are there
      }
      else {
        id = this.mainCard.id;
      }
    }
    this.ngxService.start();
    this.vitalHttpServices.GetUserslist(id, card).subscribe((ResData) => {
      this.ngxService.stop();
      if (!ResData.errors) {
        let roledata = ResData;
        // Userdatadata.push(roledata);
        this.SetSubMenuDataWithLabels(
          roledata,
          removelabel
        );
      }
    },
      (error) => {
        this.ngxService.stop();
        console.error(error);
      });
  }
  //get the usersInfo

  GetVDInformation(childMenu, id) {
    let removelabel: string = '';
    this.ngxService.start();
    this.tempSubmenudata = [];
    this.keyword = childMenu.toString();
    if (sessionStorage.getItem('search_context').toString().toLowerCase() == 'lab' || this.cardtype.toString().toLowerCase() == 'lab') {
      this.vitalHttpServices.GetAssociatedOrgGuidForAccId(id.toString(), "lab").subscribe((data) => {
        if (data.status.toLowerCase() == 'success') {
          let resData=data.content
          if (resData) {
            if (resData.length > 0) {
              this.vdguidinstallationsData = resData;
              let tempguid = '';
              for (let i = 0; i < this.vdguidinstallationsData.length; i++) {
                if (i == this.vdguidinstallationsData.length - 1) {
                  tempguid += 'OrganizationGUID = "' + this.vdguidinstallationsData[i].guid + '"';
                } else {
                  tempguid += 'OrganizationGUID = "' + this.vdguidinstallationsData[i].guid + '" or ';
                }
              }
              let queryVariable = {
                Organization_GUID: tempguid, DeploymentKey: this.vitalHttpServices.deploymentKey.toString(),
              };
              let query: string = '';
              query = this.vitalHttpServices.GetQuery(this.keyword);
              let queryResult = this.commonservice.GetCardRequest(queryVariable, query
              );
              sessionStorage.setItem('deploymentKey', 'configdb');
              this.vitalHttpServices.GetData(queryResult).subscribe(
                (indata) => {
                  sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                  let input = JSON.parse(JSON.stringify(indata.data.submenuData));
                  if (!indata.errors) {
                    for (let i = 0; i < this.vdguidinstallationsData.length; i++) {
                      let temp_guid = this.vdguidinstallationsData[i].guid.toString();
                      let temp_AT = this.vdguidinstallationsData[i].associationType.toString();
                      let filtered_Input = JSON.parse(JSON.stringify(input.filter(
                        (data) =>
                          data.Organization_GUID.toString().toLowerCase() == temp_guid.toString().toLowerCase()
                      )));
                      for (let m = 0; m < filtered_Input.length; m++) {
                        let Temp_filtered_Input = JSON.parse(JSON.stringify([]));
                        if (
                          filtered_Input[m].Association_Type == undefined
                        ) {
                          filtered_Input[m].Association_Type = temp_AT;
                          this.tempSubmenudata.push(filtered_Input[m]);
                        } else {
                          Temp_filtered_Input.push(filtered_Input[m]);
                          Temp_filtered_Input[Temp_filtered_Input.length - 1].Association_Type = temp_AT;
                          this.tempSubmenudata.push(Temp_filtered_Input[Temp_filtered_Input.length - 1]);
                        }
                      }
                    }
                    this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
                  }
                },
                (error) => {
                  sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                  console.error(error);
                }
              );
            } else {
              this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
            }
          } else {
            this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
          }
        }
      },
        (error) => {
          this.ngxService.stop();
          console.error(error);
        }
      );
    }
    else {
      let guid = 'OrganizationGUID = "' + sessionStorage.getItem('OrganizationGUID') + '"';
      let queryVariable = {
        Organization_GUID: guid, DeploymentKey: this.vitalHttpServices.deploymentKey.toString(),
      };
      let query: string = '';
      query = this.vitalHttpServices.GetQuery(this.keyword);
      let queryResult = this.commonservice.GetCardRequest(queryVariable, query
      );
      sessionStorage.setItem('deploymentKey', 'configdb');
      this.vitalHttpServices.GetData(queryResult).subscribe((indata) => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (!indata.errors) {
          this.tempSubmenudata.push(...indata.data.submenuData)
          this.tempSubmenudata.map(i => i.Association_Type = "Facility")
          this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
        }
      },
        (error) => {
          console.log(error);
        })
    }
    this.ngxService.stop();
  }

   getExpandMainMenu(){
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { "Context": "Menu", "keyword": "Main Menu" };
    let queryResult = this.commonservice.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult,'configdb').subscribe((Resdata) => {
      if(Resdata){
      if (!Resdata.errors) {

        if (Resdata.data.submenuData[0] && Resdata.data.submenuData[0].Items_JSON) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.expandbyDefault = data.Menus;
          this.tempExpandMenu = [];
          for (let i = 0; i < this.expandbyDefault.length; i++) {
            this.tempExpandMenu.push(this.expandbyDefault[i].item);
          }
        }
      }
    }
    this.menuJson.forEach(item => {
      if (this.tempExpandMenu.includes(item.Htext.toString().toLowerCase())) {
           item.expand = false
        }
       });
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  //#region Setup-new-location Listing Locations
  GetOrgLocation(id) {
    let associationType;
    let removelabel: string = '';
    let orgid;
    if (this.commonservice.orgid) {
      orgid = parseInt(this.commonservice.orgid);
    }
    else if (this.secondarykeys.OrganizationId) {
      orgid = this.secondarykeys.OrganizationId;
    }
    else {
      console.log('orgid is null', orgid);
    }
    this.ngxService.start();
    if (this.cardtype.toLowerCase() == 'lab') {
      associationType = 'Laboratories';
    }
    else if (this.cardtype.toLowerCase() == 'facility') {
      associationType = 'Ordering Facilities';
    }
    else {
      let card = sessionStorage.getItem('search_context');
      if (card.toLowerCase() == 'lab') {
        associationType = 'Laboratories';
      }
      else if (card.toLowerCase() == 'facility') {
        associationType = 'Ordering Facilities';
      }
    }
    if(this.commonservice.isPol)
    associationType = 'POL';
    this.vitalHttpServices.GetOrgLocations(orgid, id, associationType).subscribe((resData) => {
      this.ngxService.stop();
      if (!resData.errors) {
        this.tempSubmenudata = [];
        if (resData) {
          if (resData.length > 0) {
            this.tempSubmenudata = [...resData];
          } else {
            this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
          }
        }
        this.SetSubMenuDataWithLabels(this.tempSubmenudata, removelabel);
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      console.error(error);
    });
  }
  //#endregion

  GetMutationQuery(mutationKeyword) {
    this.mutationQuery = this.vitalHttpServices.GetQuery(mutationKeyword);
  }

  GetSubmenuCardData(id: number, keyword: string, secondarykeys: any, removelabel: string) {

    this.ngxService.start("1");
    if (sessionStorage.getItem('deploymentKey').toLowerCase() == 'thx' || sessionStorage.getItem('deploymentKey').toLowerCase() == 'thxvar') {
      switch (keyword) {
        case "labCustomReports":
          keyword = 'labcustomreportsforp4'
          break;
        case "getudfdetails":
          keyword = 'getudfdetailsforP4'
          break;
      }
    }
    // this.ngxService.start();
    this.subMenuCardModel.GetData(id, keyword, secondarykeys).subscribe((result) => {
      this.ngxService.stop("1");
      if (!result.errors) {
        if (result.data.templatedropdown != undefined) {
          this.templatedropdown = result.data.templatedropdown;
        }
        if (result.data.submenuData1) {
          result.data.submenuData['submenuData1'] = result.data.submenuData1;
          if (result.data.submenuData2) {
            result.data.submenuData['submenuData2'] = result.data.submenuData2;
            if (result.data.submenuData3) {
              result.data.submenuData['submenuData3'] = result.data.submenuData3;
              if (result.data.submenuData4) {
                result.data.submenuData['submenuData4'] = result.data.submenuData4;
              }
            }
          }
          this.SetSubMenuDataWithLabels(result.data.submenuData, removelabel);
        }
        else {
          this.SetSubMenuDataWithLabels(result.data.submenuData, removelabel);
        }
      }
    },
      (error) => {
        this.ngxService.stop("1");
        console.error(error);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });
  }

  SetSubMenuDataWithLabels(data: any, removelabel: string) {
    this.submenuData = data;

    //set submenu data that has to be sent to component
    if (!data) {
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      return
    }
    // if (this.template == 'app-manage-sequence-wheel') {
    //   if (data && data.length > 0) {
    //     this.submenuData = [];
    //     this.submenuData = [...data, ...data.submenuData1]
    //   }
    // }

    if (data[0]) {
      let labels = Object.keys(data[0]);
      const index = labels.indexOf(removelabel, 0);
      if (index > -1) {
        labels.splice(index, 1);
      }
      // this.templateData.template = this.template;
      this.subMenuLabels = labels;
      this.templateData.submenuData = this.submenuData;
    }
    else {
      if (this.template == "customreportgrid") {
        let labels = Object.keys(data.submenuData1);
        const index = labels.indexOf(removelabel, 0);
        if (index > -1) {
          labels.splice(index, 1);
        }
        this.subMenuLabels = labels;
        this.templateData.submenuData = this.submenuData;
      }
      else if (this.template == "app-case-comments-component") {
        this.subMenuLabels = [];
        this.templateData.submenuData = this.submenuData;
      }
      else if (this.template == "diagnosistemplates") {
        this.subMenuLabels = [];
        this.templateData.submenuData = this.submenuData;
      }
      // else if (this.template == 'app-manage-sequence-wheel') {
      //   if (data) {
      //     this.submenuData = [];
      //     this.submenuData = [...data, ...data.submenuData1]
      //     this.subMenuLabels = [];
      //     this.templateData.submenuData = this.submenuData;
      //   }
      // }
      else {
        this.subMenuLabels = [];
        this.templateData.submenuData = null;
      }
    }
    //common data to be sent to component
    this.templateData.template = this.template;
    this.templateData.menuURL = this.menuURL;
    this.templateData.headerText = this.headerText ?? '';
    this.templateData.cardtype = this.cardtype;
    this.templateData.cardIdentifier = this.genericCardIdentifier;
    this.templateData.labels = this.subMenuLabels;
    this.templateData.GroupData = this.secondarykeys;
    this.templateData.mutationQuery = this.mutationQuery;
    this.templateData.mutationKeyword = this.mutationKeyword;
    this.templateData.Keyword = this.keyword;
    this.templateData.templatedropdown = this.templatedropdown;
    this.templateChangedTime = new Date().toString();
    this.templateData.secondarykeys = this.secondarykeys;
  }

  ///Activity tracker code

  public async ngOnInit(): Promise<void> {
    try{
    // await this.getExpandMainMenu();
    this.srcheight = window.innerHeight - 197;
    this.dataservice.getmenu(this.srcheight);

    this.submentSubscrib = this.commonservice
      .getBooleanValue()
      .subscribe((x) => {
        this.changeToTrue = x;
        if (x) {

          let locid = sessionStorage.getItem('Locationid');
          let orgid = sessionStorage.getItem('org_id');
          switch (this.keyword) {
            case 'labCustomReports':
              this.GetSubmenuCardData(
                parseInt(locid),
                this.keyword,
                { casetype: 'Orgid', orgid: orgid },
                ''
              );
              break;
            case 'OrganizationreportDef':
              this.GetSubmenuCardData(
                parseInt(locid),
                this.keyword,
                { casetype: 'Orgid', orgid: orgid },
                ''
              );
              break;
            case 'vdguidinstallations':
              this.GetVDInformation('vdguidinstallations', this.genericCardIdentifier);
              break;
            case 'associatedaccounts':
              this.GetSubmenuCardData(
                parseInt(locid),
                this.keyword,
                { GroupBy: 'Association_Type', casetype: 'Orgid', orgid: orgid },
                ''
              );
              break;
            case 'associatedaccounts':
              this.GetSubmenuCardData(
                parseInt(locid),
                this.keyword,
                { GroupBy: 'Association_Type', casetype: 'Orgid', orgid: orgid },
                ''
              );
              break;
            case 'mastercasecomments':
              this.GetSubmenuCardData(parseInt(locid), this.keyword, { casetype: this.secondarykeys.casetype, orgid: orgid }, ''
              );
              break;
            case 'usercasecomments':
              this.GetSubmenuCardData(this.tempid, this.keyword, {}, ''
              );
              break;
            case 'app-case-profiles':
              this.GetSubmenuCardData(parseInt(locid), this.keyword, { orgid: orgid }, ''
              );
              break;
            case 'newmanageruleouts':
              this.GetSubmenuCardData(
                parseInt(locid), this.templateData.Keyword,
                { GroupBy: this.templateData.GroupData.GroupBy, casetype: this.templateData.GroupData.casetype, orgid: this.templateData.GroupData.OrganizationId },
                ''
              );
              break;
            case 'LabCaseType':
              //let keyword='LabCaseType'
              this.GetSubmenuCardData(parseInt(locid), this.keyword, { casetype: this.secondarykeys.casetype, orgid: orgid }, ''
              );
              break;
          }
        }
      });
    this.getOrganizationLoginType()
    }
    catch(error) {
      console.error(error);
      this._snackbar.open('An error occurred while processing your request','Close');
      this.ngxService.stop('entityLoad');
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  // code for changing the Menu json for thx and thxvar
  //below code removes the child menu /sub menu
  changeMenuJson(menuJson, headerText, url) {
    let found = false;
    for (let item of menuJson) {
      if (item.Htext == headerText){
        for (let i = 0; i < item.SubMenu.length; i++) {
          if (item.SubMenu[i].URL == url) {
            const index = item.SubMenu.indexOf(item.SubMenu[i])
            item.SubMenu.splice(index, 1);
            found = true;
            break;
          }
        }
        if (item.SubMenu.length == 0) {
          const index = menuJson.indexOf(item)
          menuJson.splice(index,1)
        }
        if (found) {
          break;
        }
      }
    }
  }

  //below code removes both parent/main menu and child/sub menu
  changeMenuJsonforSendLabs(menuJson, headerText) {
    let found = false;

    for (let i = 0; i < menuJson.length; i++) {
      if (menuJson[i].Htext == headerText) {
        const index = menuJson.indexOf(menuJson[i])
        menuJson.splice(index, 1);
        found = true;
        break;
      }
      if (found) {
        break;
      }
    }

  }

  //Activity Tracker
  activeTab: string = 'All';

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.actCompRef.updateTrackerValues(this.activeTab)
  }

  //activity tracker comment box for all tabs
  comment: string = '';
  isInputValid: boolean = false;

  onInputChange(value: string) {
    this.isInputValid = value.trim().length > 0;
  }

  entityid: any;

  @ViewChild('commentInput') commentInput: any;
  submitComment() {
    const obj = {}
    // Perform your submit logic here
    console.log('Submitted comment:', this.comment);
    this.commonservice.createActivityTracker("commented", this.entityid, "User Details", 'Notes', obj, {}, this.comment);

    // Clear the input field
    this.comment = '';
    this.commentInput.nativeElement.blur();
  }
  ngOnDestroy() {
    this.submentSubscrib.unsubscribe();
  }
  openActivityTracker() {
    this.ngxService.start();
    this.openActivityTkr = true;
    let context = {};
    let activitySearchParams: any;
    this.trackerApiUrl = environment.baseUrl2;
    this.activitySession.getActivitySession.subscribe(activityTab => activitySearchParams = activityTab);
    activitySearchParams.entityType = this.templateData.menuURL != 'CaseType'? this.templateData.menuURL: '';

    if(this.templateData.cardtype!="users" && this.templateData.cardtype != "pathologist" && this.templateData.cardtype != "physician"){
      for (let [key, value] of Object.entries(this.templateData.secondarykeys)) {
        if(key == 'casetype'){
        context['key'] = key;
        context['value'] = key==this.templateData.menuURL.toLowerCase()?'':value;
        this.activityPath = [sessionStorage.getItem("context"), sessionStorage.getItem('locationName'),value, this.templateData.menuURL,]
        }

        if(key == 'casetype' && value != 'orgid' && value != 'lab'){
          activitySearchParams.context = [{key: "parentMenu", value: "casetype"}, {key: "casetype", value: value}]
          }else{
            // AFTER CONFIRMING WITH ARUN COMMENTING THIS LINE OF CODE
            // activitySearchParams.entityId ='';
            activitySearchParams.context = [{key: "parentMenu", value: this.templateData.menuURL}]
          }

      }
    }else{
      activitySearchParams.context = [{key: "parentMenu", value: this.templateData.menuURL}]
      this.activityPath = [sessionStorage.getItem("contextdata"), sessionStorage.getItem('locationName'), sessionStorage.getItem('contextdata'), this.templateData.menuURL]
    }
    this.activitySession.setActivitySession(activitySearchParams);

    this.trackerInput = {
      'productId': this.vitalHttpServices.GetDxurlBasedOnDeployment(sessionStorage.getItem('DeploymentKey')).replace(/(^\w+:|^)\/\//, ''),
      'customerId': sessionStorage.getItem('org_id'),
      'entityType': activitySearchParams.entityType,
      'actionType': 'audit',
      'entityId': activitySearchParams.entityId,
      'ticketId': '',
      'index': '',
      'locationId': sessionStorage.getItem('Locationid'),
      'context': activitySearchParams.context,
      'createdBy': '',
      'startDate': '',
      'endDate': ''
    }
    this.ngxService.stop();
  }


      
//Move from one component to another under a specified main tab - Anyone implementing redirect, please use this method instead of writing new ones
  async changeComponent(destination,tabDetails : {changeTab : boolean, tabIndex : number} = {changeTab : false, tabIndex : 0}) {
    if(tabDetails.changeTab){
      await this.changeTab.emit({index : tabDetails.tabIndex, destination : destination}); 
      return;
    }
    let menuJson = this.menuJson;

    let matchedIndex = this.getIndexOfMenuItem(menuJson,destination);
    if(!matchedIndex || matchedIndex.length == 0){
      return;
    }
    //Get indexes for main and submenus
    let mainMenuIndex = matchedIndex[0];
    let subMenuIndex = matchedIndex[1];
    
    //Set index for corner radius
    this.menuIndex = {mainMenuIndex: mainMenuIndex,subMenuIndex : subMenuIndex};
    
    //Call component function for data
    let Htext = menuJson[mainMenuIndex].Htext;
    let SubMenu = menuJson[mainMenuIndex].SubMenu[subMenuIndex];
    this.SubMenuClick(Htext,SubMenu, { genericCard: this.genericCardIdentifier, 'cardtype':this.cardtype, mainMenuIndex: mainMenuIndex,subMenuIndex:subMenuIndex});
  }

  //Gets main and submenu indexes
  getIndexOfMenuItem(arr, k) {
    for (var i = 0; i < arr.length; i++) {
      var index = arr[i].SubMenu.findIndex(va=> va.URL.toString().toLowerCase().trim() == k.toString().toLowerCase().trim());
      if (index > -1) {
        return [i, index];
      }
    }
  }
}
