<div *ngIf="gridPage" class="m-2 col-sm-12">
    <div *ngIf="!noDataFound" class="upload-grid">
        <div class="modal-header admin-model-header mb-2 mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>{{templateData.menuURL.toString().trim()}}</strong>
            </h3>
        </div>
        <div class="row col-sm-12 button-field-wrap">
            <div class="col-sm-4 float-left">
                <span> <strong>Showing '{{masterLevel ? 'Global Level' : 'Entity Level'}}'
                        {{templateData.menuURL.toString().trim()}}</strong></span>
            </div>
            <!-- <div class="col-sm-8">
            <span *ngIf="!masterLevel" class="instr-size mb-2 float-right">
                <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
                You can drag and drop any {{templateData.menuURL.toString().toLowerCase().trim()}} to change it's
                sequence.</span>
            </div> -->
        </div>
        <wj-flex-grid [headersVisibility]="'Column'" [ngStyle]="{ 'width.px': gridwidth }"
            class="align-center" #grid [isReadOnly]="true" (initialized)="initGrid(grid)"
            [itemsSource]="collectionMethodGridData">
            <div *ngFor="let columnname of gridheader">
                <wj-flex-grid-column [binding]="columnname"
                    [visible]="shouldShowColumn(columnname)"
                    [header]="columnname.split('_').join(' ')" [width]="'*'" [fixed]="true" [allowResizing]="true"
                    [allowDragging]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="120" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                    <button [disabled]="editBtn || masterLevel" class="edit-delete-btn" [title]="editTooltip"
                        (click)="openEditScreen(grid,$event)"><em id="editButton"
                            class="edit-template p-0 fa fa-edit"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <br />
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="collectionMethodGridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="collectionMethodGridData.pageSize" [header]="'Page Size'"
                *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
        <div class="align-center mt-4">
            <button mat-raised-button [disabled]="exportBtn" class="admin-btn-success mr-4"
                (click)="exportCollectionMethodList(grid)">Export</button>
            <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4"
                (click)="getInputData('',[])">
                Create
            </button>
            <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4"
                (click)="loadUploadScreen()">
                Upload
            </button>
            <button [disabled]="copyBtn" title="Copy From Other Entity" mat-raised-button class="admin-btn-success"
                (click)="loadCopyScreen()">
                Copy
            </button>
        </div>
    </div>

    <div class="container create-btn" *ngIf="noDataFound">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>{{templateData.menuURL}}</strong>
            </h3>
        </div>
        <div class="col-sm-12 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
    <div class="align-center mt-4" *ngIf="noDataFound">
        <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('',[])">
            Create
        </button>
        <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()">
            Upload
        </button>
        <button [disabled]="copyBtn" title="Copy From Other Entity" mat-raised-button class="admin-btn-success"
            (click)="loadCopyScreen()">
            Copy
        </button>
        <!-- <button mat-raised-button [disabled]="true" class="admin-btn-success cursor mt-2 ml-2" (click)="copyOrg()">Copy
            From Org</button> -->
    </div>
</div>

<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload {{templateData.menuURL.toString().trim()}}</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate(true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
            (initialized)="initGrid(grid)" #grid [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color:  cell.item.notes == ' will be added on approval'|| cell.item.notes=='Collection Method added' || cell.item.notes == 'Extraction Procedure will be added on approval'||
                         cell.item.notes=='Extraction Procedure added' ? 'green' : (cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' || cell.item.notes=='Sequence only takes numbers'
                        || cell.item.notes=='{{templateData.menuURL.toString()}} already exists!'  || cell.item.notes =='Zero is not allowed for Sequence') ? 'red': cell.item.notes == '{{templateData.menuURL.toString()}} added' ? 'green' : '#ff9800' }">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]=" !(columnname == 'Status')" [allowResizing]="true"
                    [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                    (click)="refreshGrid('list')">
                    Back
                </button>
                <button mat-raised-button class="admin-btn-success" *ngIf="gridWidth > 0"
                    (click)="removeGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid('list')">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid('list')">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="approveCollectionMethods()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>



<!-- Add/Edit Collection Method -->
<div *ngIf="addEditScreen">
    <form [formGroup]="collectionMethodForm" class="collectionMethodForm">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>{{action}}</strong>
                </h3>
            </div>
            <div class="text-wrap-collection-methods row col-sm-12 p-0 ml-0">
                <div class="row col-sm-12 mt-2">

                    <mat-form-field appearance="outline" class="w-100 col-sm-4 mb-4 pt-2">
                        <mat-label>
                            <strong>
                                Name</strong>
                        </mat-label>
                        <input trim type="text" maxlength="255" formControlName="frmname" [readonly]="editEnabled" autoComplete='off' matInput
                            aria-label="Name">
                        <mat-error>{{getErrorMessage(collectionMethodForm.value.frmname,'name')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100 col-sm-4 mb-4 pt-2" appearance="outline">
                        <mat-label>
                            <strong>
                                Sequence</strong>
                        </mat-label>
                        <input trim type="text" maxlength="4" pattern="[0-9]+" (keypress)="allowOnlyNumber($event)"
                            formControlName="frmsequence" autoComplete='off' matInput aria-label="Sequence">
                        <mat-error>{{getErrorMessage(collectionMethodForm.value.frmsequence)}}</mat-error>
                    </mat-form-field>

                    <section class="example-section col-sm-4 mb-3 pl-4">
                        <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="frmactive">
                            Active</mat-checkbox>
                    </section>

                    <section class="example-section col-sm-3 mb-3 pl-4" *ngIf="dKey">
                        <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="frmdefault">
                            Default</mat-checkbox>
                    </section>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-3">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid('list')">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="saveCollectionMethod('',collectionMethodForm.value)">
                    Save & Close
                </button>
                <button mat-raised-button class="admin-btn-success"
                    *ngIf="(action == 'Create Collection Method' || 'Create Extraction Procedure') && !editEnabled"
                    (click)="saveCollectionMethod('new',collectionMethodForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>


<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{action}}</strong>
        </h3>
    </div>
    <form [formGroup]="copycollectionMethodForm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0">
            <mat-form-field class="col-sm-4 example-additional-selection mat-height-wrap" appearance="outline">
                <mat-label>Deployment</mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                    <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Group </mat-label>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.orgname.toLowerCase().includes(searchInput.toLowerCase())"
                            title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button><em class="fa fa-chevron-down chevron-align"
                        style="position: relative;margin-bottom: 6px;"></em></button>
                <button *ngIf="copycollectionMethodForm.value.frmOrganization" matSuffix mat-icon-button
                    aria-label="Clear" title="Reset Search" (click)="clearEntity()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <div class="col-sm-4 mb-3 flex-right">
                <button mat-raised-button class="admin-btn-success mt-1 get-height" [disabled]="postUpload"
                    (click)="getMasterLevelData('copy')">Get Global Data</button>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
                    (initialized)="initGrid(flexgrid,1)" #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="true" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                        [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.notes == 'Collection Method will be added on approval'|| cell.item.notes=='Collection Method added' || cell.item.notes == 'Extraction Procedure will be added on approval'|| cell.item.notes=='Extraction Procedure added' ? 'green' : cell.item.notes=='Mandatory field is missing!'
                                 || cell.item.notes== 'Extraction Procedure already exists!' || cell.item.notes=='Null values exist!' || cell.item.notes=='Collection Method already exists!'?'red': '#ff9800' }">{{cell.item.notes}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'Status')" [allowResizing]="true"
                            [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <section class="example-section align-center row">
                    <mat-checkbox [color]="task.color" class="mt-3 example-margin" *ngIf="!postUpload"
                        formControlName="frmcpyasinactive">Copy
                        as Inactive</mat-checkbox>
                </section>
                <div class="col-sm-12 pl-0 mt-4">
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                        (click)="refreshGrid('list')">Back</button>
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="approveCollectionMethods(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">Back</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="showConfirmPopupFlag" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message">
                        <ol>
                        <li style="text-align: start;" *ngIf="showConfirmPopupFlag==1 || showConfirmPopupFlag==2">{{ PopUpmsgs(locations)?.[showConfirmPopupFlag]?.['message'] }}</li>
                        <li style="text-align: start;" *ngIf="caseprofileAlert">{{ PopUpmsgs(locations)?.[3]?.['message'] }}</li>
                        </ol>
                        </div>
                </div>
                <div class="modal-footer">
                    <button mat-flat-button class="admin-btn-success" *ngIf="showConfirmPopupFlag==1" (click)="redirectiontoTabSave('Default Stains')">
                        Redirect to Default Stain
                     </button>
                      <button mat-flat-button class="admin-btn-success" *ngIf="caseprofileAlert" (click)="redirectiontoTabSave('Case Profiles')">
                        Redirect to Case Profile
                      </button>
                      <button mat-flat-button class="admin-btn-success" (click)="showConfirmPopupFlag = undefined">
                        Close
                      </button>
                      <button mat-flat-button *ngIf="showConfirmPopupFlag==2" class="admin-btn-success" (click)="showConfirmPopupFlag = undefined">
                        Ok
                      </button>
                </div>
            </div>
        </div>
    </div>
  </div>


