import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    const span = document.createElement('span');
    span.innerHTML = value;
    return span.textContent || span.innerText || '';
  }
}