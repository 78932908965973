<div class=" p-4" *ngIf="!noDataFoundFlag && !userDetials['faxEnable']">
  <div class="row text-sm mb-1">
    <div class="col-sm-2">Fax : {{userDetials?.['Fax']}}</div>
    <div class="col-sm-10 d-flex">
      <div class="mr-2 cursor" title="{{ faxDetailsCaseTypes }}"> CaseType : {{ faxDetailsCaseTypes?.length ? faxDetailsCaseTypes?.length : 0 }} |</div>
      <div class="mr-2 cursor" title="{{ faxDetailsServices }}">Service : {{ faxDetailsServices?.length  ? faxDetailsServices?.length  : 0}} |</div>
      <div class="mr-2 cursor" title="{{ faxDetailsLocations }}">Locations : {{ faxDetailsLocations?.length ? faxDetailsLocations?.length : 0 }} </div>
    </div>
  </div>
</div>


<div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
  <span class="col-sm-12 nodata-wrapper">No Data Found</span>
</div>