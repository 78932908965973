<div class="modal-dialog HL7Dialog">
  <form [formGroup]="HL7Logsgroup">
    <div class="modal-content header-wraps">

      <!-- Modal Header -->
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="modal-title w-100 admin-model-header-txt">HL7 Logs</h3>
          <span type="button" data-dismiss="modal" (click)="HL7LogsgroupClose()" class="mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
              title="Close">
          </span>
      </div>
      <br />

      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row mb-1">
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0 text-right">
                  <label for="DKeys"><strong>Select Deployment:&nbsp;&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <select name="DeploymentKeys" id="DKeys2" class="form-control" required
                    formControlName="DeploymentKey">
                    <option value="none" *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'"
                      disabled selected>--Select--</option>
                    <option value="none" *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL'"
                      enabled selected>{{selecteddefaultdeploymentkey}}</option>
                    <!-- <option  *ngFor="let i of DeploymentKeys" value={{i}}>{{i}}</option> -->
                    <ng-container *ngFor="let i of DeploymentKeys">
                      <option [value]="i" *ngIf="i !== selecteddefaultdeploymentkey">{{ i }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0 text-right">
                  <label for="QSearch"><strong>Quick Search:&nbsp;&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <select name="drpQSearch" id="drpQSearch" class="form-control w-100" required
                    formControlName="QuickSearch">
                    <option value="Case ID">Case ID</option>
                    <option value="Log ID">Log ID</option>
                    <option value="Accession #">Accession #</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              &nbsp;
            </div>
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0">
                  <label for="QSearch"><strong>&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <input [attr.disabled]="HL7Logsgroup.value.DeploymentKey == 'none' ? true : null"
                    *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'" type="text"
                    id="inpQSearch" class="form-control" name="inpQSearch" placeholder="Enter Search Text" required
                    formControlName="QuickSearchInpTxt">
                  <input *ngIf="selecteddefaultdeploymentkey  && selecteddefaultdeploymentkey !=='ALL'" type="text"
                    id="inpQSearch" class="form-control" name="inpQSearch" placeholder="Enter Search Text" required
                    formControlName="QuickSearchInpTxt">
                </div>
              </div>
            </div>

            <div class="col-md-5 d-lg-none m-0 p-0">&nbsp;</div>
            <div class="col-md-6 col-lg-2 p-0 m-md-1 m-lg-0" style="display: table; width: 100%;">
              <div style="display: table-cell; vertical-align: middle">
                <button mat-raised-button class="admin-btn-success ml-4" [disabled]="HL7Logsgroup.invalid"
                  (click)=searchHL7Logs(HL7Logsgroup.value)>Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <!-- <div class="modal-footer" style=" border-top: none !important; display: block; justify-content: normal;"
        *ngIf="ShowUpdateHl7">
        <form [formGroup]="HL7LogsUpdategroup">
          <div class="container-fluid row w-100 p-0 m-0">
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row p-0 m-0">
                <div class="col-md-4 p-0 m-0 text-right">
                  <label for="drpdwnChangeStatus"><strong>Change Status:&nbsp;&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <select name="drpdwnChangeStatus" id="drpdwnChangeStatus" class="form-control" required
                    formControlName="frmChangeStatus">
                    <option value="-1">--Select--</option>
                    <option *ngFor="let status of ChangeStatus" [value]="status.Description">{{status.Description}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row p-0 m-0">
                <div class="col-md-4 p-0 m-0 text-right">
                  <label for="drpdwnChangeStatus"><strong>Create Comment:&nbsp;&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <textarea name="AddComment" id="AddComment" rows="3" cols="20" required class="form-control w-100"
                    formControlName="frmAddComment">
                    </textarea>
                </div>
              </div>
            </div>
            <div class="col-md-5 d-lg-none  m-0 p-0">&nbsp;</div>
            <div class="col-md-6 col-lg-2 pl-4 p-0 m-md-1 m-lg-0" style="display: table; width: 100%;">
              <div style="display: table-cell; vertical-align: middle">
                <button mat-raised-button class="admin-btn-success d-block" [disabled]="HL7LogsUpdategroup.invalid"
                  (click)=UpdateHL7Logs(HL7LogsUpdategroup.value)>Update</button>
              </div>
            </div>
          </div>
        </form>
      </div> -->

      <!-- Extended model -->
      <div id="divgridSelectAlert" style="color: red; text-align:center;"></div>
      <GridContainerWithCheckBox *ngIf="templateData != '' && templateData != null && showGrid"
        (SelectedItemsEvent)="getSelectedGridItems($event)" [templateData]="templateData">
      </GridContainerWithCheckBox>
      <br />
      <br />
    </div>
  </form>
</div>