<div *ngIf="gridPage" class="m-2 col-sm-12">
  <div *ngIf="!noDataFound" class="upload-grid">
    <div class="modal-header admin-model-header mb-2 mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>Sites</strong>
      </h3>
    </div>
    <wj-flex-grid [ngStyle]="{ 'width.px': gridwidth }" class="align-center" #grid [isReadOnly]="true"
      [headersVisibility]="'Column'" (initialized)="initGrid(grid)" [itemsSource]="sitesGridData">

      <wj-flex-grid-column [header]="'Action'" align="center" [width]="111" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
          <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEditScreen(grid, $event)">
            <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'Siteid'" [visible]="false" [allowSorting]="true" [header]="'Siteid'"
        [width]="'*'" [minWidth]="100" [format]="'d'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 3" [binding]="'SubSite'" [allowSorting]="true" [header]="'Body Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>

      <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 1" [binding]="'EntityType'" [header]="'Site Type'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 1" [binding]="'EntityName'" [allowSorting]="true"
        [header]="'Site Name'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>

      <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 2" [binding]="'EntityName'" [allowSorting]="true"
        [header]="'Body Site'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'BodySite'" [allowSorting]="true" [header]="'Body Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column [visible]="false" [binding]="'SiteShortname'" [allowSorting]="true" [header]="'Short name'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'SubSite'" [allowSorting]="true" [header]="'Sub Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'SiteOrder'" [allowSorting]="true" [header]="'Site Order'" [isRequired]="true"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
        [binding]="'Type'" [allowSorting]="true" [header]="'Type'" [isRequired]="true" [width]="'*'">
      </wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
        [binding]="'MappingSite'" [allowSorting]="true" [header]="'Mapping Site'" [isRequired]="true" [width]="'*'"
        [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'IsActive'" [allowSorting]="true" [header]="'Status'" [isRequired]="true"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'CreatedBy'" [visible]="false" [allowSorting]="true" [header]="'CreatedBy'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'CreatedDate'" [visible]="false" [allowSorting]="true" [header]="'CreatedDate'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'ModifiedBy'" [visible]="false" [allowSorting]="true" [header]="'ModifiedBy'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'ModifiedDate'" [visible]="false" [allowSorting]="true" [header]="'ModifiedDate'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <br />
    <div class="align-center mt-2">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="sitesGridData">
      </wj-collection-view-navigator>
      <wj-menu [(value)]="sitesGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>
    <div class="align-center mt-4">
      <button mat-raised-button [disabled]="exportBtn" class="admin-btn-success mr-4" (click)="ExportExcel(grid, 1)">
        Export
      </button>
      <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('', [])">
        Create
      </button>
      <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()">
        Upload
      </button>
      <button [disabled]="copyBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadCopyScreen()">
        Copy
      </button>
      <button mat-raised-button class="admin-btn-success mr-4" (click)="OpenMangeSite()">
        Manage Site Orders
      </button>
    </div>
  </div>

  <div class="row container create-btn align-center" *ngIf="noDataFound">
    <div class="modal-header admin-model-header mb-2 mt-2 col-sm-12">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>Sites</strong>
      </h3>
    </div>
    <!-- disable the Manage site orders -->
    <!-- <div  class="button-field-wrap mt-2">
      <mat-form-field appearance="outline" class="frmMatSelect w-25">
        <mat-label>Template</mat-label>
        <mat-select disableOptionCentering class="ml-2 temp-mat-align">
          <mat-option  (click)="OpenMangeSite()">
            <span>Manage Site Orders</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <div class="col-sm-12 nodata-wrap mt-4" style="height: 48vh !important;">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>

  <div class="align-center mt-4" *ngIf="noDataFound">
    <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('', [])">
      Create
    </button>
    <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()">
      Upload
    </button>
    <button [disabled]="copyBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadCopyScreen()">
      Copy
    </button>
    <button mat-raised-button class="admin-btn-success mr-4" (click)="OpenMangeSite()">
      Manage Site Orders
    </button>
  </div>
</div>

<div *ngIf="uploadClicked" class="my-sites-form">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong>Upload Sites</strong>
    </h3>
  </div>
  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
      <mat-select isableOptionCentering class="ml-2 temp-mat-align">
        <mat-option class="temp-font-size" (click)="downloadFormatFields()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadFormatFields(true)">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload the Sites.</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
    <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto" />
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
    </div>
    <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
      Back
    </button>
  </div>

  <div *ngIf="gridWidth > 0">
    <wj-flex-grid [headersVisibility]="'Column'" [isReadOnly]="true" [frozenColumns]="2"
      [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid (formatItem)="formatItem(grid,$event)">

      <div *ngFor="let columnname of sheetHeader">
        <wj-flex-grid-column *ngIf="columnname != 'Status'" [binding]="columnname?.toString().toLowerCase()"
          [header]="columnname" [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
          [format]="'d'">
        </wj-flex-grid-column>
        <wj-flex-grid-column *ngIf="postUpload && columnname == 'Status'"
          [binding]="columnname?.toString().toLowerCase()" [header]="columnname" [allowResizing]="true" [width]="'*'"
          [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>

      <!-- Action -->
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
            <em id="deleteButton" [title]="'Delete'" class="cursor p-0 fa fa-trash"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="row pl-3">
      <div class="col-sm-2 p-0 mt-4">
        <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-wrap p-0 mt-4">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
            Finish
          </button>
          <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
            Cancel
          </button>
          <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload" class="admin-btn-success mr-4"
            (click)="uploadSitesData()">
            Approve
          </button>
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportResult(grid)">
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">* Invalid Columns: </div>
    &nbsp;<span>{{ invalidColumns }} </span>
  </div>
</div>

<!--Copy from other entity-->
<div *ngIf="copyDataClicked">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong>Copy Sites</strong>
    </h3>
  </div>
  <form [formGroup]="copycollectionMethodForm" class="copy-collection">
    <div class="row col-sm-12 p-0 ml-0">
      <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
        <mat-label><span class="error-msg">*</span>Deployment</mat-label>
        <mat-select disableOptionCentering type="text" matInput (selectionChange)="onChangeDeployment($event)"
          #searchbar formControlName="frmDepKey">
          <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{ i }}">
            <span>{{ i }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
        <mat-label><span class="error-msg">*</span>Group </mat-label>
        <em class="fa fa-chevron-down chevron-align"></em>
        <input [readonly]="postUpload" formControlName="frmOrganization" type="text" matInput #searchbar (keyup)="
                  fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)" [matAutocomplete]="orgauto"
          placeholder="Search here ..." style="width: 95%;" />
        <mat-autocomplete #orgauto="matAutocomplete">
          <div *ngFor="let show of searchResult">
            <mat-option [disabled]="postUpload" class="mat-opt-align"
              title="{{ show.organizationname }} ({{ show.organizationid }})"
              (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
              <span>{{ show.organizationname }} ({{ show.organizationid }})</span>
            </mat-option>
          </div>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="gridDisplay" class="col-sm-12 mt-2">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
          (initialized)="initGrid(flexgrid, 1)" #flexgrid [frozenColumns]="1"
          (formatItem)="formatItem(flexgrid, $event)">
          <!-- Starts -->
          <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 3" [binding]="'subsite'" [allowSorting]="true"
            [header]="'Body Site'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 1" [binding]="'sitetype'" [header]="'Site Type'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 1" [binding]="'sitename'" [allowSorting]="true"
            [header]="'Site Name'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'bodysite'" [allowSorting]="true"
            [header]="'Body Site'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'subsite'" [allowSorting]="true" [header]="'Sub Site'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column
            *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
            [binding]="'mappingsite'" [allowSorting]="true" [header]="'Mapping Site'" [isRequired]="true" [width]="'*'">
          </wj-flex-grid-column>
          <wj-flex-grid-column
            *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
            [binding]="'supportatlength'" [allowSorting]="true" [header]="'Support At Length'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [binding]="'siteorder'" [allowSorting]="true" [header]="'Site Order'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <!-- <wj-flex-grid-column *ngIf="siteFormat == 1" [binding]="'sitelocation'" [allowSorting]="true" [header]="'Site Location'" [isRequired]="true"
                    [width]="'*'" ></wj-flex-grid-column>
              <wj-flex-grid-column *ngIf="siteFormat != 3" [binding]="'subsite'" [allowSorting]="true" [header]="'Sub Site'" [isRequired]="true"
                    [width]="'*'" ></wj-flex-grid-column> -->
          <wj-flex-grid-column [binding]="'isactive'" [allowSorting]="true" [header]="'IsActive'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <!-- Ends -->
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <section *ngIf="!postUpload" class="example-section align-center row">
          <mat-checkbox [color]="task.color" id="copyasinactive" ng-init='checkStatus=false' ng-model='checkStatus'
            [checked]='checkStatus' (change)="triggerStatus($event,'inactive')">
            Copy as Inactive
          </mat-checkbox>
          <mat-checkbox [color]="task.color" id="copyruleout" [disabled]='!copyruleoutflag' ng-init='checkRuleoutStatus=false' ng-model='checkRuleoutStatus'
          [checked]='checkRuleoutStatus' (change)="triggerStatus($event,'ruleout')">
          Copy Rule Out
        </mat-checkbox>
        </section>

        <div class="col-sm-12 pl-0 mt-4">
          <button mat-raised-button class="admin-btn-success mr-4" [disabled]=""
            *ngIf="!noDataFound && !postUpload && gridWidth > 0" (click)="removeGrid()">
            Back
          </button>
          <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth == 0"
            (click)="refreshGrid('list')">
            Back
          </button>
          <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload" (click)="removeGrid()">
            Back
          </button>

          <div style="float: right;">
            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
              Cancel
            </button>
            <!-- in grid hide -->
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload" (click)="refreshGrid('list')">
              Finish
            </button>
            <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn() || (selectedItems && selectedItems.length == 0)"
              class="admin-btn-success mr-4" (click)="uploadCopyData(flexgrid)">
              Approve
            </button>
            <!-- in grid hide -->
            <button mat-raised-button class="admin-btn-success" *ngIf="postUpload" (click)="ExportResult(flexgrid)">
              Download
            </button>
          </div>

        </div>
      </div>

      <div class="col-sm-12 pl-0 mt-4" *ngIf="!gridDisplay && !noDataFound">
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!noDataFound && !postUpload && gridWidth == 0"
          (click)="refreshGrid('list')">
          Back
        </button>
      </div>

      <div class="col-sm-12" *ngIf="noDataFound">
        <div class="container create-btn col-sm-12 align-center" style="height: 48vh !important;" *ngIf="noDataFound">
          <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
          </div>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
          Back
        </button>
      </div>

    </div>
  </form>
</div>

<!-- Create Sites -->
<div *ngIf="addEditScreen" class="managesite">
  <form [formGroup]="sitesForm" class="sitesForm">
    <div class="row col-sm-12 p-0 m-0">
      <div class="col-sm-12 modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
          <strong>{{ action }}</strong>
        </h3>
      </div>
      <div class="text-wrap-sites row col-sm-12 p-0 ml-0">
        <div class="row col-sm-12 mt-2 p-2">
          <mat-form-field class="set-margin width col-sm-3 mb-3"
            *ngIf="((siteFormat == 3) || (siteFormat == 2 && sitesForm.value.frmsitetype == 'Sub Site' && sitesForm.value.frmbodysite != null))"
            appearance="outline">
            <mat-label>
              <strong><span class="text-danger" *ngIf="siteFormat != 1">*</span>
                Body Site</strong>
            </mat-label>
            <input trim type="text" maxlength="255" *ngIf="siteFormat == 2" [readonly]="bodySiteReadOnly" (input)="patchShortName(sitesForm.value.frmbodysite)"
              formControlName="frmbodysite" matInput [matAutocomplete]="auto" aria-label="Bodysite" />
            <input trim type="text" [readonly]="action == 'Edit Site'" maxlength="255" *ngIf="siteFormat != 2" (input)="patchShortName(sitesForm.value.frmbodysite)"
              formControlName="frmbodysite" matInput autocomplete="off" aria-label="Bodysite" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let bodysite of filterBodySites(sitesForm.value.frmbodysite)"
                [value]="bodysite.BodySite" (onSelectionChange)="onChangeBodySite($event, bodysite)">
                <span>{{ bodysite.BodySite }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- Added Entity type dropdown -->
          <mat-form-field appearance="outline" autoComplete="off" *ngIf="siteFormat != 3"
            class="set-margin w-100 col-sm-3 mb-3">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Site Type</strong>
            </mat-label>

            <mat-select [disabled]="action == 'Edit Site'" disableOptionCentering formControlName="frmsitetype">
              <mat-option *ngIf="siteFormat == 1" [value]="'Site Location'">Site Location</mat-option>
              <mat-option [value]="'Sub Site'">Sub Site</mat-option>
              <mat-option [value]="'Body Site'">Body Site</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="siteFormat != 3" class="set-margin w-100 col-sm-3 mb-3">
            <mat-label *ngIf="siteFormat == 1">
              <strong><span class="text-danger">*</span>
                Site Name</strong>
            </mat-label>
            <mat-label *ngIf="siteFormat == 2">
              <strong><span class="text-danger">*</span>
                {{(!sitesForm.value.frmsitetype) ? 'Site Name' : sitesForm.value.frmsitetype}}</strong>
            </mat-label>
            <input trim [readonly]="action == 'Edit Site'" type="text" maxlength="255" formControlName="frmsitename" (input)="patchShortName(sitesForm.value.frmsitename)"
              autoComplete="off" matInput aria-label="sitename"/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="set-margin w-100 col-sm-3 mb-3">
            <mat-label>
              <strong>
                Short Name</strong>
            </mat-label>
            <input trim maxLength = "100" type="text" formControlName="frmshortname"
              autoComplete="off" matInput aria-label="shortname" />
          </mat-form-field>

          <mat-form-field appearance="outline" autoComplete="off" class="set-margin w-100 col-sm-3 mb-3"
            *ngIf="sitesForm.value.frmsitetype == 'Body Site' && templateData.secondarykeys.casetype == 'GI Histology_PLUS'">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Mapping Site</strong>
            </mat-label>

            <mat-select disableOptionCentering formControlName="frmmappingsite">
              <mat-option [value]="">-- Select --</mat-option>
              <mat-option *ngFor="let value of mappingSite" [value]="value">
                {{ value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- ends -->

          <mat-form-field appearance="outline"
            class="set-margin w-100 col-sm-3 mb-3">
            <mat-label>
              <strong>Site Order</strong>
            </mat-label>
            <input [readonly]="(sitesForm.value.frmdefault == false) ? false : true" type="text"
              formControlName="frmsiteorder" pattern="[0-9]+" (keypress)="allowOnlyNumber($event)" maxlength="4"
              matInput aria-label="Type" />
          </mat-form-field>

          <div
            *ngIf="sitesForm.value.frmsitetype == 'Sub Site' && templateData.secondarykeys.casetype == 'GI Histology_PLUS'"
            class="col-sm-2 set-margin example-section pl-4">
            <mat-checkbox [color]="task.color" class="col-sm-12 mt-1 mr-2 example-margin" formControlName="frmatlength">Support At
              length
            </mat-checkbox>
          </div>

          <div class="set-margin example-section">
            <mat-checkbox [color]="task.color" class="mt-1 mr-2 ml-3 example-margin" formControlName="frmdefault">Default
            </mat-checkbox>
          </div>

          <div class="set-margin example-section">
            <mat-checkbox [color]="task.color" class="mt-1 mr-2 ml-3 example-margin" formControlName="frmactive">Active
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-3">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid('list')">
          Back
        </button>
      </div>
      <div class="col-sm-9 button-wrap">

        <button mat-raised-button class="admin-btn-success mr-4" (click)="saveSite('', sitesForm.value)">
          Save & Close
        </button>
        <button mat-raised-button class="admin-btn-success" *ngIf="action == 'Create Site'"
          (click)="saveSite('new', sitesForm.value)">
          Save & Create
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="showConfirmPopupFlag" class="col-sm-12">
  <div class="modal overlay" id="myModal1" role="dialog">
      <div class="modal-dialog" id="modaldialog1">
          <div class="modal-content">
              <div class="modal-header warning-model-header">
                  <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
              </div>
              <div class="modal-body p-2 msg-wrap">
                  <div class="row col-sm-12 body-message" style="text-align: start;">
                    <ol>
                      <li *ngIf="ruleout">{{ PopUpmsgs(locations)?.[3]?.['message'] }}</li>
                      <li *ngIf="showConfirmPopupFlag==1 || showConfirmPopupFlag==2">{{ PopUpmsgs(locations)?.[showConfirmPopupFlag]?.['message'] }}</li>
                      <li *ngIf="caseProfileAlert">{{ PopUpmsgs(locations)?.[4]?.['message'] }}</li>
                    </ol>
                      </div>
              </div>
              <div class="modal-footer">
                <button mat-flat-button class="admin-btn-success"*ngIf="ruleout" (click)="redirectToOtherTabOnSave('Rule Outs')">
                    Redirect to Rule Outs
                  </button>
                  <button mat-flat-button class="admin-btn-success" *ngIf="showConfirmPopupFlag==1" (click)="redirectToOtherTabOnSave('Default Stains')">
                    Redirect to Default Stain
                  </button>
                  <button mat-flat-button class="admin-btn-success" *ngIf="caseProfileAlert" (click)="redirectToOtherTabOnSave('Case Profiles')">
                    Redirect to Case Profiles
                  </button>
                  <button mat-flat-button  class="admin-btn-success" (click)="showConfirmPopupFlag = undefined">
                      Close
                    </button>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Send the manage site orders -->
<div class="row p-0 m-0" *ngIf="openmangesiteorders">
  <div class="col-12 p-0 m-0">
    <app-manage-site-orders [templateData]="templateData" [buttonData]="buttonData">
    </app-manage-site-orders>
  </div>
</div>

