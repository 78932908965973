import { Type } from '@angular/core';
export class VitalAdminTab {
    public id: number = -1;
    public title: string;
    public tabData: any;
    public active: boolean = false;
    public component;
    public cardType: string = '';
    public accountid: number = 0;
    public Removable : boolean;
    public rolepermission: any;
    public rolemenu:any;
    public GroupCode: any;
    constructor(title: string, tabData: any, cardType: string, accountid: number, removable: boolean, GroupCode: any) {
        this.tabData = tabData;
        //this.component = component;"VitalGeneralComponent"
        this.title = title;
        this.cardType = cardType;
        this.accountid = accountid;
        this.Removable = removable;
        this.GroupCode = GroupCode;
    }
}
