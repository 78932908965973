import { Injectable } from "@angular/core";
import { VitalAdminTabService } from "../../client/tab.service";
import { CommonService } from "./commonservices";
import { VitalHttpServices } from "./VitalHttpServices";

@Injectable()
export class InjectorService {

    public tabService: VitalAdminTabService;
    public commonService: CommonService;
    public vitalHttpServices: VitalHttpServices;

    constructor() {
    }

    getTabService(): any {
        return this.tabService;
    }
    setTabService(tabService: VitalAdminTabService): void {
        this.tabService = tabService;
    }
    getCommonService(): any {
        return this.commonService;
    }
    setCommonService(commonService: CommonService): void {
        this.commonService = commonService;
    }
    getVitalHttpServices(): any {
        return this.vitalHttpServices;
    }
    setVitalHttpServices(vitalHttpServices: VitalHttpServices): void {
        this.vitalHttpServices = vitalHttpServices;
    }

}