<div class="modal-header admin-model-header mt-3" *ngIf="fromComponent != 'homepage'">
    <h3 class="modal-title w-100 admin-model-header-txt"><b>{{ editMode ? userData.loginname :
            'Create User'}}</b></h3>
</div>

<mat-tab-group [selectedIndex]="selectedTab.value" #userTabs class="stretched-user-tabs">
    <form autocomplete="off" id="userForm" [formGroup]="frm_AddUsergroup">
        <mat-tab label="User Details" disabled="{{userTabDisable}}">
            <div class="row p-0 m-0 mt-3 p-1 facilityTabHeight">
                <div class="col-sm-12 form-wrap pt-1">
                    <div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row" id="input-container">
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>NPI
                                              <!-- <span class="star-mark">*</span> -->
                                            </mat-label>
                                            <input matInput placeholder="Enter Valid NPI" maxlength="10" type="text"
                                                autocomplete="off" class="form-control radius-change form-font"
                                                formControlName="frmInp_NPI" (input)="NPIvalidated = false" />
                                            <!-- <mat-icon matSuffix> -->
                                                <!-- <button type="button" [disabled]="disableNPI()" style="position: relative; top: -3px;">
                                                    <i class="fa fa-check" title="Validate" (click)="ValidateNPIandgetData(frm_AddUsergroup.value.frmInp_NPI)"></i>
                                                </button> -->
                                            <!-- </mat-icon> -->
                                        </mat-form-field>

                                        <br />
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="gender-wrap w-100">
                                            <mat-label>Title</mat-label>
                                            <mat-select class="form-control form-font" disableOptionCentering
                                                formControlName="frmInp_title" style="padding: 0rem 0px !important; border: none !important;">
                                                <mat-option value="Dr.">Dr.</mat-option>
                                                <mat-option value="Miss.">Miss.</mat-option>
                                                <mat-option value="Mr.">Mr.</mat-option>
                                                <mat-option value="Ms.">Ms.</mat-option>
                                                <mat-option value="Mrs.">Mrs.</mat-option>
                                                <mat-option value="Prof.">Prof.</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>First Name
                                              <!-- <span class="star-mark">*</span> -->
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter First Name" id="firstname"
                                                class="form-control radius-change" formControlName="frmInp_FirstName"
                                                maxlength="20" />
                                            <!-- <mat-error *ngIf="formcontrol['frmInp_FirstName'].hasError('required')" >Please enter the first name</mat-error> -->
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            First Name is required !</span>
                                    </div> -->
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Last Name
                                              <!-- <span class="star-mark">*</span> -->
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter Last Name" id="lastname"
                                                class="form-control radius-change" formControlName="frmInp_LastName"
                                                (change)="GenerateLoginID()" maxlength="20" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            Last Name is required !</span>
                                    </div> -->
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Middle Initial
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter Middle Initial" id="middleinitial"
                                                class="form-control radius-change" formControlName="frmInp_MiddleInitial"
                                                maxlength="20" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            Middle Initial is required !</span>
                                    </div> -->
                                    </div>

                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="gender-wrap w-100">
                                            <mat-label>Gender</mat-label>
                                            <mat-select class="form-control form-font" disableOptionCentering
                                                formControlName="frmInp_Gender" style="padding: 0.0rem 0px !important; border: none !important;">
                                                <mat-option value="1">Male</mat-option>
                                                <mat-option value="2">Female</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-12">
                                <div class="row" id="input-container">
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Qualification
                                            </mat-label>
                                            <input matInput placeholder="Enter Qualification" id="qualification" type="text"
                                                autocomplete="off" class="form-control radius-change" formControlName="frmInp_Qualification"
                                                maxlength="50" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            Last Name is required !</span>
                                    </div> -->
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>UPIN
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter UPIN" id="qualification"
                                                type="number" class="form-control radius-change" formControlName="frmInp_UPIN"
                                                maxlength="25" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            UPIN is required !</span>
                                    </div> -->
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>SSN
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter SSN" (keypress)="AllowOnlyNumber($event)"
                                                id="qualification" class="form-control radius-change" formControlName="frmInp_SSN"
                                                maxlength="9" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            UPIN is required !</span>
                                    </div> -->
                                    </div>
                                    <div class="col-sm-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>User Code
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter User Code" id="usercode"
                                                class="form-control radius-change" formControlName="frmInp_UserCode"
                                                maxlength="25" />
                                        </mat-form-field>
                                        <!-- <div style="display:table">
                                        <span class="text-danger requiredError errorDisplayFont">
                                            User Code is required !</span>
                                    </div> -->
                                    </div>

                                    <div class="col-sm-2" *ngIf="!editMode && userLoginType != 'Email'">
                                        <!-- <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Login Name<span class="text-danger pr-1" *ngIf="organizationLoginType=='LoginName'">*</span>
                                            </mat-label>
                                            <input matInput placeholder="Enter Login Name" id="loginname" type="text"
                                                class="form-control radius-change" formControlName="frmInp_LoginName"
                                                maxlength="12"  />
                                        </mat-form-field> -->
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Login Name<span class="text-danger pr-1" *ngIf="organizationLoginType=='LoginName'">*</span>
                                            </mat-label>
                                            <input appBlockCopyPaste autocomplete="off" matInput class="radius-change" [readonly]="organizationLoginType != 'LoginName' || editMode"
                                                formControlName="frmInp_LoginName" type="text" maxlength="12" (keypress)="donotAllowSpecialCharacter($event)">
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-2 align-center mb-2" *ngIf="organizationLoginType == 'Email'">
                                        <label class="mr-2">Allow Login</label>
                                        <input autocomplete="off" class="list-group-item mb-2 mr-2" type="checkbox"
                                            name="list_name" formControlName="frmInp_AllowLogin" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4 mt-2">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>External User GUID
                                    </mat-label>
                                    <input autocomplete="off" matInput title="{{frm_AddUsergroup.value.frmInp_ExternalUserGUID}}"
                                        id="inp_ExternalUserGUID" class="form-control" readonly formControlName="frmInp_ExternalUserGUID" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 row pr-0 mt-2">
                                <div class="col-sm-8">
                                    <div class="col-sm-12 addr-wrap">
                                        <span class="addr-details">Address</span>
                                        <div class="row col-sm-12">
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Address 1
                                                    </mat-label>
                                                    <input autocomplete="off" matInput placeholder="Enter Address 1" id="addressone"
                                                        type="text" class="form-control radius-change" formControlName="frmInp_Address1"
                                                        maxlength="50" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Address 2
                                                    </mat-label>
                                                    <input autocomplete="off" matInput placeholder="Enter Address 2" id="addresstwo"
                                                        type="text" class="form-control radius-change" formControlName="frmInp_Address2"
                                                        maxlength="50" />
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row col-sm-12 mt-2">
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>City
                                                    </mat-label>
                                                    <input autocomplete="off" matInput placeholder="Enter City" id="city"
                                                        type="text" class="form-control radius-change" formControlName="frmInp_City"
                                                        maxlength="20" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="gender-wrap w-100">
                                                    <mat-label>State</mat-label>
                                                    <mat-select disableOptionCentering class="form-control form-font"
                                                        formControlName="frmInp_State" style="padding: 0rem 0px !important; border: none !important;">
                                                        <!-- <mat-option >Texas</mat-option> -->
                                                        <mat-option *ngFor="let s of StateList" value="{{s.LookUpValue}} ({{s.Description}})">
                                                            {{s.LookUpValue}} ({{s.Description}})
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row col-sm-12 mt-2">
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Country
                                                    </mat-label>
                                                    <input autocomplete="off" matInput placeholder="Enter Country" id="city"
                                                        type="text" class="form-control radius-change" formControlName="frmInp_Country"
                                                        maxlength="25" />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-6 addrs-line">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>ZIP
                                                    </mat-label>
                                                    <input autocomplete="off" matInput placeholder="eg:#####-####"
                                                        maxlength="10" id="zip" type="text" (keypress)="AllowOnlyNumber($event)" class="form-control radius-change"
                                                        formControlName="frmInp_Zip" >
                                                    <!-- <mat-hint>eg:#####-####</mat-hint> -->
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-4 upload-wrap">
                                    <span class="sign-upload">User Signature</span>
                                    <div class="col-sm-12 addrs-line">
                                        <div *ngIf="!frm_sign">
                                            <div class="custom-dropzone image-height row m-0" appDnd (fileDropped)="onUserImageUpload($event)">
                                                <input autocomplete="off" type="file" id="imageFileDrop" (change)="onUserImageUpload($event)"
                                                    accept="image/*" [multiple]="false" hidden />
                                                <span class="mb-2 error-size">Drag & Drop </span>
                                                <span class="mb-2 align-center"><strong>or</strong></span>
                                                <label class="btn-sm btn rounded browse ml-2" for="imageFileDrop">Browse</label>
                                            </div>
                                        </div>
                                        <div class="image-height" *ngIf="frm_sign" style="width: 100%;">
                                            <img style="max-width: 100%;max-height: 100%;width: 100%;height:100%;overflow: auto"
                                                [src]="frm_sign" alt="" srcset="">
                                        </div>
                                        <div *ngIf="frm_sign != null && frm_sign != ''" title="Close" (click)="onRemove()"
                                            class="close-img">
                                            <img src="\assets\images\delete.jpg" class="img-style">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 mt-4 p-0">
                                <span class="addr-details ml-2">Contact</span>
                                <div class="row col-sm-12 p-0 m-0">
                                    <div class="col-sm-6 addrs-line">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Email ID
                                              <!-- <span class="text-danger pr-1" *ngIf="organizationLoginType== 'Email' && frm_AddUsergroup.value.frmInp_AllowLogin">*</span> -->
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter Email ID" id="email"
                                                type="text" class="form-control radius-change" formControlName="frmInp_Email"
                                                maxlength="50" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6 addrs-line">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Phone
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter Phone" id="phone"
                                                type="text" (keypress)="AllowOnlyNumber($event)" class="form-control radius-change" formControlName="frmInp_Phone"
                                                maxlength="10" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6 pt-2 addrs-line">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Fax
                                            </mat-label>
                                            <input autocomplete="off" matInput placeholder="Enter Fax" id="fax"
                                                type="text" (keypress)="AllowOnlyNumber($event)" class="form-control radius-change" formControlName="frmInp_Fax"
                                                maxlength="10" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Roles" disabled="{{roleTabDisable}}">
            <div class="row scroll-wraps">
                <div class="col-12 p-0 m-0">
                    <div class="role-wrap col-sm-12 mt-1">
                        <div class="col-sm-1 ml-3 align-center">
                            <input autocomplete="off" class="list-group-item mr-3" [indeterminate]="!triggerRoleChecked(frm_AddUsergroup.controls.frmArruserRoles['controls']) && checkSomeRoles(frm_AddUsergroup.controls.frmArruserRoles['controls'])"
                                [disabled]="POLOrg && !editMode ? true : false" [checked]="triggerRoleChecked(frm_AddUsergroup.controls.frmArruserRoles['controls'])"
                                type="checkbox" name="list_name" value="m1" (change)="roleCheckUncheckAll($event)" />
                        </div>
                        <div class="col-sm-3 ml-4 p-0">
                            Roles
                        </div>
                        <div class="col-sm-2">
                            Primary Role
                        </div>
                        <div class="col-sm-3">
                            Hold Release Duration (In minutes)
                        </div>
                        <div class="col-sm-2" *ngIf="editMode">
                            Status
                        </div>
                        <div class="col-sm-2">

                        </div>
                    </div>
                    <div class="form-wrap pl-4 mr-2" formArrayName="frmArruserRoles">
                        <div class="col-sm-12 d-flex buttonInOrgSearch mt-2">
                            <mat-form-field class="col-sm-4 example-form-field" appearance="outline">
                                <mat-label>Role</mat-label>
                                <input matInput autocomplete="off" type="text" id="inpRoleSearch" [(ngModel)]="roleSearch"
                                    [ngModelOptions]="{standalone: true}" placeholder="Search ...">
                                <button matSuffix mat-icon-button *ngIf="roleSearch" aria-label="Clear" (click)="roleSearch = ''">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-12 pr-0 pl-0 ml-1" *ngFor="let userRl of filteredRoles(frm_AddUsergroup.controls.frmArruserRoles['controls']); let userRole_i = index">
                            <div formGroupName="{{userRole_i}}">
                                <div class="col-sm-12 d-flex">
                                    <div class="col-sm-1 d-flex">
                                        <input type="checkbox" formControlName="frmChk_UserRoles_RoleName" #checkrole
                                            (click)="enableRadioBtnForRoleNameChk(userRl, userRole_i, $event)">
                                    </div>
                                    <div class="col-sm-3">
                                        <ul class="p-0 m-0">
                                            <div class="col-sm-12 pl-0 list-group-item">
                                                <div class="col-12 pl-0">
                                                    <span>
                                                        {{ userRl.value.frmLblChk_UserRoles_RoleName }}
                                                    </span>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div class="col-sm-1 ml-3 d-flex">
                                        <input type="radio" formControlName="frmRd_UserRoles_RoleName" class="mr-4"
                                        [value]="userRl.value.frmLblChk_UserRoles_RoleName"
                                        (click)='TogglePrimaryRole($event, userRl)' />

                                    </div>
                                    <div class="col-sm-1">

                                    </div>
                                    <div class="col-sm-3 d-flex">
                                        <!-- [value]="frm_AddUsergroup.value.frmArruserRoles[userRole_i].frmInp_RoleHoldreleaseDuration" -->

                                        <input autocomplete="off" type="text" formControlName="frmInp_RoleHoldreleaseDuration"
                                            class="w-25 list-group-item has-value" *ngIf="showHoldDuration(userRl)" 
                                            style="border: 1px solid #bababa !important;border-radius: 5px;padding: 7px;"
                                            id="displaybox" (keypress)="AllowOnlyNumber($event)" maxlength="4">

                                        <div class="align-center ml-2" *ngIf="showMedicalDirector(userRl)">
                                            <input type="checkbox" class="mr-2 mb-1" formControlName="frmChk_IsMedicalDirector"
                                                (click)="MedicalDirectorCheck($event, userRl)">Medical Director
                                        </div>
                                    </div>

                                    <!-- MD_KValue == userRole_i -->


                                    <div class="col-sm-3 mt-3">
                                        <input type="checkbox" class="d-block ml-2" (click)="activateOrInactivate($event,userRl)"
                                            formControlName="frmLbl_usrStatus"  [style.visibility]="checkrole.disabled ? 'none' : 'hidden'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="Locations" disabled="{{LocTabDisable}}">
            <div class="row pl-4 mr-2">
                <div class="col-12 p-0 m-0">
                    <div class="role-wrap col-sm-12 mt-1">
                        <div class="col-sm-1 align-center">
                            <input class="list-group-item mr-2" [indeterminate]="checkLocIndeter(frm_AddUsergroup.value.frmchk_OrgLocation,filteredAccounts(frm_AddUsergroup.controls.frmArruserAccounts['controls']))"
                                [checked]="checkLocChecked(filteredAccounts(frm_AddUsergroup.controls.frmArruserAccounts['controls']))"
                                type="checkbox" formControlName="frmchk_OrgLocation" (change)="onChangefrmchk_OrgLocation($event.target.checked)" />
                        </div>
                        <div class="col-sm-3 p-0">
                            Locations List
                        </div>
                        <div class="col-sm-4" *ngIf="checkRolesSelected(frm_AddUsergroup.value.frmArruserRoles)">
                            Reading Location
                        </div>
                    </div>

                    <div class="form-wrap">
                        <div class="col-sm-12 d-flex buttonInOrgSearch mt-2">
                            <mat-form-field class="col-sm-4 example-form-field" appearance="outline">
                                <mat-label>Location</mat-label>
                                <input matInput autocomplete="off" type="text" id="inpOrgSearch" (input)="accountSearch = $event.target.value"
                                    [(ngModel)]="accountSearch" [ngModelOptions]="{standalone: true}" placeholder="Search ...">
                                <button matSuffix mat-icon-button *ngIf="accountSearch" aria-label="Clear" (click)="accountSearch=''">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div formArrayName="frmArruserAccounts">
                            <div class="row pr-0 pl-0 ml-1" *ngFor="let userRl of filteredAccounts(frm_AddUsergroup.controls.frmArruserAccounts['controls']); let userAccounts_i = index"
                                [formGroup]="userRl">
                                <div class="col-sm-1 ml-4 d-flex">
                                    <input type="checkbox" formControlName="frmchk_Location" (change)="onChangefrmchk_Location($event.target.checked,userRl)" />
                                </div>
                                <div class="col-sm-3">
                                    <ul class="p-0 m-0">
                                        <div class="col-sm-12 pl-0 list-group-item">
                                            <div class="col-12 pl-0">
                                                <span>
                                                    {{ userRl.value.frmLbl_Location }}
                                                </span>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div class="col-sm-4 d-flex">
                                    <input type="radio" class="mr-4" formControlName="frmDrp_Works" *ngIf="checkRolesSelected(frm_AddUsergroup.value.frmArruserRoles)"
                                        (change)="onChangefrmDrp_OrgWorks($event,userRl)" [value]="true || false"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Configuration" disabled="{{configTabDisable}}">
            <div class="col-sm-12 p-0 m-0">
                <div class="role-wrap col-sm-12 mt-2">
                    <div class="col-sm-6 p-0">
                        Configuration List
                    </div>
                </div>
                <div class="form-wrap">
                    <div class="row col-sm-12 mt-2 mb-3">
                        <mat-form-field appearance="outline" class="gender-wrap w-100 col-sm-4">
                            <mat-label>User Display Format (Webpage)
                            </mat-label>
                            <mat-select class="form-control form-font" disableOptionCentering formControlName="frmInp_DisplayFormatWebpage"
                                style="padding: 0rem 0px !important; border: none !important;">
                                <mat-option value="F L">First Last</mat-option>
                                <mat-option value="F L D">First Last Qualification</mat-option>
                                <mat-option value="L, F">Last , First</mat-option>
                                <mat-option value="L, F M.">Last , First Middle.</mat-option>
                                <mat-option value="L, F D">Last , First Qualification</mat-option>
                                <mat-option value="S. F L">Title. First Last</mat-option>
                                <mat-option value="S. F L D">Title. First Last Qualification</mat-option>
                                <mat-option value="S. L, F">Title. Last , First</mat-option>
                                <mat-option value="S. L, F M.">Title. Last , First Middle.</mat-option>
                                <mat-option value="S. L, F D">Title. Last , First Qualification</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="gender-wrap w-100 col-sm-4">
                            <mat-label>User Display Format (Description)
                            </mat-label>
                            <mat-select class="form-control form-font" disableOptionCentering formControlName="frmInp_DisplayFormatDescription"
                                style="padding: 0rem 0px !important; border: none !important;">
                                <mat-option value="F L">First Last</mat-option>
                                <mat-option value="F L D">First Last Qualification</mat-option>
                                <mat-option value="L, F">Last , First</mat-option>
                                <mat-option value="L, F M.">Last , First Middle.</mat-option>
                                <mat-option value="L, F D">Last , First Qualification</mat-option>
                                <mat-option value="S. F L">Title. First Last</mat-option>
                                <mat-option value="S. F L D">Title. First Last Qualification</mat-option>
                                <mat-option value="S. L, F">Title. Last , First</mat-option>
                                <mat-option value="S. L, F M.">Title. Last , First Middle.</mat-option>
                                <mat-option value="S. L, F D">Title. Last , First Qualification</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="gender-wrap w-100 col-sm-4">
                            <mat-label>User Display Format (Reports)
                            </mat-label>
                            <mat-select class="form-control form-font" disableOptionCentering formControlName="frmInp_DisplayFormatReports"
                                style="padding: 0rem 0px !important; border: none !important;">
                                <mat-option value="F L">First Last</mat-option>
                                <mat-option value="F L D">First Last Qualification</mat-option>
                                <mat-option value="L, F">Last , First</mat-option>
                                <mat-option value="L, F M.">Last , First Middle.</mat-option>
                                <mat-option value="L, F D">Last , First Qualification</mat-option>
                                <mat-option value="S. F L">Title. First Last</mat-option>
                                <mat-option value="S. F L D">Title. First Last Qualification</mat-option>
                                <mat-option value="S. L, F">Title. Last , First</mat-option>
                                <mat-option value="S. L, F M.">Title. Last , First Middle.</mat-option>
                                <mat-option value="S. L, F D">Title. Last , First Qualification</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="row col-sm-12">
                        <div class="col-sm-6">
                            <div class="col-sm-12 font-size">
                                <div class="row col-sm-12 p-0 m-0">
                                    <mat-label for="DKeys" class="font-style col-sm-3 mb-0 pl-0">Login Region<span
                                            class="star-mark">*</span>
                                    </mat-label>
                                    <mat-checkbox [color]="task.color" formControlName="frmInp_US" class="casetype-margin check-box">
                                        <strong class="head-font">United States</strong>
                                    </mat-checkbox>

                                    <mat-checkbox [color]="task.color" formControlName="frmInp_ST" class="casetype-margin check-box">
                                        <strong class="head-font">Starmark</strong>
                                    </mat-checkbox>
                                </div>

                                <div class="col-sm-12 mt-2 p-0">
                                    <div class="col-sm-12 p-0">
                                        <mat-checkbox [color]="task.color" (change)="statusChecked($event.checked)" formControlName="frmInp_Status" class="casetype-margin check-box">
                                            <strong class="head-font">Active</strong>
                                        </mat-checkbox>
                                    </div>

                                    <div class="col-sm-12 p-0">
                                        <mat-checkbox [color]="task.color" formControlName="frmInp_ShowMyCaseOnly"
                                            class="casetype-margin check-box">
                                            <strong class="head-font">Show My Case Only</strong>
                                        </mat-checkbox>
                                    </div>

                                    <div class="col-sm-12 p-0">
                                        <mat-checkbox [color]="task.color" [checked]="assocChecked" class="casetype-margin check-box" *ngIf="checkForPhysicianSelection(frm_AddUsergroup.value.frmArruserRoles,this.frm_AddUsergroup.value.frmArruserAccounts)" (change)="associateSurgi($event.checked)">
                                            <strong class="head-font">Associate Surgicenter</strong>
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-12 p-0 mt-2">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>External User GUID
                                        </mat-label>
                                        <input matInput title="{{frm_AddUsergroup.value.frmInp_ExternalUserGUID}}" id="inp_ExternalUserGUID"
                                            class="form-control" readonly formControlName="frmInp_ExternalUserGUID" />
                                    </mat-form-field>
                                </div> -->
                                <!-- <div class="col-sm-12 p-0 mt-2" *ngIf="checkThreshold(frm_AddUsergroup.value.frmArruserRoles)">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Threshold &nbsp;&nbsp;
                                        </mat-label>
                                        <input matInput id="inp_Threshold" class="form-control" formControlName="frmInp_Threshold"
                                            autocomplete="off" (keypress)="AllowOnlyNumber($event)" maxlength="10" />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 p-0 mt-2" *ngIf="checkThreshold(frm_AddUsergroup.value.frmArruserRoles)">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label for="inp_ThresholdDuration"><strong>Threshold Duration
                                                &nbsp;&nbsp;</strong></mat-label>
                                        <mat-select name="DeploymentKeys" disableOptionCentering id="drp_ThresholdDuration"
                                            class="form-control" formControlName="frmDrp_ThresholdDuration" maxlength="100">
                                            <mat-option label="Select">Select</mat-option>
                                            <mat-option *ngFor="let i of ArrThresholdDuration" value={{i.LookUpValue}}>
                                                {{i.Description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-sm-6 addr-wrap font-size" *ngIf="checkAllowtoeditAccession(frm_AddUsergroup.value.frmArruserRoles) || checkAllowReviewPathologist(frm_AddUsergroup.value.frmArruserRoles) || checkForEditSite(frm_AddUsergroup.value.frmArruserRoles) || checkallowPathologistDiagnosisTemplate(frm_AddUsergroup.value.frmArruserRoles) || checkForNotifUsers(frm_AddUsergroup.value.frmArruserRoles)">
                            <span class="addr-details">Diagnosticians</span>

                            <div class="col-sm-12 d-flex" *ngIf="checkAllowtoeditAccession(frm_AddUsergroup.value.frmArruserRoles)">
                                <input type="checkbox" formControlName="frmInp_AllowtoeditAccession" [disabled]="true"
                                    class="mb-2" />
                                <label class="ml-2">Allow to edit Accession</label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkAllowReviewPathologist(frm_AddUsergroup.value.frmArruserRoles)">
                                <input type="checkbox" [disabled]="true" formControlName="frmInp_AllowReviewPathologist"
                                    class="mb-2" />
                                <label class="ml-2">Allow Review Pathologist to Sign-out Review Cases</label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkForEditSite(frm_AddUsergroup.value.frmArruserRoles)">
                                <input type="checkbox" class="mb-2" formControlName="frmInp_EditSite" [disabled]="true" />
                                <label class="ml-2">Allow to edit site, Grossing and Processing information</label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkallowPathologistDiagnosisTemplate(frm_AddUsergroup.value.frmArruserRoles)">
                                <input id="inp_AllowPathologistDiagnosisTemplate" type="checkbox" class="mb-2"
                                    formControlName="frmInp_AllowPathologistDiagnosisTemplate">
                                <label class="ml-2">Allow Pathologist Diagnosis Template</label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkForEditSite(frm_AddUsergroup.value.frmArruserRoles)">
                                <input id="inp_PopulateDiag" formControlName="frmInp_PopulateDiag" type="checkbox"
                                    class="mb-2">
                                <label class="ml-2"> Auto Populate Diag ICD9Codes</label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkForNotifUsers(frm_AddUsergroup.value.frmArruserRoles)">
                                <input id="inp_Notifyusers" type="checkbox" formControlName="frmInp_Notifyusers" class="mb-2">
                                <label class="ml-2"> Notify users to provide Diagnostic ICD Codes </label>
                            </div>

                            <div class="col-sm-12 d-flex" *ngIf="checkallowPathologistDiagnosisTemplate(frm_AddUsergroup.value.frmArruserRoles)">
                                <input type="checkbox" formControlName="frmChk_EnableSignOutCorrection" class="mb-2" />
                                <label class="ml-2">Enable Sign-Out Correction</label>
                            </div>

                            <!-- <div class="col-sm-12 d-flex">
                                <input type="checkbox" formControlName="frmChk_IsPathologistPrivileges" class="mb-2" />
                                <label class="ml-2">Manage Pathologist Privileges</label>
                            </div> -->

                        </div>

                        <div class="col-sm-12 addr-wrap mt-4 font-size" *ngIf="checkLicensure(frm_AddUsergroup.value.frmArruserRoles)">
                            <label class="addr-details"><strong>Licensure</strong></label>
                            <div class="col-sm-12 mb-1 px-2 m-0" *ngIf="checkLicensure(frm_AddUsergroup.value.frmArruserRoles)">
                                <div class="col-sm-12 p-0 m-md-1 m-lg-0">
                                    <div class="col-sm-12 m-0 p-0 w-100 d-block">
                                        <div class="col-sm-12 p-2 m-0">
                                            <div class="col-sm-12 p-1 m-0 d-block" id="pathalogy_scroll">
                                                <div class="col-sm-12 p-0 m-0 scroll-wrap">
                                                    <div class="col-sm-12 d-flex m-0 p-0 mt-2">
                                                        <div class="col-4">
                                                            <strong>State</strong>
                                                        </div>
                                                        <div class="col-4 pl-0">
                                                            <strong>Expiration Date (Calendar)</strong>
                                                        </div>
                                                        <div class="col-4 pl-0">
                                                            <strong>Add/Delete row</strong>
                                                        </div>
                                                    </div>

                                                    <div formArrayName="formLicensure" class="row m-0 p-0 mt-2" *ngFor="let userLicensure of frm_AddUsergroup.controls.formLicensure.controls; let userLicensure_i = index">
                                                        <span formGroupName="{{userLicensure_i}}" class="row col-sm-12">
                                                            <div class="col-4">
                                                                <mat-form-field appearance="outline" class="mr-0 p-0 mt-2">
                                                                    <mat-select disableOptionCentering matNativeControl
                                                                        formControlName="frmDrp_LicsenceStates">
                                                                        <mat-option label="Select" value="Select">Select</mat-option>
                                                                        <mat-option *ngFor="let s of StateList" value="{{s.LookUpValue}} ({{s.Description}})">
                                                                            {{s.LookUpValue}}
                                                                            ({{s.Description}})
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>

                                                            <span class="col-4">
                                                                <mat-form-field id="mat">
                                                                    <mat-label>Choose a date</mat-label>
                                                                    <input matInput [matDatepicker]="datepicker"
                                                                        formControlName="frmDrp_LicsenceDate">
                                                                    <mat-datepicker #datepicker></mat-datepicker>
                                                                </mat-form-field>
                                                                <mat-datepicker-toggle matIconSuffix [for]="datepicker">
                                                                </mat-datepicker-toggle>
                                                            </span>


                                                            <div class="col-4 pl-4">
                                                                <button class="m-2" (click)="licensure('add', userLicensure_i)"
                                                                    *ngIf="userLicensure_i == (frm_AddUsergroup.controls.formLicensure.controls.length - 1)"
                                                                    id="userLicensure">
                                                                    <em class="fa fa-plus-circle" id="LicensureIcon"></em>
                                                                </button>
                                                                <button class="m-2" (click)="licensure('delete', userLicensure_i)"
                                                                    id="userLicensure">
                                                                    <em class="fa fa-minus-circle" id="LicensureIcon"></em>
                                                                </button>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 addr-wrap mt-4 font-size" *ngIf="checkRolesSelected(frm_AddUsergroup.value.frmArruserRoles)">
                            <label class="addr-details"><strong>Threshold</strong></label>
                            <div class="col-lg-12 p-0 m-md-1 m-lg-0">
                                <div class="row m-0 p-0 w-100 d-block">
                                    <div class="col-md-5 p-0 m-0 pr-1">
                                    </div>
                                    <div class="col-md-7 p-0 m-0 pr-3">
                                    </div>

                                    <div class="col-12 p-2 m-0 pr-3 text-center">
                                        <div class="row p-1 m-0 d-block" id="pathalogy_scroll">
                                            <div class="col-12 p-0 m-0">
                                                <div class="row m-0 p-0 mt-2">
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Work</strong>
                                                    </div>
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Threshold</strong>
                                                    </div>
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Duration</strong>
                                                    </div>
                                                    <div class="col-3 p-0 m-0 d-flex" *ngIf="checkThresholdDeployment()">
                                                        <strong>Compute On</strong>
                                                    </div>
                                                </div>
                                                <div class="row m-0 p-0 mt-2" *ngIf="checkForEditSite(frm_AddUsergroup.value.frmArruserRoles)">
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Reading</strong>
                                                    </div>

                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput formControlName="frmInp_Threshold"
                                                                maxlength="4" (keypress)="AllowOnlyNumber($event)" placeholder="Enter Threshold">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3 pl-0">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <!-- <mat-label for="inp_ThresholdDuration"><strong>Threshold
                                                                        Duration
                                                                        &nbsp;&nbsp;</strong></mat-label> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select name="DeploymentKeys" disableOptionCentering id="drp_ThresholdDuration"
                                                                formControlName="frmDrp_ThresholdDuration" maxlength="100">
                                                                <mat-option label="Select" value="Select">Select</mat-option>
                                                                <mat-option *ngFor="let i of ArrThresholdDuration"
                                                                    value={{i.LookUpValue}}>
                                                                    {{i.Description}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>
                                                    <div class="col-3 pl-0" *ngIf="checkThresholdDeployment()">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <!-- <mat-label for="inp_ThresholdDuration"><strong>Threshold
                                                                        Duration
                                                                        &nbsp;&nbsp;</strong></mat-label> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select disableOptionCentering formControlName="frmDrp_ReadComputeOn"
                                                                maxlength="100">
                                                                <mat-option label="Sign-out" value="Sign-out">Sign-out
                                                                </mat-option>
                                                                <mat-option label="Assignment" value="Assignment">Assignment
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>
                                                </div>
                                                <div class="row m-0 p-0 mt-2" *ngIf="checkThresholdDeployment() && (checkForEditSite(frm_AddUsergroup.value.frmArruserRoles) || checkCytotechSuperSelection(frm_AddUsergroup.value.frmArruserRoles))">
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Reviewing</strong>
                                                    </div>

                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput formControlName="frmInp_ReviewThreshold"
                                                                maxlength="4" (keypress)="AllowOnlyNumber($event)" placeholder="Enter Threshold">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3 pl-0">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <!-- <mat-label for="inp_ThresholdDuration"><strong>Threshold
                                                                Duration
                                                                &nbsp;&nbsp;</strong></mat-label> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select name="DeploymentKeys" disableOptionCentering id="drp_ThresholdDuration"
                                                                formControlName="frmDrp_ReviewDuration" style="border-radius: 5px;"
                                                                maxlength="100">
                                                                <mat-option label="Select" value="Select">Select</mat-option>
                                                                <mat-option *ngFor="let i of ArrThresholdDuration"
                                                                    value={{i.LookUpValue}}>
                                                                    {{i.Description}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>

                                                    <div class="col-3 pl-0">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select disableOptionCentering formControlName="frmDrp_ReviewComputeOn"
                                                                maxlength="100">
                                                                <mat-option label="Sign-out" value="Sign-out">Sign-out
                                                                </mat-option>
                                                                <mat-option label="Assignment" value="Assignment">Assignment
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>
                                                </div>
                                                <div class="row m-0 p-0 mt-2" *ngIf="checkThresholdDeployment() && checkForNotifUsers(frm_AddUsergroup.value.frmArruserRoles)">
                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <strong>Screening</strong>
                                                    </div>

                                                    <div class="col-3 p-0 m-0 d-flex">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput formControlName="frmInp_ScreeningThreshold"
                                                                maxlength="4" (keypress)="AllowOnlyNumber($event)" placeholder="Enter Threshold">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3 pl-0">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <!-- <mat-label for="inp_ThresholdDuration"><strong>Threshold
                                                                Duration
                                                                &nbsp;&nbsp;</strong></mat-label> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select name="DeploymentKeys" disableOptionCentering id="drp_ThresholdDuration"
                                                                formControlName="frmDrp_ScreeningDuration" style="border-radius: 5px;"
                                                                maxlength="100">
                                                                <mat-option label="Select" value="Select">Select</mat-option>
                                                                <mat-option *ngFor="let i of ArrThresholdDuration"
                                                                    value={{i.LookUpValue}}>
                                                                    {{i.Description}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>

                                                    <div class="col-3 pl-0">
                                                        <!-- <mat-form-field appearance="outline" class="w-100"> -->
                                                        <mat-form-field appearance="outline">
                                                            <mat-select disableOptionCentering formControlName="frmDrp_ScreeningComputeOn"
                                                                maxlength="100">
                                                                <mat-option label="Sign-out" value="Sign-out">Sign-out
                                                                </mat-option>
                                                                <mat-option label="Assignment" value="Assignment">Assignment
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>

                                                        <!-- </mat-form-field> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 addr-wrap mt-4 font-size" *ngIf="checkForEditSite(frm_AddUsergroup.value.frmArruserRoles)">
                            <label class="addr-details"><strong>Out of Office</strong></label>
                            <div class="col-lg-12 p-0 m-md-1 m-lg-0">
                                <div class="row m-0 p-0 w-100 d-block">
                                    <div class="col-md-7 p-0 m-0 pr-1 LicensureLabel ">
                                    </div>
                                    <div class="col-md-5 p-0 m-0 pr-3">
                                    </div>
                                    <div class="col-12 p-0 m-0 scroll-wrap" id="pathalogy_scroll">
                                        <div class="row m-0 p-0">
                                            <div class="col-4 p-0 m-0 d-flex">
                                                <strong>From/To Date</strong>
                                            </div>
                                            <div class="col-5 d-flex">
                                                <strong>Add/Delete row</strong>
                                            </div>
                                        </div>
                                        <div formArrayName="frmOutOfOffice">
                                            <span *ngFor="let userOffice of frm_AddUsergroup.controls.frmOutOfOffice.controls; let userOffice_i = index"
                                                formGroupName="{{userOffice_i}}">
                                                <div class="row">
                                                    <div class="col-4 d-flex">
                                                        <mat-date-range-input [matDatepicker]="picker" [min]="minDate"
                                                            [rangePicker]="picker">
                                                            <input matStartDate readonly formControlName="frm_startdate"
                                                                placeholder="Start Date">
                                                            <input matEndDate readonly formControlName="frm_enddate" placeholder="End Date">
                                                        </mat-date-range-input>
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                                    </div>
                                                    <div class="col-5 mt-1 ml-2">
                                                        <button (click)="addOutOfOffice()" *ngIf="userOffice_i == (frm_AddUsergroup.controls.frmOutOfOffice.controls.length - 1)"
                                                            id="userLicensure" class="mr-2">
                                                            <em class="fa fa-plus-circle"></em>
                                                        </button>
                                                        <button (click)="deleteOutOfOffice(userOffice_i)" id="userLicensure">
                                                            <em class="fa fa-minus-circle"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 addr-wrap mt-4 font-size" *ngIf="associateSurgiCenter">
                            <label class="addr-details"><strong>Associated Surgicenters</strong></label>
                            <div class="col-sm-12">
                                <mat-form-field class="col-sm-3 mt-2 select-input-field example-additional-selection p-0"
                                    appearance="outline">
                                    <mat-label class="label-wrap">Primary Location <span class="error-msg">*</span></mat-label>
                                    <mat-select disableOptionCentering [(value)]="primaryLocation">
                                        <!-- <mat-autocomplete #auto="matAutocomplete"> -->
                                        <mat-option class="mat-opt-align" value="">Select</mat-option>
                                        <mat-option class="mat-opt-align" *ngFor="let option of locationsList" value="{{option.accountid}}">{{option.accountname}}</mat-option>
                                        <!-- </mat-autocomplete> -->
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="col-sm-3 mt-3" appearance="outline">
                                    <mat-label>Search Surgicenters</mat-label>
                                    <input type="text" autocomplete="off" style="width: 94%" matInput [(ngModel)]="searchSurgi"
                                        [ngModelOptions]="{standalone: true}">
                                    <!-- <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option class="mat-opt-align" value="">Select</mat-option>
                                    </mat-autocomplete> -->
                                </mat-form-field>

                                <!-- <button mat-raised-button class="col-sm-1 admin-btn-success mb-2">
                                        Search
                                    </button> -->
                            </div>

                            <div class="row col-sm-12 m-0">
                                <div class="col-sm-5">
                                    <div class="row ml-1 font-wet">Search Results</div>
                                    <div class="row col-sm-12 m-0 p-0 name-wrap">
                                        <div class="col-sm-6 pl-1">Name</div>
                                        <div class="col-sm-6 p-0">Location (City)</div>
                                    </div>

                                    <div class="value-wrap">
                                        <div [ngStyle]="data && data.selected ? {'background': '#e8f0fe','color':'#000'} : default"
                                            *ngFor="let data of filteredSurgiCenters(searchSurgi)">
                                            <div class="row col-sm-12 m-0 p-0 value-font-size" (click)="selectedSurgiCenter(data)"
                                                *ngIf="!data.ishidden">
                                                <div class="col-sm-6 pl-1 p-1">{{data.displayname}} {{data.npi ? "(" + data.npi.toString().toUpperCase() + ")" : ''}}</div>
                                                    <div class="col-sm-6 p-1">{{data.displayname}} {{ data.city ? "(" + data.city + ")" : ''}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <div class="col-sm-1 m-0 mb-2">
                                        <button mat-raised-button class="admin-btn-success d-block m-auto" id="displaybox"
                                            (click)="selectUsers()" [disabled]="checkDisabled('list',surgicenterList)">
                                            <!-- <img aria-hidden="true" src="/assets/icons/keyboard_double_arrow_right.svg"
                                                    width="20px" height="auto" class="arrow-wrap" /> -->
                                            <span class="material-icons">keyboard_double_arrow_right</span>
                                        </button>

                                    </div>
                                    <div class="col-sm-1 m-0 mt-2 mb-2">
                                        <button mat-raised-button class="admin-btn-success d-block m-auto" id="displaybox"
                                            (click)="deselectUsers()" [disabled]="checkDisabled('',selectedSurgiCenters)">
                                            <!-- <img aria-hidden="true" src="/assets/icons/keyboard_double_arrow_left.svg"
                                                    width="20px" height="auto" class="arrow-wrap" /> -->
                                            <span class="material-icons">keyboard_double_arrow_left</span>
                                        </button>
                                    </div>
                                </div>


                                <div class="col-sm-5 font-size">
                                    <div class="row ml-1 font-wet">Surgicenters associated to this user </div>
                                    <div class="row col-sm-12 m-0 p-0 name-wrap">
                                        <div class="col-sm-6 pl-1">Name</div>
                                        <div class="col-sm-6 p-0">Location (City)</div>
                                    </div>

                                    <div class="value-wrap">
                                        <div [ngStyle]="data && data.selected ? {'background': '#e8f0fe','color':'#000'} : default"
                                            *ngFor="let data of selectedSurgiCenters">
                                            <div class="row col-sm-12 m-0 p-0 value-font-size" *ngIf="!data.ishidden" (click)="selectedSurgiCenter(data)">
                                                    <div class="col-sm-6 pl-1 p-1">{{data.displayname}} {{data.npi ? "(" + data.npi.toString().toUpperCase() + ")" : ''}}</div>
                                                    <div class="col-sm-6 p-1">{{data.displayname}} {{ data.city ? "(" + data.city + ")" : ''}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-sm-12 font-size">
                        <div formArrayName="frmPhysician" class="col-sm-12 addr-wrap mt-4" *ngIf="userAssocFlag && checkForPhysician(frm_AddUsergroup.controls.frmArruserRoles['controls'])">
                            <label class="addr-details"><strong>Physician</strong></label>
                            <div class="row col-sm-12">
                                <div class="row col-sm-12 note-wrap mb-2 ml-2">
                                    Note : Select Physicians for whom this Medical Staff works. This Medical Staff will have access only to the cases ordered by these associated Physicians. Select "All" to provide access to all Physicians cases.
                                </div>

                                <div class="col-sm-12 d-flex p-0">
                                    <div class="col-sm-8">
                                        <input type="checkbox" class="mb-3"  [indeterminate]="!allChecked(frm_AddUsergroup.value.frmPhysician) && someChecked(frm_AddUsergroup.value.frmPhysician)" [checked]="allChecked(frm_AddUsergroup.value.frmPhysician)" (change)="selectAllPhysicians($event.target.checked)" />
                                        <label class="col-sm-3 mt-3"><strong>Physician </strong></label>
                                    </div>

                                    <mat-form-field appearance="outline" class="col-sm-4 p-0">
                                        <mat-label>Search</mat-label>
                                        <input matInput (input)="physFilter = $event.target.value">
                                        <mat-icon matSuffix *ngIf="physFilter" (click)="physFilter == ''">close</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="col-sm-12 path-wrap">
                                    <ul class="p-0 m-0">
                                        <div class="col-sm-12 m-0 p-0 list-group-item" *ngFor="let userAssoc of filteredUsers('physician',frm_AddUsergroup.controls.frmPhysician['controls']); let physIndex = index">
                                            <div class="row col-12 m-0 p-0" formGroupName="{{physIndex}}">
                                                <div class="col-sm-1 align-center">
                                                    <input type="checkbox" formControlName="frmchk_user" (change)="onChangePhysician($event.target.checked,userAssoc.value.frmlbl_userid)" />
                                                </div>
                                                <div class="col-sm-9">
                                                    <ul class="col-sm-12 p-0 m-0">
                                                        <div class="col-sm-12 pl-0 list-group-item">
                                                            <div class="col-12 pl-0">
                                                                <span>
                                                                    {{ userAssoc.value.frmlbl_username }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div formArrayName="frmPathologist" class="col-sm-12 addr-wrap mt-4 font-size" *ngIf="userAssocFlag && checkForPathologist(frm_AddUsergroup.controls.frmArruserRoles['controls'])">
                            <label class="addr-details"><strong>Pathologist</strong></label>
                            <div class="row col-sm-12">
                                <div class="row col-sm-12 note-wrap mb-2 ml-2">
                                    Note : Select Pathologist for whom this Transcriptionist works. This
                                    Transcriptionist
                                    will have access only to the cases assigned to these associated Pathologist. Select
                                    "All" to provide access to all Pathologist cases.
                                </div>

                                <div class="col-sm-12 d-flex p-0">
                                    <div class="col-sm-8">
                                        <input type="checkbox" class="mb-3"  [indeterminate]="!allChecked(frm_AddUsergroup.value.frmPathologist) && someChecked(frm_AddUsergroup.value.frmPathologist)" [checked]="allChecked(frm_AddUsergroup.value.frmPathologist)" (change)="selectAllPathologists($event.target.checked)" />
                                        <label class="col-sm-3 mt-3"><strong>Pathologist </strong></label>
                                    </div>

                                    <mat-form-field class="col-sm-4 p-0" appearance="outline">
                                        <mat-label>Search</mat-label>
                                        <input matInput (input)="pathFilter = $event.target.value">
                                        <mat-icon matSuffix *ngIf="pathFilter" (click)="pathFilter = ''">close</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="col-sm-12 path-wrap">
                                    <ul class="p-0 m-0">
                                        <div class="col-sm-8 m-0 p-0 list-group-item" *ngFor="let userAssoc of filteredUsers('pathologist',frm_AddUsergroup.controls.frmPathologist['controls']); let pathIndex = index">
                                            <div class="row col-12 m-0 p-0" formGroupName="{{pathIndex}}">
                                                <div class="col-sm-1 align-center">
                                                    <input type="checkbox" formControlName="frmchk_user" (change)="onChangePathologist($event.target.checked,userAssoc.value.frmlbl_userid)" />
                                                </div>
                                                <div class="col-sm-10">
                                                    <ul class="col-sm-12 p-0 m-0">
                                                        <div class="col-sm-12 pl-0 list-group-item">
                                                            <div class="col-12 pl-0">
                                                                <span>
                                                                    {{ userAssoc.value.frmlbl_username }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </mat-tab>
    </form>

    <mat-tab label="Summary" disabled="{{summaryTabDisable}}">
        <div class="row mt-2 font-size" style=" height: 40vh !important;" >
            <div class="row col-sm-12 mb-2">
                <div class="col-sm-2 user-details">User Details</div>
                <div class="col-sm-10 user-line"></div>
            </div>

            <div class="row col-sm-12">
                <div class="row col-sm-9">
                    <div class="row col-sm-12 pt-1 pb-1 pr-0">
                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    NPI
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.npi ? summaryData.npi : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Title
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.title ? summaryData.title : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    First Name
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.fname ? summaryData.fname : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Last Name
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.lname ? summaryData.lname : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Middle Initial
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.midInitial ? summaryData.midInitial : 'Not Specified'}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-sm-12 pt-1 pb-1 pr-0">
                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Gender
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.gender != null ? summaryData.gender == true ? 'Male' : 'Female' :
                                    'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Login Name
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.loginName ? summaryData.loginName : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    Qualification
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.Qualification ? summaryData.Qualification : 'Not Specified'}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-sm-12 pt-1 pb-1 pr-0">
                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    UPIN
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.upin ? summaryData.upin : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    User Code
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.userCode ? summaryData.userCode : 'Not Specified'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-2 pl-0">
                            <div class="col-sm-12">
                                <div class="w-100">
                                    SSN
                                </div>
                                <div class="w-100 font-weight-bold">
                                    {{summaryData.ssn ? summaryData.ssn : 'Not Specified'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-sm-3 upload-wrap">
                    <span class="sign-upload">User Signature</span>
                    <div class="col-sm-12 addrs-line drop-wrap">
                        <div appDnd>
                            <img style="max-width: 100%;max-height: 100%;width: 100%;height:100%;overflow: auto" [src]="clone_sign"
                                *ngIf="clone_sign" alt="" srcset="">
                            <p *ngIf="!clone_sign">No Image Found</p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-sm-12 mt-3">
                <div class="col-sm-12 d-flex">
                    <div class="row col-sm-9 ml-0 font-weight-bold p-0">
                        <div>Address Details</div>
                    </div>
                    <div class="row col-sm-3 ml-0 font-weight-bold p-0">
                        <div *ngIf="summaryData.email || summaryData.phone">Contact Details</div>
                    </div>
                </div>
                <div class="row col-sm-9 pt-3 pb-3 pr-0">
                    <div class="col-sm-4 pl-0">
                        <div class="col-sm-12">
                            <div class="w-100">
                                Address 1
                            </div>
                            <div class="w-100 font-weight-bold">
                                {{summaryData.address1 ? summaryData.address1 : 'Not Specified'}}
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 pl-0">
                        <div class="col-sm-12">
                            <div class="w-100">
                                Address 2
                            </div>
                            <div class="w-100 font-weight-bold">
                                {{summaryData.address2 ? summaryData.address2 : 'Not Specified'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-sm-3 pt-3 pb-3 pr-0 pl-0" *ngIf="summaryData.email || summaryData.phone">
                    <div class="col-sm-12 pl-0">
                        <div class="col-sm-12 pl-2">
                            <div class="w-100 d-flex" *ngIf="summaryData.email">
                                <i class="fa fa-envelope-o mr-2" aria-hidden="true" style="padding-right: 3px;"></i>
                                {{summaryData.email ? summaryData.email : 'Not Specified'}}
                            </div>
                            <div class="w-100 d-flex" *ngIf="summaryData.phone">
                                <i class="fa fa-phone mr-2" aria-hidden="true"></i>
                                &nbsp;
                                {{summaryData.phone ? summaryData.phone : 'Not Specified'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-sm-9 pt-3 pb-3 pr-0">
                <div class="col-sm-4 pl-0">
                    <div class="col-sm-12">
                        <div class="w-100">
                            City
                        </div>
                        <div class="w-100 font-weight-bold">
                            {{summaryData.city ? summaryData.city : 'Not Specified'}}
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 pl-0">
                    <div class="col-sm-12">
                        <div class="w-100">
                            State
                        </div>
                        <div class="w-100 font-weight-bold">
                            {{summaryData.state ? summaryData.state : 'Not Specified'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-sm-9 pt-3 pb-3 pr-0">
                <div class="col-sm-4 pl-0">
                    <div class="col-sm-12">
                        <div class="w-100">
                            Country
                        </div>
                        <div class="w-100 font-weight-bold">
                            {{summaryData.country ? summaryData.country : 'Not Specified'}}
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 pl-0">
                    <div class="col-sm-12">
                        <div class="w-100">
                            ZIP
                        </div>
                        <div class="w-100 font-weight-bold">
                            {{summaryData.zip ? summaryData.zip : 'Not Specified'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-sm-12" *ngIf="roleList">
                <div class="col-sm-2 user-details">Roles</div>
                <div class="col-sm-10 user-line"></div>

                <div class="col-sm-12 mt-2 p-0">
                    <mat-form-field class="col-sm-3" appearance="outline" *ngFor="let role of roleList">
                        <mat-label *ngIf="checkPrimaryRole(role)">Primary</mat-label>
                        <!-- <mat-input placeholder="Customer Service">
                             -->
                        <input readonly matInput title="{{role}}" value="{{role}}">
                    </mat-form-field>
                </div>

            </div>

            <div class="row col-sm-12">
                <div class="col-sm-2 user-details">Locations</div>
                <div class="col-sm-10 user-line"></div>

                <div class="col-sm-12 mt-2 p-0">
                    <mat-form-field class="col-sm-3" appearance="outline" *ngFor="let account of accounts">
                            <mat-label *ngIf="checkReadingLocation(account)">Reading Location</mat-label>
                        <!-- <mat-label>Reading Location</mat-label> -->
                        <input readonly matInput title="{{account.frmLbl_Location}}" value="{{account.frmLbl_Location}}">
                    </mat-form-field>
                </div>

            </div>

            <div class="row col-sm-12">
                <div class="col-sm-2 user-details">Configuration</div>
                <div class="col-sm-10 user-line"></div>
            </div>

            <div class="col-sm-12 mt-3 d-flex p-0">
                <div class="col-sm-4">
                    <mat-label>User Display Format(WebPage)</mat-label><br>
                    <mat-label><strong>
                            {{summaryData.userNamedisplayFormat ? summaryData.userNamedisplayFormat : 'Not Specified'}}
                        </strong></mat-label>
                </div>

                <div class="col-sm-4">
                    <mat-label>User Display Format(Description)</mat-label><br>
                    <mat-label><strong>
                            {{summaryData.userNamedescriptionFormat ? summaryData.userNamedescriptionFormat : 'Not
                            Specified'}}
                        </strong></mat-label>
                </div>

                <div class="col-sm-4">
                    <mat-label>User Display Format(Reports)</mat-label><br>
                    <mat-label><strong>
                            {{summaryData.usernameReportformat ? summaryData.usernameReportformat : 'Not Specified'}}
                        </strong></mat-label>
                </div>
            </div>

            <div class="col-sm-12 mt-3 d-flex p-0">
                <div class="col-sm-4" *ngIf="checkForEditSite(frm_AddUsergroup.value.frmArruserRoles)">
                    <mat-label><strong>Pathologist</strong></mat-label><br>
                    <mat-label>Allow to edit Accession : {{summaryData.isModifyTechnicalDataAccession ?
                        summaryData.isModifyTechnicalDataAccession : 'Not Specified'}}</mat-label>
                    <mat-label>Allow Review Pathologist to Sign-out Review Cases : {{summaryData.allowPathologistReview
                        ? summaryData.allowPathologistReview : 'Not Specified'}} </mat-label>
                </div>

                <div class="col-sm-4 p-0">
                    <div class="col-sm-12 d-flex">
                        <mat-label><strong>Login Region : </strong></mat-label>
                        <mat-label>
                            {{summaryData.region ? summaryData.region : 'Not Specified'}}
                        </mat-label>
                    </div>

                    <div class="col-sm-12 d-flex">
                        <mat-label><strong>Status : </strong></mat-label>
                        <mat-label>
                            {{summaryData.status ? summaryData.status : 'Not Specified'}}
                        </mat-label>
                    </div>

                    <div class="col-sm-12 d-flex">
                        <mat-label><strong>Show My Case Only : {{summaryData.showMyCaseOnly}}
                            </strong></mat-label>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

</mat-tab-group>
<div class="col-sm-12 p-0 m-0" *ngIf="summaryTabDisable">
    <div class="col-sm-12 align-center pl-0 pr-0 mt-4">
        <div class="col-sm-4 p-0">
            <button mat-raised-button (click)="openAlert(userTabs,'left')" class="admin-btn-success mr-4">
                Previous
            </button>
            <button mat-raised-button form="userForm" class="admin-btn-success mr-4" (click)="openAlert(userTabs,'right')"
                type="submit">
                Next
            </button>
        </div>
        <div class="col-sm-8 button-wrap p-0 save-btn-wrap ml-2">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="manageUserClose()">
                Close
            </button>
            <button mat-raised-button class="admin-btn-success" (click)="saveTabData(userTabs)" form="userForm" type="submit">
                Save
            </button>
        </div>
    </div>
</div>
<div class="row col-sm-12 align-center mt-5" *ngIf="!summaryTabDisable">
    <div class="user-btn-wrap">
        <button mat-raised-button class="admin-btn-success mr-2" (click)="finishUserConfig()" type="button">Finish</button>
    </div>
</div>
<!-- Alert -->
<div class="popup-wrap model-dialog row p-0 m-0 mt-2" *ngIf="alertPopUp" aria-hidden="true" data-backdrop="static"
    data-keyboard="false">
    <div class="col-12 p-0 m-0">
        <!-- The Modal -->
        <div class="col-sm-12 align-center modal" style="background: #393d406b;">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header admin-model-header">
                    <h4 class="modal-title p-1 m-0">{{currentTab()}}</h4>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="text-align: center;font-size:16px">
                    <span class="title-card">Save Changes ?
                    </span><br><span class="title-card">
                        If not saved, any modifications made will be lost.
                    </span><br>
                </div>
                <!-- Modal footer -->
                <div class="col-12 modal-footer" style="margin: auto;">
                    <button type="button" mat-raised-button class="admin-btn-success mr-6" (click)="saveAndOrProceed('save',userTabs)">Save
                        and Proceed</button>
                    <button type="button" mat-raised-button class="admin-btn-success mr-6" *ngIf="checkSelectedTab(userTab)"
                        (click)="saveAndOrProceed('dontsave',userTabs)">Proceed
                        without Saving</button>
                    <button type="button" mat-raised-button class="admin-btn-success mr-6" (click)="alertPopUp = false">Stay
                        in this Page</button>
                </div>
            </div>
        </div>
    </div>
</div>
