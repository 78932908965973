import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LabUsersService {

  //Lab Users
  templateListLabUser = [{
    templateName: "Lab Users",
    headers: [
      { Name: 'Name', type: 'input', key: 'name', colWidth: 'col-sm-2 pl-0 column-wrpper' }
      , { Name: 'Login ID', type: 'input', key: 'loginId', colWidth: 'col-sm-2 pl-0 column-wrpper' }
      , { Name: 'Roles', type: 'input', key: 'roles', colWidth: 'col-sm-4 pl-0 role-wrpper' }
      , { Name: 'Phone Number', type: 'input', key: 'phoneNumber', colWidth: 'col-sm-1 pl-2 column-wrpper' }
      , { Name: 'NPI(Status)', type: 'text', key: 'npi', colWidth: 'col-sm-2 column-wrpper pl-4' }
    ]
  }]

  // Clients
  templateListClient = [{
    templateName: "Clients",
    headers: [
      { Name: 'Facility Name', type: 'input', key: 'facilityName', colWidth: 'col-sm-2 pl-0 column-wrpper' }
      , { Name: 'Owner Lab', type: 'input', key: 'ownerlab', colWidth: 'col-sm-2 pl-0 column-wrpper' }
      , { Name: 'Location Code', type: 'input', key: 'locationCode', colWidth: 'col-sm-1 pl-0 role-wrpper' }
      , { Name: 'Address', type: 'input', key: 'address', colWidth: 'col-sm-2  pl-0 column-wrpper' }
      , { Name: 'Phone Number', type: 'input', key: 'phoneNumber', colWidth: 'col-sm-1 pl-0 column-wrpper' }
      , { Name: 'Fax Number', type: 'input', key: 'faxNumber', colWidth: 'col-sm-1 pl-0 column-wrpper' }
      , { Name: 'NPI(Status)', type: 'text', key: 'npi', colWidth: 'col-sm-2 column-wrpper pl-0 pr-0' }
    ]
  }]

  constructor() { }
}

function includes(Name: any) {
  throw new Error('Function not implemented.');
}
