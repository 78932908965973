<div class="vdm-sidemenu">
  <div class="d-flex justify-content-end" >
    <button mat-flat-button class="expand-side-bar" (click)="sideBarExpand()">
      <i class="fa fa-angle-double-right text-md" *ngIf="!isExpand" aria-hidden="true"></i>
      <i class="fa fa-angle-double-left text-md" *ngIf="isExpand" aria-hidden="true"></i>
    </button>
  </div>



  <ul class="nav flex-column " *ngIf="isExpand">
    <ng-container *ngFor="let item of submenuList;let i=index;">
      <li class="nav-item px-2 mb-1" *ngIf="item.Htext !== undefined && item.SubMenu.length == 0 " (click)="sideMenuSelected(item)">
        <a class="nav-link  px-2" [ngClass]="newValue?.routerLink == item.routerLink ? 'active' : 'inactive'">{{item.Htext|titlecase}}
        </a>
      </li>
      <span class="mt-1" *ngIf="item.Htext !== undefined && item.SubMenu.length>0">
        <div class="subMenuHeading text-muted _600" style="font-size:11px;"> {{item.Htext}} </div>
        <ng-container *ngFor="let list of item.SubMenu;let j = index;">
          <li class="nav-item px-2 mb-1" *ngIf="list.URL !== undefined" (click)="sideMenuSelected(item.SubMenu[j])">
            <a class="nav-link  px-2" [ngClass]="newValue?.routerLink == list.routerLink ? 'active' : 'inactive'">{{list.URL|titlecase}}
            </a>
          </li>
        </ng-container>
      </span>
    </ng-container>
  </ul>
</div>
