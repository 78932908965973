<div class="col-sm-12">
  <div class="row">
    <div *ngIf="tableGrid" class="col-sm-12">
      <div class="modal-header admin-model-header mt-3 mb-2">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>Scheduled Reports</strong>
        </h3>
      </div>
      <div *ngIf="templateData && templateData.labels && gridwidth > 0">
        <wj-flex-grid #grid [isReadOnly]="true" (click)="showReportDetails(grid, $event)" [headersVisibility]="'Column'"
          (initialized)="initGrid(grid)" [itemsSource]="gridData" >
          <div *ngFor="let columnname of columns">
            <wj-flex-grid-column [binding]="columnname.binding" [visible]="!(columnname.header == 'Report ID')"
              [header]="columnname.header" [width]="170" [allowDragging]="false" [allowResizing]="true">
            </wj-flex-grid-column>
          </div>
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="pagination-align mt-1">
          <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
            headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
          </wj-collection-view-navigator>
          <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
            <wj-menu-item [value]="0">No Paging</wj-menu-item>
            <wj-menu-item [value]="10">10</wj-menu-item>
            <wj-menu-item [value]="15">15</wj-menu-item>
            <wj-menu-item [value]="30">30</wj-menu-item>
            <wj-menu-item [value]="50">50</wj-menu-item>
          </wj-menu>
        </div>
        <div class="sub-button mt-4">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="ExportToExcel(tempExport)"
            [disabled]="hideExportBtn">
            Export
          </button>
          <button mat-raised-button class="admin-btn-success" (click)="addNewReport()"
            [disabled]="hideCreateBtn">Create</button>
        </div>
      </div>
    </div>
    <div *ngIf="gridwidth == 0" class="col-sm-12">
      <button *ngIf="tableGrid" mat-raised-button class="admin-btn-success mt-4" (click)="addNewReport()"
        [disabled]="hideCreateBtn">Create</button>
      <div *ngIf="tableGrid" class="nodata-wrap mt-4">
        <div class="nodata-design">No Data Found</div>
      </div>
    </div>
    <!--edit Update Modal-->
    <div *ngIf="ShowModalEdit" class="col-sm-12 p-0">
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>
            <div *ngIf="Title">Edit Scheduled Report</div>
          </strong>
        </h3>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-12">
            <form [formGroup]="recipientData" class="mt-1 form-height">
              <div class="row px-3">
                <div class="col-sm-6">
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Name<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ recipientData.value.name }}" formControlName="name" maxlength="100" type="text"
                        class="form-control form-font" />
                    </div>
                  </div>
                  <div class="row mt-3 p-0">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Category<span class="error-msg">*</span></label>
                    </div>

                    <div class="col-sm-7 text-xs">
                      <input trim formControlName="Category" type="text" class="form-control form-font" disabled />
                    </div>
                  </div>

                  <div class="row mt-3 p-0">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Named Range<span class="error-msg">*</span></label>
                    </div>

                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ recipientData.value.NamedRange }}" formControlName="NamedRange" type="text"
                        maxlength="100" class="form-control form-font" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Title<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ recipientData.value.title }}" formControlName="title" maxlength="100" type="text"
                        class="form-control form-font" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">SP Name<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ recipientData.value.storedProcedure }}" formControlName="storedProcedure"
                        maxlength="250" type="text" class="form-control form-font" />
                    </div>
                  </div>
                  <div class="row mt-3 p-0">
                    <section class="example-section col-sm-4 mb-3 pl-4">
                        <mat-checkbox [color]="task.color" class="mt-3 example-margin"  formControlName="isActive">Active
                        </mat-checkbox>
                    </section>
                   </div>
                  <div class="row mt-3 p-0 row">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Connection Name</label>
                    </div>
                    <div class="col-sm-7 row reportdb-align">
                      <input type="checkbox" formControlName="ConnectionName" value="ReportDB"
                        class="check-box-align m-0" />
                      <label class="pl-2 text-xs m-0">ReportDB</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Set frequency here for edit start-->
              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Frequency<span class="error-msg">*</span></label>
                </div>
                <div class="col-sm-10 text-xs">

                  <div class="row">
                    <div class="col-sm-12">
                      <input type="text" class="form-control form-font cron-div" (click)="openModal('edit')"
                        value="{{recipientData.value.cronExpression}} {{cronDescriptionNew}}" readonly
                        data-toggle="tooltip" data-placement="bottom"
                        title="{{recipientData.value.cronExpression}} {{cronDescriptionNew}}" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Set frequency here for edit end-->

              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Template File</label>
                </div>
                <div class="col-sm-9 text-xs">
                  <input ID="myFileInputID" type="file" accept=".xlsx" class="form-control form-font custom-dropzone"
                    (change)="handleUpload($event,'edit')" />
                  <p>Please upload only .xlsx format file</p>
                </div>
                <div class="col-sm-1 pt-2 text-xs">
                  <em class="fa fa-download curser-pointer" [value]="custTemplateFile"
                    (click)="downloadTemplate(this.TemplateFile)" title="Download" aria-hidden="true"></em>
                </div>
              </div>
              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Template Filename</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input type="text" maxlength="100" class="form-control form-font" formControlName="templateFile"/>
                </div>
              </div>
              <div class="row mt-3 mb-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Description</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <textarea title="{{ recipientData.value.Description }}" formControlName="Description" type="text"
                    maxlength="2000" class="form-control form-font p-2"></textarea>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Subject<span class="error-msg">*</span></label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input trim title="{{ recipientData.value.EmailSubject }}" formControlName="EmailSubject" type="text"
                    maxlength="1000" class="form-control form-font" required />
                </div>
              </div>
              <div class="border-design mb-2 mr-3 mt-2">
                <div class="col-12">
                  <span class="label-font text-left row">Primary email address</span>
                </div>
                <div class="row mt-0 form-align mb-2" id="emailDetails">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">To <span class="error-msg">*</span></label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs mb-2 form-margin">
                    <mat-chip-list #primaryEditChecklist style="margin: 10px !important;">
                      <mat-chip name="chips"  [color]="editToMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editToMail of editToMailData; let i = index" [selectable]="true"
                        [removable]="removable" (removed)="editRemoveEmailto(editToMail)" required>
                        {{ editToMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editToMailInput matInput class="col-sm-6"   formControlName="edittoMailCtrl"  [matAutocomplete]="toAuto"
                        [matChipInputFor]="primaryEditChecklist" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailto($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="selectedEditmailto($event)">
                      <mat-option  class="mat-opt-align" *ngFor="let mailTo of filteredToMail | async" [value]="mailTo">
                        {{ EmailTo }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editToEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Cc </label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #alternativeEditChecklist>
                      <mat-chip name="chipsCc" selected *ngFor="let editCcMail of editCcMailData; let i = index"
                        [color]="editCcMail.invalid ? 'warn' : ''" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveEmailcc(editCcMail)" required>
                        {{ editCcMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editCcMailInput matInput class="col-sm-6"   [formControl]="editccMailCtrl" [matAutocomplete]="ccAuto"
                        [matChipInputFor]="alternativeEditChecklist" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="selectedEditmailcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailCc of filteredCcMail | async" [value]="mailCc">
                        {{ mailCc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editCcEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Bcc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs mb-2 form-margin">
                    <mat-chip-list #editChipList2>
                      <mat-chip name="chipsBcc" [color]="editBccMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editBccMail of editBccMailData" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveEmailbcc(editBccMail)" required>
                        {{ editBccMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editBccMailInput matInput class="col-sm-6"   [formControl]="editbccMailCtrl" [matAutocomplete]="bccAuto"
                        [matChipInputFor]="editChipList2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailbcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="selectedEditmailbcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailBcc of filteredBccMail | async" [value]="mailBcc">
                        {{ mailBcc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editBccEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>



                <div class="row mt-2 form-align">
                  <div class="col-sm-2 text-xs">
                    <label class="label-font">Body</label>
                  </div>
                  <div class="col-sm-10 text-xs mb-2">
                    <div class="mb-2">
                      <button *ngIf="htmlEmailBody" type="button" mat-raised-button class="admin-btn-success mr-4"
                        (click)="viewInEmailBody()">
                        Preview
                      </button>
                      <button *ngIf="previewEmailBody" type="button" mat-raised-button class="admin-btn-success"
                        (click)="viewInEmailBody()">
                        Back
                      </button>
                    </div>
                    <textarea *ngIf="htmlEmailBody" class="mailtext-area" formControlName="EmailBody" type="text"
                      class="form-control form-font p-2 comments-area"></textarea>

                    <div *ngIf="previewEmailBody">
                      <iframe width="620px" height="250px" title="Emailbody" style="
                        border-width: 1px;
                        border-top: #e5e5e5 !important;
                        border-left: #e5e5e5 !important;
                        -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                        -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                        box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                      " class="frame-cg" srcdoc="<!doctype html>
                    <html lang=&quot;en&quot;>
                    <head>
                      <meta charset=&quot;utf-8&quot;>
                    </head>

                    </head>
                    <body>
                      {{ recipientData.value.EmailBody }}
                    </body>
                    </html>"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-design mr-3">
                <div class="col-12">
                  <span class="label-font text-left row">Alternative email address</span>
                </div>
                <div class="row mt-2 form-align" id="emailDetails">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">To</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs mb-2 form-margin">
                    <mat-chip-list #editChipList1>
                      <mat-chip name="chips" [color]="editToMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editToMail of editAlterToMailData; let i = index" [selectable]="true"
                        [removable]="removable" (removed)="editRemoveAlterEmailto(editToMail)">
                        {{ editToMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editToMailInput matInput class="col-sm-6"   [formControl]="editAltertoMailCtrl" [matAutocomplete]="toAuto"
                        [matChipInputFor]="editChipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailto($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="selectedEditAltermailto($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailTo of filteredAlterToMail | async" [value]="mailTo">
                        {{ EmailTo }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterToEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>
                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Cc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs mb-2 form-margin">
                    <mat-chip-list #editChipList3>
                      <mat-chip name="chipsCc" selected *ngFor="let editCcMail of editAlterCcMailData; let i = index"
                        [color]="editCcMail.invalid ? 'warn' : ''" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveAlterEmailcc(editCcMail)">
                        {{ editCcMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editCcMailInput matInput class="col-sm-6"   [formControl]="editAlterccMailCtrl" [matAutocomplete]="ccAuto"
                        [matChipInputFor]="editChipList3" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="selectedEditAltermailcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailCc of filteredAlterCcMail | async" [value]="mailCc">
                        {{ mailCc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterCcEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>
                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Bcc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs mb-2 form-margin">
                    <mat-chip-list #editChipList5>
                      <mat-chip name="chipsBcc" [color]="editBccMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editBccMail of editAlterBccMailData" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveAlterEmailbcc(editBccMail)">
                        {{ editBccMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editBccMailInput matInput class="col-sm-6"   [formControl]="editAlterbccMailCtrl" [matAutocomplete]="bccAuto"
                        [matChipInputFor]="editChipList5" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailbcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="selectedEditAltermailbcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailBcc of filteredAlterBccMail | async" [value]="mailBcc">
                        {{ mailBcc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterBccEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-2 form-align">
                  <div class="col-sm-2 text-xs">
                    <label class="label-font">Body</label>
                  </div>
                  <div class="col-sm-10 mb-2">
                    <div class="mb-2">
                      <button type="button" *ngIf="htmlAlterEmailBody" mat-raised-button class="admin-btn-success mr-4"
                        (click)="viewInAlterEmailBody()">
                        Preview
                      </button>
                      <button type="button" *ngIf="previewAlterEmailBody" mat-raised-button class="admin-btn-success"
                        (click)="viewInAlterEmailBody()">
                        Back
                      </button>
                    </div>
                    <textarea *ngIf="htmlAlterEmailBody" class="mailtext-area" formControlName="AlterEmailbody"
                      type="text" class="form-control form-font p-2 comments-area"></textarea>

                    <div *ngIf="previewAlterEmailBody">
                      <iframe width="620px" height="250px"  title="Emailbody" style="
                        border-width: 1px;
                        border-top: #e5e5e5 !important;
                        border-left: #e5e5e5 !important;
                        -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                        -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                        box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                      " class="frame-cg" srcdoc="<!doctype html>
                    <html lang=&quot;en&quot;>
                    <head>
                      <meta charset=&quot;utf-8&quot;>
                    </head>

                    </head>
                    <body>
                      {{
                        recipientData.value.AlterEmailbody
                      }}
                    </body>
                    </html>"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-3 form-align">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-2 text-xs">
                        <label class="label-font">Report Type</label>
                      </div>
                      <div class="col-sm-10 text-xs">
                        <select class="form-control form-font" formControlName="Report_Type">
                          <option selected value="EXTERNAL">External</option>
                          <option value="INTERNAL">Internal</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-12 mt-2 p-0">
                <div class="row">
                  <div class="col-2">
                    <label class="label-font">Recipient Type</label>
                  </div>

                  <div class="col-10">
                    <input
                      formControlName="Recipient_Type"
                      type="text"
                      class="form-control form-font"
                    />
                  </div>
                </div>
              </div> -->

              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2">
                    <label class="label-font">Notify Only On Data </label>
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" (change)="notifyOnDataChange(true)" value="true"
                      formControlName="notifyOnlyOnData" class="mr-1" />Yes
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" (change)="notifyOnDataChange(false)" value="false"
                      formControlName="notifyOnlyOnData" class="mr-1" />No
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2">
                    <label class="label-font">Notify Blank Attachment</label>
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" [formControl]='frmradioNBAY' value="true"
                      formControlName="notifyBlankAttachment" class="mr-1" />Yes
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" [formControl]='frmradioNBAN' value="false"
                      formControlName="notifyBlankAttachment" class="mr-1" />No
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font"> Include Date In Subject </label>
                  </div>
                  <div class="col-2">
                    <input type="radio" value="true" formControlName="IncludeDateInSubject" class="mr-1" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" value="false" formControlName="IncludeDateInSubject" class="mr-1" />No
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">Protect File </label>
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" value="true" formControlName="fileToBeProtected" class="mr-1"
                      (click)="clickProtect(false)" />Yes
                  </div>
                  <div class="col-2 text-xs">
                    <input type="radio" value="false" formControlName="fileToBeProtected" class="mr-1"
                      (click)="clickProtect(true)" />No
                  </div>
                </div>
              </div>

              <div *ngIf="
                  recipientData.controls['fileToBeProtected'].value == 'true'
                " class="row mt-2 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Password</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input formControlName="password" type="password" maxlength="10" class="form-control form-font" />
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">HTML Report</label>
                  </div>
                  <div class="col-2">
                    <input type="radio" value="true" formControlName="htmlReport" class="mr-1" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" value="false" formControlName="htmlReport" class="mr-1" />No
                  </div>
                </div>
              </div>
            </form>
            <div class="row mb-2 mt-2">
              <div class="col-12">
                <div class="row mt-4" style="justify-content: center">
                  <button mat-raised-button class="admin-btn-success mr-4" (click)="UpdateBackSchedule()" type="button">
                    Back
                  </button>
                  <button mat-raised-button class="admin-btn-success mr-4" (click)="resetTheForm()" type="button">
                    Reset
                  </button>
                  <button mat-raised-button class="admin-btn-success" (click)="UpdateScheduleReport(recipientData.value)" type="button">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!-- Create-->
    <div *ngIf="AddModel" class="col-sm-12 p-0">
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>Create Scheduled Report </strong>
        </h3>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-12">
            <form [formGroup]="ScheduleReportData" class="mt-1 form-height">
              <div class="row px-3">
                <div class="col-sm-6">
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Name<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ ScheduleReportData.value.name }}" formControlName="name" type="text"
                        maxlength="100" class="form-control form-font" />
                    </div>
                  </div>
                  <div class="row mt-3 p-0">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Category </label>
                    </div>

                    <div class="col-sm-7">
                      <input trim formControlName="Category" type="text" class="form-control form-font" disabled />
                    </div>
                  </div>
                  <div class="row mt-3 p-0">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Named Range<span class="error-msg">*</span></label>
                    </div>

                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ ScheduleReportData.value.NamedRange }}" formControlName="NamedRange"
                        maxlength="100" type="text" class="form-control form-font" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">Title<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ ScheduleReportData.value.title }}" formControlName="title" maxlength="100"
                        type="text" class="form-control form-font" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-5 text-xs">
                      <label class="label-font">SP Name<span class="error-msg">*</span></label>
                    </div>
                    <div class="col-sm-7 text-xs">
                      <input trim title="{{ ScheduleReportData.value.storedProcedure }}" formControlName="storedProcedure"
                        maxlength="250" type="text" class="form-control form-font" />
                    </div>
                  </div>

                  <div class="row mt-3 p-0 row">
                    <section class="example-section col-sm-4 mb-3 pl-4">
                        <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="isActive">Active
                        </mat-checkbox>
                    </section>
                </div>
                  <div class="row mt-3 p-0 text-xs">
                    <div class="col-sm-5">
                      <label class="label-font">Connection Name</label>
                    </div>
                    <div class="col-sm-7 row reportdb-align text-xs">
                      <input type="checkbox" formControlName="ConnectionName" value="ReportDB"
                        class="check-box-align m-0" />
                      <label class="pl-2 m-0">ReportDB</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Set frequency here for add start-->
              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Frequency<span class="error-msg">*</span></label>
                </div>
                <div class="col-sm-10 text-xs">
                  <div class="row">
                    <div class="col-sm-12">
                      <input  type="text" class="form-control form-font cron-div" (click)="openModal('add')"
                        value="{{ScheduleReportData.value.cronExpression}} {{cronDescriptionNew}}" readonly readonly
                        data-toggle="tooltip" data-placement="bottom"
                        title="{{ScheduleReportData.value.cronExpression}} {{cronDescriptionNew}}" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Set frequency here for add end-->

              <div class="row mt-3 mb-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Description</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <textarea title="{{ ScheduleReportData.value.Description }}" formControlName="Description" type="text"
                    maxlength="2000" class="form-control form-font p-2"></textarea>
                </div>
              </div>

              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Template File<span class="error-msg">*</span></label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input ID="myFileInputID" type="file" accept=".xlsx" formControlName="templateFile"
                    class="form-control form-font custom-dropzone" (change)="handleUpload($event,'create')" required />
                  <p>Please upload only .xlsx format file</p>
                </div>
              </div>
              <div class="row mt-3 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Template Filename</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input type="text" maxlength="100" class="form-control form-font" formControlName="templateFile" [value]="filename"/>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Subject<span class="error-msg">*</span></label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input trim title="{{ ScheduleReportData.value.EmailSubject }}" formControlName="EmailSubject" type="text"
                    maxlength="1000" class="form-control form-font" required />
                </div>
              </div>
              <div class="border-design mb-2 mr-3 mt-2">
                <div class="col-12">
                  <span class="label-font text-left row">Primary email address</span>
                </div>
                <div class="row mt-0 form-align" id="emailDetails">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">To <span class="error-msg">*</span></label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList>
                      <mat-chip name="chips" [color]="editToMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editToMail of editToMailData; let i = index" [selectable]="true"
                        [removable]="removable" (removed)="editRemoveEmailto(editToMail)" required>
                        {{ editToMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editToMailInput matInput class="col-sm-6"   formControlName="edittoMailCtrl" [matAutocomplete]="toAuto"
                        [matChipInputFor]="editChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailto($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="selectedEditmailto($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailTo of filteredToMail | async" [value]="mailTo">
                        {{ EmailTo }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editToEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Cc </label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList2>
                      <mat-chip name="chipsCc" selected *ngFor="let editCcMail of editCcMailData; let i = index"
                        [color]="editCcMail.invalid ? 'warn' : ''" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveEmailcc(editCcMail)">
                        {{ editCcMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editCcMailInput matInput class="col-sm-6"   [formControl]="editccMailCtrl" [matAutocomplete]="ccAuto"
                        [matChipInputFor]="editChipList2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="selectedEditmailcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailCc of filteredCcMail | async" [value]="mailCc">
                        {{ mailCc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editCcEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font">Bcc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList4>
                      <mat-chip name="chipsBcc" [color]="editBccMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editBccMail of editBccMailData" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveEmailbcc(editBccMail)">
                        {{ editBccMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editBccMailInput matInput class="col-sm-6"   [formControl]="editbccMailCtrl" [matAutocomplete]="bccAuto"
                        [matChipInputFor]="editChipList4" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editEmailbcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="selectedEditmailbcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailBcc of filteredBccMail | async" [value]="mailBcc">
                        {{ mailBcc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editBccEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>
                <div class="row mt-2 form-align">
                  <div class="col-sm-2 text-xs">
                    <label class="label-font">Body</label>
                  </div>
                  <div class="col-sm-10 mb-2">
                    <div class="mb-2">
                      <button type="button" *ngIf="htmlEmailBody" mat-raised-button class="admin-btn-success mr-4"
                        (click)="viewInEmailBody()">
                        Preview
                      </button>
                      <button type="button" *ngIf="previewEmailBody" mat-raised-button class="admin-btn-success"
                        (click)="viewInEmailBody()">
                        Back
                      </button>
                    </div>
                    <textarea *ngIf="htmlEmailBody" class="mailtext-area" formControlName="EmailBody" type="text"
                      class="form-control form-font p-2 comments-area"></textarea>

                    <div *ngIf="previewEmailBody">
                      <iframe width="620px" height="250px"  title="Emailbody" style="
                        border-width: 1px;
                        border-top: #e5e5e5 !important;
                        border-left: #e5e5e5 !important;
                        -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                        -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                        box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                      " class="frame-cg" srcdoc="<!doctype html>
                    <html lang=&quot;en&quot;>
                    <head>
                      <meta charset=&quot;utf-8&quot;>
                    </head>

                    </head>
                    <body>
                      {{
                        ScheduleReportData.value.EmailBody
                      }}
                    </body>
                    </html>"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-design mr-3 mb-2">
                <div class="col-12">
                  <span class="label-font text-left row">Alternative email address</span>
                </div>
                <div class="row mt-2 form-align" id="emailDetails">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font"> To</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList1>
                      <mat-chip name="chips" [color]="editToMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editToMail of editAlterToMailData; let i = index" [selectable]="true"
                        [removable]="removable" (removed)="editRemoveAlterEmailto(editToMail)">
                        {{ editToMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editToMailInput matInput class="col-sm-6"   [formControl]="editAltertoMailCtrl" [matAutocomplete]="toAuto"
                        [matChipInputFor]="editChipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailto($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="selectedEditAltermailto($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailTo of filteredAlterToMail | async" [value]="mailTo">
                        {{ EmailTo }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterToEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>
                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font"> Cc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList3>
                      <mat-chip name="chipsCc" selected *ngFor="let editCcMail of editAlterCcMailData; let i = index"
                        [color]="editCcMail.invalid ? 'warn' : ''" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveAlterEmailcc(editCcMail)">
                        {{ editCcMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editCcMailInput matInput class="col-sm-6"   [formControl]="editAlterccMailCtrl" [matAutocomplete]="ccAuto"
                        [matChipInputFor]="editChipList3" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="selectedEditAltermailcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailCc of filteredAlterCcMail | async" [value]="mailCc">
                        {{ mailCc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterCcEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>
                <div class="row mt-0 form-align">
                  <div class="col-sm-2 mt-2 text-xs">
                    <label class="label-font"> Bcc</label>
                  </div>
                  <mat-form-field class="example-chip-list col-sm-10 text-xs form-margin">
                    <mat-chip-list #editChipList5>
                      <mat-chip name="chipsBcc" [color]="editBccMail.invalid ? 'warn' : ''" selected
                        *ngFor="let editBccMail of editAlterBccMailData" [selectable]="true" [removable]="removable"
                        (removed)="editRemoveAlterEmailbcc(editBccMail)">
                        {{ editBccMail.value }}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                      </mat-chip>
                      <input #editBccMailInput matInput class="col-sm-6"   [formControl]="editAlterbccMailCtrl" [matAutocomplete]="bccAuto"
                        [matChipInputFor]="editChipList5" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="editAlterEmailbcc($event)" />
                    </mat-chip-list>
                    <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="selectedEditAltermailbcc($event)">
                      <mat-option class="mat-opt-align" *ngFor="let mailBcc of filteredAlterBccMail | async" [value]="mailBcc">
                        {{ mailBcc }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error *ngIf="editAlterBccEmailInvalid()">
                    Invalid email ID</mat-error>
                </div>

                <div class="row mt-2 form-align">
                  <div class="col-sm-2 text-xs">
                    <label class="label-font">Body</label>
                  </div>
                  <div class="col-sm-10 mb-2">
                    <div class="mb-2">
                      <button type="button" *ngIf="htmlAlterEmailBody" mat-raised-button class="admin-btn-success mr-4"
                        (click)="viewInAlterEmailBody()">
                        Preview
                      </button>
                      <button type="button" *ngIf="previewAlterEmailBody" mat-raised-button class="admin-btn-success"
                        (click)="viewInAlterEmailBody()">
                        Back
                      </button>
                    </div>
                    <textarea *ngIf="htmlAlterEmailBody" class="mailtext-area" formControlName="AlterEmailbody"
                      type="text" class="form-control form-font p-2 comments-area"></textarea>

                    <div *ngIf="previewAlterEmailBody">
                      <iframe width="620px" height="250px"  title="Emailbody" style="
                        border-width: 1px;
                        border-top: #e5e5e5 !important;
                        border-left: #e5e5e5 !important;
                        -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                        -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                        box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                      " class="frame-cg" srcdoc="<!doctype html>
                    <html lang=&quot;en&quot;>
                    <head>
                      <meta charset=&quot;utf-8&quot;>
                    </head>

                    </head>
                    <body>
                      {{
                        ScheduleReportData.value.AlterEmailbody
                      }}
                    </body>
                    </html>"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-3 form-align">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-2 text-xs">
                        <label class="label-font">Report Type</label>
                      </div>
                      <div class="col-sm-10">
                        <select class="form-control form-font" formControlName="Report_Type">
                          <option selected value="EXTERNAL">External</option>
                          <option value="INTERNAL">Internal</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">Notify Only On Data </label>
                  </div>
                  <div class="col-sm-2">
                    <input type="radio" (change)="notifyOnDataChange(true)" value="true"
                      formControlName="notifyOnlyOnData" class="mr-1" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" (change)="notifyOnDataChange(false)" value="false"
                      formControlName="notifyOnlyOnData" class="mr-1" />No
                  </div>
                </div>
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">Notify Blank Attachment</label>
                  </div>
                  <div class="col-2">
                    <input type="radio" [formControl]='frmradioNBAY' value="true"
                      formControlName="notifyBlankAttachment" class="mr-1" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" [formControl]='frmradioNBAN' value="false"
                      formControlName="notifyBlankAttachment" class="mr-1" />No
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">

              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font"> Include Date In Subject </label>
                  </div>
                  <div class="col-2">
                    <input type="radio" value="true" formControlName="IncludeDateInSubject" class="mr-1"
                      (click)="clickProtect(true)" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" value="false" formControlName="IncludeDateInSubject" class="mr-1"
                      (click)="clickProtect(false)" />No
                  </div>
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">Protect File </label>
                  </div>
                  <div class="col-2">
                    <input type="radio" value="true" formControlName="fileToBeProtected" class="mr-1"
                      (click)="clickProtect(false)" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" value="false" formControlName="fileToBeProtected" class="mr-1"
                      (click)="clickProtect(true)" />No
                  </div>
                </div>
              </div>

              <div *ngIf="
                ScheduleReportData.controls['fileToBeProtected'].value == 'true'
                " class="row mt-2 form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Password</label>
                </div>
                <div class="col-sm-10">
                  <input formControlName="password" type="password" maxlength="10" class="form-control form-font" />
                </div>
              </div>
              <div class="row mt-2 form-align">
                <div class="row mt-2 col-12">
                  <div class="col-2 text-xs">
                    <label class="label-font">HTML Report</label>
                  </div>
                  <div class="col-2">
                    <input type="radio" value="true" formControlName="htmlReport" class="mr-1" />Yes
                  </div>
                  <div class="col-2">
                    <input type="radio" value="false" formControlName="htmlReport" class="mr-1" />No
                  </div>
                </div>
              </div>
            </form>
            <div class="row mb-2 mt-2">
              <div class="col-12">
                <div class="row mt-4" style="justify-content: center">
                  <button mat-raised-button class="admin-btn-success mr-4" (click)="Addback()" type="button">
                    Back
                  </button>
                  <button mat-raised-button class="admin-btn-success" (click)="addNewScheduleReport(ScheduleReportData.value)" type="button">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Show Email Modal -->
    <div *ngIf="ShowModal" class="col-sm-12 p-0">
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong> {{Title}} </strong>
        </h3>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row px-3">
              <div class="col-sm-12 form-height">
                <div class="row">
                  <div class="col-sm-6 border-leftalign">
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Report Name </label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ reportName }}</span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Category </label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ Category }}</span>
                      </div>
                    </div>

                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-3">
                        <label class="label-font">Template File</label>
                      </div>
                      <em class="fa fa-download col-sm-1 curser-pointer" [value]="this.TemplateFile"
                        (click)="downloadTemplate(this.TemplateFile)" title="Download" aria-hidden="true"></em>
                      <div class="col-sm-8 break-words">
                        <span readonly>{{ TemplateFile }}</span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font"> Connection Name</label>
                      </div>
                      <div class="col-8">
                        <span>{{ connectionname }}</span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Created Date</label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ CreatedDate }} </span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Modified Date</label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ ModifiedDate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Title </label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ Title }}</span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-3">
                        <label class="label-font">SP Name</label>
                      </div>
                      <div class="col-sm-1">
                        <img class="copyIcon cursor" title="copy" alt="copy" (click)="copy(StoredProcedure)"
                          src="/assets/images/copy.svg" />
                      </div>
                      <div class="col-8 break-words">
                        <span readonly style="text-overflow: ellipsis">{{
                          StoredProcedure
                          }}</span>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Active </label>
                      </div>
                      <div class="col-8 break-words">
                        <span readonly>{{ Active }}</span>
                      </div>
                    </div>

                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font"> Named Range</label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ NamedRange }}</span>
                      </div>
                    </div>

                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font"> Date in Subject</label>
                      </div>
                      <div class="col-8 break-words">
                        <span>{{ dateInSubject }}</span>
                      </div>
                    </div>
                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Created By</label>
                      </div>
                      <div class="col-8 break-words">
                        <span *ngIf="CreatedBy.toLowerCase() != 'not specified'">{{ createrName[0].firstname }}
                          {{ createrName[0].lastname }} ({{ CreatedBy }})
                        </span>
                        <span *ngIf="CreatedBy.toLowerCase() == 'not specified'">{{ CreatedBy }}
                        </span>
                      </div>
                    </div>

                    <div class="row mt-1 report-bottom-line">
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Modified By </label>
                      </div>
                      <div class="col-8 break-words">
                        <span *ngIf="ModifiedBy.toLowerCase() != 'not specified' ">{{ modifierName[0].firstname }}
                          {{ modifierName[0].lastname }} ({{
                          ModifiedBy
                          }})</span>
                        <span *ngIf="ModifiedBy.toLowerCase() == 'not specified'">{{ ModifiedBy }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- for frequency start -->
                <div class="row mt-2 report-bottom-line">
                  <div class="text-xs text-left col-2">
                    <label class="label-font">Frequency</label>
                  </div>
                  <div class="col-10">
                    <p>{{cronExpression}} {{cronDescriptionNew}}</p>
                    <!-- <p style="margin-top:-10px">{{cronDescriptionNew}}</p> -->
                  </div>
                </div>
                <!-- for frequency  end-->

                <div class="row mt-2 report-bottom-line">
                  <div class="text-xs text-left col-2">
                    <label class="label-font">Description</label>
                  </div>
                  <div class="col-10 break-words">
                    <span>{{ Description }} </span>
                  </div>
                </div>
                <div class="col-12">
                  <span class="label-font text-left row">Email content</span>
                </div>
                <div class="border-design">
                  <span class="label-font text-left row ml-1">Primary email</span>
                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Subject</label>
                    </div>
                    <div class="col-9 break-words">
                      <span readonly>{{ EmailSubject }}</span>
                    </div>
                  </div>

                  <div *ngIf="EmailTo != null" class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">To </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let to of EmailTo" selected color="chip.color">
                        <span>
                          {{ to }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">Cc </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let ecc of EmailCC" selected color="chip.color">
                        <span>
                          {{ ecc }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">Bcc </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let ebcc of EmailBCC" selected color="chip.color">
                        <span>
                          {{ ebcc }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>



                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Body</label>
                    </div>
                    <div class="col-9 break-words">
                      <mat-slide-toggle  (change)="onChange($event.checked)">
                        Raw Format
                      </mat-slide-toggle>

                      <div *ngIf="showEmailData">
                        <span>
                          <iframe width="500px" height="250px"  title="Emailbody" style="
                              border-width: 1px;
                              border-top: #e5e5e5 !important;
                              border-left: #e5e5e5 !important;
                              -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                              -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                              box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                            " class="frame-cg" srcdoc="<!doctype html>
                        <html lang=&quot;en&quot;>
                        <head>
                          <meta charset=&quot;utf-8&quot;>
                        </head>

                        </head>
                        <body>
                          {{ EmailBody }}

                        </body>
                        </html>"></iframe>
                        </span>
                      </div>
                      <div *ngIf="showhtmlCode" class="emailBody p-2 break-words">
                        {{ EmailBody }}
                      </div>
                    </div>
                  </div>
                  <span class="label-font text-left row ml-1">Alternative email</span>
                  <div *ngIf="alterEmailTo != null" class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">To </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let to of alterEmailTo" selected color="chip.color">
                        <span>
                          {{ to }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">Cc </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let ecc of alterEmailCC" selected color="chip.color">
                        <span>
                          {{ ecc }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3 align-mail">
                      <label class="label-font">Bcc </label>
                    </div>
                    <mat-chip-list class="mt-1 mb-1 col-sm-9">
                      <mat-chip *ngFor="let ebcc of alterEmailBCC" selected color="chip.color">
                        <span>
                          {{ ebcc }}
                        </span>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Body</label>
                    </div>
                    <div class="col-9 break-words">
                      <mat-slide-toggle  (change)="onAlterChange($event.checked)">
                        Raw Format
                      </mat-slide-toggle>

                      <div *ngIf="showAlterEmailData">
                        <span>
                          <iframe width="500px" height="250px"  title="Emailbody" style="
                              border-width: 1px;
                              border-top: #e5e5e5 !important;
                              border-left: #e5e5e5 !important;
                              -webkit-box-shadow: 7px 7px 5px -2px rgba(207, 198, 207, 0.81);
                              -moz-box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                              box-shadow: 7px 7px 5px -2px rgba(181, 174, 181, 0.81);
                            " class="frame-cg" srcdoc="<!doctype html>
                        <html lang=&quot;en&quot;>
                        <head>
                          <meta charset=&quot;utf-8&quot;>
                        </head>

                        </head>
                        <body>
                          {{ AlterEmailbody }}

                        </body>
                        </html>"></iframe>
                        </span>
                      </div>
                      <div *ngIf="showAlterhtmlCode" class="emailBody p-2 break-words">
                        {{ AlterEmailbody }}
                      </div>
                    </div>
                  </div>
                  <div  class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Notify Only On Data </label>
                    </div>
                    <div class="col-9">
                      <span readonly>{{ notifyOnlyOnData }}</span>
                    </div>
                  </div>
                  <div  class="row mt-1">
                    <div class="col-sm-3 text-xs text-left">
                      <label class="label-font">Notify Blank Attachment</label>
                    </div>
                    <div class="col-9">
                      <span readonly>{{ notifyBlankAttachment }}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-sm-3 text-xs text-left">
                      <label class="label-font">HTML Report</label>
                    </div>
                    <div class="col-9">
                      <span readonly>{{ htmlReport }}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-sm-3 text-xs text-left">
                      <label class="label-font">HTML Report</label>
                    </div>
                    <div class="col-9">
                      <span readonly>{{ htmlReport }}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">File Protected </label>
                    </div>
                    <div class="col-9">
                      <input *ngIf="fileToBeProtected == true" type="checkbox" checked disabled />
                      <input *ngIf="fileToBeProtected != true" type="checkbox" disabled />
                    </div>
                  </div>

                  <div *ngIf="fileToBeProtected == true" class="row mt-1">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Password </label>
                    </div>
                    <div class="col-9">
                      <span *ngIf="showpassword == false" title="click to view" (click)="passwordShow()">***</span>
                      <span *ngIf="showpassword == true" (click)="passwordShow()" readonly>{{ password }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex">
            <div class="col-sm-12 mt-4">
              <div class="sub-button">
                <button (click)="editBack()" mat-raised-button class="admin-btn-success mr-4">
                  Back
                </button>
                <button (click)="ExportDetailsToExcel()" mat-raised-button class="admin-btn-success mr-4"
                  [disabled]="hideExportBtn">
                  Export
                </button>
                <button mat-raised-button class="admin-btn-success mr-4" (click)="editScheduleReport()" type="button"
                  [disabled]="hideEditBtn">
                  Edit
                </button>
                <button mat-raised-button class="admin-btn-success" (click)="InitiateScheduleReports()" type="button"
                [disabled]="hideSendNowBtn || statsData[0].IsActive === false">
                Send Now
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- for frequency modal -->
  <div class="col-12">
    <app-frequency-modal (cronMessage)="receiveCronMessage($event)"></app-frequency-modal>
  </div>
