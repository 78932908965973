<div *ngIf="!typesso">
  <div #content id="content" class="app-content box-shadow" role="main" style="width: auto">
    <!-- small device view header -->
    <div class="container small_nav">
      <div class="row">
        <div class="col-lg-2 col-md-2 text-center">
          <div class="logo">
            <a href="javascript:;" routerLink="/home" (click)="refreshPage()">
              <img alt="Product Logo" class="product_img" src="/assets/images/VitalAdmin_With_Name.png"
                style="height: 50px; width: auto" />
            </a>
          </div>
        </div>
  
        <div class="col-lg-7 col-md-7">
  
          <form #mysmallform="ngForm" (ngSubmit)="fnChildSearchSubmit(false)"
            style="width: 99%; display: inline-block; margin-right: 2%">
            <div class="input-group m-t-xs" style="width: 100%; border:2px solid #c5c1c1;border-radius: 5px;">
              <div class="input-group-btn dropdown" id="smallentity" click-stop-propagation>
                <button type="button" class="btn white cursor" data-toggle="dropdown" id="dropdownMenu1"
                  aria-haspopup="true" (click)="smallEventPropagation($event);showDrop = !showDrop" aria-expanded="false"
                  style="background-color: #00bcd4; color: white">
                  <span class="entity-list">{{DrpDwnEntityValue}}</span>
                  <!-- <em class="fa fa-chevron-down" *ngIf="!showDrop"></em> -->
                  <em class="fa fa-angle-down" style="font-size: 20px;"></em>
                </button>
  
                <ul class="dropdown-menu" role="menu">
                  <li>
                    <a (click)="OnChangeEntry(entity, en)" class="entity"
                      *ngFor="let entity of EntityList; let en = index">
                      <div style="width: 20px">
                        <em class="fa fa-star-o" *ngIf="en !== isFavEntity" style="margin-right: 6px"></em>
                        <em class="fa fa-star show" *ngIf="en == isFavEntity"
                          style="margin-right: 6px;color: #00bbd3;"></em>
                      </div>
                      <span>{{ entity }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <input type="text" class="form-control" #SearchInput autofocus name="queryvalue" [(ngModel)]="queryvalue"
                placeholder="Deployment:Entity:Search Parameter" style="border: 0" />
              <em class="material-icons close-wrap" *ngIf="queryvalue !== null && queryvalue !== ''"
                (click)="clearSearch()">close</em>
              <button class="search-icon" type="submit">
                <em class="fa fa-search"></em>
              </button>
            </div>
          </form>
        </div>
        <div class="col-lg-3 col-md-3 toggle_btn navbar navbar-expand-lg navbar-light bg-light">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent" style="
              position: absolute;
              border: 1px solid grey;
              border-radius: 5px;
              padding-inline: 10%;
              background-color: #fbfbfb;
              box-shadow: 0px 0px 0px 0px;
              z-index: 999;
            ">
  
            <ul class="navbar-nav mr-auto">
              <li class="nav-item dropdown">
                <a class="nav-link quick-active" title="Quick" data-toggle="dropdown" [ngClass]="{'disableAnchor': quckListdisabled}">
                  <img src="../../../assets/icons/home_icons/quick.svg" alt="Quick" class="material-icons md-24">
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="SelectedModal('$event')"
                    *ngFor="let quick of quicklist; let i = index" [value]="quicklist"> {{quick.Htext }}</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link create-active" title="Create" data-toggle="dropdown">
                  <img src="../../../assets/icons/home_icons/create.svg" alt="Create" class="material-icons md-24">
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="SelectedModal('$event')" *ngFor="let menu of menulist; let i = index"
                    [value]="menulist"> {{menu.Htext }}</a>
                </div>
              </li>
  
              <li class="nav-item dropdown">
                <a class="nav-link compare-active" title="Compare" data-toggle="dropdown">
                  <img src="../../../assets/icons/home_icons/compare.svg" alt="compair" class="material-icons md-24"
                    title="Compare" />
                  <span class="ml-1">Text compare</span>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="SelectedModal(menu)" *ngFor="let menu of comaprelist;" [value]="menu">
                    {{menu.Htext }}</a>
                </div>
              </li>
              <li class="nav-item">
                <div class="p-l-sm p-r-sm m-t-sm text-muted mt-2" style="max-width: 200px; text-overflow: ellipsis; white-space: nowrap;
                      overflow: hidden;  ">
                  {{ username }}
                </div>
              </li>
              <li class="nav-item vline"></li>
              <li class="nav-item">
                <a class="nav-link logout-active" href="javascript:;" (click)="logout()">
                  <img src="../../../assets/icons/home_icons/logout.svg" alt="Logout" class="material-icons md-24"
                    title="Logout">
                </a>
              </li>
            </ul>
          </div>
        </div>
  
        <div class="col-sm-2 align-center p-0" id="drop-down">
          <ul class="nav navbar-nav pull-right" style="margin-right: 10px;">
            <li class="dropdown">
              <a title="Help" class="help-active" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/help.svg" style="margin-top: 5px;" alt="Help"
                  class="material-icons md-24" title="Help">
              </a>
              <div class="dropdown-menu help-wrap">
                <span class="help_label mb-2" style="word-wrap: break-word;">Three part search pattern is <br>
                  <strong>"Deployment:Entity:Search Parameter"</strong></span><br>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Deployments <br>
                  <strong><span *ngFor="let item of DeploymentList">{{item}} ,</span></strong>
                </span>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Entities <br>
                  <strong><span style="text-transform: lowercase;" *ngFor="let entity of EntityList">{{entity}}
                      ,</span><span>accid ,orgid</span></strong>
                </span>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Examples <br>
                  <strong>
                    <span class="help_label">1.lis:case:9(Requisition#,Accession#,CaseID)</span>
                    <span class="help_label">2.upm:user:donald</span>
                    <span class="help_label">3.vplabs:orgid:1</span>
                  </strong>
                </span>
              </div>
            </li>
          </ul>
  
          <mat-form-field class="dep-wrap example-additional-selection mt-1" appearance="outline" style="width: 75%;">
            <!-- <mat-label *ngIf="!defaultdeploymentkey"><strong>Deployment</strong></mat-label> -->
            <mat-label><strong>Deployment</strong></mat-label>
            <mat-select disableOptionCentering type="text" matInput #searchbar [(value)]="defaultdeploymentkey">
              <mat-option class="temp-font-size" [value]="i" *ngFor="let i of DeploymentList; let en = index"
                (onSelectionChange)="onChangeDeployment($event, i)">
                <div style="display: flex; align-items: center;">
                  <em class="fa fa-star-o" (click)="onDeploymentSave(i, en)" *ngIf="en !== isFavDeployment "
                    style="margin-right: 6px"></em>
                  <em class="fa fa-star show" (click)="onDeploymentSave(i, en)" *ngIf="en === isFavDeployment"
                    style="margin-right: 6px;color: #00bbd3;"></em>
                  <span (click)="onChangeDeployment($event,i)">{{ i }}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class="col-lg-3 col-md-3 headerList">
          <ul style="padding: 0; margin-top: 1%">
            <li class="dropdown">
              <a class="nav-link" class="quick-active" title="Quick" data-toggle="dropdown"  [ngClass]="{'disableAnchor': quckListdisabled}">
                <img src="../../../assets/icons/home_icons/quick.svg" alt="Quick" class="material-icons md-24">
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item " (click)="SelectedModal(quick)" *ngFor="let quick of quicklist;" [value]="quick">
                  {{quick.Htext }}</a>
              </div>
            </li>
            <li class="dropdown">
              <a class="nav-link" class="create-active" title="Create" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/create.svg" alt="Create" style="color: #848484;"
                  class="material-icons md-24">
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" *ngFor="let menu of menulist; let i = index" [value]="menu">
                  {{menu.Htext}}</a>
              </div>
            </li>
            <li class="dropdown">
              <a class="nav-link" class="compare-active" title="Compare" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/compare.svg" alt="compare" class="material-icons md-24"
                  title="Compare" />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="SelectedModal(menu)" *ngFor="let menu of comaprelist;" [value]="menu">
                  {{menu.Htext }}</a>
              </div>
            </li>
            <li class="nav-item vline p-0" style="top: 1px"></li>
            <li class="col-sm-6 p-0">
              <div class="p-l-sm p-r-sm user-wrap w-100" title=" {{ username }}">
                {{ username }}
              </div>
              <div class="p-l-sm p-r-sm user-wrap  w-100" title=" {{ rolename }}">
                {{ rolename }}
              </div>
            </li>
            <li class="nav-item vline p-0" style="top: 1px"></li>
            <li>
              <a class="nav-link logout-active" href="javascript:;" (click)="logout()">
                <img src="../../../assets/icons/home_icons/logout.svg" alt="Logout" class="material-icons md-24"
                  title="Logout">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
    <!-- desktop view header -->
    <div class="app-header white vtnav_bar box-shadow" style="margin-left: 0;position: relative;">
      <div class=" row navbar navbar-toggleable-sm align-items-center pl-2 pt-0 pb-0">
        <div class="col-sm-2 navbar-item pull-left h8">
          <a href="javascript:;" routerLink="/home" (click)="refreshPage()">
            <img alt="." class="" src="/assets/images/VitalAdmin_With_Name.png" style="height: 50px; width: auto" />
          </a>
        </div>
        <div class="col-sm-5">
          <ul style="color: grey" class="
              nav
              navbar-nav
              top-navbar
              text-center
              text-admin-hover
              mr-auto
            ">
            <li class="nav-item" style="width: 100%;">
              <form #myform="ngForm" (ngSubmit)="fnChildSearchSubmit(false)">
                <div class="input-group m-t-xs" style="width: 100%;border:2px solid #c5c1c1;border-radius: 5px;">
                  <div class="input-group-btn dropdown" id="entityDrpdwn" click-stop-propagation>
                    <button type="button" class="btn white cursor" data-toggle="dropdown" id="dropdownMenu2"
                      (click)="EventPropagation($event);showDrop = !showDrop" aria-haspopup="true" aria-expanded="true"
                      style="background-color: #00bcd4; color: white">
                      <span class="entity-list">{{DrpDwnEntityValue}}</span>
                      <!-- <em class="fa fa-chevron-down" *ngIf="!showDrop"></em> -->
                      <em class="fa fa-angle-down" style="font-size: 20px;"></em>
                    </button>
                    <ul class="dropdown-menu" role="menu">
                      <li>
                        <a (click)="OnChangeEntity(entity, en)" class="entity"
                          *ngFor="let entity of EntityList; let en = index">
                          <div style="width: 20px" (click)="onEntitySave(entity,en)">
                            <em class="fa fa-star-o" *ngIf="en !== isFavEntity" style="margin-right: 6px"></em>
                            <em class="fa fa-star show" *ngIf="en == isFavEntity"
                              style="margin-right: 6px;color: #00bbd3;"></em>
                          </div>
                          <span>
                            <span><strong>{{ entity }}</strong></span>
                            <!-- <div>This will search for {{entity}}</div> -->
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <input type="text" class="form-control" #SearchInput autofocus name="queryvalue"
                    [(ngModel)]="queryvalue" placeholder="Deployment:Entity:Search Parameter" style="border: 0" />
                  <em class="material-icons close-wrap" *ngIf="queryvalue !== null && queryvalue !== ''"
                    (click)="clearSearch()">close</em>
                  <button class="search-icon" type="submit">
                    <em class="fa fa-search"></em>
                  </button>
                </div>
              </form>
            </li>
          </ul>
        </div>
        <div class="col-sm-2 align-center p-0" id="drop-down">
          <ul class="nav navbar-nav pull-right" style="margin-right: 10px;">
            <li class="dropdown">
              <a title="Help" class="help-active" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/help.svg" style="margin-top: 5px;" alt="Help"
                  class="material-icons md-24" title="Help">
              </a>
              <div class="dropdown-menu help-wrap">
                <span class="help_label mb-2" style="word-wrap: break-word;">Three part search pattern is <br>
                  <strong>"Deployment:Entity:Search Parameter"</strong></span>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Deployments <br>
                  <strong><span *ngFor="let item of DeploymentList">{{item}} ,</span></strong>
                </span>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Entities <br>
                  <strong><span style="text-transform: lowercase;"
                      *ngFor="let entity of EntityList">{{entity}},</span><span>accid,orgid</span></strong>
                </span>
  
                <span class="help_label mb-2" style="word-wrap: break-word;">Examples <br>
                  <strong>
                    <span class="help_label">1.lis:case:9(Requisition#,Accession#,CaseID)</span>
                    <span class="help_label">2.upm:user:donald</span>
                    <span class="help_label">3.vplabs:orgid:1</span>
                  </strong>
                </span>
              </div>
            </li>
          </ul>
  
          <mat-form-field class="dep-wrap example-additional-selection mt-1" appearance="outline" style="width:75%;">
            <!-- <mat-label *ngIf="!defaultdeploymentkey"><strong>Deployment</strong></mat-label> -->
            <mat-label><strong>Deployment</strong></mat-label>
            <mat-select disableOptionCentering type="text" matInput #searchbar [(value)]="defaultdeploymentkey" [disabled]=true>
              <!-- this code will used when we want the Deployment wise application -->
              <mat-option [disabled]=true class="temp-font-size" [value]="i.toUpperCase()"
                *ngFor="let i of DeploymentList; let en = index" (onSelectionChange)="onChangeDeployment($event, i)">
                <div style="display: flex; align-items: center;">
                  <em class="fa fa-star-o" (click)="onDeploymentSave(i, en)" *ngIf="en !== isFavDeployment "
                    style="margin-right: 6px"></em>
                  <em class="fa fa-star show" (click)="onDeploymentSave(i, en)" *ngIf="en === isFavDeployment"
                    style="margin-right: 6px;color: #00bbd3;"></em>
                  <span (click)="onChangeDeployment(i.toUpperCase())">{{ i.toUpperCase() }}</span>
                </div>
              </mat-option>
              <!-- this will used when we want the for the multiple deployment wise
              <mat-option  class="temp-font-size" [value]="i.toUpperCase()"
              *ngFor="let i of DeploymentList; let en = index" (onSelectionChange)="onChangeDeployment($event, i)">
              <div style="display: flex; align-items: center;">
                <em class="fa fa-star-o" (click)="onDeploymentSave(i, en)" *ngIf="en !== isFavDeployment "
                  style="margin-right: 6px"></em>
                <em class="fa fa-star show" (click)="onDeploymentSave(i, en)" *ngIf="en === isFavDeployment"
                  style="margin-right: 6px;color: #00bbd3;"></em>
                <span (click)="onChangeDeployment(i.toUpperCase())">{{ i.toUpperCase() }}</span>
              </div>
            </mat-option> -->
            </mat-select>
          </mat-form-field>
  
        </div>
  
        <div class="col-sm-3 p-0">
          <ul class="col-sm-12 nav navbar-nav pull-right align-center p-0">
            <li class="dropdown">
              <a class="nav-link quick-active" title="Quick" data-toggle="dropdown"  [ngClass]="{'disableAnchor': quckListdisabled}">
                <img src="../../../assets/icons/home_icons/quick.svg" alt="Quick" class="material-icons md-24">
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item " (click)="SelectedModal(quick)" *ngFor="let quick of quicklist;" [value]="quick">
                  {{quick.Htext }}</a>
              </div>
            </li>
            <li class="dropdown">
              <a class="nav-link create-active" title="Create" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/create.svg" alt="Create" class="material-icons md-24">
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="SelectedModal(menu)" *ngFor="let menu of menulist;" [value]="menu">
                  {{menu.Htext }}</a>
              </div>
            </li>
            <li class="dropdown">
              <a class="nav-link compare-active" title="Compare" data-toggle="dropdown">
                <img src="../../../assets/icons/home_icons/compare.svg" alt="copair" class="material-icons md-24"
                  title="Compare" />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="SelectedModal(menu)" *ngFor="let menu of comaprelist;" [value]="menu">
                  {{menu.Htext }}</a>
              </div>
            </li>
            <li class="nav-item vline p-0" style="top: 1px"></li>
            <li class="col-sm-6 p-0">
              <div class="p-l-sm p-r-sm user-wrap w-100" title=" {{ username }}">
                {{ username }}
              </div>
              <div class="p-l-sm p-r-sm user-wrap w-100" title=" {{ rolename }}">
                {{ rolename }}
              </div>
            </li>
  
            <li class="nav-item vline p-0" style="top: 1px"></li>
            <li>
              <a class="nav-link logout-active" href="javascript:;" title="Logout" (click)="logout()">
                <img src="../../../assets/icons/home_icons/logout.svg" alt="Logout" class="material-icons md-24">
              </a>
            </li>
          </ul>
  
        </div>
      </div>
      <div class="mb-0 h5 no-wrap" ng-bind="$state.current.data.title" id="pageTitle"></div>
      <div class="maintabs"></div>
    </div>
  </div>
  
  <div class="app-footer" style="position: fixed">
    <div class="pr-2 text-xs">
      <div class="pull-right text-muted">
        &copy; Copyright
        <span style="color: #00365f !important">
          <strong>VitalAxis</strong>
        </span>
        <span class="hidden-xs-down"></span>
      </div>
    </div>
  </div>
  <!-- class="app-body p-a-0" -->
  <div ui-view id="view">
    <!-- <router-outlet></router-outlet> -->
    <!-- <vs-entitysearch></vs-entitysearch> -->
    <vs-entitysearchs [SearchData]="{
        EntityValue: searchDrpDwnEntityValue,
        queryvalue: searchQueryValue
      }" [submitType]="submitType"></vs-entitysearchs>
    <!-- <vs-entitysearch [SearchData]='{EntityValue: searchDrpDwnEntityValue, queryvalue: searchQueryValue}' [submitType]= 'submitType'></vs-entitysearch> -->
  </div>
  <!-- generic Modal -->
  <div ngClass={{GenericModalClass}} id="genericModal" data-backdrop="static" data-keyboard="false">
    <!-- user Ip Modal -->
    <span *ngIf="SelectedMenuModel === 'UserIpModel'">
      <template-AddIPAddress [selecteddefaultdeploymentkey]=defaultdeployment></template-AddIPAddress>
    </span>
    <!-- HL7 Logs Modal -->
    <span *ngIf="SelectedMenuModel === 'HL7LogsModel'">
      <template-HL7LogsModel [selecteddefaultdeploymentkey]=defaultdeployment></template-HL7LogsModel>
    </span>
    <!-- Add User -->
    <span *ngIf="SelectedMenuModel === 'AddUserModel'">
      <template-AddUserToOrg [createLiveUser]="createLiveUser" [selecteddefaultdeploymentkey]=defaultdeployment>
      </template-AddUserToOrg>
    </span>
    <!---Add VDID Modal-->
    <span *ngIf="SelectedMenuModel === 'VDIDModel'">
      <template-GenerateVDId></template-GenerateVDId>
    </span>
    <span *ngIf="SelectedMenuModel === 'ReplicateOrgModel'">
        <app-replicate-organization [selecteddefaultdeploymentkey]=defaultdeployment></app-replicate-organization>
      </span>
      <span *ngIf="SelectedMenuModel === 'VitalInsightsModel'">
        <app-vital-insights [selecteddefaultdeploymentkey]="SelectedMenuModel"></app-vital-insights>
      </span>
    <!---Add Executesql Modal-->
    <span *ngIf="SelectedMenuModel === 'ExecutesqlModel'">
      <template-Executesql [selecteddefaultdeploymentkey]="defaultdeployment"></template-Executesql>
    </span>
    <!---Org Compare Modal-->
    <span *ngIf="SelectedMenuModel === 'OrgCompareModel'">
      <template-OrgCompare [fromButton]="HomeComponent"></template-OrgCompare>
    </span>
    <!---Account Compare Modal-->
    <!-- <span *ngIf="SelectedMenuModel === 'AccCompare'">
      <template-AccCompare></template-AccCompare>
    </span> -->
    <!---Org Compare Modal-->
    <span *ngIf="SelectedMenuModel === 'TextCompareModel'">
      <template-TextCompare></template-TextCompare>
    </span>
    <!---Add Revision Reason Modal-->
    <span *ngIf="SelectedMenuModel === 'RevisionReasonModal'">
      <template-RevisionReasonModel [selecteddefaultdeploymentkey]=defaultdeployment></template-RevisionReasonModel>
    </span>
    <!---Add Manage Report Recipient Modal-->
    <!-- <span *ngIf="SelectedMenuModel === 'ManageReportRecipientsModal'">
      <app-manage-report-recipient></app-manage-report-recipient>
    </span> -->
    <!-- Setup New LAB with casetype Modal -->
    <span *ngIf="SelectedMenuModel === 'new-casetype-enable-casetype'">
      <template-setup-customer-enable-casetype [selecteddefaultdeploymentkey]=defaultdeployment>
      </template-setup-customer-enable-casetype>
    </span>
    <!-- <span *ngSwitchDefault>No Modal popup</span> -->
    <!-- Add New Lap Setup Modal -->
    <span *ngIf="SelectedMenuModel === 'setup-new-customer'">
      <template-setup-new-customer [selecteddefaultdeploymentkey]=defaultdeployment></template-setup-new-customer>
    </span>
  
    <!-- <span *ngIf="SelectedMenuModel === 'app-user-role-mgmt'">
      <app-user-role-mgmt [selecteddefaultdeploymentkey]=defaultdeployment></app-user-role-mgmt>
    </span> -->
  
    <span *ngIf="SelectedMenuModel === 'app-json-format'">
      <app-json-format></app-json-format>
    </span>
    <!-- Add Export Cases -->
    <!-- export-case-details modal -->
    <!-- <span *ngIf="SelectedMenuModel === 'export-case-details'">
      <app-export-case-details [selecteddefaultdeploymentkey]=defaultdeployment></app-export-case-details>
    </span> -->
    <span *ngIf="SelectedMenuModel === 'app-compendium-admin'">
      <app-compendium-admin [selecteddefaultdeploymentkey]=defaultdeployment></app-compendium-admin>
    </span>
  </div>
  </div>
  
  
  <!-- to lunch the application by superAdmin -->
  <div *ngIf="typesso">
    <div *ngFor="let aTab of EachEntityTabArray;let tabIndex = index">
      <div class="row">
        <ngx-ui-loader></ngx-ui-loader>
      </div>
      <tab >
        <app-vital-admin-search-connector [currentTab]='aTab.Content.DisplayField.locationid' [searchTabContent]='aTab.Content'>
        </app-vital-admin-search-connector>
        <!-- <test [testData]="aTab.Content"></test> -->
      </tab>
    </div>
    <!-- <test [testData]="aTab.Content"></test> -->
  </div>
  
  <!-- <div class="modal genericModalinLab OrgCompareDialog OrgCompareinHomeModal" id="OrgCompareinHomeModal"
    data-backdrop="static" data-keyboard="false">
    Org Compare Modal
    <span *ngIf="SelectedMenuModel === 'OrgCompareModel'">
      <template-OrgCompare [fromButton]="HomeComponent"></template-OrgCompare>
    </span>
  </div> -->

  <!-- superAdmin user check  -->
  <div *ngIf="userAuthFlag">
    <div class="text-color m-a">
      <h3>
        Unauthorized user ,Please logout and re-login</h3>
    </div>

  </div>
  
