<div class="p-2 infodetails">

  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="!checkUserDetails()" [diameter]="50">
  </mat-spinner>

  <div class=" col-sm-12 row" style="font-size: 12px;">
    <div class="col-sm-8 pl-4">
      <div class="row">
        <span class="_600 text-muted span-first">ID </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['UniqueID']}}">{{locationDetails?.['UniqueID']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">NPI</span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['NPI']}}">{{locationDetails?.['NPI']}} </strong>

      </div>
      <div class="row">
        <span class="_600 text-muted span-first">GUID </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['GUID']}}">{{locationDetails?.['GUID']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">Mnemonic </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['Mnemonic']}}">{{locationDetails?.['Mnemonic']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">CLIA </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['CLIA']}}">{{locationDetails?.['CLIA']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">LocationCode</span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third">{{locationDetails?.['LocationCode']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">ClientNumber </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['ClientNumber']}}">{{locationDetails?.['ClientNumber']}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">Is Listed as Ordering Facility </span>
        <span class="span-second">: </span>
        <app-toggle-switch style="margin-left: -10px;" [isChecked]="locationDetails?.['IsListedAsOF']" [isDisable]="true"></app-toggle-switch>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">TimeZone </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['TimeZone'] | titlecase}}">{{locationDetails?.['TimeZone'] | titlecase}}</strong>
      </div>
      <div class="row">
        <span class="_600 text-muted span-first">Default Service </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" matTooltip="{{locationDetails?.['serviceDetails']}}">{{locationDetails?.['serviceDetails'] | titlecase}}</strong>
      </div>
    </div>
    <div class="col-sm-4">

      <div class="row d-block" style="width: 99%;">
        <span class="_600 text-muted">Primary Contact :</span>

        <div class="icon_label ml-2 tool-tip " matTooltip="{{locationDetails?.['PrimaryContactName'] | titlecase}}">
          <mat-icon class="vdm_icon">person</mat-icon>
          {{locationDetails?.['PrimaryContactName'] | titlecase}}
        </div>
        <div class="icon_label ml-2 tool-tip " matTooltip="{{locationDetails?.['PrimaryContactEmail'] | lowercase}}">
          <mat-icon class="vdm_icon">email</mat-icon>
          {{locationDetails?.['PrimaryContactEmail'] | lowercase}}
        </div>
        <div class="icon_label ml-2 tool-tip " matTooltip="{{locationDetails?.['PrimaryContactPhone']}}">
          <mat-icon class="vdm_icon">phone</mat-icon>
          {{locationDetails?.['PrimaryContactPhone'] | lowercase}}
      </div>
      </div>
    </div>
  </div>
</div>
