import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-multisheet-popup',
  templateUrl: './multisheet-popup.component.html',
  styleUrls: ['./multisheet-popup.component.scss']
})
export class MultisheetPopupComponent implements OnInit {
  [x: string]: any;
  isDirty: any;
  constructor(public dialogRef: MatDialogRef<MultisheetPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public sheetData: any) { }
  

  ngOnInit(): void {
  }
  onNoClick(sheet) {
    this.isDirty = false;
    sheet==''?this.dialogRef.close(false):this.dialogRef.close(sheet);
    // return sheet;
  }

  // canDeactivate(): boolean {
  //   return true
  // }

}
