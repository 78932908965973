import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-grossing-attributes',
  templateUrl: './grossing-attributes.component.html',
  styleUrls: ['./grossing-attributes.component.scss']
})

export class GrossingAttributesComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  [x: string]: any;
  @Input()
  public subMenudata: any;
  @Input()
  templateData: any;
  gridData: any;
  dropDownvalues = ["Color", 'Fixatives']
  grossingAttributeData: any;
  AttributeTypes = [];
  filterOrganizations: Observable<any[]>;
  gridheader = [
    "Attribute_ID",
    "Attribute_Level",
    "Status",
    "Sequence_Order",
    "Attribute_Name",
    "Attribute_Type",
    "Created_By",
    "CreatedDate",
    "Modified_By",
    "Modified_Date"
  ];
  showPaginationMainGrid: boolean;
  attributeType = new UntypedFormControl();
  tempExport: any;
  caseType: any;
  orgname = new UntypedFormControl();
  uploadClicked: boolean = false;
  gridPage: boolean = false;
  gridWidth: number = 0;
  workBook: any;
  sheetsToSelect: any[];
  grossAttributeForm = this._fb.group({
    frmAttrType: ["", Validators.required],
    frmAttrName: "",
    frmIsActive: ["", Validators.required],
    frmSeqOrder: "",
  })
  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: ''
  })
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  excelDataArray: any[];
  sheetHeader: any[];
  showInvalidColumns: boolean = false;
  invalidColumns: any = "";
  gridDisplay: boolean;
  showDelete: boolean;
  orgId: any;
  cardType: any;
  postUpload: boolean = false;
  allFields = [{}];
  mandatoryFields = [{}];
  attrtype: any = "";
  uploadHeader: string;
  addEditScreen: boolean;
  showGrid: boolean;
  action: string;
  attrID: any;
  postDownload: boolean;
  copyfromScreen: boolean;
  srcDeployment: any;
  DeploymentKeys: any;
  gridwidth: number;
  searchInput: string;
  searchUserInput: string;
  usertoggle: boolean;
  toggle: boolean;
  OrganizationList: any;
  hideGrid: boolean;
  gridShowDiv: boolean;
  showPagination: boolean;
  dataToTakeAsInput: { OrganizationID: any; CaseType: any; TableName: string; ColumnNames: string[], columnDefs: string[] };
  searchResult: any[];
  orgName: any;
  organizationid: any;
  destDeployment: string;
  selectedItems: any = [];
  checkStatus: boolean;
  showMsg: boolean;
  getTableData: boolean;
  globalexist: boolean = false;
  showModal: boolean = true;
  auditableColumns: any;
  activityEntity:any;
  constructor(public _snackbar: MatSnackBar, private dialog: MatDialog, private _fb: UntypedFormBuilder, private vitalHttpServices: VitalHttpServices, 
    private commonService: CommonService, private ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService) { }

  ngOnInit(): void {
    this.caseType = this.templateData.secondarykeys.casetype;
    this.orgId = this.templateData.secondarykeys.OrganizationId;
    this.cardType = this.templateData.cardtype;
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.attrtype = "All";
    this.GetButtondetails();
    this.getAttributeTypesDropdown();
    this.attributeType.setValue('All');
    this.dataToTakeAsInput = {
      OrganizationID: this.orgId.toString(),
      CaseType: this.caseType.toString(),
      TableName: 'OrganizationAttributes',
      ColumnNames: ["AttributeID", "AttributeType", "AttributeName", "AttributeContext", "IsActive", "SequenceOrder", "CreatedBy", "CreatedDate", "ModifiedBy", "ModifiedDate", "Organizationid", "AttributeContextId", "AttributeDescription", "AttributeValue", "OrganizationAttributeGUID", "AttributeServiceType", "AttributeContextId2", "AttributeContextId3"],
      columnDefs: ["IsActive", 'SequenceOrder', 'AttributeName', 'AttributeType']
    };
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    //this.AddGridData();
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'CopyToOrg':
          this.hideCopyBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Upload':
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  ngOnChanges() {
    this.AddGridData();
  }

  getAttributeTypesDropdown() {
    this.ngxService.start();
    this.noConfiguration = false;
    sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { "Context": "GrossAttributes", "keyword": this.caseType.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.ngxService.stop();
      if (!Resdata.errors) {
        if (Resdata.data.submenuData[0] && Resdata.data.submenuData[0].Items_JSON) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.AttributeTypes = data.AttributeTypes;
          this.tempAttributeTypes = [];
          for (let i = 0; i < this.AttributeTypes.length; i++) {
            this.tempAttributeTypes.push(this.AttributeTypes[i].Item);
          }
          let Obj = {
            "organizationid": this.orgId,
            "attributeContext": this.caseType,
            "attributetype": this.tempAttributeTypes
          }
          this.getGrossAttibutes(Obj);
        }
        else {
          this._snackbar.open("Grossing attributes is not configured for this casetype", 'close');
          this.noConfiguration = true;
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    if (allFieldsCheck) {
      filename = 'Grossing Attributes_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(allFieldsCheck ? this.allFields : this.mandatoryFields);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'Grossing Attributes');
      ws = XLSX.utils.json_to_sheet(this.sampleAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else {
      filename = 'Grossing Attributes_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(allFieldsCheck ? this.allFields : this.mandatoryFields);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'Grossing Attributes');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMinFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }

  }

  sampleDataMinFields = [{
    AttributeType: 'Fixatives'
    , AttributeName: 'AttributeName'
    , IsActive: '1'
  }]
  sampleAllFields = [{
    AttributeType: 'Fixatives'
    , AttributeName: 'AttributeName'
    , IsActive: '1'
    , SequenceOrder: '123'
  }]


  getGrossAttibutes(obj) {
    this.ngxService.start();
    this.vitalHttpServices.getGrossAttributes(obj).subscribe(result => {
      this.ngxService.stop();
      if (!result.error) {
        this.templateData.submenuData = JSON.parse(result.Message)
        this.globalAttrTypes = [];
        this.globalData = [];
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          if (this.templateData.submenuData[i]['Attribute_Level'] == 'Default') {
            this.globalexist = true;
            this.globalAttrTypes.push(this.templateData.submenuData[i]['Attribute_Type']);
            this.globalData.push(this.templateData.submenuData[i]);
          }
        }
        this.globalAttrTypes = [...new Set(this.globalAttrTypes)] // to get the distinct attribute types
        this.activityEntity.entityId = '';
        this.activityService.setActivitySession(this.activityEntity);
        this.AddGridData();
      }
    }, error => {
      this.ngxService.stop();
      console.log(error)
    })
  }

  AddGridData() {
    let gridarray = []
    let primary = {}
    this.showGrid = true;
    this.addEditScreen = false;
    this.uploadClicked = false;
    if (this.templateData.submenuData) {
      //this.tempExport = this.templateData.submenuData;
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        if (this.templateData.submenuData.find(r => (r.Attribute_Level == 'Default'))) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
        gridarray.sort((a, b) => a.Sequence_Order < b.Sequence_Order ? 1 : -1).sort((a, b) => a.Attribute_Type.toLowerCase() > b.Attribute_Type.toLowerCase() ? 1 : -1);
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }

    }
  }

  getGrossingAttribute(data) {
    this.tempAttributeTypes = [];
    if (data != 'All') {
      this.tempAttributeTypes.push(data)
    }
    else {
      for (let i = 0; i < this.AttributeTypes.length; i++) {
        this.tempAttributeTypes.push(this.AttributeTypes[i].Item);
      }
    }
    this.attrtype = data;
    let obj = {
      "organizationid": this.orgId,
      "attributeContext": this.caseType,
      "attributetype": this.tempAttributeTypes
    }
    this.getGrossAttibutes(obj);
  }
  showEditIconInGrid(row) {
    let level = row.dataItem.Attribute_Level;
    if (level == 'Default')
      return false;
    else
      return true;
  }

  initGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#region Export grid data as excel

  exportGrossAttributesData(flex) {
    let excel = [];
    let modifiedExcel: any = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate()
    let rows = flex.rows
    rows.find(e => {
      excel.push(e._data);
    });

    for (let i = 0; i < excel.length; i++) {


      modifiedExcel[i] = {}
      modifiedExcel[i]["AttributeID"] = excel[i]["Attribute_ID"]
      modifiedExcel[i]["AttributeType"] = excel[i]["Attribute_Type"]
      modifiedExcel[i]["AttributeName"] = excel[i]["Attribute_Name"]
      modifiedExcel[i]["SequenceOrder"] = excel[i]["Sequence_Order"]
      if (excel[i]['Status']) {
        modifiedExcel[i]['IsActive'] = excel[i]['Status'] == 'Active' ? true : false;
        //delete excel[i]['Status']
      }
      modifiedExcel[i]["ConfigurationLevel"] = excel[i]["Attribute_Level"]
      modifiedExcel[i]["CreatedBy"] = excel[i]["Created_By"]
      modifiedExcel[i]["CreatedDate"] = excel[i]["CreatedDate"]
      modifiedExcel[i]["ModifiedBy"] = excel[i]["Modified_By"]
      modifiedExcel[i]["ModifiedDate"] = excel[i]["Modified_Date"]
      // modifiedExcel[i]["IsActive"] = excel[i]["Status"]
    }
    let filename = 'Grossing Attributes_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      modifiedExcel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Grossing Attributes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }

  uploadGrossAttrExcelData() {
    if (this.hideUploadBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.uploadClicked = true;
    this.postUpload = false;
    this.showGrid = false;
    this.showModal = false;
    this.gridPage = false;
    this.gridWidth = 0
    this.uploadHeader = this.attrtype == 'All' ? 'Upload Grossing Attributes' : 'Upload ' + this.attrtype + ' Grossing attributes'
    // this.getTemplates()
    let queryVariable = { tablename: 'OrganizationAttributes', identity: "false", nullable: "false" };
    let query = this.vitalHttpServices.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          let siteFormatFields = ['AttributeType', 'AttributeName', 'IsActive']
          for (let item of siteFormatFields) {
            this.allFields[0][item] = ''
            this.mandatoryFields[0][item] = ''
          }
          for (let item of data.data.allFields) {
            this.allFields[0][item.Column] = '';
          }
          delete this.allFields[0]['AttributeID']
          delete this.allFields[0]['CreatedBy']
          delete this.allFields[0]['CreatedDate']
          delete this.allFields[0]['ModifiedDate']
          delete this.allFields[0]['ModifiedBy']
          delete this.allFields[0]['AttributeContext'];
          delete this.allFields[0]['OrganizationAttributeGUID']
          delete this.allFields[0]['Organizationid']
          delete this.allFields[0]['AttributeContextId']
          delete this.allFields[0]['AttributeDescription']
          delete this.allFields[0]['AttributeValue']
          delete this.allFields[0]['AttributeServiceType']
          delete this.allFields[0]['AttributeContextId2']
          delete this.allFields[0]['AttributeContextId3']
        }
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }


  //#region Method to handle file drop or selection
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  convertToJson(sheetname: string) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (!key.toLowerCase().toString().match(/isactive/i) && !key.toLowerCase().toString().match(/active/i)) {
            if (!value) {
              value = ''
            }
            else if (value.toString().match(/null/i)) {
              value = null;
            }
          }
          if (key.toLowerCase().toString().match(/isactive/i) || key.toLowerCase().toString().match(/active/i)) {
            // key='IsActive'
            if (value === 1 || value === true || value === 'true' || value === '' || value === null) {
              value = true
            } else if (value === 0 || value === false || value === 'false') {
              value = false
            }
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()
    temp[0].push("Notes")
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    if (!this.copyfromScreen) {
      this.sheetHeader.reverse();
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    let data: any = [];
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    for (let i = 0; i < this.tempAttributeTypes.length; i++) {
      data = data + this.tempAttributeTypes[i] + ',';
    }
    data = data.substring(0, data.length - 1);

    // this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationattributes', organizationid: this.orgId.toString(), AttributeContext: this.cardType.toString(), Type: data });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  refreshGrid() {
    this.ngxService.start();
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.copyfromScreen = false;
    this.getTableData = false;
    this.showGrid = true;
    this.addEditScreen = false;
    this.showInvalidColumns = false;
    this.uploadClicked = false;
    this.globalIndicater = false;
    this.grossAttributeForm.reset();
    this.getGrossingAttribute(this.attrtype)
    this.attributeType.setValue(this.attrtype);
    this.postUpload = false;
    this.postDownload = false;
    this.searchInput = '';
    this.searchUserInput = '';
    this.checkStatus = false;
    this.excelDataArray = [];
    this.ngxService.stop();
  }
  disableApprovebtn() {
    let existsCount = 0;
    if (this.copyfromScreen) {
      return this.selectedItems.every(va => va._data['notes'] && (va._data['notes'].toString().match(/already exists /i) || va._data['notes'].toString().match(/mandatory/i) || va._data['notes'].toString().match(/invalid/i) || va._data['notes'].toString().match(/type selected in the list page/i)) || !va._data['notes'].toString().match(/valid/i))
    }

    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/already exists /i) || this.excelDataArray[i]['notes'].toString().match(/mandatory/i) || this.excelDataArray[i]['notes'].toString().match(/invalid/i) || this.excelDataArray[i]['notes'].toString().match(/type selected in the list page/i)) || !this.excelDataArray[i]['notes'].toString().match(/valid/i)) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.showDelete = false;
    this.postDownload = false;
    //this.typeData = []
    //this.mappingSite = []
  }

  //#region Convert data keys to lowercase
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion
  //#region Function to upload GrossAttributes Data
  uploadGrossAttributesData(context?: string) {
    let sendjson = [];
    let tempDataArray = [];
    tempDataArray = this.excelDataArray;
    if (context == 'copy') {
      for (let i = 0; i < this.selectedItems.length; i++) {
        sendjson.push(this.selectedItems[i]._data);
        if (this.checkStatus) {
          sendjson[i].isactive = false;
        }
      }
      tempDataArray = sendjson;
      this.excelDataArray = sendjson;
      this.showInvalidColumns = false;
    }

    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]["notes"] == "") {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          this.excelDataArray = tempDataArray;
          return;
        }
        else if (this.excelDataArray[i]["notes"] && (!this.excelDataArray[i]["notes"].toString().match(/already exists/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/invalid/i) && !this.excelDataArray[i]["notes"].toString().match(/mandatory/i) && !this.excelDataArray[i]["notes"].toString().match(/type selected in the list page/i))) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        this.excelDataArray = tempDataArray;
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        this.excelDataArray = tempDataArray;
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              if (key.toString().match(/isactive/i)) {
                value = value == true || value.toString().toLowerCase() == 'true' ? 1 : 0
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["sequenceorder"] = this.excelDataArray[i]["sequenceorder"] == null ? "" : this.excelDataArray[i]["sequenceorder"]

            this.excelDataArray[i]["attributename"] = this.excelDataArray[i]["attributename"];
          }
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      sendjson = dataArray.filter(va => va.notes.toString().toLowerCase() == 'valid')

      this.excelDataArray = tempDataArray;
      if (this.copyfromScreen) {
        let temp1 = []
        for (let i = 0; i < this.AttributeTypes.length; i++) {
          temp1.push(this.AttributeTypes[i].Item)
        }
        let configuredAttributetype = [];
        configuredAttributetype = this.excelDataArray.filter(item => temp1.includes(item.attributetype))
        let invalidAttributetype = [];
        let temp = [];
        invalidAttributetype = this.excelDataArray.filter(item => !temp1.includes(item.attributetype))
        for (let i = 0; i < invalidAttributetype.length; i++) {
          temp.push(invalidAttributetype[i].attributetype)
        }
        let displayInvalidAttributetype = [...new Set(temp)];
        if (displayInvalidAttributetype.length > 0) {
          this.repeatedNames = '';
          let str = '';
          for (let i = 0; i < displayInvalidAttributetype.length; i++) {
            str += displayInvalidAttributetype[i] + ', '
          }
          if (configuredAttributetype.length > 0) {
            this.excelDataArray = configuredAttributetype;
          }
          this.repeatedNames = str.substring(0, str.length - 2);
          this.invalidAttributeTypeFlag = true;
        }
        else {
          if (configuredAttributetype.length > 0) {
            this.excelDataArray = configuredAttributetype;
          }
        }
      }
      if (context == 'copy' && sendjson.length <= 0) {
        this.invalidAttributeTypeFlag = false;
        this._snackbar.open('Please select valid rows', 'Close')
        return;
      }
      else {
        if (!this.invalidAttributeTypeFlag) {
          sendjson.length > 0 ? this.addGrossAttributes(sendjson, true, context ? context : '') : this._snackbar.open("Grossing Attributes(s) already exist!", "Close");
          //this.addGrossAttributes(sendjson, true, '')
        }
        else {
          this.invalidAttributeTypeFlag = true;
        }
        this.context = context;
      }
    }
  }
  //#endregion

  // warning model close
  closeAttributetypeModel() {
    this.invalidAttributeTypeFlag = false;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      this.addGrossAttributes(this.excelDataArray, true, '');
    }
  }
  //#region
  addGrossAttributes(dataArray, bulkUpload: boolean = false, context?: string, opt?: boolean) {
    if (!this.globalexist && this.context != 'copy') {
      for (let i = 0; i < dataArray.length; i++) {
        if (this.templateData.submenuData.some(va => va.Attribute_Name == dataArray[0].attributename && va.Attribute_Type == dataArray[0].attributetype && va.Attribute_ID != dataArray[0].attributeid)) {
          this._snackbar.open("Configuration is already exists", 'close');
          this.ngxService.stop();
          return;
        }
      }
    }
    this.ngxService.start();
    this.checkStatus = false;
    this.vitalHttpServices.bulkUploadGrossAttributes(dataArray).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (opt) {
          this.refreshGrid();
          this.showModal = false;
          dataArray.filter((e: any) => {
            result.filter(r => {
              if (r.AttributeName.toString() === e.attributename.toString()) {
                e.status = r.Status
                e.notes = r.Notes;
              }
            });
          });
          this.commonService.auditDetails('', '', [], dataArray, 'Migrate', this.templateData, this.auditableColumns);
          return;
        }
        if (result.length > 0) {
          this.showDelete = false;
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          let tempArr = []
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['status'] = result[j]['Status']
                this.excelDataArray[i]['sequenceorder'] = result[j]['SequenceOrder']
                this.excelDataArray[i]['attributename'] = result[j]['AttributeName']
                break;
              }
              else {
                if (this.excelDataArray[i]['notes'].toString().match(/already exists/i) || this.excelDataArray[i]['notes'].toString().match(/invalid/i) || this.excelDataArray[i]['notes'].toString().match(/mandatory/i) || this.excelDataArray[i]['notes'].toString().match(/type selected in the list page/i)) {
                  this.excelDataArray[i]['status'] = 'Ignored'
                }
              }
            }
          }
          for (let ele = 0; ele < result.length; ele++) {
            tempArr.push(result[ele]['attributeid']);
          }
          // dataArray.length==1?this.commonService.createActivityTracker('Created', tempArr, 'Grossing Attributes', 'Audit', dataArray, {}):
          // this.commonService.createActivityTracker('Created', tempArr, 'Grossing Attributes', 'Audit', this.excelDataArray, {})
          

          this.postUpload = true;
          bulkUpload ? this.AddExcelData(this.excelDataArray) : null;
          dataArray[0]['isactive'] = this.commonService.getStatus(dataArray[0]['isactive']);
          dataArray[0]['sequenceorder'] = result[0]['SequenceOrder'];
          this.commonService.auditDetails('attributeid', 'attributename', bulkUpload ? '' : result, bulkUpload ? this.excelDataArray : dataArray, bulkUpload ? context ? 'Copy' : 'Upload' : 'Create', this.templateData, this.auditableColumns);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            this._snackbar.open('Attribute with the same name already exists.', 'Close');
          }
          else {
            bulkUpload ? this._snackbar.open('Data uploaded successfully', 'Close') : this._snackbar.open('Grossing Attribute added successfully', 'Close');
            if (context != null && !bulkUpload) {
              this.globalIndicater = false;
              this.getGrossingAttribute(this.attrtype)
              this.attributeType.setValue(this.attrtype);
            }
            else {
              this.grossAttributeForm.reset();
              this.globalexist = false;
              let attrtype = this.attrtype == 'All' ? '' : this.attrtype
              this.grossAttributeForm.reset();
              this.grossAttributeForm.patchValue({
                frmAttrType: attrtype,
                frmAttrName: "",
                frmIsActive: "true",
                frmSeqOrder: ""
              })
              this.globalIndicater = false;
              Object.keys(this.grossAttributeForm.controls).forEach(key => {
                this.grossAttributeForm.controls[key].setErrors(null)
              });
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Grossing Attribute addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion
  //#region  for patching to the form to update
  updateGrossAttributes(row) {
    if (this.hideEditBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.ngxService.start();
    this.addEditScreen = true;
    this.showGrid = false;
    this.uploadClicked = false;
    let attribute: any
    this.action = 'Edit ' + row.dataItem.Attribute_Type;
    let data: any = row.dataItem.Attribute_Type.toLowerCase();
    let FirstcharAttributetype: any = data.toString().charAt(0).toUpperCase();
    let remaincharAttributetype: any = data.toString().slice(1).toLowerCase();
    this.attrID = row.dataItem.Attribute_ID;
    // row.dataItem.Attribute_Type = row.dataItem.Attribute_Type;
    attribute = FirstcharAttributetype + remaincharAttributetype;
    this.grossAttributeForm.patchValue({
      frmAttrType: attribute,
      frmAttrName: row.dataItem.Attribute_Name,
      frmIsActive: row.dataItem.Status == "Active" ? true : false,
      frmSeqOrder: row.dataItem.Sequence_Order
    })
    this.oldObject = {
      "attributetype": attribute,
      "attributename": row.dataItem.Attribute_Name,
      "attributeid": row.dataItem.Attribute_ID,
      "isactive": row.dataItem.Status == 'Active' ? true : false,
      "sequenceorder": row.dataItem.Sequence_Order
    }
    this.activityEntity.entityId = this.attrID;
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.stop();
  }
  //#endregion
  //#region Sending the data for validation
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.ValidateGrossAttrData(dataArray).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddExcelData(dataArray);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
    this.ngxService.stop();
  }
  //#endregion

  AddExcelData(data: object[]) {
    this.ngxService.start();
    this.gridDisplay = true;
    this.gridWidth = 0;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          delete data[i]['type'];
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {

              if (key.toString().toLowerCase().match(/attributetype/i)) {
                if (!this.attrtype.toString().toLowerCase().match(/all/i) && value.toLowerCase() != this.attrtype.toLowerCase()) {
                  primary["notes"] = "Upload only for the attribute type selected in the list page!"
                }
              }
              key == 'attributename' ? value = value == null ? "" : value : null
              if (value == null) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }

            }
            if ((!data[i]["attribute type"])) {
              value == null ? primary['notes'] = 'Mandatory field(s) missing!' : null

            }
            if (this.copyfromScreen) {
              if (key == 'notes' && value == null) {
                primary['notes'] = 'Valid';
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.gridData = new CollectionView(gridArray)
        if (this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null;
        }
        // this.gridData = new CollectionView(gridarray, )
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
        if (gridArray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
      }

    }
    this.ngxService.stop();
  }
  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["tablename"];
      delete e._data["slno"];
      excel.push(e._data);
    });
    let filename = 'Grossing Attributes_' + this.orgId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Grossing Attributes');
    XLSX.writeFile(wb, filename);
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region for indivisual add
  createGrossAttribute() {
    if (this.hideCreateBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.addEditScreen = true; // flag for enabling Add and edit screen
    this.uploadClicked = false;
    this.showGrid = false;
    this.showModal = false;
    //  if(this.globalexist){
    //   for(let i=0;i<this.globalData.length;i++){
    //     this.globalIndicater
    //   }
    //  }
    let attrtype = this.attrtype == 'All' ? '' : this.attrtype;
    this.action = 'Create Grossing Attribute'
    this.grossAttributeForm.reset();
    this.grossAttributeForm.patchValue({
      frmAttrType: attrtype,
      frmAttrName: '',
      frmIsActive: 'true',
      frmSeqOrder: ''
    })
  }
  //#endregion

  onChangeAttrType(value) {
    if (value.source._selected) {
      for (let i = 0; i < this.globalData.length; i++)
        if (value.source.value == this.globalData[i]['Attribute_Type']) {
          this.globalIndicater = true;
          return
        }
        else {
          this.globalIndicater = false;
        }
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  saveGrossAttributes(action: string, data: any) {
    this.attributeType.setValue('All');
    if ((data.frmAttrType == '') || (data.frmAttrType.trim() == '')) {
      this._snackbar.open("Please enter the required values", 'Close')
      return;
    }
    //     let index = a.findIndex(x => x.LastName === "Skeet");
    if (this.AttributeTypes.findIndex(x => x.Item == data.frmAttrType) === -1) {
      this._snackbar.open("Attribute type doesn't Match", 'Close')
      return;
    }

    let dataJson = [];
    let globalJson = {};
    let tempdataJson = {};
    if (this.globalexist == true && !this.action.match(/edit/i)) {
      for (let i = 0; i < this.globalData.length; i++) {
        if (data.frmAttrType == this.globalData[i].Attribute_Type) {
          globalJson = {
            "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
            "attributetype": this.globalData[i].Attribute_Type,
            "attributename": this.globalData[i].Attribute_Name,
            "attributecontext": this.caseType,
            "organizationid": sessionStorage.getItem("org_id"),
            "isactive": this.globalData[i].Status = 'Active' ? true : false,
            "sequenceorder": this.globalData[i].Sequence_Order,
            "attributeid": null
          }
          dataJson.push(globalJson)
        }
      }
    }
    tempdataJson = {
      "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
      "attributetype": data.frmAttrType,
      "attributename": data.frmAttrName,
      "attributecontext": this.caseType,
      "organizationid": sessionStorage.getItem("org_id"),
      "isactive": data.frmIsActive,
      "sequenceorder": data.frmSeqOrder == null ? "" : data.frmSeqOrder,
      "attributeid": this.attrID ? this.attrID : null
    }
    dataJson.push(tempdataJson)
    if (this.action.match(/edit/i)) {
      this.editGrossAttributes(dataJson);
    }
    else {
      this.excelDataArray = [];
      let context = action != 'new' ? "All" : null
      // this.globalexist=action!='new' ? false : null
      this.addGrossAttributes(dataJson, false, context);
      // this.grossAttributeForm.reset();
      //this.getInputData('', this.templateData.submenuData);

    }
  }
  editGrossAttributes(data) {
    this.ngxService.start();
    if (this.templateData.submenuData.some(va => va.Attribute_Name == data[0].attributename && va.Attribute_Type == data[0].attributetype && va.Attribute_ID != data[0].attributeid)) {
      this._snackbar.open("Attribute with the same name already exists.", 'close');
      this.ngxService.stop();
      return;
    }

    this.vitalHttpServices.updateGrossAttributes(data).toPromise().then(response => {
      this.ngxService.stop();
      if (response[0].Notes == "Updated Successfully") {
        this._snackbar.open("Grossing attribute updated successfully", "Close")
        // this.getGrossingAttribute("All");
        this.oldObject['isactive'] = this.commonService.getStatus(this.oldObject['isactive']);
        data[0]['isactive'] = this.commonService.getStatus(data[0]['isactive']);
        data[0]['sequenceorder'] = response[0]['SequenceOrder'];
        this.commonService.auditDetails('attributeid', 'attributename', [this.oldObject], data, 'Edit', this.templateData, this.auditableColumns);
        // this.commonService.createActivityTracker('Edited', [data[0].attributeid], 'Grossing Attributes', 'Audit', data[0], this.oldObject);
        this.grossAttributeForm.reset();
        this.getGrossingAttribute(this.attrtype)
        this.attributeType.setValue(this.attrtype);
      }
      else {
        this._snackbar.open("Grossing attribute update failed!", "Close")
      }
    }
    ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
    this.ngxService.stop();
  }
  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName == 'sequence') {
      if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }

  }
  //#region  copy from other entity
  copyFromOrg() {
    if (this.hideCopyBtn) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.showGrid = false;
    this.showModal = false;
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.copyfromScreen = true;
    this.searchResult = [];
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.destDeployment,
      frmOrganization: ''
    })
    this.organizationid = '';
    this.orgName = null;
    this.searchInput = '';
  }
  //#endregion

  onChangeDeployment(DeploymentKey) {
    if (DeploymentKey.source._selected) {
      this.srcDeployment = DeploymentKey.source.value;
      //this.VitalHttpServices.deploymentKey = DeploymentKey
      // sessionStorage.setItem('deploymentKey', DeploymentKey.toLowerCase());
      // this.AddGridData2([]);
      this.gridWidth = 0
      this.searchInput = '';
      this.searchUserInput = '';
      this.usertoggle = false;
      this.toggle = false;
      this.searchResult = [];
      this.copyFromOrgFrm.patchValue({ frmOrganization: '' })
      this.getListOrg();
    }
  }
  getListOrg() {
    let query = this.vitalHttpServices.GetQuery('adduserfilterorg');
    //let queryString = "organizationid !=\"" + sessionStorage.getItem('org_id') + "\""
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.OrganizationList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }
  fetchOrgSeries(value: string) {
    this.searchResult = []
    if (value === '') {
      return (this.searchResult = []);
    }
    this.toggle = false;
    let idValidate = /^[0-9]*$/

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.toggle = true
        this.searchResult = this.OrganizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.toggle = true;
      this.searchResult = this.OrganizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }
  selectedOrganization(orgname, orgid) {
    this.toggle = false;
    this.organizationid = orgid;
    this.orgName = orgname;
    this.searchInput = orgname;
    this.getDataToCopy(orgid, 0, this.dataToTakeAsInput)
    // this.getListUsers(data.organizationid);
  }

  getDataToCopy(orgid, userid, dataToTakeAsInput) {
    orgid == -1 ? this.copyFromOrgFrm.patchValue({ frmOrganization: '' }) : null
    this.ngxService.start();
    this.selectedItems = [];
    this.checkStatus = false;
    this.showMsg = false;
    let roleSel;
    let siteId = "";
    let dbName;
    roleSel = "";
    dbName = this.srcDeployment;
    let siteName = "";
    userid = 100;
    roleSel = this.attrtype == 'All' ? '' : this.attrtype;// selected attribute type is sending as the userid for getting selected attribute type data
    this.vitalHttpServices.getOrgDataForCopy(orgid, dataToTakeAsInput.CaseType, userid, dataToTakeAsInput.TableName, roleSel, siteId, siteName, dbName).subscribe(data => {
      this.ngxService.stop();
      if (data && data.length > 0) {
        this.getTableData = true;
        this.validateDataForCopy(data)

      } else {
        this.getTableData = false;
        this._snackbar.open('No data Found', 'Close')
      }
    }, error => {
      this.ngxService.stop()
      console.log(error)
    })
  }


  initializeGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedItems = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  //#regin to show notes
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field(s) missing!' || value == 'Null' || value.toString().includes('Upload only for the attribute type selected in the list page!') || value.toString().includes('Null values exist!') || value.toString().includes('Invalid Attribute Type') || value.toString().includes('Data already exists') || value.toString().includes('Failure') || (value.toString().includes('Attribute with the same name already exists.')) || value.toString().includes('Gross attributes not exists!')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('Created Successfully') || value == 'Success'
          || value.toString().includes('Collection Method will be added on approval') || value.toString().includes('Updated Successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  validateDataForCopy(tempExcelArr) {
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      delete tempExcelArr[i]['SequenceOrder'];
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (!value) {
            value = ''
          }
          else if (value.toString().match(/null/i)) {
            value = null;
          }
          if (key.toString().match(/isactive/i) || key.toString().match(/active/i)) {
            // key='IsActive'
            value = value ? value : value == '1' ? true : false;
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = Object.keys(tempExcelArr[0])
    temp.reverse()
    temp.push("Notes")
    this.sheetHeader = temp;
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let data: any = [];
    for (let i = 0; i < this.tempAttributeTypes.length; i++) {
      data = data + this.tempAttributeTypes[i] + ',';
    }
    data = data.substring(0, data.length - 1);
    // this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationattributes', organizationid: this.orgId.toString(), AttributeContext: this.cardType.toString(), Type: data });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  checkValue(event) {
    this.checkStatus = event.target.checked ? true : false;
  }

  getGLDataPopup() {
    if (this.hideCopyBtn) {
      this._snackbar.open("User is not Authorized", 'close');
      return;
    }
    this.showModal = true;
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '400px',
      data: { header: "Grossing Attributes", message: "Are you sure to copy the Default to Group level? \n Cannot rollback once it is done.", alert: "", continue: "Yes", cancel: "No" }
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          let dataJson = [];
          let globalJson = {};
          for (let i = 0; i < this.globalData.length; i++) {
            //let Attrtype = this.globalData[i].Attribute_Type == 'Dimensions' ? 'Dimension1' : this.globalData[i].Attribute_Type
            if (this.attrtype == this.globalData[i].Attribute_Type || this.attrtype.toLowerCase() == 'all') {
              let temp = this.templateData.submenuData.filter(va => va.Attribute_Level.toLowerCase() != 'group' && (va.Attribute_Name != this.globalData[i].Attribute_Name && va.Attribute_Type != this.globalData[i].Attribute_Type));
              if (temp) {
                globalJson = {
                  "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                  "attributetype": this.globalData[i].Attribute_Type,
                  "attributename": this.globalData[i].Attribute_Name,
                  "attributecontext": this.caseType,
                  "organizationid": sessionStorage.getItem("org_id"),
                  "isactive": this.globalData[i].Status = 'Active' ? true : false,
                  "sequenceorder": this.globalData[i].Sequence_Order,
                  "attributeid": null
                }
                dataJson.push(globalJson)
              }
            }
          }
          this.addGrossAttributes(dataJson, false, null, true);
        }
      })

  }

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }


}
