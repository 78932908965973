<div class="col-sm-12">
  <div *ngIf="templateData.submenuData && templateData.submenuData.length>0">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
          <div>Case Profiles</div>
        </strong>
      </h3>
    </div>
    <span *ngIf ="!enablecreate" class="label-font-size">
       <strong>Note :</strong>
       Manage case Profile configuration is disabled for this Group. If you wish to access the case profiles please enable the flag under configurations menu.
      </span>
    <wj-flex-grid class="mt-2" [headersVisibility]="'Column'" (initialized)="initGrid(grid)" [itemsSource]="gridData"
      (draggingRow)="onRowDragging(grid, $event)" (draggedRow)="onRowDragged(grid, $event)" [allowDragging]="'Both'"
      #grid>
      <wj-flex-grid-column [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="editCaseProfile(row)" [disabled]="hideEditBtn"><em id="editButton"
              [title]="'Action'" class="edit-template important-text p-0 fa fa-edit"> </em></button>
        </ng-template>
      </wj-flex-grid-column>

      <wj-flex-grid-column [binding]="'Sequence'" [isReadOnly]="true"> </wj-flex-grid-column>
      <!-- <wj-flex-grid-column  [header]="'Display name'" [binding]="'Display_name'"> </wj-flex-grid-column> -->
      <wj-flex-grid-column [header]="'Display name'" [binding]="'Display_name'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
          <div class="divwordwrap" style="word-break: break-word;">
            {{cell.item.Display_name}}
          </div>
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
          <div class="tadiv">
            <input class="tadiv" maxlength="125" [disabled]="hideEditBtn" [(ngModel)]="cell.value" />
          </div>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Case type'" [binding]="'Case_type'" [isReadOnly]="true"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Order code'" [isReadOnly]="true" [binding]="'Order_code'"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Extraction procedure'" [binding]="'Extraction_procedure'" [isReadOnly]="true">
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Services'" [isReadOnly]="true" [binding]="'Services'"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'No of jars'" [binding]="'No_of_jars'" [isReadOnly]="true"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'case id'" [isReadOnly]="true" [binding]="'caseid'" [visible]="!('caseid')">
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'ICD10'" [binding]="'ICD10'" [isReadOnly]="true"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Bill to'" [isReadOnly]="true" [binding]="'Bill_To'"> </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Modified date'" [binding]="'Modified_date'" [isReadOnly]="true">
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Modified by'" [isReadOnly]="true" [binding]="'Modified_by'">
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'locationid'" [binding]="'accountid'" [isReadOnly]="true" [visible]="!('caseid')">
      </wj-flex-grid-column>
      <wj-flex-grid-column [header]="'CaseProfileId'" [isReadOnly]="true" [binding]="'CaseProfileId'"
        [visible]="!('caseid')"> </wj-flex-grid-column>
      <!-- <div *ngFor="let columnname of columns">
      <wj-flex-grid-column [binding]="columnname.binding" [header]="columnname.header.split('_').join(' ')"
        [visible]="!(columnname.header == 'Location ID')&&!(columnname.header == 'Case id') &&!(columnname.header == 'CaseProfileId')"
        [allowDragging]="false" [allowSorting]="false" [width]="170" [allowResizing]="true" [format]="'d'"[isReadOnly]="true">
      </wj-flex-grid-column>
    </div> -->
      <wj-flex-grid-column [header]="'Active'" [isReadOnly]="hideEditBtn" [disabled]="hideEditBtn" [binding]="'IsActive'">


      </wj-flex-grid-column>
     

      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <!-- <wj-collection-view-navigator
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator> -->

    <div class="align-center mt-4">
      <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideExportBtn" (click)="exportCaseProfile(tempExport)">
        Export
      </button>
      <!-- Create new report -->
      <button mat-raised-button class="admin-btn-success" [disabled]="hideCreateBtn" (click)="createCaseProfile()">Create</button>
    </div>
  </div>
  <div *ngIf="!templateData.submenuData" class="col-sm-12">
    <button mat-raised-button class="admin-btn-success mt-4" [disabled]="hideCreateBtn" (click)="createCaseProfile()">Create</button>
    <div class="nodata-wrap mt-4">
      <div class="nodata-design">No Data Available</div>
    </div>
  </div>
</div>
