import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LabadminService {

  sourceApp: string = "";
  menuList = []
  public baseUrl2 = environment.baseUrl2;
  selectedSubmenu = {}
  rbacPermissions = {}
  subMenuPermissions = {};
  organizationId: string = '';
  accountId: string = '';
  deploymentKey: string = '';
  templateData: any = {};
  detailsData: any={};
  subSideMenu=[]
  hidCMSCATURl1 = environment.hidCMSCATURl1;
 // public baseUrl2 = environment.baseUrl2;
  hidCMSCATCSKTKN = environment.hidCMSCATCSKTKN;
  formattedDisplayName: string = '';

  constructor(public httpClient: HttpClient,) { }

  getUserRoles(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/getuserinformation`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  getContentMgmtData(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/masterdata/getcontentmanagementdata`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  // lab user card list
  getLabUserDetails(orgId, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/GetLabUserDetails`, orgId, { params: { 'Conn': dbName } });
  }

  // client user card list
  getClientUserDetails(orgId, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/GetClientsDetails`, orgId, { params: { 'Conn': dbName } });
    }

  getPdfFile(PDFid) {
    return this.httpClient.get<any>(
      `${this.hidCMSCATURl1}/assets/${PDFid.id}?access_token=${this.hidCMSCATCSKTKN}`
    );
  }
  getProfileInformation(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/getprofileinformation`,
      obj, { params: { 'Conn': dbName } }
    );
  }

  getProfileInformationMasterData(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/masterdata/profilemasterdata`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  getSecurityQuestionsList(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/masterdata/getorganizationattributes`,
      obj, { params: { 'Conn': dbName } }
    );
  }

  getSecurityQuestions(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/getcustomsecurityquestions`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  saveSecurityQuestions(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/savesecurityquestions`,
      obj, { params: { 'Conn': dbName } }
    );
  }

  saveProfileInfo(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/saveprofileinformation`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  changePassword(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/passwordchange`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  sendMigrateInvitation(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/updateisemailIdverified`,
      obj, { params: { 'Conn': dbName } }
    );
  }
  verify(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/requestmailtousers`,
      obj, { params: { 'Conn': dbName } }
    );
  }

  getDepartments(dbName){
    return this.httpClient.post<any>( `${this.baseUrl2}api/labadmin/OrganizationDepartments`,{params:{'Conn':dbName}})
 }


  // UpdateLockUnlock  lab user
  UpdateLockUnlockUser(commonkey, status, dbName) {
    let userDetails = {
              commonkey: commonkey,
              isAccountLockedOut: status
        }
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labadmin/UpdateLockUnlockUser`, userDetails, { params: { 'Conn': dbName } });
  }

  GetIsMedicalDirectorExists(OrganizationId, UserId, dbName) {
    return this.httpClient.get<string>(
      `${this.baseUrl2}api/user/IsMedicalDirectorExists?OrganizationId=` +
      OrganizationId + '&iUserId=' + UserId,  { params: { 'Conn': dbName } });
  }

  getRoles(orgID: any, Lab: any,dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Roles?orgID=` + orgID + '&forLab=' + Lab,{ params: { 'Conn': dbName } });
  };

  getValueFromLookup(contextID: any, lookuptype: any, dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/States?contextID=`
    +contextID+'&lookuptype='+lookuptype,{ params: { 'Conn': dbName } });
  };

  getStateAndCity(zipCode: string, dbName: string)
  {
       return this.httpClient.get<any>(`${this.baseUrl2}api/GetCityAndStateFormZipCode?ZipCode=`+zipCode, { params: { 'Conn': dbName } });
  }

  getSharedSurgiCenterForOF(accountID: any, labaccountID: any, oper: any, dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/SharedSurgiCenterForOF?accountID=${accountID}&labaccountID=${labaccountID}&oper=${oper}`,{ params: { 'Conn': dbName } })
  }

  npiValidate(organizationid : number, APIEndPoint : string, searchParms : { City:string, FirstName:string, LastName:string, NPI:string, OrganizationName:string, State:string, Taxonomy:string, Zip:string }, dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/NPIDetailsForValidate/${organizationid}?APIEndPoint=${APIEndPoint}`, searchParms, { params: { 'Conn': dbName } })
  }

  getSalesExecutiveAndCourierData(orgId: number, ofAccId: number, labAccountIDList: string, dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/SalesExecutiveUsers?labAccountIDList=${labAccountIDList}&oFAccID=${ofAccId}&organizationID=${orgId}`,{ params: { 'Conn': dbName } })
  }

  getAttachmentsAndNotes(userId : string, accountid : string, loggedInUserId : string, oper : number, dbName: string){
    const url=`${this.baseUrl2}api/attachments/all/${userId}?accountId=${accountid}&loggedInUserId=${loggedInUserId}&oper=${oper}`
    return this.httpClient.get<any>(url,{ params: { 'Conn': dbName }})
  }

  uploadSuplements(uploadedAttachment : any, dbName: string){
    const url = `${this.baseUrl2}api/attachments/upload`
    return this.httpClient.post(url, uploadedAttachment,{ params: { 'Conn': dbName }})
  }

  ViewSupplements(fileId : string, dbName: string){
    const url=`${this.baseUrl2}api/attachments/view?fileId=${fileId}`
    return this.httpClient.get(url,{params: { 'Conn': dbName }, responseType: 'blob'})
  }

  deleteSupplements(fileId : string, caseId : string | null, dbName: string){
    const url = `${this.baseUrl2}api/attachments/Delete/${fileId}?caseId=${caseId}`;
    return this.httpClient.post(url, fileId, { params: { 'Conn': dbName }})
  }

  saveAndUpdateAccount(data: any, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/SaveLocation`, data,{ params: { 'Conn': dbName } })
  }
  saveAndUpdateNewPractice(data: any, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/Practice`, data,{ params: { 'Conn': dbName } })
  }

  isExists( strName :string, iOper : number,  id :number,  orgid : string = '', dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/IsExists?strName=${strName}&iOper=${iOper}&id=${id}&orgid=${orgid}`,{ params: { 'Conn': dbName } })
  }
  organizationDetails( orgId : number, dbName: string){
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/OrganizationDetails?orgID=${orgId}`,{ params: { 'Conn': dbName } })
  }

  GetVARulesSearchDetails(ruleName:string, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/VARulesDetails?SearchValue=${ruleName}`,{ params: { 'Conn': dbName } })
  }

  InsertVARulesExclusions(ModiftId : number,data : any,dbName: string)
  {
    const url = `${this.baseUrl2}api/InsertVARulesExclusions?ModifiedBy=${ModiftId}`
    return this.httpClient.post<any>(url,(data),{ params: { 'Conn': dbName } });
  }

  ActivateDeactivateVARules(ruleID : number, status : number,ModifiedBy : number, dbName: string)
  {
    const url=`${this.baseUrl2}api/ActivateDeactivateVARules?ruleID=${ruleID}&status=${status}&ModifiedBy=${ModifiedBy}`;
    return this.httpClient.post(url,null,{ params: { 'Conn': dbName } });
  }

  physicianDataForFaxandPrinting(accountId:number,userId:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/OrderingPhysician?OFActID=${accountId}&userID=${userId}`,{ params: { 'Conn': dbName } })
  }
  createRuleId(objVARule:any,dbName: string)
  {
    const url = `${this.baseUrl2}api/InsertVARulesDetails`
    return this.httpClient.post<any>(url,(objVARule),{ params: { 'Conn': dbName } });
  }
  InsertVaIntRecipient(vaIntRecipientnDTO :any,dbName: string)
  {
    const url = `${this.baseUrl2}api/InsertVaIntRecipient`
    return this.httpClient.post<any>(url,(vaIntRecipientnDTO),{ params: { 'Conn': dbName } });
  }

  GetIsPcEnabled(loggedinuseraaccountid: string, accountid: string, OrganizationID: number, dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/IsPcEnabled?loggedinuseraaccountid=${loggedinuseraaccountid}&accountid=${accountid}&OrganizationID=${OrganizationID}`, { params: { 'Conn': dbName } })
  }
  SaveCustomerAttribute(data:any, dbName: string)
  {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/CustomAttributes`, data,{ params: { 'Conn': dbName } })
  }
  DeleteVaRulesExclusions(ruleID:number, dbName: string)
  {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/DeleteVaRulesExclusions?ruleID=${ruleID}`, ruleID,{ params: { 'Conn': dbName } })
  }

  getLabLocations(LabGroupID,userid,AccountId, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/LabLocations?LabGroupID=${LabGroupID}&userid=${userid}&AccountId=${AccountId}`,{ params: { 'Conn': dbName } })

  }
  getCaseCategoryByAccId(accountId,orgId,labAccountIds, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/CaseTypesbyActId?actID=${accountId}&orgID=${orgId}&labActIDs=${labAccountIds}`,{ params: { 'Conn': dbName } })
  }
  getLabLocationsCasetype(LabGroupID:string,userid:string,AccountId:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/LabLocationsCasetype?LabGroupID=${LabGroupID}&userid=${userid}&AccountId=${AccountId}`,{ params: { 'Conn': dbName }})
  }
  getLocationDetails(accountId:number,userOrgId:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/GetAccount?accountID=${accountId}&UserOrgID=${userOrgId}`,{ params: { 'Conn': dbName } })
  }
  getPhysicinData(accountID:number,userID:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/PhysicianData?accountID=${accountID}&userID=${userID}`,{ params: { 'Conn': dbName } })
  }
  CustomOrgValues(LabOrgID:number,Orgid:number,entityId:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/CustomOrgValues?LabOrgID=${LabOrgID}&EntityID=${Orgid}&EntityType=${entityId}`,{ params: { 'Conn': dbName } })
  }
  GetServices( accountId:number,iOper :number, UserId:number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Services?Id=${accountId}&iOper=${iOper}&UserId=${UserId}`,{ params: { 'Conn': dbName } })
  }

  getOFAssociationForSE(accountIds : string, salesExecutiveUserId : number, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/OFAssociationForSE?accountIds=${accountIds}&SalesExecutiveUserId=${salesExecutiveUserId}`,{ params: { 'Conn': dbName } })
  }

  getPathList(OrganizationID: string, ExecutiveUserId: string, iOFAccountID: string, loggedInUserid: string, Type: string, dbName: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}/api/users/AssociationsByRole?OrganizationID=${OrganizationID}&ExecutiveUserId=${ExecutiveUserId}&iOFAccountID=${iOFAccountID}&loggedInUserid=${loggedInUserid}&Type=${Type}`, { params: { 'Conn': dbName } })
  }

  generatePassword(loginId:string,modifiedby:string,dbName:string){
    return this.httpClient.get<string>(
      `${this.baseUrl2}api/user/resetpassword?loginid=` + loginId + '&ModifiedBy=' + modifiedby,
      { params: { 'Conn': dbName } }
    );
  }

  migrateUser(requestData:any, dbName:string){
    return this.httpClient.post<any>(`${this.baseUrl2}api/user/UserMigration`, requestData, { params: { 'Conn': dbName } });
  }

  // to send a password link
  sendResetPwdLink(data) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/user/resetpasswordlink`, data)
  }

  getUserProfile(userId: string,dbName){
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/Users?userid=${userId}`, { params: { 'Conn': dbName } })
  }

  getsaveRoles(userId: string,dbName){
    return this.httpClient.get<any>(`${this.baseUrl2}api/SavedRoles?userID=${userId}`, { params: { 'Conn': dbName } })
  }

  getOrgDetails(orgId: string, dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/OrganizationDetails?orgID=${orgId}`, { params: { 'Conn': dbName } })
  }

  getAddSettings(labOrgId: string,accountid:string,entitytype:string, dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/CustomOrgValues?LabOrgID=${labOrgId}&EntityID=${accountid}&EntityType=${entitytype}`, { params: { 'Conn': dbName } })
  }
  getGlobalSearchDetails(searchData:any,dbName : string){
    return this.httpClient.post<any>(`${this.baseUrl2}api/labadmin/GlobalSearchResults`, searchData,{ params: { 'Conn': dbName } })
  }

  getAssociationsByRole(OrganizationID:string,ExecutiveUserId:number=0,iOFAccountID:string,loggedInUserid:string,Type:string='pathologist',dbName:string="LIS")
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/AssociationsByRole?OrganizationID=${OrganizationID}&ExecutiveUserId=${ExecutiveUserId}&iOFAccountID=${iOFAccountID}&loggedInUserid=${loggedInUserid}&Type=${Type}`,{ params: { 'Conn': dbName } })
  }
  SaveUsers( userData: any, accountList :string,  pathReadingLocation :number, LoggedInUserOrgID :string,  OFsSelectedForAssociation :string, AssPathologistUserIdsSelected :string,  AssPhysicianUserIdsSelected :string,  EnableUserAssociation :string, EnableProviderMDM : string,dbName:string)
  {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/users/SaveUsers?accountList=${accountList}&pathReadingLocation=${pathReadingLocation}&LoggedInUserOrgID=${LoggedInUserOrgID}&OFsSelectedForAssociation=${OFsSelectedForAssociation}&AssPhysicianUserIdsSelected=${AssPhysicianUserIdsSelected}&AssPathologistUserIdsSelected=${AssPathologistUserIdsSelected}&EnableUserAssociation=${EnableUserAssociation}&EnableProviderMDM=${EnableProviderMDM}`,userData,{ params: { 'Conn': dbName } })
  }
  GetUserDetails( userid:number,dbName:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/Users?userid=${userid}`,{ params: { 'Conn': dbName } })
  }
  GetsavedRoleList( userID :number,dbName:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/SavedRoles?userID=${userID}`,{ params: { 'Conn': dbName } })
  }
   GetLocationList( OrganizationID :number,  loggedInUserID :number,  userID:number,  type:string,  _UserID:number = 0,dbName:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/LocationList?OrganizationID=${OrganizationID}&loggedInUserID=${loggedInUserID}&userID=${userID}&type=${type}&_UserID=${_UserID}`,{ params: { 'Conn': dbName } })
  }

  GetVAruleExclusionDetails(ruleID:number,dbName:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/VaruleExclusionDetails?ruleID=${ruleID}`,{ params: { 'Conn': dbName } })
  }
  GetVAExtAttrValue( EntityID :string,  ExtAttrName :string ,  ExtAttrType :string, dbName: string)
  {
    return this.httpClient.get(`${this.baseUrl2}api/VAExtAttr?EntityID=${EntityID}&ExtAttrName=${ExtAttrName}&ExtAttrType=${ExtAttrType}`,{ params: { 'Conn': dbName },responseType: 'text' })
  }

// npi tab api integration
  npiDetails(dbName: string, LoggedInUserOrgID: string,data:any) {
    return this.httpClient.post<any>(`${this.baseUrl2}/api/NPIDetailsForValidate/${LoggedInUserOrgID}/?APIEndPoint=SearchUser`,data, { params: { 'Conn': dbName } })
  }

  locationNpiDetails(dbName: string, LoggedInUserOrgID: string,data:any){
    return this.httpClient.post<any>(`${this.baseUrl2}/api/NPIDetailsForValidate/${LoggedInUserOrgID}/?APIEndPoint=SearchLocation`,data, { params: { 'Conn': dbName } })
  }

  GetSampleTemplates(type : 'users' | 'labusers', dbName : string){
    return  this.httpClient.get(`${this.baseUrl2}api/bulkupload/templates/${type}`, {params: { 'Conn': dbName }, responseType: 'blob'})
  }

  ValidateUploadedData(type : 'users' | 'labusers', loggedInUserOrgId : string, file : any, dbName : string){
    return  this.httpClient.post<any>(`${this.baseUrl2}api/bulkupload/validate/${type}?organizationId=${loggedInUserOrgId}`, file, {params: { 'Conn': dbName }})
  }

  saveUploadedUsers(type : 'users' | 'labusers', uplodedData : any, dbName : string){
    return  this.httpClient.post<any>(`${this.baseUrl2}api/bulkupload/upload/${type}`, uplodedData, {params: { 'Conn': dbName }})
  }

  GetSharedSurgiAccountsData( userID:string, loggedInUserID:string ,dbName:string)
  {
      return this.httpClient.get<any>(`${this.baseUrl2}api/users/SharedSurgiForPhysician?userID=${userID}&loggedInUserID=${loggedInUserID}`,{ params: { 'Conn': dbName } })
  }
  GetAccountsByUserid(iUserID : number,dbName:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/AccountsByUserid?iUserID=${iUserID}`,{ params: { 'Conn': dbName } })
  }

  UpdateAccountStatus(accountid:number,status:string,dbName:string){
    return this.httpClient.get<any>(`${this.baseUrl2}api/Location/updateaccountstatus?accountId=${accountid}&status=${status}`,{ params: { 'Conn': dbName } });
  }
  FaxandPrinting(obj, dbName)
  {
    return this.httpClient.post<any>(`${this.baseUrl2}api/getfaxdetails/faxdetails`, obj, {params: { 'Conn': dbName }});
  }

  isPathologistPrivilagePresent(OrganizationId: string, UserId: string, dbName: string){
    return  this.httpClient.get<any>(`${this.baseUrl2}api/isPathPrivilageExists?OrganizationId=${OrganizationId}&UserId=${UserId}`, {params: { 'Conn': dbName }})
  }

  ActiveInActiveDeleteUsers( userID :string,vFrom:string,  Status:string,accountId:string,dbName:string)
  {
    return  this.httpClient.post<any>(`${this.baseUrl2}api/users/ActiveInActiveUsers?userID=${userID}&vFrom=${vFrom}&Status=${Status}&accountId=${accountId}`,'',{params: { 'Conn': dbName }})
  }

  getEncrptectURL(locationId:string,physicianId:string,patientPortalUrl:string, dbName: string)
  {
    return  this.httpClient.get(`${this.baseUrl2}api/Location/GetEncriptedURL?PhysicianId=${physicianId}&LocationId=${locationId}&PatientPortalURL=${patientPortalUrl}`, {params: { 'Conn': dbName },responseType:'text' as const})

  }
  getIsSharedSurgicenter(OrganizationId : string, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/users/IsSharedSurgicenter??OrganizationID=${OrganizationId}`,{ params: { 'Conn': dbName } })
  }
  
  isExistsWithValue( strName :string, iOper : number,  id :number,  orgid : string = '', dbName: string) {
    return this.httpClient.get(`${this.baseUrl2}api/Location/IsExistsInDBWithValue?strName=${strName}&iOper=${iOper}&id=${id}&orgid=${orgid}`,{ params: { 'Conn': dbName },responseType: 'text'})
  }
}
