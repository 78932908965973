<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="!checkInfoPreference() && !noDataFoundFlag"
    [diameter]="50">
  </mat-spinner>

  <div class="preference_contianer" *ngIf="checkInfoPreference() && !noDataFoundFlag">
    <!-- // show only for Pathologist,  Cytotechnologist Supervisor & Cytotechnologist role -->
    <div *ngIf="isRoleExists(userRoles)">
      <div class="mb-3">
        <span class="label_border _600">Threshold</span>
        <div class="thresold_table">
          <div class="thresold_header row mx-0">
            <div class="col-sm-2">Work</div>
            <div class="col-sm-2">Threshold</div>
            <div class="col-sm-4">Duration</div>
            <div class="col-sm-4">Compute on</div>
          </div>
          <div class="thresold_body px-2 py-3">
            <div class="row mb-2">
              <div class="col-sm-2 _600 ">Reading</div>
              <div class="col-sm-2">
                <span class="col-sm-12 value-box text-sm p-1 d-flex align-center"> {{userDetails['Threshold']}}</span>
              </div>
              <div class="col-sm-4">
                Per <span class="value-box px-2 text-sm" style="width: 54%;">
                  {{userDetails['ThresholdDuration']}}</span>
              </div>
              <div class="col-sm-4">
                <span class="value-box px-2 text-sm" style="width: 68%;"> {{userDetails['ThresholdComputeOn']}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-2 _600 ">Screening</div>
              <div class="col-sm-2">
                <span class="col-sm-12 value-box text-sm p-1 d-flex align-center">
                  {{userDetails['ScreenThreshold']}}</span>
              </div>
              <div class="col-sm-4">
                Per <span class="value-box px-2 " style="width: 54%;"> {{userDetails['ScreenThresholdDuration']}}</span>
              </div>
              <div class="col-sm-4">
                <span class="value-box px-2 " style="width: 68%;"> {{userDetails['ScreenComputeOn']}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-2 _600 ">Reviewing</div>
              <div class="col-sm-2">
                <span class="col-sm-12 value-box text-sm p-1 d-flex align-center">
                  {{userDetails['ReviewThreshold']}}</span>
              </div>
              <div class="col-sm-4">
                Per <span class="value-box px-2 " style="width: 54%;"> {{userDetails['ReviewThresholdDuration']}}</span>
              </div>
              <div class="col-sm-4">
                <span class="value-box px-2 " style="width: 66%;"> {{userDetails['ReviewComputeOn']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <span class="label_border _600">Licensure</span>
          <div class="thresold_table w-100">
            <div class="thresold_header row mx-0">
              <div class="col-sm-6">States</div>
              <div class="col-sm-6">Expiration Date</div>
            </div>
            <div class="thresold_body pt-2 px-2">
              <div *ngIf="LicensureList.length>0">
                <div class="row px-3 mb-2" *ngFor="let item of LicensureList">
                  <div class=" col-sm-6 pl-1">
                    <span class="value-box px-3 text-sm">{{item.Statefullname}}</span>
                  </div>
                  <div class="col-sm-6">
                    <span class="value-box px-3 text-sm"> {{item.Expirydate}}</span>
                  </div>
                </div>
              </div>

              <div *ngIf="LicensureList.length ==0" class="align-center no-data-founds">
                No Data Found
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <span class="label_border _600">Out of Office</span>
          <div class="thresold_table w-100">
            <div class="thresold_header row mx-0">
              <div class="col-sm-6">Start Date</div>
              <div class="col-sm-6">End Date</div>
            </div>

            <div class="thresold_body pt-2 px-2">
              <div *ngIf="outOfoffice.length>0">
                <div class="row px-3 mb-2" *ngFor="let item of outOfoffice">
                  <div class=" col-sm-6 pl-1">
                    <span class="value-box px-3 text-sm">{{item.StartDate}}</span>
                  </div>
                  <div class="col-sm-6">
                    <span class="value-box px-3 text-sm"> {{item.EndDate}}</span>
                  </div>

                </div>

              </div>
            </div>

            <div *ngIf="outOfoffice.length ==0" class="align-center no-data-founds">
              No Data Found
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 text-sm">
      <span class="label_border _600 mt-2" *ngIf="userRoles?.length > 0">Settings</span>
      <div *ngFor="let item of userRoles">
        <div class="d-flex justify-content-between mb-2" *ngIf="item.Role === 'Pathologist'">
          <span>Show my Cases only</span>
          <app-toggle-switch isDisable=true [isChecked]=' item.ShowMyCaseOnly==null ?false : item.ShowMyCaseOnly'
            class="ml-auto mr-4">
          </app-toggle-switch>
        </div>

        <div class="d-flex justify-content-between mb-2" *ngIf="item.Role === 'Pathologist'">
          <span>Allow to Edit Accession, Site, Grossing, and Processing information </span>
          <app-toggle-switch isDisable=true [isChecked]=' item.ModifyTechnicalData==null ?false : item.ModifyTechnicalData && 
          item.IsModifyTechnicalDataAccession==null ?false : item.IsModifyTechnicalDataAccession' class="ml-auto mr-4">
          </app-toggle-switch>
        </div>

        <div class="d-flex justify-content-between mb-2" *ngIf="item.Role === 'Pathologist'">
          <span>Allow Review Pathologist to Sign-out Review Cases </span>
          <app-toggle-switch isDisable=true
            [isChecked]=' item.AllowReviewPathCanSignOut==null ?false : item.AllowReviewPathCanSignOut'
            class="ml-auto mr-4"></app-toggle-switch>
        </div>

        <div class="d-flex justify-content-between mb-2"
          *ngIf="item.Role === 'Pathologist' && 'Cytotechnologist' && 'Cytotechnologist Supervisor'">
          <span>Notify users to provide Diagnostic ICD Codes </span>
          <app-toggle-switch isDisable=true
            [isChecked]=' item.DiagICDcodeReminder==null ?false : item.DiagICDcodeReminder' class="ml-auto mr-4">
          </app-toggle-switch>
        </div>

        <div class="d-flex justify-content-between mb-2"
          *ngIf="item.Role === 'Pathologist' && 'Cytotechnologist' && 'Cytotechnologist Supervisor'">
          <span>Show Interpretation as Tooltip in Diagnosis Drop-Down </span>
          <app-toggle-switch isDisable=true [isChecked]=' userDetails.ShowInterpretationHelp' class="ml-auto mr-4">
          </app-toggle-switch>
        </div>

        <div class="d-flex justify-content-between mb-2" *ngIf="item.Role === 'Lab Administrator'">
          <span>Manage Pathologist Privileges </span>
          <app-toggle-switch isDisable=true
            [isChecked]=' item.AllowAdminAsPathologistPrivileges==null ?false : item.AllowAdminAsPathologistPrivileges'
            class="ml-auto mr-4"></app-toggle-switch>
        </div>

        <div class="col-sm-12 p-0 d-flex justify-content-between mb-2" *ngIf="item.Role === 'Lab Manager'">
          <span class="col-sm-6 p-0">Hold Release Duration</span>
          <span class="col-sm-6 button-wrap">
            <input class="col-sm-2 input-wrpper button-wrap mr-2" type="text" id="holdrelease" name="holdrelease"
              value="{{item.HoldReleas==null ?false : item.HoldReleas}}" readonly>
            <div class="col-sm-2 button-wrap" *ngIf="item.HoldReleas > 0">minutes</div>
          </span>
        </div>

        <!-- <div class="d-flex justify-content-between mb-2"
          *ngIf="item.Role === 'Pathologist' && !item.AllowAdminAsPathologistPrivileges">
          <span>Medical Director</span>
          <app-toggle-switch isDisable = true
            [isChecked]=' item.AllowPathAsMedicalDirector==null ?false : item.AllowPathAsMedicalDirector'
            class="ml-auto mr-4"></app-toggle-switch>
        </div> -->

      </div>
    </div>
  </div>

  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
    <span class="col-sm-12 nodata-wrapper">No Data Found</span>
  </div>
</div>