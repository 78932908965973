<div class="modal-content">
    <div class="modal-header admin-model-header ">
        <h3 class="modal-title w-100 admin-model-header-txt">Manage Recipients of Email Reports</h3>
        <span type="button" data-dismiss="modal" (click)="closeRecipient()" class="mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                title="Close">
        </span>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12 mt-2">
                <mat-tab-group class="mat-wrap">
                    <mat-tab *ngFor="let tab of tabs" [label]="tab">
                        <div class="mt-2 modal-wrap" *ngIf="tab==='Edit'">
                            <div class="row">
                                <div class="col-sm-6">
                                    <form [formGroup]="recipientData" class="form-wrap">
                                        <div class="row text-center">
                                            <div class="col-sm-3">
                                                <label for="DKeys"><strong>Job Name </strong></label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input [(ngModel)]="queryvalue" type="text" class="form-control p-2"
                                                    placeholder="Search by Job Name"
                                                    formControlName="recipientJobName" title="{{queryvalue}}">
                                                <em *ngIf="queryvalue !== null && queryvalue !== ''"
                                                    (click)="clearSearch()"
                                                    class="fa fa-close searchbar-cancel"></em>
                                                <button class="search-btn"
                                                    (click)="searchByJobName(recipientData.value.recipientJobName)"
                                                    id="btnSearchVDId">
                                                    <em class="fa fa-search"></em>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div *ngIf="showGrid" class="row mt-3">
                                        <!-- [visible]="!(columnname.header == 'Job ID')" -->
                                        <div class="d-block m-auto">
                                            <wj-flex-grid [isReadOnly]="true"
                                                [headersVisibility]="'Column'" [itemsSource]="gridData" #grid
                                                (click)="tableGrid(gridData)">
                                                <div *ngFor="let columnname of columns">
                                                    <wj-flex-grid-column [binding]="columnname.binding"
                                                        [header]="columnname.header.split('_').join(' ')"
                                                        [width]="250" [allowResizing]="true"></wj-flex-grid-column>
                                                </div>
                                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                                            </wj-flex-grid>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <form [formGroup]="recipientData" class="mt-3">
                                        <div *ngIf="showTableGrid" class="row px-5">
                                            <div class="col-sm-6">
                                                <div class="row mt-2">
                                                    <div class="col-sm-3 mt-2">
                                                        <label for="DKeys"><strong>To </strong>
                                                            <p class="error-msg">*</p>
                                                        </label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #editChipList>
                                                            <mat-chip-row name="chips"
                                                                [color]="editToMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let editToMail of editToMailData let i=index"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="editRemoveEmailto(editToMail)" required>
                                                                {{editToMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #editToMailInput 
                                                                matInput
                                                                [formControl]="edittoMailCtrl"                                                                
                                                                [matAutocomplete]="toAuto"
                                                                [matChipInputFor]="editChipList"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="editEmailto($event)" />

                                                        </mat-chip-grid>
                                                        <mat-autocomplete #toAuto="matAutocomplete"
                                                            (optionSelected)="selectedEditmailto($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailTo of filteredToMail | async"
                                                                [value]="mailTo">
                                                                {{mailTo}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                           
                                                    <mat-error *ngIf="editToEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3 mt-2">
                                                        <label for="DKeys"><strong>Cc </strong></label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #editChipList1>
                                                            <mat-chip-row name="chipsCc"
                                                                [color]="editCcMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let editCcMail of editCcMailData let i=index"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="editRemoveEmailcc(editCcMail)" required>
                                                                {{editCcMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #editCcMailInput [formControl]="editccMailCtrl"
                                                                matInput
                                                                [matAutocomplete]="ccAuto"
                                                                [matChipInputFor]="editChipList1"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="editEmailcc($event)" />
                                                        </mat-chip-grid>
                                                        <mat-autocomplete #ccAuto="matAutocomplete"
                                                            (optionSelected)="selectedEditmailcc($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailCc of filteredCcMail | async"
                                                                [value]="mailCc">
                                                                {{mailCc}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="editCcEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3 mt-2">
                                                        <label for="DKeys"><strong>Bcc </strong></label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #editChipList2>
                                                            <mat-chip-row name="chipsBcc"
                                                                [color]="editBccMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let editBccMail of editBccMailData"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="editRemoveEmailbcc(editBccMail)"
                                                                required>
                                                                {{editBccMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #editBccMailInput [formControl]="editbccMailCtrl"
                                                                matInput
                                                                [matAutocomplete]="bccAuto"
                                                                [matChipInputFor]="editChipList2"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="editEmailbcc($event)" />
                                                        </mat-chip-grid>
                                                        <mat-autocomplete #bccAuto="matAutocomplete"
                                                            (optionSelected)="selectedEditmailbcc($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailBcc of filteredBccMail | async"
                                                                [value]="mailBcc">
                                                                {{mailBcc}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="editBccEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Subject </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientEmailSubject" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Body </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <textarea formControlName="recipientEmailBody" type="text"
                                                            class="form-control p-2"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Status </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <!-- <form [formGroup]="emailStatus" ngNativeValidate> -->
                                                        <mat-slide-toggle class="mt-auto"
                                                            formControlName="recipientEmailStatus">
                                                            <p class="toggle-wrap">
                                                                {{recipientData.value.recipientEmailStatus == true
                                                                ?'Active':'Inactive'}}</p>
                                                        </mat-slide-toggle>
                                                        <!-- </form> -->
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Job Name </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientReportName" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>File Name </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientFileName" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Protect File </strong></label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="radio" value="true"
                                                            formControlName="recipientProtectFile" class="mr-2"
                                                            (click)="clickProtect(false)">Yes
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="radio" value="false"
                                                            formControlName="recipientProtectFile" class="mr-2"
                                                            (click)="clickProtect(true)">No
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Report Type </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <select class="form-control"
                                                            formControlName="recipentReportType">
                                                            <option value="" selected disabled>-- Select --</option>
                                                            <option value="internal">Internal
                                                            </option>
                                                            <option value="external">External
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3  mt-2">
                                                        <label for="DKeys"><strong>Email Key </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientEmailKey" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2"
                                                    *ngIf="recipientData.controls['recipientProtectFile'].value =='true'">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Email Password </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <!-- <form [formGroup]="emailPassword" ngNativeValidate> -->
                                                        <mat-slide-toggle class="mt-auto"
                                                            formControlName="recipientEmailPassword">
                                                            <p class="toggle-wrap">
                                                                {{recipientData.value.recipientEmailPassword
                                                                == true ?'Yes':'No'}}</p>
                                                        </mat-slide-toggle>
                                                        <!-- </form> -->
                                                    </div>
                                                </div>

                                                <div *ngIf="recipientData.controls['recipientProtectFile'].value =='true' && recipientData.value.recipientEmailPassword"
                                                    class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Password </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientPassword" type="password"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Comments </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <textarea formControlName="recipientComments" type="text"
                                                            class="form-control comments-area p-2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div *ngIf="showTableGrid" class="row">
                                <div class="col-sm-12 mt-4 button-wrap">
                                    <button (click)="cancelRecipient()" mat-raised-button
                                        class="admin-btn-success mr-4">Cancel</button>
                                    <!-- [disabled]="(editToMailData.length == 0 || editCcMailData.length == 0 ||  editBccMailData.length == 0) || editToEmailInvalid() == true || editCcEmailInvalid() == true  || editBccEmailInvalid() == true ||  recipientData.invalid" -->
                                    <button mat-raised-button class="admin-btn-success mr-2"
                                        [disabled]="editToMailData.length == 0||editToEmailInvalid() == true||editCcEmailInvalid() == true||editBccEmailInvalid()"
                                        (click)="updateRecipient(recipientData.value)">Update</button>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2 modal-wrap" *ngIf="tab==='Create New Job'">
                            <div class="row">
                                <div class="col-sm-12">
                                    <form [formGroup]="recipientAddNewJob" class="mt-3">
                                        <div class="row px-5">
                                            <div class="col-sm-6">
                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys" class="d-flex"><strong>To </strong>
                                                            <p class="error-msg">*</p>
                                                        </label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #saveChipList>
                                                            <mat-chip-row name="chips"
                                                                [color]="toMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let toMail of toMailData;let i=index"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="saveRemoveEmailto(toMail)" required>
                                                                {{toMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #toMailInput [formControl]="toMailCtrl"
                                                                matInput    
                                                                [matAutocomplete]="toAuto"
                                                                [matChipInputFor]="saveChipList"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="saveEmailto($event)" />

                                                        </mat-chip-grid>
                                                        <mat-autocomplete #toAuto="matAutocomplete"
                                                            (optionSelected)="selectedSavemailto($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailTo of filteredToMail | async"
                                                                [value]="mailTo">
                                                                {{mailTo}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <!-- recipientAddNewJob.get('formEmailTo').hasError('incorrectEmail') -->
                                                    <mat-error *ngIf="areToEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3 mt-2">
                                                        <label for="DKeys"><strong>Cc </strong></label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #saveChipList1>
                                                            <mat-chip-row name="chipsCc"
                                                                [color]="ccMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let ccMail of ccMailData let i=index"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="saveRemoveEmailcc(ccMail)" required>
                                                                {{ccMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #ccMailInput [formControl]="ccMailCtrl"
                                                                matInput
                                                                [matAutocomplete]="ccAuto"
                                                                [matChipInputFor]="saveChipList1"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="saveEmailcc($event)" />
                                                        </mat-chip-grid>
                                                        <mat-autocomplete #ccAuto="matAutocomplete"
                                                            (optionSelected)="selectedSavemailcc($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailCc of filteredCcMail | async"
                                                                [value]="mailCc">
                                                                {{mailCc}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="areCcEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3 mt-2">
                                                        <label for="DKeys"><strong>Bcc </strong></label>
                                                    </div>
                                                    <mat-form-field class="example-chip-list col-sm-9">
                                                        <mat-chip-grid #saveChipList2>
                                                            <mat-chip-row name="chipsBcc"
                                                                [color]="bccMail.invalid ? 'warn' : ''" selected
                                                                *ngFor="let bccMail of bccMailData"
                                                                [selectable]="true" [removable]="removable"
                                                                (removed)="saveRemoveEmailbcc(bccMail)" required>
                                                                {{bccMail.value}}
                                                                <mat-icon matChipRemove *ngIf="removable">cancel
                                                                </mat-icon>
                                                            </mat-chip-row>
                                                            <input #bccMailInput [formControl]="bccMailCtrl"
                                                                matInput
                                                                [matAutocomplete]="bccAuto"
                                                                [matChipInputFor]="saveChipList2"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="true"
                                                                (matChipInputTokenEnd)="saveEmailbcc($event)" />
                                                        </mat-chip-grid>
                                                        <mat-autocomplete #bccAuto="matAutocomplete"
                                                            (optionSelected)="selectedSavemailbcc($event)">
                                                            <mat-option class="mat-opt-align"
                                                                *ngFor="let mailBcc of filteredBccMail | async"
                                                                [value]="mailBcc">
                                                                {{mailBcc}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="areBccEmailInvalid()">
                                                        Invalid email ID</mat-error>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Subject </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientEmailSubject" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Body </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <textarea formControlName="recipientEmailBody" type="text"
                                                            class="form-control p-2"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Status </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <!-- <form [formGroup]="emailStatus" ngNativeValidate> -->
                                                        <mat-slide-toggle class="mt-auto"
                                                            formControlName="recipientEmailStatus">
                                                            <p class="toggle-wrap">
                                                                {{recipientAddNewJob.value.recipientEmailStatus
                                                                == true ?'Active':'Inactive'}}</p>
                                                        </mat-slide-toggle>
                                                        <!-- </form> -->
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Job Name </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientReportName" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>File Name </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientFileName" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Protect File </strong></label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="radio" value="true"
                                                            formControlName="recipientProtectFile" class="mr-2"
                                                            (click)="clickProtect(false)">Yes
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <input type="radio" value="false"
                                                            formControlName="recipientProtectFile" class="mr-2"
                                                            (click)="clickProtect(true)">No
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Report Type </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <select class="form-control"
                                                            formControlName="recipentReportType">
                                                            <option value="" selected disabled>-- Select --</option>
                                                            <option value="internal">Internal
                                                            </option>
                                                            <option value="external">External
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3  mt-2">
                                                        <label for="DKeys"><strong>Email Key </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientEmailKey" type="text"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div *ngIf="recipientAddNewJob.controls['recipientProtectFile'].value =='true'"
                                                    class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Email Password </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <!-- <form [formGroup]="emailPassword" ngNativeValidate> -->
                                                        <mat-slide-toggle class="mt-auto"
                                                            formControlName="recipientEmailPassword">
                                                            <p class="toggle-wrap">
                                                                {{recipientAddNewJob.value.recipientEmailPassword
                                                                == true ? 'Yes' : 'No'}}
                                                            </p>
                                                        </mat-slide-toggle>
                                                        <!-- </form> -->
                                                    </div>
                                                </div>

                                                <div *ngIf="recipientAddNewJob.controls['recipientProtectFile'].value =='true' && recipientAddNewJob.value.recipientEmailPassword"
                                                    class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Password </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <input formControlName="recipientPassword" type="password"
                                                            class="form-control p-2">
                                                    </div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-sm-3">
                                                        <label for="DKeys"><strong>Comments </strong></label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <textarea formControlName="recipientComments" type="text"
                                                            class="form-control comments-area p-2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 mt-4 button-wrap">
                                    <button (click)="resetRecipient()" mat-raised-button
                                        class="admin-btn-success mr-4">Reset</button>
                                    <!-- [disabled]="(toMailData.length == 0 || ccMailData.length == 0 ||  bccMailData.length == 0) || areToEmailInvalid() == true || areCcEmailInvalid() == true || areBccEmailInvalid() == true || recipientAddNewJob.invalid" -->
                                    <button mat-raised-button class="admin-btn-success mr-2"                                   
                                    [disabled]="toMailData.length == 0 || areToEmailInvalid() == true|| areCcEmailInvalid() == true || areBccEmailInvalid() == true"
                                        (click)="saveRecipient(recipientAddNewJob.value)">Save</button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
<div class="modal-footer">
</div>
</div>