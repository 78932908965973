<h1 mat-dialog-title>{{data.header}}</h1>
<span class="migrate_wrap">
  <div mat-dialog-content>
    <span [innerText]="data.message"></span>
    <p>{{data.alert}}</p>
  </div>
</span>

<div mat-dialog-actions class="confirm_dia" [ngStyle]="{'justify-content': data.cancel === 'dontshow' ? 'center':''}">
  <button type="submit" class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn" (click)="onNoClick()">
    {{data.continue}}
  </button>
  <button *ngIf="data.cancel !== 'dontshow'" [mat-dialog-close]="data.animal" cdkFocusInitial
    class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn">{{data.cancel}} </button>
</div>