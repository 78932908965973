import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSelect } from '@angular/material/select';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';

declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-identity-formats',
  templateUrl: './identity-formats.component.html',
  styleUrls: ['./identity-formats.component.scss'],
})

export class IdentityFormatsComponent implements OnInit {
  actionTitle: string;
  allServices: any[];
  createScreen: boolean = false;
  uploadClicked: boolean = false;
  gridheader: any[];
  formatGridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean = false;
  gridwidth: number = 0;
  gridWidth: number = 0;
  excelDataArray: any[];
  showDelete: boolean = true;
  postDownload: boolean = false;
  postUpload: boolean = false;
  backBtn: boolean = false;
  gridArray: any[];
  showPaginationMainGrid: boolean = false;
  columns: ColDef[];
  @Input() templateData: any;
  public SubMenuCardModel;
  public DeploymentKeys = [];
  workBook: any;
  sheetsToSelect: any;
  hideCreateBtn: boolean = false;
  hideUploadBtn: boolean = false;
  hideEditBtn: boolean = false;
  hideDeleteBtn: boolean = false;

  userchanged: any;
  selectedrowData: any = {};
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  @ViewChild('grid') flex: WjFlexGrid;

  sheetHeader: any = [];
  destDeployment: string = "";
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  AllFields = [{}]
  updateFields = [{}]
  MandatoryFields = [{}]
  sampleDataAll = [{
    EntityType : 'Entity Type',
    EntityName : 'Entity Name',
    Format : 'Format',
    AlternateFormat : 'Alternate Format',
    BillTo : 'BillTo',
    Services : 'Services',
    OForgID : '0-9',
    LabAccountID : '0-9',
    CaseflowType : 'Case Flow Type'    
  }]
  sampleDataUpdate = [{
    FormatID:1234,
    EntityType : 'Entity Type',
    EntityName : 'Entity Name',
    Format : 'Format',
    AlternateFormat : 'Alternate Format',
    BillTo : 'BillTo',
    Services : 'Services',
    OForgID : '0-9',
    LabAccountID : '0-9',
    CaseflowType : 'Case Flow Type'    
  }]
  sampleDataMin = [{
    EntityType : 'Entity Type',
    EntityName : 'Entity Name',
    AlternateFormat : 'Alternate Format'
    
  }]
  gridPage: boolean = true;
  destOrgid: string;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  arrowIconSubject1 = new BehaviorSubject('arrow_drop_down1');
  arrowIconSubject2 = new BehaviorSubject('arrow_drop_down2');
  arrowIconSubject3 = new BehaviorSubject('arrow_drop_down3');
  arrowIconSubject4 = new BehaviorSubject('arrow_drop_down4');
  arrowIconSubject5 = new BehaviorSubject('arrow_drop_down5');
  arrowIconSubject6 = new BehaviorSubject('arrow_drop_down6');
  arrowIconSubject7 = new BehaviorSubject('arrow_drop_down7');
  public associationTypeList: any[];
  DrpDwnEntityValue: any;
  editEnabled: boolean = false;
  sequenceList: any = [];
  serviceList: any = [];
  entityList: any = [];
  ofList: any = [];
  billToList: any = [];
  entityTypeList: any = [];
  entityNames: any = [];
  task: Task = {
    color: 'primary',
  };
  action: string;
  ofId: any;
  showDefaultFormats: boolean = false;
  disableEntityName: boolean = false;
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  userDropdownList1: any;
  userDropdownList2: any;
  ServiceListname: any = [];
  billTo: any = [];
  allServicesSelected: boolean = false;
  allBilltoSelected: boolean = false;
  defaultFormatList: any = [];
  customFormatList: any;
  arrayTemp : any;
  arrayTemp1 : any;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: UntypedFormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, public DatabaseService: VitalHttpServices, private dialog: MatDialog, public commonService: CommonService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;

    this.columns = [
      //************* Grid Customized Column names *********/
      new ColDef('entitytype', 'Entity Type'),
      new ColDef('entity', 'Entity'),
      new ColDef('sequencetype', 'Sequence Type'),
      new ColDef('format', 'Default Accession'),
      new ColDef('alternateformat', 'Custom Accession'),
      new ColDef('oforgname', 'Ordering Facility'),
      new ColDef('services', 'Services'),
      new ColDef('billto', 'Bill To'),
      new ColDef('caseflowtype', 'Caseflow Type'),
      new ColDef('labaccountid', 'This Location'),
    ];
  }
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  formatForm = this._fb.group({
    frmEntity: ['', Validators.required],
    frmOrdFacility: '',
    frmAltFormat: ['', Validators.required],
    frmFormat: [''],
    frmSerType: [""],
    frmBillto: "",
    // frmSeqType: ["", Validators.required],
    frmType: ["", Validators.required],
    frmCaseflowtype: "",
    frmLabaccId: ""
  })

  ngOnInit(): void {
    this.GetButtondetails();
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.destOrgid = (!sessionStorage.getItem('org_id')) ? '' : sessionStorage.getItem('org_id');
    this.actionTitle = 'Accession Prefix List';
    this.addformatGridData(this.templateData.submenuData);
    this.getLookupValues();
  }

  //#region Tooltip for the wijmo grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field(s) missing!' || (value.toString().includes('should end')) || (value.toString().includes('already exists')) || value == 'Null values exist!' || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('Please upload') || value.toString().includes('only selected') || value.toString().includes('accepts only') || value.toString().includes('does not exist')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('will be added') || value.toString().includes('will be updated')
          || value.toString().includes('inserted') || value == 'Success' || value.toString().includes('successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value == 'Ignored')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  getInputData() {
    let entitytype = ""
    let defaultAccession = null;
    let customAccession = null;

    if (this.selectedrowData.entitytype &&
      this.selectedrowData.entitytype.toLowerCase() == 'account casetype') {
      entitytype = 'Casetype';
    } else {
      if (this.selectedrowData.entitytype &&
        (this.selectedrowData.entitytype.toLowerCase() == 'all locations'
          || this.selectedrowData.entitytype.toLowerCase() == 'organization')) {
        entitytype = 'All Locations';
      } else {
        entitytype = this.selectedrowData.entitytype;
      }
    }
    if (this.selectedrowData.format && this.selectedrowData.alternateformat) {
      defaultAccession = this.selectedrowData.alternateformat;
      customAccession = this.selectedrowData.format;
    }
    else {
      defaultAccession = this.selectedrowData.format;
      customAccession = this.selectedrowData.alternateformat;
    }
    const arrayBillto = []
    this.selectedrowData.billto = this.selectedrowData.billto ? (this.selectedrowData.billto).split(',') : [];
    const arrayServices = []
    this.selectedrowData.services = this.selectedrowData.services ? (this.selectedrowData.services).split(',') : [];
    if (this.selectedrowData.billto && this.selectedrowData.billto.toString().trim().toLowerCase() != 'all') {
      this.selectedrowData.billto.forEach(element => {
        const temp = {
          'Attribute_Name': element.toString().trim()
        }
        arrayBillto.push(temp)
      });
    }

    if (this.selectedrowData.services && this.selectedrowData.services.toString().trim().toLowerCase() != 'all') {
      this.selectedrowData.services.forEach(element => {
        const temp1 = {
          'Attribute_Name': element.toString().trim()
        }
        arrayServices.push(temp1)
      });
    }
    this.arrayTemp = this.getUniqueData(arrayServices);
    this.arrayTemp1 = this.getUniqueData(arrayBillto);
    this.formatForm.patchValue({
      frmEntity: this.selectedrowData.entity ? (this.selectedrowData.entity) : "",
      frmOrdFacility: this.selectedrowData.oforgname ? (this.selectedrowData.oforgname) : "",
      frmFormat: customAccession,
      frmAltFormat: defaultAccession,
      frmSerType: this.arrayTemp,
      frmBillto: this.arrayTemp1,
      // frmSeqType: this.selectedrowData.sequencetype ? (this.selectedrowData.sequencetype) : "",
      frmType: entitytype,
      frmCaseflowtype: (this.selectedrowData.caseflowtype && this.selectedrowData.caseflowtype != "0") ? "1" : "0",
      frmLabaccId: (this.selectedrowData.labaccountid && this.selectedrowData.labaccountid != "0") ? "1" : "0",
    })
    this.ofId = this.selectedrowData.oforgid ? this.selectedrowData.oforgid : null
  }

  // group id
  getOfID(event, value) {
    if (event.source.selected) {
      this.ofId = value.organizationid
    }
  }

  // insertdata
  saveCloseFormatData(action, data) {
    let temp = [];
    let temp1 = [];
    if (this.allServicesSelected && this.allBilltoSelected && ((!this.ofId) || (this.ofId && this.ofId == -1))) {
        temp = [];
        temp1 = [];
    }
    else {
      if (data.frmSerType) {
        for (let i = 0; i < data.frmSerType.length; i++) {
          temp.push(data.frmSerType[i].Attribute_Name.toString().trim())
        }
      }
      if (data.frmBillto) {
        for (let i = 0; i < data.frmBillto.length; i++) {
          temp1.push(data.frmBillto[i].Attribute_Name.toString().trim())
        }
      }
      if (temp.length > 0 && temp1.length > 0 && !this.ofId) {
        this.ofId = -1
      }
    }
    let obj = [{
      "casetype": this.templateData.secondarykeys.casetype,
      "organizationid": Number(sessionStorage.getItem('org_id')),
      "formatid": !this.selectedrowData.formatid ? null : this.selectedrowData.formatid,
      "entityname": data.frmEntity ? data.frmEntity.split('(')[0].trim() : this.destOrgid,
      "entitytype": (data.frmType && data.frmType.toLowerCase() == 'casetype') ? 'Account Casetype' : data.frmType,
      // "sequencetype": data.frmSeqType ? data.frmSeqType.trim() : null,
      "format": data.frmFormat ? data.frmFormat : null,
      "alternateformat": data.frmAltFormat ? data.frmAltFormat.trim() : null,
      "billto": temp1,
      "services": temp,
      "oforgid": this.ofId ? this.ofId : null,
      "labaccountid": data.frmLabaccId ? 1 : 0,
      "caseflowtype": (data.frmCaseflowtype == true) ? 'QC' : null,
      "accountid": this.templateData.cardIdentifier
    }]
    this.action = action;
    this.excelDataArray = [];
    this.excelDataArray = obj;
    if (!this.validatePrefixData()) {
      return;
    }
    if (!this.actionTitle.toString().match(/edit/i)) {
      delete this.excelDataArray[0]['formatid'];
    }
    !this.actionTitle.toString().match(/edit/i) ? this.uploadData() : this.editAccessionPrefix(this.excelDataArray);
  }

  validatePrefixData() {
    if (this.excelDataArray[0].entitytype.trim() && this.excelDataArray[0].entitytype.trim().toLowerCase() != 'all locations') {
      if (this.excelDataArray.some(va => !va.entitytype || !va.entityname || !va.alternateformat)) {
        this._snackbar.open('Please enter all mandatory fields', 'Close');
        return false;
      }
    }
    else {
      if (this.excelDataArray.some(va => !va.entitytype || !va.alternateformat)) {
        this._snackbar.open('Please enter all mandatory fields', 'Close');
        return false;
      }
    }
    if (this.entityTypeList && this.excelDataArray[0].entitytype && (this.excelDataArray[0].entitytype.toString().toLowerCase() != 'account casetype' && this.excelDataArray[0].entitytype.toString().toLowerCase() != 'casetype') && !this.entityTypeList.some(va => va && va.entitytype && va.entitytype.toString().trim() == this.excelDataArray[0].entitytype.toString().trim())) {
      this._snackbar.open('Select valid entity type', 'Close');
      return false
    }
    if ((this.excelDataArray[0].entitytype.toString().trim().toLowerCase() != 'all locations' && this.excelDataArray[0].entitytype.toString().trim().toLowerCase() != 'organization') && (this.excelDataArray[0].entitytype.trim().toString().toLowerCase() != 'account casetype' && this.excelDataArray[0].entitytype.toString().trim().toLowerCase() != 'casetype') && this.entityList && this.excelDataArray[0].entityname && !this.entityList.some(va => va && va.entityname && va.entityname.toString().trim() == this.excelDataArray[0].entityname.toString().trim())) {
      this._snackbar.open('Select valid entity', 'Close');
      return false
    }
    if ((this.excelDataArray[0].alternateformat && this.excelDataArray[0].alternateformat.toString().replace(/\s/g, '').length > 0) &&
      (this.excelDataArray[0].format && this.excelDataArray[0].format.toString().replace(/\s/g, '').length > 0)) {
      if (!(this.excelDataArray[0].alternateformat.toString().trim().endsWith("NN%"))) {
        this._snackbar.open('Default Accession should end with NN%', 'Close');
        return false;
      }
      if (!(this.excelDataArray[0].format.toString().trim().endsWith("US%"))) {
        this._snackbar.open('Custom Accession should end with US%', 'Close');
        return false;
      }
    }
    else {
      if (!(this.excelDataArray[0].alternateformat && this.excelDataArray[0].alternateformat.toString().trim().endsWith("NN%"))) {
        this._snackbar.open('Default Accession should end with NN%', 'Close');
        return false;
      }
    }
    if (this.ofList && (this.ofId && this.ofId != -1) && !this.ofList.some(va => va.organizationid == this.ofId)) {
      this._snackbar.open('Please select valid ordering facility', 'Close');
      return false
    }
    if (this.ofId && this.ofId != -1 && (!(this.excelDataArray[0].billto.length > 0) || !(this.excelDataArray[0].services.length > 0))) {
      this._snackbar.open('Service type & BillTo are mandatory', 'Close');
      return false
    }
    if (this.excelDataArray[0].billto.length > 0) {
      if (this.excelDataArray[0].services.length < 1) {
        this._snackbar.open('Please select the Service type', 'Close');
        return false
      }

      if (this.serviceList && this.excelDataArray[0].services) {
        let invalidServiceList = [];
        for (let i = 0; i < this.excelDataArray[0].services.length; i++) {
          if (!this.serviceList.some(va => va.AttributeName.toString().toLowerCase().trim() == this.excelDataArray[0]['services'][i].toString().toLowerCase().trim())) {
            invalidServiceList.push(this.excelDataArray[i])
          }
        }
        if (invalidServiceList.length > 0) {
          this._snackbar.open('Please select valid service', 'Close');
          return false
        }
        else {
          return true
        }
      }
    }
    if (this.excelDataArray[0].services.length > 0) {
      if (this.excelDataArray[0].billto.length < 1) {
        this._snackbar.open('Please select the BillTo', 'Close');
        return false
      }
      if (this.billToList && this.excelDataArray[0].billto) {
        let invalidBillToList = [];
        for (let i = 0; i < this.excelDataArray[0].billto.length; i++) {
          if (!this.billToList.some(va => va.AttributeName.toString().toLowerCase().trim() == this.excelDataArray[0]['billto'][i].toString().toLowerCase().trim())) {
            invalidBillToList.push(this.excelDataArray[i])
          }
        }
        if (invalidBillToList.length > 0) {
          this._snackbar.open('Please select valid billto', 'Close');
          return false
        }
        else {
          return true
        }
      }
    }
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      // let id = this.excelDataArray[0].formatid ? this.excelDataArray[0].formatid.toString().split(',')[0] : null;
      // if (id) {
      //   if (this.templateData.submenuData.find(d => d.formatid != this.excelDataArray[0].formatid && d.format == this.excelDataArray[0].format
      //     && d.alternateformat == this.excelDataArray[0].alternateformat && d.entitid == this.excelDataArray[0].entityid
      //     && d.oforgid == this.excelDataArray[0].oforgid
      //     && d.entitytype == this.excelDataArray[0].entitytype)) {
      //     this._snackbar.open('Accession Prefix with same combination already exists', 'Close');
      //     return false;
      //   }
      //   else {
      //     return true;
      //   }
      // }
      // else {
      // if (this.templateData.submenuData.find(d => d.format == this.excelDataArray[0].format
      //   && d.alternateformat == this.excelDataArray[0].alternateformat && d.entitid == this.excelDataArray[0].entityid
      //   && d.oforgid == this.excelDataArray[0].oforgid
      //   && d.entitytype == this.excelDataArray[0].entitytype)) {
      //   this._snackbar.open('Accession Prefix with same combination already exists', 'Close');
      //   return false;
      // }
      // else {
      //   return true;
      // }
      // }
    }
    return true;
  }

  editAccessionPrefix(excelDataArray) {
    this.ngxService.start()
    for (let i = 0; i < excelDataArray.length; i++) {
      excelDataArray[i]['formatids'] = excelDataArray[i].formatid
      excelDataArray[i]["billto"] = ((excelDataArray[i]["billto"]) ? (excelDataArray[i]["billto"]).toString() : null);
      excelDataArray[i]["services"] = ((excelDataArray[i]["services"]) ? (excelDataArray[i]["services"]).toString() : null);
      excelDataArray[i]['formatid'] = excelDataArray[i].formatid.toString().split(',')[0];

      // if (excelDataArray[i]['formatids']) {
      //   if (this.templateData.submenuData.some(d => (d.alternateformat && this.excelDataArray[i].format && d.alternateformat.toString().toLowerCase().trim() == this.excelDataArray[i].format.toString().toLowerCase().trim())
      //     && (d.format && this.excelDataArray[i].alternateformat && d.format.toString().toLowerCase().trim() == this.excelDataArray[i].alternateformat.toString().toLowerCase().trim())
      //     && (d.entitid && this.excelDataArray[i].entityid && d.entitid.toString().trim() == this.excelDataArray[i].entityid.toString().trim())
      //     && (d.oforgid && this.excelDataArray[i].oforgid && d.oforgid.toString().trim() == this.excelDataArray[i].oforgid.toString().trim())
      //     && (d.entitytype && this.excelDataArray[i].entitytype && d.entitytype.toString().toLowerCase().trim() == this.excelDataArray[i].entitytype.toString().toLowerCase().trim()))) {
      //     this._snackbar.open('Accession Prefix with same combination already exists', 'Close');
      //     return;
      //   }
      // }
    }

    this.VitalHttpServices.EditIdentityFormats(excelDataArray, this.destDeployment).subscribe(result => {
      this.ngxService.stop()
      if (result && result.length > 0) {
        if (result[0].Status == 'Success') {
          this.auditDetails(this.selectedrowData, result[0], "updated");
          this.actionTitle = "Accession Prefix List"
          this.refreshGrid();
          this.formGroupDirective.resetForm()
          this.formatForm.reset();
          this._snackbar.open('Accession Prefix updated successfully', 'Close');
        } else {
          if (result[0].Status == 'Ignored') {
            this._snackbar.open(result[0]['notes'], 'Close');
          }
          else {
            this._snackbar.open('Accession prefix updation failed!', 'Close');
          }
        }
      }
      else {
        this._snackbar.open('Accession prefix updation failed!', 'Close');
      }
    },
      error => {
        this.ngxService.stop()
        this._snackbar.open('Accession prefix updation failed!', 'Close');
        console.error(error)
      })
  }

  //clear search
  clearEntity(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmEntity: "",
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearEntityType(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmType: ""
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearFormat(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmFormat: ""
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearAltFormat(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmAltFormat: ""
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearOrdFacility(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmOrdFacility: ""
    })
    this.ofId = null
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearSerType(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmSerType: "",
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  clearBillto(evt: any): void {
    evt.stopPropagation();
    this.formatForm.patchValue({
      frmBillto: ""
    })
    this.inputAutoComplete?.nativeElement.focus();
  }

  // clearSeqType(evt: any): void {
  //   evt.stopPropagation();
  //   this.formatForm.patchValue({
  //     frmSeqType: ""
  //   })
  //   this.inputAutoComplete?.nativeElement.focus();
  // }

  async getLookupValues() {
    this.getFormats();
    // Entity,  & OF Org Dropdown
    let query = this.SubMenuCardModel.GetQuery('getidentityformatsentities');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id').toString(), "accid": this.templateData.cardIdentifier.toString(), "casetype": this.templateData.secondarykeys.casetype };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let res = await this.VitalHttpServices.GetData(queryResult).toPromise();
    this.ngxService.stop();
    if (!res.errors) {
      if (res) {
        let temp = [...res.data.submenuDataOrgAttributes, ...res.data.submenuDataOrgAttributesGlobal]
        let serviceArray = (temp.length > 0) ? temp.filter(res => res.AttributeType.toLowerCase() == 'services') : [];
        let billToArray = (temp.length > 0) ? temp.filter(res => res.AttributeType.toLowerCase() == 'billto') : [];

        this.serviceList = this.getUniqueArray(serviceArray);
        this.billToList = this.getUniqueArray(billToArray);

        this.serviceList.forEach(element => {
          element.AttributeName = element.AttributeName.toString().trim()
        });


        this.billTo.forEach(element => {
          element.AttributeName = element.AttributeName.toString().trim()
        });
        this.billTo = this.billTo.filter(element => element.AttributeName && element.AttributeName.toString().toLowerCase() != '-- select --')
        this.entityList = res.data.submenuDataEntities;

        this.ofList = res.data.submenuDataOFOrgs;

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'Attribute_Name',
          textField: 'Attribute_Name',
          enableCheckAll: true,
          allowSearchFilter: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 150,
          itemsShowLimit: 44,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false
        };
        this.userDropdownList1 = [];
        this.serviceList.forEach(element => {
          const temp = {
            'Attribute_Name': element.AttributeName
          }
          this.userDropdownList1.push(temp);
        });
        this.userDropdownList2 = [];
        this.billToList = this.billToList.filter(element => element.AttributeName && element.AttributeName.toString().toLowerCase() != '-- select --')
        this.billToList.forEach(element => {
          const temp = {
            'Attribute_Name': element.AttributeName
          }
          this.userDropdownList2.push(temp);
        });
      }
      /* Get VitalAdmin Attributes */
      sessionStorage.setItem('deploymentKey', 'configdb');
      let query = this.GetQuery('CategoryDropDown');
      let queryVariable = {
        keyword: 'identityformats',
        context: 'AccessionPrefix',
      };
      let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.DatabaseService.GetData(queryResult, dbname).subscribe((Resdata) => {
        this.ngxService.stop();
        sessionStorage.setItem('deploymentKey', this.DatabaseService.deploymentKey);
        if (!Resdata.errors) {
          if (Resdata.data.submenuData.length > 0) {
            let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
            // this.entityTypeList = data.EntityType.map((item) => item.entitytype)
            //   .filter((value, index, self) => (self.indexOf(value) === index) && (value.trim() != ''));

            this.entityTypeList = data.EntityType
            let tempEntitylist = []
            tempEntitylist = this.entityList.filter(e => e.entitytype.toString().toLowerCase().trim() != 'alternateformat'
              && e.entitytype.toString().toLowerCase().trim() != 'format')

            if (tempEntitylist && tempEntitylist.length > 0) {
              let temEntityTypeList = [];
              temEntityTypeList = this.entityTypeList.filter(e => (e.entitytype.toString().toLowerCase().trim() != 'casetype' &&
                e.entitytype.toString().toLowerCase().trim() != 'all locations'))
              for (let i = 0; i < temEntityTypeList.length; i++) {
                for (let j = 0; j < tempEntitylist.length; j++) {
                  if (temEntityTypeList[i] && temEntityTypeList[i].entitytype &&
                    tempEntitylist[j].entitytype &&
                    (tempEntitylist[j].entitytype.toString().toLowerCase().trim() != temEntityTypeList[i].entitytype.toString().toLowerCase().trim())) {
                    delete temEntityTypeList[i];
                  }
                }
              }
              this.entityTypeList = [];
              this.entityTypeList = [...temEntityTypeList, { entitytype: 'Casetype' }, { entitytype: 'All Locations' }]
              this.entityTypeList = this.entityTypeList.filter(a => a && a.entitytype)
            }
            else {
              this.entityTypeList = [];
              this.entityTypeList.push({ entitytype: 'Casetype' }, { entitytype: 'All Locations' });
            }
            this.entityTypeList.sort((a, b) => a.entitytype < b.entitytype ? -1 : a.entitytype > b.entitytype ? 1 : 0)
          }
        }
      }, (error) => {
        sessionStorage.setItem('deploymentKey', this.DatabaseService.deploymentKey);
        this.ngxService.stop();
        console.error(error);
      });
    }
    this.ngxService.stop();
  }

  getUniqueArray(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].AttributeName.toString().trim()]) continue;
      flags[array[i].AttributeName.toString().trim()] = true;
      array[i].AttributeName = array[i].AttributeName.toString().trim()
      output.push(array[i]);
    }
    return output;
  }

  getUniqueData(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].Attribute_Name]) continue;
      flags[array[i].Attribute_Name] = true;
      output.push(array[i]);
    }
    return output;
  }

  onCheckChange(item: any, type) {
    if (type == 'services') {
      this.ServiceListname.push(item.Attribute_Name);
      this.allServicesSelected = false;
    }
    else {
      this.billTo.push(item.Attribute_Name);
      this.allBilltoSelected = false;
    }
  }

  onSelectAll(items: any, type) {
    if (type == 'services') {
      this.ServiceListname = [];
      for (let itr = 0; itr < items.length; itr++) {
        this.ServiceListname.push(items[itr].Attribute_Name);
      }
      this.allServicesSelected = true;
    }
    else {
      this.billTo = [];
      for (let itr = 0; itr < items.length; itr++) {
        this.billTo.push(items[itr].Attribute_Name);
      }
      this.allBilltoSelected = true;
    }
  }

  onItemDeSelect(items: any, type) {
    if (type == 'services') {
      const index: number = this.ServiceListname.indexOf(items.Attribute_Name);
      if (index !== -1) {
        this.ServiceListname.splice(index, 1);
        this.allServicesSelected = false;
      }
    }
    else {
      const index: number = this.billTo.indexOf(items.Attribute_Name);
      if (index !== -1) {
        this.billTo.splice(index, 1);
        this.allBilltoSelected = false;
      }
    }
  }

  onDeSelectAll(type) {
    if (type == 'services') {
      this.ServiceListname = [];
      this.allServicesSelected = false;
    }
    else {
      this.billTo = [];
      this.allBilltoSelected = false;
    }
  }

  getEntityName(event, entityType) {
    this.disableEntityName = false;
    this.formatForm.patchValue({
      frmEntity: ""
    })
    if (event.source.selected) {
      if (this.entityList.length > 0) {
        this.entityNames = this.entityList.filter(e => e.entitytype == entityType)
      }
      if (entityType.toLowerCase() == 'casetype') {
        this.disableEntityName = true;
        this.formatForm.patchValue({
          frmEntity: this.templateData.secondarykeys.casetype.toString().trim()
        })
      }
      else if (entityType.toLowerCase() == 'all locations') {
        this.disableEntityName = true;
        this.formatForm.patchValue({
          frmEntity: sessionStorage.getItem('Org_Name') ? sessionStorage.getItem('Org_Name') : 'all locations'
        })
      }
      else if (this.entityNames.length == 1) {
        this.formatForm.patchValue({
          frmEntity: this.entityList[0].entityname
        })
      }
    }
  }

  public GetQuery(keyword: string) {
    // let query: string = '';
    let mainQueryIndex: number = this.DatabaseService.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.DatabaseService.mainQueryList[mainQueryIndex].Query;
  }

  // entityList has only entityname
  filterEntity(value) {
    if (!value || value == '') {
      return this.entityNames
    }
    let users = this.entityNames
    return users.filter(va => va && va.entityname.toLowerCase().includes(value.toLowerCase()));
  }

  // entityTypeList has only entitytype
  filterEntityType(value) {
    if (!value || value == '' || value == null) {
      return this.entityTypeList
    }
    let users = this.entityTypeList
    return users.filter(va => va && va.entitytype && va.entitytype.toString().toLowerCase().includes(value.toLowerCase()));
  }

  // sequenceList has only sequencetype
  // filterSeqType(value) {
  //   if (!value || value == '' || value == null) {
  //     return this.sequenceList
  //   }
  //   let users = this.sequenceList
  //   return users.filter(va => va && va.sequencetype && va.sequencetype.toLowerCase().includes(value.toLowerCase()));
  // }

  // ofList has only of organizationname
  filterOF(value) {
    if (!value || value == '') {
      return this.ofList
    }
    let users = this.ofList
    if (users && users.length > 0) {
      return users.filter(va => va && va.organizationname && va.organizationname.toString().toLowerCase().includes(value.toLowerCase()));
    } else {
      return []
    }
  }

  filterFormat(value, type) {
    switch (type) {
      case 'alternateformat':
        if (!value) {
          return this.defaultFormatList
        }
        else {
          let arr = []
          arr = this.defaultFormatList.filter(d => d && d.toString().toLowerCase() == value);
          return arr
        }
      case 'format':
        if (!value) {
          return this.customFormatList
        }
        else {
          let arr = []
          arr = this.customFormatList.filter(d => d && d.toString().toLowerCase() == value);
          return arr
        }
    }
  }

  // billToList has only billto
  filterBillto(value) {
    if (!value || value == '') {
      return this.billToList
    }
    return this.billToList.filter(va => va && va.AttributeName.toLowerCase().includes(value.toLowerCase()));
  }

  // serviceList has only services
  filterSerType(value) {
    if (!value || value == '') {
      return this.serviceList
    }
    let users = this.serviceList
    return users.filter(va => va && va.AttributeName.toLowerCase().includes(value.toLowerCase()));
  }

  onSelectedUser(e, obj) {
    if (e.source.selected) {
      this.userchanged = (this.selectedrowData.UserID != obj.userid) ? true : false;
      this.selectedrowData.UserID = obj.userid
    }
  }

  // up/down arrow
  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  openOrClosePanel1(evt: any, trigger1: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger1.panelOpen)
      trigger1.closePanel();
    else
      trigger1.openPanel();
  }

  openOrClosePanel2(evt: any, trigger2: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger2.panelOpen)
      trigger2.closePanel();
    else
      trigger2.openPanel();
  }

  openOrClosePanel3(evt: any, trigger3: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger3.panelOpen)
      trigger3.closePanel();
    else
      trigger3.openPanel();
  }

  openOrClosePanel4(evt: any, trigger4: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger4.panelOpen)
      trigger4.closePanel();
    else
      trigger4.openPanel();
  }

  openOrClosePanel5(evt: any, trigger5: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger5.panelOpen)
      trigger5.closePanel();
    else
      trigger5.openPanel();
  }

  // add data in the grid
  addformatGridData(data) {
    this.gridArray = [];
    let primary = {};
    let inArray = [];
    if (data && data.length > 0) {
      inArray = data.filter(r => r.entity && r.entity.toString() != 'NA');
      if (inArray.length > 0) {
        this.showDefaultFormats = false;
      }
      else {
        inArray = data.filter(r => r.entity && r.entity.toString() == 'NA');
        this.showDefaultFormats = true;
      }
    }
    if (inArray) {
      if (inArray.length > 0) {
        this.formatGridData = new CollectionView([]);
        for (let i = 0; i < inArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(inArray[i])) {
            if (this.showDefaultFormats) {
              if (!value) {
                value = 'NA';
              }
            }
            else {
              if (!value) {
                value = 'Not Specified';
              }
              if (key == 'billto' || key == 'services' || key == 'oforgname') {
                value = (value == '' || value == null || value.toString().toLowerCase() == 'not specified') ? 'All' : value;
              }
              if (key == 'entitytype') {
                value = (value.toString().toLowerCase() == 'organization') ? 'All locations' :
                  (value.toString().toLowerCase() == 'account casetype') ? 'Casetype' : value;
              }
            }
            primary[key] = value;
          }
          this.gridArray.push(primary);
        }
        this.formatGridData = (this.showDefaultFormats) ? new CollectionView(this.gridArray, { pageSize: 10 }) : new CollectionView(this.gridArray, { groupDescriptions: ['entitytype'], pageSize: 10 });
        this.gridwidth = (194 * Object.keys(inArray[0]).length) + 37;
        this.showPaginationMainGrid = this.gridArray.length > 10 ? true : false;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }

  // edit format
  openEditScreen(grid, event) {
    if(this.hideEditBtn){
      this._snackbar.open("User is not authorized !","Close");
      return;
    }
    this.getFormats()
    this.editEnabled = true;
    this.createScreen = true;
    this.gridPage = false;
    this.disableEntityName = true;
    this.actionTitle = 'Edit Accession Prefix'
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.selectedrowData = this.templateData.submenuData.find(e => e.formatid == selectedrowData.formatid)
    }
    if ((this.selectedrowData.entitytype) && (this.selectedrowData.entitytype.toLowerCase() == 'all locations' || this.selectedrowData.entitytype.toLowerCase() == 'casetype')) {
      this.disableEntityName = true;
    }
    // this.getFormats();
    this.getInputData();
  }

  createFormat() {
    this.getFormats();
    this.createScreen = true;
    this.gridPage = false;
    this.editEnabled = false;
    this.actionTitle = 'Create Accession Prefix'
    this.ofId = null;
  }

  getFormats() {
    this.defaultFormatList = [];
    this.customFormatList = [];
    if (this.gridArray && this.gridArray.length > 0) {
      let temp = [];
      let temp1 = [];
      this.gridArray.filter(e => {
        if ((e.alternateformat && e.alternateformat.toString().endsWith("NN%")) && (e.entitytype.toString().toLowerCase().trim().endsWith("casetype"))) {
          temp.push(e.alternateformat)
        }
        if ((e.format && e.format.toString().endsWith("NN%")) && (e.entitytype.toString().toLowerCase().trim().endsWith("casetype"))) {
          temp.push(e.format)
        }
      })
      this.defaultFormatList = temp.map((item) => item)
        .filter((value, index, self) => (self.indexOf(value) === index) && (value.trim() != ''));

      this.gridArray.filter(e => {
        if ((e.alternateformat && e.alternateformat.toString().endsWith("US%")) && (e.entitytype.toString().toLowerCase().trim().endsWith("casetype"))) {
          temp1.push(e.alternateformat)
        }
        if ((e.format && e.format.toString().endsWith("US%")) && (e.entitytype.toString().toLowerCase().trim().endsWith("casetype"))) {
          temp1.push(e.format)
        }
      })
      this.customFormatList = temp1.map((item) => item)
        .filter((value, index, self) => (self.indexOf(value) === index) && (value.trim() != ''));
    }
  }

  backtoGrid() {
    this.resetForm();
    this.actionTitle = 'Accession Prefix List'
    this.refreshGrid();
  }

  resetForm() {
    this.formatForm.reset();
    this.formatForm.patchValue(
      {
        frmEntity: "",
        frmOrdFacility: "",
        frmFormat: "",
        frmAltFormat: "",
        frmSerType: "",
        frmBillto: "",
        // frmSeqType: "",
        frmType: "",
        frmCaseflowtype: "",
        frmLabaccId: ""
      });
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va=> va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'identityformat')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {};
    let tempArray = [];
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
        if (primary[key] == "") {
          primary[key] = null;
        }
      }
      tempArray.push(primary);
      primary = {};
    });
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse();
    temp[0].push("Notes");
    temp[0].push("Status");
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.commonService.convertObjKeysToLower(tempArray);
    this.excelDataArray.find(d => {
      Object.assign(d, {
        slno: Number(i), notes: '', tablename: 'identityformat',
        organizationid: Number(sessionStorage.getItem('org_id')),
        casetype: this.templateData.secondarykeys.casetype.toString(),
        accountid: Number(this.templateData.cardIdentifier)
      });
      i++;
      d.labaccountid = (this.checkBool(d.labaccountid) && ((d.labaccountid && d.labaccountid.toString().toLowerCase() == 'true') || (d.labaccountid && d.labaccountid.toString() == '1'))) ? 1 : 0;
    });
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion



  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if(result){
      this.convertToJson(result)
    }
  }
  //#endregion


  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    if (excelArr) {
      if (excelArr.length > 0) {
        for (let i = 0; i < excelArr.length; i++) {
          if (excelArr[i]['formatid']) {
            excelArr[i]['formatids'] = excelArr[i]['formatid'] ? excelArr[i]['formatid'] : null
            excelArr[i]['formatid'] = excelArr[i]['formatid'] ? excelArr[i]['formatid'].toString().split(',')[0] : null;
          }
          excelArr[i]['formatid'] = excelArr[i]['formatid'] ? excelArr[i]['formatid'].toString().split(',')[0] : null;
          excelArr[i]['formatid'] = excelArr[i]['formatid'] ? excelArr[i]['formatid'].toString().split(',')[0] : null;
        }
      }
    }
    this.VitalHttpServices.validateIdentityFormats(excelArr, this.destDeployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          excelArr.filter(e => {
            result.find(r => {
              if (r.slno === e.slno) {
                e.notes = r.notemessage;
              }
            });
          });
          this.ngxService.stop();
          if (!this.createScreen) {
            this.excelGridData(excelArr);
          } else {
            this.applyValidation(excelArr);
          }
        }
        else {
          if (!this.createScreen) {
            this._snackbar.open("Please provide valid data", 'Failed');
          } else {
            this.applyValidation(excelArr);
          }
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //#endregion

  applyValidation(arr) {
    let alertMessage = '';
    if (arr[0].notes.includes('Accession Prefix already exists')) {
      alertMessage = "Accession Prefix with the same name already exists under selected Group. Do you still want to continue adding the same?"
    } else if (arr[0].notes === 'New Group will be added on approval') {
      alertMessage = "New Group will be added on approval. Do you still want to continue?";
    }
    if (alertMessage) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "", message: "", alert: alertMessage, continue: "Yes", cancel: "No" }
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.excelDataArray = [];
          this.excelDataArray = arr;
          // this.uploadData();
        }
        else {
          return
        }
      }, error => {

        console.log(error);
      });
    }
    else {
      this.excelDataArray = [];
      this.excelDataArray = arr;
      // this.uploadData();
    }
  }

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArray = [];
    this.formatGridData = new CollectionView([]);
    let primary = {};
    if (data) {
      if (data.length > 0) {
        data.sort((a, b) => a.entityname < b.entityname ? -1 : a.entityname > b.entityname ? 1 : 0);
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false;
          let checkDataType = false;
          let dataTypeCol;
          if (data[i]["entitytype"] && (data[i]["entitytype"].toString().toLowerCase().trim() == "all locations" || data[i]["entitytype"].toLowerCase() == "organization")) {
            let orgname = sessionStorage.getItem('Org_Name');
            if (data[i]["entityname"] && data[i]["entityname"].toString() != this.destOrgid.toString() && data[i]["entityname"].toString().toLowerCase().trim() != orgname.toString().toLowerCase().trim()) {
              data[i]["notes"] = "You can upload data for selected OrganizationID only!";
              primary['notes'] = data[i]["notes"];
            }
            else {
              primary['notes'] = '';
            }
          }
          else if (data[i]["entitytype"] && (data[i]["entitytype"].toString().toLowerCase().trim() == "account casetype" || data[i]["entitytype"].toString().toLowerCase().trim() == "casetype")) {
            if (data[i]["entityname"] && data[i]["entityname"].toString().toLowerCase().trim() != this.templateData.secondarykeys.casetype.toString().toLowerCase().trim()) {
              data[i]["notes"] = "You can upload data for selected casetype only!";
              primary['notes'] = data[i]["notes"];
            }
            else {
              primary['notes'] = '';
            }
          }
          else if (data[i]["billto"]) {
            if (!data[i]["services"]) {
              data[i]["notes"] = "Please upload the Service!";
              primary['notes'] = data[i]["notes"];
            }
            else {
              primary['notes'] = '';
            }
          }
          else if (data[i]["services"]) {
            if (!data[i]["billto"]) {
              data[i]["notes"] = "Please upload the BillTo!";
              primary['notes'] = data[i]["notes"];
            }
            else {
              primary['notes'] = '';
            }
          }
          else {
            primary['notes'] = '';
          }
          for (let [key, value] of Object.entries(data[i])) {
            if (primary['notes'] == '') {
              let flag = false;
              if (key.toString().toLowerCase() != 'entityname' && key.toString().toLowerCase() != 'format'
                && key.toString().toLowerCase() != 'entitytype') {
                if (value != null) {
                  if (value.toString().toLowerCase().trim() == 'null') {
                    flag = true;
                  }
                }
                primary[key] = value;
              }
              if (data[i]['oforgid'] && (isNaN(data[i]['oforgid'])) && primary['notes'] == '') {
                checkDataType = true;
                dataTypeCol = 'OFOrgId field accepts only number';
              }
              else {
                if (data[i]['oforgid'] && (!this.ofList.some(x => x.organizationid == data[i]['oforgid']))) {
                  primary['notes'] = "Invalid OfOrgId!";
                }
              }

              if (key.toLowerCase() == 'labaccountid' && primary['notes'] == '') {
                if (value) {
                  if (!this.checkBool(value)) {
                    checkDataType = true;
                    dataTypeCol = 'LabLocationID field accepts only boolean';
                  }
                  else {
                    value = (this.checkBool(value) && ((value.toString().toLowerCase() == 'true') || (value.toString() == '1'))) ? true : false;
                  }
                }
                primary[key] = value;
              }
              if ((primary['notes'] == '') && (key.toString().toLowerCase() == 'services')) {
                if (value) {
                  if (value.toString().includes(',')) {
                    let value1 = value.toString().split(',');
                    if (value1 && value1.length > 0) {
                      for (let i = 0; i > value1.length; i++) {
                        if (value1[i] && (!this.serviceList.some(x => x.AttributeName.toString().toLowerCase().trim() == value1[i].toString().toLowerCase().trim()))) {
                          primary['notes'] = "Invalid Service Type!";
                        }
                      }
                    }
                  }
                  else if (value && (!(value.toString().toLowerCase().trim() == 'all') && (!this.serviceList.some(x => x.AttributeName.toString().toLowerCase().trim() == value.toString().toLowerCase().trim())))) {
                    primary['notes'] = "Invalid Service Type!";
                  }
                }
                primary[key] = value;
              }
              if ((primary['notes'] == '') && (key.toString().toLowerCase() == 'billto')) {
                if (value) {
                  if (value.toString().includes(',')) {
                    let value1 = value.toString().split(',');
                    if (value1 && value1.length > 0) {
                      for (let i = 0; i > value1.length; i++) {
                        if (value1[0] && (!this.billToList.some(x => x.AttributeName.toString().toLowerCase().trim() == value1[0].toString().toLowerCase().trim()))) {
                          primary['notes'] = "Invalid BillTo!";
                        }
                      }
                    }
                  }
                  else if (value && (!(value.toString().toLowerCase().trim() == 'all') && (!this.billToList.some(x => x.AttributeName.toString().toLowerCase().trim() == value.toString().toLowerCase().trim())))) {
                    primary['notes'] = "Invalid BillTo!";
                  }
                }
                primary[key] = value;
              }

              if ((key.toString().toLowerCase() == 'format')) {
                if (value) {
                  if (!(value.toString().trim().endsWith("US%"))) {
                    primary['notes'] = "format should end with 'US%'";
                  }
                }
                primary[key] = value;
              }

              flag ? primary['notes'] = 'Null values exist!' : primary['notes']
              if (key.toString().toLowerCase() == 'entityname' || key.toString().toLowerCase() == 'entitytype'
                || key.toString().toLowerCase() == 'alternateformat') {
                if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                  mandatoryFieldEmpty = true;
                  primary[key] = value;
                }
                else if ((key.toString().toLowerCase() == 'entitytype')) {
                  if ((value) && (value.toString().toLowerCase().trim() != 'account casetype' && value.toString().toLowerCase().trim() != 'casetype' && value.toString().toLowerCase().trim() != 'organization' && value.toString().toLowerCase().trim() != 'all locations') && (!this.entityTypeList.some(x => x.entitytype.toString().toLowerCase().trim() == value.toString().toLowerCase().trim()))) {
                    primary['notes'] = "Invalid Entity Type. Only Location Casetype, Case Profile, Instrument Codes, Order Code & group are acceptable! OR Data is not configured for selected Entity Type";
                  }
                  primary[key] = value;
                }
                else if ((key.toString().toLowerCase() == 'alternateformat')) {
                  if (!(value && value.toString().trim().endsWith("NN%"))) {
                    primary['notes'] = "Alternateformat should end with 'NN%'";
                  }
                  primary[key] = value;
                }
                else {
                  primary[key] = value;
                }
              }
              if (checkDataType) {
                value = dataTypeCol;
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }
              if (mandatoryFieldEmpty) {
                value = 'Mandatory field(s) missing!';
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }
              else if (key == 'notes' && primary['notes'] == '') {
                value = data[i]['notes'];
                primary[key] = value;
              }
            }
            else {
              if (key == 'notes' && primary['notes'] != '') {
                value = primary['notes'];
              }
              primary[key] = value;
            }
          }
          this.gridArray.push(primary);
        }

        this.excelDataArray = this.gridArray;
        this.formatGridData = new CollectionView(this.gridArray)
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  checkBool(stringValue) {
    switch (stringValue?.toString().toLowerCase()?.trim()) {
      case "true":
      case "1":
      case "false":
      case "0":
      case 0:
      case 1:
      case null:
      case undefined:
        return true;
      default:
        return false;
    }
  }

  //#region Upload screen
  loadUploadScreen() {
    this.gridPage = false;
    this.uploadClicked = true;
    this.createScreen = false;
    this.gridDisplay = false;
    this.actionTitle = 'Upload Accession Prefix'

    this.AllFields[0]['EntityType'] = null
    this.AllFields[0]['EntityName'] = null
    // this.AllFields[0]['SequenceType'] = ''
    this.AllFields[0]['Format'] = null
    this.AllFields[0]['AlternateFormat'] = null
    this.AllFields[0]['BillTo'] = null
    this.AllFields[0]['Services'] = null
    this.AllFields[0]['OForgID'] = null
    this.AllFields[0]['LabAccountID'] = null
    this.AllFields[0]['CaseflowType'] = null

    this.updateFields[0]['FormatID'] = null
    this.updateFields[0]['EntityType'] = null
    this.updateFields[0]['EntityName'] = null
    // this.updateFields[0]['SequenceType'] = ''
    this.updateFields[0]['Format'] = null
    this.updateFields[0]['AlternateFormat'] = null
    this.updateFields[0]['BillTo'] = null
    this.updateFields[0]['Services'] = null
    this.updateFields[0]['OForgID'] = null
    this.updateFields[0]['LabAccountID'] = null
    this.updateFields[0]['CaseflowType'] = null

    this.MandatoryFields[0]['EntityType'] = null
    this.MandatoryFields[0]['EntityName'] = null
    this.MandatoryFields[0]['AlternateFormat'] = null
    // this.MandatoryFields[0]['Format'] = ''
    // this.MandatoryFields[0]['SequenceType'] = ''
  }
  //#endregion

  //#region Download All Fields
  downloadTemplate(type: String) {
    let templateType 
    let sample
    let sampleExcel = [{}]
    //= (type == 'All') ? this.AllFields : (type == 'Minimal') ? this.MandatoryFields : this.updateFields;
    if(type == 'All'){
      templateType = this.AllFields
      sample = true;
      sampleExcel = this.sampleDataAll
    }else if(type == 'Minimal'){
      templateType = this.MandatoryFields
      sample = true;
      sampleExcel = this.sampleDataMin
    }else if(type == 'Bulk Update'){
      templateType = this.updateFields
      sample = true;
      sampleExcel = this.sampleDataUpdate
    }
    let filename = 'Accession Prefix_' + type + ' Fields_' + this.destOrgid + '.xlsx';
    templateType.reverse()
    var ws = XLSX.utils.json_to_sheet(templateType);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'identityformat');
    sample ? ws = XLSX.utils.json_to_sheet(sampleExcel) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().match(/accepts only/i) ||
        this.gridArray[i]['notes'].toString().match(/Invalid/i) ||
        this.gridArray[i]['notes'].toString().match(/already exists/i) ||
        this.gridArray[i]['notes'].toString().match(/does not exist/i) ||
        this.gridArray[i]['notes'].toString().match(/should end/i) ||
        this.gridArray[i]['notes'].toString().match(/mandatory/i))) {
        existsCount++;
      }
    }
    if (existsCount > 0) {
      return true
    }
    return false;
  }

  //#region Delete Row (soft-delete)
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  onBackBtnClick() {
    if (this.createScreen) {
      this.formGroupDirective.resetForm();
      this.resetForm();
    }
    this.gridPage = true;
    this.editEnabled = false;
    this.uploadClicked = false;
    this.createScreen = false;
    this.removeGrid();
    this.refreshGrid()
  }

  //#region Upload ExcelData to Insert API
  uploadData() {
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      if (!this.createScreen) {
        let errorcount = 0;
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (!this.excelDataArray[i]["notes"]) {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/will be added/i)
            && !this.excelDataArray[i]["notes"].toString().match(/will be updated/i)
            && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
            errorcount++
          }
        }
        if (errorcount > 0) {
          this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
          return
        }
        if ((this.invalidColumns != "")) {
          this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
          return
        }
        else {
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let [key, value] of Object.entries(this.excelDataArray[i])) {
              if (value === "" || value === null) {
                this.excelDataArray[i][key] = null;
              }
              else {
                this.excelDataArray[i][key] = value.toString();
              }
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            delete this.excelDataArray[i]["slno"]
            delete this.excelDataArray[i]["tablename"]
            delete this.excelDataArray[i]["notes"]
            this.excelDataArray[i]["casetype"] = (this.excelDataArray[i]["casetype"] && this.excelDataArray[i]["casetype"].toString().trim()) ? this.excelDataArray[i]["casetype"].toString().trim() : null
            this.excelDataArray[i]["labaccountid"] = ((this.excelDataArray[i]["labaccountid"]) && (this.excelDataArray[i]["labaccountid"] == true || this.excelDataArray[i]["labaccountid"].toString() == 'true' || this.excelDataArray[i]["labaccountid"].toString() == '1')) ? 1 : 0;
            this.excelDataArray[i]["caseflowtype"] = ((this.excelDataArray[i]["caseflowtype"]) && (this.excelDataArray[i]["caseflowtype"] == true || this.excelDataArray[i]["caseflowtype"].toString() == 'true' || this.excelDataArray[i]["caseflowtype"].toString() == '1')) ? 'QC' : null;
            this.excelDataArray[i]["services"] = ((this.excelDataArray[i]["services"] && this.excelDataArray[i]["services"].toString().trim().toLowerCase() == 'all') &&
              (this.excelDataArray[i]["billto"] && this.excelDataArray[i]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.excelDataArray[i]["services"] ? "" : this.excelDataArray[i]["services"].toString());
            this.excelDataArray[i]["billto"] = ((this.excelDataArray[i]["services"] && this.excelDataArray[i]["services"].toString().trim().toLowerCase() == 'all') &&
              (this.excelDataArray[i]["billto"] && this.excelDataArray[i]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.excelDataArray[i]["billto"] ? "" : this.excelDataArray[i]["billto"].toString());
          }
        }
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          this.excelDataArray[i]["casetype"] = (this.excelDataArray[i]["casetype"] && this.excelDataArray[i]["casetype"].toString().trim()) ? this.excelDataArray[i]["casetype"].toString().trim() : null
          this.excelDataArray[i]["labaccountid"] = ((this.excelDataArray[i]["labaccountid"]) && (this.excelDataArray[i]["labaccountid"] == true || this.excelDataArray[i]["labaccountid"].toString() == 'true' || this.excelDataArray[i]["labaccountid"].toString() == '1')) ? 1 : 0;
          this.excelDataArray[i]["caseflowtype"] = ((this.excelDataArray[i]["caseflowtype"]) && (this.excelDataArray[i]["caseflowtype"] == true || this.excelDataArray[i]["caseflowtype"].toString() == 'true' || this.excelDataArray[i]["caseflowtype"].toString() == '1' ||
            this.excelDataArray[i]["caseflowtype"].toString().toLowerCase() == 'qc')) ? 'QC' : null;
          this.excelDataArray[i]["billto"] = ((this.excelDataArray[i]["billto"] && this.excelDataArray[i]["billto"].toString().trim()) ? (this.excelDataArray[i]["billto"]).toString() : '');
          this.excelDataArray[i]["services"] = ((this.excelDataArray[i]["services"] && this.excelDataArray[i]["services"].toString().trim()) ? (this.excelDataArray[i]["services"]).toString() : '');
          this.excelDataArray[i]['formatids'] = this.excelDataArray[i]['formatid'] ? this.excelDataArray[i]['formatid'] : null
          this.excelDataArray[i]['formatid'] = this.excelDataArray[i]['formatid'] ? this.excelDataArray[i]['formatid'].toString().split(',')[0] : null;
        }
      }
      this.ngxService.start();
      if (this.excelDataArray.some(x => x.formatid)) {
        this.VitalHttpServices.EditIdentityFormats(this.excelDataArray, this.destDeployment).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.length > 0 && result.every(va => va.Status == 'Success' || va.Status == 'Ignored')) {
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              this.excelDataArray = [];
              this.excelDataArray = this.commonService.convertObjKeysToLower(result);
              this.excelGridData(this.excelDataArray);
              this.auditDetails({}, result, "updated");
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error);
        })
      }
      else {
        this.VitalHttpServices.bulkUploadIdentityFormats(this.excelDataArray, this.destDeployment).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (!this.createScreen) {
              if (result.length > 0) {
                this.postUpload = true;
                this.postDownload = true;
                this.showDelete = false;
                this.excelDataArray = [];
                this.excelDataArray = this.commonService.convertObjKeysToLower(result);
                this.excelGridData(this.excelDataArray);
              }
              else {
                this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
              }
            }
            else {
              if (result.length > 0 && result.every(va => va.Status == 'Success')) {
                this.ofId = null
                this._snackbar.open('Accession prefix created successfully', 'Close');
                if (this.createScreen) {
                  if (this.action == 'new') {
                    setTimeout(() => {
                      this.refreshGrid(this.action);
                    }, 20);
                    this.formGroupDirective.resetForm();
                    this.resetForm();
                  }
                  else {
                    this.actionTitle = "Accession Prefix List"
                    this.onBackBtnClick();
                    this.formGroupDirective.resetForm();
                    this.resetForm();
                  }
                }
              }
              else {
                this._snackbar.open(result[0]['notes'], 'Close');
              }
            }
            this.auditDetails({}, result, "saved");
          }
          else {
            this.createScreen ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') :
              this._snackbar.open('Accession prefix creation failed!', 'Close');
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error);
        })
      }
    }
  }
  //#endregion

  //#region Back to upload
  refreshGrid(createNew?) {
    let queryVariable = { accid: this.templateData.cardIdentifier.toString(), orgid: this.destOrgid.toString(), casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery("getidentityformats");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          this.templateData['submenuData'] = [...data.data.submenuData, ...data.data.submenuData1, ...data.data.submenuData2]
        }
        if (!createNew) {
          this.gridPage = true;
          this.editEnabled = false;
          this.uploadClicked = false;
          this.createScreen = false;
          this.removeGrid();
          if (this.gridPage) {
            if (this.templateData['submenuData'].length > 0) {
              this.addformatGridData(this.templateData['submenuData']);
            } else {
              this.addformatGridData([]);
            }
          }
        }
      }
      else {
        this._snackbar.open('Something went wrong! Please refresh the menu', 'Close');
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion

  ExportToExcel(flex) {
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    let filename = 'Accession Prefix_' +  this.templateData.secondarykeys.OrganizationId.toString();
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      filename + ".xlsx",
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
  }

  // export grid data
  exportExcelData() {
    let excel = [];
    const view: any = this.flex.collectionView;
    let oldPgSize = view.pageSize;
    view.pageSize = 0;
    this.flex.beginUpdate();
    let rows = this.flex.rows
    rows.find((e: any) => {
      if (!e._data._gd) {
        delete e._data["organizationid"];
        delete e._data["casetype"];
        excel.push(e._data);
      }
    });

    let filename = 'Accession Prefix_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'identityformat');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    this.flex.endUpdate();
  }

  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Upload":
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = '';
    msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';

    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj.formatid, 'Identity Formats', 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], this.selectedrowData);
      this.ngxService.stop();
    } if (info == 'Deleted') {
      this.commonService.createActivityTracker(info, newObjArray[0].formatid, 'Identity Formats', 'Audit', newObjArray[0], {});
    }
    else {
      /*New records*/
      this.ngxService.start();
      for (let i = 0; i < newObjArray.length; i++) {
        this.commonService.createActivityTracker(info, newObjArray[i].ID, 'Identity Formats', 'Audit', newObjArray[i], {});
      }
      this.ngxService.stop();
    }
  }

  deleteRecord(grid, event) {
    if(this.hideDeleteBtn){
      this._snackbar.open("User is not authorized !","Close");
      return;
    }
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    let arrayObj = [{
      formatid: selectedrowData.formatid,
    }];
    // arrayObj[0]['formatid'] = arrayObj[0].formatid.toString().split(',')[0];
    // if ((selectedrowData.billto && selectedrowData.billto.toString().includes(','))
    //   || (selectedrowData.services && selectedrowData.services.toString().includes(','))) {
      arrayObj[0]['formatids'] = selectedrowData.formatid;
      // arrayObj[0]['formatid'] = selectedrowData.formatid.toString().split(',')[0];
      arrayObj[0]['billto'] = selectedrowData.billto;
      arrayObj[0]['services'] = selectedrowData.services;
    // }

    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "", message: "", alert: "Clicking on Yes, the record will be deleted! Do you want to continue ?", continue: "Yes", cancel: "No" }
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.VitalHttpServices.DeleteIdentityFormats(arrayObj, this.destDeployment).subscribe(result => {
          if (!result.errors) {
            this._snackbar.open('Deleted successfully!', 'Close');
            this.refreshGrid();
            this.auditDetails({}, arrayObj, 'Deleted');
          }
          else {
            this._snackbar.open('Deleted failed!', 'Close')
          }
        }, error => {
          console.log(error);
        })
      }
      else {
        return
      }
    }, error => {
      console.log(error);
    });
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
