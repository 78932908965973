import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, tap } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AttachmentsComponent implements OnInit, OnChanges{
  @Input() accountId: string | number | null = ''
  @Input() loggedInUserId: string | number | null = ''
  @Input() userId: string | number | null = ''
  @Input() accountOrgId: string | number | null = ''
  @Input() type: string = 'Accounts'
  @Input() category: string = 'Accounts'
  @Input() Oper: 0 | 1 = 0
  @Input() formcontrolname : string = ''
  @Input() clearAll : boolean = false
  @Output() emitFileIds = new EventEmitter()
  attachments$: any
  fileIds: string[] = []
  url: string = '';

  constructor(
    private _LabadminService: LabadminService,
    private _snakbar : MatSnackBar,
    private _dailogService: DialogService,
    private _fcd:  FormGroupDirective
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.clearAll && this.clearAll){
      this.ngOnInit()
      this.fileIds = []
      //this.clearAll = false
    }
  }

  ngOnInit(): void {
    if (this.fileIds.length != 0 || this.accountId != 0 || this.type != 'Accounts') {
      this.attachments$ = this._LabadminService.getAttachmentsAndNotes(String(this.userId), String(this.accountId), String(this.loggedInUserId), this.Oper, this._LabadminService.deploymentKey).pipe(
        map((attachment: any) => attachment.Attachements.filter((attc: any) => this.fileIds.includes(attc.Id) || this.accountId != 0 || this.type != 'Accounts')
          .map((attc: any) => ({
            ...attc,
            disableView: !this.enableView(attc.MediaType)
          }))),
        tap((res: any) => console.log(res))
      )
    }
  }

  ValidateAttachment(AttachmentToUpload: any) {
    //this.displayImagePdfTxt(AttachmentToUpload[0], AttachmentToUpload[0].name)
    const regexp = new RegExp(`^(${mediaConstants.attachments.filetype.replace(/,/g,'|')})$`)
    if (AttachmentToUpload != undefined) {
      if (AttachmentToUpload.length > 5) {
        this._dailogService.openCommonDialog(
          'Maximum limit exceeded',
          `Please upload a maximum of 5 files.`,
          '',
          'OK',
          'dontshow'
        )
        //this.showCommonPopup('Maximum limit exceeded', 'Please upload a maximum of 5 files.', 'center', '5 files')
        return
      }
      for (const files of AttachmentToUpload) {        
        var ext = /[^\.]*$/.exec(files.name);
        if ((ext && regexp.test(ext[0]))) {
          this._dailogService.openCommonDialog(
            `Invalid File "${files.name}"`,
            `Please upload valid files.`,
            '',
            'OK',
            'dontshow'
          )
          //this.showCommonPopup('Invalid File', 'Please upload valid files.')
          return
        }
        if(files.size > Number(mediaConstants.attachments.fileSize) * (1024 * 1024)){
          this._dailogService.openCommonDialog(
            `File size exceeded for file ${files.name}`,
            `Please upload files less than ${mediaConstants.attachments.fileSize}mb.`,
            '',
            'OK',
            'dontshow'
          )
          //this.showCommonPopup('File size exceeded.', `Please upload files less than ${mediaConstants.attachments.fileSize}mb.`,'center',`less than ${mediaConstants.attachments.fileSize}mb`)
          return
        }
      };
      var attachmentsData = this.GetDataForAttachmnet(AttachmentToUpload)
      this._LabadminService.uploadSuplements(attachmentsData, this._LabadminService.deploymentKey)
      .subscribe({
        next: (res : any) => {
          this.afterFileUpload(res)
        },
        error: (error: any) => {
          console.error(error)
          this._dailogService.openCommonDialog(
            `Failed to upload document`,
            `Something went wrong while uploading ${error.error.fileName}. Please try after sometime.`,
            '',
            'OK',
            'dontshow'
          )
          //this.showCommonPopup('Failed to upload documents', `Something went wrong while uploading ${error.error.fileName}. Please try after sometime.`, 'center', error.error.fileName)
          this.afterFileUpload(error.error.successFileIds.split(','))          
          //this.handleError()
        }
      })
      // .add(() => )
    }
  }

  private afterFileUpload(fileIds : string[]){
    if (this.accountId != 0 || this.type != 'Accounts') {
      this._snakbar.open("Documents Uploaded Successfully.", 'success')
    }
    else {
      if(fileIds.length != 0){
        this.fileIds.push(...fileIds)
        this._fcd.control.get(this.formcontrolname).setValue(this.fileIds.join(','))
        //this.emitFileIds.emit(this.fileIds.join(','))
      }
    }
    this.ngOnInit()
  }

  GetAttachmentToUpload(file: any) {
    let AttachmentToUpload = file.target?.files
    this.ValidateAttachment([...AttachmentToUpload])
  }

  GetDataForAttachmnet(AttachmentToUpload: any): FormData {
    var formData: any = new FormData();

    formData.append('Type', this.type);
    formData.append('category', this.category);
    formData.append('accountId', this.accountId);
    formData.append('pathuserid', this.userId);
    AttachmentToUpload.forEach((file : any) => {
      formData.append('files', file);      
    });

    return formData
  }

  deleteAttachment(fileId: string, fileName: string) {
    if (this.accountId === 0 && this.type === 'Accounts') this.deleteAttachmenthelper(fileId)
    else {
      this._dailogService.openCommonDialog(
        'Delete Attachment',
        `You are about to delete the file ${fileName}. This action cannot be undone. Do you want to proceed?`,
        '',
        'OK',
        'Cancel'
      ).afterClosed().subscribe({
        next : (res) => {
          res && this.deleteAttachmenthelper(fileId)
        }
      })
      // let ref = this.showCommonPopup("Delete Attachment", `You are about to delete the file ${fileName}. This action cannot be undone. Do you want to proceed?`, 'justify', fileName, true)
      // ref.closed.subscribe((message: string) => {
      //   if (message === 'success') {
      //     this.deleteAttachmenthelper(fileId)
      //   }
      // })
    }
  }

  deleteAttachmenthelper(fileId: string) {
    this._LabadminService.deleteSupplements(fileId, '', this._LabadminService.deploymentKey).subscribe({
      next: () => {
        this.ngOnInit()
        if (this.accountId != 0 || this.type != 'Accounts') {
          this._snakbar.open("Attachment Deleted Successfully", 'success')
        }
        else {
          this.fileIds = this.fileIds.filter((id: string) => id != fileId)
          this._fcd.control.get(this.formcontrolname).setValue(this.fileIds.join(','))
          //this.emitFileIds.emit(this.fileIds.join(','))
        }
      },
      error: (error: any) => this.handleError(error)
    })
  }

  downloadAttachments(fileId: string, fileName: string) {
    this._LabadminService.ViewSupplements(fileId, this._LabadminService.deploymentKey).subscribe({
      next: (file) => {
        importedSaveAs(file, fileName)
      },
      error: (err) => this.handleError(err)
    })
  }

  viewAttachment(fileId: string, fileName: string) {
    this._LabadminService.ViewSupplements(fileId, this._LabadminService.deploymentKey).subscribe({
      next: (file) => {
        this.displayImagePdfTxt(file, fileName)
      },
      error: (err) => this.handleError(err)
    })
  }

  displayImagePdfTxt(data: any, fileName: string) {
    const url = window.URL.createObjectURL(data);
    const documentType = this.getDocumentType(data.type);

    if(documentType === "image"){
      this.url = url
      return
    }
    else{
      this._dailogService.openAttachmentsViewDailog(data, fileName)
    }
  }

  private getDocumentType(contextType: string) {
    if (contextType === 'application/pdf') return 'pdf';
    else if (
      contextType === 'image/jpeg' ||
      contextType === 'image/png' ||
      contextType === 'image/jpg'
    )
      return 'image';
    return 'text';
  }

  handleError(err){
    console.error(err)
    this._snakbar.open("Something went wrong. Try after some time", 'error')
  } 

  enableView(contextType: string) {
    return contextType === 'application/pdf' || contextType === 'image/jpeg' || contextType === 'image/png' || contextType === 'image/jpg' || contextType === 'text/plain' || contextType === 'application/octet-stream'
  }

  onDrop(files : any) {
    this.ValidateAttachment([...files]);
  }
}

export class mediaConstants{
  public static readonly attachments = { filetype : 'bat,exe,dll', fileSize : '5' /* only in mb */}
  public static readonly logo = { filetype : 'jpg,png,jpeg', fileSize : '5' /* only in mb */}
}