<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="!checkNPIDetails() && !noDataFoundFlag" [diameter]="50">
  </mat-spinner>

  <div class=" col-sm-12 row" *ngIf="checkNPIDetails() && !noDataFoundFlag" style="font-size: 12px;">
    <div class="col-sm-12" *ngIf="npiDetails!={}">
      <div class="row ">
        <span class=" col-sm-3 _600 text-muted">NPI </span>
        <div class=" col-sm-8 pl-0 d-flex">
          <div class="mx-2"> :</div>
          <strong>
            {{npiDetails?.['npi']}}
            <span [ngClass]="npiDetails.basic?.['status']=='A' ?'active_lable':'Inactive_lable'">{{
              npiDetails.basic?.['status']=='A' ?'Active':npiDetails.basic?.['status']}}</span>
          </strong>
        </div>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-3 _600 text-muted">Enumerate Date</span>
        <span class="mx-2">: </span>
        <strong class=" col-sm-6 pl-0">{{npiDetails?.['enumerationdate']}} </strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-3 _600 text-muted">NPI Type</span>
        <span class="mx-2">: </span>
        <strong class=" col-sm-6 pl-0" *ngIf="fromLocation != 'location'">NPI-1 Individual</strong>
        <strong class=" col-sm-6 pl-0" *ngIf="fromLocation == 'location'">NPI-2 Organization</strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-3 _600 text-muted">Sole Proprietor</span>
        <span class="mx-2">: </span>
        <strong class=" col-sm-6 pl-0"> {{npiDetails.basic?.['soleproprietor']}} </strong>
      </div>

      <div class="row mb-1">
        <span class="col-sm-3 _600 text-muted">Mailing Contact</span>
        <span class="mx-2">: </span>
        <strong class="col-sm-6 pl-0">
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[0].address1,npiDetails.address[0].address2]) + ','}}</div>
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[0].city,npiDetails.address[0].state,npiDetails.address[0].zip])
            + ','}}</div>
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[0].telephonenumber,npiDetails.address[0].faxnumber])+ '.'}}
          </div>
        </strong>
      </div>

      <div class="row mb-1">
        <span class="col-sm-3 _600 text-muted">Primary Practice Contact</span>
        <span class="mx-2">: </span>
        <strong class="col-sm-6 pl-0">
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[1].address1,npiDetails.address[1].address2]) + ','}}</div>
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[1].city,npiDetails.address[1].state,npiDetails.address[1].zip])
            + ','}}</div>
          <div class="col-sm-12 _600 p-0">{{
            showAddressValue([npiDetails.address[1].telephonenumber,npiDetails.address[1].faxnumber])+ '.'}}
          </div>
        </strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-3 _600 text-muted">Secondary Practice Contact</span>
        <span class="mx-2">: </span>
        <strong class=" col-sm-6 pl-0"></strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-3 _600 text-muted">Health Information Exchange</span>
        <span class="mx-2">: </span>
        <strong class=" col-sm-6 pl-0"></strong>
      </div>

      <div class="row mt-2 ">
        <span class=" col-sm-3 _600 text-muted">Other Identifiers </span>
        <div class=" col-sm-9 pl-0 px-0 d-flex">
          <div class="mx-2"> :</div>
          <div class="grid_view w-100">
            <div class="header_title px-2">
              <div class="row">
                <div class="col-sm-4 _600">Issuer</div>
                <div class="col-sm-4 _600">State</div>
                <div class="col-sm-4 _600">Number</div>
              </div>
            </div>
            <div class="grid-data-content">
              <div class="row" *ngFor="let item of npiDetails['identifiers']">
                <div class="col-sm-4 _600">{{item.desc}}</div>
                <div class="col-sm-4 _600">{{item.state}}</div>
                <div class="col-sm-4 _600">{{item.identifier}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <span class=" col-sm-3 _600 text-muted">Taxonomy </span>

        <div class=" col-sm-9 pl-0  px-0 d-flex">
          <div class="mx-2"> :</div>
          <div class="grid_view w-100">
            <div class="header_title px-2">
              <div class="row">
                <div class="col-sm-3 _600">Primary Taxonomy</div>
                <div class="col-sm-4 _600 pl-4">Selected Taxonomy</div>
                <div class="col-sm-2 _600">State</div>
                <div class="col-sm-3 _600">License Number</div>
              </div>
            </div>
            <div class="grid-data-content">
              <div class="row" *ngFor="let item of npiDetails['taxonomies']">
                <div class="col-sm-3 _600">{{item.primary == "true" ? "Yes" : item.primary}}</div>
                <div class="col-sm-4 _600 pl-4">{{item.code +'-'+ item.desc}}</div>
                <div class="col-sm-2 _600">{{item.state}}</div>
                <div class="col-sm-3 _600">{{item.license}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
    <span class="col-sm-12 nodata-wrapper">No Data Found</span>
  </div>
</div>
