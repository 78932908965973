import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { LabadminService } from '../../labadmin.service';
import { LabUsersService } from 'src/app/core/utility/lab-users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { enableCreationMode } from '../../store/actions/creation.actions';
import { addNabvTab } from '../../store/actions/navbar.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  @Input() gridData;
  @Input() inputObject;
  globalSearchDetails: any;
  userSessionDetails;
  gridHeaderList: any;
  gridHeader: any;
  listHeaderbackup: any;
  showDetails: any;
  showloader: boolean = false;
  @Output() closeGlobalComp: EventEmitter<any> = new EventEmitter<any>();

  constructor(private LabadminSession: LabadminSessionService, private labService: LabadminService, public templateHeaders: LabUsersService,
    private router: Router,    private store:Store, private route:ActivatedRoute  ) {
  }

  ngOnInit() {
    this.getColumnsForGlobalSearch();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.gridData) {
      this.getColumnsForGlobalSearch();
    }
    else {
      this.showloader = true;
      this.getColumnsForGlobalSearch();
      setTimeout(() => {
        this.showloader = false;
      }, 1500);
    }
  }
  getColumnsForGlobalSearch() {
    if (this.inputObject.dropdownValue.toLowerCase() == 'clients') {
      this.gridHeaderList = this.templateHeaders.templateListClient;
      for (let i = 0; i < this.gridHeaderList.length; i++) {
        if (this.gridHeaderList[i].templateName == 'Clients') {
          this.gridHeader = this.gridHeaderList[i].headers;
          this.listHeaderbackup = JSON.parse(JSON.stringify(this.gridHeader));
        }
      }
    }
    else {
      this.gridHeaderList = this.templateHeaders.templateListLabUser;
      for (let i = 0; i < this.gridHeaderList.length; i++) {
        if (this.gridHeaderList[i].templateName == 'Lab Users') {
          this.gridHeader = this.gridHeaderList[i].headers;
          this.listHeaderbackup = JSON.parse(JSON.stringify(this.gridHeader));
        }

      }
      this.gridData = this.groupData(this.gridData);//only for users
    }
   
  }
  closeUserDetails(event) {
    this.showDetails = event;
  }
  cardSelect(event) {
    this.showDetails = event;
    this.userSessionDetails = event;
  }
  homeClicked() {
    this.closeGlobalComp.emit();    
  }
  groupData(data: any[]) {
    // Grouping data by commonKey
    if (!data) {
      return;
    }
    const groupedData: { [key: string]: any[] } = data.reduce((acc, obj) => {
      const key = obj.commonKey;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    // Processed data to display in grid
    const processedData = Object.values(groupedData).map((group: any[]) => {
      const sortedGroup = group.sort((a, b) =>
        a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1
      );
      const roles = sortedGroup.map((obj) => obj.roles).join(', ');
      return {
        ...sortedGroup[0],
        roles: roles,
      };
    });
    return processedData;
  }
  redirectToCreateEditPage(userid:number=0)
  {
    enableCreationMode()
    let htext = userid?'Edit Provider':'Create Provider'
    let routerLink = userid?`/labadmin/clients/orders/edit/${userid}`: '/labadmin/clients/orders/create'
    let obj : any = {Htext : htext, Level: 1, routerLink : routerLink}
    this.store.dispatch(addNabvTab({value: obj}));
    userid?(this.router.navigate(['edit',userid],{relativeTo:this.route}),this.showDetails=false):this.router.navigate(['create'],{relativeTo:this.route})
  }
}

