<!-- <tab *ngFor="let aTab of SearchList" id="{{aTab.GUID}}" [TabGUID]="aTab.GUID" [Count]="aTab.Count"
    [TabName]="aTab.TabName" [active]="aTab.active" (deselect)="aTab.active = false" [disabled]="true"
    [Removable]="aTab.Removable"> -->
    <vs-tabset (currentTabSelected)="onClickInTab($event)" *ngIf="EntitytabList.length > 0" >
      <tab *ngFor="let aTab of EntitytabList" id="{{aTab.GUID}}" [TabGUID]="aTab.GUID" [Count]="aTab.Count" [TabName]="aTab.TabName"
        [active]="aTab.active" (deselect)="aTab.active = false" [disabled]="aTab.disabled" [Removable]="aTab.Removable">
        <!-- entity-grid -->
        <div class="row m-t-sm p-r">
          <div [ngClass]="oClose===true?'col-9 scroll-height':'col-12  scroll-height'" id="scrollMe" (scroll)="OnScroll($event)"
            [style.min-height.px]="srcheight">
    
            <div class="col-12 pointer" *ngIf="aTab.Count > 0 && (aTab.ResultContent[0].Context.toLowerCase() =='users' || aTab.ResultContent[0].Context.toLowerCase() =='userid' || aTab.ResultContent[0].Context.toLowerCase() =='pathologist' || aTab.ResultContent[0].Context.toLowerCase() =='physician')">
              <div class="row col-12 p-0 m-0 entity-grid">
                <!-- <div class="col-12 p-0"> -->
                  <wj-flex-grid class="user-grid" (click)=" openUserCard(grid,$event)" (formatItem)="formatItem(grid, $event)"
                    [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="aTab.ResultContent" #grid
                    (initialized)="initGrid(grid)">
                    <wj-flex-grid-column [width]="'*'" [minWidth]="40" [binding]="col.binding" [header]="col.header" *ngFor="let col of columns"
                      [allowResizing]="true" [allowDragging]="false" [format]="'d'">
                    </wj-flex-grid-column>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                  </wj-flex-grid>
                <!-- </div> -->
              </div>
            </div>
    
            <div *ngIf="aTab.Count > 0 && (aTab.ResultContent[0].Context.toLowerCase() !=='users' && aTab.ResultContent[0].Context.toLowerCase() !=='userid' && aTab.ResultContent[0].Context.toLowerCase() !=='pathologist' && aTab.ResultContent[0].Context.toLowerCase() !=='physician')">
              <div  *ngFor="let res of aTab.ResultContent;index as i" id="mainParentDiv">
                <div class="col-12 pointer" *ngIf="res.Context.toLowerCase() != 'case' && res.Context.toLowerCase() != 'patient'"
                  (click)="DisplayCardClick('',res)">
                  <div class="card vt-card">
                    <div class="card-block  p-t-xs p-b-xs">
                      <div class="row p-0">
                        <div class="col-12">
                          <div class="row">
                            {{i + 1}}
                            <div class="col-8 display-text">
                              <div class="card-headers">
                                <a><span class="_500 text-sm m-r-sm card-header-text" [innerHTML]="res.DisplayText"></span></a>
                              </div>
                            </div>
                            <div class="col-3 p-0">
    
                              <span class="text-muted pull-right m-r-sm time-ago">
                                <em class="fa fa-fw fa-clock-o"></em>
                                <span>{{timeSince(res.DisplayField[res.TimeAgoDate])}}
                                  ago</span>
                              </span>
                            </div>
                          </div>
                          <div class="row b-t blue-50 m-t-xs">
                            <div class="col-12 date-align">
                              <div class="field-data" *ngFor="let scheme of res.SchemeProperty;index as i">
                                <div class="inline p-x b-r search-align text-align:left {{scheme.ColumnLength}}" attr.id="Search{{i}}"
                                  *ngIf="scheme.IsListing == true">
                                  <small class="text-xs text-muted">{{getHeaders(scheme.DisplayText)}}</small>
                                  <div *ngIf="scheme.Type == date;then Normal else DatePipe">
                                  </div>
                                  <ng-template #Normal>
                                    <p class="ellipsisfull h6 text-xs block m-0" placement="top" matTooltip="{{res.DisplayField[scheme.FieldName]}}"
                                      container="body">
                                      {{ scheme.FieldName == 'status' ? titleCaseWord(res.DisplayField[scheme.FieldName]) :
                                      (res.DisplayField[scheme.FieldName])}}
                                    </p>
                                  </ng-template>
                                  <ng-template #DatePipe>
                                    <p class="ellipsisfull h6 text-xs block m-0" placement="top" container="body"
                                      matTooltip="{{res.DisplayField[scheme.FieldName] | date:'mediumDate'}}">
                                      {{res.DisplayField[scheme.FieldName] |
                                      date:'mediumDate'}}
                                    </p>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            <div class="col-12 pointer"
                *ngIf="aTab.ResultContent[0].Context.toLowerCase() === 'case' || aTab.ResultContent[0].Context.toLowerCase() === 'patient'">
                <div class="row col-12 p-0 m-0 entity-grid">
                  <div class="col-12 p-0">
                    <wj-flex-grid class="user-grid" [headersVisibility]="'Column'" [isReadOnly]="true"
                      [itemsSource]="aTab.ResultContent" #grid (initialized)="initGrid(grid)" >
                      <wj-flex-grid-column [header]="col.header" [binding]="col.binding" 
                        [width]="getColumnWidth(col.binding)" *ngFor="let col of patientgrid" 
                        [allowResizing]="true" [allowDragging]="false" [format]="'d'">
                      </wj-flex-grid-column>
                      <wj-flex-grid-column [header]="'Actions'" align="center" [width]="200" [isReadOnly]="true">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'"  let-cell="cell" let-row="row">
                          <div class="row d-flex">
        
                            <button mat-icon-button [matMenuTriggerFor]="menu" title="Assignment"> 
                                <img src="../../../assets/images/assignment.svg" alt="assignment"  width="6px"
                                height="auto" class="md-24 icon-size assign-icon p-0" />                    
                            </button>
        
                            <mat-menu #menu="matMenu"> 
                              <button mat-menu-item  (click)="CaseActions('assign',$event,row)" >
                                <img src="../../../assets/images/account_circle.png" alt="circle"  class="icon-size p-1">
                                <span >Assign</span>
                              </button>
        
                              <button mat-menu-item (click)="CaseActions('unassign',$event,row)">
                                <img src="../../../assets/images/no_account.png" alt="account" class="icon-size p-1">
                                <span>Un-Assign</span>
                              </button>
                              <button mat-menu-item (click)="CaseActions('reassign',$event,row)">
                                <img src="../../../assets/images/supervised_user_circle.png" alt="supervise" 
                                  class="icon-size p-1">
                                <span  >Re-Assign</span>
        
                              </button>
                            </mat-menu>
                            
                            <button class="edit-delete-btn-wrap p-0">
                              <img src="../../../assets/images/reset.svg" alt="reset" id="reset" title="Reset" width="24px"
                                height="auto" (click)="CaseActions('reset',$event,row)">
                            </button>
                            <button class="edit-delete-btn-wrap">
                              <img src="../../../assets/images/pdf.png" alt="pdf" id="Report"
                                (click)="CaseActions('Report',$event,row)" class="icon-size" title="Report" width="10px"
                                height="auto">
                            </button>
                            <button  class="edit-delete-btn-wrap">
                              <img src="/assets/images/ic-popup.png" alt="popup" id="openToDX"
                                (click)="CaseActions('openToDX',$event,row)" title="Case-360" title="Re-Direct" width="18px"
                                height="auto">
                            </button>
                            <button  class="edit-delete-btn-wrap">
                              <img src="../../../assets/icons/Case_360.svg" alt="Case360"
                              (click)="CaseActions('case360',$event,row)" id="Case360" width="26px" title="Case-360"
                              height="auto">
                            </button>
                          </div>
                        </ng-template>
                      </wj-flex-grid-column>
                      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                  </div>
                </div>
              </div>
    
    
    
              </div>
            </div>
            <!-- <div class="row m-b-sm" *ngFor="let res of ResultContent | FilterDataOn : FilterOnValue: Filterfield "> -->
    
            <div class="row" *ngIf="aTab.ResultContent.length==0">
              <div class="col-12" style="color: #80808078;">
                <div class="row noDataFound mt-1">
                  <div class="col-12 align-center">
                    <p class="text-md _500 mb-0">No result found for the below search criteria</p>
                  </div>
    
                  <div class="col-12 row message align-center word-break">
                    <span>Entity: {{EntityName}}</span>
                    <span>Search Text: {{SearchText}}</span>
                  </div>
                </div>
    
                <div class="row" *ngIf="advNoResult != null">
                  <div class="col-4">
                  </div>
                  <div class="col-8 message">
                    <p class="text-md _500">Advance filter
                      parameters</p>
                  </div>
                </div>
                <div class="row" *ngFor="let adv of advNoResult | keyvalue">
                  <div class="col-4">
                  </div>
                  <div class="col-8 message">
                    <p class="advresultmsg">{{adv.key}}: {{adv.value}}</p>
                  </div>
                </div>
              </div>
            </div>
    
          <!-- Filter Expand Collapse -->
          <div [ngClass]="oClose===true?'col-3':'col-0'">
            <nav class="navside white  box-shadow-z0 panel-right-filter" [ngClass]="oClose===false?'disclose':''">
              <div class="col-12 filter-parent-height pb-2 px-2" style="border: 1px solid #e4d9d9;border-radius: 5px;">
                <div class="box filter-box">
                  <div class="row" style="margin: 0">
                    <div class="col-4">
                      Filters
                    </div>
                    <div class="col-8 flex-right">
                      <a class="text-danger" (click)="fnToggleFilter(true)"><em class="fa fa-remove text-xs"></em></a>
                    </div>
                  </div>
    
                  <div class="box-body" *ngFor="let facet of aTab.FacetsContent | keyvalue">
                    <a class="drop-down-caret _400 filter-key" data-toggle="collapse" href="#{{facet.key}}" style="text-transform: capitalize">
                      {{facet.key | ReplaceFilterText }}
                    </a>
                    <div id="{{facet.key}}">
                      <div class="collapse show list-unstyled m-t-n-sm">
                        <mat-radio-group>
                          <mat-radio-button #checkboxes class="col-12" color="primary" value="{{fd.value}}" (change)="fnFilterData(fd.value,facet.key, $event)"
                            *ngFor="let fd of facet.value;let ct = index">
                            <span style="font-size: 11px;">{{fd.value}}
                              ({{fd.count}})</span>
                          </mat-radio-button>
                        </mat-radio-group>
                        <!-- <label class="filter-values">
                          <input type="radio" #checkboxes id="check" value="{{fd.value}}" name="{{fd.value}}"
                            (change)="fnFilterData(fd.value,facet.key, $event.target.checked,fd.count)">
                          <em></em>{{fd.value}} ({{fd.count}})
                        </label> -->
                      </div>
                      <!-- <div class="collapse show list-unstyled m-t-n-sm" *ngFor="let fd of facet.value">
                        <div class="checkbox" style="padding-bottom: 0.5rem;">
                          <label class="ui-check filter-values">
                            <input type="radio" #checkboxes id="check" value="{{fd.count}}"
                              (change)="fnFilterData(fd.value,facet.key, $event.target.checked,fd.count)">
                            <em></em>{{fd.value}} ({{fd.count}})
                          </label>
                        </div>
                      </div> -->
                    </div>
                  </div>
    
                </div>
                <div class="box-footer" style="padding: 0">
                  <div class="row mt-4">
                    <div class="col-6">
                      <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="fnClearFilter()"
                        [disabled]="isDisabled" style="width:-webkit-fill-available"> Clear All</button>
                    </div>
                    <div class="col-6">
                      <button mat-raised-button class="admin-btn-success" type="button" (click)="fnApplyFilter()"
                        [disabled]="isDisabled" style="width: -webkit-fill-available"> Apply
                        Filter</button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div (click)="fnToggleFilter(false)" class="toggle-show-right cursor hidden-xs flex align-center" *ngIf="isFilterVisible">
            <em class="fa fa-angle-left" style="font-size: 1.2rem;font-weight: 700;margin-right: 10px;"></em>
            <span translate="">Filter</span>
          </div>
    
        </div>
      </tab>
    
      <tab *ngFor="let aTab of EachEntityTabArray;let tabIndex = index" id="{{aTab.GUID}}" [TabGUID]="aTab.GUID" [Count]="aTab.Count"
        [TabName]="aTab.TabName" [active]="aTab.active" (select)="aTab.active = true" (deselect)="aTab.active = false"
        [disabled]="aTab.disabled" [Removable]="aTab.Removable" (removed)="removeThisTab($event)">
        <app-vital-admin-search-connector [currentTab]='aTab.Content.DisplayField.locationid' [searchTabContent]='aTab.Content'>
        </app-vital-admin-search-connector>
        <!-- <test [testData]="aTab.Content"></test> -->
      </tab>
    
    </vs-tabset>
    <div class="row">
      <ngx-ui-loader></ngx-ui-loader>
    </div>
    
    <div class="row" *ngIf="NoResult">
      <div class="col-12" style="color: #80808078;">
        <div class="row noDataFound mt-1">
          <div class="col-12 align-center">
            <p class="text-md _500 mb-0">No result found for the below search criteria</p>
          </div>
    
          <div class="col-12 row message align-center word-break">
            <span>Entity: {{EntityName}}</span>
            <span>Search Text: {{SearchText}}</span>
          </div>
        </div>
        <div class="row" *ngIf="advNoResult != null">
          <div class="col-12">
          </div>
          <div class="col-12 message">
            <p class="text-md _500">Advance filter
              parameters</p>
          </div>
        </div>
        <div class="row" *ngFor="let adv of advNoResult | keyvalue">
          <div class="col-12">
          </div>
          <div class="col-12 message">
            <p class="advresultmsg">{{adv.key}}: {{adv.value}}</p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row" *ngIf="isOnLoad || NoResult">
      <!-- <div class="ml-4">
        <app-manage-reflex-rules></app-manage-reflex-rules>
      </div> -->
      <div class="row noDataFound mt-2">
        <div class="help-inline-wrap">
          <div class="row col-12 p-0 m-0">
            <div class="col-3">
              <div class="col-sm-12 p-0 modal-header admin-model-header-wrap header-border title-wraps">
                <h3 class="modal-title w-100 admin-model-header-txt mb-3">Deployments</h3>
                <!-- <span class="row m-0" style="border-top: 1px solid;">
                  <span class="col-sm-3" style="border-right: 1px solid;">
                    <strong>Key</strong>
                  </span>
                  <span class="col-sm-9 align-center">
                    <strong>Link</strong>
                  </span>
                </span> -->
              </div>
              <div class="col-12 entitycard-wrap p-0 deploy-table">
                <span *ngFor="let dep of deployArr;index as i">
                  <span class="border-help row m-0">
                    <span class="col-sm-12">
                      <strong>{{dep.DeployKeys | uppercase}}</strong>
                    </span>
                    <span class="col-sm-12">
                      <a (click)="goToLink(dep.URLs.toString().split(',')[i])" *ngFor="let item of dep.URLs.toString().split(','); let i = index"
                        target="_blank">
                        {{item}}
                      </a>
                    </span>
                  </span>
                </span>
              </div>
            </div>
    
            <div class="col-5">
              <div class="col-sm-12 p-0 modal-header admin-model-header-wrap header-border title-wraps">
                <h3 class="modal-title w-100 admin-model-header-txt mb-3">Entities</h3>
                <!-- <span class="row m-0" style="border-top: 1px solid;">
                  <span class="col-sm-3" style="border-right: 1px solid;">
                    <strong>Entity</strong>
                  </span>
                  <span class="col-sm-9 align-center">
                    <strong>Description</strong>
                  </span>
                </span> -->
              </div>
              <div class="col-12 entitycard-wrap deploy-table">
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Laboratory</span>
                  <p class="col-sm-12 m-0">Keyword is <strong>lab</strong>; this will search for laboratories using the lab
                    name.</p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">User</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>user</strong>; this will search for users using
                    loginname,
                    FirstName, Last Name and Email.</p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Case</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>case</strong>; this will search for cases using
                    Requisition#,
                    Accession#,
                    CaseID.</p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Location</span>
                  <p class="col-sm-12 m-0">Keyword is <strong>loc</strong>; this will search for locations using location
                    name.
                  </p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Facility</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>fac</strong>; this will search for facilities using facility
                    name.</p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Physician</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>phy</strong>; this will search for physicians using
                    loginname,
                    displayname.
                  </p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Pathologist</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>path</strong>; this will search for pathologists using
                    loginname,
                    displayname.
                  </p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 font-bold">Account ID</span>
                  <p class="col-sm-12 m-0">Keyword is <strong>accid</strong>; this will search for all locations using
                    location ids.
                  </p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 pr-0 font-bold">Organization ID</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>orgid</strong>; this will search for all organizations using
                    Org ids.
                  </p>
                </span>
    
                <span class="row border-help">
                  <span class="col-sm-12 pr-0 font-bold">User ID</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>userid</strong>; this will search for all users using
                    User ids.
                  </p>
                </span>
    
                <span class="row">
                  <span class="col-sm-12 font-bold">Patient</span>
                  <p class="col-sm-12 m-0"> Keyword is <strong>pat</strong>; this will search for all patients using
                    patientname.
                  </p>
                </span>
              </div>
            </div>
    
            <div class="col-4">
              <div class="col-sm-12 p-0 modal-header admin-model-header-wrap header-border title-wraps">
                <h3 class="modal-title w-100 admin-model-header-txt mb-3">Direct User Search Options</h3>
                <!-- <span class="row m-0" style="border-top: 1px solid;">
                  <span class="col-sm-8 pr-1" style="border-right: 1px solid;">
                    <strong>Pattern</strong>
                  </span>
                  <span class="col-sm-4 align-center">
                    <strong>Example</strong>
                  </span>
                </span> -->
              </div>
              <div class="col-12 entitycard-wrap deploy-table p-0">
                <span *ngFor="let dep of deployArr;index as i">
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account ID:UserID</strong>
                    </span>
      
                    <span class="col-sm-12">
                      {{dep.DeployKeys | uppercase}}:5:2367
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account ID:LoginName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:5:Donald_va
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account ID:User FirstName/LastName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:5:Donald
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account ID:Email</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:5:Donald@gmail.com
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account Name:UserID</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:SL:2367
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account Name:LoginName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:SL:Donald_va
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account Name:User FirstName/LastName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:SL:Donald
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Account Name:Email</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:SL:Donald@gmail.com
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:OrgID:UserID</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:3:2367
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:OrgID:LoginName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:3:Donald_va
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:OrgID:User FirstName/LastName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:3:Donald
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:OrgID:Email</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:3:Donald@gmail.com
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Org Name:UserID</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:Specimen Lab:2367
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Org Name:LoginName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:SpecimenLab:Donald_va
                    </span>
                  </p>
      
                  <p class="border-help row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Org Name:User FirstName/LastName</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:Specimen Lab:Donald
                    </span>
                  </p>
      
                  <p class="row m-0">
                    <span class="col-sm-12 pr-0">
                      <strong>Deployment:Org Name:Email</strong>
                    </span>
      
                    <span class="col-sm-12">
                       {{dep.DeployKeys | uppercase}}:Specimen Lab:Donald@gmail.com
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
    
    <!-- Assign,Un-Assign & Re-Assign Modal -->
    
    <div class="modal fade assign-wrap" id="caseAssign" tabindex="-1" role="dialog" aria-labelledby="caseAssignTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered case-assign-action" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title modal-height" id="exampleModalLongTitle">Case Assignment</h5>
          </div>
          <div class="modal-body popup-dropdown">
            <form [formGroup]="assignmentForm" class="row">
    
              <div class="col-sm-12 pr-1 drop-down-wrap">
                <mat-form-field class="col-sm-12 select-input-field example-additional-selection p-0" appearance="outline">
                  <input class="input-width" formControlName="assigneeAccount" matInput [matAutocomplete]="auto" />
                  <mat-label class="label-wrap">Pathologist Location <span class="error-msg">*</span></mat-label>
                  <em class="fa fa-chevron-down chevron-align"></em>
                  <mat-select disableOptionCentering [(value)]="selected">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option class="mat-opt-align" *ngFor="let acc of AssigneeAccountDetails" value="{{acc.AccounId}} + {{acc.AccountName}}"
                        (onSelectionChange)="getAssigneePathologistList($event)">{{acc.AccountName}}</mat-option>
                    </mat-autocomplete>
                  </mat-select>
                </mat-form-field>
              </div>
    
              <div class="col-sm-12 pr-1 drop-down-wrap">
                <mat-form-field class="col-sm-12 select-input-field example-additional-selection p-0" appearance="outline">
                  <input class="input-width" formControlName="assigneePathologist" matInput [matAutocomplete]="auto1" />
                  <mat-label class="label-wrap">Pathologist <span class="error-msg">*</span></mat-label>
                  <em class="fa fa-chevron-down chevron-align"></em>
                  <span *ngIf="assignmentForm.value.assigneePathologist" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                    (click)="clearOrganization($event)" class="search-alignment">
                    <em class="fa fa-times" aria-hidden="true"></em>
                  </span>
                  <!-- <mat-select disableOptionCentering [(value)]="selected"> -->
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngIf="epgAccount" value='Available to all pathologists' (onSelectionChange)="checkLicenseOfPathologist($event,'epg')">[Available
                      to all pathologists]</mat-option>
                    <div *ngFor="let inspectionType of filterPathologistName(assignmentForm.value.assigneePathologist)">
                      <mat-option class="mat-opt-align" value="{{inspectionType.UserId}}+{{inspectionType.PathologistName}}"
                        (onSelectionChange)="checkLicenseOfPathologist($event,'')">
                        {{inspectionType.PathologistName}}</mat-option>
                    </div>
    
                  </mat-autocomplete>
                  <!-- </mat-select> -->
                </mat-form-field>
              </div>
    
              <!--  Assign & Cancel Button -->
              <div class="col-sm-12 modal-footer">
                <button mat-raised-button class="admin-btn-success mr-4" type="button" data-dismiss="modal" (click)="closingAssignModel()">Cancel</button>
                <button mat-raised-button class="admin-btn-success" type="button" (click)="assignToPathologist(assignmentForm.value)"
                  [disabled]="assignmentForm.value.assigneeAccount == '' || assignmentForm.value.assigneePathologist == ''">Assign</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Reset Popup -->
    <div class="col-sm-12">
      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <!-- Modal content-->
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header admin-model-header">
              <h3 class="modal-title w-100 admin-model-header-txt">Reset Case</h3>
            </div>
            <div class="modal-body">
              <form [formGroup]="resetForm">
    
                <div class="mt-3 p-0 row">
                  <div class="col-sm-4">
                    <label class="label-font">Reset to Status<span class="error-msg">*</span></label>
                  </div>
    
                  <div class="col-sm-8">
                    <select class="form-control form-font dropdown-border" formControlName="resetStatus">
                      <option value="Accession">Accession</option>
                      <option value="Processing">Processing</option>
                    </select>
                  </div>
                </div>
    
                <div *ngIf="!p4Depl" class="mt-3 p-0 row">
                  <div class="col-sm-4">
                    <label class="label-font">Reason<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-8">
                    <textarea formControlName="resetReason" type="text" maxlength="1000" class="form-control form-font p-2"></textarea>
                  </div>
                </div>
    
                <div *ngIf="!p4Depl" class="mt-3 p-0 row">
                  <p class="desc-font p-2" *ngIf="resetForm.controls['resetStatus'].value == 'Accession'">This case will be
                    Reset
                    and it will retain only the Accession details;all other information will be deleted.Select any other
                    below
                    details you wish to retain.</p>
                </div>
    
                <div *ngIf="!p4Depl && resetForm.controls['resetStatus'].value == 'Accession'" class="col-sm-12 p-0">
                  <div class="row p-0">
                    <div class="col-sm-4">
                      <label class="label-font">Tests/Panel</label>
                    </div>
                    <div class="col-sm-8">
                      <input type="checkbox" class="mt-1" formControlName="retainTestDetails" value="true" />
                    </div>
                  </div>
                </div>
    
                <div  class="mt-3 p-0 row">
                  <p class="desc-font p-2" *ngIf="resetForm.controls['resetStatus'].value == 'Processing'">This case will
                    be
                    Reset
                    and it will retain only the information entered till Processing status; all other information will be
                    deleted</p>
                </div>
              </form>
            </div>
    
            <div class="modal-footer align-center mt-4">
              <button type="button" mat-raised-button class="admin-btn-success mr-4" data-dismiss="modal" (click)="closingAssignModel()">Cancel</button>
              <button type="button" mat-raised-button class="admin-btn-success" (click)="resetCase(resetForm.value)"
                [disabled]="resetForm.value.resetStatus == ''">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
