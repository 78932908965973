import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from '../../labadmin.service';
import { LabadminSessionService } from '../../services/labadmin-session.service';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserDetailsComponent {

  @Output() EditUser = new EventEmitter<string>();

  @Input() templateData: any;
  @Input() userid = ''
  @Input() userDetials: any
  @Input() ViewType = ''

  userSessionDetails: any;
  loginType: string = '';
  showloader: boolean = false;
  tooltip: string = '';
  commomkey: string | '';
  sourceApp: string = 'VitalDx';

  islocked: boolean = false;

  isEditDisabled: boolean = true;
  isLockDisabled: boolean = true;
  isUnlockDisabled: boolean = true;
  isGeneratePwd: boolean = true;
  isEmailSent: boolean = true;

  constructor(private labadminService: LabadminService, public dialog: MatDialog,
    private labAdminSessionService: LabadminSessionService, public _vitalHttpService: VitalHttpServices,) {
  }

  ngOnInit(): void {
    this.labAdminSessionService.getLabAdminSession.subscribe(async (session) => {
      this.userSessionDetails = session['userDetails'];
      this.loginType = this.userSessionDetails.loginType || '';
      this.islocked = this.userDetials.IsAccountLockedout;
    });
    this.GetButtonAccess(this.labadminService.rbacPermissions);
  }

  ngOnChanges() {
    this.islocked = this.userDetials.IsAccountLockedout;
    if (this.userid !== '') {
    }
  }

  getUserDetails() {
    this.labadminService.getUserProfile(this.userid, 'LIS').subscribe(data => {
    })
  }

  // lock and unlock user account
  openDialog(item: any) {
    // lock and unlock should not allow for inactive users
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:item.IsAccountLockedout ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg',
          header: item.IsAccountLockedout ? 'Unlock User Account' : 'Lock User Account',
          message: 'Activate the user to lock-unlock account.',
          buttonname: 'Ok',
          type: "mail-sent"
        }
      })
      return;
    }

    this.dialog.open(LockUnlockUserDialogComponent, {
      width: '26vw',
      data: {
        imagePath:item.IsAccountLockedout ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg',
        header: item.IsAccountLockedout ? 'Unlock User Account' : 'Lock User Account',
        row1value: item.FormattedDisplayName,
        row2value: item.IsAccountLockedout ? "Locked" : "Unlocked",
        loginid: item.LoginName,
        userid: item.UserID,
        commonkey: item.CommonKey,
        row1text: 'User Name',
        row2text: 'Status',
        buttonname: item.IsAccountLockedout ? "Unlock User" : "Lock User",
        type: 'lockunlock-user',
        status: item.IsAccountLockedout,
        message: 'Activate the user to make the changes.'
      }
    }).afterClosed().subscribe((result) => {
      this.islocked = result.status;
      this.commomkey = item.CommonKey
      this.userDetials.IsAccountLockedout = result.status;
    });
  }

  passwordGenerateDialog(item: any) {
    // inactive user should not be allowed to send mail
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          header: 'Reset Password',
          message: 'Activate the user to generate new password.',
          buttonname: 'Ok',
          type: "mail-sent"
        }
      })
      return;
    }

    // unlock user should not be allowed to reset password
    if (item.IsAccountLockedout) {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          header: 'Reset Password',
          message: 'Unlock the user to generate new password.',
          buttonname: 'Ok',
          type: "mail-sent"
        }
      })
      return;
    }

    this.showloader = true;
    // login id is already verified need to send reset password link
    if (this.emailVerify(item)) {

      let obj = {
        email: item.emailId,
      }
      this.labadminService.sendResetPwdLink(obj).subscribe((res) => {
        this.showloader = false;
        this.dialog.open(LockUnlockUserDialogComponent, {
          width: '26vw',
          data: {
            imagePath:'../../../../assets/icons/user_icons/warning.svg',
            header: 'Reset Password',
            message: 'Reset Password link has been sent to your email.',
            buttonname: 'Ok',
            type: "mail-sent"
          }
        });
      }, (error) => {
        this.showloader = false;
        console.error(error);
      });
      return;
    }

    // login id is not verified will renerate the new password
    this.labadminService.generatePassword(item.loginId, this.userSessionDetails.userId, this.labadminService.deploymentKey).subscribe((data) => {
      this.showloader = false;
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          row1value: item.FormattedDisplayName,
          row2value: data[0],
          loginid: item.LoginName,
          userid: item.UserID,
          row1text: 'User Name',
          row2text: 'Password',
          buttonname: 'Copy',
          type: "generate-password",
          header: 'Reset Password',
          message: 'Reset Password link sent. check your email to proceed.'
        }
      });
    }, (error) => {
      this.showloader = false;
      console.error(error);
    });
  }

  emailType(item: any): boolean {
    if (this.emailVerify(item)) {
      this.tooltip = '';
      return true;
    }
    this.tooltip = 'Send Invitation';
    return false;
  }

  emailVerify(item: any): boolean {
    if (item.emailId && item.emailId.trim().length > 0 && item.authstatus && item.authstatus.trim().toLowerCase() == 'verified'
      && this.loginType && this.loginType.trim().toLowerCase() == 'email') {
      return true;
    }
    return false;
  }

  editUser() {
    this.EditUser.emit(this.userDetials.UserID)
  }


  // RBAC
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Lab")["SubMenu"]
      seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == "Users")["ActionButton"]
      try {
        for (let i = 0; i < seletedMenuPermissions.length; i++) {
          switch (seletedMenuPermissions[i].Button) {
            case 'Edit':
              this.isEditDisabled =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'Email':
              this.isEmailSent =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'Lock':
              this.isLockDisabled =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'UnLock':
              this.isUnlockDisabled =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'GeneratePassword':
              this.isGeneratePwd =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }


  // mail send for invitation
  mailSent(item: any) {
    // inactive user should not be allowed to send mail
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
          header: 'Send Invitation',
          message: 'Activate the user to send invitation mail.',
          buttonname: 'Ok',
          type: "mail-sent"
        }
      })
      return;
    }

    // unlock user should not be allowed to send mail
    if (item.isAccountLockedOut) {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
          header: 'Send Invitation',
          message: 'Unlock the user to send invitation mail.',
          buttonname: 'Ok',
          type: "mail-sent"
        }
      })
      return;
    }
    this.showloader = true;
    let requestData = [];
    let data = {
      Email: item.Email,
      UserID: item.UserID,
      OrganizationID: this.userSessionDetails.organizationId
    }
    requestData.push(data);
    this.labadminService.migrateUser(requestData, this.labadminService.deploymentKey).subscribe((d) => {
      this.showloader = false;
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '26vw',
        data: {
          imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
          header: 'Send Invitation',
          message: 'Email Invitation sent successfully to ' + item.Email,
          buttonname: 'Ok',
          type: "mail-sent"
        }
      });
    }, (error) => {
      this.showloader = false;
      console.error(error);
    });
  }
}
