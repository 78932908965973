<div class="filter-selector p-2"  [class.heading-effect]="!isExpanded">
    <div class="filter-selector-heading">
        <div class="d-flex" [class.show-intermediate]="selectionState === 'inter'">
            <input type="checkbox" #ref [checked]="selectionState === 'all'" (click)="selectAll(ref.checked)">
        </div>
        <div class="filter-selector-heading-grid ladm-flex-grow-1" data-toggle="collapse" (click)="onExpandClick($event)"
            [attr.href]="'#'+uniqueId" role="button" [attr.aria-expanded]="isExpanded" [attr.aria-controls]="uniqueId">
            <span class="filter-selector-heading-text ellipsis">{{headingText}}</span>
            <div class="show-selected-count ml-auto">
                <span class="filter-selector-value-text">{{selectionState === 'all' ? 'All' : FilteredSelectorSelectionModel.selected.length}}</span>
                <img [class.flip-icon]="isExpanded" src="/assets/icons/ionic-arrow-down.svg" alt="expand">
            </div>
        </div>
        <img class="close" *ngIf="showCancel" (click)="onCancelClick.emit()" src="/assets/icons/Close_black.svg" alt="close">
    </div>
    <div class="collapse" [id]="uniqueId">
        <div class="mb-2 mt-2">
            <input type="text" [placeholder]="inputSearchPlaceholder" [formControl]="control">
        </div>
        <div class="px-2 dropdown-scroll" *ngIf="dropDownValue$ | async as dropdown">
            <div class="filter-selector-body" *ngFor="let value of dropdown">
                <input [checked]="FilteredSelectorSelectionModel.isSelected(value.filterSelectIdentifier)"
                    (click)="selectValue(value)" type="checkbox">
                <span class="ellipsis" (click)="selectValue(value)" 
                [matTooltip]="tooTipText" #filterprop
                                    (mouseover)="toolTipshow(filterprop)" 
                >{{value[displayByProperty]}}</span>
                <div class="ml-auto filter-selector-body" *ngIf="isReadingLocationEnabled">
                    <input #radio name="readinglocation" type="radio" (click)="markAsReadingLocation(value)" [checked]="value.isReadingLocationEnabled"/>
                    <span class="vdm-text-no-wrap" (click)="radio.click()">Set as Reading Location</span>
                </div>
            </div>
            <div *ngIf="dropdown.length <= 0"><span>{{noRecordsFoundMessage}}</span></div>
        </div>
    </div>
</div>