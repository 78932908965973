<div *ngIf="gridPage" class="m-2 col-sm-12">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Additional Stains</strong>
        </h3>
    </div>
    <div *ngIf="!noDataFound" class="upload-grid">
        <!-- Export Config & Raw data -->
        <div class="button-field-wrap m-1 p-0">
            <mat-form-field [disabled]="exportBtn" *ngIf="!noDataFound"
                class="col-sm-3 example-additional-selection p-0" appearance="outline">
                <mat-label class="d-flex">
                    Export
                </mat-label>
                <mat-select disableOptionCentering class="ml-2 p-0">
                    <mat-option class="temp-font-size" (click)="exportStainData('')">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>
                        Config Data
                    </mat-option>
                    <mat-option class="temp-font-size" (click)="exportStainData('raw')">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>
                        Raw Data
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="button-field-wrap">
            <span class="instr-size">
                <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                You can Export the config data or raw data.</span>
        </div>

        <wj-flex-grid class="align-center" #grid [isReadOnly]="true" [headersVisibility]="'Column'"
            (initialized)="initGrid(grid)" [itemsSource]="stainGridData" [frozenColumns]="1">

            <wj-flex-grid-column [header]="'Action'" align="center" [isReadOnly]="true" [freeze]="true" [width]="120">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEditScreen(grid,$event)"><em
                            id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column *ngFor="let columnname of gridheader" [binding]="columnname"
                [visible]="shouldShowColumn(columnname)"
                [header]="columnname.split('_').join(' ')" [width]="170" [fixed]="true" [allowResizing]="true"
                [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <br />
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="stainGridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="stainGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
    </div>
    <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
        <div class="col-sm-12 nodata-wrap mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
    <div class="align-center mt-4">
        <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('',[])">
            Create
        </button>

        <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success" (click)="loadUploadScreen()">
            Upload
        </button>
        <button title="Copy From Other Entity" [disabled]="uploadBtn" mat-raised-button class="admin-btn-success ml-4"
            (click)="loadCopyScreen()">
            Copy
        </button>
    </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <!-- <strong>{{action}}</strong> -->
            <strong>Copy Stains</strong>
        </h3>
    </div>
    <form [formGroup]="copyStainsMethodForm" class="copy-collection">
        <div class="p-0 ml-0">
            <div class="row d-flex col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 w-100 mt-2 deploy-wrap" appearance="outline">
                    <mat-label><strong><span class="error-msg">*</span>Deployment</strong></mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label><strong><span class="error-msg">*</span>Group (Location)</strong></mat-label>
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input formControlName="frmOrganization" matInput #searchbar
                        (keyup)="fetchOrgSeries(copyStainsMethodForm.value.frmOrganization)" [matAutocomplete]="orgauto"
                        placeholder="Search here ..." style="width:95% !important;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align" title="{{ show.accountname }} ({{show.accountid}})"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)">
                                <span>{{ show.accountname }} ({{show.accountid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>

                    <button *ngIf="copyStainsMethodForm.value.frmOrganization" matSuffix mat-icon-button
                        aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
                    (initialized)="initialisedGrid(grid,1)" #grid (formatItem)="formatItem(grid, $event)"
                    [frozenColumns]="1">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                        [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span [ngStyle]="{color: cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' ?'red': '#ff9800' }">{{cell.item.notes}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()"
                            [header]="columnname.replace('_','')"
                            [visible]="!(columnname == 'tablename') && !(columnname == 'AccountId') && !(columnname == 'ProcedureId') && !(columnname == 'CaseType') && !(columnname == 'TestId') && !(columnname == 'Id') && !(columnname == 'AccountId') && !(columnname == 'PId') && !(columnname == 'notes') && !(columnname == 'slno') && !(columnname == 'casetype')"
                            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <br />
                <div class="row pl-3 mt-4">
                    <div class="col-sm-2 p-0">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="excelDataArray.length > 0"
                            (click)="removeGrid()">Back</button>
                        <!-- <button mat-raised-button class="admin-btn-success mr-4"
                            *ngIf="excelDataArray.length == 0 || gridWidth == 0" (click)="refreshGrid()">Back</button> -->
                    </div>
                    <div class="col-sm-10 button-wrap p-0 m-0">
                        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                            <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                                (click)="refreshGrid()">Finish</button>
                            <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                                (click)="refreshGrid()">Cancel</button>
                            <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload"
                                class="admin-btn-success mr-4" (click)="uploadTestData()">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                                (click)="ExportExcelCopy(grid)">Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-3 mt-4">
                <div class="col-sm-2 p-0">
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="excelDataArray.length == 0"
                        (click)="refreshGrid()">Back</button>
                </div>
            </div>


            <div class="col-sm-12" *ngIf="gridWidth == 0 && noData">
                <div class="container create-btn col-sm-12 align-center">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<div *ngIf="uploadClicked" class="my-bulk-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload Stains</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <em class="fa fa-chevron-down chevron-align"></em>
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="temp-font-size" (click)="downloadMandatoryFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal
                    Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadAllFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All
                    Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Stain List.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid
            [headersVisibility]="'Column'" (formatItem)="formatItem(grid, $event)" [frozenColumns]="2">

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="208"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="STATUS" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: highlightNotes? (cell.item.notes !='Mandatory field is missing' && cell.item.notes !='Null values exist!' && cell.item.notes !='Special chars exists in CPTCode' && cell.item.notes !='Stain created for this case type' && cell.item.notes !='Valid' ? '#ff9800': (cell.item.notes == 'Valid' || cell.item.notes == 'Stain created for this case type') ? 'green' : 'red'):''}">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->


            <wj-flex-grid-column *ngFor="let columnname of sheetHeader" [binding]="columnname?.toString().toLowerCase()"
                [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="220"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
            </wj-flex-grid-column>




            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3 mt-4">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="excelDataArray.length > 0"
                    (click)="removeGrid()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="excelDataArray.length == 0"
                    (click)="refreshGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 m-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload"
                        class="admin-btn-success mr-4" (click)="uploadTestData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>

</div>

<!-- Create stains -->
<div *ngIf="addEditScreen">
    <form [formGroup]="testForm" autocomplete="off">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>{{action}}</strong>
                </h3>
            </div>
            <div class="text-wrap field-wrap row col-sm-12 p-0 ml-0 pb-2">
                <div class="col-sm-12 mt-2 mb-2">
                    <mat-form-field class="width col-sm-3 panel-font" appearance="outline">
                        <mat-label><strong>Panel</strong></mat-label>
                        <input maxlength="200" matInput aria-label="Panel"
                            (input)="disableTestSeq(testForm.value.frmpanel,'')" #panel="matAutocompleteTrigger"
                            [matAutocomplete]="auto" formControlName="frmpanel" />
                        <div matSuffix style="display:flex">
                            <button (click)="clearPanel($event)"
                                *ngIf="testForm?.value.frmpanel!==null && testForm?.value.frmpanel!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrClosePanel($event, panel)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                            </button>
                        </div>
                        <mat-error class="panel-error-marg">{{getErrorMessage(testForm.value.frmpanel,'')}}</mat-error>

                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject1.next('arrow_drop_down1')"
                                (opened)="arrowIconSubject1.next('arrow_drop_up1')"
                                (optionSelected)="arrowIconSubject1.next('arrow_drop_down1')"
                                *ngFor="let panel of filterPanels(testForm.value.frmpanel)"
                                (onSelectionChange)="disableTestSeq($event,'selection')" [value]="panel.Panel">
                                <span>{{ panel.Panel }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>
                                Procedure Name</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="255" formControlName="frmprocedure"
                            [errorStateMatcher]="matcher">
                        <mat-error class=" mt-3">{{getErrorMessage(testForm.controls.frmprocedure.value,'')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>
                                CPT Code</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="2000" formControlName="frmcptcodes"
                            pattern="^[a-zA-Z0-9-,. /]+$" [errorStateMatcher]="matcher">
                        <mat-error class=" mt-3">{{getErrorMessage(testForm.controls.frmcptcodes.value,'cpt')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>
                                Sequence(Panel)</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="5" [readonly]="readonlyTestSequence"
                            [(ngModel)]="testForm.value.frmtestsequence" formControlName="frmtestsequence"
                            pattern="[0-9]+" (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                        <mat-error class=" mt-3">{{getErrorMessage(testForm.controls.frmtestsequence.value,'testsequence')}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-2">
                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>
                                Sequence(Stain)</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="5" formControlName="frmsequence" pattern="[0-9]+"
                            (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                        <mat-error class=" mt-3">{{getErrorMessage(testForm.controls.frmsequence.value,'sequence')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Billing Type</strong></mat-label>
                        <input type="text" formControlName="frmbillingtype" matInput maxlength="50"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Default Send Out Service Type</strong></mat-label>
                        <input formControlName="frmdefaultsendoutservicetype" type="text" matInput maxlength="100"
                            (input)="additionalFieldFilter($event.target.value,'Default_Send_Out_Service_Type')"
                            [matAutocomplete]="defaultsendoutservicetype" style="width: auto;">
                        <div matSuffix style="display:flex">
                            <button (click)="clearDfltSerType($event)"
                                *ngIf="testForm?.value.frmdefaultsendoutservicetype!==null && testForm?.value.frmdefaultsendoutservicetype!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseDfltSerType($event, value)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject2.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #defaultsendoutservicetype="matAutocomplete"
                            (optionSelected)="testForm.value[frmdefaultsendoutservicetype] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject2.next('arrow_drop_down2')"
                                (opened)="arrowIconSubject2.next('arrow_drop_up2')"
                                (optionSelected)="arrowIconSubject2.next('arrow_drop_down2')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmdefaultsendoutservicetype,'Default_Send_Out_Service_Type')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Send Out Full Filled Test Action</strong></mat-label>
                        <input type="text" formControlName="frmsendoutfullfilledtestaction" matInput maxlength="50"
                            (input)="additionalFieldFilter($event.target.value,'Send_Out_Fullfilled_Test_Action')"
                            [matAutocomplete]="sendoutfullfilledtestaction" style="width: auto;">
                        <div matSuffix style="display:flex">
                            <button (click)="clearSendoutfullfilledtestaction($event)"
                                *ngIf="testForm?.value.frmsendoutfullfilledtestaction!==null && testForm?.value.frmsendoutfullfilledtestaction!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrClosesendoutfullfilledtestaction($event, value)" aria-label="Clear"
                                mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject3.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #sendoutfullfilledtestaction="matAutocomplete"
                            (optionSelected)="testForm.value[frmsendoutfullfilledtestaction] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject3.next('arrow_drop_down3')"
                                (opened)="arrowIconSubject3.next('arrow_drop_up3')"
                                (optionSelected)="arrowIconSubject3.next('arrow_drop_down3')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmsendoutfullfilledtestaction,'Send_Out_Fullfilled_Test_Action')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-2">
                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Default Send Out Bill To</strong></mat-label>
                        <input formControlName="frmdefaultsendoutbillto" type="text" matInput maxlength="200"
                            (input)="additionalFieldFilter($event.target.value,'Default_Send_Out_Bill_To')"
                            [matAutocomplete]="defaultsendoutbillto" style="width: auto;">
                        <div matSuffix style="display:flex">
                            <button (click)="clearDefaultsendoutbillto($event)"
                                *ngIf="testForm?.value.frmdefaultsendoutbillto!==null && testForm?.value.frmdefaultsendoutbillto!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseDefaultsendoutbillto($event, value)" aria-label="Clear"
                                mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject4.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #defaultsendoutbillto="matAutocomplete"
                            (optionSelected)="testForm.value[frmdefaultsendoutbillto] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject4.next('arrow_drop_down4')"
                                (opened)="arrowIconSubject4.next('arrow_drop_up4')"
                                (optionSelected)="arrowIconSubject4.next('arrow_drop_down4')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmdefaultsendoutbillto,'Default_Send_Out_Bill_To')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Default Send Out Lab Id</strong></mat-label>
                        <input formControlName="frmdefaultsendoutlabid" type="text" matInput maxlength="8"
                            (keypress)="allowOnlyNumber($event)"
                            (input)="additionalFieldFilter($event.target.value,'Default_Send_Out_Lab')"
                            [matAutocomplete]="defaultsendoutlabid" style="width: auto;">
                        <div matSuffix style="display:flex">
                            <button (click)="clearDefaultsendoutlabid($event)"
                                *ngIf="testForm?.value.frmdefaultsendoutlabid!==null && testForm?.value.frmdefaultsendoutlabid!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseDefaultsendoutlabid($event, value)" aria-label="Clear"
                                mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject5.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-error>{{getErrorMessage(testForm.value.frmdefaultsendoutlabid,'defaultsendoutlabid')}}
                        </mat-error>

                        <mat-autocomplete #defaultsendoutlabid="matAutocomplete">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject5.next('arrow_drop_down5')"
                                (opened)="arrowIconSubject5.next('arrow_drop_up5')"
                                (optionSelected)="arrowIconSubject5.next('arrow_drop_down5')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmdefaultsendoutlabid,'Default_Send_Out_Lab')"
                                (onSelectionChange)="fetchAccountCaseTypes(value.AssociatedAccount,$event)"
                                [value]="value.AssociatedAccount"
                                title="{{value.AssociatedAccount}} ({{value.AccountName}})">
                                <span>{{value.AssociatedAccount}} ({{value.AccountName}})</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Spawn Case Type</strong></mat-label>
                        <input [errorStateMatcher]="matcher" formControlName="frmspawncasetype" type="text" matInput
                            maxlength="50" (input)="additionalFieldFilter($event.target.value,'SpawnCaseType')"
                            [matAutocomplete]="spawncasetype" style="width: auto;">

                        <div matSuffix style="display:flex">
                            <button (click)="clearSpawncasetype($event)"
                                *ngIf="testForm?.value.frmspawncasetype!==null && testForm?.value.frmspawncasetype!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseSpawncasetype($event, value)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject6.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #spawncasetype="matAutocomplete"
                            (optionSelected)="testForm.value[frmspawncasetype] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject6.next('arrow_drop_down6')"
                                (opened)="arrowIconSubject6.next('arrow_drop_up6')"
                                (optionSelected)="arrowIconSubject6.next('arrow_drop_down6')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmspawncasetype,'SpawnCaseType')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Spawn Casetype Mnemonic</strong></mat-label>
                        <input type="text" formControlName="frmcasetypemnemonic" matInput maxlength="20">
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-2">
                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Send Out Flag</strong></mat-label>
                        <input formControlName="frmsendoutflag" type="text" matInput maxlength="25"
                            (input)="additionalFieldFilter($event.target.value,'Send_Out_Flag')"
                            [matAutocomplete]="sendoutflag" style="width: auto;">

                        <div matSuffix style="display:flex">
                            <button (click)="clearSendoutflag($event)"
                                *ngIf="testForm?.value.frmsendoutflag!==null && testForm?.value.frmsendoutflag!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseSendoutflag($event, value)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #sendoutflag="matAutocomplete"
                            (optionSelected)="testForm.value[frmsendoutflag] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject7.next('arrow_drop_down7')"
                                (opened)="arrowIconSubject7.next('arrow_drop_up7')"
                                (optionSelected)="arrowIconSubject7.next('arrow_drop_down7')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmsendoutflag,'Send_Out_Flag')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Spawn Status</strong></mat-label>
                        <input formControlName="frmspawnstatus" type="text" matInput maxlength="2000"
                            (input)="additionalFieldFilter($event.target.value,'SpawnStatus')"
                            [matAutocomplete]="spawnstatus" style="width: auto;">

                        <div matSuffix style="display:flex">
                            <button (click)="clearSpawnstatus($event)"
                                *ngIf="testForm?.value.frmspawnstatus!==null && testForm?.value.frmspawnstatus!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseSpawnstatus($event, value)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #spawnstatus="matAutocomplete"
                            (optionSelected)="testForm.value[frmspawnstatus] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject8.next('arrow_drop_down8')"
                                (opened)="arrowIconSubject8.next('arrow_drop_up8')"
                                (optionSelected)="arrowIconSubject2.next('arrow_drop_down8')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmspawnstatus,'SpawnStatus')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Order Code ID</strong></mat-label>
                        <input formControlName="frmordercodeid" type="text" matInput maxlength="8"
                            (keypress)="allowOnlyNumber($event)"
                            (input)="additionalFieldFilter($event.target.value,'Order_Code')"
                            [matAutocomplete]="ordercodeid" style="width: auto;">

                        <div matSuffix style="display:flex">
                            <button (click)="clearOrdercodeid($event)"
                                *ngIf="testForm?.value.frmordercodeid!==null && testForm?.value.frmordercodeid!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseOrdercodeid($event, value)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #ordercodeid="matAutocomplete"
                            (optionSelected)="testForm.value[frmordercodeid] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject9.next('arrow_drop_down9')"
                                (opened)="arrowIconSubject9.next('arrow_drop_up9')"
                                (optionSelected)="arrowIconSubject9.next('arrow_drop_down9')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmordercodeid,'Order_Code')"
                                [value]="value.ID">
                                <span title="{{ value.Name }} ({{value.ID}})">{{ value.Name }} ({{value.ID}})</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label><strong>Spawn Test On Service Type</strong></mat-label>
                        <input formControlName="frmspawntestonservicetype" type="text" matInput maxlength="25"
                            (input)="additionalFieldFilter($event.target.value,'SpawnTestOnServiceType')"
                            [matAutocomplete]="spawntestonservicetype" style="width: auto;">

                        <div matSuffix style="display:flex">
                            <button (click)="clearSpawntestonservicetype($event)"
                                *ngIf="testForm?.value.frmspawntestonservicetype!==null && testForm?.value.frmspawntestonservicetype!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrCloseSpawntestonservicetype($event, value)" aria-label="Clear"
                                mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #spawntestonservicetype="matAutocomplete"
                            (optionSelected)="testForm.value[frmspawntestonservicetype] = $event.option.value">
                            <mat-option class="mat-opt-align" (closed)="arrowIconSubject10.next('arrow_drop_down10')"
                                (opened)="arrowIconSubject10.next('arrow_drop_up10')"
                                (optionSelected)="arrowIconSubject10.next('arrow_drop_down10')"
                                *ngFor="let value of additionalFieldFilter(testForm.value.frmspawntestonservicetype,'SpawnTestOnServiceType')"
                                [value]="value">
                                <span>{{ value }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-2">
                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>Procedure Code</strong>
                        </mat-label>
                        <input formControlName="frmprocedurecode" type="text" matInput maxlength="750"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>Machine Test Code</strong>
                        </mat-label>
                        <input formControlName="frmmachinetestcode" type="text" matInput maxlength="100"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>PID</strong>
                        </mat-label>
                        <input formControlName="frmpid" type="text" matInput maxlength="8"
                            (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>Summary Display Name</strong>
                        </mat-label>
                        <input formControlName="frmsummarydisplayname" type="text" matInput maxlength="50"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-2">
                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>Procedure Abbreviation</strong>
                        </mat-label>
                        <input formControlName="frmprocedureabbreviation" type="text" matInput maxlength="20"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>Default Split Int Lab</strong>
                        </mat-label>
                        <input formControlName="frmdefaultsplitintlab" type="text" matInput maxlength="8"
                            (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                    </mat-form-field>

                    <mat-checkbox [color]="task.color" formControlName="frmactive" class="col-sm-3 example-margin">
                        Active</mat-checkbox>
                </div>

                <div class="col-sm-12 mt-2 d-flex">
                    <mat-checkbox [color]="task.color" formControlName="frmisquicktest"
                        class="col-sm-3  mt-2 example-margin">
                        Quick Test
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmisownsummary"
                        class="col-sm-3 mt-2 example-margin">Own
                        Summary
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmhascustomresults"
                        class="col-sm-3 mt-2 example-margin">
                        Has Custom Results
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmiskitlotrequired"
                        class="col-sm-3 mt-2 example-margin">Is
                        Kit Lot Required
                    </mat-checkbox>
                </div>

                <div class="col-sm-12 mt-2 d-flex">
                    <mat-checkbox [color]="task.color" formControlName="frmisbillableonce"
                        class="col-sm-3 mt-2 example-margin">Billable
                        Once
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmisdataSetrequired"
                        class="col-sm-3 mt-2 example-margin">Data
                        Set Required
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmisscreeningtype"
                        class="col-sm-3 mt-2 example-margin">
                        Screening
                        Type
                    </mat-checkbox>
                    <mat-checkbox [color]="task.color" formControlName="frmhasallsitesummary"
                        class="col-sm-3  mt-2 example-margin">
                        Has All Site Summary
                    </mat-checkbox>
                </div>

                <div class="col-sm-12 mt-2 d-flex">
                    <mat-checkbox [color]="task.color" formControlName="frmsplitteststatuspending"
                        class="col-sm-3 mt-2 example-margin">
                        Split Test Status Pending
                    </mat-checkbox>

                    <mat-checkbox [color]="task.color" formControlName="frmglobalsendout"
                        class="col-sm-3 mt-2 example-margin">Global
                        Send Out
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-3">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="saveStain('',testForm.value)">
                    Save & Close
                </button>
                <button mat-raised-button *ngIf="action == 'Create Stain'" class="admin-btn-success"
                    (click)="saveStain('new',testForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>

