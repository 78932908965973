import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExecuteSqlComponent } from './client/Templates/ExecuteSql/Executesql.component';
import { HomeLayoutComponent } from './common/layout/home-layout.component';
import { LoginLayoutComponent } from './common/layout/login-layout.component';
import { AuthGuard } from './core/services/auth.guard';

const routes: Routes = [
  { path: 'executesql', component: ExecuteSqlComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeLayoutComponent, canActivate: [AuthGuard] },
  { path: 'labadmin', loadChildren: () => import('./labadmin/labadmin.module').then(m => m.LabadminModule) },
  // { path: 'home', component: HomeLayoutComponent, loadChildren: () => import('./base/common.module').then(m => m.VACommonModule),canActivate: [AuthGuard] },
  { path: '**', component: LoginLayoutComponent, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) }
  // { path: 'login', component: LoginLayoutComponent, loadChildren: './auth/login/login.module#LoginModule' },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
