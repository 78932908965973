import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataShareService {
  editorder = {}

  selectedDefaultDeployment : string;
  public guids = [];
  public TemplateData;
  public CopyData; //to be used for copying config data b/w
  private orderlist = new BehaviorSubject<any>('');
  currentMessage = this.orderlist.asObservable();
  public Guidsdata = new BehaviorSubject<any>('');
  guidlist = this.Guidsdata.asObservable();

  private menuheight = new BehaviorSubject<any>('');
  menulist = this.menuheight.asObservable();

  //casetype configuration final data - Used in setup new location, setup new entity
  //#region 
  savedCaseTypes : any = [];
  savedOrderCodes : any = [];
  savedInstruments : any = [];
  configuredCaseTypes : any = [];
  //#endregion

  // updateuserdata
  public userData;
  public accountMnemonic;
  public eligibleRoles;
  public userCard;
  public deploymentKey = new BehaviorSubject<any>('');
  deploymentChange = this.deploymentKey.asObservable();
  private userdetails = new BehaviorSubject<any>('');
  userdata = this.userdetails.asObservable();
  private MenuButton = new BehaviorSubject<any>('');
  SubmenuAction = this.MenuButton.asObservable();
  private ActionButton = new BehaviorSubject<any>('');
  MainAction = this.ActionButton.asObservable();
  private resetSqlarray = new BehaviorSubject<any>('');
  ResetSql = this.resetSqlarray.asObservable();
  constructor() { }

  changeMessage(message, val) {
    let orders = {
      json: message,
      rout: val
    }
    this.orderlist.next(orders)
  }
  getGuids(guids) {
    this.Guidsdata.next(guids)
  }
  getmenu(height) {
    this.menuheight.next(height)
  }
  getuserdata(value) {
    this.userdetails.next(value)
  }
  getreset(value) {
    this.resetSqlarray.next(value)
  }
  getMenuButtonData(value) {
    this.MenuButton.next(value);
  }
  getActionButtonData(value) {
    this.ActionButton.next(value);
  }
  changeDep(depKey){
    this.deploymentKey.next(depKey);
  }  
}