<div class="row custom-report-wrap">
  <div class="col-md-12" *ngIf="tableGrid">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>Custom Reports</strong>
      </h3>
    </div>
  </div>
  <div *ngIf="tableGrid" class="col-sm-12">
    <div *ngIf="templateData && templateData.labels && gridwidth > 0">
      <wj-flex-grid class="col-sm-12 mt-2 p-0" #grid [isReadOnly]="true" (click)="showReportDetails(grid, $event)" [headersVisibility]="'Column'"
        (initialized)="initGrid(grid)" [itemsSource]="gridData"  >
        <div *ngFor="let columnname of columns">
          <wj-flex-grid-column [binding]="columnname.binding" [visible]="!(columnname.header == 'Report ID')"
            [header]="columnname.header" [width]="'*'" [allowDragging]="false" [allowResizing]="true">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
      <div class="pagination-align mt-1">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
        <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
          <wj-menu-item [value]="0">No Paging</wj-menu-item>
          <wj-menu-item [value]="10">10</wj-menu-item>
          <wj-menu-item [value]="15">15</wj-menu-item>
          <wj-menu-item [value]="30">30</wj-menu-item>
          <wj-menu-item [value]="50">50</wj-menu-item>
        </wj-menu>
      </div>

      <div class="sub-button mt-4">
        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideExportBtn"
          (click)="ExportToExcel(tempExport)">
          Export
        </button>
        <!-- Create new report -->
        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideCreateBtn"
          (click)="addNewReport()">Create</button>
        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideUploadBtn"
          (click)="uploadCustomReport()">Upload</button>
        <button mat-raised-button class="admin-btn-success" title="Copy from other Entity"
          [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">Copy</button>
      </div>

    </div>
  </div>
  <div *ngIf="tableGrid && gridwidth == 0" class="col-sm-12 mt-4">
    <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideCreateBtn"
      (click)="addNewReport()">Create</button>
    <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideUploadBtn"
      (click)="uploadCustomReport()">Upload</button>
    <button mat-raised-button class="admin-btn-success" [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">Copy</button>
    <div class="nodata-wrap mt-4">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
  <!-- add -->
  <div *ngIf="addCustomReportModel" class="col-sm-12 p-3">
    <div class="modal-header admin-model-header">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
          <div>Create Custom Report</div>
        </strong>
      </h3>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <form [formGroup]="customReportData" class="mt-1 form-height">
            <div class="row px-3">
              <div class="col-sm-6">
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Name<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim title="{{ customReportData.value.name }}" formControlName="name" maxlength="100" type="text"
                      class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Category<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <select class="form-control form-font" formControlName="Category">
                      <option value="" selected disabled>Select</option>
                      <option *ngFor="let cat of distinctCategory" value="{{ cat.item }}">
                        {{ cat.item }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Criteria/CDN for create and save -->
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Criteria<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs mb-3">
                    <mat-form-field class="w-100 custom-border " appearance="outline">
                      <mat-select disableOptionCentering matNativeControl [disableRipple]="true"
                        [sortComparator]="sortComparator" formControlName="criteria" (click)="getSortData($event);"
                        [title]="customReportData.controls['criteria'].value" (selectionChange)="selectedValue($event)"
                        multiple #select="matSelect">
                        <mat-option value="{{item.CDN}},{{item.Criteria}}"
                          [ngClass]="item.isEdit?'isnotVisible':'isVisible'"
                          *ngFor="let item of CriteriaList;let i=index" (click)="toggleAllSelection(item.key,$event)"
                          (keyup)="tempValue($event)" title="{{item.CDN}}({{item.Criteria}})">
                          <span *ngIf="!item.isEdit" class="edit_criteria"> {{item.CDN}}({{item.Criteria}})</span>
                          <span class="pull-left" *ngIf="item.isEdit">
                            <input type="text" (keyup)="targetValue($event);" (keydown)="$event.stopPropagation();"
                              (click)="disableEvent($event)" [value]="item.CDN" class="form-control place-holder"
                              [title]="item.CDN">
                          </span>
                          <span class="pull-right ">
                            <button *ngIf="!disable" class="btn btn-sm rounded-circle border-0 btn-dark bg-white"
                              (click)="editCDN($event,i,item)" [disabled]="disable">
                              <em class="fa fa-pencil"></em>
                            </button></span>
                          <span class="pull-right">
                            <span type="button" *ngIf="item.check!=-1 && item.check != 0"
                              class="btn btn-outline-warning btn-sm rounded-circle border-0">({{item.check}})</span>
                            <span *ngIf="i==textview" class="content-wrap">
                              <span class="btn btn-sm" (click)="saveCDN($event,i,item)"><em
                                  class="fa fa-check text-success"></em></span>
                              <span class="btn btn-sm " (click)="cancelCDN($event,i,item)"><em
                                  class="fa fa-times text-danger"></em></span>
                            </span>
                          </span>
                        </mat-option>
                      </mat-select>
                      <em class="fa fa-chevron-down chevron-align"></em>
                    </mat-form-field>
                  </div>
                </div>
                <!------------------------------------------------------>
                <div class="row p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Version<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <select class="form-control form-font" title="{{ customReportData.value.Version }}"
                      formControlName="Version">
                      <option value="" selected disabled>Select</option>
                      <option value="0">0 (Multi Sheet)</option>
                      <option value="1">1 (Normal Reports)</option>
                      <option value="2">2 (XLSX format files)</option>
                      <option value="4">4 (CSV format files)</option>
                      <option value="5">5 (XLSX using GrapeCity)</option>

                    </select>
                  </div>
                </div>
                <div class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Asynchronous</label>
                  </div>
                  <div class="col-sm-7 async-align text-xs">
                    <div class="col-sm-12 row">
                      <!-- <div class="col-sm-6">
                        <input type="radio" value="True" formControlName="IsAsynchronousReport" class="mr-1" />True
                      </div>
                      <div class="col-sm-6">
                        <input type="radio" value="False" formControlName="IsAsynchronousReport" class="mr-1" />False
                      </div> -->
                      <div class="col-sm-6 text-xs row reportdb-align">
                        <input type="checkbox" formControlName="IsAsynchronousReport" value="True"
                          class="check-box-align m-0" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Approx. Generation Time</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <!-- <input formControlName="approxGenarationTime" type="number" min="0"
                      class="form-control form-font" /> -->
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="approxGenarationTime" min="0" maxlength="4">
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Title<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim formControlName="title" type="text" maxlength="100" class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">SP Name<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim formControlName="Spname" type="text" maxlength="250" class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Named Range<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim formControlName="NamedRange" type="text" maxlength="100" class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Max Days</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <!-- <input formControlName="MaxDays" type="number" min="0" maxlength="4"class="form-control form-font" /> -->
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="MaxDays" min="0" maxlength="4">
                  </div>
                </div>
                <div *ngIf="
                      customReportData.value.IsAsynchronousReport == true" class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Max Records</label>
                  </div>

                  <div class="col-sm-7 text-xs">
                    <!-- <input formControlName="ShowMaxRecord" type="number" min="0"  maxlength="4"  class="form-control form-font" /> -->
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="ShowMaxRecord" min="0" maxlength="4">
                  </div>
                </div>
                <div  class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Connection Name</label>
                  </div>
                  <div class="col-sm-7 text-xs row reportdb-align">
                    <input type="checkbox" formControlName="ConnectionName" value="ReportDB"
                      class="check-box-align m-0" />
                    <label class="pl-2 m-0">ReportDB</label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <!-- <label class="label-font">Status</label> -->
                    <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="isActive">Active
                      </mat-checkbox>
                  </div>
                  <!-- <div class="col-sm-7 text-xs">
                    <input type="checkbox" formControlName="isActive" (change)='checkValue($event)' [checked]="isActive"
                      value="isActive" class="check-box-align m-0" >
                  </div> -->
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Enable Multi-Sheet</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input type="checkbox" formControlName="isSelect" (change)='multisheetcheckValue($event)' [checked]="isSelect"
                      value="isSelect" class="check-box-align m-0" >
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 add-form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Template File<span class="error-msg">*</span></label>
              </div>

              <div class="col-sm-10 text-xs">
                <input #fileInput ID="myFileInputID" type="file" accept=".xlsx" formControlName="templateFile"
                  class="form-control form-font" (change)="handleUpload($event,'create')" />
                <p>Please upload only .xlsx format file</p>
              </div>
            </div>
            <div class="row mt-3 add-form-align">
                <div class="col-sm-2 text-xs">
                  <label class="label-font">Template Filename</label>
                </div>
                <div class="col-sm-10 text-xs">
                  <input type="text" maxlength="100" class="form-control form-font" formControlName="templateFile" [value]="filename"/>
                </div>
              </div>
            <div class="row mt-3 pt-2 add-form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Roles
                  <span class="error-msg">*</span></label>
              </div>
              <ng-multiselect-dropdown class="col-sm-10 text-xs" [placeholder]="'Select'" [settings]="dropdownSettings"
                [(ngModel)]="selectedLevel" [data]="roleArray" (onSelect)="onCheckChange($event)"
                (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll()" formControlName="item">
              </ng-multiselect-dropdown>
            </div>
            <div class="row mt-3 add-form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Description<span class="error-msg">*</span></label>
              </div>
              <div class="col-sm-10 text-xs">
                <textarea trim class="mailtext-area" title="{{customReportData.value.Description}}"
                  formControlName="Description" maxlength="2000" type="text"
                  class="form-control form-font p-2 comments-area"></textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="row sub-button mt-4">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="addBack()" type="button">Back</button>
          <button mat-raised-button class="admin-btn-success"  (click)="addNewCustomReport(customReportData.value)" type="button">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showCustomRepEdit" class="col-sm-12">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
          <div>Edit Custom Report</div>
        </strong>
      </h3>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <form [formGroup]="recipientData" class="mt-1 form-height criteria-data">
            <div class="row px-3">
              <div class="col-sm-6">
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Name<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim title="{{ recipientData.value.name }}" formControlName="name" type="text" maxlength="100"
                      class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Category<span class="error-msg">*</span></label>
                  </div>

                  <div class="col-sm-7 text-xs">
                    <select class="form-control form-font" formControlName="Category">
                      <option *ngFor="let cat of distinctCategory" value="{{ cat.item}}">
                        {{ cat.item }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Criteria/CDN for edit and update -->
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Criteria<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs mb-3">
                    <mat-form-field class="w-100 custom-border" appearance="outline">
                      <mat-select disableOptionCentering matNativeControl [disableRipple]="true"
                        formControlName="criteria" (click)="getSortData($event);"
                        [title]="customReportData.controls['criteria'].value" (selectionChange)="selectedValue($event)"
                        multiple #select="matSelect" [sortComparator]="sortComparator">
                        <mat-option value="{{item.CDN}},{{item.Criteria}}"
                          [ngClass]="item.isEdit?'isnotVisible':'isVisible'"
                          *ngFor="let item of CriteriaList;let i=index" (click)="toggleAllSelection(item.key,$event)"
                          (keyup)="tempValue($event)" title="{{item.CDN}}({{item.Criteria}})">
                          <span *ngIf="!item.isEdit" class="edit_criteria"> {{item.CDN}}({{item.Criteria}})</span>
                          <span class="pull-left" *ngIf="item.isEdit">
                            <input type="text" (keyup)="targetValue($event);" (keydown)="$event.stopPropagation();"
                              (click)="disableEvent($event)" [value]="item.CDN" class="form-control place-holder"
                              [title]="item.CDN">
                          </span>
                          <span class="pull-right ">
                            <button *ngIf="!disable" class="btn btn-sm rounded-circle border-0 btn-dark bg-white"
                              (click)="editCDN($event,i,item)" [disabled]="disable">
                              <em class="fa fa-pencil"></em>
                            </button></span>
                          <span class="pull-right">
                            <span type="button" *ngIf="item.check!=-1 && item.check != 0"
                              class="btn btn-outline-warning btn-sm rounded-circle border-0">({{item.check}})</span>
                            <span *ngIf="i==textview" class="content-wrap">
                              <span class="btn btn-sm" (click)="saveCDN($event,i,item)"><em
                                  class="fa fa-check text-success"></em></span>
                              <span class="btn btn-sm " (click)="cancelCDN($event,i,item)"><em
                                  class="fa fa-times text-danger"></em></span>
                            </span>
                          </span>
                        </mat-option>
                      </mat-select>
                      <em class="fa fa-chevron-down chevron-align"></em>
                    </mat-form-field>
                  </div>
                </div>
                <div class="p-0 row">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Version<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <select title="{{ recipientData.value.Version }}" class="form-control form-font"
                      formControlName="Version">
                      <option value="" selected disabled>Select</option>
                      <option value="0">0 (Multi Sheet)</option>
                      <option value="1">1 (Normal Reports)</option>
                      <option value="2">2 (XLSX format files)</option>
                      <option value="4">4 (CSV format files)</option>
                      <option value="5">5 (XLSX using GrapeCity)</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Asynchronous
                    </label>
                  </div>
                  <div class="col-sm-7 async-align text-xs">
                    <div class="col-sm-12 row">
                      <div class="col-sm-6 text-xs row reportdb-align">
                        <input type="checkbox" formControlName="IsAsynchronousReport" value="True"
                          class="check-box-align m-0" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font"> Approx. Generation Time</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="approxGenarationTime" min="0" maxlength="4">

                  </div>
                </div>

              </div>
              <div class="col-sm-6">
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Title<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim title="{{ recipientData.value.title }}" formControlName="title" type="text" maxlength="100"
                      class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">SP Name<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim title="{{ recipientData.value.storedProcedure }}" formControlName="storedProcedure"
                      type="text" maxlength="250" class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Named Range<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input trim title="{{ recipientData.value.NamedRange }}" formControlName="NamedRange" type="text"
                      maxlength="100" class="form-control form-font" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Max Days</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <!-- <input formControlName="MaxDays" type="number" min="0" maxlength="4" class="form-control form-font" /> -->
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="MaxDays" min="0" maxlength="4">
                  </div>
                </div>

                <div *ngIf="recipientData.value.IsAsynchronousReport == true" class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Max Records</label>
                  </div>

                  <div class="col-sm-7 text-xs">
                    <!-- <input formControlName="ShowMaxRecord" type="number" min="0" maxlength="4"  class="form-control form-font" /> -->
                    <input id="inp_Phone#" (keypress)="AllowOnlyNumber($event)" class="form-control form-font"
                      formControlName="ShowMaxRecord" min="0" maxlength="4">
                  </div>
                </div>
                <div  class="row mt-3 p-0">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Connection Name</label>
                  </div>

                  <div class="col-sm-7 text-xs row reportdb-align">
                    <input type="checkbox" formControlName="ConnectionName" value="ReportDB"
                      class="check-box-align m-0" />
                    <label class="pl-2 m-0">ReportDB</label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <!-- <label class="label-font">Status</label> -->
                    <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="isActive">Active
                      </mat-checkbox>
                  </div>
                  <!-- <div class="col-sm-7 text-xs">
                    <input type="checkbox" formControlName="isActive" (change)='checkValue($event)' [checked]="isActive"
                      value="isActive" class="check-box-align m-0" />
                  </div> -->
                </div>
                <div class="row mt-3">
                  <div class="col-sm-5 text-xs">
                    <label class="label-font">Enable Multi-Sheet</label>
                  </div>
                  <div class="col-sm-7 text-xs">
                    <input type="checkbox" formControlName="isSelect" (change)='multisheetcheckValue($event)' [checked]="isSelect"
                      value="isSelect" class="check-box-align m-0" >
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showUploadInEdit" class="row mt-3 form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Template File</label>
              </div>
              <div class="col-sm-9 text-xs">
                <input ID="myFileInputID" type="file" accept=".xlsx" class="form-control form-font"
                  (change)="handleUpload($event,'edit')" />
                <p>Please upload only .xlsx format file</p>
              </div>
              <div class="col-sm-1 pt-2">
                <em class="fa fa-download curser-pointer" [value]="custTemplateFile"
                  (click)="downloadTemplate(custTemplateFile)" title="Download" aria-hidden="true"></em>
              </div>
            </div>
            <div class="row mt-3 add-form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Template Filename</label>
              </div>
              <div class="col-sm-10 text-xs">
                <input type="text" maxlength="100" class="form-control form-font" formControlName="templateFile"/>
              </div>
            </div>
            <div class="row mt-3 pt-2 form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font">Roles<span class="error-msg">*</span></label>
              </div>
              <ng-multiselect-dropdown class="col-10 text-xs" [placeholder]="'Select'" [formControl]="formdata.name"
                [settings]="dropdownSettings" [data]="roleArray" [(ngModel)]="selectedLevel"
                (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()">
              </ng-multiselect-dropdown>
            </div>
            <div class="row mt-3 form-align">
              <div class="col-sm-2 text-xs">
                <label class="label-font ">Description<span class="error-msg">*</span></label>
              </div>
              <div class="col-sm-10 text-xs">
                <textarea trim class="mailtext-area" title="{{recipientData.value.Description}}"
                  formControlName="Description" type="text" maxlength="2000"
                  class="form-control form-font p-2 comments-area"></textarea>
              </div>
            </div>
          </form>
          <div class="row mt-2">
            <div class="col-12">
              <div class="row align-center mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="updateBackCustom()" type="button">
                  Back
                </button>
                <button mat-raised-button class="admin-btn-success mr-4" (click)="resetTheForm()" type="button">
                  Reset
                </button>
                <button mat-raised-button class="admin-btn-success" (click)="UpdateCustomReport(recipientData.value)" type="button">Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- view -->
  <div *ngIf="showCustomReportView" class="col-sm-12 pr-3 pl-3">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>{{ custTitle }}</strong>
      </h3>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="row px-3">
            <div class="col-sm-12 reportModel form-height">
              <div class="row">
                <div class="col-sm-6 border-leftalign">
                  <div class="row mt-3  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Name</label>
                    </div>
                    <div class="col-sm-8 word-break text-xs">
                      <span title="{{ custReportName }}">{{
                        custReportName
                        }}</span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4 word-break">
                      <label class="label-font">Category </label>
                    </div>
                    <div class="col-sm-8 text-xs">
                      <span title="{{ custCategory }}">{{
                        custCategory
                        }}</span>
                    </div>
                  </div>
                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">Template File</label>
                    </div>
                    <div class="col-sm-1">
                      <em class="fa fa-download curser-pointer" [value]="custTemplateFile"
                        (click)="downloadTemplate(custTemplateFile)" title="Download" aria-hidden="true"></em>
                    </div>
                    <div class="col-sm-8 word-break text-xs">
                      <span title="{{ custTemplateFile }}" readonly style="width: 20px">{{ custTemplateFile }}</span>
                    </div>
                  </div>
                  <div *ngIf="custCriteria" class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Criteria</label>
                    </div>
                    <div class="col-sm-8 word-break text-xs">
                      <span readonly title="{{ custCriteria }}">{{ custCriteria }}</span>
                    </div>
                  </div>

                  <div  class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Version </label>
                    </div>
                    <div class="col-sm-8 text-xs">
                      <span>{{ custVersion }} ({{ versiondesc }})</span>
                    </div>
                  </div>

                  <div *ngIf="custAsync" class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Asynchronous</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span readonly>{{ custAsync }}</span>
                    </div>
                  </div>
                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Created By</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span *ngIf="CreatedBy.toLowerCase() != 'not specified'">{{ createrName[0].firstname }}
                        {{ createrName[0].lastname }} ({{ CreatedBy }})
                      </span>
                      <span *ngIf="CreatedBy.toLowerCase() == 'not specified'">{{ CreatedBy }}
                      </span>
                    </div>
                  </div>
                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Modified By </label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span *ngIf="Modifiedby.toLowerCase() != 'not specified'">{{ modifierName[0].firstname }}
                        {{ modifierName[0].lastname }} ({{
                        Modifiedby
                        }})</span>
                      <span *ngIf="Modifiedby.toLowerCase() == 'not specified'">{{ Modifiedby }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mt-3  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Title </label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span title="{{ custTitle }}">{{ custTitle }}</span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-3">
                      <label class="label-font">SP Name</label>
                    </div>
                    <div class="col-sm-1">
                      <img class="copyIcon cursor" title="copy" alt="copy" (click)="copy(custStoredProcedure)"
                        src="/assets/images/copy.svg" />
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span title="{{ custStoredProcedure }}" readonly>{{
                        custStoredProcedure
                        }}</span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Named Range </label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span title="{{ custNamedRange }}">{{
                        custNamedRange
                        }}</span>
                    </div>
                  </div>

                  <!-- <div
                      *ngIf="custCriteriaDisplayName"
                      class="row mt-1  "
                    >
                      <div class="text-xs text-left col-sm-4">
                        <label class="label-font">Criteria Display Name</label>
                      </div>
                      <div class="col-sm-8">
                        <span readonly title="{{ custCriteriaDisplayName }}">{{
                          custCriteriaDisplayName
                        }}</span>
                      </div>
                    </div> -->
                  <div  class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Connection Name</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span title="{{ custConnectionName }}" readonly>{{
                        custConnectionName
                        }}</span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font"> Max Days</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span readonly>{{ custMaxDays }}</span>
                    </div>
                  </div>
                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font"> Approx. Generation Time</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span readonly>{{ approx }}</span>
                    </div>
                  </div>

                  <div *ngIf="custShowMaxRecord && custAsync != 'False'" class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font"> Max Record</label>
                    </div>
                    <div class="col-8 break-words text-xs">
                      <span readonly>{{ custShowMaxRecord }}</span>
                    </div>
                  </div>
                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Created Date</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span>{{ custCreatedDate }} </span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Modified Date</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span>{{ custmodifiedDate }} </span>
                    </div>
                  </div>

                  <div class="row mt-1  ">
                    <div class="text-xs text-left col-sm-4">
                      <label class="label-font">Status</label>
                    </div>
                    <div class="col-sm-8 break-words text-xs">
                      <span>{{ activeMsg }} </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 customChipList">
                  <div class="row">
                    <div class="text-xs text-left col-sm-2 mt-1 rol-align">
                      <label class="label-font">Roles</label>
                    </div>
                    <span *ngIf="myRolesArray != undefined && myRolesArray.length > 0" class="col-sm-10">
                      <mat-chip-list class="col-sm-10 mt-1 mb-1 text-xs">
                        <mat-chip class="margin-class" *ngFor="let roles of myRolesArray" selected color="chip.color">
                          <span>{{ roles }}</span>
                        </mat-chip>
                      </mat-chip-list>
                    </span>
                    <div class="col-sm-10 mt-1 mb-1 text-xs"
                      *ngIf="myRolesArray == undefined || myRolesArray.length <= 0">
                      <span><em>No roles Associated !</em></span>
                    </div>
                  </div>
                  <div class="row mt-2  ">
                    <div class="text-xs text-left col-2">
                      <label class="label-font">Description</label>
                    </div>
                    <div class="col-sm-10 break-words text-xs">
                      <span title="{{ custDescription }}">{{ custDescription }}
                      </span>
                    </div>
                  </div>
                  <!-- <div
                      *ngIf="myRolesArray"
                      class="row mt-2  "
                    >
                      <div class="text-xs text-left col-2">
                        <label class="label-font">Roles</label>
                      </div>
                      <div class="col-10">
                        <span>{{ myRolesArray }}</span>
                      </div>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="col-sm-12 mt-4">
            <div class="sub-button">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="editBack()">Back</button>
              <button mat-raised-button class="admin-btn-success mr-4" (click)="exportDetailsToExcel()"
                [disabled]="hideExportBtn">
                Export
              </button>
              <button mat-raised-button class="admin-btn-success" *ngIf="customerReportGrid != -1"
                (click)="editCustomReport() " [disabled]="hideEditBtn" type="button">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="uploadScreen" class="col-sm-12 pr-3 pl-3">
    <div class="modal-header admin-model-header   mb-2 mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>Custom Report Upload</strong>
      </h3>
    </div>
    <div *ngIf="gridWidth == 0">
      <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
          <mat-label class="d-flex">
            Templates
          </mat-label>
          <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
          <mat-select disableOptionCentering class="ml-2 temp-mat-align">
            <mat-option class="temp-font-size" (click)="downloadExcelTemplate()">
              <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
            </mat-option>
            <mat-option class="temp-font-size" (click)="downloadExcelTemplate(true)">
              <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="button-field-wrap">
        <span class="instr-size">
          <img aria-hidden="true" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
          You can download the sample template file to upload the Locations.</span>
      </div>

      <div class="col-sm-12 p-0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
          <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
            hidden />
          <span class="align-center img-wrap">
            <img src="/assets/images/upload_excel.svg" width="60px" height="auto">
          </span>
          <span>Drag & Drop excel files here</span>
          <span class="align-center">Or</span>
          <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-3" (click)="refreshGrid()">
          Back
        </button>
      </div>
    </div>
    <div *ngIf="gridWidth > 0">
      <wj-flex-grid class="col-sm-12 mt-2 p-0" [isReadOnly]="true" [itemsSource]="gridData" #grid
        [headersVisibility]="'Column'" (initialized)="initializedGrid(grid)" (formatItem)="formatItem(grid, $event)"
        [frozenColumns]="2" >
        <div *ngFor="let columnname of sheetHeader">
          <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [allowResizing]="true"  [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [isReadOnly]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
            <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton" [title]="'Delete'"
                class="cursor p-0 fa fa-trash"></em></button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
      <div class="pagination-align mt-1">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
        <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
          <wj-menu-item [value]="0">No Paging</wj-menu-item>
          <wj-menu-item [value]="10">10</wj-menu-item>
          <wj-menu-item [value]="15">15</wj-menu-item>
          <wj-menu-item [value]="30">30</wj-menu-item>
          <wj-menu-item [value]="50">50</wj-menu-item>
        </wj-menu>
      </div>

      <div class="row pl-3">
        <div class="col-sm-2 p-0">
          <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="backtoGrid()">
            Back
          </button>
        </div>
        <div class="col-sm-10 button-wrap p-0 ">
          <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
            <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
              (click)="refreshGrid()">Finish</button>
            <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
              (click)="refreshGrid()">Cancel</button>

            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
              [disabled]="disableApprovebtn()" (click)="uploadBulkAccountCreation()">Approve</button>
            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
              (click)="downloadResult(grid)">Download
              Result</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
      <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
  </div>
</div>
