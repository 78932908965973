import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
//import { CronOptions } from 'cron-editor/lib/CronOptions';
import cronstrue from 'cronstrue';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { environment } from '../../../../environments/environment';
import { FormControl } from '@angular/forms';

import { CronGenComponent, CronOptions } from 'ngx-cron-editor';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-frequency-modal',
  templateUrl: './frequency-modal.component.html',
  styleUrls: ['./frequency-modal.component.scss']
})

export class FrequencyModalComponent implements OnInit {


  @ViewChild('cronEditorDemo', { static: true })
  cronEditorDemo: CronGenComponent;
  public cronOptions: CronOptions = {
    defaultTime: "00:00:00",

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: false,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab : false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: "quartz" //standard or quartz
 };
  @Output() cronMessage = new EventEmitter<object>();

  public cronExpression = '0 0/1 * 1/1 * ? *';
  //public cronExpression = '0 30 12 * * ? *';
  public cronDescription;
  public isCronDisabled = false;
  showError: boolean = false;
  timeZone: String;
  cronForm: FormControl<string>;


  // public cronOptions: CronOptions = {
  //   formInputClass: 'form-control cron-editor-input',
  //   formSelectClass: 'form-control cron-editor-select',
  //   formRadioClass: 'cron-editor-radio',
  //   formCheckboxClass: 'cron-editor-checkbox',
  //   defaultTime: '10:00:00',
  //   use24HourTime: true,
  //   hideMinutesTab: false,
  //   hideHourlyTab: false,
  //   hideDailyTab: false,
  //   hideWeeklyTab: false,
  //   hideMonthlyTab: false,
  //   hideYearlyTab: false,
  //   hideAdvancedTab: false,
  //   hideSeconds: false,
  //   removeSeconds: false,
  //   removeYears: false
  //   };


  constructor(public vitalHttpServices: VitalHttpServices) {
    this.cronDescription = (this.cronExpression && this.cronExpression.toString() != '') ? cronstrue.toString(this.cronExpression) : '';
  }


  ngOnInit(): void {
    this.cronForm = new FormControl(this.cronExpression);
    if(environment.envPrefix.includes('DEV') || environment.envPrefix.includes('QC')) {
    this.timeZone="IST"
    }
    else {
      this.timeZone="EST"
    }
  }


  openModal() {
    $('#setFrequencyModal').modal('show').appendTo("body");
  }

  closeModal() {
    this.generateCronDescription('0 0/1 * 1/1 * ? *') // Minute
    this.cronDescription = 'Every minute';
    $('#setFrequencyModal').modal('hide');
  }

  saveCronExpression() {
    if (this.cronForm.value && this.cronForm.value.toString() != '') {
      this.cronMessage.emit({ cronExpression: this.cronForm.value.trim(), cronDescription: this.cronDescription.trim() });
    }
    else {
      this.cronMessage.emit({ cronExpression: '', cronDescription: '' });
    }
    this.closeModal();
  }


  generateCronDescription(cronExpression) {

    this.cronDescription =this.cronToDescription(cronExpression)
    this.cronExpression = cronExpression
    let obj = {
      cronExpression: cronExpression
    }
    if (cronExpression == '') {
      this.cronDescription = this.cronToDescription(cronExpression);
      this.showError = true;
      return;
    }
    this.vitalHttpServices.cronValidation(obj).subscribe((res) => {

      if (res) {
        try {
          this.cronDescription = cronstrue.toString(this.cronExpression)
          this.showError = false;
        }
        catch{
          this.cronDescription = '';
          this.showError = true;
        }
      }
      else {
        this.cronDescription = '';
        this.showError = true;
      }
    }, error => {
      this.cronDescription = '';
      this.showError = true;
    });
  }

  ngOnDestroy() {
    $("body>#setFrequencyModal").remove();
  }

  cronToDescription(cronExpression) {
    const cronParts = cronExpression.split(' ');

    const minute = cronParts[0];
    const hour = cronParts[1];
    const dayOfMonth = cronParts[2];
    const month = cronParts[3];
    const dayOfWeek = cronParts[4];

    let description = '';

    // Minute
    if (minute === '*') {
        description += 'Every minute';
    } else {
        description += `At minute ${minute}`;
    }

    // Hour
    if (hour === '*') {
        description += ', every hour';
    } else {
        description += `, at hour ${hour}`;
    }

    // Day of month
    if (dayOfMonth === '*') {
        description += ', every day';
    } else {
        description += `, on day ${dayOfMonth} of the month`;
    }

    // Month
    if (month === '*') {
        description += ', every month';
    } else {
        description += `, in ${month}`;
    }

    // Day of week
    if (dayOfWeek === '*') {
        description += ', every day of the week';
    } else {
        description += `, on ${dayOfWeek}`;
    }

    return description;
}
}
