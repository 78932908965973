<div >
	<mat-form-field appearance="fill" style="margin-top: 10px;">
		<mat-label>Selet Type</mat-label>
		<mat-select  disableOptionCentering [(ngModel)]="SelectedType"  (selectionChange)="selectType($event.value)">
		  <mat-option *ngFor="let result of typeList" [value]="result.Type" >
			{{result.Type}}
		  </mat-option>
		</mat-select>
	  </mat-form-field>
  <div class="content" style="border: 1px solid darkgrey; margin-right: 4%;">
      <!-- <div class="editor"> -->
        <!-- <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu> -->
        <!-- <ngx-editor [editor]="editor" [(ngModel)]="editordoc">
        </ngx-editor> -->

        <textarea name="" id="" cols="" rows="20"  style="width: 100%;" [(ngModel)]="editordoc"></textarea>
      <!-- </div> -->
  </div>
</div>
