<div class="col-sm-12 association-wrap">
  <div class="col-sm-12 p-0" *ngIf="!setupNewFacilityBtnClick && !setupNewEntitiesBtnClick">
    <div *ngIf="tableGrid" class="col-sm-12 p-1">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-header admin-model-header mt-acc">
            <h3 class="modal-title w-100 admin-model-header-txt">
              <strong>Associated Locations</strong>
            </h3>
          </div>
        </div>
        <div class="col-sm-12 ml-auto mt-3 pr-2 dropdown-wrap">
          <mat-form-field appearance="outline" *ngIf="showSetupNewFacilityBtn" class="col-sm-2 p-0 m-0 frmMatSelect epg-wrap ">
            <mat-label>Setup Entity</mat-label>
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
              <mat-option  [disabled]="btnCreateFacility" (click)="CreateNewEpg()">
                <span>EPG</span>
              </mat-option>
              <mat-option [disabled]="btnCreateFacility" (click)="CreteNewSendOut()">
                <span>Send-Out</span>
              </mat-option>
              <mat-option [disabled]="btnCreateFacility" (click)="CreateNewSendIn()">
                <span>Send-In</span>
              </mat-option>
              <mat-option  [disabled]="btnCreateFacility" (click)="CreateNewFacility()">
                <span>Facility</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button class="admin-btn-success float-right mr-3 mt-1" [title]="'De-Associate'"
            [disabled]="btnRemove" (click)="deAssociateAccounts()">De-Associate
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="tableGrid">
      <div *ngIf="templateData && templateData.labels && gridwidth > 0">

        <wj-flex-grid class="col-sm-12 p-0" #flexgrid (initialized)="initializeGrid(flexgrid)"
          [isReadOnly]="true" [itemsSource]="gridData2" [frozenColumns]="1">
          <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="320" [isReadOnly]="true">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row">
              <button [disabled]="editBtn" class="edit-delete-btn" title="Edit" *ngIf="editEnabled(row)"
                (click)="openEditScreen(flexgrid,$event)">
                <em id="editButton" class="edit-template cursor p-0 fa fa-edit"></em></button>
            </ng-template>
          </wj-flex-grid-column>
          <!-- <div *ngFor="let columnname of columnDefs"> -->
          <wj-flex-grid-column *ngFor="let columnname of columnDefs" [binding]="columnname"
            [header]="columnname.split('_').join(' ')" [visible]="!(columnname == 'ID') && !(columnname == 'Association_Type') && !(columnname == 'Associated_Account_Id') && !(columnname == 'Owner_Lab_Org_Id') &&
         !(columnname == 'Display_OF_in_Lists') && !(columnname == 'OrganizationID') " [width]="170" [format]="'d'"
            aria-expanded="false" [allowResizing]="true">
          </wj-flex-grid-column>
          <!-- </div> -->
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="align-center mt-2">
          <div class="form-inline well well-lg align-center">
            <button (click)="ExportExcel(flexgrid)" mat-raised-button class="admin-btn-success mr-4"
              [disabled]="export">
              Export
            </button>

            <button (click)="uploadOFAccountData()" [disabled]="btnUpload" *ngIf="locationType == 'Laboratory'"
              mat-raised-button class="admin-btn-success mr-4">
              Upload
            </button>

            <button mat-raised-button class="admin-btn-success" [title]="'Associate'" [disabled]="btnCreate"
              (click)="addNewAssociation()">
              Associate
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="gridwidth == 0">
        <button (click)="uploadOFAccountData()" [disabled]="btnUpload" *ngIf="locationType == 'Laboratory'"
          mat-raised-button class="admin-btn-success mr-4">
          Upload
        </button>

        <button mat-raised-button class="admin-btn-success" [title]="'Associate'" [disabled]="btnCreate"
          (click)="addNewAssociation()">
          Associate
        </button>

        <div class="nodata-wrap mt-4">
          <div class="nodata-design">No Data Found</div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="associationModel" class="col-sm-12 mt-3">
    <div class="modal-header admin-model-header marg-top">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
          <div>Associate Locations</div>
        </strong>
      </h3>
    </div>
    <div class="row col-sm-12 mr-2 ml-2 case-width boder-checkbox">
      <div class="row col-sm-12 p-0 m-1">
        <section class="col-sm-12 p-0 casetype-section card-wrap temp-font-size">
          <div class="row mt-2 ml-1 p-0">
            <div class="col-sm-9 p-0">
              <mat-checkbox [color]="task.color" [checked]="checkedAllAccounts(accountsList)"
                [indeterminate]="!checkedAllAccounts(accountsList) && notAllUnchecked(accountsList)"
                (change)="checkAll(accountsList,$event.checked)" class="casetype-margin check-box">
                <strong class="head-font">{{ accountsList.Organization }}</strong>
              </mat-checkbox>
            </div>

            <div class="row col-sm-3 error-msg" style="display: flex;align-items: center;justify-content: flex-end;"
              *ngIf="checkNoAccountSelected()">
              <mat-icon matSuffix><i class="fa fa-exclamation-circle error-msg" aria-hidden="true"></i></mat-icon>
              Please select at least one location
            </div>
          </div>

          <div class="row col-sm-7 mr-3">
            <mat-form-field appearance="outline">
              <mat-label>Location Search</mat-label>
              <i class="fa fa-chevron-down chevron-align"></i>
              <input type="text" #searchInput style="width: 94%" matInput #trigger="matAutocompleteTrigger"
                [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchAccount"
                (input)="filterAccounts(searchAccount)">
              <button *ngIf="searchAccount" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                (click)="clearResults('searchAccount',$event)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete (optionSelected)="filterAccounts($event.option.viewValue)" #auto="matAutocomplete">
                <mat-option class="mat-opt-align" title="{{account.Name}}"
                  *ngFor="let account of getAccountsList(searchAccount)">
                  <span class="font-size">{{account.Name}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="m-0 checkbox-wrap col-sm-12">
            <div *ngFor="let value of getAccountsList(searchAccount)" class="col-sm-3 p-0 m-0">
              <mat-checkbox [color]="task.color" [checked]="value.checked" [disabled]="value.disabled"
                (change)="checkAccount(value, $event.checked)">
                {{ value.Name }}
              </mat-checkbox>
            </div>
          </div>
        </section>
      </div>
    </div>

    <form [formGroup]="recipientData" class="mt-3">
      <div class="row col-sm-12 p-0 m-0">
        <div class="col-sm-3">
          <strong>Types of Association</strong>
          <mat-checkbox [color]="task.color" class="mt-2" #currLoc [color]="task.color" [disabled]="checkForDisabled()"
            (change)="fetchCurrentLocationAssociations($event.checked)">
            Same as current location
          </mat-checkbox>

          <mat-form-field appearance="outline">
            <mat-label>Select Type</mat-label>
            <mat-select disableOptionCentering [disabled]="currentAssociations" formControlName="AssociationType">
              <mat-option *ngFor="let cat of associationTypeList"
                (click)="selectAssociation($event,recipientData.controls.AssociationType)" value="{{ cat.item }}">
                {{ cat.item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-5 ngx-drop">
          <strong *ngIf="recipientData.controls.AssociationType.value != ''">Associate With</strong>
          <div class="col-sm-12 p-0" *ngIf="recipientData.controls['AssociationType'].value != ''">
            <div class="row p-0 m-0 mt-2">
              <div class="col-6 p-0">
                <mat-radio-button value="Organizations" [disabled]="currentAssociations" formControlName="Selecttype"
                  class="mr-1" (change)="changingOrganizationAndAccount('Organizations')">Location Group
                </mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="Accounts" [disabled]="currentAssociations" formControlName="Selecttype"
                  class="mr-1" (change)="changingOrganizationAndAccount('Accounts')">Locations
                </mat-radio-button>
              </div>
            </div>

            <div *ngIf="recipientData.controls.Selecttype.value == 'Accounts' " class="mt-3">
              <mat-form-field appearance="outline" class="col-sm-12 p-0">
                <mat-label>Locations</mat-label>
                <i class="fa fa-chevron-down chevron-align"></i>
                <input type="text" style="width: 94%;" matInput [matAutocomplete]="auto"
                  title="recipientData.value.AssociatedAccountid" formControlName="AssociatedAccountid" maxlength="200"
                  placeholder="Please enter minimum three characters to search" aria-label="Select" (input)="locationFilter($event)">
                <button *ngIf="recipientData.value.AssociatedAccountid" matSuffix mat-icon-button aria-label="Clear"
                  title="Reset Search" (click)="clearResults('AssociatedAccountid',$event)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option class="mat-opt-align" id="drop-down-mat" *ngFor="let acc of  locationsList"
                    value="{{ acc.accountname }} ({{ acc.accountid }})"
                    (onSelectionChange)="getAssociatedAccount($event)">
                    <span>{{ acc.accountname }} ({{ acc.accountid }})</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div *ngIf="recipientData.controls.Selecttype.value ==
              'Organizations'" class="mt-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Location Group</mat-label>
                <i class="fa fa-chevron-down chevron-align" style="margin-top: 6px;"></i>
                <input type="text" style="width: 93%" class="asso-input-wrap" matInput [matAutocomplete]="auto"
                  formControlName="associateOrg" (ngModelChange)="onOrganizationChange($event,recipientData.controls.AssociationType)"
                  placeholder="Please enter minimum three characters to search" class="mt-2"  (input)="locationFilter($event)" />
                <button *ngIf="recipientData.value.associateOrg" matSuffix mat-icon-button aria-label="Clear"
                  title="Reset Search" (click)="clearResults('associateOrg',$event)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option class="mat-opt-align" id="drop-down-mat" *ngFor="let org of organizationsList" value="{{
                      org.organizationname}} ({{ org.organizationid }})"
                    (onSelectionChange)="getOrganizationName($event)">
                    <span>{{ org.organizationname }}({{org.organizationid}})</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div *ngIf="(AddMultiselect && recipientData.controls.Selecttype.value == 'Organizations' &&
            recipientData.controls['associateOrg'].value != '') && (this.temporgid != null)"class="mt-3">
              <ng-multiselect-dropdown [placeholder]="'Locations'" [settings]="dropdownSettings"
                [data]="accountsOfSelectedOrg" [(ngModel)]="selectedLevel" (onSelect)="onCheckChange($event)"
                (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)" formControlName="AssociatedAccountid">
              </ng-multiselect-dropdown>
            </div>

            <div *ngIf="(UpdateMultiselect && recipientData.controls['Selecttype'].value =='Organizations' &&
            recipientData.controls['associateOrg'].value != '') && (this.temporgid != null)" class="mt-3">
              <ng-multiselect-dropdown [placeholder]="'Locations'" [formControl]="getformDetails.id"
                [settings]="dropdownSettings" [data]="accountsOfSelectedOrg" [(ngModel)]="selectedLevel"
                (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)"
                formControlName="AssociatedAccountid">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div>
            <strong>Configurations</strong>

            <div class="row mt-2">
              <div class="col-sm-12 pr-0">
                <mat-form-field appearance="outline">
                  <mat-label>Service Type</mat-label>
                  <mat-select disableOptionCentering [(ngModel)]="serviceType" [disabled]="currentAssociations"
                    formControlName="Service">
                    <mat-option *ngFor="let cat of servicesList" value="{{ cat.item }}">
                      {{ cat.item }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row p-0 temp-font-size">
              <div class="col-sm-11">
                <label>Diagnosis Reporting done using VitalDx(VitalOffice)
                </label>
              </div>
              <div class="col-sm-1">
                <mat-checkbox [color]="task.color" [disabled]="currentAssociations" formControlName="isPcEnabled"
                  value="true">
                </mat-checkbox>
              </div>
            </div>

            <div class="row mt-3 p-0 temp-font-size">
              <div class="col-sm-11">
                <label>Allow lab to view reports </label>
              </div>
              <div class="col-sm-1">
                <mat-checkbox [color]="task.color" [disabled]="currentAssociations" formControlName="AllowReportViewing"
                  value="true">
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row mb-2 boder-checkbox">
      <div class="col-12 d-flex">
        <div class="col-sm-12 mt-4 mb-4">
          <div class="sub-button">
            <button *ngIf="cancelButton || (addeddata && addeddata.length == 0)" [disabled]="currentAssociations"
              mat-raised-button class="admin-btn-success mr-4" (click)="backAssociation()" type="button">
              Back
            </button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="currentAssociations"
              (click)="getpatchdata()" type="button">
              Reset
            </button>
            <!-- *ngIf="!(cancelButton||( addeddata && addeddata.length==0)) && recipientData.controls['AssociationType'].value !=''" -->
            <button mat-raised-button class="admin-btn-success" (click)="addAssociationList(recipientData.value)"
              type="button" [disabled]="
                (recipientData.value.AssociationType == null ||
                recipientData.value.AssociationType == '' ||
                recipientData.value.AssociatedAccountid == '' ||
                recipientData.value.AssociatedAccountid == null)">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-3" *ngIf="associationTable && addeddata.length > 0"><strong>Locations to be Associated</strong></div>
    <div *ngIf="associationTable && addeddata.length > 0" class="col-sm-12 mt-2">
      <wj-flex-grid #assocgrid [itemsSource]="gridData" [allowDelete]="false"
        [headersVisibility]="'Column'" (initialized)="initGrid(grid)">
        <wj-flex-grid-column [header]="'Services'" [dataMap]="serviceDataMap" [width]="120" [binding]="'services'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'PC Enabled'" [width]="120" [binding]="'isPcEnabled'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Allow report viewing'" [width]="120" [binding]="'AllowReportViewing'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
            <button id="deleteButton" class="wj-btn remove-background p-0">
              <em id="deleteButton" [title]="'Delete'" class="fa fa-trash" (click)="deleteRowFromGrid(grid, row)"></em>
            </button>
          </ng-template>
        </wj-flex-grid-column>
        <div *ngFor="let columnname of columns">
          <wj-flex-grid-column [visible]="columnname.header != 'AssociationID'" [isReadOnly]="true"
            [binding]="columnname.binding" width="*" [header]="columnname.header">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
      <div class="pagination-align mt-1">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="col-md-6 mt-4 p-0 m-0">
            <div class="sub-button d-flex float-left">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="backAssociation()">
                Back
              </button>
            </div>
          </div>
          <div class="col-sm-6 mt-4 p-0 m-0">
            <div class="sub-button float-right">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="AddAssociation(assocgrid)" type="button"
                [disabled]="Edit">
                Save & Close
              </button>
              <button mat-raised-button class="admin-btn-success" (click)="AddAssociation(assocgrid,true)" type="button"
                [disabled]="Edit">
                Save & Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-0 m-0" *ngIf="setupNewFacilityBtnClick">
    <div class="col-12 p-0 m-0">
      <app-setup-new-facility [shareData]="shareData" [editMode]="editMode" [editData]="editData"
        [ContextValue]="ContextValue">
      </app-setup-new-facility>
    </div>
  </div>
  <div class="row p-0 m-0" *ngIf="setupNewEntitiesBtnClick">
    <div class="col-12 p-0 m-0">
      <app-setup-new-entities [shareData]="shareData" [editMode]="editMode" [editData]="editData"
        [ContextValue]="ContextValue">
      </app-setup-new-entities>
    </div>
  </div>
</div>

<!-- bulk upload -->
<div *ngIf="uploadScreen">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong>Bulk Facility Upload</strong>
    </h3>
  </div>
  <div *ngIf="gridWidth == 0">
    <div class="button-field-wrap m-1 p-0">
      <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
        <mat-label class="d-flex">
          Templates
        </mat-label>
        <mat-select disableOptionCentering class="ml-2 temp-mat-align">
          <mat-option class="temp-font-size" (click)="downloadTemplate()">
            <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
          </mat-option>
          <mat-option class="temp-font-size" (click)="downloadTemplate(true)">
            <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="button-field-wrap">
      <span class="instr-size">
        <img aria-hidden="true" class="mb-1" alt="help" src="/assets/icons/help.svg" width="15px" height="auto" />
        You can download the sample template file to upload the Locations.</span>
    </div>

    <div class="col-sm-12 p-0">
      <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
        <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
          hidden />
        <span class="align-center img-wrap">
          <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
        </span>
        <span>Drag & Drop excel files here</span>
        <span class="align-center">Or</span>
        <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
      </div>
      <button mat-raised-button class="admin-btn-success mt-3" (click)="refreshGrid()">
        Back
      </button>
    </div>
  </div>
  <div *ngIf="gridWidth > 0">
    <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid
      [headersVisibility]="'Column'" (initialized)="initializedGrid(grid)" (formatItem)="formatItem(grid, $event)"
      [frozenColumns]="2">

      <!-- Action -->
      <div *ngFor="let columnname of sheetHeader">
        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button [disabled]="btnEdit" class="edit-delete-btn" (click)="deleteRow(grid, row)">
            <em id="deleteButton" [title]="'Delete'" class="cursor p-0 fa fa-trash">
            </em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <div class="page-align">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
      </wj-collection-view-navigator>
      <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>

    <div class="row pl-3">
      <div class="col-sm-2 p-0">
        <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="backtoGrid()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-wrap p-0">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4" (click)="refreshGrid()">
            Finish
          </button>
          <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4" (click)="refreshGrid()">
            Cancel
          </button>

          <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="disableApprovebtn()"
            (click)="uploadBulkAccountCreation()">
            Approve
          </button>
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="downloadResult(grid)">
            Download Result
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns:</div>
    &nbsp;<span>{{ invalidColumns }} </span>
  </div>
</div>

<!-- Alert -->
<div class="model-dialog row p-0 m-0 mt-2" *ngIf="alertPopUp" aria-hidden="true" data-backdrop="static"
  data-keyboard="false">
  <div class="col-12 p-0 m-0">
    <!-- The Modal -->
    <div class="col-sm-12 align-center modal" style="background: #393d406b;">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="admin-model-header">
          <h4 class="modal-title p-1">Associate Locations</h4>
        </div>
        <!-- Modal body -->
        <div class="modal-body" style="text-align: center;font-size:16px">
          <span class="title-card">Save Changes ?
          </span><br><span class="title-card">
            If not saved, any modifications made will be lost.
          </span><br>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer mt-4" style="display: block;margin: auto;">
          <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveAndOrProceed('save')">Save
            and Proceed</button>
          <button type="button" mat-raised-button class="admin-btn-success mr-4"
            (click)="saveAndOrProceed('dontsave')">Proceed
            without Saving</button>
          <button type="button" mat-raised-button class="admin-btn-success" (click)="alertPopUp = false">Stay
            in this Page</button>
        </div>
      </div>
    </div>
  </div>
</div>
