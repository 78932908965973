import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from '../../../core/services/datashare.service';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import {  MatDialog } from '@angular/material/dialog';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

declare var $: any;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-casetype-attributes',
  templateUrl: './casetype-attributes.component.html',
  styleUrls: ['./casetype-attributes.component.scss']
})
export class CasetypeAttributesComponent implements OnInit {
  SubMenuCardModel: SubMenuCardModel;
  task: Task = {
    color: 'primary',
  };

  @Input()
  templateData: any;
  gridData: any;
  public DeploymentKeys=[];

  orgingAttributeData: any;
  AttributeTypes = [];
  filterOrganizations: Observable<any[]>;
  gridheader = [
    "Attribute_Context",
    "Attribute_Context_Id_3",
    "Attribute_Context_Id_2",
    "Attribute_Service_Type",
    "Attribute_Value",
    "Attribute_Description",
    "Attribute_Context_Id",
    "Attribute_ID",
    "Attribute_Level",
    "Status",
    "Sequence_Order",
    "Attribute_Name",
    "Attribute_Type",
    "Created_By",
    "CreatedDate",
    "Modified_By",
    "Modified_Date"
  ];
  showPaginationMainGrid: boolean;
  attributeType = new FormControl();
  tempExport: any;
 // orgname = new FormControl();
  gridPage: boolean = false;
  gridWidth: number;
  workBook: any;
  sheetsToSelect: any[];
  organizationList: any;
  orgAttributeForm = this._fb.group({
    frmAttrType: ["", Validators.required],
    frmAttrName: "",
    frmIsActive: true,
    frmSeqOrder: "",
    AttributeContext: "",
    AttributeContextId: '',
    AttributeDescription: '',
    AttributeValue: '',
    AttributeServiceType: '',
    AttributeContextId2: '',
    AttributeContextId3: ''
  })
  copyOrgAttributesForm = this._fb.group({
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
    frmOrganization: ["", Validators.required],
    frmOrgID: [""],
    frmcpyasactive: true,
  })
  configname = new FormControl();
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetHeader: any[];
  gridDisplay: boolean;
  showDelete: boolean;
  showModal: boolean = true;
  orgId: any;
  cardType: any;
  attrtype: any = "";
  addEditScreen: boolean;
  showGrid: boolean = false;
  action: string;
  attrID: any;
  gridwidth: number;
  searchInput: string;
  searchUserInput: string;
  usertoggle: boolean;
  toggle: boolean;
  hideGrid: boolean;
  gridShowDiv: boolean;
  showPagination: boolean;
  searchResult: any[];
  copyFlag: any;
  excelDataArray: any;
  //orgName: any;
  organizationid: any;
  selectedItems: any = [];
  checkStatus: boolean;
  showMsg: boolean;
  globalexist: boolean = false;
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  hideCopyBtn: boolean;
  hideUploadBtn: boolean;
  tempAttributeTypes: any[];
  globalAttrTypes: any[];
  globalData: any[];
  selectedData: any = [];
  selectedCopyData: any = [];
  globalIndicater: boolean;
  context: string;
  oldObject: { attributetype: any; attributename: any;attributeid: any; isactive: boolean; sequenceorder: any; };

  //---------for searchable Dropdown-------------
  filteredOptions: Observable<any[]>;
  filteredContextId: Observable<any[]>;

  filterAttrContext1: Observable<any[]>;
  filteredAttrContext2: Observable<any[]>;
  filteredAttrContext3: Observable<any[]>;
  filteredDesc: Observable<any[]>;
  filteredAttrValue: Observable<any[]>;
  filteredServicesType: Observable<any[]>;
  filterAttrContext: Observable<any[]>;
  //-----------------------------------------------
  attributesName: any;
  attributeFields: any;
  attributeJson: any;
  lookupData: any;

  // Array fro storing dropdown

  attributecontextidArray: any;
  attributecontextid2Arr: any;
  attributeDescArr: any;
  attrValue: any;
  attrServiceType: any;
  attrContext: any
  attributecontextid3Arr: any;

  //------------------------------------
  // checking id or name it wants for configuration
  attrContextIdflag: boolean = false;
  attrContextId2flag: boolean = false;
  attrContextflag: boolean = false;
  attrDescFlag: boolean = false;
  attrValueflag: boolean = false;
  attrServiceflag: boolean = false;
  attrContextid3flag: boolean = false;
  searchAttr: boolean = true;
  deploymentKey: any;
  accountID: any;
  allFields: any =[{}];
  updateFields = [{}];
  noDataFound: boolean;
  uploadClicked: boolean;
  gridShow: boolean;
  bulkUpload: boolean;
  showInvalidColumns: boolean;
  invalidColumns: string = "";
  copyDataClicked: boolean = false;
  postUpload: boolean;
  selector: Selector = null;
   //sample data for excel

  noData: boolean;
  ctCategoy: any;
  nodataFoundEnable: boolean;
  auditableColumns: any;
  activityEntity:any;
  constructor(public _snackbar: MatSnackBar, private dialog: MatDialog, private _fb: FormBuilder, private vitalHttpServices: VitalHttpServices,
     private commonService: CommonService, private ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService) { }

  ngOnInit(): void {
    this.orgId = this.templateData.secondarykeys.OrganizationId;
    this.cardType = this.templateData.cardtype;
    this.attrtype = "All";
    this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.accountID = sessionStorage.getItem('Locationid')
    this.GetButtondetails();
    this.getCaseTypecategory();
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
          case 'Upload':
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
          case 'CopyToOrg':
          this.hideCopyBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  ngOnChanges() {
    this.AddGridData();
  }
  getCaseTypecategory() {
    this.ngxService.start();
    this.searchAttr = true;
    this.showGrid = false;
    this.configname.reset();
    let query = this.vitalHttpServices.GetQuery('getCasetypeCategory');

    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.deploymentKey).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.ctCategoy = Resdata.data.submenuData[0].category;
        this.getAttributeTypesDropdown();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  getAttributeTypesDropdown() {
    this.ngxService.start();
    this.searchAttr = true;
    this.showGrid = false;
    this.configname.reset();
    let query = this.vitalHttpServices.GetQuery('getAttributeName');

    let queryVariable = { scope: "CasetypeAttributes" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.attributesName = [];
        for (let i = 0; i < Resdata.data.submenuData.length; i++) {
          let val = JSON.parse(Resdata.data.submenuData[i].displayCriteria)
          if (val) {
            //let criteriaList = []
            for (let [key, value] of Object.entries(val)) { // taking key and values on json
              if (key.toLowerCase() == this.ctCategoy.toLowerCase()){
               if (Object.values(value).length > 0) { // checking key with category(casetype)
                for (let items of Object.values(value)) {
                  if (items.toLowerCase() == this.templateData.secondarykeys.casetype.toString().toLowerCase()) { // checking the casetypes
                    this.attributesName.push(Resdata.data.submenuData[i])
                  }
                }
              }
              else if (Object.values(value).length == 0) { // if only category and empty array configure it will take all the ct under that category
                this.attributesName.push(Resdata.data.submenuData[i]);
              }
              }
            }
          }
          else {
            this.attributesName.push(Resdata.data.submenuData[i]);
          }
        }
        this.filteredOptions = this.configname.valueChanges
          .pipe(
            startWith(''),
            map(user => user && typeof user === 'object' ? user.configname || user.name : user),
            map((name: string) => name ? this.filterName(name) : this.attributesName.slice())
          );
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });

  }

  filterName(name: string): any[] {
    return this.attributesName.filter(option =>
      option.refconfigname.toLowerCase().includes(name.toLowerCase()) || option.name.toLowerCase().includes(name.toLowerCase()));
  }


  filterLookupAttrId(name: string): any[] {
    return this.attributecontextidArray.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrConId2(name: string): any[] {
    return this.attributecontextid2Arr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrConId3(name: string): any[] {
    return this.attributecontextid3Arr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrContext(name: string): any[] {
    return this.attrContext.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrdesc(name: string): any[] {
    return this.attributeDescArr.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrServ(name: string): any[] {
    return this.attrServiceType.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterLookupAttrVal(name: string): any[] {
    return this.attrValue.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }

  getOrgAttibutes(obj) {
    this.ngxService.start();
    this.vitalHttpServices.getOrganizationAttributes(obj, this.deploymentKey).subscribe(async result => {
      this.ngxService.stop();
      if (!result.error) {
        try {
          this.templateData.submenuData = JSON.parse(result.Message)
          this.globalAttrTypes = [];
          this.globalData = [];
          this.allFields = [{}];
          for (let i = 0; i < this.templateData.submenuData.length; i++) {
            if (this.templateData.submenuData[i]['Attribute_Level'] == 'Default') {
              this.globalexist = true;
              this.globalAttrTypes.push(this.templateData.submenuData[i]['Attribute_Type']);
              this.globalData.push(this.templateData.submenuData[i]);
            }
          }
          this.allFields[0]["AttributeName"]=null;
          this.allFields[0]["SequenceOrder"]=null;
          this.allFields[0]["IsActive"]=null
          this.updateFields[0]["AttributeId"]=null;
          this.updateFields[0]["AttributeName"]=null;
          this.updateFields[0]["SequenceOrder"]=null;
          this.updateFields[0]["IsActive"]=null
          this.nodataFoundEnable=false;
          if(this.templateData.submenuData[0].Attribute_ID=="N/A"){
            this.nodataFoundEnable=true;
          }
          if (this.templateData.submenuData[0].validvalues) {
            this.attributeJson = JSON.parse(this.templateData.submenuData[0].validvalues);
            this.attributeFields = this.attributeJson.AttributeFields.filter(va => va.status == 'true')
            for (let j = 0; j < this.attributeFields.length; j++) {
              this.allFields[0][this.attributeFields[j].columnname]=null
              this.updateFields[0][this.attributeFields[j].columnname]=null
            }
            let lookUpField = this.attributeFields.filter(va => va.datatype == 'lookup')
            for (let i = 0; i < lookUpField.length; i++) {
              if (lookUpField[i].datasource[0].procedure) {
                let casetype = this.templateData.cardtype
                this.ngxService.start();
                this.lookupData = await this.vitalHttpServices.getLookUpData(lookUpField[i].datasource[0].procedure, this.orgId, casetype, this.accountID, this.deploymentKey).toPromise()
                // this.vitalHttpServices.getLookUpData(lookUpField[i].datasource[0].procedure, this.orgId, 'dermatology').subscribe(data => {
                this.ngxService.stop();
                if (this.lookupData && this.lookupData.length > 0) {
                  switch (lookUpField[i].columnname.toLowerCase()) {
                    case "attributecontextid":
                      this.attrContextIdflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextidArray = this.lookupData;
                      this.filterAttrContext1 = this.orgAttributeForm.controls.AttributeContextId.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrId(name) : this.attributecontextidArray.slice())
                        );
                      break
                    case 'attributecontextid2':
                      this.attrContextId2flag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextid2Arr = this.lookupData;
                      this.filteredAttrContext2 = this.orgAttributeForm.controls.AttributeContextId2.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrConId2(name) : this.attributecontextid2Arr.slice())
                        );
                      break

                    // ----------------
                    case 'attributecontext':
                      this.attrContextflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrContext = this.lookupData;
                      this.filterAttrContext = this.orgAttributeForm.controls.AttributeContext.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrContext(name) : this.attrContext.slice())
                        );
                      break
                    case 'attributedescription':
                      this.attrDescFlag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributeDescArr = this.lookupData;
                      this.filteredDesc = this.orgAttributeForm.controls.AttributeDescription.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrdesc(name) : this.attributeDescArr.slice())
                        );
                      break
                    case 'attributevalue':
                      this.attrValueflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrValue = this.lookupData;
                      this.filteredAttrValue = this.orgAttributeForm.controls.AttributeValue.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrVal(name) : this.attrValue.slice())
                        );
                      break
                    case 'attributeservicetype':
                      this.attrServiceflag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attrServiceType = this.lookupData;
                      this.filteredServicesType = this.orgAttributeForm.controls.AttributeServiceType.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrServ(name) : this.attrServiceType.slice())
                        );
                      break
                    case 'attributecontextid3':
                      this.attrContextid3flag = lookUpField[i].valuesource == 'id' ? true : false;
                      this.attributecontextid3Arr = this.lookupData;
                      this.filteredAttrContext3 = this.orgAttributeForm.controls.AttributeContextId3.valueChanges
                        .pipe(
                          startWith(''),
                          map((user:any) => user && typeof user === 'object' ? user.name || user.id : user),
                          map((name: string) => name ? this.filterLookupAttrConId3(name) : this.attributecontextid3Arr.slice())
                        );
                      break
                  }
                }
              }
            }
          }
          else {
            this.attributeFields = [];
          }
          this.globalAttrTypes = [...new Set(this.globalAttrTypes)] // to get the distinct attribute types
          this.AddGridData();
        }
        catch {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }

      }
    }, error => {
      this.ngxService.stop();
      console.error(error)
    })
  }

  AddGridData() {
    let gridarray = []
    let primary = {}
    this.searchAttr = true;
    this.showGrid = true;
    this.addEditScreen = false;
    if (this.templateData.submenuData) {
      //this.tempExport = this.templateData.submenuData;
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        if (this.templateData.submenuData.find(r => (r.Attribute_Level == 'Default'))) {
          this.showModal = true;
        }else{
          this.showModal = false;
        }
        gridarray.sort((a, b) => a.Sequence_Order < b.Sequence_Order ? 1 : -1).sort((a, b) => a.Attribute_Type > b.Attribute_Type ? 1 : -1)
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);

    }
  }

  getOrgAttributeOfDropdown(data) {
    this.tempAttributeTypes = [];
    if (data != 'All') {
      this.tempAttributeTypes.push(data)
    }
    else {
      for (let i = 0; i < this.AttributeTypes.length; i++) {
        this.tempAttributeTypes.push(this.AttributeTypes[i].Item);
      }
    }
    this.attrtype = data;
    let obj = {
      "organizationid": this.orgId,
      "attributetype": this.tempAttributeTypes,
      "casetype": this.templateData.secondarykeys.casetype,
      "tablename":"OrganizationAttributes"
    }
    this.getOrgAttibutes(obj);
  }
  showEditIconInGrid(row) {
    let level = row.dataItem.Attribute_Level;
    if (level == 'Default')
      return false;
    else
      return true;
  }

  initGrid(flexgrid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(flexgrid, {
          itemChecked: () => {
            this.selectedData = flexgrid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });

  }
  //#region Export grid data as excel

  exportOrgAttributesData(flex) {
    let excel = [];
    let modifiedExcel: any = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate()
    let rows = flex.rows
    rows.find(e => {
      delete e._data['Attribute_Context'];
      delete e._data['Attribute_Context_Id'];
      delete e._data['Attribute_Description'];
      delete e._data['Attribute_Value'];
      delete e._data['Attribute_Service_Type'];
      delete e._data['Attribute_Context_Id_2'];
      delete e._data['Attribute_Context_Id_3'];
      // delete e._data['Attribute_Level'];
      let primary = {}
        for (let [key, value] of Object.entries(e._data)) {
          if (key == 'Status') {
            value = value == 'Active' ? true : false;
            primary['Status'] = value;
          }
          if(!value){
            value = 'Not Specified';
          }
            primary[key] = value;
        }
            excel.push(primary);
    });
    let filename = 'Attributes_' +  this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Attributes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }


  refreshGrid() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.start();
    this.showGrid = true;
    this.searchAttr = true;
    this.addEditScreen = false;
    this.uploadClicked = false;
    this.postUpload = false;
    this.copyDataClicked=false;
    this.copyOrgAttributesForm.controls.frmOrganization.enable()
    this.globalIndicater = false;
    this.orgAttributeForm.reset();
    this.getOrgAttributeOfDropdown(this.attrtype)
    this.attributeType.setValue(this.attrtype);
    this.searchInput = '';
    this.searchUserInput = '';
    this.checkStatus = false;
    this.ngxService.stop();
    this.resetFormErrors();
  }

  removeGrid() {
    this.gridWidth = 0;
    this.showDelete = false;
    this.excelDataArray = [];
    this.selectedData = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.noDataFound = false;
    this.checkStatus = false;
    let defDep = sessionStorage.getItem('deploymentKey')
    this.copyOrgAttributesForm.patchValue({
      frmDepKey: defDep.toUpperCase(),
      frmOrganization: ''
    })
    this.resetFormErrors();
  }

    //#region  Reset any error indication in the form
    resetFormErrors() {
      Object.keys(this.copyOrgAttributesForm.controls).forEach(key => {
        this.copyOrgAttributesForm.controls[key].setErrors(null)
      });
    }
  //#region
  addOrgAttributes(dataArray, bulkUpload: boolean = false, context?: string, opt? :boolean) {
    // if (!this.globalexist && this.context != 'copy') {
    //   for (let i = 0; i < dataArray.length; i++) {
    //     if (this.templateData.submenuData.some(va => va.Attribute_Name == dataArray[0].attributename && va.Attribute_Type == dataArray[0].attributetype && va.Attribute_ID != dataArray[0].attributeid)) {
    //       this._snackbar.open("Configuration is already exists", 'close');
    //       this.ngxService.stop();
    //       return;
    //     }
    //   }
    // }
    this.ngxService.start();
    this.checkStatus = false;
    this.vitalHttpServices.addOrganizationAttributes(dataArray, this.deploymentKey).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if(opt){
          this.refreshGrid();
          this.showModal= false;
          dataArray.filter((e: any) => {
            result.filter(r => {
              if (r.AttributeName.toString() === e.attributename.toString()) {
                e.status = r.Status
                e.notes = r.Notes;
              }
            });
          });
          this.commonService.auditDetails('','',[],dataArray, 'Migrate', this.templateData, this.auditableColumns);
          return;
        }
        if (result.length > 0) {
          this.showDelete = false;
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            this._snackbar.open('Attribute with the same name already exists.', 'Close');
          }
          else {
            this._snackbar.open('Group attribute added successfully', 'Close');
            dataArray[0]['isactive'] = this.commonService.getStatus(dataArray[0]['isactive']);
            dataArray[0]['sequenceorder'] = result[0]['SequenceOrder'];
            this.commonService.auditDetails('attributeid','attributename',result, dataArray, 'Create',this.templateData,this.auditableColumns);
            if (context) {
              this.globalIndicater = false;
              this.getOrgAttributeOfDropdown(this.attrtype)
              this.attributeType.setValue(this.attrtype);
            }
            else {
              this.orgAttributeForm.reset();
              this.globalexist = false;
              let attrtype = this.attrtype == 'All' ? '' : this.attrtype
              this.orgAttributeForm.reset();
              this.orgAttributeForm.patchValue({
                frmAttrType: attrtype,
                frmAttrName: "",
                frmIsActive: true,
                frmSeqOrder: "",
                AttributeContext: "",
                AttributeContextId: '',
                AttributeDescription: '',
                AttributeValue: '',
                AttributeServiceType: '',
                AttributeContextId2: '',
                AttributeContextId3: ''
              })
              this.globalIndicater = false;
              Object.keys(this.orgAttributeForm.controls).forEach(key => {
                this.orgAttributeForm.controls[key].setErrors(null)
              });
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Group Attribute addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion
  //#region  for patching to the form to update
  updateOrgAttributes(row) {
    if (this.hideEditBtn) {

      return;
    }
    let patchContextId = this.attrContextIdflag && this.attributecontextidArray && this.attributecontextidArray.length > 0 ? this.attributecontextidArray.filter(va => va.id == row.dataItem.Attribute_Context_Id) : null
    let patchAttrContext = this.attrContextflag && this.attrContext && this.attrContext.length > 0 ? this.attrContext.filter(va => va.id == row.dataItem.Attribute_Context) : null
    let patchDescription = this.attrDescFlag && this.attributeDescArr && this.attributeDescArr.length > 0 ? this.attributeDescArr.filter(va => va.id == row.dataItem.Attribute_Description) : null
    let patchAttrValue = this.attrValueflag && this.attrValue && this.attrValue.length > 0 ? this.attrValue.filter(va => va.id == row.dataItem.Attribute_Value) : null
    let patchServiceType = this.attrServiceflag && this.attrServiceType && this.attrServiceType.length > 0 ? this.attrServiceType.filter(va => va.id == row.dataItem.Attribute_Service_Type) : null
    let patchAttrContextId2 = this.attrContextId2flag && this.attributecontextid2Arr && this.attributecontextid2Arr.length > 0 ? this.attributecontextid2Arr.filter(va => va.id == row.dataItem.Attribute_Context_Id_2) : null
    let patchAttrContextId3 = this.attrContextid3flag && this.attributecontextid3Arr && this.attributecontextid3Arr.length > 0 ? this.attributecontextid3Arr.filter(va => va.id == row.dataItem.Attribute_Context_Id_3) : null

    this.ngxService.start();
    this.addEditScreen = true;
    this.showGrid = false;
    this.searchAttr = false;
    this.action = 'Edit ' + row.dataItem.Attribute_Type;
    this.attrID = row.dataItem.Attribute_ID;
    row.dataItem.Attribute_Type = row.dataItem.Attribute_Type;
    this.orgAttributeForm.patchValue({
      frmAttrType: row.dataItem.Attribute_Type,
      frmAttrName: row.dataItem.Attribute_Name,
      frmIsActive: row.dataItem.Status == "Active" ? true : false,
      frmSeqOrder: row.dataItem.Sequence_Order,
      AttributeContext: patchAttrContext ? patchAttrContext[0].name + ' (' + patchAttrContext[0].id + ')' : row.dataItem.Attribute_Context,
      AttributeContextId: patchContextId ? patchContextId[0].name + ' (' + patchContextId[0].id + ')' : row.dataItem.Attribute_Context_Id,
      AttributeDescription: patchDescription ? patchDescription[0].name + ' (' + patchDescription[0].id + ')' : row.dataItem.Attribute_Description,
      AttributeValue: patchAttrValue ? patchAttrValue[0].name + ' (' + patchAttrValue[0].id + ')' : row.dataItem.Attribute_Value,
      AttributeServiceType: patchServiceType ? patchServiceType[0].name + ' (' + patchServiceType[0].id + ')' : row.dataItem.Attribute_Service_Type,
      AttributeContextId2: patchAttrContextId2 ? patchAttrContextId2[0].name + ' (' + patchAttrContextId2[0].id + ')' : row.dataItem.Attribute_Context_Id_2,
      AttributeContextId3: patchAttrContextId3 ? patchAttrContextId3[0].name + ' (' + patchAttrContextId3[0].id + ')' : row.dataItem.Attribute_Context_Id_3
    })
    this.oldObject = {
      "attributetype": row.dataItem.Attribute_Type,
      "attributename": row.dataItem.Attribute_Name,
      "attributeid": row.dataItem.Attribute_ID,
      "isactive": row.dataItem.Status == 'Active' ? true : false,
      "sequenceorder": row.dataItem.Sequence_Order
    }
    this.activityEntity.entityId = this.attrID;
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.stop();
  }
  //#endregion

  //#region for indivisual add
  createOrgAttribute() {
    if (this.hideCreateBtn) {

      return;
    }
    this.searchAttr = false;
    this.addEditScreen = true; // flag for enabling Add and edit screen
    this.showGrid = false;
    this.showModal = false;
    let attrtype = this.attrtype == 'All' ? '' : this.attrtype
    this.action = 'Create Group Attribute'
    this.orgAttributeForm.reset();
    this.orgAttributeForm.patchValue({
      frmAttrType: attrtype,
      frmAttrName: '',
      frmIsActive: true,
      frmSeqOrder: '',
      AttributeContext: "",
      AttributeContextId: '',
      AttributeDescription: '',
      AttributeValue: '',
      AttributeServiceType: '',
      AttributeContextId2: '',
      AttributeContextId3: ''
    })

  }
  //#endregion

  onChangeAttrType(value) {
    if (value.source._selected) {
      for (let i = 0; i < this.globalData.length; i++)
        if (value.source.value == this.globalData[i]['Attribute_Type']) {
          this.globalIndicater = true;
          return
        }
        else {
          this.globalIndicater = false;
        }
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  takingIdOrName(data) {
    var regExp = /\(([^)]+)\)/;
    // to get id or name based on the condition
    data.AttributeContextId = this.attrContextIdflag ? data.AttributeContextId && data.AttributeContextId.match(regExp) && data.AttributeContextId.match(regExp)[1] ? data.AttributeContextId.match(regExp)[1] : data.AttributeContextId : data.AttributeContextId && data.AttributeContextId.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeDescription = this.attrDescFlag ? data.AttributeDescription && data.AttributeDescription.match(regExp) && data.AttributeDescription.match(regExp)[1] ? data.AttributeDescription.match(regExp)[1] : data.AttributeDescription : data.AttributeDescription && data.AttributeDescription.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeValue = this.attrValueflag ? data.AttributeValue && data.AttributeValue.match(regExp) && data.AttributeValue.match(regExp)[1] ? data.AttributeValue.match(regExp)[1] : data.AttributeValue : data.AttributeValue && data.AttributeValue.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeServiceType = this.attrServiceflag ? data.AttributeServiceType && data.AttributeServiceType.match(regExp) && data.AttributeServiceType.match(regExp)[1] ? data.AttributeServiceType.match(regExp)[1] : data.AttributeServiceType : data.AttributeServiceType && data.AttributeServiceType.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeContextId2 = this.attrContextId2flag ? data.AttributeContextId2 && data.AttributeContextId2.match(regExp) && data.AttributeContextId2.match(regExp)[1] ? data.AttributeContextId2.match(regExp)[1] : data.AttributeContextId2 : data.AttributeContextId2 && data.AttributeContextId2.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeContextId3 = this.attrContextid3flag ? data.AttributeContextId3 && data.AttributeContextId3.match(regExp) && data.AttributeContextId3.match(regExp)[1] ? data.AttributeContextId3.match(regExp)[1] : data.AttributeContextId3 : data.AttributeContextId3 && data.AttributeContextId3.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
    data.AttributeContext = this.attrContextflag ? data.AttributeContext && data.AttributeContext.match(regExp) && data.AttributeContext.match(regExp)[1] ? data.AttributeContext.match(regExp)[1] : data.AttributeContext : data.AttributeContext && data.AttributeContext.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, "")
  }
  saveOrgAttributes(action: string, data: any) {
    this.attributeType.setValue('All');
    if (data.frmAttrType.trim() == '') {
      this._snackbar.open("Please enter the required values", 'Close')
      return;
    }
    this.takingIdOrName(data);

    //checking whether user selects proper value from dropdown

    let validDesc = false, validService = false, validContext = false, validAttrValue = false, validContextid = false, validContextid2 = false, validContextid3 = false
    if (!this.attrDescFlag && data.AttributeDescription && this.attributeDescArr && !this.attrContext.find(va => va.name == data.AttributeDescription)) {
      validDesc = true;
    }
    else if (this.attrDescFlag && data.AttributeDescription && this.attributeDescArr && !this.attrContext.find(va => va.id == data.AttributeDescription)) {
      validDesc = true
    }
    if (!this.attrServiceflag && data.AttributeServiceType && this.attrServiceType && !this.attrServiceType.find(va => va.name == data.AttributeServiceType)) {
      validService = true
    }
    else if (this.attrServiceflag && data.AttributeServiceType && this.attrServiceType && !this.attrServiceType.find(va => va.id == data.AttributeServiceType)) {
      validService = true
    }
    if (!this.attrContextflag && data.AttributeContext && this.attrContext && !this.attrContext.find(va => va.name == data.AttributeContext)) {
      validContext = true
    }
    else if (this.attrContextflag && data.AttributeContext && this.attrContext && !this.attrContext.find(va => va.id == data.AttributeContext)) {
      validContext = true
    }
    if (!this.attrValueflag && data.AttributeValue && this.attrValue && !this.attrValue.find(va => va.name == data.AttributeValue)) {
      validContext = true
    }
    else if (this.attrValueflag && data.AttributeValue && this.attrValue && !this.attrValue.find(va => va.id == data.AttributeValue)) {
      validAttrValue = true
    }
    if (!this.attrContextIdflag && data.AttributeContextId && this.attributecontextidArray && !this.attributecontextidArray.find(va => va.name == data.AttributeContextId)) {
      validAttrValue = true
    }
    else if (this.attrContextIdflag && data.AttributeContextId && this.attributecontextidArray && !this.attributecontextidArray.find(va => va.id == data.AttributeContextId)) {
      validContextid = true
    }
    if (!this.attrContextId2flag && data.AttributeContextId2 && this.attributecontextid2Arr && !this.attributecontextid2Arr.find(va => va.name == data.AttributeContextId2)) {
      validContextid2 = true
    }
    else if (this.attrContextId2flag && data.AttributeContextId2 && this.attributecontextid2Arr && !this.attributecontextid2Arr.find(va => va.id == data.AttributeContextId2)) {
      validContextid2 = true
    }
    if (!this.attrContextid3flag && data.AttributeContextId3 && this.attributecontextid3Arr && !this.attributecontextid3Arr.find(va => va.name == data.AttributeContextId3)) {
      validContextid3 = true
    }
    else if (this.attrContextid3flag && data.AttributeContextId3 && this.attributecontextid3Arr && !this.attributecontextid3Arr.find(va => va.id == data.AttributeContextId3)) {
      validContextid3 = true
    }
    if (validDesc || validService || validContext || validAttrValue || validContextid || validContextid2 || validContextid3) {
      if (validDesc) {
        this.orgAttributeForm.controls["AttributeDescription"].setErrors({ 'incorrect': true })
      }
      if (validService) {
        this.orgAttributeForm.controls["AttributeServiceType"].setErrors({ 'incorrect': true })
      }
      if (validContext) {
        this.orgAttributeForm.controls["AttributeContext"].setErrors({ 'incorrect': true })
      }
      if (validAttrValue) {
        this.orgAttributeForm.controls["AttributeValue"].setErrors({ 'incorrect': true })
      }
      if (validContextid) {
        this.orgAttributeForm.controls["AttributeContextId"].setErrors({ 'incorrect': true })
      }
      if (validContextid2) {
        this.orgAttributeForm.controls["AttributeContextId2"].setErrors({ 'incorrect': true })
      }
      if (validContextid3) {
        this.orgAttributeForm.controls["AttributeContextId3"].setErrors({ 'incorrect': true })
      }
      this._snackbar.open("Please select proper value", "Close")
      return
    }

    let dataJson = [];
    let globalJson = {};
    let tempdataJson = {};
    if (this.globalexist == true && !this.action.match(/edit/i)) {
      for (let i = 0; i < this.globalData.length; i++) {
        if (data.frmAttrType == this.globalData[i].Attribute_Type) {
          globalJson = {
            "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
            "attributetype": this.globalData[i].Attribute_Type,
            "attributename": this.globalData[i].Attribute_Name,
            "attributecontext": this.globalData[i].Attribute_Context,
            "organizationid": sessionStorage.getItem("org_id"),
            "isactive": this.globalData[i].Active = 'Active' ? true : false,
            "sequenceorder": this.globalData[i].Sequence_Order,
            "attributeid": null,
            "attributecontextid": this.globalData[i].Attribute_Context_Id ? this.globalData[i].Attribute_Context_Id : null,
            "attributedescription": this.globalData[i].Attribute_Description ? this.globalData[i].Attribute_Description : null,
            "attributevalue": this.globalData[i].Attribute_Value ? this.globalData[i].Attribute_Value : null,
            "attributeservicetype": this.globalData[i].Attribute_Service_Type ? this.globalData[i].Attribute_Service_Type : null,
            "attributecontextid2": this.globalData[i].Attribute_Context_Id_2 ? this.globalData[i].Attribute_Context_Id_2 : null,
            "attributecontextid3": this.globalData[i].Attribute_Context_Id_3 ? this.globalData[i].Attribute_Context_Id_3 : null
          }
          dataJson.push(globalJson)
        }
      }
    }
    tempdataJson = {
      "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
      "attributetype": data.frmAttrType,
      "attributename": data.frmAttrName,
      "attributecontext": this.templateData.secondarykeys.casetype,
      "organizationid": sessionStorage.getItem("org_id"),
      "isactive": data.frmIsActive,
      "sequenceorder": data.frmSeqOrder == null ? "" : data.frmSeqOrder,
      "attributeid": this.attrID ? this.attrID : null,
      "attributecontextid": data.AttributeContextId ? data.AttributeContextId : null,
      "attributedescription": data.AttributeDescription ? data.AttributeDescription : null,
      "attributevalue": data.AttributeValue ? data.AttributeValue : null,
      "attributeservicetype": data.AttributeServiceType ? data.AttributeServiceType : null,
      "attributecontextid2": data.AttributeContextId2 ? data.AttributeContextId2 : null,
      "attributecontextid3": data.AttributeContextId3 ? data.AttributeContextId3 : null
    }
    dataJson.push(tempdataJson)
    if (this.action.match(/edit/i)) {
      this.editOrgAttributes(dataJson);
    }
    else {
      let context = action != 'new' ? "All" : null
      // this.globalexist=action!='new' ? false : null
      this.addOrgAttributes(dataJson, false, context);
    }
  }
  editOrgAttributes(data, bulkupdate?:boolean) {
    this.ngxService.start();
    // if (this.templateData.submenuData.some(va => va.Attribute_Name == data[0].attributename && va.Attribute_Type == data[0].attributetype && va.Attribute_ID != data[0].attributeid)) {
    //   this._snackbar.open("Attribute with the same name already exists.", 'close');
    //   this.ngxService.stop();
    //   return;
    // }

    this.vitalHttpServices.updateOrganizationAttributes(data, this.deploymentKey).toPromise().then(response => {
     //this.ngxService.stop();
      if (response[0].Notes.toLowerCase() == "updated successfully") {
        this._snackbar.open("Group attribute updated successfully", "Close")
        // this.getGrossingAttribute("All");
        if(!bulkupdate){
          data[0]['isactive'] = this.commonService.getStatus(data[0]['isactive']);
          this.oldObject['isactive'] = <any>this.commonService.getStatus(this.oldObject['isactive']);
          data[0]['sequenceorder'] = response[0]['SequenceOrder'];
          this.commonService.auditDetails('attributeid','attributename',[this.oldObject], data, 'Edit',this.templateData,this.auditableColumns);
          // this.commonService.createActivityTracker('Edited', data[0].attributeid, 'Attributes', 'Audit', data[0], this.oldObject);
          this.orgAttributeForm.reset();
          this.getOrgAttributeOfDropdown(this.attrtype)
          this.attributeType.setValue(this.attrtype);
        }else{
          if(response.length>0){
            this.postUpload = true;
            this.showDelete = false;
            //this.masterLevel = false;
            console.log(response);
            let uploadresult = this.convertObjKeysToLower(response);

              for (let j = 0; j < uploadresult.length; j++) {
                if (this.excelDataArray[j]['slno'] == uploadresult[j].slno) {
                  this.excelDataArray[j]['status'] = uploadresult[j].notes.toString().match(/updated successfully/i) ? 'Success' : 'Ignored'
                  this.excelDataArray[j]['notes'] = uploadresult[j].notes
                }
              }

            let dataExistsCount = 0;
            for (let i = 0; i < response.length; i++) {
              if (Object.values(response).every(function (item: any) {
                return item.Status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
           // this.sheetHeader.push('Status');
            this.AddGridtoData(this.excelDataArray, 0);

            if (dataExistsCount == response.length) {
              this._snackbar.open('Data already exist', 'Close');
              return;
            }
            else {
              let message = 'Data updated successfully';
              this.commonService.auditDetails('','',[],this.excelDataArray, 'Bulk Update', this.templateData, this.auditableColumns);
              this._snackbar.open(message, 'Close');
            }
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }
      else {
        this._snackbar.open("Group attribute update failed!", "Close")
      }
      this.ngxService.stop();
    }
    ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
    this.ngxService.stop();
  }

  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName == 'sequence') {
      if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
  }
  fnToggleFilter() {
    this.configname.reset();
    this.showGrid = false;
  }

  //Upload screen
  LoaduploadScreen() {
    this.noDataFound = false;
    this.uploadClicked = true;
    this.searchAttr = false;
    this.showModal = false;
    this.showGrid= false
    this.gridShow = false;
    this.bulkUpload = true;
    this.gridWidth = 0

  }
  //Upload file
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
           this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

    //Upload templates
    downloadTemplate(type) {
      let filename = ""
      let excel = [{}];
      if (type == 'allFieldsCheck') {
        filename = 'Attributes_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
        var ws = XLSX.utils.json_to_sheet(this.allFields);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + this.attrtype);
        //ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
        //XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
        XLSX.writeFile(wb, filename);
      }
      else if (type == 'updateCheck') {
        filename = 'Attributes_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx'
        var ws = XLSX.utils.json_to_sheet(this.updateFields);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + this.attrtype);
        //ws = XLSX.utils.json_to_sheet(this.smapleDataUpdateFields)
       // XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
        XLSX.writeFile(wb, filename);
      }

    }
     //Append validation notes in the grid
  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/SequenceOrder/i)) {
              if (value && value.toString() == '0'&& value.toString()=='') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value === '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'attributeid') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        //let ttype =
       // ttype = ttype.substring(0, ttype.length - 1);
        primary['attributetype'] = this.attrtype
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationAttributes', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), attributecontext: this.templateData.cardtype.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if(result){
      this.convertToJson(result)
    }
  }
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateOrgAttributes(dataArray, this.deploymentKey).subscribe(result => {
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridtoData(dataArray, 1);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
    this.ngxService.stop();
  }
  AddGridtoData(data, hitCount) {
    console.log(data);
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (value && value.toString().match(/null/i)) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              // if ((key == 'AttributeName') && (!value || value.toString() == '')) {
              //   value = ''
              //   primary['notes'] = 'Mandatory fields are missing'
              // }
            }
            if(this.copyDataClicked){
              if(key=='isactive'){
                if(value == 'false' || value == "0"){
                  value = false
                }
                else if(value == 'true' || value == "1"){
                  value = true
                }
                value =  value ? 1 :0
              }
            }
          if(hitCount==1){
            if(key=='isactive'){
              if(value === 'false' || value === "0" || value === false ){
                value = false
              }
              else if(value === 'true' || value === true || value === "1" || value ==='' || value=== null){
                value = true
              }
              value =  value ? 1 :0
            }
          }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        gridArray.sort((a, b) => a.sequence < b.sequence ? -1 : 1)
        this.excelDataArray = gridArray;
        if (this.showDelete) {
          // this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'Status'), 1)
        }
        else {
          this.sheetHeader.push('Status');
        }
        this.showDelete = true

        // this.sheetHeader.push('Status');
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.gridData = new CollectionView(gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
    this.ngxService.stop();
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }

   //approve button
   approveOrgAttributes(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
              //this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
            if ( key.toString().toLowerCase() === 'isactive') {
              if ( value === "null" || value === "" || value === null) {
                value = 0
              }
            }
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      // if (selectedData.some(va => !va.testname || !va.attributename)) {
      //   this._snackbar.open('Some of the mandatory data is missing!', 'Close');
      //   return
      // }
      if (copyData) {
        //this.enableActive = false;
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.selectedCopyData[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
          this.selectedCopyData[i]["sequenceorder"] = null;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData

      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
     // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      if (!sendjson[0].attributeid || copyData && this.bulkUpload) {
        this.addUploadOrgAttr(dataArray, this.bulkUpload, context)
      }
      else {
       this.editOrgAttributes(dataArray, true)
      }
    }
  }
  //method for bulk upload
  addUploadOrgAttr(dataArray: object[],  bulkUpload: boolean, context?) {
    this.ngxService.start();
    this.vitalHttpServices.addOrganizationAttributes(dataArray,  this.deploymentKey).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors) {
        //this.auditDetails([], sendjson, 'copied');
        this.noData = false;
        console.log(result)
        // this.addScreeningType.controls.frmOrganization.disable()
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.SlNo.toString() === e.slno.toString()) {
              e.status = r.Status
              e.notes = r.Notes;
            }
          });
        });
        dataArray.forEach((va: any) => {
          if (va.notes.toString().toLowerCase().match(/already exists/i)) {
            va.status = 'Ignored'
          }
        })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == uploadresult[j].slno) {
                this.excelDataArray[i]['status'] = uploadresult[j].notes.toString().match(/Created successfully/i) ? 'Success' : 'Ignored';
                this.excelDataArray[i]['notes']=uploadresult[j].notes;
                this.excelDataArray[i]['sequenceorder']=uploadresult[j].sequenceorder ;
                this.excelDataArray[i]['AttributeName']=uploadresult[j].attributename ;
              }
            }
          }
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            this.AddGridtoData(this.excelDataArray, 1);
            this.commonService.auditDetails('attributeid','attributename',[], this.excelDataArray,this.bulkUpload?'Upload':'Copy', this.templateData, this.auditableColumns);
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      this.checkStatus = true;
      console.error(error)
    })
  }

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory fields are missing' || value == 'Data already exists' || value == 'This record does not exists' || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Does not') || value.toString().includes('not belongs')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('successfully') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString() == 'Ignored')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  //Copy from other entity
  loadCopyScreen() {
    this.copyDataClicked = true
    this.searchAttr=false;
    this.showGrid=false;
    //this.gridPage = false;
    this.checkStatus= false;
    this.showModal = false;
    this.noDataFound=false;
    this.uploadClicked=false;
    this.gridWidth=0;
    this.copyOrgAttributesForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey'),
      frmOrgType: "Laboratory",
      frmOrganization: "",
      frmOrgID: "",
      frmcpyasactive: false
    })
    //this.disableOrgSearch = true;
    //this.destDeployment = sessionStorage.getItem('deploymentKey')
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.getListOrg();
  }

  onChangeDeployment(e) {
    this.copyOrgAttributesForm.patchValue({
        frmDepKey: e.value,
        frmOrganization: "",
        frmOrgID: "",
        frmcpyasactive: false
      })
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
  }
  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.copyOrgAttributesForm.value.frmOrgID = ""
    if (value === '') {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/

    if (this.copyOrgAttributesForm.value.frmOrgType) {
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchResult = this.organizationList.filter(function (series) {
            if (series && series.organizationname != null) {
              return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
            }
          });

          this.searchResult = this.searchResult.splice(0, 25);
        }
      }
      else {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationid != null) {
            return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
      this.copyOrgAttributesForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
        if (series && series.organizationname != null) {
          return series.organizationname.toString().toLowerCase() == value.toLowerCase() ? series.organizationid : "";
        }
      });
      //this.resetFormErrors()
    }
  }
  getListOrg() {
    let dbName = this.copyOrgAttributesForm.value.frmDepKey;
    let queryVariable = {};
    let query = this.vitalHttpServices.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        //this.resetFormErrors();
        if (data) {
          if (data.data.Organizations_list.length > 0) {
            this.organizationList = data.data.Organizations_list
            //this.organizationList = this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities')
          }
        }
      }
    }, error => {
    });
    this.ngxService.stop();
  }
  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.copyOrgAttributesForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      })
    }
    this.getDataToCopy();
  }
  getDataToCopy() {
    this.postUpload = false;
    if (typeof (this.copyOrgAttributesForm.value.frmOrgID) == 'object') {
      let frmorgid : any
      frmorgid = this.copyOrgAttributesForm.value.frmOrgID
      this.copyOrgAttributesForm.value.frmOrgID = frmorgid[0].organizationid
    }
    let queryVariable = { casetype: this.templateData.cardtype.toString(), orgid: this.copyOrgAttributesForm.value.frmOrgID.toString(), Attrtype:this.attrtype };
    let query = this.vitalHttpServices.GetQuery("getOrganizationAttributesCopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyOrgAttributesForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let copyData = data.data.submenuData;
        this.resetFormErrors();
        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFound = true;
          return
        }
        this.noDataFound = false;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }
  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.match(/name/i)) {
          primary['Attribute Name'] = value
        }
        // else if (key.match(/Description/i)) {
        //   primary['Description'] = value
        //}
        // else if (key.match(/Default/i)) {
        //   primary['isDefault'] = value=='Yes'? 1 : 0
        // }
        else if (key.match(/Sequence/i)) {
          primary['Sequence Order'] = value;
        }
        else if (key.match(/Active/i)) {
          primary['IsActive'] = value
        }
        delete copyData[i]['status']
      }
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }

  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data['slno']
      delete e._data['tablename']
      excel.push(e._data);
    });
    // this.attrtype
    let filename = 'Attributes_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.attrtype);
    XLSX.writeFile(wb, filename);
  }

  getGLDataPopup() {
    if(this.hideCreateBtn){
      return;
    }
    this.showModal = true;
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '400px',
      data: { header: this.attrtype, message: "Are you sure to copy the Default to Group level? \n Cannot rollback once it is done.", alert: "", continue: "Yes", cancel: "No" }
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          let dataJson = [];
          let globalJson = {};
          for (let i = 0; i < this.globalData.length; i++) {
            //let Attrtype = this.globalData[i].Attribute_Type == 'Dimensions' ? 'Dimension1' : this.globalData[i].Attribute_Type
            if (this.attrtype == this.globalData[i].Attribute_Type) {
              // let temp = this.templateData.submenuData.filter(va =>va.Attribute_Level.toLowerCase() != 'group' && (va.Attribute_Name != this.globalData[i].Attribute_Name && va.Attribute_Type != this.globalData[i].Attribute_Type ));
              //   if (temp) {
                  globalJson = {
                    "createdby": sessionStorage.getItem('Userid') == '' ? -100 : sessionStorage.getItem('Userid'),
                    "attributetype": this.globalData[i].Attribute_Type,
                    "attributename": this.globalData[i].Attribute_Name,
                    "attributecontext": this.globalData[i].Attribute_Context,
                    "organizationid": sessionStorage.getItem("org_id"),
                    "isactive": this.globalData[i].Active = 'Active' ? true : false,
                    "sequenceorder": this.globalData[i].Sequence_Order,
                    "attributeid": null,
                    "attributecontextid": this.globalData[i].Attribute_Context_Id ? this.globalData[i].Attribute_Context_Id : null,
                    "attributedescription": this.globalData[i].Attribute_Description ? this.globalData[i].Attribute_Description : null,
                    "attributevalue": this.globalData[i].Attribute_Value ? this.globalData[i].Attribute_Value : null,
                    "attributeservicetype": this.globalData[i].Attribute_Service_Type ? this.globalData[i].Attribute_Service_Type : null,
                    "attributecontextid2": this.globalData[i].Attribute_Context_Id_2 ? this.globalData[i].Attribute_Context_Id_2 : null,
                    "attributecontextid3": this.globalData[i].Attribute_Context_Id_3 ? this.globalData[i].Attribute_Context_Id_3 : null
                  }
                  dataJson.push(globalJson)
          //}
        }
          }
          this.addOrgAttributes(dataJson, false,null,true);
        }
      })

  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location}).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

}
