import { AllUsersComponent } from './../../../../client/Templates/allusers/allusers.component';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { UserPersonalInfoComponent } from 'src/app/client/Templates/UserPersonalInfo/UserPersonalInfo.component';
import { ActionbtnComponent } from '../../../../client/Templates/actionbtn/actionbtn.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [DatePipe],
})
export class EditUserComponent implements OnInit {

  @Input() userData: any
  @Input() fromComponent: any
  @Input() public organizationLoginType: any;
  @Input() userLoginType: any;

  editback: boolean = false;
  public DiagICDcodeReminder: boolean;
  public EPGorgType = "";
  ifUserDelete: boolean = false;
  ClickFrom: string = "UI";
  public hidIsPathlogistSelected: boolean = false;
  public hidIsMedicalDirector = false;
  public hidIsPhysicianSelected = false;
  ShoHoldReleaseDuration = false;
  ShowMedicalDirector = false;
  showfrmInp_AllowReviewPathologist: boolean = false;
  showfrmInp_AllowtoeditAccession: boolean = false;
  showfrmInp_EditSite: boolean = false;
  showfrmInp_AllowPathologistDiagnosisTemplate: boolean = false;
  showfrmInp_Notifyusers: boolean = true;
  showfrmInp_DisplayFormatReports: boolean = true;
  hidlicensure: string;
  showfrmInp_Threshold: boolean = false;
  showfrmDrp_ThresholdDuration: boolean = false;
  showfrmInp_PopulateDiag: boolean = false;
  ShowLicensureDetails: any = false;
  alertOnSave1: boolean = false;
  alertOnSave2: boolean = false;
  //alertOnSave3: boolean = false;
  alertOnSave4: boolean = false;
  alertOnSave5: boolean = false;
  alertOnSave6: boolean = false;
  alertOnSave7: boolean = false;
  alertOnSave10: boolean = false;
  alertOnSaveCommon: boolean = false;
  editStateList: any[];
  StateDiscription = "";
  HRD_KValue: any = "";
  MD_KValue: any = "";
  detailsDisplay: boolean = true;
  public usersdeatils;
  EditCommonKey: any;
  public Editpassword;
  public EditholdReleaseDuration;
  CommonAlertOnSave: string = "";
  public hidIsChecked = false;
  public CommonKey = "";
  loginNameExists: boolean = false;
  public ArrSavedRoles = [];
  showAccounts = false;
  showRoles = false;
  public associationData;
  public rolesdata;
  public IsMedicalDirector: boolean = false;
  public IsMedDirectorExistsForCommon: boolean = false;
  public hidIsCytoSelected: boolean = false;
  public hidIsCytoSupervisiorSelected = false;
  public hidPrimaryRole = "";
  rolename: any;
  public UserType = "";
  public IsOrderingFacility: boolean;
  public ArrThresholdDuration = [];
  public ifStatusDeleted: boolean;
  titlename;
  frmChk_IsMedicalDirector: boolean;
  EditisMedicalDirector: boolean;
  rolelistcount: any;
  rolelablistcount: any;
  rolelablistcytcount: any;
  rolelablistcyscount: any;
  createdby: any;
  pathuserid: any;
  LoginTypesflag: boolean;
  HRD_value: number = -1;
  Status_value: number = -1;
  userstatus: string;
  statuslist = [];
  oldobj: any;


  constructor(private ngxService: NgxUiLoaderService, public actionBtn: ActionbtnComponent, public datepipe: DatePipe, public UserPersonalInfoComponent: UserPersonalInfoComponent,
    private commonService: CommonService,
    public AllUsersComponent: AllUsersComponent, public vaservices: VitalHttpServices,
    private _fb: FormBuilder, private dialog: MatDialog, public _snackbar: MatSnackBar) { }

  zip_pattern = "/^\d{5}([\-]\d{4})?$/";
  //edit from-group
  frm_EditUsers = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmOrganization: ['', Validators.required],
    frmAccountName: '',
    frmInp_title: '',
    frmInp_Address1: '',
    frmInp_FirstName: ['', Validators.required],
    frmInp_Address2: '',
    frmInp_LastName: ['', Validators.required],
    frmInp_Street: '',
    frmInp_MiddleInitial: '',
    frmInp_City: '',
    frmInp_Qualification: '',
    frmEditInp_State: '',
    frmInp_LoginName: ['', Validators.required],
    frmInp_Zip: ['', [Validators.required, Validators.pattern(this.zip_pattern)]],
    frmInp_Gender: "1",
    frmInp_Country: '',
    frmInp_NPI: ['', Validators.required],
    frmInp_Phone: '',
    frmInp_DisplayFormatWebpage: "",
    frmInp_Fax: '',
    frmInp_DisplayFormatReports: "",
    frmInp_Email: ['', Validators.required],
    frmInp_SSN: '',
    frmInp_Status: "Active",
    frmInp_UserCode: '',
    frmInp_UPIN: '',
    frmInp_ShowMyCaseOnly:  false,
    frmInp_ExternalUserGUID: '',
    frmInp_US: false,
    frmInp_ST: false,
    frmInp_AllowReviewPathologist: false,
    frmInp_AllowtoeditAccession: true,
    frmInp_Threshold: '',
    frmInp_EditSite: true,
    frmDrp_ThresholdDuration: '',
    frmInp_AllowPathologistDiagnosisTemplate: false,
    frmInp_PopulateDiag: false,
    frmInp_Notifyusers: true,
    frmArrEditRoles: this._fb.array([]),
    frmArrEditAccounts: this._fb.array([]),
    frmDrp_OrgSendforReview: 'true',
    frmDrp_OrgManages: 'true',
    frmDrp_OrgWorks: 'true',
    frmchk_OrgLocation: false,
    formLicensure: this._fb.array([]),
    frmLblchk_OrgLocation: '',
    frmChk_RoleCheckboxValidator: false,
    frmInp_AllowLogin: false
  });

  ngOnInit(): void {
    if (this.userData.Status.toLowerCase() == "deleted") {
      this.ifStatusDeleted = true;
    } else {
      this.ifStatusDeleted = false;
    }
    this.getOrganizationLoginType();
    this.editMethod();
  }


  getOrganizationLoginType() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id') };
    let query = this.vaservices.GetQuery("getorganizationname");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(res => {
      if (res) {
        this.organizationLoginType = (res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' || res.data.submenuData[0].LoginType.toLowerCase() == 'loginname') ? 'LoginName' : 'Email';

      }
    }, error => {
      console.error(error);
    })
  }
  editBack() {
    if (this.fromComponent == "allusers") {
      this.AllUsersComponent.editBack();
    }
    else {
      Object.keys(this.usersdeatils).forEach(key => {
        if (!this.usersdeatils[key] || this.usersdeatils[key] == ''
          || this.usersdeatils[key] == '') {
          this.usersdeatils[key] = 'Not Specified';
        }
      });
      this.UserPersonalInfoComponent.back();
    }
  }

  changestatus(key) {
    if (key.toLowerCase() == 'deleted') {
      this.ifUserDelete = true;
    }
    this.deleteUser(key)
  }
  //#region Edit

  deleteUser(key) {
    var obj =
    {
      "loginName": this.userData.loginname,
      "commonkey": this.userData.CommonKey,
      "Deletekey": key
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        header: 'Delete User',
        message: '',
        alert: 'Are you sure you want to delete the user ' + this.userData.loginname + ' ?',
        continue: 'yes',
        cancel: 'no',
      },
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.ngxService.start();
          this.vaservices.DeleteUser(obj).subscribe(res => {
            let data: any = res;
            if (!data.error) {
              if (data.Success) {
                if (this.fromComponent == "allusers") {
                  this.ngxService.stop();
                  this._snackbar.open("User " + this.userData.loginname + " deleted successfully", "Success");
                  this.AllUsersComponent.refreshgriddata();
                  this.AllUsersComponent.gridDisplay = true;
                  this.AllUsersComponent.ViewDisplay = false;
                  this.AllUsersComponent.detailsDisplay = false;
                  this.AllUsersComponent.adduserform = false;
                }
                else {
                  this.ngxService.stop();
                  this._snackbar.open("User " + this.userData.loginname + " deleted successfully", "Success");
                  this.detailsDisplay = false;
                  this.UserPersonalInfoComponent.update(this.EditCommonKey);
                }

              }
            }
          }, error => {
            console.error(error);
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
        }
      });
  }
  isValidDate(dtvalue) {
    dtvalue = this.datepipe.transform(dtvalue, 'MM/dd/yyyy');
    var RegExPattern = /^(?=\d)(?:(?:(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2}))($|\ (?=\d)))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\ [AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    var validDate = false;
    if ((RegExPattern.test(dtvalue)) && (dtvalue.value != '')) {
      var index = dtvalue.lastIndexOf("/");
      var temp = dtvalue.substring(index + 1);
      if (temp.length == 4)
        validDate = true;
      else
        validDate = false;
    }
    else if (dtvalue == '') {
      validDate = true;
    }
    else
      validDate = false;
    return validDate;
  }

  clearAlertsOnSave() {
    this.alertOnSave1 = false;
    this.alertOnSave2 = false;
    this.alertOnSave4 = false;
    this.alertOnSave5 = false;
    this.alertOnSave6 = false;
    this.alertOnSave7 = false;
    this.alertOnSave10 = false;
    this.alertOnSaveCommon = false;
  }

  resetEditUserDetails() {
    this.clearAlertsOnSave();
    this.ClickFrom = "UI";
    this.hidIsPathlogistSelected = false;
    this.hidIsMedicalDirector = false;
    this.ShowMedicalDirector = false;
    this.ShoHoldReleaseDuration = false;
    this.hidIsPhysicianSelected = false;
    this.showfrmInp_AllowReviewPathologist = false;
    this.showfrmInp_AllowtoeditAccession = true;
    this.showfrmInp_EditSite = true;
    this.showfrmInp_Notifyusers = true;
    this.showfrmInp_DisplayFormatReports = true;
    this.hidlicensure = "";
    this.showfrmInp_Threshold = false;
    this.showfrmDrp_ThresholdDuration = false;
    this.showfrmInp_PopulateDiag = false;
    this.editMethod()
  }

  //Edit Send Review
  editSendforReview(show: boolean) {
    if (show) {
      this.frm_EditUsers.controls['frmDrp_OrgSendforReview'].enable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
      }
    }
    else {
      this.frm_EditUsers.controls['frmDrp_OrgSendforReview'].disable();
      let k;
      for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_SendforReview'].disable();
      }
    }
  }

  //Edit Toggle Role
  editToggleMainChkBxForRoleName(event) {
    let k;
    for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
          {
            frmChk_UserRoles_RoleName: event.target.checked,
            frmRd_UserRoles_RoleName: ''
          });
      }
      else if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
        && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
          {
            frmRd_UserRoles_RoleName: ''
          });
      }

      if (event.target.checked) {
        //when checked =  true
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
      }
      else {
        //when checked =  false
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
          && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
        else if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].disable();
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
    }
  }

  //Edit Toggle Primay Role
  editTogglePrimaryRole(event, index) {
    let k;
    for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; k++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
        event.target.checked = true;
      }
      else if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
      event.target.checked = true;
    }
    event.target.checked = true;
  }

  //Edit for fetch State details
  editLicensureDetails() {
    // call set change status dropdown
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "licensurestates");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.ngxService.start();
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        this.frm_EditUsers.patchValue(
          {
            frmEditInp_State: this.userData.state,
          });
        this.ShowLicensureDetails = true;
        this.editStateList = [];
        this.editStateList = result.data.submenudata;
        this.StateDiscription = this.editStateList[0].LookUpValue + " (" + this.editStateList[0].Description + ")";
        (<FormArray>this.frm_EditUsers.get('formLicensure')).clear();
        let frm;
        frm as FormGroup;
        frm = this._fb.group({
          frmDrp_LicsenceStates: new FormControl("Select"),
          frmDrp_LicsenceDate: ""
        });

        (<FormArray>this.frm_EditUsers.get('formLicensure')).push(frm);
        this.ShowLicensureDetails = false;
      }
      this.editThresholdDuration();
    }, error => {
      console.info(error);
    });
  }

  //Edit enable Role name check
  edit_onChangefrmDrp_OrgWorks(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).patchValue(
        {
          frmDrp_Works: eventValue
        });
    }
  }

  edit_onChangefrmDrp_OrgManages(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).patchValue(
        {
          frmDrp_Manages: eventValue
        });
    }
  }

  edit_onChangefrmDrp_OrgSendforReview(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = 'true';
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).patchValue(
        {
          frmDrp_SendforReview: eventValue
        });
    }
  }

  edit_onChangefrmchk_OrgLocation(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; m++) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).patchValue(
        {
          frmchk_Location: eventValue
        });
    }
  }
  editdoEnableRadioBtnForRoleNameChk(userRl, i, event) {
    if (event.target.checked == true) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
    else if (event.target.checked == false) {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
  }

  edit_enableRadioBtnForRoleNameChk(userRl, i, event) {
    this.editdoEnableRadioBtnForRoleNameChk(userRl, i, event);
    this.editRoleCheckboxValidation();
    if (this.ClickFrom === 'UI') {
      this.editToggle(i);
    }
  }

  //Edit role check box
  editRoleCheckboxValidation() {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length;

    for (m = 0; m < count; m++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == false) {
        count_false++;
      }
      else if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        count_true++;
      }
    }
    if (count_true === count) {
      this.frm_EditUsers.patchValue({
        frmChk_RoleCheckboxValidator: true
      });
    }
    else if (count_false === count) {
      this.frm_EditUsers.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
    else {
      this.frm_EditUsers.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
  }

  //Edit part
  editMethod() {
    this.ngxService.start();
    this.getIsMedicalDirectorvalue();
    this.searchOrgType();
    this.editSavedRoleList(this.userData.userid, this.userData.Organizationid);
    this.usersdeatils = this.userData;
    Object.keys(this.usersdeatils).forEach(key => {
      if (key != 'AllowLogin') {
        if (!this.usersdeatils[key] || this.usersdeatils[key] == 'Not Specified'
          || this.usersdeatils[key] == '') {
          this.usersdeatils[key] = '';
        }
      }
    });
    var chkUS = false;
    var chkST = false;
    if (this.usersdeatils.Region != null) {
      if (this.usersdeatils.Region.length > 0) {
        if (this.usersdeatils.Region.toLowerCase().includes('us')) {
          chkUS = true;
        } else {
          chkUS = false;
        }
        if (this.usersdeatils.Region.toLowerCase().includes('st')) {
          chkST = true;
        } else {
          chkST = false;
        }
      }
    }
    this.EditCommonKey = this.usersdeatils.CommonKey;
    this.EditholdReleaseDuration = this.userData.HoldReleaseDuration == "" ? 5 : this.userData.HoldReleaseDuration;
    this.Editpassword = this.usersdeatils.password;
    this.frm_EditUsers.patchValue({
      frmInp_title: this.usersdeatils.title,
      frmInp_FirstName: this.usersdeatils.firstname,
      frmInp_LastName: this.usersdeatils.lastname,
      frmInp_MiddleInitial: this.usersdeatils.middleinitials,
      frmInp_LoginName: this.usersdeatils.loginname,
      frmInp_Qualification: this.usersdeatils.Qualification,
      frmInp_NPI: this.usersdeatils.npi,
      frmInp_Gender: this.usersdeatils.gender != "" ? (this.usersdeatils.gender === "Male" ? "1" : "2") : this.usersdeatils.gender,
      frmInp_Status: this.usersdeatils.Status === "Active" ? "active" : "inactive",
      frmInp_Address1: this.usersdeatils.address1,
      frmInp_Address2: this.usersdeatils.address2,
      frmInp_Street: this.usersdeatils.street,
      frmInp_City: this.usersdeatils.city,
      frmEditInp_State: this.usersdeatils.state,
      frmInp_Country: this.usersdeatils.country,
      frmInp_Zip: this.usersdeatils.zip,
      frmInp_Phone: this.usersdeatils.phone,
      frmInp_Fax: this.usersdeatils.fax,
      frmInp_Email: this.usersdeatils.email,
      frmInp_SSN: this.usersdeatils.ssn,
      frmInp_UserCode: this.usersdeatils.usercode,
      frmInp_UPIN: this.usersdeatils.upin,
      frmInp_ExternalUserGUID: this.usersdeatils.ExternalUserGUID,
      frmInp_US: chkUS,
      frmInp_ST: chkST,
      frmInp_ShowMyCaseOnly: this.usersdeatils.ShowMyCaseOnly === "" ? false : this.usersdeatils.ShowMyCaseOnly,
      frmInp_DisplayFormatWebpage: this.usersdeatils.usernamedisplayformat,
      frmInp_DisplayFormatReports: this.usersdeatils.usernamereportformat,
      frmInp_AllowReviewPathologist: this.usersdeatils.ReviewPathCanSignout,
      frmInp_Threshold: this.usersdeatils.Threshold === "" ? 0 : this.usersdeatils.Threshold,
      frmDrp_ThresholdDuration: this.usersdeatils.ThresholdDuration === "" ? "Select" : this.usersdeatils.ThresholdDuration,
      frmInp_AllowPathologistDiagnosisTemplate: this.usersdeatils.IsUserLevelDiagnosisTemplateEnabled === "" ? false : this.usersdeatils.IsUserLevelDiagnosisTemplateEnabled,
      frmInp_PopulateDiag: this.usersdeatils.AutoPopulateDiagICD9Codes === "" ? false : this.usersdeatils.AutoPopulateDiagICD9Codes,
      frmInp_Notifyusers: this.usersdeatils.diagICDcodeReminder === "" ? false : this.usersdeatils.diagICDcodeReminder,
      frmInp_AllowtoeditAccession: true,
      frmInp_EditSite: true,
      frmInp_AllowLogin: this.usersdeatils.AllowLogin == 0 ? false : true,
    });

    this.oldobj = {
      "title": this.usersdeatils.title,
      "fname": this.usersdeatils.firstname,
      "lname": this.usersdeatils.lastname,
      "midInitial": this.usersdeatils.middleinitials,
      "loginName": this.usersdeatils.loginname,
      "eduQual": this.usersdeatils.Qualification,
      "gender": this.usersdeatils.gender,
      "npi": this.usersdeatils.npi,
      "address1": this.usersdeatils.address1,
      "address2": this.usersdeatils.address2,
      "street": this.usersdeatils.street,
      "city": this.usersdeatils.city,
      "state": this.usersdeatils.state,
      "zip": this.usersdeatils.zip,
      "country": this.usersdeatils.country,
      "phone": this.usersdeatils.phone,
      "fax": this.usersdeatils.fax,
      "email": this.usersdeatils.email,
      "status": this.usersdeatils.Status,
      "userNamedisplayFormat": this.usersdeatils.usernamedisplayformat,
      "usernameReportformat": this.usersdeatils.usernamereportformat,
      "password": this.usersdeatils.password,
      "rolename": this.usersdeatils.rolename,
      "ssn": this.usersdeatils.ssn,
      "upin": this.usersdeatils.upin,
      "userCode": this.usersdeatils.usercode,
      "commonKey": this.usersdeatils.CommonKey,
      "organizationID": this.usersdeatils.Organizationid,
      "showMyCaseOnly": this.usersdeatils.ShowMyCaseOnly,
      "alowPathologistDiagnosisTemplate": this.usersdeatils.IsUserLevelDiagnosisTemplateEnabled,
      "diagICDcodeReminder": this.usersdeatils.diagICDcodeReminder,
      "autoPopulateDiagICD9Codes": this.usersdeatils.AutoPopulateDiagICD9Codes,
      "region": this.usersdeatils.region,
      "externalUserGUID": this.usersdeatils.ExternalUserGUID,
      "HoldReleaseDurationInfo": this.usersdeatils.HoldReleaseDuration,
      "threshold": this.usersdeatils.Threshold,
      "thresholdDuration": this.usersdeatils.ThresholdDuration,
    };

    this.ngxService.stop();
  }


  searchOrgType() {
    let searchDrpValue = "Group ID"
    let searchInpValue = this.userData.Organizationid;
    let templateData
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserfilterorg");
    let queryString: string = "";
    switch (searchDrpValue) {
      case 'Group ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Group Name':
        queryString = "organizationid >= \"1\":decimal and organizationname like \"%" + searchInpValue + "%\"";
        queryVariable = { "filterParam": queryString }
        break;
      default:
        queryVariable = '';
        break;
    }

    queryResult = this.commonService.GetCardRequest(queryVariable, query[0].Query);

    this.vaservices.GetData(queryResult).subscribe(res => {

      if (!res.errors) {
        templateData = res.data.Organizations_list;
        if (templateData[0].LoginType != null) {
          this.LoginTypesflag = templateData[0].LoginType.toLowerCase() != 'email' ? true : false;
        }
        else {
          this.LoginTypesflag = true;
        }
      }
    }, error => {
      console.error(error);
      templateData = [];

    });
  }

  //#region validate empty data
  checkEmpties(data) {
    if (this.organizationLoginType === 'LoginName') {
      if ((data.frmInp_ST === false && data.frmInp_US === false) || data.frmInp_LoginName === "" || data.frmInp_LastName === "" || data.frmInp_FirstName === "" || data.frmInp_NPI === "") {
        return true;
      }
      return false;
    } else {
      if ((data.frmInp_ST === false && data.frmInp_US === false) || data.frmInp_LastName === "" || data.frmInp_FirstName === "" || this.checkEmail(data.frmInp_AllowLogin, data.frmInp_Email)) {
        return true;
      }
      return false;
    }

  }
  //#endregion

  get formcontrol() {
    return this.frm_EditUsers.controls;
  }

  validateForm(data: any) {
    if (this.checkEmpties(data)) {
      this._snackbar.open('Please enter all the required fields before saving!', 'Close');
      return;
    }
  }

  //Edit Validate
  editValidate() {
    this.clearAlertsOnSave();
    let form = this.frm_EditUsers.value;

    //*Mandatory fields: First Name, Last Name, Login Name, Region, Email !!
    if (this.organizationLoginType === 'LoginName') {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || form.frmInp_LoginName == "" || (form.frmInp_ST == false && form.frmInp_US == false) || form.frmInp_NPI == "") {
        this.alertOnSave1 = true;
        return false;
      }
      else {
        this.alertOnSave1 = false;
      }
    }
    else {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || (form.frmInp_ST == false && form.frmInp_US == false) || form.frmInp_NPI == "") {
        this.alertOnSave1 = true;
        return false;
      }
      else {
        this.alertOnSave1 = false;
      }
    }

    if (this.organizationLoginType.toString().toLowerCase() == 'email' && form.frmInp_AllowLogin) {
      if (form.frmInp_Email == "") {
        this.alertOnSave1 = true;
      }
    }


    //LoginName Availability check
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusercheckloginname");
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "LoginName": form.frmInp_LoginName }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenuData != undefined && result.data.submenuData != null) {
          //*Please select at least one Location!!
          let m;
          let count: number = 0;
          for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; m++) {
            if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).controls['frmchk_Location'].value) {
              count++;
            }
          }

          if (count == 0) {
            this.alertOnSave2 = true;
            return false;
          }
          else if (count > 0) {
            this.alertOnSave2 = false;
          }

          //*Please Enter valid Email!!
          this.alertOnSave4 = false;
          //this.emailcheck(form.frmInp_Email);
          if (form.frmInp_Email != "") {
            var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (re.test(form.frmInp_Email) == false) {
              this.alertOnSave4 = true;
              this.frm_EditUsers.patchValue(
                {
                  frmInp_Email: ""
                });
              return false;
            }
            else {
              this.alertOnSave4 = false;
            }
          }
          // this.emailcheck(form.frmInp_Email);

          //validate fax number
          this.alertOnSave5 = true;
          // npi check
          this.alertOnSave6 = false;
          if (form.frmInp_NPI != "" && form.frmInp_NPI.toLowerCase() != "unknown") {
            // var value = {
            //   "npi": form.frmInp_NPI,
            // }
            // this.vaservices.NpiValidation(value).subscribe((Info) => {
            if (form.frmInp_NPI.length != 10) {
              this.alertOnSave6 = true;
              // this.frm_EditUsers.patchValue(
              //   {
              //     frmInp_NPI: ""
              //   });
              return false;
            }
            else {
              this.alertOnSave6 = false;
              var fnValidate_RoleSelected = false;
              var fnValidate_primarySelected = false;
              var fnValidate_HoldDurationTime = 0;
              var fnValidate_isPrimarybelongsToSelectedRole = false;
              var fnValidate_isLabManager = false;
              var fnValidate_iscytotechnologist = false;
              var fnValidate_iscyt = false;
              var fnValidate_isPathologist = false;

              let mn;
              for (mn = 0; mn < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; mn++) {
                var fnValidate_chkrole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
                var fnValidate_rdnPrimaryRole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
                var fnValidate_txtHoldduration = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

                if (fnValidate_chkrole == true) {
                  fnValidate_RoleSelected = true;
                }
                if (fnValidate_rdnPrimaryRole != "") {
                  fnValidate_primarySelected = true;
                }
                if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                  fnValidate_isPrimarybelongsToSelectedRole = true;
                }
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                  && fnValidate_chkrole == true) {
                  fnValidate_isLabManager = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  this.EditholdReleaseDuration = fnValidate_txtHoldduration.toString();
                }
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                  && fnValidate_chkrole == true) {
                  fnValidate_iscytotechnologist = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  this.EditholdReleaseDuration = fnValidate_txtHoldduration.toString();
                }
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                  && fnValidate_chkrole == true) {
                  fnValidate_iscyt = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                  this.EditholdReleaseDuration = fnValidate_txtHoldduration.toString();
                }
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                  && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                  fnValidate_isPathologist = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                }
              }

              if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
                || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Please enter hold duration time in minutes (0-60)";
                return false;
              }

              if (fnValidate_RoleSelected == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = '*Please select at least one Role!!';
                return false;
              }

              if (fnValidate_primarySelected == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = '*Please select primary role!!';
                return false;
              }

              if (fnValidate_isPrimarybelongsToSelectedRole == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Primary role selected is invalid!!";
                return false;
              }

              var fnValidate_chkAccountcount: number = 0;
              var fnValidate_cnt: number = 0;
              let k;
              for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                  fnValidate_chkAccountcount++;
                  var fnValidate_drpwrktext = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_Works'].value;
                  if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                    fnValidate_cnt++;
                  }
                }
              }
              if (this.hidIsPathlogistSelected == true) {
                var fnValidate_drpWorksSelected = 'false';
                for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
                  if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                    fnValidate_drpWorksSelected = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_Works'].value;
                  }
                  if (fnValidate_drpWorksSelected == 'true') {
                    break;
                  }
                }

                if (fnValidate_drpWorksSelected == 'false') {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                  return false;
                }
              }
              if (fnValidate_chkAccountcount == 0) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Please select at least one Location!!";
                return false;
              }
              if (fnValidate_cnt > 1) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Only one location can be marked as true!!";
                return false;
              }

              if (this.hidIsPathlogistSelected == true) {
                var IsMedicalDirectorExists_isMedicalDirector = false;
                var IsMedicalDirectorExists_status;
                var IsMedicalDirectorExists_userName;
                var IsMedicalDirectorExists_commonKey;
                var IsMedicalDirectorExists_userID;
                //IsMedicalDirectorExists
                this.vaservices.GetIsMedicalDirectorExists(this.userData.Organizationid, this.userData.userid).subscribe(data => {
                  var jsonResult: any = data;
                  if (jsonResult != undefined && jsonResult != null) {
                    if (jsonResult.length > 0) {

                      IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                      IsMedicalDirectorExists_status = jsonResult[0].Status;
                      IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                      IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                      IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                    }
                    else {
                      IsMedicalDirectorExists_isMedicalDirector = false;
                      IsMedicalDirectorExists_status = "";
                      IsMedicalDirectorExists_userID = "";
                      IsMedicalDirectorExists_userName = "";
                      IsMedicalDirectorExists_commonKey = "";
                    }
                  }
                  else {
                    IsMedicalDirectorExists_isMedicalDirector = false;
                    IsMedicalDirectorExists_status = "";
                    IsMedicalDirectorExists_userID = "";
                    IsMedicalDirectorExists_userName = "";
                    IsMedicalDirectorExists_commonKey = "";
                  }

                  if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Medical Director already exist!!";
                    return false;
                  }
                  if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                    && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                    if (IsMedicalDirectorExists_status == "active") {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*Medical Director already exist!!";
                      return false;
                    }
                    else if (IsMedicalDirectorExists_status == "inactive") {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                      return false;
                    }
                  }

                  var stateID;
                  var confirmexpdate;
                  var alldata = [];
                  var StateLists = [];
                  var DuplicateStateCount = 0;
                  var i = 0;
                  var DateValueCount = 0;

                  if (this.hidIsPathlogistSelected == true) {
                    for (k = 0; k < (<FormArray>this.frm_EditUsers.get('formLicensure')).length; k++) {
                      var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                      var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                      var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                      if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                        return true;
                      }
                      if (alldata.length == i) {
                        alldata[i] = {
                          id: "",
                          State: "",
                          Expirydate: "",
                          Statefullname: "",
                        };
                        alldata[i].id = JSON.stringify(i + 1);
                        alldata[i].State = PathLic_State;
                        alldata[i].Expirydate = PathLic_Expirydate;
                        alldata[i].Statefullname = PathLic_Statefullname;

                        if (alldata[i].Expirydate == "") {
                          confirmexpdate = true;
                        }
                        if (alldata[i].State != "") {
                          if (StateLists.indexOf(alldata[i].State) === -1) {
                            StateLists.push(alldata[i].State);
                          }
                          else {
                            DuplicateStateCount++;
                            return false;
                          }
                        }
                      }
                      i++;
                    }
                    if (DuplicateStateCount > 0) {
                      alert("The state has been selected multiple times. Please select some other state or delete the state.");
                      document.getElementById(stateID).focus();
                      return false;
                    }
                    if (DateValueCount > 0) {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*Please enter a valid Expirydate!!";
                      return false;
                    }
                    //licensure details in json object format
                    this.hidlicensure = JSON.stringify(alldata);

                    //begin save by forming save json to send to API
                    let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                    let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                    this.vaservices.GetData(queryResult).subscribe(res => {
                      if (!res.errors) {
                        if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                          let result = res.data.submenuData.filter((va) => va.commonkey != this.EditCommonKey)
                          if (result.length > 0) {
                            let dialogRef = this.dialog.open(ConfirmComponent, {
                              disableClose: true,
                              width: '500px',
                              data: { header: "Edit user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                            });
                          }
                          else {
                            this.alertOnSave10 = false;
                            var usersData_Jsonformat = this.editgenerateSaveJson();
                            this.editUserData(usersData_Jsonformat);
                          }
                        }
                        else {
                          this.alertOnSave10 = false;
                          var usersData_Jsonformat = this.editgenerateSaveJson();
                          this.editUserData(usersData_Jsonformat);
                        }
                      }
                    }, error => {
                      console.error(error);
                    })

                  }
                }, error => {
                  console.error(error)
                });
              }
              else {
                //begin save by forming save json to send to API
                let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                this.vaservices.GetData(queryResult).subscribe(res => {
                  if (!res.errors) {
                    if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                      let result = res.data.submenuData.filter((va) => va.commonkey != this.EditCommonKey)
                      if (result.length > 0) {
                        let dialogRef = this.dialog.open(ConfirmComponent, {
                          disableClose: true,
                          width: '500px',
                          data: { header: "Edit user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                        });
                      }
                      else {
                        this.alertOnSave10 = false;
                        var usersData_Jsonformat = this.editgenerateSaveJson();
                        this.editUserData(usersData_Jsonformat);
                      }
                    }
                    else {
                      this.alertOnSave10 = false;
                      var usersData_Jsonformat = this.editgenerateSaveJson();
                      this.editUserData(usersData_Jsonformat);
                    }
                  }
                }, error => {
                  console.error(error);
                })
              }

            }
            // }, error => {
            //   console.info(error);
            //   this.alertOnSave6 = true;
            // });
          }
          else {
            this.alertOnSave6 = false;
            var fnValidate_RoleSelected = false;
            var fnValidate_primarySelected = false;
            var fnValidate_HoldDurationTime = 0;
            var fnValidate_isPrimarybelongsToSelectedRole = false;
            var fnValidate_isLabManager = false;
            var fnValidate_iscytotechnologist = false;
            var fnValidate_iscyt = false;
            var fnValidate_isPathologist = false;

            let mn;
            for (mn = 0; mn < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; mn++) {
              var fnValidate_chkrole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
              var fnValidate_rdnPrimaryRole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
              var fnValidate_txtHoldduration = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

              if (fnValidate_chkrole == true) {
                fnValidate_RoleSelected = true;
              }
              if (fnValidate_rdnPrimaryRole != "") {
                fnValidate_primarySelected = true;
              }
              if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                fnValidate_isPrimarybelongsToSelectedRole = true;
              }
              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                && fnValidate_chkrole == true) {
                fnValidate_isLabManager = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                this.EditholdReleaseDuration = fnValidate_txtHoldduration.toString();
              }
              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_isPathologist = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscyt = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }

              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscytotechnologist = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
            }

            if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
              || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Please enter hold duration time in minutes (0-60)";
              return false;
            }

            if (fnValidate_RoleSelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = '*Please select at least one Role!!';
              return false;
            }

            if (fnValidate_primarySelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = '*Please select primary role!!';
              return false;
            }

            if (fnValidate_isPrimarybelongsToSelectedRole == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Primary role selected is invalid!!";
              return false;
            }

            var fnValidate_chkAccountcount: number = 0;
            var fnValidate_cnt: number = 0;
            let k;
            for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                fnValidate_chkAccountcount++;
                var fnValidate_drpwrktext = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_Works'].value;
                if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                  fnValidate_cnt++;
                }
              }
            }
            if (this.hidIsPathlogistSelected == true) {
              var fnValidate_drpWorksSelected = 'false';
              for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {
                if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                  fnValidate_drpWorksSelected = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_Works'].value;
                }
                if (fnValidate_drpWorksSelected == 'true') {
                  break;
                }
              }

              if (fnValidate_drpWorksSelected == 'false') {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                return false;
              }
            }

            if (fnValidate_chkAccountcount == 0) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Please select at least one Location!!";
              return false;
            }
            if (fnValidate_cnt > 1) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Only one location can be marked as true!!";
              return false;
            }

            if (this.hidIsPathlogistSelected == true) {
              var IsMedicalDirectorExists_isMedicalDirector = false;
              var IsMedicalDirectorExists_status;
              var IsMedicalDirectorExists_userName;
              var IsMedicalDirectorExists_commonKey;
              var IsMedicalDirectorExists_userID;
              this.vaservices.GetIsMedicalDirectorExists(this.userData.Organizationid, this.userData.userid).subscribe(data => {
                var jsonResult: any = data;
                if (jsonResult != undefined && jsonResult != null) {
                  if (jsonResult.length > 0) {

                    IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                    IsMedicalDirectorExists_status = jsonResult[0].Status;
                    IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                    IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                    IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                  }
                  else {
                    IsMedicalDirectorExists_isMedicalDirector = false;
                    IsMedicalDirectorExists_status = "";
                    IsMedicalDirectorExists_userID = "";
                    IsMedicalDirectorExists_userName = "";
                    IsMedicalDirectorExists_commonKey = "";
                  }
                }
                else {
                  IsMedicalDirectorExists_isMedicalDirector = false;
                  IsMedicalDirectorExists_status = "";
                  IsMedicalDirectorExists_userID = "";
                  IsMedicalDirectorExists_userName = "";
                  IsMedicalDirectorExists_commonKey = "";
                }

                if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Medical Director already exist!!";
                  return false;
                }
                if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                  && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                  if (IsMedicalDirectorExists_status == "active") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Medical Director already exist!!";
                    return false;
                  }
                  else if (IsMedicalDirectorExists_status == "inactive") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                    return false;
                  }
                }

                var stateID;
                var confirmexpdate;
                var alldata = [];
                var StateLists = [];
                var DuplicateStateCount = 0;
                var i = 0;
                var DateValueCount = 0;

                if (this.hidIsPathlogistSelected == true) {
                  for (k = 0; k < (<FormArray>this.frm_EditUsers.get('formLicensure')).length; k++) {
                    var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                    var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                    var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                    if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                      return true;
                    }
                    if (alldata.length == i) {
                      // alldata[i] = this.pathLicesData();
                      alldata[i] = {
                        id: "",
                        State: "",
                        Expirydate: "",
                        Statefullname: "",
                      };
                      alldata[i].id = JSON.stringify(i + 1);
                      alldata[i].State = PathLic_State;
                      alldata[i].Expirydate = PathLic_Expirydate;
                      alldata[i].Statefullname = PathLic_Statefullname;

                      if (alldata[i].Expirydate == "") {
                        confirmexpdate = true;
                      }
                      if (alldata[i].State != "") {
                        if (StateLists.indexOf(alldata[i].State) === -1) {
                          StateLists.push(alldata[i].State);
                        }
                        else {
                          DuplicateStateCount++;
                          return false;
                        }
                      }
                    }
                    i++;
                  }

                  if (DuplicateStateCount > 0) {
                    alert("The state has been selected multiple times. Please select some other state or delete the state.");
                    document.getElementById(stateID).focus();
                    return false;
                  }
                  if (DateValueCount > 0) {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Please enter a valid Expirydate!!";
                    return false;
                  }
                  //licensure details in json object format
                  this.hidlicensure = JSON.stringify(alldata);

                  //begin save by forming save json to send to API
                  let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                  let query = this.vaservices.GetQuery("checkUserDuplicateValues");
                  let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                  this.vaservices.GetData(queryResult).subscribe(res => {
                    if (!res.errors) {
                      if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                        let result = res.data.submenuData.filter((va) => va.commonkey != this.EditCommonKey)
                        if (result.length > 0) {
                          let dialogRef = this.dialog.open(ConfirmComponent, {
                            disableClose: true,
                            width: '500px',
                            data: { header: "Edit user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                          });
                        }
                        else {
                          this.alertOnSave10 = false;
                          var usersData_Jsonformat = this.editgenerateSaveJson();
                          this.editUserData(usersData_Jsonformat);
                        }
                      }
                      else {
                        this.alertOnSave10 = false;
                        var usersData_Jsonformat = this.editgenerateSaveJson();
                        this.editUserData(usersData_Jsonformat);
                      }
                    }
                  }, error => {
                    console.error(error);
                  })
                }
              }, error => {
                console.error(error)
              });
            }
            else {
              //begin save by forming save json to send to API
              let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
              let query = this.vaservices.GetQuery("checkUserDuplicateValues");
              let queryResult = this.commonService.GetCardRequest(queryVariable, query);
              this.vaservices.GetData(queryResult).subscribe(res => {
                if (!res.errors) {
                  if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                    let result = res.data.submenuData.filter((va) => va.commonkey != this.EditCommonKey)
                    if (result.length > 0) {
                      let dialogRef = this.dialog.open(ConfirmComponent, {
                        disableClose: true,
                        width: '500px',
                        data: { header: "Edit user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow" }
                      });
                    }
                    else {
                      this.alertOnSave10 = false;
                      var usersData_Jsonformat = this.editgenerateSaveJson();
                      this.editUserData(usersData_Jsonformat);
                    }
                  }
                  else {
                    this.alertOnSave10 = false;
                    var usersData_Jsonformat = this.editgenerateSaveJson();
                    this.editUserData(usersData_Jsonformat);
                  }
                }
              }, error => {
                console.error(error);
              })
            }

          }

        }
      }

    }, error => {
      console.info(error);
      this.loginNameExists = false;
      this.alertOnSave7 = false;
    });
  }

  edit_onChangefrmchk_Location(event_checked) {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length;

    for (m = 0; m < count; m++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).controls['frmchk_Location'].value == false) {
        count_false++;
      }
      else if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(m)).controls['frmchk_Location'].value == true) {
        count_true++;
      }
    }

    if (count_true === count) {
      this.frm_EditUsers.patchValue({
        frmchk_OrgLocation: true
      });
    }
    else if (count_false === count) {
      this.frm_EditUsers.patchValue({
        frmchk_OrgLocation: false
      });
    }
    else {
      this.frm_EditUsers.patchValue({
        frmchk_OrgLocation: false
      });
    }
  }
  //MedicalDirectorCheck
  MedicalDirectorCheck(event, index) {
    if (event.target.checked == true)
      this.hidIsChecked = true;
    else
      this.hidIsChecked = false;
  }

  editToggle(index) {
    let m;
    for (m = 0; m < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; m++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Pathologist') {
          this.hidIsPathlogistSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist') {
          this.hidIsCytoSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist Supervisor') {
          this.hidIsCytoSupervisiorSelected = true;
        }
        if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Physician') {
          this.hidIsPhysicianSelected = true;
        }
        else {
          this.hidIsPhysicianSelected = false;
        }
      }
    }
    if (((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'lab manager' &&
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
      this.HRD_KValue = this.rolelablistcount;
      this.HRD_value = -1;
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).patchValue(
        {
          frmInp_RoleHoldreleaseDuration: '0'
        });
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
    }
    else {
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
    }
    if (((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {

        if (this.EPGorgType == 'Pathologists') {
          this.ShowLicensureDetails = true;
        }
        else {
          this.ShowLicensureDetails = false;
        }
        this.hidIsPathlogistSelected = true;
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
        if (this.hidIsMedicalDirector == true) {
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        }
        else {
          this.MD_KValue = this.rolelistcount;
          this.HRD_value = -1;
          if (this.hidIsChecked == false) {
            this.hidIsChecked = false;
            (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
          }
          else {
            (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
          }
          //this.HRD_KValue=this.rolelistcount;
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].enable();
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
          (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).patchValue(
            {
              frmRd_UserRoles_RoleName: 'Pathologists',
              frmChk_UserRoles_RoleName: true,
              frmInp_RoleHoldreleaseDuration: '0'
            });
          this.editRoleCheckboxValidation();
        }
      }
      else {
        this.ShowLicensureDetails = false;
        this.hidIsPathlogistSelected = false;
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();

        this.frm_EditUsers.controls['frmInp_AllowReviewPathologist'].disable();
        this.ShowLicensureDetails = false;
        this.showfrmInp_AllowReviewPathologist = false;

        this.frm_EditUsers.controls['frmInp_AllowtoeditAccession'].disable();
        this.showfrmInp_AllowtoeditAccession = false;
        this.showfrmInp_Threshold = false;

        this.frm_EditUsers.controls['frmInp_EditSite'].disable();
        this.showfrmInp_EditSite = false;
        this.showfrmDrp_ThresholdDuration = false;

        this.frm_EditUsers.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
        this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
        this.showfrmInp_PopulateDiag = false;

        if (this.hidIsCytoSelected == false || this.hidIsCytoSupervisiorSelected == false) {
          this.frm_EditUsers.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }

        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].disable();
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: false
          });

        return;
      }
      this.frm_EditUsers.controls['frmInp_AllowReviewPathologist'].enable();
      this.showfrmInp_AllowReviewPathologist = true;
      this.ShowLicensureDetails = true;
      this.frm_EditUsers.controls['frmInp_AllowtoeditAccession'].enable();
      this.showfrmInp_AllowtoeditAccession = true;
      this.showfrmInp_Threshold = true;
      this.frm_EditUsers.controls['frmInp_EditSite'].enable();
      this.showfrmInp_EditSite = true;
      this.showfrmDrp_ThresholdDuration = true;
      this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
      this.showfrmInp_Notifyusers = true;
      if (this.UserType == "Laboratories" && this.IsOrderingFacility == true) {
        this.frm_EditUsers.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true
        });
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist') {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSelected = true;
        this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_EditUsers.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else if (((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist supervisor') {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_value = -1;
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSupervisiorSelected = true;
        this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSupervisiorSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_EditUsers.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else {
      if (((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'physician'
        || ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
        this.frm_EditUsers.controls['frmInp_NPI'].enable();
      }
      this.frm_EditUsers.controls['frmInp_AllowReviewPathologist'].disable();
      this.showfrmInp_AllowReviewPathologist = false;
      this.ShowLicensureDetails = false;
      this.frm_EditUsers.controls['frmInp_AllowtoeditAccession'].disable();
      this.showfrmInp_AllowtoeditAccession = false;
      this.showfrmInp_Threshold = false;
      this.frm_EditUsers.controls['frmInp_EditSite'].disable();
      this.showfrmInp_EditSite = false;
      this.showfrmDrp_ThresholdDuration = false;
      this.frm_EditUsers.controls['frmInp_Notifyusers'].disable();
      this.showfrmInp_Notifyusers = false;
      this.frm_EditUsers.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
      this.showfrmInp_PopulateDiag = false;
      if (this.hidIsPathlogistSelected == true) {
        this.frm_EditUsers.controls['frmInp_AllowReviewPathologist'].enable();
        this.showfrmInp_AllowReviewPathologist = true;
        this.ShowLicensureDetails = true;
        this.frm_EditUsers.controls['frmInp_AllowtoeditAccession'].enable();
        this.showfrmInp_AllowtoeditAccession = true;
        this.showfrmInp_Threshold = true;
        this.frm_EditUsers.controls['frmInp_EditSite'].enable();
        this.showfrmInp_EditSite = true;
        this.showfrmDrp_ThresholdDuration = true;
        this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      this.showfrmInp_Notifyusers = true;
      this.frm_EditUsers.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
      this.showfrmInp_PopulateDiag = true;
    }
    if (this.hidIsPathlogistSelected == true) {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_EditUsers.controls['frmInp_DisplayFormatReports'].enable();
    }
    else {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_EditUsers.controls['frmInp_DisplayFormatReports'].disable();
    }
  }
  //edit part save
  editAndClose(adduserData) {
    this.validateForm(adduserData)
    this.editValidate();
  }
  //Edit part json create
  editgenerateSaveJson() {
    let form = this.frm_EditUsers.value;
    this.userData.loginname = form.frmInp_LoginName;
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    let roleList = "";
    let selected_primaryrole = "";
    let holdReleaseDurationvalue = "";
    let holdselected_primaryrole = "";
    //"Lab Manager,Claim tracker";
    for (let i = 0; i < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; i++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        roleList += (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
        holdReleaseDurationvalue += (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
      }
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].value != "") {
        selected_primaryrole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
        holdselected_primaryrole = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
      }
    }
    //holdduration list
    let arraylist1: string[];
    arraylist1 = holdReleaseDurationvalue.split(',');
    let listrole1: string[];
    listrole1 = arraylist1.filter(item => item !== holdselected_primaryrole)
    let role1 = String(listrole1);
    holdReleaseDurationvalue = holdselected_primaryrole + ',' + role1;
    holdReleaseDurationvalue = holdReleaseDurationvalue.substring(0, holdReleaseDurationvalue.length - 1);
    //rolelist ;
    let arraylist: string[];
    arraylist = roleList.split(',');
    let listrole: string[];
    listrole = arraylist.filter(item => item !== selected_primaryrole)
    let role = String(listrole);
    roleList = selected_primaryrole + ',' + role;
    roleList = roleList.substring(0, roleList.length - 1);
    let review_data = [];
    let accountList = "";
    // "5:1,6:1,20029:0,26112:0,26275:0";
    for (let j = 0; j < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; j++) {
      if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmchk_Location'].value as boolean == true) {
        accountList += (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmLblchk_Location'].value + ":1,";
      }
      else {
        accountList += (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmLblchk_Location'].value + ":0,";
      }
      //review data
      let obj = {
        "userID": this.userData.userid,
        "AccountID": (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmLblchk_Location'].value,
        "sendforreview": (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmDrp_SendforReview'].value == "true" ? true : false,
        "works": (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmDrp_Works'].value == "true" ? true : false,
        "manages": (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(j)).controls['frmDrp_Manages'].value == "true" ? true : false,
        "RoleName": selected_primaryrole,
        "primaryrole": selected_primaryrole != "" ? true : false,
        "CanPathologistModifyAccession": form.frmInp_AllowtoeditAccession,
        "CanPathologistModify": form.frmInp_EditSite,
        "ModifiedBy": loggedIn_userID != "" ? loggedIn_userID : "-100"
      };
      review_data.push(obj);
    }
    accountList = accountList.substring(0, accountList.length - 1);

    //check if user is medical director
    let MD_val = false;
    if (this.MD_KValue != -1 &&
      (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(this.MD_KValue)).controls['frmChk_IsMedicalDirector'].value == true) {
      MD_val = true;
    }

    //check for hold release duration value
    let HD_val: number = 0;
    if (this.HRD_KValue != -1) {
      let user_hd_val = (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(this.HRD_KValue)).controls['frmInp_RoleHoldreleaseDuration'].value;
      if (user_hd_val != null && user_hd_val != "") {
        HD_val = parseInt(user_hd_val);
      }
    }

    //convert threshold value to number
    let threshold_value: number = 0;
    if (form.frmInp_Threshold != null && form.frmInp_Threshold != "") {
      threshold_value = parseInt(form.frmInp_Threshold);
    }

    //check if primary role has changed
    let isPrimaryRoleChanged = false;
    if (this.hidPrimaryRole != "" && selected_primaryrole != "" && selected_primaryrole != this.hidPrimaryRole) {
      isPrimaryRoleChanged = true;
    }

    //get licensure details
    let lic_details: string = "";
    if (this.hidIsPathlogistSelected) {
      lic_details = "<ArrayOfPathologistLicensure>";
      for (let a = 0; a < (<FormArray>this.frm_EditUsers.get('formLicensure')).length; a++) {
        var PathLic_Statefullname: string = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value;
        var PathLic_Expirydate = (<FormGroup>(<FormArray>this.frm_EditUsers.get('formLicensure')).at(a)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
        PathLic_State = PathLic_State === 'Select' ? 'null' : PathLic_State;
        PathLic_Expirydate = this.datepipe.transform(PathLic_Expirydate, 'MM/dd/yyyy');

        //build xml string
        lic_details += "  <PathologistLicensure>";
        lic_details += "  <id>" + (a + 1) + "</id>";
        lic_details += "  <state>" + PathLic_State + "</state>";
        lic_details += "  <Expirydate>" + PathLic_Expirydate + "</Expirydate>";
        lic_details += "  <Statefullname>" + PathLic_Statefullname + "</Statefullname>";
        lic_details += "  </PathologistLicensure>";
      }
      lic_details += "  </ArrayOfPathologistLicensure>";
    }
    let orgid = this.userData.Organizationid;
    var reg = null;
    if (form.frmInp_US && form.frmInp_ST) {
      reg = 'US,ST'
    }
    else if (form.frmInp_ST) {
      reg = 'ST'
    }
    else if (form.frmInp_US) {
      reg = 'US'
    }
    let sendJson = {
      "title": form.frmInp_title,
      "fname": form.frmInp_FirstName,
      "lname": form.frmInp_LastName,
      "midInitial": form.frmInp_MiddleInitial,
      "loginName": form.frmInp_LoginName,
      "eduQual": form.frmInp_Qualification,
      "gender": form.frmInp_Gender == "1" ? true : false,
      "npi": form.frmInp_NPI,
      "address1": form.frmInp_Address1,
      "address2": form.frmInp_Address2,
      "street": form.frmInp_Street,
      "city": form.frmInp_City,
      "state": form.frmEditInp_State,
      "zip": form.frmInp_Zip,
      "country": form.frmInp_Country,
      "phone": form.frmInp_Phone,
      "fax": form.frmInp_Fax,
      "email": form.frmInp_Email,
      "status": "Active",
      "rolename": this.rolename,
      "userNamedisplayFormat": form.frmInp_DisplayFormatWebpage,
      "usernameReportformat": form.frmInp_DisplayFormatReports,
      "password": this.Editpassword,
      "defaultPage": "VAPreferences.aspx",
      "ssn": form.frmInp_SSN,
      "upin": form.frmInp_UPIN,
      "userCode": form.frmInp_UserCode,
      "sign": "",
      "commonKey": this.EditCommonKey,
      "isAccLockedOut": false,
      "allowPathologistReview": form.frmInp_AllowReviewPathologist == null ? false : form.frmInp_AllowReviewPathologist as boolean,
      "isModifyTechnicalDataAccession": form.frmInp_AllowtoeditAccession == null ? false : form.frmInp_AllowtoeditAccession as boolean,
      "modifyTechnicalData": form.frmInp_EditSite == null ? false : form.frmInp_EditSite as boolean,
      "roleList": roleList,
      "isPrimaryRoleChanged": isPrimaryRoleChanged,
      "works": form.frmDrp_OrgWorks == 'true' ? true : false,
      "manages": form.frmDrp_OrgManages == 'true' ? true : false,
      "sendForreview": form.frmDrp_OrgSendforReview == 'true' ? true : false,
      "accountList": accountList,
      "pathReadingLoc": "5",
      "newRolesAdded": false,
      "medicalDirector": MD_val as boolean,
      "associateAccID": null,
      "deassociateAccID": null,
      "organizationID": orgid,
      "primaryLocation": 0,
      "showMyCaseOnly": form.frmInp_ShowMyCaseOnly == null ? false : form.frmInp_ShowMyCaseOnly as boolean,
      "alowPathologistDiagnosisTemplate": form.frmInp_AllowPathologistDiagnosisTemplate == null ? false : form.frmInp_AllowPathologistDiagnosisTemplate as boolean,
      "diagICDcodeReminder": form.frmInp_Notifyusers == null ? false : form.frmInp_Notifyusers as boolean,
      "autoPopulateDiagICD9Codes": form.frmInp_PopulateDiag == null ? false : form.frmInp_PopulateDiag as boolean,
      "region": reg,
      "associationType": null,
      "externalUserGUID": form.frmInp_ExternalUserGUID,
      "userlicensure": lic_details,
      "HoldReleaseDurationInfo": holdReleaseDurationvalue,
      "holdReleaseDuration": HD_val,
      "threshold": threshold_value,
      "thresholdDuration": form.frmDrp_ThresholdDuration,
      "createdby": "-100",
      "modifiedby": loggedIn_userID != "" ? loggedIn_userID : "-100",
      "userid": this.userData.userid,
      "loggedInUserId": loggedIn_userID != "" ? loggedIn_userID : "-100",
      "entityType": "EditUser",
      "entityID": "",
      "actionperformed": "Edit User",
      "actiondetails": "",
      "auditDate": new Date(),
      "EntityData": null,
      "reviewData": review_data
    };
    sendJson["allowLogin"] = this.organizationLoginType == 'Email' ? form.frmInp_AllowLogin : false;
    return sendJson;
  }


  checkEmail(allowLogin, email) {
    if (allowLogin) {
      return email == '' ? true : false
    }
    else {
      return false
    }
  }
  //Edit part send json to API
  editUserData(data) {
    this.ngxService.start("1");
    this.vaservices.SaveAddUserToOrg(data).subscribe(result => {
      if (!result.errors) {
        if (result.Success == false) {
          this._snackbar.open("An error occurred while processing your request", 'Close');
          this.ngxService.stop("1");
        }
        else {
          if (this.statuslist.length > 0) {
            this.actionBtn.allowLogin = data.allowLogin
            this.UpdateUserStatus();
            if (this.LoginTypesflag == true) {
              if (this.fromComponent == "allusers") {
                this.AllUsersComponent.refreshEditdata();
              }
              else {
                // this.ActionbtnComponent.refresh();
                this.UserPersonalInfoComponent.update(this.EditCommonKey);
              }
              //audit
              this.commonService.createActivityTracker('User', this.userData.userid, 'User', 'Audit', data, this.usersdeatils);
              this._snackbar.open("Updated Successfully", 'Success');
              this.ngxService.stop("1");
            }
            else {
              if (this.fromComponent == "allusers") {
                this.AllUsersComponent.refreshEditdata();
              }
              else {
                // this.ActionbtnComponent.refresh();
                this.UserPersonalInfoComponent.update(this.EditCommonKey);
              }
              //audit
              this.commonService.createActivityTracker('User', this.userData.userid, 'User', 'Audit', data, this.usersdeatils);
              this._snackbar.open("Updated Successfully", 'Success');
              this.ngxService.stop("1");
            }
          }
          else {
            if (this.LoginTypesflag == true) {
              if (this.fromComponent == "allusers") {
                this.AllUsersComponent.refreshEditdata();
              }
              else {
                // this.ActionbtnComponent.refresh();
                this.UserPersonalInfoComponent.update(this.EditCommonKey);
              }
              //audit
              this.commonService.createActivityTracker('User', this.userData.userid, 'User', 'Audit', data, this.usersdeatils);
              this._snackbar.open("Updated Successfully", 'Success');
              this.ngxService.stop("1");
            }
            else {
              if (this.fromComponent == "allusers") {
                this.AllUsersComponent.refreshEditdata();
              }
              else {
                // this.ActionbtnComponent.refresh();
                this.UserPersonalInfoComponent.update(this.EditCommonKey);
              }
              //audit
              this.commonService.createActivityTracker('User', this.userData.userid, 'User', 'Audit', data, this.usersdeatils);
              this._snackbar.open("Updated Successfully", 'Success');
              this.ngxService.stop("1");
            }

          }
        }


      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });
  }
  editGetLiscensureInfo() {
    if (this.pathuserid != undefined) {
      var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "vwGetLicensureInfo");
      let drpqueryRequest: any = {
        "OperationName": null,
        "Query": drpquery[0].Query,
        "Variables": { "userid": this.pathuserid.toString() }
      };
      this.ngxService.start('vwGetLicensureInfo');
      this.vaservices.GetData(drpqueryRequest).subscribe(result => {
        this.ngxService.stop('vwGetLicensureInfo');
        if (!result.errors) {
          if (result.data.submenudata != undefined && result.data.submenudata != null) {
            this.ShowLicensureDetails = true;
            this.StateDiscription = this.editStateList[0].LookUpValue + " (" + this.editStateList[0].Description + ")";
            (<FormArray>this.frm_EditUsers.get('formLicensure')).clear();
            let frm;
            frm as FormGroup;
            frm = this._fb.group({
              frmDrp_LicsenceStates: new FormControl("Select"),
              frmDrp_LicsenceDate: ""
            });

            (<FormArray>this.frm_EditUsers.get('formLicensure')).push(frm);
            //this.ShowLicensureDetails = false;
          }
        }
      }, error => {
        console.info(error);
      });
    }
    else {
      this.ShowLicensureDetails = false
    }
  }
  //Edit role list
  editSavedRoleList(userid, OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.ngxService.start();
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.CommonKey = result.data.submenudata[0].CommonKey;
            if (this.CommonKey == null) {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
        }
        else {
          this.CommonKey = "";
        }
        //get getSavedRoleList
        drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetsavedrole");
        drpqueryRequest = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "Commonkey": this.CommonKey }
        };

        this.vaservices.GetData(drpqueryRequest).subscribe(result => {
          if (!result.errors) {
            if (result.data.submenudata != undefined && result.data.submenudata != null) {
              if (result.data.submenudata.length > 0) {
                this.ArrSavedRoles = result.data.submenudata
                this.ArrSavedRoles.forEach(element => {
                  element.IsPrimary = element.IsPrimary == null ? false : element.IsPrimary;
                  element.status = element.status == 1 ? true : false;
                  element.ModifyTechnicalData = element.ModifyTechnicalData == null ? false : element.ModifyTechnicalData;
                  element.IsModifyTechnicalDataAccession = element.IsModifyTechnicalDataAccession == null ? false : element.IsModifyTechnicalDataAccession;
                  element.ReviewPathCanSignout = element.ReviewPathCanSignout == null ? false : element.ReviewPathCanSignout;
                  element.isMedicalDirector = element.isMedicalDirector == null ? false : element.isMedicalDirector;
                  element.IsUserLevelDiagnosisTemplateEnabled = element.IsUserLevelDiagnosisTemplateEnabled == null ? false : element.IsUserLevelDiagnosisTemplateEnabled;
                  element.ShowMyCaseOnly = element.ShowMyCaseOnly == null ? false : element.ShowMyCaseOnly;
                  element.DiagICDcodeReminder = element.DiagICDcodeReminder == null ? false : element.DiagICDcodeReminder;
                });
                let i;
                for (i = 0; i < this.ArrSavedRoles.length; i++) {
                  if (this.ArrSavedRoles[i].rolename === 'Pathologist') {
                    this.pathuserid = this.ArrSavedRoles[i].userid;
                    this.frm_EditUsers.patchValue(
                      {
                        frmInp_AllowPathologistDiagnosisTemplate: this.ArrSavedRoles[i].IsUserLevelDiagnosisTemplateEnabled,
                        frmInp_ShowMyCaseOnly: this.ArrSavedRoles[i].ShowMyCaseOnly,

                      });
                    break;
                  }
                }
              }
            }
          }
          this.editLocationDetails(OrgId);
        }, error => {
          console.info(error);
        });
      }
    }, error => {
      console.info(error);
    });
  }
  //edit loction list
  editLocationDetails(OrgIDValue: string) {
    let loctaionList = [];
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('adduserlocations');
    queryVariable = { "orgid": OrgIDValue.toString() };
    let accIds: any[];
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vaservices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        let templateData = res.data.vwGetAccountAssociationForUser_list;
        let checkedLoction = this.userData.Userlocation.split(',');
        if (templateData.length > 0) {
          this.showAccounts = true;
          (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).clear();
          let m;
          let tempValue = false;
          for (m = 0; m < templateData.length; m++) {
            let frmAccounts;
            frmAccounts as FormGroup;
            frmAccounts = "userAccounts_" + m;
            for (let j = 0; j < checkedLoction.length; j++) {
              //let templocation=checkedLoction[j];
              let tempArrayData = templateData[m].Account_ID == checkedLoction[j];

              if (tempArrayData == true) {
                frmAccounts = this._fb.group({
                  frmchk_Location: new FormControl(true),
                  frmLblchk_Location: new FormControl(templateData[m].Account_ID),
                  frmDrp_Works: new FormControl('true'),
                  frmDrp_Manages: new FormControl('true'),
                  frmDrp_SendforReview: new FormControl('true'),
                  frmLbl_Location: new FormControl(templateData[m].Display_Name)
                });

                break;
              }
              else {
                frmAccounts = this._fb.group({
                  frmchk_Location: new FormControl(false),
                  frmLblchk_Location: new FormControl(templateData[m].Account_ID),
                  frmDrp_Works: new FormControl('true'),
                  frmDrp_Manages: new FormControl('true'),
                  frmDrp_SendforReview: new FormControl('true'),
                  frmLbl_Location: new FormControl(templateData[m].Display_Name)
                });
              }
            }
            (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).push(frmAccounts);
          }
          if (templateData.length == checkedLoction.length) {
            this.frm_EditUsers.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: true,
            });
          }
          else {
            this.frm_EditUsers.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: false,
            });
          }

          accIds = [];
          templateData.forEach(element => {
            accIds.push(element.Account_ID);
          });
          this.editAssociationTypes(accIds);

        }
        else {
          templateData = "";
          this.showAccounts = false;
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }

  //Association Location Get
  editAssociationTypes(accIDs) {

    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    for (i = 0; i < accIDs.length; i++) {
      if (i == accIDs.length - 1) {
        Qstr += "accountid = \"" + accIDs[i] + "\"";
      }
      else {
        Qstr += "accountid = \"" + accIDs[i] + "\" or ";
      }
    }
    queryVariable = { "accid": Qstr };
    let associationTypes: any[];
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vaservices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              //to get unique associations from arrayF
              associationTypes.push(element.associationtype)
            }
          });
          this.editRoles(associationTypes);
        }
        else {
          this.associationData = "";
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }
  //Edit Role Get
  editRoles(AssociationTypes) {
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    let medicalDirectorvalue;
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vaservices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata = [];
          this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.rolesdata = rolesarray;
          (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).clear();
          let k;
          for (k = 0; k < this.rolesdata.length; k++) {
            let frm;

            frm as FormGroup;
            frm = "userRole_" + k;
            let IsPathologistActive: boolean = false;
            if (this.rolesdata[k].rolename == "Medical Staff")
              this.rolesdata[k].rolename = "Medical Assistant";
            else if (this.rolesdata[k].rolename == "Transcriptionist")
              this.rolesdata[k].rolename = "Pathologist Assistant";
            if (this.rolesdata[k].rolename.toLowerCase() == "pathologist") {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = true;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: this.rolesdata[k].isMedicalDirector, disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: true })
              });
            }
            else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = true;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: true })
              });
            }
            else {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new FormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new FormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new FormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new FormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmLbl_userID: new FormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new FormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new FormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new FormControl({ value: this.rolesdata[k].HoldReleaseDuration, disabled: true }),
                frmChk_IsMedicalDirector: new FormControl({ value: false, disabled: true })
              });

            }
            this.ArrSavedRoles.forEach((e, row_index) => {
              if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
                frm.controls.frmChk_UserRoles_RoleName.value = true;
                frm.controls.frmChk_UserRoles_RoleName.disable(); //for enable checkBox
                frm.controls.frmRd_UserRoles_RoleName.value = '';
                frm.controls.frmRd_UserRoles_RoleName.enable();
                if (frm.controls.frmLblChk_UserRoles_RoleName.value == "cytotechnologist") {
                  this.hidIsCytoSelected = true

                }
                if (frm.controls.frmLblChk_UserRoles_RoleName.value == "cytotechnologist supervisor") {
                  this.hidIsCytoSupervisiorSelected = true

                }
                if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                  if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                    if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager") {
                      frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                      frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                    }
                    else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist") {
                      frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                      frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                    }
                    else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist") {
                      frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                      frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                    }
                    else if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor") {
                      frm.controls.frmInp_RoleHoldreleaseDuration.value = e.HoldReleaseDuration as string;
                      frm.controls.frmInp_RoleHoldreleaseDuration.enable();
                    }
                  }
                }


                if (e.IsPrimary == true) {
                  this.rolename = e.rolename;
                  this.hidPrimaryRole = e.rolename;
                  frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
                }
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  this.Status_value = 1;
                  frm.controls.frmLbl_usrStatus.enable();
                  frm.controls.frmLbl_userID.value = e.userid;
                  if (e.usrStatus.toLowerCase() == 'active') {
                    frm.controls.frmLbl_usrStatus.value = true
                    //  frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                    // frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                  }
                  else {
                    frm.controls.frmLbl_usrStatus.value = false;
                    // frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                    //frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                  }
                }
              }
              if (e.rolename == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                this.frm_EditUsers.patchValue(
                  {
                    frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                    frmInp_EditSite: e.ModifyTechnicalData as boolean,
                    frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                  });

                if (e.DiagICDcodeReminder == null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                  this.frm_EditUsers.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_EditUsers.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                  this.frm_EditUsers.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_EditUsers.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if (e.rolename == "Pathologist") {
                frm.controls.IsPathologistActive = e.STATUS as boolean;
                frm.controls.frmChk_IsMedicalDirector.value = e.isMedicalDirector as boolean;
                medicalDirectorvalue = e.isMedicalDirector as boolean;

              }
            });

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSelected = true;
              this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
            }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSupervisiorSelected = true;
              this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
            }
            let isPatholigistEnabled = false;

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsPathlogistSelected = true;
              isPatholigistEnabled = true;
              this.frm_EditUsers.controls['frmInp_AllowReviewPathologist'].enable();
              this.showfrmInp_AllowReviewPathologist = true;
              this.ShowLicensureDetails = true;

              this.frm_EditUsers.controls['frmInp_AllowtoeditAccession'].enable();
              this.showfrmInp_AllowtoeditAccession = true;
              this.showfrmInp_Threshold = true;

              this.frm_EditUsers.controls['frmInp_EditSite'].enable();
              this.showfrmInp_EditSite = true;
              this.showfrmDrp_ThresholdDuration = true;

              this.frm_EditUsers.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
              this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
              this.showfrmInp_PopulateDiag = true;

              this.frm_EditUsers.controls['frmInp_Notifyusers'].enable();
            }
            /*Flag as Medical Director*/
            if (medicalDirectorvalue == false) {
              if (this.hidIsChecked == true) {
                frm.controls.frmChk_IsMedicalDirector.enable()
              }
              else {
                frm.controls.frmChk_IsMedicalDirector.disable();
              }
              // if (this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false)
              //   this.hidIsMedicalDirector = true;
              // if ((this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false) || !IsPathologistActive) {
              //   frm.controls.frmChk_IsMedicalDirector.disable();
              // }
            }
            else {
              if (this.hidIsChecked == false) {
                frm.controls.frmChk_IsMedicalDirector.enable();
              }
              else {
                frm.controls.frmChk_IsMedicalDirector.enable();
              }
            }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {
              this.frm_EditUsers.controls['frmInp_NPI'].enable();
            }
            if (isPatholigistEnabled) {
              let k;
              for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).length; k++) {

                (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
              }
              this.editSendforReview(true);
            }
            (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).push(frm);
          }
          this.editSendforReview(false);
          //to display hold release duration and  medical director
          this.MD_KValue = -1;
          this.HRD_KValue = -1;
          for (k = 0; k < (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).length; k++) {
            if (this.HRD_KValue == -1 && (<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
              this.HRD_KValue = k;
            }
            if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
              this.rolelablistcount = k
            }
            if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
              this.rolelablistcytcount = k
            }
            if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
              this.rolelablistcyscount = k
            }
            if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
              this.rolelistcount = k;
              if ((<FormGroup>(<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
                this.MD_KValue = k;
                if (this.EPGorgType == 'Pathologists') {
                  this.ShowLicensureDetails = true;
                }
                else {
                  this.ShowLicensureDetails = true;
                }
              } else {
                this.ShowLicensureDetails = false;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          }
          this.showRoles = true;
        }
      }
      else {
        console.error(res.error);
        this.rolesdata = [];
        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          this.ShowMedicalDirector = false;
          this.ShoHoldReleaseDuration = false;
          let frm;
          frm as FormGroup;
          frm = "userRole_" + k;
          frm = this._fb.group({
            frmChk_UserRoles_RoleName: [{ value: false, disabled: false }],
            frmLblChk_UserRoles_RoleName: new FormControl(''),
            frmRd_UserRoles_RoleName: [{ value: '', disabled: false }],
            frmlbl_DisplayName: ''

          });
          (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).clear();
          (<FormArray>this.frm_EditUsers.get('frmArrEditRoles')).push(frm);
        }
        // this.showAccounts = false;
        this.showRoles = false;
      }
      this.editLicensureDetails();
      this.editGetLiscensureInfo();
    }, error => {
      console.error(error);
    });
  }

  editLicensure_array_Func() {
    let flag = false;
    if (this.ShowLicensureDetails == false) {
      flag = true;
      this.ShowLicensureDetails = true;
    }
    if ((<FormArray>this.frm_EditUsers.get('formLicensure')) == undefined || (<FormArray>this.frm_EditUsers.get('formLicensure')).length <= 0) {
      let frm;
      frm as FormGroup;
      frm = this._fb.group({
        frmDrp_LicsenceStates: new FormControl(this.StateDiscription),
        frmDrp_LicsenceDate: new Date()
      });

      (<FormArray>this.frm_EditUsers.get('formLicensure')).push(frm);
      if (flag == true) {
        this.ShowLicensureDetails = false;
        flag = false;
      }
      return this.frm_EditUsers.controls.formLicensure.value;
    }
    if (flag == true) {
      this.ShowLicensureDetails = false;
      flag = false;
    }
    return this.frm_EditUsers.controls.formLicensure.value;

    // *ngFor="let userLicensure of frm_AddUsergroup.controls.formLicensure.controls; let userLicensure_i = index"
    // return this.Licensure_array;
  }
  editlicensure(value: string, index: number) {
    if (this.ShowLicensureDetails == true) {
      if (value == "add") {
        const frm = this._fb.group({
          frmDrp_LicsenceStates: new FormControl(this.StateDiscription),
          frmDrp_LicsenceDate: new Date()
        });

        (<FormArray>this.frm_EditUsers.get('formLicensure')).push(frm);
      }
      else if (value == "delete") {
        if ((<FormArray>this.frm_EditUsers.get('formLicensure')).length > 1) {
          (<FormArray>this.frm_EditUsers.get('formLicensure')).removeAt(index);
        }
      }
    }
  }
  editThresholdDuration() {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserthresholdduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.ngxService.start();
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.data.submenudata != null && result.data.submenudata != undefined) {
          if (result.data.submenudata.length > 0) {
            this.ArrThresholdDuration = result.data.submenudata;
          }
          else {
            this.frm_EditUsers.patchValue({
              frmDrp_ThresholdDuration: this.usersdeatils.ThresholdDuration
            });
            this.ArrThresholdDuration = [];
          }
        }
        else {
          this.frm_EditUsers.patchValue({
            frmDrp_ThresholdDuration: this.usersdeatils.ThresholdDuration
          });
          this.ArrThresholdDuration = [];
        }
      }
    }, error => {
      console.info(error);
    });
  }
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  getIsMedicalDirectorvalue() {
    var IsMedicalDirectorExists_isMedicalDirector = false;
    var IsMedicalDirectorExists_status;
    var IsMedicalDirectorExists_userName;
    var IsMedicalDirectorExists_commonKey;
    var IsMedicalDirectorExists_userID;
    this.vaservices.GetIsMedicalDirectorExists(this.userData.Organizationid, this.userData.userid).subscribe(data => {
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }

      if (IsMedicalDirectorExists_isMedicalDirector == true) {
        this.hidIsChecked = false;
      }
      else {
        this.hidIsChecked = true;

      }
    }, error => {
      console.error(error);
    });
  }
  ActivateOrInactivate(obj, UserID_value) {
    let value;
    if (obj.target.checked == false) {
      value = 'Inactive';
    }
    else {
      value = 'active';
    }
    this.userstatus = ((UserID_value.controls.frmLbl_userID.value) + '|' + (value));
    this.statuslist.push(this.userstatus);

  }

  UpdateUserStatus() {
    this.ngxService.start("1");
    let list = "";
    for (var i = 0; i < this.statuslist.length; i++) {
      list += this.statuslist[i] + ',';

    }
    var obj = {
      "User": list,
      "modifiedBy": sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : "-100"
    }
    this.vaservices.Updateuserstatus(obj).subscribe(result => {
      this.ngxService.stop("1");
      if (!result.errors) {
        console.log("result");
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });

  }



  //#endregion
}
