<div class="container-fluid p-0" style="margin-left: -15px;margin-right: 10px;">
    <div class="row p-0 m-0" style="background-color: #a5cedc!important; font-weight: 600!important;">
        <div class="col-12 p-0 m-0" style="padding-left: 10px !important;">
            VitalDesktop Information
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 m-md-0 m-lg-0 p-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important;">
                    VitalDesktop Identifier:
                </div>
                <div class="col-md-6 m-md-0 m-lg-0 p-0">
                    <input type="text" [(ngModel)]="vitalDesktopIdentifier" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    VitalAxis URL:
                </div>
                <div class="col-md-6 m-md-0 m-lg-0 p-0">
                    <input type="text" [(ngModel)]="vitalaxisUrl" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    VitalAxis Data Handler URL:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="VitalAxisDataURL" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    VitalAxis Login Page
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="vitalaxisLoginPg" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Is Active:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="checkbox" style="cursor: pointer;" [checked]="isActive" [disabled]="isDisabled">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Template Version:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <select style="cursor: pointer;" (change)="Method($event)" [(ngModel)]="templatedata" style="padding: 1px !important;
                    border: 1px solid darkgrey;
                    border-radius: 3px;
                    width:100%">
                        <option *ngFor="let drp of drpdwnValues;" Value="templatesetData" style="font-size: 11pt; font-weight: 500;">{{drp.TemplateFolder}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Is Auto Upgrade Completed:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="checkbox" [checked]="isAutoUpgradeCompleted" [disabled]="isDisabled">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Auto-Upgrade Completed Date Time:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="AutoUpgradeCompletedDateTime" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Latest Version Number:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="latestVersion" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0 m-0" style="background-color: #a5cedc!important; font-weight: 600!important;">
        <div class="col-md-12 p-0 m-0" style="padding-left:10px !important;">
            <span style="font-weight: bolder; text-align: right;">Additional Features</span>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Customer Name:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="customerName" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Printer Type:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="printerType" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Point of Contact:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="pointofContact" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Contact Number:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="contactNumber" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Requested By:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="RequestedBy" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Is Facility:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="checkbox" [(ngModel)]="isFacility">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    On Time TIcket:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="onTimeTicket" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Group ID:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="organizationiD" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Customer Type:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="customerType" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Computer Location:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="computerLocation" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Computer Name:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="computerName" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    System OS:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="systemOS" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    System IP:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="systemIP" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Admin Login Name:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="adminLoginName" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    VDVersion:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="vdVersion" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Labels used on this Computer:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="labesUsedonComp" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Time consumed for Installation:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="timeConsumedforInstallation" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Installation Status:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="installationStatus" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Environment Key:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="environmentKey" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    API Key:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="APIKey" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2 mx-0 mt-0">
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    Sender Agent Id:
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <input type="text" [(ngModel)]="environmentKey" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md-6 p-1 m-md-0 m-lg-0">
            <div class="row m-0 p-1">
                <div class="col-md-5 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
                    <!-- Installation Status: -->
                </div>
                <div class="col-md-6 p-0 m-md-0 m-lg-0">
                    <!-- <input type="text"  > -->
                </div>
            </div>
        </div>
    </div>
    <br/>
    <div class="row mb-2 mx-0 mt-0">
        <div class="col-md-2 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
            Specific Confgn:
        </div>
        <div class="col-md-9 p-0 m-md-0 m-lg-0">
            <textarea [(ngModel)]="SpecificConfgn" class="form-control" rows="5"></textarea>
        </div>
    </div>
    <div class="row mb-3 mx-0 mt-0">
        <div class="col-md-2 p-0 m-md-0 m-lg-0" style="font-weight: bolder; text-align: right; padding-right: 1rem !important; ">
            Comments:
        </div>
        <div class="col-md-9 p-0 m-md-0 m-lg-0">
            <textarea [(ngModel)]="comments" class="form-control" rows="5"></textarea>
        </div>
    </div>
    <div class="row mb-1 mx-1">
        <div class="col-md-8">&nbsp;</div>
        <div class="col-md-4 p-0 m-md-0 m-lg-0" style="display: table;">
            <div style="display: table-cell;vertical-align: middle;">
                <button mat-raised-button class="admin-btn-success" [disabled]="ifSave" type="button" (click)="addVDID()">Save</button> &nbsp;&nbsp;&nbsp;
                <!-- <button class="btn btn-sm cnclbtn-color"  type="button" (click)="removeTab()">Back</button> -->
            </div>
        </div>
    </div>
</div>
