import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { error } from 'console';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'app-manage-by-users',
  templateUrl: './manage-by-users.component.html',
  styleUrls: ['./manage-by-users.component.scss']
})
export class ManageByUsersComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ManageByUsersComponent>,
    private _vitalHttpService: VitalHttpServices,
    private _fb: FormBuilder,
    public _snackBar: MatSnackBar,
    public _ngxloader: NgxUiLoaderService,
    private _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  usersList$: any
  searchSubject = new BehaviorSubject({ searchtext: '' })
  searchSubject$ = this.searchSubject.asObservable()
  disableButtonOnActon : Boolean = false
  isDataLoading : Boolean = false

  ngOnInit(): void {
    this._ngxloader.start()
    this.isDataLoading = true;
    this.usersList$ = combineLatest([this.data.usersList$, this.searchSubject$]).pipe(
      map(([data, filterText]: any) => {
        if (data && data.length) {
          this._ngxloader.stop()
          this.isDataLoading = false;
          return filterText.searchtext != '' ? data.filter((i: any) => i.username.toLowerCase().includes(filterText.searchtext.toLowerCase()) || i.rolename.toLowerCase().includes(filterText.searchtext.toLowerCase()) || i.departmentIds?.toLowerCase().includes(filterText.searchtext.toLowerCase())) : data
        }
        this._ngxloader.stop()
        this.isDataLoading = false;
        return data
      })
    )
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }


  tooTipText: string = '';

  formUserDetails = this._fb.group({
    OrganizationID: this.data.OrganizationID,
    LstUsers: this._fb.array([])
  })

  get TemplateUsers() {
    return this.formUserDetails.get('LstUsers') as FormArray;
  }

  textChanged(event: string) {
    this.searchSubject.next({ searchtext: event })
  }
  createUser(item: any, value: boolean): FormGroup {
    return this._fb.group({
      commonKey: item.commonKey,
      departmentIds: item.departmentIds,
      isCustomlistingEnabled: value,
      username: item.username,
      rolename: item.rolename
    });
  }

  onNoClick(data: any) {
    if (this.formUserDetails.controls.LstUsers.length > 0) {
      this.disableButtonOnActon = true
      this._dialogService.openDialog('Alert', `All your changes will be lost. Do you want to proceed?`, '', 'Proceed', 'Cancel').afterClosed().subscribe({
        next: (res) => {
            if(res) this.dialogRef.close(data)
            else this.disableButtonOnActon = false
          }
      })
    }
    else this.dialogRef.close(data)
  }

  toolTipshow(element) {
    this.tooTipText = element.scrollWidth > element.clientWidth ? element.textContent : '';
  }

  onSave() {
    if (this.formUserDetails.controls.LstUsers.length > 0) {
      this.disableButtonOnActon = true
      this._dialogService.openDialog('Alert', `The selected user(s) cannot be switched back to Group Level. Are you sure you want to make this change?`, '', 'Proceed', 'Cancel').afterClosed().subscribe({
        next: (res) => {
          if (res) {
           
             this._ngxloader.start()            
             this._vitalHttpService.saveTemplateUsers(this.formUserDetails.value).subscribe((data: any) => {
              if (data) {
                this._ngxloader.stop()
                this._snackBar.open('Data updated successfully', 'close')
                this.dialogRef.close(this.formUserDetails.value);
              }
            },
              error => this._ngxloader.stop())
          }
          else this.disableButtonOnActon = false
        }
      })
    }
    else this.dialogRef.close(this.formUserDetails.value);
  }

  setFormArray(value: any, item: any, i: any) {
    if (value) {
      this.TemplateUsers.push(this.createUser(item, value))
    }
    else {
      let index = this.TemplateUsers.value.findIndex((u: any) => u.username === item.username);
      this.TemplateUsers.removeAt(index)
    }
  }

}

