<div *ngIf="searchAttr || showGrid ">
    <div class="modal-header admin-model-header  mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div>Attributes</div>
            </strong>
        </h3>
    </div>
    <div *ngIf="searchAttr">
        <div class="col-sm-12 pl-0 gross-grid">
            <div class="row">
                <mat-form-field class="col-sm-6 mt-2 pr-0 input-hight gross-attr" appearance="outline">
                    <mat-label>Attributes</mat-label>
                    <input type="text" #txtInput aria-label="Assignee" placeholder="Search..." matInput
                        [formControl]="configname" [matAutocomplete]="auto2">
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option class="mat-opt-align p-2" id="frmMatOption"
                            *ngFor="let option of filteredOptions | async"
                            value="{{ option.name }} ({{ option.refconfigname }})"
                            (onSelectionChange)="getOrgAttributeOfDropdown(option.refconfigname)" autoActiveFirstOption
                            title="{{ option.name }} ({{ option.refconfigname }})">
                            {{ option.name }} ({{ option.refconfigname }})
                        </mat-option>
                    </mat-autocomplete>
                    <button matSuffix mat-icon-button><em class="fa fa-chevron-down chevron-align"
                            style="position: relative;;margin-bottom: 6px;"></em></button>
                    <button *ngIf="configname.value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                        (click)="fnToggleFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <!-- </div> -->
        </div>
        <div *ngIf="searchAttr && showGrid && templateData.submenuData && templateData.submenuData.length > 0 && !nodataFoundEnable"
            class="col-sm-12 p-0">
            <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" class="mt-2"
                [headersVisibility]="'Column'" (initialized)="initGrid(grid)" #grid>
                <wj-flex-grid-column [header]="'Action'" align="center"  [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button *ngIf="showEditIconInGrid(row)" class="edit-delete-btn"><em id="editButton"
                                [title]="'Update'" (click)="updateOrgAttributes(row)"
                                class="edit-template important-text p-0 fa fa-edit"
                                [disabled]="hideEditBtn"></em></button>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Type'" [width]="'*'" [binding]="'Attribute_Type'" [isReadOnly]="true">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Name'" [width]="'*'" [binding]="'Attribute_Name'" [isReadOnly]="true">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Sequence'" [width]="'*'" [binding]="'Sequence_Order'"
                    [isReadOnly]="true">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Status'"  [width]="'*'" [binding]="'Status'" [isReadOnly]="true">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Configuration Level'" [width]="'*'"  [binding]="'Attribute_Level'"
                    [isReadOnly]="true">
                </wj-flex-grid-column>
                <!-- <div *ngFor="let columnname of gridheader">
                    <wj-flex-grid-column [binding]="columnname" [visible]="!(columnname == 'Attribute_ID') &&! (columnname == 'Attribute_Context_Id')
                && !(columnname == 'Attribute_Description') && !(columnname == 'Attribute_Value') &&
                !(columnname == 'Attribute_Service_Type') && !(columnname == 'Attribute_Context_Id_2')
                && !(columnname == 'Attribute_Context_Id_3') && !(columnname == 'Attribute_Context')"
                        [allowDragging]="false" [header]="columnname.split('_').join(' ')" [width]="'*'"
                        [allowResizing]="true" [format]="'d'">
                    </wj-flex-grid-column>

                </div> -->

                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            <div class="page-align">
                <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                    headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                </wj-collection-view-navigator>
                <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'"
                    *ngIf="showPaginationMainGrid">
                    <wj-menu-item [value]="0">No Paging</wj-menu-item>
                    <wj-menu-item [value]="10">10</wj-menu-item>
                    <wj-menu-item [value]="15">15</wj-menu-item>
                    <wj-menu-item [value]="30">30</wj-menu-item>
                    <wj-menu-item [value]="50">50</wj-menu-item>
                </wj-menu>
            </div>
            <div class="submitButton-Align mt-4">
                <div class="col-sm-12 button-align mt-3 p-0">
                    <div class="p-0">
                        <button mat-raised-button class="admin-btn-success mr-4" (click)="exportOrgAttributesData(grid)"
                            [disabled]="hideExportBtn">
                            Export
                        </button>

                        <button mat-raised-button class="admin-btn-success mr-4" (click)="createOrgAttribute()"
                            [disabled]="hideCreateBtn">
                            Create
                        </button>

                        <button mat-raised-button class="admin-btn-success" *ngIf="showModal" (click)="getGLDataPopup()"
                            [disabled]="hideCreateBtn" title="Migrate default to group level">
                            Migrate
                        </button>
                    </div>

                    <!-- <div class="col-sm-5 p-0">
                        <h3 class="modal-title w-100 admin-model-header-txt main-title">
                            <strong class="configtext" *ngIf="showModal" (click)="getGLDataPopup()" data-toggle="modal"
                                data-target="#configmodal">Migrate default to group
                                level</strong>
                       </h3>
                    </div> -->
                </div>

            </div>
        </div>
        <div *ngIf="searchAttr && showGrid && (!templateData.submenuData || templateData.submenuData.length <= 0) || nodataFoundEnable"
            class="col-sm-12">
            <div class="nodata-wrap mt-4">
                <div class="nodata-design">No Data Found</div>
            </div>
            <div class="align-center mt-2">
                <button mat-raised-button class="admin-btn-success" (click)="createOrgAttribute()"
                    [disabled]="hideCreateBtn">
                    Create
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="addEditScreen">
    <form [formGroup]="orgAttributeForm" class="Gross-Form">
        <div class="row col-sm-12 pl-0 pr-0 mb-2 ml-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>{{action}}</strong>
                </h3>
            </div>
            <div class="text-wrap col-sm-12 row pb-3">
                <div class="col-sm-12 mt-4 d-flex attr-align">
                    <mat-form-field class="width col-sm-3 ml-1" appearance="outline">
                        <mat-label>
                            <strong><span class="text-danger">*</span>
                                Type</strong>
                        </mat-label>
                        <mat-error>{{getErrorMessage(orgAttributeForm.value.frmAttrType,'')}}</mat-error>
                        <mat-select [disabled]="orgAttributeForm.value.frmAttrType" disableOptionCentering
                            formControlName='frmAttrType'>
                            <mat-option class="mat-opt-align p-2" [disabled] *ngFor="let i of this.filteredOptions  | async"
                            [value]="i.refconfigname" (onSelectionChange)="onChangeAttrType($event)">
                            {{ i.name }} ({{ i.refconfigname }})
                            </mat-option>
                        </mat-select>   
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100 col-sm-3">
                        <mat-label>
                            <strong> Name</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="2000" formControlName="frmAttrName"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Sequence</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="5"
                            [readonly]="!action.includes('Edit') && readonlyTestSequence"
                            [(ngModel)]="orgAttributeForm.value.frmSeqOrder" formControlName="frmSeqOrder"
                            pattern="[0-9]+" (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                    </mat-form-field>
                    <span class="col-sm-3">
                        <mat-checkbox [color]="task.color" class="mt-3"
                            formControlName="frmIsActive">Active</mat-checkbox>
                    </span>
                </div>
                <div *ngIf="attributeFields.length>0" class="col-sm-12 row ml-1 pt-4">
                    <div *ngFor="let i of attributeFields" class="col-sm-3 p-0 m-0">
                        <div *ngIf="i.datatype=='freetext'">
                            <mat-form-field appearance="outline" class="col-sm-12">
                                <mat-label>
                                    <strong>{{i.fieldname}}</strong>
                                </mat-label>
                                <input type="text" matInput formControlName="{{i.columnname}}"
                                    [errorStateMatcher]="matcher">
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeContextId'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeContextId}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filterAttrContext1 | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeContext'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input type="text" title="{{orgAttributeForm.value.AttributeContext}}"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto6">
                                <mat-autocomplete #auto6="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filterAttrContext | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeDescription'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeDescription}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto1">
                                <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filteredDesc | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeValue'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeValue}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto2">
                                <mat-autocomplete #auto2="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filteredAttrValue | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeServiceType'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeServiceType}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto3">
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filteredServicesType | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeContextId2'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeContextId2}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto4">
                                <mat-autocomplete #auto4="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filteredAttrContext2 | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngIf="i.datatype=='lookup' && i.columnname=='AttributeContextId3'">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label><strong>{{i.fieldname}}</strong></mat-label>
                                <i class="fa fa-chevron-down chevron-align"></i>
                                <input title="{{orgAttributeForm.value.AttributeContextId3}}" type="text"
                                    aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="{{i.columnname}}" [matAutocomplete]="auto5">
                                <mat-autocomplete #auto5="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  filteredAttrContext3 | async"
                                        value="{{ option.name }} ({{ option.id }})" autoActiveFirstOption
                                        title="{{ option.name }} ({{ option.id }})">
                                        {{ option.name }} ({{ option.id }})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <p *ngIf="globalIndicater">
                    <img class="mr-1 ml-1 mb-1" alt="help" src="../../../assets/icons/help.svg" width="15px"
                        height="auto">
                    <strong>All the attributes at "Global" level will be copied to "All locations".</strong>
                </p>
            </div>
        </div>
        <span>
            <strong>
                <span>Note:</span>
            </strong> Do not enter duplicate sequence number
        </span>
        <div class="row mt-1">
            <div class="col-sm-3">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="saveOrgAttributes('',orgAttributeForm.value)">
                    Save & Close
                </button>
                <button mat-raised-button class="admin-btn-success" *ngIf="action == 'Create Group Attribute'"
                    (click)="saveOrgAttributes('new',orgAttributeForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="showConfirmPopupFlag" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message">
                        <p style="text-align: start;">{{ PopUpmsgs(locations)?.[showConfirmPopupFlag]?.['message'] }}</p>
                        </div>
                </div>
                <div class="modal-footer">
                    <button mat-flat-button class="admin-btn-success" *ngIf="showConfirmPopupFlag==1||showConfirmPopupFlag==3" (click)="redirectToSequenceSchemeOnSave()">
                        Redirect to Sequence Scheme
                      </button>
                    <button mat-flat-button *ngIf="showConfirmPopupFlag==1 ||showConfirmPopupFlag==3" class="admin-btn-success" (click)="showConfirmPopupFlag = undefined">
                        Close
                      </button>
                      <button mat-flat-button *ngIf="showConfirmPopupFlag==2" class="admin-btn-success" (click)="showConfirmPopupFlag = undefined">
                        Ok
                      </button>
                </div>
            </div>
        </div>
    </div>
  </div>

