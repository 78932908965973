import { Directive, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { Tabset } from './tabset.component';

@Directive({ selector: 'tab, [tab]' })
export class Tab  {
    @Input() public TabName: string;
    @Input() public TabGUID: any;
    @Input() public disabled: boolean;
    @Input() public Removable: boolean;
    @Input() public Count:number;
    @Input() public ClearTabs:number;
    @HostBinding('class.active')
    @Input() public get active() {
        return this._active;
    }

    @Output() public select: EventEmitter<Tab> = new EventEmitter();
    @Output() public deselect: EventEmitter<Tab> = new EventEmitter();
    @Output() public removed: EventEmitter<Tab> = new EventEmitter();

    public set active(active) {
        if (this.disabled && active || !active) {
            if (!active) {
                this._active = active;
            }

            this.deselect.emit(this);
            return;
        }

        this._active = active;
        this.select.emit(this);
        this.tabset.tabs.forEach((tab: Tab) => {
            if (tab !== this) {
                tab.active = false;
            }
        });
    }

    @HostBinding('class.tab-pane') public addClass = true;

    public _active: boolean;
    public headingRef: TemplateRef<any>;

    constructor(private tabset: Tabset) {
        this.tabset.addTab(this);
        
    }

    ngOnInit() {
        this.Removable = !!this.Removable;
        
    }

 

    // ngOnChanges(changes:SimpleChanges){
     
    // }
}
