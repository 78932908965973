import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../../../core/services/datashare.service';
import { UserIP } from '../../../model/user-ip';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'template-AddIPAddress',
  templateUrl: 'AddIPAddress.component.html',
  styleUrls: ['./AddIPAddress.component.scss']
})

export class AddIPAddressComponent {
  @Input() selecteddefaultdeploymentkey: any;
  defaultdeploymentkey: string;
  public templateData;
  public DeploymentKeys = [];
  //User Ip model
  ipPattern =
    "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";

  UserIpgroup = new FormGroup({
    DeploymentKey: new FormControl('', Validators.required),
    IPAdd: new FormControl('', [Validators.required, Validators.pattern(this.ipPattern)]),
    // CountryName: ['', Validators.required],
    // CountrySName: ['', Validators.required]
  });
  isselecteddefaultkey: boolean;
  
  // UserIpgroup = this._fb.group({
  //   DeploymentKey: ['', Validators.required],
  //   IPAdd: ['', [Validators.required, Validators.pattern(this.ipPattern)]]
  //   // CountryName: ['', Validators.required],
  //   // CountrySName: ['', Validators.required]
  // })
  
  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,public datashare: DataShareService,
    public _snackBar: MatSnackBar) {
    this.UserIpgroup.patchValue(
      {
        DeploymentKey: "none"
      });
      this.DeploymentKeys = this.vaservices.DeploymentKeys

  }

  
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.selecteddefaultdeploymentkey = this.commonService.selectedDefaultDeployment();
    // console.log(this.defaultdeploymentkey)
  }

  SelectedDeploy(deployValue) {
  }

  fnCheckRegion(ipValue){
    let ipAddress = ipValue.IPAdd;
    var newWindow = window.open(environment.IpRegionCheck + ipAddress,
      "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 ");
  }

  insertUserIP(userIPValue) {
    //let IPAddr = sessionStorage.setItem('deploymentKey', userIPValue.DeploymentKey.trim());
    if(this.selecteddefaultdeploymentkey ==  userIPValue.DeploymentKey.trim() && this.selecteddefaultdeploymentkey.toLowerCase() !='all'){
      userIPValue.DeploymentKey = this.selecteddefaultdeploymentkey;
    }else{
      if(userIPValue.DeploymentKey.trim()!=="none"){
        userIPValue.DeploymentKey = userIPValue.DeploymentKey.trim(); 
      }else{
        userIPValue.DeploymentKey = this.selecteddefaultdeploymentkey; 
      }
    }

    
    // var ipPost = {
    //   IPAdd: userIPValue.IPAdd,
    //   CountryName: "USA",
    //   CountrySName: "US"
    // }
    // CountryName: userIPValue.CountryName,
    // CountrySName: userIPValue.CountrySName
    const ipPost = new UserIP(userIPValue.IPAdd, "USA", "US");
    this.ngxService.start();
    this.vaservices.AddIPAddress(ipPost, userIPValue.DeploymentKey.trim()).subscribe(result => {
      this.ngxService.stop();
      let data=result.content;
      if(result.status.toLowerCase()=='success'){ 
          this.UserIpgroup.reset();
          // $('#UserIpModel').modal('hide');
          $('#genericModal').modal('hide');
          this._snackBar.open(data, 'Success');
          this.UserIpgroup.patchValue(
            {
              DeploymentKey: "none"
            });
          //this.setTimeout();
          //sessionStorage.setItem("id_token", data['token']);
          //this.usersession.authtoken = data['token'];
        
        }else {
          this._snackBar.open(data, 'Failed');
          console.info("error");
      }
    }, error => {
      this.ngxService.stop();
      console.info(error);
      this._snackBar.open(error.statusText, 'Failed')
    })
  }

  // public GetQuery(keyword: string) {
  //   let query: string = "";
  //   let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
  //   if (mainQueryIndex > -1)
  //     query = this.vaservices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }
  CloseUsermodal() {
    this.UserIpgroup.reset();
    this.UserIpgroup.patchValue(
      {
        DeploymentKey: "none"
      });
  }

}
