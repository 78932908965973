<div class="p-3">

  <div class="mb-2">
    <div class="label_border _600">
      Notes
    </div>

    <div class="mb-2">
      <div class="description_container p-2">
        Highly active question. Earn 10 reputation (not counting the association bonus) in order to answer this
        question. The reputation requirement helps protect this question from spam and non-answer activity
      </div>
    </div>

  </div>

  <div class="mb-2 ">
    <div class="label_border _600">
      Documents
    </div>

    <div class="mb-2">
      <div class=" attachment_container pt-2 px-2">
        <div class="row">
          <div class="col-sm-6 " *ngFor="let item of attachmentList">
            <div class="attachments ml-2 mb-2 p-1 px-2  d-flex justify-content-between">
              <div class="_600">{{item.name}}</div>
              <div class="text-sm">{{item.date}} <span><i class="fa fa-eye ml-3 _600 text-sm" aria-hidden="true"></i></span> </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
