import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, Input, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ThemePalette, _MatOptionBase } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { SetupNewLocationComponent } from '../setup-new-location/setup-new-location.component';
import { SetupNewEntitiesComponent } from '../setup-new-entities/setup-new-entities.component';
import { SetupNewFacilityComponent } from '../setup-new-facility/setup-new-facility.component';

declare var $;

export interface Task {
  color: ThemePalette;
}

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  casetype: string;
  ChildOrderName: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  selected: boolean;
  casetype: string;
  ChildOrderName: string;
}
interface OrderCodes {
  name: string;
  parentName?: string;
  selected?: boolean;
  parent: any;
  grandParentCodeName?: string;
  indeterminate?: boolean;
  children?: OrderCodes[];
}


@Component({
  selector: 'app-casetype-configuration',
  templateUrl: './casetype-configuration.component.html',
  styleUrls: ['./casetype-configuration.component.scss']
})



export class CasetypeConfigurationComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  SubMenuCardModel: any;
  categoryList: any = [];
  searchCaseType: string = '';
  checkAllCaseType: boolean;
  categoryListCopy: any = [];
  selectedAccount: string = '';
  labList: any = [];
  showCasetype: boolean;
  filterCaseTypeData: any = [];
  organizationID = sessionStorage.getItem('org_id');
  acccountID = sessionStorage.getItem('AccountID');
  selectedCaseTypes: any = [];
  selectedAccCopy: string;
  alertPopUp: boolean = false;
  selectedData: any;
  savedCaseTypes: any = [];
  ifCaseTypesSaved: boolean = false;
  selectedValue: string;
  orderCodeSavedList: any = [];
  orderCodeList: any = [];
  resultSetList: any = [];
  instrumentsList: any = [];
  orderPopup: boolean;
  searchString: string;
  orderCodeListCopy: any = [];
  resultSetListCopy: any = [];
  instrumentsListCopy: any = [];
  caseType: any;
  savedOrderCodesCasetype: any = [];

  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl = new NestedTreeControl<OrderCodes>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<OrderCodes>();
  orderCodeData: any;
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  checkAllInstruments: boolean = false;
  checkAllPanel: boolean = false;
  resultList: any[];
  closedModal: boolean;
  resultSetSavedList: any = [];
  instrumentsListSavedList: any = [];
  errorAlertSpan: string;
  caseTypesSaved: boolean = false;
  @Input()
  mode: any
  @Input()
  source: any
  @Input()
  accountid: any
  @Input()
  editAccountid: any
  @Input()
  editOrganizationID: any
  @Input()
  contextValue: any

  //Optional input params 
  @Input() 
  isPOLOrg : boolean = false;
  @Input() 
  entityType : string = 'Lab';


  configuredCaseTypes: any = [];
  configuredCaseTypesClone: any = [];
  @ViewChild('scrollMe') public myScrollContainer: ElementRef;
  caseTypePopup: boolean = false;
  currentAccountid: any;
  currentOrgID: any;
  treeStructures: any = {};
  savedResults: any = {};
  savedInstruments: any = {};

  constructor(private ngxService: NgxUiLoaderService,
    public tabService: VitalAdminTabService, public vitalHttpServices: VitalHttpServices, public datashare: DataShareService, private setupLoc: SetupNewLocationComponent, private setupEntity: SetupNewEntitiesComponent, private setupEPGFAc: SetupNewFacilityComponent,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private _fb: FormBuilder, public vaservices: VitalHttpServices) {

    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, node: OrderCodes) =>
    !!node.children && node.children.length > 0;

  setParent(data, parent) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x) => {
        this.setParent(x, data);
      });
    }
  }

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children ?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };


  async ngOnInit() {
    if (this.mode == 'edit' && this.accountid != 0) {
      await this.fetchExistingCaseTypes();
    }

    this.getLabsUnderOrganization(this.organizationID);
  }

  async fetchExistingCaseTypes() {
    let query = this.vaservices.GetQuery('getAccountCasetypes');
    let queryVariable = {
      accid: this.accountid ? this.accountid.toString() : this.editAccountid.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let data = await this.vaservices.GetData(queryResult).toPromise();
    this.ngxService.stop();
    if (data && data.data.submenuData) {
      this.configuredCaseTypes = data.data.submenuData;
      this.configuredCaseTypes.forEach(va => va.checked = va.IsActive = va.IsActive ? va.IsActive : false);
      this.configuredCaseTypesClone = cloneDeep(this.configuredCaseTypes)
      if (this.mode == 'edit' && this.selectedAccount) {
        let accountid = this.selectedAccount == 'Default Configuration' ? 60006 : Number(this.selectedAccount.toString().toLowerCase().split('(')[1].split(')')[0]);
        let organizationid = this.selectedAccount == 'Default Configuration' ? 190996 : Number(sessionStorage.getItem('org_id'));
        this.currentAccountid = accountid
        this.currentOrgID = organizationid
        this.fetchCaseTypes(organizationid, accountid);
      }
    }
  }


  getCaseTypeList(value: String) {
    let casetypeList = [];
    for (let i = 0; i < this.categoryList.length; i++) {
      for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
        casetypeList.push(this.categoryList[i].caseTypes[j].casetype);
      }
    }
    if (!value) {
      return casetypeList;
    }
    return casetypeList.filter(va => va.toString().toLowerCase().includes(value.toLowerCase()))
  }

  filterCasetypes(caseType: string) {
    this.searchCaseType = caseType;
    this.categoryList = cloneDeep(this.filterCaseTypeData)

    if (!caseType) {
      return this.categoryList
    }
    this.categoryList.forEach((item: any) => {
      item.caseTypes = item.caseTypes.filter((res: any) => {
        return res.casetype.toString().toLowerCase().includes(caseType.toString().toLowerCase());
      })
    })

    this.categoryList = this.categoryList.filter((item) => {
      return item.caseTypes.length > 0
    })
    return this.categoryList;
  }

  checkAllCasetypes(event) {
    //Checks all Category
    this.checkAllCaseType = !this.checkAllCaseType;
    this.categoryList.forEach(element => {
      element.checked = event.checked;
      this.checkOnlyCategory(event, element.Category);
    });
    this.equateCaseTypeValues();
    this.categoryListCopy = cloneDeep(this.categoryList);
  }

  checkCategory(event, category) {
    //Checks all Casetypes
    this.checkOnlyCategory(event, category);
    this.categoryAllChecked();
    this.equateCaseTypeValues();
    this.categoryListCopy = cloneDeep(this.categoryList);
  }


  checkCasetypes(event, category, casetype) {
    //Checks seleted Casetype
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].Category == category) {
        for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
          if (this.categoryList[i].caseTypes[j].casetype == casetype) {
            if (!this.categoryList[i].caseTypes[j].checked) {
              //when casetype is selected
              this.categoryList[i].caseTypes[j].checked = !this.categoryList[i].caseTypes[j].checked;
              this.categoryList[i].checked = true;
            }
            else {
              //when casetype is deselected
              this.categoryList[i].caseTypes[j].checked = !this.categoryList[i].caseTypes[j].checked;
              //check if other casetypes in the same category are selected
              this.categoryList[i].checked = false;
              for (let k = 0; k < this.categoryList[i].caseTypes.length; k++) {
                if (this.categoryList[i].caseTypes[k].checked) {
                  this.categoryList[i].checked = true;
                  break;
                }
              }
            }
            break;
          }
        }
        break;
      }
    }
    //this.casetypesAllChecked(category);
    this.categoryAllChecked();
    this.equateCaseTypeValues();
    // this.categoryListCopy = cloneDeep(this.categoryList);
  }

  categoryAllChecked() {
    let checkFlag = true;
    //Find if all Categories are checked
    for (let i = 0; i < this.categoryList.length; i++) {
      for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
        if (!this.categoryList[i].caseTypes[j].checked) {
          checkFlag = false
        }
      }
    }
    this.checkAllCaseType = checkFlag
  }

  equateCaseTypeValues() {
    for (let i = 0; i < this.filterCaseTypeData.length; i++) {
      for (let j = 0; j < this.categoryList.length; j++) {
        if (this.filterCaseTypeData[i].Category == this.categoryList[j].Category) {
          if (this.categoryList[j].checked) {
            this.filterCaseTypeData[i].checked = true;
          }
          else {
            this.filterCaseTypeData[i].checked = false;
          }
        }
        for (let k = 0; k < this.filterCaseTypeData[i].caseTypes.length; k++) {
          for (let l = 0; l < this.categoryList[j].caseTypes.length; l++) {
            if (this.filterCaseTypeData[i].caseTypes[k].casetype == this.categoryList[j].caseTypes[l].casetype) {
              if (this.categoryList[j].caseTypes[l].checked) {
                this.filterCaseTypeData[i].caseTypes[k].checked = true;
              }
              else {
                this.filterCaseTypeData[i].caseTypes[k].checked = false;
              }
            }
          }
        }
      }
    }
  }

  checkOnlyCategory(event, category) {
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].Category == category) {
        this.categoryList[i].checked = event.checked;
        let checkCategory = this.categoryList[i].checked;
        this.categoryList[i].caseTypes.forEach(element => {
          if (!element.disabled) {
            element.checked = checkCategory;
          }
        });
        break;
      }
    }

  }
  checkPartialSelection(item) {
    return item.caseTypes.some(va => va.checked) && !item.caseTypes.every(va => va.checked)
  }


  checkAllSelection(item) {
    return item.caseTypes.every(va => va.checked);
  }

  clearSearchBar(evt: any, trigger: MatAutocompleteTrigger) {
    this.searchCaseType = '';
    // this.search.nativeElement.input();
    this.categoryList = cloneDeep(this.categoryListCopy);
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }


  filteredlabList() {
    let filteredList;
    if (!this.selectedAccount || this.selectedAccount === 'Default Configuration') {
      filteredList = this.labList;
    } else {
      const isNumericAccount = /^\d+$/.test(this.selectedAccount);
      filteredList = this.labList.filter(va => {
        const filterField = isNumericAccount ? va.accountid : va.displayname;
        return filterField.toString().toLowerCase().includes(this.selectedAccount.toLowerCase());
      });
    }
    
    return filteredList.slice(0, 25);
  }

  setCaseType(event, data?: any) {
    if (event.source.selected) {
      if (this.categoryList.some(va => va.caseTypes.some(val => val.checked && !val.disabled)) && !this.caseTypesSaved) {
        this.alertPopUp = true;
        this.selectedData = data;
        return;
      }
      if (this.categoryList.every(va => va.caseTypes.every(v => !v.checked))) {
        let id = this.selectedAccCopy == 'Default Configuration' ? 60006 : this.selectedAccCopy.split('(')[1].split(')')[0];
        this.savedCaseTypes = this.savedCaseTypes.filter(va => va.SourceLabAccountID != id);
        this.selectedCaseTypes = this.selectedCaseTypes.filter(c => c.caseTypes.filter(va => va.SourceLabAccountID != id));
      }
      //   try {
      //     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      // } catch(err) { }
      this.switchSource(data);
    }
  }

  checkAllSelected() {
    this.checkAllCaseType = this.categoryList.every(va => va.caseTypes.every(val => val.checked));
    return this, this.checkAllCaseType;
  }
  saveCaseTypeAndOrProceed(action) {
    this.alertPopUp = false
    if (action == 'save') {
      let error = this.saveCaseTypeData();
      if (!error) {
        this.switchSource(this.selectedData);
      }
      else {
        this.selectedAccount = cloneDeep(this.selectedAccCopy);
      }
    }
    else if (action == 'dontsave') {
      this.switchSource(this.selectedData);
    }
    else {
      this.selectedAccount = cloneDeep(this.selectedAccCopy);
      this.alertPopUp = false;
    }
  }

  saveCaseTypeData() {
    // let caseTypeCopy = cloneDeep(this.savedCaseTypes)
    this.ngxService.start();
    let accid = this.selectedAccCopy == 'Default Configuration' ? 60006 : Number(this.selectedAccCopy.split('(')[1].split(')')[0]);
    let orgid = this.selectedAccCopy == 'Default Configuration' ? 190996 : Number(sessionStorage.getItem('org_id'));

    this.savedCaseTypes = (this.savedCaseTypes && this.savedCaseTypes.length > 0) ? this.savedCaseTypes.filter(va => va.accountid != accid) : [];
    this.selectedCaseTypes = this.selectedCaseTypes.filter(c => c.caseTypes.filter(va => va.accountid != accid));
    // if (this.checkOrderCodeConfig() && this.contextValue != 'Ordering Facility') {
    //   this._snackbar.open("Please select the Order code and Result code mapping for the case types selected for association.", 'Close');
    //   this.ngxService.stop();
    //   return true;
    // }
    for (let i = 0; i < this.filterCaseTypeData.length; i++) {
      if (this.filterCaseTypeData[i].caseTypes.some(va => va.checked)) {
        let checkedCategory = cloneDeep(this.filterCaseTypeData[i]);
        checkedCategory.caseTypes = this.filterCaseTypeData[i].caseTypes.filter(va => va.checked);
        checkedCategory.caseTypes.forEach(va => {
          va.SourceLabAccountID = accid;
          va.SourceLabOrgID = orgid;
          this.savedCaseTypes.push(va);
        });
        if (!this.selectedCaseTypes.some(va => JSON.stringify(va) == JSON.stringify(checkedCategory))) {
          this.selectedCaseTypes.push(checkedCategory);
        }
      }
      else {
        if (this.filterCaseTypeData[i].caseTypes.some(va => va.checked == false) && this.source == 'NewEntity') {
          this.savedCaseTypes = [];
        }
      }
    }
    this.ngxService.stop();
    return false;

    // if (this.savedCaseTypes.length == 0) {
    //   this._snackbar.open('Please select a new case type before saving', 'Close');
    // }
    // else {
    //   this._snackbar.open('Case types saved', 'Close');
    // }
  }


  switchSource(data) {
    this.checkAllCaseType = false;
    if (!data) {
      this.selectedAccount = 'Default Configuration'
      this.selectedAccCopy = cloneDeep(this.selectedAccount);
      this.currentAccountid = 60006
      this.currentOrgID = 190996
      this.fetchCaseTypes(190996, 60006);
    }
    else {
      this.selectedAccount = data.displayname + ' (' + data.accountid + ')'
      this.selectedAccCopy = cloneDeep(this.selectedAccount);
      this.currentAccountid = data.accountid
      this.currentOrgID = data.organizationid
      this.fetchCaseTypes(data.organizationid, data.accountid);
    }
  }

  async getLabsUnderOrganization(organizationID) {

    let obj = {
      orgId: this.source.toString().toLowerCase() == 'newlocation' ? null : organizationID.toString(),
    };

    this.accountid = this.accountid ? this.accountid : sessionStorage.getItem('AccountID');
    let labList = []
    await this.vaservices.getAARLookupValueForLabandOF(obj, sessionStorage.getItem('deploymentKey').toUpperCase()).toPromise().then(res => {
      if (res.status == 'Success') {
        labList = (res.content.LabLocations) ? res.content.LabLocations : [];
      } else {
        console.error(res.statusmessage)
      }
    }, error => {
      console.error(error);
    })
    this.labList = this.modifyKeys(labList);
    this.ngxService.stop();

    this.labList.find(va => {
      if (va.accountid == sessionStorage.getItem('AccountID')) {
        this.selectedAccount = va.displayname + ' (' + va.accountid + ')'
      }
    })
    this.selectedAccount = this.selectedAccount ? this.selectedAccount : 'Default Configuration';
    this.selectedAccCopy = cloneDeep(this.selectedAccount);

    if (this.selectedAccount == 'Default Configuration') {
      this.currentAccountid = 60006
      this.currentOrgID = 190996
      this.fetchCaseTypes(190996, 60006);
    }
    else {
      this.currentAccountid = this.acccountID
      this.currentOrgID = this.organizationID
      this.fetchCaseTypes(this.organizationID, this.acccountID);
    }
  }

  modifyKeys(labList) {
    const mappingKeys = {
      "OrgId": "organizationid",
      "LabAccountId": "accountid",
      "LabName": "displayname"
    };
  
    return labList.map(lab => {
      const modifiedObj = {};
      Object.entries(lab).forEach(([key, value]) => {
        modifiedObj[mappingKeys[key]] = value;
      });
      return modifiedObj;
    });
  }

  fetchCaseTypes(organizationid, locationid): any {
    // sessionStorage.setItem('deploymentKey', this.deploymentKey);
    this.ngxService.start();
    this.vaservices.getCasetypeDetails(organizationid, locationid).subscribe(
      res => {
        this.categoryList = [];
        // this.categoryListCopy = [];
        if (res && res != "" && res.length > 0) {
          res.forEach(e => {
            if (!this.categoryList.find(element => element.Category == e.Category))
              e.Category = e.Category ? e.Category.toString().trim() : 'Not Specified';
            this.categoryList.push({ Category: e.Category, caseTypes: [], checked: false })

            for (let i = 0; i < this.categoryList.length; i++) {
              if (this.categoryList[i].Category == e.Category) {
                let checked = false
                let disabled = false;
                if (this.savedCaseTypes.some(va => va.casetype == e.CaseType)) {
                  disabled = true;
                  if (this.savedCaseTypes.some(va => va.SourceLabAccountID == locationid)) {
                    checked = true;
                    disabled = false;
                  }
                }
                if (!this.configuredCaseTypes.some(va => va.casetype == e.CaseType)) {
                  this.categoryList[i].caseTypes.push({ casetype: e.CaseType, SourceLabAccountID: locationid, checked: checked, Iscompendium: e.Iscompendium ? e.Iscompendium : false, disabled: disabled });
                }
                break;
              }
            }
          });
          this.categoryList = this.categoryList.filter(va => va.caseTypes.length > 0);
          this.filterCaseTypeData = cloneDeep(this.categoryList);
          this.categoryListCopy = cloneDeep(this.categoryList);
          this.showCasetype = this.categoryList.length > 0 ? true : false;
        }
        else {
          this.showCasetype = false;
          // this.deploymentSelection();
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        this.categoryList = [];
        this.showCasetype = false;
        console.error(error);
        // sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
        this._snackbar.open("Error found while fetching casetypes", "Close");
        // this.deploymentSelection();
      });
  }
  clearCaseTypeResults(evt: any, trigger: MatAutocompleteTrigger,casetypeSearch : MatAutocomplete ) {
    this.searchCaseType = '';
    // this.search.nativeElement.input();
    this.categoryList = cloneDeep(this.filterCaseTypeData);
    this.clearPreviousSelectedOption(casetypeSearch,null,false);
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  clearAccounts(event: any, trigger: MatAutocompleteTrigger,matAuto : MatAutocomplete) {
    this.selectedAccount = '';
    // this.selectedAccCopy = cloneDeep(this.selectedAccount);
    // this.search.nativeElement.input();
    this.clearPreviousSelectedOption(matAuto,null,false);
    event.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  previousNxtBtn(direction) {
    this.selectedValue = direction
    if (!this.caseTypesSaved) {
      // $('#casetypeBackdrop').appendTo("body").modal('show');
      this.caseTypePopup = true;
    }
    else {
      if (this.source.toString().toLowerCase() == 'newlocation') {
        this.setupLocMovetoOtherTab(this.selectedValue);
      }
      else if (this.source.toString().toLowerCase() == 'newentity') {
        this.setupEntityMovetoOtherTab(this.selectedValue);
      }
      else {
        this.setupEPGFacMovetoOtherTab(this.selectedValue);
      }
    }
  }

  setupEPGFacMovetoOtherTab(selectedValue) {
    this.closeModal();
    if (selectedValue == 'nxt') {
      this.setupEPGFAc.nextFromCasetype();
    } else if (selectedValue == 'prev') {
      this.setupEPGFAc.prevFromCasetype();
    } else if (selectedValue == 'can') {
      this.setupEPGFAc.back();
    }
  }

  closeModal() {
    // $('#casetypeBackdrop').appendTo("body").modal('hide');
    this.caseTypePopup = false;
  }

  saveNotSave(val) {
    this.closeModal();
    this.ifCaseTypesSaved = false;
    // this.ifCasetypeChanged = false;
    if (val == 'save') {
      this.saveData(this.selectedValue);
    }
    else if (val == 'notsave') {
      if (this.source.toString().toLowerCase() == 'newlocation') {
        this.filterCaseTypeData = [];
        this.setupLocMovetoOtherTab(this.selectedValue)
        this.categoryList = cloneDeep(this.categoryListCopy);
      }
      else if (this.source.toString().toLowerCase() == 'newentity') {
        if (this.selectedValue != 'nxt') {
          this.categoryList = cloneDeep(this.categoryListCopy);
          this.setupEntityMovetoOtherTab(this.selectedValue)
        }
        else {
          this.closeModal();
          if (this.mode != 'edit') {
            this._snackbar.open('Please select at least one casetype', 'Close');
          }
          else {
            // this.saveData(this.selectedValue);\
            this._snackbar.open('Please save and proceed', 'Close');
          }
          return;
        }
      }
      else {
        if (this.selectedValue != 'nxt') {
          this.categoryList = cloneDeep(this.categoryListCopy);
          this.setupEPGFacMovetoOtherTab(this.selectedValue)
        }
        else {
          this.closeModal();
          if (this.mode != 'edit') {
            this._snackbar.open('Please select at least one casetype', 'Close');
          }
          else {
            // this.saveData(this.selectedValue);
            this._snackbar.open('Please save and proceed', 'Close');
            // this.setupEntityMovetoOtherTab(this.selectedValue);
          }
          return;
        }

      }
      this.showCasetype = true
    }
  }

  setupLocMovetoOtherTab(selectedValue) {
    this.closeModal();
    if (selectedValue == 'nxt') {
      this.setupLoc.nextFromCasetype();
    } else if (selectedValue == 'prev') {
      this.setupLoc.prevFromCasetype();
    } else if (selectedValue == 'can') {
      this.setupLoc.viewLocation();
    }
  }

  async saveData(selectedValue?) {
    try {
      this.ngxService.start('FinalSave');
      this.searchCaseType = '';
      this.saveCaseTypeData();
      this.closeModal();

      // if (this.checkOrderCodeConfig() && this.contextValue != 'Ordering Facility') {
      //   this.errorAlertSpan = "Please select the Order code and Result code mapping for the case types selected for association. "
      //   this.ngxService.stop('FinalSave');
      //   return;
      // }
      let message = this.caseTypeFinalSave();
      if (message) {
        this.closeModal();
        this.ngxService.stop('FinalSave');
        if(message.match(/moveTab/i)){
          return;
        }
        this._snackbar.open(message, 'close');
        return;
      }
      let response 
      if (this.source.toLowerCase() != 'newentity' && this.source.toLowerCase() != 'newepgfac') {
        if (this.mode == 'edit' && this.selectedValue != 'prev' && this.configuredCaseTypes && this.configuredCaseTypes.length > 0) {
          response = await this.vitalHttpServices.activateCaseTypes(this.configuredCaseTypes, sessionStorage.getItem('DeploymentKey')).toPromise();
        }
        else if (this.mode == 'edit' && this.selectedValue == 'prev' && this.configuredCaseTypes && this.configuredCaseTypes.length > 0) {
          this.closeModal();
          this.setupLoc.prevFromCasetype();
          this._snackbar.open('Case types saved successfully', 'Close');
          this.categoryListCopy = cloneDeep(this.categoryList);
          this.ngxService.stop('FinalSave');
          return;
        }
      }
      else {
        if (this.mode == 'edit' && this.selectedValue != 'prev') {
          response = await this.vitalHttpServices.activateCaseTypes(this.configuredCaseTypes, sessionStorage.getItem('DeploymentKey')).toPromise();
        }
      }

      if (this.datashare.savedCaseTypes.length > 0) {
        if (this.source.toString().toLowerCase() == 'newlocation') {
          let copyAllConfig = true;
          if(this.isPOLOrg && String(this.entityType).toLowerCase() != 'lab')
            copyAllConfig = false;
          response = await this.setupLoc.saveCaseTypes(copyAllConfig);
        }
        else if (this.source.toString().toLowerCase() == 'newentity') {
          if (!selectedValue) {
            this._snackbar.open('Case types saved successfully', 'Close');
            this.categoryListCopy = cloneDeep(this.categoryList);
            this.ngxService.stop('FinalSave');
            return;
          }
          else if (selectedValue != 'prev') {
            if (this.mode == 'edit') {
              let obj = {
                LabAccountID: this.editAccountid,
                LabOrgID: this.editOrganizationID,
                CreatedBy: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100,
                CaseTypes: this.datashare.savedCaseTypes,
                CaseTypeOrderCode: this.datashare.savedOrderCodes,
                Instruments: this.datashare.savedInstruments
              }
              await this.configureCaseTypes(obj, response);
            }
            else {
              this.closeModal();
              response = await this.setupEntity.saveCaseTypes();
            }
          }
          else {
            this.closeModal();
            this.setupEntity.prevFromCasetype();
            this._snackbar.open('Case types saved successfully', 'Close');
            this.categoryListCopy = cloneDeep(this.categoryList);
            this.ngxService.stop('FinalSave');
            return;
          }
        }
        else {
          if (!selectedValue) {
            this._snackbar.open('Case types saved successfully', 'Close');
            this.categoryListCopy = cloneDeep(this.categoryList);
            this.categoryList = cloneDeep(this.categoryListCopy);
            this.ngxService.stop('FinalSave');
            return;
          }
          if (this.selectedValue != 'prev') {
            if (this.mode == 'edit') {
              let obj = {
                LabAccountID: this.editAccountid,
                LabOrgID: this.editOrganizationID,
                CreatedBy: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100,
                CaseTypes: this.datashare.savedCaseTypes,
                CaseTypeOrderCode: this.datashare.savedOrderCodes,
                Instruments: this.datashare.savedInstruments
              }
              this.closeModal();
              await this.configureCaseTypes(obj, response);
            }
            else {
              this.closeModal();
              response = await this.setupEPGFAc.saveCaseTypes();
            }
          }
          else {
            this.closeModal();
            await this.setupEPGFAc.prevFromCasetype();
            this.categoryListCopy = cloneDeep(this.categoryList);
            this._snackbar.open('Case types saved successfully', 'Close');
            this.ngxService.stop('FinalSave');
            return;
          }
        }
      }
      else if (this.mode == 'edit' && this.configuredCaseTypes.length == 0) {
        this.setupLocMovetoOtherTab(selectedValue);
        this.ngxService.stop('FinalSave');
        return;
      }
      this.datashare.savedCaseTypes = [];
      this.datashare.savedOrderCodes = [];
      this.datashare.savedInstruments = [];
      if (this.source.toString().toLowerCase() == 'newlocation') {
        if (response && response.Success) {
          this._snackbar.open('Case types saved successfully', 'Close');
          if (this.mode == 'edit') {
            this.fetchExistingCaseTypes();
            this.selectedCaseTypes = [];
            this.savedCaseTypes = [];
          }
          else {
            this.caseTypesSaved = true;
          }
          if (selectedValue) {
            this.setupLocMovetoOtherTab(this.selectedValue)
            this.ngxService.stop('FinalSave');
          }
        }
        // else{
        // if (selectedValue) {
        //   this.setupLocMovetoOtherTab(this.selectedValue)
        //   this.ngxService.stop('FinalSave');
        // }
        else {
          this._snackbar.open('Case types save failed!', 'Close');
          this.ngxService.stop('FinalSave');
        }
        // }
      }
      else if (this.source.toString().toLowerCase() == 'newentity') {
        if (response && ((response.StatusMessage && response.StatusMessage.toString().match(/Success/i)) || response.Success)) {
          if (this.mode == 'edit') {
            this.closeModal();
            if (selectedValue == 'prev') {
              await this.setupEntity.prevFromCasetype();
              this.categoryListCopy = cloneDeep(this.categoryList);
              this._snackbar.open('Case types saved successfully', 'Close');
            }
            else {
              await this.setupEntity.saveCaseTypes();
            }
          }
        }
        else if (this.datashare.savedCaseTypes.length > 0) {
          this._snackbar.open('Case types save failed!', 'Close')
        }
        else {
          if (this.selectedValue == 'prev') {
            await this.setupEntity.prevFromCasetype();
            this.configuredCaseTypesClone = cloneDeep(this.configuredCaseTypes)
            this.ngxService.stop('FinalSave');
            return;
          }
          await this.setupEntity.saveCaseTypes();
        }
        // this.setupEntityMovetoOtherTab(this.selectedValue)

      }
      else {
        if (response && ((response.StatusMessage == "Success") || response.Success)) {
          if (this.mode == 'edit') {
            this.closeModal();
            if (selectedValue == 'prev') {
              await this.setupEPGFAc.prevFromCasetype();
              this.categoryListCopy = cloneDeep(this.categoryList);
              this._snackbar.open('Case types saved successfully', 'Close');
            }
            else {
              await this.setupEPGFAc.saveCaseTypes();
            }
          }
        }
        else if (this.datashare.savedCaseTypes.length > 0) {
          this._snackbar.open('Case types save failed!', 'Close');
        }
        else {
          if (this.selectedValue == 'prev') {
            await this.setupEPGFAc.prevFromCasetype();
            this.configuredCaseTypesClone = cloneDeep(this.configuredCaseTypes)
            this.ngxService.stop('FinalSave');
            return;
          }
        }
      }
      this.ngxService.stop('FinalSave');
      // this.categoryList = cloneDeep(this.categoryListCopy);
    }
    catch (e) {
      console.error(e)
      this.ngxService.stop('FinalSave');
      this.categoryList = cloneDeep(this.categoryListCopy);
    }
  }


  async configureCaseTypes(obj, response) {
    response = await this.vitalHttpServices.configureCaseTypes(obj, sessionStorage.getItem('DeploymentKey')).toPromise();
    if (response && response.Success) {
      this.closeModal();
      if(this.source.toLowerCase() == 'newentity')
      this.setupEntity.saveCaseTypes();
      else
      this.setupEPGFAc.saveCaseTypes();
    }
    else {
      this._snackbar.open('Case types save failed!', 'Close');
    }
    return response
  }

  setupEntityMovetoOtherTab(selectedValue) {
    this.closeModal();
    if (selectedValue == 'nxt') {
      this.setupEntity.nextFromCasetype();
    } else if (selectedValue == 'prev') {
      this.setupEntity.prevFromCasetype();
    } else if (selectedValue == 'can') {
      this.setupEntity.back();
    }
  }
  caseTypeFinalSave() {
    //forming casetype data to send to api
    let orderCodeObj = [];
    let casetypeObj = [];
    let resultsetID = [];
    let caseTypeInstrumentsArr = [];
    if (this.source == 'NewEntity' && this.selectedCaseTypes.length == 0) {
      casetypeObj.push(
        {
          ID: casetypeObj.length + 1, CaseTypeName: null,
          AccessionFormat: "", AlternateFormat: "", Category: 'Not Specified',
          SourceLabAccountID: sessionStorage.getItem('AccountID'),
          SourceLabOrgID: sessionStorage.getItem('org_id')
        });
    }
    else {
      this.selectedCaseTypes.forEach(e => {
        e.caseTypes.forEach(ele => {
          if (ele.checked) {
            casetypeObj.push(
              {
                ID: casetypeObj.length + 1, CaseTypeName: ele.casetype,
                AccessionFormat: "", AlternateFormat: "", Category: e.Category,
                SourceLabAccountID: ele.SourceLabAccountID,
                SourceLabOrgID: ele.SourceLabOrgID
              });
          }
        });
      });
    }

    // if (this.checkOrderCodeConfig() && this.contextValue != 'Ordering Facility') {
    //   return "Please select the Order code and Result code mapping for the case types selected for association. "


    // }
    this.resultSetSavedList.forEach(value => {
      value.forEach(e => {
        if (e.checked && this.selectedCaseTypes.some(ele => ele.caseTypes.some(val => val.casetype == e.Casetype))) {
          resultsetID.push({ casetype: e.Casetype, ID: e.ID });
        }
      })
    });
    this.orderCodeSavedList.forEach(value => {
      value.forEach(e => {
        e.ChildCodes.forEach(ele => {
          let childCodeOnly = true;
          ele.ResultCodes.forEach(c => {
            if (c) {
              childCodeOnly = false
              if (e.checked && ele.checked && c.checked && this.selectedCaseTypes.some(ele => ele.caseTypes.some(val => val.casetype == e.Casetype))) {
                let setID = resultsetID.filter(va => va.casetype == e.Casetype)
                orderCodeObj.push(
                  {
                    ID: orderCodeObj.length + 1,
                    CaseTypeName: e.Casetype,
                    OrderCodeID: ele.ChildOrderCodeID,
                    ParentOrderCodeID: e.ParentOrderCodeID,
                    ResultCodeID: c.ResultCodeID,
                    CustomHTMLID: setID[0] ? setID[0].ID : null
                  });
              }
            }
          })
          if (childCodeOnly) {
            if (e.checked && ele.checked && this.selectedCaseTypes.some(ele => ele.caseTypes.some(val => val.casetype == e.Casetype))) {
              let setID = resultsetID.filter(va => va.casetype == e.Casetype)
              orderCodeObj.push(
                {
                  ID: orderCodeObj.length + 1,
                  CaseTypeName: e.Casetype,
                  OrderCodeID: ele.ChildOrderCodeID,
                  ParentOrderCodeID: e.ParentOrderCodeID,
                  ResultCodeID: null,
                  CustomHTMLID: setID[0] ? setID[0].ID : null
                });
            }
          }
        });
      });
    })
    // this.ngxService.stop();
    this.instrumentsListSavedList.forEach(va => {
      va.forEach(value => {
        if (this.selectedCaseTypes.some(ele => ele.caseTypes.some(val => val.casetype == value.Casetype)) && value.checked) {
          caseTypeInstrumentsArr.push({ ID: caseTypeInstrumentsArr.length + 1, CaseTypeName: value.Casetype, InstrumentName: value.InstrumentName });
        }
      })
    })

    //check if phone and fax are numbers
    //check if atleast one casetype is selected
    this.datashare.savedCaseTypes = [];
    this.datashare.savedOrderCodes = [];
    this.datashare.savedInstruments = [];
    if (this.source != 'NewEntity') {
      if (!(casetypeObj.length > 0) && this.mode != 'edit') {
        if(this.isPOLOrg && !this.entityType.match(/lab/i)){
          this.setupLocMovetoOtherTab(this.selectedValue)
          return 'moveTab';
        }
          return 'Please select at least one casetype';
      }
      if (casetypeObj.length > 0) {
        this.datashare.savedCaseTypes = casetypeObj;
        this.datashare.savedOrderCodes = orderCodeObj;
        this.datashare.savedInstruments = caseTypeInstrumentsArr;
      }
    }
    else {
      this.datashare.savedCaseTypes = casetypeObj;
      this.datashare.savedOrderCodes = orderCodeObj;
      this.datashare.savedInstruments = caseTypeInstrumentsArr;
    }
    // this.datashare.configuredCaseTypes = this.configuredCaseTypes
  }

  // checkOrderCodeConfig() {
  //   let count = 0
  //   for (let i = 0; i < this.filterCaseTypeData.length; i++) {
  //     // if (this.filterCaseTypeData[i].checked) {
  //       for (let j = 0; j < this.filterCaseTypeData[i].caseTypes.length; j++) {
  //         if (this.filterCaseTypeData[i].caseTypes[j].Iscompendium) {
  //           // if (this.filterCaseTypeData[i].caseTypes[j].checked) {
  //             let casetype = this.filterCaseTypeData[i].caseTypes[j].casetype;
  //             !this.orderCodeSavedList.some(va => va.some(value => value.Casetype == casetype)) ? count++ : null
  //           // }
  //         }
  //       }
  //     // }
  //   }
  //   return count > 0
  // }


  orderCodePopupClose() {
    $("#manageOrderCodes").modal('hide');
    this.orderCodeList = cloneDeep(this.orderCodeListCopy);
    this.resultSetList = cloneDeep(this.resultSetListCopy);
    this.instrumentsList = cloneDeep(this.instrumentsListCopy);
    this.orderPopup = false
    this.searchString = ''
  }


  async getOrderCodes(value) {
    this.caseType = value
    let constraints = {
      casetype: value,
      dbname: sessionStorage.getItem('DeploymentKey'),
      LabAccountID: this.currentAccountid,
      LabOrgID: this.currentOrgID
    }

    if (this.treeStructures[this.caseType] && this.treeStructures[this.caseType].length > 0) {
      this.ngxService.start('selected panels');

      //Patch existing case type data for the casetype
      this.dataSource.data = this.treeStructures[this.caseType];

      //Patch existing result set data for the casetype
      this.resultSetList = this.savedResults[this.caseType] ? this.savedResults[this.caseType] : [];

      //Patch existing instruments data for the casetype
      this.instrumentsList = this.savedInstruments ? this.savedInstruments[this.caseType] : [];
      $("#manageOrderCodes").appendTo("body").modal("show")
      this.ngxService.stop('selected panels');

    }
    else {
      this.orderCodeListCopy = [];
      this.orderCodeList = [];
      this.resultList = [];
      this.resultSetList = [];
      this.resultSetListCopy = [];
      this.instrumentsList = [];
      this.instrumentsListCopy = []
      this.ngxService.start();
      this.vaservices.getOrderCodes(constraints).subscribe(res => {
        this.ngxService.stop();
        if (res) {
          if (res.Table.length == 0) {
            this._snackbar.open('No order codes available for this case type. Please select another case type', 'Close');
            return;
          }
          res.Table.forEach(e => {
            if (!this.orderCodeList.some(element => element.ParentOrderCodeName == e.ParentOrderCodename)) {
              this.orderCodeList.push({ ParentOrderCodeName: e.ParentOrderCodename, Casetype: value, ParentOrderCodeID: e.ParentOrderCodeID, ChildCodes: [], checked: true })
            }

            for (let i = 0; i < this.orderCodeList.length; i++) {
              if (!this.orderCodeList[i].ChildCodes.some(element => element.ChildCodeName == e.ChildOrderCodeName)) {
                if (this.orderCodeList[i].ParentOrderCodeName == e.ParentOrderCodename) {
                  this.orderCodeList[i].ChildCodes.push({ ChildCodeName: e.ChildOrderCodeName, ChildOrderCodeID: e.OrderCodeID, ResultCodes: [], checked: true });
                  break;
                }
              }
            }

            for (let i = 0; i < this.orderCodeList.length; i++) {
              for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++)
                if (this.orderCodeList[i].ParentOrderCodeName == e.ParentOrderCodename && this.orderCodeList[i].ChildCodes[j].ChildCodeName == e.ChildOrderCodeName && e.ResultName) {
                  this.orderCodeList[i].ChildCodes[j].ResultCodes.push({ ResultName: e.ResultName, ResultCode: e.ResultName, ParentOrderCodeName: e.ParentOrderCodename, ResultCodeID: e.Resultcodeid, checked: true });
                  break;
                }
            }
          });

          res.Table1.forEach(e => {
            if (e.ID) {
              this.resultSetList.push({ Casetype: value, ResultObjectName: e.ObjectName, ID: e.ID, checked: false })
            }
          })

          res.Table2.forEach(e => {
            this.instrumentsList.push({ InstrumentName: e.InstrumentName, Casetype: value, checked: true })
          })

          if (this.resultSetList.length > 0) {
            this.resultSetList[0].checked = true;
          }
          this.checkAllInstruments = true;
          let treeArr = this.createTreeStructure();
          // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
          // this.dataSource.data = this.buildFileTree(treeArr, 0);
          this.orderCodeData = treeArr
          this.dataSource.data = treeArr
          Object.keys(this.dataSource.data).forEach((x) => {
            this.setParent(this.dataSource.data[x], null);
          });
          let event = new MatCheckboxChange()
          this.dataSource.data.forEach((nodes) => {
            this.todoItemSelectionToggle(true, nodes);
          })
          // this.checkAllNodes(event, true);
          this.orderPopup = true;

          this.orderCodeListCopy = cloneDeep(this.orderCodeList);
          this.resultSetListCopy = cloneDeep(this.resultSetList);
          this.instrumentsListCopy = cloneDeep(this.instrumentsList);
          $("#manageOrderCodes").appendTo("body").modal("show")
        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }
  selectSavedData(savedData) {
    for (let k = 0; k < savedData.length; k++) {
      for (let l = 0; l < this.dataSource.data.length; l++) {
        if (savedData[k].selected) {
          delete savedData[k].selected
          let savedParent = savedData[k].parent
          for (let m = 0; m < this.dataSource.data[l].children.length; m++) {
            if (this.dataSource.data[l].children[m].children.length > 0) {
              this.handleResultCodes(savedData[k], this.dataSource.data[l].children[m].children)
            }
            else {
              let dataParent = this.dataSource.data[l].children[m].parent
              delete savedData[k].parent
              delete this.dataSource.data[l].children[m].parent
              if (_.isEqual(savedData[k], this.dataSource.data[l].children[m])) {
                this.dataSource.data[l].children[m].parent = dataParent
                this.todoItemSelectionToggle(true, this.dataSource.data[l].children[m])
              }
              this.dataSource.data[l].children[m].parent = !this.dataSource.data[l].children[m].parent ? dataParent : this.dataSource.data[l].children[m].parent;
            }

          }
          savedData[k].parent = savedParent
          savedData[k].selected = true
        }
      }
    }
  }

  // handleResultCodes(savedData, resultCodes) {
  //   if (savedData.children.length == 0) {
  //     for (let i = 0; i < resultCodes.length; i++) {
  //       let dataParent = resultCodes[i].parent
  //       let savedParent = savedData.parent
  //       delete resultCodes[i].parent
  //       delete savedData.parent
  //       if (_.isEqual(savedData, resultCodes[i])) {
  //         resultCodes[i].parent = dataParent
  //         this.todoItemSelectionToggle(true, resultCodes[i])
  //       }
  //       resultCodes[i].parent = !resultCodes[i].parent ? dataParent : resultCodes[i].parent;
  //       savedData.parent = savedParent
  //     }
  //   }
  //   else {
  //     for (let i = 0; i < resultCodes.length; i++) {
  //       for (let j = 0; j < savedData.children.length; j++) {
  //         let dataParent = resultCodes[i].parent
  //         let savedParent = savedData.children[j].parent
  //         delete resultCodes[i].parent
  //         delete savedData.children[j].parent
  //         if (_.isEqual(savedData.children[j], resultCodes[i])) {
  //           resultCodes[i].parent = dataParent
  //           this.todoItemSelectionToggle(true, resultCodes[i])
  //         }
  //         resultCodes[i].parent = !resultCodes[i].parent ? dataParent : resultCodes[i].parent;
  //         savedData.children[j].parent = savedParent
  //       }
  //     }
  //   }
  // }

  handleResultCodes(savedData, resultCodes) {
    const isSavedDataLeafNode = savedData.children.length === 0;

    resultCodes.forEach((resultCode) => {
      const dataParent = resultCode.parent;
      let savedParent;

      if (isSavedDataLeafNode) {
        savedParent = savedData.parent;
        delete savedData.parent;
      } else {
        savedParent = resultCode.parent;
        savedData.children.forEach((child) => delete child.parent);
      }

      delete resultCode.parent;

      if (_.isEqual(savedData, resultCode)) {
        resultCode.parent = dataParent;
        this.todoItemSelectionToggle(true, resultCode);
      }

      resultCode.parent = resultCode.parent || dataParent;

      if (!isSavedDataLeafNode) {
        savedData.children.forEach((child) => {
          child.parent = savedParent;
        });
      }
    });
  }


  todoItemSelectionToggle(checked, node) {
    node.selected = checked;
    if (node.children) {
      node.children.forEach((x) => {
        this.todoItemSelectionToggle(checked, x);
      });
    }
    this.checkAllParents(node);
  }

  checkAllParents(node) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every((child) => child.selected);
      node.parent.indeterminate = descendants.some((child) => child.selected);
      this.checkAllParents(node.parent);
    }
    this.checkAllPanel = this.dataSource.data.every(va => va.selected) ? true : false
  }

  createTreeStructure(): any {
    this.ngxService.start();
    let treeArray = []
    if (!this.orderCodeSavedList.some(va => va.some(value => value.Casetype == this.caseType))) {
      for (let i = 0; i < this.orderCodeList.length; i++) {
        let treeObj = {};
        if (!Object.keys(treeObj).includes(this.orderCodeList[i].ParentOrderCodeName)) {
          var value = this.orderCodeList[i].ParentOrderCodeName;
          treeArray.push({ name: value, children: [] });
        }
      }
      for (let k = 0; k < treeArray.length; k++) {
        let childArray = [];
        for (let i = 0; i < this.orderCodeList.length; i++) {
          for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++) {
            if (treeArray[k].name == this.orderCodeList[i].ParentOrderCodeName) {
              var value = this.orderCodeList[i].ChildCodes[j].ChildCodeName
              childArray.push({ name: value, parentName: this.orderCodeList[i].ParentOrderCodeName, children: [] });
            }
          }
        }
        treeArray[k]["children"] = childArray
      }

      for (let i = 0; i < treeArray.length; i++) {
        for (let j = 0; j < treeArray[i].children.length; j++) {
          let childArray = [];

          for (let k = 0; k < this.orderCodeList.length; k++) {
            for (let l = 0; l < this.orderCodeList[k].ChildCodes.length; l++) {
              for (let m = 0; m < this.orderCodeList[k].ChildCodes[l].ResultCodes.length; m++)
                if (treeArray[i].name == this.orderCodeList[k].ParentOrderCodeName && treeArray[i].children[j].name == this.orderCodeList[k].ChildCodes[l].ChildCodeName) {
                  var value = this.orderCodeList[k].ChildCodes[l].ResultCodes[m].ResultName
                  childArray.push({ name: value, parentName: this.orderCodeList[i].ParentOrderCodeName, grandParentCodeName: this.orderCodeList[k].ChildCodes[l].ChildCodeName, children: [] });
                }
            }
            // treeArray[i].children[k].children = []
            treeArray[i].children[j].children = childArray;
          }

        }
      }
    }
    else {
      let panels = this.orderCodeSavedList.filter(va => va.some(val => val.Casetype == this.caseType))
      panels = panels[0];

      let treeObj = {};
      panels.forEach(va => {
        if (!Object.keys(treeObj).includes(va.ParentOrderCodeName)) {
          var value = va.ParentOrderCodeName;
          treeArray.push({ name: value, children: [] });
        }
      })


      for (let k = 0; k < treeArray.length; k++) {
        let childArray = [];
        panels.forEach(va => {

          if (treeArray[k].name == va.ParentOrderCodeName) {
            for (let k = 0; k < va.ChildCodes.length; k++) {
              var value = va.ChildCodes[k].ChildCodeName
              childArray.push({ name: value, parentName: va.ParentOrderCodeName, children: [] });
            }
          }
        });
        treeArray[k]["children"] = childArray;
      }

      for (let i = 0; i < treeArray.length; i++) {
        for (let j = 0; j < treeArray[i].children.length; j++) {
          let childArray = [];
          panels.forEach(va => {
            va.ChildCodes.forEach(val => {

              if (treeArray[i].name == va.ParentOrderCodeName && treeArray[i].children[j].name == val.ChildCodeName) {
                for (let n = 0; n < val.ResultCodes.length; n++) {
                  var value = val.ResultCodes[n].ResultName
                  childArray.push({ name: value, parentName: va.ParentOrderCodeName, grandParentCodeName: val.ChildCodeName, children: [] });
                }
                treeArray[i].children[j].children = childArray;
              }
            })
          })
        }
      }
    }
    this.ngxService.stop();
    return treeArray;
  }

  orderCodeSave() {
    let result = [];
    this.savedOrderCodesCasetype = this.savedOrderCodesCasetype.filter(va => va.some(value => !Object.keys(value).includes(this.caseType)))
    result = this.getSelectedData(result);
    this.searchString = ''
    if (!result.length) {
      this._snackbar.open("Please select at least one panel!", "Close");
      return;
    }
    $("#manageOrderCodes").modal("hide")
    result.length > 0 ? this.savedOrderCodesCasetype.push(result) : null;
    for (let i = 0; i < this.orderCodeSavedList.length; i++) {
      for (let j = 0; j < this.orderCodeSavedList[i].length; j++) {

      }
    }
    this.captureOrderCodes();
    this.orderCodeListCopy = cloneDeep(this.orderCodeList);
    this.resultSetListCopy = cloneDeep(this.resultSetList);
    this.instrumentsListCopy = cloneDeep(this.instrumentsList);

    let index = null
    this.orderPopup = false
    this.closedModal = false;
    if (this.orderCodeList[0]) {
      index = this.orderCodeSavedList ? this.orderCodeSavedList.findIndex(va => va[0].Casetype == this.orderCodeList[0].Casetype) : -1
      !(index == -1) ? this.orderCodeSavedList[index] = this.orderCodeList : this.orderCodeSavedList.push(this.orderCodeList);
    }
    if (this.resultSetList[0]) {
      index = this.resultSetSavedList ? this.resultSetSavedList.findIndex(va => va[0].Casetype == this.resultSetList[0].Casetype) : -1
      !(index == -1) ? this.resultSetSavedList[index] = this.resultSetList : this.resultSetSavedList.push(this.resultSetList);
    }
    if (this.instrumentsList[0]) {
      index = this.instrumentsListSavedList ? this.instrumentsListSavedList.findIndex(va => va[0].Casetype == this.instrumentsList[0].Casetype) : -1
      !(index == -1) ? this.instrumentsListSavedList[index] = this.instrumentsList : this.instrumentsListSavedList.push(this.instrumentsList);
    }
    this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    for (let i = 0; i < this.checklistSelection.selected.length; i++) {
      this.checklistSelection.deselect(this.checklistSelection.selected[i])
    }
    this.treeStructures[this.caseType] = this.dataSource.data
    this.savedResults[this.caseType] = this.resultSetList
    this.savedInstruments[this.caseType] = this.instrumentsList
    this._snackbar.open("Panels saved successfully", "Close");
    $('#LabSetup').modal('show');
  }

  captureOrderCodes() {
    for (let i = 0; i < this.savedOrderCodesCasetype.length; i++) {
      if (Object.keys(this.savedOrderCodesCasetype[i][0]).includes(this.caseType)) {
        for (let j = 0; j < this.savedOrderCodesCasetype[i].length; j++) {
          for (let m = 0; m < this.savedOrderCodesCasetype[i][j][this.caseType].length; m++) {
            for (let k = 0; k < this.orderCodeList.length; k++) {
              if (this.orderCodeList[k].Casetype == this.caseType) {
                if (this.savedOrderCodesCasetype[i][j][this.caseType][m].children.length == 0) {
                  if (!this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) {
                    let checked = this.orderCodeList[k].ChildCodes.length > 0 && this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) ? true : false
                    if (this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name)) {
                      if (checked) {
                        let codeFound = false
                        this.orderCodeList[k].checked = checked
                        this.orderCodeList[k].seen = true
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          if (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) {
                            codeFound = true
                            va.checked = true;
                          }
                          va.seen = true
                          !codeFound ? va.checked = false : null;
                        });
                      }
                      else {
                        this.orderCodeList[k].seen = false
                        this.orderCodeList[k].checked = checked
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          va.checked = false;
                        });
                      }
                    }
                  }
                  else {
                    let checked = this.orderCodeList[k].ChildCodes.length > 0 && this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName || va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) && va.ResultCodes.some(value => value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name))
                    if (this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) && va.ResultCodes.some(value => value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name))) {
                      if (checked) {
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          if (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName || va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) {
                            va.ResultCodes.forEach(value => {
                              if (value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) {
                                va.checked = true;
                                this.orderCodeList[k].checked = true;
                                this.orderCodeList[k].seen = true;
                                value.seen = true
                                value.checked = true
                                va.seen = true
                              }
                            })
                          }
                        });
                      }
                      else {
                        this.orderCodeList[k].seen = false
                        this.orderCodeList[k].checked = false;
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          va.checked = false;
                          va.ResultCodes.forEach(value => {
                            value.seen = false
                            value.checked = false
                          })
                          va.seen = false
                        });
                      }
                    }
                  }
                }
                else {
                  for (let n = 0; n < this.savedOrderCodesCasetype[i][j][this.caseType][m].children.length; n++) {
                    if (this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].parentName == this.orderCodeList[k].ParentOrderCodeName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].grandParentCodeName)) {
                      this.orderCodeList[k].ChildCodes.forEach(va => va.ResultCodes.forEach(value => {
                        let codeFound = false;
                        if (value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].name) {
                          codeFound = true;
                          value.checked = true;
                        }
                        !codeFound ? value.checked = false : null;
                      }));
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    for (let i = 0; i < this.orderCodeList.length; i++) {
      if (!this.orderCodeList[i].checked) {
        this.orderCodeList[i].ChildCodes.forEach(va => va.checked = false);
      }
      if (this.orderCodeList[i].seen == null || this.orderCodeList[i].seen == undefined) {
        this.orderCodeList[i].checked = false
        this.orderCodeList[i].ChildCodes.forEach(va => va.checked = false);
      }
      if (this.orderCodeList[i].seen != null && this.orderCodeList[i].seen != undefined) {
        this.orderCodeList[i].checked = this.orderCodeList[i].seen;
        for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++) {
          if (this.orderCodeList[i].ChildCodes[j].seen == true) {
            this.orderCodeList[i].ChildCodes[j].checked = true
          }
          else {
            this.orderCodeList[i].ChildCodes[j].checked = false
          }
          this.orderCodeList[i].ChildCodes[j].ResultCodes.forEach(va => {
            if (va.seen == null || va.seen == undefined) {
              va.checked = false;
            }
            else {
              va.checked = va.seen;
            }
          })
        }
      }
    }
  }

  getSelectedData(result) {
    this.dataSource.data.forEach((node) => {
      let dataobject = {};
      let selectedValue = this.treeControl.getDescendants(node).filter((x) => x.selected)
      dataobject[this.caseType] = selectedValue

      let array = this.treeControl
        .getDescendants(node)
        .filter((x) => x.selected)
      array.length > 0 ? dataobject[this.caseType].length > 0 ? result.push(dataobject) : null : null;

    });
    return result
  }

  checkAllPanels(checked) {
    this.dataSource.data.forEach(nodes => {
      this.todoItemSelectionToggle(checked, nodes);
    })
  }

  filterParentNode(node: OrderCodes): boolean {

    if (
      !this.searchString ||
      node.name.toLowerCase().indexOf(this.searchString ?.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.name
            .toLowerCase()
            .indexOf(this.searchString ?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }
  filterLeafNode(node: OrderCodes): boolean {
    if (!this.searchString) {
      return false
    }
    return node.name.toLowerCase()
      .indexOf(this.searchString ?.toLowerCase()) === -1
  }

  changeCaseType(event, data) {
    data.checked = event.checked;
    data.IsActive = data.checked;
  }

  checkPanelSelected(casetype) {
    return this.savedOrderCodesCasetype && this.savedOrderCodesCasetype.length > 0 && this.savedOrderCodesCasetype.some(va => va.some(val => Object.keys(val).includes(casetype)));
  }

  selectAllInstruments(event: MatCheckboxChange) {
    this.checkAllInstruments = !this.checkAllInstruments;
    this.instrumentsList.forEach(element => {
      element.checked = event.checked
    });
  }

  checkInstrumentValue(event: MatCheckboxChange, value) {
    this.instrumentsList.forEach(element => {
      if (element.InstrumentName == value) {
        element.checked = event.checked
      }
    });
    let allChecked = true;
    this.instrumentsList.forEach(element => {
      if (element.checked == false) {
        allChecked = false;
        return;
      }
    });
    this.checkAllInstruments = allChecked;
  }

  checkResultSet(event) {
    for (let i = 0; i < this.resultSetList.length; i++) {
      if (this.resultSetList[i].ID == event.value) {
        this.resultSetList[i].checked = true
      }
      else {
        this.resultSetList[i].checked = false
      }
    }
  }

  clearPreviousSelectedOption(autoComplete : MatAutocomplete,skip: _MatOptionBase | null, emitEvent?: boolean){
    autoComplete.options.forEach(option => {
      if (option !== skip && option.selected) {
        option.deselect(emitEvent);
      }
    });
  }
}
