import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import {VitalAdminTabService} from 'src/app/client/tab.service'
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-casetype-details',
  templateUrl: './casetype-details.component.html',
  styleUrls: ['./casetype-details.component.scss']
})

export class CasetypeDetailsComponent implements OnInit {
  @Input() templateData: any;
  showDetails: boolean;
  action: string;
  editScreen: boolean;
  frmEditCaseTypeDetails = this._fb.group({
    frmDisplayName: ["", Validators.required],
    frmShortName: ["", Validators.required],
    frmMnemonic: '',
    frmCategory: '',
    frmSummary: '',
    frmIsActive: true,
  })

  task: Task = {
    color: 'primary',
  };

  srcDeployment: any;
  changeToTrue: boolean;
  oldObject: { displayName: any; shortName: any; category: any; mnemonic: any; status: any; summary: any; casetype: any; accountid: string; };
  hideUpdateBtn: boolean;
  auditableColumns: any;
  constructor( private tabService:VitalAdminTabService, private _fb: FormBuilder, private _snackbar: MatSnackBar, private commonService: CommonService, private ngxService: NgxUiLoaderService, private HttpServices: VitalHttpServices) { }

  ngOnInit(): void {
    this. GetButtondetails();
    this.showDetails = true;
    this.commonService.createContext(this.templateData.secondarykeys,this.templateData.submenuData[0].AccountCasetypeID,this.templateData.menuURL);
    this.srcDeployment = sessionStorage.getItem('deploymentKey')
    this.action = 'Case Type Details'
    this.commonService.getBooleanValue().subscribe((res) => {
      this.changeToTrue = res;
    });
    this.commonService.finalizeAfterTc=this.templateData.submenuData[0].finalizeafterTc;
    this.commonService.isScreeningEnabled=this.templateData.submenuData[0].IsScreeningEnabled;
    this.getAuditableDetails(this.templateData.menuURL);
  }

  //RBAC
  GetButtondetails() {
      this.GetButtonAccess(this.HttpServices.CasemenuAction);
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Edit") {
        this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  editCaseDetails() {
    if(!this.hideUpdateBtn){
    this.showDetails = false;
    this.editScreen = true;
    this.action = 'Edit Case Type Details'
    this.commonService.finalizeAfterTc=this.templateData.submenuData[0].finalizeafterTc;
    console.log( this.commonService.finalizeAfterTc,this.commonService.isScreeningEnabled)
    this.commonService.isScreeningEnabled=this.templateData.submenuData[0].IsScreeningEnabled;
    this.frmEditCaseTypeDetails.patchValue({
      frmDisplayName: this.templateData.submenuData[0].Display_Name && this.templateData.submenuData[0].Display_Name.toLowerCase()
        != 'not specified' ? this.templateData.submenuData[0].Display_Name : '',
      frmShortName: this.templateData.submenuData[0].Short_Name && this.templateData.submenuData[0].Short_Name.toLowerCase()
        != 'not specified' ? this.templateData.submenuData[0].Short_Name : '',
      frmMnemonic: this.templateData.submenuData[0].AccountMnemonic && this.templateData.submenuData[0].AccountMnemonic.toLowerCase()
        != 'not specified' ? this.templateData.submenuData[0].AccountMnemonic : '',
      frmCategory: this.templateData.submenuData[0].Case_Type_Category && this.templateData.submenuData[0].Case_Type_Category.toLowerCase()
        != 'not specified' ? this.templateData.submenuData[0].Case_Type_Category : '',
      frmSummary: this.templateData.submenuData[0].SummaryDisplayName && this.templateData.submenuData[0].SummaryDisplayName.toLowerCase()
        != 'not specified' ? this.templateData.submenuData[0].SummaryDisplayName : '',
      frmIsActive: this.templateData.submenuData[0].Status.toLowerCase() == 'active' ? true : false
    })
    this.oldObject={
      displayName: this.frmEditCaseTypeDetails.value.frmDisplayName,
      shortName: this.frmEditCaseTypeDetails.value.frmShortName,
      category: this.frmEditCaseTypeDetails.value.frmCategory,
      mnemonic: this.frmEditCaseTypeDetails.value.frmMnemonic,
      status: this.frmEditCaseTypeDetails.value.frmIsActive?true:false,
      summary: this.frmEditCaseTypeDetails.value.frmSummary,
      casetype: this.templateData.GroupData.casetype.toString().trim()?this.templateData.GroupData.casetype.toString().trim():this.templateData.cardtype,
      accountid: sessionStorage.getItem("AccountID")
    }
   }
  }
  backEditpage() {
    this.editScreen = false;
    this.showDetails = true;
    this.action = 'Case Type Details'
    this.refreshData()
  }
  updateCaseDetails(value) {
    if (!this.validateCTDetails(value)) {
      this._snackbar.open("Please provide valid data", "close");
      return;
    }
    let obj = {
      displayName: value.frmDisplayName,
      shortName: value.frmShortName,
      category: value.frmCategory,
      mnemonic: value.frmMnemonic,
      status: value.frmIsActive,
      summary: value.frmSummary,
      casetype: this.templateData.GroupData.casetype.toString().trim()?this.templateData.GroupData.casetype.toString().trim():this.templateData.cardtype,
      accountid: sessionStorage.getItem("AccountID"),
      casetypeid:this.templateData.submenuData[0].AccountCasetypeID
    }
    this.ngxService.start();
    this.HttpServices.UpdateCaseTypeDetails(obj, this.srcDeployment).subscribe((data) => {
      this.ngxService.stop();
      if (data[0].Notes == "Updated successfully") {
        this.refreshData();
        this._snackbar.open(data[0].Notes, "Close")
        this.commonService.setBooleanToTrue(true);
        let tab: any = {};
        // this accountid is actually used to  populate the data for user(userid)
        // tab.accountid = 20029
        // tab.keyword = "Users";
        // tab.cardtype = "Users";
        // tab.text = user.Username;
        // tab.Removable = true;
        tab.Removable= true
        tab.accountid= this.templateData.cardIdentifier
        tab.cardtype= this.templateData.cardtype
        tab.keyword= "casetype"
        tab.text= this.templateData.cardtype
        this.tabService.addNewOrSelectTab(tab)
        // this.commonService.createActivityTracker('Edited', this.templateData.submenuData[0].AccountCasetypeID, 'Casetype Details', 'Audit', obj, this.oldObject);
        obj['status'] = this.getStatus(obj['status']);
        this.oldObject['status'] = this.getStatus(this.oldObject['status']);
        let sessionId = this.commonService.generateGuid();
        this.commonService.createActivityObject(this.templateData.submenuData[0].AccountCasetypeID,this.oldObject.displayName, this.templateData.menuURL, 'Edit', obj, this.oldObject, sessionId,this.auditableColumns);
      }
      else {
        this._snackbar.open("Update failed!", "Close")
      }
    }, error => {
      console.error(error)
      this.ngxService.stop();
    })

  }
  refreshData() {
    this.ngxService.start();
    let query = this.HttpServices.GetQuery('casetypeDetails');
    let queryVariable = { "accid": sessionStorage.getItem("AccountID"), "casetype": this.templateData.GroupData.casetype.toString().trim() };
    let dbname = this.srcDeployment;
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.HttpServices.GetData(queryResult, dbname).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.showDetails = true;
        this.editScreen = false;
        this.action = 'Case Type Details'
        this.frmEditCaseTypeDetails.reset();
        this.templateData.submenuData = Resdata.data.submenuData;

      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      sessionStorage.setItem('deploymentKey', this.HttpServices.deploymentKey);
    });
  }
  validateCTDetails(value) {
    if (!value.frmDisplayName || value.frmDisplayName == '') {
      return false;
    }
    else if (!value.frmShortName || value.frmShortName == '') {
      return false;
    }
    return true
  }
  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
  }

  getAuditableDetails(location: any) {
    this.HttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }
  getStatus(isActive:any){
    if(isActive){
      return 'Active';
    }else{
      return 'Inactive';
    }
  }
}