import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, from, map, tap } from 'rxjs';
import { DialogComponent } from 'src/app/base/popup/dialog/dialog.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';

@Component({
  selector: 'app-npi',
  templateUrl: './npi.component.html',
  styleUrls: ['./npi.component.scss']
})
export class NpiComponent implements OnInit {

  constructor(
    private _dialogService: DialogService,
    private _labAdminService: LabadminService,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<NpiComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) { }

  @ViewChild('selectedNPI') selectedNPIItemRef

  ngOnInit(): void {
    this.mdmSearchKey = this.config.NPIFor === 'location' ? 'SearchLocation' : 'SearchUser'
    this.isPopulated = this.config.isDataPopulated

    if(this.config.mode === 'view'){
      this.bodyClassName = 'npi-table-body-view'
    }
    if (this.config.NPIFor === 'location') {
      if(this.config.mode !== 'view') this.bodyClassName = 'npi-table-body lab-height'
      if (this.config.prefilledData.searchValue) {
        this.formNPIRegistry.patchValue({
          inputForLocation: this.config.prefilledData.searchValue
        })
        this.getNpiData()
      }
    }
    else if (this.config.NPIFor === 'user') {
      if(this.config.mode !== 'view') this.bodyClassName = 'npi-table-body user-height'
      if (this.config.prefilledData.npi || this.config.prefilledData.firstName || this.config.prefilledData.lastName) {
        this.formNPIRegistry.patchValue({
          firstName: this.config.prefilledData.firstName,
          lastName: this.config.prefilledData.lastName,
          npi: this.config.prefilledData.npi
        })
        this.getNpiData()
      }
      
    }
  }

  isScrollPresent: boolean;
  selectedNpi: any = null;
  npiVerified: boolean = false
  isPopulated: boolean = false
  mdmSearchKey: '' | 'SearchUser' | 'SearchLocation' = ''
  NPIList$: Observable<any>=null
  errorMessage: 'Please Provide NPI or Provider Name' | 'No Records found ' | 'Field contains special character(s) or wrong number of characters' | 'Only 30 records are displayed for security reasons. Please filter using other parameters' | 'Please Provide NPI, First Name or Last Name' | 'Invalid NPI Number' | 'Invalid Zip' | '' = ''
  bodyClassName: string = ''
  //@ViewChild('scrollDiv') npiDetailsDiv
  //@ViewChildren('scrollDiv', {read: ElementRef}) npiDetailsDiv: QueryList<ElementRef>;
  opencontent=false
  formNPIRegistry = this._fb.group({
    npi: ['', [Validators.pattern(/^\d{0,10}$/)]],
    providerName: ['', [Validators.minLength(1)]],
    firstName: [''],
    lastName: [''],
    city: [''],
    state: [''],
    zip: [''],
    inputForLocation: ['']
  });

  get npiGetters() {
    return this.formNPIRegistry.controls;
  }

  checkOverdingValues() {
    if (this.selectedNpi === null) {
      return this._dialogService.openDialog('Alert', `Select any row to import`, '', 'Ok', 'Cancel')
    }
    if (this.isPopulated) {
      var ref = this._dialogService.openDialog('Alert', `Data will be overridden. Do you want to proceed?`, '', 'Ok', 'Cancel')
      ref.afterClosed().subscribe((res) => {
        if (res) {
          this.exportNPI()
        }
      })
      return
    }
    else {
      this.exportNPI()
      return
    }
  }

  exportNPI() {
    let res: NPIDataFromLookup = {
      npi: this.selectedNpi.npi ?? '',
      accountName: this.selectedNpi.basic.organizationname ?? '',
      city: this.selectedNpi.primaryPracticeAddress.city ?? '',
      state: this.selectedNpi.primaryPracticeAddress.state ?? '',
      zip: `${this.selectedNpi.primaryPracticeAddress.zip}-${this.selectedNpi.primaryPracticeAddress.zip4}` ?? '',
      address1: this.selectedNpi.primaryPracticeAddress.address1 ?? '',
      primaryContactPhone: this.selectedNpi.primaryPracticeAddress.telephonenumber ?? '',
      npiVerifiedByUser: this.npiVerified,
      npiVerifiedByMdm: this.selectedNpi.verified,
      npiVerifiedByMdmDate: this.selectedNpi.verifieddate,
      npiStatus: this.selectedNpi.basic.status.toLowerCase(),
      mdmId: this.selectedNpi.providerid,
      mdmVerifiedStatus: this.selectedNpi.verified? 'Verified':'NotVerified',
      firstName: '',
      lastName: '',
      gender: '0',
      middleInitial: '',
      verifiedFrom: this.selectedNpi.providerid ? 'MDM' : 'NPPS',
      verifiedDateFromUser: new Date(),
      ProviderStatus: (this.selectedNpi.basic.status != '' && this.selectedNpi.basic.status.toLowerCase() == 'd') ? 'Deactivated' : this.selectedNpi.isblacklisted == true ? 'Blacklisted' : '',
      ProviderStatusDetails: ''
    };

    if (res.ProviderStatus == "Blacklisted" && this.selectedNpi.isblacklisted == true && this.selectedNpi.oiginfo != null) {
      this.selectedNpi.oiginfo.exclusionsdate = this.GetDateFormat(this.selectedNpi.oiginfo.exclusionsdate);
      res.ProviderStatusDetails = JSON.stringify(this.selectedNpi.oiginfo);
    }

    if (this.config.NPIFor === 'user') {
      res.firstName = this.selectedNpi.basic.firstname ?? ''
      res.lastName = this.selectedNpi.basic.lastname ?? ''
      res.gender = String(this.selectedNpi.basic.gender)
      res.middleInitial = this.selectedNpi.basic.middleinitial ?? ''
    }

    this._dialogRef.close(res)
  }

  GetDateFormat(Date) {
    //Masking Phone Numbers
    if (Date != undefined && Date != null && Date != "") {
        Date = Date.replace(/\D/g, '');
        Date = Date.replace(/^(\d{4})/, '$1-');
        Date = Date.replace(/-(\d{2})/, '-$1-');
    }
    return Date;
}

  selectedRadioButton(event: any, result: any) {
    if (event != null) {
      this.selectedNpi = result;
      console.log(result);
    }
  }

  markRadio(j : number){    
    const radio = document.getElementById('rad'+j) as HTMLInputElement
    radio.checked = true
  }

  getNpiData() {
    // this.NPIList$ = new Observable();
    this.selectedNpi = null;
    this.npiVerified = false;
    this.errorMessage = ''

    if (this.config.NPIFor === 'location' && this.npiGetters.inputForLocation.value.length) {
      const value = this.npiGetters.inputForLocation.value
      if (!isNaN(Number(value))) {
        if (value.length === 10) this.formNPIRegistry.patchValue({ npi: value, providerName: '' })
        else {
          this.errorMessage = 'Invalid NPI Number'
          return
        }
      }
      else this.formNPIRegistry.patchValue({ npi: '', providerName: value })
    }
    else if (this.config.NPIFor === 'user' && (this.npiGetters.npi.value.length || this.npiGetters.firstName.value.length || this.npiGetters.lastName.value.length)) {
      const value = this.npiGetters.npi.value
      if (!isNaN(Number(value)) && this.npiGetters.npi.value.length) {
        if (value.length === 10) this.formNPIRegistry.patchValue({ npi: value, providerName: '' })
        else {
          this.errorMessage = 'Invalid NPI Number'
          return
        }
      }
      if (this.npiGetters.zip.value.length < 5 && this.npiGetters.zip.value.length) {
        this.errorMessage = 'Invalid Zip'
        return
      }

    }

    if (this.formNPIRegistry.valid && ((this.npiGetters.providerName.value ?? '').length > 0 || (this.npiGetters.npi.value ?? '').length > 0 || (this.npiGetters.firstName.value ?? '').length > 0 || (this.npiGetters.lastName.value ?? '').length > 0)) {

      const obj = { City: this.npiGetters.state.value ?? '', FirstName: this.npiGetters.firstName.value ?? '', LastName: this.npiGetters.lastName.value ?? '', NPI: this.npiGetters.npi.value ?? '', OrganizationName: this.npiGetters.providerName.value ?? '', State: this.npiGetters.state.value ?? '', Taxonomy: null, Zip: this.npiGetters.zip.value }

      this.NPIList$ = this._labAdminService.npiValidate(this.config.organizationid, this.mdmSearchKey, obj, this._labAdminService.deploymentKey)
        .pipe(
          map((result: any) => {
            if (result.length == 0) 
              this.errorMessage = 'No Records found '
            else {
              if(result.length === 1) this.opencontent=true
              else this.opencontent=false
              result = result.map((results: any) => ({
              ...results,
              primaryTaxonomy: results.taxonomies.filter((k: any) => k.primary)[0] ?? [],
              primaryPracticeAddress: results.address[results.address.length - 1] ?? [],
            }));            
          }
            return result
          }
          ),
          tap((res) => console.log(res))
        );
    }
    else {
      this.errorMessage = this.config.NPIFor == 'location' ? 'Please Provide NPI or Provider Name' : 'Please Provide NPI, First Name or Last Name'
    }
  }

  clearSearch() {
    this.formNPIRegistry.reset({ npi: '', providerName: '', city: '', state: '', zip: '', firstName: '', lastName: '' })
  }

  isOverflowPresent(scrollDiv) {
    this.isScrollPresent = scrollDiv?.scrollHeight > scrollDiv?.clientHeight
    return this.isScrollPresent
  }

  getStateAndCity() {
    const zipCode = this.formNPIRegistry.value.zip;
    if (zipCode && zipCode.length >= 5) {
      this._labAdminService.getStateAndCity(zipCode, "LIS")
        .subscribe({
          next: (data) => {
            if (data && data.City && data.State) {
              const matchingState = this.config.stateList.find(state => state.LookupValue === data.State);
              this.formNPIRegistry.patchValue({ state: matchingState.Description, city: data.City });
            }
            else {
              console.log("No data or city found for the provided ZIP code.");
              this.formNPIRegistry.patchValue({ state: '', city: '' });
            }
          },
          error: (err) => {
            console.error("Error fetching state and city:", err);
            this.formNPIRegistry.patchValue({ state: '', city: '' });
          }
        })
    }

  }
}

export class NPIDataFromLookup {
  npi: string
  firstName: string
  lastName: string
  middleInitial: string
  gender: string
  accountName: string
  city: string
  state: string
  zip: string
  address1: string
  primaryContactPhone: string
  npiVerifiedByUser: boolean
  npiVerifiedByMdm: boolean
  npiVerifiedByMdmDate: string
  npiStatus: string
  mdmId: number
  verifiedFrom: string
  mdmVerifiedStatus: string
  verifiedDateFromUser: Date
  ProviderStatus: string
  ProviderStatusDetails: string
}