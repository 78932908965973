import { Component, Input, OnInit, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-activity-tracker',
  templateUrl: './activity-tracker.component.html',
  styleUrls: ['./activity-tracker.component.scss']
})
export class ActivityTrackerComponent implements OnInit {
  @ViewChild('uiElement', { static: false }) scrollContainer!: ElementRef<HTMLElement>;

  @Input() searchstring: string;


  searchActivitydata: any[] = [];


  obj: any;
  SortedsearchActivitydata: any[] = [];
  public totalCount = 0;
  public pageIndex = 0;

  //SearchEntitytype: any = "Users";
  currentPage: number = 1;
  operationextendattr: any[] = [];
  operationdata: any;
  isLoading: boolean;
  isUsernameMatch: boolean;
  hideemptydata: boolean = false;
  //scrollContainer: Element;
  constructor(private commonservice: CommonService,
    public vitalHttpServices: VitalHttpServices,public ngxService: NgxUiLoaderService, private renderer: Renderer2, private elementRef: ElementRef
  ) {
    this.searchActivitydata = this.SortedsearchActivitydate(this.searchActivitydata)
  }

  public ngOnInit() {
        this.updateTrackerValues(this.searchstring);
  }


  ngOnChanges(changes: SimpleChanges): void {
    // this.updateTrackerValues(this.searchstring);
  }


  onScrollLoadData() {
    // Your existing scroll event handler code here

    // Check if the scroll position is greater than 100 pixels
    const scrollPosition = this.scrollContainer.nativeElement.scrollTop;
    if (scrollPosition > 100) {
      this.scrollContainer.nativeElement.classList.add('scroll-top-visible');
    } else {
      this.scrollContainer.nativeElement.classList.remove('scroll-top-visible');
    }
  }

  scrollToTop() {
    this.scrollContainer.nativeElement.scrollTop = 0;
  }






  updateTrackerValues(searchString) {
    if (searchString === 'All') {
      this.getAudits(this.pageIndex, "All");
      //this.getAudits(this.pageIndex, "Notes");
    } else if (searchString === 'Audit') {
      this.getAudits(this.pageIndex, "Audit");
    } else if (searchString === 'Comments') {
      this.getAudits(this.pageIndex, "Notes");
    }
  }
  async getAudits(index: number, searchEntitytype: string) {
    try {
      if (searchEntitytype == 'All') {
        this.obj = {
          "pageindex": index,
          "entityType": "User Details"
        };
      } else {
        this.obj = {
          "pageindex": index,
          "entityType": "User Details",
          "actionType": searchEntitytype
        };
      }
      const response: any = await this.vitalHttpServices.SearchActivityTracker(this.obj).toPromise();
      const response1: any = this.SortedsearchActivitydate(response.content);
      this.searchActivitydata = [...response1, ...this.searchActivitydata];
      this.groupItems();
    } catch (error) {
      console.log(error);
    }
  }


  getObjectValues(obj: object): any[] {
    return Object.values(obj);
  }




  startBackgroundLoad() {
    this.isLoading = true;

    setTimeout(() => {
      // Simulating a background data retrieval

      this.groupItems();
      //this.pageIndex += 1;
      this.isLoading = false;
    }, 1000); // Set the timeout to 1 seconds (3000 milliseconds)
  }






  SortedsearchActivitydate(objects: any[]): any[] {
    return objects.sort((a, b) => new Date(a.createddate).getTime() - new Date(b.createddate).getTime());
  }


  isIdMatch(id: any): boolean {
    const storedId = localStorage.getItem('UserEmail'); // Replace 'name' with your desired key from localStorage
    return id == storedId;

  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    //return this.datePipe.transform(date, 'dd-MM-yyyy | HH:mm a');
    let finaldate = this.getRelativeTime(date);
    //return this.datePipe.transform(date, 'dd-MM-yyyy');
    return finaldate;
  }

  getRelativeTime(timestamp: Date): string {
    const now = new Date();
    const diff = now.getTime() - timestamp.getTime();

    // Define time intervals in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;

    if (diff < minute) {
      return 'just now';
    } else if (diff < hour) {
      const minutesAgo = Math.floor(diff / minute);
      return `${minutesAgo} min${minutesAgo > 1 ? 's' : ''} ago`;
    } else if (diff < day) {
      const hoursAgo = Math.floor(diff / hour);
      return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else {
      const daysAgo = Math.floor(diff / day);
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    }
  }





  groupedItems: { key: { username: string, userid: any, createddate: any }, values: any[] }[];


  groupItems() {
    this.groupedItems = [];
    this.ngxService.start();
    for (let item of this.searchActivitydata) {
      const group = this.groupedItems.find((g) => g.key.username === item.username);

      if (group) {
        group.values.push(item);
      } else {
        this.groupedItems.push({ key: { username: item.username, userid: item.userid, createddate: item.createddate }, values: [item] });
      }
    }

    const matchingUsernames = [];
    const remainingUsernames = [];

    this.groupedItems.forEach((group) => {
      group.values.sort((a, b) => new Date(b.createddate).getTime() - new Date(a.createddate).getTime());
      const matchingItems = group.values.filter((item) => this.isIdMatch(group.key.userid));
      const remainingItems = group.values.filter((item) => !this.isIdMatch(group.key.userid));

      if (matchingItems.length > 0) {
        matchingUsernames.push({ key: group.key, values: matchingItems });
      }

      if (remainingItems.length > 0) {
        remainingUsernames.push({ key: group.key, values: remainingItems });
      }
    });

    // Concatenate the matching usernames and remaining usernames
    this.groupedItems = matchingUsernames.concat(remainingUsernames);
    this.groupedItems.forEach((group) => {
      group.values.sort((a, b) => new Date(b.createddate).getTime() - new Date(a.createddate).getTime());
    });
    this.ngxService.stop();
  }








  hasOperationValues(values: any[]): boolean {
    for (let item of values) {
      if (item !== null && item.operation !== null && item.operation.extendedattributes !== null && item.operation.extendedattributes.operation !== null) {
        return true; // Return true if any item has non-null operation values
      }
    }
    return false; // Return false if all items have null operation values
  }



  getInitials(firstName: string): string {
    const names = firstName.split(' ');
    let initials = '';
    for (const name of names) {
      initials += name.charAt(0);
    }
    return initials;
  }




}
