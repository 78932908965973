<div class="my-2">
  <article class="my-2" *ngFor="let shm of [].constructor(line)" >
      <div class='user'>
        <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      </div>
      <div class="shimmer"></div>
  </article>
</div>
