import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAddCheckOrCross]'
})
export class AddCheckOrCrossDirective implements OnInit {
  @Input('appAddCheckOrCross') isChecked : string = ''
  @Input() width : string = '14px'

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if(+this.isChecked === -1){
      const text = this.renderer.createText('-')
      this.renderer.appendChild(this.elementRef.nativeElement, text)
    }
    else{
      const image = this.isChecked ? '/assets/icons/Active_Green.svg' : '/assets/icons/Inactive_Red.svg'
      const alt = this.isChecked ? 'Active' : 'Inactive'
      const element = this.renderer.createElement('img')
      this.renderer.setAttribute(element,"src",image)
      this.renderer.setAttribute(element,"alt",alt)
      this.renderer.setAttribute(element,"width",this.width)
      this.renderer.appendChild(this.elementRef.nativeElement, element)
    }
  }
  

}
