<div *ngIf="gridPage" class="id-format">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{actionTitle}}</strong>
        </h3>
    </div>
    <!-- // Accession list -->
    <div *ngIf="gridwidth > 0" class="modal-content bordernone">
        <div *ngIf="showDefaultFormats" class="button-field-wrap p-0 m-0 row col-sm-12">
            <div class="instr-size mb-1 col-sm-12 p-0">
                <strong>
                    Note: No data configured for this Casetype / Group. Format shown in the grid will be
                    generated by
                    default.
                </strong>
            </div>
        </div>
        <wj-flex-grid [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="formatGridData"
            #grid (initialized)="initGrid(grid)">
            <wj-flex-grid-column *ngIf="!showDefaultFormats" [header]="'Action'" align="center" [freeze]="true"
                [width]="82" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                    <button  class="edit-delete-btn" [disabled]="hideEditBtn">
                        <em id="editButton" (click)="openEditScreen(grid,$event)" [title]="'Edit'"
                            class="fa fa-edit"></em>
                    </button>
                    <button [disabled]="hideDeleteBtn" class="edit-delete-btn">
                        <em id="deleteButton" (click)="deleteRecord(grid,$event)" [title]="'Delete'"
                            class="fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="'*'" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
                [header]="col.header" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="col-sm-12 mt-4 align-center">
            <wj-collection-view-navigator class="mr-2" *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:no} of {pageCount:no}" [byPage]="true" [cv]="formatGridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="formatGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <div class="col-sm-12 mt-4 align-center">
            <div class="sub-button">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="exportExcelData()">Export</button>
                <button mat-raised-button class="admin-btn-success mr-4" [disabled]="hideCreateBtn"
                    (click)="createFormat()">Create</button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideUploadBtn"
                    (click)="loadUploadScreen()">Upload</button>
            </div>
        </div>
    </div>

    <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridwidth == 0">
        <div class="col-sm-10 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>

    <div class="align-center" *ngIf="gridwidth == 0">
        <div class="sub-button mt-4">
            <button [disabled]="hideCreateBtn" (click)="createFormat()" mat-raised-button
                class="admin-btn-success mr-4">Create</button>
            <button [disabled]="hideUploadBtn" (click)="loadUploadScreen()" mat-raised-button
                class="admin-btn-success mr-4">Upload</button>
        </div>
    </div>
</div>

<!-- Create/Edit Accession  -->
<div *ngIf="createScreen" class="col-sm-12">
    <div class="text-wrap">
        <div class="modal-header admin-model-header mb-2 mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>{{actionTitle}}</strong>
            </h3>
        </div>
        <form [formGroup]="formatForm">
            <div class="col-sm-12 d-flex ">
                <mat-form-field appearance="outline" class="w-100 col-sm-4">
                    <mat-label><strong><span class="text-danger">*</span> Default Accession</strong></mat-label>
                    <input type="text" title="{{formatForm?.value.frmAltFormat}}" matInput maxlength="100"
                        [matAutocomplete]="altFormat" formControlName="frmAltFormat"
                        #triggerAltFormat="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button (click)="clearAltFormat($event)"
                            *ngIf="formatForm?.value.frmAltFormat!==null && formatForm?.value.frmAltFormat!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button (click)="openOrClosePanel($event, triggerAltFormat)" aria-label="Clear" mat-icon-button
                            type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject6.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete #altFormat="matAutocomplete" (closed)="arrowIconSubject6.next('arrow_drop_down6')"
                        (opened)="arrowIconSubject6.next('arrow_drop_up')"
                        (optionSelected)="arrowIconSubject6.next('arrow_drop_down6')" [displayWith]="displayFn">
                        <mat-option class="mat-opt-align"  title=" {{ obj }}"
                            *ngFor="let obj of filterFormat(formatForm.value.frmAltFormat, 'alternateformat')"
                            disableOptionCentering value="{{ obj }} ">
                            {{ obj }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 col-sm-4">
                    <mat-label><strong>Custom Accession</strong></mat-label>
                    <input type="text" title="{{formatForm?.value.frmFormat}}" matInput maxlength="100"
                        [matAutocomplete]="Format" formControlName="frmFormat" #triggerFormat="matAutocompleteTrigger"
                        [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button (click)="clearFormat($event)"
                            *ngIf="formatForm?.value.frmFormat!==null && formatForm?.value.frmFormat!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button (click)="openOrClosePanel($event, triggerFormat)" aria-label="Clear" mat-icon-button
                            type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject7.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete #Format="matAutocomplete" (closed)="arrowIconSubject7.next('arrow_drop_down7')"
                        (opened)="arrowIconSubject7.next('arrow_drop_up')"
                        (optionSelected)="arrowIconSubject7.next('arrow_drop_down7')" [displayWith]="displayFn">
                        <mat-option class="mat-opt-align"  title=" {{ obj }}"
                            *ngFor="let obj of filterFormat(formatForm.value.frmFormat, 'format')"
                            disableOptionCentering value="{{ obj }} ">
                            {{ obj }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="divider col-sm-12 d-flex"><b>Criteria for Accession Prefix</b></div>

            <div class="col-sm-12 mt-2">
                <mat-form-field appearance="outline" class="w-100 col-sm-4">
                    <mat-label><strong><span class="text-danger">*</span> Entity Type</strong></mat-label>
                    <input type="text" title="{{formatForm?.value.frmType}}" matInput maxlength="100"
                        [matAutocomplete]="entityType" formControlName="frmType" #trigger5="matAutocompleteTrigger"
                        [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button (click)="clearEntityType($event)"
                            *ngIf="formatForm?.value.frmType!==null && formatForm?.value.frmType!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button (click)="openOrClosePanel($event, trigger5)" aria-label="Clear" mat-icon-button
                            type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject5.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete #entityType="matAutocomplete"
                        (closed)="arrowIconSubject5.next('arrow_drop_down5')"
                        (opened)="arrowIconSubject5.next('arrow_drop_up5')"
                        (optionSelected)="arrowIconSubject5.next('arrow_drop_down5')" [displayWith]="displayFn">
                        <mat-option class="mat-opt-align" title=" {{ entity.entitytype }}"
                            *ngFor="let entity of filterEntityType(formatForm.value.frmType)" disableOptionCentering
                            value="{{ entity.entitytype }} "
                            (onSelectionChange)="getEntityName($event,entity.entitytype)">
                            {{ entity.entitytype }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100 col-sm-4">
                    <mat-label><strong><span class="text-danger">*</span> Entity</strong></mat-label>
                    <input [readonly]="disableEntityName" type="text" title="{{formatForm?.value.frmEntity}}" matInput
                        maxlength="100" [matAutocomplete]="enitity" formControlName="frmEntity"
                        #trigger="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button (click)="clearEntity($event)" [disabled]="disableEntityName"
                            *ngIf="formatForm?.value.frmEntity!==null && formatForm?.value.frmEntity!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button (click)="openOrClosePanel($event, trigger)" [disabled]="disableEntityName"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete [readonly]="disableEntityName" #enitity="matAutocomplete"
                        (closed)="arrowIconSubject.next('arrow_drop_down')"
                        (opened)="arrowIconSubject.next('arrow_drop_up')"
                        (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                        <mat-option class="mat-opt-align"  title=" {{ entity.entityname }} ({{ entity.entityid }})"
                            *ngFor="let entity of filterEntity(formatForm.value.frmEntity)" disableOptionCentering
                            value="{{ entity.entityname }} ({{ entity.entityid }})">
                            {{ entity.entityname }} ({{ entity.entityid }})
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 col-sm-4">
                    <mat-label><strong> Ordering Facility</strong></mat-label>
                    <!-- [readonly]="formatForm?.value.frmBillto?.length > 0 && formatForm?.value.frmSerType?.length > 0" -->
                    <input type="text"
                        title="{{formatForm?.value.frmOrdFacility}}" matInput maxlength="100"
                        [matAutocomplete]="orderingFac" formControlName="frmOrdFacility"
                        #trigger1="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button
                            (click)="clearOrdFacility($event)"
                            *ngIf=" formatForm?.value.frmOrdFacility!==null && formatForm?.value.frmOrdFacility!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button
                            (click)="openOrClosePanel1($event, trigger1)" aria-label="Clear" mat-icon-button
                            type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject1.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete #orderingFac="matAutocomplete"
                        (closed)="arrowIconSubject1.next('arrow_drop_down1')"
                        (opened)="arrowIconSubject1.next('arrow_drop_up1')"
                        (optionSelected)="arrowIconSubject1.next('arrow_drop_down1')" [displayWith]="displayFn">
                        <div *ngFor="let a of filterOF(formatForm.value.frmOrdFacility)">
                            <mat-option class="mat-opt-align" (onSelectionChange)="getOfID($event,a)"
                                title="{{a.organizationname}} ({{a.organizationid}})" disableOptionCentering
                                value="{{a.organizationname}} ({{a.organizationid}})">
                                {{ a.organizationname }} ({{ a.organizationid }})
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-sm-12 d-flex mb-2 pb-4">

                <ng-multiselect-dropdown class="col-sm-4 text-xs" [placeholder]="'Bill To'"
                    #matSelectOption formControlName="frmBillto" [settings]="dropdownSettings"
                    [data]="userDropdownList2" (onSelect)="onCheckChange($event,'')" (onDeSelect)="onItemDeSelect($event,'billto')"
                    (onSelectAll)="onSelectAll($event, '')" (onDeSelectAll)="onDeSelectAll('')">
                </ng-multiselect-dropdown>


                <ng-multiselect-dropdown class="col-sm-4 text-xs" [placeholder]="'Services'"
                    #matSelectOption formControlName="frmSerType" [settings]="dropdownSettings"
                    [data]="userDropdownList1" (onSelect)="onCheckChange($event,'services')" (onDeSelect)="onItemDeSelect($event, 'services')"
                    (onSelectAll)="onSelectAll($event,'services')" (onDeSelectAll)="onDeSelectAll('services')">
                </ng-multiselect-dropdown>


                <div class="w-100 col-sm-4 check-box-wrap">
                    <span class="d-flex col-sm-8 pl-0">
                        <label class="example-margin mr-2 ml-0"><strong>Caseflow Type:</strong></label>
                        <mat-checkbox class="example-margin mr-2" formControlName="frmCaseflowtype"
                            [color]="task.color">
                            <strong>QC</strong>
                        </mat-checkbox>
                    </span>

                    <mat-checkbox class="example-margin" formControlName="frmLabaccId" [color]="task.color">
                        <strong>This location</strong>
                    </mat-checkbox>
                </div>
            </div>
        </form>
    </div>
    <div class="row mt-4">
        <div class="col-sm-3">
            <button mat-raised-button class="admin-btn-success" (click)="backtoGrid()">Back</button>
        </div>
        <div class="col-sm-9 button-wrap">
            <button mat-raised-button class="admin-btn-success mr-4"
                (click)="saveCloseFormatData('',formatForm.value)">Save
                & Close</button>
            <button mat-raised-button *ngIf="!editEnabled" class="admin-btn-success"
                (click)="saveCloseFormatData('new',formatForm.value)">Save
                & Create</button>
        </div>
    </div>
</div>

<!-- // Upload Accession -->
<div *ngIf="uploadClicked">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{actionTitle}}</strong>
        </h3>
    </div>

    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="mat-opt-align" (click)="downloadTemplate('Minimal')">
                    <mat-icon style="font-size: 120% !important" class="pr-2 get-app">get_app</mat-icon>Minimal
                    Fields
                </mat-option>
                <mat-option class="mat-opt-align" (click)="downloadTemplate('All')">
                    <mat-icon style="font-size: 120% !important" class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="mat-opt-align" (click)="downloadTemplate('Bulk Update')">
                    <mat-icon style="font-size: 120% !important" class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" class="mb-1 mr-1" alt="help" src="/assets/icons/help.svg" width="15px"
                height="auto" />
            You can download the sample template file to upload the Accession Prefix.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn btn-sm rounded admin-btn-success mt-2 browse ml-5" for="fileDropRef">Browse file</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="backtoGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="formatGridData"  #grid1
            (initialized)="initGrid(grid1)" [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)"
            [frozenColumns]="2">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [allowResizing]="true" [width]="120" [allowDragging]="false"
                    [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="'*'"
                    [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="120"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3 mt-4">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success" (click)="backtoGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-field-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                        (click)="backtoGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                        (click)="removeGrid()">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()"
                        class="admin-btn-success mr-4" (click)="uploadData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportToExcel(grid1)">Download
                        Result</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>

