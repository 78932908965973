import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';

@Component({
  selector: 'app-infonpi',
  templateUrl: './infonpi.component.html',
  styleUrls: ['./infonpi.component.scss']
})
export class InfonpiComponent {

  userid = ''

  @Input() userDetials: any
  @Input() fromLocation:string='';
  npiDetails = {}
  otherIdentifiers = []
  noDataFoundFlag: boolean = false;

  constructor(private labadminService: LabadminService) {
  }

  ngOnInit() {
    if(this.fromLocation == 'location'){
      this.getLocationNpiDetails();
    }
    else{
      this.getNPIDetails();
    }
  }

  checkNPIDetails() {
    return Object.keys(this.npiDetails).length
  }

  getNPIDetails() {
    if (this.userDetials['NPI']?.toLowerCase() === 'unknown' || !this.userDetials['NPI']) {
      this.noDataFoundFlag = true;
      return
    }
    else {
      let objData = {
        City: null,
        FirstName: null,
        LastName: null,
        NPI: this.userDetials['NPI'],
        OrganizationName: null,
        State: null,
        Taxonomy: null,
        Zip: null
      }
      this.userid = this.userDetials['UserID'];
      this.labadminService.npiDetails(this.labadminService.deploymentKey, this.userid, objData).subscribe(data => {
        if (data.length)
          this.npiDetails = data[0];
        else
          this.noDataFoundFlag = true
      })
    }
  }

  getLocationNpiDetails(){
    if(this.userDetials?.['NPI']?.toLowerCase() == 'unknown'){
      this.noDataFoundFlag = true;
      return;
    }
    let objData = {
      City: '',
      FirstName: '',
      LastName: '',
      NPI: this.userDetials['NPI'],
      OrganizationName: '',
      State: '',
      Taxonomy: '',
      Zip: ''
    }
    this.labadminService.locationNpiDetails(this.labadminService.deploymentKey, this.labadminService.organizationId, objData).subscribe(data => {
      if (data.length)
        this.npiDetails = data[0];
      else
        this.noDataFoundFlag = true
    })
  }

  // remove comma(,) and null in the address
  showAddressValue(addressDetailsArray) {
    let value = ""
    addressDetailsArray.forEach(val => {
      if (val)
        value += val + ',';
    });
    return value.toString().trim()[value.length - 1] == ',' ? value.substring(0, value.length - 1) : value;
  }
}
