import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { LabadminSessionService } from '../../services/labadmin-session.service';

@Component({
  selector: 'app-location-preferences',
  templateUrl: './location-preferences.component.html',
  styleUrls: ['./location-preferences.component.scss'],
})
export class LocationPreferencesComponent {
  @Input() locationInfo: any;
  preferenceinfo: any;
  userSessionDetails: any;
  isPcEnabled: boolean = false;
  additionalSettings: any = [];
  showAdditionalSettings:boolean = false;
  showLoader: boolean = true;

  constructor(
    private labAdminservice: LabadminService,
    private labAdminSessionService: LabadminSessionService
  ) {}

  ngOnInit() {
    this.labAdminSessionService.getLabAdminSession.subscribe(
      async (session) => {
        this.userSessionDetails = session['userDetails'];
      }
    );
    this.getPCEnabledInfo();
    this.getCustomOrgValues();
  }
  getPCEnabledInfo() {
    const accountIdLst = this.userSessionDetails.userAccountIDs.map((account) =>
      account.accountId.toString()
    );
    const accountIds = accountIdLst.join(',');
    this.locationInfo.AccountID;
    this.labAdminservice
      .GetIsPcEnabled(
        accountIds,
        this.locationInfo.AccountID,
        Number(this.labAdminservice.organizationId),
        this.labAdminservice.deploymentKey
      )
      .subscribe((data) => {
        this.isPcEnabled = data?.IsPcEnabled == "true" ? true: false;
      });


  }
  getCustomOrgValues() {
    this.labAdminservice
      .CustomOrgValues(
        Number(this.labAdminservice.organizationId),
         this.locationInfo.AccountID,
        //46057,
        3,
        this.labAdminservice.deploymentKey
      )
      .subscribe((data) => {
        if (data) {
          this.showLoader = false;
          this.additionalSettings = data;
          this.additionalSettings.filter((c) => {
            if (c?.Value?.length > 0 ) {
              this.showAdditionalSettings = true;
            }
          });
        }
        else{
          this.showLoader = false;
        }
      },(error) =>{
        this.showLoader = false;
        console.error(error);
      });
  }
}
