import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuActiveItemService {
  private activeItemIdSubject = new BehaviorSubject<any>(null);
  activeItem$ = this.activeItemIdSubject.asObservable();

  setActiveItemId(navvalue: any) {
    this.activeItemIdSubject.next(navvalue);
  }
}
