<div mat-dialog-title class="fax-header">
    <span>{{data.header}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content class="fax-content">
    <div class="fax-input" *ngIf="labAccountDetails.length > 1" [formGroup]="faxForm">
        <app-custom-select labelValue="Laboratory" formcontrolname="laboratory" [required]="true"
            [dropDownValues]="labAccountDetails" dropDownKeyProp="DisplayName" dropDownValueProp="AccountId"
            defaultOption="Select Laboratory"></app-custom-select>
    </div>
    <ng-container *ngIf="loader$|async as loader">
    <div [class]="labAccountDetails.length === 1 ? 'fax-content-height-without-search' : 'fax-content-height'" *ngIf="faxAdvanceAlert==''&&getter.value">
        <div>
            <app-shimmerui [line]="5" *ngIf="loader.caseListSection.loading"></app-shimmerui>
            <div *ngIf="!loader.caseListSection.loading">
                <div>
                    <div class="d-flex mb-3">
                        <div class="heading">
                            <b>Case Type</b>
                        </div>                        
                        <div class="each-item ml-3" *ngIf="!loader.caseListSection.loading" >
                            <input type="checkbox" [checked]="isAllCasesCategorySelected(caseCategoriesList)"
                                (click)="$event.stopPropagation()"
                                (change)="$event?toggleAllCaseCategory(caseCategoriesList):null">
                            <span (click)="cAll.click()">All</span>
                        </div>
                    </div>
                    <app-shimmerui [line]="5" *ngIf="loader.caseListSection.loading"></app-shimmerui>
                    <div *ngIf="!loader.caseListSection.loading">
                        <div class="mb-4" *ngFor="let caseCategory of caseCategoriesList; let i = index">
                            <div class="each-item vdm-b-13px">
                                <input type="checkbox" #inp
                                    [checked]="caseCategory.selectionModel.hasValue() && isAllCaseSelected(caseCategory.Cases,caseCategory.selectionModel)"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? toggleAllCases(caseCategory,caseCategory.selectionModel):null">
                                <b (click)="inp.click()">{{caseCategory.Category}}</b>
                            </div>
                            <div class="grid-case-list mb-1 pl-4">
                                <div class="each-item" *ngFor="let caseTypes of caseCategory.Cases">
                                    <input #ctype type="checkbox" (click)="$event.stopPropagation()"
                                        (change)="$event?caseCategory.selectionModel.toggle(caseTypes.CaseTypeId):null"
                                        [checked]="caseCategory.selectionModel.isSelected(caseTypes.CaseTypeId)">
                                    <span (click)="ctype.click()" appTooltipEllipsis>{{caseTypes.CaseTypeDisplayname}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="servicesList">
                    <div class="d-flex mb-3">
                        <div class="heading">
                            <b>Service Type</b>
                        </div>
                        <div class="each-item ml-3">
                            <input #sAll type="checkbox" (change)="$event ? toggleAllServices() : null"
                                [checked]="orderingServiceSelection.hasValue() && isSelectedService()">
                            <span (click)="sAll.click()">All</span>
                        </div>
                    </div>
                    <app-shimmerui [line]="2" *ngIf="loader.servicesSection.loading"></app-shimmerui>
                    <div class="service" *ngIf="!loader.servicesSection.loading">
                        <div class="each-item" *ngFor="let service of servicesList; let i = index">
                            <input #ser type="checkbox" (click)="$event.stopPropagation()"
                                (change)="$event ? orderingServiceSelection.toggle(service) : null"
                                [checked]="orderingServiceSelection.isSelected(service)">
                            <span (click)="ser.click()" appTooltipEllipsis>{{service.value}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="physicianList.length">
                    <div class="d-flex mb-3">
                        <div class="heading ">
                            <b> Physicians</b>
                        </div>
                        <div class="each-item ml-3">
                            <input #pAll type="checkbox" (change)="$event ? toggleAllPhysicians() : null"
                                [checked]="orderingPhysicianSelection.hasValue() && isSelectedPhysicains()">
                                <span (click)="pAll.click()">All</span>
                        </div>
                    </div>
                    <app-shimmerui [line]="4" *ngIf="loader.physicianSection.loading"></app-shimmerui>
                    <div class="service" *ngIf="!loader.physicianSection.loading">
                        <div class="each-item" *ngFor="let case of physicianList">
                            <input #phy type="checkbox" (click)="$event.stopPropagation()"
                                (change)="$event ? orderingPhysicianSelection.toggle(case) : null"
                                [checked]="orderingPhysicianSelection.isSelected(case)">
                            <span (click)="phy.click()" appTooltipEllipsis>{{case.Physician}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="grid-popupButton">
                <div class="row pl-5 align-items-center">
                    <app-button class="ml-auto col-1" buttonclass="secondary" (click)="onNoClick()">Return</app-button>
                    <app-button class="creation-btn-width" buttonclass="primary"
                        (click)="saveCaseType()">Save</app-button>
                </div>
            </div> -->
        </div>
    </div>
    <div *ngIf="faxAdvanceAlert!=''">
        {{faxAdvanceAlert}}
    </div>
</ng-container>
</mat-dialog-content>
<mat-dialog-actions allign="end">
    <div class="my-2 mx-4 d-flex align-items-center w-100">        
        <app-button mat-dialog-close class="ml-auto fax-btn mr-3" buttonclass="secondary" customClass="admin" >Return</app-button>
        <app-button buttonclass="primary" class="fax-btn" customClass="admin" (click)="saveCaseType()">Save</app-button>
    </div>
</mat-dialog-actions>