import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ClickedTabData } from '../../model/Tab';
import { Tab } from './tab.directive';

@Component({
  selector: 'vs-tabset',
  // directives: [NgClass, NgTransclude],
  template: `
    <div class="nav-active-border b-warning bottom m-b-sm">
    <!-- <div class="leftScroll entity-color scroller" (click)="leftScroll()" *ngIf="isScrollVisible" tabindex="0">
    <em class="fa fa-chevron-circle-left text-md"></em>
    </div>

    <div class="rightScroll scroller"  (click)="rightScroll()" *ngIf="isScrollVisible"  tabindex="0">
    <em class="fa fa-chevron-circle-right text-md"></em>
    </div> -->
    <div class="nav nav-md tabWrapper">
    <ul class="nav nav-md tabsSet" (click)="$event.preventDefault();">
        <li class="nav-item tabitem" *ngFor="let tabz of tabs; let i= index" >
            <a class="nav-link" data-toggle="tab" placement="top" *ngIf="tabz.TabName"   matTooltip="{{tabz.TabName}}" container="body"
                href="{{tabz.TabGUID}}" aria-expanded="true"  title="{{tabz.TabName}}"
                (click)="updateTab(tabz.TabName,true,i,tabz)" [class.active]="tabz.active" id="{{tabz.TabGUID}}" [class.disabled]="tabz.disabled">
                {{tabz.TabName | ellipsis:20}}  <span *ngIf=!tabz.Removable> ( {{tabz.Count}} )</span>
                <a class="text-danger ml-2" (click)="fnRemoveTab(tabz)" (click)="$event.preventDefault();"  title="Close" *ngIf=tabz.Removable>
                    <em class="fa fa-remove text-xs"></em></a>
            </a>
        </li>
        <li *ngIf="this.tabs.length>1" class="nav-item tabitem">
        </li>
    </ul>
  </div>
<div>
<div>
</div>
    <div class="tab-content" style="height: 80vh;overflow-y: auto;overflow-x: hidden;">
       <ng-content></ng-content>
    </div>
  `
})

// <div class="b-b b-primary nav-active-primary">
// <ul class="nav nav-tabs" (click)="$event.preventDefault();">
//     <li class="nav-item" *ngFor="let tabz of tabs; let i= index" >
//         <a class="nav-link" data-toggle="tab" placement="top" *ngIf="tabz.TabName" matTooltip="{{tabz.TabName}}" container="body"
//             href="{{tabz.TabGUID}}" aria-expanded="true"  title="{{tabz.TabName}}"
//             (click)="updateTab(tabz.TabName,true,i,tabz)" [class.active]="tabz.active" id="{{tabz.TabGUID}}" [class.disabled]="tabz.disabled">
//             {{tabz.TabName | ellipsis:20}}  <span *ngIf=!tabz.Removable> ( {{tabz.Count}} )</span>
//             <a class="text-white" (click)="fnRemoveTab(tabz)" (click)="$event.preventDefault();"  title="Close" *ngIf=tabz.Removable>
//                 <em class="fa fa-remove text-xs"></em></a>
//         </a>
//     </li>
// </ul>
// </div>

export class Tabset implements OnInit {
  directives: [NgClass];
  @Input() public ClearTabs: any;
  @Output() delevent = new EventEmitter<any>();
  @Output('currentTabSelected') tabSelected = new EventEmitter<ClickedTabData>();

  // Scroll controll for tabs
  public isScrollVisible = true;
  public rightCount = 0;
  public leftCount = 0;
  index: any;
  public widthOfList() {
    var itemsWidth = 0;
    let tabsetel = document.querySelector('.tabsSet')
    var tablist: any = tabsetel ? tabsetel.childNodes : null;
    if (tablist && tablist.length != 0) {
      for (let i = 0; i < tablist.length; i++) {
        let itemWidth = tablist[i].offsetWidth;
        itemsWidth = itemWidth ? itemsWidth += itemWidth : itemsWidth;
      }
    }
    return itemsWidth
  }

  //VitalDX url in new tab
  goToLink(dxurl: string) {
    window.open(dxurl)
  }

  public widthOfTab() {
    const tab: any = document.querySelector('.tabWrapper');
    return tab && tab.length != 0 ? tab.offsetWidth : 0;
  }
  public leftScroll() {
    var tablist: any = document.querySelector('.tabsSet') ? document.querySelector('.tabsSet').getElementsByTagName('li') : [];
    let temp = [];
    for (let i = 0; i <= tablist.length - 1; i++) {
      if (tablist[i].style.display === 'none') {
        temp.push(i)
      }
    }
    temp.length > 0 ? tablist[temp[temp.length - 1]].style.display = 'block' : null;
    // tablist[this.rightCount].style.display = 'block';
    this.rightCount > 0 ? this.rightCount-- : null;
    //  tablist[this.leftCount].style.display = 'none';
    // this.leftCount--;
  }

  public rightScroll() {
    let a = this.widthOfTab();
    let b = this.widthOfList();
    if ((b + 90) > a) {
      var tablist: any = document.querySelector('.tabsSet') ? document.querySelector('.tabsSet').getElementsByTagName('li') : [];
      tablist && tablist.length != 0 ? tablist[this.rightCount].style.display = 'none' : null;
      this.rightCount++;
      // b + 90 > a ? this.rightScroll() : null ;
    }
  }

  public tabs: Array<Tab> = [];
  public isDestroyed: boolean;

  constructor(private DatabaseService: VitalHttpServices) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }


  public addTab(tab: Tab) {
    if (tab.TabName !== "Search") {
      if (this.tabs.length > 0) {
        let exeis = this.tabs.findIndex(val => val.TabGUID === null)
        if (exeis !== -1) {
          this.tabs.splice(exeis, 1)
        }
      }
      this.tabs.push(tab);
      tab.active = this.tabs.length === 1 && tab.active !== false;
    }
  }

  public updateTab(tabname, isclick, index, tabs) {
    this.index = index;
    const data = this.BuildTabData(tabname, isclick, index, tabs);
    if (tabs == null || tabs.active == false) {
      tabs ? tabs.active = true : null;
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].active) {
          if (tabs == null) {
            this.tabSelected.emit(this.BuildTabData(this.tabs[i].TabName, false, i, this.tabs[i]))
          } else {
            this.tabSelected.emit(data);
          }//end of if -> for tabs null i.e remove tab
        }//end of if -> check for active tab
      }//end of for each
    }//end of outer if
    //let deployment = this.tempvalue.DisplayField.deploymentkey.toLowerCase();
  }

  BuildTabData(tabname, isclick, index, tabs) {
    const data = new ClickedTabData();
    data.TabName = tabname;
    data.IsClick = isclick;
    data.Index = index;
    data.Tab = tabs;
    return data;
  }

  public fnRemoveTab(tab: Tab) {
    let index = this.tabs.indexOf(tab);
    if (index === -1 || this.isDestroyed) {
      return;
    }
    let newActiveIndex;
    // Select a new tab if the tab to be removed is selected and not destroyed
    if (this.hasAvailableTabs(index)) {
      newActiveIndex = this.getClosestTabIndex(index);
      this.tabs[newActiveIndex].active = true;
    }
    tab.removed.emit(tab);
    this.tabs.splice(index, 1);
    this.leftScroll();
    setTimeout(() => {
      this.updateTab(null, null, null, null);
    }, 20);
  }

  public getClosestTabIndex(index: number): number {
    let tabsLength = this.tabs.length;
    if (!tabsLength) {
      return -1;
    }
    for (let step = 1; step <= tabsLength; step += 1) {
      let prevIndex = index - step;
      let nextIndex = index + step;
      if (this.tabs[prevIndex] && !this.tabs[prevIndex].disabled) {
        return prevIndex;
      }
      if (this.tabs[nextIndex] && !this.tabs[nextIndex].disabled) {
        return nextIndex;
      }
    }
    return -1;
  }

  public hasAvailableTabs(index: number) {
    let tabsLength = this.tabs.length;
    if (!tabsLength) {
      return false;
    }
    for (let i = 0; i < tabsLength; i += 1) {
      if (!this.tabs[i].disabled && i !== index) {
        return true;
      }
    }
    return false;
  }
}
