<mat-spinner class="labadmin-spinner" *ngIf="(sourceApp === 'VitalDx') && page=='list' && !(reflexRulesList$|async)?.['data']"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner>

<div class="modal-body set-location-wrap" *ngIf="sourceApp === 'VitalDx' ? (page && (reflexRulesList$|async)?.['data']) : true">
    <div class="row">
        <div class="col-sm-12 p-0">
            <div *ngIf="page=='list' && sourceApp !== 'VitalDx'" class="modal-content bordernone">
                <div class="modal-header admin-model-header headering listingModalHeader">
                    <h3 class="modal-title w-100 admin-model-header-txt">
                        <strong class="header-View">{{page=='list'?'Manage': 'Edit'}} Reflex Rules</strong>
                    </h3>
                </div>
            </div>
            <section *ngIf="reflexRulesList$|async as reflexrules">
                <div class=" mt-2" *ngIf="page=='list'&&!reflexrules.isNoDataPresent">
                    <div class="d-flex align-items-center mt-3 mb-2 filters">
                        <span class="not-selectable" tabindex="0"
                            [class.filterselected]="filtereReflex.value.status=='all'"
                            (click)="toggleButton({status:'all'})">All ({{reflexrules.totalCount}})</span>
                        <span id="seperator"></span>
                        <div class="filters d-flex not-selectable flex-wrap">
                            <span class="not-selectable" [class.filterselected]="filtereReflex.value.status=='active'"
                                tabindex="0" (click)="toggleButton({status:'active'})" [class.filterDisabled]="(reflexrules.activeCount <= 0)">
                                Active ({{reflexrules.activeCount}})
                            </span>
                            <span class="not-selectable" [class.filterselected]="filtereReflex.value.status=='inactive'"
                                tabindex="0" (click)="toggleButton({status:'inactive'})" [class.filterDisabled]="(reflexrules.totalCount-reflexrules.activeCount <= 0)">
                                Inactive ({{reflexrules.totalCount-reflexrules.activeCount}})
                            </span>

                        </div>
                        <app-search-box class="ml-auto search-box-width "
                            (enteredValue)="toggleButton({searchText:$event})" placeHolder="Search by Rule Name or Casetype"></app-search-box>

                    </div>
                </div>

                <ng-container>
                    <div class="mb-1 mt-2" *ngIf="page=='list'&& !reflexrules.isNoDataPresent" [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
                        <div class="header">
                            <div class="rr-header m-1 ml-3">
                                <div class="col">Name</div>
                                <div class="col">Case Type</div>
                                <div class="col">Effective Date</div>
                                <div class="col">Expiry Date</div>
                                <div class="justify-self-end">
                                    <span class="mr-2">
                                        <img src="/assets/icons/Icon_Excel-Download.svg"
                                            [class.disabled]="reflexrules.isNoDataPresent || reflexrules.data.length === 0" class="cusor-pointer"
                                            alt="Export" [matTooltipDisabled]="reflexrules.isNoDataPresent || reflexrules.data.length === 0"
                                            matTooltip="Export"
                                            (click)="!reflexrules.isNoDataPresent && reflexrules.data.length > 0 ? exportexcel(reflexrules.data): null  ">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center" *ngIf="reflexrules.data.length==0">
                            <span class="w-50 text-center">
                                No Results
                            </span>
                        </div>
                        <div class="rr-body item mt-2">
                            <div class="each-item m-1 ml-3 p-2 align-items-center" *ngFor="let i of reflexrules.data"
                                [ngClass]="i.RuleStatus!='Active'?'inactiveRule':''">
                                <div class=" col ellipsis " [matTooltip]="tooTipText">
                                    <div class="tableRowValue ellipsis" (mouseover)="toolTipshow(ruleName)" #ruleName> {{i.RuleName}}</div>
                                </div>
                                <div class="col ellipsis ml-1" [matTooltip]="tooTipText">
                                    <div class="tableRowValue ellipsis" (mouseover)="toolTipshow(CaseType)" #CaseType>{{i.CaseTypeDisplayName}}</div>
                                </div>
                                <div class="col ellipsis ml-1" [matTooltip]="tooTipText" #EffectiveDate
                                    (mouseover)="toolTipshow(EffectiveDate)">
                                    {{i.EffectiveDate}}
                                </div>
                                <div class="col ellipsis ml-2" [matTooltip]="tooTipText" #ExpirationDate
                                    (mouseover)="toolTipshow(ExpirationDate)">
                                    <span
                                        [ngClass]="compareDate(i.ExpirationDate)?'text-danger expired-font-style':''">{{compareDate(i.ExpirationDate)?'Expired':i.ExpirationDate}}
                                    </span>
                                </div>
                                <div class="justify-self-center">
                                    <span>
                                        <img src="/assets/icons/Edit-fill.svg" [class.disabled]="!EditBtn"
                                            class="cusor-pointer" alt="Edit" width="14px"
                                            [matTooltipDisabled]="!EditBtn" matTooltip="Edit"
                                            (click)="EditBtn? viewRule(i) : null  ">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="page=='list'&&reflexrules.isNoDataPresent" class="col-sm-12 text-center">
                        <span class="w-50 text-center ">
                            No Data Available
                        </span>
                    </div>
                </ng-container>
            </section>
            <!-- End list Refelex Rules -->
            <!--Edit Rule-->

            <section *ngIf="page!='list'">

                <div class="p-2 mt-2 m-1 d-flex align-items-center">
                    <div class="mt-1">
                        <strong class="heading-border">Edit Reflex Rules </strong>
                    </div>
                    <app-button class="ml-auto edit-page-button mr-3" buttonclass="secondary"
                        (click)="onBackClick()">Return</app-button>
                    <app-button class="edit-page-button" buttonclass="primary" [isDisabled]="!SaveBtn"
                        (click)="saveRule()">Save</app-button>
                </div>

            </section>
            <div class="modal-body mt-2" *ngIf="page!='list'">
                <form [formGroup]="formEditRuleGrp" autocomplete="off">
                    <ng-container *ngIf="this.ReflexRuleData[0]!=null">
                        <div class="">
                            <div class="execute-date-row d-flex align-items-center ">
                                <div class="ml-1">
                                    <span>Last Executed : </span>
                                    <span class=" font-color ml-2"
                                        [appNotAvailable]="this.ReflexRuleData[0]?.LastExecuted"></span>
                                </div>
                                <div class="last-excecuted-seperator"></div>
                                <div>
                                    <span>Case Identifier: </span>
                                    <span class="text-muted  font-color  mr-5 ml-2"
                                        [appNotAvailable]="this.ReflexRuleData[0].CaseIdentifier"></span>
                                </div>
                                    <app-toggle-switch class="ml-auto mr-4" [isChecked]="getters.IsActive.value" formcontrolname="IsActive" labelActivetext='Active:'
                                        labelInactivetext="Inactive:" type="secondary"></app-toggle-switch>
                            </div>
                            <div class="reflex-rules-body">
                                <div class="m-3 p-1">
                                    <div>
                                        <span class="sub-heading-border">
                                            Rule ID
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].RuleId">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Rule Name
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].RuleName">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Speciality
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].Speciality">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Case Type
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].CaseTypeDisplayName">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Status
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].Status">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Services
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].Services">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Trigger On
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.ReflexRuleData[0].TriggerOn">
                                        </p>
                                    </div>
                                    <div>
                                        <span class="sub-heading-border">
                                            Message
                                        </span> <br>
                                        <p class="mt-1" [appNotAvailable]="this.message">
                                        </p>
                                    </div>

                                </div>
                                <div class="below-Section mb-2">
                                    <div class=" m-3 p-1 mt-2" style="width: 75%;">
                                        <div class="text-container"
                                            [class.inavalid-field]="!formEditRuleGrp.controls.Description.value">
                                            <textarea rows="3" class=" p-2 word-wrap label-font-size"
                                                formControlName="Description" #svalue (focus)="isFocused = true"
                                                (focusout)="svalue.value ? isFocused = true : isFocused = false"></textarea>
                                            <label [class.position]="isFocused || svalue.value ">Description<span
                                                    class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div class=" effective-date">
                                        <div class="input-margin">
                                            <app-input [placeholder]="" type="date" formcontrolname="EffectiveDate" labelValue="Effective Date"
                                                [required]="true">
                                            </app-input>
                                        </div>
                                        <div>
                                            <app-input type="date" [placeholder]=""  formcontrolname="ExpirationDate" labelValue="Expiration Date"
                                                [required]="true">
                                            </app-input>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <!-- 
                            <div class="row col-sm-12  colBorder ">
                                <div class="row ">
                                    <span scope="row" class=" text-sm heading-font-size" aria-readonly="true">Last
                                        Executed: </span>
                                    <span class="text-muted  font-size mr-5 ml-2"
                                        [appNotAvailable]="this.ReflexRuleData[0]?.LastExecuted"></span>
                                </div>

                                <div class="row ">
                                    <span scope="row" class=" text-sm heading-font-size" aria-readonly="true">Case
                                        Identifier: </span>
                                    <span class="text-muted  font-size  mr-5 ml-2"
                                        [appNotAvailable]="this.ReflexRuleData[0].CaseIdentifier"></span>
                                </div>
                                <div class="row ml-auto">
                                    <span scope="row" class=" text-sm heading-font-size text-dark"
                                        aria-readonly="true">Status: </span>
                                    <span class="text-muted  font-size ml-2"
                                        [ngClass]="getters.IsActive.value?'active':'inactive'">{{getters.IsActive.value?'Active':'Inactive'}}</span>
                                </div>
                            </div>
                            <div class="rr-grid-col-3">
                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Rule Id:
                                        </span>
                                    </div>

                                    <div class="text-muted  col-sm-8  ">
                                        <span class=" body-font-size "
                                            [appNotAvailable]="this.ReflexRuleData[0].RuleId"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Rule Name:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].RuleName"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Speciality:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].Speciality"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Case Type:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].CaseTypeDisplayName"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Status: </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].Status"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Services:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].Services"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Trigger On:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size"
                                            [appNotAvailable]="this.ReflexRuleData[0].TriggerOn"></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="text-sm col-sm-4 ">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Message:
                                        </span>
                                    </div>
                                    <div class="text-muted  col-sm-8 ">
                                        <span class=" body-font-size" [appNotAvailable]="this.message"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="rr-grid-col-2 mb-2">
                                <div class="mt-2 m-0 p-0 w-100">
                                    <div class="text-sm p-0">
                                        <span scope="row" class="heading-font-size" aria-readonly="true">Description:
                                        </span>
                                        <textarea rows="6" class="form-control word-wrap label-font-size  mt-1 "
                                            formControlName="Description" aria-readonly="false"></textarea>
                                    </div>
                                </div>
                                <div class=" mt-auto p-0  mb-1 effective-date">
                                    <div class="rr-flex-col-2 mb-4">
                                        <label class="heading-font-size text-nowrap" aria-readonly="true">Effective Date
                                            <span class="text-danger">*</span> </label>
                                        <input type="date" class="form-control label-font-size calender-input "
                                            formControlName="EffectiveDate" aria-readonly="false" [min]="start_date">
                                    </div>
                                    <div class="rr-flex-col-2">
                                        <label class="heading-font-size text-nowrap" aria-readonly="true">Expiration
                                            Date <span class="text-danger">*</span> </label>
                                        <input type="date" class="form-control label-font-size calender-input"
                                            formControlName="ExpirationDate" aria-readonly="false" [min]="start_date">
                                    </div>
                                </div>

                            </div> -->
                        </div>
                        <!-- <div class="col-sm-12 align-center mt-5">
                            <div class="col-sm-5 button-align ">
                                <button (click)="onBackClick()" mat-button class="cancel-btn "
                                    id="Btnback">Back</button>
                                <button [disabled]="!ActiveBtn" mat-button class="action-btn" id="Btnactive"
                                    (click)="ActivateDeactivateRule()">{{!getters.IsActive.value?'Activate':'Deactivate'}}</button>
                                <button [disabled]="!SaveBtn" mat-button class="action-btn" id="Btnsave"
                                    (click)="saveRule()">Save</button>
                            </div>
                        </div> -->

                    </ng-container>
                </form>
            </div>
      <!--End- Edit Rule-->
        </div>
    </div>
</div>