import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import '@grapecity/wijmo.input';
//import '@grapecity/wijmo.styles/wijmo.css';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Error when invalid control is dirty, touched, or submitted. */

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
declare var $: any;
export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-user-role-mgmt',
  templateUrl: './user-role-mgmt.component.html',
  styleUrls: ['./user-role-mgmt.component.scss']
})

export class UserRoleMgmtComponent implements OnInit {
  @Input() selecteddefaultdeploymentkey: any;
  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary'
  };
  // [x: string]: any;
  email = new FormControl('', [Validators.required, Validators.email]);
  emailUser = new FormControl('', [Validators.required, Validators.email]);
  suggestionList: any;
  configname = new FormControl();
  OrganizationName: any;
  organizationList: any;
  statusread: boolean = true;
  rolenameRead: Boolean = false;
  data: [];
  masterCheckboxState = false;
  deploymentSelections = {};
  gridArray = [];
  @Input()
  templateData: any;
  gridData: CollectionView;
  gridDataAssociate: CollectionView;
  public SubMenuCardModel;
  showPaginationMainGrid: boolean = false;
  @ViewChild('txtInput') txtInput: ElementRef;
  @ViewChild('selectedUser') selectedUser!: ElementRef;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  public DeploymentKeys = [];
  gridHeader: any = [];
  FormDataEntry: boolean = false;
  submitted: boolean = false;
  resultPage: boolean = false;
  UserEnteredDataDiv: boolean = false;
  emailValid: boolean = true;
  showGrid: boolean = false;
  selectedType: any;
  gridwidth: number = 0;
  datavalue = [];
  RoleList: any = [];
  orgNameList: any = [];
  private _currentEditItem: any = null;
  noData: boolean = false;
  labDetails: any = [];
  labEmail: any = [];
  FormUserEntryDiv: boolean = false;
  arr: Array<string>;
  backbutton: any;
  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;
  Users: string[] = ['User', 'Role', 'Laboratory'];
  search: String = '';
  postUpload: boolean = false;
  searchResult: any[];
  OrganizationList: any;
  organizationid: any;
  orgName: any;
  searchInput: any;
  dataToTakeAsInput: { OrganizationID: any; CaseType: any; TableName: string; ColumnNames: string[], columnDefs: string[] };
  DataEntity: boolean = false;
  enableInsert: boolean = false;
  emailSuggestion: any[];
  selectedOption: any = 'User'
  selectedCreate1: boolean = false;
  selectedCreate2: boolean = false;
  setConfig: boolean = false;
  Searching: string = 'Search here......';
  deployment: any;
  alllabdeatils: any;
  assignRole: boolean = false;
  btnexportdisabled: boolean = false;
  defaultdeploymentkey: string;

  constructor(
    private ngxService: NgxUiLoaderService,
    public vaservices: VitalHttpServices,
    private _fb: FormBuilder,
    private commonService: CommonService,
    private _snackbar: MatSnackBar,
    private datashare: DataShareService,
    private activeModal: NgbActiveModal
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vaservices,
      datashare
    );
  }

  //Form of entity search
  UserRoleMgmtform = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmUsersKey: [this.selectedOption, Validators.required],
    RoleName: ['', Validators.required],
    LabId: ['', Validators.required],
    OrganizationName: '',
    UserEmail: ['', Validators.required]
    // ['', Validators.required]
  });

  //Form of inserting user
  AddUserForm = this._fb.group({
    UserEmail: ['', Validators.required],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    AddEmail: ['', Validators.required],
    Title: ['', Validators.required],
    gender: ['', Validators.required]
  });

  ngOnInit() {
    this.UserRoleMgmtform.value.frmUsersKey = this.selectedOption;
    this.GetUserIdInfo();
    // this.GetButtondetails();
    this.getDeploymentKeys();
    let defaultdeployment = localStorage.getItem('defaultdeployment');
    if (defaultdeployment === undefined || defaultdeployment === null || defaultdeployment === '') {
      this.defaultdeploymentkey = "LIS";
    } else {
      this.defaultdeploymentkey = defaultdeployment.toUpperCase();
    }
  }
  getDeploymentKeys(): any {
    this.ngxService.start();
    this.vaservices.GetAllUrls().subscribe(
      result => {
        this.ngxService.stop();
        if (!result.errors) {
          result.DeploymentKeys.forEach(va =>
            this.DeploymentKeys.push({ value: va, checked: true })
          );
        } else {
          this._snackbar.open(
            'An error occurred while processing your request',
            'Failed'
          );
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  //Handling 2 forms
  get formcontrol() {
    if (this.UserRoleMgmtform) {
      return this.UserRoleMgmtform.controls;
    } else {
      return this.AddUserForm.controls;
    }
  }
  //Deployment selection while inserting the user
  checkAllDeps() {
    return this.DeploymentKeys.every(va => va.checked);
  }

  //Enter the form and get the dropdown items in assoicate
  associateSetUpForm() {
    this.enableInsert = false;
    this.FormDataEntry = true;
    this.UserEnteredDataDiv = true;
    this.templateData = [];
    this.postUpload = true;
    this.btnexportdisabled = true;
    this.formGroupDirective.resetForm();
  }

  //Flag
  UserCreationForm() {
    this.UserRoleMgmtform.value.frmUsersKey = this.selectedOption;
    this.FormUserEntryDiv = true;
    this.FormDataEntry = true;
    this.DeploymentKeys = [];
    this.getDeploymentKeys();
    this.AddUserForm.reset();
    this.AddUserForm.patchValue({
      FirstName: '',
      LastName: '',
      AddEmail: '',
      Title: '',
      gender: '',
    });
  }

  //On click of the 'X' button to close modal
  //and refresh the page
  SetConfigClose() {
    this.showGrid = false;
    this.UserRoleMgmtform.patchValue({
      UserEmail: '',
      LabId: '',
      RoleName: '',
      frmUsersKey: this.selectedOption,
      frmDeploymentKey: ''
    });
    this.emailValid = true;
    this.activeModal.close();
    this.AddUserForm.patchValue({
      FirstName: '',
      LastName: '',
      AddEmail: '',
      Title: '',
      gender: '',
    });
    this.FormUserEntryDiv = false;
    this.FormDataEntry = false;
    this.submitted = false;
    this.FormDataEntry = false;
    this.UserEnteredDataDiv = false;
    this.search = '';
    this.showGrid = false;
    this.enableInsert = false;
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    // this.formGroupDirective.resetForm();

  }

  //Back to selecting deployment
  backSelect() {
    this.formcontrol['frmDeploymentKey'].setValue('');
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.submitted = false;
    this.FormDataEntry = false;
    this.UserEnteredDataDiv = false;
    this.FormUserEntryDiv = false;
    this.showGrid = false;
    this.UserRoleMgmtform.patchValue({
      UserEmail: '',
      LabId: '',
      RoleName: '',
      frmUsersKey: this.selectedOption
    });
    this.formcontrol['frmUsersKey'].setErrors(null);
    this.search = '';
    this.formcontrol['UserEmail'].setErrors(null);
    this.AddUserForm.patchValue({
      FirstName: '',
      LastName: '',
      AddEmail: '',
      Title: '',
      gender: '',
    });
    // this.formGroupDirective.resetForm();
  }

  //#region
  //Update form values and send them for insertion
  initializeGrid(flex: wjcGrid.FlexGrid) {
    flex.rows.defaultSize = 40;
    // custom formatter to paint buttons and editors
    flex.formatItem.addHandler(
      (s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
        if (e.panel == s.cells) {
          let col = s.columns[e.col],
            item = s.rows[e.row].dataItem;
          if (item == this._currentEditItem) {
            // create editors and buttons for the item being edited
            if (this.selectedType.toLowerCase() === 'user') {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'OrganizationId':
                case 'OrganizationName':
                case 'UserEmail':
                case 'RoleName':
                case 'status':
                  break;
              }
            } else if (this.selectedType.toLowerCase() === 'role') {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'OrganizationId':
                case 'OrganizationName':
                case 'UserEmail':
                case 'RoleName':
                case 'status':
                  break;
              }
            } else if (this.selectedType.toLowerCase() === 'laboratory') {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'OrganizationId':
                case 'OrganizationName':
                case 'UserEmail':
                case 'RoleName':
                case 'status':
                  break;
              }
            } else if (this.templateData) {
              switch (col.binding) {
                case 'buttons':
                  e.cell.innerHTML = document.getElementById(
                    'tplBtnEditMode'
                  ).innerHTML;
                  e.cell['dataItem'] = item;
                  break;
                case 'UserEmail':
                case 'LabId':
                case 'RoleName':
                  break;
              }
            }
          } else {
            // create buttons for items not being edited
            switch (col.binding) {
              case 'buttons':
                e.cell.innerHTML = document.getElementById(
                  'tplBtnViewMode'
                ).innerHTML;
                e.cell['dataItem'] = item;
                break;
            }
          }
        }
      }
    ),
      // handle button clicks
      flex.addEventListener(flex.hostElement, 'click', (e: MouseEvent) => {
        let targetBtn: HTMLButtonElement;
        if (e.target instanceof HTMLButtonElement) {
          targetBtn = e.target;
        } else if (
          e.target instanceof HTMLElement &&
          e.target.classList.contains('fa')
        ) {
          targetBtn = e.target.parentElement as HTMLButtonElement;
        }
        if (targetBtn) {
          // get button's data item
          let item = wjcCore.closest(targetBtn, '.wj-cell')['dataItem'];
          // handle buttons
          switch (targetBtn.id) {
            // start editing this item
            case 'btnEdit':
              this.statusread = false;
              this.flex = flex;
              this._editItem(item);
              this.rolenameRead = this.RoleList.sort();
              break;
            // remove this item from the collection
            case 'btnDelete':
              (<wjcCore.CollectionView>flex.collectionView).remove(item);
              this.btnexportdisabled = true;
              this.postUpload = true;
              this._snackbar.open('Deleted successfully', 'Close');
              break;
            // commit edits
            case 'btnOK':
              this._commitEdit();
              break;
            // cancel edits
            case 'btnCancel':
              this._cancelEdit();
              break;
          }
        }
      });
    // exit edit mode when scrolling the grid or losing focus
    flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
    flex.lostFocus.addHandler(this._cancelEdit.bind(this));
  }
  //Grid edit
  private _editItem(item: any) {
    this.statusread = false;
    this.rolenameRead = false;
    this._cancelEdit();
    this._currentEditItem = item;
    this.flex.invalidate();
  }
  //Grid OK
  private _commitEdit() {
    if (this._currentEditItem) {
      this.flex.columns.forEach((col: wjcGrid.Column) => {
        let input = <HTMLInputElement>(
          this.flex.hostElement.querySelector('#' + col.binding)
        );
        if (input) {
          let value = wjcCore.changeType(input.value, col.dataType, col.format);
          if (wjcCore.getType(value) == col.dataType) {
            this._currentEditItem[col.binding] = value;
          }
        }
      });
    }
    this.UpdateSuperAdmin(this._currentEditItem);
    this._currentEditItem = null;
    this.flex.invalidate();
  }

  checkEditItem(currentEditItem): any {
    let query = '';
    let queryVariable = {};
    this.showGrid = true;
    this.noData = false;
    this.formcontrol['UserEmail'].setErrors(null);
    let dbName = this.UserRoleMgmtform.value.frmDeploymentKey;
    query = this.SubMenuCardModel.GetQuery('getlabview');
    queryVariable = { OrganizationId: null };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop();
        if (!data.errors) {
          if (data.data.submenuData && this.compareEditData(data.data.submenuData, currentEditItem)) {
            this._snackbar.open('Data already exists', 'Close');
            return false;
          } else {
            return true
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }


  compareEditData(data, currentEditItem) {
    return (data.UserEmail == currentEditItem.UserEmail && data.OrganizationId == currentEditItem.OrganizationId)
  }
  //Grid cancel
  private _cancelEdit() {
    if (this._currentEditItem) {
      this._currentEditItem = null;
      this.statusread = true;
      this.getUserData()
      this.rolenameRead = true;
      this.flex.invalidate();
    }
  }
  //clear data
  clearData() {
    this.UserRoleMgmtform.patchValue({
      UserEmail: '',
      LabId: '',
      RoleName: ''
    });
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.formcontrol['frmUsersKey'].setErrors(null);
    this.formcontrol['UserEmail'].setErrors(null);
  }

  //Fetch all the data based on entity from database
  getUserData() {
    let query = '';
    let queryVariable = {};
    let dbName = this.UserRoleMgmtform.value.frmDeploymentKey;
    if (
      this.UserRoleMgmtform.value.frmUsersKey &&
      this.UserRoleMgmtform.value.frmUsersKey.toString().match(/user/i)
    ) {
      query = this.SubMenuCardModel.GetQuery('getallusers');
      queryVariable = this.search
        ? { UserEmail: this.search }
        : { UserEmail: null };
    } else if (
      this.UserRoleMgmtform.value.frmUsersKey &&
      this.UserRoleMgmtform.value.frmUsersKey.toString().match(/role/i)
    ) {
      query = this.SubMenuCardModel.GetQuery('getallrolesbyorg');
      //dbName = 'config';
      queryVariable = this.search
        ? { RoleName: this.search }
        : { RoleName: null };
    } else if (
      this.UserRoleMgmtform.value.frmUsersKey &&
      this.UserRoleMgmtform.value.frmUsersKey.toString().match(/laboratory/i)
    ) {
      this.search = !this.search ? '' : this.search;
      if (/[0-9]+/.test(this.search.toString())) {
        query = this.SubMenuCardModel.GetQuery('getalllabs');
        queryVariable = this.search
          ? { OrganizationId: this.search }
          : { OrganizationId: null, OrganizationName: null };
      } else {
        query = this.SubMenuCardModel.GetQuery('getalllabs');
        queryVariable = this.search
          ? { OrganizationName: this.search }
          : { OrganizationId: null, OrganizationName: null };
      }
    } else {
      this._snackbar.open('Please enter a value to get user data', 'Close');
      // alert('Please enter a value to get user data');
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop();
        if (!data.errors) {
          this.templateData = data.data;
          if (this.templateData.submenuData.length > 0) {
            this.addGridData(this.templateData);
          } else {
            this.showGrid = false;
            this.noData = true;
          }
        }
      },
      error => {
        this.showGrid = false;
        this.noData = true;
        console.error(error);
      }
    );
  }

  //add the data based on entity search to the grid
  addGridData(templateData: any) {
    let primary = {};
    if (this.selectedType.toLowerCase() == 'user') {
      this.gridHeader = [
        'Id',
        'status',
        'RoleName',
        'UserEmail',
        'OrganizationName',
        'OrganizationId'
      ];
    } else if (this.selectedType.toLowerCase() == 'role') {
      this.gridHeader = [
        'Id',
        'status',
        'RoleName',
        'UserEmail',
        'OrganizationName',
        'OrganizationId'
      ];
    } else if (this.selectedType.toLowerCase() == 'laboratory') {
      this.gridHeader = [
        'Id',
        'status',
        'RoleName',
        'UserEmail',
        'OrganizationName',
        'OrganizationId'
      ];
    }
    let gridArray = [];
    for (let i = 0; i < templateData.submenuData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData.submenuData[i])) {
        for (let j = 0; j < this.gridHeader.length; j++) {
          if (key.toLowerCase() == this.gridHeader[j].toLowerCase()) {
            if (key == 'OrganizationName') {
              value =
                templateData.submenuData[i].OrganizationName +
                ' (' +
                templateData.submenuData[i].OrganizationId +
                ')';
            }
            primary[key] = value;
          }
        }
      }
      gridArray.push(primary);
    }
    var grid = gridArray.sort((a, b) => a.OrganizationName < b.OrganizationName ? -1 : 1)
    // .sort((a, b) => a.TSequence < b.TSequence ? -1 : 1
    if (grid.length > 0) {
      this.gridHeader.splice(
        this.gridHeader.findIndex(va => va == 'RoleName'),
        1
      );
      this.gridData = new wjcCore.CollectionView(grid, { pageSize: 10 });
      this.showGrid = true;
      this.noData = false;
    } else {
      this.noData = true;
      this.showGrid = false;
    }
    this.showPaginationMainGrid = gridArray.length > 0 ? true : false;
    this.gridwidth = (170 * this.gridHeader.length) + 37;
    if (this.gridwidth > 1300) {
      this.gridwidth = 1300;
    }
  }
  //add assoicate data to grid
  addGridAssociateData(templateData: any) {
    this.gridHeader = ['RoleName', 'LabId', 'UserEmail'];
    this.formcontrol['UserEmail'].setErrors(null);
    // this.gridDataAssociate = new wjcCore.CollectionView(templateData, {
    //   pageSize: 10
    // });
    // if (templateData.length > 0) {
    //   this.showGrid = true;
    //   this.noData = false;
    //   this.postUpload = false;
    // }
    // else {
    //   this.showGrid = false;
    //   this.noData = false;
    //   this.postUpload = true;
    // }
    var grid = templateData.sort((a, b) => a.OrganizationName < b.OrganizationName ? -1 : 1)
    if (templateData.length > 0) {
      this.gridDataAssociate = new wjcCore.CollectionView(grid, { pageSize: 10 });
      this.showGrid = true;
      this.noData = false;
      this.postUpload = false;
      this.btnexportdisabled = false;
    }
    else {
      this.showGrid = false;
      this.noData = false;
      this.postUpload = true;
      this.btnexportdisabled = true;
    }
    this.showPaginationMainGrid = templateData.length > 0 ? true : false;
    this.gridwidth = (170 * this.gridHeader.length) + 37;
    if (this.gridwidth > 1300) {
      this.gridwidth = 1300;
    }
  }


  //adding data in the assoicate grid and validating it
  getAssociateUserData() {
    let query = '';
    let queryVariable = {};
    this.noData = false;
    this.selectedCreate2 = true;
    this.setConfig = true;
    this.formcontrol['UserEmail'].setErrors(null);
    let dbName = this.UserRoleMgmtform.value.frmDeploymentKey;
    query = this.SubMenuCardModel.GetQuery('getlabview');
    queryVariable = { OrganizationId: null };
    ;
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop();
        if (!data.errors) {
          if (data.data.submenuData && this.compareDbData(data)) {
            this._snackbar.open('User Data already exists', 'Close');
          } else {

            //  {
            //this.showGrid = true;
            // this.formcontrol['UserEmail'].setErrors(null);
            let dataObject = {};
            dataObject['LabId'] = this.UserRoleMgmtform.value.LabId;
            dataObject['RoleName'] = this.UserRoleMgmtform.value.RoleName;
            dataObject['UserEmail'] = this.UserRoleMgmtform.value.UserEmail;
            if (this.templateData.length > 0) {
              this.templateData.forEach(element => {
                if (element.UserEmail === this.UserRoleMgmtform.value.UserEmail && element.LabId === this.UserRoleMgmtform.value.LabId && element.RoleName === this.UserRoleMgmtform.value.RoleName) {
                  this._snackbar.open('User Data already exist in Grid', 'Close');
                }
                else {
                  this.templateData.push(dataObject);
                  //this.addGridAssociateData(this.templateData);
                  //this.showGrid=true
                  this.getUserEmailData();

                }
              });
            }
            else {
              this.templateData.push(dataObject);
              this.getUserEmailData();
            }
          }
          // }
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  //popping up the fields
  getDetails() {
    if (this.enableInsert == false) {
      this.getAssociateUserData()
    }
    else {
      this.InsertNewUser();
    }

  }
  //user email validation
  getUserEmailData() {
    let query = '';
    let queryVariable = {};
    let dbName = this.UserRoleMgmtform.value.frmDeploymentKey;
    // this.getAllLabsDeatils(dbName);
    let emailSuggestion = []
    query = this.SubMenuCardModel.GetQuery('getAllUserEmail');
    queryVariable = { UserEmail: this.UserRoleMgmtform.value.UserEmail };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop();
        if (!data.errors) {
          //this.labEmail=data.data.submenuData;
          data.data.submenuData.forEach(va => emailSuggestion.push(va.email));
          if (data.data.submenuData.some(va => va.email == this.UserRoleMgmtform.value.UserEmail)) {
            this.showGrid = true;
            this.postUpload = true;
            this.btnexportdisabled = true;
            this.enableInsert = false;
            this.addGridAssociateData(this.templateData);
          }
          else {
            this._snackbar.open('Insert the user details for Association', 'Close');
            this.enableInsert = true;
            this.templateData.length = 0;
            // this.InsertNewUser();
            this.showGrid = false;
            this.postUpload = true;
            this.btnexportdisabled = true;
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  //Add new user
  InsertNewUser() {
    let deploymentList = [];
    let deploymentsSelected = [];
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.DeploymentKeys.forEach(va => {
      if (va.checked) {
        deploymentsSelected.push(va.value);
      }
    });
    if (deploymentList.length > 0) {
      deploymentList.push(deploymentsSelected)
    }
    else {
      deploymentList.push(this.deployment);
    }

    if (deploymentsSelected.length != 0 || this.UserRoleMgmtform.value.frmDeploymentKey != '') {
      let obj: any = {
      DeploymentList : this.setConfig ? deploymentList : (deploymentsSelected || this.UserRoleMgmtform.value.frmDeploymentKey),
      firstname : this.AddUserForm.value.FirstName,
      lastname : this.AddUserForm.value.LastName,
      email : this.UserRoleMgmtform.value.UserEmail || this.AddUserForm.value.AddEmail,
      password : '8clpNzfFAebd6l/nd2R0BQ==',
      title : this.AddUserForm.value.Title,
      }
      if (this.AddUserForm.value.Title == 'Mr.' && this.AddUserForm.value.gender == 'Female') {
        this._snackbar.open('Gender and Title does not match!', 'Close');
        return;
      }
      this.AddUserForm.value.gender.toLowerCase() == 'male' ? obj.gender = 1 : obj.gender = 0;
      obj.loginname =
        this.AddUserForm.value.FirstName +
        Array.from(this.AddUserForm.value.LastName.trim())[0] +
        'supptech';
      obj.createdby =
        sessionStorage.getItem('Userid') != ''
          ? sessionStorage.getItem('Userid')
          : '100';
          if (
            this.AddUserForm.value.FirstName == '' ||
            this.AddUserForm.value.LastName == '' ||
            this.AddUserForm.value.AddEmail == '' ||
            this.AddUserForm.value.Title == ''
          ) {
        this._snackbar.open('Please insert values', 'Close');
        return;
      }
      else {
        if(obj.email != ""){
          var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (email.test(obj.email)) {
            obj.email = this.UserRoleMgmtform.value.UserEmail || this.AddUserForm.value.AddEmail;
          }
          else{
            this._snackbar.open('Please enter valid Email ID', 'Close');
            return;
          }
      }

        this.ngxService.start();
        this.vaservices.InsertNewUser(obj).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            this._snackbar.open("User created successfully", "Close");
            this.enableInsert = false;
            if(!this.AddUserForm){
            this.getDetails();
            }
            // this.UserRoleMgmtform.patchValue({
            //   LabId: '',
            //   RoleName: ''
            // });
            // this._snackbar.open(result[0].Notes, "Close");
          }
        }, error => {
          console.error(error);
        });
      }

    }
    else {
      this._snackbar.open('Select Deployment', 'Close');
      return;

    }
  }

  //Validation while adding in grid from grid and database
  compareDbData(data, configFormData?): boolean {
    let UserEmail;
    let OrganizationID;
    ;
    if (configFormData) {
      UserEmail = configFormData.UserEmail;
      OrganizationID = configFormData.LabId;
    }
    if (data &&
      data.data.submenuData.some(va => va.deployment == this.UserRoleMgmtform.value.frmDeploymentKey && va.UserEmail == this.UserRoleMgmtform.value.UserEmail)) {
      if (this.deployment == this.UserRoleMgmtform.value.frmDeploymentKey && configFormData.UserEmail == this.UserRoleMgmtform.value.UserEmail) {
        this.enableInsert = false;
      }
      this.enableInsert = true;
      return true;
    }
    else if (
      data &&
      data.data.submenuData.some(va => va.UserEmail == this.UserRoleMgmtform.value.UserEmail && va.OrganizationId == this.UserRoleMgmtform.value.LabId.toString()
        .split('(')[1]
        .split(')')[0])) {
      return true;
    }
    else if (
      this.templateData &&
      this.templateData.some(
        va =>
          va.UserEmail &&
          va.UserEmail.toString().toLowerCase() ==
          this.UserRoleMgmtform.value.UserEmail &&
          this.UserRoleMgmtform.value.UserEmail.toString().toLowerCase() &&
          va.LabId == this.UserRoleMgmtform.value.LabId
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  //dropdown in assoicate
  deploymentSelected(selection, checked?) {
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    // this.Users = ['Users', 'Role', 'Lab'];

    //selected default deployment
    if (selection.value !== this.selecteddefaultdeploymentkey) {
      this.deployment = selection.value;
    } else {
      this.deployment = this.selecteddefaultdeploymentkey;
    }
    this.getALLUserEmailData(this.deployment);
    this.getAllLabsDeatils(this.deployment);
    //this.getListOrg(this.deployment);
    checked != null ? (selection.checked = checked) : null;
    this.formcontrol['frmUsersKey'].setErrors(null);
    this.showGrid = false;
    this.noData = false;
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery('allrolenames');
    queryVariable = { RoleName: null };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, 'configdb').subscribe(
      data => {
        // this.ngxService.stop();
        this.getSuggestions();
        if (!data.errors) {
          // if (data.RoleName != data.data.submenuData) {
          this.suggestionList = data.data.submenuData;
          data.data.submenuData.forEach(element => {
            if (element.RoleName === 'Associate') {

            }
            else {
              this.RoleList.push(element.RoleName);
            }

          });
          this.RoleList = [...new Set(this.RoleList)].sort();
          //this.getLabNames(this.deployment);
          //  this.getUserEmailData();
          // }
        }
      },
      error => {
        console.error(error);
      }
    );
  }


  //To get all checkbox
  toggleAllSelections(value) {
    this.DeploymentKeys.forEach(va => (va.checked = value));
  }

  //Laboratory names whie bulk uploading
  getLabNames(deployment) {
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery('getalllabs');
    // let query = this.SubMenuCardModel.GetQuery('getalllabnames');
    queryVariable = { OrganizationId: null };
    //queryVariable = { "Status": "active" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start()
    this.vaservices.GetData(queryResult, deployment).subscribe(
      data => {
        // this.ngxService.stop()
        if (!data.errors) {
          if (data.data.submenuData) {
          }
        }

        this.getSuggestions();
      },
      error => {
        console.error(error);
      }
    );
  }
  //fetching the data based on entity search
  //region begin
  //Suggestions based on entity search and requirement
  filterOptions(value: string) {
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        let data = [];
        let filteredData = [];
        if (this.suggestionList) {
          data = this.suggestionList;
          if (!value && this.selectedType) {
            if (this.selectedType.toLowerCase() === 'user') {
              data.forEach(element => {
                filteredData.push(element.UserEmail);
              });
            } else if (this.selectedType.toLowerCase() === 'role') {
              return this.RoleList.sort();
            } else if (this.selectedType.toLowerCase() === 'laboratory') {
              data.forEach(element => {
                filteredData.push(element.OrganizationName);
              });
            }
            filteredData = [...new Set(filteredData)];
            return filteredData;
          }
          if (this.suggestionList) {
            if (
              this.UserRoleMgmtform.value.frmUsersKey &&
              this.UserRoleMgmtform.value.frmUsersKey.toString().match(/user/i)
            ) {
              data = this.suggestionList.filter(
                va =>
                  va.UserEmail &&
                  va.UserEmail.toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
              );
              Object.values(data).forEach(element => {
                filteredData.push(element.UserEmail);
              });
            } else if (
              this.UserRoleMgmtform.value.frmUsersKey &&
              this.UserRoleMgmtform.value.frmUsersKey.toString().match(/role/i)
            ) {
              data = this.suggestionList.filter(
                va =>
                  va.RoleName &&
                  va.RoleName.toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
              );
              Object.values(data).forEach(element => {
                filteredData.push(element.RoleName);
              });
            } else if (
              this.UserRoleMgmtform.value.frmUsersKey &&
              this.UserRoleMgmtform.value.frmUsersKey.toString().match(/laboratory/i)
            ) {
              if (/[0-9]+/.test(this.search.toString())) {
                data = this.suggestionList.filter(
                  va =>
                    va.OrganizationId &&
                    va.OrganizationId.toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                );
                data.forEach(element => {
                  filteredData.push(element.OrganizationId);
                });
              } else {
                filteredData = [];
                data = this.suggestionList.filter(
                  va =>
                    va.OrganizationName &&
                    va.OrganizationName.toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                );
                Object.values(data).forEach(element => {
                  filteredData.push(element.OrganizationName);
                });
              }
            }
          }
          filteredData = [...new Set(filteredData)];
          return filteredData;
        }
      }
    }
  }

  //fetch suggestion based on entity search
  getSuggestions(event?) {
    this.search = '';
    this.showGrid = false;
    this.noData = false;
    this.formcontrol['frmUsersKey'].setErrors(null);
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.selectedType = event ? event.source.value : this.UserRoleMgmtform.value.frmUsersKey;
    if (this.selectedType) {
      let query = '';
      let queryVariable = {};
      let dbName = this.UserRoleMgmtform.value.frmDeploymentKey;
      if (this.selectedType.toString().match(/user/i)) {
        query = this.SubMenuCardModel.GetQuery('getallusers');
        queryVariable = { UserEmail: null };
      } else if (this.selectedType.toString().match(/role/i)) {
        query = this.SubMenuCardModel.GetQuery('allrolenames');
        dbName = 'configdb';
        queryVariable = { RoleName: null };
      } else if (this.selectedType.toString().match(/laboratory/i)) {
        if (/[0-9]+/.test(this.search.toString())) {
          query = this.SubMenuCardModel.GetQuery('getalllabs');
          queryVariable = { OrganizationId: null };
        } else {
          if (
            this.organizationList &&
            !this.organizationList.some(
              va => va.OrganizationName == this.search
            )
          ) {
            this._snackbar.open(
              'Please select a value from the dropdown',
              'Close'
            );
            return;
          }
          query = this.SubMenuCardModel.GetQuery('getalllabs');
          queryVariable = { OrganizationName: null };
        }
      }
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      // this.ngxService.start();
      this.vaservices.GetData(queryResult, dbName).subscribe(
        data => {
          // this.ngxService.stop();
          if (!data.errors) {
            this.suggestionList = data.data.submenuData;
          }
          let rolenamevalue;
          this.datavalue = [];
          //convert that one in string list;
          if (this.rolenameRead == false) {
            for (let i = 0; i < this.RoleList.length; i++) {
              if (this.RoleList[i].RoleName === 'Associate') {

              }
              else {
                rolenamevalue = this.RoleList[i].RoleName;
                this.datavalue.push(rolenamevalue);
              }


            }
            // this.datavalue=this.datavalue.remove("Associate");
            // this.datavalue = [...new Set(this.datavalue)].sort();
            // this.RoleList = this.datavalue;
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  //all labs name
  getAllLabsDeatils(dbname) {
    let dbName = dbname;
    //this.suggestionList = [];
    // let alllabsuggestion = []
    let query = this.SubMenuCardModel.GetQuery('getalllabnames');
    let queryVariable = { "Status": "active" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop();
        if (!data.errors) {
          //this.alllabdeatils=data.data.submenuData;
          this.alllabdeatils = [];
          data.data.submenuData.forEach(element => {
            this.alllabdeatils.push({
              OrganizationId: element.organizationid,
              OrganizationName: element.organizationname
            });
          });
          let labDataMap = new Map();
          //this.labEmail = data.data.submenuData;
          this.labDetails = this.alllabdeatils.filter(el => {
            const val = labDataMap.get(el.OrganizationName);
            if (val) {
              if (el.OrganizationId < val) {
                labDataMap.delete(el.OrganizationName);
                labDataMap.set(el.OrganizationName, el.OrganizationId);
                return true;
              } else {
                return false;
              }
            }
            labDataMap.set(el.OrganizationName, el.OrganizationId);
            return true;
          });
          this.getLabNames(dbName);
        }

      },
      error => {
        console.error(error);
      }
    );
  }
  //all user emails
  getALLUserEmailData(dbname) {
    let query = '';
    let queryVariable = {};
    let dbName = dbname;
    // this.getAllLabsDeatils(dbName);
    //let emailSuggestion = []
    query = this.SubMenuCardModel.GetQuery('getAllUserEmail');
    queryVariable = { UserEmail: this.UserRoleMgmtform.value.UserEmail };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start()
    this.vaservices.GetData(queryResult, dbName).subscribe(
      data => {
        // this.ngxService.stop()
        if (!data.errors) {
          this.labEmail = data.data.submenuData;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  //search and cancel button toggle
  fnToggleFilter() {
    event.stopPropagation();
    this.txtInput.nativeElement.focus();
    this.configname.reset();
    this.search = '';
    this
    // if (this.search == '') {
    //   this.getSuggestions();
    // }
    this.getSuggestions();
  }


  //Search grid based on entity search
  searchGrid(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    }
    else {
      if (this.search == '') {
        this.showGrid = false;
        this.getSuggestions();
      }
      else {
        this.getUserData();
      }
    }
  }

  //Export bull upload of user association in the grid
  ExportExcel() {
    let excel = [];
    //let rows=flex.rows;

    for (let i = 0; i < this.templateData.length; i++) {
      excel.push(this.templateData[i]);
    }

    //excel.push(this.templateData);

    let filename = 'User Role Management.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Sheet1');
    XLSX.writeFile(wb, filename);
  }


  //add the array of data into the database
  uploadUserRole() {
    for (let i = 0; i < this.templateData.length; i++) {
      this.templateData[i]['CreatedBy'] =
        sessionStorage.getItem('Userid') == null ||
          sessionStorage.getItem('Userid') == ''
          ? -100
          : sessionStorage.getItem('Userid');
      var regExp = /\(([^)]+)\)/;
      var matches = regExp.exec(this.templateData[i]['LabId']);
      this.templateData[i]['OrganizationId'] = matches[1];
    }
    this.ngxService.start();
    this.vaservices
      .uploadUserAccess(
        this.templateData,
        this.UserRoleMgmtform.value.frmDeploymentKey
      )
      .subscribe(
        result => {
          this.ngxService.stop();
          if (!result.errors) {
            this._snackbar.open('Data saved successfully', 'Close');
            this.postUpload = true;
          }

        },
        error => {
          console.error(error);
        }
      );
  }

  //update values of entity from grid
  UpdateSuperAdmin(editItem) {
    if (!editItem.status) {
      editItem.status = false;
    }
    this.ngxService.start();
    this.vaservices
      .UpdateSuperAdmin(editItem, this.UserRoleMgmtform.value.frmDeploymentKey)
      .subscribe(
        result => {
          this.ngxService.stop();
          if (!result.errors) {
            this._snackbar.open(result.Message, 'Close');
            this.statusread = true;
            this.rolenameRead = true;
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  getSearching(e, val) {
    // this.selectedType = e ? e.source.value : this.UserRoleMgmtform.value.frmUsersKey;
    this.formcontrol['frmDeploymentKey'].setValue('');
    if (e.source.selected) {
      if (val && e.source.value.toLowerCase() === 'user') {

        this.Searching = 'Enter Email';
      }
      else if (val && e.source.value.toLowerCase() === 'role') {
        this.Searching = 'Enter Role';
      }
      else if (val && e.source.value.toLowerCase() === 'laboratory') {
        this.Searching = 'Enter Laboratory';
      }
    }
  }

  filterLabDetails(search: string) {
    if (!search) {
      return this.labDetails;
    } else {
      if (this.labDetails) {
        return /0-9+/.test(search)
          ? this.labDetails.filter(va =>
            va.OrganizationId.toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          )
          : this.labDetails.filter(va =>
            va.OrganizationName.toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          );
      }
    }
    return [];
  }

  //Organization email suggestion while adding laboratory
  filterLabEmails(search: string) {
    let emailArray: any = [];
    this.labEmail.forEach(va => emailArray.push(va.email));
    emailArray = [...new Set(emailArray)].sort();
    if (!search) {
      if (emailArray == this.UserRoleMgmtform.value.UserEmail) {
        this.DataEntity = true;
      }
      else {
        this.DataEntity = false;
        return emailArray;
      }
    } else {
      return emailArray.filter(va => {
        va.toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase());
      });
    }
    this.labEmail.sort();
  }


  setErrorMessage(val) {
    return val;
  }

  //to get userid from storage
  GetUserIdInfo() {
    let query = this.vaservices.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem('UserEmail') }
    };
    this.ngxService.start();
    this.vaservices.GetData(queryRequest).subscribe(
      data => {
        this.ngxService.stop();
        if (
          !data.errors &&
          (data != undefined && data.data.submenuData.length > 0)
        ) {
          let userid = data.data.submenuData[0].userid;
          sessionStorage.setItem('Userid', userid);
        } else {
          sessionStorage.setItem('Userid', '');
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    );
  }

}
