<div class="input-elements">
  <input type="text" [placeholder]="placeholder" [class.cform-in-valid]="!isFormValid" [formControl]="control" [id]="id"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin" #ele [readOnly]="isReadOnly" (focus)="[toggle(),onFocus()]"
    (keyup)="[toggle($event),onKeyUp($event)]" (keydown)="onKeyDown($event)"  (click)="isOpen=!isOpen">
  <label>{{labelValue}}
    <ng-container *ngIf="required">
      <span class="text-danger fw-bold fs-6">*</span>
    </ng-container>
  </label>
  <div class="drop-icon" (click)="[isOpen=!isOpen,toggle(),ele.focus()]" matRipple [matRippleCentered]="centered"
    [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayWidth]="contentwidth" [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayOpen]="isOpen" (backdropClick)="onBackdropClick()">
  <div class="overlay-popup">
    <span *ngFor="let i of dropDownValue1; let index = index" (click)="selectItem(i)"
    (mouseenter)="focusedIndex = index" [class.selected-item]="focusedIndex === index"
    [class.cursur-pointer]="true">{{i}}</span>
  <span *ngIf="!dropDownValue1.length" class="disabled-option"> Matches Not Found</span>
  </div>
</ng-template>

<!-- <div class="input-elements">
  <input type="text" [placeholder]="placeholder" [class.cform-in-valid]="!isFormValid" [formControl]="control" [id]="id"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin" #ele [readOnly]="isReadOnly" (focus)="onFocus()"
    (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (click)="isOpen = !isOpen">
  <label>{{labelValue}}
    <ng-container *ngIf="required">
      <span class="text-danger fw-bold fs-6">*</span>
    </ng-container>
  </label>
  <div class="drop-icon" (click)="toggleDropdown()" matRipple [matRippleCentered]="centered"
    [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayWidth]="contentwidth" [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayOpen]="isOpen" (backdropClick)="onBackdropClick()">
  <div class="overlay-popup">
    <span *ngFor="let i of dropDownValue1; let index = index" (click)="selectItem(i)"
      (mouseenter)="focusedIndex = index" [class.selected-item]="focusedIndex === index"
      [class.cursur-pointer]="true">{{i}}</span>
    <span *ngIf="!dropDownValue1.length" class="disabled-option"> Matches Not Found</span>
  </div>
</ng-template> -->
