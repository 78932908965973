<div class="modal-dialog">
  <form [formGroup]="addVDIDModal">
    <div class="modal-content" style="margin-top: 25px !important; ">
      <!-- Modal Header -->
      <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">Create VD GUID</h3>
        <span type="button" data-dismiss="modal" (click)="addVDIDModalClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
            title="Close">
        </span>
      </div>
      <br />
      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid p-0">
          <div class="row mb-1">
            <div class="col-sm-6 col-md-5 col-lg-5 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0 text-right">
                  <label for="DKeys"><strong>Select Deployment:&nbsp;&nbsp;</strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <select name="DKeys" id="DKeys" class="form-control" required formControlName="frmDeploymentKey">
                    <option value="none" disabled selected>-- Select --</option>
                    <option *ngFor="let i of DeploymentKeys" value={{i}}> {{i}} </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-2 col-lg-2 p-0 m-md-1 m-lg-0" style="display:table;width:100%">
              <div style="display: table-cell;vertical-align:middle; padding-left: 20px;">
                <button [attr.disabled]="addVDIDModal.value.frmDeploymentKey == 'none' ? true : null" mat-raised-button
                  class="admin-btn-success" [disabled]="addVDIDModal.invalid"
                  (click)="openVDID(addVDIDModal.value)">Generate</button>
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-4 p-0 m-0" style="display:table;width:100%">
              <div class="buttonSearchVDId" style="display: table-cell;vertical-align:middle; padding-left: 20px;">
                <!-- <button class="btn btn-sm admin-btn-success d-block m-auto" [disabled]="addVDIDModal.invalid"
                    (click)="openVDID(addVDIDModal.value)">Search</button> -->
                <!-- <form [formGroup]="FrmGrp_SearchVDId" style="width: 100%; display: inline-block;"> -->
                <input type="text" id="SearchVDId" class="form-control p-2" name="SearchVDId"
                  placeholder="Search by GUID" formControlName="SearchVDId_value">
                <!--formControlName="frm_SearchVDId" -->
                <button (click)='btnSearchVDId()' id="btnSearchVDId" style="background-color: #00bcd4">
                  <em class="fa fa-search"></em>
                </button>
                <!-- </form> -->
              </div>
            </div>
          </div>
          <div class="row " *ngIf="validateVDID">
            <div class="col-12" style="color: red; text-align: center; width: 100%;">*Enter valid Vital Desktop ID!!!
            </div>
          </div>
          <div class="row w-100" style="width: 97% !important;">
            <!-- <div class="col-md-9 d-none d-md-block">&nbsp;</div> -->
            <div class="col-md-12" *ngIf="ifaddVDID" style="margin-left: 21px;
            margin-top: 18px;">
              <app-vdinformation [templateData]="templateData" [templateChangedTime]="templateChangedTime">
              </app-vdinformation>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style=" border-top: none !important; display: block; justify-content: normal;">
      </div>
      <br />
      <br />
    </div>
  </form>
</div>