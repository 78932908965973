import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'SplitCamelCase',
    pure: false
})


export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string ): string {   
    if(typeof(value) == null ){
            return value;
    }      
    let convertValue = value.replace(/([a-z])([A-Z])/g, '$1 $2');
    return convertValue;
    }//end of check if array has a value      
  }//end of transform  