<div class="text-center mt-2">
  <div mat-dialog-title class="m-0 p-0">
    <img src="{{data.imagePath}}" alt="Warning" class="material-icons md-48">
    <h1 class="header m-0 p-0">{{data.header}}</h1>
  </div>
  <!-- <div class="header-div">
    <b mat-dialog-title class="header-text">{{ data.header }}</b>
    <span
      type="button"
      data-dismiss="modal"
      (click)="closeDialog()"
      class="mr-2"
    >
      <img
        src="../../../../assets/icons/Close_Icon.svg"
        alt="Close"
        class="material-icons md-18 cursor"
        title="Close"
      />
    </span>
  </div> -->
  <div mat-dialog-content class="m-2 m-0 p-0">
    <div class="message-wrap">
      <div class="user-status-div" *ngIf="data.type !== 'mail-sent'">
        <div>{{data.row1text}} : <b>{{ data.row1value | titlecase }}</b></div>
        <div>{{data.row2text}}:<b> {{ data.row2value }}</b></div>
        <!-- <div class="tool-tip" matTooltip="{{data.row1value | titlecase}}">{{data.row1text}} : <b>{{ data.row1value | titlecase }}</b></div>
        <div>{{data.row2text}}<span [ngStyle]="{'margin-left.px': data.type === 'lockunlock-user' ? 31 : 11}">:</span><b> {{ data.row2value }}</b></div> -->
      </div>
     <div class="col-sm-12 message" *ngIf="data.type === 'mail-sent'">
        {{data.message}}
      </div>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="d-flex justify-content-center align-self-center mb-2"
  >
    <button type="button" class="btn lock-unlock-button" (click)="!isUpdatOnProcess && actionClick()">
      <span class="lock-unlock-button-text" [ngClass]="{'action-button-disabled': isUpdatOnProcess}">{{ data.buttonname }}</span>
    </button>
  </div>
</div>
