<div class="modal-header admin-model-header m-1">
  <!-- title -->
  <h3 class="modal-title w-100 admin-model-header-txt">
    <strong>Export Case Data</strong>
  </h3>
</div>

<!-- First Row -->
<div class="col-sm-12 m-1 p-1" id="export-case">
  <div [ngClass]="moreHide ? 'show_hide' : 'show_more'">
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">
        <div class="col-sm-3 pr-1 pl-0" *ngIf="locationContext == 'Laboratory'"> <!-- if context is lab -->
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Lab</mat-label>
            <mat-select disableOptionCentering #matLab multiple  [formControl]="labAccounnt">
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedLabName"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAllLabName()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClickLabName()" *ngFor="let lab of labname; let i=index"
                [value]="lab.LabAccountId" class="dropdown-opt-wrap">
                {{lab.LabName}}({{lab.LabAccountId}})

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1 pl-0" *ngIf="locationContext != 'Laboratory'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Lab</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input #matLab type="text" matInput [formControl]="labAccounnt" [matAutocomplete]="auto1"
              (input)="locationFilter($event,'lab')">
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filterLaboratories | async"
                value="{{acc.AccountName}} ({{acc.AccountId}})" (onSelectionChange)="selectedLab(acc.AccountId)" class="dropdown-opt-wrap">
                {{acc.AccountName}} ({{acc.AccountId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1" *ngIf="locationContext == 'Facility'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Ordering Facility</mat-label>
            <!-- <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button> -->
            <mat-select disableOptionCentering #matOrderingFacility multiple
              [formControl]="facilityAccount">
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedOf" [ngModelOptions]="{standalone: true}"
                  (change)="toggleAllSelectionOf()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClickOf(matOrderingFacility)"
                *ngFor="let facility of OrderingFacility; let i=index" [value]="facility.OFAccountId" class="dropdown-opt-wrap">
                {{facility.FacilityName}}({{facility.OFAccountId}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1 " *ngIf="locationContext != 'Facility'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Ordering Facility</mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input type="text" matInput [formControl]="facilityAccount" [matAutocomplete]="auto2"
              (input)="locationFilter($event,'Facility')">
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filterFacilities | async"
                value="{{acc.AccountName}} ({{acc.AccountId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedOf(acc.AccountId)">
                {{acc.AccountName}} ({{acc.AccountId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1" *ngIf="locationContext == 'EPG'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Reading Location</mat-label>
            <mat-select disableOptionCentering #matReadingLoc multiple [formControl]="frmreadingLocation">
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedReadingloc"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAllSelectionReading()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClickReadingLoc(matReadingLoc)"
                *ngFor="let facility of readingLocation; let i=index" class="dropdown-opt-wrap" [value]="facility.PathAccountId">
                {{facility.ReadingLocationName}}({{facility.PathAccountId}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1" *ngIf="locationContext != 'EPG'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Reading Location</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input type="text" matInput [formControl]="frmreadingLocation" [matAutocomplete]="auto5"
              (input)="locationFilter($event,'ReadingLocation')">
            <mat-autocomplete #auto5="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filterReadingLocation | async"
                value="{{acc.AccountName}} ({{acc.AccountId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedReadingLoc(acc.AccountId)">
                {{acc.AccountName}} ({{acc.AccountId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1" *ngIf="locationContext =='Send Out'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Send Out Laboratory</mat-label>
            <mat-select disableOptionCentering #matSendOut multiple
              [formControl]="SendoutlaboratoryControl">
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedReadingloc"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAllSelectionReading()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClickSendOut()" class="dropdown-opt-wrap" *ngFor="let send of SendoutlaboratoryList; let i=index"
                [value]="send.SendOutAccountId">
                {{send.SendOutAccountName}} ({{send.SendOutAccountId}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1" *ngIf="locationContext != 'Send Out'">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Send Out Laboratory</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input #matSendoutlaboratory type=" text" matInput [formControl]="SendoutlaboratoryControl"
              [matAutocomplete]="auto3" (input)="locationFilter($event,'Send-out Laboratories')">
            <mat-autocomplete #auto3="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filteredSendoutlaboratory | async"
                value="{{acc.AccountName}} ({{acc.AccountId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedSendOutLab(acc.AccountId)">
                {{acc.AccountName}} ({{acc.AccountId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

      </div>
    </div>

    <!-- Second Row -->
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">

        <!-- <div class="col-sm-3 pr-1 pl-0">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Ordering Physician</mat-label>
            <mat-select disableOptionCentering #matOrderingPhysician multiple class="mt-2">
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedOp" [ngModelOptions]="{standalone: true}"
                  (change)="toggleAllSelectionOP()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClickOP()" *ngFor="let phy of OrderingPhysicianList; let i=index"
                [value]="phy.OFUserId">
                {{phy.PhysicianName}} ({{phy.OFUserId}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-sm-3 pr-1 pl-0">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Ordering Physician</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input #matSendoutlaboratory type=" text" matInput [formControl]="orderingPhysician"
              [matAutocomplete]="auto6" (input)="locationFilter($event,'OrderingPhysician')">
            <mat-autocomplete #auto6="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filteredOrderingPhycisian | async"
                value="{{acc.UserName}} ({{acc.UserId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedPhycisian(acc.UserId)">
                {{acc.UserName}} ({{acc.UserId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Pathologist</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input #matPathologist type="text" matInput [formControl]="PathologistControl" [matAutocomplete]="auto"
              (input)="locationFilter($event,'Pathologist')">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filteredOfPathologist | async "
                value="{{acc.UserName}} ({{acc.UserId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedPathologists(acc.UserId)">
                {{acc.UserName}} ({{acc.UserId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Cytotechnologist</mat-label>
            <button matSuffix mat-icon-button ml-5>
              <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
            <input #matCytotechnologiest type="text" matInput [formControl]="CytotechnologistControl"
              [matAutocomplete]="auto4" (input)="locationFilter($event,'CytoTechnologist')">
            <mat-autocomplete #auto4="matAutocomplete">
              <mat-option class="mat-opt-align" *ngFor="let acc of filteredCytotechnologist | async"
                value="{{acc.UserName}} ({{acc.UserId}})" class="dropdown-opt-wrap" (onSelectionChange)="selectedCytotecnician(acc.UserId)">
                {{acc.UserName}} ({{acc.UserId}})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">
        <div class="col-sm-3 pr-1 pl-0">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label class="form-field">Case Type</mat-label>
            <mat-select disableOptionCentering #matCaseType multiple >
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClick()" class="dropdown-opt-wrap" *ngFor="let case of CaseTypeList; let i=index"
                [value]="case.CaseType">{{case.CaseTypeDisplayName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Service Type</mat-label>
            <mat-select disableOptionCentering #matServiceType multiple >
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedServiceType"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAllSelectionServiceType()">Select All
                </mat-checkbox>
              </div>
              <mat-option (click)="optionClickServiceType()" *ngFor="let service of SarviceType; let i=index"
                [value]="service.ServiceType" class="dropdown-opt-wrap">{{service.ServiceType}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1 ">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Case Status</mat-label>
            <mat-select disableOptionCentering #matCaseStatus multiple >
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCasestatus"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAllSelectionCaseStatus()">Select All
                </mat-checkbox>
              </div>
              <mat-option (click)="optionClickCaseStatus()" *ngFor="let status of CaseStatus; let i=index"
                [value]="status.CaseStatus" class="dropdown-opt-wrap">{{status.CaseStatus}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>
    <!--Third  Row -->
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">

        <div class="col-sm-3 pr-1 pl-0">
          <mat-form-field class="w-100 example-additional-selection " appearance="outline">
            <mat-label>Collection Date</mat-label>
            <mat-date-range-input #matcollectiondate [formGroup]="range" [rangePicker]="picker1" class="date-align">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle class="svg-align" matSuffix [for]="picker1">
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
          </mat-form-field>
          <div *ngIf="CollectionDate" class="error-validation">Selected date is more then 31 Days.</div>
        </div>


        <div class="col-sm-3 pr-1">
          <mat-form-field class="w-100 example-additional-selection" appearance="outline">
            <mat-label>Received Date</mat-label>
            <mat-date-range-input #matReceiveddate [formGroup]="range2" [rangePicker]="picker3" class="date-align">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle class="svg-align" matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
          </mat-form-field>
          <div *ngIf="ReceivedDate" class="error-validation">Selected date is more then 31 Days.</div>
        </div>

        <div class="col-sm-3 pr-1">
          <mat-form-field class="w-100 example-additional-selection" appearance="outline">
            <mat-label>Send Out Date</mat-label>
            <mat-date-range-input #matSendOutDate [formGroup]="range3" [rangePicker]="picker4" class="date-align">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle class="svg-align" matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
          </mat-form-field>
          <div *ngIf="SendOutDate" class="error-validation">Selected date is more then 31 Days.</div>
        </div>

        <div class="col-sm-3 pr-1">
          <mat-form-field class="w-100 example-additional-selection" appearance="outline">
            <mat-label>Report Date</mat-label>
            <mat-date-range-input #matReportdate [formGroup]="range1" [rangePicker]="picker2" class="date-align">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle class="svg-align" matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
          </mat-form-field>
          <div *ngIf="ReportedDate" class="error-validation">Selected date is more then 31 Days.</div>
        </div>

      </div>
    </div>
    <!-- Fourth Row -->
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">
        <div class="col-sm-3 pr-1 pl-0">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Accession#</mat-label>
            <input #matAccession type="text" matInput>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>Requistion#</mat-label>
            <input #matRequistion type="text" matInput>
          </mat-form-field>
        </div>

        <div class="col-sm-3 pr-1">
          <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
            <mat-label>EMR#</mat-label>
            <input #matEMR type="text" matInput>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="w-100 example-additional-selection" appearance="outline">
            <mat-label>CaseID</mat-label>
            <input #matCaseId type="number" (keypress)="AllowOnlyNumber($event)" matInput>
          </mat-form-field>
        </div>

      </div>
    </div>
    <!-- Fifth Row -->
    <div class="col-sm-12">
      <div class="row d-flex p-1 box-align">
        <div class="col-sm-3 pr-1 pl-0 form-field-dropdown">
          <mat-form-field class="w-100 example-additional-selection p-0 form-field-dropdown" appearance="outline">
            <mat-label style="height: 40px;" >Patient Name</mat-label>
            <input #matPatientName matInput>
          </mat-form-field>
        </div>
        <div class="col-sm-3 pr-1">
          <mat-form-field class="w-100 example-additional-selection" appearance="outline">
            <mat-label>Patient Date Of Birth</mat-label>
            <input #matPatientDOB matInput [matDatepicker]="picker5" class="date-align patient-dob">
            <mat-datepicker-toggle class="svg-align" matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- // more hide -->
  <div class="row col-sm-12 mt-3 more-data mb-2" *ngIf="moreHide">
    <span class="mr-2 cursor" (click)="moreHide=false">More</span><em
      class="fa fa-chevron-down mt-1 font-size more-arrow" (click)="moreHide=false"></em>
  </div>
  <div class="row col-sm-12 more-data mb-2" *ngIf="!moreHide">
    <span class="mr-2 cursor" (click)="moreHide=true">Hide</span><em class="fa fa-chevron-up mt-1 font-size more-arrow"
      (click)="moreHide=true"></em>
  </div>

  <!-- buttons  -->
  <div class="row col-sm-12 btn-wrap mt-4">
    <button mat-raised-button class="admin-btn-success mr-4"
      (click)="getClear(matCaseType,matPathologist,matCaseId,matAccession,matRequistion,matEMR,matPatientName,matSendoutlaboratory,matCytotechnologiest,matCaseStatus,matServiceType,matLab)">Clear</button>
    <button mat-raised-button class="admin-btn-success"
      (click)="getData(matCaseType,matPathologist,matcollectiondate,matReportdate,matReceiveddate,matSendOutDate,matCaseId,matAccession,matRequistion,matEMR,matPatientName,matPatientDOB,matSendoutlaboratory,matCytotechnologiest,matCaseStatus,matServiceType,matLab)"
      [disabled]="btnGetData">Get Data</button>

  </div>
  <div class="row mt-2 d-flex error-align">
    <div class="row col-sm-3 pl-5">
      <span *ngIf="btndisabled" class="error-validation">Please enter atleast one data</span>
    </div>
  </div>
  <span class="error"> Note: By default, cases for the last 31 days will be listed considering the Collection
    Date And Exporting cases during working hours, you don't get proper user experience.</span>
  <!-- wijmo-grid -->
  <div *ngIf="gridDisplay" class="gridshow col-sm-12">
    <div class="d-block table-align ">
      <wj-flex-grid #grid [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="gridData"
        (initialized)="initGrid(grid)">
        <div *ngFor="let col of columns">
          <wj-flex-grid-column [width]="200" [minWidth]="40" [binding]="col.binding" [header]="col.header"
            [visible]="!(col.header == 'CreatedBy') && !(col.header == 'CreatedDate') && !(col.header == 'ModifiedBy') && !(col.header == 'ModifiedDate')"
            [fixed]="true" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>

      <br />
      <div class="navigator-fix">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
      </div>
      <div class="align-center mt-4">
        <button type="button" mat-raised-button class="admin-btn-success" [disabled]="btnExport"
          (click)="exportExcel(grid)">
          Export
        </button>
      </div>
    </div>
  </div>


  <!-- Nodata part -->
  <div *ngIf="gridEmpty" class="col-sm-12">
    <div class="nodata-wrap mt-4">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
</div>
