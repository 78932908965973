import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { Action } from 'rxjs/internal/scheduler/Action';

declare var $: any;

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-organization-tags',
  templateUrl: './organization-tags.component.html',
  styleUrls: ['./organization-tags.component.scss']
})

export class OrganizationTagsComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };
  gridheader: any[];
  formatGridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean = false;
  gridwidth: number = 0;
  gridWidth: number = 0;
  excelDataArray: any[];
  showDelete: boolean = true;
  postDownload: boolean = false;
  postUpload: boolean = false;
  backBtn: boolean = false;
  gridArray: any[];
  showPaginationMainGrid: boolean = false;
  columns: ColDef[];
  @Input() templateData: any;
  public SubMenuCardModel;
  public DeploymentKeys = [];
  workBook: any;
  sheetsToSelect: any;
  hideCreateBtn: boolean = false;
  hideUploadBtn: boolean = false;
  hideEditBtn: boolean = false;
  uploadClicked: boolean = false;
  addEditClicked: boolean = false;
  editEnabled: boolean = false;
  mandatoryFields = [{}];
  allFields: any = [{}];
  sampleExcel: any = [{}];

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  @ViewChild('inputAutoCompleteGroup') inputAutoCompleteGroup: any;

  @ViewChild('grid') flex: WjFlexGrid;
  @ViewChild('flexgrid') organizationtags: WjFlexGrid;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  private _currentEditItem: any = null;
  sheetHeader: any = [];
  destDeployment: string = "";
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  AllFields = [{}]
  updateFields = [{}]
  MandatoryFields = [{}]
  copyFlag: any;
  noData: boolean = true;
  selector: any;
  gridData: wjcCore.CollectionView<any>;
  selectedData: any = [];
  copyDataClicked: boolean;
  gridShow: boolean = false;
  bulkUpload: boolean;
  enableInsert: boolean;
  searchResult: any[];
  selectedCopyData: any = [];
  showDefaultFormats: boolean = false;
  organizationList: any = [];
  RoleList: any = [];
  usertoggle: boolean = false;
  srcRole: string = '';
  searchRoleList: any[];
  Orgtype: any;
  searchUserInput: String = '';
  tagValueRowList: any = [];
  destOrgid: any;
  CasetypeList: any = [];
  CaseStatusList: any = [];
  CaseNStatusList: any = [];
  AccountsList: any = [];
  selectedRoleList: any = [];
  selectedCasetypeList: any = [];
  selectedCasestatusList: any = [];
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  selectedNCasestatusList: any = [];
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  arrowIconSubject2 = new BehaviorSubject('arrow_drop_down');
  CasetypeDataList: any;
  caseStatusDataList: any;
  nCaseStatusDataList: any;
  GroupList: any = [];
  groupId: any;
  searchInput: String = '';
  srcOrgid: string;
  srcDeployment: any;
  checkStatus: boolean = false;
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  uploadBtn: boolean = false;
  copyBtn: boolean = false;
  selectedAccountid: any;
  caseTagForm: FormGroup;
  selectedrowData: any;
  TagValueColor: any = '';

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.caseTagForm = this._fb.group({
      frmTagType: ['', Validators.required],
      frmTagGroupName: [''],
      frmTagName: ['', Validators.required],
      frmGroupColor: [''],
      frmFontColor: [''],
      frmTagSequence: [''],
      frmIsActive: [''],
      frmAllowBulkEdit: [''],
      frmTagId: [''],
      frmGroupId: [''],
      frmAccountId: [''],
      frmTagValueGroup: this._fb.array([
        this._fb.group({
          frmTagValue: [''],
          frmTagValueSequence: [''],
          frmTagValueColor: [''],
          frmTagValueFontColor: [''],
          frmTagValueIsDefault: [''],
          frmTagValueId: ['']
        })]),
      frmAccountName: ['', Validators.required],
      frmCasetype: ['', Validators.required],
      frmCaseStatus: ['', Validators.required],
      frmNonEditableCaseStatus: [''],
      frmRoles: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.destOrgid = sessionStorage.getItem('org_id').toUpperCase();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noData = true;
    this.getAccountType();
    this.GetButtondetails();
  }
  colorCtr: AbstractControl = new FormControl(null);

  copyOrgTagsMethodForm = this._fb.group({
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmOrgID: [''],
    frmRole: [''],
    frmcpyasinactive: false
  });

  rolesDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,
  };

  casetypeDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,
  };

  caseStatusDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,
  };

  removeFormControl(i) {
    let tempArray = this.caseTagForm.controls.frmTagValueGroup as FormArray;
    tempArray.removeAt(i);
  }

  addFormControl() {
    let tempArray = this.caseTagForm.controls.frmTagValueGroup as FormArray;
    let arraylen = tempArray.length;

    let newUsergroup: FormGroup = this._fb.group({
      frmTagValue: [''],
      frmTagValueSequence: [''],
      frmTagValueColor: [''],
      frmTagValueFontColor: [''],
      frmTagValueIsDefault: [''],
      frmTagValueId: "",
      frmGroupId: "",
      frmTagId: "",
      frmAccountId: ""
    })
    tempArray.insert(arraylen, newUsergroup);
  }

  addGridData(templateData: any) {
    let primary = {};
    let gridarray = [];
    this.GroupList = [];
    if (templateData && templateData.length > 0) {
      for (let i = 0; i < templateData.length; i++) {
        if (!templateData[i].groupid) {
          this.GroupList.push({ groupid: templateData[i].organizationtagid, groupname: templateData[i].tagname })
        }
        primary = {};
        for (let [key, value] of Object.entries(templateData[i])) {
          if (!value) {
            value = 'Not Specified';
          }
          if (key.toString().toLowerCase() == 'isgroup' || key.toString().toLowerCase() == 'isactive' || key.toString().toLowerCase() == 'allowbulkedit') {
            if (!value || value.toString() == 'false' || value.toString().toLowerCase() == 'not specified') {
              value = false;
            }
            else {
              value = true;
            }
          }
          primary[key] = value;
        }
        gridarray.push(primary);
      }
      this.gridData = new CollectionView(gridarray, { groupDescriptions: ['groupname'], pageSize: 10 });
      this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
      if (this.gridwidth > 1300) {
        this.gridwidth = 1300;
      }
      this.noData = false;
    }
    else {
      this.gridwidth = 0;
      this.noData = true;
    }
    this.gridShow = true;
  }

  async getOrgTaglist(context?) {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getorganizationtags');
    queryVariable = {
      orgid: sessionStorage.getItem('org_id').toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let data = await this.VitalHttpServices.GetData(queryResult).toPromise();
    if (!data.errors) {
      this.templateData.submenuData = data.data.submenuData;
      let templateData = data.data.submenuData;
      if (templateData.length > 0) {
        this.addGridData(templateData);
        if(context == true || this.editEnabled){
          this.gridShow = true;
        }
      else{
        this.gridShow = false;
      }
        this.noData = false;
      }
      else {
        this.gridwidth = 0
        this.noData = true;
      }
    }
    this.ngxService.stop();
  }

  //sample data
  sampleDataAllFields = [{
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '1'
    , GroupColor: ''
    , TagName: 'Any text'
    , TagValue: ''
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: ''
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Group'
  }, {
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '0'
    , GroupColor: ''
    , TagName: 'Transcription Status'
    , TagValue: 'Yes'
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: '0/1'
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Tag and Tag values under the selected group name'
  }, {
    Roles: 'All/RoleName'
    , GroupName: ''
    , IsGroup: '0'
    , GroupColor: ''
    , TagName: 'Transcription Status'
    , TagValue: 'Yes'
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: '0/1'
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Tag and Tag values'
  }]

  sampleDataMandatoryFields = [{
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '1'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: ''
    , Result: 'Creates Group'
  },
  {
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '0'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: 'Yes/No'
    , Result: 'Creates Tag and Tag values under selected Group'
  },
  {
    Roles: 'All/RoleName'
    , GroupName: ''
    , IsGroup: '0'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: 'Yes/No'
    , Result: 'Creates Tag and Tag values'
  }]

  loadUploadScreen() {
    this.noData = false;
    this.uploadClicked = true;
    this.copyDataClicked = false;
    this.enableInsert = false;
    this.gridShow = false;
    this.bulkUpload = true;
    this.gridwidth = 0
    let queryVariable = { tablename: 'organizationtags', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.mandatoryFields[0]["Roles"] = ''
        this.mandatoryFields[0]["GroupName"] = ''
        this.mandatoryFields[0]["IsGroup"] = ''
        this.mandatoryFields[0]["TagName"] = ''
        this.mandatoryFields[0]["CaseType"] = ''
        this.mandatoryFields[0]["CaseStatus"] = ''
        this.mandatoryFields[0]["AccountId"] = ''
        this.mandatoryFields[0]["TagValue"] = ''
        this.allFields[0]["Roles"] = ''
        this.allFields[0]["GroupName"] = ''
        this.allFields[0]["IsGroup"] = ''
        this.allFields[0]["GroupColor"] = ''
        this.allFields[0]["TagName"] = ''
        this.allFields[0]["TagValue"] = ''
        this.allFields[0]["SequenceOrder"] = ''
        this.allFields[0]["FontColor"] = ''
        this.allFields[0]["CaseType"] = ''
        this.allFields[0]["CaseStatus"] = ''
        this.allFields[0]["AccountId"] = ''
        this.allFields[0]["IsActive"] = ''
        this.allFields[0]["AllowBulkEdit"] = ''
        this.allFields[0]["NotEditableCaseStatus"] = ''
        this.allFields[0]["IsDefault"] = ''
        this.allFields[0]["TagValueFontColor"] = ''
        this.allFields[0]["Color"] = ''
      }
    }, error => {
      console.log(error);
    });
  }

  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'Case Tags_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTags');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'mandatoryFieldsCheck') {
      filename = 'Case Tags_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.mandatoryFields;
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTags');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMandatoryFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
  }

  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationtags')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if(result){
      this.convertToJson(result)
    }
  }



  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/isgroup/i)) {
              if (!value || value.toString() === '0') {
                value = 0;
              }
              else {
                value = 1;
              }
            }
            if (key == 'IsActive') {
              if (value === 1 || value === true) {
                value = true;
              }
              else if (value === 0 || value === false) {
                value = false;
              } else if(value === null || value === "" || value === undefined){
                value = true;
              }
              primary[key] = value;
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value == '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'OrganizationTagId') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationtags', organizationid: this.templateData.secondarykeys.OrganizationId.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  async validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    for (let i = 0; i < dataArray.length; i++) {
      if (this.copyDataClicked) {
        dataArray[i]['accountid'] = (dataArray[i]['accountid'] && dataArray[i]['accountid'].toString() != '-1' ? this.templateData.cardIdentifier : -1);
        dataArray[i]['groupname'] = (!dataArray[i]['groupid'] && (!dataArray[i]['isgroup'] || (dataArray[i]['isgroup'] && dataArray[i]['isgroup'].toString() == '0')) ? null : dataArray[i]['groupname']);
      } else {
        if ('isactive' in dataArray[i]) {
          dataArray[i]['isactive'] = (dataArray[i]['isactive'] && (dataArray[i]['isactive'].toString() == '1' || dataArray[i]['isactive'].toString() == 'true')) ? 1 : 0;
        }
        if (dataArray[i]['accountid'] && dataArray[i]['accountid'].toString().toLowerCase() == 'all') {
          dataArray[i]['accountid'] = -1;
        }
      }

      dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
      delete dataArray[i]['accountname']
      delete dataArray[i]['organizationcasetagvalueid']
      delete dataArray[i]['tagvaluesequence']
    }
    let result = await this.VitalHttpServices.validateOrganizationTagsData(dataArray, this.destDeployment).toPromise();
    if (!result.errors) {
      this.noData = false
      if (result && result.length > 0) {
        if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
          this.showInvalidColumns = true;
          this.invalidColumns = result[0].InvalidColumns;
        }
        dataArray.filter(e => {
          result.filter(r => {
            if (r.slno === e.slno) {
              e.notes = r.notemessage;
            }
          });
        });
        this.ExcelGridData(dataArray);
        this.ngxService.stop();
      }
      else {
        this._snackbar.open("Please provide valid data", 'Failed');
      }
    }
    this.noData = true;
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  ExcelGridData(data) {
    this.ngxService.start()
    this.noData = false;
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i]["status"] || data[i]["status"] == '') {
            data[i]["status"] = 'Ignored'
          }
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i)) {
              if (key == 'isgroup') {
                if (!value || value.toString() == '0') {
                  value = false;
                }
                else {
                  value = true;
                }
              }
              if (key == 'isactive') {
                if (value == 1) {
                  value = true;
                }
                else if (value == 0) {
                  value = false;
                } else //if(value != 0 && (value == null || value ==''))
                {
                  value = true;
                }
                primary[key] = value;
              }
              if (key.toString().toLowerCase() == 'allowbulkedit') {
                if (!value || value.toString() == 'false' || value.toString().toLowerCase() == 'not specified') {
                  value = false;
                }
                else {
                  value = true;
                }
                primary[key] = value;
              }
              if (!this.copyDataClicked) {
                if (key && key.toString().toLowerCase().trim() == 'accountid' || key.toString().toLowerCase().trim() == 'groupname' || key.toString().toLowerCase().trim() == 'tagname' || key.toString().toLowerCase().trim() == 'casetype' || key.toString().toLowerCase().trim() == 'casestatus') {
                  if (!value || value.toString() == '') {
                    primary['notes'] = 'Mandatory field is missing!'
                  }
                  else {
                    primary[key] = value;
                  }
                }
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
        }
        this.gridData = new CollectionView(gridArray, { groupDescriptions: ['groupname'] });
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    } else {
      this.gridwidth = 0;
    }
    this.ngxService.stop();
  }


  initGrid(grid, hitCount?) {
    let tempData = [];
    tempData = this.excelDataArray && this.excelDataArray.length > 0 ? [...this.excelDataArray] : [];
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    if (this.copyDataClicked) {
      for (let i = 0; i < this.selectedData.length; i++) {
        if (!this.selectedData[i]._data.groupid) {
          if (tempData && tempData.length > 0) {
            for (let j = 0; j < tempData.length; j++) {
              if (tempData[j].groupid && tempData[j].groupid == this.selectedData[i]._data.organizationtagid) {
                this.excelDataArray.push(tempData[j])
              }
              else {
                this.excelDataArray.push(this.selectedData[i]._data);
              }
            }
          }
        }
      }
    }

    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  gridRemove() {
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.searchResult = []
    this.gridwidth = 0
    this.copyOrgTagsMethodForm.reset();
    // this.copyOrgTagsMethodForm.controls.frmOrganization.enable()
    this.copyOrgTagsMethodForm.patchValue({
      frmOrganization: '',
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      frmOrgID: '',
      frmRole: '',
      frmcpyasinactive: false
    })
    this.searchInput = ''
    this.gridData = new CollectionView([]);
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  //#region Tooltip for the wijmo grid
  initTooltipGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  disableGetDataBtn() {
    return (this.searchInput == '') ? true : (this.srcOrgid && this.srcOrgid) != '' ? false : true
  }

  async refreshGrid(context?) {
    if(context){
      this.organizationList = [];
      this.noData = false;
      this.srcRole = '';
      this.gridwidth = 0;
      this.excelDataArray = [];
      this.showInvalidColumns = false;
      this.postUpload = false;
      this.copyDataClicked = false;
      this.bulkUpload = false
      this.searchResult = [];
      this.addEditClicked = false;
      this.uploadClicked = false;
      this.selectedData = [];
      this.searchInput = ''
      this.srcDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.copyOrgTagsMethodForm.reset();
      this.copyOrgTagsMethodForm.patchValue({
        frmOrganization: '',
        frmDepKey: this.srcDeployment,
        frmOrgID: "",
        frmRole: "",
        frmcpyasinactive: false
      })
    }
    await this.getOrgTaglist(context);
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }

  approveDisable() {
    // let data;
    let existsCount = 0;
    if (!this.copyDataClicked) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if ((this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Invalid/i))) || (this.excelDataArray[i]['notes'] && this.excelDataArray[i]['notes'].toString().match(/mandatory/i)) || (this.excelDataArray[i]['notes'] && this.excelDataArray[i]['notes'].toString().match(/Wrong/i))) {
          existsCount++;
        }
      }
      if (existsCount > 0) {
        return true
      }
    }
    else {
      if (this.selectedData.length == 0) {
        return true
      }
      return this.selectedData.every(va=> va._data['notes'] && (va._data['notes'].match(/Invalid/) || va._data['notes'].match(/mandatory/) || va._data['notes'].match(/Wrong/)))

    }
    return false;
  }

  ExportExcel(data, type?) {
    let excel = [];
    let flex = data ? data : this.organizationtags;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = cloneDeep(flex.rows);
    rows.find((e: any): any => {
      delete e._data['Action'];
      delete e._data['organizationid']
      delete e._data['slno']
      delete e._data['copyflag']
      delete e._data['tablename']
      delete e._data['associationtype']
      delete e._data['createdby']
      delete e._data['_path']
      delete e._data['_items']
      delete e._data['_groups']
      delete e._data['_level']
      delete e._data['_isBottomLevel']
      delete e._data['_name']
      delete e._data['_gd']
      delete e._data['noteditablecasestatus']
      delete e._data['isdefault']
      delete e._data['tagvaluefontcolor']
      delete e._data['tagvaluesequence']
      delete e._data['organizationcasetagvalueid']
      delete e._data['type']
      delete e._data['groupid']
      delete e._data['color']
      delete e._data['AccountId']
      delete e._data['allowbulkedit']
      delete e._data['accountname']

      let primary = {}
      for (let [key, value] of Object.entries(e._data)) {
        if (key == 'groupname') {
          primary['Group name'] = value;
        }
        else if (key == 'accountid') {
          primary['Location Id'] = value;
        }
        else if (key == 'tagname') {
          primary['Tag name'] = value;
        }
        else if (key == 'casetype') {
          primary['CaseType'] = value;
        }
        else if (key == 'casestatus') {
          primary['CaseStatus'] = value;
        }
        else if (key == 'isgroup') {
          primary['Is group'] = value;
        }
        else if (key == 'tagvalue') {
          primary['Tag value'] = value;
        }
        else if (key == 'roles') {
          primary['Roles'] = value;
        }
        else if (key == 'status') {
          primary['Status'] = value;
        }
        else if (key == 'notes') {
          primary['Notes'] = value;
        }
        else {
          primary[key] = value;
        }
      }
      excel.push(primary);
    });
    let filename = 'Case Tags_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Organization Tags');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }

  ExportUpload(data, type?) {
    let excel = [];
    let flex = data ? data : this.organizationtags;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = cloneDeep(flex.rows);
    rows.find((e: any): any => {
      delete e._data['Action'];
      delete e._data['organizationid']
      delete e._data['slno']
      delete e._data['copyflag']
      delete e._data['tablename']
      delete e._data['associationtype']
      delete e._data['createdby']
      delete e._data['_path']
      delete e._data['_items']
      delete e._data['_groups']
      delete e._data['_level']
      delete e._data['_isBottomLevel']
      delete e._data['_name']
      delete e._data['_gd']
      if (this.copyDataClicked) {
        delete e._data['accountname']
      }

      let primary = {}
      for (let [key, value] of Object.entries(e._data)) {
        if (key == 'groupname') {
          primary['Group name'] = value
        }
        else if (key == 'accountid') {
          primary['Location Id'] = value
        }
        else if (key == 'tagname') {
          primary['Tag name'] = value
        }
        else if (key == 'casetype') {
          primary['CaseType  '] = value
        }
        else if (key == 'casestatus') {
          primary['CaseStatus  '] = value
        }
        else if (key == 'isgroup') {
          primary['Is group'] = value
        }
        else if (key == 'tagvalue') {
          primary['Tag value'] = value
        }
        else if (key == 'roles') {
          primary['Roles'] = value
        }
        else if (key == 'status') {
          primary['Status'] = value
        }
        else if (key == 'notes') {
          primary['Notes'] = value
        }
        else {
          primary[key] = value;
        }
      }
      excel.push(primary);
    });
    let filename = 'Case Tags_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Organization Tags');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }

  approveOrgTags(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) || this.excelDataArray[i]["notes"].toString().includes('does not exist')) {
            errorcount++
          }
        }
      }

      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (selectedData.some(va => !va.accountid || !va.groupname || !va.tagname || !va.casetype || !va.casestatus)) {
        this._snackbar.open('Some of the mandatory data is missing!', 'Close');
        return
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        // if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
        //   this._snackbar.open("All selected records already exist!", "Close");
        //   this.bulkUpload = true;
        //   return;
        // }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let context = copyData ? null : ''
      this.addOrganizationTagsMethods(dataArray, this.bulkUpload, context)
    }
  }

  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing!' || value == 'Null' || (value.toString().includes('Invalid configuration')) || value.toString().includes('does not exist') || value.toString().toLowerCase().includes('invalid')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('successfully') || value == 'Success'
          || value.toString().includes('will be added')
          || value.toString().includes('on approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exists') || (value.toString().includes('already exist'))
          || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  async addOrganizationTagsMethods(dataArray: object[], bulkUpload: boolean, context?) {
    this.ngxService.start();
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    for (let i = 0; i < dataArray.length; i++) {
      if (!this.addEditClicked) {
        dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
        dataArray[i]['createdby'] = userid;
        dataArray[i]['accountid'] = this.copyDataClicked ? -1 : dataArray[i]['accountid'];
        if (this.copyDataClicked) {
          dataArray[i]["isactive"] = (this.checkStatus ? 0 : 1);
          dataArray[i]['roles'] = (!this.srcRole ? 'ALL' : this.srcRole);
          dataArray[i]['groupname'] = (!dataArray[i]['groupid'] && (!dataArray[i]['isgroup'] || (dataArray[i]['isgroup'] && dataArray[i]['isgroup'].toString() == '0')) ? null : dataArray[i]['groupname']);
        }
        else {
          if ('isactive' in dataArray[i]) {
            dataArray[i]['isactive'] = (dataArray[i]['isactive'] && (dataArray[i]['isactive'].toString() == '1' || dataArray[i]['isactive'].toString() == 'true')) ? 1 : 0;
          }
          dataArray[i]['roles'] = !dataArray[i]['roles'] ? 'ALL' : dataArray[i]['roles'];
        }
        delete dataArray[i]['accountname']
        delete dataArray[i]['organizationcasetagvalueid']
        delete dataArray[i]['tagvaluesequence']
      }
      else {
        dataArray[i]['createdby'] = userid;
        dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
      }
    }
    let result = await this.VitalHttpServices.InseretOrganizationTags(dataArray, this.destDeployment).toPromise()//.subscribe(result => {
    this.noData = false;
    this.ngxService.stop();
    if (!result.errors) {
      if (!this.addEditClicked) {
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.status = r.status
              e.notes = r.notemessage;
            }
          });
        });
        // dataArray.forEach((va: any) => {
        //   if (va.notes.toString().toLowerCase().match(/already exists/i)) {
        //     va.status = 'Ignored'
        //   }
        // })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          this.showDelete = false;
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            let message = bulkUpload ? 'Data uploaded successfully' : 'Copied successfully';
            this.ExcelGridData(uploadresult);
            this._snackbar.open(message, 'Close');
            this.auditDetails({}, result, 'added');
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
      else {
        if (result.length > 0) {
          if (context == 0) {
            this.refreshGrid(true);
            this.gridShow = true;
            this.postUpload = false;
            this.showDelete = false;
            this.showDelete = false;
            this.addEditClicked = false;
          }
          else {
            this.refreshGrid();
            this.gridShow= false;
            this.resetForm();
          }
          this.auditDetails({}, result, 'added');
          this._snackbar.open('Created successfully', 'Success')
        }
        else {
          this._snackbar.open('Create failed', 'Failure')
        }
      }
    } else {
      this._snackbar.open('Create failed', 'Failure')
    }
    this.ngxService.stop();
  }

  resetForm(val?) {
    this.caseTagForm.reset();
    const formArray = new FormArray([]);

    this.caseTagForm.patchValue({
      frmTagType: "",
      frmTagGroupName: "",
      frmTagName: "",
      frmGroupColor: "",
      frmFontColor: "",
      frmTagSequence: "",
      frmIsActive: true,
      frmAllowBulkEdit: "",
      frmTagValueGroup: formArray.patchValue([]),
      frmAccountName: "ALL",
      frmCasetype: [{ casetype: "ALL" }],
      frmCaseStatus: [{ item: "ALL" }],
      frmNonEditableCaseStatus: "",
      frmRoles: [{ displayname: "ALL" }]//this.RoleList
    });
    this.caseTagForm.setControl('frmTagValueGroup', this._fb.array([
      this._fb.group({
        frmTagValue: "",
        frmTagValueSequence: "",
        frmTagValueColor: "",
        frmTagValueFontColor: "",
        frmTagValueIsDefault: "",
        frmTagValueId: "",
        frmTagId: "",
        frmGroupId: "",
        frmAccountId: ""
      })]));
    this.selectedCasetypeList = [];
    this.selectedCasestatusList = [];
    this.selectedNCasestatusList = [];
    this.selectedRoleList = [];
    if (val) {
      this.caseTagForm.patchValue({
        frmTagType: val,
      })
    }
  }

  loadCopyScreen() {
    this.noData = false;
    this.copyDataClicked = true;
    this.gridShow = false;
    this.gridwidth = -1;
    this.backBtn = true;
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys
    this.copyOrgTagsMethodForm.patchValue({
      frmOrganization: '',
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      frmOrgID: "",
      frmRole: "",
      frmcpyasinactive: false
    })
    this.searchInput = '';
    this.getListOrg();
  }


  getListOrg() {
    this.organizationList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.organizationList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  resetFormErrors() {
    this.copyOrgTagsMethodForm.controls.frmOrganization.enable()
  }

  fetchOrgSeries(value: string) {
    this.usertoggle = false;
    this.searchResult = [];
    if (value === '') {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
       this.organizationList.find(r=> {
        if (r.organizationname) {
          if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
            this.srcOrgid = r.organizationid
              return r
          }
          else{
            this.srcOrgid = '';
          }
        }
        });
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.organizationList.find(r=>{
        if(r.organizationid.toString().toLowerCase() === value.toLowerCase()){
          this.srcOrgid = r.organizationid
          return r
        }
      });
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copyOrgTagsMethodForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.organizationname != null) {
        return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
  }

  onChangeDeployment(e) {
    this.searchResult = []
    this.organizationList = [];
    this.srcRole = '';
    this.gridwidth = -1;
    this.copyOrgTagsMethodForm.patchValue({
      frmDepKey: e.value,
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false,
      frmRole: ''
    });
    this.postUpload = false;
    this.backBtn = true;
    this.searchInput = '';
    this.srcDeployment = e.value;
    this.getListOrg();
    this.usertoggle = false;
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected && Object.keys(data).length > 0) {
      this.copyOrgTagsMethodForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      })
      this.srcOrgid = data.organizationid;
      this.searchInput = data.organizationname
      this.getUserRoles(this.destDeployment);
    }
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcRole = data.displayname;
      this.searchUserInput = data.displayname;
    }
  }

  // getorganizationtagentities
  async getUserRoles(dbName) {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getuserrolesforassociationtype');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0].associationtype + "\"";
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let res = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise()
    this.ngxService.stop();
    if (!res.errors) {
      if (res.data) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.RoleList = [...res.data.vwGetRolesBasedOnAccountAssociation_list];
        }
      }
      this.RoleList.push({ displayname: "ALL" })
      this.RoleList.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0);

      this.rolesDropdownSettings = {
        singleSelection: false,
        idField: 'displayname',
        textField: 'displayname',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 5,
        allowSearchFilter: true,
        enableCheckAll: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 150,
        // itemsShowLimit: 44,
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false,
      };
      this.caseTagForm.patchValue({
        frmRoles: [{ displayname: "ALL" }]
      });
    }
  }

  async loadAddEditScreen() {
    await this.resetForm();
    await this.getLookupValues();
    this.addEditClicked = true;
    this.gridShow = false;
    this.editEnabled = false;
    this.noData = false;
  }

  async getLookupValues() {
    await this.getCasestatusList();
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getorganizationtagentities');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0].associationtype + "\"";
    queryVariable = { "associationtype": Qstr, orgid: this.destOrgid.toString() };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let res = await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise();
    if (!res.errors) {
      if (res.data) {
        this.CasetypeDataList = [];
        this.caseStatusDataList = [];
        this.nCaseStatusDataList = [];
        this.AccountsList = [];
        this.CasetypeList = [];
        this.CasetypeList = (res.data.submenuDataCasetypes.length > 0) ? res.data.submenuDataCasetypes : [];
        this.AccountsList = (res.data.submenuDataAccounts.length > 0) ? res.data.submenuDataAccounts : [];
        this.AccountsList.push({ accountid: -1, accountname: 'ALL' })
        this.CasetypeList.push({ casetype: 'ALL' })
        this.CasetypeDataList = [...this.CasetypeList];
        this.CasetypeList = res.data.submenuDataCasetypes && res.data.submenuDataCasetypes.length > 0 ? [...new Map(res.data.submenuDataCasetypes.map(item => [item["casetype"], item])).values()] : [];
        this.CasetypeList.sort((a, b) => a.casetype < b.casetype ? -1 : a.casetype > b.casetype ? 1 : 0);
        this.AccountsList.sort((a, b) => a.accountname < b.accountname ? -1 : a.accountname > b.accountname ? 1 : 0);
        if (!this.editEnabled) {
          this.caseTagForm.patchValue({
            frmAccountName: "ALL",
            frmCasetype: [{ casetype: 'ALL' }],
            frmCaseStatus: [{ item: "ALL" }],
            frmRoles: [{ displayname: "ALL" }]
          })
        }
        this.rolesDropdownSettings = {
          singleSelection: false,
          idField: 'displayname',
          textField: 'displayname',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 5,
          allowSearchFilter: true,
          enableCheckAll: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 150,
          // itemsShowLimit: 44,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false,
        };

        this.casetypeDropdownSettings = {
          singleSelection: false,
          idField: 'casetype',
          textField: 'casetype',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 5,
          allowSearchFilter: true,
          enableCheckAll: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 150,
          // itemsShowLimit: 44,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false,
        };
      }
      else {
        this._snackbar.open('Something went wrong!', 'Close')
      }
    }
    else {
      console.log(res)
    }
  }

  public GetQuery(keyword: string) {
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
  }

  async getCasestatusList() {
    sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'CaseStatus',
      context: 'OrganizationTags',
    };
    let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let Resdata = await this.VitalHttpServices.GetData(queryResult, dbname).toPromise();
    sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
    if (!Resdata.errors) {
      if (Resdata.data) {
        if (Resdata.data.submenuData.length > 0) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.CaseStatusList = [...data.CaseStatus, { item: "ALL" }]
          this.CaseStatusList.sort((a, b) => a.item < b.item ? -1 : a.item > b.item ? 1 : 0);
          this.CaseNStatusList = cloneDeep(this.CaseStatusList);
          this.caseStatusDataList = [];
          this.nCaseStatusDataList = [];
          this.caseStatusDataList = [...this.CaseStatusList];
          this.nCaseStatusDataList = [...this.CaseNStatusList];

          this.caseStatusDropdownSettings = {
            singleSelection: false,
            idField: 'item',
            textField: 'item',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 4,
            allowSearchFilter: true,
            enableCheckAll: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            // itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false,
          };
        }
      }
    }
    sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
  }

  onCheckChange(item: any, type) {
    switch (type) {
      case 'roles':
        if (item.displayname.toString().toLowerCase() == 'all') {
          this.onDeSelectAll('roles')
          this.selectedRoleList.push({ displayname: 'ALL' })
        }
        else {
          this.selectedRoleList.push({ displayname: item.displayname })
          this.selectedRoleList = this.selectedRoleList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );

          for (let i = 0; i < this.selectedRoleList.length; i++) {
            if (this.selectedRoleList[i]['displayname'] && this.selectedRoleList[i]['displayname'].toString().toLowerCase().trim() == 'all') {
              delete this.selectedRoleList[i]
            }
          }
          this.selectedRoleList = this.selectedRoleList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
        }
        if (this.selectedRoleList.some(a => a.displayname && a.displayname.toString().toLowerCase().trim() == 'all')) {
          this.selectedRoleList = [];
          this.selectedRoleList = [{ displayname: "ALL" }]
          this.caseTagForm.patchValue({
            frmRoles: this.selectedRoleList
          })
        } else {
          this.caseTagForm.patchValue({
            frmRoles: this.selectedRoleList
          })
        }
        break;
      case 'casetype':
        if (item.casetype.toString().toLowerCase() == 'all') {
          this.onDeSelectAll('casetype')
          this.selectedCasetypeList.push({ casetype: 'ALL' })
        }
        else {
          this.selectedCasetypeList.push({ casetype: item.casetype });
          this.selectedCasetypeList = this.selectedCasetypeList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );

          this.selectedCasetypeList = [...new Map(this.selectedCasetypeList.map(item => [item["casetype"], item])).values()]

          for (let i = 0; i < this.selectedCasetypeList.length; i++) {
            if (this.selectedCasetypeList[i]['casetype'] && this.selectedCasetypeList[i]['casetype'].toString().toLowerCase().trim() == 'all') {
              delete this.selectedCasetypeList[i]
            }
          }
          this.selectedCasetypeList = this.selectedCasetypeList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
        }
        if (this.selectedCasetypeList.some(a => a.casetype && a.casetype.toString().toLowerCase().trim() == 'all')) {
          this.selectedCasetypeList = [];
          this.selectedCasetypeList = [{ casetype: "ALL" }]
          this.caseTagForm.patchValue({
            frmCasetype: this.selectedCasetypeList
          })
        } else {
          this.caseTagForm.patchValue({
            frmCasetype: this.selectedCasetypeList
          })
        }
        break;
      case 'casestatus':
        if (item.item.toString().toLowerCase() == 'all') {
          this.onDeSelectAll('casestatus')
          this.selectedCasestatusList.push({ item: 'ALL' })
        } else {
          this.selectedCasestatusList.push({ item: item.item.toString().trim() });
          this.selectedCasestatusList = this.selectedCasestatusList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
          this.selectedCasestatusList = [...new Map(this.selectedCasestatusList.map(item => [item["item"], item])).values()]

          for (let i = 0; i < this.selectedCasestatusList.length; i++) {
            if (this.selectedCasestatusList[i]['item'] && this.selectedCasestatusList[i]['item'].toString().toLowerCase().trim() == 'all') {
              delete this.selectedCasestatusList[i]
            }
          }
          this.selectedCasestatusList = this.selectedCasestatusList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
        }
        if (this.selectedCasestatusList.some(a => a.item && a.item.toString().toLowerCase().trim() == 'all')) {
          this.selectedCasestatusList = [];
          this.selectedCasestatusList = [{ item: "ALL" }];
          this.caseTagForm.patchValue({
            frmCaseStatus: this.selectedCasestatusList
          })
        } else {
          this.caseTagForm.patchValue({
            frmCaseStatus: this.selectedCasestatusList
          })
        }
        break;
      case 'ncasestatus':
        if (item.item.toString().toLowerCase() == 'all') {
          this.onDeSelectAll('ncasestatus')
          this.selectedNCasestatusList.push({ item: 'ALL' })
        } else {
          this.selectedNCasestatusList.push({ item: item.item.toString().trim() })
          this.selectedNCasestatusList = this.selectedNCasestatusList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
          this.selectedNCasestatusList = [...new Map(this.selectedNCasestatusList.map(item => [item["item"], item])).values()]

          for (let i = 0; i < this.selectedNCasestatusList.length; i++) {
            if (this.selectedNCasestatusList[i]['item'] && this.selectedNCasestatusList[i]['item'].toString().toLowerCase().trim() == 'all') {
              delete this.selectedNCasestatusList[i]
            }
          }
          this.selectedNCasestatusList = this.selectedNCasestatusList.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
        }

        if (this.selectedNCasestatusList.some(a => a.item && a.item.toString().toLowerCase().trim() == 'all')) {
          this.selectedNCasestatusList = [];
          this.selectedNCasestatusList = [{ item: "ALL" }];
          this.caseTagForm.patchValue({
            frmNonEditableCaseStatus: this.selectedNCasestatusList
          })
        } else {
          this.caseTagForm.patchValue({
            frmNonEditableCaseStatus: this.selectedNCasestatusList
          })
        }
        break;
    }
  }

  onSelectAll(items: any, type) {
    switch (type) {
      case 'roles': this.selectedRoleList = [];
        this.selectedRoleList.push({ displayname: 'ALL' });
        this.caseTagForm.patchValue({
          frmRoles: this.selectedRoleList
        })
        break;
      case 'casetype': this.selectedCasetypeList = [];
        this.selectedCasetypeList.push({ casetype: 'ALL' });
        this.caseTagForm.patchValue({
          frmCasetype: this.selectedCasetypeList
        })
        break;
      case 'casestatus': this.selectedCasestatusList = [];
        this.selectedCasestatusList.push({ item: 'ALL' });
        this.caseTagForm.patchValue({
          frmCaseStatus: this.selectedCasestatusList
        })
        break;
      case 'ncasestatus': this.selectedNCasestatusList = [];
        this.selectedNCasestatusList.push({ item: 'ALL' });
        this.caseTagForm.patchValue({
          frmNonEditableCaseStatus: this.selectedNCasestatusList
        })
        break;
    }
  }

  onItemDeSelect(items: any, type) {
    switch (type) {
      case 'roles': const index: number = this.selectedRoleList.indexOf(items.roles);
      var indexNum = this.selectedRoleList.findIndex((element) => {
        return (element.displayname === items.displayname);
      });
        if (indexNum != -1) {
          this.selectedRoleList.splice(indexNum, 1);
        }
        break;
      case 'casetype':
        const index1: number = this.selectedCasetypeList.indexOf(items.casetype);
        var indexNum = this.selectedCasetypeList.findIndex((element) => {
          return (element.casetype === items.casetype);
        });
          if (indexNum != -1) {
            this.selectedCasetypeList.splice(indexNum, 1);
          }
          break;
      case 'casestatus': const index2: number = this.selectedCasestatusList.indexOf(items.item,-1)
        //      if (index2 != -1) {
        //    //this.CaseNStatusList.push({item:items.item});
        //    this.selectedCasestatusList.splice(index2, 1);
        // }
        // break;
        var indexNum = this.selectedCasestatusList.findIndex((element) => {
          return (element.item === items.item);
        });
          if (indexNum != -1) {
            this.selectedCasestatusList.splice(indexNum, 1);
          }
          break;
      case 'ncasestatus': const index3: number = this.selectedNCasestatusList.indexOf(items.item);
      var indexNum = this.selectedNCasestatusList.findIndex((element) => {
        return (element.item === items.item);
      });
        if (indexNum != -1) {
          this.selectedNCasestatusList.splice(indexNum, 1);
        }
        break;
    }
  }

  manageDataColumn(data) {
    var i;
    for (i = 0; i < data.length; i++) {
      delete data[i]['organizationid']
      delete data[i]['accountid']
      delete data[i]['rolename']
      delete data[i]['displayname'];
      delete data[i]['accountname'];
      delete data[i]['type'];
    }
    this.convertToJson("", true, data);
  }

  fetchRoleSeries(value: string) {
    this.usertoggle = false;
    this.searchRoleList = [];
    this.srcRole = ''
    if (value === '') {
      return (this.searchRoleList = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.RoleList.find(r => {
          if (r.displayname.toString().toLowerCase() === value.toLowerCase()) {
            this.srcRole = r.displayname
            this.usertoggle = false;
            return r
          } else {
            this.srcRole = ''
            this.usertoggle = true
          }
        })
        this.searchRoleList = this.RoleList.filter(function (series) {
          if (series && series.displayname != null) {
            return series.displayname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchRoleList = this.searchRoleList.splice(0, 25);
      }
    }
    else {
      this.searchRoleList = this.RoleList.filter(function (series) {
        if (series && series.roleid != null) {
          return series.roleid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchRoleList = this.searchRoleList.splice(0, 25);
    }
  }

  getAccountType() {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getassociationtypeforaccid');
    let Qstr = "";
    Qstr += "accountid = \"" + this.templateData.cardIdentifier.toString() + "\"";
    queryVariable = { "accid": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.Orgtype = res.data.vwGetAssociationtypeByOrgID_list;
          this.getUserRoles(this.destDeployment);
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  public onDeSelectAll(type) {
    switch (type) {
      case 'roles': this.selectedRoleList = []; break;
      case 'casetype': this.selectedCasetypeList = []; break;
      case 'casestatus': this.selectedCasestatusList = []; break;
      case 'ncasestatus': this.selectedNCasestatusList = []; break;
    }
  }

  clear(evt: any, type): void {
    evt.stopPropagation();
    if (type == 'acc') {
      this.caseTagForm.patchValue({
        frmAccountName: ""
      })
      this.inputAutoComplete?.nativeElement.focus();
    }
    else {
      this.caseTagForm.patchValue({
        frmTagGroupName: ""
      })
      this.inputAutoCompleteGroup?.nativeElement.focus();
    }
  }

  // UP/DOWN Arrow
  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  getCasetypeData() {
    return this.CasetypeDataList;
  }

  getCaseStatusData() {
    return this.caseStatusDataList;
  }

  getAccountName(account, event?) {
    if (event && event.source.selected) {
      this.CasetypeList = []
      let casetypeList = this.getCasetypeData();
      if ((account.accountname && account.accountname.toString().toLowerCase().trim() != 'all')
        || (account.accountid && account.accountid != -1)) {
        this.CasetypeList = casetypeList.filter(r => ((r.casetype.toString().toLowerCase().trim() == 'all') || (r.accountid && r.accountid.toString() == account.accountid.toString())))
      } else {
        this.CasetypeList = casetypeList && casetypeList.length > 0 ? [...new Map(casetypeList.map(item => [item["casetype"], item])).values()] : [];
      }
      this.CasetypeList.sort((a, b) => a.casetype < b.casetype ? -1 : a.casetype > b.casetype ? 1 : 0);
      this.selectedAccountid = account.accountid;
      this.caseTagForm.patchValue({
        frmAccountId: account.accountid,
        frmCasetype: ''
      })
      this.selectedCasetypeList = [];
    }
  }

  getGroupName(event, group) {
    if (event.source.selected) {
      this.caseTagForm.patchValue({
        frmTagGroupName: group.groupname,
        frmGroupId: group.groupid
      })
      this.groupId = group.groupid
      let obj = this.templateData.submenuData.find(va => (va && (this.groupId == va.organizationtagid)))
      if (obj && obj.accountid) {
        this.caseTagForm.patchValue({
          frmAccountId: obj.accountid,
          frmAccountName: obj.accountname
        });
      }
      if (obj && obj.casetype) {
        let tem = obj.casetype.split(',')
        let tempArr = []
        for (let i = 0; i < tem.length; i++) {
          tempArr.push({ casetype: tem[i] })
        }
        this.selectedCasetypeList = [...tempArr]
        this.caseTagForm.patchValue({
          frmCasetype: this.selectedCasetypeList
        });
      }
      if (obj && obj.casestatus) {
        let tem = obj.casestatus.split(',')
        let tempArr = []
        for (let i = 0; i < tem.length; i++) {
          tempArr.push({ item: tem[i].toString().trim()  })
        }
        this.selectedCasestatusList = [...tempArr]
        this.caseTagForm.patchValue({
          frmCaseStatus: this.selectedCasestatusList
        });
      }
      if (obj && obj.noteditablecasestatus) {
        let tem = obj.noteditablecasestatus.split(',')
        let tempArr = []
        for (let i = 0; i < tem.length; i++) {
          tempArr.push({ item: tem[i].toString().trim() })
        }
        this.selectedNCasestatusList = [...tempArr]
        this.caseTagForm.patchValue({
          frmNonEditableCaseStatus: this.selectedNCasestatusList
        });
      }
    }
  }

  filterAccountName(value) {
    if (!value || value == '') {
      return this.AccountsList
    }
    let accounts = this.AccountsList
    return accounts.filter(va => (va && (va.accountname && va.accountname.toLowerCase().includes(value.toLowerCase())) || (va.accountid && va.accountid.toString().includes(value.toLowerCase()))));
  }

  filterGroupName(value) {
    if (!value || value == '') {
      return this.GroupList
    }
    let group = this.GroupList
    return group.filter(va => (va && (va.groupname && va.groupname.toString().toLowerCase().includes(value.toLowerCase())) || (va.groupid && va.groupid.toString().includes(value.toLowerCase()))));
  }

  getUniqueCasetype(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i]]) continue;
      flags[array[i]] = true;
      output.push(array[i]);
    }
    return output;
  }

  saveCloseCasetag(action, data) {
    let val = action ? 1 : 0;
    let temp = [];
    let NonEditableCaseStatus = [];
    let CaseStatus = [];
    let CaseType = [];
    let Roles = [];

    if (this.validateFormData(action, data)) {
      return
    } else {
      if (((data.frmNonEditableCaseStatus) && (data.frmNonEditableCaseStatus.length > 0))
        && ((data.frmCaseStatus) && (data.frmCaseStatus.length > 0))) {
        let matchedCaseStatus = [], tempMatched = [];
        matchedCaseStatus = this.matchingCaseStatus(data);
        matchedCaseStatus.forEach(ele => tempMatched.push(ele.item))
        if (matchedCaseStatus.length > 0) {
          let obj = { header: "", message: "CaseStatus(s): " + tempMatched.toString() + " is(are) not common. Not Editable Case status field values must be available in Case Status field!", alert: "", continue: "OK", cancel: "dontshow" }
          let dialogRef = this.dialog.open(ConfirmComponent, {
            disableClose: true,
            width: '500px',
            data: obj
          });
          return dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              return;
            }
          },
            error => {
              console.log(error);
            });
        }
      }
      let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');

      if (data.frmRoles && data.frmRoles.toString().toLowerCase() == 'all') {
        Roles.push('ALL');
      }
      else if (data.frmRoles && data.frmRoles.length > 0) {
        for (let i = 0; i < data.frmRoles.length; i++) {
          Roles.push(data.frmRoles[i].displayname)
        }
      }
      if (data.frmCaseStatus && data.frmCaseStatus.toString().toLowerCase() == 'all') {
        CaseStatus.push('ALL');
      }
      else if (data.frmCaseStatus && data.frmCaseStatus.length > 0) {
        for (let i = 0; i < data.frmCaseStatus.length; i++) {
          CaseStatus.push(data.frmCaseStatus[i].item)
        }
      }
      if (data.frmCasetype && data.frmCasetype.toString().toLowerCase() == 'all') {
        CaseType.push('ALL');
      }
      else if (data.frmCasetype && data.frmCasetype.length > 0) {
        for (let i = 0; i < data.frmCasetype.length; i++) {
          CaseType.push(data.frmCasetype[i].casetype)
        }
      }
      if (data.frmNonEditableCaseStatus && data.frmNonEditableCaseStatus.toString().toLowerCase() == 'all') {
        NonEditableCaseStatus.push('ALL');
      }
      else if (data.frmNonEditableCaseStatus && data.frmNonEditableCaseStatus.length > 0) {
        for (let i = 0; i < data.frmNonEditableCaseStatus.length; i++) {
          NonEditableCaseStatus.push(data.frmNonEditableCaseStatus[i].item)
        }
      }
      let isgroupVal = (data.frmTagType && data.frmTagType.toString().toLowerCase() == 'tag') ? null : 1
      if (data.frmTagValueGroup) {
        if (data.frmTagValueGroup.length > 0) {
          let ids = [], tagvaluesequenceorders = [];
          data.frmTagValueGroup.forEach(va => {
            if (va.frmTagValueId) {
              ids.push(va.frmTagValueId)
            }
            if (va.frmTagValueSequence) { tagvaluesequenceorders.push(va.frmTagValueSequence) }
          })

          for (let i = 0; i < data.frmTagValueGroup.length; i++) {
            temp.push({
              "slno": i + 1
              , "casetype": (CaseType && CaseType.length > 0) ? CaseType.toString() : 'ALL'
              , "accountid": ((!data.frmAccountName) || (data.frmAccountName && data.frmAccountName.toString().toLowerCase() == 'all')) ? -1 : this.selectedAccountid
              , "organizationid": this.destOrgid
              , "organizationtagid": data.frmTagId ? data.frmTagId : null
              , "casestatus": (CaseStatus && CaseStatus.length > 0) ? CaseStatus.toString() : "ALL"
              , "groupcolor": ((!data.frmGroupColor) || (data.frmGroupColor && !data.frmGroupColor.toString().trim())) ? null : data.frmGroupColor.toString().trim()
              , "tagname": ((!data.frmTagName) || (data.frmTagName && !data.frmTagName.toString().trim())) ? null : data.frmTagName.toString().trim()
              , "groupid": data.frmGroupId ? data.frmGroupId : null
              , "isactive": data.frmIsActive ? 1 : 0
              , "isgroup": isgroupVal
              , "sequenceorder": ((!data.frmTagSequence) || (data.frmTagSequence && !data.frmTagSequence.toString().trim())) ? null : data.frmTagSequence
              , "fontcolor": ((!data.frmFontColor) || (data.frmFontColor && !data.frmFontColor.toString().trim())) ? null : data.frmFontColor.toString().trim()
              , "allowbulkedit": data.frmAllowBulkEdit ? 1 : null
              , "noteditablecasestatus": (NonEditableCaseStatus && NonEditableCaseStatus.length > 0) ? NonEditableCaseStatus.toString() : ""
              , "groupname": (isgroupVal == 1) ? data.frmTagName.toString().trim() : ((!data.frmTagGroupName) || (data.frmTagGroupName && !data.frmTagGroupName.toString().trim())) ? null : data.frmTagGroupName.toString().trim()
              , "organizationcasetagvalueid": data.frmTagValueGroup[i].frmTagValueId ? data.frmTagValueGroup[i].frmTagValueId : null
              , "tagvalue": ((!data.frmTagValueGroup[i].frmTagValue) || (data.frmTagValueGroup[i].frmTagValue && !data.frmTagValueGroup[i].frmTagValue.toString().trim())) ? null : data.frmTagValueGroup[i].frmTagValue.toString().trim()
              , "isdefault": data.frmTagValueGroup[i].frmTagValueIsDefault ? 1 : null
              , "tagvaluesequenceorder": ((!data.frmTagValueGroup[i].frmTagValueSequence) || (data.frmTagValueGroup[i].frmTagValueSequence && !data.frmTagValueGroup[i].frmTagValueSequence.toString().trim())) ? null : data.frmTagValueGroup[i].frmTagValueSequence.toString().trim()
              , "color": ((!data.frmTagValueGroup[i].frmTagValueColor) || (data.frmTagValueGroup[i].frmTagValueColor && !data.frmTagValueGroup[i].frmTagValueColor.toString().trim())) ? null : data.frmTagValueGroup[i].frmTagValueColor.toString().trim()
              , "tagvaluefontcolor": ((!data.frmTagValueGroup[i].frmTagValueFontColor) || (data.frmTagValueGroup[i].frmTagValueFontColor && !data.frmTagValueGroup[i].frmTagValueFontColor.toString().trim())) ? null : data.frmTagValueGroup[i].frmTagValueFontColor.toString().trim()
              , "createdby": userid
              , "roles": (Roles && Roles.length > 0) ? Roles.toString() : "ALL"
              , "organizationcasetagvalueids": ids.toString()
              , "copyflag": this.copyDataClicked ? 1 : 0
              , "tagvaluesequenceorders": tagvaluesequenceorders.toString()
            })
          }
        }
      }
      else {
        temp.push({
          "slno": 1
          , "casetype": (CaseType && CaseType.length > 0) ? CaseType.toString() : 'ALL'
          , "accountid": data.frmAccountId ? data.frmAccountId : -1 //((!data.frmAccountName) || (data.frmAccountName && data.frmAccountName.toString().toLowerCase() == 'all')) ? -1 : this.selectedAccountid
          , "organizationid": this.destOrgid
          , "organizationtagid": data.frmTagId ? data.frmTagId : null
          , "casestatus": (CaseStatus && CaseStatus.length > 0) ? CaseStatus.toString() : "ALL"
          , "groupcolor": ((!data.frmGroupColor) || (data.frmGroupColor && !data.frmGroupColor.toString().trim())) ? null : data.frmGroupColor.toString().trim()
          , "tagname": ((!data.frmTagName) || (data.frmTagName && !data.frmTagName.toString().trim())) ? null : data.frmTagName.toString().trim()
          , "groupid": data.frmGroupId ? data.frmGroupId : null //this.groupId
          , "isactive": data.frmIsActive ? 1 : 0
          , "isgroup": isgroupVal
          , "sequenceorder": ((!data.frmTagSequence) || (data.frmTagSequence && !data.frmTagSequence.toString().trim())) ? null : data.frmTagSequence
          , "fontcolor": ((!data.frmFontColor) || (data.frmFontColor && !data.frmFontColor.toString().trim())) ? null : data.frmFontColor.toString().trim()
          , "allowbulkedit": data.frmAllowBulkEdit ? 1 : null
          , "noteditablecasestatus": (NonEditableCaseStatus && NonEditableCaseStatus.length > 0) ? NonEditableCaseStatus.toString() : ""
          , "groupname": (isgroupVal == 1) ? data.frmTagName.toString().trim() : ((!data.frmTagGroupName) || (data.frmTagGroupName && !data.frmTagGroupName.toString().trim())) ? null : data.frmTagGroupName.toString().trim()
          , "organizationcasetagvalueid": data.frmTagValueId ? data.frmTagValueId : null
          , "tagvalue": null
          , "isdefault": null
          , "tagvaluesequenceorder": null
          , "color": null
          , "tagvaluefontcolor": null
          , "createdby": userid
          , "roles": ((Roles && Roles.length > 0) ? Roles.toString() : this.editEnabled ? "" : "ALL")
          , "copyflag": this.copyDataClicked ? 1 : 0
        })
      }
      this.excelDataArray = [];
      this.excelDataArray = temp;
      if (this.excelDataArray.length > 0) {
        let tagvalueDuplicateCount = 0, groupDuplicateRec = false, tagDuplicateRec = false
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i].isgroup != 1 && this.templateData.submenuData.find(d =>
            d.organizationtagid == this.excelDataArray[i].organizationtagid
            && d.tagvalue && this.excelDataArray[i].tagvalue && d.tagvalue.toString().toLowerCase() == this.excelDataArray[i].tagvalue.toString().toLowerCase())) {
            tagvalueDuplicateCount += 1
          }
        }
        if (tagvalueDuplicateCount > 1) {
          this._snackbar.open("Multiple Tagvalues with same name are present", "Close")
          return
        }
        if (this.editEnabled) {
          let index = -1
          index = this.templateData.submenuData.findIndex(d =>
            d.organizationtagid != this.excelDataArray[0].organizationtagid
            && d.tagname && this.excelDataArray[0].tagname && d.tagname.toString().toLowerCase().trim() == this.excelDataArray[0].tagname.toString().toLowerCase().trim()
            && d.isgroup == true
            && d.isactive == ((this.excelDataArray[0].isactive == 1) ? true : false)
            && ((d.accountid == this.excelDataArray[0].accountid) || (d.accountid == -1) || (this.excelDataArray[0].accountid == -1)))
          if (this.excelDataArray[0].isgroup == 1 && index != -1) {
            groupDuplicateRec = true
          }
          else if (this.excelDataArray[0].isgroup != 1 && this.templateData.submenuData.find(d =>
            d.organizationtagid != this.excelDataArray[0].organizationtagid
            && d.tagname && this.excelDataArray[0].tagname && d.tagname.toString().toLowerCase().trim() == this.excelDataArray[0].tagname.toString().toLowerCase().trim()
            && d.isactive == ((this.excelDataArray[0].isactive == 1) ? true : false)
            && d.groupid == this.excelDataArray[0].groupid
            && ((d.accountid == this.excelDataArray[0].accountid) || (d.accountid == -1) || (this.excelDataArray[0].accountid == -1)))) {
            tagDuplicateRec = true
          }

          if (groupDuplicateRec) {
            let obj = {
              header: "", message: "Group Already Exists! ",
              alert: "", continue: "OK", cancel: "dontshow"
            }
            let dialogRef = this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '500px',
              data: obj
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) {
                return;
              }
            },
              error => {
                console.log(error);
              });
          }

          else if (tagDuplicateRec) {
            let dialogRef = this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '560px',
              data: { header: "", message: "Record Already Exists! Clicking on Yes will lead to duplicate record. Do you wish to continue?", alert: "", continue: "Yes", cancel: "No" }
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) {
                this.updateOrganizationTags(this.excelDataArray);
              }
              else {
                return
              }
            },
              error => {
                console.log(error);
              });
          }
          else {
            this.updateOrganizationTags(this.excelDataArray);
          }
        } else {
          if (this.excelDataArray[0]['isgroup'] == 1 && this.templateData.submenuData &&
            (this.templateData.submenuData.length > 0) && this.templateData.submenuData.find(d =>
              d.tagname && d.tagname.toString().toLowerCase().trim() == this.excelDataArray[0].tagname.toString().toLowerCase().trim()
              && d.isgroup == true
              && d.isactive == ((this.excelDataArray[0].isactive == 1) ? true : false)
              // && d.groupid == this.excelDataArray[0].groupid
              && ((d.accountid == this.excelDataArray[0].accountid) || (d.accountid == -1) || (this.excelDataArray[0].accountid == -1))
            )) {
            groupDuplicateRec = true
          }

          else if (this.excelDataArray[0]['isgroup'] != 1 && this.templateData.submenuData
            && (this.templateData.submenuData.length > 0) && this.templateData.submenuData.find(d =>
              d.tagname && d.tagname.toString().toLowerCase().trim() == this.excelDataArray[0].tagname.toString().toLowerCase().trim()
              // && d.isgroup == this.excelDataArray[0].isgroup
              && d.isactive == ((this.excelDataArray[0].isactive == 1) ? true : false)
              && d.groupid == this.excelDataArray[0].groupid
              && ((d.accountid == this.excelDataArray[0].accountid) || (d.accountid == -1) || (this.excelDataArray[0].accountid == -1)))) {
            tagDuplicateRec = true
          }

          if (groupDuplicateRec) {
            let obj = {
              header: "", message: "Group Already Exists! ",
              alert: "", continue: "OK", cancel: "dontshow"
            }
            let dialogRef = this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '500px',
              data: obj
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) {
                return;
              }
            },
              error => {
                console.log(error);
              });
          }
          else if (tagDuplicateRec) {
            let dialogRef = this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '560px',
              data: { header: "Record Already Exists!", message: "Clicking on Yes will create duplicate record. Do you wish to continue?", alert: "", continue: "Yes", cancel: "No" }
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) {
                this.addOrganizationTagsMethods(this.excelDataArray, this.bulkUpload, val);
              }
              else {
                return
              }
            },
              error => {
                console.log(error);
              });
          }
          else {
            this.addOrganizationTagsMethods(this.excelDataArray, this.bulkUpload, val);
          }
        }
      }
    }
  }

  matchingCaseStatus(data) {
    let filteredData = _.cloneDeep(data.frmNonEditableCaseStatus)
    data.frmCaseStatus.forEach(va => {
      if (filteredData.some(val => val.item.toString().toLowerCase().trim() != 'all' && val.item == va.item) && va.item.toString().toLowerCase().trim() != 'all') {
        let index = filteredData.findIndex(val => val.item == va.item)
        filteredData.splice(index, 1)
      }
    })
    if(data.frmCaseStatus.some(va=> va.item.toString().toLowerCase().trim() == 'all')){
      filteredData = []
    }
    return filteredData.filter(va=> va.item.toString().toLowerCase().trim() != 'all');
  }

  async updateOrganizationTags(dataArray) {
    this.ngxService.start();
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    for (let i = 0; i < dataArray.length; i++) {
      dataArray[i]['createdby'] = userid;
      dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
    }
    let dataArray1 = dataArray.filter(a => !a.tagvaluesequenceorder)
    dataArray = dataArray.filter(a => a.tagvaluesequenceorder)
    dataArray = [...dataArray, ...dataArray1]

    let res = await this.VitalHttpServices.updateOrganizationTags(dataArray, this.destDeployment).toPromise();
    this.ngxService.stop();
    if (res) {
      this._snackbar.open("Update successfull", "Success");
      this.editEnabled = false;
      this.addEditClicked = false;
      this.gridShow = true;
      this.refreshGrid(true);
      this.auditDetails(dataArray[0], res, 'updated');
    }
    else {
      this._snackbar.open("Update failed", "Failure");
      return
    }
  }

  async openEdit(grid, event) {
    this.ngxService.start();
    this.editEnabled = true;
    this.resetForm();
    this.removeFormControl(0)
    this.selectedrowData = null
    await this.getLookupValues();
    this.gridShow = false;
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.selectedrowData = this.templateData.submenuData.find(e => e.organizationtagid == selectedrowData.organizationtagid)
    }
    let temCaseType = this.selectedrowData.casetype ? this.selectedrowData.casetype.toString().split(',') : "";
    let temCaseStatus = this.selectedrowData.casestatus ? this.selectedrowData.casestatus.toString().split(',') : "";
    let temNonEditableCaseStatus = this.selectedrowData.noteditablecasestatus ? this.selectedrowData.noteditablecasestatus.toString().split(',') : "";
    let tempRole = (this.selectedrowData.roles) ? this.getUniqueCasetype(this.selectedrowData.roles.split(',')) : []
    let casetypeData = [], caseStatusData = [], nonEditableCaseStatusData = [], roleData = []
    this.ngxService.stop();

    if (temCaseType) {
      if (temCaseType.length > 0) {
        let data = this.getUniqueCasetype(temCaseType)
        data.forEach(element => {
          if (element) { casetypeData.push({ casetype: element }) }
        });
      } else {
        casetypeData = [];
      }
    }
    if (temCaseStatus) {
      if (temCaseStatus.length > 0) {
        let data = this.getUniqueCasetype(temCaseStatus)
        data.forEach(element => {
          if (element) { caseStatusData.push({ item: element.toString().trim() }) }
        });
      }
      else {
        caseStatusData = [];
      }
    }
    if (temNonEditableCaseStatus) {
      if (temNonEditableCaseStatus.length > 0) {
        let data = this.getUniqueCasetype(temNonEditableCaseStatus)
        data.forEach(element => {
          if (element) { nonEditableCaseStatusData.push({ item: element.toString().trim() }) }
        });
      } else {
        nonEditableCaseStatusData = [];
      }
    }
    if (tempRole) {
      if (tempRole.length > 0) {
        let data = this.getUniqueCasetype(tempRole)
        data.forEach(element => {
          if (element) { roleData.push({ displayname: element }) }
        });
      } else {
        roleData = [];
      }
    }

    this.caseTagForm.patchValue({
      frmTagType: this.selectedrowData.isgroup ? "Group" : "Tag",
      frmTagGroupName: this.selectedrowData.groupname ? this.selectedrowData.groupname : "",
      frmTagName: this.selectedrowData.tagname ? this.selectedrowData.tagname : "",
      frmGroupColor: this.selectedrowData.groupcolor ? this.selectedrowData.groupcolor : "",
      frmFontColor: this.selectedrowData.fontcolor ? this.selectedrowData.fontcolor : "",
      frmTagSequence: this.selectedrowData.sequenceorder ? this.selectedrowData.sequenceorder : "",
      frmIsActive: this.selectedrowData.isactive ? true : false,
      frmAllowBulkEdit: (this.selectedrowData.allowbulkedit || this.selectedrowData.allowbulkedit == 1) ? true : false,
      frmAccountName: this.selectedrowData.accountname ? this.selectedrowData.accountname : "",
      frmCasetype: casetypeData ? casetypeData : [],
      frmCaseStatus: caseStatusData ? caseStatusData : [],
      frmNonEditableCaseStatus: nonEditableCaseStatusData ? nonEditableCaseStatusData : [],
      frmRoles: roleData ? (((this.RoleList.length - 1) == roleData.length) ? [{ displayname: "ALL" }] : roleData) : [],
      frmGroupId: (this.selectedrowData.groupid) ? this.selectedrowData.groupid : null,
      frmTagId: (this.selectedrowData.organizationtagid) ? this.selectedrowData.organizationtagid : null,
      frmAccountId: (this.selectedrowData.accountid) ? this.selectedrowData.accountid : -1
    });

    this.CasetypeList = []
    let casetypeList = this.getCasetypeData();
    if ((this.selectedrowData.accountid && this.selectedrowData.accountid != -1)) {
      this.CasetypeList = casetypeList.filter(r => ((r.casetype && r.casetype.toString().toLowerCase().trim() == 'all') || (r.accountid && r.accountid.toString() == this.selectedrowData.accountid.toString())))
    } else {
      this.CasetypeList = casetypeList && casetypeList.length > 0 ? [...new Map(casetypeList.map(item => [item["casetype"], item])).values()] : [];
    }
    this.CasetypeList.sort((a, b) => a.casetype < b.casetype ? -1 : a.casetype > b.casetype ? 1 : 0);
    this.selectedAccountid = this.selectedrowData.accountid;
    this.selectedRoleList = roleData ? (((this.RoleList.length - 1) == roleData.length) ? [{ displayname: "ALL" }] : roleData) : []
    this.selectedCasetypeList = [...casetypeData]
    this.selectedCasestatusList = [...caseStatusData]
    this.selectedNCasestatusList = [...nonEditableCaseStatusData]
    // for(let i=0;i<this.selectedCasestatusList.length;i++)
    // {
    //   this.CaseNStatusList=this.CaseNStatusList.filter(va => va.item != this.selectedCasestatusList[i].item);
    // }

    // for(let i=0;i<this.selectedNCasestatusList.length;i++)
    // {
    //   this.CaseStatusList=this.CaseStatusList.filter(va => va.item != this.selectedNCasestatusList[i].item);
    // }

    let tagValueArray = [];
    if (this.selectedrowData.organizationcasetagvalueid) {
      let tagValueid = this.selectedrowData.organizationcasetagvalueid ? this.selectedrowData.organizationcasetagvalueid.toString().split(',') : []
      let tagValue = this.selectedrowData.tagvalue ? this.selectedrowData.tagvalue.toString().split(',') : []
      let tagvaluesequence = this.selectedrowData.tagvaluesequence ? this.selectedrowData.tagvaluesequence.toString().split(',') : []
      let color = this.selectedrowData.color ? this.selectedrowData.color.toString().split(',') : []
      let isdefault = this.selectedrowData.isdefault ? this.selectedrowData.isdefault.toString().split(',') : []
      let tagvaluefontcolor = this.selectedrowData.tagvaluefontcolor ? this.selectedrowData.tagvaluefontcolor.toString().split(',') : []
      for (let i = 0; i < tagValueid.length; i++) {
        tagValueArray.push({
          tagvalue: ""
          , organizationcasetagvalueid: tagValueid[i]
          , tagvaluesequence: ""
          , color: ""
          , tagvaluefontcolor: ""
          , isdefault: false
        })
      }
      for (let i = 0; i < tagValue.length; i++) {
        tagValueArray[i]['tagvalue'] = tagValue[i]
      }
      for (let i = 0; i < tagvaluesequence.length; i++) {
        tagValueArray[i]['tagvaluesequence'] = tagvaluesequence[i]
      }
      for (let i = 0; i < color.length; i++) {
        tagValueArray[i]['color'] = color[i]
      }
      for (let i = 0; i < isdefault.length; i++) {
        tagValueArray[i]['isdefault'] = isdefault[i]
      }
      for (let i = 0; i < tagvaluefontcolor.length; i++) {
        tagValueArray[i]['tagvaluefontcolor'] = tagvaluefontcolor[i]
      }
    }
    if (tagValueArray.length > 0) {
      for (let i = 0; i < tagValueArray.length; i++) {
        let tempArray = this.caseTagForm.controls.frmTagValueGroup as FormArray;
        let arraylen = tempArray.length;
        let newUsergroup: FormGroup = this._fb.group({
          frmTagValue: tagValueArray[i]['tagvalue'] ? tagValueArray[i]['tagvalue'] : "",
          frmTagValueSequence: tagValueArray[i]['tagvaluesequence'] ? tagValueArray[i]['tagvaluesequence'] : "",
          frmTagValueColor: tagValueArray[i]['color'] ? tagValueArray[i]['color'] : "",
          frmTagValueFontColor: tagValueArray[i]['tagvaluefontcolor'] ? tagValueArray[i]['tagvaluefontcolor'] : "",
          frmTagValueIsDefault: (!tagValueArray[i]['isdefault'] || tagValueArray[i]['isdefault'] == 0) ? false : true,
          frmTagValueId: tagValueArray[i]['organizationcasetagvalueid']
        })
        tempArray.insert(arraylen, newUsergroup);
      }
    }
    else {
      this.addFormControl();
    }
    this.addEditClicked = true;
  }

  validateFormData(action, data) {
    if ((!data.frmAccountName) || (!data.frmTagType) || (!data.frmTagName) ||
      (!data.frmCasetype || !data.frmCaseStatus)) {
      this._snackbar.open("Please enter madatory fields to proceed further", "Close");
      return true
    }
    else if (((data.frmTagType && data.frmTagType.toString().toLowerCase() != 'group') && ((data.frmRoles) && !(data.frmRoles.length > 0)))) {
      this._snackbar.open("Please select Role to proceed further", "Close");
      return true
    }
    else if ((data.frmTagType && data.frmTagType.toString().toLowerCase() != 'group') && (!data.frmTagGroupName)) {
      this._snackbar.open("Please enter madatory fields to proceed further", "Close");
      return true
    }
    else if (((data.frmCasetype) && !(data.frmCasetype.length > 0))
      || ((data.frmCaseStatus) && !(data.frmCaseStatus.length > 0))) {
      this._snackbar.open("Please enter madatory fields to proceed further", "Close");
      return true
    }
    else if ((data.frmTagGroupName) && !(this.GroupList.some(e => e.groupname && e.groupname.toString().toLowerCase().trim() == data.frmTagGroupName.toString().toLowerCase().trim()))) {
      this._snackbar.open("Please select valid Group Name from dropdown", "Close");
      return true
    }
    else if ((data.frmTagValueSequence && !data.frmTagValueSequence.toString().match(/^[0-9]+$/g))
      || (data.frmTagSequence && !data.frmTagSequence.toString().match(/^[0-9]+$/g))) {
      this._snackbar.open('Sequence field accepts only numbers', "Close");
      return true
    }
    else if ((data.frmAccountName && data.frmAccountName.toString().toLowerCase() != "all") && ((data.frmAccountName) && (!this.AccountsList.some(e => e.accountname &&
      e.accountname.toString().toLowerCase().trim() == data.frmAccountName.toString().toLowerCase().trim())))) {
      this._snackbar.open("Please select valid Location Name from dropdown", "Close");
      return true
    } else if (data.frmTagValueGroup) {
      if ((data.frmTagValueGroup.length > 0) && (this.toFindDuplicates(data.frmTagValueGroup, 'tagval').length > 0)) {
        this._snackbar.open("Duplicate Tag Values are configured!", "Close");
        return true
      }
      else if ((data.frmTagValueGroup.length > 0) && (this.toFindDuplicates(data.frmTagValueGroup, 'tagvalseq').length > 0)) {
        this._snackbar.open("Duplicate Tag Value Sequences are configured!", "Close");
        return true
      }
      else if ((data.frmTagValueGroup.length > 0)) {
        let count = 0;
        for (let i = 0; i < data.frmTagValueGroup.length; i++) {
          if (data.frmTagValueGroup[i]['frmTagValueIsDefault'] && ((data.frmTagValueGroup[i]['frmTagValueIsDefault'] == 1) || (data.frmTagValueGroup[i]['frmTagValueIsDefault'].toString() == 'true'))) {
            count = count + 1;
          }
        }
        if (count > 1) {
          this._snackbar.open("Please check Default field! Only one Tag Value can be set as default!", "Close");
          return true
        } else {
          return false
        }
      }
      else {
        return false
      }
    } else {
      return false
    }
  }

  toFindDuplicates(arr, key) {
    if (key == 'tagval') {
      const lookup = arr.reduce((a, e) => {
        a[e.frmTagValue] = ++a[e.frmTagValue] || 0;
        return a;
      }, {});
      return arr.filter((e) => lookup[e.frmTagValue]);
    } else if (key == 'tagvalseq') {
      const lookup = arr.reduce((a, e) => {
        a[e.frmTagValueSequence] = ++a[e.frmTagValueSequence] || 0;
        return a;
      }, {});
      return arr.filter((e) => e.frmTagValueSequence && lookup[e.frmTagValueSequence]);
    }
  }

  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }

  approveCopyOrgTags(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/will be added/i) && this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      for (let i = 0; i < this.excelDataArray.length; i++) {
        for (let [key, value] of Object.entries(this.excelDataArray[i])) {
          if (value == "null" || value == "" || value == null) {
            this.excelDataArray[i][key] = null;
          }
          else {
            this.excelDataArray[i][key] = value.toString();
          }
        }
      }

      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
          this._snackbar.open("All selected records already exist!", "Close");
          this.bulkUpload = true;
          return;
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      // dataArray = dataArray.filter(va => !va.notes.toString().toLowerCase().match(/already exists/i) || !va.notes.toString().toLowerCase().match(/wrong/i) || !va.notes.toString().toLowerCase().match(/Invalid/i))
      this.addOrganizationTagsMethods(dataArray, this.bulkUpload)
    }
  }

  async getDataToCopy() {
    this.checkStatus = false;
    if (typeof (this.copyOrgTagsMethodForm.value.frmOrgID) == 'object') {
      let frmorgid : any = this.copyOrgTagsMethodForm.value.frmOrgID
      this.copyOrgTagsMethodForm.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { orgid: this.copyOrgTagsMethodForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getorganizationtagscopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let userSpecificData = [];
    this.ngxService.start();
    let data = await this.VitalHttpServices.GetData(queryResult, this.copyOrgTagsMethodForm.value.frmDepKey).toPromise()
    this.ngxService.stop();
    if (!data.errors) {
      if (!this.usertoggle) {
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          this.backBtn = data.data.submenuData.length > 0 ? false : true;
          this.postUpload = false;
          if (this.srcRole && this.srcRole.toString().toLowerCase() != 'all') {
            for (let i = 0; i < data.data.submenuData.length; i++) {
              if ((data.data.submenuData[i].roles && data.data.submenuData[i].roles.toLowerCase() == this.srcRole.toLowerCase())
                || (!data.data.submenuData[i].groupid)) {
                userSpecificData.push(data.data.submenuData[i]);
              }
            }
            this.manageDataColumn(userSpecificData);
          }
          else {
            for (let i = 0; i < data.data.submenuData.length; i++) {
              data.data.submenuData[i]['roles'] = 'ALL';
            }
            data.data.submenuData = _.uniqWith(data.data.submenuData, _.isEqual);
            this.manageDataColumn(data.data.submenuData);
          }
        }
        else {
          this.backBtn = true
          this.ExcelGridData([]);
        }
      } else {
        this._snackbar.open("Please enter valid user", "Close");
        this.ngxService.stop();
        return
      }
    }
    this.ngxService.stop();
  }

  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
  }

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = '';
    msgActivityTracker = (info == 'updated') ? 'Updated' : 'Added';

    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj['organizationtagid'], this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    } else {
      /*New records*/
      this.ngxService.start();
      for (let i = 0; i < newObjArray.length; i++) {
        this.commonService.createActivityTracker(info, newObjArray[i].ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[i], {});
      }
      this.ngxService.stop();
    }
  }

  setColorValue(colorValue, type) {
    switch (type) {
      case 'frmFontColor':
        this.caseTagForm.patchValue({
          frmFontColor: colorValue
        });
        break;
      case 'frmGroupColor':
        this.caseTagForm.patchValue({
          frmGroupColor: colorValue
        });
        break;
    }
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
