<!-- <div class="row">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Report Builder</strong>
            </h3>
        </div>
        <div *ngIf="errMessage && errMessage.length > 0" class="col-sm-12 alignText mt-4">
            <div class="col-sm-12">
              <span>{{errMessage}}</span>
            </div>
          </div>
        <iframe class="custom-frame" id="frame-reportbuilder" name="frame-reportbuilder" width="100%" height="700px" frameBorder="0"></iframe>
    </div>    
</div> -->



<!-- <div class="modal-content">
    <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">{{organizationName}} Report Builder</h3>
        <span type="button" data-dismiss="modal" (click)="close()" class="cursor mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24"
                title="Close">
        </span>
    </div>
    <div *ngIf="errMessage && errMessage.length > 0" class="col-sm-12 alignText mt-4">
        <div class="col-sm-12">
          <span>{{errMessage}}</span>
        </div>
      </div>
    <iframe class="custom-frame" id="frame-reportbuilder" name="frame-reportbuilder" width="100%" height="700px" frameBorder="0"></iframe>
</div>     -->

<div class="align-center mt-4">
  <button  mat-raised-button class="admin-btn-success mr-4" (click)="Popup()" [disabled]="hideReportBuilderBtn">
    Launch Report Builder
  </button>
  </div>
   


