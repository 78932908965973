<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned"
    *ngIf="!checkInfoAssociation() && !locationsList?.length && !isProvidersMenu && !noDataFoundFlag && fromLocation !='location'"
    [diameter]="50">
  </mat-spinner>
  <div class="p-3 parent_container" *ngIf="checkInfoAssociation() && !noDataFoundFlag">
    <div class="mb-3" *ngIf="locationsList?.length>0"> <span class="label_border _600">Location(s)</span><mat-accordion>
        <mat-expansion-panel *ngFor="let item of locationsList" class="mb-2"> <mat-expansion-panel-header>
            <mat-panel-title> {{item.OrganizationName}} <span class="text-muted ml-2"> (Locations:
                {{locationsList.length}})</span> </mat-panel-title> </mat-expansion-panel-header>
          <p class="my-2">{{item.DisplayName}}</p>
        </mat-expansion-panel> </mat-accordion> </div>
    <div class="mb-3" *ngIf="facilityLocations?.length>0"> <span class="label_border _600">Facility Location(s)</span>
      <mat-accordion> <mat-expansion-panel *ngFor="let item of facilityLocations" class="mb-2">
          <mat-expansion-panel-header> <mat-panel-title> {{item.OrganizationName}} <span class="text-muted ml-2">
                (Facility Locations: {{item.sublocation.length}})</span> </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="my-2" *ngFor="let list of item.sublocation">{{list.displayname}}</p>
        </mat-expansion-panel> </mat-accordion>
    </div>
    <div class="mb-3" *ngIf="workGroupList?.length>0"> <span class="label_border  _600">Workgroup(s)</span>
      <div class="d-flex">
        <div class="label_button" *ngFor="let item of workGroupList">{{item.DepartmentName}}</div>
      </div>
    </div>
    <div class="mb-3" *ngIf="associationTabData?.length>0">
      <ng-container *ngFor="let item of associationTabData">
        <ng-container *ngIf="item.IsAssociated.toLowerCase() === 'true'">
          <span class="label_border _600">Pathologist List(s)</span>
          <div class="d-flex">
            <div class="label_button">{{item.FormattedDisplayName}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="mb-3" *ngIf="associationTabPhyData?.length > 0">
      <ng-container *ngFor="let item of associationTabPhyData">
        <ng-container *ngIf="item.IsAssociated.toLowerCase() === 'true'">
          <span class="label_border _600">Physician List(s)</span>
          <div class="d-flex">
            <div class="label_button">{{item.FormattedDisplayName}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </div> <span *ngIf="isProvidersMenu && surgiCenterDetails?.length>0">
    <span class=" _600 label_border">Surgicenter(s)</span>
    <div class=" col-sm-12 pl-0  px-0 d-flex">
      <div class="grid_view w-100">
        <div class="header_title px-2">
          <div class="row">
            <div class="col-sm-6 _600 dividers">Name (NPI)</div>
            <div class="col-sm-6 _600">Location (City)</div>
          </div>
        </div>
        <div class="grid-data-content">
          <div class="row" *ngFor="let item of surgiCenterDetails">
            <div class="col-sm-6 _600 p-2 pb-4">{{item.DispalyNameNPI}}</div>
            <div class="col-sm-6 _600 p-2 pb-4">{{item.LocationCity}}</div>
          </div>
        </div>
      </div>
    </div>
  </span>
  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag"> <span class="col-sm-12 nodata-wrapper">No Data
      Found</span> </div>
</div>
<div class="pl-3 pr-3" *ngIf="fromLocation =='location'">

  <!-- <div class="lab-list p-2">
<div *ngFor="let item of LabOrganizations">
  {{item}}
  <div *ngFor="let ele of labLocations">
    <div *ngIf="ele.OrganizationName==item">{{ele.DisplayName}}</div>
  </div>
</div>
</div> -->
  <div class="mb-3" *ngIf="LabOrganizations?.length>0">
    <h5 class="mt-2">
      <strong class="header-View">Laboratories</strong>
    </h5>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of LabOrganizations" class="mb-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{item.OrganizationName}} <span class="text-muted ml-2"> (Locations:
              {{item.sublocation.length}})</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="my-2 location-card" *ngFor="let list of item.sublocation">{{list.DisplayName}}</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <h5 class="mt-2" *ngIf="casetypeList?.length>0">
    <strong class="header-View">Case Types</strong>
  </h5>
  <div class="casetype-box row">
    <div *ngFor="let val of casetypeList" class="casetypes-display m-1" style="position: relative;"
      (mouseenter)="onMouseEnter(val)" (mouseleave)="onLeaveMouse()">

      <span class="caseTypeClass">{{val.Category}}({{caseTypeTitleArr?.[val['Category']]?.length}})</span>
      <div *ngIf="showToolTip && currentCategory === val['Category']" class="tooltip-show">
        <div *ngFor="let item of toolTipContent">{{ item }}</div>
      </div>
    </div>
  </div>
  <h5 class="mt-2" *ngIf="seUserList?.length>0">
    <strong class="header-View">Sales Executive</strong>
  </h5>
  <div class="se-Courier-parent">
    <div class="se-Courier-box" *ngFor="let ele of seUserList">{{ele.name}}</div>
  </div>
  <h5 class="mt-2" *ngIf="courierUserList?.length>0">
    <strong class="header-View">Courier</strong>
  </h5>
  <div class="se-Courier-parent">
    <div class="se-Courier-box" *ngFor="let ele of courierUserList">{{ele.name}}</div>
  </div>
  <h5 class="mt-2">
    <strong class="header-View"
      *ngIf="associatedSavedSurgicenterData?.length>0 || associatedSavedPhysicianData?.length>0 || associatedSavedOFData?.length>0">Surgicenter</strong>
  </h5>
  <div *ngIf="associatedSavedPhysicianData?.length>0">
    <span class=" _600 label_border">Phycision</span>
    <div class=" col-sm-12 m-0 p-0   d-flex">
      <div class=" w-100">
        <div class="header_title  grid_view">
          <div class="row col-sm-12 m-0 p-0">
            <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
            <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
          </div>
        </div>
        <div class="grid-data-content gridDetails-view">
          <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associatedSavedPhysicianData">
            <div class="col-sm-6  padding-surgigrid">{{item.PhysicianNPI}}</div>
            <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="associatedSavedSurgicenterData?.length>0">
    <span class=" _600 label_border">Ordering Facility</span>
    <div class=" col-sm-12 m-0 p-0   d-flex">
      <div class=" w-100">
        <div class="header_title  grid_view">
          <div class="row col-sm-12 m-0 p-0">
            <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
            <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
          </div>
        </div>
        <div class="grid-data-content gridDetails-view">
          <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associatedSavedSurgicenterData">
            <div class="col-sm-6 padding-surgigrid">{{item.DispalyNameNPI}}</div>
            <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="associatedSavedOFData?.length>0">
    <div class=" col-sm-12 m-0 p-0 d-flex">
      <div class="w-100">
        <div class="header_title px-2 grid_view">
          <div class="row col-sm-12 m-0 p-0">
            <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
            <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
          </div>
        </div>
        <div class="grid-data-content gridDetails-view">
          <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associatedSavedOFData">
            <div class="col-sm-6  padding-surgigrid">{{item.DispalyNameNPI}}</div>
            <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>