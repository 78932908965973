import { SelectionModel } from '@angular/cdk/collections';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.scss'],
  // providers:{}
})
export class MultiSelectCheckboxComponent implements OnInit {
  
@ViewChild('ref') ele:ElementRef
  @Input() type : string = 'text'
  @Input() labelValue : string = ''
  @Input() required : boolean = false
  @Input() formcontrolname : string | null = null
  @Input() maxLength : number = 50
  @Input() mask : string = ''
  @Input() prefix : string = ''
  @Input() placeholder : string = ''
  @Input() validateField : boolean = false
  @Input() InvalidMessage : string = ''
  @Input() containerClass : string = 'mb-3 me-3'
  @Input() selectedValue:string=''
  @Input() isFormValid:boolean|null=true
  @Input() isDisabled:boolean=false
  @Input() listData:string[]=[]
  @Output() onFocusOut = new EventEmitter()
  @Input()selectionModel:SelectionModel<string>=new SelectionModel<string>(true,[])
  isOpen:boolean=false;
  contentwidth=0
  toggle()
  {
    this.contentwidth= this.ele.nativeElement.clientWidth
  }
  ngOnInit(): void {
  //  this.listDataSelectionModel.select(...this.listData)

  //  this.selectionModelUnsubscribe=this.listDataSelectionModel.changed.subscribe(data=>{
  //     this.selectedValue=data.source.selected.length?this.selectedDisplay(data.source.selected):''
  //  })
  }
  focusOut(red)
  {
    this.onFocusOut.emit()
  }
  selectedDisplay(list:any)
  {
        if(list.length==1)
        return list[0]
        else if(list.length==2)
          return `${list[0]} +(1 other)`
        else if(list.length>2)
        return `${list[0]} +(${list.length-1}other)`
        else return ''
  }    
  isAllItemsSelected()
  {
    return this.listData.length==this.selectionModel.selected.length
  }
  toggleAllItems(){
    if(this.isAllItemsSelected())
    {
      this.selectionModel.clear()
      return 
    }
    this.selectionModel.select(...this.listData)

  }
  
  

}
