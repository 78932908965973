import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PdfServiceService {

  [x: string]: any;
  public baseUrl2 = environment.baseUrl2;
  pdfurl: string = `${this.baseUrl2}hiq/GetPDFDataOneCasetype`;
  laburl: string = `${this.baseUrl2}hiq/GetLabData`;
  pdfurl2: string = `${this.baseUrl2}hiq/GetPDFData`;
  constructor(private pdfservice: HttpClient) { }


  getPdfReport() {
    const headers = new HttpHeaders().append('responseType', 'blob');
    return this.pdfservice.get(this.pdfurl, { headers, responseType: 'blob' as 'json' });
  }

  getPdfReportdata(orgid: any, accid: any, casetype: any) {
    const headers = new HttpHeaders().append('responseType', 'blob');
    return this.pdfservice.get(`${this.pdfurl}?OrganizationID=${orgid}&Casetype=${casetype}&AccountID=${accid}`
      , { headers, responseType: 'blob' as 'json' });
  }


  getPdfReportdata2(obj: object) {
    const headers = new HttpHeaders().append('responseType', 'blob');
    return this.pdfservice.post(`${this.pdfurl2}`, obj
      , { headers, responseType: 'blob' as 'json' });
  }

  getLabdata(orgid: any, accid: any, casetype: any, type: any) {
    const headers = new HttpHeaders().append('responseType', 'blob');
    return this.pdfservice.get(`${this.laburl}?OrganizationID=${orgid}&Casetype=${casetype}&AccountID=${accid}&typeData=${type}`
      , { headers, responseType: 'blob' as 'json' });
  }


}
