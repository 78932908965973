<div class="surgicenter">
  <div class="surgicenter-action-center">
    <app-search-box (enteredValue)="searchInput($event)" class="searchbox"></app-search-box>
    <app-button buttonclass="secondary" class="return-btn" (click)="onReturn.emit()">Return</app-button>
    <app-button buttonclass="primary" class="add-btn" (click)="selectedData()" >Add</app-button>
  </div>
  <div cdkDropListGroup class="surgicenter-dd-full-container mt-2">
    
      <div class="surgicenter-dd-container">
        <div class="surgicenter-tbl-head surgicenter-table-grid">
          <div >
            Name (NPI)
          </div>
          <div>
          Location(City)
          </div>
        </div>
        <div
          cdkDropList
          [cdkDropListData]="intialFilterList"
          class="surgicenter-dd-main-container"
          (cdkDropListDropped)="drop($event)">
        <!-- <ng-container *ngIf="intialFilterList.length"> -->
          <div *ngFor="let item of intialFilterList;index as i" class=" surgicenter-table-grid surgicenter-tbl-items " (click)="[moveElemetOne.toggle(i),moveElemetTwo.clear()]"  
            [class.selected-item]="moveElemetOne.isSelected(i)"  cdkDrag >
          <div > {{item[key1]}}</div>
          <div > {{item[key2]}}</div>
          </div>
        <!-- </ng-container> -->
        <!-- <ng-container *ngIf="!intialFilterList.length"> -->
          <!-- <div>No Data Found</div>
        </ng-container> -->
        </div>
    
      </div>
      <div class="move-btn-container">
        <app-button [image]="'../../../../assets/images_vla/Double arrow white.svg'" buttonclass="primary" [isDisabled]="!moveElemetOne.selected.length" (click)="[moveElemetOne.selected.length?move(1):0,$event.preventDefault()]" ></app-button>
        <app-button [image]="'../../../../assets/images_vla/Double arrow white_reverse.svg'" buttonclass="primary" [isDisabled]="!moveElemetTwo.selected.length" (click)="[moveElemetTwo.selected.length?move(0):0,$event.preventDefault()]" ></app-button>
      </div>

      <div class="surgicenter-dd-container">
        <div class="surgicenter-tbl-head surgicenter-table-grid">
          <div >
            Name (NPI)
          </div>
          <div>
          Location(City)
          </div>
        </div>
        <div
          cdkDropList
          [cdkDropListData]="selectedFilterList"
          class="surgicenter-dd-main-container"
          (cdkDropListDropped)="drop($event)">
          <div class="surgicenter-table-grid surgicenter-tbl-items"  (click)="[moveElemetTwo.toggle(i),moveElemetOne.clear()]"  [class.selected-item]="moveElemetTwo.isSelected(i)" *ngFor="let item of selectedFilterList; index as i" cdkDrag>
            <div > {{item[key1]}}</div>
            <div > {{item[key2]}}</div>
          </div>
        </div>
      </div>
  </div>

</div>

