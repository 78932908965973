<div class="modal " id="myModal1">
    <div class="modal-dialog RevisionReasonDialog">
        <form [formGroup]="RevisionReasongroup">
            <div class="modal-content header-wraps">
                <!-- Modal Header -->
                <div class="modal-header admin-model-header header-freeze">
                    <h3 class="modal-title w-100 admin-model-header-txt">Revision Reason</h3>
                    <span type="button" data-dismiss="modal" (click)="RevisionClose()" class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                            class="material-icons md-24 cursor" title="Close">
                    </span>
                </div>
                <br />
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid popup-wrap">
                        <div class="row mb-1">
                            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
                                <div class="row m-0 p-0">
                                    <div class="col-md-4 p-0 m-0 text-right">
                                        <label for="DKeys"><strong>Select Deployment:&nbsp;&nbsp;</strong></label>
                                    </div>
                                    <div class="col-md-8 p-0 m-0">
                                        <select name="DeploymentKeys" id="DKeys2" class="form-control" required
                                            formControlName="DeploymentKey">
                                            <option value="none"
                                                *ngIf="!selecteddefaultdeploymentkey || selecteddefaultdeploymentkey === 'ALL'"
                                                disabled selected>--Select--</option>
                                            <option value="none"
                                                *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !== 'ALL'">
                                                {{ selecteddefaultdeploymentkey }}</option>
                                            <!-- <option *ngFor="let i of DeploymentKeys" value={{i}}>{{i}}</option> -->
                                            <ng-container *ngFor="let i of DeploymentKeys">
                                                <option [value]="i" *ngIf="i !== selecteddefaultdeploymentkey">{{ i }}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
                                <div class="row m-0 p-0">
                                    <div class="col-md-4 p-0 m-0 text-right">
                                        <label for="QSearch"><strong>Quick Search:&nbsp;&nbsp;</strong></label>
                                    </div>
                                    <div class="col-md-8 p-0 m-0">
                                        <select name="drpQSearch" id="drpQSearch" class="form-control w-100" required
                                            formControlName="QuickSearch">
                                            <option value="Accession">Accession #</option>
                                            <option value="Case ID">Case ID</option>
                                            <option value="Requisition Number">Requisition #</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
                                &nbsp;
                            </div>
                            <div class="col-lg-5 p-0 m-md-1 m-lg-0">
                                <div class="row m-0 p-0">
                                    <div class="col-md-4 p-0 m-0">
                                        <label for="QSearch"><strong>&nbsp;</strong></label>
                                    </div>
                                    <!-- <div class="col-md-8 p-0 m-0">
                                        <input
                                            [attr.disabled]="RevisionReasongroup.value.DeploymentKey == 'none' ? true : null"
                                            type="text" id="inpQSearch" class="form-control" name="inpQSearch"
                                            placeholder="Enter Search Text" required
                                            formControlName="RevisionSearchInpTxt">
                                    </div> -->
                                    <div class="col-md-8 p-0 m-0">
                                        <input
                                            [attr.disabled]="RevisionReasongroup.value.DeploymentKey == 'none' ? true : null"
                                            *ngIf="!selecteddefaultdeploymentkey || selecteddefaultdeploymentkey === 'ALL'"
                                            type="text" id="inpQSearch" class="form-control" name="inpQSearch"
                                            placeholder="Enter Search Text" required
                                            formControlName="RevisionSearchInpTxt">
                                        <input
                                            *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !== 'ALL'"
                                            type="text" id="inpQSearch" class="form-control" name="inpQSearch"
                                            placeholder="Enter Search Text" required
                                            formControlName="RevisionSearchInpTxt">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-5 d-lg-none m-0 p-0">&nbsp;</div>
                            <div class="col-md-6 col-lg-2 p-0 m-md-1 m-lg-0" style="display: table; width: 100%;">
                                <div style="display: table-cell; vertical-align: middle">
                                    <button mat-raised-button class="admin-btn-success d-block ml-4"
                                        [disabled]="RevisionReasongroup.invalid"
                                        (click)=search(RevisionReasongroup.value)>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    &nbsp;
                    &nbsp;
                    <div *ngIf="showGrid">
                        <div *ngIf="templateData  && gridarray.length > 0" class="d-block m-auto">
                            <wj-flex-grid [headersVisibility]="'Column'" [format]="'d'" [isReadOnly]="true"
                                (click)="onClick(grid, $event)" [itemsSource]="gridData" #grid>
                                <div *ngFor="let columnname of gridheader">
                                    <wj-flex-grid-column [binding]="columnname" [format]="'d'"
                                        [header]="columnname.split('_').join(' ')" [width]="'*'" [allowResizing]="true">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>

                            </wj-flex-grid>
                            <br />
                            <!-- <div [ngStyle]="{'padding-left.px': gridwidth/3}">
                            <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                [byPage]="true" [cv]="gridData"> </wj-collection-view-navigator>
                        </div> -->
                        </div>
                        <div *ngIf="gridarray.length == 0" class="nodata-wrap mt-4">
                            <div class="nodata-design"> No Data Found </div>
                        </div>
                    </div>

                    <div *ngIf="showTab" class="d-block m-auto" style="padding-left: 21px;">

                        <div class="row p-0 m-0 py-2 my-1" style="border: 1px solid grey;border-radius: 10px;width:91%">
                            <mat-tab-group style="width:100% !important;">
                                <mat-tab label="Correction" id="correction" class="label-width matLabel"
                                    style="border-right:1px solid #e5e5e5;width: 336px !important;">
                                    <div class="col-12 row p-1 py-2 m-0">

                                        <div class="col-md-12 p-1 m-0" *ngIf="correctn[0].Reason!= null">
                                            <div class="row m-0 my-1 py-1"
                                                *ngFor="let crctn of correctn; index as i;let last=last"
                                                [style.border-bottom]="last==1?'0px':'1px dotted grey'">

                                                <div class="col-sm-10 m-0 p-0 ">
                                                    {{crctn.Reason}}
                                                </div>
                                                <div class="col-sm-2 m-0 p-0  " style="display: table;">
                                                    <div style="display: table-cell; vertical-align: middle;">
                                                        <button class="md-fab md-btn  m-0 black d-block m-auto"
                                                            (click)='delete("Correction",crctn.Reason)'
                                                            title="Delete Reason" style="font-size: 10px;margin-right: 10px !important;padding: 0px;width: 39px !important;
                                                        height: 40px !important;">
                                                            <em class="fa fa-trash fa-2x" style="cursor: pointer;"
                                                                aria-hidden="true"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="correctn[0].Reason== null" class="nodata-wrap mt-4">
                                            <div class="nodata-design"> No Data Found
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Addendum" style="width: 433px !important;">
                                    <div class="col-12 row p-1 py-2 m-0">

                                        <div class="col-md-12 p-1 m-0" *ngIf="addndnRsn[0].Reason!= null">
                                            <div class="row m-0 my-1 py-1"
                                                *ngFor="let aadndn of addndnRsn; index as i;let last=last"
                                                [style.border-bottom]="last==1?'0px':'1px dotted grey'">

                                                <div class="col-sm-10 m-0 p-0 ">
                                                    {{aadndn.Reason}}
                                                </div>
                                                <div class="col-sm-2 m-0 p-0  " style="display: table;">
                                                    <div style="display: table-cell; vertical-align: middle;">
                                                        <button class="md-fab md-btn  m-0 black d-block m-auto"
                                                            (click)='delete("Addendum",aadndn.Reason)'
                                                            title="Delete Reason" style="font-size: 10px;margin-right: 10px !important;padding: 0px;width: 39px !important;
                                                        height: 40px !important;">
                                                            <em class="fa fa-trash fa-2x" style="cursor: pointer;"
                                                                aria-hidden="true"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="addndnRsn[0].Reason== null" class="nodata-wrap mt-4">
                                            <div class="nodata-design"> No Data Found
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Amendment" style=" width: 179px   !important;">
                                    <div class="col-12 row p-1 py-2 m-0">

                                        <div class="col-md-12 p-1 m-0" *ngIf="ammendRsn[0].Reason!= null">
                                            <div class="row m-0 my-1 py-1"
                                                *ngFor="let ammdn of ammendRsn; index as i;let last=last"
                                                [style.border-bottom]="last==1?'0px':'1px dotted grey'">

                                                <div class="col-sm-10 m-0 p-0 ">
                                                    {{ammdn.Reason}}
                                                </div>
                                                <div class="col-sm-2 m-0 p-0  " style="display: table;">
                                                    <div style="display: table-cell; vertical-align: middle;">
                                                        <button class="md-fab md-btn  m-0 black d-block m-auto"
                                                            (click)='delete("Amendment",ammdn.Reason)'
                                                            title="Delete Reason" style="font-size: 10px;margin-right: 10px !important;padding: 0px;width: 39px !important;
                                                        height: 40px !important;">
                                                            <em class="fa fa-trash fa-2x" style="cursor: pointer;"
                                                                aria-hidden="true"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="ammendRsn[0].Reason== null" class="nodata-wrap mt-4">
                                            <div class="nodata-design"> No Data Found
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>

                        </div>
                    </div>



                    <br />
                    <br />
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="ifModalShow">
    <div class="modal overlay" id="myModal" role="dialog" style="display: flex;background-color: #0c0c0c75;">
        <div class="modal-dialog" cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="margin: auto;
      touch-action: none;
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      user-select: none;
      max-width:30% !important;">
            <form [formGroup]="DeleteModalGrp" style="margin-top:80px !important;">
                <div class="modal-header admin-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Notes</h3>
                    <span type="button" data-dismiss="modal" (click)="close()" class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                            class="material-icons md-24 cursor" title="Close">
                    </span>
                </div>
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid popup-wrap">
                            <div class="row m-0 mb-1 p-0">

                                <div class="col-md-4 p-0 m-0">
                                    <label for="Approver"><strong>Approver&nbsp;</strong></label>
                                </div>
                                <div class="col-md-8 p-0 m-0 px-1">
                                    <input type="text" id="approvertxt" class="form-control txtwidth" name="approvertxt"
                                        required formControlName="ApproverInpTxt">
                                </div>

                            </div>
                            <div class="row m-0 mb-1 p-0">

                                <div class="col-md-4 p-0 m-0">
                                    <label for="Ticket"><strong>Ticket #&nbsp;</strong></label>
                                </div>
                                <div class="col-md-8 p-0 m-0 px-1">
                                    <input type="text" id="tickettxt" class="form-control txtwidth" name="tickettxt"
                                        required formControlName="TicketInpTxt">
                                </div>

                            </div>
                            <div class="row m-0 mb-1 p-0">

                                <div class="col-md-4 p-0 m-0">
                                    <label for="Notes"><strong>Notes&nbsp;</strong></label>
                                </div>
                                <div class="col-md-8 p-0 m-0 px-1">
                                    <input type="text" id="notestxt" class="form-control txtwidth" name="notestxt"
                                        required formControlName="NotesInpTxt">
                                </div>

                            </div>
                            <br>
                            <div class="row mb-1" style="    display: flex;
                                    justify-content: center;
                                    align-items: center;">
                                <div class="row mt-4">
                                    <div class="col-md-6 col-lg-2 p-0 m-md-1 m-lg-0"
                                        style="display: table; width: 100%;">
                                        <div style="display: table-cell; vertical-align: middle">
                                            <button mat-raised-button class="admin-btn-success d-block mr-4"
                                                [disabled]="DeleteModalGrp.invalid"
                                                (click)="Save(DeleteModalGrp.value)">OK</button>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-2 p-0 m-md-1 m-lg-0"
                                        style="display: table; width: 100%;">
                                        <div style="display: table-cell; vertical-align: middle">
                                            <button mat-raised-button class="admin-btn-success d-block" style="background-color: #c8c8c8;
                                                    color: white;" (click)="close()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </form>
        </div>
    </div>
</div>