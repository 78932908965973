<div class="modal-content">
    <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt"> VitalInsights </h3>
        <span type="button" data-dismiss="modal" (click)="closeModal()" class="cursor mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24"
                title="Close">
        </span>
    </div>
    <iframe class="custom-frame" [src]="url" width="100%" height="700px" frameBorder="0"></iframe>
</div>  
