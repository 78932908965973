<!-- <p>templates-listing works!</p> -->
<div class="col-sm-12 common-div d-flex" *ngIf="!dragDrop">
    <div *ngIf="!isGroupLevelTabs" class="col-sm-6 d-flex p-0">
        <button class="rounded-btns" id="All" [class.selected]="selectedButton === 'All'" (click)="selectButton('All')">All
            ({{AllCount}})</button>
        <div class="seperator-filter">
        </div>
        <button class="rounded-btns" id="Active" [class.selected]="selectedButton === 'Active'" [disabled]="ActiveCount == 0"
            (click)="selectButton('Active')">Active
            ({{ActiveCount}})</button>

        <button class="rounded-btns" id="InActive" [class.selected]="selectedButton === 'InActive'" [disabled]="InactiveCount == 0"
            (click)="selectButton('InActive')">Inactive
            ({{InactiveCount}})</button>
    </div>
    <div *ngIf="isGroupLevelTabs" class="col-sm-6 d-flex p-0">
        <button class="rounded-btns" id="All" [class.selected]="selectedButton === 'All'" (click)="selectButton('All')">All
            ({{AllCount}})</button>
        <div class="seperator-filter">
        </div>
        <button class="rounded-btns" id="Org-Level" [class.selected]="selectedButton === 'Org-Level'" (click)="selectButton('Org-Level')">Group
            ({{ActiveCount}})</button>

        <button class="rounded-btns" id="Location-level" [class.selected]="selectedButton === 'Location-Level'" (click)="selectButton('Location-Level')">Location
            ({{InactiveCount}})</button>
    </div>
    <div class="d-flex col-sm-6 justify-content-end p-0">

        <div class="global-search-panel d-flex col-sm-7">
            <div style="display: flex;
            margin-right: 6px;">
                <mat-icon class="search-icon">search</mat-icon>
            </div>
            <div class="standing-line">
            </div>
            <input type="text" class="search-input" [placeholder]="context" [(ngModel)]="searchText" (keyup)="handleSearch()">
        </div>

        <!-- <div class="col-sm-2 pr-0 d-flex" style="justify-content: end; padding-left: 5px;"> -->
        <!-- <span class="col-sm-12 pl-0 pr-0 d-flex"> -->
        <div *ngIf="!hideCopy" class="ml-2"><span class="cpy-drg-Icon-globe" title="Copy" (click)="copyFrmEntity()">
                <img src=".../../../../assets/icons/Globe_Blue.svg" class="globe_blue" />
                <img src=".../../../../assets/icons/Global_white.svg" class="globe_white" />
            </span></div>
        <div *ngIf="!hideEdit && !hideDragDrop" class="ml-1 p-0"><span class="cpy-drg-Icon_sort" title="Drag and drop"
                (click)="toggleDragDrop()">
                <img src=".../../../../assets/icons/Reorder Blue.svg" class="sort_blue" />
                <img src=".../../../../assets/icons/Reorder White.svg" class="sort_white" />
            </span>
        </div>
        <!-- <div *ngIf="!hideCreate" class="create-upload-btn d-flex">
            <div *ngIf="!hideCreate" class="create-only" (click)="create(0)" title="Create">Create
            </div>

            <div class="Standing-line-create">
            </div>

            <div *ngIf="!hideUpload" class="upload-icon" (click)="create(1)" title="Upload"><img
                    src=".../../../../assets/icons/Bulk Upload_White.svg" style="height: 15px; width: 15px;">
            </div>
        </div> -->
        <div *ngIf="!hideCreate" class="create-upload-btn d-flex ml-2 justify-content-center">
            <div *ngIf="!hideCreate" class="create-only" (click)="create(0)">
                Create
            </div>

            <div class="Standing-line-create" *ngIf="!hideUpload">
            </div>

            <div *ngIf="!hideUpload" class="upload-icon" (click)="create(1)"><img src=".../../../../assets/icons/Bulk Upload_White.svg"
                    title="Upload" style="height: 15px; width: 15px;">

            </div>
        </div>
        <!-- </span> -->
        <!-- </div> -->
    </div>
</div>
<div class="col-sm-12 common-div d-flex" *ngIf="dragDrop">
    <div class="row col-sm-12 p-0 m-0">
        <div class="col-sm-12 d-flex p-0">
            <div class="col-sm-6 mt-3">
            </div>
            <!-- <div class="col-sm-6 button-wrap align-center mb-2 p-0">
                <a [ngClass]="checkListChanges() ? 'reset-btn' : 'disabled-reset-btn'" (click)="resetDraggedItems()">
                    Reset
                </a>
                <button mat-raised-button class="admin-btn-success ml-2 mr-2" (click)="returnToGrid()">
                    Return
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="!checkListChanges()"
                    (click)="saveDragDrop()">
                    Save
                </button>
            </div> -->

            <div class="p-0 button-wrap col-sm-6">
                <a [ngClass]="checkListChanges() ? 'reset-btn' : 'disabled-reset-btn'" class="reset-btn mr-3" (click)="resetDraggedItems()">Reset</a>
                <button class="mr-3 align-center" (click)="returnToGrid()">Return</button>
                <button class="align-center primaryButtonColor" [disabled]="!checkListChanges()" (click)="saveDragDrop()">Save</button>
            </div>
        </div>
    </div>
</div>
<hr class="separation-line m-0 p-0" *ngIf="!dragDrop">
<div *ngIf="showGrid" class="container-fluid list-container col-sm-12 p-0 mt-0">
    <div class="col-sm-12 header d-flex py-1 mb-1 list-align">
        <div class="col-sm-12 header-wrap">
            <span [ngClass]="groupBy.length ? header.colWidth : header.colWidth" *ngFor="let header of gridHeader">
                <div class="dataTable">{{header.Name}}
                    <img *ngIf="dragDrop && sortAZ" width="16" height="16" src="https://img.icons8.com/small/16/alphabetical-sorting.png"
                        alt="alphabetical-sorting" (click)="saveDragDrop(sortAZ)" />
                    <img *ngIf="dragDrop && !sortAZ" width="16" height="16" src="https://img.icons8.com/small/16/alphabetical-sorting-2.png"
                        alt="alphabetical-sorting-2" (click)="saveDragDrop(sortAZ)" />
                </div>
                <!-- <div *ngIf="header.type == 'input'">
                    <input class="filter-search" type="text" (keyup)="filter($event, header.key)" placeholder='Search {{header.Name}}'>
                </div> -->
                <!-- <div *ngIf="header.type == 'input'" class="search-control">
                    <span class="label"> {{header.Name}}
                    </span>
                    <input class="filter-search" type="text" (keyup)="filter($event, header.key)"
                        placeholder="Search {{header.Name}}">
                </div> -->
            </span>

            <div class="col-sm-1 pr-0 edit-wraps dataTable">
                <span *ngIf="!hideExport && GridData.length > 0 && !dragDrop" (click)="exportData()" title="Export"><img
                        src=".../../../../assets/icons/Excel_Download.svg" class="export-icon"></span>
            </div>

            <!-- <div class="col-sm-1 pr-0 edit-wraps dataTable">
                    <span *ngIf="!hideExport"><img src=".../../../../assets/icons/Excel_Download.svg"
                            class="export-icon"></span>
                </div> -->
        </div>
    </div>

    <div [ngClass]="groupBy.length ? 'col-sm-12 py-1 mb-1' : 'py-1 mb-1'">
        <span *ngIf="showGrid && GridData.length > 0" class="separation-line">
                <cdk-virtual-scroll-viewport [appendOnly]="true"  itemSize="10"  [ngClass]="groupBy.length ? 'col-sm-12 tableScroll container-style' : 'container-style tableScroll'">
                    <div *cdkVirtualFor="let parentItem of GridData | searchfiltersTemplate: templatefilter;let i = index ">
                        <div class="mt-2" cdkDropList cdkDropListOrientation="vertical" *ngIf="(getDataList(parentItem) | searchfiltersTemplate: templatefilter).length"
                            [ngClass]="(groupBy.length && (getDataList(parentItem)).length)? 'row container-fluid list-container-inner mt-2 mr-0 p-0 main-list' : 'row container-fluid mt-2 mr-0 p-0 main-list' ">
                            <span *ngIf="(groupBy.length && (getDataList(parentItem)).length)" (click)="toggle(i)"
                                class="col-sm-12 p-1 header header-wrap">
                                <span class="col-sm-8 align-items-center">
                                    {{groupBy[0]}} : <span class="text-admin mb-0">{{parentItem.Name}}</span>
                                </span>

                                <img *ngIf="expandCollapse[i] && !dragDrop" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQUlEQVR4nGNgoAWQYmBgUMYjrwxVw6DAwMBwnoGBQQOLIg2oHEgNGKhBBbSRFKljEcNQjFMRulW4nIIClKCYxgAAl0wJXaLiw14AAAAASUVORK5CYII=">
                                <img *ngIf="!expandCollapse[i] && !dragDrop" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAASUlEQVR4nM3OUQaAUBhE4a/aWiK5LafWkcilu9hED8n/0Et0nsY4zPAbGhTsVw6psKJHi4w6khYMt67D9pQnpGBlxPz297n2AQfjuQY9pqE0NQAAAABJRU5ErkJggg==">
                                <!-- <button>{{ !expandCollapse[i] ? 'collapse' : 'expand'}} </button> -->
                            </span>
                            <div class="col-sm-12" *ngIf="!expandCollapse[i] && (getDataList(parentItem) | searchfiltersTemplate: templatefilter).length">
                                <div cdkDropList [cdkDropListData]="getDataList(parentItem)" (cdkDropListDropped)="dropItem($event)">
                                    <div cdkDrag [cdkDragDisabled]="hideEdit" [ngClass]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0)?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                                        *ngFor="let item of getDataList(parentItem) | searchfiltersTemplate: templatefilter;let j = index;"
                                        cdkDragBoundary=".main-list" cdkDragLockAxis="y" [cdkDragDisabled]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0) || hideEdit || !dragDrop">
                                        <div [ngClass]="header.colWidth" class="text-muted text-ellipsis dataTable"
                                            title="{{displayedColumns(item,header)}}" *ngFor="let header of gridHeader">
                                            <div *ngIf="header.Name.toLowerCase() !='default'" style="max-width: 100%; overflow: hidden;display: flex;">
                                                <div class="p-0 mr-2" *ngIf="item.Sequence">
                                                    {{item.Sequence}}
                                                </div>
                                                {{displayedColumns(item,header)}}</div>
                                            <mat-icon *ngIf="header.Name.toLowerCase() =='default' && displayedColumns(item,header)"
                                                style="position: relative;left: 35%;color: #0B9601; height: 17px;"
                                                (click)="edit()">check</mat-icon>

                                        </div>

                                        <!-- <div *ngIf="hideEdit" class="col-sm-1 pr-0 edit-wraps">
                                                <mat-icon class="actions">visibility</mat-icon>
                                            </div> -->

                                        <div class="col-sm-1 pr-0 edit-wraps" *ngIf="!dragDrop">
                                            <!-- <mat-icon class="actions mr-3">visibility</mat-icon> -->
                                            <mat-icon *ngIf="isTemplateFile(item)" class="actions" (click)="downloadMethod(item)"
                                                title="Download">download</mat-icon>
                                            <mat-icon *ngIf="!hideEdit" class="actions ml-1" (click)="editMethod(item)"
                                                title="Edit">create</mat-icon>
                                            <mat-icon *ngIf="!hideDelete" class="actions" (click)="delete(item)" title="Delete">delete</mat-icon>
                                            <!-- <button class="actions edit-delete-btn" (click)="delete(item)"><em id="deleteButton"
                                    [title]="'Delete'" class="p-0 fa fa-trash"></em></button> -->
                                            <!-- <mat-icon cdkDragHandle>drag_indicator</mat-icon> -->
                                        </div>
                                    </div>

                                    <!-- </span> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </cdk-virtual-scroll-viewport>
            </span>
            <span *ngIf="GridData.length === 0" style="display: flex; justify-content: center;">No Results Found</span>
        </div>
    </div>