<div class="top-navigation-bar  bg-secondary d-flex home-icon-align ">
    <div>{{gridData?.length}} results for '{{inputObject.queryvalue}}'</div>
    <!-- <span >Return</span> -->
    <div class="home-font-clr"  (click)="homeClicked()"><img src="../../../../assets/icons/home-icon.svg" class="home-icon-position"/>Home</div>
</div>

<app-commondetails [viewType]="'small'" *ngIf="showDetails" [userInfo]="userSessionDetails" style="z-index: 20; position: absolute; width: 100%" (CloseDetials)="closeUserDetails($event)" (EditUser)="redirectToCreateEditPage($event)"
></app-commondetails>
<app-labusersgrid  *ngIf="!showloader" class="col-sm-12 p-0" [SelectValue]="" [searchText]="" [gridHeaders]="gridHeader"  [gridData]="gridData" [fromClientUser]="true"  (selectedCard)="cardSelect($event)">
</app-labusersgrid>
<div class="px-4 w-100" *ngIf="showloader">
    <app-shimmerui [line]=8></app-shimmerui>
</div>

<!-- gridHeader -->
