import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
declare var $: any;
@Component({
  selector: 'app-compendium-admin',
  templateUrl: './compendium-admin.component.html',
  styleUrls: ['./compendium-admin.component.scss']
})
export class CompendiumAdminComponent implements OnInit {
  @Input() selecteddefaultdeploymentkey: any;
  DeploymentKeys: any;
  defaultdeploymentkey: string;
  commonService: any;
  DeploymentKey: string;
  constructor(public vaservices: VitalHttpServices, private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.DeploymentKeys = this.vaservices.DeploymentKeys
    //this.Deployment.setValue('');
    //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
  }

  SetupNewCustomerClose() {
    $('#genericModal').modal('hide');
    this.DeploymentKey = null;
  }
  // onChangeDep(deviceValue) {

  // }
  launchAdmin() {
    // window.open(this.vaservices.Admin20)
    if (!this.DeploymentKey) {
      this._snackbar.open("Please select the Deployment", "Close")
      return;
    }
    this.ngxService.start();
    try {
      //.const dbName = sessionStorage.getItem('deploymentKey') ?.toUpperCase();
      let admin2Obj =
      {
        "DeploymentKey": this.DeploymentKey,
        "userEmail": localStorage.getItem("UserEmail")
      }
      this.vaservices.launchAdmin2URL(admin2Obj).subscribe(
        (data: any) => {
          this.ngxService.stop();
          // let urlstring=JSON.parse(data);
          window.open(data)
        },
        (error) => {
          this.ngxService.stop();
          console.log(error);
        }
      );
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }
}
