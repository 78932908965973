<div class="modal" id="myModalCopy">
  <div class="modal-dialog">
      <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">Copy From Other Entity</h3>
      </div>

      <!-- Modal body -->
      <div class="modal-body modal-wrap d-flex px-2 mx-2">
        <div class="row col-sm-12 d-flex container-fluid p-0">
          <div class="row col-sm-12 ml-2 d-flex p-0 font-size align-center">
            <div class="col-sm-4 p-0 d-flex input-wraper"
              *ngIf="(dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationdiagtemplates') || (dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') || (dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes')">
              <label for="DKeys" class="col-sm-4 p-0 m-0 font-style mr-0 mt-2">
                <p><span class="error-msg">*</span>Deployment:</p>
              </label>
              <select class="form-control col-sm-5 deployment-wrap" (change)="onChangeDeployment($event.target.value)">
                <option selected>{{destDeployment}}</option>
                <option *ngFor="let i of DeploymentKeys" value="{{i}}">
                  {{ i }}
                </option>
              </select>
            </div>

            <div class="row col-sm-3 d-flex p-0">
              <label for="DKeys" class="row font-style input-wraper m-0">
                <p class="mt-2"><span class="error-msg">*</span>Group:</p>
                <input class="search-bar col-sm-8 form-control ml-2" type="text" #searchbar [(ngModel)]="searchInput"
                  (keyup)="fetchSeries(searchbar.value)" placeholder="Search here ..." />
              </label>
              <ul *ngIf="toggle" class="p-0 group-wrap" >
                <li *ngFor="let show of searchResult" (click)="selectedOrganization(show)">
                  <p> {{ show.organizationname }} ({{show.organizationid}})</p>
                </li>
              </ul>
            </div>



            <div class="col-sm-3 input-wraper mt-1"
              *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationdiagtemplates'">
              <label for="DKeys" class="font-style mr-2 input-wraper">
                <p class="mb-2 mr-2">User:</p>
                <input class="search-bar-user col-sm-12 form-control" type="text" #searchuserbar
                  [(ngModel)]="searchUserInput" (keyup)="fetchUserSeries(searchuserbar.value)"
                  placeholder="Search here ..." />
              </label>
              <ul *ngIf="usertoggle" style="left: 15px !important">
                <li *ngFor="let user of searchuserlist" (click)="selectedUser(user)">
                  <p> {{ user.username }} ({{user.rolename}})</p>
                </li>
              </ul>
            </div>
            <div class="row col-sm-3"
              *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef'">
              <label for="DKeys" class="col-sm-12 d-flex font-style">
                <p class="col-sm-4 p-0 mt-2 ">Roles:</p>
                <input class="search-bar-user col-sm-12 form-control" type="text" #searchrolebar
                  [(ngModel)]="searchRoleInput" (keyup)="fetchRoleSeries(searchrolebar.value)"
                  placeholder="Search here ..." />
              </label>
              <ul *ngIf="roletoggle" class="role-wrap">
                <li *ngFor="let role of searchrolelist" (click)="selectedRole(role)">
                  <p> {{role.rolename}} </p>
                </li>
              </ul>
            </div>
            <div class="col-sm-3 input-wraper mt-1"
              *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes'">
              <label for="DKeys" class="font-style mr-2 input-wraper">
                <p><span class="error-msg">*</span>Sites:</p>
                <input class="search-bar-user col-sm-12 form-control" type="text" #searchsitebar
                  [(ngModel)]="searchSiteTextInput" (keyup)="fetchSiteSeries(searchsitebar.value)"
                  placeholder="Search here ..." />
              </label>
              <ul *ngIf="siteToggle" style="left: 15px !important;width: 100%;">
                <li *ngFor="let site of searchSitelist" (click)="selectedSite(site)"
                  style="margin-left: 0px !important">
                  <p> {{site.SiteName}} </p>
                </li>
              </ul>
            </div>
            <div class="col-sm-2 mb-2 button-wrap"
              *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() != 'organizationattributes'">
              <button mat-raised-button   [ngClass]="{'admin-btn-success': (orgName != null ) }"                class="d-block"

                [disabled]="(searchResult.length <= 0) ||orgName == null || orgName === '' || searchInput == ''"
                (click)="getDataToCopy(orgid, userid, dataToTakeAsInput)">Get Data</button>
            </div>
            <div class="col-sm-2 mb-2 button-wrap"
              *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes'">
              <button mat-raised-button  [ngClass]="{'admin-btn-success': (orgName != null) }"              class="d-block"
                [disabled]="(searchResult.length <= 0) ||orgName == null || orgName === '' || searchSiteInput == '' || searchInput == ''"
                (click)="getDataToCopy(orgid, userid, dataToTakeAsInput)">Get Data</button>
            </div>
          </div>
        </div>
      </div>

    <div>
        <div class="nodata-wrap p-2 ml-4"
          *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef'">
          <div class="font-size" style="text-align: left;margin-left:0px">
            <em class="fa fa-info-circle" style="cursor: pointer;" aria-hidden="true"></em>
            <!-- Only Configurations will be copied, you have to manually deploy the stored procedure and excel template -->
            Only Configurations will be copied, you have to manually deploy the stored procedure and excel templates
            if you're trying to copy reports across the deployment.
          </div>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="getTableData">
        <span *ngIf="gridwidth > 0">
          <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
            [itemsSource]="gridData" (formatItem)="formatItem(flexgrid, $event)" class="grid-height mt-3">
            <div *ngFor="let columnname of columnDefs">
              <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
                [format]="'d'" aria-expanded="false" [allowResizing]="true">
              </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
          </wj-flex-grid>
          <br />
          <div *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() != 'templates'" class="navigator-fix align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
              headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
              <wj-menu-item [value]="0">No Paging</wj-menu-item>
              <wj-menu-item [value]="10">10</wj-menu-item>
              <wj-menu-item [value]="15">15</wj-menu-item>
              <wj-menu-item [value]="30">30</wj-menu-item>
              <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
          </div>
        </span>
        <div class="nodata-wrap mt-4 mb-2" *ngIf="gridwidth == 0">
          <div class="nodata-design">No Data Found</div>
        </div>
      </div>
      <!-- <div class="nodata-wrap" *ngIf="showMsg">
        <div class="font-size" style="text-align: center;margin-left:170px">
          <em class="fa fa-info-circle" style="cursor: pointer;" aria-hidden="true"></em>
          The selected custom reports will be copied as Inactive and no roles will be associated to the reports in
          the destination group.
          <p class="mb-0">Please associate roles when you want to make the report as Active.</p>
        </div>
      </div> -->
      <div *ngIf="dataToTakeAsInput.TableName.toString().toLowerCase() != 'templates'"
        class="row pl-3 mt-1 mb-1 ml-5 align-center">
        <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0" ng-init='checkStatus=false'
          [checked]='checkStatus' ng-model='checkStatus' (change)='checkValue($event)' class="border-style">
        <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
      </div>

      <div class="row align-center mb-2 mt-2">
        <div class="dml-btn">
          <button mat-raised-button class="admin-btn-success mr-4" 
          [disabled]="selectedItems.length==0 || !isValid()"
            (click)="uploadData(flexgrid)">Upload</button>
        </div>
        <div class="dml-btn">
          <button mat-raised-button class="admin-btn-success" (click)="onNoClick()">Close</button>
        </div>
      </div>

      <!-- warning popup -->
      <div *ngIf="ifOrgUpload" class="col-sm-12">
        <div class="modal overlay" id="myModal1" role="dialog">
          <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
              <div class="modal-header warning-model-header">
                <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                <span type="button" id="button_size" data-dismiss="modal" (click)="closeModel()" class="mr-2">
                  <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                    title="Close">
                </span>
              </div>
              <div class="modal-body p-2 msg-wrap">
                <div class="row col-sm-12 body-message">
                  <p id="deleteuser"
                    *ngIf="dataToTakeAsInput.TableName != 'OrganizationDiagTemplates' && dataToTakeAsInput.TableName != 'OrganizationReportsDef' &&  dataToTakeAsInput.TableName != 'OrganizationAttributes'">
                    Cannot insert
                    repeated values. <br>Repeated values : {{repeatedNames}}</p>
                  <p id="deleteuser" *ngIf="dataToTakeAsInput.TableName == 'OrganizationDiagTemplates'"> The Diagnosis
                    templates cannot be copied as the casetype is not enabled in destination group.
                    Please enable the casetype and then try copying the Templates <br>Failed for Casetype :
                    {{repeatedNames}}</p>
                  <p id="deleteuser" *ngIf="dataToTakeAsInput.TableName == 'OrganizationReportsDef'">
                    <strong>Duplicate Reports : {{repeatedNames}}</strong> <br>
                    Report with the
                    same name already exist in the destination group.
                    Do you still want to continue copying?
                  </p>
                  <p id="deleteuser" *ngIf="dataToTakeAsInput.TableName == 'OrganizationAttributes'">
                    <strong>Duplicate RuleOut : {{repeatedNames}}</strong> <br>
                    RuleOut with the
                    same name already exist in the destination group.
                    Do you still want to continue copying?
                  </p>
                </div>
              </div>
              <div class="modal-footer"
                *ngIf="dataToTakeAsInput.TableName != 'OrganizationReportsDef' && dataToTakeAsInput.TableName != 'OrganizationAttributes'">
                <button mat-raised-button class="admin-btn-success" (click)="closeModel()" type="button">OK</button>
              </div>
              <div class="modal-footer"
                *ngIf="dataToTakeAsInput.TableName == 'OrganizationReportsDef' || dataToTakeAsInput.TableName == 'OrganizationAttributes'">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="generateSaveJson(finalData)"
                  type="button">Copy</button>
                <button mat-raised-button class="admin-btn-success" (click)="closeModel()" type="button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="ifNoRole" class="col-sm-12">
        <div class="modal overlay" id="myModal1" role="dialog">
          <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
              <div class="modal-header warning-model-header">
                <h3 class="modal-title w-100 admin-model-header-txt">Alert<span>&nbsp;!</span></h3>
                <span type="button" id="button_size" data-dismiss="modal" (click)="close()" class="mr-2">
                  <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                    title="Close">
                </span>
              </div>
              <div class="modal-body p-2 msg-wrap">
                <div class="row col-sm-12 body-message">
                  <p id="deleteuser">
                  The role <strong>{{roleDiff}}</strong> is not enabled in the destination group.Do you want to proceed?
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button mat-raised-button class="admin-btn-success" (click)="close(flexgrid,true)" type="button">Yes</button>
                <button mat-raised-button class="admin-btn-success" (click)="close()" type="button">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="ifNoSiteFound" class="col-sm-12">
        <div class="modal overlay" id="myModal1" role="dialog">
          <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
              <div class="modal-header warning-model-header">
                <h3 class="modal-title w-100 admin-model-header-txt">Alert<span>&nbsp;!</span></h3>
                <span type="button" id="button_size" data-dismiss="modal" (click)="close()" class="mr-2">
                  <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                    title="Close">
                </span>
              </div>
              <div class="modal-body p-2 msg-wrap">
                <div class="row col-sm-12 body-message">
                  <p id="deleteuser">
                    <!-- {{roleDiff}} roles are not present in the Destination Group<br>
                       You cannot copy the reports that are associated with the roles {{roleDiff}}  -->
                    The selected Rule Out cannot be copied as the sub-site is not available in the destination location.
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button mat-raised-button class="admin-btn-success" (click)="close()" type="button">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
