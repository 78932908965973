import { SelectionModel, isDataSource } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError, concatMap, groupBy, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { DialogService } from 'src/app/core/services/dialog.service';
import * as XLSX from 'xlsx';
import { Validators } from 'ngx-editor';
import { FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/core/services/commonservices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { qualityAttributeAndFlags } from 'src/app/common/constants/qualityAttributeAndFlags';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { addClass } from '@grapecity/wijmo';
import { Tab } from 'src/app/base/Tab/tab.directive';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Subscription } from 'rxjs';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-new-quality-attributes',
  templateUrl: './new-quality-attributes.component.html',
  styleUrls: ['./new-quality-attributes.component.scss']
})
export class NewQualityAttributesComponent implements OnInit, AfterViewInit {
  userSessionDetails: any;
  sourceApp: string;
  dbName: string;

  @Input() templateData: any;
  //qualityAttribueList: any = [];
  listAll: 'Active' | 'All' = 'All';
  rolesB: string = '';
  caseStatusB: string = '';
  gridPage: Boolean = true
  copyDataClicked: Boolean = true
  uploadClicked: Boolean = false;
  filtercondition = new BehaviorSubject({ status: 'all', searchText: '', roles: '', caseStatus: '' });
  filtercondition$ = this.filtercondition.asObservable()
  searchText: string = ''
  page: boolean = true
  items: any[] = [];
  scrollDistance = 2;
  scrollUpDistance = 1;
  deploymentKey = ""
  listHeight = window.innerHeight - 180;
  creationHeading: 'Quality Attribute' | 'Add Quality Attribute' | 'Others' = 'Quality Attribute'
  toppings = new FormControl([]);
  qualityAttributeSelectionModel = new SelectionModel(false, [])
  test: any
  ReportTypes = ['HIPPA', 'MIRP']
  caseStatus = ['Requisition', 'Accession', 'Grossing', 'Processing', 'Diagnosis', 'Finalized']
  Orgtype: any;
  RoleList = [];
  isGroupAscending: string = 'ASC'
  qualityAttributeFilter$: any
  uploadBtn: boolean = true
  createBtn: boolean = true
  editBtn: boolean = true
  exportBtn: boolean = true
  copyBtn: boolean = true
  scrollHistory: number = 0

  @ViewChildren('scrollableDiv') maindiv
    auditableColumns: qualiyAttributeAudit
  currentAuditObject : qualiyAttributeAudit

  sequenceEditMode: boolean = false
  tracking: any = {}
  previousGroupOrder: string;
  qualityAttributeValueForSort: { previousValue: any, currentValue: any }
  isSortDataChanged: boolean;
  storeSub: Subscription = new Subscription();
  orgId: any;
  destDeployment: any;
  deployment: any;


  constructor(
    private _vitalHttpService: VitalHttpServices,
    private ngxService: NgxUiLoaderService,
    private dialog: DialogService,
    private _fb: FormBuilder,
    public commonService: CommonService,
    private _snackbar: MatSnackBar,
    private labAdminSessionService : LabadminSessionService,
    private store : Store<{breadcrum : []}>,
    private labAdminService : LabadminService,
    private activityService: ActivityTrackerService,
  ) { }

  ngAfterViewInit(): void {
    this.maindiv.changes.subscribe((comps: QueryList<any>) => {
      if (comps.first) comps.first.nativeElement.scrollTop = this.scrollHistory
    });
  }

  formGroupQA = this._fb.group({
    frmGsequence: [""],
    frmAsequenece: [""],
    frmActive: true,
    frmGname: ["", Validators.required],
    frmAname: ["", Validators.required],
    frmAdescription: ["", Validators.required],
    frmRole: new FormControl([]),
    frmReportType: new FormControl([]),
    frmCaseStatus: new FormControl([]),
    frmRequisition: false,
    frmAccession: false,
    frmGrossing: false,
    frmProcessing: false,
    frmDiagnosis: false,
    frmFinalized: false,
    frmComments: false,
    frmIshippa: false,
    frmIsmirp: true,
    frmUsedforqc: false,
    frmStage: false,
    frmAllowSync: false,
    // frmFlagRole: [""]
  });

  RolesSelectionModel = new SelectionModel<any>(true, []);
  CaseStatusSelectionModel = new SelectionModel<any>(true, []);
  ReportTypesSelectionModel = new SelectionModel<any>(true, []);
  selectedAttribute: any = {}
  frmname = ""
  frmDescription = ""
  frmSequence = ""
  frmComments = false
  frmIsActive = false
  isOthersPress = false;
  frmGpName = ""
  frmGpActive = false
  frmGpSquence = ""
  activityEntity: any;
  QualityAttributeObject = {
    frmName: { name: 'frmName', isValid: true, message: 'QualityAttribute Name Required' },
    frmGpName: { name: 'frmGpName', isValid: true, message: 'QualityAttribute Group Name Required' },
    frmRole: { name: 'frmRole', isValid: true, message: 'Select Atleast One ' },
    frmSequence: { name: 'frmSequence', isValid: true, message: 'Attribute Sequence is Required ' },
    frmGpSquence: { name: 'frmGpSquence', isValid: true, message: 'Group Sequence is Required  ' }
  }
  validateQualityfrm(attrName) {
    switch (attrName) {
      case 'frmname':
        this.QualityAttributeObject = this.frmname.trim() === '' ? ({
          ...this.QualityAttributeObject,
          frmName: {
            ...this.QualityAttributeObject.frmName,
            isValid: false,
          }
        })
          : ({
            ...this.QualityAttributeObject,
            frmName: {
              ...this.QualityAttributeObject.frmName,
              isValid: true,
            },
          });
        // !this.QualityAttributeObject.frmName.isValid ? document.getElementById('frmname')?.querySelector('div').classList.add('mdc-text-field--invalid') : document.getElementById('frmname')?.querySelector('div').classList.remove('mdc-text-field--invalid')

        break;
      case 'frmGpName':
        this.QualityAttributeObject = this.frmGpName.trim() == ''
          ? {
            ...this.QualityAttributeObject,
            frmGpName: {
              ...this.QualityAttributeObject.frmGpName,
              isValid: false,
            },
          }
          : {
            ...this.QualityAttributeObject,
            frmGpName: {
              ...this.QualityAttributeObject.frmGpName,
              isValid: true,
            },
          };
        // !this.QualityAttributeObject.frmGpName.isValid ? document.getElementById('frmGpName')?.querySelector('div').classList.add('mdc-text-field--invalid') : document.getElementById('frmGpName').querySelector('div')?.classList.remove('mdc-text-field--invalid')

        break;
      case 'frmRole':
        this.QualityAttributeObject = !this.RolesSelectionModel.selected.length
          ? {
            ...this.QualityAttributeObject,
            frmRole: {
              ...this.QualityAttributeObject.frmRole,
              isValid: false,
            },
          }
          : {
            ...this.QualityAttributeObject,
            frmRole: {
              ...this.QualityAttributeObject.frmRole,
              isValid: true,
            },
          };
        // !this.QualityAttributeObject.frmRole.isValid ? document.getElementById('frmRole')?.querySelector('div').classList.add('mdc-text-field--invalid') : document.getElementById('frmRole')?.querySelector('div').classList.remove('mdc-text-field--invalid')

        break;
      case 'frmSequence':
        this.QualityAttributeObject = this.frmSequence == ''
          ? {
            ...this.QualityAttributeObject,
            frmSequence: {
              ...this.QualityAttributeObject.frmSequence,
              isValid: false,
            },
          }
          : {
            ...this.QualityAttributeObject,
            frmSequence: {
              ...this.QualityAttributeObject.frmSequence,
              isValid: true,
            },
          };
        // !this.QualityAttributeObject.frmSequence.isValid ? document.getElementById('frmSequence')?.querySelector('div').classList.add('mdc-text-field--invalid') : document.getElementById('frmSequence')?.querySelector('div').classList.remove('mdc-text-field--invalid')

        break;

      case 'frmGpSquence':
        this.QualityAttributeObject = this.frmGpSquence == ''
          ? {
            ...this.QualityAttributeObject,
            frmGpSquence: {
              ...this.QualityAttributeObject.frmGpSquence,
              isValid: false,
            },
          }
          : {
            ...this.QualityAttributeObject,
            frmGpSquence: {
              ...this.QualityAttributeObject.frmGpSquence,
              isValid: true,
            },
          };
        // !this.QualityAttributeObject.frmGpSquence.isValid ? document.getElementById('frmGpSquence')?.querySelector('div').classList.add('mdc-text-field--invalid') : document.getElementById('frmGpSquence')?.querySelector('div').classList.remove('mdc-text-field--invalid')

        break;

      default:
        break;
    }
  }

  // ngOnInit() : void {
  //   let checkLaunchUrl=window.location.href.split('/#/')[1];
  //     if (checkLaunchUrl.toString().toLowerCase() !== "home") {
  //       let menuObj;
  //       this.labAdminSessionService.getLabAdminSession.pipe(
  //         switchMap(session => {
  //           if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
  //             this.userSessionDetails = session["userDetails"];
  //             this.sourceApp = 'VitalDx';
  //             return this.store.select("breadcrum").pipe(
  //               switchMap(result => {
  //                 menuObj = result[result.length - 1];
  //                 return this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails);
  //               })
  //             );
  //           } else {
  //             return [];
  //           }
  //         })
  //       ).subscribe(() => {
  //         this.templateData = this.labAdminService.templateData;
  //         this.dbName = this.labAdminService.deploymentKey;
  //         sessionStorage.setItem('deploymentKey', this.dbName);
  //         this.deploymentKey = this.dbName.toUpperCase();
  //         this.templateData.Keyword = menuObj["Keyword"]
  //         this.templateData['cardIdentifier'] = this.userSessionDetails.userAccountIDs[0].accountId;
  //         this.GetButtonAccess(this.labAdminService.subMenuPermissions)
  //         this.getAccountType();
  //         this.filterQA()
  //       });
  //    }
  //    else {
  //      //this.getQualityAttributes(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
  //      this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
  //      this.GetButtondetails()
  //      this.getAccountType()
  //      this.filterQA()
  //    }
  // }

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.dbName = this.destDeployment;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"];
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
            await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
            _this.templateData = _this.labAdminService.templateData;
            _this.dbName = _this.labAdminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', _this.dbName);
            _this.deploymentKey = _this.dbName.toUpperCase();
            _this.templateData.Keyword = menuObj["Keyword"]
            _this.templateData['cardIdentifier'] = _this.userSessionDetails.userAccountIDs[0].accountId;
            _this.GetButtonAccess(_this.labAdminService.subMenuPermissions)
            _this.getAccountType();
            _this.filterQA();
            _this.getAuditableDetails(_this.templateData.menuURL);
            _this.activityService.setActivitySession({ 'entityId': '', 'entityType': _this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':_this.templateData.menuURL}] });
          });
        }
      })
    }
    else {
      this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.GetButtondetails()
      this.getAccountType()
      this.filterQA()
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
      this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
      this.getAuditableDetails(this.templateData.menuURL);

    }
  }



  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this._vitalHttpService.SubmenuAction);
  }

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext  == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
      }
    }
  }
  //#endregion
  selectAll(listItems: any, formcontrols: any) {
    const formref = this.formGroupQA.get(formcontrols)
    //this.toppings.value.includes('All')?this.toppings.patchValue(['All',...this.toppingList]):this.toppings.patchValue([])
    formref.value.includes('All') ? formref.patchValue(['All', ...listItems]) : this.formGroupQA.get(formcontrols).patchValue([])

  }

  selectOne(listItem: any, formcontrols: any) {
    const formref = this.formGroupQA.get(formcontrols)
    if (!formref.value.includes('All') && formref.value.length == listItem.length)
      formref.patchValue([...formref.value, 'All']);
    else if (formref.value.includes('All') && formref.value.length != listItem.length + 1) {
      let tempToppingValue = formref.value
      tempToppingValue.splice(tempToppingValue.indexOf('All'), 1)

      formref.patchValue(tempToppingValue);
    }

  }
  addSelectionModel(data: any, selctionModelList: any, mainList: any) {
    selctionModelList.toggle(data);
    if (selctionModelList.selected.includes('All') && mainList.length + 1 != selctionModelList.selected.length) {
      selctionModelList.toggle("All")
    }
    else if (!selctionModelList.selected.includes('All') && mainList.length == selctionModelList.selected.length) {
      selctionModelList.toggle("All")
    }
    this.validateQualityfrm('frmRole')

  }
  allSelected = () => this.RoleList.length + 1 == this.RolesSelectionModel.selected.length
  isAllSelected(selctionModelList: any, mainList: any) {
    if (selctionModelList.selected.includes('All') && mainList.length + 1 != selctionModelList.selected.length) {
      selctionModelList.toggle("All")
    }
    else if (!selctionModelList.selected.includes('All') && mainList.length == selctionModelList.selected.length) {
      selctionModelList.toggle("All")
    }
    this.validateQualityfrm('frmRole')
  }
  addAllSelectionModel(selctionModelList: any, mainList: any) {
    if (mainList.length + 1 == selctionModelList.selected.length) {
      selctionModelList.clear()
      this.validateQualityfrm('frmRole')
      return
    }
    selctionModelList.select(...mainList, 'All');
    this.validateQualityfrm('frmRole')
  }

  private getQualityAttributes(keyword: string, orgId: string) {
    return this._vitalHttpService.GetData({
      OperationName: null,
      Query: this.GetQuery(keyword),
      Variables: { orgid: `${orgId}` }
    },this.dbName).pipe(
      map(i => i.data.submenuData),
      concatMap(e => e),
      groupBy((i: any) => i.parentid),
      mergeMap(group => group.pipe(toArray())),
      toArray(),
      map((i: any) => this.orderData(i))
    )
  }

  orderData(data: any) {
    if(data.length === 0) return data
    let index=data.findIndex(i => !i[0].parentid)
    const group: [] = data[index]
    let result = []
    data.splice(index,1)
    let attribute = data
    if (data.length == 0)
      return data
    group.forEach((grp: any) => {
      attribute.forEach((attr) => {
        if (attr[0].parentid === grp.attrid) {
          let isAscending = 'ASC'
          let isOthersPresent = attr.filter((attr) => attr.attrname.toLowerCase() === 'others').length === 1
          let isAllAttrActive = attr.filter((attr) => attr.isactive.toLowerCase() === 'active').length > 0
          if (attr.length > 0)
            isAscending = this.checkOrder(attr, 'attrname')
          const tempdata = {
            attrGroupName: {
              ...grp,
              isChildAscending: isAscending,
              currentChildSortOrder: isAscending,
              isOthersPresent,
              isAllAttrActive
            },
            attrList: attr
          }
          result.push(tempdata)
        }
      })
    })
    this.isGroupAscending = this.checkOrder(result.map((i) => i.attrGroupName), 'category')
    this.previousGroupOrder = this.isGroupAscending
    return result
  }

  drop(event: CdkDragDrop<any>, from: 'group' | 'attr' = 'attr', item: any) {
    if (event.previousContainer === event.container && event.previousIndex != event.currentIndex) {

      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }

      if (from === 'group') {
        this.sequenceTracking('group', 'drop')
        this.isGroupAscending = this.checkOrder(event.container.data.map((i) => i.attrGroupName), 'category')
      }
      else {
        this.sequenceTracking(item.attrGroupName.attrid, 'drop')
        item.attrGroupName.currentChildSortOrder = this.checkOrder(event.container.data, 'attrname')
      }

      // let previousIndex = event.previousIndex
      // let currentIndex = event.currentIndex

      // if (event.previousIndex > event.currentIndex) {
      //   previousIndex = event.currentIndex
      //   currentIndex = event.previousIndex
      // }

      // let previousData = event.previousContainer.data.slice(previousIndex, currentIndex + 1).map((i) => {
      //   if (from === 'attr') return i.attrorder
      //   else if (from === 'group') return i.attrGroupName.grouporder
      // })

      //   let currentChangedData = event.container.data.slice(previousIndex, currentIndex + 1)
      //   let oper = from === 'attr' ? qualityAttributeAndFlags.Swap_sequence_QA_Attribute : qualityAttributeAndFlags.Swap_sequence_QA_Group

      //   if (currentChangedData.length > 1) {
      //     let modifiedAttributes = []

      //     for (let index = 0; index < currentChangedData.length; index++) {
      //       const element = from === 'attr' ? { ...currentChangedData[index] } : { ...currentChangedData[index].attrGroupName };
      //       element.attrorder = previousData[index]
      //       modifiedAttributes.push(element)
      //     }

      //     let data = modifiedAttributes.map((i) => (
      //       {
      //         id: i.attrid,
      //         sequence: i.attrorder
      //       }
      //     ))
      //     this.ngxService.start()
      //     this._vitalHttpService.SwapSequence(oper, data)
      //       .subscribe({
      //         next: () => {
      //           this._snackbar.open('Sequence updated successfully', 'Close')
      //           this.filterQA()
      //         },
      //         error: () => {
      //           this._snackbar.open('Something went wrong. Please try again', 'Close')
      //           moveItemInArray(
      //             event.container.data,
      //             event.currentIndex,
      //             event.previousIndex
      //           );
      //           this.ngxService.stop()
      //         },
      //         complete: () => this.ngxService.stop()
      //       })
      //   }
      // }
    }
  }

  private GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this._vitalHttpService.mainQueryList.findIndex(
      mainQuery =>
        mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this._vitalHttpService.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  openPreview() {
    this.ngxService.start()
    let previousFilterConditions = this.filtercondition.value
    this.filtercondition.next({ status: 'Active', searchText: '', roles: '', caseStatus: '' })
    const preview$ = this.qualityAttributeFilter$.pipe(map((i: any) => i.data)).subscribe((res) => {
      this.dialog.openLargeDialog("Quality Attribures", res, 'qualityattribute').afterClosed().subscribe(() => {
        preview$.unsubscribe()
        this.filtercondition.next({ ...previousFilterConditions })
        this.ngxService.stop()
      })
    })
  }
  openEditAttribute(selectedGroup: any) {
    this.clearFormFeilds();
    this.selectedAttribute = selectedGroup;
    this.qualityAttributeSelectionModel.select(selectedGroup.attrid);
    this.setFormData()
    this.currentAuditObject = this.constructObjectForAudit("Edit Attribute")
  }

  openAddAttribute(to: 'Add Quality Attribute' | 'Others', selectedGroup: any) {
    this.clearFormFeilds();
    this.selectedAttribute = selectedGroup;
    this.frmIsActive = true;
    this.creationHeading = to

    to == "Others" ? (this.frmname = "Others", this.isOthersPress = true) : ''
    this.qualityAttributeSelectionModel.select(selectedGroup.attrid);
  }
  openCreateGroup() {
    this.clearFormFeilds()
    this.frmIsActive = true;
    this.qualityAttributeSelectionModel.select(-1)

  }
  setFormData() {
    let roleList = this.selectedAttribute.rolename.split(',').map(i => i.trim(' ')).filter((role) => this.RoleList.includes(role)).sort()
    this.RolesSelectionModel.select(...roleList)
    this.selectedAttribute.rolename = roleList.join(',')
    this.selectedAttribute.requisition && this.CaseStatusSelectionModel.select('Requisition') //,'Accession','Grossing','Processing','Diagnosis','Finalized'
    this.selectedAttribute.accession && this.CaseStatusSelectionModel.select('Accession')
    this.selectedAttribute.grossing && this.CaseStatusSelectionModel.select('Grossing')
    this.selectedAttribute.processing && this.CaseStatusSelectionModel.select('Processing')
    this.selectedAttribute.diagnosis && this.CaseStatusSelectionModel.select('Diagnosis')
    this.selectedAttribute.reports && this.CaseStatusSelectionModel.select('Finalized')
    this.selectedAttribute.ishippa && this.ReportTypesSelectionModel.select('HIPPA')
    this.selectedAttribute.ismirp && this.ReportTypesSelectionModel.select('MIRP')
    this.frmname = this.selectedAttribute.attrname
    this.frmSequence = this.selectedAttribute.attrorder
    this.frmDescription = this.selectedAttribute.attrdescription
    this.frmComments = this.selectedAttribute.iscomment
    this.frmIsActive = this.selectedAttribute.isactive.toLowerCase() == 'active';
    this.isAllSelected(this.RolesSelectionModel, this.RoleList)
    this.isAllSelected(this.CaseStatusSelectionModel, this.caseStatus)
    this.isAllSelected(this.ReportTypesSelectionModel, this.ReportTypes)
    this.isOthersPress = this.selectedAttribute.attrname.toLowerCase() == 'others'

  }
  clearFormFeilds() {
    this.frmname = ""
    this.frmDescription = ""
    this.frmSequence = ""
    this.frmComments = false
    this.frmIsActive = false
    this.CaseStatusSelectionModel.clear();
    this.ReportTypesSelectionModel.clear();
    this.RolesSelectionModel.clear();
    this.selectedAttribute = {}
    this.frmGpName = ''
    this.frmGpSquence = ''
    this.frmGpActive = false
    this.isOthersPress = false
    this.QualityAttributeObject = {
      frmName: { name: 'frmName', isValid: true, message: 'QualityAttribute Name Required' },
      frmGpName: { name: 'frmGpName', isValid: true, message: 'QualityAttribute Group Name Required' },
      frmRole: { name: 'frmRole', isValid: true, message: 'Select Atleast One ' },
      frmSequence: { name: 'frmSequence', isValid: true, message: 'Attribute Sequence is Required ' },
      frmGpSquence: { name: 'frmGpSquence', isValid: true, message: 'Group Sequence is Required  ' }
    }
  }
  getAccountType() {
    let queryResult: any;
    let queryVariable: any;
    let query = this._vitalHttpService.GetQuery('getassociationtypeforaccid');
    let Qstr = "";
    Qstr += "accountid = \"" + this.templateData.cardIdentifier.toString() + "\"";
    queryVariable = { "accid": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this._vitalHttpService.GetData(queryResult, this.dbName).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.Orgtype = res.data.vwGetAssociationtypeByOrgID_list;
          this.getUserRoles(this.dbName);
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }
  getUserRoles(dbName) {
    let queryResult: any;
    let queryVariable;
    let query = this._vitalHttpService.GetQuery('getuserrolesforassociationtype');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0].associationtype + "\"";
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this._vitalHttpService.GetData(queryResult, dbName).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.RoleList = res.data.vwGetRolesBasedOnAccountAssociation_list.map(i => i.displayname).sort()
        }
      }
    }, error => {
      this.ngxService.stop();
      console.log(error)
    })
  }
  createNewAttribute(isGroup = false) {
    if (!this.validateForm(isGroup)) return
    let roles = this.RolesSelectionModel.selected.filter(i => i != 'All')
    let caseStatus = this.CaseStatusSelectionModel.selected;
    let reportType = this.ReportTypesSelectionModel.selected;
    let ObjectArray = [{
      "roles": roles.join(','),
      "associationtype": this.Orgtype[0].associationtype
      , "organizationid": this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id')
      , "diagnosis": caseStatus.includes('Diagnosis') ? 1 : 0      //'Requisition','Accession','Grossing','Processing','Diagnosis','Finalized'            // data.frmDiagnosis ? 1 : 0
      , "processing": caseStatus.includes('Processing') ? 1 : 0                 // data.frmProcessing ? 1 : 0
      , "grossing": caseStatus.includes('Grossing') ? 1 : 0                  // data.frmGrossing ? 1 : 0
      , "accession": caseStatus.includes('Accession') ? 1 : 0                     // data.frmAccession ? 1 : 0
      , "requisition": caseStatus.includes('Requisition') ? 1 : 0
      , "reports": caseStatus.includes('Finalized') ? 1 : 0                 // data.frmFinalized ? 1 : 0
      , "category": this.frmGpName == '' ? this.selectedAttribute?.category : this.frmGpName                  // data.frmGname
      , "attrdescription": this.frmDescription                 // data.frmAdescription 'HIPPA','MIRP'
      , "attrorder": ""                          //data.frmAsequenece
      , "attrname": this.frmname                           //data.frmAname
      , "ismirp": reportType.includes('MIRP') ? 1 : 0          //data.frmIsmirp ? 1 : 0
      , "ishippa": reportType.includes('HIPPA') ? 1 : 0  ///data.frmIshippa ? 1 : 0
      , "isactive": this.frmIsActive ? 1 : 0  //((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      , "usedforqc": this.selectedAttribute?.allowexternalsync?.usedforqc ?? false //data.frmUsedforqc ? 1 : 0
      , "grouporder": this.selectedAttribute?.grouporder ?? ''
      , "iscomment": this.frmComments ? 1 : 0     //data.frmComments ? 1 : 0
      , "allowexternalsync": this.selectedAttribute?.allowexternalsync
      , "stage": this.selectedAttribute?.stage ? 1 : 0
      , "tablename": "qualityattributes"
      , "slno": 1
    }]
    this.ngxService.start()
    this._vitalHttpService.BulkUploadQualityAttributes(ObjectArray, this.dbName).subscribe({
      next: (result) => {
        if (result.status == 'Success') {
          let res = result.content
          if (res.Result[0].STATUS == "Success") {
            this._snackbar.open(res.Result[0].Notes + ' successfully', 'Close')
	                if(isGroup){
              this.recordAudit('Create Group')
              this.recordAudit('Create Attribute')
            }
            else this.recordAudit('Create Attribute')

            this.clearFormFeilds()
            this.qualityAttributeSelectionModel.clear();
          this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
            this.filterQA()
          }
          else {
            this._snackbar.open(res.Result[0].Notes, 'Close');
          }
        }
        else {
          this._snackbar.open('Adding Quality Attribute failed!', 'Close');
        }
      },
      error: (err) => {
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(err)
      },
      complete: () => {

        this.ngxService.stop()


      }

    })

  }
  updateAttribute() {
    if (!this.validateForm(false, true)) return
    let roles = this.RolesSelectionModel.selected.filter(i => i != 'All')
    let caseStatus = this.CaseStatusSelectionModel.selected;
    let reportType = this.ReportTypesSelectionModel.selected;
    let ObjectArray = [{
      "roles": roles.join(','),
      "associationtype": this.Orgtype[0].associationtype
      , "organizationid": this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id')
      , "diagnosis": caseStatus.includes('Diagnosis') ? 1 : 0      //'Requisition','Accession','Grossing','Processing','Diagnosis','Finalized'            // data.frmDiagnosis ? 1 : 0
      , "processing": caseStatus.includes('Processing') ? 1 : 0                 // data.frmProcessing ? 1 : 0
      , "grossing": caseStatus.includes('Grossing') ? 1 : 0                  // data.frmGrossing ? 1 : 0
      , "accession": caseStatus.includes('Accession') ? 1 : 0                     // data.frmAccession ? 1 : 0
      , "requisition": caseStatus.includes('Requisition') ? 1 : 0
      , "reports": caseStatus.includes('Finalized') ? 1 : 0                 // data.frmFinalized ? 1 : 0
      , "category": this.selectedAttribute.category                  // data.frmGname
      , "attrdescription": this.frmDescription                 // data.frmAdescription 'HIPPA','MIRP'
      , "attrorder": this.frmSequence              // this.selectedAttribute.attrorder ?? ""                          //data.frmAsequenece
      , "attrname": this.frmname
      , "parentid": this.selectedAttribute.parentid
      , "attrid": this.selectedAttribute.attrid
      , "ismirp": reportType.includes('MIRP') ? 1 : 0          //data.frmIsmirp ? 1 : 0
      , "ishippa": reportType.includes('HIPPA') ? 1 : 0  ///data.frmIshippa ? 1 : 0
      , "isactive": this.frmIsActive ? 1 : 0  //((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      , "usedforqc": this.selectedAttribute.allowexternalsync.usedforqc ?? false //data.frmUsedforqc ? 1 : 0
      , "grouporder": this.selectedAttribute.grouporder                                      //this.selectedAttribute.grouporder ?? ''
      , "iscomment": this.frmComments ? 1 : 0     //data.frmComments ? 1 : 0
      , "allowexternalsync": this.selectedAttribute.allowexternalsync
      , "stage": this.selectedAttribute.stage ? 1 : 0
      , "tablename": "qualityattributes"
      , "slno": 1
    }]
    this.ngxService.start()
    this._vitalHttpService.UpdateQualityAttributes(ObjectArray, this.dbName).subscribe({
      next: (result) => {
          if (result.status=='Success') {
            this.recordAudit('Edit Attribute')
            this.clearFormFeilds()
            this.qualityAttributeSelectionModel.clear();
            this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
            this.filterQA()
            this._snackbar.open('Data updated successfully', 'Close');
          }
          else {
            this._snackbar.open('Update failed!', 'Close');
          }


      },
      error: (error) => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      }, complete: () => {
        this.ngxService.stop()
      }
    })

  }
  validateForm(isCreateGp = false, isEdit = false): Boolean {

    if (isCreateGp) {
      if (this.frmGpName.trim() == "") {
        this.validateQualityfrm('frmGpName')
        this._snackbar.open('Group Name is required', 'Close');
        return false
      }
    }
    if (isEdit) {
      this.validateQualityfrm('frmSequence')
      if (!this.frmSequence) {
        this._snackbar.open('Attribute Sequence is required', 'Close');
        return false
      }
    }
    this.validateQualityfrm('frmname')
    this.validateQualityfrm('frmRole')

    if (this.frmname.trim() == "") {
      this._snackbar.open('Attribute Name is required', 'Close');
      return false
    }

    if (this.RolesSelectionModel.selected.filter(i => i != 'All').length == 0) {
      this._snackbar.open('Select Altleast One Role', 'Close');
      return false
    }

    return true
  }

  loadUploadScreen() {
    this.gridPage = false;
    this.uploadClicked = true;
    this.copyDataClicked = false;
  }
  savedValue: any;



  editGroupName(data) {
    this.validateQualityfrm('frmGpName')
    this.validateQualityfrm('frmGpSquence')
    if (this.frmGpName.trim() == '') {
      this._snackbar.open('Group Name is required', 'Close');
      return false
    }
    else if (!this.frmGpSquence) {
      this._snackbar.open('Group Sequence is required', 'Close');
      return false
    }
    let tempData = [{
      "associationtype": this.Orgtype[0].associationtype
      , "organizationid": this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id')
      , "category": this.frmGpName                  // data.frmGname
      , "attrdescription": data.attrdescription             // data.frmAdescription 'HIPPA','MIRP'
      , "attrname": this.frmGpName
      , "attrid": data.attrid
      , "isactive": data.isactive.toLowerCase() == 'active' ? 1 : 0 ///this.frmGpActive?1:0  //((data.frmActive) || (data.frmActive.toString().toLowerCase() == 'active')) ? 1 : 0
      , "grouporder": this.frmGpSquence
      , "tablename": "qualityattributes"
      , "slno": 1,
      "roles": data.rolename
    }]
    this.ngxService.start();
    this._vitalHttpService.UpdateQualityAttributes(tempData, this.dbName).subscribe({
      next: (result) => {
        if (result.status == 'Success') {
            this.recordAudit('Edit Group')

          this.clearFormFeilds()
          this.qualityAttributeSelectionModel.clear();
          //this.qualityAttribueList=[]
          //this.getQualityAttributes(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
          this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
          this.filterQA()
          this._snackbar.open('Group ' + tempData[0].attrname + ' updated successfully', 'Close');
          // this.auditDetails(this.selectedrowData, dataArray, 'updated');
        }
        else {
          this._snackbar.open('Group ' + tempData[0].attrname + ' update failed!', 'Close');
        }


      },
      error: (error) => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      }, complete: () => {
        this.ngxService.stop()
      }
    })
  }
  openEditGroup(data: any) {
    this.savedValue = data
    this.qualityAttributeSelectionModel.select(data.attrid * -1);
    this.frmGpName = data.category
    this.frmGpSquence = data.grouporder
    this.selectedAttribute = data
    this.currentAuditObject = this.constructObjectForAudit('Edit Group')
    // this.frmGpActive=data.isactive.toLowerCase()=='active';
  }

  copyOrg() {
    this.gridPage = false;
    this.copyDataClicked = true;
    this.uploadClicked = false;
  }
  backClicked() {
    this.gridPage = true;
  }

  //#start region - Export
  // export the list of QualityAttributes data in Excel format
  exportexcel(QualityAttributesData) {
    const fileName = 'QualityAttributesList.xlsx';
    let QualityAttributesFilteredData = QualityAttributesData.map(i => ({
      ...i, attrList: [...i.attrList.map(j => ({
        organizationid: j.organizationid,
        category: j.category,
        attrid: j.attrid,
        attrname: j.attrname,
        attrdescription: j.attrdescription,
        attrorder: j.attrorder,
        attrtype: j.attrtype,
        defaultvalue: j.defaultvalue,
        grouporder: j.grouporder,
        parentid: j.parentid,
        isactive: j.isactive,
        rolename: j.rolename,
        groupisactive: j.groupisactive
      }))]
    }))
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var i;
    for (i = 0; i < QualityAttributesFilteredData.length; ++i) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(QualityAttributesFilteredData[i].attrList, QualityAttributesFilteredData[i].attrGroupName);
      XLSX.utils.book_append_sheet(wb, ws, ('QA' + "_" + (i + 1) + '_' + QualityAttributesFilteredData[i].attrGroupName.category.toString().replace(/[^\w\s]/gi, "")).substring(0, 30));
    }
    XLSX.writeFile(wb, fileName);
  }
  //#region Export

  // //to sort quality attributes in asc or desc order
  // sortData(isAscending: boolean, parentId: string = null, groupName? : string) {
  //   let ref = this.dialog.openDialog('Sort', `The ${parentId ? 'Attributes' : 'Groups'} will be sorted in ${isAscending ? 'ascending' : 'descending'} order, It cannot be reverted. Are you sure to continue?`, '', 'Sort', 'Cancel')
  //   ref.afterClosed().subscribe((res) => {
  //     if (res) {
  //       let postData = {
  //         orderby: isAscending ? qualityAttributeAndFlags.Ascending : qualityAttributeAndFlags.Descending,
  //         organizationId: this.templateData.secondarykeys.OrganizationId,
  //         oper: qualityAttributeAndFlags.Quality_Attributes,
  //         parentId: parentId,
  //         accountid: null,
  //         casetype: null
  //       }
  //       this.ngxService.start()
  //       this._vitalHttpService.UpdateSequence(postData).subscribe({
  //         next: (res) => {
  //           this.commonService.createActivityObject('', 'Order', `${parentId ? `Quality Attributes under group ${groupName}` : 'Quality Attribute Groups'}`, 'Edit', {order : `${isAscending ? 'Ascending' : 'Descending'}`}, {order : ''}, this.commonService.generateGuid(), this.auditableColumns);
  //           this.filterQA()
  //           //  this.getQualityAttributes(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
  //         },
  //         error: () => {
  //           console.error("sort failed")
  //           this.ngxService.stop()
  //         },
  //         complete: () => this.ngxService.stop()
  //       })
  //     }
  //   })
  // }

  Filter$(filtervalues: any = null) {
    if (!filtervalues)
      this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
    else {
      this.filtercondition.next({ ...this.filtercondition.value, ...filtervalues })
    }
  }

  private filterQA() {
    this.qualityAttributeFilter$ = combineLatest([
      this.filtercondition$,
      this.getQualityAttributes(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
    ]).pipe(
      map(([filter, res]) => {
        if (res && res.length) {
          let data: any
          //filter text,role,casetype
          data = filter.searchText != '' ?
            res.map(i => ({
              ...i, attrList: i.attrList.filter(j => this.stringNormalize(j.attrname).includes(this.stringNormalize(filter.searchText)))
            }))
            : res
          data = filter.caseStatus != '' ? data.map(i => ({
            ...i, attrList: i.attrList.filter(j => j[this.stringNormalize(filter.caseStatus)])
          })) : data
          data = filter.roles != '' ? data.map(i => ({
            ...i, attrList: i.attrList.filter(j => this.stringNormalize(j.rolename).split(',').includes(this.stringNormalize(filter.roles)))
          })) : data

          let totalCount = data.reduce((p, c) => (
            p + c.attrList.length
          ), 0)
          let activeCount = data.reduce((p, c) => (
            p + (c.attrList.filter(i => i.isactive.toLowerCase() == 'active').length))
            , 0)
            if (filter.status != 'all')
              data = filter.status.toLowerCase() === 'active' ? data.map(i => ({ ...i, attrGroupName:{...i.attrGroupName,isAllAttrActive:true}, attrList: i.attrList.filter(j => j.isactive.toLowerCase() == 'active') })) :
            data.map(i => ({ ...i,attrGroupName:{...i.attrGroupName,isAllAttrActive:false}, attrList: i.attrList.filter(j => j.isactive.toLowerCase() == 'inactive') }))
          data = data.length > 0 ? data.filter(i => i.attrList.length > 0) : data;

          data = { data: data, statusCount: { totalCount, activeCount }, isNoDataPresent: false }
          this.ngxService.stop()
          return data;

        }
        return { data: [], statusCount: { totalCount: 0, activeCount: 0 }, isNoDataPresent: true }
      }),
      tap(() => this.ngAfterViewInit()),
      tap((res) => this.qualityAttributeValueForSort = { currentValue: res.data, previousValue: res.data.map((qa) => ({ ...qa, attrList: qa.attrList.map((j) => ({ ...j })) })) })
    )
  }
  stringNormalize(str: any) {
    return str != null ? str.toLowerCase().replace(/\s/g, "") : '';
  }
  onScroll = (e: any) => this.scrollHistory = e.target.scrollTop

  setAttributeStatus(status: boolean, AttrValue: any, id: string) {
    AttrValue.attrGroupName.isAllAttrActive = status
    const attrIds = AttrValue.attrList.map((attr: any) => attr.attrid).join(',')
    const statusText = status ? 'Activate' : 'Deactivate'
    const ref = this.dialog.openDialog('Alert', `Are you sure you want to ${statusText} the attributes of ${AttrValue.attrGroupName.category}.`, '', statusText, 'Cancel')
    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.ngxService.start()
        this._vitalHttpService.SetStatus({ status, attrIds },this.dbName).subscribe({
          next: (res) => {
            this._snackbar.open(`Data updated successfully`, 'Close')
            this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
            this.filterQA()
          },
          error: (err) => {
            console.log(err)
            //document.getElementById(id).querySelector('input').checked = !status
            AttrValue.attrGroupName.isAllAttrActive = !status
            this._snackbar.open('Something went wrong. Please try again', 'Close');
            this.ngxService.stop()
          },
          complete: () => this.ngxService.stop()
        })
      }
      else
      {
        //AttrValue.attrGroupName.isAllAttrActive = status
        //document.getElementById(id).querySelector('input').checked = !status
        AttrValue.attrGroupName.isAllAttrActive = !status
      }
    })
  }

  private checkOrder(array, key): 'ASC' | 'DESC' | 'UNS' | "" {
    if (array.length <= 1) return ""
    var aa = array.slice(1);
    if (aa.every((a, i) => array[i][key].toLowerCase() <= a[key].toLowerCase())) {
      return "ASC";
    }
    if (aa.every((a, i) => array[i][key].toLowerCase() >= a[key].toLowerCase())) {
      return "DESC";
    }
    return "UNS";
  }
  caseStatusSelection(event: any) {
    if (!event && this.filtercondition.value.caseStatus != '')
      this.filtercondition.next({ ...this.filtercondition.value, caseStatus: '' })
    else
      event && this.filtercondition.next({ ...this.filtercondition.value, caseStatus: event.selectedValue })
  }
  RoleStatusSelection(event: any) {
    if (!event && this.filtercondition.value.roles != '')
      this.filtercondition.next({
        ...this.filtercondition.value,
        roles: '',
      });
    else
      event &&
        this.filtercondition.next({
          ...this.filtercondition.value,
          roles: event.selectedValue,
        });
  }

  filterStatus(updateValue) {
    this.filtercondition.next({ ...this.filtercondition.value, ...updateValue })
  }
  enableSortEdit() {
    this.ngxService.start()
    this.filtercondition.next({ status: 'active', searchText: '', roles: '', caseStatus: '' })
    this.sequenceEditMode = !this.sequenceEditMode
    this.isSortDataChanged = false
  }

  sequenceTracking(from: string, message: 'ASC' | 'DESC' | 'drop', values: any = [], fromcode: boolean = false) {
    if (!fromcode) {
      if (this.tracking.hasOwnProperty(from)) this.tracking[from].push(message)
      else this.tracking[from] = [message]
    }

    if (from === 'group') {
      if (message === 'ASC') {
        values = values.sort((a, b) => (a.attrGroupName.category.toLowerCase() > b.attrGroupName.category.toLowerCase() ? 1 : -1))
        this.updateLocalSequence('group')
        this.isGroupAscending = 'ASC'
      }
      else if (message === 'DESC') {
        values = values.sort((a, b) => (a.attrGroupName.category.toLowerCase() > b.attrGroupName.category.toLowerCase() ? -1 : 1))
        this.isGroupAscending = 'DESC'
        this.updateLocalSequence('group')
      }
    }
    else {
      if (message === 'ASC') {
        values.attrList = values.attrList.sort((a, b) => (a.attrname.toLowerCase() > b.attrname.toLowerCase() ? 1 : -1))
        values.attrGroupName.currentChildSortOrder = 'ASC'
        this.updateLocalSequence(values.attrGroupName.attrid)
      }
      else if (message === 'DESC') {
        values.attrList = values.attrList.sort((a, b) => (a.attrname.toLowerCase() > b.attrname.toLowerCase() ? -1 : 1))
        values.attrGroupName.currentChildSortOrder = 'DESC'
        this.updateLocalSequence(values.attrGroupName.attrid)
      }
      // const prev = this.qualityAttributeValueForSort.previousValue.filter((j) => j.attrGroupName.category === values.attrGroupName.category)[0].attrList
      // const curr = this.qualityAttributeValueForSort.currentValue.filter((j) => j.attrGroupName.category === values.attrGroupName.category)[0].attrList

      // this.isSortDataChanged = curr.map((i) => i.attrid).join(',') != prev.map((i) => i.attrid).join(',')
    }

    this.checkIfDataChanged()
  }

  checkIfDataChanged() {
    this.isSortDataChanged = this.qualityAttributeValueForSort.currentValue.map((i) => i.attrGroupName.attrid).join(',') != this.qualityAttributeValueForSort.previousValue.map((i) => i.attrGroupName.attrid).join(',')

    if (!this.isSortDataChanged) {
      for (let index = 0; index < this.qualityAttributeValueForSort.currentValue.length; index++) {
        this.isSortDataChanged = this.qualityAttributeValueForSort.currentValue[index].attrList.map((i) => i.attrid).join(',') != this.qualityAttributeValueForSort.previousValue[index].attrList.map((i) => i.attrid).join(',')
        if (this.isSortDataChanged) break
      }
    }
  }

  returnToList() {
    if (this.isSortDataChanged) {
      let ref = this.dialog.openDialog("Alert", `All your changes will be lost. Do you want to proceed?`, "", "Proceed", "Cancel");
      ref.afterClosed().subscribe((res) => {
        if (res) {
          this.ngxService.start()
          this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
          this.sequenceEditMode = !this.sequenceEditMode
          this.filterQA()
          this.tracking = {}
        }
      })
    }
    else {
      this.ngxService.start()
      this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
      this.sequenceEditMode = !this.sequenceEditMode
      this.tracking = {}
    }
  }

  // isSortDataChanged = () => !(Object.keys(this.tracking).length > 0)

  saveSequence() {
    this.ngxService.start()
    let groupSort = []
    let attrSort = []
    let grpSorted = false

    try {
      for (let index = 0; index < this.qualityAttributeValueForSort.currentValue.length; index++) {
        const currentElement = this.qualityAttributeValueForSort.currentValue[index];
        let previousElement = this.qualityAttributeValueForSort.previousValue[index]

        if (this.tracking.hasOwnProperty('group')) {

          if (!grpSorted) {
            const ascIndex = this.tracking['group'].lastIndexOf('ASC')
            const descIndex = this.tracking['group'].lastIndexOf('DESC')
            const sortedValue = ascIndex >= descIndex ? ascIndex : descIndex

            if (sortedValue != -1) {

              if (this.previousGroupOrder != this.tracking['group'][sortedValue]) {
                this.sequenceTracking('group', this.tracking['group'][sortedValue], this.qualityAttributeValueForSort.previousValue, true)
                groupSort.push({ organizationId: currentElement.attrGroupName.organizationid, oper: qualityAttributeAndFlags.Quality_Attributes, orderby: this.tracking['group'][sortedValue], accountid: null, casetype: null, parentId: null })

                for (let index2 = 0; index2 < this.qualityAttributeValueForSort.previousValue.length; index2++) {
                  this.qualityAttributeValueForSort.previousValue[index2].attrGroupName.grouporder = index2 + 1
                }

                previousElement = this.qualityAttributeValueForSort.previousValue[index]
                grpSorted = true
              }

              const len = this.tracking['group'].length - 1
              if (this.tracking['group'][len] === this.tracking['group'][sortedValue]) delete this.tracking['group']

              else this.tracking['group'] = ['drop']
            }
          }

          if (currentElement.attrGroupName.attrid != previousElement.attrGroupName.attrid && this.tracking.hasOwnProperty('group')) {
            attrSort.push({ id: currentElement.attrGroupName.attrid, sequence: previousElement.attrGroupName.grouporder, to: qualityAttributeAndFlags.Swap_sequence_QA_Group })
          }
        }

        if (this.tracking.hasOwnProperty(previousElement.attrGroupName.attrid)) {
          const key = previousElement.attrGroupName.attrid
          const ascIndex = this.tracking[key].lastIndexOf('ASC')
          const descIndex = this.tracking[key].lastIndexOf('DESC')
          const sortedValue = ascIndex >= descIndex ? ascIndex : descIndex

          if (sortedValue != -1) {

            if (previousElement.attrGroupName.isChildAscending != this.tracking[key][sortedValue]) {

              this.sequenceTracking('attr', this.tracking[key][sortedValue], previousElement, true)

              groupSort.push({ organizationId: currentElement.attrGroupName.organizationid, oper: qualityAttributeAndFlags.Quality_Attributes, orderby: this.tracking[key][sortedValue], accountid: null, casetype: null, parentId: previousElement.attrList[0].parentid })

              const i = this.qualityAttributeValueForSort.previousValue.findIndex((qa) => qa.attrGroupName.attrid === key);
              for (let index = 0; index < this.qualityAttributeValueForSort.previousValue[i].attrList.length; index++) {
                this.qualityAttributeValueForSort.previousValue[i].attrList[index].attrorder = index + 1
              }

              previousElement = this.qualityAttributeValueForSort.previousValue[index]
            }

            const len = this.tracking[key].length - 1
            if (this.tracking[key][len] === this.tracking[key][sortedValue]) delete this.tracking[key]

            else this.tracking[key] = ['drop']
          }

          if (this.tracking.hasOwnProperty(previousElement.attrGroupName.attrid)) {
            const currentChildElement = this.qualityAttributeValueForSort.currentValue.filter((qa) => qa.attrGroupName.attrid === previousElement.attrGroupName.attrid)[0];

            for (let childIndex = 0; childIndex < previousElement.attrList.length; childIndex++) {
              const previousChildattrElement = previousElement.attrList[childIndex]
              const currentChildattrElement = currentChildElement.attrList[childIndex]

              if (currentChildattrElement.attrid != previousChildattrElement.attrid) {
                attrSort.push({ id: currentChildattrElement.attrid, sequence: previousChildattrElement.attrorder, to: qualityAttributeAndFlags.Swap_sequence_QA_Attribute })
              }
            }
          }
        }
      }

      console.log(groupSort, attrSort)
      this._vitalHttpService.SwapSequence2({ UpdateSequences: attrSort, SortItems: groupSort },this.dbName).subscribe({
        next: (res) => {
          this._snackbar.open('Sequence updated successfully', 'Close')
          this.sequenceEditMode = !this.sequenceEditMode
          this.filtercondition.next({ status: 'all', searchText: '', roles: '', caseStatus: '' })
          this.filterQA()
          this.tracking = {}
        },
        error: (err) => this.handleError(err)
      })
    } catch (error) {
      this.handleError(error)
    }


  }

  updateLocalSequence(from: string) {
    if (from === 'group') {
      for (let index = 0; index < this.qualityAttributeValueForSort.currentValue.length; index++) {
        this.qualityAttributeValueForSort.currentValue[index].attrGroupName.grouporder = index + 1
      }
    }
    else {
      const i = this.qualityAttributeValueForSort.currentValue.findIndex((qa) => qa.attrGroupName.attrid === from);
      for (let index = 0; index < this.qualityAttributeValueForSort.currentValue[i].attrList.length; index++) {
        this.qualityAttributeValueForSort.currentValue[i].attrList[index].attrorder = index + 1
      }
    }
  }

  handleError(err) {
    this.ngxService.stop()
    this._snackbar.open('Something went wrong. Please try again', 'Close')
    console.error(err)
  }

  onHoverShowSort(order: string, value: any) {
    value.attrGroupName.currentChildSortOrder = order
  }

  OnReturnGroup() {
    if (!(this.savedValue.category != this.frmGpName || this.savedValue.grouporder != this.frmGpSquence)) {
      this.qualityAttributeSelectionModel.clear()
      return
    }
    this.openReturnPopup()
  }

  openReturnPopup() {
    const confirmPopUp = this.dialog.openDialog("Alert", `All your changes will be lost. Do you want to proceed?`, "", "Proceed", "Cancel");
    confirmPopUp.afterClosed().subscribe((res) => {
      if (res) this.qualityAttributeSelectionModel.clear()
      else return
    })
  }
  private isRolesChanged() {
    const data = this.selectedAttribute?.rolename?.split(',') || [];
    const initialRoles = new Set(data);
    return !this.RolesSelectionModel.selected.every(role => initialRoles.has(role)) || data.length !== this.RolesSelectionModel.selected.length;
  }

  private isCaseStatusChanged() {
    return this.selectedAttribute.accession != this.CaseStatusSelectionModel.isSelected('Accession') ||
      this.selectedAttribute.grossing != this.CaseStatusSelectionModel.isSelected('Grossing') ||
      this.selectedAttribute.processing != this.CaseStatusSelectionModel.isSelected('Processing') ||
      this.selectedAttribute.diagnosis != this.CaseStatusSelectionModel.isSelected('Diagnosis') ||
      this.selectedAttribute.reports != this.CaseStatusSelectionModel.isSelected('Finalized') ||
      this.selectedAttribute.ishippa != this.ReportTypesSelectionModel.isSelected('HIPPA') ||
      this.selectedAttribute.ismirp != this.ReportTypesSelectionModel.isSelected('MIRP') ||
      this.selectedAttribute.requisition != this.CaseStatusSelectionModel.isSelected('Requisition')

  }
  onAttributeReturn() {
    if (!this.onEditAttributeReturn()) {
      this.qualityAttributeSelectionModel.clear()
      return
    }
    this.openReturnPopup()
  }

  onEditAttributeReturn(): boolean {
    return (this.selectedAttribute.attrname != this.frmname || this.selectedAttribute.attrorder != this.frmSequence || this.selectedAttribute.attrdescription != this.frmDescription || this.selectedAttribute.iscomment != this.frmComments || this.selectedAttribute.isactive.toLowerCase() == 'active' != this.frmIsActive || this.isCaseStatusChanged() || this.isRolesChanged())
  }

  private isCaseStatusChangedAtCreate() {
    return this.CaseStatusSelectionModel.isSelected('Accession') ||
      this.CaseStatusSelectionModel.isSelected('Grossing') ||
      this.CaseStatusSelectionModel.isSelected('Processing') ||
      this.CaseStatusSelectionModel.isSelected('Diagnosis') ||
      this.CaseStatusSelectionModel.isSelected('Finalized') ||
      this.ReportTypesSelectionModel.isSelected('HIPPA') ||
      this.ReportTypesSelectionModel.isSelected('MIRP') ||
      this.CaseStatusSelectionModel.isSelected('Requisition')

  }
  onCreateAttributeReturn() {
    if (this.frmGpName || (this.frmname && this.frmname !='Others') || this.frmSequence || this.frmDescription || this.frmComments || !this.frmIsActive || this.RolesSelectionModel.selected.length != 0 || this.isCaseStatusChangedAtCreate()) {
      this.openReturnPopup()
    }
    else {
      this.qualityAttributeSelectionModel.clear()
      return
    }

  }
  onReset = () => this.openEditAttribute(this.selectedAttribute)

  isFilterEnabledChanged()
  {
    const x = this.filtercondition.value
    return !(x.searchText == '' && x.roles == '' && x.status == 'all' && x.caseStatus == '');
  }
    //Audit
    private getAuditableDetails(location: any) {
      this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe({
        next: (res) => {
          try {
            this.auditableColumns = JSON.parse(res.content.JsonData);
          } catch (error) {
            console.error(error)
          }
        },
        error: (err) => console.error(err)
      })
    }

    private constructObjectForAudit(objectFor : 'Edit Group' | 'Create Group' | 'Edit Attribute' | 'Create Attribute'){
      switch(objectFor){

        case 'Create Group' : return new qualityAttributeGroupAudit('', this.frmGpName, '', true);

        case 'Create Attribute' : return new qualiyAttributeAudit('', this.frmGpName == '' ? this.selectedAttribute?.category : this.frmGpName , '', this.frmIsActive, this.frmname, this.frmDescription, this.RolesSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.CaseStatusSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.ReportTypesSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.frmComments , '')

        case "Edit Attribute" : return new qualiyAttributeAudit(this.selectedAttribute.attrid, this.selectedAttribute.category, this.frmGpSquence, this.frmIsActive, this.frmname, this.frmDescription, this.RolesSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.CaseStatusSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.ReportTypesSelectionModel.selected.filter(i => i != 'All').sort().join(','), this.frmComments ,this.frmSequence)

        case "Edit Group" : return new qualityAttributeGroupAudit(this.selectedAttribute.attrid, this.frmGpName, this.frmGpSquence, this.frmIsActive)

        default : break
      }
    }

    private recordAudit(update : 'Edit Group' | 'Create Group' | 'Edit Attribute' | 'Create Attribute') {

      let sessionId = this.commonService.generateGuid();
      let action : 'Create' | 'Edit'
      let actionPerformedOn : string
      let previousObject : qualiyAttributeAudit | {} = {}
      let entityId : string
      let headingMessage = update.includes('Group') ? 'Quality Attribute Group' : 'Quality Attribute '
      const changedObject = this.constructObjectForAudit(update)

      if(update === 'Create Group' || update === 'Create Attribute'){
        action = 'Create'
        entityId = ''
        actionPerformedOn = update === 'Create Attribute' ? changedObject['attrname'] : changedObject.attrgroupname
      }
      else{
        action = 'Edit'
        if(update === 'Edit Attribute'){
          actionPerformedOn = this.currentAuditObject.attrname
          headingMessage += `under the group ${this.currentAuditObject.attrgroupname}`
        }
        else actionPerformedOn = this.currentAuditObject.attrgroupname
        previousObject = this.currentAuditObject
        entityId = changedObject.attrid
      }

      console.log(this.currentAuditObject, changedObject)
      this.commonService.createActivityObject(entityId, actionPerformedOn, this.templateData.menuURL, action, changedObject, previousObject, sessionId, this.auditableColumns);
    }
}

class qualiyAttributeAudit{

  constructor(
    public attrid : string,
    public attrgroupname : string,
    public groupsequence : string,
    public isactive : boolean,
    public attrname? : string,
    public description? : string,
    public role? : string,
    public casestatus? : string,
    public reporttype? : string,
    public comments? : boolean,
    public attrsequence? : string
    )
    {
      attrgroupname = attrgroupname,
      groupsequence = groupsequence,
      attrname = attrname ?? '',
      description = description ?? '',
      role = role ?? '',
      casestatus= casestatus ?? '',
      reporttype = reporttype ?? '',
      comments = comments,
      attrsequence = attrsequence ?? ''
      attrid = attrid
      isactive = isactive
  }
}

class qualityAttributeGroupAudit{
  constructor(
    public attrid : string,
    public attrgroupname : string,
    public groupsequence : string,
    public isactive : boolean,
    )
    {
      attrgroupname = attrgroupname,
      groupsequence = groupsequence,
      attrid = attrid
      isactive = isactive
  }
}
