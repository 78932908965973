<mat-spinner class="labadmin-spinner" *ngIf="sourceApp == 'VitalDx'  && !templateData"
style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner>
<div class="col-sm-12 m-0 p-0">
    <div *ngIf="gridPage">
        <div class="adequacy-grid">
            <!-- <div class="modal-header admin-model-header mb-2 mt-3">
                <h3 *ngIf="!userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
                    <strong *ngIf="adequacyType.toLowerCase() == 'group'">Group Level Adequacy Codes</strong>
                    <strong *ngIf="adequacyType.toLowerCase() != 'group'">User Level Adequacy Codes</strong>
                </h3>
                <h3 *ngIf="userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>User Level Adequacy Codes </strong>
                </h3>
            </div> -->

            <!-- <div class="row button-wrap"> -->
                <!-- <div *ngIf="!userCardFlag && !ViewScreen" class="col-sm-3 p-0"> -->
                    <!-- <mat-form-field class="col-sm-12 example-additional-selection p-0" appearance="outline">
                        <mat-label>Adequacy Codes</mat-label>
                        <input type="text" #inputAutoComplete matInput [(ngModel)]="selectedOption"
                            #trigger="matAutocompleteTrigger" (input)="filterUsers(selectedOption)"
                            [matAutocomplete]="auto" style="width: auto;" />
                        <div matSuffix style="display:flex">
                            <button (click)="clearInput($event)" *ngIf=" selectedOption!==null && selectedOption!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon>clear</mat-icon>
                            </button>

                            <button (click)="openOrClosePanel($event, trigger)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                            </button>
                        </div>
                        <mat-autocomplete #auto="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                            (opened)="arrowIconSubject.next('arrow_drop_up')"
                            (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                            <div *ngFor="let user of userList">
                                <mat-option class="mat-opt-align"
                                    *ngIf="user.username && user.username.toString().toLowerCase().includes(selectedOption.toLowerCase())"
                                    (onSelectionChange)="onSelectedType($event, user)" [value]="user.username">
                                    {{ user.username }} ({{ user.rolename }})
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field> -->

                    <!-- <span class="col-sm-12 instr-size mb-2">
                        <img aria-hidden="true" alt="help" class="mb-1 mr-2" src="/assets/icons/help.svg" width="15px"
                            height="auto" />
                        You can select the group or user level adequacy code.</span> -->
                <!-- /</div> -->

                <!-- <div *ngIf="userCardFlag && !ViewScreen" class="col-sm-3 p-0">
                    <mat-form-field appearance="outline" class="row col-sm-12 button-wrap mr-0 pr-0">
                        <mat-label>Role</mat-label>
                        <mat-select disableOptionCentering [(ngModel)]="selectedRole">
                            <mat-option (onSelectionChange)="setUserRole($event,user)" value="{{user.RoleName}}"
                                *ngFor="let user of userRoles">
                                {{user.RoleName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            <!-- </div> -->
            <!-- <wj-flex-grid [headersVisibility]="'Column'" class="mt-2" #grid [isReadOnly]="true"
                    (initialized)="initLocationGrid(grid)" [itemsSource]="gridBulkData">
                    <div *ngFor="let columnname of gridHeader">
                        <wj-flex-grid-column *ngIf="!userCardFlag"
                            [visible]="!(columnname == 'ID') && !(columnname == 'UserID') && !(columnname == 'CaseType') && !(columnname == 'CreatedBy') && !(columnname == 'CreatedDate') && !(columnname == 'ModifiedBy') && !(columnname == 'ModifiedDate')"
                            [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="185"
                            [allowResizing]="true" [format]="'d'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column *ngIf="userCardFlag"
                            [visible]="!(columnname == 'ID') && !(columnname == 'UserID') && !(columnname == 'CreatedBy') && !(columnname == 'CreatedDate') && !(columnname == 'ModifiedBy') && !(columnname == 'ModifiedDate')" [binding]="columnname"
                            [header]="columnname.split('_').join(' ')" [width]="185" [allowResizing]="true"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>

                    <wj-flex-grid-column [visible]="userCardFlag == true ? false : true" [header]="'Action'"
                        align="center" [freeze]="true" [width]="130" [isReadOnly]="true">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                            <button [disabled]="editBtn" class="edit-delete-btn"
                                (click)="openEditScreen(grid,$event)"><em id="editButton" [title]="'Edit'"
                                    class="edit-template cursor p-0 fa fa-edit"></em></button>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid> -->
            <!-- <div class="align-center">
                    <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridBulkData">
                    </wj-collection-view-navigator>
                    <wj-menu [(value)]="gridBulkData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                    </wj-menu>
                </div> -->

            <app-templates-listing *ngIf="templateData" [gridHeader]="ListedGridHeader" [gridData]="SendGridData"
                [context]="'Adequacy Codes'" [hideEdit]="editBtn" [hideCreate]="createBtn || userCardFlag"
                [hideUpload]="uploadBtn || userCardFlag" [hideExport]="exportBtn" [hideCopy]="copyBtn"
                (closingListTemplate)="handleListingScreen($event)" (editTemplateClicked)="newEditMethod($event)"
                [hideDelete]="true" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
                [showTopButtons]="true" [selectedListOption]="selectedOption" [userAutoCompleteValue]="inData"
                (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
                [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
                (copyTemplateEventTriggered)="copyOrg()" (uploadTemplateEventTriggered)="loadUploadScreen()"
                (emitFilters)="emitFilters($event)" [isUserLevel]="userCardFlag"
                [caseTypeUserRoles]="userRoles" [selectedRole]="selectedRole" (roleChangedEventTriggered)="roleChanged($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true">
            </app-templates-listing>

            <!-- <div *ngIf="!ViewScreen" class="align-center">
                <div *ngIf="!userCardFlag" class="sub-button mt-4">
                    <button mat-raised-button [disabled]="exportBtn" (click)="ExportGridToExcel(grid)"
                        class="admin-btn-success mr-4">Export</button>
                    <button mat-raised-button [disabled]="createBtn" class="admin-btn-success mr-4"
                        (click)="getInputData('')">
                        Create
                    </button>
                    <button mat-raised-button [disabled]="uploadBtn" (click)="loadUploadScreen()"
                        class="admin-btn-success mr-4">
                        Upload
                    </button>
                    <button mat-raised-button class="admin-btn-success" [disabled]="copyBtn"
                        title="Copy from other entity" (click)="copyOrg()">Copy</button>
                </div>
                <div *ngIf="userCardFlag" class="sub-button mt-4">
                    <button mat-raised-button [disabled]="exportBtn" (click)="ExportGridToExcel(grid)"
                        class="admin-btn-success mr-4">Export</button>
                    <button mat-raised-button class="admin-btn-success" [disabled]="copyBtn"
                        title="Copy from other entity" (click)="copyOrg()">Copy</button>
                </div>
            </div> -->

            <!-- <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridwidth == 0">
                <div class="col-sm-10 nodata-wrap">
                    <div class="nodata-design">No Data Found</div>
                </div>
            </div> -->
        </div>

        <!-- <div class="align-center mt-4" *ngIf="gridwidth == 0">
            <div *ngIf="!userCardFlag" class="sub-button">
                <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4"
                    (click)="getInputData('')">
                    Create
                </button>
                <button [disabled]="uploadBtn" mat-raised-button (click)="loadUploadScreen()"
                    class="admin-btn-success mr-4">
                    Upload
                </button>
                <button mat-raised-button class="admin-btn-success" title="Copy from Other Entity" [disabled]="copyBtn"
                    title="Copy from other entity" (click)="copyOrg()">Copy</button>
            </div>
            <div *ngIf="userCardFlag" class="sub-button">
                <button mat-raised-button class="admin-btn-success cursor" title="Copy from Other Entity"
                    [disabled]="copyBtn" title="Copy from other entity" (click)="copyOrg()">Copy</button>
            </div>
        </div> -->
    </div>

    <div *ngIf="uploadClicked" class="my-adequacy-form">
        <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View" *ngIf="adequacyType.toLowerCase() == 'group'">Upload Group Level Adequacy Codes</strong>
                <strong class="header-View" *ngIf="adequacyType.toLowerCase() != 'group'">Upload User Level Adequacy Codes</strong>
            </h3>
        </div>

        <div class="button-field-wrap m-1 p-0">
            <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
                <mat-label class="d-flex">
                    Templates
                </mat-label>
                <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
                <mat-select disableOptionCentering class="ml-2">
                    <mat-option class="temp-font-size" (click)="downloadMadatoryFields()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal
                        Fields
                    </mat-option>
                    <mat-option class="temp-font-size" (click)="downloadAllFields()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>All
                        Fields
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="button-field-wrap">
            <span class="instr-size mb-2">
                <img aria-hidden="true" alt="help" class="mb-1 mr-1" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                You can download the sample template file to upload the Adequacy Codes.</span>
        </div>

        <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
            <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
                <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                    (change)="onFileChange($event)" hidden />
                <span class="align-center img-wrap">
                    <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
                </span>
                <span>Drag & Drop excel files here</span>
                <span class="align-center">Or</span>
                <label class="btn btn-sm rounded admin-btn-success mt-2 browse ml-5" for="fileDropRef">Browse
                    file</label>
            </div>
            <button mat-raised-button class="admin-btn-success mt-4" (click)="adequacyType = 'Group'; refreshGrid(true)">
                Back
            </button>
        </div>

        <div *ngIf="gridWidth > 0">
            <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1 (initialized)="initLocationGrid(grid1)"
                [headersVisibility]="'Column'" (formatItem)="formateItem(grid1, $event)">
                <!-- Status -->
                <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                    [width]="'*'" [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>

                <!-- Notes -->
                <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" *ngIf="!postUpload" [allowResizing]="true"
                    [width]="320" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span
                            [ngStyle]="{color: (cell.item.notes == 'Valid') ? 'green' : cell.item.notes=='Display Name already exist!' ? '#ff9800' : 'red'}">{{cell.item.notes}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column> -->

                <!-- <div> -->
                <wj-flex-grid-column *ngFor="let columnname of sheetHeader"
                    [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="'*'" [allowDragging]="false"
                    [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
                <!-- Action -->
                <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="'*'"
                    [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton"
                                [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                    </ng-template>
                </wj-flex-grid-column>
                <!-- </div> -->
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>

            <div class="row pl-3 mt-4">
                <div class="col-sm-2 p-0">
                    <button mat-raised-button class="admin-btn-success" (click)="removeGrid()">
                        Back
                    </button>
                </div>
                <div class="col-sm-10 button-field-wrap p-0">
                    <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                        <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                            (click)="adequacyType = 'Group'; refreshGrid(true)">Finish</button>
                        <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                            (click)="adequacyType = 'Group'; removeGrid()">Cancel</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()"
                            class="admin-btn-success mr-4" (click)="uploadData()">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(grid1)">Download
                            Result</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
            <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
        </div>
    </div>

    <!-- Create/Edit Adequacy Codes -->
    <!-- <div *ngIf="addEditScreen">
        <form [formGroup]="adequacyForm" class="adequacyForm">
            <div class="row col-sm-12 p-0 m-0">
                <div class="col-sm-12 modal-header admin-model-header mt-3">
                    <h3 class="modal-title w-100 admin-model-header-txt main-title">
                        <strong *ngIf="editEnabled">Edit {{addHeader}} Adequacy Code</strong>
                        <strong *ngIf="!editEnabled">Create {{addHeader}} Adequacy Code</strong>
                    </h3>
                </div>
                <div class="text-wraps row col-sm-12 p-0 ml-0">
                    <div class="col-sm-12 mt-2">
                        <mat-form-field class="width col-sm-3" appearance="outline">
                            <mat-label>
                                <strong><span class="text-danger">*</span>
                                    Name</strong>
                            </mat-label>
                            <input trim type="text" formControlName="frmname"
                                (change)="setDisplayName($event.target.value)" maxlength="2000" matInput
                                aria-label="Name" [matAutocomplete]="auto" [matAutocompleteDisabled]="!auto" />
                            <mat-error>{{getErrorMessage(adequacyForm.value.frmname,'')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 col-sm-3">
                            <mat-label>
                                <strong><span class="text-danger">*</span>
                                    Display Name</strong>
                            </mat-label>
                            <input trim type="text" formControlName="frmdisplayname" maxlength="2000" matInput
                                aria-label="DisplayName" [matAutocomplete]="auto" [matAutocompleteDisabled]="!auto">
                            <mat-error>{{getErrorMessage(adequacyForm.value.frmdisplayname,'')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-100 col-sm-3" appearance="outline">
                            <mat-label>
                                <strong>Sequence Order</strong>
                            </mat-label>
                            <input type="text" formControlName="frmsequanceorder" maxlength="15" pattern="[0-9]+"
                                matInput aria-label="SequenceOrder">
                            <mat-error>{{getErrorMessage(adequacyForm.value.frmsequanceorder,'sequence')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="adequacyType.toLowerCase() != 'group'" appearance="outline"
                            class="w-100 col-sm-3">
                            <mat-label> <strong><span class="text-danger">*</span> User</strong></mat-label>
                            <em class="fa fa-chevron-down chevron-align"></em>
                            <input trim type="text" formControlName="frmusername" maxlength="200" matInput
                                aria-label="UserName" [matAutocomplete]="auto">
                            <mat-error>{{getErrorMessage(adequacyForm.value.frmusername,'')}}
                            </mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option class="mat-opt-align"
                                    *ngFor="let user of filterFormUsers(adequacyForm.value.frmusername)"
                                    (onSelectionChange)="onSelectedUser($event, user)" value="{{ user.username }}">
                                    <span>{{ user.username }} ({{ user.rolename }})</span>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 d-flex mt-2 mb-2">
                        <mat-form-field class="w-100 col-sm-8" appearance="outline">
                            <mat-label>
                                <strong> <span class="text-danger">*</span>Description</strong>
                            </mat-label>
                            <textarea trim matInput formControlName="frmdescription" maxlength="2000"> </textarea>
                            <mat-error>{{getErrorMessage(adequacyForm.value.frmdescription,'')}}
                            </mat-error>
                        </mat-form-field>

                        <section class="example-section col-sm-2 mt-2">
                            <mat-checkbox [color]="task.color" class="mt-2 example-margin"
                                formControlName="frmabnormal">Abnormal
                            </mat-checkbox>
                        </section>
                        <section class="example-section col-sm-2 mt-2">
                            <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmstatus">
                                Active</mat-checkbox>
                        </section>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-sm-3">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid(true)">
                        Back
                    </button>
                </div>
                <div class="col-sm-9 button-wrap">
                    <button mat-raised-button class="admin-btn-success mr-4"
                        (click)="saveAdequacyCodes('',adequacyForm.value)">
                        Save & Close
                    </button>
                    <button *ngIf="!editEnabled" mat-raised-button class="admin-btn-success"
                        (click)="saveAdequacyCodes('new',adequacyForm.value)">
                        Save & Create
                    </button>
                </div>
            </div>
        </form>
    </div> -->

    <div *ngIf="copyDataClicked" class="copyform-adequacycodes">
        <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
            <h3 *ngIf="!userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View" *ngIf="adequacyType.toLowerCase() == 'group'">Copy Group Level Adequacy Codes</strong>
                <strong class="header-View" *ngIf="adequacyType.toLowerCase() != 'group'">Copy User Level Adequacy Codes</strong>
            </h3>
            <h3 *ngIf="userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View">Copy User Level Adequacy Codes</strong>
            </h3>
        </div>
        <form [formGroup]="copyEntityForm" (keydown.enter)="$event.preventDefault()" class="copy-collection">
            <div class="row col-sm-12 p-0 ml-0 mt-4">
                <div class="row col-sm-12 mt-2 align-center">

                    <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                        <mat-label><span class="error-msg">*</span>Deployment</mat-label>
                        <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                            <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                                (onSelectionChange)="onChangeDeployment($event,i)">
                                <span>{{ i }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                        <mat-label><span class="error-msg">*</span>Group</mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>
                        <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                            [disabled]="disableOrgSearch" #searchbar
                            (keyup)="fetchOrgSeries(copyEntityForm.value.frmOrganization)" [matAutocomplete]="orgauto"
                            placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #orgauto="matAutocomplete">
                            <div *ngFor="let show of searchResult">
                                <mat-option class="mat-opt-align"
                                    *ngIf="show.organizationid.toString().includes(searchInput.toString()) || show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                                    (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                    value="{{ show.organizationname }}"
                                    title="{{ show.organizationname }} ({{show.organizationid}})">
                                    <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>

                        <button *ngIf="copyEntityForm.value.frmOrganization" matSuffix mat-icon-button
                            aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                        <mat-label>User</mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>
                        <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput"
                            #searchuserbar (keyup)="fetchUserSeries(searchuserbar.value)" [matAutocomplete]="userauto"
                            placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #userauto="matAutocomplete">
                            <div *ngFor="let user of searchuserlist">
                                <mat-option class="mat-opt-align"
                                    *ngIf="user.username.toLowerCase().includes(searchUserInput.toLowerCase())"
                                    (onSelectionChange)="selectedCopyDataUser($event, user)" value="{{ user.username }}"
                                    title="{{ user.username }} ({{user.rolename}})">
                                    <span>{{ user.username }} ({{user.rolename}})</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>

                        <button *ngIf="copyEntityForm.value.frmUser" matSuffix mat-icon-button aria-label="Clear"
                            title="Reset Search" (click)="clearUser()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                </div>
            </div>
            <div class="row align-center mt-2">
                <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn"> <button *ngIf="userCardFlag"
                        mat-raised-button class="admin-btn-success mr-4" (click)="refreshUserGrid()">
                        Back
                    </button>
                    <button *ngIf="!userCardFlag" mat-raised-button class="admin-btn-success mr-4"
                        (click)="adequacyType = 'Group'; refreshGrid(true)">
                        Back
                    </button>
                </div>
                <div class="dml-btn">
                    <button mat-raised-button class="admin-btn-success" [disabled]="disableGetDataBtn() || postUpload"
                        (click)="getDataToCopy()">Get
                        Data</button>
                </div>
            </div>
        </form>
        <div *ngIf="gridWidth > 0">
            <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
                [itemsSource]="gridData" style="margin-top: 17px;" (formatItem)="formateItem(flexgrid, $event)">
                <!-- Status -->
                <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                    [width]="120" [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>

                <!-- Notes -->
                <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" *ngIf="!postUpload" [allowResizing]="true"
                    [width]="320" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span
                            [ngStyle]="{color: (cell.item.notes == 'Valid') ? 'green' : cell.item.notes=='Display Name already exist!' ? '#ff9800' : 'red'}">{{cell.item.notes}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column> -->
                <div *ngFor="let columnname of sheetHeader">
                    <wj-flex-grid-column *ngIf="!userCardFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'"
                        [visible]="!(columnname == 'Status') && !(columnname.toLowerCase()=='userid') && !(columnname.toLowerCase()=='id') && !(columnname.toLowerCase()=='casetype') && !(columnname.toLowerCase()=='sequence')"
                        [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="userCardFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'"
                        [visible]="!(columnname == 'Status') && !(columnname.toLowerCase()=='userid') && !(columnname.toLowerCase()=='id') && !(columnname.toLowerCase()=='sequence')"
                        [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                </div>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            <br />
            <div *ngIf="!backBtn && !postUpload" class="row pl-3 mt-2 mb-2 ml-5 align-center">
                <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0"
                    ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                    (change)='checkValue($event)' class="border-style">
                <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
            </div>
            <div class="row align-center mt-2">
                <div *ngIf="!backBtn" class="dml-btn mr-4">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="ExportExcel(flexgrid)">Download
                        Result</button>
                    <button *ngIf="userCardFlag && postUpload" mat-raised-button class="admin-btn-success"
                        (click)="refreshUserGrid()">
                        Finish
                    </button>
                    <button *ngIf="!userCardFlag && postUpload" mat-raised-button class="admin-btn-success"
                        (click)="refreshGrid(true)">
                        Finish
                    </button>
                </div>
                <div *ngIf="!postUpload" class="dml-btn">
                    <button mat-raised-button class="admin-btn-success" [disabled]="disableApprovebtn()"
                        (click)="uploadCopyData(flexgrid)">Approve</button>
                </div>
            </div>
        </div>
        <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
            <div class="col-sm-10 nodata-wrap">
                <div class="nodata-design">No Data Found</div>
            </div>
        </div>
    </div>
</div>


<div class="modal-header admin-model-header mb-2 mt-1" *ngIf="noRole">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>User Level Adequacy Codes </strong>
    </h3>
</div>

<div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noRole">
    <div class="col-sm-10 nodata-wrap">
        <div class="nodata-design">Eligible role not found for user</div>
    </div>
</div>


<!-- warning popup -->
<div *ngIf="invalidCasetypeFlag" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                    <span type="button" id="button_size" data-dismiss="modal" (click)="closeCasetypeModel()"
                        class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                            class="material-icons md-24 cursor" title="Close">
                    </span>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message">
                        <p id="deleteuser"> The Adequacy Codes
                            cannot be copied as the casetype is not enabled in destination Group.
                            Please enable the casetype and then try copying<br>Failed for Casetype :
                            {{repeatedNames}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm warning-model-header" (click)="closeCasetypeModel()"
                        type="button">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
