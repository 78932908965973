<div *ngIf="showIgnore">
    <h2 mat-dialog-title>Keys Ignored:</h2>
    <div mat-dialog-content style="font-size: medium;">The following keys are refrained from being compared.
        <!-- <p>
            {{ignoredList}}
        </p> -->
        <br /><br />
        <ul style="list-style-type:circle;">
            <li *ngFor="let item of ignoredKeysArr">{{item}}</li>
        </ul>
    </div>
    <div mat-dialog-actions style="display: table;width: 100%;">
        <div style="display: table-cell; vertical-align: middle;">
            <button mat-button mat-dialog-close class="d-block m-auto" style="background-color: #e5e5e5;">Close</button>
        </div>
    </div>
</div>
<div *ngIf="!showIgnore">
    <button id="closeblock" mat-button class="d-block m-auto"
        style="float: right; font-size: xx-large; position: relative; top: -25x; right: -20px" mat-dialog-close>
        &times;
    </button>
    <ng-container *ngFor="let menu of Block_level_menu">
        <button (click)="findDiff($event.target.innerText)" class="menuButtonBlockLevel">
            {{menu}}
        </button>
        <mat-divider style="border-color: white;"></mat-divider>
    </ng-container>
    <!-- <div mat-dialog-actions style="display: table;width: 100%;">
        <div style="display: table-cell; vertical-align: middle;">
            <button id="closeblock" mat-button class="d-block m-auto"
                 style="background-color: #e5e5e5;" [mat-dialog-close]="data.menu">
                Close
            </button>
            {{data.menu}}
        </div>
    </div> -->
</div>