<div class="mainDiv">
  <h2 mat-dialog-title class="row toggle-table-header d-flex align-center">
    <span>{{data.title}}</span>
    <!-- <mat-icon class="closeBtn float-right d-flex pt-1" (click)="closeDialog()">close</mat-icon> -->
  </h2>
<div mat-dialog-content class="tableDiv">
  <table mat-table [dataSource]="dataSource" >

    <!-- <ng-container matColumnDef="slNo">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.slNo}} </td>
    </ng-container> -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef class="dropdown-opt-wrap"> <input [formControl]="userFilter" id="user" mat-input placeholder="User"> </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>
    <ng-container matColumnDef="rolename">
      <th mat-header-cell *matHeaderCellDef class="dropdown-opt-wrap"> <input [formControl]="roleFilter" id="role" mat-input placeholder="Role"> </th>
      <td mat-cell *matCellDef="let element"> {{element.rolename}} </td>
    </ng-container>
    <ng-container matColumnDef="Allowuserlvl">
      <th mat-header-cell *matHeaderCellDef class="dropdown-opt-wrap text-color"> <span class="ml-4">Template</span><br>(Organization/User) </th>
      <td mat-cell *matCellDef="let element" class="pl-4 pt-3">
        <label class="toggle">
          <input type="checkbox" [disabled]="element.disabletoggle" [(ngModel)]="element.Allowuserlvl" (change)="OntogglechangeOrgToUser($event, element, 'userlvl')">
          <span class="slider"></span>
          <span class="labels" data-on="User" data-off="Org"></span>
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="Allowedit">
      <th mat-header-cell *matHeaderCellDef class="dropdown-opt-wrap text-color"> Permission<br>(View/Edit) </th>
      <td mat-cell *matCellDef="let element" class="pl-2 pt-3">
        <label class="toggle">
          <input type="checkbox" [disabled]="element.disabletoggle || element.Allowuserlvl" [(ngModel)]="element.Allowedit" (change)="OntogglechangeOrgToUser($event, element, 'editlvl')">
          <span class="slider"></span>
          <span class="labels" data-on="Edit" data-off="View"></span>
        </label>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="color: gray;"></tr>
  </table>
      <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator> -->
</div>
<div mat-dialog-actions class="float-right mb-2 mr-2">
  <button mat-raised-button (click)="savetoggleconfiguration('edit')" class="admin-btn-success saveBtn">Save</button>
  <button mat-raised-button (click)="savetoggleconfiguration('close')" class="admin-btn-success saveBtn">Save & Close</button>
  <button mat-raised-button class="mr-2" (click)="closeDialog()" class="admin-btn-success saveBtn">Return</button>
</div>
</div>
