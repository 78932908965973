import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalAdminTabService } from '../../tab.service';
import { VitalHttpServices } from './../../../core/services/VitalHttpServices';

@Component({
  selector: 'hgrid-container',
  templateUrl: './hgridcontainer.component.html',
  styleUrls: ['../GridContainer/gridcontainer.component.scss']
})
export class HGridContainerComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  UserList = []
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  @ViewChild("flexgrid") flexgrid: FlexGrid;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideExportBtn: boolean = true;
  // public actionButtonDetails: any[];
  constructor(public vitalHttpServices:VitalHttpServices, private injectorServicehHgrid: InjectorService, private datashare: DataShareService, public tabService: VitalAdminTabService, public CommonService: CommonService) {
    super(injectorServicehHgrid);
  }

  GetButtondetails() {
    // this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.datashare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // })
    let casebutton=this.vitalHttpServices.CasemenuAction;
    if(!casebutton)
    {
      this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    }
    else
    {
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  initializeGrid(flexgrid: FlexGrid) {
    this.GetButtondetails();
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid)
        this.flexgrid.refresh(true);
    });
    flexgrid.formatItem.addHandler((s: any, e: wjcGrid.FormatItemEventArgs) => {

      // center-align column headers
      if (e.panel == s.columnHeaders) {
        e.cell.innerHTML = '<div class="v-center">' +
          e.cell.innerHTML + '</div>';
      }

      // custom rendering for "Diff" columns
      if (e.panel == s.cells) {

        let col = s.columns[e.col];
        let item;
        let html = "";
        if (e.row > 0 && (col.binding == 'ICD_Codes')) {
          item = e.cell.textContent;
          let item1 = e.cell.textContent.split(";")[0];
          let item2 = e.cell.textContent.split(";")[1];
          if (item2 === "Yes") {
            html = `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> ` +
              `<em class="fa fa-info-circle text-primary pull-right text-md" title="ICD codes configured for Sub-site, Site location"    style="cursor:pointer;" aria-hidden="true"  ></em>`;
          } else {
            html = `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> `
          }

          // html = html.replace('{{e.cell.textContent}}', wjcCore.Globalize.format(diff, col.format));
          e.cell.innerHTML = html;
        }
      }
    });
  }


  AddGridData() {
    let gridarray = []
    let primary = {}
    // if (this.templateData.submenuData.length > 0) {
    //   for (let i = 0; i < this.templateData.submenuData.length; i++) {
    //     primary = {}
    //     for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
    //       for (let j = 0; j < this.columnDefs.length; j++) {
    //         if (key === this.columnDefs[j]) {
    //           primary[key] = value
    //         }
    //       }
    //     }
    //     gridarray.push(primary)
    //   }
    // }
    this.gridData = new CollectionView(this.templateData.submenuData, { groupDescriptions: [this.templateData.GroupData.GroupBy] })
    this.gridwidth = (170 * (this.columnDefs.length - 1));
    if (this.gridwidth > 1300)
      this.gridwidth = 1300;
    if (this.gridwidth != 0) {
      this.gridwidth = this.getpercentage(this.gridwidth)
    }
    // this.gridwidth = (170 * (this.columnDefs.length - 1)) + 43;
    // if (this.gridwidth > 1300){
    //   this.gridwidth = 1300;
    // }

    // if (this.flexgrid) {
    //   this.flexgrid.collapseGroupsToLevel(0);
    //   this.flexgrid.refresh(true);
    // }
    setTimeout(function () {
      if (this.flexgrid) {
        this.flexgrid.collapseGroupsToLevel(0);
        this.flexgrid.refresh(true);
      }
    });
    if (this.templateData.submenuData.submenuData1 !== undefined) {
      if (this.templateData.submenuData.submenuData1.length > 0) {
        this.UserList = this.templateData.submenuData.submenuData1
      }
    } else {
      this.UserList = []
    }
  }

  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width - 150;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 5
    }
    return per;
  }
  AddTab(user: any,) {

    let tab: any = {};
    // this accountid is actually used to  populate the data for user(userid)
    tab.accountid = user.UserID;
    tab.keyword = "Users";
    tab.cardtype = "Users";
    tab.text = user.Username;
    tab.Removable = true;
    this.tabService.addNewOrSelectTab(tab);
  }

  ExportExcel(flex) {
    let url:any = this.templateData.menuURL;
    let id:any = this.templateData.secondarykeys.OrganizationId;
    let filename = this.CommonService.getExcelFileName(url,id)
    this.CommonService.ExportToExcel(flex,filename);
  }
}
