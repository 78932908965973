import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from '../../../core/services/datashare.service';
import { InjectorService } from '../../../core/services/Injectorservices';
import { SettingsTemplateContainerComponent } from '../SettingsTemplateContainer/settingstemplatecontainer.component';

@Component({
  selector: 'materialtemplate-container',
  templateUrl: './materialtemplatecontainer.component.html',
  styleUrls: ['../SettingsTemplateContainer/settingstemplatecontainer.component.scss']
})
export class MaterialTemplateContainerComponent extends SettingsTemplateContainerComponent implements OnInit, OnChanges {
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  public template: string = '';
  @Input()
  public submenuData: any;
  @Input()
  labels: any;
  @Input()
  public GroupData: any = {};

  @Input()
  templateData: any;


  public groupByKey: string = "";

  public subHeader: string = "";
  templateGroupedData: any;
  templateGroupedDataKeys: string[];
  public templateLables: any;
  public settingsQuery: string;
  subDataList: any;
  labelFirstCount: number = 0;
  firstInstance: boolean;

  constructor( private injectorServiceMat: InjectorService,private datashare: DataShareService,public ngxService:NgxUiLoaderService,public _snackbar:MatSnackBar) {
    super(injectorServiceMat,ngxService,_snackbar);}

  GetSettingsQuery() {
    let query: string = "query($accid: String,  $casetype  :  String)  { ";
    let index: number = 0;
    for (index = 0; index < this.templateData.submenuData.length; index++) {
      query = query + this.templateData.submenuData[index].Querytxt + " ";
    }
    query = query + " }";

    let queryVariable: any = {};
    queryVariable.accid = this.templateData.cardIdentifier.toString();
    if (this.templateData.GroupData && this.templateData.GroupData.casetype)
      queryVariable.casetype = this.templateData.GroupData.casetype.toString();

    let queryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };

    return queryRequest;
  }


  GetSubDataResultList(template: any) {
    if (this.subDataListModel.length > 0) {

      let index: any = 0;
      index = this.subDataList.findIndex(model => model.key.toString() === template.split(' ').join('_'));
      if (index > -1)
        return this.subDataListModel[index].value;
      else
        return 0;
    }
    else
      return 0;
  }


  GetSubDataListItem(template: any,index: any) {
    template = template.split(' ').join('_');
    if (this.subDataResultList !== undefined) {
      let index: any = 0;
      if (this.subDataResultList[template]) {
        if (this.subDataResultList[template].length == 0) {
          return 0;
        } else {
          if(this.subDataResultList[template].length>0){
            var tempvalu = this.subDataResultList[template][0];
            let valueResp = template.split('_').join('');
            if (tempvalu[valueResp] === "true") {
              return true
            } else if ( tempvalu[valueResp] === "false") {
              return false
            }
            else{
              return tempvalu[valueResp];
            }

          }
        }
      } else {
        return 0;
      }
    }
    else
      return 0;
  }


  IsValidSubDataResultListItem(rowsubHeader: any, subHeader: any) {
    let valid: boolean = false;
    if (this.subDataResultList.length > 0) {
      if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')])
        valid = true;
    }
    return valid;
  }

  IsCheckedSubDataResultListItem(rowsubHeader: any, subHeader: any) {
    let valid: boolean = false;
    if (this.subDataResultList.length > 0) {
      if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')]) {
        if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')][0].Status.trim() == 'Active')
          valid = true;
      }
    }
    return valid;
  }

  AddSubDataList(template: any) {
    {
      let key: string = "";
      let arr: any = {};
      if (this.subDataResultList[template.split(' ').join('_')][0][template.split(' ').join('')]) {
        key = template.split(' ').join('_');
        arr = { key: key, value: this.subDataResultList[template.split(' ').join('_')][0][template.split(' ').join('')] };
        this.subDataList.push(arr);
      }
      else {
        key = template.split(' ').join('_');
        arr = { key: key, value: 0 };
        this.subDataList.push(arr);
      }
    }
  }
  UpdateSaveButton(materialTableData, template, inputValue) {
    let parseColumnNames = JSON.parse(materialTableData.ColumnName);
    let updatefileds = {};
    var upcol = parseColumnNames.UpdateColumns.split(',');
    upcol.forEach(element => {
      updatefileds[element] = inputValue.toString();
    });
    let colval = this.GetUpdateID(template, parseColumnNames.IDColumnName).toString();
    if (colval != 0) {
      let query = {
        inputdata: {
          TableName: materialTableData.TableName,
          ColumnName: parseColumnNames.IDColumnName,
          ColumnValue: colval
        },
        dynamic: {
          Dynamic: JSON.stringify(updatefileds)
        }
      }
      let finalquery = this.commonservice.GetUpdateQuery(query);
      this.ngxService.start();
      this.injectorService.vitalHttpServices.GetData(finalquery).subscribe(result => {
        if (!result.errors) {
          this.ngxService.stop();
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
        console.error(error);
      });
    }//end of if
  }

  GetUpdateID(template, idColname) {
    let utemplate = template.split(' ').join('_');
    if (this.subDataResultList != undefined) {
      if (this.subDataResultList[utemplate]) {
        if (this.subDataResultList[utemplate].length != 0) {
          return this.subDataResultList[utemplate][0][idColname];
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else { return 0 }
  }

}
