import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateHeadersService {
  templateList = [{
    templateName: "Case Comments",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Sites', type: 'input', key: 'SiteName', colWidth: 'col-sm-1' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-5' }
      , { Name: 'User', type: 'text', key: 'User', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "Grossing template",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Sites', type: 'input', key: 'SiteName', colWidth: 'col-sm-1' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Rejection template",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'AttributeName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Screening Comments",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "RevisionNotes",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "RevisionReasons",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "AdequacyCodes",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Adequacy Code', type: 'input', key: 'Name', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'text', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "MicroscopicCodes",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Microscopic Code', type: 'input', key: 'Name', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'text', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "DeletionTemplates",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Categories",
    headers: [
      { Name: 'Category Type', type: 'input', key: 'CategoryType', colWidth: 'col-sm-3' }
      , { Name: 'Category Name', type: 'input', key: 'CategoryName', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "DiagnosisReferences",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "CustomAbbreviations",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'caseType', colWidth: 'col-sm-3' },
      { Name: 'Abbreviation', type: 'input', key: 'Abbreviation', colWidth: 'col-sm-3' },
      { Name: 'Phrase', type: 'input', key: 'Phrase', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "UserDefaultRoles",
    headers: [
      { Name: 'Email', type: 'input', key: 'UserEmail', colWidth: 'col-sm-3' }
      , { Name: 'Role Name', type: 'input', key: 'RoleName', colWidth: 'col-sm-3' }
      , { Name: 'Deployment', type: 'input', key: 'DeploymentKey', colWidth: 'col-sm-2' }
      , { Name: 'Entity', type: 'input', key: 'Entity', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "SequesterReasons",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Correlation Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'AttributeValue', colWidth: 'col-sm-3' }
      , { Name: 'Summary', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-4' }
      // , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Tubetype Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'AttributeValue', colWidth: 'col-sm-3' }
      , { Name: 'Summary', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-4' }
      // , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Nomenclature Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "AutoAssignmentRule-path",
    headers: [
      { Name: 'Pathologist', type: 'input', key: 'Name', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Facility', type: 'input', key: 'OrderingFacility', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Physician', type: 'input', key: 'OrderingPhysician', colWidth: 'col-sm-2' }
      , { Name: 'Casetype', type: 'input', key: 'CaseType', colWidth: 'col-sm-1' }
      , { Name: 'Effective Date', type: 'input', key: 'EffectiveDate', colWidth: 'col-sm-2' }
      , { Name: 'Expiry Date', type: 'input', key: 'DisplayExpiryDate', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "AutoAssignmentRule-cyto",
    headers: [
      { Name: 'Cytotechnologist', type: 'input', key: 'Name', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Facility', type: 'input', key: 'OrderingFacility', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Physician', type: 'input', key: 'OrderingPhysician', colWidth: 'col-sm-2' }
      , { Name: 'Casetype', type: 'input', key: 'CaseType', colWidth: 'col-sm-1' }
      , { Name: 'Effective Date', type: 'input', key: 'EffectiveDate', colWidth: 'col-sm-2' }
      , { Name: 'Expiry Date', type: 'input', key: 'DisplayExpiryDate', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "Sendout Reasons",
    headers: [
       { Name: 'Reason Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-4' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-6' }
    ]
  },
  // ,
  // {
  //   templateName: "Pathologist Privileges",
  //   headers: [
  //     { Name: 'Pathologist Name', type: 'text', key: 'PathologistName', colWidth: 'col-sm-3' }
  //     , { Name: 'AllowEditingUserTemplates', type: 'text', key: 'AllowEditingUserTemplates', colWidth: 'col-sm-3' }
  //     , { Name: 'Subject Matter Expert', type: 'text', key: 'EditprivilegesforCaseTypes', colWidth: 'col-sm-4' }
  //   ]
  // }

  ]
  constructor() { }
}
