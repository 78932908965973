import { createReducer, on } from '@ngrx/store';
import { decrement, addNabvTab } from '../actions/navbar.actions';

export const initialState:any = [];

export const BredCrumReducer = createReducer(
  initialState,
  on(decrement, (state,action) => state - 1),
  on(addNabvTab, (state,{value}) => [...changeTabData(state,value)])
);


function changeTabData(data,value){
  let updateData = [...data];
  if(updateData.length>0){
   updateData= updateData.slice(0,value.Level);
    updateData[value.Level] = value
  }else{
    updateData = [...updateData, value];
  }
  return updateData;
}
