<div class="input-container">
<div class="input-elements">
    <input #ref  type="text" [placeholder]="placeholder" readonly="true"
    [class.cform-in-valid]="!isFormValid" 
     [value]="selectedDisplay(selectionModel.selected)"  cdkOverlayOrigin #trigger="cdkOverlayOrigin" 
     (focus)="[isOpen=!isOpen,toggle()]" 
    [disabled]="isDisabled" />
    <label>{{labelValue}}
      <ng-container *ngIf="required">
          <span class="text-danger fw-bold fs-6">*</span>
      </ng-container>
  </label>
  <div class="drop-icon" (click)="[isOpen=!isOpen,toggle()]">
    <img #dropdown [class.cursor-not-allowed]="isDisabled" class="cusor-pointer" src="../../../assets/images/expand.png" alt="expand">
  </div>

</div>
</div>
  <ng-template  
      cdkConnectedOverlay  
      [cdkConnectedOverlayWidth]="contentwidth"
      [cdkConnectedOverlayOrigin]="trigger"  
      [cdkConnectedOverlayHasBackdrop]="true"  
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayOpen]="isOpen" 
      (backdropClick)="[isOpen=false]" >
      <div class="dropdown-style">
        <mat-checkbox color="primary" class="custom-matcheckbox" *ngIf="listData.length>1"
          [checked]="selectionModel.hasValue()&&isAllItemsSelected()" (click)="$event.stopPropagation()"
          (change)="$event?[toggleAllItems(),focusOut(ref)]:null">
          Select All</mat-checkbox>
        <mat-checkbox color="primary" class="custom-matcheckbox" *ngFor="let i of listData" (click)="[selectionModel.toggle(i),focusOut(ref)]"
          [checked]="selectionModel.isSelected(i)">{{i}}</mat-checkbox>
      </div>
    </ng-template>
