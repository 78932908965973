<!-- <div *ngIf="templateData && templateData.labels && gridwidth > 0">
  <div *ngIf="UserList.length>0" class="mb-2">
    <strong class="mr-2">User Case Comments:</strong>
    <a href="javascript:;" (click)="AddTab(item,'user')"
      style="margin-right: 1%;color: blue;text-decoration: underline;"
      *ngFor="let item of UserList">{{item.Username}},</a>
  </div>
  <strong *ngIf="UserList.length>0" style="display: block;">Group Case Comments:</strong> -->
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 p-0">

      <!------------ VitalDesktop Grid--------------------- -->
      <div *ngIf="gridDisplay">
        <div class="modal-header admin-model-header">
          <h3 class="modal-title w-100 admin-model-header-txt"><strong>VitalDesktop Installations</strong></h3>
        </div>
        <div *ngIf="templateData && templateData.labels && gridwidth > 0" class="modal-content bordernone">
          <wj-flex-grid (initialized)="initGrid(gridguid)" [headersVisibility]="'Column'" [isReadOnly]="true"
            (click)="onClick(gridguid, $event)" [itemsSource]="gridData" class="mt-3" [itemFormatter]="formatItem"
            #gridguid>
            <wj-flex-grid-column [width]="200" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
              [header]="col.header" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
          </wj-flex-grid>
          <div class="alignText">
            <wj-collection-view-navigator *ngIf="gridarray.length > 10" class="mt-2"
              headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
          </div>
          <div class="alignText">
            <br />
            <div class="sub-button mt-4">
              <button (click)="ExportExcel(gridguid)" mat-raised-button class="admin-btn-success mr-4"
                [disabled]="export">Export</button>
              <button (click)="getVitalDesktopLocDetails()" mat-raised-button class="admin-btn-success"
                [disabled]="Create">Create</button>
            </div>
          </div>
        </div>
      </div>


      <!---------------VitalDesktop View Details----------------->
      <div *ngIf="ShowGrid" class="modal-content bordernone">
        <form [formGroup]="recipientData" id="form-data">
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt"><strong>{{this.VDName}}</strong></h3>
          </div>
          <div class="mt-3 _scroll">

            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span scope="row" class="word-wrap" aria-readonly="true">VitalDesktop ID </span>
              </div>
              <div class="col-sm-1">
                <img class="copyIcon cursor" alt="copy" (click)="copy(this.VD_Identifier)"
                  src="/assets/images/copy.svg" />
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.VD_Identifier}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Version</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.VDVersion}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">Login Page</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.LoginPage}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">VitalAxis URL </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.VitalAxis_URL}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">VitalAxis Data URL </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.VitalAxisDataURL}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Status </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="col-sm-6 text-muted" *ngIf="checkbox">
                <input type="checkbox" checked disabled> Active
              </div>
              <div class="col-sm-6 text-muted" *ngIf="!checkbox">
                <input type="checkbox" disabled> InActive
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Auto Upgrade Completed ? </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="col-sm-6 text-muted" *ngIf="AutoUpgradecheckbox">
                <input type="checkbox" checked disabled>
              </div>
              <div class="col-sm-6 text-muted" *ngIf="!AutoUpgradecheckbox">
                <input type="checkbox" disabled>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">Auto Upgrade Date Time</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.AutoUpgradeCompletedDateTime}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">Computer Location</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.ComputerLocation}}</span>
              </div>
            </div>
            <div class=" row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">Computer Name</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.ComputerName}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">System OS</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.SystemOS}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs  col-sm-5">
                <span class="word-wrap">System IP</span>
              </div>
              <div class="col-sm-1">
                <img class="copyIcon cursor" alt="copy" (click)="copy(this.SystemIP)" *ngIf="notSpecified"
                  src="/assets/images/copy.svg" />
              </div>
              <div class="text-muted col-sm-6">

                <span class="word-wrap">{{this.SystemIP}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Admin Login Name</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.AdminLoginName}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Installation Date</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.ActivatedDate}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Request Reference Number</span>
              </div>
              <div class="col-sm-1">
                <img class="copyIcon cursor" alt="copy" (click)="copy(this.OnTimeTicket)" *ngIf="Req_notSpecified"
                  src="/assets/images/copy.svg" />
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.OnTimeTicket}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Sender Agent ID</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.senderagentid}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">API Key</span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.apikey}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Labels Used </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.LabelsUsedOnThisComputer}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Printer Make & Model </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.PrinterType}}</span>
              </div>
            </div>

            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Specific Configuration </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.SpecificConfig}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Contact Person </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.CustomerName}}</span>
              </div>
            </div>
            <div class="row mt-2 col-sm-12">
              <div class="text-xs col-sm-5">
                <span class="word-wrap">Contact Number </span>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="text-muted col-sm-6">
                <span class="word-wrap">{{this.ContactNumber}}</span>
              </div>
            </div>
            <div class="modal-header admin-model-header mt-3">
              <h3 class="modal-title w-100 admin-model-header-txt"><strong>VitalDesktop Printer Settings</strong></h3>
            </div>
            <table class="table table-hover word-wrap mt-3">
              <thead>
                <tr>
                  <th>Settings File Name</th>
                  <th>Processed Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{SettingsFileName}}</td>
                  <td>{{ProcessedDate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>



        <!--Action buttons-->
        <div class="sub-button mt-4">
          <button (click)="back()" mat-raised-button class="admin-btn-success mr-4">Back</button>
          <button (click)="exportdata()" mat-raised-button class="admin-btn-success mr-4"
            [disabled]="export ">Export</button>
          <button (click)="goEdit()" mat-raised-button class="admin-btn-success" [disabled]="Edit ">Edit</button>
        </div>
      </div>

      <!-------------- VitalDesktop Edit Feature-------------- -->
      <div *ngIf="ShowEditPage" class="modal-content bordernone">
        <form [formGroup]="recipientData" autocomplete="off" novalidate>
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt"><strong>{{this.VDName}}</strong></h3>
          </div>
          <div class="mt-3 _scroll">
            <div class="row">
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>ID</label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap" type="text" (click)="copy(VD_Identifier)"
                      title="{{recipientData.controls['VD_Identifier'].value}}" formControlName="VD_Identifier"
                      readonly />
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>VD Instance Name <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap waves-light"
                      title="{{recipientData.controls['formInput_VDName'].value}}" formControlName="formInput_VDName">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_VDName'].invalid && (recipientData.controls['formInput_VDName'].dirty ||
                      recipientData.controls['formInput_VDName'].touched)" class="alert-danger">
                    It can't be empty, must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_VDName'].errors.maxLength ||
                        recipientData.controls['formInput_VDName'].errors.required ||
                        recipientData.controls['formInput_VDName'].errors.pattern">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Version <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_Version" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_VDVersion'].value}}"
                      formControlName="formInput_VDVersion">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_VDVersion'].invalid && (recipientData.controls['formInput_VDVersion'].dirty ||
                      recipientData.controls['formInput_VDVersion'].touched)" class="alert-danger">
                    It can't be empty, Must be 10 characters or less.
                    <div *ngIf="recipientData.controls['formInput_VDVersion'].errors.maxLength ||
                        recipientData.controls['formInput_VDVersion'].errors.pattern">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>New Version </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LatestVersion" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_LatestVersion'].value}}"
                      formControlName="formInput_LatestVersion" readonly>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Login Page <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LoginPage" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_LoginPage'].value}}"
                      formControlName="formInput_LoginPage">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_LoginPage'].invalid &&
                      (recipientData.controls['formInput_LoginPage'].dirty ||
                      recipientData.controls['formInput_LoginPage'].touched)" class="alert-danger">
                    It can't be empty, must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_LoginPage'].errors.maxLength ||
                        recipientData.controls['formInput_LoginPage'].errors.required  ||
                        recipientData.controls['formInput_LoginPage'].errors.pattern">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>VitalAxis URL </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap" id="input_VitalAxisURL" maxlength="500" list="VitalAxisURL"
                      title="{{recipientData.controls['VitalAxis_URL'].value}}" formControlName="VitalAxis_URL">
                    <datalist id="VitalAxisURL">
                      <option *ngFor="let item of filterAxisDataURLs(AddRecipientData.value.VitalAxis_URL,'AxisURL')"
                        [value]="item">{{item}}</option>
                    </datalist>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>VitalAxis Data URL </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap" id="input_VitalAxisDataURL" maxlength="1000"
                      list="AxisDataURL" title="{{recipientData.controls['formInput_VitalAxisDataURL'].value}}"
                      formControlName="formInput_VitalAxisDataURL">
                    <datalist id="AxisDataURL">
                      <option *ngFor="let item of filterAxisDataURLs(AddRecipientData.value.formInput_VitalAxisDataURL)"
                        [value]="item">{{item}}</option>
                    </datalist>
                  </div>
                </div>


              </div>
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <mat-checkbox [color]="task.color" class="mt-3" id="input_IsActiveStatus" formControlName="IsActive"
                      [checked]='checkStatus'>Active</mat-checkbox>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Sender Agent ID </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_SenderAgentID" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_SenderAgentID'].value}}"
                      formControlName="formInput_SenderAgentID">
                  </div>
                  <div class="mt-1" *ngIf="recipientData.controls['formInput_SenderAgentID'].invalid && (recipientData.controls['formInput_SenderAgentID'].dirty ||
                        recipientData.controls['formInput_SenderAgentID'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_SenderAgentID'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>API Key </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_APIKey" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_APIKey'].value}}" formControlName="formInput_APIKey">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_APIKey'].invalid && (recipientData.controls['formInput_APIKey'].dirty ||
                      recipientData.controls['formInput_APIKey'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_APIKey'].errors.maxLength">
                    </div>
                  </div>
                </div>

                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Group </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_Orgid" class="form-control word-wrap" (click)="copy(OrgName)"
                      title="{{recipientData.controls['formInput_orgname'].value}}" formControlName="formInput_orgname"
                      readonly>
                  </div>
                </div>

                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Environment Key </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_EnvKey" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_EnvKey'].value}}" formControlName="formInput_EnvKey">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_EnvKey'].invalid && (recipientData.controls['formInput_EnvKey'].dirty ||
                        recipientData.controls['formInput_EnvKey'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_EnvKey'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Deployment Key </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_DeploymentKey" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_DeploymentKey'].value}}"
                      formControlName="formInput_DeploymentKey" readonly>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Computer Name </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_ComputerName" class="form-control word-wrap"
                      title="{{recipientData.controls['ComputerName'].value}}" formControlName="ComputerName">
                  </div>
                  <div *ngIf="recipientData.controls['ComputerName'].invalid && (recipientData.controls['ComputerName'].dirty ||
                        recipientData.controls['ComputerName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['ComputerName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Computer Location </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_ComputerLocation" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_ComputerLocation'].value}}"
                      formControlName="formInput_ComputerLocation">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_ComputerLocation'].invalid && (recipientData.controls['formInput_ComputerLocation'].dirty ||
                      recipientData.controls['formInput_ComputerLocation'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_ComputerLocation'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>System OS </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input autocomplete="off" type="text" list="SystemOS" formControlName="SystemOS"
                      title="{{recipientData.controls['SystemOS'].value}}" class="form-control word-wrap" />
                    <!-- value="{{formInput_SystemOS}} -->
                    <datalist id="SystemOS">
                      <option *ngFor="let a of OS_UpdateDropdownItems">
                        {{a.item}}</option>
                    </datalist>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>System IP </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_SystemIP" class="form-control word-wrap"
                      title="{{recipientData.controls['SystemIP'].value}}" formControlName="SystemIP">
                  </div>
                  <div *ngIf="recipientData.controls['SystemIP'].invalid && (recipientData.controls['SystemIP'].dirty ||
                        recipientData.controls['SystemIP'].touched)" class="alert-danger">
                    Must be 20 characters or less.
                    <div *ngIf="recipientData.controls['SystemIP'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Installation Status </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_InstallationStatus" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_InstallationStatus'].value}}"
                      formControlName="formInput_InstallationStatus">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_InstallationStatus'].invalid && (recipientData.controls['formInput_InstallationStatus'].dirty ||
                        recipientData.controls['formInput_InstallationStatus'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_InstallationStatus'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Time Consumed For Installation </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="time" id="input_InstallationTime" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_InstallationTime'].value}}"
                      formControlName="formInput_InstallationTime">
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Labels Used </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LabelsUsed" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_LabelsUsed'].value}}"
                      formControlName="formInput_LabelsUsed">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_LabelsUsed'].invalid && (recipientData.controls['formInput_LabelsUsed'].dirty ||
                        recipientData.controls['formInput_LabelsUsed'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_LabelsUsed'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Printer Make & Model </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_PrinterType" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_PrinterType'].value}}"
                      formControlName="formInput_PrinterType">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_PrinterType'].invalid && (recipientData.controls['formInput_PrinterType'].dirty ||
                        recipientData.controls['formInput_PrinterType'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_PrinterType'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Admin Login Name </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_AdminLoginName" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_AdminLoginName'].value}}"
                      formControlName="formInput_AdminLoginName">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_AdminLoginName'].invalid && (recipientData.controls['formInput_AdminLoginName'].dirty ||
                        recipientData.controls['formInput_AdminLoginName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_AdminLoginName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Customer Type </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_CustomerType" class="form-control word-wrap"
                      title="{{recipientData.controls['Customer_Type'].value}}" formControlName="Customer_Type"
                      readonly>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Request Reference Number </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_OnTimeTicket" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_OnTimeTicket'].value}}"
                      formControlName="formInput_OnTimeTicket">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_OnTimeTicket'].invalid && (recipientData.controls['formInput_OnTimeTicket'].dirty ||
                      recipientData.controls['formInput_OnTimeTicket'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_OnTimeTicket'].errors.maxLength">
                    </div>
                  </div>
                </div>


                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Requested By </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="text" id="input_RequestedBy" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_RequestedBy'].value}}"
                      formControlName="formInput_RequestedBy">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_RequestedBy'].invalid && (recipientData.controls['formInput_RequestedBy'].dirty ||
                        recipientData.controls['formInput_RequestedBy'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_RequestedBy'].errors.maxLength">
                    </div>
                  </div>
                </div>

                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Contact Person </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_CustomerName" class="form-control word-wrap"
                      title="{{recipientData.controls['CustomerName'].value}}" formControlName="CustomerName">
                  </div>
                  <div *ngIf="recipientData.controls['CustomerName'].invalid && (recipientData.controls['CustomerName'].dirty ||
                      recipientData.controls['CustomerName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['CustomerName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Contact Number </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="text" id="input_ContactNumber" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_ContactNumber'].value}}"
                      formControlName="formInput_ContactNumber">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_ContactNumber'].invalid && (recipientData.controls['formInput_ContactNumber'].dirty ||
                        recipientData.controls['formInput_ContactNumber'].touched)" class="alert-danger">
                    Must be 50 digits range or less.
                    <div *ngIf="recipientData.controls['formInput_ContactNumber'].errors.maxLength ||
                        recipientData.controls['formInput_ContactNumber'].errors.minLength">
                    </div>
                  </div>

                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1  word-wrap">
                    <label>Point of Contact </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_PointOfContact" class="form-control word-wrap"
                      title="{{recipientData.controls['formInput_PointOfContact'].value}}"
                      formControlName="formInput_PointOfContact">
                  </div>
                  <div *ngIf="recipientData.controls['formInput_PointOfContact'].invalid && (recipientData.controls['formInput_PointOfContact'].dirty ||
                        recipientData.controls['formInput_PointOfContact'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="recipientData.controls['formInput_PointOfContact'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-2 p-0 m-0 pr-0 word-wrap">
                    <label>Specific Configuration </label>
                  </div>
                  <div class="col-md-10 col-md-10 pr-3">
                    <textarea rows="1" id="input_SpecificConfig" class="form-control word-wrap"
                      title="{{recipientData.controls['SpecificConfig'].value}}"
                      formControlName="SpecificConfig"></textarea>
                  </div>
                  <div *ngIf="recipientData.controls['SpecificConfig'].invalid && (recipientData.controls['SpecificConfig'].dirty ||
                      recipientData.controls['SpecificConfig'].touched)" class="alert-danger">
                    Must be 100 characters or less.
                    <div *ngIf="recipientData.controls['SpecificConfig'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-2 p-0 m-0 pr-0 word-wrap">
                    <label>Description </label>
                  </div>
                  <div class="col-md-10 col-md-10 pr-3">
                    <textarea rows="4" id="input_Comments" class="form-control word-wrap"
                      formControlName="Comments"></textarea>
                  </div>
                  <div *ngIf="recipientData.controls['Comments'].invalid && (recipientData.controls['Comments'].dirty ||
                      recipientData.controls['Comments'].touched)" class="alert-danger">
                    Must be 800 characters or less.
                    <div *ngIf="recipientData.controls['Comments'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row sub-button mt-4 btnStyle">
            <button (click)="updateOnClick()" mat-raised-button class="admin-btn-success mr-4">Back</button>
            <button (click)="goEdit()" mat-raised-button class="admin-btn-success mr-4">Reset</button>
            <button mat-raised-button class="admin-btn-success"
              (click)="updateVDData(recipientData.value)">Update</button>
          </div>
        </form>
      </div>

      <!------------- VitalDesktop Setup New Feature ------------>
      <div *ngIf="ShowAddNewPage" class="modal-content bordernone">
        <form [formGroup]="AddRecipientData" autocomplete="off">
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt"><strong>Setup New VitalDesktop</strong></h3>
          </div>
          <div class="mt-3 _scroll">
            <div *ngIf="!hideChooseOrgOption" class="row m-0 p-0 mb-1">
              <div class="p-0 m-0 pr-1 word-wrap" style="width: 223px;">
                <label for="input_location">Choose Group <span class="text-danger">*</span></label>
              </div>
              <div class="p-0 m-0" style="width: 78%;">
                <select name="account_name" id="input_location" formControlName="formInput_OrganizationID"
                  class="form-control word-wrap" (change)="onChangeLoc($event.target.value)">
                  <!-- <option *ngFor="let a of LocationList" value="{{a.organizationid}}">
                        {{a.organizationname}} ({{a.organizationid}}) ({{a.OrganizationGUID}}) </option> -->
                  <option *ngFor="let a of LocationList" value="{{a.organizationid}}">
                    {{a.organizationname}} </option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>VD Instance Name <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap" formControlName="formInput_VDName" required>
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_VDName'].invalid &&
                      (AddRecipientData.controls['formInput_VDName'].dirty ||
                      AddRecipientData.controls['formInput_VDName'].touched)" class="alert-danger">
                    It can't be empty, must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_VDName'].errors.maxLength ||
                        AddRecipientData.controls['formInput_VDName'].errors.pattern ||
                        AddRecipientData.controls['formInput_VDName'].errors.required">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Version <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_Version" class="form-control word-wrap" formControlName="formInput_VDVersion"
                      required>
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_VDVersion'].invalid && (AddRecipientData.controls['formInput_VDVersion'].dirty ||
                      AddRecipientData.controls['formInput_VDVersion'].touched)" class="alert-danger">
                    It can't be empty, Must be 10 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_VDVersion'].errors.maxLength ||
                        AddRecipientData.controls['formInput_VDVersion'].errors.required  ||
                        AddRecipientData.controls['formInput_VDVersion'].errors.pattern">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>New Version </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LatestVersion" class="form-control word-wrap"
                      formControlName="formInput_LatestVersionAvailable" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_LatestVersionAvailable'].invalid && (AddRecipientData.controls['formInput_LatestVersionAvailable'].dirty ||
                      AddRecipientData.controls['formInput_LatestVersionAvailable'].touched)" class="alert-danger">
                    Must be 20 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_LatestVersionAvailable'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Login Page <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LoginPage" class="form-control word-wrap" formControlName="formInput_LoginPage"
                      autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_LoginPage'].invalid &&
                      (AddRecipientData.controls['formInput_LoginPage'].dirty ||
                      AddRecipientData.controls['formInput_LoginPage'].touched)" class="alert-danger">
                    It can't be empty, must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_LoginPage'].errors.maxLength ||
                        AddRecipientData.controls['formInput_LoginPage'].errors.required  ||
                        AddRecipientData.controls['formInput_LoginPage'].errors.pattern">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>VitalAxis URL</label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_VitalAxisURL" class="form-control word-wrap" list="AddAxisURLs" maxlength="500"
                      formControlName="formInput_VitalAxisURL" autocomplete="off">
                    <datalist id="AddAxisURLs">
                      <option *ngFor="let item of filterAxisDataURLs(AddRecipientData.value.VitalAxis_URL,'AxisURL')"
                        [value]="item">{{item}}</option>
                    </datalist>
                  </div>
                  <!-- <div *ngIf="AddRecipientData.controls['formInput_VitalAxisURL'].invalid &&
                        (AddRecipientData.controls['formInput_VitalAxisURL'].dirty ||
                        AddRecipientData.controls['formInput_VitalAxisURL'].touched)" class="alert-danger">
                          It can't be empty, must be 50 characters or less.
                          <div *ngIf="AddRecipientData.controls['formInput_VitalAxisURL'].errors.maxLength ||
                          AddRecipientData.controls['formInput_VitalAxisURL'].errors.required  ||
                          AddRecipientData.controls['formInput_VitalAxisURL'].errors.pattern">
                          </div>
                        </div> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <mat-checkbox [color]="task.color" class="mt-3" id="input_IsActiveStatus"
                      formControlName="formInput_IsActive" [checked]='checkStatus'>Active</mat-checkbox>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Sender Agent ID</label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_SenderAgentID" class="form-control word-wrap"
                      formControlName="formInput_senderagentid" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_senderagentid'].invalid && (AddRecipientData.controls['formInput_senderagentid'].dirty ||
                      AddRecipientData.controls['formInput_senderagentid'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_senderagentid'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>API Key </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_APIKey" class="form-control word-wrap" formControlName="formInput_apiKey"
                      autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_apiKey'].invalid && (AddRecipientData.controls['formInput_apiKey'].dirty ||
                      AddRecipientData.controls['formInput_apiKey'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_apiKey'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>VitalAxis Data URL </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input class="form-control word-wrap" id="input_VitalAxisDataURL" maxlength="1000"
                      list="AddAxisDataURL" title="{{recipientData.controls['formInput_VitalAxisDataURL'].value}}"
                      formControlName="formInput_VitalAxisDataURL">
                    <datalist id="AddAxisDataURL">
                      <option *ngFor="let item of filterAxisDataURLs(AddRecipientData.value.formInput_VitalAxisDataURL)"
                        [value]="item">{{item}}</option>
                    </datalist>
                  </div>
                  <!-- <div *ngIf="AddRecipientData.controls['formInput_apiKey'].invalid && (AddRecipientData.controls['formInput_apiKey'].dirty ||
                        AddRecipientData.controls['formInput_apiKey'].touched)" class="alert-danger">
                          Must be 50 characters or less.
                          <div *ngIf="AddRecipientData.controls['formInput_apiKey'].errors.maxLength">
                          </div>
                        </div> -->
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Environment Key </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_EnvKey" class="form-control word-wrap" formControlName="formInput_environmentKey"
                      autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_environmentKey'].invalid && (AddRecipientData.controls['formInput_environmentKey'].dirty ||
                      AddRecipientData.controls['formInput_environmentKey'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_environmentKey'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Customer Name </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_CustomerName" class="form-control word-wrap"
                      formControlName="formInput_CustomerName" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_CustomerName'].invalid && (AddRecipientData.controls['formInput_CustomerName'].dirty ||
                      AddRecipientData.controls['formInput_CustomerName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_CustomerName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Point of Contact </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_PointOfContact" class="form-control word-wrap"
                      formControlName="formInput_PointOfContact" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_PointOfContact'].invalid && (AddRecipientData.controls['formInput_CustomerName'].dirty ||
                      AddRecipientData.controls['formInput_PointOfContact'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_PointOfContact'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Contact Number </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="text" id="input_ContactNumber" class="form-control word-wrap"
                      formControlName="formInput_ContactNumber" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_ContactNumber'].invalid && (AddRecipientData.controls['formInput_ContactNumber'].dirty ||
                      AddRecipientData.controls['formInput_ContactNumber'].touched)" class="alert-danger">
                    Must be 50 digits range or less.
                    <div *ngIf="AddRecipientData.controls['formInput_ContactNumber'].errors.maxLength ||
                        AddRecipientData.controls['formInput_ContactNumber'].errors.minLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Requested By </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="text" id="input_RequestedBy" class="form-control word-wrap"
                      formControlName="formInput_RequestedBy" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_RequestedBy'].invalid && (AddRecipientData.controls['formInput_RequestedBy'].dirty ||
                      AddRecipientData.controls['formInput_RequestedBy'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_RequestedBy'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Request Reference Number </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_OnTimeTicket" class="form-control word-wrap"
                      formControlName="formInput_OnTimeTicket" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_OnTimeTicket'].invalid && (AddRecipientData.controls['formInput_OnTimeTicket'].dirty ||
                      AddRecipientData.controls['formInput_OnTimeTicket'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_OnTimeTicket'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Admin Login Name </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_AdminLoginName" class="form-control word-wrap"
                      formControlName="formInput_AdminLoginName" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_AdminLoginName'].invalid && (AddRecipientData.controls['formInput_AdminLoginName'].dirty ||
                      AddRecipientData.controls['formInput_AdminLoginName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_AdminLoginName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Facility ? </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input type="checkbox" id="Input_IsFacility" formControlName="formInput_IsFacility">
                  </div>
                </div>

              </div>
              <div class="col-sm-6">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Customer Type </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <select id="CustomerType-select" formControlName="formInput_CustomerType"
                      class="form-control word-wrap">
                      <option *ngFor="let a of CustType_DropdownItems">
                        {{a.item}}</option>
                    </select>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Computer Location </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_ComputerLocation" class="form-control word-wrap"
                      formControlName="formInput_ComputerLocation">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_ComputerLocation'].invalid && (AddRecipientData.controls['formInput_ComputerLocation'].dirty ||
                      AddRecipientData.controls['formInput_ComputerLocation'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_ComputerLocation'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Computer Name </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_ComputerName" class="form-control word-wrap"
                      formControlName="formInput_ComputerName" autocomplete="off">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_ComputerName'].invalid && (AddRecipientData.controls['formInput_ComputerName'].dirty ||
                      AddRecipientData.controls['formInput_ComputerName'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_ComputerName'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>System OS </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input autocomplete="off" type="text" list="SystemOS" formControlName="formInput_SystemOS"
                      class="form-control word-wrap" />
                    <datalist id="SystemOS">
                      <option *ngFor="let a of OS_DropdownItems">
                        {{a.item}}</option>
                    </datalist>
                  </div>
                </div>

                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>System IP </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_SystemIP" class="form-control word-wrap" formControlName="formInput_SystemIP">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_SystemIP'].invalid && (AddRecipientData.controls['formInput_SystemIP'].dirty ||
                      AddRecipientData.controls['formInput_SystemIP'].touched)" class="alert-danger">
                    Must be 20 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_SystemIP'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Printer Make & Model </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_PrinterType" class="form-control word-wrap"
                      formControlName="formInput_PrinterType">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_PrinterType'].invalid && (AddRecipientData.controls['formInput_PrinterType'].dirty ||
                      AddRecipientData.controls['formInput_PrinterType'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_PrinterType'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-5 p-0 m-0 pr-1 word-wrap">
                    <label>Labels Used </label>
                  </div>
                  <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                    <input id="input_LabelsUsed" class="form-control word-wrap"
                      formControlName="formInput_LabelsUsedOnThisComputer">
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_LabelsUsedOnThisComputer'].invalid && (AddRecipientData.controls['formInput_LabelsUsedOnThisComputer'].dirty ||
                      AddRecipientData.controls['formInput_LabelsUsedOnThisComputer'].touched)" class="alert-danger">
                    Must be 50 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_LabelsUsedOnThisComputer'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-2 p-0 m-0 pr-1 word-wrap">
                    <label>Specific Configuration </label>
                  </div>
                  <div class="col-md-10 pr-3">
                    <textarea rows="1" id="input_SpecificConfig" class="form-control word-wrap"
                      formControlName="formInput_SpecificConfig"></textarea>
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_SpecificConfig'].invalid && (AddRecipientData.controls['formInput_SpecificConfig'].dirty ||
                      AddRecipientData.controls['formInput_SpecificConfig'].touched)" class="alert-danger">
                    Must be 100 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_SpecificConfig'].errors.maxLength">
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mb-1">
                  <div class="col-md-2 p-0 m-0 pr-0 word-wrap">
                    <label>Description </label>
                  </div>
                  <div class="col-md-10 pr-3">
                    <textarea rows="4" id="input_Comments" class="form-control word-wrap"
                      formControlName="formInput_Comments"></textarea>
                  </div>
                  <div *ngIf="AddRecipientData.controls['formInput_Comments'].invalid && (AddRecipientData.controls['formInput_Comments'].dirty ||
                      AddRecipientData.controls['formInput_Comments'].touched)" class="alert-danger">
                    Must be 800 characters or less.
                    <div *ngIf="AddRecipientData.controls['formInput_Comments'].errors.maxLength">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sub-button mt-4">
            <button (click)="back()" mat-raised-button class="admin-btn-success mr-4">Back</button>
            <!-- <button *ngIf="!hideChooseOrgOption" (click)="AddVDInstallation(AddRecipientData.value)" mat-raised-button class="admin-btn-success mr-4">Save</button> -->
            <button (click)="AddVDInstallation(AddRecipientData.value)" mat-raised-button
              class="admin-btn-success">Save</button>
          </div>
        </form>
      </div>

      <div *ngIf="NoDataCreateNewPage">
        <div class="alignText">
          <br />
          <div class="d-flex mt-4">
            <button (click)="getVitalDesktopLocDetails()" mat-raised-button class="admin-btn-success mr-4"
              [disabled]="Create">Create</button>
          </div>
        </div>
        <div class="nodata-wrap mt-4 align-center">
          <div class="nodata-design"> No Data Found </div>
        </div>
      </div>
    </div>

    <!-------------------Comment section----------------------->
    <!-- <div class="col-sm-4 cardBorder">
          <mat-tab-group selectedIndex="0">
            <mat-tab>
              <ng-template mat-tab-label>
                <span>All</span>
              </ng-template>
              <br>
              <p>Entered Template Version : NiceEngine 5 <br>
                By Username on mm/dd/yyyy</p>
              <p>Entered Computer Name : ABC<br>
                By Username on mm/dd/yyyy</p><br><br>
              <div class="row">
                <div class="col-sm-4">
                  <div class="radio">
                    <label><input id="s1" type="radio" name="optradio" class="radio-align" checked>Incidents</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="radio">
                    <label><input type="radio" name="optradio" class="radio-align">Comments</label>
                  </div>
                </div>
              </div>
              <div class="form-group shadow-textarea textareaWidth">
                <textarea class="form-control z-depth-1" id="exampleFormControlTextarea6" rows="3"
                  placeholder="Write something here..."></textarea>
              </div>
              <div class="sub-button mt-2">
                <button type="button" class="btn btn-info" [disabled]="true">Submit</button>
              </div>

            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span>Incidents</span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span>Audits</span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span>Comments</span>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div> -->
  </div>
</div>