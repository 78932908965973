export class CreateActivityTrackerInput
{
        product  :string ;
        productId  :string ;
        customerName  :string ;
        customerId  :string ;
        entityType  :string ;
        entityId  :string ;
        actionType: string;
        userName  :string ;
        userId  :string ;
        locationName  :string ;
        locationId  :string ;
        isInternal  :boolean ;
        transactionId  :string ;
        ticketId  :string ;
        ticketInfo  :string ;
        context  :any;
        operation  :Operations ;
        createdByApplication :string;
}

export class Operations{
    info:string;
    extendedAttributes : {};
    attachments : any;
}
export class Attachments{
    id : string;
    name : string;
    source : string;
}
export class Context{
    key : string;
    value : string;
}
