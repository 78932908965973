import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabUsersService } from 'src/app/core/utility/lab-users.service';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { relative } from 'path';

import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
@Component({
  selector: 'app-labusersgrid',
  templateUrl: './labusersgrid.component.html',
  styleUrls: ['./labusersgrid.component.scss'],
  providers: [searchFilterPipetemplate],
})
export class LabusersgridComponent {
  templatefilter: object = {};

  @Input() SelectValue;
  @Input() gridData;
  @Input() gridHeaders;
  @Input() searchtext;
  @Input() type:string= '';

  @Input() fromClientUser = false;
  //@Input() showloader = true;
  loginType:string='';
  @Output() selectedCard = new EventEmitter<any>();

  filename: string = '';
  userSessionDetails:any;
  tooltip:string = '';
  showloader:boolean = false;

  gridHeaderList: {
    templateName: string;
    headers: { Name: string; type: string; key: string; colWidth: string }[];
  }[];
  isEmailDisabled: boolean=false;
  isLockDisabled: boolean=false;
  isGeneratePwd: boolean=false;
  isUnlockDisabled: boolean=false;
  sourceApp = 'VitalDx';
  toolTipText: any;

  constructor(
    public templateHeaders: LabUsersService,
    public VitalHttpServices: VitalHttpServices,
    private labAdminService: LabadminService,
    private searchfiltersTemplate: searchFilterPipetemplate,
    private labAdminSessionService: LabadminSessionService,
    private _router : Router
  , public dialog: MatDialog) {

    }

  ngOnInit(): void {
    this.labAdminSessionService.getLabAdminSession.subscribe(async(session) => {
      this.userSessionDetails = session['userDetails'];
      this.loginType = this.userSessionDetails.loginType || '';
    });
    this.GetButtonAccess(this.labAdminService.rbacPermissions);
  }

  ngOnChanges() {
    this.filtertemplates();
  }

   // RBAC
   GetButtonAccess(actionButtonDetails) {

    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      if(this.type == 'labusers'){
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Lab")["SubMenu"]
      seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == "Users")["ActionButton"]
      }
      else if(this.type == 'Users' || this.type == 'Providers' || this.type == 'Referring Physicians'){
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Clients")["SubMenu"]
        seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == this.type)["ActionButton"]
      }
      else if(this.type == 'Clients'){
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Clients")["ActionButton"]
      }
      try {
        for (let i = 0; i < seletedMenuPermissions?.length; i++) {
          switch (seletedMenuPermissions[i].Button.toLowerCase()) {
            case 'email':
              this.isEmailDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'lock':
              this.isLockDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'unlock':
              this.isUnlockDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'generatepassword':
              this.isGeneratePwd = seletedMenuPermissions[i].IsPermitted === "false"
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  filtertemplates() {
    if(this.gridHeaders !=undefined && this.gridHeaders !=null && this.gridHeaders.length > 0) {
    for (let i = 0; i < this.gridHeaders.length; i++) {
      if(this.fromClientUser){
        if(this.gridHeaders[i].key.toLowerCase() == 'facilityname' || this.gridHeaders[i].key.toLowerCase() == 'locationcode'
        || this.gridHeaders[i].key.toLowerCase() == 'npi'){
          this.templatefilter[this.gridHeaders[i].key] = this.searchtext;
        }
      }
      else{
        this.templatefilter[this.gridHeaders[i].key] = this.searchtext;
      }
    }
  }
  }

  displayedColumns(item: any, header: any) {

    if(header.key.trim().toLowerCase() == 'npi' && item[header.key].trim().toLowerCase() == 'unknown'){
      return '';
    }
    return item[header.key]
  }

  /**
   * grid data export
   */
  ExportExcel() {
    if (this.fromClientUser) {
      this.filename = 'ClientUser';
    } else {
      this.filename = 'LabUser';
    }
    let filename = this.filename + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      this.searchfiltersTemplate.transform(this.gridData, this.templatefilter)
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.filename);
    XLSX.writeFile(wb, filename);
  }

  // RedirectToEditOrg(item, header){
  //   if(this.fromClientUser){
  //     if(header.key.toLowerCase() === 'facilityname'){
  //       //return item['orderingFacilityName']
  //     }
  //     if(header.key.toLowerCase() === 'ownerlab'){
  //       //return item['ownerlab']
  //       this._router.navigate(['labadmin','editclient',item['OfOrgId']])
  //     }

  //   }
  // }

  // lock and unlock user account
  openDialog(item:any){

    // lock and unlock should not allow for inactive users
    if(item.status.trim().toLowerCase() !== 'active'){
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:item.isAccountLockedOut ? '../../../../assets/icons/user_icons/Unlocked.svg': '../../../../assets/icons/user_icons/Locked.svg',
          header: item.isAccountLockedOut ? 'Unlock User Account':'Lock User Account',
          message:'Activate the user to make the changes.',
          buttonname:'Ok',
          type:"mail-sent"
         }
    })
    return;
    }

    this.dialog.open(LockUnlockUserDialogComponent,{
    width: '26vw',
     data:{
      imagePath:item.isAccountLockedOut ?'../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg',
      header: item.isAccountLockedOut ? 'Unlock User Account':'Lock User Account',
      row1value:item.name,
      row2value:item.isAccountLockedOut ?"Locked":"Unlocked",
      loginid:item.loginId,
      userid:item.userid,
      commonkey:item.commonKey,
      row1text:'User Name',
      row2text:'Status',
      buttonname:item.isAccountLockedOut?"Unlock User":"Lock User",
      type:'lockunlock-user',
      status:item.isAccountLockedOut
     }
}).afterClosed().subscribe((result)=>{
  if (result && result.userid) {
    // Find the index of the item in gridData with the matching loginId
    const indexToUpdate = this.gridData.findIndex(e => e.userid == result.userid);
    if (indexToUpdate !== -1) {
      // Update the isAccountLockedOut property of the found item
      this.gridData[indexToUpdate].isAccountLockedOut = result.status;
    }
  }
});
}
// find npi number is number or not
  isNpiKnown(item: any): boolean {
    return item.npi.toLowerCase() !== 'unknown' && item.npi !== '' && item.npi !== null && item.npi != undefined;
  }

  passwordGenerateDialog(item:any) {

    // inactive user should not be allowed to send mail
    if(item.status.trim().toLowerCase() !== 'active'){
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          header: 'Reset Password',
          message:'Activate the user to generate new password.',
          buttonname:'Ok',
          type:"mail-sent"
         }
    })
    return;
    }

    // unlock user should not be allowed to reset password
    if(item.isAccountLockedOut){
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          header: 'Reset Password',
          message:'Unlock the user to generate new password.',
          buttonname:'Ok',
          type:"mail-sent"
         }
    })
    return;
    }

    this.showloader = true;
    // login id is already verified need to send reset password link
    if(this.emailVerify(item)){

      let obj = {
        email: item.emailId,
      }
      this.labAdminService.sendResetPwdLink(obj).subscribe((res)=>{
        this.showloader = false;
        this.dialog.open(LockUnlockUserDialogComponent,{
          width: '26vw',
           data:{
            imagePath:'../../../../assets/icons/user_icons/warning.svg',
            header: 'Reset Password',
            message:'Reset Password link sent. check your email to proceed.',
            buttonname:'Ok',
            type:"mail-sent"
           }
      });
      },(error) =>{
        this.showloader = false;
        console.error(error);
      });
      return;
    }

    // login id is not verified will renerate the new password
    this.labAdminService.generatePassword(item.loginId, this.userSessionDetails.userId, this.labAdminService.deploymentKey).subscribe((data)=>{
      this.showloader = false;
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:'../../../../assets/icons/user_icons/Regenerate_Password2.svg',
          header: 'Regenerate Password',
          row1value:item.loginId,
          row2value:data[0],
          loginid:item.loginId,
          userid:item.userid,
          row1text:'User Name',
          row2text:'Password',
          buttonname:'Copy',
          type:"generate-password"
         }
    });
    },(error) => {
      this.showloader = false;
        console.error(error);
  });
  }

  emailType(item:any):boolean{
   if(this.emailVerify(item)){
    this.tooltip = '';
    return true;
   }
   this.tooltip = 'Send Invitation';
   return false;
  }

  emailVerify(item:any):boolean{
    if(item.emailId && item.emailId.trim().length > 0 && item.authstatus && item.authstatus.trim().toLowerCase() == 'verified'
    && this.loginType && this.loginType.trim().toLowerCase() == 'email'){
      return true;
    }
    return false;
  }

  // mail send for invitation
  mailSent(item:any){

    // inactive user should not be allowed to send mail
    if(item.status.trim().toLowerCase() !== 'active'){
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
          header: 'Send Invitation',
          message:'Activate the user to send invitation mail.',
          buttonname:'Ok',
          type:"mail-sent"
         }
    })
    return;
    }

    // unlock user should not be allowed to send mail
    if(item.isAccountLockedOut){
      this.dialog.open(LockUnlockUserDialogComponent,{
        width: '26vw',
         data:{
          imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
          header: 'Send Invitation',
          message:'Unlock the user to send invitation mail.',
          buttonname:'Ok',
          type:"mail-sent"
         }
    })
    return;
    }
    this.showloader = true;
      let requestData = [];
      let data = {
        Email: item.emailId,
        UserID: item.userid,
        OrganizationID: this.userSessionDetails.organizationId
      }
      requestData.push(data);
      this.labAdminService.migrateUser(requestData,this.labAdminService.deploymentKey).subscribe((d)=>{
        this.showloader = false;
        this.dialog.open(LockUnlockUserDialogComponent,{
          width: '26vw',
           data:{
            imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
            header: 'Send Invitation',
            message:'Email Invitation sent successfully to '+item.emailId,
            buttonname:'Ok',
            type:"mail-sent"
           }
      });
      },(error)=>{
        this.showloader = false;
        console.error(error);
      });
    //}
  }

  RedirectToView(value) {
    this.selectedCard.emit(value);
  }

  enableToolTip(e, item: any = '', header: any = '') {
    let title: any;
    if(item || header) {
      title = this.displayedColumns(item, header);
    }
    this.toolTipText = e.scrollWidth > e.clientWidth ? title : '';
  }
}
