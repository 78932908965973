import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';


declare var $: any;
declare var jQuery: any;
declare var window: any;

export class Validationclass {
  Loginname: string;
  Password: string;
}
@Component({
  selector: 'template-HL7LogsModel',
  templateUrl: 'HL7Logs.component.html',
  styleUrls: ['./HL7Logs.component.scss']
})

export class HL7LogsComponent {
  @Input() selecteddefaultdeploymentkey: any;
  @Input()
  templateDataInp: any;

  // public DeploymentKeys = environment.DeploymentKeys;
  public templateData;
  private deploymentKey = "";
  public ChangeStatus: any[];
  public DeploymentKeys = [];

  HL7Logsgroup = this._fb.group({
    DeploymentKey: ['', Validators.required],
    QuickSearch: ['', Validators.required],
    QuickSearchInpTxt: ['', Validators.required]
  })

  searchType = "";
  searchValue = "";

  showGrid = false;
  ShowUpdateHl7 = false;
  SelectedGridItems: any[] = [];

  HL7LogsUpdategroup = this._fb.group({
    frmChangeStatus: ['', Validators.required],
    frmAddComment: ['', Validators.required]
  })
  
  subMenuCardModel: SubMenuCardModel
  defaultdeploymentkey: string;
  isbuttonenabled: boolean = false;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
  }

  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,
    public GridwithCB: GridContainerWithCheckBoxComponent, public _snackBar: MatSnackBar, private datashare: DataShareService) {
    this.HL7Logsgroup.patchValue(
      {
        DeploymentKey: "none",
        QuickSearch: "Case ID"
      });
    this.DeploymentKeys = this.vaservices.DeploymentKeys
    this.subMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
  }

  searchHL7Logs(HL7LogsValue) {
    let queryResult: any;
    let queryVariable;

    let query = this.vaservices.GetQuery('HL7Logs');

    // let depKey: string = (<HTMLInputElement>document.getElementById("DKeys")).value;
    // let searchType: string = (<HTMLInputElement>document.getElementById("drpQSearch")).value;
    // let searchValue: string = (<HTMLInputElement>document.getElementById("inpQSearch")).value;

    if (this.selecteddefaultdeploymentkey == HL7LogsValue.DeploymentKey.trim() && this.selecteddefaultdeploymentkey.toLowerCase() != 'all') {
      this.deploymentKey = this.selecteddefaultdeploymentkey;
    } else {
      if (HL7LogsValue.DeploymentKey.trim() !== "none") {
        this.deploymentKey = HL7LogsValue.DeploymentKey.trim();
      } else {
        this.deploymentKey = this.selecteddefaultdeploymentkey;
      }
    }
    this.searchType = HL7LogsValue.QuickSearch;
    this.searchValue = HL7LogsValue.QuickSearchInpTxt;

    this.clearHL7UpdateControls();

    //sessionStorage.setItem( 'deploymentKey', depKey.toLowerCase());
    //this.vaservices.deploymentKey = depKey.toLowerCase();
    switch (this.searchType) {
      case 'Case ID':
        if (!isNaN(Number(this.searchValue))) {
          queryVariable = { "caseId": this.searchValue };
        }
        else {
          queryVariable = { "caseId": "" };
        }
        break;
      case 'Log ID':
        if (!isNaN(Number(this.searchValue))) {
          queryVariable = { "logId": this.searchValue };
        }
        else {
          queryVariable = { "logId": "" };
        }
        break;
      case 'Accession #':
        queryVariable = { "accessionNum": this.searchValue };
        break;
      default:
        queryVariable = { "caseId": "" };
        break;
    }
    //queryVariable = { "caseId": sessionStorage.getItem("orgid"), "accessionNum": accountId.toString(), "logId": accountId.toString() };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vaservices.GetData(queryResult, this.deploymentKey.toLowerCase()).subscribe(res => {
      if (!res.errors) {
        this.ngxService.stop();
        // this.HL7Logsgroup.reset();
        this.templateData = res;
        switch (this.searchType) {
          case 'Case ID':
            this.templateData.Keyword = "HL7Logs_CaseID";
            break;
          case 'Log ID':
            this.templateData.Keyword = "HL7Logs_LogID";
            break;
          case 'Accession #':
            this.templateData.Keyword = "HL7Logs_AccessionNum";
            break;
          default:
            this.templateData.Keyword = "HL7Logs";
            break;
        }
        this.showGrid = true;
        if (this.templateData.data.submenuData.length > 0) {
          // call set change status dropdown
          var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "HL7LogsDropDown");
          //sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);
          let drpqueryRequest: any = {
            "OperationName": null,
            "Query": drpquery[0].Query,
            "Variables": {}
          };
          this.vaservices.GetData(drpqueryRequest, this.deploymentKey.toLowerCase()).subscribe(result => {
            if (!result.errors) {
              this.ChangeStatus = [];
              this.HL7LogsUpdategroup.value.frmChangeStatus = "";
              this.ChangeStatus = result.data.submenuData;
              this.HL7LogsUpdategroup.patchValue({
                // frmChangeStatus: this.ChangeStatus[0].Description
                frmChangeStatus: "-1"
              })
            }
          }, error => {
            console.info(error);
          });
          this.ShowUpdateHl7 = true;
        }
        else {
          this.ShowUpdateHl7 = false;
        }
      }
      else {
        this.ngxService.stop();
        console.error(res.error);
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  // public GetQuery(keyword: string) {
  //   let query: string = "";
  //   let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
  //   if (mainQueryIndex > -1)
  //     query = this.vaservices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }
  HL7LogsgroupClose() {
    this.HL7Logsgroup.reset();
    this.showGrid = false;
    // $('#HL7LogsModel').modal('hide');
    $('#genericModal').modal('hide');
    this.ShowUpdateHl7 = false;
    this.clearHL7UpdateControls();
    this.HL7Logsgroup.patchValue(
      {
        DeploymentKey: "none",
        QuickSearch: "Case ID"
      });
  }
  clearHL7UpdateControls() {
    this.SelectedGridItems = [];
    this.HL7LogsUpdategroup.reset();
    (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "";
  }
  getSelectedGridItems($event) {
    (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "";
    this.SelectedGridItems = $event;
  }
  // UpdateHL7Logs(HL7LogsUpdateValue) {
  //   if (HL7LogsUpdateValue.frmChangeStatus != "-1" && HL7LogsUpdateValue.frmChangeStatus != "") {
  //     (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "";
  //     if (this.SelectedGridItems.length > 0) {
  //       (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "";
  //       let UpdateQuery = [];
  //       this.SelectedGridItems.forEach(element => {
  //         UpdateQuery.push(
  //           {
  //             "LogID": element.Log_Id,
  //             "Handler": element.Handler,
  //             "Direction": element.Direction,
  //             "HandlerData": element.Handler_Data,
  //             "Status": HL7LogsUpdateValue.frmChangeStatus,
  //             "Comments": HL7LogsUpdateValue.frmAddComment,
  //             "LoginName": localStorage.getItem("user_name")
  //           }
  //         )
  //       });
  //       var jsondata = "{\"jsonstring\":" + JSON.stringify(UpdateQuery) + "}";
  //       jsondata = JSON.parse(jsondata);
  //       this.ngxService.start();
  //       this.vaservices.UpdateHL7LogStatus(jsondata, this.deploymentKey).subscribe(res => {
  //         this.ngxService.stop();
  //         let data: any = res;
  //         if (data.Success) {
  //           this._snackBar.open(data.Message, 'Success');
  //           // this.SelectedGridItems = [];
  //           this.HL7Logsgroup["DeploymentKey"] = this.deploymentKey;
  //           this.HL7Logsgroup["QuickSearch"] = this.searchType;
  //           this.HL7Logsgroup["QuickSearchInpTxt"] = this.searchValue;
  //           this.searchHL7Logs(this.HL7Logsgroup);
  //         } else {
  //           this._snackBar.open(data.Message, 'Failed');
  //           console.info("error");
  //         }
  //       }, error => {
  //         this.ngxService.stop();
  //         console.info(error);
  //         this._snackBar.open(error.statusText, 'Failed')
  //       });
  //       //reload the grid
  //     }
  //     else {
  //       (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "*Select atleast one row from the grid!";
  //     }
  //   }
  //   else {
  //     (<HTMLInputElement>document.getElementById("divgridSelectAlert")).innerHTML = "*Select Status!"
  //   }
  // }
}