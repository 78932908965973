<div *ngIf="templateGroupedDataKeys && subHeader && mainData" style="line-height: 25px;">

  <div *ngFor="let template of templateGroupedDataKeys;let i=index" class="card vt-card my-2"
    style="border: 1px solid #0000008f !important;margin-right: 1%;">
    <div>
      <span *ngIf="template" class="_600 ml-2 pull-left">{{template}}</span>
    </div>
    <div *ngFor="let rowsubHeader of templateGroupedData[template];let j=index" class="rowsubHeader">

      <div style="float: left;">
        <p *ngIf="subHeader" class="m-0 ml-3 _500 text-sm"> {{rowsubHeader[subHeader]}}</p>
        <span class="ml-5">
          <span class="label blue mr-2 "> <em class="fa fa-info"></em></span>
          <span *ngIf="subHeader">{{rowsubHeader[templateLables[0]]}} </span>
        </span>

      </div>
      <span *ngIf="rowsubHeader.FieldType.includes(',')">
        <span *ngFor="let item of  rowsubHeader.FieldType.split(',')">
          <label class="switch sliderproperties" *ngIf="item =='togglebutton'">
            <!-- <mat-slide-toggle [ngModel]="GetSubDataListItem(rowsubHeader[subHeader])" ></mat-slide-toggle> -->

            <label class="ui-switch primary m-t-xs m-r"><input type="checkbox"
                [ngModel]="GetSubDataListItem(rowsubHeader[subHeader])"> <em></em></label>
            <!-- <input type="checkbox"
                *ngIf="(subHeader && subDataResultList && IsValidSubDataResultListItem(rowsubHeader,subHeader))"
                [checked]="true" >
               <span class="slider round"></span> -->
          </label>
          <!-- <span *ngIf="item =='textbox'" class="sliderproperties">
                 <input type="text" class="textFields" [disabled] ="!rowsubHeader.edit" />
                  <em class="fa fa-pencil-square-o textFields" aria-hidden="true" (click) ="rowsubHeader.edit=true"></em>
                  <em class="fa fa-save" (click) ="rowsubHeader.edit=false"></em> </span>               -->

          <!-- [ngModel]="GetSubDataListItem(template)"  //this component binding value is not added-->
          <span *ngIf="item =='textbox'" class="sliderproperties">
            <input type="text" class="textFields" [disabled]="!rowsubHeader.edit" #textInput
              [ngModel]="GetSubDataListItem(rowsubHeader[subHeader])" />
            <button class="btn btn-icon btn-rounded blue btn-sm text-white mr-1" [disabled]="test"
              (click)="rowsubHeader.edit=true">
              <em class="fa fa-pencil-square-o" aria-hidden="true"></em>
            </button>
            <button class="btn btn-icon btn-rounded blue btn-sm text-white" [disabled]="test"
              (click)="rowsubHeader.edit=false;UpdateSaveButton(rowsubHeader,rowsubHeader[subHeader],textInput.value)">
              <em class="fa fa-save"></em>
            </button>
          </span>
          <span class="pull-right mr-3 text-md  text-blue" *ngIf="item =='link'"
            (click)="getsubdata_link(rowsubHeader)">
            <em class="fa fa-eye" aria-hidden="true"></em>
          </span>
        </span>
      </span>
      <span *ngIf="!rowsubHeader.FieldType.includes(',')">
        <label class="switch sliderproperties" *ngIf="rowsubHeader.FieldType =='togglebutton'">
          <!-- <mat-slide-toggle [ngModel]="GetSubDataListItem(rowsubHeader[subHeader])" ></mat-slide-toggle> -->

          <label class="ui-switch primary m-t-xs m-r"><input type="checkbox"
              (change)="UpdateSaveButton(rowsubHeader,rowsubHeader[subHeader],$event.target.checked)"
              [ngModel]="GetSubDataListItem(rowsubHeader[subHeader])"> <em></em></label>
          <!-- <input type="checkbox"
            *ngIf="(subHeader && subDataResultList && IsValidSubDataResultListItem(rowsubHeader,subHeader))"
            [checked]="true" >
           <span class="slider round"></span> -->
        </label>
        <!-- <span *ngIf="rowsubHeader.FieldType =='textbox'" class="sliderproperties">
             <input type="text" class="textFields" [disabled] ="!rowsubHeader.edit" />
              <em class="fa fa-pencil-square-o textFields" aria-hidden="true" (click) ="rowsubHeader.edit=true"></em>
              <em class="fa fa-save" (click) ="rowsubHeader.edit=false"></em> </span>               -->

        <!-- [ngModel]="GetSubDataListItem(template)"  //this component binding value is not added-->
        <span *ngIf="rowsubHeader.FieldType =='textbox'" class="sliderproperties">
          <input type="text" class="textFields" [disabled]="!rowsubHeader.edit" #textInput
            [ngModel]="GetSubDataListItem(rowsubHeader[subHeader],j)" />
          <!-- [disabled]="textInput.value == 0 ? true : false"  -->
          <button class="btn btn-icon btn-rounded blue btn-sm text-white mr-1" [disabled]="true"
            (click)="rowsubHeader.edit=true">
            <em class="fa fa-pencil-square-o" aria-hidden="true"></em>
          </button>
          <!-- [disabled]="textInput.value == 0 ? true : false"  -->
          <button class="btn btn-icon btn-rounded blue btn-sm text-white" [disabled]="true"
            (click)="rowsubHeader.edit=false;UpdateSaveButton(rowsubHeader,rowsubHeader[subHeader],textInput.value)">
            <em class="fa fa-save"></em>
          </button>
        </span>
        <span class="pull-right mr-3 text-md  text-blue" *ngIf="rowsubHeader.FieldType =='link'"
          (click)="getsubdata_link(rowsubHeader)">
          <em class="fa fa-eye" aria-hidden="true"></em>
        </span>
      </span>


    </div>
  </div>
</div>
<div class="nodata-wrap mt-4" *ngIf="!templateGroupedDataKeys">
  <div class="nodata-design">No Data Found</div>
</div>


<div *ngIf="!mainData" class="mt-3 ml-1">
  <div *ngIf="gridData && DataSub.length>0">

    <Strong style="display: block;margin-block:1%;">{{Querydata.ConfigurationName}}</Strong>
    <span *ngIf="Querydata.CategoryName === 'L2L Configurations'" style="display: inline-block;margin-block: 1%;">
      <button [ngClass]="queryTitle === 'Send_Out_Professional_Case_L2L1'?'btn-primary':' '" class="btn btn-sm"
        (click)="getL2LData('L2L Configurations')"> L2L Configurations</button>
      <button [ngClass]="queryTitle === 'Send_Out_Professional_Case_L2L'?'btn-primary':' '" class="btn btn-sm"
        (click)="getL2LData('Test Level Configurations')"> Procedure Level Configurations</button>
    </span>
    <div *ngIf="girdView ==='hgrid'">
      <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [ngStyle]="{'width.px': gridwidth}"
        [isReadOnly]="true" [itemsSource]="gridData" >
        <div *ngFor="let columnname of gridHeader">
          <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
            aria-expanded="false" [allowResizing]="true" [visible]="!(columnname==groupbyName )">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>

    <div *ngIf="girdView ==='grid'">
      <wj-flex-grid  [ngStyle]="{'width.px': gridwidth}" [isReadOnly]="true" [itemsSource]="gridData"
        #grid>
        <div *ngFor="let columnname of gridHeader">
          <wj-flex-grid-column [binding]="columnname" [allowDragging]="false" [header]="columnname.split('_').join(' ')"
            [width]="170" [allowResizing]="true"></wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
      <br />
      <div class="align-center">
        <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
          [cv]="gridData"> </wj-collection-view-navigator>
      </div>
    </div>
  </div>

  <button mat-raised-button class="admin-btn-success mt-4" (click)="mainData = true" type="button">Back</button>
  <div *ngIf="gridwidth == 0 " class="nodata-wrap mt-4">
    <div class="nodata-design"> No Data Found </div>
  </div>

</div>