import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-new-diagnosis-templates',
  templateUrl: './new-diagnosis-templates.component.html',
  styleUrls: ['./new-diagnosis-templates.component.scss']
})
export class NewDiagnosisTemplatesComponent implements OnInit {
  @Input() public templateData: any
  constructor(public sanitizer: DomSanitizer, private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar) { }

  deployment: string;
  iframeSrc: SafeResourceUrl;


  ngOnInit(): void {
    this.ngxService.start();
    this.deployment = sessionStorage.getItem('deploymentKey');
    this.calculateIframeSrc();
    this.ngxService.stop();
  }
  private calculateIframeSrc(): void {
    const baseUrl = environment.dxTemplateUrl + 'TemplatesController/sso'
    // const baseUrl = 'TemplatesController/sso';
    const queryParams = {
      emailId: localStorage.getItem('UserEmail'),
      caseType: this.templateData.secondarykeys.casetype,
      organizationId: sessionStorage.getItem("org_id"),
      accountID: sessionStorage.getItem("Locationid"),
      userID: sessionStorage.getItem("Userid") ? sessionStorage.getItem("Userid") : "-100",
      deploymentKey: this.deployment,
      userRole: sessionStorage.getItem("RoleName"),
      sourceApp: "VitalAdmin3",
      // userName: localStorage.getItem('user_name'),
      // userEmail: localStorage.getItem('UserEmail'),
      // orgname: sessionStorage.getItem('Org_Name'),
      // locationName: sessionStorage.getItem('locationName')
    };

    let finalurl = `${baseUrl}?${this.serialize(queryParams)}`;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(finalurl);
  }
  private serialize(obj: { [key: string]: string }): string {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }

}
