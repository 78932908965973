<div class="modal-dialog TextCompareDialog my-0">
  <div class="modal-content header-wraps">

    <!-- Modal Header -->
    <div class="modal-header admin-model-header header-freeze">
      <h3 class="modal-title w-100 admin-model-header-txt">
        Text Compare Tool
      </h3>
      <span type="button" data-dismiss="modal" (click)="closeModal()" class="mr-2">
        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
          title="Close">
      </span>
    </div>

    <!-- Modal body -->
    <div class="modal-body">
      <div class="container-fluid container-diff mt-4 pr-0 pl-0">
        <div *ngIf="isCompared" class="row">
          <div class="col-md-12">
            <form class="form-inline" role="form">
              <label for="lang" class="mb-2 mr-sm-2" *ngIf="false">Language:</label>
              <select id="lang" *ngIf="false" name="lang" class="form-control mb-2 mr-sm-2" [(ngModel)]="selectedLang"
                (change)="onChangeLanguage($event.target.value)">
                <option *ngFor="let option of languages" [value]="option">
                  {{ option }}
                </option>
              </select>
              <label for="theme" class="mb-2 mr-sm-2">Theme:</label>
              <select id="theme" name="theme" class="form-control mb-2 mr-sm-2" [(ngModel)]="selectedTheme"
                (change)="onChangeTheme($event.target.value)">
                <option *ngFor="let option of themes" [value]="option.value">
                  {{ option.name }}
                </option>
              </select>
              <div class="form-check mb-2 mr-sm-2">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" (change)="onChangeInline($event.target.checked)" />
                  Inline Diff
                </label>
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="isCompared" class="row">
          <div class="col-md-12 editor" style="border: solid 1px" [style.min-height.px.!important]="diffHeight">
            <ngx-monaco-diff-editor style="height: 100%" [options]="diffOptions" [originalModel]="originalModel"
              [modifiedModel]="modifiedModel"></ngx-monaco-diff-editor>
          </div>
        </div>
        <div class="row mt-3" *ngIf="ifCompared">
          <div class="col-sm-6 col-md-6" style="border: solid 1px #eee; padding: 0px"
            [style.min-height.px.!important]="diffHeight">
            <div class="input-title">
              <label>Original Text</label>
            </div>
            <ngx-monaco-editor style="height: 100%" [options]="inputOptions" [(ngModel)]="text1"></ngx-monaco-editor>
          </div>
          <div class="col-sm-6 col-md-6" style="border: solid 1px #eee; padding: 0px"
            [style.min-height.px.!important]="diffHeight">
            <div class="input-title">
              <label>Changed Text</label>
            </div>
            <ngx-monaco-editor style="height: 100%" [options]="inputOptions" [(ngModel)]="text2"></ngx-monaco-editor>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer mt-4">
      <button mat-raised-button class="admin-btn-success mr-4" *ngIf="ifCompared" (click)="onCompare()" type="button">
        Find Difference
      </button>
      <button mat-raised-button class="admin-btn-success mr-4" *ngIf="isCompared" (click)="offCompare()" type="button">
        Edit
      </button>
      <button mat-raised-button class="admin-btn-success" data-dismiss="modal" (click)="closeModal()" type="button">
        Cancel
      </button>
    </div>

  </div>
</div>