import { Component, Input, ViewChild } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridDetail from '@grapecity/wijmo.grid.detail';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FormBuilder, Validators } from '@angular/forms';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import * as XLSX from 'xlsx';

@Component({
  selector: 'resultnested',
  templateUrl: './resultnested.component.html',
  styleUrls: ['./resultnested.component.scss']
})

export class ResultNestedComponent {
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  public hideExportBtn: boolean = true;
  // public actionButtonDetails: any[];
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  subMenuCardModel: SubMenuCardModel;
  copyGridData: CollectionView;
  cvPaging: CollectionView;
  data: string[] = [];
  data1: string[] = [];
  gridData: CollectionView;
  childData: any;
  childData1: any;
  childData2: any;
  accidvalue: any
  // data;
  public DeploymentKeys = [];
  searchInput: String = '';
  searchUserInput: String = '';
  copyDataClicked: boolean = false;
  usertoggle: boolean = false;
  destDeployment: string;
  searchuserlist: any[];
  srcDeployment: any;
  orgList: any[];
  public searchResult: Array<any> = [];
  srcOrgid: any;
  srcUserid: string;
  srcOrgName: any;
  srcUserName: any;
  selector: Selector;
  selectedItems: any = [];
  checkStatus = false;
  copyData: any = [];
  caseTypeArray: any[];
  repeatedNames: string;
  invalidCasetypeFlag: boolean = false;
  backBtn: boolean = true;
  userchanged: any;
  srcOrgType: any;
  orgFilterList: any[];
  userCardFlag: boolean = false;
  sheetHeader = [];
  gridWidth:number = 0;

  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;
  @ViewChild('flexgrid', { static: true }) flexgrid: wjcGrid.FlexGrid;

  uploadbutton: boolean=false;
  uploadClicked: boolean=false;
  detailsDisplay: boolean=false;
  gridDisplay: boolean=true;
  casetypevalue: any;
  postUpload: boolean;
  excelDataArray: any[];
  invalidColumns: string;
  showInvalidColumns: boolean;
  hideuploadBtn: boolean=false;
  gridArray: any[];
  postDownload: boolean;
  showDelete: boolean;
  bulkUpload: boolean;


 public  columns: any = [
    new ColDef('OrderCodeID', 'OrderCodeID'),
    new ColDef('OrderCode', 'Order Code'),
    new ColDef('DisplayName', 'Display Name'),
    new ColDef('ShortName', 'Short Name'),
    new ColDef('Type', 'Type'),
    new ColDef('Orderable', 'Orderable'),
    new ColDef('PanelCount', 'Panel Count'),
    new ColDef('Active', 'Active'),
    new ColDef('CPTCode', 'CPTCode'),
    new ColDef('CreatedBy', 'CreatedBy'),
    new ColDef('CreatedDate', 'CreatedDate'),
    new ColDef('ModifiedBy', 'ModifiedBy'),
    new ColDef('ModifiedDate', 'ModifiedDate'),
  ];
  constructor(private commonService: CommonService, private ngxService: NgxUiLoaderService, private _fb: FormBuilder, private _snackbar: MatSnackBar, private vitalHttpServices: VitalHttpServices, commonservice: CommonService, private datashare: DataShareService) {
    this.subMenuCardModel = new SubMenuCardModel(commonservice, vitalHttpServices, datashare);
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
  }

  copycollectionMethodForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmUser: [""],
    frmDepKey: ["", Validators.required],
  })
 onInit(){
  this.casetypevalue = this.templateData.cardtype.toString();
  this.GetButtondetails();

 }
  ngOnChanges(): void {
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        this.data = this.templateData.submenuData
      }
      if (this.templateData.submenuData.submenuData1.length > 0) {
        this.childData = this.templateData.submenuData.submenuData1
      }
    }

    this.data = this.getSampleData(this.data.length);
    this.GetButtondetails();
  }

  init(grid) {
    grid.columns.forEach((col) => {
      col.width = "*";
    });
    this.flex = grid;
    this.addDetailGrid(grid);
    this.GetButtondetails();
  }



  GetButtondetails() {
    let casebutton = this.vitalHttpServices.CasemenuAction;
    if (!casebutton) {
      this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
          case "Upload":
            this.hideuploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
            break;
      }
    }
  }
  addDetailGrid(grid) {
    // add detail grid
    new wjcGridDetail.FlexGridDetailProvider(grid, {
      // allow user to open multiple detail rows on a single grid (or not)
      detailVisibilityMode: 'ExpandMulti',
      // create the detail cell for a given row
      createDetailCell: (row) => {
        var cell = document.createElement('div');
        var detailGrid = this.createGrid(cell, row.dataItem.childs);
        this.addDetailGrid(detailGrid);
        return cell;
      },
      rowHasDetail: (row) => {
        return row.dataItem.childs && row.dataItem.childs.length;
      }
    });
  }



  createGrid(host, items) {
    let boj
    let colms = []
    for (let [key, value] of Object.entries(items[0])) {
      boj = {
        binding: key, isReadOnly: true, width: 100
      }
      colms.push(boj)
    }
    return new wjcGrid.FlexGrid(host, {
      autoGenerateColumns: false,
      columns: colms,
      itemsSource: items
    });
  }

  getSampleData(levels) {
    let data = this.data;
    this.fillNestedData(data, 0, levels);
    return data;
  }

  fillNestedData(dataArray, curLv, maxLv) {
    if (curLv >= maxLv) {
      return;
    }
    dataArray.forEach(item => {
      let value = this.childData.filter(va => va.OrderCode === item.OrderCode)
      if (value.length > 0) {
        item["childs"] = value
      }
    });
  }


  ExportExcel(flex) {
    let url:any = this.templateData.menuURL;
    let id:any = this.templateData.secondarykeys.OrganizationId;
    let filename = this.commonService.getExcelFileName(url,id)
    this.commonService.ExportToExcel(flex, filename);
  }
  //Bulk upload
  UploadMethod() {
    if(!this.hideuploadBtn)
    {
    this.gridwidth = 1;
   // this.userlevel = false;
    this.uploadbutton = false;
    this.uploadClicked = true;
    this.detailsDisplay = true;
    this.gridDisplay = false;
  }
}

   //refresh Back data
   refreshBackData() {
    this.uploadbutton = true
    //this.showtemplated = false
    // this.userNames = [];
    //this.userList = [];
    //this.getListUsers();
    let orgid = sessionStorage.getItem('org_id').toString()
    this.accidvalue = sessionStorage.getItem('AccountID');
    let query = this.subMenuCardModel.GetQuery('casetypeordercode');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id').toString(),  "casetype": this.templateData.cardtype.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        this.templateData.submenuData = res.data.submenuData
        this.templateData.submenuData.submenuData1 = res.data.submenuData1
        this.data = this.templateData.submenuData;
        this.childData = this.templateData.submenuData.submenuData1
        this.data = this.getSampleData(this.data.length);
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();
  }

  //copy screen
  copyOrgData() {
    this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.srcDeployment = this.destDeployment
    this.getListOrg();
    this.gridWidth = -1
    this.uploadbutton = false;
    this.detailsDisplay = false;
    this.gridDisplay = false;
    this.copyDataClicked = true;
    //this.gridPage = false;
   // this.postUpload = false;
    this.backBtn = true;
    this.srcOrgid = ''
    this.copycollectionMethodForm.reset()
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.destDeployment,
    })
  }

    //#region to change the deployment
    onChangeDeployment(e, DeploymentKey) {
      if (e.source.selected) {
        this.srcDeployment = DeploymentKey;
        this.getListOrg();
        this.gridWidth = -1
        this.searchInput = '';
        this.searchUserInput = '';
        this.srcUserid = '';
        this.usertoggle = false;
        this.searchResult = []
        this.searchuserlist = []
        this.srcOrgType = "Laboratory"
        this.removeGrid();
        this.resetCopyForm();
      }
    }
    //#endregion

    //#region copy-from-entity: orglist
    fetchOrgSeries(value: string) {
      this.searchResult = []
      this.searchuserlist = [];
      this.searchUserInput = '';
      this.srcUserid = '';
      if (value === '') {
        this.searchuserlist = [];
        return (this.searchResult = []);
      }
      let idValidate = /^[0-9]*$/
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.orgList.find(r => {
            if (r.organizationname) {
              if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
                this.srcOrgid = r.organizationid
                return r
              }
              else {
                this.srcOrgid = ''
              }
            }
          })
          this.searchResult = this.orgList.filter(function (series) {
            if (series && series.organizationname != null) {
              return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
            }
          });
          this.searchResult = this.searchResult.splice(0, 25);
        }
      }
      else {
        this.orgList.find(r => {
          if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
            this.srcOrgid = r.organizationid
            return r
          }
          else {
            this.srcOrgid = ''
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationid != null) {
            return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    //#endregion

    disableGetDataBtn() {
      return this.searchInput == '' ? true : (this.srcOrgid) != '' ? false : true
    }


    public GetQuery(keyword: string) {
      let query: string = "";
      let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
      if (mainQueryIndex > -1)
        query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
      return query;
    }

    getListOrg() {
      this.orgList = [];
      let dbName;
      let query = this.GetQuery('adduserfilterorg');
      let queryString = null
      dbName = this.srcDeployment;

      let queryVariable = { "filterParam": queryString };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.vitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
        if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
          this.orgList = res.data.Organizations_list;
        }
        this.ngxService.stop();
      }
        , error => {
          console.error(error);
          this.ngxService.stop();
        });
    }

    //#region
    removeGrid() {
      this.gridWidth = -1;
     // this.excelDataArray = [];
    //  this.postUpload = false;
      this.backBtn = true;
    //  this.postDownload = false;
     // this.showInvalidColumns = false;
     // this.invalidColumns = '';
     // this.showDelete = true;
    }
    //#endregion

    resetCopyForm() {
      this.copycollectionMethodForm.patchValue({
        frmOrganization: "",
        frmUser: "",
        frmDepKey: this.srcDeployment,
      })
    }

    refreshBackcopy() {
      this.gridDisplay = true;
      this.copyDataClicked = false;
      this.uploadClicked = false;
      this.refreshBackData();

    }

    //#region Get data to copy-from-other-entity
    getDataToCopy() {
    this.detailsDisplay = false;
    this.gridDisplay = false;
    this.postUpload = false;
    this.uploadClicked = false;
    this.invalidCasetypeFlag = false;
    this.backBtn = true
    let queryVariable;
      queryVariable = { "orgid": this.srcOrgid.toString(),"casetype" : this.templateData.secondarykeys.casetype };
    let query = this.subMenuCardModel.GetQuery("copyoforderandresult");
    this.ngxService.start();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      if (!data.errors) {
        this.ngxService.stop();
        if (data.data.submenuData.length>0) {
          this.sheetHeader = []
          this.sheetHeader = Object.keys(data.data.submenuData[0]);
          this.sheetHeader.unshift('Notes');
          this.sheetHeader.reverse();
          // this.sheetHeader.unshift('Notes');
          this.backBtn = false
          this.manageDataColumn(data.data.submenuData);
        }
       else{
        this.backBtn = true
        this.excelGridData(data.data.submenuData);
       }
        //this.addDetailGrid()

      }
    }, error => {
      this.ngxService.stop();
      console.log(error);
    })
    }
  //#endregion

  manageDataColumn(data) {
    var i = 1;
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, tablename: 'OrganizationOrderCodes', organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString(),type:'Copy'
      });
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }

  validateExcelData(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.invalidColumns = '';
    this.showInvalidColumns = false;
    // if (!this.bulkUpload) {
      this.vitalHttpServices.ValidateBulkupdateOrderandResultcodes(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this.ngxService.stop();
            if ( this.invalidColumns && this.invalidColumns.length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }

            if (!result[0]['NoteMessage'] && result[0]["InvalidColumns"]) {
              // this.showInvalidColumns = false;
              this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
            // this._snackbar.open('Please upload a valid excel sheet','Close')
              return;
            }
            else {
              dataArray.filter(e => {
                result.filter(r => {
                  if (r.SlNo === e.slno) {
                    e.notes = r.NoteMessage;
                  }
                });
              });
              this.ngxService.stop();
              this.excelGridData(dataArray);
            }
            // else {
            //   this.ngxService.stop();
            //   this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
            // }

          }

        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
      });
  }
  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
      this.uploadData();
  }

  //#region Upload ExcelData to Insert API
    uploadData() {
    let dataArray = [];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      // for (let i = 0; i < this.excelDataArray.length; i++) {
      //   if (!this.excelDataArray[i]["notes"]) {
      //     if (this.copyDataClicked) {
      //       this._snackbar.open('Copying data failed!', 'Close');
      //     }
      //   }
      //   else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
      //     errorcount++
      //   }
      // }
      if (errorcount > 0) {
        this._snackbar.open("Order code  already exists for this Entity.", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["casetype"] = !this.userCardFlag ? this.templateData.cardtype.toString() : this.excelDataArray[i]["casetype"];

          }
        }
      }
      dataArray=this.excelDataArray;
      let srcDB, destDB
      if (this.copyDataClicked) {
        srcDB = this.srcDeployment
        destDB = this.destDeployment
      } else {
        srcDB = null
        destDB = null
      }

      let jsonArray = {
        "accountId": sessionStorage.getItem('Locationid'),
        "srcDB": srcDB,
        "destDB": destDB,
        "srcOrgid": this.srcOrgid,
        "destOrgid": sessionStorage.getItem('org_id'),
        "casetype": this.templateData.secondarykeys.casetype,
        "dataRows": dataArray
      };
      this.ngxService.start();
      console.log(jsonArray);
      this.vitalHttpServices.CopyOrderandResult(jsonArray).subscribe(result => {
        this.ngxService.stop();
        // if (!result.errors) {
        //   if (result.length > 0) {
        //     this.checkStatus = false;
        //     this.postUpload = true;
        //     this.postDownload = true;
        //     this.showDelete = false;
        //     this.excelDataArray = [];
        //     this.excelDataArray = this.convertObjKeysToLower(result);
        //     this.commonService.createActivityTracker('Created', -1, 'Copy from other entity -Diagnosis Templates', 'Audit', dataArray, {});
        //     this.ngxService.start();
        //     this.bulkUpload = false;
        //     for (let i = 0; i < result.length; i++) {
        //       this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
        //       if (result[i]["NoteMessage"].match(/Failed/)) {
        //         this.excelDataArray[i]["Status"] = "Ignored"
        //       }
        //       else {
        //         this.excelDataArray[i]["Status"] = result[i]["Status"]
        //       }
        //     }
        //     this.excelGridData(this.excelDataArray);
        //     this.ngxService.stop();
        //     // this._snackbar.open('Data copied successfully', 'Close');
        //     //this.getListUsers();
        //   }
        //   else {
        //     this._snackbar.open('Data copying failed! Please try try again later', 'Close');
        //   }
        // }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong. Please try again', 'Close');
        console.error(error)
      })
    }
    }
  //#endregion

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  initGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
   // const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
    });
  }

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridArray = [];
    this.copyGridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          for (let [key, value] of Object.entries(data[i])) {
            let flag = false;
            if (key.toString().toLowerCase() != 'ordercodeid' && key.toString().toLowerCase() != 'organizationid') {
              if (value != null) {
                if (value.toString().toLowerCase().trim() == 'null') {
                  flag = true;
                }
                primary[key] = value;
              }
            }
            if (key.toLowerCase() == 'ordercodeid') {
              if (value == 'true' || value == true) {
                value = true
              } else if (value == 'false' || value == false || !value) {
                value = false
              }
              primary[key] = value;
            }
            flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null
            if (key.toString().toLowerCase() == 'ordercodeid' || key.toString().toLowerCase() == 'organizationid') {
              if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
              }
              primary[key] = value;
            }
            if (mandatoryFieldEmpty) {
              value = 'Mandatory field is missing';
              this.excelDataArray[i]["notes"] = value;
              primary['notes'] = value;
            }
          }
          this.gridArray.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.excelDataArray = this.gridArray;
        this.copyGridData = new CollectionView(this.gridArray);
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
     // this.getUsers(this.srcOrgid);
    }
  }

   //#regin to show notes
   formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null' || value.toString().includes('exits')
          || value.toString().includes('Invalid') || value.toString().includes('accepts only')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('New template added') || value == 'Success' || value.toString().includes('created successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('exist')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }
  //#endregion


   //#region Export Grid data
   ExportCopyStatus(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["notemessage"];
      delete e._data["slno"];
      delete e._data["slno1"];
      delete e._data["modifiedby"];
      delete e._data["createdby"];
      delete e._data["orgdiagtemplateid"];
      delete e._data["organizationid"];
      excel.push(e._data);
    });
    let filename = 'Diagnosis Templates_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'DiagTemplates_');
    XLSX.writeFile(wb, filename);
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
