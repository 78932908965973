import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatOption } from '@angular/material/core';
import {  MatSelect } from '@angular/material/select';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import {  SelectionModel } from '@angular/cdk/collections';

export interface Task {
  color: ThemePalette;
}

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss']
})

export class DisclaimersComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @ViewChild('matDisclaimerSelect') matDisclaimerSelect: MatSelect;
  @ViewChild('matDisclaimerMultSelect') matDisclaimerMultSelect: MatSelect;
  @ViewChild("matSingleSelect", { static: false }) matSingleSelect: any;
  @ViewChild("inputSearch", { static: false }) inputSearch: any;

  length: number;
  criteriaValues = new FormControl();
  criteria = new FormControl();
  filteredValue: Observable<any[]>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  editor: Editor;
  html: '';
  gridHeader = ['Status', 'RepeatForEachTest', 'Type', 'Description', 'ConditionID', 'Condition', 'DisclaimerId', 'Sequence'];
  inputTypeList: any = [];
  operatorValue: any = [];
  valueList: any = [];
  conditionData: any = [];
  inpList: any = [];
  DynamicJson = [];
  noDataFound: boolean = false;
  allSelected = false;

  @Input()
  templateData: any;
  maxlength: number;
  formSubmit : boolean = false;

  columns = [
    new ColDef('Status', 'Status'),
    new ColDef('RepeatForEachTest', 'RepeatForEachTest'),
    new ColDef('Type', 'Type'),
    new ColDef('Description', 'Disclaimers'),
    new ColDef('Disclaimer', 'Disclaimer'),
    new ColDef('ConditionID', 'ConditionID'),
    new ColDef('DisclaimerId', 'DisclaimerId'),
    new ColDef('Sequence', 'Sequence')
  ];

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  gridData: CollectionView;
  gridShowDiv: boolean;
  public hideGrid = false;
  showPagination: boolean;
  gridwidth: number = 0;
  showPaginationMainGrid: boolean;
  filterForm: FormGroup;
  filterFields: any[];
  InpOpData: any;
  conditionValueData: { Controls: { type: string; value: string; label: string; data: any; selections? : any,formControl? : FormControl }[]; };
  condition: any = [];
  input: any = [];
  operator: any = [];
  selectedValue: string = "";
  disableSave: boolean = false;
  valuesForInput: any = [];
  listCareUnits: any;
  searchResult: any[];
  toggle: any = [false];
  searchInput: any = [];
  searchvalue: any = "";
  SubMenuCardModel: SubMenuCardModel;
  singlesearchInput: any = [];
  singlesearchvalue: any;
  selectedrowData: any = {};
  intervalId: number;
  subscription: Subscription;
  datastring: any = [];
  saveDisclaimerForm: boolean = false;
  headerValue: string = "";
  inputDataarray: any = [];
  hideCreateBtn: boolean = false;
  hideDeleteBtn: boolean = false;
  hideUpdateBtn: boolean = false;
  parameterIdArray: any = [];
  dupDisclaimer: string = '';
  finalSave: any;
  finalFlag: any;
  actionEdit: string;
  placeholderCriteria: string[] = [];
  placeHolders: any;
  showFlag: boolean = false;
  allSelectedOf = false;
  surgicalCaseType: boolean;
  siteFormat: number = 2;
  allSelectDropDown: any = [];

  parentChildInput: ParentChildInput = {
    'Sub Site': "Body Site",
    'Procedures': "Tests"
  }

  constructor(private ngxService: NgxUiLoaderService, private _fb: FormBuilder, private datashare: DataShareService,
    private commonService: CommonService, private vitalHttpServices: VitalHttpServices,
    private fb: FormBuilder, private clipboard: Clipboard, public _snackBar: MatSnackBar) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    );

  }

  placeHoldersList(value) {
    this.criteriaValues = new FormControl();
    this.placeHolders = this.InpOpData.Placeholders[0][value.value]
    this.filteredValue = this.criteriaValues.valueChanges.pipe(
      startWith(''),
      map((data) =>
        data ? this.filterData(data) : this.placeHolders.slice().sort(
          (a, b) => {
            const nameA = a.Value.toUpperCase();
            const nameB = b.Value.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
      )
    );
    this.showFlag = true
  }

  fnToggleFilter() {
    this.criteriaValues.setValue(null);
  }

  addingDescription(data) {
    if (data.option._selected) {
      let appendValue = data.option.value != '' ? " {{" + data.option.value + "}}" : '';
      let position = this.disclaimerForm.value.frmDescription && this.disclaimerForm.value.frmDescription.lastIndexOf("</")
      this.disclaimerForm.value.frmDescription = this.disclaimerForm.value.frmDescription ? this.disclaimerForm.value.frmDescription : ""
      let description = [this.disclaimerForm.value.frmDescription.slice(0, position), appendValue, this.disclaimerForm.value.frmDescription.slice(position)].join('');;

      this.disclaimerForm.patchValue({
        frmDescription: description
      })
      this.criteriaValues.setValue('');
    }
  }
  filterData(name: string) {
    let arr = this.placeHolders.filter(
      (country: any) => country.Value.toLowerCase().includes(name.toLowerCase())
    );
    return arr.length ? arr.sort(
      (a, b) => {
        const nameA = a.Value.toUpperCase();
        const nameB = b.Value.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }) : [{ Value: 'No Item found', code: 'null' }];
  }

  deleteReason = "";

  disclaimerForm = this._fb.group({
    frmcondition: "",
    frmdisctype: "Test",
    frmrepeatfortests: false,
    frmdisplayorder: "",
    frmDescription: ""
  })

  deleteForm = this._fb.group({
    frmdeletereason: "",
  })

  ngOnInit(): void {
    this.editor = new Editor();
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    //this.AddGridData();
    this.caseTypeDetails();
    this.refreshDisclaimergrid();
    this.getSiteFormat()
  }


  // checking the casetype(molecular/ surgical)
  caseTypeDetails() {
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = {
      //keyword: 'AssociationTypes',
      context: 'VitalAdminCaseTypes', Keyword: 'SurgicalCaseTypes'
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      let caseTypeList: any;
      caseTypeList = JSON.parse(Resdata.data.submenuData[0].Items_JSON)
      let test = caseTypeList.casetypes_list.find(va => (va.toLowerCase() == this.templateData.cardtype.toLowerCase()))
      this.surgicalCaseType = test ? true : false
    }, (error) => {
      console.error(error)
      this.ngxService.start();
    })
  }
  //method filters single select values based on input
  fetchSeries(index, value: string) {
    this.searchResult = []
    this.toggle[index] = true;
    let searchResult = this.inputDataarray[index].filter(function (series) {
      return series.Name.toString().toLowerCase().includes(value.toLowerCase());
    });
    this.DynamicJson[index].Controls[2].data = searchResult.splice(0, 25);
  }

  // save disclaimers
  saveDisclaimer(value) {
    this.gridShowDiv = false
    this.addForms()
  }

  async checkCaseType() {
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'CaseType',
      context: 'ScreeningType',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let data = await this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise();
    let isPresent: boolean = false;
    if (!data.errors) {
      if (data.data.submenuData.length > 0) {
        data = JSON.parse(data.data.submenuData[0].Items_JSON);
        let caseTypeList = [];
        for (let i = 0; i < data.CaseType.length; i++) {
          for (let [key, value] of Object.entries(data.CaseType[i])) {
            caseTypeList.push(value);
          }
        }
        isPresent = caseTypeList.some(va => va.toString().toLowerCase() == this.templateData.secondarykeys.casetype.toString().toLowerCase());
      }
    }
    return isPresent;
  }
  //method to get input type and operator values
  async getInputData(value, selectedData) {
    if (value == 'create') {
      if (this.hideCreateBtn) {
        return
      }
    }
    else if (value == 'edit' || value == 'copy') {
      if (this.hideUpdateBtn) {
        return
      }
    }
    if (selectedData == '' || value == 'copy') {
      this.selectedrowData = {};
    }
    if (value != 'edit') {
      this.parameterIdArray = [];
    }
    this.gridShowDiv = false;
    this.noDataFound = false;
    if (value === 'create') {
      this.saveDisclaimerForm = true;
    }
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = {
      //keyword: 'AssociationTypes',
      context: 'Disclaimers',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise().then(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.inpList = []
        this.inputTypeList = []
        let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
        let caseTypeCheck = await this.checkCaseType();
        if (!caseTypeCheck) {
          data.InputType = data.InputType.filter(va => !va.Value.toString().toLowerCase().includes('screening types'))
        }
        let surgiCaseType = this.vitalHttpServices.casetypeList.some(data => data.toString().toLowerCase() === this.templateData.secondarykeys.casetype.toLowerCase());
        if (surgiCaseType) {
          data.InputType = data.InputType.filter(va => !va.Value.toString().toLowerCase().includes('material type'))
        }
        // Modifying Sites input types and adding to Input types
        data.InputType = this.addSiteData(data.InputType);
        this.InpOpData = data //surgicalCaseType
        // this.countryList();
        this.placeholderCriteria = Object.keys(this.InpOpData.Placeholders[0])
        for (let i = 0; i < data.InputType.length; i++) {
          this.inputTypeList.push(data.InputType[i].Value.toString().split(' - ')[0]);
        }
        this.inpList = JSON.parse(JSON.stringify(this.inputTypeList));
        if (this.surgicalCaseType) {
          let index = this.inpList.indexOf('Order Code')
          this.inpList.splice(index, 1)
        }
        if (value.toString().toLowerCase() == "edit" || value.toString().toLowerCase() == "copy") {
          this.headerValue = value.toString().toLowerCase() == "edit" ? 'Edit Disclaimer' : 'Copy Disclaimer';
          // DisclaimerID: this.selectedrowData.DisclaimerId,
          // ConditionID: this.selectedrowData.ConditionID,
          // OrganizationID: this.templateData.secondarykeys.OrganizationId,
          // UserID: sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
          // AccountID: this.templateData.cardIdentifier,
          // DeleteReason: this.deleteForm.value.frmdeletereason
          this.disclaimerForm.patchValue({
            frmcondition: "",
            frmdisctype: selectedData.Type,
            frmrepeatfortests: selectedData.RepeatForEachTest.toString().toLowerCase() == 'no' ? false : true,
            frmdisplayorder: selectedData.Sequence.toString().toLowerCase() == '' ? '' : selectedData.Sequence,
            frmDescription: selectedData.Description,
          })
          this.html = selectedData.Description
          await this.splitConditionString(selectedData.Condition, selectedData.DisclaimerParamter);
        }
        else {
          this.headerValue = 'Configure Disclaimer';
          this.disclaimerForm.patchValue({
            frmcondition: "",
            frmdisctype: "Test",
            frmrepeatfortests: false,
            frmdisplayorder: "",
            frmDescription: ""
          });
          this.disclaimerForm.controls.frmrepeatfortests.enable()
          this.saveDisclaimer(value)
        }
      }
    }, (error) => {
      this._snackBar.open('An error occurred while processing your request', 'Failed');
      this.ngxService.stop();
      console.error(error);
    });
  }


  //method to split the condition string to get the input types, operators, values and conditions
  async splitConditionString(condition, DisclaimerParamter) {
    let inputArray = [];
    let operatorArray = [];
    let valueArray = [];
    let conditionArray = [];
    let parameterIdArray = [];


    // let Parts = condition.replace(/\s+/gi, '  ').replace(/(?<=[(][^)])(  )(?=[^(][)])/g, ' ').replace(/(?<=[(][^)]+)(\s*)([,])(\s*)(?=[^(]+[)])/gi, '$2').replace(/(?<==\s*['][^']+)(\s)(\s*)(?=[^']+['])/gi, '$1').replace(/(?<==  )(.?)(  )(.?)(?=AND|OR|$)/gi, '$1 $3 ').trim().split('  ')
    let Parts = [];
    condition.replace(/(.+?)(\s*)(<=|>=|<>|<|>|!=|=|(?<= )IN)(\s*)(.*?)(\s*)(AND|OR|$)(\s*)/gi, function (s, g1, g2, g3, g4, g5, g6, g7, g8) {
      Parts.push(g1);
      Parts.push(g3);
      Parts.push(g5);
      if (g7 != '') {
        Parts.push(g7);
      }
    });
    // condition.replace(/\s+/gi, '  ').replace(/(?<=[(][^)]*)(  )(?=[^(]*[)])/g, ' ').replace(/(?<=[(][^)]*)(\s*)([,])(\s*)(?=[^(]*[)])/gi, '$2').replace(/(?<==  )(.*?)(  )(.*?)(?=AND|OR|$)/gi, '$1 $3 ').trim().split('  ');
    // let Parts = condition.replace(/\s+/gi, '  ').replace(/(?<=[(].*?)(  )(?=.*?[)])/g, ' ').replace(/(?<=[(].*?)(\s*)([,])(\s*)(?=.*?[)])/gi, '$2').replace(/(?<==  )(.*?)(  )(.*?)(?=AND||OR||$)/gi, '$1 $3 ').trim().split('  ');
    //delete '= 1' in [dbo].[HasInTheList](@List10, @Services) = 1
    Parts.forEach((element, index) => {
      if (element.includes("[dbo].[HasInTheList]") || element.includes("[dbo].[HasAllInTheList]")) {
        Parts.splice(index + 1, 2);
      }
    });
    let tempArray = [];
    DisclaimerParamter.forEach(e => {
      if (e.Property.toString().toLowerCase() == 'billto') {
        e.Value = e.Value.replace(/'/g, "");
        tempArray.push({
          'billto': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'caseprofileid') {
        tempArray.push({
          'caseprofile': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'flagid') {
        tempArray.push({
          'flags': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'orderingfacilityid') {
        tempArray.push({
          'orderingfacility': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'patient age') {
        tempArray.push({
          'patientage': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'pathologistid') {
        tempArray.push({
          'pathologist': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'screeningtypeid') {
        tempArray.push({
          'screeningtypes': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'procedureid') {
        tempArray.push({
          'procedures': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'testcategory') {
        e.Value = e.Value.replace(/'/g, "");
        tempArray.push({
          'panel': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'services') {
        e.Value = e.Value.replace(/'/g, "");
        tempArray.push({
          'services': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'materialtype') {
        e.Value = e.Value.replace(/'/g, "");
        tempArray.push({
          'materialtype': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'readinglocation') {
        tempArray.push({
          'readinglocation': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'sign-outuser') {
        tempArray.push({
          'sign-outuser': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'diagnosistemplates') {
        tempArray.push({
          'diagnosistemplates': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'adequacy') {
        tempArray.push({
          'adequacy': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'ordercode') {
        tempArray.push({
          'ordercode': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'tests') {
        tempArray.push({
          'tests': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'casetags') {
        tempArray.push({
          'casetags': e.Value,
          'parameterId': e.ParameterId
        })

      }
      else if (e.Property.toString().toLowerCase() == 'siteprocedure') {
        tempArray.push({
          'extractionprocedure': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'bodysite') {
        tempArray.push({
          'bodysite': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'subsiteid') {
        tempArray.push({
          'subsite': e.Value,
          'parameterId': e.ParameterId
        })
      }
      else if (e.Property.toString().toLowerCase() == 'sitelocationid') {
        tempArray.push({
          'sitelocation': e.Value,
          'parameterId': e.ParameterId
        })
      }
    });
    let abc = 0;
    for (let i = 0; i < Parts.length; (Parts[i].includes("[dbo].[HasInTheList]") || Parts[i].includes("[dbo].[HasAllInTheList]")) ? i += 2 : i += 4) {
      let inputtype = '';
      switch (Parts[i].toLowerCase()) {
        case '[dbo].[hasinthelist](@list3, @billto)':
        case '[dbo].[hasallinthelist](@list3, @billto)':
        case '@billto':
          inputtype = 'BillTo';
          break;
        case '[dbo].[hasinthelist](@list1, @caseprofileid)':
        case '[dbo].[hasallinthelist](@list1, @caseprofileid)':
        case '@caseprofileid':
          inputtype = 'Case Profile';
          break;
        case '[dbo].[hasinthelist](@list4, @flagid)':
        case '[dbo].[hasallinthelist](@list4, @flagid)':
        case '@flagid':
          inputtype = 'Flags';
          break;
        case '[dbo].[hasinthelist](@list5, @orderingfacilityid)':
        case '[dbo].[hasallinthelist](@list5, @orderingfacilityid)':
        case '@orderingfacilityid':
          inputtype = 'Ordering Facility';
          break;
        case '[dbo].[hasinthelist](@list6, @patientage)':
        case '[dbo].[hasallinthelist](@list6, @patientage)':
        case '@patientage':
          inputtype = 'Patient Age';
          break;
        case '[dbo].[hasinthelist](@list7, @pathologistid)':
        case '[dbo].[hasallinthelist](@list7, @pathologistid)':
        case '@pathologistid':
          inputtype = 'Pathologist';
          break;
        case '[dbo].[hasinthelist](@list8, @screeningtypeid)':
        case '[dbo].[hasallinthelist](@list8, @screeningtypeid)':
        case '@screeningtypeid':
          inputtype = 'Screening Types';
          break;
        case '[dbo].[hasinthelist](@list2, @procedureid)':
        case '[dbo].[hasallinthelist](@list2, @procedureid)':
        case '@procedureid':
          inputtype = 'Procedures';
          break;
        case '[dbo].[hasinthelist](@list9, @testcategory)':
        case '[dbo].[hasallinthelist](@list9, @testcategory)':
        case '@testcategory':
          inputtype = 'Panel';
          break;
        case '[dbo].[hasinthelist](@list10, @services)':
        case '[dbo].[hasallinthelist](@list10, @services)':
        case '@services':
          inputtype = 'Services';
          break;
        case '[dbo].[hasinthelist](@list12, @organizationtagid)':
        case '[dbo].[hasallinthelist](@list12, @organizationtagid)':
        case '@organizationtagid':
          inputtype = 'Case Tags';
          break;

        case '[dbo].[hasinthelist](@list13, @userid)':
        case '[dbo].[hasallinthelist](@list13, @userid)':
        case '@userid':
          inputtype = 'Sign-out User';
          break;

        case '[dbo].[hasinthelist](@list11, @accountid)':
        case '[dbo].[hasallinthelist](@list11, @accountid)':
        case '@accountid':
          inputtype = 'Reading Location';
          break;

        case '[dbo].[hasinthelist](@list14, @orgdiagtemplateid)':
        case '[dbo].[hasallinthelist](@list14, @orgdiagtemplateid)':
        case '@orgdiagtemplateid':
          inputtype = 'Diagnosis Templates';
          break;

        case '[dbo].[hasinthelist](@list16, @adequacy)':
        case '[dbo].[hasallinthelist](@list16, @adequacy)':
        case '@adequacy':
          inputtype = 'Adequacy';
          break;

        case '[dbo].[hasinthelist](@list15, @ordercodeid)':
        case '[dbo].[hasallinthelist](@list15, @ordercodeid)':
        case '@ordercodeid':
          inputtype = 'Order Code';
          break;
        case '[dbo].[hasinthelist](@list17, @testid)':
        case '[dbo].[hasallinthelist](@list17, @testid)':
        case '@testid':
          inputtype = 'Tests';
          break;
        case '[dbo].[hasinthelist](@list22, @materialtype)':
        case '[dbo].[hasallinthelist](@list22, @materialtype)':
        case '@materialtype':
          inputtype = 'Material Type';
          break;
        case '[dbo].[hasinthelist](@list21, @siteprocedure)':
        case '[dbo].[hasallinthelist](@list21, @siteprocedure)':
        case '@siteprocedure':
          inputtype = 'Extraction Procedure';
          break;
        case '[dbo].[hasinthelist](@list18, @bodysite)':
        case '[dbo].[hasallinthelist](@list18, @bodysite)':
        case '@bodysite':
          inputtype = 'Body Site';
          break;
        case '[dbo].[hasinthelist](@list19, @subsiteid)':
        case '[dbo].[hasallinthelist](@list19, @subsiteid)':
        case '@subsiteid':
          inputtype = 'Sub Site';
          break;
        case '[dbo].[hasinthelist](@list20, @sitelocationid)':
        case '[dbo].[hasallinthelist](@list20, @sitelocationid)':
        case '@sitelocationid':
          inputtype = 'Site Location';
          break;
      }
      inputArray.push(inputtype);
      this.input[abc] = inputtype;
      abc++;

      // Parts[i + 1].match(/in/i) ? operatorArray.push('has any of') : Parts[i + 1].match(/=/i) && !inputtype.match(/patient age/i) ? operatorArray.push('is') : operatorArray.push(Parts[i + 1]);
      Parts[i].includes("[dbo].[HasInTheList]") ?
        operatorArray.push('has any of') :
        (Parts[i].includes("[dbo].[HasAllInTheList]") ?
          operatorArray.push('has all of') :
          (Parts[i + 1].match(/=/i) && !inputtype.match(/patient age/i) ?
            operatorArray.push('is') :
            operatorArray.push(Parts[i + 1])));

      // valueArray.push(Parts[i + 2].replace('(', '').replace(')', ''));
      if (!(Parts[i].includes("[dbo].[HasInTheList]") || Parts[i].includes("[dbo].[HasAllInTheList]"))) {
        valueArray.push(Parts[i + 2].replace(/[()]/g, '').replace(/'/g, ''));
        // parameterIdArray.push('');
        ((i + 3) < Parts.length) ? conditionArray.push(Parts[i + 3].toUpperCase()) : conditionArray.push('');
      }
      else if (Parts[i].includes("[dbo].[HasInTheList]") || Parts[i].includes("[dbo].[HasAllInTheList]")) {
        // valueArray.push(Parts[i + 1].replace(/;/g, ','));
        let tempinputtype = inputtype.replace(' ', '').toLowerCase();
        let tempValue = '';
        for (let a = 0; a < tempArray.length; a++) {
          if (tempArray[a][tempinputtype] != undefined && tempArray[a][tempinputtype] != null && tempArray[a][tempinputtype] != "") {
            tempValue = tempArray[a][tempinputtype];
            parameterIdArray.push(tempArray[a].parameterId);
            break;
          }
        }
        valueArray.push(tempValue.replace(/;/g, ','));
        ((i + 1) < Parts.length) ? conditionArray.push(Parts[i + 1].toUpperCase()) : conditionArray.push('');
      }

      // while (((i + 4) < Parts.length) && (Parts[i].toLowerCase() == Parts[i + 4].toLowerCase())) {
      //   operatorArray[operatorArray.length - 1] = 'has all of';
      //   i += 4;
      //   valueArray[valueArray.length - 1] = valueArray[valueArray.length - 1] + ',' + Parts[i + 2];
      //   conditionArray[conditionArray.length - 1] = ((i + 3) < Parts.length) ? Parts[i + 3] : '';
      // }

    }
    this.parameterIdArray = parameterIdArray;
    this.addToConditionJson(inputArray, operatorArray, valueArray, conditionArray);
  }

  async addToConditionJson(inputArray, operatorArray, valueArray, conditionArray) {
    await this.getDataForInput(inputArray, valueArray, operatorArray, conditionArray);
  }

  async getDataForInput(inputArray, valueArray, operatorArray, conditionArray) {
    this.ngxService.start();
    let dataValues
    let valuesToShow
    let values = []
    this.inputDataarray = []
    for (let i = 0; i < inputArray.length; i++) {
      this.operatorValue = [];
      let obj = {
        organizationID: sessionStorage.getItem('org_id'),
        CaseType: this.templateData.secondarykeys.casetype,
        AccountID: this.templateData.cardIdentifier,
        ParentID: this.fetchParentID(this.DynamicJson, inputArray[i]),
        InputType: inputArray[i]
      }
      let dbName = sessionStorage.getItem('DeploymentKey')
      dataValues = await this.vitalHttpServices.getValuesForInput(obj, dbName).toPromise();
      if (dataValues && dataValues.length > 0) {
        if (dataValues.some(va => va.ID))
          dataValues = [...new Map(dataValues.map((item) => [item['ID'], item])).values()];
      }
      this.valuesForInput[inputArray[i]] = dataValues;
      if (operatorArray[i].match(/is/i)) {
        if (!inputArray[i].match(/panel/i) && !inputArray[i].match(/services/i) && !inputArray[i].match(/billto/i) && !inputArray[i].match(/material type/i)) {
          valuesToShow = dataValues.filter(value => (value.ID) == valueArray[i])
          values[i] = valuesToShow
        }
        else {
          values.push([{ Name: valueArray[i] }])
        }
      }
      else if (operatorArray[i].match(/has a.. of/i)) {
        let valueString = []
        if (!inputArray[i].match(/panel/i) && !inputArray[i].match(/services/i) && !inputArray[i].match(/billto/i) && !inputArray[i].match(/material type/i)) {
          for (let j = 0; j < valueArray[i].split(',').length; j++) {
            valuesToShow = dataValues.filter(value => (value.ID) == valueArray[i].split(',')[j])
            if (valuesToShow.length > 0) {
              valueString.push(valuesToShow[0])
            }
          }
          this.datastring[inputArray[i]] = valueArray[i];
          values[i] = valueString
        }
        else {
          for (let j = 0; j < valueArray[i].split(',').length; j++) {
            valuesToShow = valueArray[i].split(',')[j]
            valueString.push(valuesToShow)
          }
          this.datastring[inputArray[i]] = valueArray[i]
          values[i] = valueString
        }
        for (let j = 0; j < values[i].length; j++) {
          if (typeof (values[i][j]) == 'string') {
            if (values[i][j].match(/[0-9]+/g)) {
              let value = dataValues.filter(va => va.ID == values[i][j])
              values[i][j] = value.length > 0 ? value[0] : [];
            }
            else {
              let value = dataValues.filter(va => va.Name == values[i][j])
              values[i][j] = value.length > 0 ? value[0] : [];
            }
          }
        }
      }
      else {
        values[i] = valueArray[i]
      }

      // if (!inputArray[i].match(/services/i)) {
      this.inputDataarray.push(dataValues);
      // }
      // else {
      // this.inputDataarray.push([{ Name: "Technical Only" }, { Name: "Global" }, { Name: "Professional Only" }, { Name: "Consult" }]);
      // }

      let conditionData = []
      conditionData.push('AND');
      conditionData.push('OR');
      let compareString = '';
      for (let j = 0; j < this.InpOpData.InputType.length; j++) {
        compareString = this.InpOpData.InputType[j].Value.toString().toLowerCase().trim();
        if (compareString.includes(inputArray[i].toString().toLowerCase())) {
          let operatorsString = compareString.split(" - ")[1]
          let operators = operatorsString.split(',');
          for (let k = 0; k < operators.length; k++) {
            this.operatorValue.push(operators[k])
          }
        }
      }
      this.conditionValueData = { Controls: [] };
      if (!inputArray[i].match(/Patient Age/i)) {
        if (operatorArray[i].match(/is/i)) {
          this.singlesearchInput[i] = values && values[i].length > 0 ? values[i][0].Name : [];
          this.conditionValueData = {
            Controls: [
              {
                type: 'select',
                value: inputArray[i],
                label: 'Input Type',
                data: this.inpList,
              formControl : new FormControl(inputArray[i],Validators.required)                
              },
              {
                type: 'select',
                value: operatorArray[i],
                label: 'Operator',
                data: this.operatorValue,
              formControl : new FormControl(operatorArray[i],Validators.required)                
              },
              {
                type: 'singleselect',
                value: values[i],
                label: 'Value',
                data: this.inputDataarray[i],
              formControl : new FormControl(values[i][0].Name,Validators.required)
                
              },
              {
                type: 'select',
                value: conditionArray[i],
                label: 'Condition',
                data: conditionData,
              formControl : new FormControl(conditionArray[i],Validators.required)                
              }
            ]
          }
        }
        else {
          this.conditionValueData = {
            Controls: [
              {
                type: 'select',
                value: inputArray[i],
                label: 'Input Type',
                data: this.inpList,
              formControl : new FormControl(inputArray[i],Validators.required)                
              },
              {
                type: 'select',
                value: operatorArray[i],
                label: 'Operator',
                data: this.operatorValue,
              formControl : new FormControl(operatorArray[i],Validators.required)
                
              },
              {
                type: 'multiselect',
                value: values[i],
                label: 'Value',
                data: this.inputDataarray[i],
                selections: new SelectionModel<Element>(false, []),
              formControl : new FormControl("",Validators.required)                
              },
              {
                type: 'select',
                value: conditionArray[i],
                label: 'Condition',
                data: conditionData,
              formControl : new FormControl(conditionArray[i],Validators.required)                
              }
            ]
          }
        }
      }
      else if (inputArray[i].match(/Patient Age/i)) {
        this.conditionValueData = {
          Controls: [
            {
              type: 'select',
              value: inputArray[i],
              label: 'Input Type',
              data: this.inpList,
              formControl : new FormControl(inputArray[i],Validators.required)              
            },
            {
              type: 'select',
              value: operatorArray[i],
              label: 'Operator',
              data: this.operatorValue,
              formControl : new FormControl(operatorArray[i],Validators.required)              
            },
            {
              type: 'text',
              value: values[i],
              label: 'Value',
              data: '',
              formControl : new FormControl(values[i],Validators.required)              
            },
            {
              type: 'select',
              value: conditionArray[i],
              label: 'Condition',
              data: conditionData,
              formControl : new FormControl(conditionArray[i],Validators.required)              
            }
          ]
        }
      }
      else {
        if (operatorArray[i].match(/is/i)) {
          this.singlesearchInput[i] = values && values[i].length > 0 ? values[i][0].Name : [];          
          this.conditionValueData = {
            Controls: [
              {
                type: 'select',
                value: inputArray[i],
                label: 'Input Type',
                data: this.inpList,
              formControl : new FormControl(inputArray[i],Validators.required)                
              },
              {
                type: 'select',
                value: operatorArray[i],
                label: 'Operator',
                data: this.operatorValue,
              formControl : new FormControl(operatorArray[i],Validators.required)                
              },
              {
                type: 'singleselect',
                value: values[i],
                label: 'Value',
                data: [{ Name: "Technical Only" }, { Name: "Global" }, { Name: "Professional Only" }, { Name: "Consult" }],
                formControl : new FormControl(values[i][0].Name,Validators.required)                
              },
              {
                type: 'select',
                value: conditionArray[i],
                label: 'Condition',
                data: conditionData,
              formControl : new FormControl(conditionArray[i],Validators.required)                
              }
            ]
          }
        }
        else {
          this.conditionValueData = {
            Controls: [
              {
                type: 'select',
                value: inputArray[i],
                label: 'Input Type',
                data: this.inpList,
              formControl : new FormControl(inputArray[i],Validators.required)                
              },
              {
                type: 'select',
                value: operatorArray[i],
                label: 'Operator',
                data: this.operatorValue,
              formControl : new FormControl(operatorArray[i],Validators.required)                
              },
              {
                type: 'multiselect',
                value: values[i],
                label: 'Value',
                data: [{ Name: "Technical Only" }, { Name: "Global" }, { Name: "Professional Only" }, { Name: "Consult" }],
              formControl : new FormControl("",Validators.required)                
              },
              {
                type: 'select',
                value: conditionArray[i],
                label: 'Condition',
                data: conditionData,
              formControl : new FormControl(conditionArray[i],Validators.required)                
              }
            ]
          }
        }
      }
      this.allSelectDropDown.push(false);
      this.DynamicJson.push(this.conditionValueData);
      if (this.DynamicJson[i].Controls[2].type == 'multiselect') {
        this.DynamicJson[i].Controls[2].formCtrl = new FormControl(values.join(','))
      }
    }
    this.saveDisclaimerForm = true;
    this.ngxService.stop();
  }

  //check for repeat for each test click
  setRepeatTest(value) {
    if (value == "Case") {
      //this._snackBar.open("Disclaimers can't be repeated at the Case level.", "Close");
      this.disclaimerForm.patchValue({
        frmrepeatfortests: false
      })
      return true;
    }
    return false;
  }

  //method to add another condition instance
  addCondition(i, conditionSelected?) {
    let conditionCheck = 0;
    for (let j = 0; j < this.DynamicJson[i].Controls.length; j++) {
      if (this.DynamicJson[i].Controls[j].value == "") {
        conditionCheck++;
      }
    }
    let checkNextIndex = (conditionSelected && this.DynamicJson[i + 1] && this.DynamicJson[i + 1].Controls.length > 0)
    if (conditionCheck > 0 || checkNextIndex) {
      if (!conditionSelected) {
        this._snackBar.open("Please enter all the input parameters before proceeding to the next Condition.", "Close");
        this.setFormErrors(true);
      }
      return;
    }
    else {
      this.parentChildSelection(i);
    }
  }

  //method to update input list for each iteration
  getInputs(input, value, ind) {
    if (input.label.toString().toLowerCase().trim() == 'input type') {
      for (let i = 0; i < this.input.length; i++) {
        if (this.input[ind] !== value) {
          let nue = this.input.filter(va => va == value)
          if (nue.length > 0 || this.parentSelected(value) || this.childSelected(value)) {
            return true;
          } else { return false }
        } else { return false }
      }
    }
    else if (input.label.toString().toLowerCase().trim() == 'condition') {
      let inputType = this.DynamicJson[ind].Controls[0].value;
      if (Object.values(this.parentChildInput).includes(inputType)) {
        let childName = Object.keys(this.parentChildInput).find(key => this.parentChildInput[key] === inputType);
        if (this.siteFormat == 2 && childName.match(/sub site/i)) {
          return this.DynamicJson.some(va => va.Controls[0].value == childName && value == 'OR');
        }
        else if (!childName.match(/sub site/i)) {
          return this.DynamicJson.some(va => va.Controls[0].value == childName && value == 'OR');
        }
        else {
          return false
        }
      }
    }
    return false;
  }

  //Hide parent when only child is selected
  childSelected(value) {
    let parentsToBeHidden = Object.values(this.parentChildInput);
    let childSelected = this.DynamicJson.filter(va => Object.keys(this.parentChildInput).includes(va.Controls[0].value))
    if (childSelected && childSelected.length > 0) {
      if (value && childSelected.some(va => va.Controls[0].value == Object.keys(this.parentChildInput).find(key => this.parentChildInput[key] == value))) {
        return parentsToBeHidden.some(va => va == value) && ((value.match(/body site/i) && this.siteFormat == 2) || !value.match(/body site/i)) && childSelected.length;
      }
      else {
        return false;
      }
    }
    return false;
  }

  //Hide parent when only child is selected and or condition is selected
  parentSelected(value) {
    let parentConditions = this.DynamicJson.filter(va => Object.values(this.parentChildInput).some(val => val == va.Controls[0].value) && va.Controls[3].value.match(/or/i))
    let childrenToBeHidden = Object.keys(this.parentChildInput).filter(key => parentConditions.some(val => val.Controls[0].value == this.parentChildInput[key]));
    if (value.match(/sub site/)) {
      return childrenToBeHidden.some(va => va == value) && this.siteFormat == 2;
    }
    return childrenToBeHidden.some(va => va == value);
  }

  // reset checkbox
  resetCheckedBox(value) {
    if (value.toString().trim().toLowerCase() == "Case") {
      this.disclaimerForm.patchValue({
        frmdisctype: value,
        frmrepeatfortests: false
      })
    }
  }

  //method to hit once deletion is confirmed.
  confirmDeleteDisc() {
    if (this.deleteForm.value.frmdeletereason == "") {
      this._snackBar.open("Please provide a reason before deleting the Disclaimer.", "Close");
    }
    else {
      let obj = {
        DisclaimerID: this.selectedrowData.DisclaimerId,
        ConditionID: this.selectedrowData.ConditionID,
        OrganizationID: this.templateData.secondarykeys.OrganizationId,
        UserID: sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
        AccountID: this.templateData.cardIdentifier,
        DeleteReason: this.deleteForm.value.frmdeletereason
      }
      this.deleteForm.patchValue({
        frmdeletereason: ""
      })
      this.vitalHttpServices.DeleteDisclaimer(obj).subscribe(value => {
        if (value.Success) {
          this._snackBar.open(value.Message, "Close")
          this.closeModal();
          this.refreshDisclaimergrid();
        }
        else {
          this._snackBar.open("Something went wrong. Please try again", "Close");
          this.closeModal();
        }
      }, error => {
        this._snackBar.open("Something went wrong. Please try again", "Close");
        this.closeModal();
      })
    }
  }

  //method to set values for each field in condition
  async setInputFormValues(index, value, label) {
    if (label.toString().trim().toLowerCase() == "input type") {
      this.operatorValue = [];
      this.conditionData = [];
      this.valueList = [];
      let compareString = "";
      for (let i = 0; i < this.InpOpData.InputType.length; i++) {
        compareString = this.InpOpData.InputType[i].Value.toString().toLowerCase().trim();
        if (compareString.includes(value.toString().toLowerCase())) {
          let operatorsString = compareString.split(" - ")[1]
          let operators = operatorsString.split(',');
          for (let i = 0; i < operators.length; i++) {
            this.operatorValue.push(operators[i])
          }
        }
      }
      this.conditionData.push("AND");
      this.conditionData.push("OR");
      //Input Type
      this.DynamicJson[index].Controls[0].value = value;
      this.checkIfChildReSelected(index, value);
      //Operators
      let operatorValues = JSON.parse(JSON.stringify(this.operatorValue))
      this.DynamicJson[index].Controls[1] = {
        type: 'select',
        value: operatorValues.length == 1 ? operatorValues[0] : 'is',
        label: 'Operator',
        data: operatorValues,
        formControl : new FormControl(operatorValues.length == 1 ? operatorValues[0] : 'is',Validators.required)              
      }
      //Values
      if (operatorValues.length > 1) {
        this.DynamicJson[index].Controls[2] = {
          type: 'text',
          value: '',
          label: 'Value',
          data: '',
          formControl : new FormControl("",Validators.required)              
        }
      }
      else {
        this.DynamicJson[index].Controls[2] = {
          type: 'text',
          value: '',
          label: 'Value',
          data: '',
          formControl : new FormControl("",Validators.required)              
        }
        if (this.valuesForInput[value] && this.valuesForInput[value].length == 1) {
          this.setInputFormValues(index, this.valuesForInput[value][0], 'Value')
        }
      }
      //Conditions
      this.DynamicJson[index].Controls[3] = {
        type: 'select',
        value: '',
        label: 'Condition',
        data: this.conditionData,
        formControl : new FormControl("",Validators.required)              
      }
      this.input[index] = value;
      this.operator.splice(index, 1);
      this.condition.splice(index, 1);
      await this.setInputFormValues(index, this.DynamicJson[index].Controls[1].value, 'Operator');
      this.checkChildSelected(value, 'operator');

    }
    else if (label.toString().trim().toLowerCase() == "operator") {
      this.DynamicJson[index].Controls[1].value = value;
      this.DynamicJson[index].Controls[1].formControl.value = value;      
      this.allSelected = false;
      await this.getValuesForInput(this.DynamicJson[index].Controls, index)
      let inputType = this.DynamicJson[index].Controls[0].value
      //Set Values if a single value exists in the data
      if (this.valuesForInput[inputType] && this.valuesForInput[inputType].length == 1) {
        if (value == 'is') {
          this.setInputFormValues(index, this.valuesForInput[inputType][0], 'Value')
        }
        else {
          this.DynamicJson[index].Controls[2].value = this.valuesForInput[inputType];
          this.setMultiselectValues(index, this.valuesForInput[inputType])
        }
      }
      this.checkChildSelected(inputType, 'operator');
    }
    else if (label.toString().trim().toLowerCase() == "condition") {
      this.DynamicJson[index].Controls[3].value = value;
      this.addCondition(index, true)
    }
    else {
      if (this.DynamicJson[index].Controls[0].value.toString().trim().toLowerCase() == 'patient age') {
        this.DynamicJson[index].Controls[2].value = value;
      }
      else {
        if (this.DynamicJson[index].Controls[0].value.toString().trim().toLowerCase() != 'panel' && this.DynamicJson[index].Controls[0].value.toString().trim().toLowerCase() != 'services' && this.DynamicJson[index].Controls[0].value.toString().trim().toLowerCase() != 'material type') {
          if (this.DynamicJson[index].Controls[1].value == 'is') {
            this.DynamicJson[index].Controls[2].value = [];
            this.singlesearchInput[index] = value.Name;
            this.singlesearchvalue = this.singlesearchInput[index];
            this.DynamicJson[index].Controls[2].value.push({ ID: value.ID, Name: value.Name });
            this.toggle[index] = false
          }
          else {
            this.searchInput[index] = "";
            this.searchInput[index] = value.Name;
            this.searchvalue = this.searchInput[index];
            this.DynamicJson[index].Controls[2].value += value.ID + ',';
          }
        }
        else {
          if (this.DynamicJson[index].Controls[1].value == 'is') {
            this.DynamicJson[index].Controls[2].value = [];
            this.singlesearchInput[index] = value.Name;
            this.singlesearchvalue = this.singlesearchInput[index];
            this.DynamicJson[index].Controls[2].value.push({ Name: value.Name });
            this.toggle[index] = false
          }
          else {
            this.searchInput[index] = "";
            this.searchInput[index] = value.Name;
            this.searchvalue = this.searchInput[index];
            this.DynamicJson[index].Controls[2].value += value.Name + ',';
          }
        }
        this.checkChildSelected(this.DynamicJson[index].Controls[0].value, 'value');
      }
    }
  }



  //method to get values for selected input type
  async getValuesForInput(inputvalue, index) {
    let inputtype = inputvalue[0].value
    if (this.DynamicJson[index].Controls[0].value.toString().toLowerCase() != 'patient age') {
      let obj = {
        organizationID: sessionStorage.getItem('org_id'),
        CaseType: this.templateData.secondarykeys.casetype,
        AccountID: this.templateData.cardIdentifier,
        InputType: inputtype,
        ParentID: this.fetchParentID(this.DynamicJson, this.DynamicJson[index].Controls[0].value)
      }
      let dbName = sessionStorage.getItem('DeploymentKey');
      this.ngxService.start();
      await this.vitalHttpServices.getValuesForInput(obj, dbName).toPromise().then((value: any) => {
        this.ngxService.stop();
        if (value && value.length > 0) {
          if (value.some(va => va.ID)) {
            value = [...new Map(value.map((item) => [item['ID'], item])).values()];
          }
          for (let i = 0; i < value.length; i++) {
            value[i].checked = -1
          }
          this.valuesForInput[inputtype] = value;
          if (inputvalue[1].value == 'is') {
            this.DynamicJson[index].Controls[2] = {
              type: 'singleselect',
              value: '',
              label: 'Value',
              data: value,
              formControl : new FormControl("",Validators.required)              
            }
            this.inputDataarray[index] = value
          }
          else {
            this.DynamicJson[index].Controls[2] = {
              type: 'multiselect',
              value: '',
              label: 'Value',
              data: value,
              selections: new SelectionModel<Element>(false, []),   
              formControl : new FormControl("",Validators.required)
            }
          }
        }
        else {
          this.ngxService.stop();
          this._snackBar.open("There are no values available for the selected input type.", "Close")
        }
      }, error => {
        this.ngxService.stop();
        console.error(error);
      }
      );
    } else if (this.DynamicJson[index].Controls[0].value.toString().toLowerCase() == 'patient age') {
      this.DynamicJson[index].Controls[2] = {
        type: 'text',
        value: '',
        label: 'Value',
        data: ''
      }
    }
  }


  // dynamic tooltip for single select
  checkData(inputData) {
    if (inputData !== undefined && inputData !== null && inputData !== '') {
      let uniqObj = inputData.data.find(va => va.ID == inputData.value)
      if (uniqObj !== undefined) {
        return uniqObj.Name
      } else {
        return ''
      }
    }
  }

  // dynamic tooltip multi select
  buildTitle(value) {
    if (value.length > 0) {
      return value.join(",")
    }
  }

  // method to set IDs for names in value dropdown
  setMultiselectValues(index, dataarray) {
    let filteredData = [];
    // dataarray = dataarray.value;
    for (let i = 0; i < dataarray.length; i++) {
      if (this.DynamicJson[index].Controls[0].value.toString().toLowerCase() !== 'panel' && this.DynamicJson[index].Controls[0].value.toString().toLowerCase() !== 'services' && this.DynamicJson[index].Controls[0].value.toString().toLowerCase() !== 'billto' && this.DynamicJson[index].Controls[0].value.toString().toLowerCase() !== 'material type') {
        if (this.valuesForInput) {
          let matchingData = []
          if (dataarray[i].ID) {
            matchingData = this.valuesForInput[this.DynamicJson[index].Controls[0].value].filter(va => va.ID == dataarray[i].ID)
          }
          else {
            matchingData = this.valuesForInput[this.DynamicJson[index].Controls[0].value].filter(va => va.Name == dataarray[i].Name)
          }
          filteredData.push(matchingData[0].ID);
        }
      }
      else {
        filteredData.push(dataarray[i].Name);
      }
    }
    this.datastring[this.DynamicJson[index].Controls[0].value] = filteredData.join(",")
    this.checkChildSelected(this.DynamicJson[index].Controls[0].value, 'value')
  }
  onchangedesc(val) {
    if (val == 'Case') {
      this.disclaimerForm.controls.frmrepeatfortests.disable()
      this.disclaimerForm.patchValue({
        frmdisctype: val,
        frmrepeatfortests: false
      })
    } else {
      this.disclaimerForm.controls.frmrepeatfortests.enable()
    }
  }

  checkFordisabled(index) {
    if (this.DynamicJson[index].Controls[0].value != 'Patient Age') {
      return true;
    }
    else {
      return false;
    }
  }

  // method to add another form to push to get a new instance of condition
  addForms(operatorValue?, conditionData?) {
    operatorValue = operatorValue ? operatorValue : this.operatorValue;
    conditionData = conditionData ? conditionData : this.conditionData;
    this.conditionValueData = {
      Controls: [
        {
          type: 'select',
          value: '',
          label: 'Input Type',
          data: this.inpList,
          formControl : new FormControl("",Validators.required)                    
        },
        {
          type: 'select',
          value: '',
          label: 'Operator',
          data: operatorValue,
          formControl : new FormControl("",Validators.required)                    
        },
        {
          type: 'text',
          value: '',
          label: 'Value',
          data: '',
          formControl : new FormControl("",Validators.required)                    
        },
        {
          type: 'select',
          value: '',
          label: 'Condition',
          data: conditionData,
          formControl : new FormControl("",Validators.required)                    
        }
      ]
    }
    this.allSelectDropDown.push(false);
    this.DynamicJson.push(this.conditionValueData)
  }


  //deletes a condition instance
  deleteCondition(position) {
    this.input.splice(position, 1);
    this.condition.splice(position, 1);
    this.operator.splice(position, 1);
    if (this.DynamicJson[position].Controls[0].value && this.parentChildInput[this.DynamicJson[position].Controls[0].value]) {
      this.checkIfChildDeleted(position);
    }
    else {
      this.checkIfParentDeleted(position);
    }
    this.allSelectDropDown.splice(position, 1);
    this.DynamicJson.splice(position, 1);
    if (this.DynamicJson.length == 0) {
      this.addForms([], []);
    }
  }

  //aggregates all the conditions and gives a single string
  createCondition() {
    let condition: any = '';
    let inputValue = '';
    let inputValue2 = '';
    let parameterValue = '';
    let propertyName = '';
    let conditionVariable = ''
    let listJson: any = [];
    for (let i = 0; i < this.DynamicJson.length; i++) {
      for (let j = 0; j < this.DynamicJson[i].Controls.length; j++) {
        if (this.DynamicJson[i].Controls[j].label.toString().toLowerCase() == "input type") {
          switch (this.DynamicJson[i].Controls[j].value.toString().toLowerCase()) {
            case "billto":
              inputValue = '[dbo].[HasInTheList](@List3, @BillTo) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List3, @BillTo) = 1'
              parameterValue = '@List3';
              propertyName = 'BillTo'
              conditionVariable = 'BillTo';
              break;
            case "case profile":
              inputValue = '[dbo].[HasInTheList](@List1, @CaseProfileId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List1, @CaseProfileId) = 1'
              parameterValue = '@List1';
              propertyName = 'CaseProfileId'
              conditionVariable = 'CaseProfileId';
              break;
            case "flags":
              inputValue = '[dbo].[HasInTheList](@List4, @FlagId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List4, @FlagId) = 1'
              parameterValue = '@List4';
              propertyName = 'FlagId'
              conditionVariable = 'FlagId';
              break;
            case "ordering facility":
              inputValue = '[dbo].[HasInTheList](@List5, @OrderingFacilityId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List5, @OrderingFacilityId) = 1'
              parameterValue = '@List5';
              propertyName = 'OrderingFacilityId'
              conditionVariable = 'OrderingFacilityId'
              break;
            case "patient age":
              inputValue = '[dbo].[HasInTheList](@List6, @PatientAge) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List6, @PatientAge) = 1'
              parameterValue = '@List6';
              propertyName = 'Patient Age'
              conditionVariable = 'PatientAge'
              break;
            case "pathologist":
              inputValue = '[dbo].[HasInTheList](@List7, @PathologistId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List7, @PathologistId) = 1'
              parameterValue = '@List7';
              propertyName = 'PathologistId'
              conditionVariable = 'PathologistId'
              break;
            case "screening types":
              inputValue = '[dbo].[HasInTheList](@List8, @ScreeningTypeId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List8, @ScreeningTypeId) = 1'
              parameterValue = '@List8';
              propertyName = 'ScreeningTypeId'
              conditionVariable = 'ScreeningTypeId'
              break;
            case "procedures":
              inputValue = '[dbo].[HasInTheList](@List2, @ProcedureId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List2, @ProcedureId) = 1'
              parameterValue = '@List2';
              propertyName = 'ProcedureId'
              conditionVariable = 'ProcedureId'
              break;
            case "panel":
              inputValue = '[dbo].[HasInTheList](@List9, @TestCategory) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List9, @TestCategory) = 1'
              parameterValue = '@List9';
              propertyName = 'TestCategory'
              conditionVariable = 'TestCategory'
              break;
            case "services":
              inputValue = '[dbo].[HasInTheList](@List10, @Services) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List10, @Services) = 1'
              parameterValue = '@List10';
              propertyName = 'Services'
              conditionVariable = 'Services'
              break;
            case "reading location":
              inputValue = '[dbo].[HasInTheList](@List11, @AccountId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List11, @AccountId) = 1'
              parameterValue = '@List11';
              propertyName = 'ReadingLocation'
              conditionVariable = 'AccountId'
              break;
            case "case tags":
              inputValue = '[dbo].[HasInTheList](@List12, @OrganizationTagId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List12, @OrganizationTagId) = 1'
              parameterValue = '@List12';
              propertyName = 'CaseTags'
              conditionVariable = 'OrganizationTagId'
              break;
            case "sign-out user":
              inputValue = '[dbo].[HasInTheList](@List13, @UserId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List13, @UserId) = 1'
              parameterValue = '@List13';
              propertyName = 'Sign-OutUser'
              conditionVariable = 'UserId'
              break;
            case "diagnosis templates":
              inputValue = '[dbo].[HasInTheList](@List14, @OrgDiagTemplateId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List14, @OrgDiagTemplateId) = 1'
              parameterValue = '@List14';
              propertyName = 'DiagnosisTemplates'
              conditionVariable = 'OrgDiagTemplateId'
              break;
            case "adequacy":
              inputValue = '[dbo].[HasInTheList](@List16, @Adequacy) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List16, @Adequacy) = 1'
              parameterValue = '@List14';
              propertyName = 'Adequacy'
              conditionVariable = 'Adequacy'
              break;
            case "order code":
              inputValue = '[dbo].[HasInTheList](@List15, @OrderCodeId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List15, @OrderCodeId) = 1'
              parameterValue = '@List15';
              propertyName = 'OrderCode'
              conditionVariable = 'OrderCodeId'
              break;
            case "tests":
              inputValue = '[dbo].[HasInTheList](@List17, @TestId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List17, @TestId) = 1'
              parameterValue = '@List17';
              propertyName = 'Tests'
              conditionVariable = 'TestId'
              break;
            case "extraction procedure":
              inputValue = '[dbo].[HasInTheList](@List21, @SiteProcedure) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List21, @SiteProcedure) = 1'
              parameterValue = '@List21';
              propertyName = 'SiteProcedure'
              conditionVariable = 'SiteProcedure';
              break;
            case "material type":
              inputValue = '[dbo].[HasInTheList](@List22, @MaterialType) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List22, @MaterialType) = 1'
              parameterValue = '@List22';
              propertyName = 'MaterialType'
              conditionVariable = 'MaterialType';
              break;
            case "body site":
              inputValue = '[dbo].[HasInTheList](@List18, @BodySite) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List18, @BodySite) = 1'
              parameterValue = '@List18';
              propertyName = 'BodySite'
              conditionVariable = 'BodySite';
              break;
            case "sub site":
              inputValue = '[dbo].[HasInTheList](@List19, @SubSiteId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List19, @SubSiteId) = 1'
              parameterValue = '@List19';
              propertyName = 'SubSiteId'
              conditionVariable = 'SubSiteId';
              break;
            case "site location":
              inputValue = '[dbo].[HasInTheList](@List20, @SiteLocationId) = 1'
              inputValue2 = '[dbo].[HasAllInTheList](@List20, @SiteLocationId) = 1'
              parameterValue = '@List20';
              propertyName = 'SiteLocationId'
              conditionVariable = 'SiteLocationId';
              break;
          }

          if (this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has any of') {
            condition += inputValue + ' ';
          }
          else if (this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has all of') {
            condition += inputValue2 + ' ';
          }
          else if (!(this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has any of' ||
            this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has all of')) {
            condition += '@' + conditionVariable;
          }
        }

        else if (this.DynamicJson[i].Controls[j].label.toString().toLowerCase() == "operator") {
          if (this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == 'is'
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '>'
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '>='
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '<'
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '<='
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '='
            || this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == '!='
          ) {
            condition += this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == 'is' ? ' = ' : ' ' + this.DynamicJson[i].Controls[j].value.toString() + ' ';
          }
          // else if (this.DynamicJson[i].Controls[j].value.toString().toLowerCase() == 'has any of') {
          //   condition += ' IN ';
          // }
        }
        else if (this.DynamicJson[i].Controls[j].label.toString().toLowerCase() == "value") {
          if (this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'is'
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '>'
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '>='
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '<'
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '<='
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '='
            || this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == '!=') {
            if (inputValue.match(/patientage/i)) {
              condition += this.DynamicJson[i].Controls[j].value + ' '
            }
            else if (!inputValue.match(/services/i) && !inputValue.match(/panel/i) && !inputValue.match(/billto/i) && !inputValue.match(/testcategory/i) && !inputValue.match(/materialtype/i)) {
              condition += this.DynamicJson[i].Controls[j].value[0].ID + ' '
            }
            else {
              condition += "'" + this.DynamicJson[i].Controls[j].value[0].Name + "' "
            }
          }
          else if (this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has any of' ||
            this.DynamicJson[i].Controls[1].value.toString().toLowerCase() == 'has all of') {
            if (!inputValue.match(/services/i) && !inputValue.match(/panel/i) && !inputValue.match(/billto/i) && !inputValue.match(/materialtype/i)) {
              listJson.push({ '0': propertyName, '1': this.datastring[this.DynamicJson[i].Controls[0].value], '2': parameterValue });
            }
            else {
              let data = this.datastring[this.DynamicJson[i].Controls[0].value].split(',')
              for (let i = 0; i < data.length; i++) {
                data[i] = data[i].replace(/'/g, '');
              }
              this.datastring[this.DynamicJson[i].Controls[0].value] = data.join(',');
              listJson.push({ '0': propertyName, '1': this.datastring[this.DynamicJson[i].Controls[0].value], '2': parameterValue });
            }
          }
        }
        else if (this.DynamicJson[i].Controls[j].label.toString().toLowerCase() == "condition") {
          if (i != this.DynamicJson.length - 1) {
            condition += this.DynamicJson[i].Controls[j].value + ' '
          }
        }
      }
    }
    condition = condition.trim();
    condition = { "condition": condition, "values": listJson }
    return condition;
  }

  //get button details(RBAC)
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }


  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  validateDisclaimer(flag, controls) {
    let values = {
      frmDescription: controls.frmDescription.value,
      frmcondition: controls.frmcondition.value,
      frmdisctype: controls.frmdisctype.value,
      frmdisplayorder: controls.frmdisplayorder.value,
      frmrepeatfortests: controls.frmrepeatfortests.value
    };
    let ifDuplciate = false;;
    this.finalFlag = flag;
    this.finalSave = values;
    if (this.actionEdit != 'edit') {
      if (this.templateData.submenuData) {
        for (var j = 0; j < this.templateData.submenuData.length; j++) {
          let str1 = '';
          if (values.frmDescription)
            str1 = values.frmDescription.toLowerCase().trim();
          else
            str1 = this.html.toLowerCase().trim();
          let str2 = this.templateData.submenuData[j].Description.toLowerCase().trim();
          str1 = this.removeTags(str1);
          str2 = this.removeTags(str2);
          if (str1 == str2) {
            this.dupDisclaimer = str1;
            ifDuplciate = true;
            break;
          }
          else {
            ifDuplciate = false;
          }
        }
      }
      if (!ifDuplciate) {
        this.saveNewForm(flag, values);
      } else {
        $('#setDisclaimerDupModal').appendTo("body").modal('show');
      }
    } else {
      this.saveNewForm(flag, values);
    }

  }

  SaveDupDisclaimer(flag, values) {
    $('#setDisclaimerDupModal').modal('hide');
    $('.setDisclaimerModal').modal('hide');
    $('.setDisclaimerDupModal').modal('hide');

    this.saveNewForm(flag, values);
  }
  closeDupModal() {
    $('#setDisclaimerDupModal').modal('hide');
    $('.setDisclaimerModal').modal('hide');
    $('.setDisclaimerDupModal').modal('hide');
  }

  //method to save the disclaimer
  saveNewForm(flag, values) {
    this.formSubmit = false;
    for (let i = 0; i < this.DynamicJson.length; i++) {
      for (let j = 0; j < this.DynamicJson[i].Controls.length; j++) {
        if (i == this.DynamicJson.length - 1 && j == 3) {
          continue;
        }
        else if (this.DynamicJson[i].Controls[j].value == "") {
          this._snackBar.open("Please select all the mandatory fields before saving!", "Close")
          this.formSubmit = true
          this.setFormErrors();
          return;
        }
      }
    }
    if (!values.frmDescription || values.frmDescription.replace(/<[^>]*>/g, '') == '') {
      this._snackBar.open("Please select all the mandatory fields before saving!", "Close")
      this.formSubmit = true
      this.disclaimerForm.controls.frmDescription.markAsTouched();
      return;
    }
    // if (values.frmDescription.trim().length > 2000) {
    //   this._snackBar.open("Please enter the Description upto 2000 characters!", "Close")
    //   return;
    // }
    let condition: any = ''
    condition = this.createCondition();
    if (condition.condition && condition.condition != "") {
      let disclaimerValues = [];
      // if (this.parameterIdArray.length <= 0) {
      condition.values.forEach(e => {
        disclaimerValues.push({
          "ParameterId": 0,
          "ParameterName": e['2'].toString(),
          "ResultCodeId": null,
          "OrderCodeId": null,
          "ParameterType": "List",
          'Property': e['0'].toString(),
          "Value": e['1'].toString().replace(/,/g, ';')
        })
      });
      // }
      // else if (this.parameterIdArray.length > 0) {
      //   condition.values.forEach((e, index) => {
      //     disclaimerValues.push({
      //       "ParameterId": this.parameterIdArray[index] || 0,
      //       "ParameterName": e['2'].toString(),
      //       "ResultCodeId": null,
      //       "OrderCodeId": null,
      //       "ParameterType": "List",
      //       'Property': e['0'].toString(),
      //       "Value": e['1'].toString().replace(/,/g, ';')
      //     })
      //   });
      // }
      let obj = {
        DisclaimerID: this.selectedrowData.DisclaimerId || 0,
        ConditionID: this.selectedrowData.ConditionID || 0,
        Sequence: values.frmdisplayorder == '' ? null : values.frmdisplayorder,
        OrganizationID: this.templateData.secondarykeys.OrganizationId,
        AccountID: this.templateData.cardIdentifier,
        IsActive: 1,
        RepeatForEachTest: values.frmrepeatfortests,
        Type: values.frmdisctype,
        CreatedBy: sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
        ModifiedBy: sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
        Condition: condition.condition,
        DisclaimerValue: values.frmDescription ? values.frmDescription : '',
        DisclaimerParamter: disclaimerValues,
        Casetype: this.templateData.GroupData.casetype.toString().trim()
      }
      this.ngxService.start();
      this.vitalHttpServices.SaveDisclaimer(obj).subscribe(value => {
        this.ngxService.stop();
        if (!value.errors) {
          if (value.Success) {
            this.showFlag = false
            this._snackBar.open(value.Message, "Close");
            this.DynamicJson = [];
            this.singlesearchInput = [];
            this.operatorValue = [];
            this.conditionData = [];
            this.criteria.setValue('')
            this.addForms();
            // this._snackBar.open("Disclaimer configuration saved successfully", "Close");
            this.disclaimerForm.patchValue({
              frmcondition: "",
              frmdisctype: "Test",
              frmrepeatfortests: false,
              frmdisplayorder: "",
              frmDescription: ""
            });
            this.selectedrowData = [];
            this.parameterIdArray = [];
            if (flag == 'saveandclose') {
              this.backtoGrid()
            }
          }
          else {
            this._snackBar.open("An error occurred !", "Close");
          }
        }
        else {
          this._snackBar.open("Something went wrong! Please try again", "Close");
          console.error(value.errors);
        }
      }, error => {
        this._snackBar.open("Something went wrong! Please try again", "Close");
        this.ngxService.stop();
        console.error(error)
      }
      )
    }
    else {
      this._snackBar.open("Something went wrong! Please try again", "Close");
    }
  }

  //method to refresh the grid
  refreshDisclaimergrid() {
    let queryVariable = { "orgid": this.templateData.secondarykeys.OrganizationId.toString(), "caseType": this.templateData.GroupData.casetype };
    let query = this.SubMenuCardModel.GetQuery("getTestDisclaimers");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.templateData.submenuData = [];

        this.templateData.submenuData = data.data.submenuData;
        this.AddGridData();
      }
    }, error => {

      console.error(error);
    });
  }


  //tooltip for disclaimers listing
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  //allows only number input
  AllowOnlyNumberValue(event: KeyboardEvent, value) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (value < 120) {
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
    else {
      event.preventDefault();
    }
  }


  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (this.disclaimerForm.value.frmdisplayorder.length < 2) {
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
    else {
      event.preventDefault();
    }
  }

  //method to add data to the grid
  AddGridData() {
    this.headerValue = 'Disclaimers'
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
                if (key.match(/Description/i)) {
                  primary['Disclaimer'] = value.toString().replace(/<[^>]*>/g, '');
                }
              }
            }
          }
          gridarray.push(primary)
        }
        gridarray.sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
      }
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
        this.hideGrid = false;
        this.noDataFound = false
      }
      else {
        this.gridShowDiv = false;
        this.hideGrid = false;
        this.noDataFound = true;
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * this.gridHeader.length) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
    } else {
      this.gridShowDiv = (this.templateData && this.templateData.labels && this.gridwidth > 0) ? true : false;
      this.gridwidth = 0;
      this.noDataFound = true;
    }
    this.ngxService.stop();
    // this.gridData = new CollectionView(this.templateData.submenuData, { pageSize: 10 })
    // this.gridwidth = (170 * this.templateData.labels.length) + 37;
    // if (this.gridwidth > 1300)
  }

  // takes the user back to the grid
  backtoGrid() {
    this.saveDisclaimerForm = false;
    this.DynamicJson = [];
    this.input = [];
    this.criteria.setValue(null)
    this.disclaimerForm.patchValue({
      frmcondition: "",
      frmdisctype: "Test",
      frmrepeatfortests: false,
      frmdisplayorder: "",
      frmDescription: ""
    });
    this.ngxService.start();
    this.showFlag = false;
    this.refreshDisclaimergrid();
    this.ngxService.stop();
  }

  // copy disclaimer
  copyDisclaimers(grid) {
    this.ngxService.start();
    var hti = grid.hitTest(event);
    let rowData = hti.panel.rows[hti.row].dataItem;
    this.clipboard.copy(rowData.Disclaimer);
    this.ngxService.stop();
    this._snackBar.open('Disclaimer copied.', 'Success');
  }

  // delete the disclaimers
  deleteDisclaimers(grid) {
    var hti = grid.hitTest(event);
    this.selectedrowData = hti.panel.rows[hti.row].dataItem;
    $('#setDisclaimerModal').appendTo("body").modal('show');
  }

  // edit disclaimer form
  async editDisclaimers(action, grid, event) {
    if (this.hideUpdateBtn) {
      return
    }
    this.actionEdit = action;
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    //get values from third table
    let query = this.vitalHttpServices.GetQuery('getDisclaimerCondition');
    let queryVariable = {
      ConditionId: selectedrowData.ConditionID.toString(),
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vitalHttpServices.GetData(queryResult).toPromise().then(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        selectedrowData['DisclaimerParamter'] = Resdata.data.submenuData;
        this.selectedrowData = selectedrowData;
        await this.getInputData(action, selectedrowData);
      }
      else {
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      }
    }, error => {

      console.error(error)
      this.ngxService.stop();
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    })
  }

  optionClick() {
    let newStatus2 = true;
    this.matDisclaimerSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus2 = false;
      }
    });
    this.allSelected = newStatus2;
  }

  multSelectAll(event,instanceData,searchValue,item,index){
    let data = this.sortedData(instanceData,searchValue);
    if(event.checked){
      data.forEach(va=> {
        item.selections.select(va);
      })
      item.value = data;
      this.setMultiselectValues(index,data);
    }
    else{
      item.value = [];
      this.setMultiselectValues(index,[]);
      item.selections.clear();
    }
  }

  isAllSelected(item,searchValue) {
    const numSelected = item.value.length;
    let rows = this.sortedData(item.data,searchValue);
    const numRows = rows.length;
    return numSelected === numRows;
  }

  // close delete the disclaimers
  closeModal() {
    this.deleteForm.patchValue({
      frmdeletereason: ""
    })
    $('.setDisclaimerModal').modal('hide');
    $('.setDisclaimerDupModal').modal('hide');
  }

  ngOnDestroy() {
    $('#setDisclaimerDupModal').remove();
    $('#setDisclaimerModal').remove();
  }

  //Get Site Format for case type
  getSiteFormat() {
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery('casetypesiteformat');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices
      .GetData(queryResult)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              this.siteFormat = data.data.submenuData[0].SiteFormat ? data.data.submenuData[0].SiteFormat : 2;
            }
          }
        })
  }

  //Splits input data for sites
  splitSiteData(inputValues) {
    return [...inputValues[this.siteFormat - 1]];
  }

  //Concats array values to Input type array
  addSiteData(InputTypes: Array<InputTypes>) {
    let siteValues: Array<InputTypes>;
    InputTypes = InputTypes.filter(va => {
      if (typeof (va.Value) != 'string') {
        siteValues = this.splitSiteData(va.Value);
        return false;
      }
      return true;
    });
    InputTypes = InputTypes.concat(siteValues);
    return InputTypes;
  }


  showError(item) {
    if (this.formSubmit && !item.value) {
      return 'case-level-desclaimer p-0 error';
    }
    return 'case-level-desclaimer p-0';
  }

  //Fetches parentId for corresponding child input types
  fetchParentID(DynamicJson, inputType) {
    let parentIDs;
    switch (inputType.toString().toLowerCase()) {
      case "sub site":
        parentIDs = this.siteFormat == 2 ? this.getParentIDs(DynamicJson, inputType) : null;
        break
      case "procedures":
        parentIDs = this.getParentIDs(DynamicJson, inputType);
        break;
    }
    return parentIDs;
  }

  //Checks if parent was selected to fetch Ids
  getParentIDs(DynamicJson, childName) {
    let matchChild = DynamicJson.filter(va => va.Controls[0].value.toString().toLowerCase().trim() == this.parentChildInput[childName].toString().toLowerCase().trim())
    return matchChild[0] ? String(matchChild[0].Controls[2].value.map(x => x.ID).join(",")) : matchChild[0];
  }


  parentChildSelection(currentIndex) {
    if (this.DynamicJson[currentIndex].Controls[3].value == 'AND') {
      let parentName = this.DynamicJson[currentIndex].Controls[0].value;
      let childName = Object.keys(this.parentChildInput).find(key => this.parentChildInput[key] === parentName);
      if (childName) {
        if (this.siteFormat == 2 && childName.match(/sub site/i)) {
          this.removeCurrentCondition(currentIndex);
          this.addCustomCondition(currentIndex, childName);
        }
        else if (!childName.match(/sub site/i)) {
          this.removeCurrentCondition(currentIndex);
          this.addCustomCondition(currentIndex, childName);
        }
        else {
          this.addForms();
        }
      }
      else {
        this.addForms();
      }
    }
    else {
      this.addForms();
    }
  }

  removeCurrentCondition(currentIndex) {
    this.DynamicJson[currentIndex].Controls[3].value = 'AND'
    this.DynamicJson[currentIndex].Controls[3].data = ['AND'];
  }

  addCustomCondition(currentIndex, childName) {
    let conditionValueData = {
      Controls: [
        {
          type: 'select',
          value: childName,
          label: 'Input Type',
          data: this.inpList,
          formControl : new FormControl("",Validators.required)          
        },
        {
          type: 'select',
          value: '',
          label: 'Operator',
          data: this.operatorValue,
          formControl : new FormControl("",Validators.required)
          
        },
        {
          type: 'text',
          value: '',
          label: 'Value',
          data: '',
          formControl : new FormControl("",Validators.required)
          
        },
        {
          type: 'select',
          value: '',
          label: 'Condition',
          data: this.conditionData,
          formControl : new FormControl("",Validators.required)          
        }
      ]
    }
    this.allSelectDropDown.push(false);
    this.DynamicJson.push(conditionValueData);
    this.setInputFormValues(currentIndex + 1, childName, 'input type');
  }

  checkChildSelected(inputType, field) {
    let childName = Object.keys(this.parentChildInput).find(key => this.parentChildInput[key] === inputType)
    let childControlIndex = this.DynamicJson.findIndex(va => va.Controls[0].value == childName);
    if (childControlIndex != -1) {
      if (field == 'operator') {
        if (childName.match(/sub site/i)) {
          this.siteFormat == 2 ? this.deleteCondition(childControlIndex) : null;
        }
        else {
          this.deleteCondition(childControlIndex);
        }
      }
      else {
        this.getChildData(childControlIndex, inputType);
      }
    }
  }

  //get child data when parent data is changed
  async getChildData(childControlIndex: number, inputType) {
    let parentIndex = this.DynamicJson.findIndex(va => va.Controls[0].value.toString().toLowerCase().trim() == inputType.toString().toLowerCase().trim())//#endregion   
    if (this.DynamicJson[parentIndex].Controls[2].value.length == 0) {
      this.deleteCondition(childControlIndex);
      return
    }
    let obj = {
      organizationID: sessionStorage.getItem('org_id'),
      CaseType: this.templateData.secondarykeys.casetype,
      AccountID: this.templateData.cardIdentifier,
      ParentID: this.fetchParentID(this.DynamicJson, this.DynamicJson[childControlIndex].Controls[0].value),
      InputType: this.DynamicJson[childControlIndex].Controls[0].value
    }
    let dbName = sessionStorage.getItem('DeploymentKey');
    let value = await this.vitalHttpServices.getValuesForInput(obj, dbName).toPromise();
    let childInputOperator = this.DynamicJson[childControlIndex].Controls[1].value
    if (value && value.length > 0) {
      if (value.some(va => va.ID)) {
        value = [...new Map(value.map((item) => [item['ID'], item])).values()];
      }
      for (let i = 0; i < value.length; i++) {
        value[i].checked = -1
      }
      this.valuesForInput[this.DynamicJson[childControlIndex].Controls[0].value] = value;

      if (this.DynamicJson[parentIndex].Controls[1].value == 'is') {
        this.DynamicJson[childControlIndex].Controls[2].data = value;
        if (value && value.length == 1) {
          if (childInputOperator == 'is') {
            this.setInputFormValues(childControlIndex, value[0], 'Value')
          }
          else {
            this.DynamicJson[childControlIndex].Controls[2].value = value;
            this.setMultiselectValues(childControlIndex, value)
          }
        }
        else {
          this.DynamicJson[childControlIndex].Controls[2].value = [];
        }
        return
      }
      this.DynamicJson[childControlIndex].Controls[2].data = this.DynamicJson[childControlIndex].Controls[2].data != '' ? [...new Map(this.DynamicJson[childControlIndex].Controls[2].data.map((item) => [item['ID'], item])).values()] : [];

      this.assignChildData(childControlIndex, value);
    }
    else {
      this.DynamicJson[childControlIndex].Controls[2].data = [];
      this.DynamicJson[childControlIndex].Controls[2].value = [];
      this.deleteCondition(childControlIndex);
    }

    let childInputType = this.DynamicJson[childControlIndex].Controls[0].value

    if (this.valuesForInput[childInputType] && this.valuesForInput[childInputType].length == 1) {
      if (childInputOperator == 'is') {
        this.setInputFormValues(childControlIndex, this.valuesForInput[childInputType][0], 'Value')
      }
      else {
        // this.DynamicJson[childControlIndex].Controls[2].value = this.valuesForInput[childInputType];
        this.setMultiselectValues(childControlIndex, this.valuesForInput[childInputType])
      }
    }
    else if (this.valuesForInput[childInputType].length > 1 && childInputOperator != 'is') {
      // this.DynamicJson[childControlIndex].Controls[2].value = this.DynamicJson[childControlIndex].Controls[2].value.filter(va=> this.DynamicJson[childControlIndex].Controls[2].data.some(val=> val.ID != va.ID))
      this.setMultiselectValues(childControlIndex, this.DynamicJson[childControlIndex].Controls[2].value);
    }
  }

  assignChildData(childControlIndex: number, value) {
    if (value.length > this.DynamicJson[childControlIndex].Controls[2].data.length) {
      value = this.DynamicJson[childControlIndex].Controls[2].data.length == 0 ? value : value.filter(va => this.DynamicJson[childControlIndex].Controls[2].data.some(val => val.ID != va.ID));
      this.DynamicJson[childControlIndex].Controls[2].data = this.DynamicJson[childControlIndex].Controls[2].data.concat(value);
    }
    else {
      this.DynamicJson[childControlIndex].Controls[2].data = this.DynamicJson[childControlIndex].Controls[2].data.filter(va => value.some(val => val.ID == va.ID));
    }
    this.DynamicJson[childControlIndex].Controls[2].data = [...new Map(this.DynamicJson[childControlIndex].Controls[2].data.map((item) => [item['ID'], item])).values()]
  }

  sortedData(data, value) {
    let multiSelectValues = data.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    if (!value) {
      return multiSelectValues;
    }
    return multiSelectValues.filter(va => va.Name.toString().toLowerCase().trim().includes(value.toString().toLowerCase().trim()))
  }

  //Filter single select values
  filterSingleSelectValues(data, value?) {
    let singleSelectValues = data.sort((a, b) => {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    if (!value) {
      return singleSelectValues;
    }
    return singleSelectValues.filter(va => va.Name.toString().toLowerCase().trim().includes(value.toString().toLowerCase().trim()));
  }

  //Add OR back to parent condition if child is deleted
  checkIfChildDeleted(index) {
    let childName = this.DynamicJson[index].Controls[0].value;
    let parentName = this.parentChildInput[childName]
    let parentIndex = this.DynamicJson.findIndex(va => va.Controls[0].value.match(parentName));
    if (parentIndex != -1) {
      this.DynamicJson[parentIndex].Controls[3].data = ['AND','OR'];
    }
  }

  //Delete child row if parent row is deleted
  checkIfParentDeleted(index) {
    let parentName = this.DynamicJson[index].Controls[0].value;
    let childName = Object.keys(this.parentChildInput).find(key => this.parentChildInput[key] === parentName);
    let childIndex = this.DynamicJson.findIndex(va => va.Controls[0].value.match(childName));
    if (childName && childIndex != -1) {
      if (this.siteFormat == 2 && childName.match(/sub site/i)) {
        this.input.splice(childIndex, 1);
        this.condition.splice(childIndex, 1);
        this.operator.splice(childIndex, 1);
        this.allSelectDropDown.splice(childIndex, 1);
        this.DynamicJson.splice(childIndex, 1);
      }
      else if (!childName.match(/sub site/i)) {
        this.input.splice(childIndex, 1);
        this.condition.splice(childIndex, 1);
        this.operator.splice(childIndex, 1);
        this.allSelectDropDown.splice(childIndex, 1);
        this.DynamicJson.splice(childIndex, 1);
      }
    }
  }


  checkIfChildReSelected(index, inputType) {
    if (this.parentChildInput[inputType]) {
      let childIndex = this.DynamicJson.indexOf(va => va.Controls[0].value == this.parentChildInput[inputType]);
      if (childIndex != -1) {
        this.removeCurrentCondition(childIndex);
      }
    }
    else {
      this.DynamicJson[index].Controls[3].data = ['AND', 'OR'];
    }
  }

  showNoResult(data) {
    return data.length == 0;
  }

  searchSingleValue(event) {
    let code
    let e = event;
    if (e.keyCode) {
      code = e.keyCode;
    } else if (e.which) {
      code = e.which;
    }
    if (code == 32) {
      if (e.stopPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
      return false;
    }
  }

  filterInputValues(label, data, search?) {
    if (label == 'Input Type') {
      if (!search) {
        return data.sort();
      }
      let searchResult = data.filter(va => va.toString().toLowerCase().trim().includes(search.toString().toLowerCase().toLowerCase().trim()))
      return searchResult.sort();
    }
    return data;
  }

  setFormErrors(addCondition? : boolean){
    this.DynamicJson.forEach(va=> va.Controls.forEach(
      function(val, idx, array){
        if ((!(idx === array.length - 1 && val.label.match(/condition/i))) || addCondition){ 
          if(!val.value){
            val.formControl.markAsTouched();
            val.formControl.setErrors({required : true});
          }   
        }
     }));
  }

  disclaimerError(){
   return this.formSubmit && !this.disclaimerForm.controls.frmDescription.value.replace(/<[^>]*>/g, '');
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}

export interface InputTypes {
  Value: any
}

export interface ParentChildInput {
  'Sub Site': string
  'Procedures': string
}