import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appMarkAsChecked]'
})
export class MarkAsCheckedDirective implements OnChanges{

  @HostBinding('style.color') textColor = ''
  @HostBinding('style.background-color') backgroundColor = ''
  @Input('appMarkAsChecked') isSelected : boolean = false

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSelected){
      this.textColor =  this.isSelected ? 'white' : 'inherit'
      this.backgroundColor = this.isSelected ? '#3874FF' : 'inherit'
    }
  } 
}
