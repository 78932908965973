<div *ngIf="templateData && templateData.labels && gridwidth > 0">
  <div *ngIf="UserList.length>0" class="mb-2">
    <strong class="mr-2">User Template:</strong>
    <a href="javascript:;" (click)="AddTab(item)" style="margin-right: 1%;color: blue;text-decoration: underline;"
      *ngFor="let item of UserList">{{item.Username}},</a>
  </div>
  <strong *ngIf="UserList.length>0">Group Template:</strong>
  <div>
    <!-- [ngStyle]="{'width.px': gridwidth+1}" -->
    <wj-flex-grid [headersVisibility]="'Column'" [ngStyle]="{'width.%': gridwidth}" #flexgrid (initialized)="initializeGrid(flexgrid)"
      [isReadOnly]="true" [itemsSource]="gridData"  [ngStyle]="{'width.%': 90}">
      <div *ngFor="let columnname of columnDefs">
        <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
          [format]="'d'" aria-expanded="false" [allowResizing]="true"
          [visible]="!(columnname==templateData.GroupData.GroupBy)">
        </wj-flex-grid-column>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
  </div>
  <div style="text-align: center;">
    <br />
    <div class="form-inline well well-lg mt-4">
      <button (click)="ExportExcel(flexgrid)" mat-raised-button class="admin-btn-success"  [disabled]="hideExportBtn ">Export</button>
      <!-- <div class="checkbox" style="margin-left: 8px;">
        <label>
          <input [(ngModel)]="includeColumnHeader" type="checkbox"> Include Column Header
        </label>
      </div> -->
    </div>
  </div>
</div>
<div *ngIf="gridwidth == 0" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found </div>
</div>
