import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { HomeLayoutComponent } from 'src/app/common/layout/home-layout.component';
// import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
//import 'src/assets/css/styles.css';
import 'monaco-themes/themes/Monokai.json';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-export-case-details',
  templateUrl: './export-case-details.component.html',
  styleUrls: ['./export-case-details.component.scss']
})

export class ExportCaseDetailsComponent {
  // editorOptions = { theme: 'iPlastic', // Choose the theme you want
  // language: 'sql', };
  editorOptions = {theme: 'vs-dark', language: 'javascript'};
  code: string= 'function x() {\nconsole.log("Hello world!");\n}';
  onInit(editor) {
      let line = editor.getPosition();
      console.log(line);
    }
  @Input() selecteddefaultdeploymentkey: any;
  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";
  value = 'Clear me';

  public DeploymentKeys = [];
  searchType = "";
  searchValue = "";
  showTab = false;
  roledata: any;
  nodata: boolean;
  defaultdeploymentkey: string;
  exportBtn: boolean = true;
  DownloadFilename: string;
  task: Task = {
    color: 'primary',
  };
  methodHit: boolean = false;

  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,
    public GridwithCB: GridContainerWithCheckBoxComponent, private clipboard: Clipboard,
    public _snackBar: MatSnackBar, public dialog: MatDialog, private datashare: DataShareService, private homeComponent: HomeLayoutComponent,private activeModal: NgbActiveModal) {
    this.DeploymentKeys = this.vaservices.DeploymentKeys
  }

  ExportCasegroup = this._fb.group({
    DeploymentKey: ['', Validators.required],
    QuickSearch: ['', Validators.required],
    ExportCaseSearchInput: ['', Validators.required],
    ConnectionName: [false, Validators.required],
    ExportCaseGenerateDML: ['Hello everyone', Validators.required],
  })

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
    this.ExportCasegroup.patchValue({
      DeploymentKey: this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey != 'ALL' ? this.selecteddefaultdeploymentkey : 'none',
      QuickSearch: "caseid",
      ExportCaseSearchInput: "",
      ExportCaseGenerateDML: "",
      ConnectionName: false
    })
  }



  // to close the modal
  exportCaseClose() {
    this.ExportCasegroup.reset();
    this.activeModal.close();
    this.ExportCasegroup.patchValue(
      {
        DeploymentKey: "none",
        QuickSearch: "caseid",
        ExportCaseSearchInput: "",
        ExportCaseGenerateDML: "",
        ConnectionName: false
      });
    this.roledata = ""
    this.nodata = false
    this.homeComponent.SelectedMenuModel = false;
  }

  selectDeployment(tab, event) {
    if (event.source.selected) {
      tab.ResultContent.Deployment = event.source.value.toString().toUpperCase();
    }
  }

  // copy the script
  copyData(value: string) {
    this.clipboard.copy(value);
    this._snackBar.open('The script has been copied.', 'Success');
  }

  // get call - to gengrate the DML script
  async generateDML(value) {
    console.log(value)
    this.nodata = false;
    this.roledata = "";
    this.methodHit = true;
    let form = this.ExportCasegroup.value;
    if (form.DeploymentKey === this.selecteddefaultdeploymentkey || form.DeploymentKey == 'none') {
      form.DeploymentKey = this.selecteddefaultdeploymentkey == 'ALL' ? form.DeploymentKey : this.selecteddefaultdeploymentkey;
    }
    if (!form.ConnectionName) {
      form.ConnectionName = false;
    }
    if (form.QuickSearch == 'supplyorderid') {
      this.nodata = true;
    } else {
      this.ngxService.start();
      try {
        await this.vaservices.generateDMLScript(form.QuickSearch, form.ExportCaseSearchInput, form.ConnectionName, form.DeploymentKey).toPromise().then(ResData => {
          this.DownloadFilename = form.DeploymentKey + '_' + form.QuickSearch + '_' + form.ExportCaseSearchInput;
          this.ngxService.stop();
          if (ResData == null) {
            this.nodata = true;
          }
          else if (!ResData.errors) {
            this.roledata = JSON.parse(JSON.stringify(ResData.replace(/<br \/>/gi, '\n').replace(/&nbsp;/gi, ' ')));
            this.ExportCasegroup.patchValue({
              ExportCaseGenerateDML: this.roledata
            })

            if (this.roledata == '<br />No data found<br />') {
              this.nodata = true;
            }
          } else {
            this._snackBar.open("An error occurred while processing your request", 'Failed');
            this.nodata = true;
          }
        }), (error) => {
          this.ngxService.stop();
          this.getError(error)
        };
      }
      catch {
        this.ngxService.stop();
        this.nodata = true;
      }

    }
  }

  downloadData() {
    const blob = new Blob([this.roledata], { type: "application/sql" });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = this.DownloadFilename + '.sql';
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  // validation
  getError(error) {
    this.roledata = ""
    this.ngxService.stop();
    this._snackBar.open(error, 'Failed');
    console.log(error);
  }
}
