import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lab-admin-dailog',
  templateUrl: './lab-admin-dailog.component.html',
  styleUrls: ['./lab-admin-dailog.component.scss']
})
export class LabAdminDailogComponent {
  constructor(
    public dialogRef: MatDialogRef<LabAdminDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClick(value : boolean) {
    this.dialogRef.close(value);
  }
}
