<div *ngIf="hideGrid" style="width: 100%;">
  <div *ngIf="templateData && templateData.labels && gridwidth > 0">
    <wj-flex-grid class="p-0 mt-2" [headersVisibility]="'Column'" [isReadOnly]="true" (click)="onClick(grid, $event)" [itemsSource]="gridData" 
      #grid>
      <div *ngFor="let columnname of gridheader">
        <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
          [allowResizing]="true"></wj-flex-grid-column>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>

    </wj-flex-grid>
    <br />
    <div class="align-center">
      <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
        [cv]="gridData"> </wj-collection-view-navigator>
    </div>
  </div>
  <div *ngIf="gridwidth == 0"  class="nodata-wrap mt-4">
    <div class="nodata-design"> No Data Found </div>
  </div>
</div>

<div *ngIf="showDescription" style="padding-top: 24px;
  padding-left: 24px;">
  <div class="col-12">
    <div class="row">
      <span style="font-weight: 500;">Description</span>
    </div>
    <div class="row pt-10">
      <textarea style="width:100%;height:81px;font-size: 12px;" [(ngModel)]="description"></textarea>
    </div>
  </div>
  <div class="col-12 pt-14">
    <div class="row">
      <span style="font-weight: 500;">Evaluation Criteria</span>
    </div>
    <!-- <div class="row pt-10">
        <span style= "width: 80%;
        height: 42px;
        color: blue;
        text-decoration-line: underline;
        font-size: 12px; cursor: pointer;
        font-weight: 600;border: solid 1px black;">    View the Evaluation Criteria code</span>
      </div> -->
    <div class="row pt-10">
      <span style="width: 100%;
        height: 42px;
        font-size: 12px; cursor: pointer;
         border: solid 1px black;"> {{evaluationCriteriaCode}}</span>
    </div>
  </div>

  <div class="col-12 pt-14 row">

    <span style="font-weight: 500;">Action</span>

  </div>
  <div class="col-12 pt-10">
    <div class="row pt-14" style="
      height: 254px;
      border: solid 1px;">

      <div class="row">
        <span style="font-weight: 500; padding-left: 10px;    position: absolute;
        left: 2px;">Message :</span>
        <em style="    position: absolute;
        right: 275px;" class="fa fa-pencil-square-o" aria-hidden="true"></em>
      </div>
      <div style="    width: 75%;
     border: solid 1px;
     position: absolute;
     top: 53px;
     ">
        <span style="font-size: 12px; padding-left: 10px;">"Requisition form is not scanned for this case. Do you wish
          to proceed without attaching this requisition form?"
        </span>
      </div>
      <div class="row">
        <div class="col-3" style="position: absolute;
       top: 122px;
       left: -11px;">
          <span style="font-weight: 500; padding-left: 10px;">Buttons :</span>
        </div>
        <div class="col-3" style="position: absolute;
       top: 122px;
       left: 94px;">
          <span style="font-weight: 500; padding-left: 10px;">Action :</span>
        </div>
      </div>

      <div class="row">
        <div class="col-3" style="position: absolute;
        top: 150px;
        left: 2px;">
          <button mat-raised-button class="admin-btn-success"
            type="button">Yes</button>
        </div>
        <div class="col-3" style="position: absolute;
       top: 150px;
       left: 94px;">
          <span style="font-weight: 400; padding-left: 10px;">Apply and Continue</span>
        </div>
      </div>

      <div class="row">
        <div class="col-3" style="position: absolute;
        top: 191px;
        left: 2px;">
          <button mat-raised-button class="admin-btn-success" type="button">No</button>
        </div>
        <div class="col-3" style="position: absolute;
       top: 191px;
       left: 94px;">
          <span style="font-weight: 400; padding-left: 10px;">Don't apply and stay</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 pt-14">
    <div class="row col-sm-10 px-0">
      <div class="col-3 p-0">
        <span style="font-weight: 500;"> Created By: {{createdBy}}</span>
      </div>
      <div class="col-3 p-0">
        <span style="font-weight: 500;"> Created On: {{createdDate}}</span>
      </div>
      <div class="col-3 p-0">
        <span style="font-weight: 500;"> Effective Date: {{effectiveDate}} </span>
      </div>
      <div class="col-3 p-0">
        <span style="font-weight: 500;">Expiry Date : {{expiryDate}}</span>
      </div>
    </div>
  </div>
  <div class="col-12 pt-14" style="width: 100%;">
    <div class="row mt-4" style="justify-content: flex-end;">
      <button mat-raised-button class="admin-btn-success mr-4" [disabled]="true"
        (click)="saveChanges()" type="button">Save</button>
      <button mat-raised-button class="admin-btn-success" (click)="back()" type="button">Back</button>
    </div>
  </div>
</div>
