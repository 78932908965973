<div class="row">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>New Diagnosis Templates</strong>
            </h3>
        </div>
        <iframe [src]="iframeSrc" width="100%" height="200%" frameborder="0"></iframe>
    </div>    
</div>
