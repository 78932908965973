<section>
    <div *ngIf="sourceApp != 'VitalDx'" class="row" style="width: calc(100vw - 18vw);">
        <div class="col-md-12">
            <div class="modal-header admin-section-header mt-3 ml-1">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>Password Configuration</strong>
                </h3>
            </div>
        </div>
    </div>
    <!-- <div class="row col-sm-12" *ngIf="sourceApp == 'VitalDx'">
        <div class="col-sm-8 m-0 p-0 mt-2 ml-1">
            <strong class="menu-title">Password Configuration</strong>
        </div>
    </div> -->
    <br *ngIf="sourceApp != 'VitalDx'"/>
    <div class=" password-policy ml-1" *ngIf="isLodingCompleted&&loginSessionForRole.controls && policies" style="width: calc(100vw - 20vw);">
        <div [formGroup]="loginSessionForm">
            <div class="header-margin-bottom">
                <strong class="heading-border">Login Session Controls </strong>
            </div>
            <div class="login-session" formArrayName="loginSessionForRole">
                <div class="display-list header">
                    <b>Role Names</b>
                    <b>Screen Auto-Lock (In Min)</b>
                    <b>Session Timeout (In Min)</b>
                </div>
                <div class="password-policy-scroll" style="max-height: 339px; min-height: 75px;">
                    <div class="display-list" *ngFor="let role of loginSessionForRole.controls let i = index"
                        [formGroupName]="i">
                        <span>{{role.get('RoleName').value}}</span>
                        <div [id]="'ForLockInp_'+i">
                            <input type="text" [validation]="false" mask="000" id="loginInp"
                                (focus)="displayOrHideApplyAll('TimeOutForLock', i)" (focusout)="[SuggestTimeOut(i)]"
                                (keyup)="checkforValueZero(i,'TimeOutForLock')" maxlength="3"
                                formControlName="TimeOutForLock" [attr.disabled]="!editBtn?true:null">
                            <span [id]="'TimeOutForLock'+i" (click)="ApplyAll('TimeOutForLock', i)"></span>
                        </div>
                        <div [id]="'ForSessionInp_'+i">
                            <input type="text" id="loginInp" [validation]="false" mask="0000"
                                (focus)="displayOrHideApplyAll('TimeOutForSession', i)"
                                (focusout)="[validateSessionValues(i)]"
                                (keyup)="checkforValueZero(i,'TimeOutForSession')" maxlength="4"
                                formControlName="TimeOutForSession" [attr.disabled]="!editBtn?true:null">
                            <span *ngIf="role.get('TimeOutForLock').value > 0" class="text-danger">*</span>
                            <span [id]="'TimeOutForSession'+i" (click)="ApplyAll('TimeOutForSession', i)"></span>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center no-data-found" *ngIf="loginSessionForRole.controls.length==0">
                        <span class="w-50 text-center">
                            No Data Available
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div [formGroup]="formAddPasswordDetails">
            <div class="password-control-body">
                <div class="header-margin-bottom align-items-end d-flex">
                    <div>
                        <strong class="heading-border">Password Controls</strong>
                    </div>
                    <app-button buttonclass="secondary" class="ml-auto edit-page-button mr-3" [isDisabled]="!restoreBtn"
                        (click)="OpenRestoreDialog()">Restore Defaults</app-button>
                    <app-button buttonclass="primary" class="edit-page-button" [isDisabled]="!saveBtn"
                        (click)="SavePasswordConfiguration()">Save</app-button>
                </div>
                <div class="password-policy-scroll">
                    <div class="row-item  header">
                        <span> <b>Settings </b> <span class="ml-1"> <img width="14px" height="14px"
                                    src="../../../assets/images/info.png" /></span>
                            <Span class="submessage-font-size"> Changes made here will not apply to users logging
                                in using email</Span>
                        </span>
                        <b>Value</b>
                    </div>
                    <div style="max-height: calc(100vh - 47vh);overflow-y: scroll;">
                        <div class="row-item padding-top-bottom">
                            <div>Passwords should contain at least one numeric and one alpha character
                            </div>
                            <div class=" ml-1">
                                <input type="checkbox" id="" formControlName="alphanumeric"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Passwords should contain at least one upper case alpha character</div>
                            <div class="ml-1">
                                <input type="checkbox" id="" formControlName="uppercase"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Passwords should contain at least one lower case alpha character</div>
                            <div class="ml-1 ">
                                <input type="checkbox" id="" formControlName="lowercase"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Passwords should contain at least one special character</div>
                            <div class="ml-1 ">
                                <input type="checkbox" id="" formControlName="specialchar"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Minimum number of characters required<span class="text-danger font-weight-bold ">*</span>
                                <span
                                    *ngIf="formAddPasswordDetails.get('minmumchar').hasError('required') && ( formAddPasswordDetails.get('minmumchar').touched || formAddPasswordDetails.get('minmumchar').dirty)"
                                    class="text-danger f-11"> This field cannot be empty
                                </span>
                            </div>
                            <div class=" ml-1">
                                <input type="text" mask="00" maxlength="2" [validation]="false" id="minmumchar"
                                    [ngClass]="formAddPasswordDetails.get('minmumchar').invalid && (formAddPasswordDetails.get('minmumchar').touched || formAddPasswordDetails.get('minmumchar').dirty)? 'validtion' : ''"
                                    formControlName="minmumchar" [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Password expiration after (Days) <span class="text-danger">*</span>
                                <span
                                    *ngIf="formAddPasswordDetails.get('passwordexpiration').hasError('required') && ( formAddPasswordDetails.get('passwordexpiration').touched || formAddPasswordDetails.get('passwordexpiration').dirty)"
                                    class="text-danger f-11">This field cannot be empty</span>
                            </div>
                            <div class="ml-1">
                                <input type="text" mask="00000" maxlength="5" [validation]="false"
                                    [ngClass]="formAddPasswordDetails.get('passwordexpiration').hasError('required') && (formAddPasswordDetails.get('passwordexpiration').touched || formAddPasswordDetails.get('passwordexpiration').dirty)? 'validtion' : ''"
                                    id="passwordexpiration" formControlName="passwordexpiration"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>User login expiration after inactivity for (Days) <span class="text-danger">*</span>
                                <span
                                    *ngIf="formAddPasswordDetails.get('loginexpiration').hasError('required') && ( formAddPasswordDetails.get('loginexpiration').touched || formAddPasswordDetails.get('loginexpiration').dirty)"
                                    class="text-danger f-11">This field cannot be empty</span>
                            </div>
                            <div class="ml-1">
                                <input type="text" [validation]="false"
                                    [ngClass]=" formAddPasswordDetails.get('loginexpiration').hasError('required') && (formAddPasswordDetails.get('loginexpiration').touched || formAddPasswordDetails.get('loginexpiration').dirty)? 'validtion' : ''"
                                    mask="00000" maxlength="5" id="loginexpiration" formControlName="loginexpiration"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                        <div class="row-item padding-top-bottom">
                            <div>Lock User IDs after (Incorrect Login
                                Attempts) <span class="text-danger">*</span> 
                                <span
                                    *ngIf="formAddPasswordDetails.get('incorrectlogin').hasError('required') && ( formAddPasswordDetails.get('incorrectlogin').touched || formAddPasswordDetails.get('incorrectlogin').dirty)"
                                    class="text-danger f-11"> This field cannot be empty</span>
                            </div>
                            <div class="">
                                <input type="text" maxlength="5"
                                    [ngClass]="formAddPasswordDetails.get('incorrectlogin').hasError('required') && (formAddPasswordDetails.get('incorrectlogin').touched || formAddPasswordDetails.get('incorrectlogin').dirty) ? 'validtion' : ''"
                                    [validation]="false" mask="00000" id="incorrectlogin" formControlName="incorrectlogin"
                                    [attr.disabled]="!editBtn?true:null">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>













    <div class="col-sm-12 text-center no-data-found"
        *ngIf="isLodingCompleted&&loginSessionForRole.controls.length==0 && policies == undefined ">
        <span class="w-50 text-center">
            No Results
        </span>
    </div>
</section>

<!-- <div class="d-flex justify-content-center align-self-center mt-3 " *ngIf="loginSessionForRole.controls && policies">
    <button type="button" [disabled]="!restoreBtn" class="btn m-3 general" (click)="OpenRestoreDialog()">Restore
        Defaults</button>
    <button type="button" [disabled]="!saveBtn" class="btn m-3 general"
        (click)="SavePasswordConfiguration()">Save</button>
</div> -->