import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-common-options',
  templateUrl: './common-options.component.html',
  styleUrls: ['./common-options.component.scss']
})
export class CommonOptionsComponent implements OnInit {

 
  @Input() ExportData: any; 
  @Input() buttonFlags: any;
  uploadClicked:boolean=false;
  copyDataClicked:boolean=false;
  optionArray:any=[]
  uploadTableData: any;
  uploadCopyData: any;
  createClicked: boolean;
  createDataClicked: boolean;
  exportDataCliked:boolean;
  
  constructor(public _snackBar: MatSnackBar) { }

  ngOnInit() {
    
  }

  @Output() UploadandCopyData = new EventEmitter<any>();

  sendObject() {
    const objToSend = [];
    if(this.uploadClicked){
      let uploadObject={key: 'upload',tableData:this.uploadTableData,isUploadOrCopyFlag:this.uploadClicked}
      objToSend.push(uploadObject)
    }else if(this.copyDataClicked){
      let CopyObject={key: 'copy',isUploadOrCopyFlag:this.copyDataClicked}
      objToSend.push(CopyObject)
    }
    else if(this.createDataClicked){
      let CreateObject={key: 'create',isUploadOrCopyFlag:this.createDataClicked}
      objToSend.push(CreateObject)

    }
    else if(this.exportDataCliked){
      let ExportObject={key: 'export',isUploadOrCopyFlag:this.exportDataCliked}
      objToSend.push(ExportObject)
    }
    this.UploadandCopyData.emit(objToSend);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.getButtons(this.buttonFlags)
  }

  getButtons(buttonFlags) {
    this.optionArray=[]
    buttonFlags.forEach(element => {
      if (element && element.isBtnAccess && element.btnName) {
        if (element.btnName === 'Export') {
          element.imgsrc = '../../../../assets/icons/export_icon.svg';
        } else if (element.btnName === 'Upload') {
          element.imgsrc = '../../../../assets/icons/upload_icon.svg';
        } else if (element.btnName === 'Copy') {
          element.imgsrc = '../../../../assets/icons/copy_icon.svg';
        }
        this.optionArray.push(element);
      }
    });
  
  }

  disableIcon(data) {
    if (data.length == 1) {
      if (data[0].btnName === "Export" && !data[0].isIdAvailble) {
        return true
      } else {
        return false
      }
    } else if (data.length == 0) {
      return true
    } else {
      return false
    }
  }
  ActionItem(data){
    switch(data.btnName){
      case 'Export':this.uploadClicked=false;this.copyDataClicked=false;this.exportDataCliked=true;this.sendObject();break;
      case 'Upload':this.uploadClicked=true;this.copyDataClicked=false;this.exportDataCliked=false;this.sendObject();break;
      case 'Copy':this.uploadClicked=false;this.copyDataClicked=true;this.exportDataCliked=false;this.sendObject();break;
      case 'Create':this.uploadClicked=false;this.copyDataClicked=false;this.exportDataCliked=false;this.createDataClicked=true;this.sendObject();break;
    }
  }

  // ExportGridToExcel(nameFile,ExportData) {
  //   // if (this.exportDataCliked) {
  //     let filename = nameFile + '_.xlsx';
  //     var ws = XLSX.utils.json_to_sheet(ExportData);
  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "" + nameFile);
  //     XLSX.writeFile(wb, filename);
  //   // }
  // }

  

}

