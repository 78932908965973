import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vital-insights',
  templateUrl: './vital-insights.component.html',
  styleUrls: ['./vital-insights.component.scss']
})

export class VitalInsightsComponent {
  constructor(public sanitizer: DomSanitizer,private vitalHttpServices: VitalHttpServices,) {}
@Input()selecteddefaultdeploymentkey=''
  userEmail: string;
  deploymentKey:string;
  endpoint: string;
  url: any;

  ngOnInit(): void {
    this.userEmail = localStorage.getItem('UserEmail');
    this.deploymentKey = sessionStorage.getItem('DeploymentKey');
    this.vitalHttpServices.EncryptInsigths(this.userEmail).subscribe(result => {
      if (!result.error) {
        // this.endpoint = "https://vainsightsapiqa.vitalaxis.com/Users/api/adminSSO?email=" + result;
        this.endpoint=this.vitalHttpServices.vitalInsights + 'adminSSO?email=' + result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.endpoint);
      }
    }
    );

  }

  closeModal() {
    
  }
}
