import {
  Component, EventEmitter, Input, OnChanges, OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../../core/services/commonservices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { SettingsTemplateContainerComponent } from '../SettingsTemplateContainer/settingstemplatecontainer.component';

@Component({
  selector: 'Casetypetemplate-container',
  templateUrl: './Casetypetemplatecontainer.component.html',
  styleUrls: ['./Casetypetemplatecontainer.component.scss']
})

export class CasetypeTemplateContainerComponent extends SettingsTemplateContainerComponent
  implements OnInit, OnChanges {
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  public template: string = '';
  @Input()
  public submenuData: any;
  @Input()
  labels: any;
  @Input()
  headerName: string = "Template"
  public GroupData: any = {};
  public groupByKey: string = '';
  public xml: any;
  public subHeader: string = '';
  templateGroupedData: any;
  templateGroupedDataKeys: string[];
  public actionbuttondata: any;
  public download: boolean;
  public preview: boolean;
  public templateLables: any;
  labelFirstCount: number = 0;
  uploadToServer: boolean = false;
  newTempfile: File;
  @Input()
  templateData: any;
  blankTemptabs = ['Existing Template'];
  tabs = ['Existing Template', 'Try New Template'];
  reports = ['Upload', 'Download'];
  gridData: CollectionView;
  showGrid = false;
  gridwidth;
  gridheader = ['Status', 'Service', 'Accession_Number', 'Case_ID'];
  selected = new FormControl(0);
  previewHeader: any;
  public queryVariable;
  public selectTempName = '';
  public ShowPreviewUploadTemplate: boolean = false;
  columns = [
    //Outer grid
    new ColDef('TemplateLevel', 'Scope'),
    new ColDef('Description', 'Description'),
    new ColDef('TemplateName', 'Name')
  ];
  listes = ['Accession', 'Case ID', 'Requisition Number'];
  public searchData: any = null;
  public CaseId: any = null;
  valueData = '';
  templateName: any;
  TemplateCategory: string;
  showSelectAllCheckbox = false;
  caseTemplateinformation: CollectionView;
  //to add check boxes to grid
  selectedItems: any[] = [];
  selector: Selector = null;
  viewedFinalizedReport: boolean = false;

  @Output()
  SelectedItemsEvent = new EventEmitter<any>();
  columnheaders: any;
  Templatetypegridwidth: number;
  NotBlankTemplate: boolean;
  filteredInformation: any;
  templateInfo: any;
  templateType: any;
  previewVariable: any;
  dataUnavailable: boolean = false;
  BlankTemplate = false;
  filename = '';
  GroupDataKeys: (string | string[] | CollectionView<any>)[];
  tab: any;
  subMenuCardModel: any;
  Order_ID: any;
  Status: any;
  result: any;
  isHomeHeader: boolean = false;
  fileList: any;
  uploadFileContent: { id: string; name: any; source: string; }[];
  fileExtention: string;

  constructor(
    private vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private injectorServiceCaseType: InjectorService,
    public ngxservice: NgxUiLoaderService,
    public _snackbar: MatSnackBar,
    private DomSanitizer: DomSanitizer, private datashare: DataShareService
  ) {
    super(injectorServiceCaseType, ngxservice, _snackbar);
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }


  // public GetconfigQuery() {
  //   let query =
  //     'query ($RoleName: String, $CardType: String) {    submenuData: VitalAdminUserRoles_list(RoleName: $RoleName, CardType: $CardType) {      RoleName      CardType      Permission      Status    }  }';
  //   return query;
  // }
  frm_Report = this._fb.group({
    frm_InputValue: [''],
    selectSearch: ['caseid', Validators.required]
  });

  frm_UploadReport = this._fb.group({
    filename: ['', Validators.required]
  });

  backToGrid() {
    this.ShowPreviewUploadTemplate = false;
    this.NotBlankTemplate = false;
    this.dataUnavailable = false;
    this.BlankTemplate = false;
    this.valueData = null;
    this.filename = '';
    this.isHomeHeader = false;
    this.headerName = "Template"
  }

  showPreviewUploadTemplateUI(obj, value) {
    if (obj !== '') {
      let queryResult: any;
      let status = null;
      let service = null;
      let template = value.toString().toLowerCase().trim();
      this.previewHeader = value;
      switch (template) {
        case 'case report':
          status = 'finalized';
          this.templateName = 'casereport';
          break;
        case 'case requisition':
          status = 'requisition'
          this.templateName = 'caserequisition';
          break;
        case 'send out requisition':
          status = 'send out';
          this.templateName = 'sendoutrequisition';
          break;
        case 'technical worksheet':
          service = 'technical only';
          this.templateName = 'technicalworksheet';
          break;
        case 'rejection report':
          status = 'rejectionreport';
          this.templateName = 'rejectionreport';
          break;
        case 'blank requisition':
        case 'blank technical worksheet':
          //In the case of blank templates,the data fetch is not required, hence the use of the return statement.
          this.templateName = 'blanktechnicalworksheet';
          this.ShowPreviewUploadTemplate = true;
          this.BlankTemplate = true;
          return;
        default: status = "finalized";
          break;
      }
      let query = this.vitalHttpServices.GetQuery('previewtemplatelatestcaseid');
      this.queryVariable = {
        labaccountid: this.templateData.cardIdentifier.toString(),
        casetype: this.templateData.cardtype,
        status: status,
        service: service
      };
      queryResult = this.commonService.GetCardRequest(this.queryVariable, query);
      let Outputresult = [];
      this.templateType = value.toString().toLowerCase().trim();
      this.ngxservice.start();
      this.vitalHttpServices.GetData(queryResult).subscribe((res) => {
        this.ngxservice.stop();
        if (!res.errors) {
          Outputresult = [];
          if (res.data.submenudata != undefined && res.data.submenudata.length > 0) {
            Outputresult = res.data.submenudata
            this.templateInfo = Outputresult;

            if (Outputresult) {
              if (Outputresult.length > 0) {
                this.Status = Outputresult[0].Status;
                this.previewVariable = Outputresult[0].Case_ID;
                this.ShowPreviewUploadTemplate = true;
                this.NotBlankTemplate = true;
                this.dataUnavailable = false;
                this.AddGridData(Outputresult);
                this.showGrid = true;
                setTimeout(() => {
                  this.frm_Report.patchValue({
                    frm_InputValue: Outputresult[0].Case_ID,
                    selectSearch: 'caseid'
                  });
                });
              } else {
                this.ShowPreviewUploadTemplate = true;
                this.NotBlankTemplate = true;
                // this.showGrid = true;
                this.showGrid = false;
                setTimeout(() => {
                  this.frm_Report.patchValue({
                    frm_InputValue: '',
                    selectSearch: 'caseid'
                  });
                });
              }


            }
          }
          else {
            this.templateInfo = [];
            this.previewHeader = value;
            this.ShowPreviewUploadTemplate = true;
            this.NotBlankTemplate = true;
            this.showGrid = false;
            // this.frm_Report.patchValue({ selectSearch: 'caseid' });
            setTimeout(() => {
              this.frm_Report.patchValue({
                frm_InputValue: '',
                selectSearch: 'caseid'
              });
            });
          }
        }
        else {
          this.frm_Report.patchValue({
            frm_InputValue: '',
            selectSearch: 'caseid'
          });
        }
      },
        error => {
          this.ngxservice.stop();
          this._snackbar.open(
            'An error occurred while processing your request',
            'Failed'
          );
        }
      );
    } else {
      this.ShowPreviewUploadTemplate = false;
    }
  }

  ngOnInit() {
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.GridArrangement();
      this.getActionbutton();
    }
    else {
      this.dataUnavailable = true;
    }
  }

  initializeGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  GridArrangement(): any {
    for (let i = 0; i < this.templateData.submenuData.length; i++) {
      delete this.templateData.submenuData[i].Querytxt;
      this.templateData.submenuData[i].TemplateCategory = this.templateData.submenuData[i].TemplateCategory.trim('\r\n');
    }
    this.columnheaders = Object.keys(this.templateData.submenuData[0]);
    let reportData = [];
    let requisitionData = [];
    for (let i = 0; i < this.templateData.submenuData.length; i++) {
      let primary = {};
      for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
        for (let j = 0; j < this.columnheaders.length; j++) {
          if (key.toLowerCase() === this.columnheaders[j].toLowerCase()) {
            if (key.toLowerCase() == 'templatelevel') {
              if (value.toString() == 'Deployment')
                value = 'Deployment specific';
              else
                value = 'Location specific';
            }
            primary[key] = value;
          }
        }
      }
      if (this.templateData.submenuData[i].TemplateCategory.toString().toLowerCase().trim() == 'requisition templates') {
        requisitionData.push(primary);
      } else {
        reportData.push(primary);
      }
    }
    this.gridarray = [...reportData, ...requisitionData];
    this.columnheaders = Object.keys(this.templateData.submenuData[0]);
    this.caseTemplateinformation = new CollectionView(this.gridarray, {
      pageSize: 10,
      groupDescriptions: [Object.keys(this.templateData.submenuData[0])[0]]
    });
  }

  ClearBtnTxt() {
    (<HTMLInputElement>document.getElementById('Avalue')).value = '';
  }
  getActionbutton() {
    if (sessionStorage.getItem('RoleName') !== null) {
      let queryVariable = {
        RoleName: sessionStorage.getItem('RoleName'),
        CardType: 'casetypetemplate'
      };
      let query = this.vitalHttpServices.GetconfigQuery();
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      sessionStorage.setItem('deploymentKey', 'configdb');
      this.vitalHttpServices.GetData(queryResult).subscribe((data) => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (!data.errors) {
          let roledata = JSON.parse(data.data.submenuData[0].Permission);
          // this.actionbuttondata = roledata.ActionButton;
          this.actionbuttondata = roledata.ActionButton;
          for (var i = 0; i < this.actionbuttondata.length; i++) {
            if (this.actionbuttondata[i].Button === 'Download template') {
              if (this.actionbuttondata[i].IsPermitted == 'false') {
                this.download = true;
              } else if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.download = false;
              } else {
                this.download = true;
              }
            } else if (this.actionbuttondata[i].Button === 'Preview') {
              if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.preview = false;
              } else {
                this.preview = true;
              }
            }
          }
        }
      }, error => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      });
    }
  }

  caseTypeTemplateInformation(grid, e) {
    var hti = grid.hitTest(e);
    var row = hti.row;
    var item = hti.panel.rows[row].dataItem;
    this.isHomeHeader = true;
    if (item != null) {
      let row = grid.rows[hti.row];
      let dataItem = row.dataItem;
      if (!item.TemplateLevel) {
        return;
      } else {
        this.showPreviewUploadTemplateUI(dataItem, dataItem.TemplateName);
      }
    }
  }

  DownloadGitFile(value) {
    let downloadjson = {
      CaseType: this.templateData.cardtype, // dermatology
      Accid: this.templateData.cardIdentifier, // 18858
      Template: this.templateName
    };
    this.ngxservice.start();
    this.vitalHttpServices.DownloadExistTemplate(downloadjson).subscribe(res => {
      this.ngxservice.stop();
      if (res.Success) {
        // this.commonservice.downloadBlob(res.Message, "LatestXml.xml", false, 512);
        res.Message = JSON.parse(res.Message);
        if (res.Message.filecontent != '' && res.Message.filename != '') {
          //code to download file with filename
          this.commonService.downloadBlob(
            res.Message.filecontent,
            res.Message.filename,
            false,
            512
          );
        } else
          this._snackbar.open('No template found', 'Close');
      } else
        this._snackbar.open('No template found', 'Close');
    }, error => {

      this.ngxservice.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    });
  }

  fnGitFilePath(tempdata) {
    tempdata.filePath.length > 0 ? this.uploadToServer = true : this.uploadToServer = false
  }

  onInputChange(value: string) {
    this.valueData = value.trim();
  }

  isGoButtonEnabled(): boolean {
    return this.frm_Report.valid && this.valueData !== '' && this.frm_Report.value.selectSearch !== 'none';
  }

  onGoButtonClick() {
    if (this.isGoButtonEnabled()) {
      this.CaseSearch(this.frm_Report.value.frm_InputValue, this.frm_Report.value.selectSearch);
    }
  }

  CaseSearch(value, selectSearch) {
    let templateResult;
    let caseid = "";
    let accessionnumber = "";
    this.previewVariable = null;
    switch (selectSearch) {
      case 'caseid':
        if (value.trim() && !isNaN(Number(value))) {
          this.previewVariable = value;
          caseid = value.toString();
          accessionnumber = null;
        } else {
          this._snackbar.open('Please enter a valid Case ID', 'Close');
          this.showGrid = false;
          return;
        }
        break;
      case 'acc':
        if (value) {
          if (isNaN(value) && /[a-zA-Z]/.test(value)) {
            accessionnumber = value.trim().toString();
            caseid = null;
          } else {
            this._snackbar.open('Please enter a valid Accession Number', 'Close');
            this.showGrid = false;
            return;
          }
        } else {
          this._snackbar.open('Please enter a valid Accession Number', 'Close');
          this.showGrid = false;
          return;
        }
        break;
    }
    let queryResult: any = {};
    let query = this.vitalHttpServices.GetQuery('previewtemplateforcaseid');
    let queryVariable = {
      labaccountId: this.templateData.cardIdentifier.toString(),
      casetype: this.templateData.cardtype,
      accessionnumber: accessionnumber,
      caseid: caseid,
      requisitionnumber: null
    };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxservice.start();
    this.vitalHttpServices.GetData(queryResult).subscribe((res) => {
      this.ngxservice.stop();
      templateResult = res.data.submenudata
      if (templateResult) {
        if (templateResult.length > 0) {
          this.Status = templateResult[0].Status;
          this.previewVariable = templateResult[0].Case_ID;
          this.dataUnavailable = false;
          this.AddGridData(templateResult);
        } else {
          this.showGrid = false;
          this.dataUnavailable = true;
          this.gridwidth = 0;
          this.gridData = new CollectionView([], { pageSize: 0 });
          this.ngxservice.stop();
        }
      }
    });
  }

  AllowOnlyNumber(event: KeyboardEvent, value) {
    if ((this.frm_Report.value.selectSearch = 'caseid')) {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }

  resetSearch() {
    this.frm_Report.patchValue({
      frm_InputValue: ''
    })
    this.showGrid = false;
    this.valueData = ''
  }

  public gridarray = [];
  public colWidth;
  AddGridData(dataValue) {
    this.gridarray = [];
    let primary = {};
    if (dataValue) {
      if (dataValue.length > 0) {
        this.showGrid = true;
        this.gridwidth = 1;
        primary = {};
        for (let [key, value] of Object.entries(dataValue[0])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key === this.gridheader[j]) {
              primary[key] = value;
            }
          }
        }
        this.gridarray.push(primary);
      }
      else 
      this.showGrid = true;
    }
    this.gridData = new CollectionView(this.gridarray, { pageSize: 10 });
  }
  UploadToServer(tempValue) {
    let gitpath = { GitFilePath: tempValue.filePath };
    this.ngxservice.start();

    this.vitalHttpServices.uploadGitFileToServer(gitpath).subscribe(
      res => {
        this.ngxservice.start();
        if (res.Success) {
          this.ngxservice.stop();
          this._snackbar.open(res.Message, 'Success');
        } else {
          this.ngxservice.stop();
          this._snackbar.open(res.Message, 'Failed');
        }
      }, error => {

        this.ngxservice.stop();
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    );
  }

  PreviewExistingTemp(value) {
    if (this.selectedItems.length > 0) {
      this.selectedItems;
    }
    this.ngxservice.start();
    let queryResult: any;
    let query = this.vitalHttpServices.GetQuery('OrdIDByCaseID');
    this.queryVariable = {
      CaseID: this.previewVariable.toString()
    };
    queryResult = this.commonService.GetCardRequest(
      this.queryVariable,
      query
    );
    this.vitalHttpServices.GetData(queryResult).subscribe(
      res => {
        this.ngxservice.stop();
        if (!res.error) {
          if (res.data.GetOrderByCaseID_list && res.data.GetOrderByCaseID_list.length > 0) {
            this.Order_ID = res.data.GetOrderByCaseID_list[0].Order_ID;
          }
          else {
            this.Order_ID = null;
          }
        }
        else {
          this.Order_ID = null;
        }
        this.GenerateTemplates(value);
      }, error => {

        this.ngxservice.stop();
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    );


  }

  GenerateTemplates(value) {
    let deployid = sessionStorage.getItem('deploymentKey');
    let dxUrl = this.vitalHttpServices.OrgaAppUrl; 
    if(!(dxUrl && dxUrl.length > 0))
      dxUrl = this.vitalHttpServices.GetDxurlBasedOnDeployment(deployid);

    if(dxUrl.substr(dxUrl.length - 1) != '/')
      dxUrl += '/';

    let url = '';
    if (this.Status.toString().toLowerCase() == 'rejected' && ((this.previewHeader.toString().toLowerCase().trim() != 'rejection report') && (this.previewHeader.toString().toLowerCase().trim() != 'case requisition'))) {
      this._snackbar.open('This case was rejected', 'Close');
      return;
    }
    if (this.previewHeader.trim().toLowerCase() == "technical worksheet") {
      url = dxUrl+ 'VATechnicalworkSheetReport.aspx?caseid=' + this.previewVariable; //+ '&type=printtechworksheet';
      this.createNewWindow(url);
    }
    else if (this.previewHeader.trim().toLowerCase() == "send out requisition") {
      if (this.Order_ID != null) {
        url = dxUrl + 'VASentOutReport.aspx?caseid=' + this.previewVariable + '_' + this.Order_ID + '&dummy=1#zoom=100';
        this.createNewWindow(url)
      }
      else {
        this._snackbar.open('Template Not found', 'Close');
        this.ngxservice.stop();
        return;
      }
    }
    else if (this.previewHeader.trim().toLowerCase() == 'case requisition') {
      url = dxUrl + 'VAPrintRequisition.aspx?caseid=' + this.previewVariable + '&dummy=1#zoom=100';
      this.createNewWindow(url);
    }
    else if (this.previewHeader.trim().toLowerCase() == "case report" || this.previewHeader.trim().toLowerCase() == "rejection report") {
      this.vitalHttpServices.GetEncryptedData(value).subscribe(
        res => {
          if (res.Success) {
            this.ngxservice.start();
            if (this.Status.toString().toLowerCase() == 'finalized' && this.previewHeader.toString().toLowerCase().trim() == 'case report') {
              this.viewedFinalizedReport = true;
              url = dxUrl + 'VAReportGetForCase.aspx?' + res.Message;
            }
            else if (this.Status.toString().toLowerCase() == 'rejected' && this.previewHeader.toString().toLowerCase().trim() == 'rejection report') {
              url = dxUrl + 'VAReportGetForCase.aspx?' + res.Message;
            }
            else if (this.Status.toString().toLowerCase() != 'rejected' && this.previewHeader.toString().toLowerCase().trim() == 'rejection report') {
              this._snackbar.open('This case was not rejected', 'Close')
              this.ngxservice.stop();
              return;
            }
            if (this.viewedFinalizedReport = true && this.Status.toString().toLowerCase() == 'finalized') {
              this.viewedFinalizedReport = false;
              var auditInfo = {
                CaseID: value,
                UserID: (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
                ActionPerformed: "Viewed Final Report",
                ActionDetails: ";Finalized",
                TimeTaken: 0,
                TraceID: "",
                // AuditType: null,
                // ObjectID: null,
                IsShown: 0,
                // OrderNumber: null
                // AdditionalDetails: null,
              }
              if (sessionStorage.getItem("Userid") && sessionStorage.getItem("Userid") != "") {
                this.vitalHttpServices.RecordAudit(auditInfo).subscribe(result => {
                  this.result = result
                  if (!result.error && result.message == 'Audit Successful') {
                    this.createNewWindow(url);
                  }
                  else {
                    this._snackbar.open('You are unauthorized to view the report!', 'Close');
                  }
                }, (error) => {
                  console.error(error);
                });
              }
              else {
                this._snackbar.open('You are unauthorized to view the report!', 'Close');
              }
            }
            else {
              this._snackbar.open('Case ID is not is Finalized state!', 'Close');
            }
            this.ngxservice.stop();
          } else {
            this._snackbar.open(res.Message, 'Failed');
            this.ngxservice.stop();
          }
        }, error => {

          this.ngxservice.stop();
          console.error(error);
          this._snackbar.open('An error occurred while processing your request', 'Failed');
        }
      );
    }
  }

  createNewWindow(url) {
    const finalURL = url;
    var newWindow = window.open(
      finalURL,
      '_blank',
      'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 '
    );
  }

  handleInputChangeXML(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    reader.onload = this._handleReaderLoadedXML.bind(this);
    reader.readAsDataURL(file);
  }
  pageSrc: any = null;
  ifClicked = false;
  _handleReaderLoadedXML(e) {
    let reader = e.target;
    this.ifClicked = true;
    // this.pageSrc = this.DomSanitizer.bypassSecurityTrustHtml(reader.result);
    this.pageSrc = this.DomSanitizer.bypassSecurityTrustResourceUrl(
      reader.result
    );
    var tempFile = e.target.result.split(';base64,');
    //this.XMLBase64 = tempFile[1];
    //this.pageSrc = this.DomSanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + resTempData.previewTemplateFile);
  }
  isProperfile = false;
  fileChange(event) {
    this.fileList = event.target.files[0];
    this.filename = this.fileList.name;
    if (this.filename) {
      this.uploadToServer = false;
      let splitarry = this.filename.split('.');
      this.fileExtention = splitarry[1].toUpperCase();
      if (!(splitarry[1].toUpperCase() == 'XML' || splitarry[1].toUpperCase() == 'FLXR')) {
        (document.getElementById("myFileInputID") as HTMLInputElement).value = null;
        this._snackbar.open('Please upload an XML or FLXR file format only', 'Failed');
        this.isProperfile = false;
      } else {
        this.isProperfile = true;
        this.newTempfile = this.fileList;
        const fileReader = new FileReader()
        fileReader.readAsDataURL(this.fileList)

        fileReader.onload = () => {
          let fileContent = (<string>fileReader.result).split(',')[1]
          this.uploadFileContent = [{
            "id": "1234",
            "name": this.fileList.name,
            "source": fileContent.toString()
          }]
        }
      }
    }
  }

  initGrid(gridcheckbox: FlexGrid) {
    this.selector = new Selector(gridcheckbox, {
      itemChecked: () => {
        // this.selectedItems = gridcheckbox.rows.filter(r => r.isSelected);
        this.showCheckedCount(gridcheckbox);
      }
    });
  }

  showCheckedCount(gridcheckbox);
  showCheckedCount(gridcheckbox) {
    this.selectedItems = gridcheckbox.rows.filter(r => r.isSelected);
    // for (let i = 0; i < this.selectedItems.length; i++) {
    //   if (this.selectedItems[i]._data) {
    //     this.selectedItems[i] = this.selectedItems[i]._data;
    //   }
    // }
    this.selectedItems.forEach(function (element, index) {
      this[index] = element._data;
    }, this.selectedItems);
    this.SelectedItemsEvent.emit(this.selectedItems);
  }

  PreviewNewTemp(value, searchtext) {
    this.ngxservice.start();
    if (this.frm_UploadReport.value.filename == null || !this.isProperfile) {
      this._snackbar.open('Please select the file', 'Failed');
      this.ngxservice.stop();
      return;
    }
    let query = this.vitalHttpServices.GetDxUseridBasedOnDeployment(/*sessionStorage.getItem('deploymentKey')*/);
    // this.ngxservice.start();
    this.vitalHttpServices.GetData(query).subscribe(data => {
      try {
        //let result = data;
        // let DxUserid = result.data.submenuData.userid;
        // let dxRoleName = result.data.submenuData.rolename;
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          let DxUserid = data.data.submenuData ? data.data.submenuData : null;
          let dxRoleName = data.data.submenuData ? data.data.submenuData : null;
          if (DxUserid && DxUserid.length > 0) {
            var obj = {
              caseid: this.previewVariable, //"1453864",//"4735134",
              accountid: this.templateData.cardIdentifier, //"36181",// "22763",
              userid: DxUserid[0].userid, //this.commonservice.GetDxUseridBasedOnDeployment(sessionStorage.getItem( 'deploymentKey')),
              userrole: dxRoleName[0].rolename //"Support Technician",
            };
            //"conn":sessionStorage.getItem( 'deploymentKey')
            //case version id from caseversion table report template
            // var httpHeaders = new HttpHeaders({
            //   'Conn': "vplabs"
            // });
            // let options = {
            //   headers: httpHeaders
            // };
            // $('#PreviewDialogxmlModel').appendTo('body').modal('show');
            const frmData = new FormData();
            frmData.append('fileUpload', this.newTempfile);
            frmData.append('CaseDetailsJson', JSON.stringify(obj));
           
            this.vitalHttpServices.uploadFile(this.uploadFileContent).subscribe(
              res => {
                try {
                  if (res) {
                    if (res == null || res == '') {
                      this._snackbar.open('Template Not Found', 'Close');
                      this.ngxservice.stop();
                      return;
                    }
                    let templateIds = res.content[0].id;
                    let fileType= this.fileExtention == 'XML'? 'xml' :  this.fileExtention == 'FLXR' ? 'flxr': null;
                    let obj = {
                      "TemplateId": templateIds,
                      "CaseId": this.previewVariable,
                      "FileType": fileType
                    }
                    this.ngxService.start('preview');
                    let dbname=sessionStorage.getItem("DeploymentKey")
                    this.vitalHttpServices.previewFile(obj,dbname).subscribe((result) => {
                      this.ngxService.stop('preview');
                      // window.open(fileURL);
                      let file = new Blob([result], { type: 'application/pdf' });
                      var fileURL = URL.createObjectURL(file);
                      window.open(fileURL);

                    })
                    this.pageSrc = 'data:application/pdf;base64,' + res;
                    this.frm_UploadReport.patchValue({
                      filename: ''
                    });
                    if (this.previewHeader.toString().toLowerCase() == 'case report' && this.Status.toString().toLowerCase() == 'finalized') {
                      var auditInfo = {
                        CaseID: value,
                        UserID: (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
                        ActionPerformed: "Viewed Final Report",
                        ActionDetails: ";Finalized",
                        TimeTaken: 0,
                        TraceID: "",
                        AuditType: null,
                        ObjectID: null,
                        IsShown: 0,
                        OrderNumber: null,
                        AdditionalDetails: null,
                      }
                      if (sessionStorage.getItem("Userid") && sessionStorage.getItem("Userid") != "") {
                        this.vitalHttpServices.RecordAudit(auditInfo).subscribe(result => {
                          if (!result.errors) {
                            // let pdfWindow = window.open(
                            //   '',
                            //   '_blank',
                            //   'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 '
                            // );
                            // pdfWindow.document.write(
                            //   "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
                            //   encodeURI(res) +
                            //   "'></iframe>"
                            // );
                          }
                          else {
                            this._snackbar.open('You are unauthorized to view the report!', 'Close');
                          }
                        });
                      }
                      else {
                        this._snackbar.open('You are unauthorized to view the report!', 'Close');
                      }
                    }
                  } else {
                    this._snackbar.open('No report found', 'Close');
                    this.frm_UploadReport.patchValue({
                      filename: ''
                    });
                  }
                  //this.DomSanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res);
                  this.ngxservice.stop();
                } catch {
                  this.ngxservice.stop();
                  this._snackbar.open(
                    'An error occurred while processing your request',
                    'Failed'
                  );
                }
              }, error => {

                this.ngxservice.stop();
                this._snackbar.open(
                  'An error occurred while processing your request',
                  'Failed'
                );
              }
            );
          } else {
            this._snackbar.open('User is not authorized for this operation', 'Failed');
            this.ngxservice.stop();
          } //end of check if user id is present or not
        } else {
          this._snackbar.open('User is not authorized for this operation', 'Failed');
          this.ngxservice.stop();
        }
      } catch {
        this.ngxservice.stop();
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    }, error => {

      this.ngxservice.stop();
      this._snackbar.open(
        'An error occurred while processing your request',
        'Failed'
      );
    }
    );
    //end of if else
  } //end of method

  ApplyNewTempInDB(value) {
    if (value && value.filePath != null) {
      let downloadjson = {
        CaseType: this.templateData.cardtype,
        Accid: this.templateData.cardIdentifier,
        Template: value.TemplateType,
        GitFilePath: value.filePath
      };
      this.ngxservice.start();
      this.vitalHttpServices.ApplyTempToDB(downloadjson).subscribe(
        res => {
          this.ngxservice.stop();
          if (res.Success) {
            this._snackbar.open(res.Message, 'Success');
          } else {
            this._snackbar.open(res.Message, 'Failed');
          }
        }, error => {

          this.ngxservice.stop();
          this._snackbar.open(
            'An error occurred while processing your request',
            'Failed'
          );
        }
      );
    } else {
      this._snackbar.open('Please enter the file path', 'Failed');
    }
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
