
<div class="detailsTab">
  <mat-tab-group (selectedTabChange)="onTabChanged($event);" >
    <mat-tab *ngFor="let item of tabData; let i = index; let row"  >
      <ng-template  mat-tab-label class="cursor_pointer">
        <span style="font-size: 12px;">{{item.Htext}}</span>
      </ng-template>
        </mat-tab>
        </mat-tab-group>
</div>

