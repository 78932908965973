import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/core/services/dialog.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-manage-password-policy',
  templateUrl: './manage-password-policy.component.html',
  styleUrls: ['./manage-password-policy.component.scss']
})
export class ManagePasswordPolicyComponent implements OnInit {
  keyword: any;

  constructor(
    private _fb: FormBuilder,
    private _vitalHttpService: VitalHttpServices,
    private dialog: DialogService,
    private _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
    public activityService: ActivityTrackerService,
    private commonService: CommonService
  ) { }

  @Input() templateData: any;
  // isLoginTypeEmail: boolean = true
  loggedInUserId: string = sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : "-100"
  saveBtn: boolean = false;
  restoreBtn: boolean = false;
  editBtn: boolean = false
  policies: any
  isLodingCompleted = false;
  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  dbName: string;
  storesub:Subscription = new Subscription();
  auditableColumns: any;
  OldObjectConfig: any[] = [];
  OldObjectPPConfig: {Alphanumeric: any; IncorrectLogin: any; LoginExpiration: any; Lowercase: any; Minmumchar: any; PasswordExpiration: any; SpecialChar: any; Uppercase: any;};
  NewObjectConfig: any[] = [];
  NewObjectPPConfig: {Alphanumeric: any; IncorrectLogin: any; LoginExpiration: any; Lowercase: any; Minmumchar: any; PasswordExpiration: any; SpecialChar: any; Uppercase: any;};
  activityEntity: any;


  loginSessionForm = this._fb.group({
    loginSessionForRole: this._fb.array([])
  })

  formAddPasswordDetails = this._fb.group({
    alphanumeric: [false],
    uppercase: [false],
    lowercase: [false],
    specialchar: [false],
    minmumchar: ['', [Validators.required, ValueNotZero, ValueCannotBeLessthanSix]],
    passwordexpiration: ['', [Validators.required, ValueNotZero]],
    loginexpiration: ['', [Validators.required, ValueNotZero]],
    incorrectlogin: ['', [Validators.required, ValueNotZero]],

  })

  get loginSessionForRole() {
    return this.loginSessionForm.get('loginSessionForRole') as FormArray;
  }

  get getters() {
    return this.formAddPasswordDetails.controls
  }

  // get PwdPolicyName() {
  //   return this.formAddPasswordDetails.get('PwdPolicyName') as FormArray
  // }

  //custom error messages for ivalid inputs
  customErrorMessages: any = {
    required: {
      minmumchar: 'Enter the Minimum number of characters',
      passwordexpiration: 'Enter the Password expiration value',
      loginexpiration: 'Enter the login expiration value',
      incorrectlogin: 'Enter the Lock User IDs value',
    },
    invalidValue: {
      minmumchar: ' Minimum number of characters value Cannot be zero',
      passwordexpiration: 'Password expiration value cannot be  equal to 0',
      loginexpiration: 'Login expiration value cannot be equal to 0',
      incorrectlogin: 'Lock User IDs value cannot be equal to 0',
    },
    mincharLength:
    {
      minmumchar: 'Minimum number of characters value cannot be less than 6'
    }
  }

  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
      this.sourceApp='VitalDx';
      await this.labAdminSessionService.getLabAdminSession.subscribe(async (session:any) => {
       this.storesub= this.store.select("breadcrum").subscribe(async result => {
          let menuObj = result[result.length -1];
          this.storesub.unsubscribe();
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            // this.userSessionDetails = session['userDetails'];
            this.keyword = menuObj["Keyword"];
            // await this.commonService.getCommmonTemplateData(menuObj,session['userDetails']);
            //  this.templateData=this.labadminService.templateData;
             this.sourceApp = session['userDetails'].sourceApp
             this.dbName = this.labadminService.deploymentKey;
             sessionStorage.setItem('deploymentKey', this.dbName);
             this.GetButtonAccess(this.labadminService.subMenuPermissions);
             this.getPasswordPolicyAndLoginSessionData(this.keyword, session["userDetails"]["organizationId"])
             this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Password Policy', 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Settings','value':'Password Policy'}] }) ;
              this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
             this.getAuditableDetails('Password Policy');
          }
         })
       })
      }
    else{
      this.GetButtonAccess(this._vitalHttpService.SubmenuAction);
    this.ngxService.start()
    //  this.getLoginType(this.templateData.secondarykeys.OrganizationId)
    console.log(this.templateData)
    this.getPasswordPolicyAndLoginSessionData(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] });
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.getAuditableDetails(this.templateData.menuURL);

    }
  }


  //gets data and binds to formarray
  private getPasswordPolicyAndLoginSessionData(keyword: string, orgdId: string) {
    this._vitalHttpService.GetData({
      OperationName: null,
      Query: this.GetQuery(keyword),
      Variables: { OrganizationId: `${orgdId}` }
    }).subscribe({
      next: res => {
        res.data.TimeOutConfigurations?.map((role) =>
          this.loginSessionForRole.push(this._fb.group({
            RoleName: role.Rolename,
            TimeOutConfigurationID: role.TimeOutConfigurationID ?? 0,
            TimeOutForLock: role.LockScreenAfter ?? "",
            TimeOutForSession: role.LogOutAfter ?? ""
          })
          ))
        this.policies = res.data.policies
        res.data.policies.forEach(policy => {
          const policyName = policy.PasswordPolicyName;
          this.formAddPasswordDetails.patchValue({ [policyName]: policy.PolicyValue });
        });
        this.isLodingCompleted = true
	      this.OldObjectConfig = this.loginSessionForm.value.loginSessionForRole;
        this.OldObjectPPConfig={
          Alphanumeric: this.formAddPasswordDetails.value.alphanumeric == true ? true : false,
          IncorrectLogin:this.formAddPasswordDetails.value.incorrectlogin,
          LoginExpiration:this.formAddPasswordDetails.value.loginexpiration,
          Lowercase:this.formAddPasswordDetails.value.lowercase == true ? true : false,
          Minmumchar:this.formAddPasswordDetails.value.minmumchar,
          PasswordExpiration:this.formAddPasswordDetails.value.passwordexpiration,
          SpecialChar:this.formAddPasswordDetails.value.specialchar == true ? true : false,
          Uppercase:this.formAddPasswordDetails.value.uppercase == true ? true : false
        }

      },
      error: (err) => this.handleError(),
      complete: () => this.ngxService.stop()


    })
  }

  //To save all the values in the database
  SavePasswordConfiguration() {
    if (!this.validateData()) {
      return;
    }

    // if (this.isLoginTypeEmail) {
    //   this.ngxService.start();
    //   this._vitalHttpService.InsertAndUpdateOFTimeOutConfigurationDetails(this.TimeOutConfiguration()).subscribe({
    //     next: () => this.handleSuccess(),
    //     error: () => this.handleError(),
    //     complete: () => this.ngxService.stop()
    //   });
    //   return;
    // }

    // if (!this.validatePasswordControls()) {
    //   return;
    // }

    const postRequest1 = this._vitalHttpService.InsertAndUpdateOFTimeOutConfigurationDetails(this.TimeOutConfiguration());
    var obj : any =  this.TimeOutConfiguration().objListOfRoles.TimeOutConfiguration ;
    const newresult: any[] = [];
    this.NewObjectConfig = this.loginSessionForm.value.loginSessionForRole;
    const postRequest2 = this._vitalHttpService.InsertAndUpdateOrderingFacilityPasswordDetails(this.getPolicyObject());
    this.NewObjectPPConfig={
      Alphanumeric: this.formAddPasswordDetails.value.alphanumeric,
      IncorrectLogin:this.formAddPasswordDetails.value.incorrectlogin,
      LoginExpiration:this.formAddPasswordDetails.value.loginexpiration,
      Lowercase:this.formAddPasswordDetails.value.lowercase,
      Minmumchar:this.formAddPasswordDetails.value.minmumchar,
      PasswordExpiration:this.formAddPasswordDetails.value.passwordexpiration,
      SpecialChar:this.formAddPasswordDetails.value.specialchar,
      Uppercase:this.formAddPasswordDetails.value.uppercase
    }
    this.ngxService.start();

    forkJoin([postRequest1, postRequest2]).subscribe({
      next: () => this.handleSuccess(),
      error: () => this.handleError(),
      complete: () => this.ngxService.stop()
    });
  }

  // validatePasswordControls(): boolean {
  //   for (const controlName in this.formAddPasswordDetails.controls) {
  //     const control = this.formAddPasswordDetails.get(controlName);
  //     if (control?.errors) {
  //       const errorMessages = [];
  //       for (const errorKey in control?.errors) {
  //         const errorMessage = this.customErrorMessages[errorKey][controlName];
  //         errorMessages.push(errorMessage);
  //         this._snackbar.open(errorMessages.join(' '), 'Close')
  //         document.getElementById(controlName).focus()
  //         break;
  //       }
  //       return false
  //     }
  //   }
  //   return true
  // }

  //applies or hides "apply all" text to UI
  displayOrHideApplyAll(from: string, id: string) {
    for (let i = 0; i < this.loginSessionForRole.length; i++) {
      let element = document.getElementById(from + i) as HTMLElement
      let otherRow = document.getElementById(['TimeOutForLock', 'TimeOutForSession'].find((e) => e != from) + i) as HTMLElement
      if (i == Number(id)) {
        element.innerHTML = 'Apply to All'
        otherRow.innerHTML = ''
      }
      else {
        element.innerHTML = ''
        otherRow.innerHTML = ''
      }
    }
  }

  //Suggests time out value based on lock screen value only if time out is empty
  SuggestTimeOut(id: string) {
    var TimeOutForLock = this.loginSessionForRole.at(Number(id)).get('TimeOutForLock').value

    if (this.loginSessionForRole.at(Number(id)).get('TimeOutForSession').value === '' && TimeOutForLock != '')
      this.loginSessionForRole.at(Number(id)).patchValue({
        TimeOutForSession: Number(TimeOutForLock) + 60
      })

    this.validateSessionValues(id)
  }

  //validate the session values and give alert on focus out
  validateSessionValues(id: string) {
    let TimeOutForLock = this.loginSessionForRole.at(Number(id)).get('TimeOutForLock').value
    let TimeOutForSession = this.loginSessionForRole.at(Number(id)).get('TimeOutForSession').value

    if (Number(TimeOutForLock) > Number(TimeOutForSession) && TimeOutForSession != '') {
      this.dialog.openDialog("Invalid Input", `Lock screen time for ${this.loginSessionForRole.at(Number(id)).get('RoleName').value} must be less than log out time`, "", "OK", "dontshow")
      return false
    }
    return true
  }

  //Get graphql query
  private GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this._vitalHttpService.mainQueryList.findIndex(
      mainQuery =>
        mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this._vitalHttpService.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  //applies that value to all other values of that row
  ApplyAll(from: string, id: string) {
    if (this.validateSessionValues(id)) {
      let replaceValue = this.loginSessionForRole.at(Number(id)).get(from).value
      for (let i = 0; i < this.loginSessionForRole.length; i++) {
        this.loginSessionForRole.at(i).get(from).setValue(replaceValue)

        if (from == "TimeOutForLock") {
          if (this.loginSessionForRole.at(i).get('TimeOutForSession').value === '' && replaceValue != '') {
            this.loginSessionForRole.at(i).get('TimeOutForSession').setValue(Number(replaceValue) + 60)
          }
        }
      }
    }
  }

  private getPolicyObject() {
    return {
      "Organizationid": this.sourceApp == 'VitalDx' ? this.labadminService.organizationId.toString() : this.templateData.secondarykeys.OrganizationId.toString(),
      "PwdPolicyName": [
        { Name: 'alphanumeric', Value: this.formAddPasswordDetails.controls.alphanumeric.value == true ? '1' : '0', Sequence: 1, IsActive: 1 },
        { Name: 'uppercase', Value: this.formAddPasswordDetails.controls.uppercase.value == true ? '1' : '0', Sequence: 2, IsActive: 1 },
        { Name: 'lowercase', Value: this.formAddPasswordDetails.controls.lowercase.value == true ? '1' : '0', Sequence: 3, IsActive: 1 },
        { Name: 'specialchar', Value: this.formAddPasswordDetails.controls.specialchar.value == true ? '1' : '0', Sequence: 4, IsActive: 1 },
        { Name: 'minmumchar', Value: this.formAddPasswordDetails.controls.minmumchar.value, Sequence: 5, IsActive: 1 },
        { Name: 'passwordexpiration', Value: this.formAddPasswordDetails.controls.passwordexpiration.value, Sequence: 6, IsActive: 1 },
        { Name: 'loginexpiration', Value: this.formAddPasswordDetails.controls.loginexpiration.value, Sequence: 7, IsActive: 1 },
        { Name: 'incorrectlogin', Value: this.formAddPasswordDetails.controls.incorrectlogin.value, Sequence: 8, IsActive: 1 }
      ],
      "IsRole": "0"
    }
  };

  private TimeOutConfiguration() {
    let loginSessionData = this.loginSessionForm.value


    loginSessionData.loginSessionForRole = loginSessionData.loginSessionForRole
      ?.map((element: any) => ({
        ...element,
        TimeOutForSession: element.TimeOutConfigurationID && element.TimeOutForSession === '' ? '0' : element.TimeOutForSession,
        TimeOutForLock: element.TimeOutConfigurationID && element.TimeOutForLock === '' ? '0' : element.TimeOutForLock
      }))
      ?.filter((obj: any) => obj.TimeOutForLock !== '');
    return {
      "OrgID": this.sourceApp == 'VitalDx' ? this.labadminService.organizationId.toString() : this.templateData.secondarykeys.OrganizationId.toString(),
      "objListOfRoles":
      {
        "TimeOutConfiguration": loginSessionData.loginSessionForRole
      },
      "UserID": this.loggedInUserId
    }
  };

  // To check the validation
  private validateData(): boolean {
    const formArray = this.loginSessionForm.get('loginSessionForRole') as FormArray;

    for (let i = 0; i < formArray.length; i++) {
      const formGroup = formArray.at(i) as FormGroup;
      const timeOutForSession = Number(formGroup.get('TimeOutForSession').value);
      const timeOutForLock = Number(formGroup.get('TimeOutForLock').value);

      if (!timeOutForSession && timeOutForLock) {
        this._snackbar.open(`Log Out time for ${formGroup.get('RoleName').value} is mandatory`, 'Close')
        document.getElementById(`ForSessionInp_${i}`).querySelector('input').focus()
        return false;
      }
      if (timeOutForSession < timeOutForLock) {
        const dialog1 = this.dialog.openDialog("Invalid Input", `Lock screen time for ${formGroup.get('RoleName').value} must be less than log out time`, "", "OK", "dontshow")
        dialog1.afterClosed().subscribe(() => {
          document.getElementById(`ForSessionInp_${i}`).querySelector('input').focus()
        })
        return false;
      }
    }

    for (const controlName in this.formAddPasswordDetails.controls) {
      const control = this.formAddPasswordDetails.get(controlName);
      if (control?.errors) {
        const errorMessages = [];
        for (const errorKey in control?.errors) {
          const errorMessage = this.customErrorMessages[errorKey][controlName];
          errorMessages.push(errorMessage);
          this._snackbar.open(errorMessages.join(' '), 'Close')
          document.getElementById(controlName).focus()
          break;
        }
        return false
      }
    }

    return true
  }
  OpenRestoreDialog() {
    const confirmPopUp = this.dialog.openDialog("", "Do you want to Restore changes?", "", "Restore", "Back");
    confirmPopUp.afterClosed().subscribe(result => {
      result && this.RestoreDefault()
    })
  }

  // To restore the default values
  RestoreDefault() {

    for (let i = 0; i < this.loginSessionForRole.length; i++) {
      let element = document.getElementById('TimeOutForLock' + i) as HTMLElement
      let otherRow = document.getElementById('TimeOutForSession' + i) as HTMLElement
      element.innerHTML = ''
      otherRow.innerHTML = ''
    }

    this.ngxService.start()
    this._vitalHttpService.GetData({
      OperationName: null,
      Query: this.GetQuery('getdefaultpasswordpolicy'),
    }).subscribe(
      {
        next: res => {
          res.data.DefaultPasswordPolicy.forEach(policy => {
            const policyName = policy.PasswordPolicyName;
            this.formAddPasswordDetails.patchValue({ [policyName]: policy.PolicyValue });
          })
        },
        error: () => this.handleSuccess(),
        complete: () => this.ngxService.stop()

      }
    )
    const loginSessionFormArray = this.loginSessionForm.get('loginSessionForRole') as FormArray;
    loginSessionFormArray.controls.forEach((formGroup: FormGroup) => {
      formGroup.get('TimeOutForSession').patchValue('');
      formGroup.get('TimeOutForLock').patchValue('')
    })
  }

  //configure the buttons
  private GetButtondetails() {
    this.GetButtonAccess(this._vitalHttpService.SubmenuAction);
  }

  private GetButtonAccess(actionButtonDetails: any) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext  == 'Password Policy')["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    // let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (const element of seletedMenuPermissions) {
      switch (element.Button) {
        case "Save":
          this.saveBtn = element.IsPermitted === "true";
          break;
        case "Restore Defaults":
          this.restoreBtn = element.IsPermitted === "true";
          break;
        case "Edit":
          this.editBtn = element.IsPermitted === "true";
          break;

      }
    }
  }

  //checks if value is entered is 0 or not. If zero then clears the value
  checkforValueZero(id: string, from: string) {
    var value = this.loginSessionForRole.at(Number(id)).get(from).value
    if (Number(value) === 0) {
      this.loginSessionForRole.at(Number(id)).get(from).setValue('')
    }
  }

  private handleSuccess() {
    this.ngxService.stop();
    let actionPerformedOn : string
    actionPerformedOn = 'Settings';
    let sessionId = this.commonService.generateGuid();
    for (let i = 0; i < this.NewObjectConfig.length; i++){
    this.commonService.createActivityObject(this.NewObjectConfig[i].RoleName,this.NewObjectConfig[i].RoleName, this.sourceApp == 'VitalDx' ? 'Password Policy' :this.templateData.menuURL, 'Edit', this.NewObjectConfig[i], this.OldObjectConfig[i], sessionId,this.auditableColumns);
    }
    this.commonService.createActivityObject('',actionPerformedOn, this.sourceApp == 'VitalDx' ? 'Password Policy' :this.templateData.menuURL, 'Edit', this.NewObjectPPConfig, this.OldObjectPPConfig, sessionId,this.auditableColumns);
    this._snackbar.open('Data updated successfully', 'Close');
    this.getPasswordPolicyAndLoginSessionData(this.sourceApp == 'VitalDx' ? this.keyword :this.templateData.Keyword, this.sourceApp == 'VitalDx' ? this.labadminService.organizationId :this.templateData.secondarykeys.OrganizationId)
  }

  private handleError() {
    this.isLodingCompleted = true
    this.ngxService.stop();
    this._snackbar.open('Something went wrong. Please try again', 'Close');
  }

  getAuditableDetails(location: any) {
    this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }
}

//customValidation
export function ValueNotZero(control: AbstractControl) {
  if (control.value === 0) {
    return { invalidValue: true };
  }
  return null;
}

export function ValueCannotBeLessthanSix(control: AbstractControl) {
  if (control.value < 6) {
    return { mincharLength: true };
  }
  return null;
}


