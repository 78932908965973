import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-instructions-editor',
  templateUrl: './instructions-editor.component.html',
  styleUrls: ['./instructions-editor.component.scss'],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InstructionsEditorComponent  implements OnInit {
  @Input() formcontrolname : string | null = null
  @Input() heading:string|null=''
  @Input() Id:string|null=''
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    //height:'12rem',
    minHeight: '12rem',
    maxHeight:'12rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Times New Roman',
    defaultFontSize: '4',
    toolbarHiddenButtons: [
      ['fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'strikeThrough',
        'subscript',
        'superscript',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit(): void {
    
  }

}
