<form *ngIf="isCopy" [formGroup]="copyFormGroup">
    <span class="sub-header m-1"><b>Source <span class="sub-header grey-color">(Copy from)</span> </b></span>
    <div class="row row-border ml-1 mr-1 mb-3">
        <mat-form-field class="col-sm-4 example-additional-selection mt-4 mb-4 ml-0 mr-0" appearance="outline">
            <mat-label>Entity<span class="text-danger">*</span></mat-label>
            <mat-select disableOptionCentering formControlName="frmSrcEntity"
                (selectionChange)="onSourceEntityChange($event)">
                <mat-option value="Custom Source">Custom Source</mat-option>
                <mat-option value="Default Templates for Lab">Default Templates for Lab</mat-option>
                <mat-option value="Default Templates for Facility">Default Templates for Facility</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="copyFormGroup.controls.frmSrcEntity.value == 'Custom Source'"
            class="col-sm-4 example-additional-selection mt-4 mb-4 ml-0 mr-0" appearance="outline">
            <mat-label>Deployment<span class="text-danger">*</span></mat-label>
            <mat-select disableOptionCentering formControlName="frmSrcDeployment"
            (selectionChange)="onDeploymentChange($event)">
                <mat-option *ngFor="let deptKey of DeploymentKeys" value="{{deptKey}}">
                    <span>{{ deptKey }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="copyFormGroup.controls.frmSrcEntity.value == 'Custom Source'"
            class="col-sm-4 example-additional-selection mt-4 mb-4 ml-0 mr-0" appearance="outline">
            <mat-label>Group<span class="text-danger">*</span></mat-label>
            <input type="text" placeholder="Search " matInput [matAutocomplete]="autoGroup"
                formControlName="frmSrcGroup">
            <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let group of filteredSrcGroup | async" [value]="group">
                    {{group.organizationname}} ({{group.organizationid}})
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <span class="sub-header m-1"><b>Destination <span class="sub-header grey-color mt-3">(Copy to)</span> </b></span>

    <div class="row row-border ml-1 mr-1 mb-3">
        <mat-form-field class="col-sm-4 example-additional-selection mt-4 mb-4 ml-0 mr-0" appearance="outline">
            <mat-label>Entity<span class="text-danger">*</span></mat-label>
            <input type="text" placeholder="Search " matInput [matAutocomplete]="autoEntity"
                formControlName="frmDestEntity">
            <mat-autocomplete #autoEntity="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let entity of filteredDestEntity | async" [value]="entity">
                    {{entity.displayname}} ({{entity.accountid}})
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="row ml-1 mr-1 mb-3">
        <div class="col-sm-8 m-0 p-0">
            <span class="instr-size mb-2">
                <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                Only templates will be copied, you have to manually upload the template files if you're trying to copy
                templates across the deployment.</span>
        </div>
        <div class="col-sm-4 button-wrap m-0 p-0">
            <button mat-raised-button *ngIf="postUpload" class="ct-admin-btn-success-outline" (click)="resetCopyGrid()">
                Cancel
            </button>
            <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success-outline"
                (click)="returnBack()">Return</button>
            <button mat-raised-button class="ml-4"
                [ngClass]="(disableGetDataBtn || postUpload) ? 'disable-admin-btn' : 'admin-btn'"
                [disabled]="disableGetDataBtn || postUpload" (click)="getData()">Get Data</button>
        </div>
    </div>
    <div *ngIf="gridWidth && gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid)" #flexgrid
            [frozenColumns]="1" (formatItem)="formatItem(flexgrid, $event)">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [allowResizing]="true"
                    [minWidth]="200" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [visible]="!(columnname == 'Status')"
                    [allowResizing]="true" [minWidth]="200" [allowDragging]="false" [allowSorting]="false"
                    [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="row ml-1 mr-1 mb-3 button-wrap" style="float: right;">
            <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success-outline mr-4"
                (click)="resetCopyGrid()">
                Cancel
            </button>
            <button mat-raised-button class="admin-btn mr-4" *ngIf="postUpload" (click)="returnBack()">
                Finish
            </button>
            <button mat-raised-button *ngIf="!postUpload" [disabled]="(selectedItems && selectedItems.length == 0) || !isValid(true)"
                [ngClass]="((selectedItems && selectedItems.length == 0) || (isValid(true) == false)) ? 'disable-admin-btn' : 'admin-btn'"
                (click)="uploadCopyData(flexgrid)">
                Approve
            </button>
            <button mat-raised-button class="admin-btn" *ngIf="postUpload" (click)="ExportResult(flexgrid)">
                Download
            </button>
        </div>
        <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
            <div class="error-msg">* Invalid Columns: </div>
            &nbsp;<span>{{ invalidColumns }} </span>
        </div>
    </div>
    <div class="col-sm-12" *ngIf="noDataFound">
        <div class="container create-btn col-sm-12 align-center" style="height: 48vh !important;">
            <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                <div class="nodata-design">No Data Found</div>
            </div>
        </div>
    </div>
</form>

<div *ngIf="!isCopy" class="my-adequacy-form">
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0 m-0" appearance="outline">
            <mat-label>Templates</mat-label>
            <mat-select disableOptionCentering>
                <mat-option (click)="downloadAllFields()" class="dropdown-opt-wrap">
                <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1 mr-1" src="/assets/icons/help.svg" width="15px"
                height="auto" />
            You can download the sample template file to upload VD Templates.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span class="align-center">Drag & Drop excel file here</span>
            <span class="align-center">Or</span>
            <span class="align-center"><label class="btn btn-sm rounded admin-btn mt-2 browse" for="fileDropRef">Browse
                    file</label></span>
        </div>
        <button mat-raised-button class="ct-admin-btn-success-outline mt-4" (click)="returnBack()">
            Return
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1 (initialized)="initGrid(grid1)"
            [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)" [frozenColumns]="2">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [allowResizing]="true" [width]="120" [allowDragging]="false"
                    [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
                    [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="'*'"
                    [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="120"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3 mt-4">
            <div class="col-sm-2 p-0">
                <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success-outline" (click)="returnBack()">
                    Return
                </button>
                <button mat-raised-button *ngIf="postUpload" class="ct-admin-btn-success-outline mr-4"
                        (click)="resetCopyGrid()">Cancel</button>
            </div>
            <div class="col-sm-10 button-field-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success-outline mr-4"
                        (click)="resetCopyGrid()">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" [disabled]="!isValid()" class="mr-4"
                        [ngClass]="(isValid() == false) ? 'disable-admin-btn' : 'admin-btn'"
                        (click)="saveData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn mr-4"
                        (click)="returnBack()">Finish</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn"
                        (click)="ExportResult(grid1)">Download
                        Result</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>

</div>