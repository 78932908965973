import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-infopreference',
  templateUrl: './infopreference.component.html',
  styleUrls: ['./infopreference.component.scss']
})
export class InfopreferenceComponent {

  @Input() userDetails = ''
  @Input() userRoles: any
  settingList: any;
  LicensureList = [];
  outOfoffice = []
  frm_isActive: any
  isValidRole: boolean = false;
  isReadOnly: boolean = true; 
  noDataFoundFlag: boolean = false;

  ngOnChanges() {
    if (this.userDetails !== '' && this.userDetails !== undefined) {
      if (this.userDetails['userlicensure']?.length) {
        this.LicensureList = this.userDetails['userlicensure']
      }
      if (this.userDetails['PathOOODetails']?.length) {
        this.outOfoffice = this.userDetails['PathOOODetails']
      }
    }
  }

  checkInfoPreference() {
    return Object.keys(this.userDetails)?.length;
  }

  isRoleExists(roles: any[]): boolean {
    const valuesToCheck = ['Pathologist', 'Cytotechnologist Supervisor', 'Cytotechnologist'];
    if (roles.some(obj => valuesToCheck.includes(obj.Role))) {
      return true;
    }
    this.noDataFoundFlag = true;
    return false;
  }

}
