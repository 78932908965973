import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';

declare var $: any;
declare var jQuery: any;
declare var window: any;

export class Validationclass {
    Loginname: string;
    Password: string;
}

@Component({
    selector: 'template-RevisionReasonModel',
    templateUrl: 'RevisionReason.component.html',
    styleUrls: ['./RevisionReason.component.scss']
})
export class RevisionReasonComponent {
    @Input()
    templateDataInp: any;
    @Input() selecteddefaultdeploymentkey:any;
    public caseId;
    public revisionType;
    public reason;
    public DeploymentKeys = [];
    private deploymentKey = "";
    //  public DeploymentKeys = environment.DeploymentKeys;
    public templateData: any[];
    public ChangeStatus: any[];
    public ExecuteSqlTabArray: any[];
    RevisionReasongroup = this._fb.group({
        DeploymentKey: ['none', Validators.required],
        QuickSearch: ['', Validators.required],
        RevisionSearchInpTxt: ['', Validators.required],
    })

    DeleteModalGrp = this._fb.group({
        ApproverInpTxt: ['', Validators.required],
        TicketInpTxt: ['', Validators.required],
        NotesInpTxt: ['', Validators.required],
    })

    searchType = "";
    searchValue = "";
    showTab = false;
    gridData: CollectionView;
    showGrid = false;
    ShowUpdateHl7 = false;
    SelectedGridItems: any[] = [];
    gridwidth
    gridheader = ['Status', 'CaseType', 'Lab', 'Requisition_Number', 'Case_ID', 'Accesion_Number'];
    subMenuCardModel: SubMenuCardModel;
    defaultdeploymentkey: string;
    constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
        private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,
        public GridwithCB: GridContainerWithCheckBoxComponent,
        public _snackBar: MatSnackBar, public dialog: MatDialog, private datashare: DataShareService) {
        this.RevisionReasongroup.patchValue(
            {
                DeploymentKey: "none",
                QuickSearch: "Accession",
            });
        this.subMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
        this.DeploymentKeys = this.vaservices.DeploymentKeys;
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
    }

    RevisionClose() {
        this.RevisionReasongroup.reset();
        this.resetformCOntrols();
        // $('#HL7LogsModel').modal('hide');
        $('#genericModal').modal('hide');
        this.ShowUpdateHl7 = false;
        this.RevisionReasongroup.patchValue(
            {
                DeploymentKey: "none",
                QuickSearch: "Accession",
            });
    }

    public addndnRsn = [];
    public ammendRsn = [];
    public correctn = [];
    public ifModalShow = false;
    public crctnReason;
    public ammdmnReason;
    public addndmReason;
    public tabQuery;
    onClick(grid, e) {
        var hti = grid.hitTest(e);
        var col = hti.col;
        var row = hti.row;
        if (hti.panel === grid.cells) {
            var item = hti.panel.rows[row].dataItem;
        }

        this.caseId = item.Case_ID;
        let query = this.vaservices.GetQuery('revisionreasondisplay');
        let queryVariable = { "caseid": item.Case_ID.toString() };
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.tabQuery = queryResult;
        this.GetTabData(queryResult);

    }

    GetTabData(query) {
        let addndnRsnArr = {};
        let ammendRsnArr = {};
        let correctnArr = {};
        this.addndnRsn = [];
        this.ammendRsn = [];
        this.correctn = [];
        this.ngxService.start();
        this.vaservices.GetData(query, this.deploymentKey.toLowerCase()).subscribe(
            res => {
                this.ngxService.stop();
                if (!res.errors) {
                    let reslt = res.data.submenudata[0];
                    let substr = reslt.AddendumReason;
                    let substr1 = reslt.AmendmentReason;
                    if (reslt.AddendumReason != null && reslt.AddendumReason != '' && reslt.AddendumReason != undefined && reslt.AddendumReason != "" && reslt.AddendumReason.includes('<BR>')) {
                        this.addndmReason = reslt.AddendumReason;
                        substr = reslt.AddendumReason.split('<BR>');
                        for (var i = 0; i < substr.length; i++) {
                            addndnRsnArr = {};
                            addndnRsnArr["Reason"] = substr[i];
                            this.addndnRsn.push(addndnRsnArr);
                        }
                    } else {
                        if (reslt.AddendumReason != "" && reslt.AddendumReason != '') {
                            this.addndmReason = reslt.AddendumReason;
                            addndnRsnArr["Reason"] = substr;
                            this.addndnRsn.push(addndnRsnArr);
                        } else {
                            this.addndmReason = null;
                            addndnRsnArr["Reason"] = null;
                            this.addndnRsn.push(addndnRsnArr);
                        }

                    }

                    if (reslt.AmendmentReason != null && reslt.AmendmentReason != '' && reslt.AmendmentReason != undefined && reslt.AmendmentReason != "" && reslt.AmendmentReason.includes('<BR>')) {
                        this.ammdmnReason = reslt.AmendmentReason;
                        substr1 = reslt.AmendmentReason.split('<BR>');
                        for (var i = 0; i < substr1.length; i++) {
                            ammendRsnArr = {};
                            ammendRsnArr["Reason"] = substr1[i];
                            this.ammendRsn.push(ammendRsnArr);
                        }
                    } else {
                        if (reslt.AmendmentReason != "" && reslt.AmendmentReason != '') {
                            this.ammdmnReason = reslt.AmendmentReason;
                            ammendRsnArr["Reason"] = substr1;
                            this.ammendRsn.push(ammendRsnArr);
                        } else {
                            this.ammdmnReason = null;
                            ammendRsnArr["Reason"] = null;
                            this.ammendRsn.push(ammendRsnArr);
                        }

                    }
                    let substr2 = reslt.CorrectionReason;
                    if (reslt.CorrectionReason != null && reslt.CorrectionReason != '' && reslt.CorrectionReason != undefined && reslt.CorrectionReason != "" && reslt.CorrectionReason.includes('<BR>')) {
                        substr2 = reslt.CorrectionReason.split('<BR>');
                        this.crctnReason = reslt.CorrectionReason;
                        for (var i = 0; i < substr2.length; i++) {
                            correctnArr = {};
                            correctnArr["Reason"] = substr2[i];
                            this.correctn.push(correctnArr);
                        }
                    } else {
                        this.crctnReason = reslt.CorrectionReason;
                        if (reslt.CorrectionReason != "" && reslt.CorrectionReason != '') {
                            correctnArr["Reason"] = substr2;
                            this.correctn.push(correctnArr);
                        }
                        else {
                            this.crctnReason = null;
                            correctnArr["Reason"] = null;
                            this.correctn.push(correctnArr);
                        }
                    }
                    // setTimeout(() => {
                    //     document.getElementById("correction").style.width = '100% !important;';
                    // }, 50);

                    this.showTab = true;
                } else
                    console.error(res.error);
            }, error => {

                this.ngxService.stop();
                console.error(error);
            });
    }

    ngOnDestroy() {

    }

    public mainqueryResut

    search(selVal) {
        let queryResult: any;
        let queryVariable;
        this.showTab = false;
        this.showGrid = false;
        let query = this.vaservices.GetQuery('revisionreasonsearch');

        //this.deploymentKey = selVal.DeploymentKey.trim();
        // if(selVal.DeploymentKey === this.selecteddefaultdeploymentkey){
        //     this.deploymentKey = this.selecteddefaultdeploymentkey;
        // }else{
        //     this.deploymentKey =selVal.DeploymentKey;
        // }

        if(this.selecteddefaultdeploymentkey ==  selVal.DeploymentKey.trim() && this.selecteddefaultdeploymentkey.toLowerCase() !='all'){
            this.deploymentKey = this.selecteddefaultdeploymentkey;
          }else{
            if(selVal.DeploymentKey.trim()!=="none"){
              this.deploymentKey = selVal.DeploymentKey.trim(); 
            }else{
              this.deploymentKey = this.selecteddefaultdeploymentkey; 
            }
          }
        this.searchType = selVal.QuickSearch;
        this.searchValue = selVal.RevisionSearchInpTxt;

        //this.vaservices.deploymentKey = depKey.toLowerCase();
        switch (this.searchType) {
            case 'Case ID':
                if (!isNaN(Number(this.searchValue))) {
                    queryVariable = { "caseid": this.searchValue, "requisitionnumber": "null", "accessionnumber": "null" };
                } else {
                    queryVariable = { "accessionnumber": "null", "requisitionnumber": "null", "caseid": "0" };
                }
                break;
            case 'Requisition Number':
                queryVariable = { "requisitionnumber": this.searchValue, "caseid": "0", "accessionnumber": "null" };
                break;
            case 'Accession':
                queryVariable = { "accessionnumber": this.searchValue, "requisitionnumber": "null", "caseid": "0" };
                break;
            default:
                queryVariable = { "accessionnumber": "null", "requisitionnumber": "null", "caseid": "0" };
                break;
        }
        //queryVariable = { "caseId": sessionStorage.getItem("orgid"), "accessionNum": accountId.toString(), "logId": accountId.toString() };
        queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.ngxService.start();
        this.mainqueryResut = queryResult;
        this.getGridData(queryResult);
    }

    // public GetQuery(keyword: string) {
    //     let query: string = "";
    //     let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    //     if (mainQueryIndex > -1)
    //         query = this.vaservices.mainQueryList[mainQueryIndex].Query;
    //     return query;
    // }

    getGridData(queryResult) {
        this.vaservices.GetData(queryResult, this.deploymentKey.toLowerCase()).subscribe(res => {
            this.ngxService.stop();
            if (!res.errors) {
                // this.HL7Logsgroup.reset();
                let result = res.data;
                if (result.submenudata.length > 0) {
                    this.templateData = result.submenudata;
                } else if (result.submenudata1.length > 0) {
                    this.templateData = result.submenudata1;
                } else {
                    this.templateData = result.submenudata2;
                }
                this.AddGridData();
            }
            else
                console.error(res.error);
        }, error => {
            this.ngxService.stop();
            console.error(error);
        });
    }
    public prevReason;

    delete(type, reasn) {

        this.ifModalShow = true;
        this.revisionType = type;
        this.reason = reasn;
        this.prevReason = reasn;

    }

    Save(val) {
        let query = this.vaservices.GetDxUseridBasedOnDeployment(/*sessionStorage.getItem( 'deploymentKey')*/);
        this.ngxService.start();
        this.DeleteModalGrp.patchValue(
            {
                ApproverInpTxt: "",
                TicketInpTxt: "",
                NotesInpTxt: ""
            });
        if (this.revisionType == 'Correction') {
            if (this.crctnReason != null && this.crctnReason.includes('<BR>')) {
                var splitted = this.crctnReason.split('<BR>');
                let rea = this.reason;
                let str;
                let breakTrue = false;;
                let rsn;
                for (var i = 0; i < splitted.length; i++) {
                    if (splitted[i] != rea) {
                        if (splitted.length == 2) {
                            this.reason = splitted[i];
                            breakTrue = true;
                            break;
                        } else {
                            str = splitted[i];

                            if (i == 0) {
                                rsn = str;;
                            } else {
                                if (rsn != undefined) {
                                    rsn = rsn.concat("<BR>", str);
                                } else {
                                    rsn = str;
                                }
                                this.reason = rsn;
                            }
                        }
                    }
                }
                // this.reason = this.crctnReason.replace(this.reason + '<BR>',' ');
                // this.reason =  this.crctnReason.replace( '<BR>' + this.reason ,' ');
            } else {
                this.reason = this.crctnReason.replace(this.reason, ' ');
            }
        } else if (this.revisionType == 'Addendum') {
            if (this.addndmReason != null && this.addndmReason.includes('<BR>')) {
                var splitted = this.addndmReason.split('<BR>');
                let rea = this.reason;
                let str;
                let breakTrue = false;;
                let rsn;
                for (var i = 0; i < splitted.length; i++) {
                    if (splitted[i] != rea) {
                        if (splitted.length == 2) {
                            this.reason = splitted[i];
                            breakTrue = true;
                            break;
                        } else {
                            str = splitted[i];

                            if (i == 0) {
                                rsn = str;;
                            } else {
                                if (rsn != undefined) {
                                    rsn = rsn.concat("<BR>", str);
                                } else {
                                    rsn = str;
                                }
                                this.reason = rsn;
                            }

                        }
                    }
                }
            } else {
                this.reason = this.addndmReason.replace(this.reason, ' ');
            }
        } else if (this.revisionType == 'Amendment') {
            if (this.ammdmnReason != null && this.ammdmnReason.includes('<BR>')) {
                var splitted = this.ammdmnReason.split('<BR>');
                let rea = this.reason;
                let str;
                let rsn;
                for (var i = 0; i < splitted.length; i++) {
                    if (splitted[i] != rea) {
                        if (splitted.length == 2) {
                            this.reason = splitted[i];
                            break;
                        } else {
                            str = splitted[i];

                            if (i == 0) {
                                rsn = str;;
                            } else {
                                if (rsn != undefined) {
                                    rsn = rsn.concat("<BR>", str);
                                } else {
                                    rsn = str;
                                }
                                this.reason = rsn;
                            }

                        }


                    }
                }
            } else {
                this.reason = this.ammdmnReason.replace(this.reason, ' ');
            }
        }
        // return;
        this.vaservices.GetData(query, this.deploymentKey.toLowerCase()).subscribe(data => {
            let result = data;
            let DxUserid = result.data.submenuData ? result.data.submenuData : null;
            // let DxUserid = '22637296';
            let notes = {
                "PreviousReason": this.prevReason,
                "Approver": val.ApproverInpTxt,
                "Ticket": val.TicketInpTxt,
                "Notes": val.NotesInpTxt
            }
            let strNotes = JSON.stringify(notes);
            let caseid = this.caseId;
            let revisiontype = this.revisionType;
            let userid = DxUserid[0].userid;
            var obj = {
                "reason": this.reason,
                "caseId": this.caseId,
                "revisionType": this.revisionType,
                "userid": DxUserid[0].userid,
                "notes": strNotes
            }
            this.vaservices.DeleteRevisionReason(obj, this.deploymentKey.toLowerCase()).subscribe(
                res => {
                    this.ngxService.stop();
                    let reslt = res;
                    this.GetTabData(this.tabQuery);
                    this.ifModalShow = false;
                    this._snackBar.open("Deleted Successfully", "Success");
                }, error => {

                    this.ngxService.stop();
                    console.error(error);
                }
            );
        });
    }


    close() {
        this.ifModalShow = false;
        this.DeleteModalGrp.patchValue(
            {
                ApproverInpTxt: "",
                TicketInpTxt: "",
                NotesInpTxt: ""
            });
    }
    public gridarray;
    public colWidth;
    AddGridData() {
        this.showGrid = true;
        this.gridarray = []
        let primary = {}
        if (this.templateData) {
            if (this.templateData.length > 0) {
                for (let i = 0; i < this.templateData.length; i++) {
                    primary = {}
                    for (let [key, value] of Object.entries(this.templateData[i])) {
                        for (let j = 0; j < this.gridheader.length; j++) {
                            if (key === this.gridheader[j]) {
                                primary[key] = value
                            }
                        }
                    }
                    this.gridarray.push(primary)
                }
            }
        }
        this.gridData = new CollectionView(this.gridarray, { pageSize: 10 })
        this.gridwidth = (170 * this.gridheader.length) + 37;
        if (this.gridwidth > 1300)
            this.gridwidth = 1300;
    }

    resetformCOntrols() {
        this.showTab = false;
        this.showGrid = false;
        this.ifModalShow = false;
        this.RevisionReasongroup.patchValue(
            {
                DeploymentKey: "",
                QuickSearch: "Accession",
                RevisionSearchInpTxt: ""
            });
        this.DeleteModalGrp.patchValue(
            {
                ApproverInpTxt: "",
                TicketInpTxt: "",
                NotesInpTxt: ""
            });
    }
}
