import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  template: `
    <label class="switch mb-0">
      <input type="checkbox" id="togBtn" [class.disable]="disabled" (click)="emitValue($event)" [checked]="value" [disabled]="disabled">
        <div class="slider round">
          <span class="on">{{text.split(':')[0]}}</span>
          <span class="off">{{text.split(':')[1]}}</span>
      </div>
      </label>
  `,
  styles: [
    `
    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2.5px;
  bottom: 2.1px;
  background-color: silver;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  border-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  background-color: #1871bb;
  -webkit-transform: translateX(39px);
  -ms-transform: translateX(39px);
  transform: translateX(39px);
}

.on {
  display: none;
}

.on,
.off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 9px;
  font-weight: 500;
  font-family: Verdana, sans-serif;
}

.on{  
  color : #1871bb;
  left: 35%;
}

.off{
  color : silver;
  left: 62%;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

.slider.round {
  border : 1px solid grey;
  border-radius: 36px;
}

.slider.round:before {
  border-radius: 70%;
}

.disable{
  opacity : 0.2;
  cursor : not-allowed;
}
`
  ]
})
export class SwitchComponent implements OnInit {
  @Input() value : Boolean = true;
  @Output() clicked =  new EventEmitter()
  @Input() disabled : Boolean = false;
  @Input() text : string = 'Active:Inactive'

  constructor() { }

  ngOnInit(): void {
  }

  emitValue(e){
    this.clicked.emit(e.target.checked)
  }

}
