<!-- <div class="row mt-2 text-white pr-4">
  <div class="col-sm-3 pr-0">
    <div class=" card vt-card red pl-1 pr-2">
      <p class="mt-1">
        <span class="text-md">Cold</span>
        <em class="fa fa-close _600 pull-right text-lg" aria-hidden="true"></em>
      </p>
      <span *ngIf="dashboradData">
        <p class="text-md"> {{dashboradData.coldagents}}/{{totalDashboard}} </p>
        <p class="text-md">{{ mathAround(dashboradData.coldagents/totalDashboard * 100) }} % of all bridges</p>
      </span>
    </div>
  </div>
  <div class="col-sm-3 pr-0">
    <div class="card  vt-card warn pl-1 pr-2 text-white">
      <p class="mt-1">
        <span class="text-md">Critical</span>
        <em class="fa fa-exclamation-circle _600 pull-right text-lg" aria-hidden="true"></em>
      </p>
      <span *ngIf="dashboradData">
        <p class="text-md"> {{dashboradData.criticalagents}}/ {{totalDashboard}} </p>
        <p class="text-md">{{ mathAround(dashboradData.criticalagents/totalDashboard * 100)}} % of all bridges</p>
      </span>
    </div>
  </div>
  <div class="col-sm-3  pr-0">
    <div class=" card vt-card green pl-1 pr-2">
      <p class="mt-1">
        <span class="text-md">Healthy</span>
        <em class="fa fa-check  _600 pull-right text-lg" aria-hidden="true"></em>
      </p>
      <span *ngIf="dashboradData">
        <p class="text-md"> {{dashboradData.healthyagents}}/{{totalDashboard}} </p>
        <p class="text-md">{{mathAround(dashboradData.healthyagents/totalDashboard * 100)}} % of all bridges</p>
      </span>
    </div>
  </div>
  <div class="col-sm-3  ">
    <div class="card vt-card blue pl-1 pr-2">
      <p class="mt-1">
        <span class="text-md">Pending</span>
        <span class="material-icons  _600 pull-right text-lg"> attach_file</span>
      </p>
      <p class="text-md" style="padding-bottom:11% ;" *ngIf="dashboradData"> {{this.dashboradData.pendingagent}} </p>
    </div>
  </div>
</div> -->
<!-- <div *ngIf="gridDisplay" class="container-fluid mt-5">
  <div class="col-sm-10">
    <div class="row">
      <div class="col-sm-2">
        <select scope="row" (change)="setgetfactor($event.target.value)">
          <option selected disabled>Select</option>
          <option value="getvbagentinfobyvbname?vbname=">Name</option>
          <option value="getvbagentinfobyagentid?agentid=">AgentID</option>
          <option value="getvbagentinfobyorgguid?orgguid=">Group GUID</option>
        </select>
      </div>
      <div class="col-sm-8">
        <input (keyup.enter)="getVBData($event)" class="form-control" placeholder="Enter VitalBridge keyword..">
      </div>
    </div>
  </div>
  <wj-flex-grid [ngStyle]="{'width.%': gridwidth}" [isReadOnly]="true" (click)="onClick(agentid,$event)"
    [itemsSource]="gridData"  [itemFormatter]="formatItem" #agentid>
    <div *ngFor="let columnname of gridHeader">
      <wj-flex-grid-column [binding]="columnname" [allowDragging]="false" [width]="170" [allowResizing]="true"
        [format]="'d'"></wj-flex-grid-column>
    </div>
    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
  </wj-flex-grid>
   <wj-flex-grid #flex [(itemsSource)]="gridData">
  </wj-flex-grid>
</div> -->
<div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div *ngIf="gridDisplay" class="modal-content">
          <wj-flex-grid  (initialized)="initGrid(agentid)" [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="gridData"
            (click)="onClick(agentid,$event)" style="margin: auto;"
            [itemFormatter]="formatItem"  #agentid>
            <wj-flex-grid-column
            [width]="200" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
              [header]="col.header">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
          </wj-flex-grid>
          <div style="text-align: center;">
            <wj-collection-view-navigator *ngIf="gridarray.length > 10" class="mt-2"
              headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
          </div>
          <div class="sub-button-space mt-4">
            <button mat-raised-button class="admin-btn-success" (click)="ExportExcel(agentid)" [disabled]="hideExportBtn ">Export</button>
          </div>
        </div>
        <div *ngIf="DataNotFound" class="nodata-wrap mt-4">
          <div class="nodata-design"> No Data Found </div>
        </div>

        <div *ngIf="ShowGrid">
          <center>
            <div class="headerLine">
              VitalBridge ID:  <strong>{{this.AgentID}}</strong>
            </div>
          </center>
          <wj-flex-grid [headersVisibility]="'Column'"  class="main-grid" [isReadOnly]="true" [itemsSource]="gridVBJobData"
            [itemFormatter]="formatItem" (initialized)="initGrid(gridd)" #gridd>
            <wj-flex-grid-column [width]="200" [minWidth]="40" [maxWidth]="160" *ngFor="let col of columnsJobDetail" format="MM-dd-yyyy" [binding]="col.binding"
              [header]="col.header">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
          </wj-flex-grid>
          <div style="text-align: center;">
            <wj-collection-view-navigator *ngIf="gridJobarray.length > 10"
              headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridVBJobData">
            </wj-collection-view-navigator>
            <div *ngIf="gridjobwidth == 0" class="nodata-wrap mt-4">
              <div class="nodata-design"> No Data Found </div>
            </div>
          </div>
          <div class="sub-button-space mt-4">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="ExportExcel(gridd)"  [disabled]="hideExportBtn ">Export</button>
            <button mat-raised-button class="admin-btn-success" (click)="back()" >Back</button>
          </div>
        </div>

        <!-- <div class="sub-button-space mt-3">
          <button (click)="ExportExcel(gridd)" class="btn btn-sm admin-btn-success ExportButtonSize" [disabled]="export ">Export</button>
          <button (click)="back()" class="btn BackButtonSize">Back</button>
        </div> -->

      </div>
    </div>
  </div>
</div>
