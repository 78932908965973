import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatDialog,  MatDialogConfig } from "@angular/material/dialog";
import {  MatSnackBar } from '@angular/material/snack-bar';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { ActionbtnComponent } from '../actionbtn/actionbtn.component';
import { BehaviorSubject } from "rxjs";
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { environment } from 'src/environments/environment';
import { OrgCompare } from '../../../model/org-compare';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'template-OrgCompare',
  templateUrl: 'OrgCompare.component.html',
  styleUrls: ['./OrgCompare.component.scss']
})

export class OrgCompareComponent implements OnInit, OnDestroy {

  ResultJSON1: any = "";
  ResultJSON2: any = "";
  editor_height: number;
  language = "text/plain";
  // OrgCompare_GroupByKeys: ['0.OrganizationAttributes.AttributeContext', '0.OrganizationPasswordPolicies.IsActive'],
  fileToUpload: File | null = null;

  diffOptions = {
    theme: "vs",
    // language: this.language,
    language: "plaintext",
    readOnly: true,
    renderSideBySide: true,
    lineNumbers: true,
    roundedSelection: false,
    scrollBeyondLastLine: true
  };

  originalModel: DiffEditorModel = {
    code: this.ResultJSON1,
    // language: 'text/plain'
    language: this.language
  };

  modifiedModel: DiffEditorModel = {
    code: this.ResultJSON2,
    // language: 'text/plain'
    language: this.language
  };
  errorJsonFile1: boolean = false;
  errorJsonFile2: boolean = false;

  // disableSelect1 = new FormControl(true);
  // disableSelect2 = new FormControl(true);

  public Block_level_menu: string[] = [];
  public BlockLevelMenuArr = environment.OrgCompare_BlockLevel_Menu;
  public DeploymentKeys = [];

  NoDeploymentKeyAlert = false;
  //public DeploymentKeys = environment.DeploymentKeys;

  NoAccData = false;
  showAccData = false;
  public templateData;
  SelectedAccID: any = "";
  AccCaseTypeArr: string[] = [];
  SelectedAcc_orgID: any = "";
  SelectedAccountId: any;
  ToggleTextChecked: boolean = false;
  ToggledJSONChecked = false;
  public ToggledJSON = 'JSON1';
  public ToggledText = 'OrgInfo';

  public GetJSON1Result = {
    OrganizationAttributes: "",
    OrgInfo: ""
  };
  public GetJSON2Result = {
    OrganizationAttributes: "",
    OrgInfo: ""
  };

  @Input() fromButton: string;
  @Input() CaseType: string;
  @Input() OrgID: string;
  @Input() AccID: string;
  @Input() ModalID: number;
  ToggleTextCheckedJSON1: boolean = false;
  textareaclass: boolean = true;
  isJSON1: boolean = false;
  isJSON2: boolean = false;
  message: string;
  // @Output() public destroychild = new EventEmitter();
  OrgCompareArray: any[] = [];
  selectedOrgModal: any;
  public modalSub = new BehaviorSubject<any>(this.OrgCompareArray);

  @Output() ModalIDNew = new EventEmitter();

  ngOnInit() {
    // this.editor_height = 0;
    // if (this.fromButton == "actionButtoninLab") {
    //   this.SelectedAccountId = this.AccID;
    //   this.SelectedAcc_orgID = this.OrgID;
    //   sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);
    //   this.ToggledJSON = 'JSON1';
    //   this.ToggledJSONChecked = false;
    //   this.onChangeAccCaseTypeJSON1(this.CaseType);
    // }
    this.initial();
  }

  constructor(public fb: FormBuilder, public dialog: MatDialog, private ngxService: NgxUiLoaderService,
    breakpointObserver: BreakpointObserver, public vaservices: VitalHttpServices,
    public _snackBar: MatSnackBar, private commonService: CommonService) {
    this.Block_level_menu = [];
    for (let i = 0; i < this.BlockLevelMenuArr.length; i++) {
      this.Block_level_menu.push(this.BlockLevelMenuArr[i].split(':')[0]);
    }
    this.DeploymentKeys = this.vaservices.DeploymentKeys;
  }

  Jform: FormGroup = this.fb.group(
    {
      // frm_json1: [JSON.stringify(this.json1), Validators.required],
      // frm_json2: [JSON.stringify(this.json2), Validators.required],
      frm_json1: ["", Validators.required],
      frm_json2: ["", Validators.required],
      firstFormGroup: this.fb.group(
        {
          frmDeploymentKey: new FormControl('none')
        }),
      secondFormGroup: this.fb.group(
        {
          frmAccount: {
            value: '',
            disabled: true
          },
          frm_OrgCompareSelectAccGroup: this.fb.group(
            {
              frmDrpAccSearch: '',
              frmAccSearchInpTxt: ''
            })
        }),
      ThirdFormGroup: this.fb.group(
        {
          frmAccCaseType: {
            value: '-1',
            disabled: true
          }
        })
    });

  initial() {
    this.editor_height = 0;
    if (this.fromButton == "actionButtoninLab") {
      // $('#OrgCompareinLabModal').appendTo("body").modal('show');
      this.SelectedAccountId = this.AccID;
      this.SelectedAcc_orgID = this.OrgID;
      sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);
      this.ToggledJSON = 'JSON1';
      this.ToggledJSONChecked = false;
      this.onChangeAccCaseTypeJSON1(this.CaseType);
    }
  }

  isEditable: boolean = this.Jform.value.secondFormGroup.frmAccount == '' ? true : false;


  CloseOrgCompare() {
    if (this.fromButton == "actionButtoninLab") {
      $('#OrgCompareinLabModal').appendTo("body").modal('hide');
      // $('.OrgCompareinLabModal').appendTo("body").modal('hide');
      // this.message = "";
    }
    else {
      $('#genericModal').modal('hide');
    }
    this.errorJsonFile1 = false;
    this.errorJsonFile2 = false;
    this.editor_height = 0;
    // this.Jform.patchValue({
    //   frm_json1: '',
    //   frm_json2: ''
    // });
    this.originalModel = {
      code: '',
      language: this.language
    };

    this.modifiedModel = {
      code: '',
      language: this.language
    };

    // let el: HTMLElement = this.stepperreset.nativeElement;
    // el.click();

    // this.stepperreset.reset();
    this.closePopAcc();

    this.Jform.patchValue({
      frm_json1: '',
      frm_json2: '',
      firstFormGroup: {
        frmDeploymentKey: 'none'
      },
      secondFormGroup:
      {
        frmAccount: '',
        frm_OrgCompareSelectAccGroup:
        {
          frmDrpAccSearch: '',
          frmAccSearchInpTxt: ''
        }
      },
      ThirdFormGroup: {
        frmAccCaseType: '-1'
      }
    });

    this.language = "text/plain";
    // OrgCompare_GroupByKeys: ['0.OrganizationAttributes.AttributeContext', '0.OrganizationPasswordPolicies.IsActive'],
    this.fileToUpload = null;

    this.Block_level_menu = [];
    this.BlockLevelMenuArr = environment.OrgCompare_BlockLevel_Menu;
    for (let i = 0; i < this.BlockLevelMenuArr.length; i++) {
      this.Block_level_menu.push(this.BlockLevelMenuArr[i].split(':')[0]);
    }

    this.NoDeploymentKeyAlert = false;
    this.DeploymentKeys = this.vaservices.DeploymentKeys;

    this.NoAccData = false;
    this.showAccData = false;
    this.templateData = [];
    this.SelectedAccID = "";
    this.AccCaseTypeArr = [];
    this.SelectedAcc_orgID = "";
    this.SelectedAccountId = "";
    this.ToggleTextChecked = false;
    this.ToggledJSONChecked = false;
    this.ToggledJSON = 'JSON1';
    this.ToggledText = 'OrgInfo';

    this.GetJSON1Result = {
      OrganizationAttributes: "",
      OrgInfo: ""
    };
    this.GetJSON2Result = {
      OrganizationAttributes: "",
      OrgInfo: ""
    };

    // $('.collapse').collapse('hide');
    $('.collapsePopAcc').collapse('hide');
    $('#collapseTextArea').removeClass('collapsed');
    if (this.fromButton != "actionButtoninLab") {
      $('#TextArea').removeClass('hide');
      $('#TextArea').addClass('show');
      // this.ModalID = this.ModalID + 1;
      // this.ModalIDNew.emit(this.ModalID);
    }
    else {
      this.textareaclass = true;
    }
    // this.ngOnDestroy();
    // this.destroychild.emit;
    // this.initial();
    // if (this.fromButton == "actionButtoninLab") {
    //   this.actnbtncomp.destroyChild();
    // }
  }
  dataToggle() {
    // if ($('#TextArea').hasClass('show')) {
    if (this.textareaclass) {
      // $('#TextArea').removeClass('show');
      // $('#TextArea').addClass('hide');
      // $('#TextArea').collapse('hide');
      // (<HTMLElement>document.getElementById('TextArea')).classList.remove('show');
      this.textareaclass = false;
    }
    else {
      // $('#TextArea').removeClass('hide');
      // $('#TextArea').addClass('show');
      // $('#TextArea').collapse('show');
      // (<HTMLElement>document.getElementById('TextArea')).classList.add('show');
      this.textareaclass = true;
    }
  }

  handleFileInput(files: FileList, FileNum: string) {
    this.errorJsonFile1 = false;
    this.errorJsonFile2 = false;
    // this.fileToUpload = files.item(0); //displays file details
    if (files[0].type.toLowerCase() === "application/json") {
      this.fileToUpload = files[0];
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        //onload event will be fired after the content is read so the logic should be inside the onLoad function
        if (FileNum == 'JsonFile1') {
          this.Jform.patchValue(
            {
              frm_json1: fileReader.result
            }
          );
        }
        else if (FileNum == 'JsonFile2') {
          this.Jform.patchValue(
            {
              frm_json2: fileReader.result
            }
          );
        }
      }
      fileReader.readAsText(this.fileToUpload);
    }
    else {
      if (FileNum == 'JsonFile1') {
        this.errorJsonFile1 = true;
      }
      else if (FileNum == 'JsonFile2') {
        this.errorJsonFile2 = true;
      }
    }
  }

  sort(unorderedJSON1: any, unorderedJSON2: any) {
    this.ngxService.start();
    try {
      let ordered1;
      let ordered2;
      if (unorderedJSON1 != "") {
        //ignore mentioned keys
        ordered1 = this.removeKeys(JSON.parse(unorderedJSON1));

        //sort based on values
        ordered1 = this.sortByValue(ordered1);

        //group based on mentioned keys
        // ordered1 = this.groupingJSON(ordered1);

        //sort json by object key
        ordered1 = this.sortObject(ordered1, false);

        //prettify json
        ordered1 = JSON.stringify(ordered1, null, 2); // spacing level = 2
      }
      if (unorderedJSON2 != "") {
        ordered2 = this.removeKeys(JSON.parse(unorderedJSON2));
        ordered2 = this.sortByValue(ordered2);
        // ordered2 = this.groupingJSON(ordered2);
        ordered2 = this.sortObject(ordered2, false);
        // ordered2 = this.sortObject(JSON.parse(unorderedJSON2), false);
        ordered2 = JSON.stringify(ordered2, null, 2); // spacing level = 2
      }
      // this.ResultJSON1 = ordered1;
      // this.ResultJSON2 = ordered2;

      this.editor_height = 30;

      this.originalModel = {
        code: ordered1,
        language: this.language
      };

      this.modifiedModel = {
        code: ordered2,
        language: this.language
      };

      if (this.fromButton == 'actionButtoninLab') {
        this.textareaclass = false;
      }
      else {
        $('#TextArea').removeClass('show');
      }

      // $('#TextArea').removeClass('show')
      // $('#TextArea').addClass("hide");
      this.ngxService.stop();
    }
    catch {
      this.ngxService.stop();
    }
  }

  sortObject(unordered: any, sortArrays = false) {
    if (!unordered || typeof unordered !== 'object') {
      return unordered;
    }

    if (Array.isArray(unordered)) {
      const newArr: any = unordered.map((item) => this.sortObject(item, sortArrays));
      if (sortArrays) {
        newArr.sort();
      }
      return newArr;
    }

    const ordered: any = {};
    Object.keys(unordered)
      .sort()
      .forEach((key) => {
        ordered[key] = this.sortObject(unordered[key], sortArrays);
      });
    return ordered;
  }

  groupingJSON(InputJson) {
    let InputJsonArray = InputJson;
    let InputJsonCopy = InputJson;
    let Key = "";

    for (let i = 0; i < environment.OrgCompare_GroupByKeys.length; i++) {
      let KeyPath: string = environment.OrgCompare_GroupByKeys[i];
      let Key_arr: string[] = KeyPath.split('.');
      let flag = false;

      for (let j = 0; j < Key_arr.length; j++) {
        if (!flag) {
          if (j != Key_arr.length - 1) {
            if (InputJsonArray[Key_arr[j]]) {
              // InputJsonArray = InputJsonArray[0][Key_arr[j]];
              InputJsonArray = InputJsonArray[Key_arr[j]];
            }
            else {
              flag = true;
            }
          }
          else if (j == Key_arr.length - 1) {
            Key = Key_arr[j];
          }
        }
        else {
          break;
        }
      }

      //grouping json code
      // requires array
      if (Array.isArray(InputJsonArray) && !flag) {
        InputJsonArray = InputJsonArray.reduce((rv: any, x: any) => {
          (rv[x[Key]] = rv[x[Key]] || []).push(x);
          return rv;
        }, {});// converted into json object

        // InputJsonCopy[0][Key_arr[Key_arr.length - 2]] = InputJsonArray;
        // return InputJsonCopy;

        let KeyPathWithoutKey = KeyPath.substring(0, KeyPath.lastIndexOf('.'));
        this.assign(InputJsonCopy, KeyPathWithoutKey, InputJsonArray);
        // return InputJsonCopy;
        InputJsonArray = InputJsonCopy;
      }
      // return InputJsonCopy;//Returns initial value when its not 555555an array
      else {
        InputJsonArray = InputJson;
      }
    }
    return InputJsonArray;
    //   // requires array
    //   if (Array.isArray(InputJson)) {
    //     for (let i = 0; i < environment.OrgCompare_GroupByKeys.length; i++) {
    //       let keyPath = environment.OrgCompare_GroupByKeys[i];
    //       let Key_arr: string[] = keyPath.split(':');
    //       // if (Array.isArray(InputJson)) {
    //       InputJson = InputJson.reduce((rv: any, x: any) => {
    //         (rv[x[Key_arr[Key_arr.length - 1]]] = rv[x[Key_arr[Key_arr.length - 1]]] || []).push(x);
    //         return rv;
    //       }, {});
    //       return InputJson;// converted into json object
    //     }
    //   }
    //   return InputJson;//Returns initial value when its not an array
  }

  assign(obj: any, prop: any, value: any) {
    //ConvertStringToObjectPathAndAssignValue
    if (typeof prop === "string")
      prop = prop.split(".");

    if (prop.length > 1) {
      var e = prop.shift();
      this.assign(obj[e] =
        Object.prototype.toString.call(obj[e]) === "[object Object]"
          ? obj[e]
          : {},
        prop,
        value);
    }
    else
      obj[prop[0]] = value;
  }

  // //items.sort(sortByProperty(items, “pId”));
  sortByValue(Input_Json: any) {
    // if (!unordered || typeof unordered !== 'object') {
    //   return unordered;
    // }

    // if (Array.isArray(unordered)) {
    //   const newArr: any = unordered.map((item) => this.sortByProperty(item, property));
    //   return newArr;
    // }
    let UnSorted_Json_Temp = Input_Json;
    let sortByValueArr: string[] = environment.OrgCompare_sortByValue;
    for (let i = 0; i < sortByValueArr.length; i++) {
      let KeyPath = sortByValueArr[i];
      let pathArr: string[] = sortByValueArr[i].split('.');
      let flag = false;
      for (let j = 0; j < pathArr.length - 1; j++) {
        if (!flag) {
          if (UnSorted_Json_Temp[pathArr[j]]) {
            UnSorted_Json_Temp = UnSorted_Json_Temp[pathArr[j]];
          }
          else {
            flag = true;
          }
        }
        else {
          break;
        }
      }
      let property: string = pathArr[pathArr.length - 1];
      if (Array.isArray(UnSorted_Json_Temp) && !flag) {
        UnSorted_Json_Temp.sort((a: any, b: any) => {
          if (a[property] > b[property])
            return 1;
          else if (a[property] < b[property])
            return -1;

          return 0;
        });
        let KeyPathWithoutKey = KeyPath.substring(0, KeyPath.lastIndexOf('.'));
        this.assign(Input_Json, KeyPathWithoutKey, UnSorted_Json_Temp);
        UnSorted_Json_Temp = Input_Json;
      }
      else {
        UnSorted_Json_Temp = Input_Json;
      }
    }
    return UnSorted_Json_Temp;
  }

  removeKeys(InputJson) {
    let InputJsonArray = InputJson;
    let InputJsonCopy = InputJson;
    let Key = "";

    for (let i = 0; i < environment.OrgCompare_RemoveKeys.length; i++) {
      let KeyPath: string = environment.OrgCompare_RemoveKeys[i];
      let Key_arr: string[] = KeyPath.split('.');
      let flag = false;

      for (let j = 0; j < Key_arr.length; j++) {
        if (!flag) {
          if (j != Key_arr.length - 1) {
            if (InputJsonArray[Key_arr[j]]) {
              InputJsonArray = InputJsonArray[Key_arr[j]];
            }
            else {
              flag = true;
            }
          }
          else if (j == Key_arr.length - 1) {
            Key = Key_arr[j];
          }
        }
        else {
          break;
        }
      }

      //removing keys from json
      // requires array
      if (Array.isArray(InputJsonArray) && !flag) {
        for (let k = 0; k < InputJsonArray.length; k++) {
          delete InputJsonArray[k][Key];
        }
        let KeyPathWithoutKey = KeyPath.substring(0, KeyPath.lastIndexOf('.'));
        this.assign(InputJsonCopy, KeyPathWithoutKey, InputJsonArray);
        // return InputJsonCopy;
        InputJsonArray = InputJsonCopy;
      }
      // return InputJsonCopy;//Returns initial value when its not 555555an array
      else {
        InputJsonArray = InputJson;
      }
    }
    return InputJsonArray;
  }

  openDialogIgnoreKeys() {
    // this.dialog.open(openDialogIgnoreKeys);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    // dialogConfig.position = {
    //   'top': '30%',
    //   left: '30%'
    // };

    this.dialog.open(openDialogIgnoreKeys, dialogConfig);
  }

  groupBlockJson(json: any, Key: string, jObjectName: string) {
    let InputJsonArray = json;
    let InputJsonCopy = {};
    if (Array.isArray(InputJsonArray)) {
      //grouping json code
      // requires array
      InputJsonArray = InputJsonArray.reduce((rv: any, x: any) => {
        (rv[x[Key]] = rv[x[Key]] || []).push(x);
        return rv;
      }, {});// converted into json object
      this.assign(InputJsonCopy, jObjectName, InputJsonArray);
      InputJsonArray = InputJsonCopy;
    }
    else {
      InputJsonArray = json;
    }
    return InputJsonArray;
  }

  groupBlockJsonByValue(json: any, ObjKey: string, ValKey: string, jObjectName: string) {
    let InputJsonArray: any = json;
    let InputJsonCopy: any = {};
    if (Array.isArray(InputJsonArray)) {
      //grouping json code
      // requires array
      InputJsonArray = InputJsonArray.reduce((rv: any, x: any) => {
        (rv[x[ObjKey]] = rv[x[ObjKey]] || []).push(x);
        return rv;
      }, {});// converted into json object
      // this.assign(InputJsonCopy, jObjectName, InputJsonArray);
      // InputJsonArray = InputJsonCopy;

      if (InputJsonArray[ValKey])
        InputJsonArray = (InputJsonArray[ValKey]);
      else {
        InputJsonArray = InputJsonCopy;
      }
    }
    else {
      InputJsonArray = json;
    }
    return InputJsonArray;
  }

  getJSON(json: any, name: string) {
    if (json != "") {
      // let InputJson = json;
      let InputJsonCopy = {};
      for (let i = 0; i < this.BlockLevelMenuArr.length; i++) {
        let flag = false;
        if (this.BlockLevelMenuArr[i].split(':')[0] == name) {
          let menuArr: string[] = this.BlockLevelMenuArr[i].split(':');
          let path: string[] = menuArr[1].split('.');
          let Objectkey: string = path[path.length - 1];
          let ValueKey: string = "";
          if (menuArr[2].toLowerCase() == 'true') {
            // Objectkey = "";
            // ValueKey = "";
            if (menuArr.length === 4) {
              ValueKey = menuArr[menuArr.length - 1];
            }
            for (let j = 0; j < (path.length - 1); j++) {
              if (!flag) {
                if (json[path[j]]) {
                  json = json[path[j]];
                }
                else {
                  flag = true;
                }
              }
              else {
                break;
              }
            }
            if (!flag) {
              if (ValueKey != "") {
                return this.groupBlockJsonByValue(json, Objectkey, ValueKey, path[path.length - 2]);
                // json = json[0]['OrganizationPasswordPolicies'];
                // return this.groupBlockJson(json, "PwdPolicyName", "OrganizationPasswordPolicies");
              }
              else {
                return this.groupBlockJson(json, Objectkey, path[path.length - 2]);
              }
            }
            else {
              return InputJsonCopy;
            }
          }
          else if (menuArr[2].toLowerCase() == 'false') {
            for (let j = 0; j < path.length; j++) {
              if (!flag) {
                if (json[path[j]]) {
                  json = json[path[j]];
                }
                else {
                  flag = true;
                }
              }
              else {
                break;
              }
            }
            if (!flag) {
              this.assign(InputJsonCopy, Objectkey, json);
              return InputJsonCopy;
              // json = json[0].Organizations;
              // this.assign(InputJsonCopy, "Organizations", json);
              // return InputJsonCopy;
            }
            else {
              return InputJsonCopy;
            }
          }
          break;
        }
      }
      // if (name == "Organizations") {
      //   json = json[0].Organizations;
      //   this.assign(InputJsonCopy, "Organizations", json);
      //   return InputJsonCopy;
      // }
      // else if (name == "Group Attributes") {
      //   json = json[0]['OrganizationAttributes'];
      //   this.assign(InputJsonCopy, "OrganizationAttributes", json);
      //   return InputJsonCopy;

      // }
      // else if (name == "Group Password Policies") {
      //   json = json[0]['OrganizationPasswordPolicies'];
      //   this.assign(InputJsonCopy, "OrganizationPasswordPolicies", json);
      //   return InputJsonCopy;
      // }
      // else if (name == "Group Password Policies -> Pwd Policy Name") {
      //   json = json[0]['OrganizationPasswordPolicies'];
      //   return this.groupBlockJson(json, "PwdPolicyName", "OrganizationPasswordPolicies");
      // }
      // else if (name == "Group Attributes -> AttributeContext") {
      //   json = json[0]['OrganizationAttributes'];
      //   return this.groupBlockJson(json, "AttributeContext", "OrganizationAttributes");
      // }
      // else if (name == "Group Attributes -> AttributeType") {
      //   json = json[0]['OrganizationAttributes'];
      //   return this.groupBlockJson(json, "AttributeType", "OrganizationAttributes");
      // }
    }
    else {
      return {};
    }
  }

  findDiff(name: any) {
    let json1: any = this.Jform.value.frm_json1;
    let json2: any = this.Jform.value.frm_json2;
    let ordered1: any = "";
    let ordered2: any = "";
    if (name != "") {
      if (json1 != "") {
        ordered1 = this.removeKeys(JSON.parse(json1));
        ordered1 = this.sortByValue(ordered1);
        ordered1 = this.getJSON(ordered1, name);
        // ordered1 = this.groupingJSON(ordered1);
        ordered1 = this.sortObject(ordered1, false);
        // ordered1 = this.sortObject(JSON.parse(unorderedJSON2), false);
        ordered1 = JSON.stringify(ordered1, null, 2); // spacing level = 2
      }
      if (json2 != "") {
        ordered2 = this.removeKeys(JSON.parse(json2));
        ordered2 = this.sortByValue(ordered2);
        ordered2 = this.getJSON(ordered2, name);
        // ordered2 = this.groupingJSON(ordered2);
        ordered2 = this.sortObject(ordered2, false);
        // ordered2 = this.sortObject(JSON.parse(unorderedJSON2), false);
        ordered2 = JSON.stringify(ordered2, null, 2); // spacing level = 2
      }
    }

    this.editor_height = 30;

    this.originalModel = {
      code: ordered1,
      language: this.language
    };

    this.modifiedModel = {
      code: ordered2,
      language: this.language
    };

    if (this.fromButton == 'actionButtoninLab') {
      this.textareaclass = false;
    }
    else {
      $('#TextArea').removeClass('show');
    }
  }

  //Function for comparision start here
  filterJSON(obj1: any, obj2: any) {
    // obj1 = JSON.parse(obj1);
    // obj2 = JSON.parse(obj2);
    // let ret1 = {};
    // let ret2 = {};
    // ret1 = this.CompareNDiff(obj2, obj1);
    // ret2 = this.CompareNDiff(obj1, obj2);

    // this.editor_height = 30;

    // this.originalModel = {
    //   code: "" + JSON.stringify(ret1, null, 2),
    //   language: this.language
    // };

    // this.modifiedModel = {
    //   code: "" + JSON.stringify(ret2, null, 2),
    //   language: this.language
    // };
  }

  CompareNDiff(JSON1: any, JSON2: any) {
    // let ret1 = {};
    // let ret2 = {};
    // for (let i in JSON1) {
    //   if (!JSON1 || typeof JSON1 !== 'object') {
    //     return JSON1;
    //   }

    //   if (Array.isArray(JSON1)) {
    //     const newArr: any = JSON1.map((item) => this.CompareNDiff(item, JSON2));
    //     return newArr;
    //   }

    //   const ordered: any = {};
    //   Object.keys(JSON1)
    //     .sort()
    //     .forEach((key) => {
    //       ordered[key] = this.CompareNDiff(JSON1[key], JSON2);
    //     });

    //   if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
    //     ret2[i] = obj2[i];
    //   }
    // }
  }
  //Function for comparision end here

  onChangeDep(deviceValue) {
    this.closePopAcc();
    // this.Jform.value.GenerateJSONfromAcc.frmDeploymentKey = deviceValue;
    this.Jform.patchValue(
      {
        firstFormGroup:
        {
          frmDeploymentKey: deviceValue
        }
      });
    this.NoDeploymentKeyAlert = false;
  }

  funcShowPopAcc() {
    this.Jform.patchValue(
      {
        secondFormGroup: {
          frmAccount: "",
          frm_OrgCompareSelectAccGroup:
          {
            frmDrpAccSearch: "Location ID"
          }
        }
      });
    if (this.Jform.value.firstFormGroup.frmDeploymentKey.toString() != "" && this.Jform.value.firstFormGroup.frmDeploymentKey != undefined) {
      $('.collapse').collapse('show');
      this.NoDeploymentKeyAlert = false;
    }
    else {
      $('.collapsePopAcc').collapse('hide');
      this.NoDeploymentKeyAlert = true;
    }
  }

  closeManualPopAcc() {
    $('.collapsePopAcc').collapse('hide');
    this.Jform.patchValue(
      {
        secondFormGroup:
        {
          frm_OrgCompareSelectAccGroup:
          {
            frmDrpAccSearch: '',
            frmAccSearchInpTxt: ''
          }
        }
      }
    );
    this.NoAccData = false;
    this.showAccData = false;
  }

  closePopAcc() {
    this.closeManualPopAcc();
    this.resetOrgCompareDetails();
  }

  resetOrgCompareDetails() {
    this.Jform.patchValue(
      {
        secondFormGroup:
        {
          frmAccount: ''
        },
        ThirdFormGroup:
        {
          frmAccCaseType: '-1'
        }
      });
    this.SelectedAccountId = "";
    this.SelectedAcc_orgID = "";
    this.AccCaseTypeArr = [];
    (<FormGroup>(this.Jform.get('ThirdFormGroup'))).controls['frmAccCaseType'].disable();
  }

  // public GetQuery(keyword: string) {
  //   let query: string = "";
  //   let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
  //   if (mainQueryIndex > -1)
  //     query = this.vaservices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }

  btnSearchAcc(frm_OrgCompareSelectAccGroupValue) {
    // let searchValue = (<HTMLInputElement>document.getElementById('inpOrgSearch')).value;
    let searchDrpValue = frm_OrgCompareSelectAccGroupValue.frmDrpAccSearch;
    let searchInpValue = frm_OrgCompareSelectAccGroupValue.frmAccSearchInpTxt.trim();
    let depKey: string = this.Jform.value.firstFormGroup.frmDeploymentKey.toString().trim();

    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('OrgComparefilterAcc');

    let queryString: string = "";
    this.vaservices.deploymentKey = depKey.toLowerCase();
    sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);

    switch (searchDrpValue) {
      case 'Location ID':
        queryString = "accountid >= \"0\":decimal and accountid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Location Name':
        queryString = "accountid >= \"0\":decimal and accountname like \"%" + searchInpValue + "%\"";
        queryVariable = { "filterParam": queryString }
        break;
      default:
        queryVariable = '';
        break;
    }

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        this.templateData = res.data.Accounts_list;
        if (this.templateData.length > 0) {
          // this.templateData = this.templateData;
          this.showAccData = true;
          this.NoAccData = false;
        }
        else {
          this.templateData = []
          this.showAccData = false;
          this.NoAccData = true;
        }
      }
      else {
        console.error(res.error);
        this.templateData = [];
        this.showAccData = true;
        this.NoAccData = false;
      }
    }, error => {
      console.error(error);
      this.templateData = [];
      this.showAccData = true;
      this.NoAccData = false;
    });
  }

  setAccount(AccValue) {
    let displayAcc: string = "(" + AccValue.accountid + ") " + AccValue.accountname;
    this.SelectedAccountId = AccValue.accountid;
    this.SelectedAcc_orgID = AccValue.organizationid;
    this.Jform.patchValue({
      secondFormGroup: {
        frmAccount: displayAcc.trim()
      }
    });
    // this.SelectedAccID = AccValue.organizationid;
    // this.closePopAcc();
    this.closeManualPopAcc();
    this.getAccCaseTypes(this.SelectedAccountId);
  }

  getAccCaseTypes(accountId) {
    let depKey: string = "";
    depKey = this.Jform.value.firstFormGroup.frmDeploymentKey.toString().trim();
    let queryResult: any;
    let queryVariable;
    let query = this.vaservices.GetQuery('orgcomparegetcasetypes');

    this.vaservices.deploymentKey = depKey.toLowerCase();
    sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);

    queryVariable = { "filterParam": "accountid >= \"0\":decimal and accountid in \"" + accountId + "\" and IsActive = \"true\"" };

    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult).subscribe(res => {
      this.AccCaseTypeArr = [];
      if (!res.errors) {
        // this.frm_AddUserSelectOrgGroup.reset();
        this.templateData = res.data.AccountCasetypes_list;
        if (this.templateData.length > 0) {
          this.AccCaseTypeArr = this.templateData;
          // this.Jform.patchValue(
          //   {
          //     GenerateJSONfromAcc:
          //     {
          //       frmAccCaseType: '-1',
          //     }
          //   });
          (<FormGroup>(this.Jform.get('ThirdFormGroup'))).controls['frmAccCaseType'].enable();
        }
        else {
          this.templateData = [];
          (<FormGroup>(this.Jform.get('ThirdFormGroup'))).controls['frmAccCaseType'].disable();
        }
      }
    }, error => {
      console.error(error);
      this.templateData = [];
      (<FormGroup>(this.Jform.get('ThirdFormGroup'))).controls['frmAccCaseType'].disable();
    });
  }

  onChangeAccCaseType(SelectedCaseType) {
    const AccDetails = new OrgCompare(this.SelectedAcc_orgID, this.SelectedAccountId, SelectedCaseType, null);
    if (this.fromButton != 'actionButtoninLab') {
      if (SelectedCaseType != "-1") {
        if (this.SelectedAccountId != "" && this.SelectedAcc_orgID != "") {
          // let AccDetails =
          // {
          //   OrgID: this.SelectedAcc_orgID,
          //   AccID: this.SelectedAccountId,
          //   CaseType: SelectedCaseType,
          //   TableName: null
          // };

          this.ngxService.start();
          this.vaservices.genereateJSON(AccDetails).subscribe(data => {
            this.ngxService.stop();
            if (!data.errors) {
              let res=data.content
              if (res.OrgInfo != null && res.OrganizationAttributes != null) {
                if (this.ToggledJSON == 'JSON1' && this.ToggledJSONChecked == false) {
                  this.GetJSON1Result.OrganizationAttributes = res.OrganizationAttributes;
                  this.GetJSON1Result.OrgInfo = res.OrgInfo;
                }
                else if (this.ToggledJSON == 'JSON2' && this.ToggledJSONChecked == true) {
                  this.GetJSON2Result.OrganizationAttributes = res.OrganizationAttributes;
                  this.GetJSON2Result.OrgInfo = res.OrgInfo;
                }
              }
              this.ToggleTextFunc(this.ToggleTextChecked);
            }
          },error => {

              console.info(error);
              this.ngxService.stop();
            });
        }
      }
      else {
        if (this.ToggledJSON == 'JSON1' && this.ToggledJSONChecked == false) {
          this.GetJSON1Result.OrganizationAttributes = '';
          this.GetJSON1Result.OrgInfo = '';
        }
        else if (this.ToggledJSON == 'JSON2' && this.ToggledJSONChecked == true) {
          this.GetJSON2Result.OrganizationAttributes = '';
          this.GetJSON2Result.OrgInfo = '';
        }
        this.ToggleTextFunc(this.ToggleTextChecked);
      }
    }
    else {
      this.ToggledJSON = 'JSON2';
      this.ToggledJSONChecked = true;
      if (SelectedCaseType != "-1") {
        if (this.SelectedAccountId != "" && this.SelectedAcc_orgID != "") {
          // let AccDetails =
          // {
          //   OrgID: this.SelectedAcc_orgID,
          //   AccID: this.SelectedAccountId,
          //   CaseType: SelectedCaseType,
          //   TableName: null
          // };
          this.ngxService.start();
          this.vaservices.genereateJSON(AccDetails).subscribe(data => {
            this.ngxService.stop();
            if (!data.errors) {
              let res=data.content;
              if (res.OrgInfo != null && res.OrganizationAttributes != null) {
                this.GetJSON2Result.OrganizationAttributes = res.OrganizationAttributes;
                this.GetJSON2Result.OrgInfo = res.OrgInfo;
              }
              this.ToggleTextFunc(this.ToggleTextChecked);
            }
          },error => {

              console.info(error);
              this.ngxService.stop();
            });
        }
      }
      else {
        this.GetJSON2Result.OrganizationAttributes = '';
        this.GetJSON2Result.OrgInfo = '';
        this.ToggleTextFunc(this.ToggleTextChecked);
      }

    }
  }

  onChangeAccCaseTypeJSON1(SelectedCaseType) {
    this.ToggledJSON = 'JSON1';
    this.ToggledJSONChecked = false;
    const AccDetails = new OrgCompare(this.SelectedAcc_orgID, this.SelectedAccountId, SelectedCaseType, null);
    if (SelectedCaseType != "-1") {
      if (this.SelectedAccountId != "" && this.SelectedAcc_orgID != "") {
        // let AccDetails =
        // {
        //   OrgID: this.SelectedAcc_orgID,
        //   AccID: this.SelectedAccountId,
        //   CaseType: SelectedCaseType,
        //   TableName: null
        // };
        this.ngxService.start();
        this.vaservices.genereateJSON(AccDetails).subscribe(data => {
          this.ngxService.stop();
          if (!data.errors) {
           if(data.status.toLowerCase()=='success'){
            let res=data.content;
            if (res.OrgInfo != null && res.OrganizationAttributes != null) {
              this.GetJSON1Result.OrganizationAttributes = res.OrganizationAttributes;
              this.GetJSON1Result.OrgInfo = res.OrgInfo;
            }
            // this.ToggleTextFunc(this.ToggleTextChecked);
            if (this.GetJSON1Result.OrganizationAttributes == '' && this.GetJSON1Result.OrgInfo == '') {
              this.CloseOrgCompare();
              this._snackBar.open("No data", 'Failed')
            }
            else {
              this.ToggleTextJSON1Func(this.ToggleTextCheckedJSON1);
            }
           }
          }
        },error => {

            console.info(error);
            this.ngxService.stop();
            this.CloseOrgCompare();
            this._snackBar.open("No data", 'Failed')
          });
      }
    }
    else {
      this.GetJSON1Result.OrganizationAttributes = '';
      this.GetJSON1Result.OrgInfo = '';
      this.ToggleTextJSON1Func(this.ToggleTextCheckedJSON1);
    }
  }

  ToggleJSONFunc(event) {
    if (this.fromButton != 'actionButtoninLab') {
      this.ToggledJSONChecked = event.checked;
      if (event.checked) {
        this.ToggledJSON = 'JSON2';
      }
      else {
        this.ToggledJSON = 'JSON1';
      }
      this.ToggleTextFunc(this.ToggleTextChecked);
      this.onChangeDep('none');
    }
    // else {
    //   this.ToggledJSON = 'JSON2';
    //   this.ToggleTextFunc(this.ToggleTextChecked);
    //   this.onChangeDep('VPLABS');
    // }
  }

  ToggleTextJSON1Func(eventchecked) {
    if (this.fromButton == 'actionButtoninLab') {
      // this.ToggleTextChecked = eventchecked;
      this.ToggleTextCheckedJSON1 = eventchecked;
      if (eventchecked) {
        this.Jform.patchValue({
          frm_json1: this.GetJSON1Result.OrganizationAttributes
        });
      }
      else {
        this.Jform.patchValue({
          frm_json1: this.GetJSON1Result.OrgInfo
        });
      }
    }
  }

  ToggleTextFunc(eventchecked) {
    if (this.fromButton != 'actionButtoninLab') {
      this.ToggleTextChecked = eventchecked;
      if (eventchecked) {
        this.ToggledText = 'OrgAttributes';
        if (this.ToggledJSON == 'JSON1') {
          this.Jform.patchValue({
            frm_json1: this.GetJSON1Result.OrganizationAttributes
          });
        }
        else {
          this.ToggledText = 'OrgInfo';
          this.Jform.patchValue({
            frm_json2: this.GetJSON2Result.OrganizationAttributes
          });
        }
      }
      else {
        if (this.ToggledJSON == 'JSON1') {
          this.Jform.patchValue({
            frm_json1: this.GetJSON1Result.OrgInfo
          });
        }
        else {
          this.Jform.patchValue({
            frm_json2: this.GetJSON2Result.OrgInfo
          });
        }
      }
    }
    else {
      this.ToggleTextChecked = eventchecked;
      if (eventchecked) {
        this.ToggledText = 'OrgInfo';
        this.Jform.patchValue({
          frm_json2: this.GetJSON2Result.OrganizationAttributes
        });
      }
      else {
        this.Jform.patchValue({
          frm_json2: this.GetJSON2Result.OrgInfo
        });
      }
    }
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    if (this.Jform.value.secondFormGroup.frm_OrgCompareSelectAccGroup.frmDrpAccSearch == 'Location ID') {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }

  IsJsonString(str, JSON_no) {
    try {
      JSON.parse(str);
      if (JSON_no == 'JSON1') {
        this.isJSON1 = true;
      }
      else {
        this.isJSON2 = true;
      }
    }
    catch (e) {
      if (JSON_no == 'JSON1') {
        this.isJSON1 = false;
      }
      else {
        this.isJSON2 = false;
      }
    }
  }

  // @HostListener('unloaded')
  ngOnDestroy() {
  }
}

@Component({
  selector: 'openDialogIgnoreKeys',
  templateUrl: 'openDialogIgnoreKeys.html',
})
export class openDialogIgnoreKeys {
  // public ignoredList = "";
  public ignoredKeysArr: string[] = [];
  ngOnInit() {
  }

  constructor() {
    for (let i = 0; i < environment.OrgCompare_RemoveKeys.length; i++) {
      let KeyPath: string = environment.OrgCompare_RemoveKeys[i];
      let Key_arr: string[] = KeyPath.split('.');
      if (Key_arr.length > 1) {
        this.ignoredKeysArr.push(Key_arr[Key_arr.length - 2] + ' -> ' + Key_arr[Key_arr.length - 1])

        // if (i != 0 && this.ignoredList != "") {
        //   this.ignoredList += ', ';
        // }
        // if (i != environment.OrgCompare_RemoveKeys.length - 1) {
        //   this.ignoredList += Key_arr[Key_arr.length - 2] + ' -> ' + Key_arr[Key_arr.length - 1];
        // }
        // else {
        //   this.ignoredList += Key_arr[Key_arr.length - 2] + ' -> ' + Key_arr[Key_arr.length - 1]
        // }
      }
    }
  }
}
