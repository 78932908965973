import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatAutocomplete,  MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionView } from '@grapecity/wijmo';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
declare var $: any;

export class Validationclass {
  Loginname: string;
  Password: string;
}

@Component({
  selector: 'app-manage-report-recipient',
  templateUrl: './manage-report-recipient.component.html',
  styleUrls: ['./manage-report-recipient.component.scss'],
})

export class ManageReportRecipientComponent {
  @Input()
  templateDataInp: any;
  public caseId;
  public revisionType;
  public reason;
  public DeploymentKeys = [];
 // public DeploymentKeys = environment.DeploymentKeys;
  templateData: any;
  public ChangeStatus: any[];
  public ExecuteSqlTabArray: any[];

  recipientData = this._fb.group({
    recipientJobName: [''],
    editFormEmailTo: [''],
    editFormEmailCc: [''],
    editFormEmailBcc: [''],
    recipientEmailStatus: true,
    recipientEmailSubject: ['', Validators.required],
    recipientEmailBody: ['', Validators.required],
    recipientReportName: ['', Validators.required],
    recipientFileName: ['', Validators.required],
    recipientComments: ['', Validators.required],
    recipientEmailKey: [' ', Validators.required],
    recipientPassword: ['', Validators.required],
    recipentReportType: ['', Validators.required],
    recipientProtectFile: ['true', Validators.required],
    recipientEmailPassword: true
  })

  recipientAddNewJob = this._fb.group({
    formEmailTo: [''],
    formEmailCc: [''],
    formEmailBcc: [''],
    recipientEmailStatus: true,
    recipientEmailSubject: ['', Validators.required],
    recipientEmailBody: ['', Validators.required],
    recipientReportName: ['', Validators.required],
    recipientFileName: ['', Validators.required],
    recipientComments: ['', Validators.required],
    recipientEmailKey: ['  ', Validators.required],
    recipientPassword: '',
    recipentReportType: ['', Validators.required],
    recipientProtectFile: 'true',
    recipientEmailPassword: true
  })

  unitTrustsPnl: '';
  searchType = "";
  searchValue = "";
  showTab = false;
  tabs = ['Edit', 'Create New Job'];
  gridData: CollectionView;
  showGrid = false;
  showTableGrid = false;
  SelectedGridItems: any[] = [];
  selected = new FormControl(0);
  gridwidth
  // gridHeader = [];
  isProtect: boolean;
  isStatus: boolean;
  isPassword: boolean;
  isReport: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  submenuData: any;
  subMenuLabels: string[] = [];
  SelectedRowData: any;
  Job_ID: any;
  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();
  public queryvalue: any = null;
  alertOnEmailTo: boolean = false;
  errorFlag: number = 0;

  filteredToMail: Observable<string[]>;
  filteredCcMail: Observable<string[]>;
  filteredBccMail: Observable<string[]>;

  public editToMailData = [];
  public editCcMailData = [];
  public editBccMailData = [];

  public toMailData = [];
  public ccMailData = [];
  public bccMailData = [];

  allToMail: string[] = [];
  allCcMail: string[] = [];
  allBccMail: string[] = [];

  edittoMailCtrl = new FormControl();
  editccMailCtrl = new FormControl();
  editbccMailCtrl = new FormControl();

  toMailCtrl = new FormControl();
  ccMailCtrl = new FormControl();
  bccMailCtrl = new FormControl();

  @ViewChild('editToMailInput') editToMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editCcMailInput') editCcMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editBccMailInput') editBccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('toMailInput') toMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccMailInput') ccMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccMailInput') bccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('toAuto') matAutocomplete: MatAutocomplete;
  @ViewChild('ccAuto') matAutocomplete1: MatAutocomplete;
  @ViewChild('bccAuto') matAutocomplete2: MatAutocomplete;

  gridHeader = ["Report_Type", "Subject", 'Job_Name', 'Job_ID']
  columns = [
    //Outer grid
    new ColDef('Report_Type', ' Report Type'),
    new ColDef('Subject', 'Subject'),
    new ColDef('Job_Name', 'Job Name'),
    new ColDef('Job_ID', 'Job ID'),
  ];


  constructor(public formBuilder: FormBuilder, private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private httpClient: HttpClient, private activatedroute: ActivatedRoute, private router: Router,
    private GridwithCB: GridContainerWithCheckBoxComponent, public _snackBar: MatSnackBar, public dialog: MatDialog, private activeModal: NgbActiveModal,) {

    this.filteredToMail = this.edittoMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailTo: string | null) => mailTo ? this._filter(mailTo) : this.allToMail.slice())
    );

    this.filteredCcMail = this.editccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailCc: string | null) => mailCc ? this._filter1(mailCc) : this.allCcMail.slice())
    );

    this.filteredBccMail = this.editbccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailBcc: string | null) => mailBcc ? this._filter2(mailBcc) : this.allBccMail.slice())
    );

    this.filteredToMail = this.toMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailTo: string | null) => mailTo ? this._filter(mailTo) : this.allToMail.slice())
    );

    this.filteredCcMail = this.ccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailCc: string | null) => mailCc ? this._filter1(mailCc) : this.allCcMail.slice())
    );

    this.filteredBccMail = this.bccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailBcc: string | null) => mailBcc ? this._filter2(mailBcc) : this.allBccMail.slice())
    );
    this.DeploymentKeys = this.vaservices.DeploymentKeys;
  }

  // emailStatus: FormGroup = this.formBuilder.group({
  //   recipientEmailStatus: [true, Validators.requiredTrue],
  // });

  // emailPassword: FormGroup = this.formBuilder.group({
  //   recipientEmailPassword: [true, Validators.requiredTrue],
  // });


  //edit validation

  editToEmailInvalid(): boolean {
    return this.editToMailData.some(email => !this.validateEmail(email.value));
  }

  editCcEmailInvalid(): boolean {
    return this.editCcMailData.some(email => !this.validateEmail(email.value));
  }

  editBccEmailInvalid(): boolean {
    return this.editBccMailData.some(email => !this.validateEmail(email.value));
  }

  // create new validation

  areToEmailInvalid(): boolean {
    return this.toMailData.some(email => !this.validateEmail(email.value));
  }

  areCcEmailInvalid(): boolean {
    return this.ccMailData.some(email => !this.validateEmail(email.value));
  }

  areBccEmailInvalid(): boolean {
    return this.bccMailData.some(email => !this.validateEmail(email.value));
  }

  editEmailto(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editToMailData.push({ value: event.value, invalid: false });
      } else {
        this.editToMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.edittoMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.edittoMailCtrl.setValue(null);
  }

  editEmailcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editCcMailData.push({ value: event.value, invalid: false });
      } else {
        this.editCcMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.ccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.ccMailCtrl.setValue(null);
  }

  editEmailbcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editBccMailData.push({ value: event.value, invalid: false });
      } else {
        this.editBccMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.bccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.bccMailCtrl.setValue(null);
  }

  editRemoveEmailto(editToMail: any): void {
    if (this.editToMailData.indexOf(editToMail) >= 0) {
      this.editToMailData.splice(this.editToMailData.indexOf(editToMail), 1);
    }
  }

  editRemoveEmailcc(editCcMail: any): void {
    if (this.editCcMailData.indexOf(editCcMail) >= 0) {
      this.editCcMailData.splice(this.editCcMailData.indexOf(editCcMail), 1);
    }
  }

  editRemoveEmailbcc(editBccMail: any): void {
    if (this.editBccMailData.indexOf(editBccMail) >= 0) {
      this.editBccMailData.splice(this.editBccMailData.indexOf(editBccMail), 1);
    }
  }

  saveEmailto(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.toMailData.push({ value: event.value, invalid: false });
      } else {
        this.toMailData.push({ value: event.value, invalid: true });
        // this.recipientAddNewJob.controls['formEmailTo'].setErrors({ 'incorrectEmail': true });
        if (event) {
          event.value = '';
        }
        this.toMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.toMailCtrl.setValue(null);
  }

  saveEmailcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.ccMailData.push({ value: event.value, invalid: false });
      } else {
        this.ccMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.ccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.ccMailCtrl.setValue(null);
  }

  saveEmailbcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.bccMailData.push({ value: event.value, invalid: false });
      } else {
        this.bccMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.bccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.bccMailCtrl.setValue(null);
  }

  saveRemoveEmailto(toMail: any): void {
    if (this.toMailData.indexOf(toMail) >= 0) {
      this.toMailData.splice(this.toMailData.indexOf(toMail), 1);
    }
  }

  saveRemoveEmailcc(ccMail: any): void {
    if (this.ccMailData.indexOf(ccMail) >= 0) {
      this.ccMailData.splice(this.ccMailData.indexOf(ccMail), 1);
    }
  }

  saveRemoveEmailbcc(bccMail: any): void {
    if (this.bccMailData.indexOf(bccMail) >= 0) {
      this.bccMailData.splice(this.bccMailData.indexOf(bccMail), 1);
    }
  }

  selectedEditmailto(event: MatAutocompleteSelectedEvent): void {
    this.editToMailData.push(event.option.viewValue);
    this.editToMailInput.nativeElement.value = '';
    this.edittoMailCtrl.setValue(null);
  }

  selectedEditmailcc(event: MatAutocompleteSelectedEvent): void {
    this.editCcMailData.push(event.option.viewValue);
    this.editCcMailInput.nativeElement.value = '';
    this.editccMailCtrl.setValue(null);
  }

  selectedEditmailbcc(event: MatAutocompleteSelectedEvent): void {
    this.editBccMailData.push(event.option.viewValue);
    this.editBccMailInput.nativeElement.value = '';
    this.editbccMailCtrl.setValue(null);
  }

  selectedSavemailto(event: MatAutocompleteSelectedEvent): void {
    this.toMailData.push(event.option.viewValue);
    this.toMailInput.nativeElement.value = '';
    this.toMailCtrl.setValue(null);
  }

  selectedSavemailcc(event: MatAutocompleteSelectedEvent): void {
    this.ccMailData.push(event.option.viewValue);
    this.ccMailInput.nativeElement.value = '';
    this.ccMailCtrl.setValue(null);
  }

  selectedSavemailbcc(event: MatAutocompleteSelectedEvent): void {
    this.bccMailData.push(event.option.viewValue);
    this.bccMailInput.nativeElement.value = '';
    this.bccMailCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allToMail.filter(
      (mailTo) => mailTo.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter1(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCcMail.filter(
      (mailCc) => mailCc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBccMail.filter(
      (mailBcc) => mailBcc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  Templatedata = this._fb.group({
    CaseId: ['', Validators.required],
  })

  clickProtect(selectedChoice: boolean) {
    this.isProtect = selectedChoice;
  }

  clickStatus(selectedOptions: boolean) {
    this.isStatus = selectedOptions;
  }

  clickPassword(selectedOptions: boolean) {
    this.isPassword = selectedOptions;
  }

  clickReport(selectedOptions: boolean) {
    this.isReport = selectedOptions;
  }


  closeRecipient(): void {
    // $('#genericModal').modal('hide');
    this.activeModal.close();
    this.recipientData.reset();
    this.recipientAddNewJob.reset();
    this.recipientAddNewJob.patchValue(
      {
        recipentReportType: '',
        recipientProtectFile: 'true'
      }
    );

    this.recipientData.patchValue(
      {
        recipentReportType: '',
        recipientProtectFile: 'true'
      }
    );
    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.toMailData = [];
    this.ccMailData = [];
    this.bccMailData = [];
    this.allToMail = [];
    this.allCcMail = [];
    this.allBccMail = [];
    this.showGrid = false;
    this.showTableGrid = false;
  }

  resetRecipient(): void {
    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.toMailData = [];
    this.ccMailData = [];
    this.bccMailData = [];
    this.allToMail = [];
    this.allCcMail = [];
    this.allBccMail = [];
    this.recipientAddNewJob.reset();
    this.recipientAddNewJob.patchValue(
      {
        recipentReportType: ''
      }
    )
    this.recipientAddNewJob.patchValue(
      {
        recipientProtectFile: 'true'
      }
    )
  }

  cancelRecipient(): void {
    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.toMailData = [];
    this.ccMailData = [];
    this.bccMailData = [];
    this.allToMail = [];
    this.allCcMail = [];
    this.allBccMail = [];
    this.recipientAddNewJob.reset();
    this.showTableGrid = false;
    this.recipientAddNewJob.patchValue(
      {
        recipientProtectFile: 'true'
      }
    )
  }

  clearSearch() {
    this.queryvalue = "";
    this.showGrid = false;
    this.showTableGrid = false;
  }

  // update mail recipient -- Update existing data
  updateRecipient(UpdateMailRecipientDetails) {
    // let errorFlag = this.validatedetails(UpdateMailRecipientDetails);
    if (this.editToMailData.length > 0) {
      let tempToMail = [];
      this.editToMailData.forEach(element => {
        if (!element.invalid)
          tempToMail.push(element.value)
      });
      let tempCCMail = [];
      this.editCcMailData.forEach(element => {
        if (!element.invalid)
          tempCCMail.push(element.value)
      });
      let tempBCCMail = [];
      this.editBccMailData.forEach(element => {
        if (!element.invalid)
          tempBCCMail.push(element.value)
      });
      let updateSaveJson = {
        "Job_ID": this.Job_ID,
        "Job_Name": UpdateMailRecipientDetails.recipientReportName == null ? "" : UpdateMailRecipientDetails.recipientReportName,
        "Email_To": tempToMail,
        "Email_CC": tempCCMail,
        "Email_BCC": tempBCCMail,
        "File_Name": UpdateMailRecipientDetails.recipientFileName,
        "Subject": UpdateMailRecipientDetails.recipientEmailSubject,
        "Body": UpdateMailRecipientDetails.recipientEmailBody,
        "Report_Name": UpdateMailRecipientDetails.recipientReportName,
        "File_To_Be_Protected": UpdateMailRecipientDetails.recipientProtectFile == 'true' ? 1 : 0,
        "Report_Type": UpdateMailRecipientDetails.recipentReportType,
        "Password": UpdateMailRecipientDetails.recipientProtectFile == 'true' ? UpdateMailRecipientDetails.recipientPassword : "",
        "Password_To_Be_Mailed": UpdateMailRecipientDetails.recipientProtectFile == 'true' ? (UpdateMailRecipientDetails.recipientEmailPassword == true ? 1 : 0) : 0,
        "Modified_By": "1",
        "Modified_Date": new Date(),
        "Is_Active": UpdateMailRecipientDetails.recipientEmailStatus == true ? 1 : 0,
        "SMTP_Config_Key": UpdateMailRecipientDetails.recipientEmailKey,
        "Notes": UpdateMailRecipientDetails.recipientComments
      };
      this.ngxService.start();
      //sessionStorage.setItem( 'deploymentKey', "configdb");
      this.vaservices.updateMailRecipient(updateSaveJson, "configdb").subscribe(result => {
        this.ngxService.stop();
        //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
        
        if (result.Success) {
          this._snackBar.open(result.Message, 'Success');
          this.cancelRecipient();
          this.clearSearch();
        }else{
          this._snackBar.open("Mail details not updated", 'Failed');
          console.error(result.Message);
        }
      },error => {
          this.ngxService.stop();
          //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
          this._snackBar.open("An error occurred while processing your request", 'Failed');
          console.error(error);
        });
    }
    else {
      this._snackBar.open("Insert valid data", 'Failed');
    }
  }

  validatedetails(UpdateMailRecipientDetails: any) {
    // if (UpdateMailRecipientDetails.recipientReportName == null ||
    //   UpdateMailRecipientDetails.recipientReportName == ""
    //   || UpdateMailRecipientDetails.recipientReportName == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.editFormEmailTo.length > 0) {
    //   this.errorFlag = 1;
    //   return;
    // }
    // else if (UpdateMailRecipientDetails.editFormEmailCc.length > 0) {
    //   this.errorFlag = 1;
    //   return;
    // }
    // else if (UpdateMailRecipientDetails.editFormEmailBcc.length > 0) {
    //   this.errorFlag = 1;
    //   return;
    // }
    // else if (UpdateMailRecipientDetails.recipientFileName == null ||
    //   UpdateMailRecipientDetails.recipientFileName == ""
    //   || UpdateMailRecipientDetails.recipientFileName == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipientEmailSubject == null ||
    //   UpdateMailRecipientDetails.recipientEmailSubject == ""
    //   || UpdateMailRecipientDetails.recipientEmailSubject == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipientEmailBody == null ||
    //   UpdateMailRecipientDetails.recipientEmailBody == ""
    //   || UpdateMailRecipientDetails.recipientEmailBody == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipientProtectFile == null
    //   || UpdateMailRecipientDetails.recipientProtectFile == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipentReportType == null ||
    //   UpdateMailRecipientDetails.recipentReportType == ""
    //   || UpdateMailRecipientDetails.recipentReportType == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipentReportType == true) {
    //   if (UpdateMailRecipientDetails.recipientPassword == null ||
    //     UpdateMailRecipientDetails.recipientPassword == ""
    //     || UpdateMailRecipientDetails.recipientPassword == undefined) {
    //     return 1;
    //   }
    //   if (UpdateMailRecipientDetails.recipientEmailPassword == null
    //     || UpdateMailRecipientDetails.recipientEmailPassword == undefined) {
    //     return 1;
    //   }
    // }
    // else if (UpdateMailRecipientDetails.recipientEmailStatus == null
    //   || UpdateMailRecipientDetails.recipientEmailStatus == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipientEmailKey == null ||
    //   UpdateMailRecipientDetails.recipientEmailKey == ""
    //   || UpdateMailRecipientDetails.recipientEmailKey == undefined) {
    //   return 1;
    // }
    // else if (UpdateMailRecipientDetails.recipientComments == null ||
    //   UpdateMailRecipientDetails.recipientComments == ""
    //   || UpdateMailRecipientDetails.recipientComments == undefined) {
    //   return 1;
    // }
  }

  // save mail recipient -- Insert new data
  saveRecipient(MailRecipientDetails) {
    let tempToMail = [];
    this.toMailData.forEach(element => {
      if (!element.invalid)
        tempToMail.push(element.value)
    });
    let tempCCMail = [];
    this.ccMailData.forEach(element => {
      if (!element.invalid)
        tempCCMail.push(element.value)
    });
    let tempBCCMail = [];
    this.bccMailData.forEach(element => {
      if (!element.invalid)
        tempBCCMail.push(element.value)
    });
    let sendSaveJson = {
      "Job_Name": MailRecipientDetails.recipientReportName == null ? "" : MailRecipientDetails.recipientReportName,
      "File_Name": MailRecipientDetails.recipientFileName,
      "Subject": MailRecipientDetails.recipientEmailSubject,
      "Body": MailRecipientDetails.recipientEmailBody,
      "File_To_Be_Protected": MailRecipientDetails.recipientProtectFile == 'true' ? 1 : 0,
      "Report_Type": MailRecipientDetails.recipentReportType,
      "Report_Name": MailRecipientDetails.recipientReportName,
      // "Password": MailRecipientDetails.recipientEmailPassword == true ? MailRecipientDetails.recipientPassword : "",
      // "Password_To_Be_Mailed": MailRecipientDetails.recipientPassword == true ? 1 : 0,
      "Password": MailRecipientDetails.recipientProtectFile == 'true' ? MailRecipientDetails.recipientPassword : "",
      "Password_To_Be_Mailed": MailRecipientDetails.recipientProtectFile == 'true' ? (MailRecipientDetails.recipientEmailPassword == true ? 1 : 0) : 0,
      "Created_By": "1",
      "Created_Date": new Date(),
      "Modified_By": "1",
      "Modified_Date": new Date(),
      "Is_Active": MailRecipientDetails.recipientEmailStatus == true ? 1 : 0,
      "SMTP_Config_Key": MailRecipientDetails.recipientEmailKey,
      "Email_To": tempToMail,
      "Email_CC": tempCCMail,
      "Email_BCC": tempBCCMail,
      "Notes": MailRecipientDetails.recipientComments
    };
    this.ngxService.start();
    //sessionStorage.setItem( 'deploymentKey', "configdb");
    this.vaservices.saveMailRecipient(sendSaveJson, "configdb").subscribe(result => {
      //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
      this.ngxService.stop();
      if (result.Success) {
        this._snackBar.open(result.Message, 'Success');
        this.closeRecipient();
      }else{
        this._snackBar.open("An error occurred while processing your request", 'Failed');
        console.error(result.Message);
      }
    },error => {

        this.ngxService.stop();
        //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
        this._snackBar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
      });
  }

  // public GetQuery(keyword: string) {
  //   let query: string = "";
  //   let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
  //   if (mainQueryIndex > -1)
  //     query = this.vaservices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }

  //to add labels to grid
  SetSubMenuDataWithLabels(data: any, removelabel: string) {
    this.submenuData = data;
    if (data[0]) {
      let labels = Object.keys(data[0]);
      const index = labels.indexOf(removelabel, 0);
      if (index > -1) {
        labels.splice(index, 1);
      }
      this.subMenuLabels = labels;
      this.templateData.submenudata = this.submenuData;
      this.templateData.labels = this.subMenuLabels;
    }
    else {
      this.subMenuLabels = [];
      this.templateData.submenudata = null;
      this.templateData.labels = this.subMenuLabels;
    }
  }

  searchByJobName(jobname) {
    if (jobname !== null && jobname != "") {
      this.gridHeader = [];
      let queryResult: any;
      let queryVariable = { JobName: jobname };
      let query = this.vaservices.GetQuery('managerecipentssearchjobname');
      let queryString: string = "";
      //sessionStorage.setItem( 'deploymentKey', "configdb");
      queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vaservices.GetData(queryResult, "configdb").subscribe(res => {
        //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
        if (!res.errors) {
          this.templateData = {
            submenudata: res.data.submenudata,
            submenudata1: res.data.submenudata1
          }
          if (this.templateData.submenudata.length > 0) {
            let removelabel: string = "";
            this.SetSubMenuDataWithLabels(this.templateData.submenudata, removelabel);
            if (this.templateData.labels !== undefined) {
              let labels = JSON.parse(JSON.stringify(this.templateData.labels))
              for (let i = 0; i < labels.length; i++) {
                let column: any;
                this.gridHeader.unshift(labels[i]);
              }
              this.AddGridData()
            }
          }
          else {
            this.clearSearch();
            this._snackBar.open("Please enter valid job name", "Failed");
          }
        }
      },error => {

          //sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
          this._snackBar.open("An error occurred while processing your request", "Failed");
          console.error(error);
        });
    }
    else {
      this.clearSearch();
      this._snackBar.open("Please enter valid job name", "Failed");
    }
  }

  AddGridData() {
    // if (this.templateData.submenudata != null) {
    //   this.gridHeader = ["Report_Type", "Subject", 'Job_Name', 'Job_ID']
    // }
    let gridarray = []
    let primary = {}
    if (this.templateData.submenudata) {
      if (this.templateData.submenudata.length > 0) {
        for (let i = 0; i < this.templateData.submenudata.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenudata[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value
              }
              else if (key.toLowerCase() == 'job_id') {
                primary[key] = String(value.toString().replace(/,/g, ''));
              }
            }
          }
          gridarray.push(primary);
          this.showGrid = true;
        }
      }
      else {
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 0;
        this.showGrid = false;
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (250 * (this.gridHeader.length - 1)) + 30;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
    } else {
      this.gridwidth = 0;
      this.gridData = new CollectionView(gridarray, { pageSize: 10 });
      this.showGrid = false;
    }
  }

  tableGrid(gridData) {
    if (gridData.currentItem) {
      var item = gridData.currentItem;
      let val: any;
      val = this.templateData.submenudata.filter(va => va.Job_ID === item.Job_ID)
      if (val.length > 0) {
        this.SelectedRowData = val[0]
      }
    }
    this.Job_ID = item.Job_ID;
    if (this.SelectedRowData) {
      this.AssignMailData(this.SelectedRowData);
      this.recipientData.patchValue(
        {
          recipientComments: ""
        }
      );
    }
  }

  AssignMailData(Getdata) {
    this.showGrid = true;
    this.showTableGrid = true;
    let val2 = this.templateData.submenudata1.filter(va => va.Job_ID === this.Job_ID && va.Is_Active == 1);
    let emailTO: any[] = [];
    let emailCC: any[] = [];
    let emailBCC: any[] = [];
    if (val2.length > 0) {
      for (let i = 0; i < val2.length; i++) {
        if (val2[i].Email_Type == "EmailTo") {
          emailTO.push(val2[i].Email_Address)
        }
        else if (val2[i].Email_Type == "EmailCC") {
          emailCC.push(val2[i].Email_Address)
        }
        else if (val2[i].Email_Type == "EmailBCC") {
          emailBCC.push(val2[i].Email_Address)
        }
      }
    }
    this.recipientData.patchValue(
      {
        recipientReportName: Getdata.Job_Name,
        recipientEmailSubject: Getdata.Subject,
        recipientEmailBody: Getdata.Body,
        recipientPassword: Getdata.Password,
        recipientFileName: Getdata.File_Name,
        recipientEmailKey: Getdata.SMTP_Config_Key != null ? Getdata.SMTP_Config_Key : "",
        recipentReportType: Getdata.Report_Type.toLowerCase(),
        recipientProtectFile: Getdata.File_To_Be_Protected == 1 ? 'true' : 'false',
        recipientEmailPassword: Getdata.Password_To_Be_Mailed,
        recipientEmailStatus: Getdata.Is_Active
        //recipientComments: Getdata.Notes
      }
    );

    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    emailTO.forEach(element => {
      this.editToMailData.push({ value: element, invalid: false });
    });
    emailCC.forEach(element => {
      this.editCcMailData.push({ value: element, invalid: false });
    });
    emailBCC.forEach(element => {
      this.editBccMailData.push({ value: element, invalid: false });
    });
  
  }

  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnInit(): void {
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
