<table style="margin-left:100px" >
    <tr class="tablewhitepartition">
      <td colspan="5" class="tablewhitepartition">
          <br />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="tableId">
       <a class="tableId" (click) = "SetTabActive({'accountid':1557,'cardtype': 'facility','casetype':'pap'})">ID: 1557 </a> 
      </td>
    </tr>
    <tr >
      <td colspan="5">
          <br />
      </td>
    </tr>
    <tr class= "tableHeader">
      <td class= "tableHeader">
        Facility Location Name 
      </td>
      <td class= "tableHeader">
        Case Type 
      </td>
      <td class= "tableHeader">
        Collection Date 
      </td>
      <td class= "tableHeader">
        Sign Out Date 
      </td>
      <td class= "tableHeader">
        Order Number 
      </td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr class="tablewhitepartition">
      <td colspan="5" class="tablewhitepartition">
        <br />
      </td>
    </tr>
    <tr>
      <td colspan="2" >
       <a class="tableId" (click) = "SetTabActive({'accountid':42400,'cardtype': 'lab','casetype':'pap'})">ID: 42400 LIVE </a> 
      </td>
    </tr>

    <tr>
      <td colspan="2" >
       <a class="tableId" (click) = "SetTabActive({'accountid':57,'cardtype': 'lab','casetype':'pap'})">ID: 57</a> 
      </td>
    </tr>
    <tr >
      <td colspan="5">
        <br />
      </td>
    </tr>
    <tr>
      <td class= "tableHeader">
        Facility Location Name 
      </td>
      <td class= "tableHeader">
        Case Type 
      </td>
      <td class= "tableHeader">
        Collection Date 
      </td>
      <td class= "tableHeader">
        Sign Out Date 
      </td>
      <td class= "tableHeader">
        Order Number 
      </td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr class="tablewhitepartition">
      <td colspan="5" class="tablewhitepartition">
        <br />
      </td>
    </tr>
    <tr>
      <td colspan="2" >
       <a class="tableId" (click) = "SetTabActive({'accountid':5,'cardtype': 'lab','casetype':'pap'})">ID: 5  (LIVE)</a> 
      </td>
    </tr>
    <tr >
      <td colspan="5">
        <br />
      </td>
    </tr>
    <tr>
      <td class= "tableHeader">
        Facility Location Name 
      </td>
      <td class= "tableHeader">
        Case Type 
      </td>
      <td class= "tableHeader">
        Collection Date 
      </td>
      <td class= "tableHeader">
        Sign Out Date 
      </td>
      <td class= "tableHeader">
        Order Number 
      </td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr class="tablewhitepartition">
      <td colspan="5" class="tablewhitepartition">
        <br />
      </td>
    </tr>
    <tr>
      <td colspan="2" >
       <a class="tableId" (click) = "SetTabActive({'accountid':15,'cardtype': 'facility','casetype':'pap'})">ID: 15</a> 
      </td>
    </tr>
    <tr >
      <td colspan="5">
        <br />
      </td>
    </tr>
    <tr>
      <td class= "tableHeader">
        Facility Location Name 
      </td>
      <td class= "tableHeader">
        Case Type 
      </td>
      <td class= "tableHeader">
        Collection Date 
      </td>
      <td class= "tableHeader">
        Sign Out Date 
      </td>
      <td class= "tableHeader">
        Order Number 
      </td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        Full circle jax of florida women care 
      </td>
      <td>
        pap( pap + ct/ng)
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td>
        Sep 19, 2020
      </td>
      <td></td>
    </tr>
  </table>
  