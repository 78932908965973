import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-location-info-details',
  templateUrl: './location-info-details.component.html',
  styleUrls: ['./location-info-details.component.scss']
})
export class LocationInfoDetailsComponent {

  @Input() locationDetails:any;
  @Input() userRoles=[]

  checkUserDetails(){
    if(this.locationDetails && this.locationDetails != null && this.locationDetails != ''){
    return Object.keys(this.locationDetails)?.length;
    }
  }
  ngOnChanges(){

  }
}
