
import { Component, Input, OnInit } from "@angular/core";
import { CollectionView } from '@grapecity/wijmo';
import { Editor, Toolbar } from "ngx-editor";
//import 'src/assets/css/styles.css';
import { InjectorService } from "../../../core/services/Injectorservices";

@Component({
  selector: 'app-resultsection',
  templateUrl: './resultsection.component.html',
  styleUrls: ['./resultsection.component.scss']
})
export class ResultsectionComponent implements OnInit {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  typeList = []
  editordoc: string;
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"]
  ];
  SelectedType: any;
  constructor(private injectorServiceGridContainer: InjectorService) {
    // super(injectorServiceGridContainer);
  }
  // form = new FormGroup({
  //   editorContent: new FormControl(this.editordoc, Validators.required())
  // });

  // get doc(): AbstractControl {
  //   return this.form.get("editorContent");
  // }

  ngOnInit(): void {
    this.editor = new Editor();
    this.typeList = this.templateData.submenuData
    if (this.typeList.length === 1) {
      this.selectType(this.typeList[0].Type)
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
  selectType(value) {
    if (value) {
      let htmlData = this.typeList.filter(val => val.Type === value);
      if (htmlData.length > 0) {
        this.SelectedType = htmlData[0].Type
        this.editordoc = htmlData[0].HtmlContent
      }
    }
  }
}
