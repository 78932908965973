<div class="select-container">
    <input #input type="text" #trigger="matAutocompleteTrigger" [placeholder]="placeholder" [formControl]="control"
        [matAutocomplete]="auto" class="select-input" [class.cursor-not-allowed]="isDisabled" (focus)="onInputFocus()" (click)="onInputFocus()"/>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption  (opened)="dropdown.classList.add('showRotate')"
        (closed)="dropdown.classList.remove('showRotate')" (optionSelected)="emitValue($event)">
        <mat-option *ngFor="let dropDown of dropDownValue$ | async" [value]="dropDown">
            {{ dropDown[key]}}
        </mat-option>
        <mat-option *ngIf="dropDownValue && dropDownValue.length === 0" disabled>{{
            NodataFound
            }}</mat-option>
    </mat-autocomplete>
    <div id="dropdown" (click)="!isDisabled && openOrCloseDD($event, trigger, input)">
        <img #dropdown [class.cursor-not-allowed]="isDisabled" class="cusor-pointer" src="../../../assets/images/expand.png" alt="expand">
    </div>
</div>
