import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-detailstabs',
  templateUrl: './detailstabs.component.html',
  styleUrls: ['./detailstabs.component.scss'],
})
export class DetailstabsComponent {
  @Output() selectedTab = new EventEmitter();
  @Input()tabData=[]
  tabList = [
    'Details',
    'NPI',
    'Associations',
    'Preference',
    'Templates',
    'Patient Portal',
    'Analyst',
  ];
  ngOnInit(){
    let obd={
      index:0
    }
    this.onTabChanged(obd)
  }
  onTabChanged(event) {
    if(this.tabData.length>0){
      this.selectedTab.emit(this.tabData[event.index].Htext);
    }

  }
}
