import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutoCompleteSelectComponent } from './components/auto-complete-select/auto-complete-select.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { AddCheckOrCrossDirective } from './directives/add-check-or-cross.directive';
import { ButtonComponent } from './components/button/button.component';
import { SwitchComponent } from './components/switch/switch.component';
import { InputComponent } from './components/input/input.component';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { MarkAsCheckedDirective } from './directives/mark-as-checked.directive';
import { MultiSelectCheckboxComponent } from './components/multi-select-checkbox/multi-select-checkbox.component'
import { OverlayModule } from "@angular/cdk/overlay";
import {  MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { InputAutoCompleteComponent } from './components/input-auto-complete/input-auto-complete.component';
import { MatRippleModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AsyncloaderPipe } from './pipes/asyncloader.pipe';




@NgModule({
    imports : [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        OverlayModule ,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRippleModule,
        MatTooltipModule
      ],
    declarations: [
      AutoCompleteSelectComponent,
      SearchBoxComponent,
      AddCheckOrCrossDirective,
      ButtonComponent,
      SwitchComponent,
      InputComponent,
      OnlyNumberDirective,
      MarkAsCheckedDirective,
      MultiSelectCheckboxComponent,
      InputAutoCompleteComponent,
      AsyncloaderPipe
    ],
    exports : [
      AutoCompleteSelectComponent,
      SearchBoxComponent,
      AddCheckOrCrossDirective,
      ButtonComponent,
      SwitchComponent,
      NgxMaskDirective,
      NgxMaskPipe,
      InputComponent,
      OnlyNumberDirective,
      MarkAsCheckedDirective,
      MultiSelectCheckboxComponent,
      InputAutoCompleteComponent, AsyncloaderPipe
    ],
    providers : [
      provideNgxMask()
    ]
})
export class sharedModule{

}
