import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'ReplaceFilterText',
  pure: false
})


export class ReplaceFilterTextPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof (value) == null) {
      return value;
    }

    let facetList = { 'deploymentkey': 'Deployment Key', 'casetype': 'Case Type', 'patientname': 'Patient Name', 'rolename': 'Role Name', 'billingprovidername': 'Billing Provider Name', 'claimstatus': 'Claim Status', 'plantype': 'Plan Type' };
    let convertValue = facetList[value.toLowerCase()];
    if (convertValue && convertValue.length > 0)
      return convertValue;

    return value;
  }//end of check if array has a value      
}//end of transform  