  <div class="row  mt-2">
    <div class="col-sm-12 upload-wrap">
      <div class="col-sm-6">
        <div class="row">
          <label class="upload-logo" style="margin-left: 12px;">Existing logo</label>
        </div>
        <div class="row image-heights" *ngIf="noLogo">
          <div class="nodata-design">No Logo Found!</div>
        </div>
        <div class="image-heights" style="width: 100%;" *ngIf="!noLogo">
          <img style="max-width: 100%;max-height: 100%;" alt="nologo" [src]="imageSrc">
        </div>
        <button mat-raised-button class="admin-btn-success float-right" title="logo remove" *ngIf="!noLogo"
          (click)="removeExistingLogo()" [disabled]="hideRemoveBtn ||logoPath">
          Remove
        </button>
      </div>
      <div class="col-sm-6 new-logo-wrap">
        <div class="row">
          <label class="upload-logo">Upload new logo</label>
        </div>
        <span class="row">
          <div class="custom-dropzone image-heights" appDnd (fileDropped)="onFileDropped($event)" *ngIf="logoPath">
            <input type="file" #fileDropRef id="fileDropRef" (change)="onFileChange($event)" [multiple]="false"
              hidden />
            <span class="mb-2">Drag and drop file here</span>&nbsp;<span class="mb-2">or</span>&nbsp;
            <label class="btn-sm btn rounded browse"  for="fileDropRef">Browse File</label>
          </div>
          <div class="image-heights" *ngIf="!logoPath" style="width: 90%;">
            <img style="max-width: 100%;max-height: 100%;" alt="newlogo" [src]="newLogoSrc" alt="" srcset="">
          </div>
        </span>

        <div class="row d-flex float-right mt-4">
          <button *ngIf="!logoPath" mat-raised-button class="admin-btn-success mr-4" title="logo cancel"
            [disabled]="logoPath" (click)="removeNewLogo()">
            Cancel
          </button>
          <button *ngIf="!logoPath" mat-raised-button class="admin-btn-success" title="logo update"
            (click)="ConfirmUploadLogo()" [disabled]="hideUploadBtn || logoPath">Update
          </button>
        </div>
      </div>
    </div>
  </div>
