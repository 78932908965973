import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
//import { CardQueries } from 'src/app/model/card-query';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
// import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
//import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalAdminTabService } from '../../tab.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { take } from 'rxjs';

declare var $: any;
export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'manage-gross-templates',
  templateUrl: './manage-gross-templates.component.html',
  styleUrls: ['./manage-gross-templates.component.scss']
})

export class ManageGrossTemplatesComponent {
  task: Task = {
    color: 'primary',
  };

  [x: string]: any;


  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridWidth: number = 0;
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  @Input()
  templateData: any;
  showPaginationMainGrid: boolean;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = false;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  public hideExportBtn: boolean = false;
  public actionButtonDetails: any[];
  gridHeader = [];
  ListedGridHeader: any = [];
  gridHeaderList: any = [];
  UserList = [];
  deleteNotice: boolean = false;
  editableData: any;
  SubMenuCardModel: SubMenuCardModel;
  bodySites: {}[];
  Result: any;
  editAction: boolean;
  Action: string;
  // Placeholders: any = [];
  showPagination: boolean;
  private dragIndex: number;
  hideCreateBtn: boolean;
  hideUpdateBtn: boolean;
  hideDeleteBtn: boolean;
  hideDragnDropBtn: boolean;
  hideCopyToOrgBtn: boolean;
  hideuploadbtn: boolean;
  gridShowDiv: boolean;
  //upload and copy
  shownodata: boolean = false;
  uploadClicked: boolean = false;
  copyDataClicked: boolean = false;
  AllFields = [{}];
  MandatoryFields = [{}];
  workBook: any;
  sheetsToSelect: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  excelDataArray = [];
  sheetHeader: string[];
  private dbname;
  searchuserlist: any[];
  searchInput: String = '';
  searchUserInput: String = '';
  srcOrgid: any;
  srcUserid: string;
  srcOrgName: any;
  srcUserName: any;
  userList: any;
  userListArray: any;
  usertoggle: Boolean = false;
  showDelete: boolean = true;
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  backBtn: boolean = true;
  selectedItems: any = [];
  selector: Selector;
  checkStatus = false;
  isdefaultconfirmaddedit: boolean = false;
  isdefaultconfirmupload: boolean = false;
  ViewScreen: boolean = false;
  auditableColumns: any;
  @ViewChild('textareaElement')
  textareaElement!: ElementRef;
  // viewPage: boolean = false;
  isListing: boolean = true;
  selectedtemplate: any;
  title: string = ''
  receivedObject: any;
  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  copycollectionMethodForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmSite: [""],
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
  })
  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService,
    private TemplateService: TemplateViewService, private templateHeaders: TemplateHeadersService, public activityService: ActivityTrackerService,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,) {
    // super(injectorServiceGridContainer);
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.dbname = sessionStorage.getItem('DeploymentKey').toUpperCase();
  }
  get formControl() {
    return this.copycollectionMethodForm.controls;
  }
  valueClone: any

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
          _this.templateData = _this.labadminService.templateData;
          _this.dbName = _this.labadminService.deploymentKey;
          sessionStorage.setItem('org_id', _this.labadminService.organizationId);
          sessionStorage.setItem('deploymentKey', this.dbName);
          _this.AddGridData();
          this.getAuditableDetails(this.templateData.menuURL)
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          _this.GetButtonAccess(_this.labadminService.subMenuPermissions)

          // _this.getAuditableDetails(_this.labadminService.templateData.menuURL)

          // this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
        }
      })

    }
    else {
      this.gridHeader = []
      this.orgId = this.templateData.secondarykeys.OrganizationId;
      let labels = JSON.parse(JSON.stringify(this.templateData.labels))
      for (let i = 0; i < labels.length; i++) {
        let column: any;
        // label = this.templateData.labels[i];
        this.gridHeader.unshift(labels[i]);
      }
      this.loadUploadScreen();
      this.GetButtondetails();
      this.AddGridData();
      this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    }
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Grossing template') {
        this.ListedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.ListedGridHeader))
      }
    }
  }

  auditDetails(oldObj: any, newObjArray, info) {
    let msgActivityTracker = ''
    msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';
    oldObj = oldObj ? oldObj : {};
    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj.OrgGrossTempID, "Gross Templates", 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    }
    else {
      /*New records*/
      this.ngxService.start();
      for (let i = 0; i < newObjArray.length; i++) {
        this.commonService.createActivityTracker(msgActivityTracker, newObjArray[i].ID, 'Gross Templates', 'Audit', newObjArray[i], {});
      }
    }
    this.ngxService.stop();
  }
  ngOnDestroy() {
    //code to remove the modals in the component appended to the body
    $('#selectSheetModal').remove();
  }
  // popup for copy org
  copyOrg() {
    this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
    this.destDeployment = this.VitalHttpServices.deploymentKey.toUpperCase();
    this.srcDeployment = this.destDeployment
    this.copyDataClicked = true;
    this.gridShowDiv = false;
    this.hideGrid = false;
    this.shownodata = true;
    this.postUpload = false;
    this.backBtn = true;
    this.srcOrgid = '';
    // this.copycollectionMethodForm.reset()
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmSite: "",
      frmDepKey: this.destDeployment,
      frmOrgType: "Laboratory"
    });
    this.getListOrg();

  }
  //popup for BulkUpload
  BulkUpload() {
    this.uploadClicked = true;
    this.gridShowDiv = false;
    this.hideGrid = false;
    this.shownodata = true;
  }
  //back copy
  BackUploadandcopy() {
    this.uploadClicked = false;
    this.copyDataClicked = false;
    this.excelDataArray = [];
    // this.gridShowDiv = true;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.hideGrid = true;
    this.getUpdatedGrid();
    this.resetCopyForm();
  }
  //#region
  //Initalizing the form for Add and Update operations on Gross tmeplates.
  GrossTemplateForm = this._fb.group({
    frmTemplateID: null,
    frmTemplateName: ['', Validators.required],
    frmActive: 1,
    frmDescription: ['', Validators.required],
    frmSite: 'All',
    frmDefault: 0,
    frmPlaceholder: ''
  })
  //#endregion

  ngOnChanges() {

  }

  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    //   this.datashare.SubmenuAction.subscribe(data => {
    //     if (data) {
    //       // this.actionButtonDetails = data;
    //       this.GetButtonAccess(data);
    //     }
    //   },
    //     error => console.error(error));
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    } for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "DragnDrop":
          this.hideDragnDropBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "CopyToOrg":
          this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Upload":
          this.hideuploadbtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }



  //#region
  //Function that refreshes the gross template list grid after CRUD operations to show updated data.
  getUpdatedGrid(caseType: string = 'All CaseTypes') {
    let queryVariable = { casetype: this.sourceApp=='VitalDx'? caseType : this.templateData.secondarykeys.casetype.toString()  , orgid: this.templateData.secondarykeys.OrganizationId.toString() };
    let query = this.SubMenuCardModel.GetQuery("casetypegrosstemplate");
    if(queryVariable) {
      queryVariable.casetype.trim().toLowerCase() === 'all casetypes' ? delete queryVariable.casetype : queryVariable.casetype;
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.activityEntity.entityId = '';
        this.activityService.setActivitySession(this.activityEntity);

        this.templateData.submenuData = data.data.submenuData;
        this.AddGridData();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion

  //#region
  //Tooltip function to show otherwise hidden data.
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#endregion

  //#region
  //Function to open up Add/Edit screen and fetch preliminary data for the respective actions.
  // editAddScreen(action, grid, event?) {
  //   this.hideGrid = true;
  //   this.gridShowDiv = true;
  //   this.editableData = [];
  //   this.copyDataClicked = false;
  //   this.uploadClicked = false;
  //   this.shownodata = true;
  //   if (action == 'edit') {
  //     // var hti = grid.hitTest(event);
  //     this.Action = 'updation';
  //     // let rowData = hti.panel.rows[hti.row].dataItem;
  //     let rowData = grid;
  //     this.oldObject = rowData;
  //     this.editAction = true;
  //     this.editableData = rowData;
  //   }
  //   else {
  //     this.editableData.SiteName = 'All';
  //     this.editableData.Active = true;
  //     this.Action = 'creation';
  //     this.editAction = false;
  //   }
  //   let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.templateData.secondarykeys.OrganizationId.toString() };
  //   let query = this.SubMenuCardModel.GetQuery('bodysitesOnCasetype');
  //   let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //   let depKey = this.VitalHttpServices.deploymentKey;
  //   this.ngxService.start();
  //   this.VitalHttpServices.GetData(queryResult).subscribe(data => {
  //     this.ngxService.stop();
  //     if (!data.errors) {
  //       let siteData = data.data.submenuData
  //       this.bodySites = [...new Map(siteData.map((item) => [item['BodySite'], item['BodySite']])).values(),]
  //       this.GrossTemplateForm.patchValue({
  //         frmTemplateID: this.editableData.OrgGrossTempID,
  //         frmTemplateName: this.editableData.Template == null ? '' : this.editableData.Template,
  //         frmActive: this.editableData.Active == true ? 1 : 0,
  //         frmDescription: this.editableData.Description == null ? '' : this.editableData.Description,
  //         frmSite: this.editableData.SiteName,
  //         frmDefault: this.editableData.Is_Default == true ? 1 : 0
  //       });
  //     }
  //   }, error => {
  //     this.ngxService.stop();
  //     console.error(error);
  //   });
  // }
  //#endregion

  // getDropdownValues() {
  //   let context = this.VitalHttpServices.deploymentKey.toString().toLowerCase() == 'thx' ? "GrossTemplateDataMarkers - THX" : "GrossTemplateDataMarkers"
  //   let placeholdersQueryVariable = { context: context, keyword: 'CaseType - ' + this.templateData.secondarykeys.casetype.toString() };
  //   let placeholdersQuery = this.SubMenuCardModel.GetQuery('CategoryDropDown');
  //   let placeholdersQueryResult = this.commonService.GetCardRequest(placeholdersQueryVariable, placeholdersQuery);
  //   this.ngxService.start();
  //   this.VitalHttpServices.GetData(placeholdersQueryResult, 'configdb').subscribe(data => {
  //     this.ngxService.stop();
  //     if (!data.errors) {
  //       if (data.data.submenuData && data.data.submenuData.length > 0) {
  //         // sessionStorage.setItem('deploymentKey', depKey);
  //         this.Placeholders = JSON.parse(data.data.submenuData[0].Items_JSON).DataMarkers
  //         let arr = JSON.parse(data.data.submenuData[0].Items_JSON).DataMarkers;
  //         const uniqueTrimmedServices = new Set();
  //         arr.forEach(obj => {
  //           const trimmedService = obj.Name.trim();
  //           uniqueTrimmedServices.add(trimmedService);
  //         });
  //         this.Placeholders = [...uniqueTrimmedServices];
  //       }
  //     }
  //   }, error => {
  //     this.ngxService.stop();
  //     console.error(error)
  //   });

  // }

  backToGrid() {
    this.uploadClicked = false;
    this.copyDataClicked = false;
    // this.gridShowDiv = true;
    this.hideGrid = true;
    this.getUpdatedGrid();
    this.gridShowDiv = (this.gridwidth > 0) ? true : false;
    this.GrossTemplateForm.patchValue({
      frmTemplateName: '',
      frmActive: 1,
      frmDescription: '',
      frmSite: 'All',
      frmDefault: 0,
      frmPlaceholder: ''
    });
  }
  //#region
  //Function to append the selected placeholder to the description in the form.
  // appendToTextarea(event: Event) {
  //   let selectedValue = (event.target as HTMLSelectElement).value;
  //   selectedValue = " {{" + selectedValue + "}} ";
  //   const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
  //   const startPos = textarea.selectionStart;
  //   const endPos = textarea.selectionEnd;
  //   let currentValue = textarea.value;
  //   // Set the cursor position after the inserted value
  //   if (this.GrossTemplateForm.value.frmDescription.length <= 1000) {
  //     const description = currentValue.substring(0, startPos) + selectedValue + currentValue.substring(endPos);
  //     textarea.value = description;
  //     this.GrossTemplateForm.patchValue({
  //       frmDescription: description,
  //       frmPlaceholder: ''
  //     })
  //     textarea.selectionStart = startPos + selectedValue.length;
  //     textarea.selectionEnd = startPos + selectedValue.length;
  //   }
  //   else {
  //     this._snackbar.open('Character limit reached for description!', 'Close')
  //     this.GrossTemplateForm.patchValue({
  //       frmPlaceholder: ''
  //     })
  //   }
  // }
  //#endregion

  //#region
  //Handling edit or delete operation from wijmo grid.
  // operationTrigger(action, grid, event) {
  // if(wjcCore.hasClass(event.target, 'delete-template')) {
  //   this.deleteTemplate(grid,event)
  // }
  // else if(wjcCore.hasClass(event.target, 'edit-template')) {
  //   this.editTemplate(grid,event)
  // }
  //Use the above while adding more CRUD options in the grid
  //   action.toString().toLowerCase() == 'delete' ? this.deleteTemplate(grid, event) : this.editAddScreen(action, grid, event);
  // }
  //#endregion

  //#region
  //Function that sets the object to be sent to the API for add and edit operations.
  // addEditTemplate() {
  //   let obj = {
  //     Action: this.Action,
  //     OrganizationID: this.templateData.secondarykeys.OrganizationId,
  //     CaseType: this.templateData.secondarykeys.casetype,
  //     TemplateName: this.GrossTemplateForm.value.frmTemplateName,
  //     IsActive: this.GrossTemplateForm.value.frmActive == 1 ? true : false,
  //     IsDefault: this.GrossTemplateForm.value.frmDefault == 1 ? true : false,
  //     Description: this.GrossTemplateForm.value.frmDescription,
  //     ModifiedBy: (sessionStorage.getItem("Userid") == undefined || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid"),
  //     CreatedBy: (sessionStorage.getItem("Userid") == undefined || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid"),
  //     OrgGrossTempID: this.GrossTemplateForm.value.frmTemplateID,
  //     SiteName: this.GrossTemplateForm.value.frmSite,
  //   }
  //   // this.grossTemplateAction(obj);
  // }
  //#endregion

  //#region
  //Function to create wijmo grid with template data.
  AddGridData() {
    let gridarray = []
    let primary = {}
    this.gridHeader = ['CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate', 'IsActive', 'IsDefault', 'SiteName', 'Description', 'TemplateName', 'Sequence', 'OrgGrossTempID', 'CaseType', 'OrganizationID'];
    this.ngxService.start();
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        this.shownodata = true;
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                if (key == 'SiteName' && value == null) {
                  value = 'All';
                }
                if (value == null) {
                  value = 'Not Specified';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
          this.isdefaultconfirmaddedit = false;
        }
        // .sort((a, b) => a.Sequence < b.Sequence ? 1 : -1)
        gridarray.sort((a, b) => a.SiteName.localeCompare(b.SiteName))
      }
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
        this.hideGrid = false;
        this.showPagination = true;
      }
      else {
        // this.gridShowDiv = false;
        // this.hideGrid = false;
        // this.shownodata = false;
      }
      this.gridData = new CollectionView(gridarray)
      // this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * this.gridHeader.length) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
    }
    else {
      this.gridShowDiv = (this.templateData && this.templateData.labels && this.gridwidth > 0) ? true : false;
      this.gridwidth = 0;
      this.gridShowDiv = true;
    }
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    this.ngxService.stop();
    // this.gridData = new CollectionView(this.templateData.submenuData, { pageSize: 10 })
    // this.gridwidth = (170 * this.templateData.labels.length) + 37;
    // if (this.gridwidth > 1300)
    //   this.gridwidth = 1300;
  }
  //#endregion

  //#region

  //  isdefault check for create/edit
  // isdefaultadd() {
  //   if (this.templateData.submenuData) {
  //     if (this.templateData.submenuData.length > 0) {
  //       if (this.GrossTemplateForm.value.frmDefault == 1
  //         // && (this.templateData.submenuData.some(va => va.Is_Default == 1 || va.Is_Default == true))
  //         && (this.templateData.submenuData.some(va => va.Template
  //           && va.Template.toString().toLowerCase().trim() != this.GrossTemplateForm.value.frmTemplateName.toString().toLowerCase().trim()
  //           && (va.Is_Default == 1 || va.Is_Default == true)))) {
  //         this.isdefaultconfirmaddedit = true;
  //         return
  //       }
  //       else {
  //         this.isdefaultconfirmaddedit = false;
  //       }
  //     }
  //   }
  //   this.addEditTemplate()
  // }
  //#endregion

  //#region
  //Function that creates an excel from the data in the grid.
  ExportExcel(flex) {
    // let excel = [];
    let rowData = flex._view
    // const view = flex.collectionView;
    // let oldPgSize = view.pageSize;
    // flex.beginUpdate();
    // view.pageSize = 0;
    // rowData.find(e => {
    //   delete e._data["tablename"];
    //   delete e._data["slno"];
    //   let primary = {}
    //   for (let [key, value] of Object.entries(e._data)) {
    //     if (key == 'Template_ID') {
    //       primary['orgGrossTempID'] = value
    //     }
    //     else {
    //       primary[key] = value;
    //     }
    //   }
    //   excel.push(primary);
    // });
    let filename = 'Grossing Templates_' + this.orgId.toString() + '.xlsx';
    // var ws = XLSX.utils.json_to_sheet(
    //   excel.reverse()
    // );
    var ws = XLSX.utils.json_to_sheet(rowData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Gross Templates');
    XLSX.writeFile(wb, filename);
    // view.pageSize = oldPgSize;
    // flex.endUpdate();
  }
  //#endregion

  //#region Download All Fields
  downloadAllFields() {
    let filename = 'Grossing Templates_' + 'All Fields_' + this.orgId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.AllFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Gross Templates');
    ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //#region Download Madatory Fields
  downloadMadatoryFields() {
    let filename = 'Grossing Templates_' + 'Minimal Fields_' + this.orgId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Gross Templates');
    ws = XLSX.utils.json_to_sheet(this.sampleDataMandatoryFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  //#endregion
  //sample data for excel
  sampleDataMandatoryFields = [{
    TemplateName: 'Template Name or Name'
    , Description: 'Description'
    , isDefault: 'TRUE or FALSE / 0 or 1'
    , IsActive: 'TRUE or FALSE / 0 or 1'
    , displayorder: 'sequence number'
  }]

  sampleDataAllFields = [{
    templateName: 'Template Name or Name',
    description: 'Description',
    isDefault: 'TRUE or FALSE / 0 or 1',
    IsActive: 'TRUE or FALSE / 0 or 1',
    displayorder: 'sequence number',
    SiteId: 'Number'

  }]
  //#region Upload screen
  loadUploadScreen() {
    this.invalidCasetypeFlag = false;
    this.tableGrid = false;
    let queryVariable = { tablename: 'OrganizationGrossTemplates', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.dbname).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          for (let i = 0; i < data.data.allFields.length; i++) {
            allFields[data.data.allFields[i]["Column"]] = ''
          }
          this.AllFields[0] = allFields
          delete this.AllFields[0]['orgGrossTempID']
          delete this.AllFields[0]['organizationID']
          delete this.AllFields[0]['caseType']
          delete this.AllFields[0]['OrganizationGrossTempGUID']
          delete this.AllFields[0]['CreatedBy']
          delete this.AllFields[0]['CreatedDate']
          delete this.AllFields[0]['ModifiedBy']
          delete this.AllFields[0]['OrgGrossTemplateGUID']
          delete this.AllFields[0]['ModifiedDate']
          this.MandatoryFields[0]['TemplateName'] = ''
          this.MandatoryFields[0]['Description'] = ''
          this.MandatoryFields[0]['isDefault'] = ''
          this.MandatoryFields[0]['IsActive'] = ''
          this.MandatoryFields[0]['displayorder'] = ''
        }
      }
    },
      error => {
        console.error(error);
      });
  }
  //#endregion
  onFileDropped($event) {
    this.onFileChange($event);
  }
  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'gross templates')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    // tempExcelArr.find(r => {
    //   for (let [key, value] of Object.entries(r)) {
    //     if (!key.toString().match(/empty/i)) {
    //       primary[key] = value;
    //     }
    //   }
    //   tempArray.push(primary)
    //   primary = {}
    // });
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().toLowerCase().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()
    temp[0].push("Notes")
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDataArray.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0)
    this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: Number(i), notes: '', tablename: 'OrganizationGrossTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
        casetype: this.templateData.cardtype.toString()
      });
      i++;
    });
    // this.sheetHeader = ["isactive", "referencetemplateid", "isuserlevelcasecommentstemplateenabled", "isuserleveltemplates", "userid","sequence", "type", "isdefault","description","templatename","casetype"];
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion
  //#region Close Modal
  closeModal() {
    $('#selectSheetModal').modal('hide');
  }
  //#endregion
  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion
  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion
  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.isdefaultconfirmupload = false;
    this.ngxService.start();
    this.dbname;
    for (let i = 0; i < excelArr.length; i++) {
      if (!("siteid" in excelArr[i])) {
        excelArr[i]["siteid"] = null;
      }
    }
    this.VitalHttpServices.ValidateBulkUploadGrossTemplate(excelArr, this.dbname).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          excelArr.filter(e => {
            result.find(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.excelGridData(excelArr);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //#endregion
  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    if (!this.copyDataClicked) { this.closeModal(); }
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArrays = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          let checkDataType = false
          let dataTypeCol;
          let flag = false;
          if (data[i]["isdefault"] == '' || data[i]["isdefault"] == null) {
            data[i]["isdefault"] = 0
          }
          else if (data[i]["isdefault"] != '0' &&
            data[i]["isdefault"] != '1' &&
            data[i]["isdefault"] != 1 &&
            data[i]["isdefault"] != 0) {
            data[i]["notes"] = "Invalid IsDefault value";
            primary['notes'] = data[i]["notes"];
          }
          else {
            primary['notes'] = data[i]["notes"] //!primary['notes'] ? '' : primary['notes'];
          }
          if ((data[i]["isactive"] == '' || data[i]["isactive"] == null) && data[i]["isactive"] != false) {
            data[i]["isactive"] = 1
          }
          else if (data[i]["isactive"] != '0' &&
            data[i]["isactive"] != '1' &&
            data[i]["isactive"] != 1 &&
            data[i]["isactive"] != 0) {
            data[i]["notes"] = "Invalid isactive value";
            primary['notes'] = data[i]["notes"];
          }

          else {
            primary['notes'] = data[i]["notes"]; //!primary['notes'] ? '' : primary['notes'];
          }
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              if (key == 'templatename' && (!value || value.toString().toLowerCase().trim() == '') || key == 'description' && (!value || value.toString().toLowerCase().trim() == '')) {
                value = ''
                primary['notes'] = 'Mandatory field is missing!'
              }
              if (primary['notes'] == '') {
                let flag = false;
                if (key.toString().toLowerCase() != 'templatename' && key.toString().toLowerCase() != 'description') {
                  if (value != null) {
                    if (value.toString().toLowerCase().trim() == 'null') {
                      flag = true;
                    }
                    primary[key] = value;
                  }
                }

                if (key.toString().toLowerCase() == 'templatename' || key.toString().toLowerCase() == 'description') {
                  if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                    mandatoryFieldEmpty = true;
                  }
                  primary[key] = value;
                }

                if (checkDataType) {
                  value = dataTypeCol;
                  this.excelDataArray[i]["notes"] = value;
                  primary['notes'] = value;
                }
                if (mandatoryFieldEmpty) {
                  value = 'Mandatory field is missing';
                  this.excelDataArray[i]["notes"] = value;
                  primary['notes'] = value;
                }

                flag ? primary['notes'] = 'Null values exist!' : (primary['notes'] == '' && data[i]['notes'] == '') ? primary['notes'] = 'Valid' : null
              }
              else {
                if (key == 'notes' && primary['notes'] && primary['notes'] != '') {
                  value = primary['notes'];
                }
                primary[key] = value;
              }
              primary[key] = value;
            }
          }

          this.gridArrays.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector.column.grid.headersVisibility = HeadersVisibility.Column
        }
        this.excelDataArray = this.gridArrays;
        this.gridData = new CollectionView(this.gridArrays)
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion
  //on changes deployment key
  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.searchInput = '';
      this.searchUserInput = '';
      this.usertoggle = false;
      this.searchResult = []
      this.searchuserlist = []
      this.srcOrgType = "Laboratory"
      // this.removeGrid();
      this.gridWidth = -1;
      this.resetCopyForm();
      this.getListOrg();
    }
  }
  //reset copy form
  resetCopyForm() {
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmDepKey: this.srcDeployment,
      frmOrgType: this.srcOrgType
    })
  }
  //get list of organizations
  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.SubMenuCardModel.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.formControl['frmOrganization'].setErrors(null);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
        this.formControl['frmOrganization'].setErrors(null);
      }
      this.ngxService.stop();
    }
      , error => {
        console.error(error);
        this.ngxService.stop();
      });
  }
  //fetch Group Name
  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.searchuserlist = [];
    this.searchUserInput = '';
    //this.srcUserid = '';
    if (value === '') {
      this.searchuserlist = [];
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }
  //
  getStiteList(org_id) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: orgid.toString() };
    let query = this.SubMenuCardModel.GetQuery('bodysitesOnCasetype');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName;
    let sitename = [];
    dbName = this.srcDeployment;
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let siteData = data.data.submenuData
        this.bodySites = [...new Map(siteData.map((item) => [item['BodySite'], item['BodySite']])).values(),]
        sitename.push(...this.bodySites);
        sitename.unshift("All");
        this.userList = sitename;
        this.userListArray = sitename;
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });

  }
  //fetch based on the orgid selected the siteid will show
  fetchSiteSeries(value: string) {
    // this.removeGrid();
    this.searchuserlist = []
    if (value === '') {
      return (this.searchuserlist = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.userListArray.find(r => {
          if (r.toString().toLowerCase() === value.toLowerCase()) {
            // this.srcUserid = r.userid
            return r
          } else {
            // this.srcUserid = ''
            //return r.push('All')
            this.usertoggle = true
          }
        })
        this.searchuserlist = this.userListArray.filter(function (series) {
          if (series != null) {
            return series.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.searchuserlist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }
  //selecteduser based on organizationId
  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      //this.removeGrid();
      this.gridWidth = -1;
      //this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getStiteList(this.srcOrgid);
    }
  }

  selectedCopyDataSite(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      // this.srcUserid = data.userid;
      this.srcUserName = data;
      this.searchUserInput = data;
    }
  }


  //  if isdefault is 1 make sure to do old isdefault is 0
  validateDefault() {
    let errorcount = 0
    if (this.excelDataArray) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          if (this.copyDataClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          }
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i) && !this.excelDataArray[i]["notes"].toString().match(/accepts only/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
          errorcount++
        }
        if (this.excelDataArray[i]["notes"].toString() == 'Invalid IsDefault value' || this.excelDataArray[i]["notes"].toString() == 'Invalid isactive value' || this.excelDataArray[i]["notes"].toString() == 'Site id is Invalid' || this.excelDataArray[i]["notes"].toString() == 'Give valid BodySiteid') {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        this.isdefaultconfirmupload = false;
        return
      }
    }
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if ((this.excelDataArray[i]["isdefault"] == 1 || this.excelDataArray[i]["isdefault"] == true)
          && (this.templateData.submenuData.some(va => va.Is_Default == 1 || va.Is_Default == true))
          && (this.excelDataArray[i]["notes"].toString().toLowerCase() == 'valid' ||
            this.excelDataArray[i]["notes"].toString().match(/approval/i))) {
          this.isdefaultconfirmupload = true;
        }
      }
    }
    if (this.isdefaultconfirmupload == false) {
      this.uploadData();
    }
  }
  //#region Upload ExcelData to Insert API
  uploadData() {
    let dataArray = [];
    let errorcount = 0;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          if (this.copyDataClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          }
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i) && !this.excelDataArray[i]["notes"].toString().match(/accepts only/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
          errorcount++
        }
        if (this.excelDataArray[i]["notes"].toString() == 'Invalid IsDefault value' || this.excelDataArray[i]["notes"].toString() == 'Invalid isactive value' || this.excelDataArray[i]["notes"].toString() == 'Site id is Invalid' || this.excelDataArray[i]["notes"].toString() == 'Give valid BodySiteid') {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        this.isdefaultconfirmupload = false;
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            // delete this.excelDataArray[i]["slno"]
            delete this.excelDataArray[i]["tablename"]
            delete this.excelDataArray[i]["notes"]
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
            this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? false : true) : this.excelDataArray[i]["isactive"]
          }
        }
        dataArray = this.excelDataArray;
      }
      this.ngxService.start();
      this.VitalHttpServices.BulkUploadGrossTemplate(dataArray, this.dbname).subscribe(result => {
        this.ngxService.stop();
        var temp1;
        for (let i = 0; i < result.length; i++) {
          if ((result[i].isdefault == true || result[i].isdefault == 1) && (result[i].isactive == true || result[i].isactive == 1)) {
            temp1 = result[i].SlNo
          }
        }
        for (let i = 0; i < result.length; i++)
          if (result[i].SlNo == temp1) {
            result[i].isdefault = 1
          }
          else {
            result[i].isdefault = 0;
          }
        for (let i = 0; i < result.length; i++) {
          if (result[i].isactive == true) {
            result[i].isactive = 1
          }
          else {
            result[i].isactive = 0;
          }
        }
        if (!result.errors) {

          if (result.length > 0) {
            this.checkStatus = false;
            this.postUpload = true;
            this.postDownload = true;
            this.showDelete = false;
            this.excelDataArray = [];
            this.excelDataArray = this.convertObjKeysToLower(result);

            for (let i = 0; i < result.length; i++) {
              this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
              if (result[i]["NoteMessage"].match(/already exists/)) {
                this.excelDataArray[i]["status"] = "Ignored"
              }
              else {
                this.excelDataArray[i]["status"] = result[i]["STATUS"]
              }
            }
            this.excelGridData(this.excelDataArray);
            // this.auditDetails([], dataArray, 'saved');
            if (this.copyDataClicked) {
              this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
            }
            else {
              this.commonService.auditDetails('', '', [], this.excelDataArray, 'Upload', this.templateData, this.auditableColumns);
            }
            this.ngxService.start();

            this.ngxService.stop();
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      })
      this.isdefaultconfirmupload = false;
    }
  }
  //#endregion
  //#region
  removeGrid() {
    // this.gridWidth = this.copyDataClicked ? -1 : 0;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion
  //#regin to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      //  if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null'
          || value.toString().includes('Invalid') || value.toString() == 'Give valid BodySiteid' || value.toString().includes('Mandatory field is missing') || value.toString().includes('accepts only')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().toLowerCase() == 'valid' || value.toString() == 'Grossing template creation successful.' || value.toString().toLowerCase() == 'success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exists')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }
  //#endregion
  // Delete Row
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#endregion
  //#region Export Grid data
  ExportExcelCopyandUpload(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["notemessage"];
      delete e._data["modifiedby"];
      delete e._data["id"];
      excel.push(e._data);
    });
    let filename = 'Grossing Template_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Gross Templates');
    XLSX.writeFile(wb, filename);
  }
  //#endregion
  //#region
  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Mandatory field is missing/i))) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }
  //#endregion

  //#region
  getDataToCopy() {
    this.removeGrid()
    this.gridWidth = -1;
    this.uploadClicked = false;
    this.postUpload = false;
    this.backBtn = true;
    let queryVariable;
    let query;
    if (this.searchUserInput == '') {
      queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.srcOrgid.toString() };
      query = this.SubMenuCardModel.GetQuery("CopyGrossTemplatelist");
    }
    else {
      queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.srcOrgid.toString(), sitename: this.searchUserInput == "All" ? null : this.searchUserInput };
      query = this.SubMenuCardModel.GetQuery("CopyGrossTemplateSiteList");
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.backBtn = false
          this.manageDataColumn(data.data.submenuData);
        } else {
          this.backBtn = true
          this.excelGridData([]);
          this.gridWidth == 0;
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  manageDataColumn(data) {
    var i = 1;
    if (this.copyDataClicked) {
      this.sheetHeader = ["IsActive", "SiteName", "IsDefault", "Description", "templateName", "DisplayOrder", "notes"]//Object.keys(data[0]);
    }
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    if (this.userFlag) {
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'OrganizationGrossTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData.cardtype.toString()
        });
        i++;
      });
    }
    else {
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'OrganizationGrossTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData.cardtype.toString()
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }
  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#region
  //Upload to database the select one
  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    this.uploadData();

  }

  getSheetName(sheetName) {
    this.closeModal();
    this.convertToJson(sheetName);
  }

  closeCasetypeModel() {
    this.isdefaultconfirmaddedit = false;
    this.isdefaultconfirmupload = false;
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }
  //#endregion

  newDeleteMethod(deleteObj: any) {
    this.deleteTemplate(deleteObj.rowData, deleteObj.event)
  }

  recieveObjFromListing(object: any) {
    this.receivedObject = object.newData;
    if (this.receivedObject.Action === 'Create') {
      delete this.receivedObject.OrgGrossTempID
      object.oldData = null;
    }
    else if (this.receivedObject.Action === 'Edit') {
      this.receivedObject['DisplayOrder'] = this.receivedObject['Sequence']
    }
    this.grossTemplateAction(this.receivedObject, object.oldData);
  }

  //#region
  //Function to call API for all CRUD operations on Gross templates.
  grossTemplateAction(newObj, oldObj = null) {
    this.ngxService.start();
    this.VitalHttpServices.grossTemplateAction(newObj)
      .subscribe((res: any) => {
        this.ngxService.stop();
        if (res) {
          this.Result = res;
          if (this.Result.Message.toString().toLowerCase().includes('same name already exists!')) {
            this.isdefaultconfirmaddedit = false;
            this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              width: '500px',
              data: { header: "", message: "", alert: "A template with the same name already exists. Try again with a different name!", continue: 'OK', cancel: "dontshow" }
            });
            return
          }
          else if (this.Result.Message.toString().toLowerCase().includes('cannot be deleted')) {
            this.isdefaultconfirmaddedit = false;
            this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              width: '500px',
              data: { header: "", message: "", alert: "Template cannot be deleted as it is being used in an existing case!", continue: 'OK', cancel: "dontshow" }
            });
          }
          else {
            this.isdefaultconfirmaddedit = false;
            this._snackbar.open(this.Result.Message, 'Close');
          }
          if (res.Success) {
            newObj.IsActive = newObj.IsActive == 1 ? 'Active' : 'Inactive'
            newObj.IsDefault = newObj.IsDefault == 1 ? 'Yes' : 'No'
            if (newObj.Action === 'Create') {
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
              delete newObj.Sequence;
              this.commonService.auditDetails('Id', 'TemplateName', [res], [newObj], newObj.Action, this.templateData, this.auditableColumns);
            }
            else if (newObj.Action === 'Edit') {
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;

              this.commonService.auditDetails('OrgGrossTempID', 'TemplateName', [oldObj], [newObj], newObj.Action, this.templateData, this.auditableColumns);
            }
            else {
              this.commonService.auditDetails('OrgGrossTempID', 'TemplateName', [oldObj], [], newObj.Action, this.templateData, this.auditableColumns);
            }
            // if (res.ID != 0) {
            //   this.auditDetails([], [res], 'saved')
            // }
            // else {
            //   this.auditDetails(this.oldObject, obj, 'updated')
            // }
            this.gridShowDiv = true;
            this.hideGrid = false;
            this.getUpdatedGrid();
          }
        }
        else {
          this.gridShowDiv = true;
          this.hideGrid = false;
          this.getUpdatedGrid();
        }
      }, error => {
        this.ngxService.stop();
        console.error(error);
      })
  }

  //#region
  //Code to show delete popup and further action on confirm.
  deleteTemplate(grid, event) {
    if (!this.hideDeleteBtn) {
      let rowData = grid;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '400px',
        // data: { header: "Delete Template", message: "Clicking on yes will remove the template - " + rowData.TemplateName + " Do you wish to continue?", alert: "", continue: "Yes", cancel: "No" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        if (result) {
          this.ngxService.start();
          let obj = {
            Action: 'Delete',
            OrgGrossTempID: rowData.OrgGrossTempID
          }
          this.grossTemplateAction(obj, grid);
          this.ngxService.stop();
        }
      });
    }
    //#endregion
  }


  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  disableGetDataBtn() {
    return this.searchInput == '' ? true : (this.srcOrgid && this.srcOrgid) != '' ? false : true
  }
  emitFilters($event: any) {
    this.getUpdatedGrid($event?.CaseType?.object?.CaseType);
  }

}
