import { Component, Inject, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialog,  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

})
export class DialogComponent {


  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
    

    onNoClick(data:any) {
      this.dialogRef.close(data);
    }
  
    
}
