<div *ngIf="gridPage">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong *ngIf="!headerName">Manage {{templateData.menuURL}}</strong>
      <strong *ngIf="headerName">{{templateData.menuURL}}</strong>
    </h3>
  </div>
  <div *ngIf="gridwidth > 0" class="modal-content bordernone qty-attributes">
    <wj-flex-grid [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="gridData"
      #grid (initialized)="initGrid(grid)">
      <div *ngIf="templateData.menuURL.toString().toLowerCase() == 'quality attributes'">
          <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="114" [isReadOnly]="true">
              <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEditScreen(grid,$event)"><em id="editButton"
                    [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em></button>
              </ng-template>
            </wj-flex-grid-column>
        <wj-flex-grid-column [width]="200" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
          [header]="col.header" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <div *ngIf="templateData.menuURL.toString().toLowerCase()  != 'quality attributes'">
          <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="114" [isReadOnly]="true">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
              <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEditScreen(grid,$event)"><em id="editButton"
                  [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em></button>
            </ng-template>
          </wj-flex-grid-column>
        <wj-flex-grid-column [width]="'*'" [minWidth]="40" *ngFor="let col of columns"
          [visible]="!(col.header == 'Group Name') && !(col.header == 'Group Sequence') && !(col.header == 'Role') && !(col.header == 'Description') "
          [binding]="col.binding" [header]="col.header" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <div class="align-center">
      <div class="mt-4">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-4"
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
        <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
          <wj-menu-item [value]="0">No Paging</wj-menu-item>
          <wj-menu-item [value]="10">10</wj-menu-item>
          <wj-menu-item [value]="15">15</wj-menu-item>
          <wj-menu-item [value]="30">30</wj-menu-item>
          <wj-menu-item [value]="50">50</wj-menu-item>
        </wj-menu>
      </div>
    </div>
    <div class="align-center">
      <div class="sub-button mt-4">
        <button (click)="ExportGridToExcel(grid)" mat-raised-button class="admin-btn-success mr-4"
          [disabled]="exportBtn">Export</button>
        <button (click)="addQA()" mat-raised-button class="admin-btn-success mr-4"
          [disabled]="createBtn">Create</button>
        <button (click)="loadUploadScreen()" mat-raised-button class="admin-btn-success mr-4"
          [disabled]="uploadBtn">Upload</button>
        <button (click)="copyOrg()" mat-raised-button class="admin-btn-success" [disabled]="copyBtn"
          title="Copy from other entity">Copy</button>
      </div>
    </div>
  </div>

  <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridwidth == 0">
    <div class="col-sm-10 nodata-wrap">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>

  <div class="align-center" *ngIf="gridwidth == 0">
    <div class="sub-button mt-4">
      <button [disabled]="createBtn" (click)="addQA()" mat-raised-button class="admin-btn-success mr-4">Create</button>
      <button [disabled]="uploadBtn" (click)="loadUploadScreen()" mat-raised-button
        class="admin-btn-success mr-4">Upload</button>
      <button [disabled]="copyBtn" (click)="copyOrg()" mat-raised-button class="admin-btn-success"
        title="Copy from other entity">Copy</button>
    </div>
  </div>
</div>

<div *ngIf="uploadClicked" class="my-adequacy-form">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong *ngIf="!headerName">Upload {{templateData.menuURL}} Details</strong>
      <strong *ngIf="headerName">Upload {{templateData.menuURL}}</strong>
    </h3>
  </div>

  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <mat-select disableOptionCentering class="ml-2">
        <mat-option class="temp-font-size" (click)="downloadTemplate('mandatoryFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
        </mat-option>
        <mat-option *ngIf="templateData.menuURL.toString().toLowerCase() != 'quality attributes'" class="temp-font-size" (click)="downloadTemplate('updateCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" class="mb-1 mr-1" alt="help" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload the {{templateData.menuURL}}.</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
    <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="btn btn-sm rounded admin-btn-success mt-2 browse ml-5" for="fileDropRef">Browse file</label>
    </div>
    <button mat-raised-button class="admin-btn-success mt-4" (click)="onBackBtnClick()">
      Back
    </button>
  </div>

  <div *ngIf="gridWidth > 0">
    <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1
      (initialized)="initGrid(grid1)" [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)"
      [frozenColumns]="3">
      <div *ngFor="let columnname of sheetHeader">
        <div *ngIf="templateData.menuURL.toString().toLowerCase() == 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="120"
            [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <div *ngIf="templateData.menuURL.toString().toLowerCase() != 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'attrorder') && !(columnname == 'rolename') && !(columnname == 'organizationid') && !(columnname == 'category')"
            [allowResizing]="true" [width]="245" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [visible]="!(columnname == 'AttrId')" [allowResizing]="true" [width]="245" [allowDragging]="false"
          [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton" [title]="'Delete'"
              class="cursor p-0 fa fa-trash"></em></button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="row pl-3 mt-4">
      <div class="col-sm-2 p-0">
        <button mat-raised-button class="admin-btn-success" (click)="removeGrid()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-field-wrap p-0">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
            (click)="onBackBtnClick()">Finish</button>
          <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
            (click)="onBackBtnClick()">Cancel</button>
          <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()" class="admin-btn-success mr-4"
            (click)="uploadData()">Approve</button>
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid1)">Download
            Result</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
  </div>
</div>

<!-- Create/Edit QA -->
<div *ngIf="addEditClicked">
  <form [formGroup]="formGroupQA">
    <div class="row col-sm-12 p-0 m-0">
      <div class="col-sm-12 modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
          <strong *ngIf="!editEnabled && !headerName">Create {{name}} Details</strong>
          <strong *ngIf="editEnabled && !headerName">Edit {{name}} Details</strong>
          <strong *ngIf="!editEnabled && headerName">Create {{name}}</strong>
          <strong *ngIf="editEnabled && headerName">Edit {{name}}</strong>
        </h3>
      </div>
      <div *ngIf="templateData.menuURL.toString().toLowerCase() == 'quality attributes'"
        class="text-wrap row col-sm-12 p-0 ml-0">
        <div class="col-sm-12 mt-2 pl-4 pr-4">
          <mat-form-field appearance="outline" class="w-100 col-sm-4">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Group </strong>
            </mat-label>
            <input trim type="text" matInput maxlength="100" [matAutocomplete]="auto" formControlName="frmGname"
              #trigger="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
            <div matSuffix style="display:flex">
              <button (click)="clearInput($event)"
                *ngIf=" formGroupQA?.value.frmGname!==null && formGroupQA?.value.frmGname!==''" aria-label="Clear"
                mat-icon-button type="button">
                <mat-icon>clear</mat-icon>
              </button>

              <button (click)="openOrClosePanel($event, trigger)" aria-label="Clear" mat-icon-button type="button">
                <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
              </button>
            </div>
            <mat-autocomplete #auto="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
              (opened)="arrowIconSubject.next('arrow_drop_up')"
              (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
              <mat-option class="mat-opt-align" *ngFor="let cat of filterCategories(formGroupQA.value.frmGname)"
                disableOptionCentering (onSelectionChange)="selectedCategory($event, cat)" [value]="cat.category">
                {{cat.category}}
              </mat-option>
            </mat-autocomplete>
            <!-- <mat-error>{{getErrorMessage(formGroupQA.value.frmGname,'')}}</mat-error>
            <em class="fa fa-chevron-down chevron-align"></em>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let c of filterCategories(formGroupQA.value.frmGname)" disableOptionCentering
                value="{{ c }}"> <span>{{ c }}</span>
              </mat-option>
            </mat-autocomplete> -->
          </mat-form-field>

          <mat-form-field class="w-100 col-sm-4" appearance="outline">
            <mat-label>
              <strong>Sequence(Group)</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="5" [(ngModel)]="formGroupQA.value.frmGsequence"
              formControlName="frmGsequence" pattern="[0-9]+" (keypress)="allowOnlyNumber($event)"
              [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmGsequence,'Gsequence')}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-100 col-sm-4" appearance="outline">
            <mat-label>
              <strong>Sequence(QA)</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="5" formControlName="frmAsequenece" pattern="[0-9]+"
              (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmAsequenece,'sequence')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 d-flex mt-2 mb-2 pl-4 pr-4">
          <mat-form-field class="w-100 col-sm-4 mt-3" appearance="outline">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Name</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="200" formControlName="frmAname" pattern="^[a-zA-Z0-9-,. /]+$"
              [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmAname,'')}}</mat-error>
          </mat-form-field>

          <mat-form-field class="w-100 col-sm-4 mt-3" appearance="outline">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Description</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="4000" formControlName="frmAdescription" pattern="^[a-zA-Z0-9-,. /]+$"
              [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmAdescription,'')}}</mat-error>
          </mat-form-field>

          <!-- start -->
          <!-- <mat-form-field class="width col-sm-4" appearance="outline"> -->
          <!-- <mat-label>
            <strong><span class="text-danger">*</span>
              User Role</strong>
          </mat-label> -->
          <!-- <mat-select disableOptionCentering #matSelectOption formControlName="frmRole" [ngModelOptions]="{standalone: true}"
              multiple>
              <div class="select-all">
                <mat-checkbox [color]="task.color" [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClick()" *ngFor="let r of RoleList" [value]="r.displayname">{{r.displayname}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <!-- end -->

          <div class="col-sm-4">
            <mat-label class="col-sm-12 pl-1 font-size">
              <strong><span class="text-danger">* </span>User Role</strong>
            </mat-label>
            <ng-multiselect-dropdown class="col-sm-4 mt-1" [placeholder]="'Select'" #matSelectOption
              formControlName="frmRole" [settings]="dropdownSettings" [data]="userDropdownList2"
              (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
              (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()">
            </ng-multiselect-dropdown>
          </div>

        </div>
        <div class="col-sm-12 align-space mt-2 mb-2 pl-4 pr-4">
          <div class="col-sm-4 w-100">
            <label><strong>Display</strong></label>
            <mat-checkbox [color]="task.color" class="ml-2 example-margin" [formControl]="selectAll"
              (change)="selectAllDisplay($event)">All</mat-checkbox>
            <section class="example-section">
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" (change)="selectingAll(formGroupQA.value)"
                formControlName="frmRequisition">Requisition</mat-checkbox>
              <mat-checkbox [color]="task.color" class="mt-2 ml-3 example-margin"
                (change)="selectingAll(formGroupQA.value)" formControlName="frmAccession">Accession</mat-checkbox>
              <mat-checkbox [color]="task.color" class="mt-2 ml-3 example-margin"
                (change)="selectingAll(formGroupQA.value)" formControlName="frmGrossing">Grossing</mat-checkbox>
            </section>
            <section class="example-section">
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" (change)="selectingAll(formGroupQA.value)"
                formControlName="frmProcessing">Processing</mat-checkbox>
              <mat-checkbox [color]="task.color" class="mt-2 ml-3 example-margin"
                (change)="selectingAll(formGroupQA.value)" formControlName="frmDiagnosis">Diagnosis</mat-checkbox>
              <mat-checkbox [color]="task.color" class="mt-2 ml-3 example-margin"
                (change)="selectingAll(formGroupQA.value)" formControlName="frmFinalized">Finalized</mat-checkbox>
            </section>
          </div>

          <div class="col-sm-3 w-100">
            <label style="margin-bottom: 28px;"><strong>Additional Attributes</strong></label>
            <div class="col-sm-3">
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmComments">Comments
              </mat-checkbox>
            </div>
          </div>

          <div class="col-sm-3 w-100">
            <label style="margin-top: 15px;"><strong>Classify</strong></label>
            <div class="col-sm-3">
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmIshippa">HIPAA
              </mat-checkbox>
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmIsmirp">MIRP
              </mat-checkbox>
            </div>
          </div>

          <div class="col-sm-2 w-100">
            <label style="margin-bottom: 20px;"><strong>Status</strong></label>
            <div class="col-sm-2">
              <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmActive">Active
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-sm-12 d-flex mt-2 mb-2 pl-4 pr-4">
          <section class="col-sm-3 w-100">
            <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmUsedforqc"><strong>Used
                for QC</strong></mat-checkbox>
          </section>
          <section class="col-sm-3 w-100">
            <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmStage">
              <strong>Stage</strong>
            </mat-checkbox>
          </section>
          <section class="col-sm-3 w-100">
            <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmAllowSync"><strong>Allow
                External Syncronization</strong></mat-checkbox>
          </section>
        </div>
      </div>
      <div *ngIf="templateData.menuURL.toString().toLowerCase() != 'quality attributes'"
        class="text-wrap row col-sm-12 p-0 ml-0">
        <div class="col-sm-12 d-flex mt-2 mb-2">
          <mat-form-field class="w-100 col-sm-4" appearance="outline">
            <mat-label>
              <strong><span class="text-danger">*</span>
                Name</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="200" formControlName="frmAname" pattern="^[a-zA-Z0-9-,. /]+$"
              [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmAname,'')}}</mat-error>
          </mat-form-field>

          <mat-form-field class="w-100 col-sm-4" appearance="outline">
            <mat-label>
              <strong>Sequence</strong>
            </mat-label>
            <input trim type="text" matInput maxlength="5" formControlName="frmAsequenece" pattern="[0-9]+"
              (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
            <mat-error>{{getErrorMessage(formGroupQA.value.frmAsequenece,'sequence')}}</mat-error>
          </mat-form-field>

          <section class="example-section col-sm-2">
            <mat-checkbox [color]="task.color" class="example-margin" style="margin-bottom: 32px;margin-top: 10px;"
              formControlName="frmActive">Active</mat-checkbox>
          </section>
        </div>

        <!-- <div class="col-sm-12 d-flex mt-2 mb-2">
          <mat-label><strong>Role<span class="text-danger">*</span></strong></mat-label>
          <ng-multiselect-dropdown class="col-sm-10 text-xs" [placeholder]="'Select'" #matSelectOption
            formControlName="frmFlagRole" [settings]="dropdownSettings" [data]="userDropdownList2"
            (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll()">
          </ng-multiselect-dropdown>
        </div> -->
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-3">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="onBackBtnClick()">
          Back
        </button>
      </div>
      <div class="align-center col-sm-9 back-btn">
        <button mat-raised-button class="admin-btn-success" (click)="validateForm('',formGroupQA.value)">
          Save & Close
        </button>
        <button mat-raised-button *ngIf="!editEnabled" class="admin-btn-success ml-4"
          (click)="validateForm('new',formGroupQA.value)">
          Save & Create
        </button>
      </div>
    </div>
  </form>
</div>

<!-- Copy-from-other-entity -->
<div *ngIf="copyDataClicked">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong *ngIf="!headerName">Copy {{templateData.menuURL}} Details</strong>
      <strong *ngIf="headerName">Copy {{templateData.menuURL}}</strong>
    </h3>
  </div>
  <form [formGroup]="copyFormGroup" (keydown.enter)="$event.preventDefault()" class="copy-collection">
    <div class="row col-sm-12 p-0 ml-0">
      <div class="row col-sm-12 mt-2">
        <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label><span class="error-msg">*</span>Deployment</mat-label>
          <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
            <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
              (onSelectionChange)="onChangeDeployment($event,i)">
              <span>{{ i }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label><span class="error-msg">*</span>Group </mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
            [disabled]="disableOrgSearch" #searchbar (keyup)="fetchOrgSeries(copyFormGroup.value.frmOrganization)"
            [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
          <mat-autocomplete #orgauto="matAutocomplete">
            <div *ngFor="let show of searchResult">
              <mat-option class="mat-opt-align"
                *ngIf="show.organizationid.toString().includes(searchInput.toString()) || show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.organizationname }}"
                title="{{ show.organizationname }} ({{show.organizationid}})">
                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
              </mat-option>
            </div>
          </mat-autocomplete>

          <button *ngIf="copyFormGroup.value.frmOrganization" matSuffix mat-icon-button aria-label="Clear"
            title="Reset Search" (click)="clearOrganization()">
            <mat-icon>close</mat-icon>
          </button>

        </mat-form-field>

        <mat-form-field *ngIf = "templateData.menuURL.toString().toLowerCase() == 'quality attributes'" class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label><span class="error-msg">*</span>Role</mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input type="text" matInput formControlName="frmRole" [(ngModel)]="searchUserInput" #searchuserbar
            (keyup)="fetchRoleSeries(searchuserbar.value)" [matAutocomplete]="userauto" placeholder="Search here ..."
            style="width: 95%;">
          <mat-autocomplete #userauto="matAutocomplete">
            <div *ngFor="let role of searchRoleList">
              <mat-option class="mat-opt-align"
                *ngIf="role.displayname.toLowerCase().includes(searchUserInput.toLowerCase())"
                (onSelectionChange)="selectedCopyDataUser($event, role)" value="{{ role.displayname }}"
                title="{{ role.displayname }}">
                <span>{{ role.displayname }}</span>
              </mat-option>
            </div>
          </mat-autocomplete>

          <button *ngIf="copyFormGroup.value.frmRole" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
            (click)="clearRole()">
            <mat-icon>close</mat-icon>
          </button>

        </mat-form-field>

      </div>
    </div>

    <div class="row align-center mt-2 ml-3 float-left" *ngIf="templateData.menuURL.toString().toLowerCase()  !== 'quality attributes'">
        <div class="dml-btn" *ngIf="(!backBtn && !postUpload) || (backBtn)" >
            <button mat-raised-button class="admin-btn-success mr-4" (click)="onBackBtnClick()">
              Back
            </button>
        </div>
    </div>

    <div class="row align-center mt-2" *ngIf="templateData.menuURL.toString().toLowerCase()  == 'quality attributes'">
      <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="onBackBtnClick()">
            Back
          </button>
        </div>

      <div class="dml-btn" >
        <button mat-raised-button class="admin-btn-success"
         [disabled]="disableGetDataBtn() || postUpload"
          (click)="getDataToCopy()">Get Data</button>
      </div>
    </div>

  </form>
  <div *ngIf="gridWidth > 0">
    <wj-flex-grid #flexgrid [isReadOnly]="true" (initialized)="initializeGrid(flexgrid)"
      [itemsSource]="gridData" class="grid-height mt-3" (formatItem)="formatItem(flexgrid, $event)" [frozenColumns]="2">
      <div *ngFor="let columnname of sheetHeader">
        <div *ngIf="templateData.menuURL.toString().toLowerCase()  == 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'attrid') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname == 'parentid') && !(columnname == 'organizationid')" 
            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [visible]="!(columnname == 'attrid') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname == 'parentid') && !(columnname == 'organizationid')"
            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <div *ngIf="templateData.menuURL.toString().toLowerCase()  != 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'AttrOrder') && !(columnname == 'AttrId') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname.toLowerCase() == 'organizationid')"
            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [visible]="!(columnname == 'AttrId') && !(columnname == 'AttrOrder')  && !(columnname?.toString().toLowerCase() == 'id')  && !(columnname.toLowerCase() == 'organizationid')"
            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <br />
    <div *ngIf="!backBtn && !postUpload" class="row align-center pl-3 mt-2 ml-5 align-center">
      <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0" ng-init='checkStatus=false'
        [checked]='checkStatus' ng-model='checkStatus' (change)='checkValue($event)' class="border-style">
      <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
    </div>
    <div class="row align-center mt-4">
      <div *ngIf="!backBtn" class="dml-btn mr-4">
        <button *ngIf="postUpload" mat-raised-button class="admin-btn-success mr-4" (click)="onBackBtnClick()">
          Finish
        </button>
        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(flexgrid)">Download
          Result</button>
      </div>
      <div *ngIf="!postUpload" class="align-center">
        <button mat-raised-button class="admin-btn-success" [disabled]="selectedItems.length==0 "
          (click)="uploadCopyData(flexgrid)">Approve</button>
      </div>
    </div>
  </div>

  <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
    <div class="col-sm-10 nodata-wrap">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
</div>

