export class SearchKey {
    //SearchValue:string ;
    SearchOnIndex: string ;
    Exceptindex :string ;
    VitalAdditionalParam : VitalAdditionalParam ;
    AdditionalParam : SearchParameter;
    VitalSearchText : SearchText;
}
export class SearchText {
    Text : string ;
    isApproximate : boolean;
    StartWith : boolean ;
    ContainsOrLike : boolean;
}
export class VitalAdditionalParam{
    VitalFilter : VitalFilter;
    VitalQueryType : any;
    VitalSearchMode : any;
    Orderby: any;
}
export class VitalFilter {
    FieldName : string;
    FieldValue : string;
    FilterGroupName : string; // for radio button
    FilterCondition: string;
}

export class SearchParameter {
    Select : any[] = [];
    IncludeTotalResultCount:boolean;
    Facets : any[] = [];
    Filter:string;
    SearchMode:string;
    Top:number;
    QueryType:string;
    OrderBy:any[] = [];
}

