import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
declare var $: any;
import { VitalAdminTabService } from '../../tab.service';
import { UserToggleComponent } from 'src/app/base/popup/user-toggle/user-toggle.component';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';

import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
@Component({
  selector: 'app-microscopic-codes',
  templateUrl: './microscopic-codes.component.html',
  styleUrls: ['./microscopic-codes.component.scss']
})

export class MicroscopicCodesComponent implements OnInit {

  @Input() templateData: any;
  gridheader = [
    "Name",
    "Description",
    "CaseType",
    "User",
    "PreferenceId",
    "UserId",
    "createdby",
    "createddate",
    "modifiedby",
    "modifieddate"
  ];
  action: string;
  createScreen: boolean;
  gridScreen: boolean;
  caseTypeList: any;
  filteredOptions: Observable<any[]>;
  excelDataArray: any[];
  gridWidth: number;
  sheetHeader: any;
  uploadClicked: boolean;
  postUpload: boolean;
  showGrid: boolean;
  gridPage: boolean;
  workBook: any;
  sheetsToSelect: any[];
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  showInvalidColumns: boolean;
  invalidColumns: any;
  showDelete: boolean;
  deployment: string;
  postDownload: boolean;
  selectedItems: any;
  checkStatus: any;
  userFlag: boolean = false;
  filterCaseType: Observable<any[]>;
  preferenceId: any;
  allFieldUpdate = [{}];
  sampleDataAll = [{
    name: 'Name',
    description: 'Description',
    userid: '0-9'
  }]
  sampleDataUpdate = [{
    id: 1234,
    name: 'Name',
    description: 'Description'
  }]
  copyfromScreen: boolean = false;
  searchResult: any[];
  destDeployment: string;
  organizationid: string;
  orgName: any;
  searchInput: string;
  DeploymentKeys: any;
  organizationList: any;
  srcUserid: string;
  searchUserInput: string;
  srcOrgid: any;
  srcOrgName: any;
  searchuserlist: any[];
  userList: any;
  userListArray: any;
  srcUserName: any;
  selector: Selector = null;
  noDataFound: boolean;
  selectedData: any = [];
  showOrgInDrop: boolean;
  userNamesList: any;
  UserList: any;
  CaseComments: string;
  PreviousUserdata: any;
  underorguserlevel: boolean;
  selectedCopyData: any[];
  selectedUser: any = [];
  userSelected: boolean;
  tempselectedUser: any;
  tempUserDetails: any;
  userDetails: any;

  oldobj: {};
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideUpdateBtn: boolean = true;
  hideCopyToOrgBtn: boolean;
  hideUploadbtn: boolean = true;
  userRoles: any = [];
  selectedRole: any;
  noRole: boolean;

  Username: any = [];
  userDropdownList: any;
  userDropdownList1: object[] = [];

  @ViewChild('matSelectOption') matSelectOption: MatSelect;

  dropdownSettings = {
    singleSelection: false,
    idField: 'UserId',
    textField: 'UserName',
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 70,
    itemsShowLimit: 3,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false
  };

  userDropdownList2: any;
  ServiceListname: any = [];
  allServicesSelected: boolean = false;
  allBilltoSelected: boolean = false;
  organizationId: any;
  minimumFields = [{}];
  isP4Depl: boolean;
  accountLevelSelected: boolean;
  tempCasetype = [];
  allowUserlvl: any;
  migrateenable: boolean;
  hideMigratebtn: boolean = false;
  hideManagemicrobtn: boolean = false;
  gridHeaderList: any[];
  listHeaderbackup: any[];
  listedGridHeader: any[];
  sendGridData: wjcCore.CollectionView<any>;
  ViewScreen: boolean = false;
  auditableColumns: any;
  receivedObject: any;
  activityEntity: {};
  saveOrEditCompleted: number;


  userSessionDetails: any;
  sourceApp: string;
  dbName: string;
  selectedFilterData: any = { CaseType: '', UserId: '' };


  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.deployment = this.destDeployment;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
          _this.templateData = _this.labadminService.templateData;
          _this.dbName = _this.labadminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', _this.dbName);
          sessionStorage.setItem('org_id', _this.templateData.secondarykeys.OrganizationId);
          _this.getUserList();
          _this.activityService.setActivitySession({ 'entityId': '', 'entityType': _this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':_this.templateData['menuURL']}] })
          _this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          _this.getAuditableDetails("Microscopic Notes")
          _this.GetButtonAccess(_this.labadminService.subMenuPermissions)
        }
      })

    }
    else {
      this.gridHeaderList = this.templateHeaders.templateList;
      for (let i = 0; i < this.gridHeaderList.length; i++) {
        if (this.gridHeaderList[i].templateName == 'MicroscopicCodes') {
          this.listedGridHeader = this.gridHeaderList[i].headers;
          this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
        }

      }
      this.action = 'Microscopic Notes'
      this.deployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.isP4Depl = this.commonService.isP4deployment(sessionStorage.getItem('deploymentKey'));
      if (sessionStorage.getItem("search_context").toLowerCase() == "users" || sessionStorage.getItem("search_context").toLowerCase() == 'physician' || sessionStorage.getItem("search_context").toLowerCase() == 'pathologist' || sessionStorage.getItem("search_context").toLowerCase() == "userid" || sessionStorage.getItem("search_context").toLowerCase() == 'physician') {
        this.userFlag = true; // for user card type
        this.userRoles = this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, '')); sessionStorage.getItem("search_context").toLowerCase() == "userid"
        if (this.userRoles && this.userRoles.length > 0) {
          let userID = this.userRoles[0].userid.toString();
          this.selectedRole = this.userRoles[0].RoleName.toString();
          this.selectedUser = userID
          this.templateViewService.globalMasterDataForQueries['userRole'] = this.userRoles[0].RoleName.toString();
          this.templateViewService.globalMasterDataForQueries['userId'] = userID;
          this.userSelected = true;
          this.getCaseType();
        }
        else {
          this.noRole = true;
        }
      } else {
        this.isP4Depl ? this.userlist.setValue('All Users') : this.userlist.setValue('Group Level')
        this.userFlag = false;
      }
      this.getUserList();
      if(this.userFlag){
        this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
      }else{
        this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      }
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.getbuttonDetails();
    }
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'MicroscopicCodes') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }
    }
  }


  allFields = [{}];
  gridData: CollectionView<any>;
  showPaginationMainGrid: boolean;

  microScopicfrm = this._fb.group({
    frmName: ["", Validators.required],
    frmDescription: ["", Validators.required],
    frmCaseType: ["", Validators.required],
    frmUsers: ["", Validators.required]
  })

  userlist = new FormControl();

  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: '',
    frmUser: ''
  })

  constructor(private dialog: MatDialog, private _fb: UntypedFormBuilder, public _snackbar: MatSnackBar, private vitalHttpServices: VitalHttpServices, private commonService: CommonService, private ngxService: NgxUiLoaderService, public tabService: VitalAdminTabService, private templateHeaders: TemplateHeadersService, public templateViewService: TemplateViewService, public activityService: ActivityTrackerService,
    private labadminService: LabadminService, private labAdminSessionService: LabadminSessionService, private store: Store<{ breadcrum: [] }>,
  ) { }

  getbuttonDetails() {
    if (this.userFlag) {
      this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va => va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    } for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Export") {
        this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Create") {
        this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Edit") {
        this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "CopyToOrg" || seletedMenuPermissions[i].Button === "Copy") {

        this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Upload") {
        this.hideUploadbtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Migrate") {
        this.hideMigratebtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "ManageMicroscopicNotes") {
        this.hideManagemicrobtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  getUserList(result?: string) {
    let role;
    if (this.commonService.finalizeAfterTc) {
      role = 'Lab Manager,Lab Director,Technician'
    }
    else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
      role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant,Screening Technologist'
    }
    else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
      role = 'Pathologist,Pathologist Assistant,Screening Technologist'
    }
    let queryVariable = {
      "orgid": sessionStorage.getItem('org_id'),
      "filtername": "RoleName in \"" + role.toString() + "\":string []"
    };
    let query = this.vitalHttpServices.GetQuery("microCodesUSerList");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.userDropdownList = data.data.submenuData;
        if (result != 'saved') {
          this.userDropdownList.forEach(data => {
            const temp: object = {
              'UserId': data.UserId, 'UserName': data.username + " " + '(' + data.RoleName + ')'
            }
            this.userDropdownList1.push(temp);
          });
          this.userDropdownList2 = this.userDropdownList1;
          if (data) {
            this.userList = data.data.submenuData
            this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData();
            this.filteredOptions = this.userlist.valueChanges
              .pipe(
                startWith(''),
                map(user => user && typeof user === 'object' ? user.name || user.id : user),
                map((name: string) => name ? this.filterName(name) : this.userList.slice())
              );
          }
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });

  }

  // filterParameter: "refconfigtarget = \"organizations\" or refconfigtarget = \"VaEntityExtAttributes\" or refconfigtarget=\"Accounts\""
  accountLevelData() {
    let queryVariable = { "casetype": this.sourceApp == 'VitalDx' ? this.selectedFilterData?.CaseType : this.templateData.secondarykeys.casetype.toString(), "orgid": this.templateData.secondarykeys.OrganizationId.toString() }
    // let queryVariable = { "casetype": this.templateData.cardtype.toString(), "orgid": this.templateData.secondarykeys.OrganizationId.toString() }
    let query = this.vitalHttpServices.GetQuery("MicroScopicCodesAccountlevel");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.deployment).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.gridScreen = true;
          if (data.data.submenuData.length >= 0) {
            if (this.sourceApp === 'VitalDx') {
              data.data.submenuData = data.data.submenuData.filter(item => {
                return (!this.selectedFilterData.caseType || item.caseType.toLowerCase() === this.selectedFilterData.caseType.toLowerCase()) &&
                  (!this.selectedFilterData.UserId || item.UserId === this.selectedFilterData.UserId);
              });
            } else {
              data.data.submenuData = data.data.submenuData
            }
            this.templateData.submenuData = data.data.submenuData
            this.showWijGrid();
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }
  groupLevelData(caseType?: String) {
    let queryVariable = { casetype: this.sourceApp == 'VitalDx' ? caseType : this.templateData.secondarykeys.casetype.toString(), orgid: this.templateData.secondarykeys.OrganizationId.toString() }
    // { "casetype": this.templateData.cardtype.toString(), "orgid": this.templateData.secondarykeys.OrganizationId.toString() }
    let query = this.vitalHttpServices.GetQuery("MicroScopicCodesGrouplevel");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.deployment).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.gridScreen = true;
          if (data.data.submenuData.length >= 0) {
            if (this.sourceApp === 'VitalDx') {
              data.data.submenuData = data.data.submenuData.filter(item => {
                return (!this.selectedFilterData.CaseType || item.CaseType.toLowerCase() === this.selectedFilterData.CaseType.toLowerCase()) &&
                  (!this.selectedFilterData.UserId || item.UserId === this.selectedFilterData.UserId);
              });
            } else {
              data.data.submenuData = data.data.submenuData
            }
            this.templateData.submenuData = data.data.submenuData
            this.showWijGrid();
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }



  filterName(name: string): any[] {
    return this.userList.filter(option =>
      option.UserName.toLowerCase().includes(name.toLowerCase()) || option.UserName.toLowerCase().includes(name.toLowerCase()));
  }

  async userSelection(data) {
    this.accountLevelSelected = false;
    if (data == 'All' || (this.isP4Depl && data == '')) {
      this.microScopicfrm.controls["frmUsers"].enable();
      this.accountLevelSelected = true;
      this.accountLevelData();
      this.selectedUser = null;
      this.userSelected = false;
    }
    else if (data == 'Group' || data == '') {
      this.microScopicfrm.controls["frmUsers"].enable();
      this.groupLevelData();
      this.selectedUser = null;
      this.userSelected = false;
    }
    else {
      this.userSelected = true;
      this.microScopicfrm.controls["frmUsers"].disable()
      this.selectedUser = data.UserId;
      this.allowUserlvl = data.Allowuserlvl;
      this.selectedRole = data.RoleName.toString();
      await this.getCaseType();
      this.getMicroNotes(data.UserId)
      this.migrateenabling(data)
    }
  }

  showWijGrid() {
    let gridarray = []
    let primary = {}
    this.createScreen = false;
    this.gridScreen = true;
    this.action = 'Microscopic Notes'
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      this.sendGridData = new CollectionView(gridarray)
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }

    }
  }

  //#region Tooltip for the wijmo grid
  initGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  createMicroNotes() {
    this.getListUsers();
    this.microScopicfrm.reset();
    this.createScreen = true;
    this.preferenceId = null;
    this.action = 'Create Microscopic Note'
    this.gridScreen = false;
    this.microScopicfrm.controls['frmCaseType'].enable();
    if (this.userSelected) {
      this.microScopicfrm.controls["frmUsers"].disable();
    }
    else {
      this.microScopicfrm.controls['frmUsers'].enable();
    }
    if (!this.userFlag) {
      this.tempUserDetails = this.userList.find(va => va.UserId == this.selectedUser)
      this.microScopicfrm.patchValue({
        frmName: '',
        frmDescription: '',
        frmCaseType: '',
        frmUsers: this.userSelected ? this.tempUserDetails.username + ' (' + this.tempUserDetails.RoleName + ')' : ''
      })
    }
    else {
      this.microScopicfrm.patchValue({
        frmName: '',
        frmDescription: '',
        frmCaseType: ''
      })
    }
  }

  editMicroCodes(row) {
    if (!this.hideUpdateBtn) {
      if (!this.userFlag && row.User_Id) {
        this.userDetails = this.userList.find(va => va.UserId == row.User_Id)
      }
      this.getListUsers();

      this.action = 'Edit Microscopic Note';
      this.createScreen = true;
      this.gridScreen = false;
      this.preferenceId = row.Preference_Id;
      this.oldobj = {
        "preferencename": row.Name,
        "preferencevalue": row.Description
      }
      this.microScopicfrm.patchValue({
        frmName: row.Name,
        frmDescription: row.Description,
        frmCaseType: row.Case_Type,
        frmUsers: !this.userFlag && row.User_Id ? this.userDetails?.username + " (" + this.userDetails?.RoleName + ")" : null
      })
      if (!this.userFlag) {
        this.microScopicfrm.controls.frmUsers.disable();
      }
      else {
        this.microScopicfrm.controls.frmCaseType.disable();
      }
    }
  }

  saveMicroNotes(action, data) {
    let dataJson = [];
    let tempdataJson = {};
    if (this.userFlag) {
      if (data.frmName == '' || data.frmDescription == '' || data.frmCaseType == '') {
        this._snackbar.open("Please provide valid data", "Close")
        return
      }
      if (this.action != 'Edit Microscopic Note') {
        let test = this.caseTypeList.find(va => va.caseType == data.frmCaseType)
        if (!test) {
          this._snackbar.open("Please select valid Case type", "Close")
          return
        }
      }
    }
    else {
      if (!this.action.match(/edit/i)) {
        let temparr = [];
        let arr: any = []
        arr = data.frmUsers ? data.frmUsers : [];
        if (this.userSelected) {
          arr.push(this.selectedUser)
        }
        for (let i = 0; i < arr.length; i++) {
          if (this.userSelected) {
            temparr.push(this.selectedUser)
          }
          else {
            temparr.push(arr[i].UserId)
          }
        }

        if (temparr) {
          this.selectedUser = temparr.toString()
          this.tempselectedUser = this.selectedUser;
        }
        if (data.frmName == '' || data.frmDescription == '') {
          this._snackbar.open("Please provide valid data", "Close")
          return
        }
        if (this.commonService.isP4deployment(sessionStorage.getItem('deploymentKey')) && data.frmUsers == '') {
          this._snackbar.open("Please select User", "Close")
          return
        }
        // if (!this.tempselectedUser) {
        //   this._snackbar.open("Please select valid user", "Close")
        //   return
        // }
      }
    }
    if (data.frmName == '' || data.frmDescription == '') {
      this._snackbar.open("Please provide valid data", "Close")
      return
    }
    // setting obj for add/edit for user card type
    tempdataJson = {
      "createdby": sessionStorage.getItem('UserId') == '' ? -100 : sessionStorage.getItem('UserId'),
      "preferencename": data.frmName,
      "preferencevalue": data.frmDescription,
      "casetype": this.userFlag ? data.frmCaseType : this.templateData.cardtype,
      "preferenceid": this.preferenceId,
      // ? this.tempselectedUser : this.userDetails.UserId
      "userid": this.userFlag ? this.selectedUser : this.selectedUser ? this.selectedUser : this.tempselectedUser,
      "organizationid": sessionStorage.getItem("org_id")
      // }
    }
    dataJson.push(tempdataJson)
    if (this.action.match(/edit/i)) {
      for (let i = 0; i < this.templateData.submenuData.length; i++) {
        if (this.templateData.submenuData[i].Preference_Id != dataJson[0].preferenceid && this.templateData.submenuData[i].Name.toLowerCase() == dataJson[0].preferencename.toLowerCase() && this.templateData.submenuData[i].User_Id == dataJson[0].userid) {
          this._snackbar.open("Microscopic codes already exists", "Close")
          return;
        }

      }
      this.excelDataArray = [];
      this.updateMicroCodes(dataJson, false, null);
    }
    else {
      this.excelDataArray = [];
      for (let i = 0; i < this.templateData.submenuData.length; i++) {
        if (this.templateData.submenuData[i].Name.toLowerCase() == dataJson[0].preferencename.toLowerCase() && this.templateData.submenuData[i].User_Id == dataJson[0].userid) {
          this._snackbar.open("Microscopic codes already exists", "Close")
          return;
        }

      }
      let context = action == 'new' ? "All" : null
      // this.globalexist=action!='new' ? false : null
      this.addMicroCodes(dataJson, false, context);
      //this.getInputData('', this.templateData.submenuData);
    }
  }

  selectingUsers(data) {
    this.tempselectedUser = data.UserId
  }

  addMicroCodes(dataArray, bulkUpload: boolean = false, context) {
    this.ngxService.start();

    //this.checkStatus = false;
    this.vitalHttpServices.bulkUploadmicroCodes(dataArray, this.deployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.showDelete = false;
          this.getUserList('saved');
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['status'] = result[j]['Status']
                if (this.copyfromScreen) {
                  this.commonService.auditDetails('', '', [], dataArray, 'Copy', this.templateData, this.auditableColumns);
                }
                else {
                  this.commonService.auditDetails('', '', [], dataArray, 'Upload', this.templateData, this.auditableColumns);
                }
                // this.commonService.createActivityTracker('Created', result[j]['id'], 'Mirroscopic Notes', 'Audit', this.excelDataArray[i], {});
                break;
              }
              else {
                // if (this.excelDataArray[i]['notes'].toString().match(/already exists/i)) {
                this.excelDataArray[i]['status'] = 'Ignored'
                // }
              }
            }
            for (let [key, value] of Object.entries(this.excelDataArray[i])) {
              if (key == 'preferencename') {
                this.excelDataArray[i]["name"] = this.excelDataArray[i]["preferencename"];
                delete this.excelDataArray[i]["preferencename"];
              }
              else if (key == 'preferencevalue') {
                this.excelDataArray[i]["description"] = this.excelDataArray[i]["preferencevalue"];
                delete this.excelDataArray[i]["preferencevalue"];
              }
            }

          }
          if (this.excelDataArray.length == 0) {
            // this.commonService.createActivityTracker('Created', dataArray[0].preferenceid, 'Mirroscopic Notes', 'Audit', dataArray[0], {});
          }
          this.postUpload = true;
          this.microScopicfrm.controls.frmCaseType.enable();
          this.microScopicfrm.controls.frmUsers.enable();
          bulkUpload ? this.AddExcelData(this.excelDataArray) : null;
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            this._snackbar.open('Microscopic code with this name already exists.', 'Close');
          }
          else {
            if (!bulkUpload && result[0]['Notes'].match(/already exist/)) {
              this._snackbar.open(result[0]['Notes'], "Close")
              return;
            }
            bulkUpload ? this._snackbar.open('Data uploaded successfully', 'Close') : this._snackbar.open('Microscopic notes created successfully', 'Close');
            if (context == null && !bulkUpload) {
              if (this.userFlag) {
                this.getMicroNotes(this.templateData.cardIdentifier.toString())

              }
              else {
                this.tempselectedUser = null;
                this.userSelected ? this.getMicroNotes(this.selectedUser) : this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData()
              }
            }
            else {
              this.userSelected ? this.microScopicfrm.controls["frmUsers"].disable() : this.microScopicfrm.controls["frmUsers"].enable()
              this.tempselectedUser = null;
              this.microScopicfrm.patchValue({
                frmName: '',
                frmDescription: '',
                frmCaseType: '',
                frmUsers: !this.userFlag ? this.userSelected ? this.tempUserDetails.Username + ' (' + this.tempUserDetails.RoleName + ')' : '' : null
              })
              Object.keys(this.microScopicfrm.controls).forEach(key => {
                this.microScopicfrm.controls[key].setErrors(null)
              });
            }

          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Microscopic addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }

  resetFormErrors() {
    Object.keys(this.microScopicfrm.controls).forEach(key => {
      this.microScopicfrm.controls[key].setErrors(null)
    });
  }

  updateMicroCodes(sendjson, bulkUpload: boolean = false, context) {
    this.ngxService.start();
    //this.checkStatus = false;
    this.vitalHttpServices.editMicroCodes(sendjson, this.deployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.showDelete = false;
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['status'] = result[j]['Status']
                this.commonService.auditDetails('', '', [], sendjson, 'Bulk Update', this.templateData, this.auditableColumns);

                // this.commonService.createActivityTracker('Edited', result[j]['id'], 'Microscopic Notes', 'Audit', this.excelDataArray[i], {});
                break;
              }
              else {
                // if (this.excelDataArray[i]['notes'].toString().match(/already exists/i)) {
                this.excelDataArray[i]['status'] = 'Ignored'
                // }
              }
            }
            for (let [key, value] of Object.entries(this.excelDataArray[i])) {
              if (key == 'preferencename') {
                this.excelDataArray[i]["name"] = this.excelDataArray[i]["preferencename"];
                delete this.excelDataArray[i]["preferencename"];
              }
              else if (key == 'preferencevalue') {
                this.excelDataArray[i]["description"] = this.excelDataArray[i]["preferencevalue"];
                delete this.excelDataArray[i]["preferencevalue"];
              }
              else if (key == 'preferenceid') {
                this.excelDataArray[i]["id"] = this.excelDataArray[i]["preferenceid"];
                delete this.excelDataArray[i]["preferenceid"];
              }
            }
          }
          if (this.excelDataArray.length == 0) {
            // this.commonService.createActivityTracker('Edited', sendjson[0].preferenceid, 'Mirroscopic Notes', 'Audit', sendjson[0], this.oldobj);
          }
          this.postUpload = true;
          this.microScopicfrm.controls.frmCaseType.enable();
          this.microScopicfrm.controls.frmUsers.enable();
          bulkUpload ? this.AddExcelData(this.excelDataArray) : null;
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            this._snackbar.open('Micrscopic code with the same name already exists.', 'Close');
          }
          else {
            bulkUpload ? this._snackbar.open('Data updated successfully', 'Close') : this._snackbar.open('Updated successfully', 'Close');
            if (!bulkUpload) {
              if (this.userFlag) {
                this.getMicroNotes(this.templateData.cardIdentifier.toString())
              }
              else {
                this.userSelected ? this.getMicroNotes(this.selectedUser) : this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData()
              }
            }
            else {
              this.microScopicfrm.patchValue({
                frmName: '',
                frmDescription: '',
                frmCaseType: '',
                frmUsers: ''
              })
              Object.keys(this.microScopicfrm.controls).forEach(key => {
                this.microScopicfrm.controls[key].setErrors(null)
              });
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Microscopic addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  setUserRole(event, data) {
    if (event.source.selected) {
      this.commonService.selectedRolesUserIDForMicroCodes = data.userid.toString();
      this.selectedRole = data.RoleName.toString();
      this.selectedUser = data.userid.toString();
      this.templateViewService.globalMasterDataForQueries['userRole'] = data.RoleName.toString();
      this.templateViewService.globalMasterDataForQueries['userId'] = data.userid.toString();
      this.userSelected = true;
      this.getCaseType();
      this.migrateenabling(data)
    }
  }

  getCaseType() {
    this.ngxService.start();
    this.vitalHttpServices.getCasetypesBasedOnRole(this.selectedRole, this.selectedUser).subscribe(
      (res) => {
        this.ngxService.stop();
        this.caseTypeList = res;
        this.getMicroNotes(this.selectedUser);
        this.filterCaseType = this.microScopicfrm.controls.frmCaseType.valueChanges
          .pipe(
            startWith(''),
            map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
            map((name: string) => name ? this.filterLookupAttrId(name) : this.caseTypeList.slice())
          );
        //}
      }, error => (
        this.ngxService.stop(),
        console.error(error)
      ));
  }

  filterLookupAttrId(name) {
    return this.caseTypeList.filter(option =>
      option.CaseType.toLowerCase().includes(name.toLowerCase()));
  }

  AddExcelData(data: object[]) {
    this.ngxService.stop();
    this.gridScreen = false;
    this.gridWidth = 0;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              if (value == null && key != 'preferenceid') {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              // if (this.userSelected && key == 'userid' && value) {
              //   primary['notes'] = 'Upload the data for selected User'
              // }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        if (this.showDelete) {
          // this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'Status'), 1)
        }
        else {
          this.sheetHeader.push('Status');
        }
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.excelDataArray = gridArray;
        this.gridData = new CollectionView(gridArray)
        // this.gridData = new CollectionView(gridarray, )
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
        if (gridArray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
      }

    }
  }

  // Export Grid
  exportMicroscopicNotes(flex) {
    let excel = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate()
    let rows = flex.rows
    rows.find(e => {
      excel.push(e._data);
    });
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Microscopic Notes_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'MicroNotes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }

  removeGrid(data?: String) {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.showDelete = false;
    this.noDataFound = false;
    this.postDownload = false;
    if (data == 'back') {
      this.copyFromOrgFrm.patchValue({
        frmOrganization: '',
        frmUser: ''
      })
      this.getListOrg();
    }
    // this.copyFromOrgFrm.reset();
    this.resetFormErrors();
    //this.typeData = []
    //this.mappingSite = []
  }

  uploadMicroNotesExcelData() {
    this.uploadClicked = true;
    this.postUpload = false;
    this.gridScreen = false;
    this.showGrid = false;
    this.gridPage = false;
    this.gridWidth = 0
    this.action = 'Upload Microscopic Notes';
    // this.getTemplates()
    let queryVariable = { tablename: 'Preferences', identity: "false", nullable: "false" };
    let query = this.vitalHttpServices.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          let microFields = ['id', 'name', 'description', 'casetype']
          for (let item of microFields) {
            this.allFields[0][item] = null
            this.minimumFields[0][item] = null;
            this.allFieldUpdate[0][item] = null
          }
          for (let item of data.data.allFields) {
            this.allFields[0][item.Column] = null;
          }

          if (this.userFlag) {
            delete this.allFields[0]['userid']
          }
          else if (this.userSelected) {
            delete this.allFields[0]['userid']
            delete this.allFields[0]['casetype']
          }
          else {
            delete this.allFields[0]['casetype']
          }

          delete this.minimumFields[0]['userid']
          delete this.minimumFields[0]['id']
          delete this.minimumFields[0]['casetype']

          delete this.allFields[0]['preferencename']
          delete this.allFields[0]['id']
          delete this.allFields[0]["preferencevalue"]
          delete this.allFields[0]['createdby']
          delete this.allFields[0]["preferenceid"]
          delete this.allFields[0]['createddate']
          delete this.allFields[0]['modifiedby']
          delete this.allFields[0]['modifieddate']
          delete this.allFields[0]['preferencetype']
          delete this.allFields[0]['accountid']
          delete this.allFields[0]['ParentID']
          delete this.allFields[0]['AdminKey']
        }
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  downloadTemplate(allFieldsCheck) {
    let filename = ""
    let sampleallfields
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    // let name = this.userFlag ? this.templateData.cardIdentifier.toString() : this.templateData.secondarykeys.OrganizationId.toString()
    var ws
    if (allFieldsCheck == 'min') {
      filename = 'Microscopic Notes Upload_' + 'Min Fields_' + this.organizationId + '.xlsx';
      sampleallfields = true
      ws = XLSX.utils.json_to_sheet(this.minimumFields);
    }
    else if (allFieldsCheck == 'all') {
      filename = 'Microscopic Notes Upload_' + 'All Fields_' + this.organizationId + '.xlsx';
      sampleallfields = true
      ws = XLSX.utils.json_to_sheet(this.allFields);
    }
    else if (allFieldsCheck == 'update') {
      filename = 'Microscopic Notes update_' + 'All Fields_' + this.organizationId + '.xlsx';
      delete this.allFieldUpdate[0]['casetype']
      ws = XLSX.utils.json_to_sheet(this.allFieldUpdate);
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Microscopic notes');
    ws = sampleallfields ? XLSX.utils.json_to_sheet(this.sampleDataAll) : XLSX.utils.json_to_sheet(this.sampleDataUpdate)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Sample Data');
    XLSX.writeFile(wb, filename);
  }

  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'Preference')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  convertToJson(sheetname: string, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (!value) {
            value = ''
          }
          else if (value.toString().toLowerCase().match(/empty/i)) {
            value = null;
          }
          else if (copyfrmorg && key == 'preferenceid') {
            value = null;
          }
          if (key.toString().match(/isactive/i) || key.toString().match(/active/i)) {
            // key='IsActive'
            value = value ? value : value == '1' ? true : false;
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    }
    else {
      temp.reverse()
      temp.push("notes")
      this.sheetHeader = temp.filter(va => !va.toString().toLowerCase().match(/empty/i));
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);

    // this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    if (this.userFlag) {
      if (!this.selectedUser) {
        this.excelDataArray.find((d): any => {
          Object.assign(d, { slno: i, notes: '', tablename: 'preferences', userid: this.templateData.cardIdentifier });
          i++;
        });
      }
      else {
        this.excelDataArray.find((d): any => {
          Object.assign(d, { slno: i, notes: '', tablename: 'preferences', userid: this.selectedUser });
          i++;
        });
      }
    }
    else {
      if (this.userSelected) {
        this.excelDataArray.find((d): any => {
          Object.assign(d, { slno: i, notes: '', tablename: 'preferences', casetype: this.templateData.cardtype, userid: this.selectedUser.toString() });
          i++;
        });
      }
      else {
        if (copyfrmorg) {
          this.excelDataArray.find((d): any => {
            Object.assign(d, { slno: i, notes: '', tablename: 'preferences', casetype: this.templateData.cardtype, organizationid: this.templateData.secondarykeys.OrganizationId.toString(), userid: "" });
            i++;
          });
        }
        else {
          this.excelDataArray.find((d): any => {
            Object.assign(d, { slno: i, notes: '', tablename: 'preferences', casetype: this.templateData.cardtype, organizationid: this.templateData.secondarykeys.OrganizationId.toString() });
            i++;
          });
        }

      }
    }
    //   for (let j = 0; j < this.excelDataArray.length; j++) {
    //   for (let [key, value] of Object.entries(this.excelDataArray[j])) {
    //     if (key == 'name') {
    //       this.excelDataArray[j]["preferencename"]=this.excelDataArray[j]["name"];
    //       delete this.excelDataArray[j]["name"];
    //     }
    //     else if (key == 'description') {
    //       this.excelDataArray[j]["preferencevalue"]=this.excelDataArray[j]["description"];
    //       delete this.excelDataArray[j]["description"];
    //     }
    //     else if (key == 'id') {
    //       this.excelDataArray[j]["preferenceid"]=this.excelDataArray[j]["id"];
    //       delete this.excelDataArray[j]["id"];
    //     }
    //   }
    // }
    this.validateExcel(this.excelDataArray);
  }
  //#region Sending the data for validation

  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateMicroCodes(dataArray, this.deployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddExcelData(dataArray);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
    this.ngxService.stop();
  }
  //#endregion

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/already exists /i) || this.excelDataArray[i]['notes'].toString().match(/Mandatory field is missing/i)) || !this.excelDataArray[i]['notes'].toString().match(/valid/i)) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }

  //#region Function to upload  Data
  uploadMicroscopicData(copyData?: string) {
    let sendjson = [];
    let tempDataArray = [];
    let errorcount = 0;
    tempDataArray = this.excelDataArray;
    if (!copyData) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]["notes"] == "") {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          return;
        }

        // else if (this.excelDataArray[i]["notes"].toString().match(/already exists/i) ) {
        //   errorcount++
        // }
      }

    }

    if (errorcount > 0) {
      this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
      this.excelDataArray = tempDataArray;
      return
    }

    if ((this.showInvalidColumns)) {
      this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
      this.excelDataArray = tempDataArray;
      return
    }
    else {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        for (let [key, value] of Object.entries(this.excelDataArray[i])) {
          if (value == "null" || value == "" || value == null) {
            if (key.toString().match(/isactive/i)) {
              value = value == true || value.toString().toLowerCase() == 'true' ? 1 : 0
            }
          }
          else {
            this.excelDataArray[i][key] = value.toString();
          }
        }
        for (let i = 0; i < this.excelDataArray.length; i++) {
          this.excelDataArray[i]["createdby"] = sessionStorage.getItem("UserId") == null || sessionStorage.getItem("UserId") == '' ? -100 : sessionStorage.getItem("UserId")
          this.excelDataArray[i]["organizationid"] = sessionStorage.getItem("org_id");
          if (this.userSelected) {
            // setting userid for bulk upload which is not passing from excel
            this.excelDataArray[i]["userid"] = this.selectedUser ? this.selectedUser : this.templateData.cardIdentifier
          }
        }

      }
    }
    let selectedData = []
    for (let i = 0; i < this.selectedData.length; i++) {
      selectedData.push(this.selectedData[i]._data);
    }
    if (copyData) {
      this.selectedCopyData = selectedData;
      for (let i = 0; i < this.selectedCopyData.length; i++) {
        this.selectedCopyData[i]["copyflag"] = true;
      }
      this.excelDataArray = this.selectedCopyData
    }
    let dataArray = [];
    for (let i = 0; i < this.excelDataArray.length; i++) {
      for (let [key, value] of Object.entries(this.excelDataArray[i])) {
        if (key == 'name') {
          this.excelDataArray[i]["preferencename"] = this.excelDataArray[i]["name"];
          delete this.excelDataArray[i]["name"];
        }
        else if (key == 'description') {
          this.excelDataArray[i]["preferencevalue"] = this.excelDataArray[i]["description"];
          delete this.excelDataArray[i]["description"];
        }
        else if (key == 'id') {
          this.excelDataArray[i]["preferenceid"] = this.excelDataArray[i]["id"];
          delete this.excelDataArray[i]["id"];
        }
      }
    }
    dataArray = this.convertObjKeysToLower(this.excelDataArray);
    sendjson = dataArray.filter(va => va.notes.toString().toLowerCase() == 'valid')
    if (!copyData) {
      this.excelDataArray = tempDataArray;
    }
    if (copyData && sendjson.length <= 0) {
      this._snackbar.open('Please select valid rows', 'Close')
      return;
    }
    else {
      if (sendjson[0].preferenceid == null) {
        sendjson.length > 0 ? this.addMicroCodes(sendjson, true, '') : this._snackbar.open("Microscopic note(s) already exist!", "Close");
      }
      else {
        sendjson.length > 0 ? this.updateMicroCodes(sendjson, true, '') : this._snackbar.open("Microscopic note(s) already exist!", "Close");;
      }
    }
  }
  //#endregion

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      wjCore.toggleClass(e.cell, 'error-msg', value == 'Mandatory(s) field is missing!' || value == 'Microscopic Code does not exists' || value == 'Null values exist!' || value.match(/already exist/) || value.match(/not matching/) || value.match(/check the role/) || value.match(/selected User/) || value == 'Microscopic Code does not exists for this Casetype' || value == 'failure' || value == 'Configuration already exists');
      wjCore.toggleClass(e.cell, 'high-value', value == 'Valid' || value == 'Created Successfully' || value == 'Success' || value == 'Updated successfully');
      wjCore.toggleClass(e.cell, 'warn-value', value.match(/cannot be reverted/) || value == 'Ignored');
      //  }
    }
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }

  refreshGrid() {
    this.ngxService.start();
    this.showGrid = true;
    this.noDataFound = false;
    this.copyfromScreen = false;
    this.createScreen = false;
    this.showInvalidColumns = false;
    this.uploadClicked = false;
    this.microScopicfrm.reset();
    if (this.userFlag) {
      this.getMicroNotes(this.selectedUser.toString())
    }
    else {
      this.userSelected ? this.getMicroNotes(this.selectedUser) : this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData()
    }
    this.postUpload = false;
    this.postDownload = false;
    this.checkStatus = false;
    this.excelDataArray = [];
    this.ngxService.stop();
  }

  getMicroNotes(UserId) {
    let queryVariable
    if (this.userFlag) {
      this.tempCasetype = [];
      for (let ct of this.caseTypeList) {
        this.tempCasetype.push(ct.CaseType)
      }
      queryVariable = { userid: UserId.toString(), casetypes: this.tempCasetype.toString() };
    }
    else {
      queryVariable = { userid: UserId.toString(), casetypes: this.templateData.cardtype.toString() };
    }


    if (this.sourceApp === 'VitalDx' && !queryVariable.casetypes) {
      queryVariable.casetypes = this.caseTypeList.map((item) => item.CaseType).join(',')
    } else {
      queryVariable.casetypes = this.templateData.cardtype.toString();
    }

    this.ngxService.start();
    this.vitalHttpServices.GetMicroCodes(queryVariable, this.deployment).subscribe(
      (res) => {
        this.ngxService.stop();
        this.templateData.submenuData = res;
        this.showWijGrid()
      },
      error => {

        this.ngxService.stop();
        console.error(error);
      });
  }

  fnToggleFilter() {
    this.userlist.reset();
    this.showGrid = false;
    this.userSelection('');
  }

  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["tablename"];
      delete e._data["slno"];
      excel.push(e._data);
    });
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Microscopic Notes_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'MicroscopicNotes');
    XLSX.writeFile(wb, filename);
  }

  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
  }

  // copyFromOrg
  copyFromOrg() {
    this.showGrid = false;
    this.gridScreen = false
    this.action = "Copy Microscopic Notes"
    this.uploadClicked = false;
    this.createScreen = false;
    this.copyfromScreen = true;
    this.searchResult = [];
    this.destDeployment = sessionStorage.getItem('deploymentKey');
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.destDeployment,
      frmOrganization: '',
      frmUser: ''
    })
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.organizationid = '';
    this.orgName = null;
    this.searchInput = '';
  }

  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmDeplyment: depKey,
        frmOrganization: '',
        frmUser: ''
      })
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
    }
  }

  getListOrg() {
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let queryVariable = {};
    let query = this.vitalHttpServices.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          if (data.data.Organizations_list.length > 0) {
            this.organizationList = data.data.Organizations_list
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }

  fetchOrgSeries(value: string) {
    this.searchResult = []
    if (value === '') {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/

    if (this.copyFromOrgFrm.value.frmOrganization) {
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchResult = this.organizationList.filter(function (series) {
            if (series && series.organizationname != null) {
              return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
            }
          });

          this.searchResult = this.searchResult.splice(0, 25);
        }
      }
      else {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationid != null) {
            return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = 0;
      this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getListUsers(this.srcOrgid);
    }
  }

  async getListUsers(org_id?) {
    let orgid = !org_id ? sessionStorage.getItem('org_id') : org_id;
    let query = this.vitalHttpServices.GetQuery('getusersMicroCodes');
    let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let res: any = await this.vitalHttpServices.GetData(queryResult, dbName).toPromise();
    if (res) {
      if (!res.errors) {
        // this.userList = res.data.Card;
        this.userListArray = res.data.Card;
        this.userListArray.sort((a, b) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0)
        let obj = { "userid": "", "username": "Organization", "RoleName": "Org-Level" }
        this.userListArray.unshift(obj);

        this.ngxService.stop();
      }
    }
    else {
      this._snackbar.open('Something went wrong. Please try again!', 'Close');
      // this.getMicroNotes
    }
  }

  fetchUserSeries(value: string) {
    // this.removeGrid();
    this.searchuserlist = []
    if (value === '') {
      return (this.searchuserlist = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.userListArray.find(r => {
          if (r.username.toString().toLowerCase() === value.toLowerCase() && r.username.toLowerCase() != 'organization') {
            this.srcUserid = r.userid
            return r
          }
          // } else {
          //   this.srcUserid = ''
          // }
        })
        this.searchuserlist = this.userListArray.filter(function (series) {
          if (series && series.UserName != null) {
            return series.UserName.toString().toLowerCase().includes(value.toLowerCase()) && series.UserName.toLowerCase() != 'organization';
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.searchuserlist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = 0;
      this.srcUserid = data.UserId;
      this.srcUserName = data.UserName;
      this.searchUserInput = data.UserName;
      //this.getDataToCopy()
    }
  }

  getDataToCopy(data) {
    this.postUpload = false;
    if (typeof (this.copyFromOrgFrm.value.frmOrganization) == 'object') {
      let frmorgid = 0;
      frmorgid = this.copyFromOrgFrm.value.frmOrganization
      this.copyFromOrgFrm.value.frmOrganization = frmorgid[0].orgid
    }
    if (this.isP4Depl && !this.srcUserid) {
      this._snackbar.open("Please select User", "Close");
      return;
    }
    let queryVariable;
    if (this.userFlag) {
      queryVariable = { "accid": this.srcUserid.toString() };
    }
    else if (this.srcUserid) {
      queryVariable = { "accid": this.srcUserid.toString(), "casetype": this.templateData.cardtype.toString() };
    }
    else if (!this.srcUserid) {
      queryVariable = { "orgid": this.srcOrgid.toString(), "casetype": this.templateData.cardtype.toString() };
    }
    let query = this.srcUserid ? this.vitalHttpServices.GetQuery("getMicroCopyData") : this.vitalHttpServices.GetQuery("getOrgLvlMicroCodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyFromOrgFrm.value.frmDeplyment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let copyData = data.data.submenuData//.filter(va=> va.userid==this.srcUserid);
        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFound = true;
          return
        }
        this.convertToJson('', true, copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  filterUsers(value: string) {
    this.showOrgInDrop = true;
    this.userNamesList = this.UserList
    if (value) {
      if (!value.replace(/\s/g, '').length) {
        this.showOrgInDrop = true;
        return
      }
      else {
        this.userNamesList = this.userNamesList.filter(va => va.Username.toLowerCase().includes(value.toLowerCase()))
        this.showOrgInDrop = 'organization templates'.includes(value.toLowerCase()) ? true : false;
      }
    }
  }
  //#endregion

  // multi select user
  onCheckChange(item: any) {
    this.Username.push(item.Attribute_Name);
    this.allBilltoSelected = false;

  }

  onItemDeSelect(items: any) {
    const index: number = this.Username.indexOf(items.Attribute_Name);
    if (index !== -1) {
      this.Username.splice(index, 1);
      this.allBilltoSelected = false;
    }
  }

  onSelectAll(items: any) {
    this.Username = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.Username.push(items[itr].Attribute_Name);
    }
    this.allBilltoSelected = true;
  }

  onDeSelectAll() {
    this.Username = [];
    this.allBilltoSelected = false;
  }
  MigrateGLdata() {
    if (!this.hideMigratebtn) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Copy Group Level Microscopic Notes", message: "You have chosen to copy all Group level Microscopic Notes to exclusive User. This copy of Microscopic Notes can be only accessed by the user and will not be shared by other users. Do you want to proceed?", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result) {
            this.tempCasetype = [];
            for (let ct of this.caseTypeList) {
              this.tempCasetype.push(ct.CaseType)
            }
            let object = {
              orgid: sessionStorage.getItem('org_id'),
              userid: this.selectedUser,
              createdby: sessionStorage.getItem('UserId') ? sessionStorage.getItem('UserId') : -100,
              CaseType: this.tempCasetype.toString(),
              tablename: "MicroscopicCodes"
            }
            this.vitalHttpServices.migrateTemplates(object).subscribe(result => {
              this.ngxService.stop();
              if (!result.errors) {
                this.refreshGrid()
              }
            })
          }
        })
    }
  }
  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }
  openUserToggleDialog() {
    if (!this.hideManagemicrobtn) {
      this.getUserList()
      for (let i = 0; i < this.userDropdownList.length; i++) {
        if (this.userDropdownList[i].UserId) {
          this.userDropdownList[i].userid = this.userDropdownList[i].UserId;
          delete this.userDropdownList[i].UserId;
        }
        if (this.userDropdownList[i].Allowedit == 1) {
          this.userDropdownList[i].Allowedit = true
        } else {
          this.userDropdownList[i].Allowedit = false
        }
        if (this.userDropdownList[i].Allowuserlvl == 1) {
          this.userDropdownList[i].Allowuserlvl = true
          this.userDropdownList[i]["disabletoggle"] = true
        } else {
          this.userDropdownList[i].Allowuserlvl = false
          this.userDropdownList[i]["disabletoggle"] = false
        }
      }
      const dialogRef = this.dialog.open(UserToggleComponent, {
        disableClose: true,
        width: '80%',
        height: '470px',
        data: {
          title: 'Manage Microscopic Notes',
          obj: this.userDropdownList,
          context: "Microscopic Codes"
        },
      });
    }

  }
  openCreateScreen() {
    if (!this.isP4Depl && !this.allowUserlvl && ((this.userFlag || this.userSelected) && this.templateData.submenuData.length == 0)) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Create User Level Microscopic Notes", message: "You have chosen to create User level Microscopic Notes, any Notes created will be specific only to this user. Do you want to proceed? ", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result) {
            this.createMicroNotes()
          }
        });

    } else {
      this.createMicroNotes()
    }
  }
  migrateenabling(selectedid?) {
    let finduser
    if (!this.userFlag) {
      finduser = this.userDropdownList.findIndex(x => selectedid.UserId == x.UserId)
      if (this.userDropdownList[finduser].Allowuserlvl == 1 || this.userDropdownList[finduser].Allowuserlvl == true) {
        this.migrateenable = true
      } else {
        this.migrateenable = false
      }
    } else {
      finduser = this.userDropdownList.findIndex(x => selectedid.userid == x.UserId)
      if (this.userDropdownList[finduser].Allowuserlvl == 1 || this.userDropdownList[finduser].Allowuserlvl == true) {
        this.migrateenable = true
      } else {
        this.migrateenable = false
      }
    }
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  #editStarting
  newEditMethod(editObj: any) {
    this.editMicroCodes(editObj.rowData);
  }

  newDeleteMethod(deleteObj: any) {
    this.deleteMicroCodes(deleteObj.rowData)
  }

  recieveObjFromListing(object: any) {
    if (object.newData.Action === 'Create') {
      delete object.newData.Action
      delete object.newData.ModifiedBy
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.addMicroCodesTemplates(newObj, 'Create');
    }
    else {
      delete object.newData.Action
      object.newData['preferenceid'] = object.oldData['PreferenceId'];
      delete object.newData.ModifiedBy
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.updateMicroCodesTemplates(newObj, object, 'Edit')
    }
  }

  addMicroCodesTemplates(newObj, action) {
    this.ngxService.start();
    newObj.userid = !newObj.userid ? null : newObj.userid.join(',');

    //this.checkStatus = false;
    this.vitalHttpServices.bulkUploadmicroCodes([newObj], this.destDeployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.userSelected = undefined;
          this.showDelete = false;
          this.getUserList('saved');
          this.postDownload = true;
          this.postUpload = true;
          this.microScopicfrm.controls.frmCaseType.enable();
          this.microScopicfrm.controls.frmUsers.enable();
          if (result[0]['Notes'].match(/already exist/)) {
            this._snackbar.open(result[0]['Notes'], "Close")
            return;
          }
          this._snackbar.open('Microscopic notes created successfully', 'Close');
          if (action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('id', 'preferencename', result, [newObj], 'Create', this.templateData, this.auditableColumns);
          }
          if (this.userFlag) {
            this.getMicroNotes(this.templateData.cardIdentifier.toString())
          }
          else {
            this.tempselectedUser = null;
            this.userSelected ? this.getMicroNotes(this.selectedUser) : this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData();
          }
        }
        else {
          this._snackbar.open('Microscopic addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }

  updateMicroCodesTemplates(newObj, object, action) {
    this.ngxService.start();
    newObj.userid = !newObj.userid ? null : newObj.userid.join(',');

    this.vitalHttpServices.editMicroCodes([newObj], this.destDeployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.userSelected = undefined;
          this.showDelete = false;
          this.postDownload = true;
          this.postUpload = true;
          this._snackbar.open('Updated successfully', 'Close');
          if (action === 'Edit') {
            object.oldData['preferencename'] = object.oldData['Name'];
            delete object.oldData['Name'];
            object.oldData['preferencevalue'] = object.oldData['Description'];
            delete object.oldData['Description'];
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('PreferenceId', 'preferencename', [object.oldData], [newObj], action, this.templateData, this.auditableColumns);
          }
          if (this.userFlag) {
            this.getMicroNotes(this.templateData.cardIdentifier.toString())
          }
          else {
            this.userSelected ? this.getMicroNotes(this.selectedUser) : this.isP4Depl ? this.accountLevelData() : this.accountLevelSelected ? this.accountLevelData() : this.groupLevelData()
          }
        }
      }
      else {
        this._snackbar.open('Microscopic addition failed! Please try again.', 'Close');
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }

  deleteMicroCodes(data) {
    if (!this.hideUpdateBtn) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '360px',
        // data: {
        //   header: 'Alert',
        //   message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no"
        // },
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        this.ngxService.start();
        if (result) {
          let obj = {
            id: data.PreferenceId,
            preferencename: data.Name,
            preferencevalue: data.Description
          };
          this.vitalHttpServices.deleteMicroCodes(obj, this.destDeployment).subscribe((res) => {
            if (res.Success) {
              this._snackbar.open(res.Message, 'Success');
              this.gridScreen = true;
              // this.commonService.createActivityTracker('Deleted', data.Preference_Id, 'Microscopic Notes', 'Audit', {}, {}, data.Preference_Id + ' Deleted');
              this.commonService.auditDetails('id', data.Name, [obj], [], 'Delete', this.templateData, this.auditableColumns);
              this.refreshGrid();
              // this.commonService.setBooleanToTrue(true);
              // this.AddGridData();
            } else {
              this._snackbar.open(res.Message, 'Failed');
              this.gridScreen = true;
            }
          }, error => {
            console.error(error);
            this._snackbar.open("Something went wrong please try again!", 'Failed');
          });
        }
        this.ngxService.stop();
      });
    }
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  emitFilters($event: any) {

    console.log('this.selectedData', this.selectedData);
    this.migrateenable = false;
    let filtersObj = {
      All: 'All',
      Group: 'Group',
    }

    if (!$event.UserId) {
      $event['UserId'] = { object: {} };
      $event['UserId']['object'] = { UserName: 'Group', dropdownDisplayColumn: 'Group Level', RoleName: 'Group Level' }
    }

    this.selectedFilterData = { CaseType: $event.CaseType === 'All Casetypes' ? '' : $event.CaseType, userId: filtersObj[$event?.UserId?.object?.UserName] ? filtersObj[$event?.UserId?.object?.UserName] : $event?.UserId?.object?.UserId };

    this.templateData.cardtype = ($event.CaseType && $event.CaseType !== 'All Casetypes') ? $event.CaseType : '';
    if (filtersObj[$event?.UserId?.object?.UserName]) {
      this.userSelection(filtersObj[$event?.UserId?.object?.UserName]);
    } else {
      this.userSelection($event?.UserId?.object);
    }
  }

  roleChanged($event: any) {
    this.setUserRole($event?.event, $event?.user)
  }
}

