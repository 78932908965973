<div *ngIf="gridShow">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>Case Tags</strong>
                </h3>
            </div>
        </div>
    </div>
    <br>
    <div class="col-lg-12 p-0 m-md-1 m-lg-0">
        <!-- The main grid -->
        <wj-flex-grid #flex [headersVisibility]="'Column'" [frozenColumns]="1" [(itemsSource)]="gridData"
            [allowDragging]="'Both'" [isReadOnly]="true" (initialized)="initTooltipGrid(flex)">
            <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="111" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                    <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEdit(flex,$event)" [title]="'Edit'">
                        <em id="editButton" class="edit-template cursor p-0 fa fa-edit"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'groupname'" [allowSorting]="true" [header]="'Group Name'" [isRequired]="true"
                [width]="220" [minWidth]="220"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'tagname'" [header]="'Tag Name'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'groupcolor'" [header]="'Background'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'fontcolor'" [header]="'Font Color'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'casestatus'" [header]="'Case Status'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'sequenceorder'" [header]="'Sequence'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'isgroup'" [header]="'Is Group'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'casetype'" [header]="'Case Type'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'tagvalue'" [header]="'Tag Value'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'roles'" [header]="'Roles'" [isRequired]="true" [width]="200" [minWidth]="200"
                [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'isactive'" [header]="'Active'" [isRequired]="true" [width]="200"
                [minWidth]="200" [format]="'d'"></wj-flex-grid-column>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!uploadClicked" type="button" (click)="ExportExcel(flex)"
                [disabled]="exportBtn">Export</button>
            <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" type="button" (click)="loadAddEditScreen()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!uploadClicked" type="button" (click)="loadUploadScreen()"
                [disabled]="uploadBtn">Upload</button>
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!copyDataClicked" type="button" (click)="loadCopyScreen()"
                [disabled]="copyBtn" title="Copy from other entity">Copy</button>
        </div>
    </div>
</div>
<div class="col-sm-12" *ngIf="noData && gridwidth==0">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>Case Tags</strong>
                </h3>
            </div>
        </div>
    </div>
    <div class="row container create-btn align-center" *ngIf="gridwidth == 0">
        <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">
            <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" type="button" (click)="loadAddEditScreen()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="loadUploadScreen()"
                [disabled]="uploadBtn">Upload</button>
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!copyDataClicked" type="button" (click)="loadCopyScreen()"
                [disabled]="copyBtn" title="Copy from other entity">Copy</button>
        </div>
    </div>
</div>

<!--Copy from other entity-->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Copy Case Tags</strong>
        </h3>
    </div>
    <form [formGroup]="copyOrgTagsMethodForm" class="copy-collection">
        <div class="p-0 ml-0">
            <div class="col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering (selectionChange)="onChangeDeployment($event)" type="text"
                        matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label>Group <span class="error-msg">*</span></mat-label>
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput" #searchbar
                    (keyup)="fetchOrgSeries(copyOrgTagsMethodForm.value.frmOrganization)" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"  *ngIf="show.organizationid.toString().includes(searchInput.toString()) || show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                             title="{{ show.organizationname }} ({{show.organizationid}})"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.organizationname }}">
                                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>

                <!-- Roles!-->
                <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                    <mat-label>Role</mat-label>
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input type="text" matInput formControlName="frmRole" [(ngModel)]="searchUserInput" #searchuserbar
                        (keyup)="fetchRoleSeries(searchuserbar.value)" [matAutocomplete]="userauto" placeholder="Search here ..."
                        style="width: 95%;">
                    <mat-autocomplete #userauto="matAutocomplete">
                        <div *ngFor="let role of searchRoleList">
                            <mat-option class="mat-opt-align"
                                *ngIf="role.displayname.toString().toLowerCase().includes(searchUserInput.toString().toLowerCase())"
                                (onSelectionChange)="selectedCopyDataUser($event, role)" value="{{ role.displayname }}"
                                title="{{ role.displayname }}">
                                <span>{{ role.displayname }}</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="align-center dml-btn row d-flex mt-2" *ngIf="copyDataClicked" >
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid(true)">Back</button>
                <button mat-raised-button class="admin-btn-success" [disabled]="disableGetDataBtn() || postUpload"
                    (click)="getDataToCopy()">Get Data</button>
            </div>

            <div *ngIf="gridwidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                        [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'organizationid') && !(columnname == 'organizationtagid') && !(columnname == 'organizationcasetagvalueid') && !(columnname == 'groupid') &&
                             !(columnname == 'slno') && !(columnname == 'tablename') && !(columnname == 'notes') && !(columnname == 'tagvaluesequence')"
                            [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

            </div>
            <div *ngIf="!backBtn && !postUpload && gridwidth > 0" class="row align-center pl-3 mt-2 ml-5 align-center">
                <input type="checkbox" id="copyasinactive" [disabled]="selectedData.length==0" ng-init='checkStatus=false'
                    [checked]='checkStatus' ng-model='checkStatus' (change)='checkValue($event)' class="border-style">
                <label class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
            </div>
            <div class="col-sm-12 pl-0 mt-4">
                <div *ngIf="gridwidth > 0" class="m-1 p-0">
                    <!-- <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button> -->
                    <span style="float: right;">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload" (click)="refreshGrid(true)">Cancel</button>
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload" (click)="refreshGrid(true)">Finish</button>
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload" [disabled]="approveDisable()"
                            (click)="approveCopyOrgTags(true)">Approve</button>
                        <button mat-raised-button class="admin-btn-success" *ngIf="postUpload" (click)="ExportUpload(flexgrid)">Download
                            Result</button>
                    </span>
                </div>
            </div>

            <div class="container create-btn col-sm-12 align-center" *ngIf="gridwidth == 0">
                <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                    <div class="nodata-design">No Data Found</div>
                </div>
            </div>
        </div>
    </form>
</div>



<!--Upload-->
<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload {{templateData.menuURL.toString().trim()}}</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate('mandatoryFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid(true)">
            Back
        </button>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)"
            #grid [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>


            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
                [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0" (click)="refreshGrid(true)">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid(true)">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="approveDisable()"
                    (click)="approveOrgTags()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="refreshGrid(true)">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportUpload(grid)">Download
                    Result</button>
            </div>
        </div>


    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>


<!-- Create & Edit Screen -->
<div *ngIf="addEditClicked" class="col-sm-12">
    <div class="text-wrap">
        <div class="modal-header admin-model-header mb-2 mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong *ngIf="!editEnabled">Create Case Tag</strong>
                <strong *ngIf="editEnabled">Edit Case Tag</strong>
            </h3>
        </div>
        <form [formGroup]="caseTagForm">
            <div class="row col-sm-12 mt-2">
                <mat-form-field appearance="outline" class="w-100 col-sm-3 form-space">
                    <mat-label><strong><span class="text-danger">*</span> Tag Type</strong></mat-label>
                    <mat-select [disabled]="editEnabled" disableOptionCentering formControlName="frmTagType" (change) = "resetForm(caseTagForm?.value.frmTagType)">
                        <mat-option class="option-align" value="">--Select--</mat-option>
                        <mat-option class="option-align" value="Group">Group</mat-option>
                        <mat-option class="option-align" value="Tag">Tag</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="caseTagForm?.value.frmTagType != 'Group'" appearance="outline" class="w-100 col-sm-3 form-space">
                    <mat-label><strong><span class="text-danger">*</span> Group </strong></mat-label>
                    <input type="text" title="{{caseTagForm?.value.frmTagGroupName}}" matInput maxlength="100"
                        [matAutocomplete]="entityType" formControlName="frmTagGroupName" #inputAutoCompleteGroup
                        #trigger8="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
                    <div matSuffix style="display:flex">
                        <button (click)="clear($event, '')" *ngIf="caseTagForm?.value.frmTagGroupName!==null && caseTagForm?.value.frmTagGroupName!==''"
                            aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">clear</mat-icon>
                        </button>
                        <button (click)="openOrClosePanel($event, trigger8)" aria-label="Clear" mat-icon-button type="button">
                            <mat-icon style="font-size: 120% !important">{{arrowIconSubject.getValue()}}</mat-icon>
                        </button>
                    </div>
                    <mat-autocomplete #entityType="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                        (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                        [displayWith]="displayFn">
                        <mat-option class="mat-opt-align" title=" {{ entity.groupname }}({{ entity.groupid }})" *ngFor="let entity of filterGroupName(caseTagForm.value.frmTagGroupName)"
                            disableOptionCentering value="{{ entity.groupname }}" (onSelectionChange)="getGroupName($event,entity)">
                            {{ entity.groupname }}({{ entity.groupid }})
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100 col-sm-3 form-space">
                    <mat-label><strong><span class="text-danger">*</span> Tag Name</strong></mat-label>
                    <input type="text" title="{{caseTagForm?.value.frmTagName}}" matInput maxlength="100"
                        formControlName="frmTagName" style="width: 95%;">
                </mat-form-field>

                <span class="row col-sm-12">

                    <mat-form-field appearance="outline" class="w-100 col-sm-3 form-field-height">
                        <mat-label><strong> Tag Background Color</strong></mat-label>
                        <div class="row col-sm-12 d-flex m-0 p-0">
                            <input class="col-sm-8 p-0" type="text" title="{{caseTagForm?.value.frmGroupColor}}"
                                matInput maxlength="100" formControlName="frmGroupColor">
                            <input class="col-sm-2 p-0 ml-2" matInput [ngxMatColorPicker]="picker" type="color"
                                formControlName="frmGroupColor" (input)="setColorValue(caseTagForm.value.frmGroupColor,'frmGroupColor')">
                            <img aria-hidden="true" alt="help" type="color" src="/assets/icons/colorpicker.svg" style="width: 10%;" />
                        </div>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 col-sm-3 form-field-height">
                        <mat-label><strong> Tag Font Color</strong></mat-label>
                        <div class="row col-sm-12 d-flex m-0 p-0">
                            <input class="col-sm-8 p-0" type="text" title="{{caseTagForm?.value.frmFontColor}}"
                                matInput maxlength="100" formControlName="frmFontColor">
                            <input class="col-sm-2 p-0 ml-2" matInput formControlName="frmFontColor"
                                [ngxMatColorPicker]="picker" type="color" (input)="setColorValue(caseTagForm.value.frmFontColor,'frmFontColor')">
                            <img aria-hidden="true" alt="help" type="color" src="/assets/icons/colorpicker.svg" style="width: 10%;" />
                        </div>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 col-sm-3 form-field-height">
                        <mat-label><strong> Tag Sequence</strong></mat-label>
                        <input type="text" title="{{caseTagForm?.value.frmTagSequence}}" matInput maxlength="100"
                            formControlName="frmTagSequence" style="width: 95%;">
                    </mat-form-field>

                    <div class="w-100 col-sm-3 check-box-wrap mb-3">
                        <mat-checkbox class="example-margin" formControlName="frmIsActive" [color]="task.color">
                            <strong>Active</strong>
                        </mat-checkbox>
                        <mat-checkbox class="example-margin" formControlName="frmAllowBulkEdit" [color]="task.color">
                            <strong>Allow Bulk Edit</strong>
                        </mat-checkbox>
                    </div>
                </span>

            </div>

            <div *ngIf="caseTagForm?.value.frmTagType != 'Group'" class="divider col-sm-12 d-flex form-space"><b>Tag Value</b>
            </div>
            <div *ngIf="caseTagForm?.value.frmTagType != 'Group'" class="col-sm-12 add-wrap p-0">
                <ng-container *ngFor="let formG of caseTagForm.controls.frmTagValueGroup.controls; let index = index;">
                    <div [formGroup]='formG'>
                        <mat-form-field appearance="outline" class="w-100 col-sm-2">
                            <mat-label><strong>Sequence </strong></mat-label>
                            <input type="text" matInput maxlength="100" [errorStateMatcher]="matcher" formControlName="frmTagValueSequence">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 col-sm-2">
                            <mat-label><strong>Tag Value</strong></mat-label>
                            <input type="text" matInput maxlength="100" [errorStateMatcher]="matcher" formControlName="frmTagValue">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 col-sm-3">
                            <mat-label><strong> Background Color</strong></mat-label>
                            <div class="row col-sm-12 d-flex m-0 p-0">
                                <input class="col-sm-8 p-0" type="text" [errorStateMatcher]="matcher" title="{{caseTagForm?.value.frmTagValueColor}}"
                                    matInput maxlength="100" formControlName="frmTagValueColor" [value]="formG.value.frmTagValueColor">
                                <input class="col-sm-2 p-0 ml-2" matInput [ngxMatColorPicker]="picker" type="color"
                                    formControlName="frmTagValueColor">
                                <img aria-hidden="true" alt="help" type="color" src="/assets/icons/colorpicker.svg"
                                    style="width: 10%;" />
                            </div>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 col-sm-3">
                            <mat-label><strong> Font Color</strong></mat-label>
                            <div class="row col-sm-12 d-flex m-0 p-0">
                                <input class="col-sm-8 p-0" type="text" [errorStateMatcher]="matcher" title="{{caseTagForm?.value.frmTagValueFontColor}}"
                                    matInput maxlength="100" formControlName="frmTagValueFontColor" [value]="formG.value.frmTagValueFontColor">
                                <input class="col-sm-2 p-0 ml-2" matInput [ngxMatColorPicker]="picker" type="color"
                                    formControlName="frmTagValueFontColor">
                                <img aria-hidden="true" alt="help" type="color" src="/assets/icons/colorpicker.svg"
                                    style="width: 10%;" />
                            </div>
                        </mat-form-field>

                        <mat-checkbox title="Is Default" class="example-margin align-center col-sm-1" style="margin-top: 14px !important;"
                            [color]="task.color" formControlName="frmTagValueIsDefault">
                            <strong>Default</strong>
                        </mat-checkbox>

                        <!-- add & delete button -->
                        <span class="col-sm-1 ml-2">
                            <img class="icon-align mt-2" src="/assets/images/plus_icon.svg" alt="plus" (click)="addFormControl()">
                            <img class="icon-align ml-2 mt-2 " *ngIf="this.caseTagForm.controls.frmTagValueGroup.length > 1"
                                src="/assets/images/minus_icon.svg" alt="minus" (click)="removeFormControl(index)">
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="divider col-sm-12 d-flex form-space"><b>Criteria for tag to appear</b></div>
            <div class="col-sm-12 mt-2 p-0 m-0 select-wrap">
                <div class="d-flex">
                    <mat-form-field appearance="outline" class="col-sm-3">
                        <mat-label><strong><span class="text-danger">*</span> Location</strong></mat-label>
                        <input type="text" title="{{caseTagForm?.value.frmAccountName}}" matInput maxlength="100"
                            #inputAutoComplete [matAutocomplete]="account" formControlName="frmAccountName" #trigger="matAutocompleteTrigger"
                            [errorStateMatcher]="matcher" style="width: 95%;">
                        <div matSuffix style="display:flex">
                            <button (click)="clear($event,'acc')" *ngIf="caseTagForm?.value.frmAccountName!==null && caseTagForm?.value.frmAccountName!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">clear</mat-icon>
                            </button>
                            <button (click)="openOrClosePanel($event, trigger)" aria-label="Clear" mat-icon-button type="button">
                                <mat-icon style="font-size: 120% !important">{{arrowIconSubject2.getValue()}}</mat-icon>
                            </button>
                        </div>
                        <mat-autocomplete #account="matAutocomplete" (closed)="arrowIconSubject2.next('arrow_drop_down')"
                            (opened)="arrowIconSubject2.next('arrow_drop_up')" (optionSelected)="arrowIconSubject2.next('arrow_drop_down')"
                            [displayWith]="displayFn">
                            <mat-option class="mat-opt-align"  title="{{ entity.accountname }}({{ entity.accountid }})"
                                *ngFor="let entity of filterAccountName(caseTagForm.value.frmAccountName)"
                                disableOptionCentering value="{{ entity.accountname }}" (onSelectionChange)="getAccountName(entity,$event)">
                                {{ entity.accountname }}({{ entity.accountid }})
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div *ngIf="caseTagForm?.value.frmTagType != 'Group'" class="col-sm-10 d-flex">
                        <label class="mt-3 d-flex"><strong><span class="text-danger">*</span> Roles</strong></label>
                        <ng-multiselect-dropdown class="col-sm-10 text-xs mt-2" *ngIf="caseTagForm?.value.frmTagType != 'Group'"
                            [placeholder]="'Roles'" formControlName="frmRoles" [settings]="rolesDropdownSettings"
                            [data]="RoleList" (onSelect)="onCheckChange($event,'roles')" (onDeSelect)="onItemDeSelect($event,'roles')"
                            (onSelectAll)="onSelectAll($event,'roles')" (onDeSelectAll)="onDeSelectAll('roles')">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="row col-sm-12">
                    <label class="col-sm-3 mt-3"><strong><span class="text-danger">*</span> Casetype</strong></label>
                    <ng-multiselect-dropdown class="col-sm-9 text-xs mt-2" title="{{frmCasetype}}" [placeholder]="'Casetype'"
                         formControlName="frmCasetype" [settings]="casetypeDropdownSettings" [data]="CasetypeList"
                        (onSelect)="onCheckChange($event, 'casetype')" (onDeSelect)="onItemDeSelect($event, 'casetype')"
                        (onSelectAll)="onSelectAll($event, 'casetype')" (onDeSelectAll)="onDeSelectAll('casetype')">
                    </ng-multiselect-dropdown>
                </div>

                <div class="row col-sm-12">
                    <label class="col-sm-3 mt-3"><strong><span class="text-danger">*</span> Case Status</strong></label>
                    <ng-multiselect-dropdown class="col-sm-9 text-xs mt-2" title="{{frmCaseStatus}}" [placeholder]="'Case Status'"
                         formControlName="frmCaseStatus" [settings]="caseStatusDropdownSettings" [data]="CaseStatusList"
                        (onSelect)="onCheckChange($event, 'casestatus')" (onDeSelect)="onItemDeSelect($event, 'casestatus')"
                        (onSelectAll)="onSelectAll($event, 'casestatus')" (onDeSelectAll)="onDeSelectAll('casestatus')">
                    </ng-multiselect-dropdown>
                </div>

                <div class="row col-sm-12 pb-2">
                    <label class="col-sm-3 mt-3"><strong>Not Editable Case Status</strong></label>
                    <ng-multiselect-dropdown class="col-sm-9 text-xs mt-2" title="{{frmNonEditableCaseStatus}}"
                        [placeholder]="'Not Editable Case Status'" formControlName="frmNonEditableCaseStatus"
                        [settings]="caseStatusDropdownSettings" [data]="CaseNStatusList" (onSelect)="onCheckChange($event,'ncasestatus')"
                        (onDeSelect)="onItemDeSelect($event,'ncasestatus')" (onSelectAll)="onSelectAll($event,'ncasestatus')"
                        (onDeSelectAll)="onDeSelectAll('ncasestatus')">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div class="row mt-4">
        <div class="col-sm-3">
            <button mat-raised-button class="admin-btn-success" (click)="refreshGrid(true)">Back</button>
        </div>
        <div class="col-sm-9 button-wrap">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="saveCloseCasetag(false,caseTagForm.value)">Save
                & Close</button>
            <button mat-raised-button *ngIf="!editEnabled" class="admin-btn-success" (click)="saveCloseCasetag(true,caseTagForm.value)">Save
                & Create</button>
        </div>
    </div>
</div>
