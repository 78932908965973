import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';
// import { MatTooltip } from '@angular/material/tooltip';
@Directive({
  selector: '[titleOverflow]',
})
export class TiltleDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2, 
    // private tooltip: MatTooltip
    ) {}

  ngAfterViewInit(): void {
    this.checkOverflow();
  }
  @HostListener('mouseover', ['$event'])
  private checkOverflow() {
    const element = this.el.nativeElement;
    // console.log(element)
    if (element.scrollWidth > element.clientWidth) {
    //     this.tooltip.message = 'Text is overflowing';
    //   this.tooltip.show();
        this.renderer.setAttribute(element, 'title', element.textContent);
     // this.renderer.setAttribute(element, 'matTooltipClass', 'custom-tooltip-class');

      
    } else {
      this.renderer.removeAttribute(element, 'title');
    //   this.tooltip.hide();
    }
  }
}
