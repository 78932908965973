import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
declare var $: any;
export interface Task {
  color: ThemePalette;
}
export class User {
  constructor(public name: string) { }
}
export interface IKeyValue {
  accountname: string;
  thumbnailUrl: string;
}

interface ICountry {
  item_id: number;
  item_text: string;
  image: string;
  isDisabled?: boolean;
}

declare var window: any;
@Component({
  selector: 'app-accounts-association',
  templateUrl: './accounts-association.component.html',
  styleUrls: ['./accounts-association.component.scss'],
})


export class AccountsAssociationComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  task: Task = {
    color: 'primary',
  };

  selectedOption: string;
  cities: Array<string> = [];
  countries: Array<ICountry> = [];
  selectedItem: Array<string> = [];
  dropdownSettings2: any = {};



  UserList = [];
  myControl = new FormControl();
  @Input() public subMenudata: any;
  @Input() public cardIdentifier: number = -1;
  @Input() public cardtype: string = '';
  @Input() labels: any;
  @Input() rowData: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('currLoc') currLoc: ElementRef;
  @ViewChild('assocgrid') assocGrid: WjFlexGrid;


  columns = [
    //Outer grid
    // new ColDef('services', 'Services'),
    // new ColDef('AllowReportViewing', 'Allow report viewing'),
    // new ColDef('isPcEnabled', 'PC Enabled'),
    // new ColDef('AccountName', 'Account Name'),
    new ColDef('associationid', 'AssociationID'),
    new ColDef('AsssociatedAccountName', 'Associated Account Name'),
    new ColDef('AsssociatedOrgName', 'Associated Group Name'),
    new ColDef('AssociationType', 'Association Type'),
  ];

  serviceType: string;
  selectType: string;
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  public formGroup: FormGroup;
  @Input() templateData: any;
  @Input() templateChangedTime: string;
  AssociatedAccountidControl = new FormControl();
  @ViewChild('flexgrid') flexgrid: FlexGrid;

  cvPaging: CollectionView;
  grid: CollectionView;
  data: any;
  gridData: CollectionView;
  gridData2: CollectionView;
  public export: boolean = true;
  public button: any[];
  id: any;
  public SelectedMenuModel;
  public GenericModalClass = 'modal';
  selector: Selector = null;
  selectedItems: any = [];
  idsArray: any = [];
  organizations: any;
  orgid: string;
  menuType: any;
  associationModel: boolean;
  public tableGrid = true;
  public showAuditModel = true;
  public accountid = sessionStorage.getItem('Locationid');
  //public accountid = this.templateData.cardIdentifier;
  associationTypeList: any;
  ofAccountDetails: any = [];
  epgAccountDetails: any = [];
  servicesList: any;
  AccountName: any;
  organizationlist: boolean = true;
  accountsList: any = [];
  AssociatedOrgid: any;
  associationTable: boolean;
  AssociationDetails: any = [];
  addeddata = [];
  showPaginationMainGrid: boolean;
  AsssociatedAccountName: any;
  AsssociatedOrgName: any;
  errorFlag: number = 0;
  ExistData: boolean;

  protected _onDestroy = new Subject<void>();

  /** control for the selected bank */

  /** control for the MatSelect filter keyword */

  singleSelect: any;
  accounts: any;
  accountsOfSelectedOrg: any;
  checkedTickets: any = [];
  SubMenuCardModel: any;
  changeToTrue: boolean;
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };

  AccountsAdded: any[];
  selectedLevel: string;
  OrgorAccDrop: boolean;
  //UpdateAssociationModel: boolean;
  association: any = [];
  usersubscript: Subscription;
  associateOrg: FormControl;
  // AssociatedAccountid:FormControl;
  AssociatedOrgaDetails: any;
  items2: any;
  AssociatedAccountsDetails: any;
  AssociatedOFAccountsDetails: any;
  AssociatedLabAccountsDetails: any;
  filteredLabAccounts: any;
  filterAccount: any;
  tempAccId: any;
  temporgid: any;
  // accountType: any;
  labOrganizations: any;
  epgOrgDetails: any;
  AddMultiselect: boolean;
  UpdateMultiselect: boolean;
  cancelButton: boolean;
  btnCreate: boolean;
  btnRemove: boolean = false;
  //#region Steup New Send In
  NewSendIn: boolean = false;
  setupNewEntitiesBtnClick: boolean = false;
  ContextValue: any;
  //#endregion
  //#region Setup new facility
  btnCreateFacility: boolean = false;
  setupNewFacilityBtnClick: boolean = false;

  showSetupNewFacilityBtn: boolean = false;
  //bulkAccountUpload:boolean=false;
  labLocationList: any = [];
  serviceAndCourierUsersList: any = [];
  SetupInformation: any;
  StateList: any;
  // Timezones: any;
  // UsernameWebFormat: any;
  // UsernameReportFormat: any;
  ClaimOptions: any;
  Service: any;
  shareData: any = {};
  uploadScreen: boolean = false;
  workBook: any;
  sheetsToSelect: any[];
  excelDataArray: any[];
  sheetHeader: any[];
  showInvalidColumns: boolean;
  invalidColumns: any;
  showDelete: boolean;
  gridWidth: number;
  gridDisplay: boolean;
  uploadClicked: boolean;
  postUpload: boolean;
  showGrid: boolean;
  gridPage: boolean;
  allFields = [{}];
  mandatoryFields = [{}];
  postDownload: boolean;
  locationType: string;
  destDeployment: string;
  btnUpload: boolean;
  currentAssociations: boolean = false;
  serviceDataMap: any = [];
  searchAccount: string;
  accountList: any;
  userOptions: any;
  editMode: boolean = false;
  editData: any = [];
  alertPopUp: boolean = false;
  btnEdit: boolean;
  locationsList = []

  SampleDataAllfields = [{
    organizationid: '1-9999',
    organizationname: 'Group Name'
    , accountname: 'Location Name'
    , npi: '1234567890'
    , address1: 'Door No, Floor'
    , address2: 'Landmark'
    , street: 'Street name'
    , city: 'city name'
    , state: 'state name'
    , country: 'country name'
    , zip: '12345-1234'
    , phone: '+1 9876543210'
    , fax: '1234567890'
    , CLIA: '07A1234567'
    , EIN: '987654321'
    , primarycontactname: 'Primary contact name'
    , primarycontactphone: '+1 9876543210'
    , primarycontactemail: 'email@starmarkit.com'
    , Accessioninstruction: '<p>Sample</p>'
    , Diagnosisinstruction: '<p>Sample</p>'
    , medicarenumber: 'MEDI-1234'
    , statelicensenumber: '07A1234567'
    , weburl: 'http://www.vitalaxis.com'
    , supportid: 'VA04'
    , accounttype: 'Location type'
    , ishq: '1'
    , displayname: 'Display name'
    , generateediclaim: '1'
    , facilitytypecode: 'A'
    , providertaxonomycode: 'provider taxonomy code'
    , billingeffectivedate: '2010-11-01 00:00:00.000'
    , billingcontactname: 'Billing Contact Name'
    , billingcontactphone: '+1 9876543210 '
    , billingcontactfax: '1234567890'
    , billingcontactemail: 'email@starmarkit.com'
    , SlideTemplate: 'Slide Template Name'
    , TCRenderingProvider: '87654321'
    , ParentAccountName: 'Parent Location Name'
    , PayToAddress1: 'Door No, Floor'
    , PayToAddress2: 'Landmark'
    , PayToCity: 'City Name'
    , PayToState: 'State Name'
    , PayToZip: '12345-1234'
    , RegAccountName: ' Reg Location Name'
    , accountcode: '123456'
    , organizationcode: '654321'
    , ExternalSystem: 'External Syatem Name'
    , ReportData: 'test'
    , FaxEnable: '1'
    , CoverPageTemplate: 'Cover Page Template Name'
    , CLPOL: 'CLPOL name'
    , GeoCoordinates: ' Geo Coordinates'
    , Region: 'US'
    , FaxCoverPageTemplate: 'Fax Cover Page Template Name'
    , ReportCoverPageTemplate: 'Report Cover Page Template'
    , CollectionDateFormat: 'YYYY-MM-DD'
    , ReceivedDateFormat: 'YYYY-MM-DD'
    , PackageDateFormat: 'YYYY-MM-DD'
    , ETADateFormat: 'YYYY-MM-DD'
    , IsSharedSurgicenter: '1'
    , TaxonomyCode: 'A1234567'
    , CommercialPIN: 'Commercial PIN'
    , AccountNotes: 'Enter Notes'
    , SalesOrganization: 'Sales Group Name'
    , IsFirstCase: '1'
    , IsPrintEnabledAtFac: '1'
    , IsPrintEnabledAtLab: '1'
    , isAccountSetupPending: '1'
    , EnableSendOutCheckBox: '1'
    , ShowPhysiciansAtOrgLevel: '1'
    , CopyInstructionsatLabGroup: '1'
    , IPLocation: 'IP Location'
    , PatientPortalPhysicianID: '9876543210'
    , timezone: 'Pacific Standard Time'
    , Email: 'email@starmarkit.com'
    , AppointmentTiming: 'Appointment Timing'
    , AdminKey: '6A1B4AD0-251F-48F8-BBB1-ECEE2ACDD2D2'
    ,UniqueID: 'L0000000307'
    ,tags: ''

  }]

  SampleDataMinfields = [{
    organizationid: '1-9999',
    organizationname: 'Group Name'
    , accountname: 'Location Name'
    , npi: '1234567890'
    , address1: 'Door No'
    , address2: 'Landmark'
    , street: 'Street name'
    , city: 'city name'
    , state: 'state name'
    , country: 'country name'
    , zip: '12345-1234'
    , phone: '+1 9876543210'
    , fax: '1234567890'
    , CLIA: '07A1234567'
    , EIN: '987654321'
    , primarycontactname: 'Primary contact name'
    , primarycontactphone: '+1 9876543210'
    , primarycontactemail: 'email@starmarkit.com'
    , Accessioninstruction: '<p>Sample</p>'
    , Diagnosisinstruction: '<p>Sample</p>'
  }];
  CreateLive: boolean;
  liveUserCreate: boolean;
  tempAssociatedOrgaDetails: any;
  organizationsList: unknown[];

  //#endregion

  /** list of banks filtered by search keyword */
  //public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<o[]>(1);
  constructor(
    private injectorServicehHgrid: InjectorService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public commonService: CommonService,
    public _snackbar: MatSnackBar,
    public DataShare: DataShareService,
    private vitalservice: VitalHttpServices,
    public ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private vitalMenuComponent : VitalMenuComponent
  ) {
    super(injectorServicehHgrid);
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalservice,
      DataShare
    );
  }

  recipientData = this._fb.group({
    Accountname: '',
    AssociationType: '',
    AsssociatedAccountName: '',
    AssociatedAccountid: '',
    Service: '',
    IsViewReport: '',
    isPcEnabled: false,
    AllowReportViewing: false,
    Selecttype: '',
    accFilterCtrl: '',
    associateOrg: new FormControl(),
    item: [],
  });

  GetButtondetails() {
    this.GetButtonAccess(this.vitalservice.SubmenuAction);
  }

  //#region to get the Associated Location in multiselect dropdown
  public setForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(this.association, Validators.required),
    });
    // this.loadContent = true;
  }
  get getformDetails() {
    return this.formGroup.controls;
  }
  //#endregion
  public GetQuery(keyword: string) {
    // let query: string = '';
    let mainQueryIndex: number = this.vitalservice.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.vitalservice.mainQueryList[mainQueryIndex].Query;

  }

  //#region to get the account to an group based on the association type
  onOrganizationChange(newValue, item) {

    newValue = this.temporgid;
      this.accountsOfSelectedOrg = cloneDeep(this.locationsList.filter(
        (va) => va.organizationid == newValue && va.accountname != null
      ));

    this.accountsOfSelectedOrg.forEach(va => va.accountname += ' (' + va.accountid + ')')
    this.AccountsAdded = [];
    this.AddMultiselect = true;
    this.UpdateMultiselect = false;
    if (newValue) {
      this.recipientData.patchValue({
        AssociatedAccountid: ''
      }
      )
    }
  }
  //#endregion
  changingOrganizationAndAccount(value) {
    this.locationsList=[];
    this.organizationsList=[];
    this.recipientData.patchValue({ Selecttype: value })
    if (value == 'Accounts') {
      this.recipientData.patchValue({
        AssociatedAccountid: '',
      });
    }
    else {
      this.recipientData.patchValue({
        AssociatedAccountid: '',
        associateOrg: '',
      });
    }
  }
  //#region code for refreshing the form after changing the asscoiation type
   selectAssociation(newValue, item) {
    // this.updateButton=false;
    this.ngxService.start();
    // this.getpatchdata();
    // this.addbutton=true;
    this.organizationsList = [];
    this.locationsList = []
    this.recipientData.patchValue({
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      AssociationType: item.value,
      Accountname: this.AccountName[0].accountname,
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
    });
    if (newValue != null) {
      this.associationModel = true;
      this.AddMultiselect = true;
      this.UpdateMultiselect = false;
    }

    this.ngxService.stop();
  }
  //#endregion



  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  editAssociations(editData, Message?, newAssociation?) {
    this.ngxService.start();
    this.vitalservice.editAssociations(editData).subscribe((res: any) => {
      if (res.Success) {
        this.currentAssociations = false;
        this._snackbar.open(Message ? Message : res.Message, 'Success');
        this.commonService.setBooleanToTrue(true);
        this.addeddata = [];
        this.AssociationDetails = [];
        if (!newAssociation) {
          this.AssociationDetails = [];
          this.tableGrid = true;
          this.associationModel = false;
        }
        else {
          this.addNewAssociation()
        }
        //this.UpdateAssociationModel = false;
      } else {
        this._snackbar.open(res.Message, 'Failed');
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      this.ngxService.stop();
    });
  }

  //#region the patching the form with empty values  for new Association
  addNewAssociation() {
    this.recipientData.patchValue({
      AssociationType: '',
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
    })
    this.AccountName = this.epgAccountDetails.filter((va) => va.accountid == this.accountid);
    this.associationModel = true;
    this.addeddata = [];
    this.UpdateMultiselect = false;
    this.AddMultiselect = true;
    this.tableGrid = false;
    this.cancelButton = true;
    // this.UpdateAssociationModel = false;
    this.AccountsAdded = [];
    this.recipientData.patchValue({
      AssociationType: '',
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      Accountname: this.AccountName[0].accountname,
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
      Selecttype: '',
    })
    this.fetchOrgAcc();
  }

  //#endregion
  fetchOrgAcc() {
    let query = this.GetQuery('ListAccountNameandId');
    let queryVariable = {
      orgid: sessionStorage.getItem("org_id"),
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        // data = data.filter(va => va.IsOF == (sessionStorage.getItem("context") == 'Facility' ? true : false));
        this.accountsList = {
          Organization: sessionStorage.getItem("Org_Name"),
          checked: true,
          Accounts: []
        }
        let primaryData = []
        for (let i = 0; i < data.length; i++) {
          if (this.templateData.cardIdentifier == data[i].ID) {
            primaryData.push({ Name: data[i].Location, ID: data[i].ID, checked: true, disabled: false });
            delete data[i];
          }
        }

        data.forEach(va =>
          primaryData.push({ Name: va.Location, ID: va.ID, checked: false, disabled: false })
        )
        this.accountsList.Accounts = primaryData;
        this.accountList = cloneDeep(this.accountsList.Accounts)
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this.epgAccountDetails = [];
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  checkAccount(value, checked) {
    // let count = false
    // let filteredArray = this.accountsList.Account.filter(va=> va.checked)
    // count = filteredArray.length == 1 ? filteredArray.some(va=> va.ID == this.templateData.cardIdentifier)  : false
    // count ? this.addNewAssociation() : null;
    value.checked = checked;
    if (this.accountsList.Accounts.every(va => !va.checked)) {
      this.currLoc["checked"] = false;
    }
  }

  // checkAllAccounts(organizationChecked, checked) {
  //   organizationChecked = checked;
  //   if (organizationChecked) {

  //   }
  //   else

  //   }
  // }

  checkedAllAccounts(accountsList) {
    return accountsList.Accounts.every(va => va.checked)
  }

  notAllUnchecked(accountsList) {
    return !accountsList.Accounts.every(va => !va.checked)
  }


  checkAll(accountsList, checked) {
    accountsList.checked = checked
    accountsList.Accounts.forEach(va => va.checked = checked)
  }

  fetchCurrentLocationAssociations(checked) {
    let primaryAccountData = this.accountsList.Accounts.find(va => va.ID == this.templateData.cardIdentifier)
    if (!checked) {
      // event.srcElement.checked = false;
      // this.addAssociationList([]);
      primaryAccountData.checked = false;
      primaryAccountData.disabled = false;
      this.addeddata = [];
      this.cancelButton = true;
      this.AssociationDetails = []
      this.associationTable = false
      this.currentAssociations = false;
    }
    else {
      primaryAccountData.checked = false;
      primaryAccountData.disabled = true;
      this.getpatchdata()
      this.cancelButton = false;
      this.currentAssociations = true;
      this.getCurrentAssociations(sessionStorage.getItem("AccountID"));
    }
  }

  getCurrentAssociations(accountID) {
    let query = this.GetQuery('getAllAssociations');
    let queryVariable = {
      accid: accountID,
      associd : null
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        data = data.filter(va => va.AssociationType != 'My Organization')
        if (data.length > 0) {
          this.addAssociationToList(data)
        }
        else {
          this.currLoc["checked"] = false;
          this._snackbar.open("No associations present for current location!", "Close");
        }
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }


  addAssociationToList(data): any {
    this.associationTable = true;
    this.addeddata = data;
    this.serviceDataMap = [];
    this.servicesList.forEach(element => {
      this.serviceDataMap.push(element.item);
    });
    this.gridData = new CollectionView(data, {
      pageSize: 10,
    });
    if (this.addeddata.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
  }

  //#region  function for patching the initial  value
  getpatchdata() {
    this.recipientData.patchValue({
      AssociationType: '',
      associateOrg: '',
      Service: 'None',
      AssociatedAccountid: '',
      Accountname: this.AccountName[0].accountname,
      AllowReportViewing: false,
      isPcEnabled: false,
      accFilterCtrl: '',
      Selecttype: '',
    });
  }
  //#endregion

  //#region Adding to the grid
  addAssociationList(associatedData) {
    this.ngxService.start();
    this.cancelButton = false;
    // this.addeddata = [];
    associatedData.AssociatedAccountid = this.tempAccId;
    this.servicesList.forEach(element => {
      this.serviceDataMap.push(element.item);
    });
    associatedData.associateOrg = this.temporgid;
    // associatedData.Service=='None'? associatedData.Service=null:associatedData.Service
    if (associatedData.Selecttype == 'Organizations') {
      if (this.AccountsAdded.length !== 0) {
        for (let i = 0; i < this.AccountsAdded.length; i++) {
          associatedData.AssociatedAccountid = this.AccountsAdded[i];
          //this.validatedetails(associatedData);
          if (this.validateDetails(associatedData)) {
            this.ExistData = false;
            this.templateData.submenuData;

            let existDataInList = [];
            if (this.addeddata && this.addeddata.length > 0) {
              existDataInList = this.addeddata.filter(
                (va) =>
                  va.associatedAccountID == associatedData.AssociatedAccountid
                  && va.AssociationType == associatedData.AssociationType
              );
              this.AssociationDetails = [...this.addeddata];
            }
            //this.addeddata.push(DatarepititionInList);

            if (existDataInList) {
              if (existDataInList.length >= 1) {
                this.ExistData = true;
              }
            }
            var filtereddata = this.epgAccountDetails.filter(
              (va) => va.accountid == associatedData.AssociatedAccountid
            );

            if (filtereddata.length >= 1) {
              this.AssociatedOrgid = filtereddata[0].organizationid;
            }

            this.AsssociatedAccountName = this.epgAccountDetails.filter(
              (va) => va.accountid == associatedData.AssociatedAccountid
            );

            this.AsssociatedOrgName = this.AssociatedOrgaDetails.filter(
              (va) => va.organizationid == this.AssociatedOrgid
            );
            //
            // OForganizationID
            var obj = {
              accountid: sessionStorage.getItem('Locationid'),
              associatedAccountID: associatedData.AssociatedAccountid.toString().split('(')[0].trim(),
              AsssociatedAccountName: this.AsssociatedAccountName[0].accountname,
              organizationID: this.orgid,
              services: associatedData.Service,
              AccountName: this.AccountName[0].accountname,
              AssociationType: associatedData.AssociationType,
              AsssociatedOrgName: this.AsssociatedOrgName[0].organizationname,
              AssociatedOrgid: this.AssociatedOrgid,
              isPcEnabled: (associatedData.isPcEnabled == true) ? false : true,
              AllowReportViewing: associatedData.AllowReportViewing,
              Selecttype: associatedData.Selecttype,
              userid: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
            };

            // this.AssociationDetails(obj);
            if (this.ExistData != true) {
              this.addeddata = [obj, ...this.AssociationDetails];
              this.gridData = new CollectionView(this.addeddata, {
                pageSize: 10,
              });
              this.AssociationDetails.push(obj);
              if (i >= this.AccountsAdded.length - 1) {
                this.getpatchdata();
              }
              // this.UpdateAssociationModel = false;
              this.associationModel = true;
              this.UpdateMultiselect = false;
              this.AddMultiselect = true;
              this.associationTable = true;

              if (this.addeddata.length > 10) {
                this.showPaginationMainGrid = true;
              } else {
                this.showPaginationMainGrid = false;
              }
            } else {
              obj = null;
              this._snackbar.open('"' + this.AsssociatedAccountName[0].accountname + '"' + ' is already selected for association with the type: ' + '"' + existDataInList[0].AssociationType + '"', 'Close');
            }
          } else {
            this._snackbar.open('Insert valid data', 'Failed');
          }
        }
      }
      else {
        this._snackbar.open('Select atleast one location', 'Failed');
      }
      this.AccountsAdded = [];
    }
    else {
      this.ExistData = false;
      this.templateData.submenuData;
      if (this.validateDetails(associatedData)) {
        let existDataInList = [];
        if (this.addeddata && this.addeddata.length > 0) {
          existDataInList = this.addeddata.filter(
            (va) =>
              va.associatedAccountID == associatedData.AssociatedAccountid
              && va.AssociationType == associatedData.AssociationType
          );
          this.AssociationDetails = [...this.addeddata];
        }

        if (existDataInList) {
          if (existDataInList.length >= 1) {
            this.ExistData = true;
          }
        }
        var filtereddata = this.epgAccountDetails.filter(
          (va) => va.accountid == associatedData.AssociatedAccountid
        );

        if (filtereddata.length >= 1) {
          this.AssociatedOrgid = filtereddata[0].organizationid;
        }

        this.AsssociatedAccountName = this.epgAccountDetails.filter(
          (va) => va.accountid == associatedData.AssociatedAccountid
        );

        this.AsssociatedOrgName = this.AssociatedOrgaDetails.filter(
          (va) => va.organizationid == this.AssociatedOrgid
        );
        //
        // OForganizationID
        var obj = {
          accountid: sessionStorage.getItem('Locationid'),
          associatedAccountID: associatedData.AssociatedAccountid,
          AsssociatedAccountName: this.AsssociatedAccountName[0].accountname,
          organizationID: this.orgid,
          services: associatedData.Service,
          AccountName: this.AccountName[0].accountname,
          AssociationType: associatedData.AssociationType,
          AsssociatedOrgName: this.AsssociatedOrgName[0].organizationname,
          AssociatedOrgid: this.AssociatedOrgid,
          isPcEnabled: (associatedData.isPcEnabled == true) ? false : true,
          AllowReportViewing: associatedData.AllowReportViewing,
          Selecttype: associatedData.Selecttype,
          userid: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100',
        };

        // this.AssociationDetails(obj);
        if (this.ExistData != true) {
          this.addeddata = [obj, ...this.AssociationDetails];
          this.gridData = new CollectionView(this.addeddata, { pageSize: 10 });
          this.AssociationDetails.push(obj);
          this.getpatchdata();
          this.associationModel = true;
          this.UpdateMultiselect = false;
          this.AddMultiselect = true;
          this.associationTable = true;
          if (this.addeddata.length > 10) {
            this.showPaginationMainGrid = true;
          } else {
            this.showPaginationMainGrid = false;
          }
        } else {
          obj = null;
          this._snackbar.open('"' + this.AsssociatedAccountName[0].accountname + '"' + ' is already selected for association with the type: ' + '"' + existDataInList[0].AssociationType + '"', 'Close');
        }
      } else {
        this._snackbar.open('Insert valid data', 'Failed');
      }
    }
    this.ngxService.stop();
  }

  //#region calling the Api to Associate
  AddAssociation(data, newAssociation?) {
    // this.ngxService.start();
    let associatedData = []
    const view = data.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    data.beginUpdate()
    let rows = data.rows
    rows.forEach(va => associatedData.push(va._data))
    view.pageSize = oldPgSize;
    data.endUpdate()
    let editableData = []
    if (!this.accountsList.Accounts.some(va => va.checked)) {
      this._snackbar.open("Select at least one location!", "Close")
      return
    }
    let finalData = this.getFinalData(associatedData, [])
    // if (associatedData.some(va => va.associationid)) {
    //   for (let i = 0; i < this.accountsList.Accounts.length; i++) {
    //     if (this.accountsList.Accounts[i].checked)
    //       associatedData.forEach(va => { if (va.accountid == this.accountsList.Accounts[i].ID) va.Action = 'Edit' })
    //   }
    editableData = finalData.filter(va => va.Action == 'Edit')
    associatedData = finalData.filter(va => va.Action != 'Edit')

    associatedData.forEach(va => {
      if (va.AssociationType.toString().match(/my organization/i)) {
        va.associatedAccountID = va.accountid
      }
    })

    // associatedData = editableData.filter(va => va.Action != "Edit")
    this.ngxService.start();
    if (associatedData.length > 0) {
      this.vitalservice.associateAccounts(associatedData).subscribe((res: any) => {
        if (res.Success) {
          this.currentAssociations = false;
          this.ngxService.stop();
          this._snackbar.open(res.Message, 'Success');
          this.commonService.setBooleanToTrue(true);
          this.addeddata = [];
          this.AssociationDetails = [];

          //this.UpdateAssociationModel = false;
          if (editableData.length > 0) {
            this.editAssociations(editableData, res.Message, newAssociation)
          }
          else {
            if (!newAssociation) {
              this.AssociationDetails = [];
              this.tableGrid = true;
              this.associationModel = false;
            }
            else {
              this.addNewAssociation()
            }
          }
        } else {
          this._snackbar.open(res.Message, 'Failed');
        }
      });
    }
    else if (editableData.length > 0) {
      this.editAssociations(editableData)
    }
  }
  getFinalData(associatedData, finalData): any {
    for (let i = 0; i < this.accountsList.Accounts.length; i++) {
      if (this.accountsList.Accounts[i].checked) {
        for (let j = 0; j < associatedData.length; j++) {
          // let object: any = {}
          // object = associatedData[j]
          let { accountid: _, ...rest } = associatedData[j];
          rest.accountid = this.accountsList.Accounts[i].ID
          rest.userid = rest.userid ? rest.userid : sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
          finalData.push(rest);
        }
      }
    }
    finalData.forEach(va => {
      if (va.accountid == this.templateData.cardIdentifier && va.associationid) {
        va.Action = "Edit"
      }
    })
    return finalData;
  }
  //#endregion
  //#region  Funtion for back button to show the initial grid
  backAssociation() {
    if (this.addeddata && this.addeddata.length > 0) {
      this.alertPopUp = true
    }
    else {
      this.ngxService.start();
      this.tableGrid = true;
      this.getpatchdata();
      // this.UpdateAssociationModel = false;
      this.associationModel = false;
      this.searchAccount = '';
      this.addeddata = [];
      this.currentAssociations = false;
      this.gridData = null;
      this.AssociationDetails = [];
      this.recipientData.reset();
      this.ngxService.stop();
    }
  }

  editAssociatingTableRow(row) {
    this.ngxService.start();
    this.association = [];
    this.association.push({
      accountname: row.dataItem.AsssociatedAccountName,
      accountid: row.dataItem.associatedAccountID,
    });
    // this.selectedLevel = [];

    this.setForm();
    this.AccountsAdded = [];
    this.AccountsAdded.push(row.dataItem.associatedAccountID);
    this.selectedLevel = row.dataItem.AsssociatedAccountName + ' ' + row.dataItem.associatedAccountID;
    this.recipientData.patchValue({
      // id: row.dataItem.id,
      AssociationType: row.dataItem.AssociationType,
      Service: row.dataItem.services,
      AsssociatedAccountName: row.dataItem.AsssociatedAccountName,
      AssociatedAccountid: row.dataItem.AsssociatedAccountName,
      // AssociatedAccountid: tempSeletion,
      Accountname: this.AccountName[0].accountname,
      AllowReportViewing: row.dataItem.AllowReportViewing,
      isPcEnabled: (row.dataItem.isPcEnabled == true) ? false : true,
      Selecttype: row.dataItem.Selecttype,
      associateOrg: row.dataItem.AsssociatedOrgName,
    });
    this.delEditableRow(row);
    //this.UpdateAssociationModel = true;
    this.associationModel = true;
    this.UpdateMultiselect = true;
    this.AddMultiselect = false;
    this.ngxService.stop();
  }
  //#endregion
  delEditableRow(row) {
    this.ngxService.start();
    this.addeddata.splice(
      this.addeddata.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.AssociationDetails.splice(
      this.AssociationDetails.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.gridData = new CollectionView(this.addeddata, { pageSize: 10 });
    if (this.addeddata.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    this.ngxService.stop();
  }
  //#region  deleting the row of the grid
  deleteRowFromGrid(grid, row) {
    this.ngxService.start();
    this.addeddata.splice(
      this.addeddata.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.AssociationDetails.splice(
      this.AssociationDetails.findIndex(
        (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
      ),
      1
    );
    this.gridData = new CollectionView(this.addeddata, { pageSize: 10 });
    if (this.addeddata.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    // this.updateButton=false;
    // this.addbutton=true;
    // this.UpdateAssociationModel = false;
    this.associationModel = true;
    if (this.recipientData.controls.AssociationType.value != '') {
      this.UpdateMultiselect = false;
      this.AddMultiselect = true;
    } else {
      this.UpdateMultiselect = true;
      this.AddMultiselect = false;
    }

    //this.getpatchdata();
    this.ngxService.stop();
    if (row.dataItem.associationid && this.accountsList.Accounts.some(va => va.ID == this.templateData.cardIdentifier && va.checked)) {
      this.deleteAssociation(row.dataItem)
    }
    else {
      this._snackbar.open('Row deleted successfully!', "Close")
    }
  }

  deleteAssociation(rowData): any {
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '250px',
      data: { header: "Delete Association", message: "Clicking on yes will delete the association for the current location.", alert: "Do you wish to continue?", continue: "Yes", cancel: "no" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.ngxService.start();
        this.vitalservice.deleteAssociation(rowData.associationid, sessionStorage.getItem("DeploymentKey")).subscribe(data => {
          this.ngxService.stop();
          this._snackbar.open(data.Message, "Close")
        }, error => {
          console.error(error)
          this.ngxService.stop();
        });
      }
    })
  }
  //#endregion

  //#region Deassociating the accounts using the id
  deAssociateAccounts() {
    if (!this.btnRemove) {
      if (this.idsArray && this.idsArray.length >= 1) {
        let dialogRef = this.dialog.open(ConfirmComponent, {
          disableClose: true,
          // width: '250px',
          data: {
            header: 'De-Association',
            message: '',
            alert: 'Are you sure to De-Associate the location?',
            continue: 'yes',
            cancel: 'no',
          },
        });
        return dialogRef.afterClosed().toPromise().then((result) => {
          this.ngxService.start();
          if (result) {
            let obj = {
              id: this.idsArray,
            };
            this.vitalservice.deAssociateAccounts(obj).subscribe((res) => {
              if (res.Success) {
                this._snackbar.open(res.Message, 'Success');
                this.commonService.setBooleanToTrue(true);
                this.tableGrid = true;
                this.associationModel = false;
                this.idsArray = [];
                // this.commonService.setBooleanToTrue(true);
                // this.AddGridData();
              } else {
                this._snackbar.open(res.Message, 'Failed');
                this.tableGrid = true;
              }
            }, error => {
              console.error(error);
              this._snackbar.open("Something went wrong please try again!", 'Failed');
            });
          }
          this.ngxService.stop();
        });
      } else {
        this._snackbar.open('Please select an Location', 'Close');
      }
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    // this.button = this.button['MenuItems'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.export = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Create':
          this.btnCreate = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Remove':
          this.btnRemove = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'SetupNewFacility':
          this.btnCreateFacility = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Upload':
          this.btnUpload = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Edit':
          this.btnEdit = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "CreateLive":
          this.CreateLive = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
      }
    }
    if (this.commonService.isLiveAccount) {
      if (this.CreateLive) {
        this.liveUserCreate = true;
      }
      else {
        this.liveUserCreate = false;
      }
    }
    else {
      this.liveUserCreate = true;
    }
  }
  initializeGrid(flexgrid) {
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });

    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems.push(va);
        this.idsArray = [];
        for (
          let i = 0;
          i < this.selectedItems[this.selectedItems.length - 1].length;
          i++
        ) {
          this.idsArray.push(
            this.selectedItems[this.selectedItems.length - 1][i]._data.ID
          );
        }
      },
    });

    flexgrid.formatItem.addHandler((s: any, e: wjcGrid.FormatItemEventArgs) => {
      // center-align column headers
      if (e.panel == s.columnHeaders) {
        e.cell.innerHTML =
          '<div class="v-center">' + e.cell.innerHTML + '</div>';
      }

      // custom rendering for "Diff" columns
      if (e.panel == s.cells) {
        let col = s.columns[e.col];
        let item;
        let html = '';
        if (e.row > 0 && col.binding == 'ICD_Codes') {
          item = e.cell.textContent;
          let item1 = e.cell.textContent.split(';')[0];
          let item2 = e.cell.textContent.split(';')[1];
          if (item2 === 'Yes') {
            html =
              `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> ` +
              `<em class="fa fa-info-circle text-primary pull-right text-md" title="ICD codes configured for Sub-site, Site location"    style="cursor:pointer;" aria-hidden="true"  ></em>`;
          } else {
            html =
              `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> `;
          }
          e.cell.innerHTML = html;
        }
      }
    });
  }

  ngOnInit() {
    this.setupNewFacilityBtnClick = false;
    this.setupNewEntitiesBtnClick = false;
    this.showSetupNewFacilityBtn = false;
    this.locationType = sessionStorage.getItem('context')
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    if (sessionStorage.getItem('contextdata').toLowerCase() == 'lab') {
      this.showSetupNewFacilityBtn = true;
    }
    this.GetButtondetails();
    this.commonService.getBooleanValue().subscribe((x) => {
      this.changeToTrue = x;
    });
    if (this.templateData.GroupData.OrganizationId) {
      this.orgid = this.templateData.GroupData.OrganizationId;
    }
    else {
      this.orgid = sessionStorage.getItem('org_id');
    }
    this.getAssociationTypesDropdown();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'accountid',
      textField: 'accountname',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 150,
      itemsShowLimit: 44,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }

  filter(name: string): User[] {
    return this.userOptions.filter(option =>
      option.name.toLowerCase().inludes(name.toString().toLowerCase().split('(')[0].trim()));
  }

  displayFn(user: User): string {
    return user ? user.name : '';
  }

  updateSelectedOption(event: any) {
    this.selectedOption = event.option.selected;
  }

  //#endregion
  //#region getting the Location details
  getAccountName() {
    let query = this.GetQuery('accountDetails');
    let queryVariable = {
      context: 'AssociatedAccounts',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        this.epgAccountDetails = Resdata.data.submenuData;
      }
      this.ngxService.stop();
    }), (error) => {
      this.ngxService.stop();
      this.epgAccountDetails = [];
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }
  //#endregion

  getAssociatedAccount(value) {
    if (value.source) {
      let tempAccName = value.source.value.split('(')[0];
      this.tempAccId = value.source.value.split(')')[0].split('(')[1];
      if (this.tempAccId != 'null') {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          AssociatedAccountid: value.source.value,
        });
      }
      else {
        this.tempAccId = ''
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          AssociatedAccountid: '',
        });
      }
    }
    else {
      this.tempAccId = value;
      // this.recipientData.patchValue({
      //   // AssociatedAccountid:value.option.value
      //   AssociatedAccountid: value,
      // });
    }
  }

  getOrganizationName(value) {
    if (value.source.selected) {
      let tempAccName = value.source.value.split('(')[0];
      this.temporgid = value.source.value.split('(')[1].split(')')[0];
      if (this.temporgid != 'null') {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          associateOrg: value.source.value
        });
      }
      else {
        this.recipientData.patchValue({
          // AssociatedAccountid:value.option.value
          associateOrg: ''
        });
      }
    }
  }
  //#region Dropdowns like Services and Association types
  getAssociationTypesDropdown() {
    // let deploymentkey = sessionStorage.getItem('deploymentKey');
    sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      //keyword: 'AssociationTypes',
      context: 'AssociatedAccounts',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.usersubscript = this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
        this.associationTypeList = data.AssociationTypes;
        if (sessionStorage.getItem('context') == 'Facility') {
          this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Ordering Facilities')
        }
        else if (sessionStorage.getItem('context') == 'Laboratory') {
          this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Laboratories')
        }
        else if (sessionStorage.getItem('context') == 'Send Out') {
          this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Send-out Laboratories')
        }
        else if (sessionStorage.getItem('context') == 'Send In') {
          this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Send In Lab')
        }
        else if (sessionStorage.getItem('context') == 'EPG') {
          this.associationTypeList = this.associationTypeList.filter(va => va.item != 'Patologists')
        }


        this.servicesList = data.Services;
        this.usersubscript.unsubscribe();
        sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
        this.getAccountName();
      }
    }, (error) => {
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
      this.ngxService.stop();
      console.error(error);
    });

  }
  //#endregion

  checkForDisabled() {
    return !this.accountsList.Accounts.some(va => va.ID != this.templateData.cardIdentifier && va.checked)
    // return disabled
  }

  checkNoAccountSelected() {
    return this.accountsList.Accounts.every(va => !va.checked)
  }

  //#region  for showing the grid
  AddGridData() {
    let gridData = [];
    this.columnDefs.reverse();
    for (let i = 0; i < this.templateData.submenuData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
        if ((key == 'Created_By' || key == 'Created_Date' || key == 'Modified_Date' || key == 'Modified_By') && !value) {
          value = 'Not Specified'
        }
        primary[key] = value;
      }
      gridData.push(primary);
    }

    this.gridData2 = new CollectionView(gridData, {
      groupDescriptions: [Object.keys(this.templateData.submenuData[0])[0]]
    });


    this.gridwidth = 170 * (this.columnDefs.length - 1);
    if (this.gridwidth > 1300) this.gridwidth = 1300;
    if (this.gridwidth != 0) {
      this.gridwidth = this.getpercentage(this.gridwidth);
    }
  }
  //#endregion
  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width - 150;
    let per = (pixel / screenWidth) * 100;
    if (per < 80) {
      per = per + 5;
    }
    return per;
  }


  //#region  validation the field
  validateDetails(associationDetails) {
    if (!associationDetails.AssociatedAccountid || associationDetails.AssociatedAccountid == '') {
      return false;
    } else if (
      !associationDetails.AssociationType || associationDetails.AssociationType == '') {
      return false;
    }
    return true;
  }
  //#endregion
  ExportExcel(data) {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Associated Locations_';
    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '');
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    let array = [];
    for (let itr = 0; itr < data.length; itr++) {
      array.push(data[itr]);
    }
    filename = filename + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName + orgid;
    }
    const view = data.collectionView;
    let oldPgSize = view.pageSize;
    data.beginUpdate();
    view.pageSize = 0;
    let excel = [];
    data.rows.find(e => {
      if (!e._data._gd) {
      delete e._data["_gd"]
        delete e._data["_name"]
        delete e._data["_level"]
        delete e._data["_isBottomLevel"]
        delete e._data["_groups"]
        delete e._data["_items"]
        delete e._data["_path"]
      excel.push(e._data);
      }
    });
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Associated Locations' );
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    data.endUpdate();
  }


  //#region for multislect dropdown operations
  onCheckChange(item: any) {
    this.AccountsAdded.push(item.accountid);
  }
  onSelectAll(items: any) {
    this.AccountsAdded = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.AccountsAdded.push(items[itr].accountid);
    }
  }
  onItemDeSelect(items: any) {
    const index: number = this.AccountsAdded.indexOf(items.accountid);
    if (index !== -1) {
      this.AccountsAdded.splice(index, 1);
    }
  }
  public onDeSelectAll(items: any) {
    this.AccountsAdded = [];
  }

  //#region setup new facility
  //when setup new customer button is clicked this function is triggered
  CreateNewFacility() {
    if(this.commonService.isPol){
      this._snackbar.open("For a POL group, facilities can be created within the group under the locations menu.","Close")
      this.vitalMenuComponent.changeComponent('Location');
      return;
    }
    if (!this.btnCreateFacility) {
      this.editMode = false;
      this.ContextValue = 'Ordering Facility'
      this.getLabOrgLocations();
    }
  }
  CreateNewEpg() {
    if (!this.btnCreateFacility) {
      this.editMode = false;
      this.NewSendIn = false;
      this.ContextValue = 'EPG'
      this.getLabOrgLocations();
    }
  }

  CreteNewSendOut() {
    if (!this.btnCreateFacility) {
      this.editMode = false;
      this.NewSendIn = true;
      this.ContextValue = 'Send-Out'
      this.getLabOrgLocations();
    }
  }

  CreateNewSendIn() {
    if (!this.btnCreateFacility) {
      this.editMode = false;
      this.NewSendIn = true;
      this.ContextValue = 'Send-In'
      this.getLabOrgLocations();
    }
  }
  //function to fetch all the locations for the current lab's group
  getLabOrgLocations() {
    // if(this.vitalservice.deploymentKey.toString().match(/thx/i)){
    //   this._snackbar.open('This feature is temporarily disabled.','Close');
    //   return;
    // }
    this.labLocationList = [];
    let query = this.GetQuery('GetLocationLab');
    let queryVariable = {
      id: sessionStorage.getItem('org_id').toString().trim()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        if (Resdata && Resdata.data.Card.length > 0) {
          this.labLocationList = Resdata.data.Card;
        }
      }
      this.getServiceAndCourierUsers();
    }), (error) => {
      this.labLocationList = [];
      console.error(error);
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  //function to fetch Service And Courier Users
  getServiceAndCourierUsers() {
    this.serviceAndCourierUsersList = [];
    let query = this.GetQuery('GetNewOfUserList');
    let queryVariable = {
      id: sessionStorage.getItem('AccountID').toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        if (Resdata && Resdata.data.Card.length > 0) {
          this.serviceAndCourierUsersList = Resdata.data.Card;
        }
      }
      this.GetUserIdInfo();
    }), (error) => {
      this.serviceAndCourierUsersList = [];
      console.error(error);
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  //function to fetch states list, services list, claim options
  getDropdownList() {
    this.SetupInformation = [];
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = { keyword: 'getDropDownValues' };
    let query = this.SubMenuCardModel.GetQuery('CategoryDropDown');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data && data.data.submenuData && data.data.submenuData.length > 0) {
          this.SetupInformation = data.data.submenuData[0];
          if (this.SetupInformation.Items_JSON) {
            let dropdowndata = JSON.parse(this.SetupInformation.Items_JSON);
            this.StateList = dropdowndata.States ?? [];
            this.StateList.forEach(e => {
              e['itemValue'] = e.item.substring(e.item.lastIndexOf('(') + 1, e.item.lastIndexOf(')'))
            });
            // this.Timezones = dropdowndata.TimeZone;
            // this.UsernameWebFormat = dropdowndata.UserNameDisplayFormat;
            // this.UsernameReportFormat = dropdowndata.UserNameReportFormat;
            this.ClaimOptions = dropdowndata.ClaimOptions ?? [];
            this.Service = dropdowndata.Service ?? [];
          }
          else {
            this.StateList = [];
            this.ClaimOptions = [];
            this.Service = [];
          }
        }
        else {
          this.Service = [];
          this.ClaimOptions = [];
          this.StateList = [];
        }
      }
      else {
        this.Service = [];
        this.ClaimOptions = [];
        this.StateList = [];
      }
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);

      //mandatory data to be shared to child component
      if (this.labLocationList.length > 0 && this.ClaimOptions.length > 0) {
        // && this.StateList.length > 0 && this.Service.length > 0 &&
        // this.serviceAndCourierUsersList.length > 0  && sessionStorage.getItem('Userid') != ''
        this.shareData = {
          labLocationList: this.labLocationList,
          StateList: this.StateList,
          Service: this.Service,
          ClaimOptions: this.ClaimOptions,
          serviceAndCourierUsersList: this.serviceAndCourierUsersList,
          CreateLiveUser: this.liveUserCreate
          // Timezones: this.Timezones,
          // UsernameWebFormat: this.UsernameWebFormat,
          // UsernameReportFormat: this.UsernameReportFormat
        }
        if (this.editMode) {
          if (this.ContextValue == 'Ordering Facility' || this.ContextValue == 'EPG') {
            this.setupNewFacilityBtnClick = true;
          }
          else {
            this.setupNewEntitiesBtnClick = true;
          }
          return;
        }
        if (this.NewSendIn) {
          this.setupNewEntitiesBtnClick = true;
          this.setupNewFacilityBtnClick = false;
        }
        else {
          this.setupNewFacilityBtnClick = true;
          this.setupNewEntitiesBtnClick = false;
        }
      }
      else {
        if (!(this.labLocationList.length > 0)) {
          this._snackbar.open('An error occurred while fetching Locations. Contact Support Team !', 'Failed');

          if (this.NewSendIn) {
            this.setupNewEntitiesBtnClick = false;
            this.setupNewFacilityBtnClick = false;
          }
          else {
            this.setupNewFacilityBtnClick = false;
            this.setupNewEntitiesBtnClick = false;
          }
        }
        if (!(this.ClaimOptions.length > 0)) {
          this._snackbar.open('An error occurred while fetching Claim Options. Contact Support Team !', 'Failed');
          if (this.NewSendIn) {
            this.setupNewEntitiesBtnClick = false;
            this.setupNewFacilityBtnClick = false;
          }
          else {
            this.setupNewFacilityBtnClick = false;
            this.setupNewEntitiesBtnClick = false;
          }
        }
      }
    }, error => {
      console.error(error);
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
      this.StateList = [];
      this.Service = [];
      this.ClaimOptions = [];
      this.shareData = {
        labLocationList: this.labLocationList,
        StateList: this.StateList,
        Service: this.Service,
        ClaimOptions: this.ClaimOptions,
        serviceAndCourierUsersList: this.serviceAndCourierUsersList,
        CreateLiveUser: this.liveUserCreate
      }
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      if (this.NewSendIn) {
        this.setupNewEntitiesBtnClick = false;
        this.setupNewFacilityBtnClick = false;
      }
      else {
        this.setupNewFacilityBtnClick = false;
        this.setupNewEntitiesBtnClick = false;
      }
    });
  }

  //function to fetch user id for the logged in user
  GetUserIdInfo() {
    let query = this.vitalservice.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem("UserEmail") },
    };
    this.vitalservice.GetData(queryRequest).subscribe(
      (data) => {
        if (!data.errors && (data && data.data.submenuData.length > 0)) {
          let userid = data.data.submenuData[0].userid && data.data.submenuData[0].userid != "" ? data.data.submenuData[0].userid : "-100";
          sessionStorage.setItem('Userid', userid);
        }
        else {
          sessionStorage.setItem('Userid', '-100');
        }
        setTimeout(() => {
          this.getDropdownList();
        });
      }, (error) => {
        console.error(error);
        sessionStorage.setItem('Userid', '');
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });
  }

  closeSetupNewFacilityAndRefresh() {
    this.NewSendIn = false;
    this.commonService.setBooleanToTrue(true);
    this.tableGrid = true;
    this.ngxService.stop();
  }
  //#endregion
  //#region for bulk uploading the excel data
  uploadOFAccountData() {
    if (this.btnUpload) {
      this._snackbar.open('User is not Authorized', 'Close');
      return;
    }
    this.uploadScreen = true;
    this.tableGrid = false;
    this.uploadClicked = true;
    this.postUpload = false;
    this.showGrid = false;
    this.gridPage = false;
    this.gridWidth = 0
    let queryVariable = { tablename: 'Accounts', identity: "false", nullable: "false" };
    let query = this.vitalservice.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          let siteFormatFields = ['organizationid','organizationname', 'accountname', 'npi', 'address1', 'address2', 'street', 'city', 'state', 'country', 'zip', 'phone', 'fax', 'CLIA', 'EIN', 'primarycontactname', 'primarycontactphone', 'primarycontactemail', 'Accessioninstruction', 'Diagnosisinstruction']
          for (let item of siteFormatFields) {
            this.allFields[0][item] = null
            this.mandatoryFields[0][item] = null
          }
          for (let item of data.data.allFields) {
            this.allFields[0][item.Column] = null;
          }
          delete this.allFields[0]['clia']
          delete this.allFields[0]['ein']
          delete this.allFields[0]['logo']
          delete this.allFields[0]['createdby']
          delete this.allFields[0]['createddate']
          delete this.allFields[0]['modifieddate']
          delete this.allFields[0]['modifiedby']
          delete this.allFields[0]['mnemonic']
          delete this.allFields[0]['status']
          delete this.allFields[0]['accountid']
          //delete this.allFields[0]['organizationid']
          delete this.allFields[0]['usernamedisplayformat']
          delete this.allFields[0]['usernamereportformat']
          delete this.allFields[0]['claimmnemonic']
          delete this.allFields[0]['AccountGUID']
          delete this.allFields[0]['IsListedAsOF']
          delete this.allFields[0]['ExternalGUID']
          delete this.allFields[0]['ShortName']
          delete this.allFields[0]['StateDisplayName']
          delete this.allFields[0]['claimoptions']
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion


  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'OrganizationAttributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  convertToJson(sheetname: string) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (!value) {
            value = ''
          }
          else if (value.toString().toLowerCase() == 'null') {
            value = null;
          }
        }
        if (key.toString().match(/isactive/i) || key.toString().match(/active/i)) {
          // key='IsActive'
          value = value ? value : value == '1' ? true : false;
        }
        if(this.commonService.isPol){
          primary['organizationid'] = this.orgid.toString();
          primary['organizationname'] = sessionStorage.getItem("Org_Name");
        }
        primary[key] = value;
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse();
    temp[0].push("Notes")
    temp[0].push("status")
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: "Accounts", labaccountid: this.accountid});
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        wjCore.toggleClass(e.cell, 'error-msg', value == 'Invalid Email!' || value == 'Mandatory field(s) missing!' || value == 'Null values exist!' || value == 'Type or length mismatch check excel again!' || value == 'NPI length should be 10 digits.' || value == 'NPI cannot contain characters.' || value == 'Clia length should be 10 characters.' || value.toString().includes('is matching') || value.toString().includes('have two') || value == 'failure' || value =='Invalid OF organization ID' || value =='OF Organization Name and OF Organization ID does not match' || value == 'Organization name already exists.Please provide OF organization ID');
        wjCore.toggleClass(e.cell, 'high-value', value == 'Valid. New OF organization and new OF account will be created.' || value == 'Created & Associated Successfully' ||value=='There is already another OF Organization with the same name. On Approve, a duplicate organization will be created.' || value == 'Success' || value == 'Valid');
        wjCore.toggleClass(e.cell, 'warn-value', value == 'Ignored' || value =='There is already another OF organization with the same name.OF account will get added under the existing OF Organization.' || value =='There is already another OF Organization & OF Account with the same name.');
      }
    }
  }

  // Make POL check for bulk uplaod
  polCheck(noteMessage : string){
    if(this.commonService.isPol){
      noteMessage = noteMessage.match(/There is already another OF Organization & OF Account with the same name./i) ? 'Valid' : noteMessage;
    }
    return noteMessage;
  }

  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalservice.validateBulkAccounts(dataArray, this.destDeployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = this.polCheck(r.NoteMessage) ;
              }
            });
          });
          this.AddExcelData(dataArray);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  AddExcelData(data: object[]) {
    this.ngxService.start();
    this.gridDisplay = true;
    this.gridWidth = 0;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              if (value == null) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              this.validationOfBulkdata(key, value);
            }
            if (key == 'primarycontactemail') {
              if (value && !this.validateEmail(value.toString())) {
                primary['notes'] = 'Invalid Email!'
              }
            } else if (key == 'billingcontactemail') {
              if (value && !this.validateEmail(value.toString())) {
                primary['notes'] = 'Invalid Email!'
              }
            } else if (key == 'email') {
              if (value && !this.validateEmail(value.toString())) {
                primary['notes'] = 'Invalid Email!'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        if (this.showDelete) {
          this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'status'), 1)
        }
        else {
          this.sheetHeader.push('status');
        }
        // (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
        this.excelDataArray = gridArray;
        this.gridData = new CollectionView(gridArray, { pageSize: 10 })
        // this.gridData = new CollectionView(gridarray, )
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
        if (gridArray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
      }

    }
    this.ngxService.stop();
  }
  validationOfBulkdata(key: any, value: any) {

  }
  backtoGrid() {
    this.tableGrid = false
    this.uploadScreen = true;
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.gridWidth = 0;
  }
  refreshGrid() {
    this.uploadScreen = false;
    this.tableGrid = true;
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.commonService.setBooleanToTrue(true);
  }

  removeGrid() {
    this.gridWidth = 0;
    this.postDownload = false;
    this.uploadScreen = true;
    this.postUpload = false;
  }
  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region Function to upload accounts Data
  uploadBulkAccountCreation() {
    let sendjson = [];

    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]["notes"] == "") {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          return;
        }
      }

      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              if (key.toString().match(/isactive/i)) {
                value = value == true || value.toString().toLowerCase() == 'true' ? 1 : 0
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["ownerlaborgid"]=this.orgid.toString();
          }
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      sendjson = dataArray.filter(va => va.notes.toString() == 'Valid. New OF organization and new OF account will be created.'
      || va.notes.toString() == 'There is already another OF organization with the same name.OF account will get added under the existing OF Organization.'
      || va.notes.toString() == 'There is already another OF Organization & OF Account with the same name.'
      || va.notes.toString() == 'There is already another OF Organization with the same name. On Approve, a duplicate organization will be created.'
      || va.notes.toString() == 'Valid'
    )
      this.addBulkAccounts(sendjson)
    }
  }
  //#endregion

  disableApprovebtn() {
    let tempflag=this.excelDataArray.some(va=>va.notes == 'Invalid OF organization ID' || va.notes.toLowerCase() == 'invalid email' || va.notes.toLowerCase() =='mandatory field(s) missing!' || va.notes =='OF Organization Name and OF Organization ID does not match' || va.notes =='Organization name already exists.Please provide OF organization ID')
    if(tempflag){
      return true
    }else{
      return false
    }

  }
  //#region
  addBulkAccounts(dataArray) {
    this.ngxService.start();
    this.vitalservice.bulkUploadAccountCreation(dataArray, this.destDeployment).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.showDelete = false;
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['organizationid'] = result[j]['OrganizationId']
                this.excelDataArray[i]['status'] = result[j]['Status']
                if (!result[j]['exist']) {
                  this.commonService.createActivityTracker('Created', result[j]['OrganizationId'], 'Organizations', 'Audit', this.excelDataArray[i], {});
                }
                this.commonService.createActivityTracker('Created', result[j]['AssoaccountId'], 'location', 'Audit', this.excelDataArray[i], {});
                this.commonService.createActivityTracker('Associated', result[j]['AssoaccountId'], 'Clients', 'Audit', this.excelDataArray[i], {}, result[j]['AssoaccountId'] + ' associated to ' + this.accountid + ' as \'Ordering Facilities\'');
                this.commonService.createActivityTracker('Associated', this.accountid, 'clients', 'Audit', this.excelDataArray[i], {}, this.accountid + ' associated to ' + result[j]['AssoaccountId'] + ' as \'Laboratories\'');
                break;
              }
              else {
                this.excelDataArray[i]['status'] = 'Ignored'
                if (this.excelDataArray[i]['notes'].toString().match(/already exists/i)) {
                  this.excelDataArray[i]['status'] = 'Ignored'
                }
              }
            }
          }
          this.postUpload = true;
          this.AddExcelData(this.excelDataArray)
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this._snackbar.open('Data uploaded successfully', 'Close');
        }
        else {
          this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close')
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion

  //#region download result
  downloadResult(flex) {
    let excel = [];
    flex.rows.find(e => {
      let notes = e._data["notes"];
      delete e._data["notes"];
      delete e._data["tablename"];
      delete e._data['slno'];
      excel.push(e._data);
      e._data["notes"] = notes
    });
    let filename = 'Associated Locations_' + this.orgid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'BulkFacCreate_');
    XLSX.writeFile(wb, filename);
  }
  initializedGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    let SampleDataMinfields = false
    let SampleDataAllfields = false

    if (allFieldsCheck) {
      filename = 'Associated Locations_Bulk Facility_All Fields_' + this.orgid + '.xlsx';
      SampleDataAllfields = true
    }
    else {
      filename = 'Associated Locations_Bulk Facility_Minimal Fields_' + this.orgid + '.xlsx';
      SampleDataMinfields = true
    }

    // Removing organizationid and organizationanme when it comes to POL organizations
    if(this.commonService.isPol)
    {
      this.allFields = this.removeColumns(this.allFields);
      this.SampleDataAllfields = this.removeColumns(this.SampleDataAllfields);
      this.mandatoryFields = this.removeColumns(this.mandatoryFields);
      this.SampleDataMinfields = this.removeColumns(this.SampleDataMinfields);
    }

    var ws = XLSX.utils.json_to_sheet(allFieldsCheck ? this.allFields : this.mandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'BulkOFCreation');
    SampleDataAllfields ? ws = XLSX.utils.json_to_sheet(this.SampleDataAllfields) : null;
    SampleDataAllfields ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    SampleDataMinfields ? ws = XLSX.utils.json_to_sheet(this.SampleDataMinfields) : null;
    SampleDataMinfields ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }

  removeColumns(fields){
    delete fields[0]['organizationid'];
    delete fields[0]['organizationname'];
    return fields;
  }


  filterAccounts(account: string) {
    this.searchAccount = account
    this.accountList = cloneDeep(this.accountsList.Accounts)

    if (!account) {
      return this.accountList
    }

    // return this.filterCaseTypeData
  }
  getAccountsList(account): any {
    if (!account) {
      return this.accountsList.Accounts
    }
    return this.accountsList.Accounts.filter(va => va.Name && account && va.Name.toString().toLowerCase().includes(account.toString().toLowerCase()));
  }
  clearResults(value, evt: any, trigger: MatAutocompleteTrigger) {
    this.locationsList=[];
    this.organizationsList=[];
    if (value == 'searchAccount') {
      this.searchAccount = ''
    }
    else if (value == 'AssociatedAccountid') {
      this.recipientData.patchValue({
        AssociatedAccountid: ''
      })
    }
    else if (value == 'associateOrg') {
      this.recipientData.patchValue({
        associateOrg: ''
      })
    }
    // this.search.nativeElement.input();
    this.accountList = cloneDeep(this.accountsList.Accounts);
    evt.stopPropagation();
    // if (trigger) {
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }
  // }

  openEditScreen(grid, event) {
    // if(this.vitalservice.deploymentKey.toString().match(/thx/i)){
    //   this._snackbar.open('This feature is temporarily disabled.','Close');
    //   return;
    // }
    if (!this.btnEdit) {
      this.ngxService.start();
      var hti = grid.hitTest(event);
      this.editMode = true;
      this.editData = hti.panel.rows[hti.row].dataItem;
      this.ngxService.stop();
      if (!this.editData.Associated_Account_Id.toString().includes('-')) {
        if (this.editData.Association_Type.toString().toLowerCase() == 'ordering facilities') {
          this.ContextValue = 'Ordering Facility';
          this.getLabOrgLocations();
        }
        else if (this.editData.Association_Type.toString().toLowerCase() == 'send in lab' || this.editData.Association_Type.toString().toLowerCase() == 'send-out laboratories' || this.editData.Association_Type.toString().toLowerCase() == 'pathologists') {
          this.ContextValue = (this.editData.Association_Type.toString().toLowerCase() == 'send in lab') ? 'Send-In' : (this.editData.Association_Type.toString().toLowerCase() == 'send-out laboratories') ? 'Send-Out' : (this.editData.Association_Type.toString().toLowerCase() == 'pathologists') ? 'EPG' : null
          this.getLabOrgLocations();
        }
        else {
          this._snackbar.open(this.editData.Association_Type.toString() + " not allowed to edit!", 'Close');
        }
      }
      else {
        this._snackbar.open('Cannot edit global locations!', 'Close');
      }
    }
  }

  saveAndOrProceed(action) {
    if (action == 'save') {
      this.AddAssociation(this.assocGrid, 1)
      this.getpatchdata();
    }
    else {
      this.ngxService.start();
      this.tableGrid = true;
      this.getpatchdata();
      // this.UpdateAssociationModel = false;
      this.associationModel = false;
      this.addeddata = [];
      this.currentAssociations = false;
      this.gridData = null;
      this.AssociationDetails = [];
      this.recipientData.reset();
      this.ngxService.stop();
    }
    this.alertPopUp = false;
  }

  //#endregion disable edit button
  editEnabled(row) {
    if ((row._data.Association_Type.toString().toLowerCase() == "ordering facilities" ||
      row._data.Association_Type.toString().toLowerCase() == "send in lab" ||
      row._data.Association_Type.toString().toLowerCase() == "send-out laboratories" ||
      row._data.Association_Type.toString().toLowerCase() == "pathologists") && row._data.Associated_Account_Id != this.accountid) {
      return true
    } else {
      return false;
    }

  }

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  async locationFilter(event) {
    this.temporgid = null;
    var RegExp = /^[0-9]+$/
    let value = event.target.value ? event.target.value.trim() : ''
    let idCheck = RegExp.test(value)
    if (value.length >= 3 || idCheck) {
      let item = this.recipientData.controls.AssociationType.value
      if (value.length == 3 || RegExp.test(value)) {
        let associationtype = item == 'Ordering Facilities' ? 'Ordering Facilities' : item == 'Pathologists' ? 'epg' : 'Laboratories'
        var obj;

        if (this.recipientData.controls.Selecttype.value == 'Accounts') {
          obj = {
            loggedInAccountId:this.templateData.cardIdentifier,
            associationType: associationtype,
            accountName: !idCheck ? value : null,
            accountId: idCheck ? value : null
          };
        }
        else if (this.recipientData.controls.Selecttype.value == 'Organizations') {
          obj = {
            loggedInAccountId:this.templateData.cardIdentifier,
            associationType: associationtype,
            organizationName: !idCheck ? value : null,
            organizationid: idCheck ? value : null
          };
        }
        this.ngxService.start();
        await this.vitalservice.getAssociatedOrganizations(obj).toPromise().then(
          (res) => {
            this.ngxService.stop();
            if (res.Success) {
              this.currentAssociations = false;
              this.AssociatedOrgaDetails = JSON.parse(res.Message);
              this.tempAssociatedOrgaDetails = this.AssociatedOrgaDetails;
            }
            else {
              this.AssociatedOrgaDetails = [];
            }
          }), error => {
            this.ngxService.stop();
            console.log(error)
          }
      }
      else if (event.target.value.length > 3) {
        this.AssociatedOrgaDetails = this.tempAssociatedOrgaDetails.filter(va => va.accountname.toLowerCase().includes(event.target.value))
      }
      let tempAssociationArray = this.templateData.submenuData && this.templateData.submenuData.filter(va => va.Association_Type == item)
      if (tempAssociationArray && tempAssociationArray.length > 0) {
        // Create a Set of unique Associated_Account_Id values
        const associatedAccountIdsSet = new Set(tempAssociationArray.map(element => element.Associated_Account_Id.toString()));

        // Filter out elements that have a matching Associated_Account_Id in tempAssociationArray
        this.AssociatedOrgaDetails = this.AssociatedOrgaDetails.filter(element1 =>
          !associatedAccountIdsSet.has(element1.accountid)
        );
      }

      this.ofAccountDetails = this.AssociatedOrgaDetails.filter(
        (va) =>
          va.organizationid != null && !(Math.sign(va.organizationid) == -1)
      );
      this.organizationsList = [...new Map(this.ofAccountDetails.map((item) => [item['organizationid'], item])).values(),];
      this.locationsList = this.AssociatedOrgaDetails;
    }
    else {
      this.organizationsList = [];
      this.locationsList = []
    }
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
