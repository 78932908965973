<div id="modal-content-wrapper">
  <div id="OrgCompareinLabModal" class="genericModalinLab OrgCompareDialog OrgCompareinLabModal" data-backdrop="static"
    data-keyboard="false">
    <div class="OrgCompareDialog my-0">
      <div>
        <!-- style="margin-top: 20px !important" -->
        <!-- Modal Header -->
        <div class="container-fluid row m-0 p-0 admin-model-header" id="modal-header">
          <div class="col-11 p-0 m-0  w-100 d-block m-auto" id="modal-title" style="font-size: 17px;">
            <strong>Group Compare</strong>
          </div>
          <div class="pr-2 pt-1 pb-1">
            <div style="display: table-cell; vertical-align: middle;">
              <span type="button" data-dismiss="modal" (click)="closeModal()" class="mr-2">
                <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                  title="Close">
              </span>
            </div>
          </div>
        </div>
        <!-- Modal body -->
        <div id="modal-body">
          <div class="container-fluid p-0">
            <!-- <form [formGroup]="Jform" (ngSubmit)="sort(Jform.value.frm_json1, Jform.value.frm_json2)"> -->
            <form [formGroup]="Jform">
              <div class="row p-0 m-0" style="border: 1px solid #e5e5e5;">
                <div class="col-12 p-0 m-0">

                  <a data-toggle="collapse" data-target="#TextArea" id="collapseTextArea"
                    *ngIf="fromButton != 'actionButtoninLab'">
                    <div class="accordion mt-3">
                      <mat-icon aria-hidden="false" class="d-inline-block m-auto" style="font-size: 20px;">compare
                      </mat-icon>
                      Compare Group JSON
                    </div>
                  </a>
                  <a (click)="dataToggle()" id="collapseTextArea" *ngIf="fromButton == 'actionButtoninLab'">
                    <div class="accordion mt-3">
                      <mat-icon aria-hidden="false" class="d-inline-block m-auto" style="font-size: 20px;">compare
                      </mat-icon>
                      Compare Group JSON
                    </div>
                  </a>

                  <div id="TextArea" class="collapse" [ngClass]="{show: textareaclass}">
                    <div class="row p-0 m-0">
                      <div class="col-6 p-0 m-0 text-center" style="border-right: 1px solid #e5e5e5;
                  background-color: #00bcd4;
                  color: white;">
                        <strong>JSON 1</strong>
                      </div>
                      <div class="col-6 p-0 m-0 text-center" style="background-color: #00bcd4;
                    color: white;">
                        <strong>JSON 2</strong>
                      </div>
                    </div>

                    <!-- Json Here -->

                    <div class="row p-0 m-0" style="border:1px solid #e5e5e5;">
                      <div class="col-6 p-2 m-0 " style="border-right:1px solid #e5e5e5; overflow: auto !important;">
                        <textarea name="json1" id="json1" style="width:100%; height: 100%; min-height: 20rem;"
                          formControlName="frm_json1" [readonly]="fromButton == 'actionButtoninLab'"
                          (keypress)="IsJsonString(Jform.value.frm_json1, 'JSON1')">
                          </textarea>
                      </div>
                      <div class="col-6 p-2 m-0" style="overflow: auto !important;">
                        <textarea name="json2" id="json2" style="width:100%; height: 100%;min-height: 20rem;"
                          formControlName="frm_json2" (keypress)="IsJsonString(Jform.value.frm_json1, 'JSON2')">
                          </textarea>
                      </div>
                    </div>
                    <div class="row p-0 m-0" style="border:1px solid #e5e5e5;"
                      *ngIf="this.fromButton == 'actionButtoninLab'">
                      <div class="col-6 p-2 m-0 "
                        style="color:blue;border-right:1px solid #e5e5e5; overflow: auto !important;">
                        <strong>Select (for JSON 1):&nbsp; &nbsp;</strong> <span>Group Info&nbsp; &nbsp;<mat-slide-toggle
                            [checked]="ToggleTextCheckedJSON1" (change)="ToggleTextJSON1Func($event.checked)">
                            Group Attributes
                          </mat-slide-toggle></span>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mb-3" style="border:1px solid #e5e5e5; color: brown; text-align: center;">
                      <div class="col-12 px-0 py-1 mx-0 my-1" style="border-right:1px solid #e5e5e5;">
                        *<u>Copy JSON to above Text-Fields</u> <strong> Or </strong> <u>Upload JSON file
                          below</u><strong> Or </strong>
                        <u>Select Group and
                          generate JSON</u>
                      </div>
                      <div class="col-12 row p-1 mx-0 my-1" style="border-top: 1px dashed #e5e5e5;">
                        <div class="col-6 px-0 py-1 mx-0 my-1" style="border-right: 1px dashed #e5e5e5;"
                          *ngIf="this.fromButton != 'actionButtoninLab'">
                          <div class="form-group">
                            <label for="file1">
                              <em class="fa fa-upload" title="Uplaod JSON file" style="font-size: 20px;"></em>
                              &nbsp;&nbsp;
                            </label>
                            <input type="file" accept=".json" id="file1"
                              (change)="handleFileInput($event.target.files, 'JsonFile1')">
                            <span *ngIf="errorJsonFile1">*Only JSON files allowed!</span>
                          </div>
                        </div>
                        <div class="col-6 px-0 py-1 mx-0 my-1" style="border-right: 1px dashed #e5e5e5;"
                          *ngIf="this.fromButton == 'actionButtoninLab'">
                        </div>
                        <div class="col-6 px-0 py-1 mx-0 my-1">
                          <div class="form-group">
                            <label for="file2">
                              <em class="fa fa-upload" title="Uplaod JSON file"
                                style="font-size: 20px;"></em>&nbsp;&nbsp;
                            </label>
                            <input type="file" accept=".json" id="file2"
                              (change)="handleFileInput($event.target.files,'JsonFile2')">
                            <span *ngIf="errorJsonFile2">*Only JSON files allowed!</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0 py-2 mx-0 my-2" style="border-top: 1px dashed #e5e5e5; color: blue;">
                        <p style="color: blue; margin:0"><strong>Select Location to Generate JSON</strong></p>
                        <div class="row p-0 m-0">
                          <div class="col-6 px-0 mx-0 py-0 my-1" *ngIf="this.fromButton != 'actionButtoninLab'">
                            <strong>Select:&nbsp; &nbsp;</strong> <span>JSON 1&nbsp; &nbsp;<mat-slide-toggle
                                [checked]="ToggledJSONChecked" (change)="ToggleJSONFunc($event)">
                                JSON 2
                              </mat-slide-toggle></span>
                          </div>
                          <div class="col-6 px-0 mx-0 py-0 my-1" *ngIf="this.fromButton == 'actionButtoninLab'">

                          </div>
                          <div class="col-6 px-0 mx-0 py-0 my-1">
                            <strong>Select
                              <ng-container *ngIf="this.fromButton == 'actionButtoninLab'">&nbsp;(for JSON 2)
                              </ng-container>
                              :&nbsp; &nbsp;
                            </strong> <span>Group Info&nbsp; &nbsp;<mat-slide-toggle [checked]="ToggleTextChecked"
                                (change)="ToggleTextFunc($event.checked)">
                                Group Attributes
                              </mat-slide-toggle></span>
                          </div>
                        </div>
                        <div class="row p-0 m-0">
                          <!-- <div class="col-6 px-0 mx-0 py-1 my-1" style="border-right: 1px dashed #e5e5e5;"></div> -->
                          <div class="col-12 px-0 mx-0 py-0 my-1">
                            <mat-horizontal-stepper linear #stepper labelPosition="bottom">
                              <mat-step [stepControl]="Jform.value.firstFormGroup" [editable]="true">
                                <div formGroupName="firstFormGroup">
                                  <ng-template matStepLabel>Select Deployment</ng-template>
                                  <div class="row p-0 m-0">
                                    <div class="col-md-8 col-lg-6 p-0 m-0 d-block m-auto">
                                      <div class="row m-0 p-0">
                                        <div class="col-8 p-0 m-0">
                                          <mat-form-field appearance="fill" class="d-block m-auto">
                                            <mat-label>
                                              <strong><span class="text-danger pr-1">*</span>
                                                Deployment:&nbsp;&nbsp;</strong>
                                            </mat-label>
                                            <select matNativeControl name="DeploymentKeys" id="DKeys"
                                              class="form-control" formControlName="frmDeploymentKey"
                                              (change)="onChangeDep($event.target.value)">
                                              <option value="none" disabled selected>Select</option>
                                              <option *ngFor="let i of DeploymentKeys" value={{i}}>{{i}}</option>
                                            </select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-4 p-0 m-0" style="display: table;">
                                          <div style="display: table-cell; vertical-align: middle;">
                                            <button mat-raised-button class="admin-btn-success d-block m-auto"
                                              matStepperNext>
                                              Next
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </mat-step>
                              <mat-step [stepControl]="Jform.value.secondFormGroup" [editable]="true">
                                <div formGroupName="secondFormGroup">
                                  <ng-template matStepLabel>Select Location</ng-template>
                                  <div class="row p-0 m-0 d-block m-auto">
                                    <div class="col-md-8 col-lg-6 p-0 m-0 d-block m-auto">
                                      <div class="row m-0 p-0">
                                        <div class="col-8 p-0 m-0">
                                          <mat-form-field appearance="fill" disabled="true" class="d-block">
                                            <mat-label>Location</mat-label>
                                            <input matInput value="" formControlName="frmAccount">
                                          </mat-form-field>
                                        </div>
                                        <div class="col-4 p-0 mt-4" style="display: table;">
                                          <div style="display: table-cell;vertical-align: middle;">
                                            <button mat-raised-button class="admin-btn-success mr-4"
                                              matStepperPrevious>Back</button>
                                            <button mat-raised-button class="admin-btn-success" matStepperNext>
                                              Next
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row p-0 m-0">
                                        <div class="col-8 p-0 m-0">
                                          <span (click)="funcShowPopAcc()"
                                            [ngStyle]="Jform.value.firstFormGroup.frmDeploymentKey == 'none' ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}"
                                            style="padding: 5px; cursor: pointer; display: block; color:blue; text-decoration: underline; float: right;">
                                            <em class="fa fa-check-circle mr-2"></em>Select Location
                                          </span>
                                        </div>
                                      </div>
                                      <div class="row p-0 m-0">
                                        <div class="col-md-10 col-lg-8 p-0 m-0">
                                          <div *ngIf="NoDeploymentKeyAlert">
                                            <div class="p-0 w-100 text-center text-danger">
                                              * Select Deployment Key
                                            </div>
                                          </div>
                                          <div id="PopAcc" class="collapse collapsePopAcc mt-3 px-3 pt-1 pb-3"
                                            style="overflow-y: auto; max-height: 400px; min-height:150px">

                                            <span type="button" data-dismiss="modal" (click)="closeManualPopAcc()"
                                              class="mr-2">
                                              <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                                                class="material-icons md-24 cursor" title="Close">
                                            </span>
                                            <div formGroupName="frm_OrgCompareSelectAccGroup">
                                              <div class="row m-0 p-0">
                                                <div class="col-12 p-0 m-0">
                                                  <mat-form-field appearance="fill" class="d-block">
                                                    <mat-label>
                                                      <strong>Search Location:&nbsp;&nbsp;</strong>
                                                    </mat-label>
                                                    <select matNativeControl name="drpAccSearch" id="drpAccSearch"
                                                      class="form-control w-100" formControlName="frmDrpAccSearch">
                                                      <option value="Location ID">Location ID</option>
                                                      <option value="Location Name">Location Name</option>
                                                    </select>
                                                  </mat-form-field>
                                                </div>
                                              </div>
                                              <div class="row m-0 p-0">
                                                <div class="col-md-12 p-0 m-0 mt-1 buttonInAccSearch">
                                                  <!-- <input type="text" id="inpAccSearch" class="form-control p-2" name="inpAccSearch"
                                            placeholder="Enter Location" formControlName="frmAccSearchInpTxt"> -->
                                                  <mat-form-field appearance="fill" class="d-block">
                                                    <mat-label>Location</mat-label>
                                                    <input matInput class="inpAccSearch" placeholder="Enter Location"
                                                      autocomplete="off" (keypress)="AllowOnlyNumber($event)"
                                                      formControlName="frmAccSearchInpTxt">
                                                    <button type="button"
                                                      (click)='btnSearchAcc(Jform.value.secondFormGroup.frm_OrgCompareSelectAccGroup)'
                                                      [disabled]="Jform.value.secondFormGroup.frm_OrgCompareSelectAccGroup.invalid"
                                                      id="btnSearchAcc"
                                                      [ngStyle]="Jform.value.secondFormGroup.frm_OrgCompareSelectAccGroup.invalid && {'background-color': '#e5e5e5'}">
                                                      <em class="fa fa-search"></em>
                                                    </button>
                                                  </mat-form-field>
                                                </div>
                                              </div><br />
                                              <div class="row m-0 p-0" *ngIf="showAccData">
                                                <div
                                                  *ngFor="let data of templateData; let i = index; let even = even; let odd = odd"
                                                  class="col-md-6 p-1 m-0 displaySearchedAcc" (click)="setAccount(data)"
                                                  style="cursor: pointer;">
                                                  {{i+1}} - {{data.accountname }} (Acc ID: {{data.accountid}}, Org ID:
                                                  {{data.organizationid}})
                                                </div>
                                              </div>
                                              <span class="w-50 nodata-wrap mt-4" *ngIf="NoAccData"
                                                class="nodata-design">
                                                <br /> No Data Found
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </mat-step>
                              <mat-step [stepControl]="Jform.value.ThirdFormGroup" [editable]="isEditable">
                                <div formGroupName="ThirdFormGroup">
                                  <ng-template matStepLabel>Select Location CaseType</ng-template>
                                  <div class="row p-0 m-0 d-block m-auto">
                                    <div class="col-md-8 col-lg-6 p-0 m-0 d-block m-auto">
                                      <div class="row p-0 m-0">
                                        <div class="col-8 m-0 p-0">
                                          <mat-form-field appearance="fill" class="d-block">
                                            <mat-label>
                                              <strong><span class="text-danger pr-1">*</span>
                                                Select Location CaseType:&nbsp;&nbsp;</strong>
                                            </mat-label>
                                            <select matNativeControl name="AccCaseType" id="AccCaseType"
                                              class="form-control" formControlName="frmAccCaseType"
                                              (change)="onChangeAccCaseType($event.target.value)">
                                              <option value="-1">---Select---</option>
                                              <option *ngFor="let i of AccCaseTypeArr" value={{i.casetype}}>
                                                {{i.casetype}}
                                              </option>
                                            </select>
                                          </mat-form-field>
                                        </div>
                                        <div class="col-4 m-0 p-0" style="display: table;">
                                          <div style="display: table-cell; vertical-align:middle;">
                                            <button mat-raised-button class="admin-btn-success"
                                              matStepperPrevious>Back</button>
                                            <!-- <button #stepperreset class="admin-btn-success m-1"  style="display: block;"
                                                (click)="stepper.reset()">Reset</button> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </mat-step>
                            </mat-horizontal-stepper>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- <div class="row p-0 m-0 my-3">
                      <div class="col-12 p-0 m-0">
                        <button type="submit" [disabled]="Jform.invalid" class="btn btn-sm d-block m-auto admin-btn-success">
                          <mat-icon aria-hidden="false" class="d-inline-block m-auto" style="font-size: 20px;">compare
                          </mat-icon>
                          <span>&nbsp;Compare JSON</span>
                        </button>
                      </div>
                    </div> -->
                  <!-- <a class="accordion" data-toggle="collapse" data-target="#collapseTwo">
                  <strong>Json 1</strong>
                  </a> -->
                  <!-- <div class="accordion" data-toggle="collapse" data-target="#EditorArea">
                    <a>Result</a>
                  </div>
                  <div id="EditorArea" class="collapse"> -->
                  <div class="row p-0 m-0 mt-3">
                    <div id="comapreOptionsInline" class="col-12 p-0 m-0" style="display:block">
                      <button type="submit"
                        [disabled]="Jform.invalid && (!isJSON1 || !isJSON2) && (Jform.value.frm_json1 == '' || Jform.value.frm_json2 == '')"
                        mat-stroked-button (click)="sort(Jform.value.frm_json1, Jform.value.frm_json2)"
                        class="mx-1 my-2 admin-btn-success">
                        <span class="px-1 pt-1 text-center ">
                          <mat-icon aria-hidden="false" aria-label="All" style="font-size: 19px;">
                            spellcheck
                          </mat-icon> Compare All
                        </span>
                      </button>

                      <button type="button" mat-stroked-button class="mx-1 my-2 admin-btn-success"
                        [disabled]="Jform.invalid && (!isJSON1 || !isJSON2) && (Jform.value.frm_json1 == '' || Jform.value.frm_json2 == '')"
                        (click)="OpenBlockLevelMenu()">
                        <span class="px-1 pt-1 text-center ">
                          <mat-icon aria-hidden="false" aria-label="Block level comparison" style="font-size: 19px;">
                            help
                          </mat-icon>Block Level Comparison
                        </span>
                      </button>
                      <mat-menu #Blocklevel="matMenu" style="z-index: 9999;">
                        <ng-container *ngFor="let menu of Block_level_menu">
                          <button (click)="findDiff($event.target.innerText)" mat-menu-item>
                            {{menu}}
                          </button>
                          <mat-divider></mat-divider>
                        </ng-container>
                      </mat-menu>
                      <button mat-stroked-button class="mx-1 my-2 admin-btn-success" (click)="openDialogIgnoreKeys()">
                        <span class="px-1 pt-1 text-center  ">
                          <mat-icon aria-hidden="false" aria-label="Ignore List" style="font-size: 19px;">
                            visibility_off
                          </mat-icon>Ignore Keys
                        </span>
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="row p-0 m-0 mt-3" *ngIf="editor_height > 0">
              <div class="col-12" style="border: 1px solid black;" [style.min-height.rem.!important]="editor_height">
                <ngx-monaco-diff-editor style="height: 100% !important" [options]="diffOptions"
                  [originalModel]=originalModel [modifiedModel]="modifiedModel">
                </ngx-monaco-diff-editor>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div id="modal-footer" style=" border-top: none !important; display: block;justify-content: normal;">

        </div>
      </div>
    </div>
    <br />
  </div>
</div>
