<div *ngIf="templateData && templateData.labels && gridwidth > 0 && gridViewShow" style="margin-top:20px">
  <wj-flex-grid [ngStyle]="{'width.px': gridwidth}" [isReadOnly]="true" (click)="onClick(gridData)"
    [itemsSource]="gridData"  #grid>
    <div *ngFor="let columnname of gridHeader">
      <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
        [allowResizing]="true" [visible]="!(columnname == 'VDSettingsID')" [allowDragging]="false">
      </wj-flex-grid-column>
    </div>
    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
  </wj-flex-grid>
  <br />
  <div class="align-center">
    <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
      [cv]="gridData"> </wj-collection-view-navigator>
  </div>
</div>
<div *ngIf="gridwidth == 0 && templateData.Keyword !== 'vdprinteractions' " class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found</div>
</div>
<div *ngIf="templateData.Keyword === 'vdprinteractions' "   class="nodata-wrap">
  <div class="nodata-design"> Work in progress </div>
</div>

<div *ngIf="editScreenGrey">
  <div
    style="background-color: #a5cedc!important; font-weight: 600!important; padding-block: 1px!important; margin-left: -15px!important; padding-left: 10px!important;">
    <span _ngcontent-crl-c355="">{{editTitle}}</span>
  </div>
  <form [formGroup]="jarcasetteslidergroup">
    <div style="margin-left:-1.1%;background-color: lightgray;">
      <div class="row px-2 pt-2 pr-4">
        <div class="col-sm-6">
          <div class="row mb-1">
            <div class="col-sm-6 text-black">
              <Strong>Printer Model <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
            </div>
            <div class="col-sm-6 pl-4">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="printerModel"
                  (change)="OnChangeOfdrpdwn($event,'drpprintermodel')">

                  <option *ngFor="let model of printermodel" [value]="model.context">{{model.context}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-sm-6 text-black">
              <Strong>Print Mode <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
            </div>
            <div class="col-sm-6 pl-4">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="printMode">
                  <option *ngFor="let model of printmode" [value]="model.mode">{{model.mode}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-sm-6 text-black">
              <Strong>Alias/ Printer Name <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
            </div>
            <div class="col-sm-6 pl-4">
              <div class="form-group">
                <input type="text" class="form-control form-control-sm input_style" formControlName="printerName">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row mb-1">
            <div class="col-sm-6 text-black">
              <Strong>Default Printer?</Strong>
            </div>
            <div class="col-sm-6">
              <label class="checkbox-inline"> <input type="checkbox" value="option3" formControlName="isDefaultPrinter">
              </label>
            </div>
          </div>
          <div class="row mb-1" *ngIf="templateData.Keyword !== 'vdslideprinter' ">
            <div class="col-sm-6 text-black">
              <Strong>Printer <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="printer">
                  <option *ngFor="let model of printerarry" [value]="model.Name">{{model.Name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-1" *ngIf="templateData.Keyword === 'vdslideprinter' ">
            <div class="col-sm-6 text-black">
              <Strong>Folder Path <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <input type="text" class="form-control form-control-sm input_style " formControlName="folderpath">
                  </div>
                  <div class="col-sm-3">
                    <input type="file" #fileUpload hidden class="form-control form-control-sm input_style ">
                    <button mat-raised-button class="admin-btn-success" (click)="fileUpload.click()">...</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ml-2 mr-4 p-2 editBorderBox">
        <span class="text-muted text_casesetting">Case Level Settings</span>
        <div class="col-sm-6">
          <div class="row mb-1">
            <div class="col-sm-6 text-black"><Strong>Case Meta Type</Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="caseMetaType"
                  (change)="OnChangeOfdrpdwn($event,'drpCaseMetatype')">
                  <option value="All">All</option>
                  <option *ngFor="let model of casemetatype" [value]="model.caseMetaType">{{model.caseMetaType}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-sm-6 text-black"><Strong>Case Type</Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="caseType">
                  <option value="All">All</option>
                  <option *ngFor="let model of casetype" [value]="model.CaseType">{{model.CaseType}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-sm-6 text-black"><Strong>Priority</Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="priority">
                  <option value="All">All</option>
                  <option *ngFor="let model of priorityarry" [value]="model.AttributeName">{{model.AttributeName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-1 mt-4">
            <div class="col-sm-6 text-black"><Strong>Template for Any/TBD Service Type
                <em class="fa  fa-asterisk text-xxs text-danger"></em> </Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="tbdservicetype">
                  <option [ngValue]="">Choose</option>
                  <option *ngFor="let model of tbdServiceType" [ngValue]="model.templateid">{{model.displayname}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="col-sm-6 text-black"><Strong>Template for Technical Service Type <em
                  class="fa  fa-asterisk text-xxs text-danger"></em> </Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="technicalservicetype">
                  <option value="">Choose</option>
                  <option *ngFor="let model of technicalServiceType" [ngValue]="model.templateid">{{model.displayname}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="col-sm-6 text-black"><Strong>Template for Global Service Type <em
                  class="fa  fa-asterisk text-xxs text-danger"></em> </Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="globalservicetype">
                  <option value="">Choose</option>
                  <option *ngFor="let model of globalServiceType" [ngValue]="model.templateid">{{model.displayname}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="col-sm-6 text-black"><Strong>Template for Professional Service Type <em
                  class="fa  fa-asterisk text-xxs text-danger"></em> </Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <select class="form-control form-control-sm input_style" formControlName="profservicetype">
                  <option value="">Choose</option>
                  <option *ngFor="let model of profServiceType" [ngValue]="model.templateid">{{model.displayname}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="col-sm-6 text-black"><Strong>Number of Sets</Strong></div>
            <div class="col-sm-6">
              <div class="form-group">
                <input type="text" class="form-control form-control-sm input_style " formControlName="numberOfSet">
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-sm-6 text-black">
              <Strong>Show options on print</Strong>
            </div>
            <div class="col-sm-6">
              <label class="checkbox-inline"> <input type="checkbox" value="option3" formControlName="isShowOption">
              </label>
            </div>
          </div>

          <div *ngIf="templateData.Keyword === 'vdjarprinter' ">
            <div class="row mb-1">
              <div class="col-sm-6 text-black">
                <Strong>Print case labels (for requisition form)</Strong>
              </div>
              <div class="col-sm-6">
                <label class="checkbox-inline"> <input type="checkbox" value="option3"
                    formControlName="isPrintCaseLabel"> </label>
              </div>
            </div>

            <div class="row mb-1 mt-1" *ngIf="false">
              <div class="col-sm-6 text-black"><Strong> Case label Template <em
                    class="fa  fa-asterisk text-xxs text-danger"></em></Strong></div>
              <div class="col-sm-6">
                <div class="form-group">
                  <select class="form-control form-control-sm input_style" formControlName="caselabeltemplate">
                    <option *ngFor="let model of caseLabelTemplate" [value]="model.Name">{{model.Name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mb-1 mt-1">
              <div class="col-sm-6 text-black"><Strong>Number of Case label(s) <em
                    class="fa  fa-asterisk text-xxs text-danger"></em></Strong></div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" class="form-control form-control-sm input_style" formControlName="numOfCaseLabel">
                </div>
              </div>
            </div>
          </div>


          <div *ngIf="templateData.Keyword === 'vdcassetteprinter' ">
            <div class="row mb-1">
              <div class="col-sm-6 text-black">
                <Strong>Magazine / Round robin range</Strong>
              </div>
              <div class="col-sm-6">
                <div class="form-group" style="width: 40%;float: left;margin-inline-end: 6%;">
                  <input type="text" class="form-control form-control-sm input_style" formControlName="robinRange">
                </div>
                <div class="radio" style="float: left;">
                  <label> <input type="radio" value="bycase" class="mr-1" formControlName="isByCase">By Case</label>
                </div>
                <div class="radio">
                  <label> <input type="radio" value="byblock" class="ml-1 mr-1" formControlName="isByCase">By Block
                  </label>
                </div>
              </div>
            </div>
          </div>


          <div *ngIf="templateData.Keyword === 'vdslideprinter' ">
            <div class="row mb-1">
              <div class="col-sm-6 text-black">
                <Strong>Include Unstained Slides</Strong>
              </div>
              <div class="col-sm-6">
                <label class="checkbox-inline"> <input type="checkbox" value="option3"
                    formControlName="isUnstainedSlides"> </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-sm-6 text-black">
                <Strong>Magazine / Round robin range</Strong>
              </div>
              <div class="col-sm-6">
                <div class="form-group" style="width: 40%;float: left;margin-inline-end: 6%;">
                  <input type="text" class="form-control form-control-sm input_style" formControlName="slideRobinRange">
                </div>
                <div class="radio" style="float: left;">
                  <label> <input type="radio" value="bycase" class="mr-1" formControlName="slideByCase">By Case</label>
                </div>
                <div class="radio">
                  <label> <input type="radio" value="byslide" class="ml-2 mr-1" formControlName="slideByCase">By Slide
                  </label>
                </div>
              </div>
            </div>

            <div class="row mb-1 mt-3">
              <div class="col-sm-6 text-black">
                <Strong>Print Each Site Per File</Strong>
              </div>
              <div class="col-sm-6">
                <label class="checkbox-inline"> <input type="checkbox" value="option3" formControlName="sitePerFile">
                </label>
              </div>
            </div>
          </div>






          <div class="row mb-1 mt-1" style="height: 10em;">
            <div class="col-sm-6 text-black"></div>
            <div class="col-sm-6 my-auto">
              <button mat-raised-button class="admin-btn-success" [disabled]="true">Save Settings</button>
            </div>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="row mb-1 mt-1">
            <div class="col-sm-3text-black"><Strong>All Cases</Strong></div>
            <div class="col-sm-9">
              <div class="form-group">
                <input type="text" class="form-control form-control-sm input_style" formControlName="allCases">
              </div>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="form-group col-sm-12">
              <strong>Case Meta Type Specific</strong>
              <textarea readonly class="form-control" rows="3" formControlName="caseMetaTypeSpecific"></textarea>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="form-group col-sm-12">
              <strong>Case Type Specific</strong>
              <textarea class="form-control" rows="3" readonly formControlName="caseTypeSpecific"></textarea>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="form-group col-sm-12">
              <strong>Priority Specific</strong>
              <textarea class="form-control" rows="3" readonly formControlName="prioritySpecific"></textarea>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="form-group col-sm-12">
              <strong>Case Meta Type Priority Specific</strong>
              <textarea class="form-control" rows="3" readonly
                formControlName="caseMetaTypePrioritySpecific"></textarea>
            </div>
          </div>

          <div class="row mb-1 mt-1">
            <div class="form-group col-sm-12">
              <strong>Case Meta Type CaseType Priority Specific</strong>
              <textarea class="form-control" rows="3" readonly
                formControlName="caseMetaTypeCaseTypePrioritySpecific"></textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row  mt-2  justify-content-center">
        <button class="btn btn-secondary btn-sm mx-2 my-2"
          (click)="savePrinterDetails(jarcasetteslidergroup.value)">Save </button>
        <button class="btn btn-secondary btn-sm mx-2 my-2">Cancel</button>
      </div> -->
    </div>
    <div class="float-right mt-4">
      <button type="button" mat-raised-button class="admin-btn-success mr-4"
        (click)="backToGrid()">Back</button>
      <button type="button" mat-raised-button class="admin-btn-success" [disabled]="true"
        style="background-color: #00bcd4; color: white;">Save</button>
    </div>
  </form>
</div>

<div *ngIf="documentPrinter">
  <div
    style="background-color: #a5cedc!important; font-weight: 600!important; padding-block: 1px!important; margin-left: -15px!important; padding-left: 10px!important;">
    <span _ngcontent-crl-c355="">Select Document Printer</span>
  </div>
  <form [formGroup]="doctechgroup">
    <div class="row mt-2 mr-4">
      <div class="col-sm-6">
        <div class="row mb-1 mt-1">
          <div class="col-sm-6 text-black"><Strong> Printer </Strong></div>
          <div class="col-sm-6">
            <div class="form-group">
              <select class="form-control form-control-sm input_style" formControlName="PrinterName">
                <!-- <option selected>{{PrinterName}}</option> -->
                <option *ngFor="let model of PrinterName" [value]="model.Name">{{model.Name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row mb-1 mt-1">
          <div class="col-sm-3 text-black"><Strong>Alias <em class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
          </div>
          <div class="col-sm-9">
            <div class="form-group">
              <!-- <input type="text" class="form-control form-control-sm input_style " [(ngModel)]="Alias"> -->
              <input type="text" class="form-control form-control-sm input_style" formControlName="Alias">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 mr-4">
      <div class="col-sm-6">
        <div class="row mb-1 mt-1">
          <div class="col-sm-6 text-black"><Strong>Number of Requisition forms to print <em
                class="fa  fa-asterisk text-xxs text-danger"></em></Strong></div>
          <div class="col-sm-6 ">
            <div class="form-group">
              <!-- <input type="text" class="form-control form-control-sm input_style " [(ngModel)]="ReqFormNum"> -->
              <input type="text" class="form-control form-control-sm input_style" formControlName="ReqFormNum">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="col-sm-6">
          <!-- <label class="checkbox-inline"> <input type="checkbox" class="mr-2" [checked]="DefaultValue"/>Is Default Printer </label> -->
          <label class="checkbox-inline"> <input type="checkbox" class="mr-2" formControlName="DefaultValueDocTech"
              [checked]=false>
            Is Default Printer</label>
        </div>
      </div>
    </div>
    <div class="float-right mt-4">
      <button type="button" mat-raised-button class="admin-btn-success mr-4"
        (click)="backToGrid()">Back</button>
      <button type="button" mat-raised-button class="admin-btn-success" [disabled]="true"
        (click)="savePrinterDetails(doctechgroup.value)" >Save</button>
    </div>
  </form>
</div>


<div *ngIf="deviceEdit">
  <div
    style="background-color: #a5cedc!important; font-weight: 600!important; padding-block: 1px!important; margin-left: -15px!important; padding-left: 10px!important;">
    <span _ngcontent-crl-c355="">Create</span>
  </div>
  <form [formGroup]="medicalprintergroup">
    <div style="background-color: lightgrey;margin-left: -1.1%;padding: 1%;width: 99%;">
      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Device Name <em
              class="fa  fa-asterisk text-xxs text-danger"></em></Strong></div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="DeviceName">
              <!-- <option>1</option>
            <option>2</option>
            <option>3</option> -->
              <!-- <option selected>{{DeviceName}}</option> -->
              <option *ngFor="let model of DeviceName" [value]="model.Name">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Communication Mode <em
              class="fa  fa-asterisk text-xxs text-danger"></em>
          </Strong></div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="CommunicationMode">
              <!-- <option selected>{{CommunicationMode}}</option> -->
              <option *ngFor="let model of CommunicationMode" [value]="model.Name">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Communication Port <em
              class="fa  fa-asterisk text-xxs text-danger"></em>
          </Strong></div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="CommunicationPort">
              <!-- <option selected>{{CommunicationPort}}</option> -->
              <option *ngFor="let model of CommunicationPort" [value]="model.Name">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Baud Rate <em
              class="fa  fa-asterisk text-xxs text-danger"></em>
          </Strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="BaudRate">
              <!-- <option selected>{{BaudRate}}</option> -->
              <option *ngFor="let model of BaudRate" [value]="model.Name">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Parity <em class="fa  fa-asterisk text-xxs text-danger"></em>
          </Strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="Parity">
              <!-- <option selected>{{Parity}}</option> -->
              <option *ngFor="let model of Parity" [value]="model.Name">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong>Alias/Printer name /Label name <em
              class="fa  fa-asterisk text-xxs text-danger"></em></Strong></div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <input type="text" class="form-control form-control-sm  input_style" formControlName="Alias">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3">
          <strong>Is Default Device</strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <label class="checkbox-inline"> <input type="checkbox" class="mr-2" formControlName="DefaultValueMed"
              [checked]="DefaultValueMed">
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3">
          <strong>Show options during Data transfer</strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <label class="checkbox-inline"> <input type="checkbox" value="option3" class="mr-2"
              formControlName="ShouldShowDetailsDuringTransfer" [checked]="ShouldShowDetailsDuringTransfer"></label>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong>Data Bits <em
              class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <input type="text" class="form-control form-control-sm  input_style" formControlName="DataBits">
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong>Stop Bit <em
              class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <input type="text" class="form-control form-control-sm  input_style" formControlName="StopBits">
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong>Start Bit <em
              class="fa  fa-asterisk text-xxs text-danger"></em></Strong>
        </div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <input type="text" class="form-control form-control-sm  input_style" formControlName="StartBits">
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-2 .d-none .d-sm-none .d-md-block"></div>
        <div class="col-sm-6 col-md-3 text-black"><Strong> Line Terminator </Strong></div>
        <div class="col-sm-6 col-md-5">
          <div class="form-group">
            <select class="form-control form-control-sm input_style" formControlName="LineTerminator">
              <!-- <option selected>{{LineTerminator}}</option> -->
              <option *ngFor="let model of LineTerminator" [value]="model.Value">{{model.Name}}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- <div class="row  mt-2  justify-content-center">
      <button class="btn btn-secondary btn-sm mx-2 my-2" [disabled]="true">Save </button>
      <button class="btn btn-secondary btn-sm mx-2 my-2">Cancel</button>
    </div> -->
    </div>
    <div class="float-right mt-4">
      <button type="button" mat-raised-button class="admin-btn-success mr-4"
        (click)="backToGrid()">Back</button>
      <button type="button" mat-raised-button class="admin-btn-success"
        (click)="savePrinterDetails(medicalprintergroup.value)" [disabled]="true"
        >Save</button>
    </div>
  </form>
</div>
