<div mat-dialog-title class="popup-header">
    <span>{{config.fileName}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content [class.ifpdf]="documentType === 'pdf'">
    <div>
        <div *ngIf="documentType === 'pdf'">
            <ngx-extended-pdf-viewer [src]="url" [rotation]="0" [showToolbar]="true" [showSidebarButton]="false"
                [showFindButton]="false" [showPagingButtons]="false" [showDrawEditor]="false"
                [showTextEditor]="false" [showZoomButtons]="false" [showPresentationModeButton]="false"
                [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false"
                [showSecondaryToolbarButton]="true" [showRotateButton]="false" [showHandToolButton]="false"
                [showScrollingButton]="true" [showSpreadButton]="false" [showPropertiesButton]="false"
                pdfBackgroundColor="black" zoom="100%"></ngx-extended-pdf-viewer>
        </div>
        <div *ngIf="documentType === 'text'" class="pt-3" >
            <pre class="display-text">{{txtFileContent}}</pre>
        </div>
    </div>
</mat-dialog-content>