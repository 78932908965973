<hr class="separation-line m-0 p-0">
<div class="col-sm-12 p-0 mt-0">
    <div class="col-sm-12 title-wrap d-flex py-1 mb-1 pr-3 pl-2">
        <div class="col-sm-12 header-wrap p-0">
            <span [ngClass]="header.colWidth" *ngFor="let header of gridHeaders;let i =index;">
              <div *ngIf="fromClientUser">
                <div style="color:black!important" *ngIf="i !== gridHeaders.length-1">{{header.Name}}</div>
                <div *ngIf="i === gridHeaders.length -1" class="d-flex justify-content-between">
                  <span>{{header.Name}}</span>
                  <span (click)="ExportExcel()"><img *ngIf="gridData?.length > 0"
                  src=".../../../../assets/icons/lab_users_icons/excel_download_green.svg" title="Export"
                  class="export-icon"></span></div>
              </div>
      <div style="color:black!important" *ngIf="!fromClientUser">{{header.Name}}</div>
            </span>

      <div class="col-sm-1 pr-0 edit-wraps" *ngIf="!fromClientUser">
                <span (click)="ExportExcel()"><img *ngIf="gridData?.length > 0"
                        src=".../../../../assets/icons/lab_users_icons/excel_download_green.svg" title="Export"
                        class="export-icon"></span>
            </div>
          </div>
        </div>

    <div class="header-wrap pr-0">
        <div class="col-sm-12 pl-0 pr-1 table-scroll" [style.max-height.vh]="fromClientUser ? '76' : '65'">
            <div class="col-sm-12 nodata-header-wrap"
                *ngIf="gridData.length != 0 && (gridData | searchfiltersTemplate: templatefilter).length === 0 && searchtext">
                <span class="col-sm-12 nodata-wrapper">No Data Found</span>
            </div>
            <span *ngIf="gridData.length > 0">
                <div [ngClass]="(item?.status?.trim().toLowerCase() === 'active') ? 'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 cursor':'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 inactive-class cursor'"
                    *ngFor="let item of gridData | searchfiltersTemplate: templatefilter;let i = index;" (click)="RedirectToView(item)" >
                    <div [ngClass]="header.colWidth" 
                        [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed, item, header)" #isElipsed
                        *ngFor="let header of gridHeaders">
                        {{displayedColumns(item,header) | titlecase}}
                        <span *ngIf="header.key== 'npi' && isNpiKnown(item) && !fromClientUser" style="display: inline-flex;">
                            <span *ngIf="item?.npiStatus?.toLowerCase() === 'validated'">
                                <div  class="active-circle ml-2">
                                </div>
                            </span>
                            <span *ngIf="item?.npiStatus?.toLowerCase() !== 'validated'">
                              <div  class="inactive-circle ml-2">
                              </div>
                          </span>
                        </span>

                    </div>

                    <div class="col-sm-1 button-wrap pr-0" *ngIf="!fromClientUser">
                     <span class="cursor mr-2" (click)="mailSent(item);$event.stopPropagation();" *ngIf="!emailType(item) && item?.emailId?.length > 0" [ngClass]="{'action-button-disabled':showloader || isEmailDisabled}"><img
                      style="height: 15px!important;width: 27px!important;" src="../../../../../assets/icons/lab_users_icons/email.svg"
                              matTooltip={{tooltip}}></span>
                      <span (click)="openDialog(item);$event.stopPropagation();" *ngIf="item.isAccountLockedOut" class="cursor mr-3" style="min-width: 11px;" [ngClass]="{'action-button-disabled':showloader || isLockDisabled}"><img style="width: 11px;"
                                src="../../../../../assets/icons/lab_users_icons/lock_user.svg"
                                matTooltip="Unlock User"></span>
                      <span (click)="openDialog(item);$event.stopPropagation();" *ngIf="!item.isAccountLockedOut" class="cursor mr-3" style="min-width: 11px;" [ngClass]="{'action-button-disabled':showloader || isUnlockDisabled}"><img style="width: 16px;"
                                  src="../../../../../assets/icons/lab_users_icons/Unlock.svg"
                                  matTooltip="Lock User"></span>
                     <span class="cursor" (click)="passwordGenerateDialog(item);$event.stopPropagation();" [ngClass]="{'action-button-disabled':showloader || isGeneratePwd}"><img
                      src="../../../../../assets/icons/lab_users_icons/generate_password.svg"
                      matTooltip="Regenerate Password"></span>
                  </div>
                  <mat-spinner class="labadmin-spinner center"  *ngIf="showloader && i == 1" style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50">
                    </mat-spinner>
                </div>

            </span>
            <div class="col-sm-12 nodata-header-wrap"
            *ngIf="gridData.length === 0">
            <span class="col-sm-12 nodata-wrapper">No Data Found</span>
        </div>
        </div>
    </div>
</div>

