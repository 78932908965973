<div mat-dialog-title class="common-dailog-header">
    <span>{{data.dailogHeading}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content>
    <p class="popup-content pt-2">{{data.message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-flex align-items-center pr-3">
        <div class="ml-auto">
            <app-button buttonclass="secondary" *ngIf="data.cancel !== 'dontshow'"
            (click)="onClick(false)">{{data.cancel}}</app-button>
        </div>
    <app-button buttonclass="primary" class="ml-2"  (click)="onClick(true)">{{data.continue}}</app-button>
    </div>
</mat-dialog-actions>
