
<div *ngIf="uploadClicked" class="my-adequacy-form">
  <div class="modal-header mb-2 mt-3 admin-section-header">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong *ngIf="!headerName">Upload {{templateData.menuURL}} Details</strong>
      <strong *ngIf="headerName">Upload {{templateData.menuURL}}</strong>
    </h3>
  </div>

  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <mat-select disableOptionCentering class="ml-2">
        <mat-option class="temp-font-size" (click)="downloadTemplate('mandatoryFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
        </mat-option>
        <mat-option *ngIf="templateData.menuURL.toString().toLowerCase() != 'quality attributes'" class="temp-font-size" (click)="downloadTemplate('updateCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" class="mb-1 mr-1" alt="help" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload the {{templateData.menuURL}}.</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
    <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="Expbtn btn-sm rounded btn mt-2 browse ml-5" style="background-color: #3874ff;" for="fileDropRef">Browse file</label>
    </div>
    <button mat-raised-button class="Expbtn mt-4" (click)="backToParent()">
      Back
    </button>
  </div>

  <div *ngIf="gridWidth > 0">
    <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1
      (initialized)="initGrid(grid1)" [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)"
      [frozenColumns]="3">
      <div *ngFor="let columnname of sheetHeader">
        <div *ngIf="templateData.menuURL.toString().toLowerCase() == 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="120"
            [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <div *ngIf="templateData.menuURL.toString().toLowerCase() != 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'attrorder') && !(columnname == 'rolename') && !(columnname == 'organizationid') && !(columnname == 'category')"
            [allowResizing]="true" [width]="245" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [visible]="!(columnname == 'AttrId')" [allowResizing]="true" [width]="245" [allowDragging]="false"
          [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton" [title]="'Delete'"
              class="cursor p-0 fa fa-trash"></em></button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="row pl-3 mt-4">
      <div class="col-sm-2 p-0">
        <button mat-raised-button class="Expbtn" (click)="backToParent()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-field-wrap p-0">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button *ngIf="postDownload" class="Expbtn mr-4"
            (click)="backToParent()">Finish</button>
          <button mat-raised-button *ngIf="!postDownload" class="Expbtn mr-4"
            (click)="backToParent()">Cancel</button>
          <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()" class="Expbtn mr-4"
            (click)="uploadData()">Approve</button>
          <button mat-raised-button *ngIf="postUpload" class="Expbtn" (click)="ExportExcel(grid1)">Download
            Result</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
  </div>
</div>

<!-- Copy-from-other-entity -->
<div *ngIf="copyDataClicked">
  <div class="modal-header mb-2 mt-3 admin-section-header">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong *ngIf="!headerName">Copy {{templateData.menuURL}} Details</strong>
      <strong *ngIf="headerName">Copy {{templateData.menuURL}}</strong>
    </h3>
  </div>
  <form [formGroup]="copyFormGroup" (keydown.enter)="$event.preventDefault()" class="copy-collection">
    <div class="row col-sm-12 p-0 ml-0">
      <div class="row col-sm-12 mt-2">
        <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label>Deployment</mat-label>
          <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
            <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
              (onSelectionChange)="onChangeDeployment($event,i)">
              <span>{{ i }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label>Group </mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
            [disabled]="disableOrgSearch" #searchbar (keyup)="fetchOrgSeries(copyFormGroup.value.frmOrganization)"
            [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
          <mat-autocomplete #orgauto="matAutocomplete">
            <div *ngFor="let show of searchResult">
              <mat-option class="mat-opt-align"
                *ngIf="show.organizationid.toString().includes(searchInput.toString()) || show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.organizationname }}"
                title="{{ show.organizationname }} ({{show.organizationid}})">
                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
              </mat-option>
            </div>
          </mat-autocomplete>

        </mat-form-field>

        <mat-form-field *ngIf = "templateData.menuURL.toString().toLowerCase() == 'quality attributes'" class="col-sm-4 example-additional-selection" appearance="outline">
          <mat-label>Role</mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input type="text" matInput formControlName="frmRole" [(ngModel)]="searchUserInput" #searchuserbar
            (keyup)="fetchRoleSeries(searchuserbar.value)" [matAutocomplete]="userauto" placeholder="Search here ..."
            style="width: 95%;">
          <mat-autocomplete #userauto="matAutocomplete">
            <div *ngFor="let role of searchRoleList">
              <mat-option class="mat-opt-align"
                *ngIf="role.displayname.toLowerCase().includes(searchUserInput.toLowerCase())"
                (onSelectionChange)="selectedCopyDataUser($event, role)" value="{{ role.displayname }}"
                title="{{ role.displayname }}">
                <span>{{ role.displayname }}</span>
              </mat-option>
            </div>
          </mat-autocomplete>

        </mat-form-field>

      </div>
    </div>

    <div class="row align-center mt-2 ml-3 float-left" *ngIf="templateData.menuURL.toString().toLowerCase()  !== 'quality attributes'">
        <div class="dml-btn" *ngIf="(!backBtn && !postUpload) || (backBtn)" >
            <button mat-raised-button class="Expbtn mr-4" (click)="backToParent()">
              Back
            </button>
        </div>
    </div>

    <div class="row align-center mt-2" *ngIf="templateData.menuURL.toString().toLowerCase()  == 'quality attributes'">
      <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
        <button mat-raised-button class="Expbtn mr-4" (click)="backToParent()">
            Back
          </button>
        </div>

      <div class="dml-btn" >
        <button mat-raised-button class="Expbtn"
         [disabled]="disableGetDataBtn() || postUpload"
          (click)="getDataToCopy()">Get Data</button>
      </div>
    </div>

  </form>
  <div *ngIf="gridWidth > 0">
    <wj-flex-grid #flexgrid [isReadOnly]="true" (initialized)="initializeGrid(flexgrid)"
      [itemsSource]="gridData" class="grid-height mt-3" (formatItem)="formatItem(flexgrid, $event)" [frozenColumns]="2">
      <div *ngFor="let columnname of sheetHeader">
        <div *ngIf="templateData.menuURL.toString().toLowerCase()  == 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'attrid') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname == 'parentid') && !(columnname == 'organizationid')"
            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [visible]="!(columnname == 'attrid') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname == 'parentid') && !(columnname == 'organizationid')"
            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <div *ngIf="templateData.menuURL.toString().toLowerCase()  != 'quality attributes'">
          <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
            [header]="columnname"
            [visible]="!(columnname == 'Status') && !(columnname == 'AttrOrder') && !(columnname == 'AttrId') && !(columnname?.toString().toLowerCase() == 'id') && !(columnname.toLowerCase() == 'organizationid')"
            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [visible]="!(columnname == 'AttrId') && !(columnname == 'AttrOrder')  && !(columnname?.toString().toLowerCase() == 'id')  && !(columnname.toLowerCase() == 'organizationid')"
            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <br />
    <div *ngIf="!backBtn && !postUpload" class="row align-center pl-3 mt-2 ml-5 align-center">
      <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0" ng-init='checkStatus=false'
        [checked]='checkStatus' ng-model='checkStatus' (change)='checkValue($event)' class="border-style">
      <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
    </div>
    <div class="row align-center mt-4">
      <div *ngIf="!backBtn" class="dml-btn mr-4">
        <button *ngIf="postUpload" mat-raised-button class="Expbtn mr-4" (click)="backToParent()">
          Finish
        </button>
        <button mat-raised-button *ngIf="postUpload" class="Expbtn" (click)="ExportExcel(flexgrid)">Download
          Result</button>
      </div>
      <div *ngIf="!postUpload" class="align-center">
        <button mat-raised-button class="Expbtn" [disabled]="selectedItems.length==0 "
          (click)="uploadCopyData(flexgrid)">Approve</button>
      </div>
    </div>
  </div>

  <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
    <div class="col-sm-10 nodata-wrap">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
</div>
