import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroupDirective, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import * as wjCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { cloneDeep } from 'lodash';

export interface Task {
  color: ThemePalette;
}

declare var $: any;
declare var jQuery: any;
export class Panel {
  constructor(public name: string, public code: string) { }
}
@Component({
  selector: 'app-bulk-upload-tests',
  templateUrl: './bulk-upload-tests.component.html',
  styleUrls: ['./bulk-upload-tests.component.scss']
})

export class BulkUploadTestsComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  gridDisplay: boolean = false;
  gridEmpty: boolean = false;
  excelDataArray = [];
  gridArray = [];
  gridWidth: number = 0;
  gridData: wjcCore.CollectionView<any>;
  stainGridData: wjcCore.CollectionView<any>;
  selectedrowData: any = {};
  sheetHeader: string[];
  SubMenuCardModel: SubMenuCardModel;
  isProperfile: boolean;
  invalidColumns: string = "";
  showInvalidColumns: boolean = false;
  highlightNotes = true;
  showDelete: boolean = true;
  uploadClicked: boolean = false;
  addEditScreen: boolean = false;
  @ViewChild('flexgrid') stainGridExport: WjFlexGrid;

  gridPage: boolean = true;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;

  @Input()
  public templateData: any;
  postDownload: boolean = false;
  postUpload: boolean = false;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  gridheader: any = [];
  sheetsToSelect: any;
  selectedSheet: any;
  worksheet: any;
  workBook: any;
  length: number;
  panelCtrl: UntypedFormControl;
  panelDropdown = [];
  action: string = "";
  currentData: any = {};
  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  readonlyTestSequence: boolean = false;
  disableApprove: boolean = false;
  serviceType: string[];
  sendOutBillTo: any = [];
  organizationOrderCodes: any;
  sendOutFlags: any;
  spawnStatus: any;
  accountCaseTypes: any = [];
  associatedAccounts: any;
  orderCodeError: boolean = false;
  defaultsendoutlabidError: boolean = false;
  pidError: boolean = false;
  defaultsplitintlabError: boolean = false;
  defaultsendoutservicetypeError: boolean = false;
  defaultsendoutbilltoError: boolean = false;
  spawncasetypeError: boolean = false;
  sendoutflagError: boolean = false;
  spawnstatusError: boolean = false;
  spawntestonservicetype: boolean = false;
  sendOutFullfilledTest: any;
  copyDataClicked: boolean;
  DeploymentKeys: any;
  destDeployment: string;
  organizationList: any;
  searchResult: any = [];
  noData: boolean;
  copyFlag: any;
  selector: Selector;
  selectedData: any[];
  checkStatus: boolean = false;
  srcDeployment: string;

  arrowIconSubject1 = new BehaviorSubject('arrow_drop_down1');
  arrowIconSubject2 = new BehaviorSubject('arrow_drop_down2');
  arrowIconSubject3 = new BehaviorSubject('arrow_drop_down3');
  arrowIconSubject4 = new BehaviorSubject('arrow_drop_down4');
  arrowIconSubject5 = new BehaviorSubject('arrow_drop_down5');
  arrowIconSubject6 = new BehaviorSubject('arrow_drop_down6');
  arrowIconSubject7 = new BehaviorSubject('arrow_drop_down7');
  arrowIconSubject8 = new BehaviorSubject('arrow_drop_down8');
  arrowIconSubject9 = new BehaviorSubject('arrow_drop_down9');
  arrowIconSubject10 = new BehaviorSubject('arrow_drop_down10');
  copyClickedSequence: boolean = false;
  hiddenColumns: any = [];

  constructor(public _snackbar: MatSnackBar, private _fb: UntypedFormBuilder, private dialog: MatDialog,
    private datashare: DataShareService,
    private commonService: CommonService, private vitalHttpServices: VitalHttpServices, public ngxService: NgxUiLoaderService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    );
  }

  testForm = this._fb.group({
    frmpanel: ["", Validators.required],
    frmprocedure: ["", Validators.required],
    frmcptcodes: ["", Validators.required],
    frmtestsequence: ["", Validators.required],
    frmsequence: ["", Validators.required],
    frmbillingtype: [""],
    frmdefaultsendoutservicetype: [""],
    frmsendoutfullfilledtestaction: [""],
    frmdefaultsendoutbillto: [""],
    frmspawntestonservicetype: [""],
    frmspawncasetype: [""],
    frmsendoutflag: [""],
    frmspawnstatus: [""],
    frmordercodeid: [""],
    frmdefaultsendoutlabid: [""],
    frmprocedurecode: [""],
    frmprocedurename: [""],
    frmmachinetestcode: [""],
    frmpid: [""],
    frmsummarydisplayname: [""],
    frmprocedureabbreviation: [""],
    frmdefaultsplitintlab: [""],
    frmtestid: null,
    frmprocedureid: null,
    frmactive: true,
    frmhasallsitesummary: false,
    frmisquicktest: false,
    frmisownsummary: false,
    frmhascustomresults: false,
    frmiskitlotrequired: false,
    frmisbillableonce: false,
    frmisdataSetrequired: false,
    frmisscreeningtype: false,
    frmsplitteststatuspending: false,
    frmglobalsendout: false,
    frmcasetypemnemonic: [""]
  })

  AllFields = [{}]
  MandatoryFields = [{}]

  ngOnInit(): void {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.showStainData(this.templateData.submenuData) : this.noDataFound = true;
    this.GetButtondetails();
  }

  clearOrganization() {
    this.copyStainsMethodForm.patchValue({
      frmOrganization: ''
    })
  }

  disableTestSeq(selectedValue, action) {
    if (action == '' || selectedValue.source._selected) {
      let value = selectedValue.source ? selectedValue.source.value : selectedValue;
      let panel = this.panelDropdown.filter(va => va.Panel.toString().toLowerCase().trim() === value.toString().toLowerCase().trim())
      if (!this.action.includes('Edit')) {
        if (panel.length > 0) {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: panel[0].Sequence.toString() != "" ? panel[0].Sequence.toString() : ""
          })

          // this.readonlyTestSequence = true;
        }
        else {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: ""
          })
          this.formcontrol['frmtestsequence'].setErrors(null);

        }
      }
      else {
        if (panel.length > 0) {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: panel[0].Sequence.toString() != "" ? panel[0].Sequence.toString() : ""
          })

        }

        this.formcontrol['frmtestsequence'].setErrors(null);
      }

      if (this.testForm.value.frmpanel && this.panelDropdown.some(va => va.Panel.toLowerCase() == value.toLowerCase())) {
        this.readonlyTestSequence = true;
      }
      else {
        this.readonlyTestSequence = false;
      }
    }
  }

  // Upload screen
  loadUploadScreen() {
    this.uploadClicked = true;
    this.gridPage = false;
    let queryVariable = { tablename: 'AccountProcedures', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          allFields['Panel'] = null
          allFields['ProcedureName'] = null
          allFields['Sequence'] = null
          for (let i = 0; i < data.data.allFields.length; i++) {
            allFields[data.data.allFields[i]["Column"]] = null
          }
          this.AllFields[0] = allFields
          delete this.AllFields[0]['accountid']
          delete this.AllFields[0]['casetype']
          delete this.AllFields[0]['testid']
          delete this.AllFields[0]['procedureid']
          delete this.AllFields[0]['AccountProcedureGUID']
          delete this.AllFields[0]['TestSequence']
          delete this.AllFields[0]['IsDefaultTest']
          delete this.AllFields[0]['CreatedDate']
          delete this.AllFields[0]['CreatedBy']
          delete this.AllFields[0]['ModifiedDate']
          delete this.AllFields[0]['ModifiedBy']
          this.MandatoryFields[0]['Panel'] = null
          this.MandatoryFields[0]['ProcedureName'] = null
          this.MandatoryFields[0]['CPTCodes'] = null
        }
      }
    }, error => {
      console.error(error);
    });
  }



  // Download Stain data
  exportStainData(exportType) {
    let configurationData: any = []
    let filename;
    filename = 'Additional Stains_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';

    let exportDataValue: any = [];
    let exportData = this.templateData.submenuData;
    for (let i = 0; i < exportData.length; i++) {
      let primary = {};
     for (let [key, value] of Object.entries(exportData[i])) {
       if(!value){
          value = 'Not Specified';
          primary[key] = value;
       }
       else{
        primary[key] = value;
       }
     }
     exportDataValue.push(primary)
    }
    exportData= exportDataValue;
    for (let i = 0; i < exportData.length; i++) {
      delete exportData[i]["AccountId"]
      delete exportData[i]["testid"]
      delete exportData[i]["Procedure_Id"]
      delete exportData[i]["Id"]
      delete exportData[i]["OFAccountId"]
      delete exportData[i]["PId"]
      delete exportData[i]["AlternateProcedureId"]
      delete exportData[i]["ResultReceivedDeviceId"]
      delete exportData[i]["ResultReceivedDeviceId"]
      delete exportData[i]["OrderSentDeviceId"]
      delete exportData[i]["AccountProcedureGUID"]
      delete exportData[i]["IsDefaultTest"]
    }
    if (exportType.match(/raw/i)) {
      var ws = XLSX.utils.json_to_sheet(
        exportData
      );
    }
    else {
      for (let i = 0; i < this.templateData.submenuData.length; i++) {
        let obj =
        {
          "Panel": this.templateData.submenuData[i]["Panel"],
          "ProcedureName": this.templateData.submenuData[i]["Procedure_Name"],
          "CPTCodes": this.templateData.submenuData[i]["CPT_Code"],
          "IsActive": (this.templateData.submenuData[i]["Status"] == 'Active' ? 1 : this.templateData.submenuData[i]["Status"] == 'Inactive' ? 0 : ""),
          "ISQUICKTEST": this.templateData.submenuData[i]["ISQUICKTEST"],
          "SpawnTestOnServiceType": this.templateData.submenuData[i]["SpawnTestOnServiceType"],
          "SpawnCaseType": this.templateData.submenuData[i]["SpawnCaseType"],
          "SpawnCaseTypeMnemonic": this.templateData.submenuData[i]["SpawnCaseTypeMnemonic"],
          "SendOutFlag": this.templateData.submenuData[i]["Send_Out_Flag"],
          "SendOutFullfilledTestAction": this.templateData.submenuData[i]["Send_Out_Fullfilled_Test_Action"],
          "Sequence": this.templateData.submenuData[i]["Sequence"],
          "IsKitLotRequired": this.templateData.submenuData[i]["IsKitLotRequired"],
          "IsDataSetRequired": this.templateData.submenuData[i]["IsDataSetRequired"],
          "DefaultSendOutLab": this.templateData.submenuData[i]["Default_Send_Out_Lab"],
          "DefaultSendOutServiceType": this.templateData.submenuData[i]["Default_Send_Out_Service_Type"],
          "DefaultSendOutBillTo": this.templateData.submenuData[i]["Default_Send_Out_Bill_To"],
          "SpawnStatus": this.templateData.submenuData[i]["SpawnStatus"],
          "TSequence": this.templateData.submenuData[i]["Procedure_Sequence"],
          "IsScreeningType": this.templateData.submenuData[i]["Is_Screening_Type"],
          "MachineTestCode": this.templateData.submenuData[i]["Machine_Test_Code"],
          "ProcedureCode": this.templateData.submenuData[i]["Procedure_Code"],
          "HasCustomResults": this.templateData.submenuData[i]["Has_Custom_Results"],
          "GlobalSendOut": this.templateData.submenuData[i]["GlobalSendOut"],
          "IsTIQ": this.templateData.submenuData[i]["Is_TIQ"],
          "OrderCodeID": this.templateData.submenuData[i]["Order_Code"],
          "ResultReceivedDeviceId": this.templateData.submenuData[i]["ResultReceivedDeviceId"],
          "OrderSentDeviceId": this.templateData.submenuData[i]["OrderSentDeviceId"],
          "isOrderablethroughPanel": this.templateData.submenuData[i]["isOrderablethroughPanel"],
          "CreatedBy": this.templateData.submenuData[i]["CreatedBy"],
          "CreatedDate": this.templateData.submenuData[i]["CreatedDate"],
          "ModifiedBy": this.templateData.submenuData[i]["ModifiedBy"],
          "ModifiedDate": this.templateData.submenuData[i]["ModifiedDate"]
        }
        configurationData.push(obj)
      }
      let exportConfigData : any = [];
      for (let i = 0; i < configurationData.length; i++) {
        let primary = {};
       for (let [key, value] of Object.entries(configurationData[i])) {
         if(!value){
            value = 'Not Specified';
            primary[key] = value;
         }
         else{
          primary[key] = value;
         }
       }
       exportConfigData.push(primary)
      }
      configurationData= exportConfigData;
      var ws = XLSX.utils.json_to_sheet(
        configurationData
      );
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'accountprocedures');
    XLSX.writeFile(wb, filename);
  }

  showStainData(templateData) {
    let gridarray = []
    let primary = {}
    this.gridheader = [
      'Status',
      'PId',
      // 'IsDefaultTest',
      'ISQUICKTEST',
      'SpawnTestOnServiceType',
      'SpawnCaseType',
      'SpawnCaseTypeMnemonic',
      'OFAccountId',
      'PId',
      'IsOwnDiagnosis',
      'IsOwnSummary',
      'IsKitLotRequired',
      'IsDataSetRequired',
      'SummaryDisplayName',
      'HasAllSiteSummary',
      'SpawnStatus',
      'BillableOnce',
      'Procedure_Code',
      'RefData',
      'AlternateProcedureId',
      'Default_SplitIntLab',
      'SplitTestStatusPending',
      'Has_Custom_Results',
      'ProcedureAbbreviation',
      'GlobalSendOut',
      'Is_TIQ',
      'ResultReceivedDeviceId',
      'OrderSentDeviceId',
      'isOrderablethroughPanel',
      'ConsiderForThreshold',
      'TriggerEvent',
      'DepartmentDetails',
      'CopyAsAttachment',
      'OFAccountId',
      'SpawnCaseTypeMnemonic',
      'SpawnCaseType',
      'Procedure_Id',
      'testid',
      'SpawnTestOnServiceType',
      'BillingType',
      'Id',
      'Send_Out_Fullfilled_Test_Action',
      'Send_Out_Flag',
      'Default_Send_Out_Lab',
      'Default_Send_Out_Service_Type',
      'Default_Send_Out_Bill_To',
      'Is_Screening_Type',
      'Machine_Test_Code',
      'Order_Code',
      'TestSequence',
      'Sequence',
      'Procedure_Sequence',
      'CPT_Code',
      'Procedure_Name',
      'Panel',
      'CreatedBy',
      'CreatedDate',
      'ModifiedBy',
      'ModifiedDate'
    ];
    this.gridheader.reverse();
    this.ngxService.start();

    for (let i = 0; i < templateData.length; i++) {
      templateData[i].TestSequence = templateData[i].Sequence

      if (this.copyClickedSequence) {
        templateData[i].TestSequence = templateData[i].Sequence
      }
      primary = {}
      for (let [key, value] of Object.entries(templateData[i])) {
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            if (key == "ISQUICKTEST" || key == "GlobalSendOut" || key == "Has_Custom_Results" || key == "Is_Screening_Type" || key == "TriggerEvent"
              || key == "SplitTestStatusPending" || key == "IsKitLotRequired" || key == "IsDataSetRequired" || key == "BillableOnce"
              || key == "HasAllSiteSummary" || key == "IsOwnSummary") {
              if (value == null) {
                value = false;
              }
            }
            else if (value == null) {
              value = 'Not Specified';
            }
            primary[key] = value
          }
        }
        if(!value){
          value = 'Not Specified';
          primary[key] = value;
        }
        primary[key] = value;
      }
      gridarray.push(primary)
    }
    console.log(gridarray);

    gridarray.sort((a, b) => a.Panel < b.Panel ? -1 : 1).sort((a, b) => a.TSequence < b.TSequence ? -1 : 1)
    // .sort((a, b) => a.TSequence < b.TSequence ? -1 : 1)
    this.noDataFound = gridarray.length > 0 ? false : true;
    this.stainGridData = new CollectionView(gridarray, {
      groupDescriptions: ['Panel']
      , pageSize: 10
    })
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    this.ngxService.stop();
  }
  // Back to upload
  refreshGrid() {
    this.uploadClicked = false;
    this.noDataFound = false;
    this.noData = false;
    this.copyDataClicked = false;
    this.copyClickedSequence = true;
    this.gridPage = true;
    this.addEditScreen = false;
    let query;
    this.removeGrid();
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
    if (this.destDeployment.toLowerCase() == 'thx' || this.destDeployment.toLowerCase() == 'thxvar') {
      query = this.SubMenuCardModel.GetQuery("casetypestainlistthx");
    }
    else {
      query = this.SubMenuCardModel.GetQuery("casetypestainlist");
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.templateData.submenuData = data.data.submenuData;
        this.testForm.reset();
        this.copyStainsMethodForm.controls.frmOrganization.enable();
        this.copyStainsMethodForm.controls.frmDepKey.enable();
        this.showStainData(this.templateData.submenuData);
      }
    }, error => {
      console.error(error);
    });
    this.searchResult = [];
  }

  //To get the fresh data
  refreshData() {
    let query;
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
    if (this.destDeployment.toLowerCase() == 'thx' || this.destDeployment.toLowerCase() == 'thxvar') {
      query = this.SubMenuCardModel.GetQuery("casetypestainlistthx");
    }
    else {
      query = this.SubMenuCardModel.GetQuery("casetypestainlist");
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.templateData.submenuData = data.data.submenuData;
        this.testForm.reset();
        this.copyStainsMethodForm.controls.frmOrganization.enable();
        this.copyStainsMethodForm.controls.frmDepKey.enable();
        this.showStainData(this.templateData.submenuData);
      }
    }, error => {
      console.error(error);
    });
    this.searchResult = [];
  }

  // Download All Fields
  downloadAllFields() {
    let filename = 'Additional Stains_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.AllFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'AccountProcedures');
    ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  // Download Mandatory Fields
  downloadMandatoryFields() {
    let filename = 'Additional Stains_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'AccountProcedures');
    ws = XLSX.utils.json_to_sheet(this.sampleDataMinimalFields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  sampleDataMinimalFields = [{
  Panel: 'Panel'
    , ProcedureName: 'ProcedureName'
    , CPTCodes: 'C123'
  }]

  // sample data for excel
  sampleDataAllFields = [{
    Panel: 'panel'
    , ProcedureName: 'ProcedureName'
    , isactive: '1/0'
    , ISQUICKTEST: '1/0'
    , BillingType: 'BillingType'
    , SpawnTestOnServiceType: 'SpawnTestOnServiceType'
    , SpawnCaseType: 'SpawnCaseType'
    , SpawnCaseTypeMnemonic: 'SpawnCaseTypeMnemonic'
    , SendOutFlag: 'SendOutFlag'
    , SendOutFullfilledTestAction: 'SendOutFullfilledTestAction'
    , OFAccountId: '123'
    , PId: '123'
    , Sequence: '123'
    , IsOwnDiagnosis: '1/0'
    , IsOwnSummary: '1/0'
    , IsKitLotRequired: '1/0'
    , IsDataSetRequired: '1/0'
    , SummaryDisplayName: 'SummaryDisplayName'
    , HasAllSiteSummary: '1/0'
    , DefaultSendOutLab: '123'
    , DefaultSendOutServiceType: 'DefaultSendOutServiceType'
    , DefaultSendOutBillTo: 'DefaultSendOutBillTo'
    , SpawnStatus: 'SpawnStatus'
    , CPTCodes: 'CPTCodes'
    , TSequence: '123'
    , BillableOnce: '1/0'
    , IsScreeningType: '1/0'
    , MachineTestCode: 'MachineTestCode'
    , ProcedureCode: 'ProcedureCode'
    , RefData: 'RefData'
    , AlternateProcedureId: 'AlternateProcedureId'
    , DefaultSplitIntLab: '123'
    , SplitTestStatusPending: '1/0'
    , hasCustomResults: '1/0'
    , ProcedureAbbreviation: 'ProcedureAbbreviation'
    , GlobalSendOut: '1/0'
    , IsTIQ: '1/0'
    , OrderCodeID: '123'
    , ResultReceivedDeviceId: '123'
    , ProcessorDeviceId: '123'
    , OrderSentDeviceId: '123'
    , isOrderablethroughPanel: '1/0'
    , ConsiderForThreshold: '1/0'
    , TriggerEvent: '1/0'
    , DepartmentDetails: 'DepartmentDetails'
    , CopyAsAttachment: '1/0'
    // , IsDefaultTest: '1/0'
    , ProcedureCount: '123'
    , StainLevelResult: '1/0'
  }]




  onFileDropped($event) {
    this.onFileChange($event);
  }

  // Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'accountprocedures')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  //Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          // if (key == 'Testname' && key == 'ProcedureName' && key == 'CPTCode') {
          //   primary[key] = value.toString().trim();
          // }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()
    temp[0].push("Notes")
    temp[0].reverse()
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', type: 'Upload', tablename: 'accountprocedures', accountid: sessionStorage.getItem('Locationid'), casetype: this.templateData.cardtype.toString() , organizationid : this.templateData.secondarykeys.OrganizationId.toString() ,});
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }



  // Cancel
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.checkStatus = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.searchResult = []
    this.copyStainsMethodForm.controls.frmOrganization.enable();
    this.copyStainsMethodForm.controls.frmDepKey.enable();
    this.copyStainsMethodForm.patchValue({
      frmOrganization: ''
    })
  }

  // Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  // Validate Excel
  validateExcelData(excelArr) {
    if (!this.copyDataClicked) {
      for (let k = 0; k < excelArr.length; k++) {
        excelArr[k].panel = !excelArr[k].panel ? excelArr[k].panel : excelArr[k].panel.toString().trim()
        excelArr[k].procedurename = !excelArr[k].procedurename ? excelArr[k].procedurename : excelArr[k].procedurename.toString().trim()
        excelArr[k].cptcodes = !excelArr[k].cptcodes ? excelArr[k].cptcodes : excelArr[k].cptcodes.toString().trim()
      }
    }
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.ValidateTestData(dataArray).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.ngxService.stop();
          this.AddGridData(dataArray);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
  }

  // Upload test Data
  uploadTestData() {
    if (this.copyDataClicked) {
      let selectData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        selectData.push(this.selectedData[i]._data);
      }
      this.excelDataArray = selectData;
    }
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i].isactive == null) {
            this.excelDataArray[i].isactive = 1
          }
          else {
            this.excelDataArray[i].isactive = this.excelDataArray[i].isactive == 0 || this.excelDataArray[i].isactive.toString().toLowerCase() == 'false' ? 0 : 1
            // this.excelDataArray[i].isactive = (!this.excelDataArray[i].isactive || this.excelDataArray[i].isactive == null ||
            //   this.excelDataArray[i].isactive == '1' || this.excelDataArray[i].isactive == 'true') && (this.excelDataArray[i].isactive != 0) ? 1 : 0
            if (this.addEditScreen) {
              for (let [key, value] of Object.entries(this.excelDataArray[i])) {
                if (key != 'isactive') {
                  if (value === "null" || value === "" || value === null) {
                    this.excelDataArray[i][key] = null;
                  }
                  else {
                    this.excelDataArray[i][key] = value.toString();
                  }
                }
              }
            }
            else {
              for (let [key, value] of Object.entries(this.excelDataArray[i])) {
                if (value === "null" || value === "" || value === null) {
                  this.excelDataArray[i][key] = null;
                }
                else {
                  this.excelDataArray[i][key] = value.toString();
                }
              }
            }
          }
          delete this.excelDataArray[i]["slno"]
          delete this.excelDataArray[i]["tablename"]
          delete this.excelDataArray[i]["notes"]
          this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          this.excelDataArray[i]["accountid"] = this.templateData.cardIdentifier.toString()
          this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString()
          if (this.uploadClicked) {
            if (this.excelDataArray[i]["isactive"] == 1 || this.excelDataArray[i]["isactive"] == null || this.excelDataArray[i]["isactive"] == true || this.excelDataArray[i]["isactive"] == "1") {
              this.excelDataArray[i]["isactive"] = 1
            }
            else {
              this.excelDataArray[i]["isactive"] = 0
            }
          }
          if (this.copyDataClicked) {
            this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
          }

          if (this.copyDataClicked)  //Do not copy sequence
            this.excelDataArray[i]["tsequence"] = null;
        }
      }
      let dataArray = [];
      this.ngxService.start();
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      this.vitalHttpServices.bulkUploadTests(dataArray).subscribe(result => {
        this.ngxService.stop();
        this.copyStainsMethodForm.controls.frmOrganization.disable();
        this.copyStainsMethodForm.controls.frmDepKey.disable()
        if (!result.errors) {
          if (this.addEditScreen) {
            for (let i = 0; i < result.length; i++) {
              if (result[i].isactive == true) {
                result[i].isactive = 1
              }
              else {
                result[i].isactive = 0
              }
            }
          }
          else {
            // if(this.uploadClicked)
            // for (let i = 0; i < result.length; i++) {
            //   if (result[i].sequence == null) {
            //     result[i].sequence = result[i].testsequence
            //   }
            //   else {
            //     result[i].sequence = null
            //   }
            // }
          }
          if (result.length > 0) {
            this.postUpload = true;
            this.postDownload = true;
            this.showDelete = false;
            this.excelDataArray = [];
            for (let i = 0; i < result.length; i++) {
              result[i].Notes = result[i].NoteMessage
              result[i].accountId = result[i].accountid1
              result[i].procedureId = result[i].procedureid1
              result[i].testId = result[i].testid1
              result[i].testsequence = result[i].testsequence1;
              if (result[i]["NoteMessage"].match(/already exists/)) {
                result[i]["STATUS"] = "Ignored"
              }
            }
            this.excelDataArray = this.convertObjKeysToLower(result);
            this.ngxService.start();
            this.AddGridData(this.excelDataArray);
            this.ngxService.stop();
            let dataExistsCount = 0;
            for (let i = 0; i < result.length; i++) {
              if (Object.values(result).every(function (item: any) {
                return item.STATUS == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
            if (dataExistsCount == result.length) {
              this._snackbar.open('Stains already exist!', 'Close');
            }
            else {
              if (this.copyDataClicked) {
                this._snackbar.open('Data added successfully', 'Close');
              }
              else {
                this._snackbar.open('Data uploaded successfully', 'Close');

              }

            }
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      })
    }
  }

  // Export Grid data
  ExportExcel(flex) {
    let excel = [];
    let grid = cloneDeep(flex.rows);
    grid.forEach(e => {
      delete e._data["notemessage"];
      delete e._data['_gd']
      delete e._data['_name']
      delete e._data['_level']
      delete e._data['_isBottomLevel']
      delete e._data['_groups']
      delete e._data['_items']
      delete e._data['_path']
      delete e._data['accountid1']
      delete e._data['procedureid1']
      delete e._data['testid1']
      delete e._data['testid']
      delete e._data['procedureid']
      delete e._data['accountid']
      delete e._data['createdby']
      excel.push(e._data);
    });
    let filename = 'Additional Stains_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'accountprocedures');
    XLSX.writeFile(wb, filename);
  }


  openEditScreen(grid, event) {
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    this.selectedrowData = selectedrowData;
    this.getInputData('edit', selectedrowData);
  }

  getInputData(action, data) {
    this.panelDropdown = this.templateData.submenuData && this.templateData.submenuData.length > 0 ? [...new Map(this.templateData.submenuData.map(item =>
      [item["Panel"], item])).values()] : [];

    this.panelDropdown = this.panelDropdown.sort((a, b) => a.Panel.toLowerCase() < b.Panel.toLowerCase() ? -1 : a.Panel.toLowerCase() > b.Panel.toLowerCase() ? 1 : 0);

    if (action.toLowerCase() == 'edit') {
      this.action = 'Edit Stain'
      this.currentData = data
      this.testForm.patchValue({
        frmpanel: data.Panel,
        frmprocedure: data.Procedure_Name,
        frmactive: data.Status.toLowerCase() == 'active' ? true : false,
        frmtestsequence: (data.Sequence == 'Not Specified' || data.Sequence == '') ? "" : data.Sequence,
        frmsequence: data.Procedure_Sequence == 'Not Specified' || data.Procedure_Sequence == '' ? "" : data.Procedure_Sequence,
        frmcptcodes: data.CPT_Code,
        frmtestid: data.testid,
        frmprocedureid: data.Procedure_Id,
        frmbillingtype: (data.BillingType && data.BillingType != 'Not Specified') ? data.BillingType : '',
        frmdefaultsendoutservicetype: (data.Default_Send_Out_Service_Type && data.Default_Send_Out_Service_Type != 'Not Specified') ? data.Default_Send_Out_Service_Type : null,
        frmsendoutfullfilledtestaction: (data.Send_Out_Fullfilled_Test_Action && data.Send_Out_Fullfilled_Test_Action != 'Not Specified') ? data.Send_Out_Fullfilled_Test_Action : null,
        frmdefaultsendoutbillto: (data.Default_Send_Out_Bill_To && data.Default_Send_Out_Bill_To != 'Not Specified') ? data.Default_Send_Out_Bill_To : null,
        frmspawntestonservicetype: (data.SpawnTestOnServiceType && data.SpawnTestOnServiceType != 'Not Specified') ? data.SpawnTestOnServiceType : null,
        frmspawncasetype: (data.SpawnCaseType && data.SpawnCaseType != 'Not Specified') ? data.SpawnCaseType : '',
        frmsendoutflag: (data.Send_Out_Flag && data.Send_Out_Flag != 'Not Specified') ? data.Send_Out_Flag : null,
        frmspawnstatus: (data.SpawnStatus && data.SpawnStatus != 'Not Specified') ? data.SpawnStatus : null,
        frmordercodeid: (data.Order_Code && data.Order_Code != 'Not Specified') ? data.Order_Code : null,
        frmdefaultsendoutlabid: (data.Default_Send_Out_Lab && data.Default_Send_Out_Lab != 'Not Specified') ? data.Default_Send_Out_Lab : null,
        frmprocedurecode: (data.Procedure_Code && data.Procedure_Code != 'Not Specified') ? data.Procedure_Code : null,
        frmmachinetestcode: (data.Machine_Test_Code && data.Machine_Test_Code != 'Not Specified') ? data.Machine_Test_Code : null,
        frmpid: (data.PId && data.PId != 'Not Specified') ? data.PId : null,
        frmsummarydisplayname: (data.SummaryDisplayName && data.SummaryDisplayName != 'Not Specified') ? data.SummaryDisplayName : null,
        frmprocedureabbreviation: (data.ProcedureAbbreviation && data.ProcedureAbbreviation != 'Not Specified') ? data.ProcedureAbbreviation : null,
        frmdefaultsplitintlab: (data.Default_SplitIntLab && data.Default_SplitIntLab != 'Not Specified') ? data.Default_SplitIntLab : null,
        frmhasallsitesummary: (data.HasAllSiteSummary && data.HasAllSiteSummary != 'Not Specified') ? data.HasAllSiteSummary : false,
        frmisquicktest: (data.ISQUICKTEST && data.ISQUICKTEST != 'Not Specified') ? data.ISQUICKTEST : false,
        frmisownsummary: (data.IsOwnSummary && data.IsOwnSummary != 'Not Specified') ? data.IsOwnSummary : false,
        frmhascustomresults: (data.Has_Custom_Results && data.Has_Custom_Results != 'Not Specified') ? data.Has_Custom_Results : false,
        frmiskitlotrequired: (data.IsKitLotRequired && data.IsKitLotRequired != 'Not Specified') ? data.IsKitLotRequired : false,
        frmisbillableonce: (data.BillableOnce && data.BillableOnce != 'Not Specified') ? data.BillableOnce : false,
        frmisdataSetrequired: (data.IsDataSetRequired && data.IsDataSetRequired != 'Not Specified') ? data.IsDataSetRequired : false,
        frmisscreeningtype: (data.Is_Screening_Type && data.Is_Screening_Type != 'Not Specified') ? data.Is_Screening_Type : false,
        frmsplitteststatuspending: (data.SplitTestStatusPending && data.SplitTestStatusPending != 'Not Specified') ? data.SplitTestStatusPending : false,
        frmglobalsendout: (data.GlobalSendOut && data.GlobalSendOut != 'Not Specified') ? data.GlobalSendOut : false,
        frmcasetypemnemonic: (data.SpawnCaseTypeMnemonic && data.SpawnCaseTypeMnemonic != 'Not Specified') ? data.SpawnCaseTypeMnemonic : null,
      })
      this.readonlyTestSequence = true;
    }
    else {
      this.testForm.reset();
      this.testForm.patchValue({
        frmpanel: "",
        frmprocedure: "",
        frmactive: true,
        frmtestsequence: "",
        frmsequence: "",
        frmcptcodes: "",
        frmtestid: null,
        frmprocedureid: null,
        frmbillingtype: "",
        frmdefaultsendoutservicetype: "",
        frmcasetypemnemonic: "",
        frmsendoutfullfilledtestaction: "",
        frmdefaultsendoutbillto: "",
        frmspawntestonservicetype: "",
        frmspawncasetype: "",
        frmsendoutflag: "",
        frmspawnstatus: "",
        frmordercodeid: "",
        frmdefaultsendoutlabid: "",
        frmprocedurecode: "",
        frmprocedurename: "",
        frmmachinetestcode: "",
        frmpid: "",
        frmsummarydisplayname: "",
        frmprocedureabbreviation: "",
        frmdefaultsplitintlab: "",
        frmhasallsitesummary: false,
        frmisquicktest: false,
        frmisownsummary: false,
        frmhascustomresults: false,
        frmiskitlotrequired: false,
        frmisbillableonce: false,
        frmisdataSetrequired: false,
        frmisscreeningtype: false,
        frmsplitteststatuspending: false,
        frmglobalsendout: false
      })
      this.action = 'Create Stain'
    }
    this.gridPage = false;
    this.addEditScreen = true;
    this.getDropDownValues()
  }


  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (!fieldValue || fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName.match(/cpt/i)) {
      if (!fieldValue || fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (fieldValue && fieldValue.match(/[^a-zA-Z0-9-., /]/) != null) {
        return 'Special chars and spaces not allowed in CPT Code. Characters allowed: / , -';
      }
    }
    else if (fieldName == 'sequence') {
      if (!fieldValue || fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (fieldValue && !fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
    else if (fieldName.match(/testsequence/i)) {
      if (!fieldValue || fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (fieldValue && !fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  saveStain(action: string, data: any) {
    data.frmpanel = data.frmpanel.trim();
    data.frmprocedure = data.frmprocedure.trim();
    data.frmcptcodes = data.frmcptcodes.trim();

    const { frmpanel,
      frmprocedure,
      frmcptcodes,
      frmtestsequence,
      frmsequence,
      frmbillingtype,
      frmdefaultsendoutservicetype,
      frmsendoutfullfilledtestaction,
      frmdefaultsendoutbillto,
      frmspawntestonservicetype,
      frmspawncasetype,
      frmsendoutflag,
      frmspawnstatus,
      frmordercodeid,
      frmdefaultsendoutlabid,
      frmprocedurecode,
      frmprocedurename,
      frmmachinetestcode,
      frmpid,
      frmsummarydisplayname,
      frmprocedureabbreviation,
      frmdefaultsplitintlab,
      frmtestid,
      frmprocedureid,
      frmactive,
      frmhasallsitesummary,
      frmisquicktest,
      frmisownsummary,
      frmhascustomresults,
      frmiskitlotrequired,
      frmisbillableonce,
      frmisdataSetrequired,
      frmisscreeningtype,
      frmsplitteststatuspending,
      frmglobalsendout } = data;

    this.orderCodeError = false;
    this.defaultsendoutlabidError = false;
    this.pidError = false
    this.defaultsplitintlabError = false
    this.defaultsendoutservicetypeError = false
    this.defaultsendoutbilltoError = false
    this.spawncasetypeError = false
    this.sendoutflagError = false
    this.spawnstatusError = false
    this.spawntestonservicetype = false

    if (this.checkEmpties(data)) {
      this._snackbar.open('Please enter all the required fields before saving!', 'Close');
      return;
    }
    if (!/^[a-zA-Z0-9-,. /]+$/.test(frmcptcodes)) {
      this._snackbar.open('Special chars exist in CPT Code', 'Close');
      return;
    }
    if (!/^\d+$/.test(frmsequence) || !/^\d+$/.test(frmtestsequence)) {
      this._snackbar.open('Sequence takes only numbers as input', 'Close');
      return;
    }
    let message = ""
    if ((frmordercodeid && /^0*$/.test(frmordercodeid))) {
      this.orderCodeError = true
      this.formcontrol['frmordercodeid'].setErrors({ 'incorrect': true });
      message = 'Order Code only takes numbers as input';
    }
    if ((frmdefaultsendoutlabid && /^0*$/.test(frmdefaultsendoutlabid))) {
      this.defaultsendoutlabidError = true
      this.formcontrol['frmdefaultsendoutlabid'].setErrors({ 'incorrect': true });
      message = 'Default Sendout Lab ID only takes numbers as input';
    }
    if ((frmpid && /^0*$/.test(frmpid))) {
      this.pidError = true
      this.formcontrol['frmpid'].setErrors({ 'incorrect': true });
      message = 'PID only takes numbers as input';
    }
    if ((frmdefaultsplitintlab && /^0*$/.test(frmdefaultsplitintlab))) {
      this.defaultsplitintlabError = true
      this.formcontrol['frmdefaultsplitintlab'].setErrors({ 'incorrect': true });
      message = 'Default Split Int Lab only takes numbers as input';
    }
    if ((this.testForm.value.frmdefaultsendoutservicetype && !this.serviceType.some(st => st.toString().toLowerCase() == this.testForm.value.frmdefaultsendoutservicetype.toString().toLowerCase()))) {
      this.spawntestonservicetype = true
      this.formcontrol['frmdefaultsendoutservicetype'].setErrors({ 'incorrect': true });
      message = 'Wrong Sendout service type selected!';
    }
    if (this.testForm.value.frmspawntestonservicetype && !this.serviceType.some(st => st.toString().toLowerCase() == this.testForm.value.frmspawntestonservicetype.toString().toLowerCase())) {
      this.defaultsendoutservicetypeError = true
      this.formcontrol['frmspawntestonservicetype'].setErrors({ 'incorrect': true });
      message = 'Wrong service type selected!';
    }
    if ((this.testForm.value.frmdefaultsendoutbillto && !this.sendOutBillTo.some(st => st.toString().toLowerCase() == this.testForm.value.frmdefaultsendoutbillto.toString().toLowerCase()))) {
      this.defaultsendoutbilltoError = true
      this.formcontrol['frmdefaultsendoutbillto'].setErrors({ 'incorrect': true });
      message = 'Wrong Bill To selected!';
    }
    if ((this.testForm.value.frmspawncasetype && !this.accountCaseTypes.some(st => st.toString().toLowerCase() == this.testForm.value.frmspawncasetype.toString().toLowerCase()))) {
      this.spawncasetypeError = true
      this.formcontrol['frmspawncasetype'].setErrors({ 'incorrect': true });
      message = 'Wrong Spawn CaseType selected!';
    }
    if ((this.testForm.value.frmsendoutflag && !this.sendOutFlags.some(st => st.toString().toLowerCase() == this.testForm.value.frmsendoutflag.toString().toLowerCase()))) {
      this.sendoutflagError = true
      this.formcontrol['frmsendoutflag'].setErrors({ 'incorrect': true });
      message = 'Wrong Sendout Flag selected!';
    }
    if ((this.testForm.value.frmspawnstatus && !this.spawnStatus.some(st => st.toString().toLowerCase() == this.testForm.value.frmspawnstatus.toString().toLowerCase()))) {
      this.spawnstatusError = true
      this.formcontrol['frmspawnstatus'].setErrors({ 'incorrect': true });
      message = 'Wrong Spawn Status selected!';
    }
    if (this.testForm.value.frmordercodeid && !this.organizationOrderCodes.some(st => st.ID.toString().toLowerCase() == this.testForm.value.frmordercodeid.toString().toLowerCase())) {
      this.orderCodeError = true
      this.formcontrol['frmordercodeid'].setErrors({ 'incorrect': true });
      message = 'Wrong OrderCode selected!';
    }
    if (this.testForm.value.frmdefaultsendoutlabid && !this.associatedAccounts.some(st => st.AssociatedAccount.toString().toLowerCase() == this.testForm.value.frmdefaultsendoutlabid.toString().toLowerCase())) {
      this.defaultsendoutlabidError = true
      this.formcontrol['frmdefaultsendoutlabid'].setErrors({ 'incorrect': true });
      message = 'Wrong Sendout Lab ID selected!';
    }
    if (this.testForm.value.frmsendoutfullfilledtestaction && !this.sendOutFullfilledTest.some(st => st.toString().toLowerCase() == this.testForm.value.frmsendoutfullfilledtestaction.toString().toLowerCase())) {
      this.defaultsendoutlabidError = true
      this.formcontrol['frmsendoutfullfilledtestaction'].setErrors({ 'incorrect': true });
      message = 'Wrong Sendout Fullfilled Test Action selected!';
    }
    if (message) {
      this._snackbar.open(message, 'Close');
      return
    }

    this.checkPanelExists(action, data);
  }

  checkPanelExists(action, data) {
    let panelNotExists = false;
    let panelArray = this.templateData.submenuData && this.templateData.submenuData.some(va => va.Panel.toString().trim() == data.frmpanel.toString().trim())
    let message = this.action.match(/create/i) ? "A new panel will be added. Do you wish to continue?" :
      "The panel name will be changed. Do you wish to continue?"
    if (!panelArray) {
      if (!this.action.match(/create/i) && !panelNotExists) {
        let dialogRef = this.dialog.open(ConfirmComponent, {
          disableClose: true,
          width: '300px',
          data: { header: "", message: "", alert: "Please select a panel that already exists!", continue: "OK", cancel: "dontshow" }
        });
        return;
      }
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '413px',
        data: { header: "", message: "", alert: message, continue: "yes", cancel: "no" }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        if (result) {
          this.sendData(action, data);
        }
        else {
          return false
        }
      })
    }
    else {
      this.sendData(action, data);
    }
  }

  filterPanels(value) {
    if (!value) {
      return this.panelDropdown.sort();
    }
    let panels: any = this.panelDropdown.sort();
    return panels.filter(va => va.Panel.toLowerCase().includes(value.toLowerCase()));
  }

  async sendData(action, data) {
    let frmtestid = this.templateData.submenuData ? this.templateData.submenuData.filter(va => va.Panel == data.frmpanel) : []
    let objArr = [{
      accountid: Number(this.templateData.cardIdentifier),
      casetype: this.templateData.cardtype,
      panel: data.frmpanel.trim(),
      procedurename: data.frmprocedure.trim(),
      cptcodes: data.frmcptcodes.trim(),
      procedureid: data.frmprocedureid?data.frmprocedureid:null,
      testid: frmtestid.length > 0 ? frmtestid[0].testid : data.frmtestid,
      oldtestid: this.currentData.testid,
      isactive: data.frmactive,
      sequence: data.frmtestsequence,
      tsequence: data.frmsequence,
      modifiedby: !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid"),
      createdby: !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid"),
      billingtype: (data.frmbillingtype && data.frmbillingtype.toString().toLowerCase() != 'not specified') ? data.frmbillingtype : null,
      defaultsendoutservicetype: (data.frmdefaultsendoutservicetype && data.frmdefaultsendoutservicetype.toString().toLowerCase() != 'not specified') ? data.frmdefaultsendoutservicetype : null,
      sendoutfullfilledtestaction: (data.frmsendoutfullfilledtestaction && data.frmsendoutfullfilledtestaction.toString().toLowerCase() != 'not specified') ? data.frmsendoutfullfilledtestaction : null,
      defaultsendoutbillto: (data.frmdefaultsendoutbillto && data.frmdefaultsendoutbillto.toString().toLowerCase() != 'not specified') ? data.frmdefaultsendoutbillto : null,
      spawntestonservicetype: (data.frmspawntestonservicetype && data.frmspawntestonservicetype.toString().toLowerCase() != 'not specified') ? data.frmspawntestonservicetype : null,
      spawncasetype: (data.frmspawncasetype && data.frmspawncasetype.toString().toLowerCase() != 'not specified') ? data.frmspawncasetype : null,
      spawncasetypemnemonic: (data.frmcasetypemnemonic && data.frmcasetypemnemonic != 'Not Specified') ? data.frmcasetypemnemonic : null,
      sendoutflag: (data.frmsendoutflag && data.frmsendoutflag.toString().toLowerCase() != 'not specified') ? data.frmsendoutflag : null,
      spawnstatus: (data.frmspawnstatus && data.frmspawnstatus.toString().toLowerCase() != 'not specified') ? data.frmspawnstatus : null,
      ordercodeid: (data.frmordercodeid && data.frmordercodeid.toString().toLowerCase() != 'not specified') ? data.frmordercodeid : null,
      defaultsendoutlab: (data.frmdefaultsendoutlabid && data.frmdefaultsendoutlabid.toString().toLowerCase() != 'not specified') ? data.frmdefaultsendoutlabid : null,
      procedurecode: (data.frmprocedurecode && data.frmprocedurecode.toString().toLowerCase() != 'not specified') ? data.frmprocedurecode : null,
      machinetestcode: (data.frmmachinetestcode && data.frmmachinetestcode.toString().toLowerCase() != 'not specified') ? data.frmmachinetestcode : null,
      pid: (data.frmpid && data.frmmachinetestcode.toString().toLowerCase() != 'not specified') ? data.frmpid : null,
      summarydisplayname: (data.frmsummarydisplayname && data.frmsummarydisplayname.toString().toLowerCase() != 'not specified') ? data.frmsummarydisplayname : null,
      procedureabbreviation: (data.frmprocedureabbreviation && data.frmprocedureabbreviation.toString().toLowerCase() != 'not specified') ? data.frmprocedureabbreviation : null,
      defaultsplitintlab: (data.frmdefaultsplitintlab && data.frmdefaultsplitintlab.toString().toLowerCase() != 'not specified') ? data.frmdefaultsplitintlab : null,
      hasallsitesummary: (data.frmhasallsitesummary && data.frmhasallsitesummary.toString().toLowerCase() != 'not specified') ? data.frmhasallsitesummary : false,
      isquicktest: (data.frmisquicktest && data.frmisquicktest.toString().toLowerCase() != 'not specified') ? data.frmisquicktest : false,
      isownsummary: (data.frmisownsummary && data.frmisownsummary.toString().toLowerCase() != 'not specified') ? data.frmisownsummary : false,
      hascustomresults: (data.frmhascustomresults && data.frmhascustomresults.toString().toLowerCase() != 'not specified') ? data.frmhascustomresults : false,
      iskitlotrequired: (data.frmiskitlotrequired && data.frmiskitlotrequired.toString().toLowerCase() != 'not specified') ? data.frmiskitlotrequired : false,
      billableonce: (data.frmisbillableonce && data.frmisbillableonce.toString().toLowerCase() != 'not specified') ? data.frmisbillableonce : false,
      isdatasetrequired: (data.frmisdataSetrequired && data.frmisdataSetrequired.toString().toLowerCase() != 'not specified') ? data.frmisdataSetrequired : false,
      isscreeningtype: (data.frmisscreeningtype && data.frmisscreeningtype.toString().toLowerCase() != 'not specified') ? data.frmisscreeningtype : false,
      splitteststatuspending: (data.frmsplitteststatuspending && data.frmsplitteststatuspending.toString().toLowerCase() != 'not specified') ? data.frmsplitteststatuspending : false,
      globalsendout: (data.frmglobalsendout && data.frmglobalsendout.toString().toLowerCase() != 'not specified') ? data.frmglobalsendout : false
    }]
    try {
      if (data.frmtestsequence == 0 || data.frmsequence == 0) {
        this._snackbar.open('Invalid sequence', 'Close')
        return;
      }
      if (this.templateData.submenuData && this.templateData.submenuData.some(va => va.Panel.toString().toLowerCase().trim() == data.frmpanel.toString().toLowerCase().trim()
        && va.Procedure_Name.toString().toLowerCase().trim() == data.frmprocedure.toString().toLowerCase().trim()
        && va.CPT_Code.toString().toLowerCase().trim() == data.frmcptcodes.toString().toLowerCase().trim() && va.Procedure_Id != data.frmprocedureid)) {
        this._snackbar.open('Stain already exists', 'Close')
        return;
      }
      if (this.action.match(/create/i)) {
        this.ngxService.start();
        await this.vitalHttpServices.bulkUploadTests(objArr).toPromise().then(response => {
          this._snackbar.open(response[0].NoteMessage, "Close")
          if (action == 'new') {
            if (response[0].NoteMessage.match(/already exists/i)) {
              this._snackbar.open("This Stain already exists!", "Close");
              return;
            }
            this.refreshData()
            this.formGroupDirective.resetForm()
            this.testForm.patchValue({
              frmpanel: "",
              frmprocedure: "",
              frmactive: true,
              frmtestsequence: "",
              frmsequence: "",
              frmcptcodes: "",
              frmtestid: null,
              frmprocedureid: null,
              frmbillingtype: "",
              frmdefaultsendoutservicetype: "",
              frmsendoutfullfilledtestaction: "",
              frmdefaultsendoutbillto: "",
              frmspawntestonservicetype: "",
              frmspawncasetype: "",
              frmsendoutflag: "",
              frmspawnstatus: "",
              frmordercodeid: "",
              frmdefaultsendoutlabid: "",
              frmprocedurecode: "",
              frmprocedurename: "",
              frmmachinetestcode: "",
              frmcasetypemnemonic: "",
              frmpid: "",
              frmsummarydisplayname: "",
              frmprocedureabbreviation: "",
              frmdefaultsplitintlab: "",
              frmhasallsitesummary: false,
              frmisquicktest: false,
              frmisownsummary: false,
              frmhascustomresults: false,
              frmiskitlotrequired: false,
              frmisbillableonce: false,
              frmisdataSetrequired: false,
              frmisscreeningtype: false,
              frmsplitteststatuspending: false,
              frmglobalsendout: false
            })
          }
          else {
            if (response[0].NoteMessage.match(/already exists/i)) {
              this._snackbar.open("Stain already exists!", "Close");
              return;
            }
            this.refreshGrid();
            return;
          }
          let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
          let query = this.SubMenuCardModel.GetQuery("casetypestainlist");
          let queryResult = this.commonService.GetCardRequest(queryVariable, query);
          this.vitalHttpServices.GetData(queryResult).subscribe(data => {
            if (!data.errors) {
              this.templateData.submenuData = [];
              this.getInputData('', [])
              this.templateData.submenuData = data.data.submenuData;
              this.panelDropdown = this.templateData.submenuData && this.templateData.submenuData.length > 0 ? [...new Map(this.templateData.submenuData.map(item =>
                [item["Panel"], item])).values()] : [];
            }
          }, error => {
            console.error(error);
          });
        }
        ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
      }
      else {
        this.ngxService.start();
        let checkdbfordataexists = this.templateData.submenuData ? this.templateData.submenuData.filter(va =>
          va.Panel == objArr[0].panel
          && va.Procedure_Name == objArr[0].procedurename
          && va.CPT_Code == objArr[0].cptcodes
          && va.Sequence == objArr[0].sequence
          && va.Procedure_Sequence == objArr[0].tsequence
          && va.isactive == objArr[0].isactive

        ) : []
        if (checkdbfordataexists.length == 0) {
          let checkChangeArray = objArr.filter(va =>
            va.procedurename == this.currentData.Procedure_Name &&
              va.panel == this.currentData.Panel &&
              va.cptcodes == this.currentData.CPT_Code &&
              va.sequence == this.currentData.Sequence &&
              va.tsequence == this.currentData.Procedure_Sequence &&
              va.isactive == (this.currentData.Status == 'Active' ? 1 : 0) &&
              va.billingtype == (this.currentData.BillingType && this.currentData.BillingType != 'Not Specified') ? this.currentData.BillingType : null &&
                va.defaultsendoutservicetype == (this.currentData.Default_Send_Out_Service_Type && this.currentData.Default_Send_Out_Service_Type != 'Not Specified') || null &&
                va.sendoutfullfilledtestaction == (this.currentData.Send_Out_Fullfilled_Test_Action && this.currentData.Send_Out_Fullfilled_Test_Action != 'Not Specified') || null &&
                va.defaultsendoutbillto == (this.currentData.Default_Send_Out_Bill_To && this.currentData.Default_Send_Out_Bill_To != 'Not Specified') || null &&
                va.spawntestonservicetype == (this.currentData.SpawnTestOnServiceType && this.currentData.SpawnTestOnServiceType != 'Not Specified') || null &&
                va.spawncasetype == (this.currentData.SpawnCaseType && this.currentData.SpawnCaseType != 'Not Specified') || null &&
                va.spawncasetypemnemonic == (data.SpawnCaseTypeMnemonic && data.SpawnCaseTypeMnemonic != 'Not Specified') ? data.SpawnCaseTypeMnemonic : null &&
                va.sendoutflag == (this.currentData.Send_Out_Flag && this.currentData.Send_Out_Flag != 'Not Specified') || null &&
                va.spawnstatus == (this.currentData.SpawnStatus && this.currentData.SpawnStatus != 'Not Specified') || null &&
                va.ordercodeid == (this.currentData.Order_Code && this.currentData.Order_Code != 'Not Specified') || null &&
                va.defaultsendoutlab == (this.currentData.Default_Send_Out_Lab && this.currentData.Default_Send_Out_Lab != 'Not Specified') || null &&
                va.procedurecode == (this.currentData.Procedure_Code && this.currentData.Procedure_Code != 'Not Specified') || null &&
                va.machinetestcode == (this.currentData.Machine_Test_Code && this.currentData.Machine_Test_Code != 'Not Specified') || null &&
                va.pid == (this.currentData.PId && this.currentData.PId != 'Not Specified') || null &&
                va.summarydisplayname == (this.currentData.SummaryDisplayName && this.currentData.SummaryDisplayName != 'Not Specified') || null &&
                va.procedureabbreviation == (this.currentData.ProcedureAbbreviation && this.currentData.ProcedureAbbreviation != 'Not Specified') || null &&
                va.defaultsplitintlab == (this.currentData.Default_SplitIntLab && this.currentData.Default_SplitIntLab != 'Not Specified') || null &&
                va.hasallsitesummary == (!this.currentData.HasAllSiteSummary ? false : this.currentData.HasAllSiteSummary) &&
                va.isquicktest == (!this.currentData.ISQUICKTEST ? false : this.currentData.ISQUICKTEST) &&
                va.isownsummary == (!this.currentData.IsOwnSummary ? false : this.currentData.IsOwnSummary) &&
                va.hascustomresults == (!this.currentData.Has_Custom_Results ? false : this.currentData.Has_Custom_Results) &&
                va.iskitlotrequired == (!this.currentData.IsKitLotRequired ? false : this.currentData.IsKitLotRequired) &&
                va.billableonce == (!this.currentData.BillableOnce ? false : this.currentData.BillableOnce) &&
                va.isdatasetrequired == (!this.currentData.IsDataSetRequired ? false : this.currentData.IsDataSetRequired) &&
                va.isscreeningtype == (!this.currentData.Is_Screening_Type ? false : this.currentData.Is_Screening_Type) &&
                va.splitteststatuspending == (!this.currentData.SplitTestStatusPending ? false : this.currentData.SplitTestStatusPending) &&
            va.globalsendout == (!this.currentData.GlobalSendOut ? false : this.currentData.GlobalSendOut)
          )
          if (checkChangeArray.length == 0) {
            let dataExists = this.templateData.submenuData ? this.templateData.submenuData.filter(
              va => va.Panel == objArr[0].panel &&
                va.Procedure_Name == objArr[0].procedurename &&
                va.CPT_Code == objArr[0].cptcodes &&
                va.Id != this.currentData.Id
            ) : []
            // let procexists = this.currentData.filter(va =>
            //   va.Status == objArr[0].isactive == true ? 'Active' : 'Inactive'
            //     && va.Sequence == objArr[0].sequence
            //     && va.Procedure_Sequence == objArr[0].tsequence
            // )

            if (dataExists.length < 1) {
              await this.vitalHttpServices.updateTests(objArr).toPromise().then(response => {
                this.ngxService.stop();
                if (response.Success) {
                  this._snackbar.open("Stain updated successfully", "Close")
                  this.currentData.Procedure_Name = objArr[0].procedurename
                  this.currentData.Panel = objArr[0].panel
                  this.currentData.testid = objArr[0].testid
                  this.currentData.CPT_Code = objArr[0].cptcodes
                  this.currentData.Sequence = objArr[0].sequence
                  this.currentData.Procedure_Sequence = objArr[0].tsequence
                  this.currentData.Procedure_Name = objArr[0].procedurename
                  this.currentData.Status = objArr[0].isactive == 1 ? 'Active' : 'Inactive'

                  this.action = "Edit Stain"
                  this.refreshGrid();
                }
                else {
                  this._snackbar.open("Stain update failed", "Close")
                }
              }
              ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
            }
            else {
              this._snackbar.open("This combination of procedure and cptcodes already exists", "Close")
            }
          }
          else {
            this._snackbar.open("Please change at least one field before updating", "Close")
          }
        }
        else {
          this._snackbar.open("A stain with the same panel,procedure or CPT code already exists.", "Close")
        }
      }
      this.ngxService.stop();
    }
    catch {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    }
  }

  checkEmpties(data) {
    if (data.frmpanel == "" || data.frmprocedure == "" || data.frmsequence == "" || data.frmtestsequence == "" || data.frmcptcodes == "") {
      return true;
    }
    return false;
  }

  //#region RBAC
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
  }
  //#endregion

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  //#region Filter additionally added columns
  additionalFieldFilter(value, fieldName) {
    let distinctValue = []
    value = !value ? '' : value;
    if (fieldName == 'Default_Send_Out_Service_Type' || fieldName == 'SpawnTestOnServiceType') {
      distinctValue = this.serviceType;
      return distinctValue ? distinctValue.filter(va => va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'Default_Send_Out_Bill_To') {
      distinctValue = this.sendOutBillTo;
      this.defaultsendoutbilltoError = false
      return distinctValue ? distinctValue.filter(va => va && va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'SpawnCaseType') {
      distinctValue = [];
      if (this.testForm.value.frmdefaultsendoutlabid && this.associatedAccounts.length > 0 && this.associatedAccounts.some(va => va.AssociatedAccount == this.testForm.value.frmdefaultsendoutlabid)) {
        distinctValue = this.accountCaseTypes
      }
      return distinctValue ? distinctValue.filter(va => va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'Send_Out_Flag') {
      distinctValue = this.sendOutFlags;
      this.sendoutflagError = false
      return distinctValue ? distinctValue.filter(va => va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'SpawnStatus') {
      distinctValue = this.spawnStatus;
      this.spawnstatusError = false
      return distinctValue ? distinctValue.filter(va => va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'Order_Code') {
      distinctValue = this.organizationOrderCodes;
      this.orderCodeError = false
      return distinctValue ? distinctValue.filter(va => va.ID && (va.ID.toString().toLowerCase()).includes(value.toString().toLowerCase())) : distinctValue
    }
    if (fieldName == 'Default_Send_Out_Lab') {
      distinctValue = this.associatedAccounts;
      this.defaultsendoutlabidError = false
      return distinctValue ? distinctValue.filter(va => va.AssociatedAccount.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    // return distinctValue
    }
    if (fieldName == 'Send_Out_Fullfilled_Test_Action') {
      distinctValue = this.sendOutFullfilledTest;
      return distinctValue ? distinctValue.filter(va => va.toString().toLowerCase().includes(value.toString().toLowerCase())) : distinctValue
    }
    return distinctValue
  }
  //#endregion

  async getDropDownValues() {
    this.ngxService.start();
    // this.serviceType = ['Global', 'Technical Only'];
    await this.getDropDownServiceType();
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId.toString(), attributeType: 'SendOutBillTo' };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationAttributes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).subscribe(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.sendOutBillTo = [];
        if (data.data.submenuData && data.data.submenuData.length == 0) {
          await this.getDropDownValuesDefault();
        }
        else if(data.data.submenuData){
          data.data.submenuData.forEach((value) => { if (value.AttributeName != "-- Select --") this.sendOutBillTo.push(value.AttributeName) });
        }
        this.getOrganizationCodes()
      }
    }, error => {
      this.ngxService.stop();
      this.getOrganizationCodes()
    })
  }

  async getDropDownValuesDefault() {
    this.ngxService.start();
    // this.serviceType = ['Global', 'Technical Only'];
    let queryVariable = { orgid: '-1', attributeType: 'SendOutBillTo' };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationAttributes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.sendOutBillTo = [];
        data.data.submenuData.forEach((value) => { if (value.AttributeName != "-- Select --") this.sendOutBillTo.push(value.AttributeName) });
      }
    }, error => {
      this.ngxService.stop();
    })
  }


  async getDropDownServiceType() {
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId.toString(), attributeType: 'SendoutServicetype' };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationAttributes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).toPromise().then(async data => {
      if (!data.errors) {
        this.serviceType =  [];
        if (data.data.submenuData && data.data.submenuData.length == 0) {
          await this.getDropDownServiceTypeDefault();
        }
        else if(data.data.submenuData){
          data.data.submenuData.forEach((value) => {this.serviceType.push(value.AttributeName) });
        }
        this.getOrganizationCodes()
      }
    }, error => {
      this.getOrganizationCodes()
    })
  }

  async getDropDownServiceTypeDefault() {
    this.ngxService.start();
    // this.serviceType = ['Global', 'Technical Only'];
    let queryVariable = { orgid: '-1', attributeType: 'SendoutServicetype' };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationAttributes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.serviceType = [];
        data.data.submenuData.forEach((value) => {this.serviceType.push(value.AttributeName) });
      }
    }, error => {
      this.ngxService.stop();
    })
  }

  getAssociatedSendout() {
    this.ngxService.start();
    let queryVariable = { accid: this.templateData.cardIdentifier.toString() };
    let query = this.SubMenuCardModel.GetQuery("getAssociatedSendout");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.associatedAccounts = [];
        data.data.submenuData.forEach((value) => { this.associatedAccounts.push({ AssociatedAccount: value.AssociatedAccount, AccountName: value.AccountName }) });
        if (this.testForm.value.frmdefaultsendoutlabid && this.testForm.value.frmdefaultsendoutlabid.toString().toLowerCase() != 'not specified') {
          this.getAccountCasetypes(this.testForm.value.frmdefaultsendoutlabid)
        }
      }
    }, error => {
      this.ngxService.stop();
      if (this.testForm.value.frmdefaultsendoutlabid && this.testForm.value.frmdefaultsendoutlabid.toString().toLowerCase() != 'not specified') {
        this.getAccountCasetypes(this.testForm.value.frmdefaultsendoutlabid)
      }
    })
  }
  getSendoutFlagAndSpawnStatus() {
    this.ngxService.start();
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("getSendoutFlagAndSpawnStatus");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.spawnStatus = [];
        this.sendOutFlags = [];
        this.sendOutFullfilledTest = [];
        data.data.submenuData.forEach((value) => { if (value.Keyword == 'SendOutFlag') { this.sendOutFlags.push(value.Value) } else if (value.Keyword == 'SpawnStatus') { this.spawnStatus.push(value.Value) } else { this.sendOutFullfilledTest.push(value.Value) } });
        this.getAssociatedSendout();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error)
      this.getAssociatedSendout();
    })
  }

  getOrganizationCodes() {
    this.ngxService.start();
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId.toString() };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationOrderCodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.organizationOrderCodes = [];
        data.data.submenuData.forEach((value) => { if (value.OrderCodeID && value.Name) this.organizationOrderCodes.push({ Name: value.Name, ID: value.OrderCodeID }) });
        this.getSendoutFlagAndSpawnStatus()
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
      this.getSendoutFlagAndSpawnStatus()
    })
  }

  getAccountCasetypes(accountid) {
    this.ngxService.start();
    let queryVariable = { accid: accountid.toString() };
    let query = this.SubMenuCardModel.GetQuery("getAccountCasetypes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, this.vitalHttpServices.deploymentKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.accountCaseTypes = [];
        data.data.submenuData.forEach((value) => { this.accountCaseTypes.push(value.casetype) });
      }
    }, error => {
      this.ngxService.stop();
      console.error(error)
    });
  }

  fetchAccountCaseTypes(accountid: Number, event) {
    if (event && event.source.selected) {
      this.accountCaseTypes = []
      this.testForm.patchValue({
        frmspawncasetype: '',
        frmdefaultsendoutlabid: accountid.toString()
      })
      this.getAccountCasetypes(accountid);
    }
  }
  validateSelectedValues(fieldValue, fieldName) {
    if (fieldName.match(/defaultsendoutlabid/i)) {
      if (fieldValue && !this.associatedAccounts.some(st => st.AssociatedAccount.toString().toLowerCase() == fieldValue.toString().toLowerCase())) {
        return true
      }
      return false
    }
  }


  get formcontrol() {
    return this.testForm.controls;
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  AddGridData(data) {
    this.ngxService.start();
    this.sheetHeader;
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          let cptvalidation = false;
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              let flag = false;
              if (key.toString().toLowerCase() != 'panel' && key.toString().toLowerCase() != 'procedurename' && key.toString().toLowerCase() != 'cptcodes') {
                if (value != null) {
                  if (value.toString().toLowerCase().trim() == 'null') {
                    flag = true;
                  }
                  primary[key] = value;
                }
              }
              flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null
              if (key.toString().toLowerCase() == 'panel' || key.toString().toLowerCase() == 'procedurename' || key.toString().toLowerCase() == 'cptcodes') {
                if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                  mandatoryFieldEmpty = true;
                }
                else if (key.toString().toLowerCase() == 'cptcodes') {
                  if (value.toString().match(/[^a-zA-Z0-9\-., /]/) != null) {
                    cptvalidation = true;
                  }
                }
                primary[key] = value;
              }
              if (cptvalidation) {
                primary['notes'] = 'Special chars exist in CPT Code';
              }
              if (mandatoryFieldEmpty) {
                value = 'Mandatory field is missing';
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }
              // primary[key] = value;
            }
          }
          this.gridArray.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.excelDataArray, {
          groupDescriptions: ['panel']
        })
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
    this.ngxService.stop();

    return false;
  }

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && this.excelDataArray[i]['notes'].toString().match(/already exist/i)) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    if (this.copyDataClicked) {
      if (this.selectedData && this.selectedData.length == 0) {
        return true;
      }
    }
    return false;
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }

  //Copy from other Entity
  copyStainsMethodForm = this._fb.group({
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmAccountId: [''],
    frmcpyasinactive: false
  });

  loadCopyScreen() {
    this.noDataFound = false;
    this.copyDataClicked = true;
    this.gridPage = false;
    this.gridWidth = 0;
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.srcDeployment = this.destDeployment
    this.copyStainsMethodForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase()
    })
    this.getListOrg();
  }

  //get data in copy from Entity
  getListOrg() {
    let queryVariable = { "accountid": null, "IsListedAsOF": null };
    let query = this.SubMenuCardModel.GetQuery("accountDetails");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          this.organizationList = data.data.submenuData;
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }
  resetFormErrors() {
    Object.keys(this.testForm.controls).forEach(key => {
      this.testForm.controls[key].setErrors(null)
    });
    this.copyStainsMethodForm.controls.frmOrganization.enable()
  }

  onChangeDeployment(e, depKey) {
    this.selectedData = []
    this.copyStainsMethodForm.patchValue({
      frmOrganization: '',
      frmAccountId: '',
      frmcpyasinactive: false
    })
    this.gridWidth = 0;
    if (e.source.selected) {
      this.srcDeployment = depKey;
      this.copyStainsMethodForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: '',
        frmAccountId: '',
        frmcpyasinactive: false
      });
      this.searchResult = []
      this.excelDataArray = [];
      this.getListOrg();
    }
  }

  //get data for the second time in copy from Entity (PATCHING)
  selectedCopyDataOrganization(event, data) {
    this.selectedData = []
    if (event.source.selected && Object.keys(data).length > 0) {
      this.copyStainsMethodForm.patchValue({
        frmAccountId: data.accountid,
        frmOrganization: data.accountname
      })
      this.getDataToCopy();
    }
  }

  //Copy data from one org to another
  getDataToCopy() {
    if (typeof (this.copyStainsMethodForm.value.frmAccountId) == 'object') {
      let frmAccountId = null
      frmAccountId = this.copyStainsMethodForm.value.frmAccountId
      this.copyStainsMethodForm.value.frmAccountId = frmAccountId[0].accid
    }
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.copyStainsMethodForm.value.frmAccountId.toString() };
    let query = this.SubMenuCardModel.GetQuery("casetypestaincopylist");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyStainsMethodForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let copyData = data.data.submenuData;
        this.checkStatus = false;
        if (copyData.length == 0) {
          this.excelDataArray.length = 0
          this.gridWidth = 0;
          this.noData = true;
          return
        }
        this.gridWidth = 1;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  // validation while copying
  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.toString() == 'testid') {
          key = 'TestId';
        }
        if (key.toString() == 'Panel') {
          key = 'Panel';
        }
        else {
          key = key.toString().replace(/_/g, '')
        }
        primary[key] = value;
      }
      primary['notes'] = "";
      primary['slno'] = i + 1;
      primary['tablename'] = "accountprocedures";
      primary['casetype'] = this.templateData.cardtype.toString();
      dataForValidation.push(primary);
    }
    this.ConvertToJson("", true, dataForValidation);
  }

  ConvertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value == '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp.filter(va => !va.toString().toLowerCase().match(/empty/i));
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    if (copyData) {
      this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
      let i = 1;
      this.excelDataArray.find((d): any => {
        Object.assign(d, { slno: i, notes: '', type: 'copy', tablename: 'accountprocedures', accountid: sessionStorage.getItem('Locationid'), casetype: this.templateData.cardtype.toString() , organizationid : this.templateData.secondarykeys.OrganizationId.toString()});
        i++;
      });
    }
    else {
      this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
      let i = 1;
      this.excelDataArray.find((d): any => {
        Object.assign(d, { slno: i, notes: '', type: 'Upload', tablename: 'accountprocedures', accountid: sessionStorage.getItem('Locationid'), casetype: this.templateData.cardtype.toString() });
        i++;
      });
    }

    this.validateExcelData(this.excelDataArray);
  }

  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    this.searchResult = [];
    // this.copyStainsMethodForm.value.frmOrgID = '';
    if (!value) {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/;

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.accountname != null) {
            return series.accountname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.accountid != null) {
          return series.accountid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copyStainsMethodForm.value.frmAccountId = this.organizationList.filter(function (series: any) {
      if (series && series.accountname != null) {
        return series.accountid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
    this.resetFormErrors()

  }

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Failure' || value.toString().includes('Invalid')
          || value.toString().includes('exist')
          || value.toString().includes('Mandatory')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid'
          || value.toString().includes('approval')
          || value.toString().includes('created')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString() == 'Ignored') || (value.toString() == 'Ignore')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  // Export Grid data
  ExportExcelCopy(flexgrid) {
    let excel = [];
    let grid = cloneDeep(flexgrid.rows)
    grid.forEach(e => {

      for (let [key, value] of Object.entries(e._data)) {
        e._data[key] = value;
        if (key == 'testid1') {
          e._data['testid'] = value
        }
        else if (key == 'procedureid1') {
          e._data['procedureid'] = value
        }
        else if (key == 'accountid1') {
          e._data['accountid'] = value
        }
      }
      delete e._data['slno']
      delete e._data['notemessage']
      delete e._data['createdby']
      delete e._data['_gd']
      delete e._data['_name']
      delete e._data['_level']
      delete e._data['_isBottomLevel']
      delete e._data['_groups']
      delete e._data['_items']
      delete e._data['_path']
      delete e._data['accountid1']
      delete e._data['procedureid1']
      delete e._data['testid1']
      delete e._data['testsequence1']
      excel.push(e._data);
    });

    let filename = 'Additional Stains_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'accountprocedures');
    XLSX.writeFile(wb, filename);
  }

  initialisedGrid(flexgrid, hitCount?) {
    this.selectedData = []
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(flexgrid, {
          itemChecked: () => {
            this.selectedData = flexgrid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  checkValue(event) {
    if (event.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  // Panel
  clearPanel(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmpanel: ""
    })
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }


  // Default Send Out Service Type
  clearDfltSerType(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmdefaultsendoutservicetype: ""
    })
  }

  openOrCloseDfltSerType(evt: any, trigger1: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger1.panelOpen)
      trigger1.closePanel();
    else
      trigger1.openPanel();
  }

  //Send Out Full Filled Test Action
  clearSendoutfullfilledtestaction(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmsendoutfullfilledtestaction: ""
    })
  }

  openOrClosesendoutfullfilledtestaction(evt: any, trigger2: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger2.panelOpen)
      trigger2.closePanel();
    else
      trigger2.openPanel();
  }

  // Default Send Out Bill To

  clearDefaultsendoutbillto(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmdefaultsendoutbillto: ""
    })
  }

  openOrCloseDefaultsendoutbillto(evt: any, trigger3: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger3.panelOpen)
      trigger3.closePanel();
    else
      trigger3.openPanel();
  }

  // Default Send Out Lab Id

  clearDefaultsendoutlabid(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmdefaultsendoutlabid: ""
    })
  }

  openOrCloseDefaultsendoutlabid(evt: any, trigger4: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger4.panelOpen)
      trigger4.closePanel();
    else
      trigger4.openPanel();
  }

  // Spawn Case Type

  clearSpawncasetype(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmspawncasetype: ""
    })
  }

  openOrCloseSpawncasetype(evt: any, trigger5: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger5.panelOpen)
      trigger5.closePanel();
    else
      trigger5.openPanel();
  }

  // Send Out Flag

  clearSendoutflag(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmsendoutflag: ""
    })
  }

  openOrCloseSendoutflag(evt: any, trigger6: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger6.panelOpen)
      trigger6.closePanel();
    else
      trigger6.openPanel();
  }


  // Spawn Status
  clearSpawnstatus(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmspawnstatus: ""
    })
  }

  openOrCloseSpawnstatus(evt: any, trigger7: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger7.panelOpen)
      trigger7.closePanel();
    else
      trigger7.openPanel();
  }

  // Order Code ID

  clearOrdercodeid(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmordercodeid: ""
    })
  }

  openOrCloseOrdercodeid(evt: any, trigger8: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger8.panelOpen)
      trigger8.closePanel();
    else
      trigger8.openPanel();
  }

  // Spawn Test On Service Type

  clearSpawntestonservicetype(evt: any): void {
    evt.stopPropagation();
    this.testForm.patchValue({
      frmspawntestonservicetype: ""
    })
  }

  openOrCloseSpawntestonservicetype(evt: any, trigger9: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger9.panelOpen)
      trigger9.closePanel();
    else
      trigger9.openPanel();
  }
  shouldShowColumn(columnname: string) : boolean{
    this.hiddenColumns = ['Procedure_Id','Id','testid','Sequence','BillingType','CreatedBy','CreatedDate','ModifiedBy','ModifiedDate'];
    return !this.hiddenColumns.toString().toLowerCase().includes(columnname.toLowerCase());
  }
}
