<!-- if there is data -->
<div *ngIf="gridShow1 && !noDataFound" class="m-2 col-sm-12">
  <div class="upload-grid">
    <div class="modal-header admin-model-header mb-2 mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>Site Orders</strong>
      </h3>
    </div>
  </div>
  <div class="d-flex align-items-center ml-auto">
    <div class="mr-2 d-flex">
      <label><b>Sequence Label (Numeric)</b></label>
      <app-switch class="ml-3 flex-left" text="Enabled:Disabled" [disabled]="editBtn"
        (clicked)="[this.sitelebelaphabetflag = $event,UpdateVAEntityAttribute($event,'Sequence')]"
        [value]="this.sitelebelaphabetflag"></app-switch>
    </div>
  </div>

  <div class="col-lg-12 p-0 m-md-1 m-lg-0" *ngIf="siteordersitechemaflag">
    <wj-flex-grid class="grid-properties" #grid [isReadOnly]="true" (initialized)="initGrid(grid)"
      [allowMerging]="'Cells'" [itemsSource]="gridData" (initialized)="initializedGrid(grid)"
      [headersVisibility]="'Column'" [frozenColumns]="1">
      <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="80" [isReadOnly]="true"
        [allowMerging]=true>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button (click)="editClicked(row)" class="edit-delete-btn" [disabled]="editBtn">
            <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
          </button>
          <button (click)="removeSiteOrder(row)" class="edit-delete-btn" [disabled]="deletBtn">
            <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'Numberofjars'" [header]="'Number of Jars'" [allowMerging]=true></wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'Siteschema'" [header]="'Site Scheme'" [allowMerging]=true></wj-flex-grid-column>

      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'Sequence'" [header]="'Sequence'"></wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'SiteName'" [header]="'Site Name'"></wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="align-center mt-2">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
      </wj-collection-view-navigator>
      <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>

    <div class="col-12 mt-4 align-center">
      <div class="row button-align">
        <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="ExportExcelUpload(grid)"
          [disabled]="exportBtn">Export</button>
        <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="createdSiteOrders()"
          [disabled]="createBtn">Create</button>
        <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="loadUploadScreen()"
          [disabled]="uploadBtn">Upload</button>
        <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success mr-4"
          (click)="loadCopyScreen()" [disabled]="copyBtn">Copy</button>
        <button mat-raised-button class="admin-btn-success mr-4" (click)="OpenMangeSite()">
          Manage Site
        </button>
      </div>
    </div>
  </div>

  <div class="col-lg-12 p-0 m-md-1 m-lg-0" *ngIf="!siteordersitechemaflag">
    <wj-flex-grid class="grid-properties" #grid [isReadOnly]="true" (initialized)="initGrid(grid)"
      [allowMerging]="'Cells'" [itemsSource]="gridData" (initialized)="initializedGrid(grid)"
      [headersVisibility]="'Column'" [frozenColumns]="1">
      <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="80" [isReadOnly]="true"
        [allowMerging]=true>
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button (click)="editClicked(row)" class="edit-delete-btn" [disabled]="editBtn">
            <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
          </button>
          <button (click)="removeSiteOrder(row)" class="edit-delete-btn" [disabled]="deletBtn">
            <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'Numberofjars'" [header]="'Number of Jars'" [allowMerging]=true></wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'Sequence'" [header]="'Sequence'"></wj-flex-grid-column>
      <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'" [fixed]="true"
        [binding]="'SiteName'" [header]="'Site Name'"></wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="align-center mt-2">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
      </wj-collection-view-navigator>
      <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>
    <div class="col-12 mt-4 align-center">
      <div class="row button-align">
        <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="exportBtn"
          (click)="ExportExcelUpload(grid)">Export</button>
        <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="createBtn"
          (click)="createdSiteOrders()">Create</button>
        <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="uploadBtn"
          (click)="loadUploadScreen()">Upload</button>
        <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success mr-4"
          (click)="loadCopyScreen()" [disabled]="copyBtn">Copy</button>
        <button mat-raised-button class="admin-btn-success mr-4" (click)="OpenMangeSite()">
          Manage Site
        </button>
      </div>
    </div>
  </div>


</div>
<!-- if there is no data -->
<div class="col-sm-12" *ngIf="noDataFound">
  <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>Site Orders</strong>
    </h3>
  </div>
  <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
  <div class="col-12 mt-4 align-center">
    <div class="row button-align">
      <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="createdSiteOrders()"
        [disabled]="createBtn">Create</button>
      <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="loadUploadScreen()"
        [disabled]="uploadBtn">Upload</button>
      <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success mr-4" (click)="loadCopyScreen()"
        [disabled]="copyBtn">Copy</button>
      <button mat-raised-button class="admin-btn-success mr-4" (click)="OpenMangeSite()">
        Manage Site
      </button>
    </div>
  </div>
</div>

<!-- Create Sites -->
<div *ngIf="createdpage">
  <div class="row col-sm-12 p-0 m-0">
    <div class="col-sm-12 modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>{{ action }}</strong>
      </h3>
    </div>
    <form [formGroup]="frm_SiteOrder" autocomplete="off" class="col-sm-12 p-0 text-wrap">
      <div class="p-3 ml-0">
        <div class="col-sm-12 mt-2 box-align p-0">
          <div class="row col-sm-12">
            <div class="float-left col-sm-4 pr-1 pl-0">
              <div *ngIf="action=='Edit Site Orders'">
                <mat-form-field class="example-additional-selection p-0 pr-3" appearance="outline">
                  <mat-label>No of Jars</mat-label>
                  <input matInput type='text' #searchInput [matAutocomplete]="auto1" formControlName="frmNumberOfJars"
                    (input)="AllowOnlyNumber($event)" readonly />
                </mat-form-field>
              </div>
              <div *ngIf="action=='Create Site Orders'">
                <mat-form-field class="example-additional-selection p-0 pr-3" appearance="outline">
                  <mat-label><span class="error-msg">*</span>No of Jars</mat-label>
                  <input matInput type='number' [matAutocomplete]="auto1" formControlName="frmNumberOfJars"
                    (keyup)="filterNumberofJars(frm_SiteOrder.value.frmNumberOfJars)" />
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let Jars of filteredJarsvalue(frm_SiteOrder.value.frmNumberOfJars)"
                      (click)="filterNumberofJars(Jars)" [value]="Jars">
                      <span>{{Jars}}</span>
                    </mat-option>
                  </mat-autocomplete>

                </mat-form-field>
              </div>
            </div>

            <div class="float-left col-sm-4" *ngIf="siteordersitechemaflag">
              <div *ngIf="action=='Edit Site Orders'">
                <mat-form-field class="example-additional-selection p-0 w-80" appearance="outline">
                  <mat-label>Site Scheme</mat-label>
                  <input matInput type='text' formControlName="frmSechmaSite" readonly>
                </mat-form-field>
              </div>
              <div *ngIf="action=='Create Site Orders'">
                <mat-form-field class="example-additional-selection p-0 w-75" appearance="outline">
                  <mat-label>Site Scheme</mat-label>
                  <input matInput type='text' formControlName="frmSechmaSite">
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(action=='Create Site Orders' && sitesequenceleableshow && Jarsvalue.length>0 ) " class="col-sm-3 rightScroll">
              <mat-form-field class="example-additional-selection1" appearance="outline">
                <mat-label>Copy from Existing Site Order</mat-label>
                <mat-select class="ml-2 temp-mat-align" [(value)]="number">
                  <mat-option *ngFor="let Jars of copyJarsdataschema" (click)="copyNumberJars(Jars)" [value]="Jars">
                    <span>{{Jars}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


          </div>
          <div class="row col-sm-12">
            <div class="col-sm-12 row m-2">
              <div *ngIf="sitesequenceleableshow" class="col-sm-10 row">
                <div class="col-sm-10">
                  <div class="d-flex">
                    <label class="rightScroll">Sequence</label>
                    <label class="rightScroll site-wrap col-sm-5">Site</label>


                  </div>
                  <div *ngFor="let Number of sitelebelvalueshow;let index = index" class="col-sm-12 d-flex">
                    <label class="text-color">
                      {{Number}}.
                    </label>
                    <mat-form-field class="example-additional-selection2" appearance="outline">
                      <mat-select disableOptionCentering class="ml-2 temp-mat-align"
                        [(value)]="editvalueforsitename[index]">
                        <mat-option (click)="SaveBodySitename($event,Jars.BodyName,index)"
                          *ngFor="let Jars of uniqueData" [value]="Jars.BodyName">
                          <span>{{Jars.BodyName}}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                      <a class="rightScroll reset-all " *ngIf="index==0"
                      (click)="resetOption(frm_SiteOrder.value)">Reset All</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="col-sm-12 d-flex p-0 mt-2">
      <div class="col-sm-3 p-0">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="Refershback()">
          Back
        </button>
      </div>
      <div class="col-sm-9 button-wrap p-0">
        <button mat-raised-button class="admin-btn-success mr-4"
          (click)="CreatedSiteOrder(frm_SiteOrder.value,'close')">
          Save & Close
        </button>
        <button mat-raised-button class="admin-btn-success" *ngIf="action == 'Create Site Orders'"
          (click)="saveAndCreate(frm_SiteOrder.value,'Add')">
          Save & Create
        </button>
      </div>
    </div>

  </div>
</div>


<!-- Upload  -->
<div *ngIf="uploadClicked" class="mt-3 my-manage-form">
  <div class="modal-header admin-model-header mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong>Upload Site Orders</strong>
    </h3>
  </div>
  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
      <mat-select disableOptionCentering class="ml-2 temp-mat-align">
        <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
    <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
    </div>
    <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
      Back
    </button>
  </div>

  <div *ngIf="gridwidth > 0">
    <wj-flex-grid class="grid-properties" [isReadOnly]="true" [itemsSource]="copygridData"
      (initialized)="initGrid(grid)" #grid [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)"
      [frozenColumns]="2">
      <!-- Status -->
      <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
        [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <span
            [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
          </span>
        </ng-template>
      </wj-flex-grid-column>


      <div *ngFor="let columnname of sheetHeader">
        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <!-- Action -->
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
        [format]="'d'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
            <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="row pl-3">
      <div class="col-sm-2 p-0 mt-4">
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
          (click)="gridRemove()">Back</button>
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
          (click)="backSelect()">Back</button>
      </div>
      <div class="col-sm-10 button-wrap p-0 mt-4">
        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
          (click)="backSelect()">Cancel</button>
        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
          (click)="approveSiteOrder()">Approve</button>
        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
          (click)="backSelect()">Finish</button>
        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
          Result</button>
      </div>
    </div>
  </div>
  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
  </div>
</div>
<!-- Choose sheet to upload -->
<div class="modal" id="selectSheetModal" aria-hidden="true" data-backdrop="true" data-keyboard="false">
  <div class="modal-dialog large-model">
    <div class="modal-content">
      <div class="admin-model-header">
        <h4 class="modal-title p-1">Choose a sheet to be uploaded</h4>
      </div>
      <div class="modal-body modal-btn-wrap row">
        <div *ngFor="let sheet of sheetsToSelect">
          <button mat-raised-button class="admin-btn-success" (click)="getSheetName(sheet)">
            {{sheet}}
          </button>
        </div>
      </div>
      <div class="modal-footer cursor">
        <button type="button" mat-raised-button class="admin-btn-success" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
  <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <!-- <strong>{{action}}</strong> -->
      <strong>Copy Site Orders</strong>
    </h3>
  </div>
  <form [formGroup]="frm_SiteOrder" class="copy-collection">
    <div class="p-0 ml-0">
      <div class="col-sm-12 text-wrap-icdcode">
        <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
          <mat-label><span class="error-msg">*</span>Deployment</mat-label>
          <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
            <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
              (onSelectionChange)="onChangeDeployment($event,i)">
              <span>{{ i }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
          <mat-label><span class="error-msg">*</span>Group </mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input [readonly]="postUpload" formControlName="frmOrganization" type="text" matInput #searchbar
            (keyup)="fetchOrgSeries(frm_SiteOrder.value.frmOrganization)" [matAutocomplete]="orgauto"
            placeholder="Search here ..." style="width: 95%;">
          <mat-autocomplete #orgauto="matAutocomplete">
            <div *ngFor="let show of searchResult">
              <mat-option [disabled]="postUpload" class="mat-opt-align"
                title="{{ show.organizationname }} ({{show.organizationid}})"
                (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
          <mat-label>No of Jars</mat-label>
          <em class="fa fa-chevron-down chevron-align"></em>
          <input formControlName="frmNumberOfJars" type="number" matInput #searchbar [matAutocomplete]="orgjars"
            (keyup)="fetchJarCount(frm_SiteOrder.value.frmNumberOfJars)" placeholder="Search here ..."
            style="width: 95%;">
          <mat-autocomplete #orgjars="matAutocomplete">
            <div *ngFor="let k of searchJarResult">
              <mat-option class="mat-opt-align" title="{{ k }}" value="{{ k }}"
                (onSelectionChange)="selectedCopyDatajars($event, k)">
                <span>{{ k }}</span>
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="gridwidth > 0" class="col-sm-12">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="copygridData" (initialized)="initGrid(flexgrid,1)" #flexgrid
          (formatItem)="formateItem(flexgrid, $event)" [frozenColumns]="1">
          <!-- Status -->
          <div *ngIf="copysechmaflag">
            <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
              [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
              [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'jars'" [header]="'Jars'" [allowMerging]=true></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitescheme'" [header]="'Site Scheme'"
              [allowMerging]=true></wj-flex-grid-column>

            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sequencelabel'" [header]="'Sequence'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitename'" [header]="'Site Name'"></wj-flex-grid-column>
          </div>
          <div *ngIf="!copysechmaflag">
            <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
              [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
              [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'jars'" [header]="'Jars'" [allowMerging]=true></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sequencelabel'" [header]="'Sequence'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitename'" [header]="'Site Name'"></wj-flex-grid-column>
          </div>
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="col-sm-12 pl-0 mt-4">
          <div *ngIf="gridwidth == 0">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
          </div>

          <div *ngIf="gridwidth > 0" class="m-1 p-0">
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
              (click)="gridRemove()">Back</button>
            <span style="float: right;">
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload"
                (click)="backSelect()">Cancel</button>
              <!-- <div *ngIf="!btnDisable"> -->
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload"
                (click)="backSelect()">Finish</button>
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload" [disabled]="approveDisable()"
                (click)="approveSiteOrder('copy')">Approve</button>
              <button mat-raised-button class="admin-btn-success" *ngIf="postUpload"
                (click)="ExportExcel(flexgrid)">Download
                Result</button>
            </span>
          </div>
        </div>
      </div>

      <div class="col-sm-12 pl-0 mt-4">
        <div *ngIf="gridwidth == 0">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="gridwidth == 0">
        <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFoundCopy">
          <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>