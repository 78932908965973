import { Clipboard } from '@angular/cdk/clipboard';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {  MatAutocomplete,  MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../core/services/commonservices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { UserSession } from '../../../core/services/user.session';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { FrequencyModalComponent } from '../frequency-modal/frequency-modal.component';
import { ThemePalette } from '@angular/material/core';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'managescheduledreport-grid',
  templateUrl: './managescheduledreports.component.html',
  styleUrls: ['./managescheduledreports.component.scss'],
})
export class ManagerReportGridContainerComponent
extends BaseGridContainerComponent
  implements OnInit, OnChanges {
  showResult;
  gridheader = [
    'ConnectionName',
    'ModifiedDate',
    'Stored_Procedure',
    'Job_Name',
    'EmailSubject',
    'Title',
    'Name',
    'Report_ID',
  ];
  columns = [
    //Outer grid
    new ColDef('ModifiedDate', ' Modified Date'),
    new ColDef('ConnectionName', 'Connection Name'),
    new ColDef('Stored_Procedure', 'Stored Procedure Name'),
    new ColDef('Job_Name', 'Job Name'),
    new ColDef('EmailSubject', 'Email Subject'),
    new ColDef('Title', 'Title'),
    new ColDef('Name', 'Name'),
    new ColDef('Report_ID', 'Report ID'),
  ];

  task: Task = {
    color: 'primary',
  };

  //scheduledreportdistribution_list
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;

  public hideExportBtn: boolean = true;
  public hideCreateBtn: boolean = true;
  public hideEditBtn: boolean = true;
  public hideSendNowBtn: boolean = true;
  // public actionButtonDetails: any[];
  public Schedule_Report;
  newTempfile: File;
  userid = sessionStorage.getItem('Userid');

  // frequency - cron expression and cron description
  public cronExpression;
  public cronDescription;
  public formType;
  public cronDescriptionNew;
  hideRadio: boolean = false;

  recipientData = this._fb.group({
    editEmailTo: null,
    editEmailCc: null,
    editEmailBcc: null,
    EmailSubject: '',
    EmailBody: '',
    AlterEmailbody: '',
    name: '',
    CreatedDate: ['', Validators.required],
    ModifiedDate: ['', Validators.required],
    recipientProtectFile: ['true', Validators.required],
    recipientPassword: ['', Validators.required],
    makeEditable: true,
    title: '',
    edittoMailCtrl: ['', Validators.required],
    storedProcedure: [
      '',
      [Validators.maxLength(10), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
    ],
    templateFile: '',
    Description: '',
    Category: '',
    Version: '',
    criteria: '',
    NamedRange: '',
    description: '',
    approxGenarationTime: '',
    ConnectionName: '',
    MaxDays: '',
    CriteriaDisplayNames: '',
    IsAsynchronousReport: '',
    isScheduledReport: '',
    password: '',
    fileToBeProtected: '',
    isActive: '',
    includeHeader: '',
    ShowMaxRecord: '',
    Password_to_be_Mailed: '',
    Report_Type: '',
    Recepient_Type: '',
    JobID: null,
    roleChecked: false,
    notifyOnlyOnData: '',
    notifyBlankAttachment: false,
    IncludeDateInSubject: '',
    htmlReport: '',
    item: [],
    cronExpression: '',
    cronDescription: ''
  });
  public ScheduleReportData = this._fb.group({
    editEmailTo: [''],
    editEmailCc: [''],
    editEmailBcc: [''],
    EmailSubject: '',
    EmailBody: '',
    name: '',
    CreatedDate: ['', Validators.required],
    ModifiedDate: ['', Validators.required],
    recipientProtectFile: ['true', Validators.required],
    recipientPassword: ['', Validators.required],
    makeEditable: true,
    title: '',
    storedProcedure: [
      '',
      [Validators.maxLength(10), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
    ],
    templateFile: '',
    Description: '',
    Category: '',
    Version: 0,
    criteria: '',
    NamedRange: '',
    description: '',
    approxGenarationTime: 0,
    ConnectionName: '',
    MaxDays: 0,
    CriteriaDisplayNames: '',
    IsAsynchronousReport: '',
    isScheduledReport: '',
    password: '',
    fileToBeProtected: '',
    isActive: '',
    includeHeader: '',
    ShowMaxRecord: '',
    Password_to_be_Mailed: '',
    Report_Type: '',
    Recepient_Type: '',
    JobID: null,
    roleChecked: false,
    notifyOnlyOnData: '',
    notifyBlankAttachment: false,
    IncludeDateInSubject: '',
    item: [],
    cronExpression: '',
    cronDescription: '',
    htmlReport: '',
    AlterEmailbody: '',
    edittoMailCtrl: ['', Validators.required]
  });
  gridData: CollectionView;
  public base64split = '';
  public tableGrid = true;
  public showAuditModel = true;
  showCustomReportView;
  public ShowModalEdit = false;
  public showCustomRepEdit = false;
  public subMenuCardModel;
  public statsData;
  public ReportId;
  public EmailTo;
  public EmailCC;
  public EmailBCC;
  public EmailSubject;
  public EmailBody;
  public AlterEmailbody;
  public ReportType;
  public CreatedDate;
  public ModifiedDate;
  public Category;
  public Title;
  public StoredProcedure;
  public TemplateFile;
  public Version;
  public Description;
  public reportName;
  public ShowModal = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  removable = true;
  isProtect: boolean;
  showManageSchedulesMail = false;
  errorFlag: number = 0;
  base64;
  filename = '';

  filteredToMail: Observable<string[]>;
  filteredCcMail: Observable<string[]>;
  filteredBccMail: Observable<string[]>;

  filteredAlterToMail: Observable<string[]>;
  filteredAlterCcMail: Observable<string[]>;
  filteredAlterBccMail: Observable<string[]>;

  public editToMailData = [];
  public editCcMailData = [];
  public editBccMailData = [];

  // alternative
  public editAlterToMailData = [];
  public editAlterCcMailData = [];
  public editAlterBccMailData = [];


  allToMail: string[] = [];
  allCcMail: string[] = [];
  allBccMail: string[] = [];
  allAlterToMail: string[] = [];
  allAlterCcMail: string[] = [];
  allAlterBccMail: string[] = [];

  edittoMailCtrl = new FormControl();
  editccMailCtrl = new FormControl();
  editbccMailCtrl = new FormControl();
  editAltertoMailCtrl = new FormControl();
  editAlterccMailCtrl = new FormControl();
  editAlterbccMailCtrl = new FormControl();

  frmradioNBAN = new FormControl();
  frmradioNBAY = new FormControl();

  @ViewChild('editToMailInput') editToMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editCcMailInput') editCcMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editBccMailInput') editBccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('toMailInput') toMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccMailInput') ccMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccMailInput') bccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('toAuto') matAutocomplete: MatAutocomplete;
  @ViewChild('ccAuto') matAutocomplete1: MatAutocomplete;
  @ViewChild('bccAuto') matAutocomplete2: MatAutocomplete;

  // Alter emails


  @ViewChild('editAlterToMailInput') editAlterToMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editAlterCcMailInput') editAlterCcMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('editAlterBccMailInput') editAlterBccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('altertoMailInput') altertoMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('alterccMailInput') alterccMailInput: ElementRef<HTMLInputElement>;
  @ViewChild('alterbccMailInput') alterbccMailInput: ElementRef<HTMLInputElement>;

  @ViewChild('toAuto') matAutocomplete3: MatAutocomplete;
  @ViewChild('ccAuto') matAutocomplete4: MatAutocomplete;
  @ViewChild('bccAuto') matAutocomplete5: MatAutocomplete;

  criteria: any;
  Active: any;
  NamedRange: any;
  password: any;
  fileToBeProtected: any;
  jobId: any;
  jobName: any;

  public custReportName: any;
  public custTitle: any;
  public custStoredProcedure: any;
  public custTemplateFile: any;
  public custCategory: any;
  public custVersion: any;
  public custAsync: any;
  public custDescription: any;
  public custCriteria: any;
  public custName: any;
  public custConnectionName: any;
  public custShowMaxRecord: any;
  public selectedRoleArr = [];
  public roleArray = [];
  public editEmailTo: any;

  report_Id: any;
  custReportId: any;
  myRolesArray: any;
  custCriteriaDisplayName: any;
  versiondesc: string;
  grid: any;
  uniqdata: any;
  custNamedRange: any;
  custMaxDays: any;
  custCreatedDate: any;
  custmodifiedDate: any;
  reportData: any;
  dkey: string;
  showPaginationMainGrid: boolean;
  tempCriteria: string;
  tempCriteriaDispName: string;
  Jobs: any = [];
  showhtmlCode: boolean = false;
  showEmailData: boolean = true;
  notifyOnlyOnData: any;
  htmlReport: any;
  notifyBlankAttachment: any;
  previewEmailBody: boolean = false;
  htmlEmailBody: boolean = true;
  connectionname: any;
  dateInSubject: any;
  ModifiedBy: any;
  CreatedBy: any;
  UserDetails: any;
  modifierName: any;
  createrName: any;
  AddModel: boolean = false;
  temporgid: any;
  showpassword: boolean = false;
  tempEmailTo: any;
  tempEmailCC: any;
  tempEmailBCC: any;
  orgid: any;
  tempExport: any[];
  path: any;
  alterEmailTo: any;
  alterEmailCC: any;
  alterEmailBCC: any;
  previewAlterEmailBody: boolean = false;
  htmlAlterEmailBody: boolean = true;
  showAlterhtmlCode: boolean=false;
  showAlterEmailData: boolean=true;


  constructor(
    private injectorServiceGridContainer: InjectorService,
    public usersession: UserSession,
    public _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private vitalservice: VitalHttpServices,
    public DataShare: DataShareService,
    private clipboard: Clipboard,
    public frequencyModal: FrequencyModalComponent,
    private dialog: MatDialog
  ) {
    super(injectorServiceGridContainer);
    this.subMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalservice,
      DataShare
    );
    this.filtermethods();
    // $("#theGridTallRows.wj-flexgrid .wj-cell").attr("title","HNK");
  }


  filtermethods() {
    this.filteredToMail = this.edittoMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailTo: string | null) =>
        mailTo ? this._filter(mailTo) : this.allToMail.slice()
      )
    );

    this.filteredCcMail = this.editccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailCc: string | null) =>
        mailCc ? this._filter1(mailCc) : this.allToMail.slice()
      )
    );

    this.filteredBccMail = this.editbccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailBcc: string | null) =>
        mailBcc ? this._filter2(mailBcc) : this.allToMail.slice()
      )
    );
    this.filteredAlterToMail = this.editAltertoMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailTo: string | null) =>
        mailTo ? this._filter3(mailTo) : this.allToMail.slice()
      )
    );

    this.filteredAlterCcMail = this.editAlterccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailCc: string | null) =>
        mailCc ? this._filter4(mailCc) : this.allToMail.slice()
      )
    );

    this.filteredAlterBccMail = this.editAlterbccMailCtrl.valueChanges.pipe(
      startWith(null),
      map((mailBcc: string | null) =>
        mailBcc ? this._filter5(mailBcc) : this.allToMail.slice()
      )
    );
  }

  onCheckChange(item: Event) {
    if (item) {
      this.selectedRoleArr.push(item);
    }
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalservice.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Send Now':
          this.hideSendNowBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  onItemDeSelect(item: any) {
    if (item) {
      const found = this.selectedRoleArr.findIndex((item1) => item1 == item);
      {
        this.selectedRoleArr.splice(found, 1);
      }
    }
  }

  AddGridData() {
    let gridarray = [];
    let primary;
    this.tempExport = [...this.templateData.submenuData];
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(
            this.templateData.submenuData[i]
          )) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key.toLowerCase() === this.gridheader[j].toLowerCase()) {
                if (key.toLowerCase() == 'report_id') {
                  primary[key] = value.toString().replace(/,/g, '');
                } else {
                  if (
                    value == null ||
                    value == 'null' ||
                    value == '' ||
                    value == ' '
                  ) {
                    value = 'Not Specified';
                  }
                  primary[key] = value;
                }
              }
            }
          }
          gridarray.push(primary);
        }
      }
    }
    if (gridarray.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    //this.showRoleNames();
    if (this.report_Id != undefined) {
      this.showReportDetailsAfterEdit(this.report_Id);
    }

    this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    this.gridwidth = 120 * (this.gridheader.length - 1 + 37);
    if (this.gridwidth > 1300) this.gridwidth = 760;
  }

  ExportToExcel(data) {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Schedule Reports_';

    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgname.length > 31) {
          fileName = filename.split('_')[0].replace(' ','') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
          }
        }
          else {
            filename = filename + '_';
          }
        }
    var sheet = XLSX.utils.book_new();
    let array = [];
    for (let itr = 0; itr < data.length; itr++) {
      array.push(data[itr]);
    }
    filename = filename + sessionStorage.getItem('org_id')+'.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] +'_';
      let orgid = filename.split('_')[2];
      filename = fileName +orgid;
    }

    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, 'Schedule Report');
    XLSX.writeFile(sheet, filename);
  }

  // public GetQuery(keyword: string) {
  //   let query: string = '';
  //   let mainQueryIndex: number = this.vitalservice.mainQueryList.findIndex(
  //     (mainQuery) =>
  //       mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
  //   );
  //   if (mainQueryIndex > -1)
  //     query = this.vitalservice.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }

  editBack() {
    this.ngxService.start();
    this.ShowModalEdit = false;
    this.showCustomRepEdit = false;
    this.ShowModal = false;
    this.tableGrid = true;
    this.recipientData.reset();
    this.showCustomReportView = false;
    this.showManageSchedulesMail = false;
    this.statsData = null;
    this.EmailTo = '';
    this.EmailCC = '';
    this.EmailBCC = '';
    this.alterEmailTo = '';
    this.alterEmailCC = '';
    this.alterEmailBCC = '';
    this.EmailBody = null;
    this.AlterEmailbody = null;
    // this.report_Id=0;
    this.EmailSubject = null;
    this.editEmailTo = null;
    this.ngxService.stop();
    this.report_Id = undefined;
  }

  UpdateBackSchedule() {
    this.ngxService.start();
    this.ShowModalEdit = false;
    this.showCustomRepEdit = false;
    this.tableGrid = false;
    this.showCustomReportView = false;
    this.ShowModal = true;
    setTimeout(() => {
      this.patchValue_data();
    });
    this.ngxService.stop();
  }

  patchValue_data() {
    // this.recipientData.patchValue({
    //   EmailSubject: this.statsData[0].EmailSubject,
    //   EmailBody: this.statsData[0].EmailBody,
    //   CreatedDate: this.statsData[0].CreatedDate,
    //   ModifiedDate: this.statsData[0].ModifiedDate,
    // });

    this.filename = '';
    this.base64split = '';

    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.editAlterToMailData = [];
    this.editAlterCcMailData = [];
    this.editAlterBccMailData = [];
    this.EmailTo = [];
    this.EmailBCC = [];
    this.EmailCC = [];
    this.alterEmailTo = [];
    this.alterEmailBCC = [];
    this.alterEmailCC = [];
    this.reportName = this.statsData[0].Name;
    this.orgid = this.statsData[0].organizationID;


    this.editToMailData.push(this.statsData[0].EmailTo);
    this.tempEmailTo = this.statsData[0].EmailTo;
    if (this.tempEmailTo) {
      this.EmailTo = this.tempEmailTo.split(',');
    }
    this.editCcMailData.push(this.statsData[0].EmailCC);
    this.tempEmailCC = this.statsData[0].EmailCC;
    if (this.tempEmailCC) {
      this.EmailCC = this.tempEmailCC.split(',');
    }
    if (this.statsData[0].EmailBCC !== '') {
      this.editBccMailData.push(this.statsData[0].EmailBCC);
    }
    this.tempEmailBCC = this.statsData[0].EmailBCC;
    if (this.tempEmailBCC) {
      this.EmailBCC = this.tempEmailBCC.split(',');
    }
    // alter emails
    if (this.statsData[0].Alternate_Email_To) {
      this.editAlterToMailData.push(this.statsData[0].Alternate_Email_To);
    }
    this.tempEmailTo = this.statsData[0].Alternate_Email_To;
    if (this.tempEmailTo) {
      this.alterEmailTo = this.tempEmailTo.split(',');
    }
    if (this.statsData[0].Alternate_Email_CC) {
      this.editAlterCcMailData.push(this.statsData[0].Alternate_Email_CC);
    }
    this.tempEmailCC = this.statsData[0].Alternate_Email_CC;
    if (this.tempEmailCC) {
      this.alterEmailCC = this.tempEmailCC.split(',');
    }
    if (this.statsData[0].Alternate_Email_BCC ) {
      this.editAlterBccMailData.push(this.statsData[0].Alternate_Email_BCC);
    }
    this.tempEmailBCC = this.statsData[0].Alternate_Email_BCC;
    if (this.tempEmailBCC) {
      this.alterEmailBCC = this.tempEmailBCC.split(',');
    }

    this.report_Id = this.statsData[0].Report_ID;
    this.EmailSubject = this.statsData[0].EmailSubject;
    this.EmailBody = this.statsData[0].EmailBody;
    this.AlterEmailbody = this.statsData[0].Alternate_Email_Body;

    this.notifyOnlyOnData =
      this.statsData[0].NotifyOnlyOnData != null ? this.statsData[0].NotifyOnlyOnData.toString().toLowerCase() == 'true' ? 'Yes' : 'No' : 'Not Specified';

    this.notifyBlankAttachment = this.statsData[0].NotifyBlankAttachment != null ? this.statsData[0].NotifyBlankAttachment.toString().toLowerCase() == '1' ? 'Yes' : 'No' : 'Not Specified';
    this.htmlReport = this.statsData[0].Is_HTML_Report != null ? this.statsData[0].Is_HTML_Report.toString().toLowerCase() == '1' ? 'Yes' : 'No' : 'Not Specified';

    this.Active =
      this.statsData[0].IsActive != null
        ? this.statsData[0].IsActive.toString().toLowerCase() == 'true'
          ? 'True'
          : 'False'
        : 'Not Specified';
    // if (this.statsData[0].CreatedDate != null) {
    // this.CreatedDate = this.statsData[0].CreatedDate.replace(/T/g, ' ');
    this.CreatedDate = this.statsData[0].CreatedDate;
    this.CreatedBy = this.statsData[0].CreatedBy;
    // }
    // this.CreatedDate = this.CreatedDate.slice(0,10)
    // if (this.statsData[0].ModifiedDate != null) {
    this.ModifiedDate = this.statsData[0].ModifiedDate;
    this.ModifiedBy = this.statsData[0].ModifiedBy;
    // }
    //this.ModifiedDate = this.ModifiedDate.slice(0,10)
    this.Title = this.statsData[0].Title;
    this.TemplateFile = this.statsData[0].templateFile;
    this.Description =
      this.statsData[0].Description != '' ||
        this.statsData[0].Description != ' '
        ? this.statsData[0].Description
        : 'Not Specified';
    this.StoredProcedure = this.statsData[0].Stored_Procedure;
    this.Version = this.statsData[0].Version;
    this.Category = this.statsData[0].Category;
    if (this.statsData[0].criteria) {
      this.criteria = this.statsData[0].criteria.split(',');
    }
    this.Active =
      this.statsData[0].IsActive != null
        ? this.statsData[0].IsActive.toString().toLowerCase() == 'true'
          ? 'True'
          : 'False'
        : 'Not Specified';
    this.NamedRange = this.statsData[0].Named_Range;
    this.password = this.statsData[0].Password;
    if(!this.password) {
      this.showpassword=true;
    }
    else {
      this.showpassword=false;
    }

    this.fileToBeProtected =
      this.statsData[0].fileToBeProtected != null
        ? this.statsData[0].fileToBeProtected
        : false;
    this.jobId = this.statsData[0].JobID;
    this.cronExpression = this.statsData[0].CronExpression;
    this.cronDescription = this.statsData[0].CronDescription;
    this.cronDescriptionNew = (this.cronDescription != null && this.cronDescription != '') ? " Running " + this.cronDescription : '';
    this.jobName = this.statsData[0].Job_Name;
    this.connectionname = this.statsData[0].ConnectionName;
    this.ReportType = this.statsData[0].Schedule_Report;
    this.dateInSubject = this.statsData[0].IncludeDateInSubject.toString() == '1' ? 'Yes' : 'No';
    if (this.statsData[0].Role_name) {
      this.myRolesArray = this.statsData[0].Role_name.split(',');
      this.showEmailData = true;
      this.showhtmlCode = false;
    }
    //get user Details
    //compute query variable
    let Qstr = "";
    if (this.ModifiedBy.toLowerCase().trim() != 'not specified' && this.ModifiedBy.toLowerCase().trim() != '') {
      Qstr += "userid = \"" + this.ModifiedBy + "\"";
    }
    if (this.CreatedBy.toLowerCase().trim() != 'not specified' && this.CreatedBy.toLowerCase().trim() != '') {
      if (Qstr != "") {
        Qstr += " or userid = \"" + this.CreatedBy + "\"";
      }
      else {
        Qstr += "userid = \"" + this.CreatedBy + "\"";
      }
      // this.createrName = this.UserDetails.filter((va) => va.userid == this.CreatedBy);
    }
    if (Qstr != "") {
      //if modifier and creater are both are specified execute this code
      let queryVariable = { "userid": Qstr };
      let query = this.vitalservice.GetQuery('userDetails');
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vitalservice.GetData(queryResult).subscribe(resdata => {
        if (!resdata.errors) {
          this.UserDetails = resdata.data.submenuData

          this.modifierName = this.UserDetails.filter((va) => va.userid == this.ModifiedBy).length > 0 ?
            this.UserDetails.filter((va) => va.userid == this.ModifiedBy) : [{ firstname: "", lastname: "" }];
          this.createrName = this.UserDetails.filter((va) => va.userid == this.CreatedBy).length > 0 ?
            this.UserDetails.filter((va) => va.userid == this.CreatedBy) : [{ firstname: "", lastname: "" }];
          this.ShowModal = true;
          this.tableGrid = false;
          this.ngxService.stop();
        }
        else {
          console.error(resdata.errors);
          this._snackbar.open('No Details Available', 'Failed');
          this.ngxService.stop();
        }
      }, error => {
        console.error(error);
        this._snackbar.open('No Details Available', 'Failed');
        this.ngxService.stop();
      });
    }
    else {
      //if modifier and creater are both not specified execute this code
      this.modifierName = [{ firstname: "", lastname: "" }];
      this.createrName = [{ firstname: "", lastname: "" }];
      this.ShowModal = true;
      this.tableGrid = false;
      this.ngxService.stop();
    }
  }
  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open('', 'Copied');
  }

  showReportDetails(grid, e) {
    var hti = grid.hitTest(e);
    var row = hti.row;
    var item = hti.panel.rows[row].dataItem;
    if (item != null) {
      this.showReportDetailsAfterEdit(item.Report_ID);
    }
  }

  showReportDetailsAfterEdit(reportID) {
    if (reportID != null) {
      this.statsData = null;
      this.ngxService.start();
      this.statsData = this.templateData.submenuData.filter(
        (va) =>
          va.Report_ID == reportID //&& va.Schedule_Report == 'Scheduled Report'
      );
      if (this.statsData.length > 0) {
        this.patchValue_data();
      }
      this.ngxService.stop();
    }
  }

  clickProtect(selectedChoice: boolean) {
    this.isProtect = selectedChoice;
  }
  changeActiveAStatus(selectedChoice: boolean) {
    this.isProtect = selectedChoice;
  }

  cancelRecipient(): void {
    this.showManageSchedulesMail = false;
    this.tableGrid = false;
  }

  editEmailto(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editToMailData.push({ value: event.value, invalid: false });
      } else {
        this.editToMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.edittoMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.edittoMailCtrl.setValue(null);
  }

  editEmailcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editCcMailData.push({ value: event.value, invalid: false });
      } else {
        this.editCcMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.editccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.editccMailCtrl.setValue(null);
  }

  editEmailbcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editBccMailData.push({ value: event.value, invalid: false });
      } else {
        this.editBccMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.editbccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.editbccMailCtrl.setValue(null);
  }

  //alter emails
  editAlterEmailto(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editAlterToMailData.push({ value: event.value, invalid: false });
      } else {
        this.editAlterToMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.editAltertoMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.editAltertoMailCtrl.setValue(null);
  }

  editAlterEmailcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editAlterCcMailData.push({ value: event.value, invalid: false });
      } else {
        this.editAlterCcMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.editAlterccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.editAlterccMailCtrl.setValue(null);
  }

  editAlterEmailbcc(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.editAlterBccMailData.push({ value: event.value, invalid: false });
      } else {
        this.editAlterBccMailData.push({ value: event.value, invalid: true });
        if (event) {
          event.value = '';
        }
        this.editAlterbccMailCtrl.setValue(null);
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.editAlterbccMailCtrl.setValue(null);
  }

  // Removing emails id function
  editRemoveEmailto(editToMail: any): void {
    if (this.editToMailData.indexOf(editToMail) >= 0) {
      this.editToMailData.splice(this.editToMailData.indexOf(editToMail), 1);
    }
  }

  editRemoveEmailcc(editCcMail: any): void {
    if (this.editCcMailData.indexOf(editCcMail) >= 0) {
      this.editCcMailData.splice(this.editCcMailData.indexOf(editCcMail), 1);
    }
  }

  editRemoveEmailbcc(editBccMail: any): void {
    if (this.editBccMailData.indexOf(editBccMail) >= 0) {
      this.editBccMailData.splice(this.editBccMailData.indexOf(editBccMail), 1);
    }
  }
  // alternative emials editing
  editRemoveAlterEmailto(editToMail: any): void {
    if (this.editAlterToMailData.indexOf(editToMail) >= 0) {
      this.editAlterToMailData.splice(this.editAlterToMailData.indexOf(editToMail), 1);
    }
  }

  editRemoveAlterEmailcc(editCcMail: any): void {
    if (this.editAlterCcMailData.indexOf(editCcMail) >= 0) {
      this.editAlterCcMailData.splice(this.editAlterCcMailData.indexOf(editCcMail), 1);
    }
  }

  editRemoveAlterEmailbcc(editBccMail: any): void {
    if (this.editAlterBccMailData.indexOf(editBccMail) >= 0) {
      this.editAlterBccMailData.splice(this.editAlterBccMailData.indexOf(editBccMail), 1);
    }
  }

  selectedEditmailto(event: MatAutocompleteSelectedEvent): void {
    this.editToMailData.push(event.option.viewValue);
    this.editToMailInput.nativeElement.value = '';
    this.edittoMailCtrl.setValue(null);
  }

  selectedEditmailcc(event: MatAutocompleteSelectedEvent): void {
    this.editCcMailData.push(event.option.viewValue);
    this.editCcMailInput.nativeElement.value = '';
    this.editccMailCtrl.setValue(null);
  }

  selectedEditmailbcc(event: MatAutocompleteSelectedEvent): void {
    this.editBccMailData.push(event.option.viewValue);
    this.editBccMailInput.nativeElement.value = '';
    this.editbccMailCtrl.setValue(null);
  }

  // alter emails
  selectedEditAltermailto(event: MatAutocompleteSelectedEvent): void {
    this.editAlterToMailData.push(event.option.viewValue);
    this.editAlterToMailInput.nativeElement.value = '';
    this.editAltertoMailCtrl.setValue(null);
  }

  selectedEditAltermailcc(event: MatAutocompleteSelectedEvent): void {
    this.editAlterCcMailData.push(event.option.viewValue);
    this.editAlterCcMailInput.nativeElement.value = '';
    this.editAlterccMailCtrl.setValue(null);
  }

  selectedEditAltermailbcc(event: MatAutocompleteSelectedEvent): void {
    this.editAlterBccMailData.push(event.option.viewValue);
    this.editAlterBccMailInput.nativeElement.value = '';
    this.editAlterbccMailCtrl.setValue(null);
  }

  //edit validation

  editToEmailInvalid(): boolean {
    return this.editToMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }

  editCcEmailInvalid(): boolean {
    return this.editCcMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }

  editBccEmailInvalid(): boolean {
    return this.editBccMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }
  // alter email validation
  editAlterToEmailInvalid(): boolean {
    return this.editAlterToMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }

  editAlterCcEmailInvalid(): boolean {
    return this.editAlterCcMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }

  editAlterBccEmailInvalid(): boolean {
    return this.editAlterBccMailData.some(
      (email) => !this.validateEmail(email.value)
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allToMail.filter(
      (mailTo) => mailTo.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter1(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCcMail.filter(
      (mailCc) => mailCc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allBccMail.filter(
      (mailBcc) => mailBcc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  // alter email filterring
  private _filter3(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allAlterToMail.filter(
      (mailTo) => mailTo.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter4(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allAlterCcMail.filter(
      (mailCc) => mailCc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filter5(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allAlterBccMail.filter(
      (mailBcc) => mailBcc.toLowerCase().indexOf(filterValue) === 0
    );
  }

  closeRecipient(): void {
    this.showManageSchedulesMail = false;
    this.tableGrid = true;
  }
  tohtml() { }

  onChange(enable: boolean) {
    if (this.showhtmlCode == true) {
      this.showEmailData = true;
      this.showhtmlCode = false;
    } else {
      this.showEmailData = false;
      this.showhtmlCode = true;
    }
  }
  onAlterChange(enable: boolean) {
    if (this.showAlterhtmlCode) {
      this.showAlterEmailData = true;
      this.showAlterhtmlCode = false;
    } else {
      this.showAlterEmailData = false;
      this.showAlterhtmlCode = true;
    }
  }
  viewInEmailBody() {
    if (this.previewEmailBody == false) {
      this.previewEmailBody = true;
      this.htmlEmailBody = false;
    } else {
      this.previewEmailBody = false;
      this.htmlEmailBody = true;
    }
  }
  viewInAlterEmailBody() {
    if (this.previewAlterEmailBody == false) {
      this.htmlAlterEmailBody = false;
      this.previewAlterEmailBody = true;
    } else {
      this.previewAlterEmailBody = false;
      this.htmlAlterEmailBody = true;
    }
  }

  editScheduleReport() {
    this.tableGrid = false;
    this.ShowModal = false;
    this.showCustomReportView = false;
    this.ShowModalEdit = true;
    this.showCustomRepEdit = false;
    this.htmlEmailBody = true;
    this.htmlAlterEmailBody = true;
    this.previewAlterEmailBody = false;
    this.previewEmailBody = false;
    this.editToMailData = [];
    this.filtermethods();
    // this.editToMailData.push({ value: this.statsData[0].EmailTo });
    for (let i = 0; i < this.statsData[0].EmailTo.split(',').length; i++) {
      if (this.statsData[0].EmailTo.split(',')[i]) {
        this.editToMailData.push({
          value: this.statsData[0].EmailTo.split(',')[i],
        });
      }
    }
    this.edittoMailCtrl.setValue(null);
    this.editCcMailData = [];
    if(this.statsData[0].EmailCC != null){
    for (let j = 0; j < this.statsData[0].EmailCC.split(',').length; j++) {
      if (this.statsData[0].EmailCC.split(',')[j]) {
        this.editCcMailData.push({
          value: this.statsData[0].EmailCC.split(',')[j],
        });
      }
     }
    }
    this.editBccMailData = [];
    if(this.statsData[0].EmailBCC != null){
    for (let k = 0; k < this.statsData[0].EmailBCC.split(',').length; k++) {
      if (this.statsData[0].EmailBCC.split(',')[k] !== '') {
        this.editBccMailData.push({
          value: this.statsData[0].EmailBCC.split(',')[k],
        });
      }
    }
   }
    // alter email showing
    this.editAlterToMailData = [];
    if(this.statsData[0].Alternate_Email_To != null){
    for (let i = 0; i < this.statsData[0].Alternate_Email_To.split(',').length; i++) {
      if (this.statsData[0].Alternate_Email_To.split(',')[i]) {
        this.editAlterToMailData.push({
          value: this.statsData[0].Alternate_Email_To.split(',')[i],
        });
      }
    }
   }
    this.editAltertoMailCtrl.setValue(null);
    this.editAlterCcMailData = [];
    if(this.statsData[0].Alternate_Email_CC != null){
    for (let j = 0; j < this.statsData[0].Alternate_Email_CC.split(',').length; j++) {
      if (this.statsData[0].Alternate_Email_CC.split(',')[j]) {
        this.editAlterCcMailData.push({
          value: this.statsData[0].Alternate_Email_CC.split(',')[j],
        });
      }
    }
   }
    this.editAlterBccMailData = [];
    if(this.statsData[0].Alternate_Email_BCC != null){
    for (let k = 0; k < this.statsData[0].Alternate_Email_BCC.split(',').length; k++) {
      if (this.statsData[0].Alternate_Email_BCC.split(',')[k] !== '') {
        this.editAlterBccMailData.push({
          value: this.statsData[0].Alternate_Email_BCC.split(',')[k],
        });
      }
    }
   }

// if(this.statsData[0].IsActive == false){
//   this.statsData[0].IsActive = 1;
// }
// else if(this.statsData[0].IsActive == true){
//   this.statsData[0].IsActive = 0;

// }
    this.ngxService.start();
    this.recipientData.patchValue({
      storedProcedure: this.statsData[0].Stored_Procedure,
      title: this.statsData[0].Title,
      templateFile: this.statsData[0].templateFile,
      Category: this.statsData[0].Category,
      Version: this.statsData[0].Version,
      name: this.statsData[0].Name,
      criteria: this.statsData[0].criteria,
      NamedRange: this.statsData[0].Named_Range,
      JobID: this.statsData[0].JobID,
      notifyOnlyOnData:
        this.statsData[0].NotifyOnlyOnData == 1 ? 'true' : 'false',
      notifyBlankAttachment: this.statsData[0].NotifyBlankAttachment == 1 ? true : false,
      Description: this.statsData[0].Description.toLowerCase() == 'not specified' ? '' : this.statsData[0].Description,
      approxGenarationTime: this.statsData[0].ApproxGenerationTime,
      ConnectionName:
        this.statsData[0].ConnectionName.toLowerCase() == 'live db'
          ? null
          : this.statsData[0].ConnectionName,
      MaxDays: this.statsData[0].MaxDays,
      CriteriaDisplayNames: this.statsData[0].CriteriaDisplayNames,
      isScheduledReport:
        this.statsData[0].Schedule_Report == 1 ? 'true' : 'false',
      IsAsynchronousReport: this.statsData[0].Asynchronous_Report,
      editEmailTo: this.statsData[0].EmailTo,
      editEmailCc: this.statsData[0].EmailCC,
      editEmailBcc: this.statsData[0].EmailBCC,
      fileToBeProtected:
        this.statsData[0].fileToBeProtected == 1 ? 'true' : 'false',
      password:
        this.statsData[0].Password.toLowerCase() ==
          'dynamically generated password'
          ? ''
          : this.statsData[0].Password,
      EmailSubject: this.statsData[0].EmailSubject,
      EmailBody: this.statsData[0].EmailBody,
      AlterEmailbody: this.statsData[0].Alternate_Email_Body,
      isActive: this.statsData[0].IsActive,
      Report_Type: this.statsData[0].Report_Type != '' ? this.statsData[0].Report_Type : null,
      IncludeDateInSubject:
        this.statsData[0].IncludeDateInSubject == 1 ? 'true' : 'false',
      cronExpression: this.statsData[0].CronExpression,
      cronDescription: this.statsData[0].CronDescription,
      htmlReport:this.statsData[0].Is_HTML_Report== 1 ? 'true' : 'false',
    });
    this.cronDescriptionNew = (this.cronDescription != null && this.cronDescription != '') ? " Running " + this.statsData[0].CronDescription : '';

    this.hideRadio = this.recipientData.value.notifyOnlyOnData == 'true' ? true : false;
    if (this.hideRadio) {
      this.frmradioNBAN.disable();
      this.frmradioNBAY.disable();
    }
    else {
      this.frmradioNBAN.enable();
      this.frmradioNBAY.enable();
    }

    this.ngxService.stop();
  }

  resetTheForm() {
    this.tableGrid = false;
    this.ShowModal = false;
    this.showCustomReportView = false;
    this.ShowModalEdit = true;
    this.showCustomRepEdit = false;
    this.htmlEmailBody = true;
    this.htmlAlterEmailBody = true;
    this.previewAlterEmailBody = false;
    this.previewEmailBody = false;
    this.ngxService.start();
    this.editScheduleReport();
    this.ngxService.stop();
  }
  checkvalue() {
    this.recipientData.value;
  }

  getemailvalue(array) {
    if (array.length > 0) {
      let value = '';
      for (let i = 0; i < array.length; i++) {
        value += array[i].value + ',';
      }
      return value;
    }
  }
  //download Template ScheduleReports
  downloadTemplate(TemplateFile) {
    this.dkey = sessionStorage.getItem('deploymentKey');
    let prefix;
    prefix = this.vitalservice.GetReportLinksBasedOnDeployment(this.dkey)
    //prefix=prefix+'ScheduleReports/';
    window.open(prefix + 'ScheduleReports/' + TemplateFile);
  }
  // downloadTemplate(TemplateFile) {
  //   this.dkey = sessionStorage.getItem('deploymentKey');
  //   let prefix;
  //   if (this.dkey.toLowerCase() == 'upm') {
  //     prefix =
  //       'https://upm.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'tla') {
  //     prefix =
  //       'https://tla.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'lis') {
  //     prefix =
  //       'https://lis.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'quest') {
  //     prefix =
  //       'https://quest.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'thx') {
  //     prefix =
  //       'https://thx.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'thxvar') {
  //     prefix =
  //       'https://thxvar.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   } else if (this.dkey.toLowerCase() == 'vplabs') {
  //     prefix =
  //       'https://vplabs.vitalaxis.com/config/exceltemplates/ScheduleReports/';
  //   }
  //   window.open(prefix + TemplateFile);
  // }

  // update mail recipient -- Update existing data
  UpdateScheduleReport(UpdateMailRecipientDetails) {
    if (this.isMandatoryCheck(UpdateMailRecipientDetails, "update") == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!")
    }
    else {
      if (this.userid != undefined) {
        this.errorFlag = -1;
        this.ngxService.start();

        this.showCustomReportView = false;

        this.validatedetails(UpdateMailRecipientDetails);
        if (this.errorFlag != 1) {
          let tempToMail = [];
          this.filename=UpdateMailRecipientDetails.templateFile?UpdateMailRecipientDetails.templateFile:this.filename;
          this.editToMailData.forEach((element) => {
            if (!element.invalid) tempToMail.push(element.value);
          });
          let tempCCMail = [];
          this.editCcMailData.forEach((element) => {
            if (!element.invalid) tempCCMail.push(element.value);
          });
          let tempBCCMail = [];
          this.editBccMailData.forEach((element) => {
            if (!element.invalid) tempBCCMail.push(element.value);
          });
          let tempAlterToMail = [];
          this.editAlterToMailData.forEach((element) => {
            if (!element.invalid) tempAlterToMail.push(element.value);
          });
          let tempAlterCCMail = [];
          this.editAlterCcMailData.forEach((element) => {
            if (!element.invalid) tempAlterCCMail.push(element.value);
          });
          let tempAlterBCCMail = [];
          this.editAlterBccMailData.forEach((element) => {
            if (!element.invalid) tempAlterBCCMail.push(element.value);
          });
          UpdateMailRecipientDetails.ConnectionName == 'true' ||
            UpdateMailRecipientDetails.ConnectionName == true || UpdateMailRecipientDetails.ConnectionName == 'ReportDB'
            ? (UpdateMailRecipientDetails.ConnectionName = 'ReportDB')
            : (UpdateMailRecipientDetails.ConnectionName = null);

          UpdateMailRecipientDetails.isActive == true
            ? (UpdateMailRecipientDetails.isActive = 1)
            : (UpdateMailRecipientDetails.isActive = 0);

          UpdateMailRecipientDetails.notifyOnlyOnData == 'false' ||
            UpdateMailRecipientDetails.notifyOnlyOnData == false
            ? (UpdateMailRecipientDetails.notifyOnlyOnData = 0)
            : (UpdateMailRecipientDetails.notifyOnlyOnData = 1);

          UpdateMailRecipientDetails.notifyBlankAttachment == 'false' ||
            UpdateMailRecipientDetails.notifyBlankAttachment == false
            ? (UpdateMailRecipientDetails.notifyBlankAttachment = 0)
            : (UpdateMailRecipientDetails.notifyBlankAttachment = 1);

          UpdateMailRecipientDetails.IncludeDateInSubject == 'true'
            ? (UpdateMailRecipientDetails.IncludeDateInSubject = 1)
            : (UpdateMailRecipientDetails.IncludeDateInSubject = 0);
          UpdateMailRecipientDetails.htmlReport == 'false' ||
            UpdateMailRecipientDetails.htmlReport == false
            ? (UpdateMailRecipientDetails.htmlReport = 0)
            : (UpdateMailRecipientDetails.htmlReport = 1);
          this.filename = this.filename || this.filename.toString() != "" ? this.filename : (this.statsData[0].templateFile);

          var obj = {
            path: this.path,
            organizationID: this.statsData[0].organizationID,
            reportID: this.statsData[0].Report_ID,
            name: UpdateMailRecipientDetails.name.trim(),
            title: UpdateMailRecipientDetails.title.trim(),
            description:
              UpdateMailRecipientDetails.Description.toLowerCase() !=
                'not specified'
                ? UpdateMailRecipientDetails.Description
                : '',
            category: UpdateMailRecipientDetails.Category,
            storedProcedure:
              UpdateMailRecipientDetails.storedProcedure.toLowerCase() != 'not specified'
                ? UpdateMailRecipientDetails.storedProcedure.trim() : '',
            namedRange: UpdateMailRecipientDetails.NamedRange.trim(),
            TemplateFile: UpdateMailRecipientDetails.templateFile,
            criteria: UpdateMailRecipientDetails.criteria,
            version: UpdateMailRecipientDetails.Version,
            EmailTo: tempToMail,
            EmailCC: tempCCMail,
            EmailBCC: tempBCCMail,
            EmailBody: UpdateMailRecipientDetails.EmailBody,
            AlterEmailbody: UpdateMailRecipientDetails.AlterEmailbody,
            EmailSubject: UpdateMailRecipientDetails.EmailSubject.trim(),
            FileToBeProtected: UpdateMailRecipientDetails.fileToBeProtected,
            notifyOnlyOnData: UpdateMailRecipientDetails.notifyOnlyOnData,
            notifyBlankAttachment: UpdateMailRecipientDetails.notifyBlankAttachment,
            PasswordToBeMailed: UpdateMailRecipientDetails.Password_to_be_Mailed,
            ConnectionName: UpdateMailRecipientDetails.ConnectionName,
            ModifiedBy: this.userid.trim() != "" ? this.userid.trim() : "-100",
            IsActive: UpdateMailRecipientDetails.isActive?1:0,
            Password:
              UpdateMailRecipientDetails.password.toLowerCase() !=
                'dynamically generated password'
                ? UpdateMailRecipientDetails.password
                : '',
            Recipient_Type: UpdateMailRecipientDetails.Recipient_Type,
            ReportType: UpdateMailRecipientDetails.Report_Type,
            jobID: UpdateMailRecipientDetails.JobID,
            IncludeDateInSubject: UpdateMailRecipientDetails.IncludeDateInSubject,
            cronExpression: UpdateMailRecipientDetails.cronExpression,
            cronDescription: UpdateMailRecipientDetails.cronDescription,
            IsHTMLReport: UpdateMailRecipientDetails.htmlReport,
            AlternateEmailTo: tempAlterToMail,
            AlternateEmailCC: tempAlterCCMail,
            AlternateEmailBCC: tempAlterBCCMail,
            AlternateEmailBody: UpdateMailRecipientDetails.AlterEmailbody,
            file: {
              fileName: this.filename,
              fileContent: this.base64split,
            },
          };
          this.vitalservice
            .UpdateOrganizationScheduleReport(obj)
            .subscribe((res) => {
              if (res.Success) {
                this.commonService.setBooleanToTrue(true);
                this.ShowModalEdit = false;
                this.ShowModal = true;
                this.tableGrid = false;
                this.selectedRoleArr = [];
                this.filename = '';
                this.recipientData.patchValue({
                  edittoMailCtrl: null
                });
                this._snackbar.open(res.Message, 'Success');
              } else {
                this.ShowModalEdit = true;
                this.ShowModal = false;
                this._snackbar.open("Scheduled "+ res.Message, 'Failed');
              }
            }, error => {
              this.ngxService.stop();
              this._snackbar.open('Something went wrong.Please try again', 'Close');
              console.error(error);
            });
        } else {
          this._snackbar.open('Insert valid data', 'Failed');
          this.ShowModalEdit = true;
          this.ShowModal = false;
        }
        this.ngxService.stop();
      }
      else {
        this._snackbar.open("Logged in user is Unauthorized!", "Failed");
      }
    }
  }

  handleUpload(event,type?) {
    const file = event.target.files[0];
    if (file != null) {
      this.filename = file.name;
      if(type ==='create'){
        this.ScheduleReportData.patchValue({
          templateFile:this.filename
        })
      }else{
        this.recipientData.patchValue({
          templateFile:this.filename
        })
      }
      if (this.filename.split('.').pop() == 'xlsx') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (this.filename.toString() != '') {
          reader.onload = () => {
            this.base64 = reader.result;
            this.base64split = this.base64.split('base64,')[1];
          };
        }
      } else {
        (document.getElementById('myFileInputID') as HTMLInputElement).value =
          null;
        this._snackbar.open('', 'File Format is not correct');
      }
    } else {
      this.filename = '';
      this.base64split = '';
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace = false;
    let isValid = false;
    isWhitespace = (control.value || '').trim().length === 0;
    isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  validatedetails(UpdateMailRecipientDetails: any) {
    if (
      UpdateMailRecipientDetails.storedProcedure == null ||
      UpdateMailRecipientDetails.storedProcedure == '' ||
      UpdateMailRecipientDetails.storedProcedure == undefined
    ) {
      this.errorFlag = 1;
      return;
    } else if (
      UpdateMailRecipientDetails.title == null ||
      UpdateMailRecipientDetails.title == '' ||
      UpdateMailRecipientDetails.title == undefined
    ) {
      this.errorFlag = 1;
      return;
    }

    if (
      UpdateMailRecipientDetails.Category.toLowerCase == 'job' ||
      UpdateMailRecipientDetails.JobID != null
    ) {
      if (UpdateMailRecipientDetails.EmailSubject == null) {
        this.errorFlag = 1;
        return;
      }
      // else if (
      //   UpdateMailRecipientDetails.EmailBody == null ||
      //   UpdateMailRecipientDetails.EmailBody == undefined ||
      //   UpdateMailRecipientDetails.EmailBody == ''
      // ) {
      //   this.errorFlag = 1;
      // }
      else if (
        UpdateMailRecipientDetails.NamedRange == null ||
        UpdateMailRecipientDetails.NamedRange == undefined ||
        UpdateMailRecipientDetails.NamedRange == ''
      ) {
        this.errorFlag = 1;
      }
    }
  }

  private validateEmail(email) {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(String(email).toLowerCase());
  }
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  ngOnInit() {
    // this.getjobs();
    this.getData();
  }

  getData(){
    this.ngxService.start();
    let query = this.vitalservice.GetQuery('JobDetails');
    let queryVariable = {
      ClassName: 'com.va.distribution.ScheduleReports',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      // for(let i = 0 ; i <  Resdata.data.submenuData.length ; i++ ){
      // this.Jobs.push(Resdata.data.submenuData[i].Job_Name);
      // }
      if (!Resdata.errors) {
        this.Jobs = Resdata.data.submenuData;
      }
      else {
        this.Jobs = [];
      }
      // this.getUSerDetails()
      setTimeout(() => {
        this.getReportPath();
      });
      this.dropdownSettings = {
        singleSelection: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 5,
        allowSearchFilter: true,
      };
      //this.temporgid = this.templateData.GroupData.OrganizationId;
      this.temporgid = sessionStorage.getItem('org_id');
      //this.Jobs = this.templateData.submenuData.submenuData1;
      // this.UserDetails = this.templateData.submenuData.submenuData2;

      this.GetButtondetails();
      this.ngxService.stop();
    }, error => {
      this.Jobs = [];
      console.error(error);
      this.ngxService.stop();
    });
  };

  // getjobs() {
  //   let query = this.vitalservice.GetQuery('JobDetails');
  //   let queryVariable = {
  //     ClassName: 'com.va.distribution.ScheduleReports',
  //   };
  //   let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //   this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
  //     // for(let i = 0 ; i <  Resdata.data.submenuData.length ; i++ ){
  //     // this.Jobs.push(Resdata.data.submenuData[i].Job_Name);
  //     // }
  //     this.Jobs = Resdata.data.submenuData;
  //   });
  // }

  getUSerDetails() {
    let query = this.vitalservice.GetQuery('userDetails');
    let queryVariable = {
      ClassName: 'com.va.distribution.ScheduleReports',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      // for(let i = 0 ; i <  Resdata.data.submenuData.length ; i++ ){
      // this.Jobs.push(Resdata.data.submenuData[i].Job_Name);
      // }
      this.UserDetails = Resdata.data.submenuData;
    });
  }

  getReportPath() {
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = {
      //for QC
      name: (environment.envPrefix == '' ? this.vitalservice.deploymentKey : environment.envPrefix + '-' + this.vitalservice.deploymentKey).toLocaleLowerCase()
      //for Prod
      //name: this.vitalservice.deploymentKey.toLowerCase()
    };
    let query = String(this.vitalservice.GetQuery('StatisticsReportsPath'));
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
      this.path = Resdata.data.submenuData[0].ReportTemplatesBasePaths;
    }, error => {
      console.error(error);
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
    });
  }


  ExportDetailsToExcel() {
    let filename = 'Schedule Reports_';
    let orgname = sessionStorage.getItem('Org_Name');
    if (this.Title) {
      filename = filename + this.Title ;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let title = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
      if (title.length > 31) {
        fileName = filename.split('_')[0].replace(' ','') + '_';
        filename = fileName + title +'_';
      }
      else {
          filename = fileName + title + '_';
        }
      }
        else {
          filename = filename + '_';
        }
    }
    else if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ','') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
          }
        }
          else {
            filename = filename + '_';
          }
        }
        else{
          filename = filename;
        }
    filename = filename + sessionStorage.getItem('org_id')+'.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName +orgid;
    }
    var sheet = XLSX.utils.book_new();
    let sheetData = XLSX.utils.json_to_sheet(this.statsData);
    XLSX.utils.book_append_sheet(sheet, sheetData, '');
    XLSX.writeFile(sheet, filename);
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  disableButton(scheduledReport) {
    if (
      scheduledReport.title && scheduledReport.storedProcedure &&
      scheduledReport.EmailSubject && scheduledReport.name && scheduledReport.NamedRange
    ) {
      if (
        scheduledReport.title.trim() == '' ||
        scheduledReport.storedProcedure.trim() == '' ||
        scheduledReport.EmailSubject.trim() == '' ||
        scheduledReport.NamedRange.trim() == '' ||
        scheduledReport.name.trim() == ''
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  // disableUpdateButton(scheduledReport){
  //   if(scheduledReport.title&&scheduledReport.storedProcedure && scheduledReport.NamedRange&& scheduledReport.EmailSubject&& scheduledReport.name){
  //   if(scheduledReport.title.trim() == '' ||scheduledReport.storedProcedure.trim() == ''||scheduledReport.EmailSubject.trim() == ''||scheduledReport.NamedRange.trim() == ''||scheduledReport.name.trim() == ''){
  //     return true;
  //   }
  //   else{
  //     return false;
  //   }
  // }
  // else{
  //   return true;
  // }
  // }

  addNewScheduleReport(reportDetails) {
    if (this.isMandatoryCheck(reportDetails, 'add') == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!")
    }
    else {
      if (this.userid != undefined) {
        this.errorFlag = -1;
        this.ngxService.start();

        this.showCustomReportView = false;
        this.validatedetails(reportDetails);
        if (this.errorFlag != 1) {
          let tempToMail = [];
          this.filename=reportDetails.templateFile?reportDetails.templateFile:this.filename;
          this.editToMailData.forEach((element) => {
            if (!element.invalid) tempToMail.push(element.value);
          });
          let tempCCMail = [];
          this.editCcMailData.forEach((element) => {
            if (!element.invalid) tempCCMail.push(element.value);
          });
          let tempBCCMail = [];
          this.editBccMailData.forEach((element) => {
            if (!element.invalid) tempBCCMail.push(element.value);
          });
          let tempAlterToMail = [];
          this.editAlterToMailData.forEach((element) => {
            if (!element.invalid) tempAlterToMail.push(element.value);
          });
          let tempAlterCCMail = [];
          this.editAlterCcMailData.forEach((element) => {
            if (!element.invalid) tempAlterCCMail.push(element.value);
          });
          let tempAlterBCCMail = [];
          this.editAlterBccMailData.forEach((element) => {
            if (!element.invalid) tempAlterBCCMail.push(element.value);
          });
          reportDetails.ConnectionName == 'true' ||
            reportDetails.ConnectionName == true
            ? (reportDetails.ConnectionName = 'ReportDB')
            : (reportDetails.ConnectionName = null);

          reportDetails.isActive == 'true'
            ? (reportDetails.isActive = 1)
            : (reportDetails.isActive = 0);

          reportDetails.notifyOnlyOnData == 'false' ||
            reportDetails.notifyOnlyOnData == false
            ? (reportDetails.notifyOnlyOnData = 0)
            : (reportDetails.notifyOnlyOnData = 1);

          reportDetails.htmlReport == 'false' ||
            reportDetails.htmlReport == false
            ? (reportDetails.htmlReport = 0)
            : (reportDetails.htmlReport = 1);

          reportDetails.notifyBlankAttachment == 'false' ||
            reportDetails.notifyBlankAttachment == false
            ? (reportDetails.notifyBlankAttachment = 0)
            : (reportDetails.notifyBlankAttachment = 1);

          reportDetails.IncludeDateInSubject == 'true'
            ? (reportDetails.IncludeDateInSubject = 1)
            : (reportDetails.IncludeDateInSubject = 0);
          if (this.filename)
            this.filename != undefined ||
              this.filename != '' ||
              this.filename != null
              ? this.filename
              : (this.filename = this.statsData[0].templateFile);
          var obj = {
            path: this.path,
            organizationID: this.temporgid,
            // reportID: this.statsData[0].Report_ID,
            name: reportDetails.name.trim(),
            title: reportDetails.title.trim(),
            description:
              reportDetails.Description.toLowerCase() != 'not specified'
                ? reportDetails.Description.trim()
                : '',
            category: reportDetails.Category,
            storedProcedure:
              reportDetails.storedProcedure.toLowerCase() != 'not specified'
                ? reportDetails.storedProcedure.trim()
                : '',
            namedRange: reportDetails.NamedRange.trim(),
            TemplateFile: reportDetails.templateFile,
            includeHeader: reportDetails.IncludeHeader,
            criteria: reportDetails.criteria,
            version: reportDetails.Version,
            EmailTo: tempToMail,
            EmailCC: tempCCMail,
            EmailBCC: tempBCCMail,
            ALterEmailTo: tempAlterToMail,
            AlterEmailCC: tempAlterCCMail,
            AlterEmailBCC: tempAlterBCCMail,
            EmailBody: reportDetails.EmailBody,
            AlterEmailbody: reportDetails.AlterEmailbody,
            EmailSubject: reportDetails.EmailSubject.trim(),
            FileToBeProtected: reportDetails.fileToBeProtected,
            IncludeDateInSubject: reportDetails.IncludeDateInSubject,
            notifyOnlyOnData: reportDetails.notifyOnlyOnData,
            notifyBlankAttachment: reportDetails.notifyBlankAttachment,
            PasswordToBeMailed: reportDetails.Password_to_be_Mailed,
            ConnectionName: reportDetails.ConnectionName,
            Created_By: this.userid.trim() != "" ? this.userid.trim() : "-100",
            IsActive: reportDetails.isActive,
            Password:
              reportDetails.password.toLowerCase() !=
                'dynamically generated password'
                ? reportDetails.password
                : '',
            Recipient_Type: reportDetails.Recipient_Type,
            ReportType: reportDetails.Report_Type,
            jobID: reportDetails.JobID,
            file: {
              fileName: this.filename,
              fileContent: this.base64split,
            },
            cronExpression: reportDetails.cronExpression,
            cronDescription: reportDetails.cronDescription,
            IsHTMLReport: reportDetails.htmlReport,
            AlternateEmailTo: tempAlterToMail,
            AlternateEmailCC: tempAlterCCMail,
            AlternateEmailBCC: tempAlterBCCMail,
            AlternateEmailBody: reportDetails.AlterEmailbody,
          };
          this.vitalservice.AddnewScheduleReport(obj).subscribe((res) => {
            if (res.Success) {
              this._snackbar.open(res.Message, 'Close');
              this.report_Id = parseInt(res[0]);
              this.commonService.setBooleanToTrue(true);
              this.ShowModalEdit = false;
              this.ShowModal = false;
              this.AddModel = false;
              this.tableGrid = true;
              this.selectedRoleArr = [];
              this.filename = '';
           } else {
              this._snackbar.open(res.Message, 'Failed');
              this.AddModel = true;
              this.tableGrid = false;
            }
          }, error => {
            console.error(error);
            this._snackbar.open(error, 'Failed');
            this.AddModel = true;
            this.tableGrid = false;
          });
        } else {
          this._snackbar.open('Insert valid data', 'Failed');
          this.AddModel = true;
          this.tableGrid = false;
        }
        this.ngxService.stop();
      }
      else {
        this._snackbar.open("Logged in user is Unauthorized!", "Failed");
      }
    }
  }
  passwordShow() {
    if (this.showpassword == true) {
      this.showpassword = false;
    }
    else {
      this.showpassword = true;
    }

  }
  Addback() {
    this.ngxService.start();
    this.tableGrid = true;
    this.ShowModalEdit = false;
    this.ScheduleReportData.reset();
    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.editAlterToMailData = [];
    this.editAlterCcMailData = [];
    this.editAlterBccMailData = [];
    this.AddModel = false;
    this.ShowModal = false;
    this.ngxService.stop();
  }
  addNewReport() {
    this.ngxService.start();
    this.AddModel = true;
    this.tableGrid = false;
    this.ShowModal = false;
    this.ShowModalEdit = false;
    this.editToMailData = [];
    this.editCcMailData = [];
    this.editBccMailData = [];
    this.editBccMailData = [];
    this.editAlterToMailData = [];
    this.editAlterCcMailData = [];
    this.editAlterBccMailData = [];
    this.cronDescriptionNew = '';
    this.ScheduleReportData.patchValue({
      storedProcedure: '',
      title: '',
      templateFile: '',
      Category: 'Job',
      Description: '',
      Version: 0,
      name: '',
      criteria: '',
      NamedRange: '',
      JobID: 0,
      notifyOnlyOnData: 'false',
      notifyBlankAttachment: false,
      approxGenarationTime: 0,
      ConnectionName: '',
      MaxDays: 0,
      CriteriaDisplayNames: '',
      IsAsynchronousReport: 'false',
      editEmailTo: null,
      editEmailCc: null,
      editEmailBcc: null,
      fileToBeProtected: 'false',
      EmailSubject: '',
      EmailBody: '',
      AlterEmailbody: '',
      isActive: 'true',
      Report_Type: 'EXTERNAL',
      IncludeDateInSubject: 'false',
      cronExpression: '',
      cronDescription: '',
      htmlReport: 'false',
      password:''
    });

    this.hideRadio = false;
    this.frmradioNBAN.enable();
    this.frmradioNBAY.enable();

    this.ngxService.stop();
  }

  notifyOnDataChange(value) {
    try {
      if (value) {
        this.frmradioNBAN.disable();
        this.frmradioNBAY.disable();

        if (this.recipientData)
          this.recipientData.patchValue({
            notifyBlankAttachment: false
          });

        if (this.ScheduleReportData)
          this.ScheduleReportData.patchValue({
            notifyBlankAttachment: false
          });
      }
      else {
        this.frmradioNBAN.enable();
        this.frmradioNBAY.enable();
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  //#region for requency modal start
  // Open modal if we click textarea control
  openModal(formType) {
    this.frequencyModal.openModal()
    this.formType = formType;
  }

  // Receiving frequecy modal data
  receiveCronMessage($event) {
    let cronExpression = $event.cronExpression;
    let cronDescription = $event.cronDescription;
    if (this.formType == 'add') {
      this.ScheduleReportData.patchValue({
        cronExpression: cronExpression,
        cronDescription: cronDescription
      });
      this.cronDescriptionNew = cronDescription != '' ? " Running " + cronDescription : cronDescription;
    }
    else if (this.formType == 'edit') {
      this.recipientData.patchValue({
        cronExpression: cronExpression,
        cronDescription: cronDescription
      });
      this.cronDescriptionNew = cronDescription != '' ? " Running " + cronDescription : cronDescription;
    }
  }

  isMandatoryCheck(reportDetails, button) {
    if (button == "add") {
      if ((!reportDetails.title)
      || (!reportDetails.storedProcedure)
      || (!reportDetails.Category)
      || (!reportDetails.EmailSubject)
      || (!reportDetails.cronExpression)
      || (!reportDetails.cronDescription)
      || (!reportDetails.name)
      || (!reportDetails.templateFile)
      || (!reportDetails.NamedRange)
      || (reportDetails.edittoMailCtrl && reportDetails.edittoMailCtrl.trim() == '')
      || this.editToEmailInvalid() == true
      ||(this.editToMailData && this.editToMailData.length === 0)
      )
       {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if ((!reportDetails.title)
        || (!reportDetails.storedProcedure)
        || (!reportDetails.Category)
        || (!reportDetails.cronExpression)
        || (!reportDetails.cronDescription)
        || (!reportDetails.cronExpression)
        || (!reportDetails.cronDescription)
        || (!reportDetails.NamedRange)
        || (!reportDetails.EmailSubject)
        || (!reportDetails.name)
        || (reportDetails.edittoMailCtrl && reportDetails.edittoMailCtrl.trim() == '')
        || this.editToEmailInvalid() == true
        || (this.editToMailData && this.editToMailData.length ===0)
        ) {
        return true;
      }
      else {
        return false;
      }
    }


  }
  // removeFrequency(formType) {
  //   if (formType == 'add') {
  //     this.ScheduleReportData.patchValue({
  //       cronExpression: '',
  //       cronDescription:''
  //     });
  //   }
  //   else if (formType == 'edit') {
  //     this.recipientData.patchValue({
  //       cronExpression: '',
  //       cronDescription:''
  //     });
  //   }
  // }

  //#region for requency modal end

  InitiateScheduleReports(){
    if(this.hideSendNowBtn){
    let obj ={
      reportids:  [this.statsData[0].Report_ID]
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "Schedule Report", message: "The report will be sent for  intended recipients. Are you sure you want to continue?", alert: "", continue: "Yes", cancel: "No" }
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if(result){
    this.vitalservice.InitiateScheduleReports(obj, this.vitalservice.deploymentKey).subscribe(
      result => {
          if (!result.errors) {
            this._snackbar.open('Schedule report initiated', 'Close');
          } else {
            this._snackbar.open('Failed to initiate schedule report', 'Close');
          }
      }, error => {
        console.error(error);
      });
    }
    else {
      return
    }
  }, error => {
    console.error(error);
  });
  }
}
}
class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
