import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { addNabvTab } from '../../store/actions/navbar.actions';
import { LabadminService } from '../../labadmin.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { LabadminComponent } from '../../labadmin.component';
import { SideMenuActiveItemService } from '../../services/sidemenu-setactive.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-subsidemenu',
  templateUrl: './subsidemenu.component.html',
  styleUrls: ['./subsidemenu.component.scss'],
})
export class SubsidemenuComponent  implements OnInit, OnDestroy {
  @Input() submenuList = [];
  newValue: any;
  userSessionDetails: any;
  private sideMenuSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<{ breadcrum: [] }>,
    private labadminsService: LabadminService,
    private vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    private labAdminSessionService: LabadminSessionService,
    private labAdminComponent: LabadminComponent,
    private SideMenuActiveItem:SideMenuActiveItemService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.submenuList?.length) {
      this.sideMenuSelected(this.submenuList[0]);
    }
  }
  @Output() 'sidebarExpanded' = new EventEmitter();

  isExpand: boolean = true;

  ngOnInit() {
    this.sideMenuSubscription=this.SideMenuActiveItem.activeItem$.subscribe(id => {
      this.newValue = id;
    });
    this.labAdminSessionService.getLabAdminSession.subscribe(session => {
      this.userSessionDetails = session["userDetails"]
    })
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'getVitalAdminMenusForDx'
    )[0].Query;
    let OrgId=this.labadminsService.organizationId
    let queryString = "Organizationid in \""+OrgId+"\":string[] OR Organizationid in \"-1\":string[]"
    let newQueryVariable = {
      CardName: this.labadminsService.selectedSubmenu['SubMenuCardName'],
      filtername:queryString
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.vitalHttpServices.GetData(newQueryResult,this.labadminsService.deploymentKey).subscribe((newResData) => {
      if(newResData){
        let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
        this.labadminsService.subMenuPermissions= JSON.parse(newResData.data.submenuData1[0].Permission).MenuItems
         this.submenuList = data;
         this.labadminsService.subSideMenu=this.submenuList;
         this.submenuList = data.sort((a,b)=>a.Htext.trim().toLowerCase() < b.Htext.trim().toLowerCase() ? -1:1);
         this.submenuList.forEach(element => {
           element.SubMenu.sort((a,b)=>a.URL.trim().toLowerCase() < b.URL.trim().toLowerCase() ? -1:1);
         });
         this.SideMenuActiveItem.setActiveItemId(this.submenuList[0]);
        //  this.newValue = this.submenuList[0];
         this.sideMenuSelected(this.submenuList[0]);
      }
      else{
        this.labAdminComponent.logOut();
      }
     
    }, error => {
      this.labAdminComponent.logOut();
    });
  }
  sideBarExpand() {
    this.isExpand = !this.isExpand;
    this.sidebarExpanded.emit(this.isExpand);
  }

  sideMenuSelected(navvalue) {
    this.SideMenuActiveItem.setActiveItemId(navvalue);
    navvalue = { ...navvalue, Level: 2 };
    this.router.navigate([navvalue.routerLink])
    this.store.dispatch(addNabvTab({ value: navvalue }));
  }

  ngOnDestroy() {
    if (this.sideMenuSubscription) {
        this.sideMenuSubscription.unsubscribe();
    }
  } 
}
