<h2 mat-dialog-title class="header"><b>{{data.header}}</b></h2>
<mat-dialog-content *ngIf="data.preview.toLowerCase() == 'qualityattribute'">
  <section *ngIf="data.qualityAttribueList as qualityAttribueList ">
    <div class="p-3">
      <div class="qualityAttribute-main" *ngFor="let item of qualityAttribueList">
        <div class="d-flex">
          <span class="ml-1">{{ item.attrGroupName.attrname }}</span>
        </div>
        <div *ngIf="item.attrList.length > 0" class="items-list">
          <div class="items" *ngFor="let item of item.attrList">
            <p>{{ item.attrname }}<span> <img class="ml-2"
                  *ngIf="item.iscomment" src="../../../../assets/images/comment.png" /></span></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.preview.toLowerCase() ==  'flags' ">
  <section *ngIf="data.qualityAttribueList as flagList ">

    <div class="p-3">
      <div class="items-list">
        <div class="items" *ngFor="let item of flagList">
          <p>{{ item.attrname }}</p>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.preview.toLowerCase() == 'stains'">
  <section *ngIf="data.qualityAttribueList|async as satinData ">
    <div class="p-3">
      <div class="qualityAttribute-main" *ngFor="let i of satinData">
        <div class="d-flex">
          <span class="ml-1">{{i.panel}}</span>
        </div>
        <div *ngIf="i.procedures.length > 0" class="items-list">
          <div class="items" *ngFor="let prodedure of i.procedures">
            <p>{{prodedure.Procedure_Name}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" class="btn general" mat-dialog-close>Close</button>
</mat-dialog-actions>