import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lock-unlock-user-dialog',
  templateUrl: './lock-unlock-user-dialog.component.html',
  styleUrls: ['./lock-unlock-user-dialog.component.scss'],
})
export class LockUnlockUserDialogComponent {


  isUpdatOnProcess=false; // lock/unlock button disabled while click
  copiedPassword:string='';

  constructor(
    public dialogRef: MatDialogRef<LockUnlockUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private labAdminService: LabadminService,
    private clipboard: Clipboard,private _snackbar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  closeDialog() {
    this.dialogRef.close({userid:'',status:this.data.status});
  }


  // lock and unlock user action button click
  actionClick() {
    if(this.data.type =='lockunlock-user'){
      this.lockUnlockUserUpdate();

    }else if(this.data.type == 'generate-password'){
         this.copiedPassword = this.data.row2value;
         this.clipboard.copy(this.data.row2value);
         this._snackbar.open("Password copied to clipboard", "Close");
         this.dialogRef.close();
    }
    else if(this.data.type == 'mail-sent'){
      this.dialogRef.close();
    }
  }

  // lock and unlock user update action
  lockUnlockUserUpdate(){
    if(this.data.status === true){
      this.isUpdatOnProcess = true;
      this.labAdminService.UpdateLockUnlockUser(this.data.commonkey,false, this.labAdminService.deploymentKey).subscribe((data) =>{
         if(data.content && data.content == true){
          this._snackbar.open("User Unlocked successfully")
          this.dialogRef.close({userid:this.data.userid,status:false});
          this.data.status = false;
         }
         else{
          this.dialogRef.close({userid:'',status:true});
         }
         this.isUpdatOnProcess = false;
      });

    }
    else{
      this.isUpdatOnProcess = true;
      this.labAdminService.UpdateLockUnlockUser(this.data.commonkey,true, this.labAdminService.deploymentKey).subscribe((data)=>{
        if(data.content && data.content == true){
          this._snackbar.open("User Locked successfully")
          this.dialogRef.close({userid:this.data.userid,status:true});
          this.data.status = true;
        }
        else{
          this.dialogRef.close({userid:'',status:false});
        }
        this.isUpdatOnProcess = false;
      })

    }
  }
}
