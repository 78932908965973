import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'FilterDataOn',
    pure: false
})


export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], filtervalue: any,filterfield:any): any[] {   
    if(typeof(items) != 'undefined'){        
        if (filtervalue == null) {
            return items;
        }
        // filter items array, items which match and return true will be kept, false will be filtered out
     return items.filter((item: any[]) => this.applyFilter(item, filtervalue,filterfield));
    }//end of check if array has a value      
  }//end of transform
  
  applyFilter(item: any, filtervalue: any,filterfield:any ): boolean {
    let DisplayField = 'DisplayField';    
    if(item[DisplayField][filterfield] == filtervalue){        
        return true;
    } else{
        return false;
    }
  }//end of applyFilter
}