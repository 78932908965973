<h2 mat-dialog-title>Keys Ignored:</h2>
<div mat-dialog-content style="font-size: medium;">The following keys are refrained from being compared.
    <!-- <p>
        {{ignoredList}}
    </p> -->
    <br /><br />
    <ul style="list-style-type:circle;">
        <li *ngFor="let item of ignoredKeysArr">{{item}}</li>
    </ul>
</div>
<div mat-dialog-actions style="display: table;width: 100%;">
    <div  style="display: table-cell; vertical-align: middle;">
        <button mat-button mat-dialog-close class="d-block m-auto" style="background-color: #e5e5e5;">Close</button>
    </div>
</div>