import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LabadminService } from '../../labadmin.service';
import { Store } from '@ngrx/store';
import { addNabvTab } from '../../store/actions/navbar.actions';

@Component({
  selector: 'app-mattabnav',
  templateUrl: './mattabnav.component.html',
  styleUrls: ['./mattabnav.component.scss']
})
export class MattabnavComponent {

  @Input() tabList=[];
  constructor(private router:Router,private labadminService:LabadminService,private store: Store<{ breadcrum: [] }>){

  }

  // ngOnChanges(){
  //   this.changeTabNav(this.tabList[0])
  // }
 //ngOnInit(){ this.changeTabNav(this.tabList[0])}
  ngOnChanges(){
    if(this.tabList.length){
      this.changeTabNav(this.tabList[0]);
    }
  }
 ngOnInit(){}

 getLocation(path){
  let exist=  document.location.href.split('#')[1]
  return exist.includes(path)
 }
 getClass(path){
  let exist=  document.location.href.split('#')[1]
  return exist.includes(path)
 }

 tabChanged(route){

this.router.navigate([`${this.tabList[route.index].routerLink}`])
 }

 changeTabNav(item:object):any{
  this.labadminService.selectedSubmenu = item;
  item={...item,Htext:item['URL'],Level:1}
  // item['Htext']=item['URL']

  this.router.navigate([`${item['routerLink']}`])

  this.addBreadCrum(item)
// console.log(item['SubMenuCardName'])
 }

 addBreadCrum(navvalue) {
  this.store.dispatch(addNabvTab({value: navvalue}));
}

}
