export class CardQueries {
    public mainQuery: string = "";

    constructor() {

        this.CreateMainQuery();
    }
    private CreateMenu() {
        let query: string = "";
        query = query + "VitalAdminMenus_List(CardName:$CardName){";
        query = query + " MenuID ";
        query = query + " CardType ";
        query = query + " JSONMenu ";
        query = query + " CardName ";
        query = query + "  } } ";
        return query;
    }

    private CreateMainQuery() {
        let query: string = "";
        query = "query( $Keyword: String) { ";
        query = query + "Card: GraphQLQueries_List(Keyword:$Keyword ){ ";
        query = query + " Keyword ";
        query = query + " Query }";
        this.mainQuery = query + "  } ";
    }

    public getVitalAdminRoleNames() {
        let query: string = '';
        query = 'query ($status: String) { submenuData: VitalAdminRolenames_list(Status: $status) {          RoleName          Order     IsAllowAllUsers   }      }'
        return query;
    }

    public getCasetypeList() {
        //code to get surgical casetypes list
        let query: string = '';
        query = `query{
            Casetypes:VitalAdminAttributes_list(Context: "VitalAdminCaseTypes", Keyword: "SurgicalCaseTypes") {
              Items_JSON
            }
          }`
        return query;
    }
}
