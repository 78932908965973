<div *ngIf="gridDisplay" class="diagon-header">
  <div class="modal-header admin-model-header mb-2 mt-3 ">
      <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>
            <div>Order and Result Codes</div>
          </strong>
      </h3>
  </div>
<div  *ngIf="data" class="col-sm-12" >
      <wj-flex-grid #flex *ngIf="data.length> 0"  [isReadOnly]="true"  [itemsSource]="data" (initialized)="init(flex)">
        <wj-flex-grid-column *ngFor="let col of columns" [binding]="col.binding" [header]="col.header" [visible]="!(col.header == 'OrderCodeID') && !(col.header == 'CreatedBy') && !(col.header == 'CreatedDate') && !(col.header == 'ModifiedBy') && !(col.header == 'ModifiedDate') && !(col.header == '')"  [allowResizing]="true" [width]="'*'" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
  <div style="text-align: center;" *ngIf="data.length != 0">
    <br />
    <div class="sub-button mt-4">
      <button (click)="ExportExcel(flex)" mat-raised-button class="admin-btn-success mr-4" [disabled]="hideExportBtn" >Export</button>
      <button  (click)="UploadMethod()" mat-raised-button class="admin-btn-success mr-4" [disabled]="hideuploadBtn" >Upload</button>
      <!-- <button  (click)="copyOrgData()" mat-raised-button class="admin-btn-success mr-4">Copy</button> -->
    </div>
  </div>
 </div>
  <div *ngIf="data.length == 0" class="nodata-wrap mt-4">
    <button  (click)="UploadMethod()" mat-raised-button class="admin-btn-success mr-4" [disabled]="hideuploadBtn">Upload</button>
    <!-- <button  (click)="copyOrgData()" mat-raised-button class="admin-btn-success mr-4">Copy</button> -->
  <div  class="nodata-design"> No Data Found </div>
  </div>
</div>


<div *ngIf="detailsDisplay" class="col-sm-12">
  <app-bulk-upload-order-result-code [templateData]="templateData"
      [hideupload]="uploadClicked"></app-bulk-upload-order-result-code>
</div>

<div *ngIf="copyDataClicked">
  <div class="modal-header admin-model-header mb-2 mt-3">
      <h3 *ngIf="!userCardFlag" class="modal-title w-100 admin-model-header-txt main-title">
          <strong >Copy Order and Result Codes</strong>

      </h3>
  </div>
  <form [formGroup]="copycollectionMethodForm" (keydown.enter)="$event.preventDefault()" class="copy-collection">
      <div class="row col-sm-12 p-0 m-0">
          <div class="row col-sm-12 mt-2 m-0 align-center">
              <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                  <mat-label><span class="error-msg">*</span>Deployment</mat-label>
                  <mat-select type="text" disableOptionCentering matInput #searchbar formControlName="frmDepKey">
                      <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                          (onSelectionChange)="onChangeDeployment($event,i)">
                          <span>{{ i }}</span>
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                  <mat-label><span class="error-msg">*</span>Group </mat-label>
                  <em class="fa fa-chevron-down chevron-align"></em>
                  <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                      [disabled]="disableOrgSearch" #searchbar
                      (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"
                      [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                  <mat-autocomplete #orgauto="matAutocomplete">
                      <div *ngFor="let show of searchResult">
                          <mat-option class="mat-opt-align"
                              *ngIf="show.organizationname.toLowerCase().includes(searchInput.toLowerCase()) || show.organizationid.toString().includes(searchInput.toString())"
                              (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                              value="{{ show.organizationname }}"
                              title="{{ show.organizationname }} ({{show.organizationid}})">
                              <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                          </mat-option>
                      </div>
                  </mat-autocomplete>
              </mat-form-field>
          </div>
      </div>
      <div class="row align-center mt-4">
          <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshBackcopy()">
                  Back
              </button>
          </div>
          <div class="dml-btn">
              <button mat-raised-button class="admin-btn-success"
                  [disabled]="disableGetDataBtn() || (!backBtn && postUpload)" (click)="getDataToCopy()">Get
                  Data</button>
          </div>
      </div>
  </form>
  <div *ngIf="gridWidth > 0">
    <wj-flex-grid #flexgrid (initialized)="initGrid(flexgrid)" [isReadOnly]="true"
        [itemsSource]="copyGridData" [width]="170" [frozenColumns]="2"
        (formatItem)="formateItem(flexgrid, $event)">

        <div *ngFor="let columnname of sheetHeader">
            <wj-flex-grid-column  [binding]="columnname?.toString().toLowerCase()"
                [header]="columnname"
                [visible]="!(columnname.toLowerCase() == 'ordercodeid') && !(columnname.toLowerCase()=='organizationid')  && !(columnname.toLowerCase()=='casetype') "
                [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
            </wj-flex-grid-column>

        </div>

        <!-- Status -->
        <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
            [allowDragging]="false" [allowSorting]="false" [format]="'d'">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                </span>
            </ng-template>
        </wj-flex-grid-column>

        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <br />
    <div class="col-sm-12 align-center generate-btn mt-4">
        <div *ngIf="!backBtn" class="dml-btn">
            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                (click)="ExportCopyStatus(flexgrid)">Download</button>

            <button *ngIf="postUpload" mat-raised-button class="admin-btn-success mr-4"
                (click)="refreshBackcopy()">
                Finish
            </button>
        </div>
        <div *ngIf="!postUpload" class="dml-btn">
            <button mat-raised-button class="admin-btn-success" [disabled]="selectedItems.length==0"
                (click)="uploadCopyData(flexgrid)">Approve</button>
        </div>
    </div>
</div>
<div class="col-sm-12 container create-btn align-center mt-4" *ngIf="gridWidth == 0">
    <div class="col-sm-12 nodata-wrap">
        <div class="nodata-design font-size">No Data Found</div>
    </div>
</div>
</div>


