import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabadminSessionService {

  private labAdminSession = new BehaviorSubject({});
  getLabAdminSession = this.labAdminSession.asObservable();

  constructor() { }

  setLabAdminSession(sessionData:any){
    this.labAdminSession.next(sessionData)
  }
}
