<div class="mb-2 mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>There are missing default stain configurations. </strong>
        <strong class="configtext" (click)="getDefaultstainvalidator()" data-toggle="modal" data-target="#configmodal">Click
            here to configure</strong>
    </h3>
</div>

<div *ngIf="gridPage">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong> {{ gridName }}  </strong>
        </h3>
    </div>
    <div *ngIf="gridwidth > 0" class="modal-content bordernone qty-attributes">
        <wj-flex-grid class="mt-3" [frozenColumns]="2" [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="gridData" #grid
            (initialized)="initGrid(grid)">
            <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="'*'" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button [disabled]="editBtn" class="edit-delete-btn" *ngIf="editEnabled(row)" (click)="openEditScreen(grid,$event)"><i
                            id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></i></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column *ngFor="let columnname of gridHeader" [binding]="columnname" [header]="columnname.split('_').join(' ')"
                [visible]="!(columnname == 'Procedure_Id') && !(columnname == 'Id')
                && !(columnname == 'testid') && !(columnname == 'Sequence') && !(columnname == 'BillingType')
                    && !(columnname == 'OFAccountId') && !(columnname == 'CaseType') && !(columnname == 'AccountId') && !(columnname == 'siteid') && !(columnname == 'extnprocedureid')"
                [width]="'*'" [fixed]="true" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="align-center">
            <div class="mt-4">
                <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-4" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                    [byPage]="true" [cv]="gridData">
                </wj-collection-view-navigator>
                <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                    <wj-menu-item [value]="0">No Paging</wj-menu-item>
                    <wj-menu-item [value]="10">10</wj-menu-item>
                    <wj-menu-item [value]="15">15</wj-menu-item>
                    <wj-menu-item [value]="30">30</wj-menu-item>
                    <wj-menu-item [value]="50">50</wj-menu-item>
                </wj-menu>
            </div>
        </div>
        <div class="align-center">
            <div class="sub-button mt-4">
                <button (click)="exportGrid(grid)" mat-raised-button class="admin-btn-success mr-4" [disabled]="exportBtn">Export</button>
                <button (click)="addDefaultStain()" mat-raised-button class="admin-btn-success mr-4" [disabled]="createBtn">Create</button>
                <button (click)="uploadDefaultStain()" mat-raised-button class="admin-btn-success mr-4" [disabled]="uploadBtn">Upload</button>
            </div>
        </div>
    </div>

    <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridwidth == 0">
        <div class="col-sm-12 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>

    <div *ngIf="gridwidth == 0">
        <div class="col-sm-12 align-center mt-4">
            <button [disabled]="createBtn" (click)="addDefaultStain()" mat-raised-button class="admin-btn-success mr-4">Create</button>
            <button (click)="uploadDefaultStain()" mat-raised-button class="admin-btn-success" [disabled]="uploadBtn">Upload</button>
        </div>
    </div>
</div>

<!-- Create/Edit Default Stain -->
<div *ngIf="addEditClicked">
    <form [formGroup]="testForm" autocomplete="off">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 *ngIf="action == 'edit'" class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>Edit Default Stain</strong>
                </h3>
                <h3 *ngIf="action != 'edit'" class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>Add Default Stain</strong>
                </h3>
            </div>
            <div class="col-sm-12 m-0 p-0">
                <div class="text-wrap field-wrap row col-sm-12 p-0 ml-0 pb-2">
                    <div class="col-sm-12 mt-2">
                        <mat-form-field appearance="outline" class="col-sm-3 example-additional-selection"
                            disableOptionCentering [readonly]="action =='edit'">
                            <mat-label>
                                <strong>
                                    Panel</strong>
                            </mat-label>
                            <input #inputAutoCompletePanel [readonly]="action =='edit'" type="text" matInput maxlength="100"
                                [matAutocomplete]="auto3" formControlName="frmpanel" #triggerPanel="matAutocompleteTrigger"
                                [errorStateMatcher]="matcher" (input)="disableTestSeq(testForm.value.frmpanel,'')"
                                class="input-inline-wrap">
                            <div matSuffix class="d-flex">
                                <button (click)="clearInput($event, 'Panel')" *ngIf="testForm?.value.frmpanel && action !='edit'"
                                    aria-label="Clear" mat-icon-button type="button">
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button (click)="openOrClose($event, triggerPanel)" aria-label="Clear" mat-icon-button
                                    type="button" *ngIf="action !='edit'">
                                    <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                                </button>
                            </div>
                            <mat-autocomplete [readonly]="action =='edit'" #auto3="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                                (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                                [displayWith]="displayFn">
                                <div *ngFor="let c of filterPanels(testForm.value.frmpanel)">
                                    <mat-option class="mat-opt-align" disableOptionCentering [value]="c.Panel"
                                        (onSelectionChange)="disableTestSeq($event,'selection')" title="{{ c.Panel }}">
                                        {{ c.Panel }}
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    Procedure Name</strong>
                            </mat-label>
                            <input trim type="text" matInput maxlength="255" formControlName="frmprocedure"
                                [errorStateMatcher]="matcher">
                            <mat-error class="mt-2">{{getErrorMessage(testForm.value.frmprocedure,'')}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    CPT Code</strong>
                            </mat-label>
                            <input trim type="text" matInput maxlength="2000" formControlName="frmcptcodes" pattern="^[a-zA-Z0-9-,. /]+$"
                                [errorStateMatcher]="matcher">
                            <mat-error class="mt-2">{{getErrorMessage(testForm.value.frmcptcodes,'cpt')}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    Sequence(Panel)</strong>
                            </mat-label>
                            <input trim type="text" matInput maxlength="5" [readonly]="disableSequence"
                                formControlName="frmtestsequence" pattern="[0-9]+" (keypress)="allowOnlyNumber($event)"
                                [errorStateMatcher]="matcher">
                            <mat-error class="mt-2">{{getErrorMessage(testForm.value.frmtestsequence,'testsequence')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 mt-2 mb-3">
                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    Sequence(Procedure)</strong>
                            </mat-label>
                            <input trim type="text" matInput maxlength="4" formControlName="frmsequence" pattern="[0-9]+"
                                (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                            <mat-error class="mt-2">{{getErrorMessage(testForm.value.frmsequence,'sequence')}}</mat-error>
                        </mat-form-field>
                        <mat-checkbox [color]="task.color" formControlName="frmactive" class="col-sm-1">
                            Active</mat-checkbox>
                        <mat-checkbox [color]="task.color" *ngIf="checkCaseType(templateData.secondarykeys.casetype)" formControlName="frmfishtest" class="col-sm-2">
                            Fish Test</mat-checkbox>
                        <div *ngIf="((isAdvancedFlag && action == 'edit') || (action != 'edit')) && !testForm.value.frmfishtest" class="col-sm-2 float-right">
                            <!-- // more hide -->
                            <div class="row col-sm-12 mt-2 more-data ml-5 mb-2" *ngIf="!moreHide" (click)="action == 'edit' ? moreHide=true : getdropdownvalue(true)">
                                <span class="mr-2 cursor">More</span><i class="fa fa-chevron-down mt-1 font-size more-arrow"></i>
                            </div>
                            <div class="row col-sm-12 mt-2 more-data ml-5 mb-2" *ngIf="moreHide" (click)="action == 'edit' ? moreHide=false : getdropdownvalue(false)">
                                <span class="mr-2 cursor">Hide</span><i class="fa fa-chevron-up mt-1 font-size more-arrow"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mt-2" *ngIf="testForm.value.frmfishtest">
                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    Panel Description</strong>
                            </mat-label>
                            <input trim type="text" matInput maxlength="255" formControlName="frmtestdescription" [errorStateMatcher]="matcher">
                            <!-- <mat-error>{{getErrorMessage(testForm.value.frmsequence,'sequence')}}</mat-error> -->
                        </mat-form-field>
                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label>
                                <strong>
                                    Panel Type</strong>
                            </mat-label>
                            <input trim type="text" matInput #panelTypeInput maxlength="25" #triggerPanelType="matAutocompleteTrigger" [matAutocomplete]="panelType" formControlName="frmtesttype" [errorStateMatcher]="matcher">
                            <!-- <mat-error>{{getErrorMessage(testForm.value.frmsequence,'sequence')}}</mat-error> -->
                            <div matSuffix class="d-flex">
                                <button (click)="panelTypeInput.value = ''" *ngIf="panelTypeInput.value"
                                    aria-label="Clear" mat-icon-button type="button">
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button (click)="openOrClose($event, triggerPanelType)" aria-label="trigger" mat-icon-button
                                    type="button">
                                    <mat-icon>{{panelTypearrowIconSubject.getValue()}}</mat-icon>
                                </button>
                            </div>
                            <mat-autocomplete #panelType="matAutocomplete" (closed)="panelTypearrowIconSubject.next('arrow_drop_down')"
                                (opened)="panelTypearrowIconSubject.next('arrow_drop_up')" (optionSelected)="panelTypearrowIconSubject.next('arrow_drop_down')"
                                [displayWith]="displayFn">
                                <div *ngFor="let c of filterPanelTypes(panelTypeInput.value)">
                                    <mat-option class="mat-opt-align" disableOptionCentering [value]="c.testtype" title="{{ c.testtype }}">
                                        {{ c.testtype }}
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div *ngIf="(isAdvancedFlag && action == 'edit') || (action != 'edit')" class="col-sm-2 float-right">
                            <!-- // more hide -->
                            <div class="row col-sm-12 mt-2 more-data ml-5 mb-2" *ngIf="!moreHide" (click)="action == 'edit' ? moreHide=true : getdropdownvalue(true)">
                                <span class="mr-2 cursor">More</span><i class="fa fa-chevron-down mt-1 font-size more-arrow"></i>
                            </div>
                            <div class="row col-sm-12 mt-2 more-data ml-5 mb-2" *ngIf="moreHide" (click)="action == 'edit' ? moreHide=false : getdropdownvalue(false)">
                                <span class="mr-2 cursor">Hide</span><i class="fa fa-chevron-up mt-1 font-size more-arrow"></i>
                            </div>
                        </div>
                    </div>

                    <div *ngIf='moreHide' class="col-sm-12 mt-2 mb-3">
                        <mat-form-field *ngIf="action != 'edit'" class="col-sm-3 example-additional-selection"
                            appearance="outline">
                            <mat-label>
                                <strong>Extraction Procedure</strong>
                            </mat-label>
                            <mat-select placeholder="Select" disableOptionCentering #matSelectOptionExn formControlName="frmexnprocedure"
                                [ngModelOptions]="{standalone: true}" multiple>
                                <div class="select-container">
                                    <mat-form-field class="w-100">
                                        <input #search autocomplete="off" placeholder="Search" aria-label="Search"
                                            matInput formControlName="frmexnprocedure" />
                                        <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                            aria-label="Clear" (click)="clearSearch($event, 'Extn')">
                                            <mat-icon>close</mat-icon>
                                        </button>

                                    </mat-form-field>
                                    <mat-optgroup *ngIf="filterExtnProcedure(search.value).length == 0">
                                        <div>No result found!</div>
                                    </mat-optgroup>
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedExnProcedure" class="chk-box-align"
                                            [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Extn')">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Extn')" class="mat-opt-align" *ngFor="let option of filterExtnProcedure(search.value)"
                                        [value]="option.extnprocedureid" title="{{option.extnprocedurename}}({{option.extnprocedureid}})">
                                        {{option.extnprocedurename}}({{option.extnprocedureid}})
                                    </mat-option>
                                </div>
                            </mat-select>
                            <mat-error> {{getErrorMessage(testForm.value.frmexnprocedure,'ExtProcedure')}} </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="action == 'edit'" appearance="outline" class="col-sm-3 example-additional-selection">
                            <mat-label>
                                <strong>Extraction Procedure</strong>
                            </mat-label>
                            <input #inputAutoCompleteExtn type="text" matInput maxlength="100" [matAutocomplete]="auto1"
                                formControlName="frmexnprocedure" #triggerExtn="matAutocompleteTrigger"
                                [errorStateMatcher]="matcher" class="input-inline-wrap">
                            <div matSuffix class="d-flex">
                                <button (click)="clearInput($event, 'Extn')" *ngIf=" testForm?.value.frmexnprocedure!==null && testForm?.value.frmexnprocedure!==''"
                                    aria-label="Clear" mat-icon-button type="button">
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button (click)="openOrClose($event, triggerExtn)" aria-label="Clear" mat-icon-button
                                    type="button">
                                    <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                                </button>
                            </div>
                            <mat-autocomplete #auto1="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                                (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                                [displayWith]="displayFn">
                                <mat-option class="mat-opt-align w-100" *ngFor="let c of filterExtnProcedure(testForm.value.frmexnprocedure)"
                                    disableOptionCentering [value]="c.extnprocedurename" (onSelectionChange)="onSelectionOption($event, c, 'Extn')"
                                    title="{{c.extnprocedurename}}({{c.extnprocedureid}})">
                                    {{c.extnprocedurename}}({{c.extnprocedureid}})
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error> {{getErrorMessage(testForm.value.frmexnprocedure,'ExtProcedure')}} </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="action != 'edit'" class="col-sm-3 example-additional-selection"
                            appearance="outline">
                            <mat-label>
                                <strong>Ordering Facility</strong>
                            </mat-label>
                            <mat-select placeholder="Select" disableOptionCentering #matSelectOption formControlName="frmorderingfacility"
                                [ngModelOptions]="{standalone: true}" multiple>
                                <div class="select-container">
                                    <mat-form-field class="w-100">
                                        <input #searchFac autocomplete="off" placeholder="Search" aria-label="Search"
                                            matInput formControlName="frmorderingfacility" />
                                        <button [disableRipple]="true" *ngIf="searchFac.value" matSuffix
                                            mat-icon-button aria-label="Clear" (click)="clearSearch($event, 'OF')">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-optgroup *ngIf="filterOF(searchFac.value).length == 0">
                                        <div>No result found!</div>
                                    </mat-optgroup>
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" class="chk-box-align" [(ngModel)]="allSelected"
                                            [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('OF')">Select
                                            All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('OF')" class="mat-opt-align w-100" *ngFor="let option of filterOF(searchFac.value)"
                                        [value]="option.associatedaccountid" title="{{option.displayname}}({{option.associatedaccountid}})">
                                        {{option.displayname}}({{option.associatedaccountid}})
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="action == 'edit'" appearance="outline" class="col-sm-3 example-additional-selection">
                            <mat-label>
                                <strong>Ordering Facility</strong>
                            </mat-label>
                            <input #inputAutoComplete type="text" matInput maxlength="100" [matAutocomplete]="auto2"
                                formControlName="frmorderingfacility" #triggerOF="matAutocompleteTrigger"
                                [errorStateMatcher]="matcher" class="input-inline-wrap">
                            <div matSuffix class="d-flex">
                                <button (click)="clearInput($event, 'OF')" *ngIf=" testForm?.value.frmorderingfacility!==null && testForm?.value.frmorderingfacility!==''"
                                    aria-label="Clear" mat-icon-button type="button">
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button (click)="openOrClose($event, triggerOF)" aria-label="Clear" mat-icon-button
                                    type="button">
                                    <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                                </button>
                            </div>
                            <mat-autocomplete #auto2="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                                (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                                [displayWith]="displayFn">
                                <mat-option class="mat-opt-align w-100" *ngFor="let c of filterOF(testForm.value.frmorderingfacility)"
                                    disableOptionCentering [value]="c.displayname" (onSelectionChange)="onSelectionOption($event, c, 'OF')"
                                    title="{{c.displayname}}({{c.associatedaccountid}})">
                                    {{c.displayname}}({{c.associatedaccountid}})
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-sm-3 example-additional-selection">
                            <mat-label>
                                <strong>Service</strong>
                            </mat-label>
                            <mat-select (closed)="arrowIconSubject.next('arrow_drop_down')" (opened)="arrowIconSubject.next('arrow_drop_up')"
                                (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn"
                                formControlName="frmservice">
                                <mat-option class="mat-opt-align w-100" value="All" title="All">All
                                </mat-option>
                                <mat-option class="mat-opt-align w-100" *ngFor="let c of servicesList"
                                    disableOptionCentering [value]="c" title="{{ c }}">
                                    {{ c }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="action != 'edit'" class="col-sm-3 example-additional-selection"
                            appearance="outline">
                            <mat-label>
                                <strong>Site</strong>
                            </mat-label>
                            <mat-select placeholder="Select" disableOptionCentering #matSelectOptionSite
                                formControlName="frmsite" [ngModelOptions]="{standalone: true}" multiple>
                                <div class="select-container">
                                    <mat-form-field class="w-100">
                                        <input #searchSite autocomplete="off" placeholder="Search" aria-label="Search"
                                            matInput formControlName="frmsite" />
                                        <button [disableRipple]="true" *ngIf="searchSite.value" matSuffix
                                            mat-icon-button aria-label="Clear" (click)="clearSearch($event, 'Site')">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-optgroup *ngIf="filterSite(searchSite.value).length == 0">
                                        <div>No result found!</div>
                                    </mat-optgroup>
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedSite" class="chk-box-align"
                                            [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Site')">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Site')" class="mat-opt-align w-100" *ngFor="let option of filterSite(searchSite.value)"
                                        [value]="option.siteid" title="{{option.sitename}}({{option.siteid}})">
                                        {{option.sitename}}({{option.siteid}})
                                    </mat-option>
                                </div>
                            </mat-select>
                            <mat-error>{{getErrorMessage(testForm.value.frmsite,'sitename')}}</mat-error>

                        </mat-form-field>

                        <mat-form-field *ngIf="action == 'edit'" appearance="outline" class="col-sm-3 example-additional-selection">
                            <mat-label>
                                <strong>Site</strong>
                            </mat-label>
                            <input #inputAutoCompleteSite type="text" matInput maxlength="100" [matAutocomplete]="auto4"
                                formControlName="frmsite" #triggerSite="matAutocompleteTrigger" [errorStateMatcher]="matcher"
                                class="input-inline-wrap">
                            <div matSuffix class="d-flex">
                                <button (click)="clearInput($event, 'Site')" *ngIf=" testForm?.value.frmsite!==null && testForm?.value.frmsite!==''"
                                    aria-label="Clear" mat-icon-button type="button">
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button (click)="openOrClose($event, triggerSite)" aria-label="Clear" mat-icon-button
                                    type="button">
                                    <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                                </button>
                            </div>
                            <mat-autocomplete #auto4="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                                (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                                [displayWith]="displayFn">
                                <mat-option class="mat-opt-align w-100" *ngFor="let c of filterSite(testForm.value.frmsite)"
                                    disableOptionCentering [value]="c.sitename" (onSelectionChange)="onSelectionOption($event, c, 'Site')"
                                    title="{{c.sitename}}({{c.siteid}})">
                                    {{c.sitename}}({{c.siteid}})
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>{{getErrorMessage(testForm.value.frmsite,'sitename')}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-3">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="saveStain('',testForm.value)">
                    Save & Close
                </button>
                <button *ngIf="action != 'edit'" mat-raised-button class="admin-btn-success" (click)="saveStain('new',testForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload {{templateData.menuURL.toString().trim()}}</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate(true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate(true,true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Update Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="!bulkData.length">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
            Back
        </button>
    </div>

    <div *ngIf="bulkData.length">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="bulkGridData"
            (initialized)="initGrid(grid)" [frozenColumns]="2" #grid [headersVisibility]="'Column'"
            (formatItem)="addNoteColor(grid, $event)">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname.toString().toLowerCase().trim()" [format]="'d'" [header]="columnname"
                    [visible]=" !(columnname == 'Status') && !(columnname == 'slno')" [allowResizing]="true"
                    [width]="'*'" [allowDragging]="false" [allowSorting]="false" >
                </wj-flex-grid-column>
            </div>
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="true" [fixed]="true" [format]="'d'" >
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <!-- :cell.item.status=='Ignored' ? '#ff9800' -->
                    <span
                        [ngStyle]="{color: cell.item.status=='Success' ?'green' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color:  cell.item.notes == ' will be added on approval'|| cell.item.notes=='Collection Method added' || cell.item.notes == 'Extraction Procedure will be added on approval'||
                         cell.item.notes=='Extraction Procedure added' ? 'green' : (cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' || cell.item.notes=='Sequence only takes numbers'
                        || cell.item.notes=='{{templateData.menuURL.toString()}} already exists!'  || cell.item.notes =='Zero is not allowed for Sequence') ? 'red': cell.item.notes == '{{templateData.menuURL.toString()}} added' ? 'green' : '#ff9800' }">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" freeze="true" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="align-center" *ngIf="bulkData.length > 10">
            <div class="mt-4">
                <wj-collection-view-navigator  class="mr-4" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                    [byPage]="true" [cv]="bulkGridData">
                </wj-collection-view-navigator>
                <wj-menu [(value)]="bulkGridData && bulkGridData.pageSize" [header]="'Page Size'">
                    <wj-menu-item [value]="0">No Paging</wj-menu-item>
                    <wj-menu-item [value]="10">10</wj-menu-item>
                    <wj-menu-item [value]="15">15</wj-menu-item>
                    <wj-menu-item [value]="30">30</wj-menu-item>
                    <wj-menu-item [value]="50">50</wj-menu-item>
                </wj-menu>
            </div>
        </div>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success" *ngIf="bulkData.length"
                    (click)="removeGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="bulkData.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid('list')">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid('list')">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprove()" class="admin-btn-success mr-4"
                        (click)="uploadDefaultStains()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="downloadUploadedData(grid)">Download</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span >{{invalidColumns}}</span>
    </div>
</div>


<!-- Configure Default stain Modal -->
<div class="modal" tabindex="-1" role="dialog" aria-hidden="true" [ngClass]="{ 'd-block': showModal } ">
    <div class="modal-dialog modal-dialog-centered default-stain">
        <div class="modal-content">
            <div class="modal-header admin-model-header">
                <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
                    Configure Default Stain
                </h3>
                <span type="button" data-dismiss="modal" (click)="closePopup()" class="mr-2">
                    <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                        title="Close">
                </span>
            </div>

            <div class="modal-body" *ngIf="!noDataFound">
                <wj-flex-grid [headersVisibility]="'Column'" [itemsSource]="configdefaultstaindata1"
                    [autoClipboard]="false">
                    <ng-container *ngFor="let column of gridColumns">
                        <wj-flex-grid-column [header]="column.header" [isReadOnly]="true" [fixed]="true"
                            [allowResizing]="true" [allowDragging]="false" [binding]="column.binding" [format]="column.format"
                            [width]="'*'">
                        </wj-flex-grid-column>
                    </ng-container>
                </wj-flex-grid>
            </div>

            <div class="modal-body" *ngIf="noDataFound">
                <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noDataFound">
                    <div class="col-sm-12 nodata-wrap">
                        <div class="nodata-design">{{message}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer row d-flex align-center iconexport col-sm-12" *ngIf="!noDataFound">
                <div class="help-icon-container col-sm-5 ml-0 p-0">
                    <img src="../../../assets/icons/help.svg" alt="Help" class="material-icons helpicon md-10 mr-1"
                        title="Help">
                    <strong>Only 100 records are displayed, to view more click export</strong>
                </div>
                <div class="export-button-container col-sm-6 ml-0 p-0">
                    <button mat-raised-button class="admin-btn-success" *ngIf="!exportBtn" [disabled]="!hideSaveBtn"
                        (click)="exportDataToExcel()">Export</button>

                    <!-- <button mat-raised-button class="admin-btn-success" *ngIf="exportBtn" [disabled]="exportBtn">Export</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
