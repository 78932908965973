import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { error } from 'console';
import { Validators } from 'ngx-editor';
import { map, filter, tap, BehaviorSubject } from 'rxjs';
import { LabadminService } from 'src/app/labadmin/labadmin.service';

@Component({
  selector: 'app-fax',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.scss']
})
export class FaxComponent implements OnInit {

  constructor(
    private _labadminservice: LabadminService,
    private _fb: FormBuilder,
    public _dialogRef: MatDialogRef<FaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick() {
    this._dialogRef.close('');
  }

  faxAdvanceAlert = '';
  casetypesList: any = []
  servicesList: any = this.data.Services.map((i,index)=>({value: i, index :index + 1}))
  accountId: number = this.data.accountId
  ruleName: string = this.data.ruleName
  physicianList: any
  ruleId: number = 0
  loggedInUserId = this.data.loggedInUserId
  labOrgId: number = this.data.labOrgid
  orderingCaseCategorySelection = new SelectionModel<any>(true, []);
  orderingPhysicianSelection = new SelectionModel<any>(true, []);
  orderingServiceSelection = new SelectionModel<any>(true, []);
  labAccountDetails = this.data.labAccountDetails
  caseCategoriesList: any = []
  SavedVAExclusionData: VaRulesExclusions[] = []

  faxForm = this._fb.group({
    laboratory: [0, [Validators.required]]
  })
  get getter(){
    return this.faxForm.controls.laboratory
  }

  ngOnInit(): void {
  
    this.faxForm.controls.laboratory.valueChanges.subscribe((res) => {
      this.LoadCaseCategoryPhysicianDataForFax(res)
    })
    if (this.labAccountDetails.length == 1) {
      this.faxForm.controls.laboratory.patchValue(this.labAccountDetails[0].AccountId)
      // this.LoadCaseCategoryPhysicianDataForFax(this.labAccountDetails[0].AccountId, 5)
    }
  }

  LoadCaseCategoryPhysicianDataForFax(locationId: any = '', accountId: number = 0) {
    var LabaccountId = locationId == '' ? 0 : locationId
    this.changeLoadingState(this.loaderKeys.caseListSection,this.getLoaderObj(true))
    this.changeLoadingState(this.loaderKeys.physicianSection,this.getLoaderObj(true))
    this.changeLoadingState(this.loaderKeys.servicesSection,this.getLoaderObj(true))
    this.GetVARulesSearchDetails(this.ruleName, this.accountId, LabaccountId)
    this.getCasetypeByAccId(LabaccountId);
    this.getPhysicianData(LabaccountId)
    this.orderingServiceSelection.clear()
    const exclusionIds = new Set(
      this.SavedVAExclusionData
        .filter(exclusion => exclusion.entityType === 'Service')
        .map(exclusion => +exclusion.entityId)  // Convert entityId to number
    );
    this.orderingServiceSelection.select(...this.servicesList.filter(attribute => !exclusionIds.has(attribute.index)))
    this.changeLoadingState(this.loaderKeys.servicesSection,this.getLoaderObj(false))

  }

  GetVARulesSearchDetails(printingName: string, acctId: number, LabAccountId: number) {
    var SearchValue = `${LabAccountId}_${acctId}_${printingName}`;
    this._labadminservice.GetVARulesSearchDetails(SearchValue, this._labadminservice.deploymentKey).subscribe({
      next: (data: any) => {
        if (data && data.length) {
          this.ruleId = data[0].RuleId
          if (this.ruleId) {
            this.VaruleExclusionDetails(this.ruleId)
          }
        }
      }, error: (err) => console.error(err)
    })
  }

  getPhysicianData(LabaccountId: any) {
    this.changeLoadingState(this.loaderKeys.physicianSection,this.getLoaderObj(true))
    this._labadminservice.physicianDataForFaxandPrinting(LabaccountId, this.loggedInUserId, 'LIS').subscribe({
      next: (data: any) => {
        this.physicianList = data
        this.orderingPhysicianSelection.clear()
        const exclusionIds = new Set(
          this.SavedVAExclusionData
            .filter(exclusion => exclusion.entityType === 'orderingPhysician')
            .map(exclusion => +exclusion.entityId)
        );  
        this.orderingPhysicianSelection.select(...data.filter(attribute => !exclusionIds.has(attribute.UserID)))
        this.changeLoadingState(this.loaderKeys.physicianSection,this.getLoaderObj(false))

      }, error: (err) =>( console.log(err),    this.changeLoadingState(this.loaderKeys.physicianSection,this.getLoaderObj(true)))
    })
  }

  getCasetypeByAccId(labAccId) {
    this.changeLoadingState(this.loaderKeys.caseListSection,this.getLoaderObj(true))

    const exclusionIds = new Set(
      this.SavedVAExclusionData
          .filter(exclusion => exclusion.entityType === 'Casetype')
        .map(exclusion => exclusion.entityId)
    );
    this._labadminservice.getCaseCategoryByAccId('0', this.labOrgId, labAccId, this._labadminservice.deploymentKey).pipe(
      map(caseCategories =>
        caseCategories.map(category => ({
          ...category,
          selectionModel: new SelectionModel(true, [...this.getCasetypeIdHelper(category.Cases.filter(caseItem => !exclusionIds.has(caseItem.CaseTypeDisplayname)))])
        }))
      )
    ).subscribe({
      next: (data) => {
        this.caseCategoriesList = data
        this.changeLoadingState(this.loaderKeys.caseListSection,this.getLoaderObj(false))
      },
      error: (err) => (console.error(err),    this.changeLoadingState(this.loaderKeys.caseListSection,this.getLoaderObj(false,err)))
    })
  }

  getCasetypeDisplayNameHelper(caseType: any) {
    return caseType.reduce((p, c) => ([...p, c.CaseTypeDisplayname]), [])
  }

  getCasetypeIdHelper(caseType: any) {
    return caseType.reduce((p, c) => ([...p, c.CaseTypeId]), [])
  }

  saveCaseType() {
    var res = this.caseCategoriesList.reduce((p, c) => (p || c.selectionModel.hasValue()), false)
 
    if (!res && this.orderingServiceSelection.selected.length == 0 && this.orderingPhysicianSelection.selected.length == 0) {
      alert("Excluding all is not allowed, Please include at least one checkbox in each Category")
      console.log("Excluding all is not allowed, Please include at least one checkbox in each Category")
      return
    }

    var saveFilteredCaseTypes: any = [];

    for (const obj of this.caseCategoriesList) {

      let selectedCaseIds = obj.selectionModel.selected
      obj.Cases.map((i: any) => {
        if (!selectedCaseIds.includes(i.CaseTypeId)) {
          saveFilteredCaseTypes.push({
            "ruleId": this.ruleId,//to be added
            "entityType": 'Casetype',
            "entityId": i.CaseType
          })
        }
      });
    }

    var service = ["Technical Only", "Global", "Professional Only", "Consult"]

    this.data.Services.filter((x: any) => !this.orderingServiceSelection.selected.includes(x)).map((i: any) => {
      saveFilteredCaseTypes.push({
        "ruleId": this.ruleId,
        "entityType": 'Service',
        "entityId": service.indexOf(i.trim()) + 1
      })

    });

    this.physicianList.filter((x: any) => !this.orderingPhysicianSelection.selected.includes(x)).map((i: any) => {
      saveFilteredCaseTypes.push({
        "ruleId": this.ruleId,
        "entityType": 'orderingPhysician',
        "entityId": i.UserID
      })
    });

    if (this.ruleId > 0) {
      this.DeleteVaRulesExclusions(this.ruleId, saveFilteredCaseTypes)
      this._dialogRef.close('')
    }
    else this._dialogRef.close(saveFilteredCaseTypes);
  }

  DeleteVaRulesExclusions(ruleID: number, resData: any) {
    this._labadminservice.DeleteVaRulesExclusions(ruleID, this._labadminservice.deploymentKey).subscribe({
      next: (data) => {
        this._labadminservice.InsertVARulesExclusions(data.loggedInUserId, resData,this._labadminservice.deploymentKey).subscribe({
          next: (data) => console.log(data),
          error: (err) => console.error(err)
        })
      },
      error: (err) => console.error(err)
    })
  }

  toggleAllCaseCategory(caseCategoriesList) {
    if (this.isAllCasesCategorySelected(caseCategoriesList)) {
      for (let i of caseCategoriesList) {
        i.selectionModel.clear()
      }
      return
    }
    for (let i of caseCategoriesList) {
      i.selectionModel.clear();
      i.selectionModel.select(...this.getCasetypeIdHelper(i.Cases))
    }
  }

  isAllCasesCategorySelected(caseCategoriesList) {
    return caseCategoriesList.reduce((p, c) => (p && this.isAllCaseSelected(c.Cases, c.selectionModel)), true)
  }

  toggleAllCases(caseCategoryList, selectionmodel) {
    if (this.isAllCaseSelected(caseCategoryList.Cases, selectionmodel)) {
      selectionmodel.clear()
      return
    }
    selectionmodel.select(...this.getCasetypeIdHelper(caseCategoryList.Cases))
  }

  isAllCaseSelected(casesList, selectionmodel) {
    return casesList.length === selectionmodel.selected.length
  }

  toggleAllServices() {
    if (this.isSelectedService()) {
      this.orderingServiceSelection.clear();
      return;
    }
    this.orderingServiceSelection.select(...this.servicesList);
  }

  isSelectedService() {
    const numSelected = this.orderingServiceSelection.selected.length;
    const numRows = this.servicesList.length;
    return numSelected === numRows;
  }

  toggleAllPhysicians() {
    if (this.isSelectedPhysicains()) {
      this.orderingPhysicianSelection.clear();
      return;
    }
    this.orderingPhysicianSelection.select(...this.physicianList);
  }

  isSelectedPhysicains() {
    const numSelected = this.orderingPhysicianSelection.selected.length;
    const numRows = this.physicianList.length;
    return numSelected === numRows;
  }

  VaruleExclusionDetails(ruleId: number) {
    this._labadminservice.GetVAruleExclusionDetails(ruleId, this._labadminservice.deploymentKey).subscribe({
      next: (data: VaRulesExclusions[]) => {
        this.SavedVAExclusionData = data
      },
      error: (err) => console.error(err)
    })
  }
  //#region  loader
  loaderKeys={caseListSection:'caseListSection',servicesSection:'servicesSection',physicianSection:'physicianSection'}
  loaderSections={
    caseListSection:{loading:true,error:null},
    servicesSection:{loading:true,error:null},
    physicianSection:{loading:true,error:null}
  }
  loader$=new BehaviorSubject<any>(this.loaderSections)
  getLoaderObj=(loading:boolean=false,error:string|null=null)=>({loading,error})
  changeLoadingState(sectionKey,value:{loading:boolean,error:string|null})
  {
    let result = this.loader$.value
    result[sectionKey] = value
    this.loader$.next(result)
  }
 

  //#endregion

}

export interface VaRulesExclusions {
  exclusionId: string,
  ruleId: number,
  entityType: string,
  entityId: string,
}
