export class QualityAttributes{
    roles: string;
    associationtype: string;
    organizationid: string;
    diagnosis: number;
    processing: number;
    grossing: number;
    accession: number;
    reports: number;
    category: string;
    attrid: number | null;
    attrdescription: string;
    attrorder: number;
    attrname: string;
    entitytype: string;
    attrtype: string;
    defaultvalue: string;
    ismirp: number;
    ishippa: number;
    requisition: number;
    isactive: number;
    usedforqc: number;
    iscomment: number;
    allowexternalsync: number;
    stage: number;
    tablename: string;
    grouporder:number|null;
    parentid:number | null;
    slno: number;
}
