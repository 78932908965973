<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="!checkUserDetails()" [diameter]="50">
  </mat-spinner>

  <div class=" col-sm-12 row" *ngIf="checkUserDetails()" style="font-size: 12px;">
    <div class="col-sm-8">
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">NPI </span>
        <span class=" col-sm-1">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['NPI']}} <span
            *ngIf="userDetials?.['NPI'].toString().toLowerCase() !=='unknown'"
            [ngClass]="userDetials?.['NPIStatus']=='' || userDetials?.['NPIStatus']=='active'||userDetials?.['NPIStatus']==null  ?'active_lable':'Inactive_lable'">{{userDetials?.['NPIStatus']==''
            || userDetials?.['NPIStatus']=='active' ||userDetials?.['NPIStatus']==null
            ?'Active':userDetials?.['NPIStatus']}}</span></strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Login ID </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['LoginName']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Middle Name </span>
        <span class=" col-sm-1  ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['MiddleInitial']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Gender </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['Gender']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Alias Name </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['AliasName']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Educational Qualification </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['EducationQualification']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">User Code </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['UserCode']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted" style="
        font-size: 10px;
    ">Display Name in VitalDx Application </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['FormattedDisplayName']}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted" style="
        font-size: 10px;
    ">Display Name in Gross Description </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['GrossFormattedDisplayName']}}</strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Name Printed on Reports</span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-6 pl-0">{{userDetials?.['ReportFormattedDisplayName']}}</strong>
      </div>

      <div class="row mb-1">
        <span class=" col-sm-5 _600 text-muted">Signature</span>
        <span class=" col-sm-1 ">: </span>
        <!-- Signature -->
        <div class="col-sm-6 p-0">
          <div class="row">
            <div *ngIf="sign!=''" class="col-sm-12">
              <img [src]="sign" alt="" width="100%" height="70">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-2 px-0">Role :</div>
        <div class="col-sm-10 pl-0 pr-0">

          <div [ngClass]="item.AllowPathAsMedicalDirector ?' role_highlight':'' " *ngFor="let item of userRoles">
            <div class="d-flex">
              <mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> {{item.RoleDisplayName}} <span
                *ngIf="item.IsPrimary" class="primary_role ml-2"> Primary </span>
            </div>
            <div *ngIf="item.AllowPathAsMedicalDirector" class="ml-4">
              choose a <strong>Medical Director <i class="fa fa-toggle-on toggle_on ml-1"
                  aria-hidden="true"></i></strong>
            </div>
            <div *ngIf="item.Role == 'Technician' && item.IsGrosser" class="ml-4">
              choose a <strong> Grosser <i class="fa fa-toggle-on toggle_on ml-1" aria-hidden="true"></i></strong>
            </div>
            <div *ngIf="item.Role == 'Technician' && item.IsGrossingTranscriptionist" class="ml-4">
              choose a <strong> IsGrosser Transcriptionist <i class="fa fa-toggle-on toggle_on ml-1"
                  aria-hidden="true"></i></strong>
            </div>
          </div>

          <!-- <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Customer Service</div>
          <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Cytotechnologist</div>
          <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Cytotechnologist Supervisor
          </div>
          <div class="d-flex"><mat-icon class="  mr-1 checkbox_checked ">check_box</mat-icon> Lab Administator</div>
          <div class="d-flex"><mat-icon class="  mr-1 checkbox_checked ">check_box</mat-icon> Lab Director</div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
  <span class="col-sm-12 nodata-wrapper">No Data Found</span>
</div> -->
</div>