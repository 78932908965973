<form>
    <div class="row p-0 m-0">
        <div class="col-2 p-2">
            <input type="radio" name="add" id="add" value="add" (change)="ShowPopup('add')" />
              <label>Add</label><br>
        </div>
        <div class="col-2 p-2">
            <input type="radio" name="add" id="update" value="update" (change)="ShowPopup('update')" />
              <label>Update</label><br>
        </div>
    </div>
</form>

<div *ngIf="showpop">
    <!-- Modal body -->
    <div class="container-fluid">
        <div class="row mb-1">
            <div class="col-lg-12 p-0 m-md-1 m-lg-0" *ngIf="iftemplateUpdate">
                <div style="width: 100%;
                        margin-top: 74px;">
                    <div class="container-fluid">
                        <div class="row mb-1" style="margin-top: -81px;">
                            <div class="col-lg-10 p-0 m-md-1 m-lg-0">
                                <div class="col-md-12 p-0 m-0">
                                    <input type="file" (change)="onFileSelect($event)" />
                                </div>
                                <div >
                                    <button mat-raised-button class="admin-btn-success"  type="submit" (click)="onSubmit()"
                                        [disabled]="hideUploadBtn">Upload</button>
                                </div>
                            </div>
                        </div>
                        <wj-flex-grid #flex [isReadOnly]="true" [selectionMode]="'None'" [headersVisibility]="'Column'"
                            [(itemsSource)]="gridData" (initialized)="initializeGrid(flex)" style="margin-top: 78px;">
                            <wj-flex-grid-column [binding]="'TemplateID'" [header]="'TemplateID'" [isReadOnly]="true">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'AccountID'" [header]="'LocationID'" [isRequired]="true">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'Context'" [header]="'Context'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'Mode'" [header]="'Mode'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'ContextType'" [header]="'ContextType'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'DisplayName'" [header]="'DisplayName'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'TemplateType'" [header]="'TemplateType'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'Template'" [header]="'Template'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'Preview'" [header]="'Preview'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'ProductName'" [header]="'ProductName'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [binding]="'buttons'" [width]="160" [header]="'Edit'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                        </wj-flex-grid>
                        <div class="align-center">
                            <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                [byPage]="true" [cv]="gridData">
                            </wj-collection-view-navigator>
                        </div>
                        <!-- template for buttons on items in view mode -->
                        <div id="tplBtnViewMode" style="display:none" class="mt-4">
                            <button id="btnEdit" mat-raised-button class="admin-btn-success mr-4"  [disabled]="hideEditBtn">
                                <span class="glyphicon glyphicon-pencil"></span> Edit
                            </button>
                            <button id="btnDelete" mat-raised-button class="admin-btn-success">
                                <span class="glyphicon glyphicon-remove"></span> Delete
                            </button>
                            <div>
                                <!-- template for buttons on items in edit mode -->
                                <div id="tplBtnEditMode" style="display:none">
                                    <button id="btnOK" mat-raised-button class="admin-btn-success mr-4">
                                        <span class="glyphicon glyphicon-ok"></span> OK
                                    </button>
                                    <button id="btnCancel" mat-raised-button class="admin-btn-success">
                                        <span class="glyphicon glyphicon-ban-circle"></span> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 p-0 m-md-1 m-lg-0" *ngIf="ifAddUpdate">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-10 p-0 m-md-1 m-lg-0">
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="px-3">Upload (.xlsx file only)</label>
                                    <input type="file" id="file" (change)="uploadFile($event)">
                                </div>
                                <div class="col-md-5" style="display: table;">
                                    <div style="display: table-cell; vertical-align: middle;">
                                        <button mat-raised-button class="admin-btn-success"
                                            (click)="addDataToGrid()" [disabled]="hideDisplayfile || ifUpload">Display
                                            File</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="ifaddGrid">
                    <div class="col-lg-12 p-0 m-md-1 m-lg-0">
                        <div style="width: 97%;margin-top: 74px; ">
                            <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridexceldata"
                                #grid>
                                <div *ngFor="let columnname of gridexcelHeader">
                                    <wj-flex-grid-column [binding]="columnname"
                                        [header]="columnname.split('_').join(' ')" [width]="170" [allowResizing]="true">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>

                            </wj-flex-grid>
                            <br />
                            <div class="align-center">
                                <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                    [byPage]="true" [cv]="gridexceldata">
                                </wj-collection-view-navigator>
                            </div>

                            <div *ngIf="gridexcelwidth == 0"  class="nodata-wrap mt-4">
                                <div  class="nodata-design"> No Data Found
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 p-0 m-md-1 m-lg-0">
                        <button mat-raised-button class="admin-btn-success" style="    position: absolute;
                                            left: 1122 px;" (click)="saveData()" [disabled]="ifFileUpload">Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="Invalidfilealert">
                    <div class="col-8" style="text-align: center;color: red;">Invalid file!!</div>
                </div>
            </div>
        </div>
    </div>
</div>
