<div *ngIf="gridScreen">
    <div class="modal-header admin-model-h mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-h-txt main-title">
            <strong>{{actionTitle}}</strong>
        </h3>
    </div>
    <!-- Sequence Schema list -->
    <div *ngIf="gridWidth > 0" class="modal-content bordernone">
        <div *ngIf="showDefaultFormats" class="button-field-wrap p-0 m-0 row col-sm-12">
            <div class="instr-size mb-1 col-sm-12 p-0">
                <strong>
                    Note: No data configured in Sequence Schema. Format shown in the grid will be
                    generated by
                    default.
                </strong>
            </div>
        </div>
        <wj-flex-grid [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="formatGridData" #grid
            (initialized)="initGrid(grid)">
            <wj-flex-grid-column *ngIf="!showDefaultFormats" [header]="'Action'" align="center" [freeze]="true"
                [width]="82" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row">
                    <button class="edit-delete-btn" [disabled]="hideEditBtn">
                        <em id="editButton" (click)="openEditScreen(grid,$event)" [title]="'Edit'"
                            class="fa fa-edit"></em>
                    </button>
                    <button [disabled]="hideDeleteBtn" *ngIf="isDeletable(row)" class="edit-delete-btn">
                        <em id="deleteButton" (click)="deleteRecord(grid,$event)" [title]="'Delete'"
                            class="fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [width]="'*'" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
                [header]="col.header" [visible]="!(col.header == 'Case Type')" [allowResizing]="true"
                [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="col-sm-12 mt-4 align-center">
            <wj-collection-view-navigator class="mr-2" *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:no} of {pageCount:no}" [byPage]="true" [cv]="formatGridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="formatGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <div class="col-sm-12 mt-4 align-center">
            <div class="sub-button">
                <button mat-raised-button class="admin-btn mr-4" (click)="ExportExcel(grid)">Export</button>
                <button mat-raised-button class="admin-btn mr-4" [disabled]="hideCreateBtn"
                    (click)="createFormat()">Create</button>
            </div>
        </div>
    </div>

    <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
        <div class="col-sm-10 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>

    <div class="align-center" *ngIf="gridWidth == 0">
        <div class="sub-button mt-4">
            <button [disabled]="hideCreateBtn" (click)="createFormat()" mat-raised-button
                class="admin-btn mr-4">Create</button>
        </div>
    </div>
</div>

<div *ngIf="addEditScreen" class="col-sm-12 manage-seq">
    <div class="modal-header admin-model-h mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-h-txt main-title">
            <strong>{{actionTitle}}</strong>
        </h3>
    </div>
    <form [formGroup]="seqFormGrp" oninput autocomplete="off">
        <div class="col-sm-12 d-flex criteria-alignment">
            <mat-form-field class="mr-2 m-0 p-0 criteria-style example-additional-selection disable-readonly" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Sequence Type</mat-label>
                <input matInput type="text" value="Accession" readonly>
            </mat-form-field>

            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection" [ngClass]="editEnabled ? 'disable-readonly' : 'criteria-style'" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong>OF (Group) </strong>
                </mat-label>
                <input type="text" [readonly]="editEnabled" matInput maxlength="100"
                    [matAutocomplete]="orderingFac" formControlName="frmOrdFacility" title="{{seqFormGrp?.value.frmOrdFacility}}"
                    #trigger11="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;" />
                <mat-error class="mt-2"
                    *ngIf="seqFormGrp.get('frmOrdFacility').hasError('required') || (seqFormGrp.get('frmOrdFacility').hasError('required') && seqFormGrp.get('frmOrdFacility').dirty)">
                    {{'This field is required'}}</mat-error>
                <div class="m-0 p-0" *ngIf="!editEnabled" matSuffix style="display:flex">
                    <button class="m-0 p-0" (click)="clearSearch($event, 'OF')" *ngIf = "seqFormGrp?.value.frmOrdFacility"
                        aria-label="Clear" mat-icon-button type="button">
                        <mat-icon class="m-0 p-0">clear</mat-icon>
                    </button>
                    <button class="m-0 p-0" (click)="openOrClosePanel($event, trigger11)" aria-label="Clear" mat-icon-button
                        type="button">
                        <mat-icon class="m-0 p-0">{{arrowIconSubject1.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #orderingFac="matAutocomplete"
                    (closed)="arrowIconSubject1.next('arrow_drop_down')"
                    (opened)="arrowIconSubject1.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject1.next('arrow_drop_down')" [displayWith]="displayFn">
                    <div *ngFor="let a of filterOF(seqFormGrp.value.frmOrdFacility)">
                        <mat-option class="mat-opt-align" (onSelectionChange)="getOfID($event,a)"
                            title="{{a.organizationname}} ({{a.organizationid}})" disableOptionCentering
                            value="{{a.organizationname}} ({{a.organizationid}})">
                            {{ a.organizationname }} ({{ a.organizationid }})
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection" [ngClass]="editEnabled ? 'disable-readonly' : 'criteria-style'" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong>
                        Casetype </strong>
                </mat-label>
                <input #inputAutoCompleteCasetype [readonly]="editEnabled" type="text" matInput maxlength="100"
                    [matAutocomplete]="casetypeAutoComplete" formControlName="frmCasetype" title="{{seqFormGrp?.value.frmCasetype}}"
                    #trigger1="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;" />
                <mat-error class="mt-2"
                    *ngIf="seqFormGrp.get('frmCasetype').hasError('required') || (seqFormGrp.get('frmCasetype').hasError('required') && seqFormGrp.get('frmCasetype').dirty)">
                    {{'This field is required'}}</mat-error>
                <div *ngIf="!editEnabled" matSuffix style="display:flex">
                    <button (click)="clearSearch($event, 'Casetype')" *ngIf = "seqFormGrp?.value.frmCasetype"
                        aria-label="Clear" mat-icon-button type="button">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <button (click)="openOrClosePanel($event, trigger1)" aria-label="Clear" mat-icon-button
                        type="button">
                        <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #casetypeAutoComplete="matAutocomplete"
                (closed)="arrowIconSubject.next('arrow_drop_down')"
                (opened)="arrowIconSubject.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                    <mat-option title = "{{option.casetype}}" class="mat-opt-align" *ngFor="let option of filteredOptions | async"
                        disableOptionCentering [value]="option.casetype" (click)="optionClick('Casetype')">
                        {{option.casetype}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-sm-2 mr-2 m-0 p-0 criteria-style example-additional-selection" appearance="fill">
                <mat-label class="text-align  mb-0 mt-7"><strong> Service<span *ngIf="seqFormGrp.controls.frmBillto.value.length > 0"
                ></span></strong></mat-label>
                <mat-select (openedChange)="openedChange($event); searchS.focus()" placeholder="Select"
                    disableOptionCentering #matSelectOptionServiceType formControlName="frmSerType"
                    [sortComparator]="sortFunc" multiple (selectionChange)="serviceChanged()">
                    <mat-select-trigger>
                        {{matSelectOptionServiceType._value ? matSelectOptionServiceType._value[0] : ''}}
                        <span *ngIf="matSelectOptionServiceType._value?.length > 1" class="additional-selection">
                            (+{{matSelectOptionServiceType._value.length - 1}}
                            {{matSelectOptionServiceType._value?.length === 2 ? 'other' : 'others'}})
                        </span>
                    </mat-select-trigger>

                    <div class="select-container">
                        <mat-form-field class="dd w-100 m-0 p-0">
                            <input #searchS [formControl]="searchServices" (keydown)="$event.stopPropagation()"
                                autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                [ngModelOptions]="{standalone: true}" />
                            <button [disableRipple]="true" *ngIf="searchS.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearSearch($event, 'Service')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-optgroup *ngIf="(filteredServiceOptions | async)?.length == 0">
                            <div>No result found!</div>
                        </mat-optgroup>
                        <div class="select-all">
                            <mat-checkbox *ngIf="(filteredServiceOptions | async)?.length != 0" [color]="task.color"
                                class="select-all" [(ngModel)]="allSelectedService"
                                [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Service')">Select
                                All</mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick('Service')"
                            *ngFor="let option of filteredServiceOptions | async" class="mat-opt-align w-100"
                            [value]="option" title="{{option}}">
                            {{option}}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-error class="mt-2"
                    *ngIf="seqFormGrp.get('frmSerType').hasError('required') || (seqFormGrp.get('frmSerType').hasError('required') && seqFormGrp.get('frmSerType').dirty)">
                    {{'This field is required'}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-sm-2 mr-2 m-0 p-0 criteria-style example-additional-selection" appearance="fill">
                <mat-label class="text-align mb-0 mt-7" ><strong> Bill To <span *ngIf="seqFormGrp.controls.frmSerType.value.length > 0"
                  ></span></strong></mat-label>
                <mat-select (openedChange)="openedChange($event); searchC.focus()" placeholder="Select"
                    disableOptionCentering #matSelectOptionBillTo formControlName="frmBillto"
                    [sortComparator]="sortFunc" multiple (selectionChange)="billToChange()">
                    <mat-select-trigger>
                        {{matSelectOptionBillTo._value ? matSelectOptionBillTo._value[0] : ''}}
                        <span *ngIf="matSelectOptionBillTo._value?.length > 1" class="additional-selection">
                            (+{{matSelectOptionBillTo._value.length - 1}} {{matSelectOptionBillTo._value?.length === 2 ?
                            'other' : 'others'}})
                        </span>
                    </mat-select-trigger>

                    <div class="select-container">
                        <mat-form-field class="dd w-100 m-0 p-0">
                            <input #searchC [formControl]="searchBillTo" (keydown)="$event.stopPropagation()"
                                autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                [ngModelOptions]="{standalone: true}" />
                            <button [disableRipple]="true" *ngIf="searchC.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearSearch($event, 'BillTo')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-optgroup *ngIf="(filteredBillToOptions | async)?.length == 0">
                            <div>No result found!</div>
                        </mat-optgroup>
                        <div class="select-all">
                            <mat-checkbox *ngIf="(filteredBillToOptions | async)?.length != 0" [color]="task.color"
                                class="select-all" [(ngModel)]="allSelectedBillTo"
                                [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('BillTo')">Select
                                All</mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick('BillTo')" *ngFor="let option of filteredBillToOptions | async"
                            class="mat-opt-align w-100" [value]="option" title="{{option}}">
                            {{option}}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-error class="mt-2"
                    *ngIf="seqFormGrp.get('frmBillto').hasError('required') || (seqFormGrp.get('frmBillto').hasError('required') && seqFormGrp.get('frmBillto').dirty)">
                    {{'This field is required'}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 d-flex criteria-alignment mt-2">
            <mat-checkbox class="example-margin" formControlName="frmIsResearch" [color]="task.color">
                <span>Research</span>
            </mat-checkbox>
        </div>
        <mat-tab-group class="mt-4" style="color:white !important" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
            <mat-tab [label]="tabs[0]" id="tab" style="color:white !important" formGroupName="frmDefaultFormat">
                <div class="col-sm-12">
                    <mat-form-field class="m-0 p-0 example-additional-selection brd code-field" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Codes</mat-label>
                        <input *ngIf="disableCustom" class="bordernone" type="text" placeholder="Enter facility Code" matInput maxlength="4" formControlName="frmOFCodes">
                        <mat-select *ngIf="!disableCustom" disableOptionCentering #matSelectOptionCodes [sortComparator]="sortFunc"
                            formControlName="frmCodes" [ngModelOptions]="{standalone: true}" multiple>
                            <mat-select-trigger>
                                {{matSelectOptionCodes._value ? matSelectOptionCodes._value[0] : ''}}
                                <span *ngIf="matSelectOptionCodes._value?.length > 1" class="additional-selection">
                                    (+{{matSelectOptionCodes._value.length - 1}}
                                    {{matSelectOptionCodes._value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <div class="select-container">
                                <div class="select-all">
                                    <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCodes"
                                        class="select-all" [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllSelection('Codes',false)">
                                        Select All</mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick('Codes',false)" class="mat-opt-align"
                                    *ngFor="let option of codeList" [value]="option.item" title="{{option.item}}">
                                    {{option.item}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field [ngClass]="disableCustom ? 'disable-readonly' : 'brd'" class="m-0 p-0 example-additional-selection brd custom-field" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Custom Code</mat-label>
                        <input class="bordernone" formControlName="frmCustomCode" 
                            [maxlength]="5" matInput
                            [readonly]="disableCustom">
                    </mat-form-field>
                    <mat-form-field class="m-0 p-0 example-additional-selection brd date-field" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Date</mat-label>
                        <mat-select disableOptionCentering #matSelectOptionDate formControlName="frmDate"
                            [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                            <div class="select-container">
                                <div class="select-all">
                                    <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedDates"
                                        class="select-all" [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllSelection('Date',false)">
                                        Select All</mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick('Date',false)" class="mat-opt-align"
                                    *ngFor="let option of dateList" [value]="option.item" title="{{option.item}}">
                                    {{option.item}}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmDefaultFormat.get('frmDate').hasError('required') || (seqFormGrp.controls.frmDefaultFormat.get('frmDate').hasError('required') && seqFormGrp.controls.frmDefaultFormat.get('frmDate').dirty)">
                            {{'This field is required'}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm-1 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Division</mat-label>
                        <mat-select disableOptionCentering class="ml-2" formControlName="frmDivision">
                            <mat-option class="mat-opt-align" value="-" selected>
                                -
                            </mat-option>
                            <mat-option class="mat-opt-align" value="">
                                None
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Range</mat-label>
                        <mat-select disableOptionCentering class="ml-2" formControlName="frmRange">
                            <mat-option title="--Select--" [value]="">{{"--Select--"}}</mat-option>
                            <mat-option title="{{option.item}}" *ngFor="let option of rangeList" (click) = "optionClick('Range')"
                            [value]="option.item" class="mat-opt-align">
                                {{option.item}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmDefaultFormat.get('frmRange').hasError('required') || (seqFormGrp.controls.frmDefaultFormat.get('frmRange').hasError('required') && seqFormGrp.controls.frmDefaultFormat.get('frmRange').dirty)">
                            {{'This field is required'}}</mat-error>
                    </mat-form-field>
                    <mat-form-field [ngClass]="(searchContext == 'facility' || (!seqFormGrp.controls.frmDefaultFormat?.value.frmRange)) ? 'disable-readonly' : 'brd'" class="col-sm-1 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Begins at</mat-label>
                        <input class="bordernone" formControlName="frmBeginsAt" title="{{seqFormGrp.controls.frmDefaultFormat?.value.frmBeginsAt}}"
                            [maxlength]="getRangeLength('frmDefaultFormat')" matInput (keypress)="AllowOnlyNumber($event)"
                            [readonly]="searchContext == 'facility' || (!seqFormGrp.controls.frmDefaultFormat?.value.frmRange)">
                    </mat-form-field>

                    <mat-form-field *ngIf="seqFormGrp.controls.frmDefaultFormat?.value.frmOutput"
                      [ngClass]="outputClass()" class="col-sm-2 m-0 p-0 output-style example-additional-selection"
                      appearance="fill">
                      <mat-label>Result</mat-label>
                      <input matInput type="text" formControlName="frmOutput" title="{{seqFormGrp.controls.frmDefaultFormat?.value.frmOutput}}" [errorStateMatcher]="matcher" readonly>
                      <mat-error class="mt-2"
                          *ngIf="seqFormGrp.controls.frmDefaultFormat.get('frmOutput').status == 'INVALID' || seqFormGrp.controls.frmDefaultFormat.get('frmOutput').hasError('maxlength')">
                          {{'Max '}} {{this.resultMaxLen}} {{' chars allowed!'}}</mat-error>
                     </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab [disabled]="disableCustom" [label]="tabs[1]" id="tab" style="color:white !important" formGroupName="frmCustomFormat">
                <div class="col-sm-12">
                    <mat-form-field class="m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Codes</mat-label>
                        <mat-select disableOptionCentering #matSelectOptionCustomCodes formControlName="frmCodes"
                            [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                            <mat-select-trigger>
                                {{matSelectOptionCustomCodes._value ? matSelectOptionCustomCodes._value[0] : ''}}
                                <span *ngIf="matSelectOptionCustomCodes._value?.length > 1" class="additional-selection">
                                    (+{{matSelectOptionCustomCodes._value.length - 1}}
                                    {{matSelectOptionCustomCodes._value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <div class="select-container">
                                <div class="select-all">
                                    <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCustomCodes"
                                        class="select-all" [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllSelection('Codes',true)">
                                        Select All</mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick('Codes',true)" class="mat-opt-align"
                                    *ngFor="let option of codeList" [value]="option.item" title="{{option.item}}">
                                    {{option.item}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="m-0 p-0 example-additional-selection brd custom-field-width" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Custom Code</mat-label>
                        <input class="bordernone" formControlName="frmCustomCode" maxlength="5" matInput title="{{seqFormGrp.controls.frmCustomFormat?.value.frmCustomCode}}" type="text">
                    </mat-form-field>
                    <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Date</mat-label>
                        <mat-select disableOptionCentering #matSelectOptionCustomDate formControlName="frmDate"
                            [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                            <div class="select-container">
                                <div class="select-all">
                                    <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCustomDates"
                                        class="select-all" [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllSelection('Date',true)">
                                        Select All</mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick('Date',true)" class="mat-opt-align"
                                    *ngFor="let option of dateList" [value]="option.item" title="{{option.item}}">
                                    {{option.item}}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmCustomFormat.get('frmDate').hasError('required') || (seqFormGrp.controls.frmCustomFormat.get('frmDate').hasError('required') && seqFormGrp.controls.frmCustomFormat.get('frmDate').dirty)">
                            {{'This field is required'}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm-1 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Division</mat-label>
                        <mat-select disableOptionCentering class="ml-2" formControlName="frmDivision">
                            <mat-option class="mat-opt-align" value="-" selected>
                                -
                            </mat-option>
                            <mat-option class="mat-opt-align" value="">
                                None
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                        <mat-label class="text-align mb-0 mt-7">Range</mat-label>
                        <mat-select disableOptionCentering class="ml-2" formControlName="frmRange">
                            <mat-option title="--Select--" [value]="">{{"--Select--"}}</mat-option>
                            <mat-option *ngFor="let option of rangeList" (click) = "optionClick('Range',true)" title="{{option.item}}" [value]="option.item" class="mat-opt-align">
                                {{option.item}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmCustomFormat.get('frmRange').hasError('required') || (seqFormGrp.controls.frmCustomFormat.get('frmRange').hasError('required') && seqFormGrp.controls.frmCustomFormat.get('frmRange').dirty)">
                            {{'This field is required'}}</mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="seqFormGrp.controls.frmCustomFormat?.value.frmOutput"
                        [ngClass]="outputClass()" class="col-sm-2 m-0 p-0 output-style example-additional-selection"
                        appearance="fill">
                        <mat-label>Result</mat-label>
                        <input matInput type="text" formControlName="frmOutput" title="{{seqFormGrp.controls.frmCustomFormat?.value.frmOutput}}" [errorStateMatcher]="matcher" readonly>
                        <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmCustomFormat.get('frmOutput').hasError('maxlength')">
                            {{'Max '}} {{this.resultMaxLen}} {{' chars allowed!'}}</mat-error>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="row mt-3">
            <div class="col-sm-12">
                <span class="example-additional-selection"><b><u>Standard Templates</u></b></span>
            </div>
            <div class="col-sm-12 mt-2">
                <div class="row Sample-height">
                    <div class="col-sm-4  mt-2" *ngFor="let data of standardFormat let i = index">
                        <div *ngIf="i<3" class="card" [ngClass]="cardClass(i)" (click)="onClickCard(i,data)" [ngStyle]="{'background-color': newstatus==true ? '#e4f2ff;' : '#0364B4;' }">
                            <div class="container">
                                <p>{{data.Description}}</p>
                                <h4><b>{{data.item}}</b></h4>
                            </div>
                        </div>
                        <div *ngIf="i>2" class="card" [ngClass]="cardClass(i)" (click)="onClickCard(i,data)">
                            <div class="container">
                                <p>{{data.Description}}</p>
                                <h4><b>{{data.item}}</b></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br>
        <br><br><br>
        <div class="row p-0 mt-2">
            <div class="col-sm-3">
                <button mat-raised-button class="admin-btn" (click)="refreshGrid()">Back</button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn" (click)="saveCloseData('',seqFormGrp)">Save
                    & Close</button>
                <button mat-raised-button *ngIf="!editEnabled" class="ml-1 admin-btn"
                    (click)="saveCloseData('new',seqFormGrp)">Save
                    & Create</button>
            </div>
        </div>
        <br><br>
    </form>
</div>
