import { Injectable } from '@angular/core';
import { CardQueries } from '../../model/card-query';
import { CommonService } from '../../core/services/commonservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';
import {  MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root',
})
export class MainCardModel {
  cardQueries: CardQueries = new CardQueries();
  constructor(private commonService: CommonService, private vitalHttpServices: VitalHttpServices, public _snackbar: MatSnackBar) {

  }

  GetData(accountId: number, keyword: string, cardname: string, org: number) {
    let queryRequest = this.getParameters(accountId, keyword, cardname, org);
    if (queryRequest.Query == undefined)
      this._snackbar.open("No data found", "Failed");
    return this.vitalHttpServices.GetData(queryRequest);
  }

  getParameters(accountid: number, keyword: string, cardname: string, orgid: number) {
    let queryVariable: any
    if (keyword === "vdinstallations" && orgid !== 0) {
      queryVariable = { "id": accountid.toString(), "CardName": cardname, "orgid": orgid.toString() };

      sessionStorage.setItem("orgid", orgid.toString())
    } else {
      queryVariable = { "id": accountid.toString(), "CardName": cardname };
    }

    let queryResult: any;
    let query: string = "";
    let mainQueryIndex: number = -1;
    mainQueryIndex = this.vitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLowerCase() == keyword.toLowerCase());
    if (mainQueryIndex > -1)
      query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;

    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return queryResult;
  }
}
