<div class="row">
  <div class="col-12">
      <!-- The Modal -->
      <div class="modal" id="setFrequencyModal" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog modal-lg large-model">
              <div class="modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                      <h4 class="modal-title">Scheduled Frequency</h4>
                      <span type="button" data-dismiss="modal" (click)="closeModal()" class="mr-2">
                          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                              class="material-icons md-24 cursor" title="Close">
                      </span>
                  </div>
                  <!-- Modal body -->
                  <div class="modal-body">
                    <cron-editor #cronEditorDemo (click)="generateCronDescription(cronForm.value)" [formControl]="cronForm"  [options]="cronOptions"></cron-editor>

                      <!-- <cron-editor class="cron-label" [(cron)]="cronExpression" [disabled]="isCronDisabled"
                          [(options)]="cronOptions" (click)="generateCronDescription(cronExpression)"
                          (change)="generateCronDescription(cronExpression)"></cron-editor> -->
                          <div class="note-wrap">
                             <strong> <label>All the times are saving in {{timeZone}} time zone</label></strong>
                          </div>
                      <div class="mt-2"><span class="out-put">Output:</span>   {{cronForm.value}}</div>
                      <div *ngIf="showError" class="error">Invalid cron expression</div>
                      <div><textarea class="form-control form-font mt-2" rows="2" value="{{cronDescription}}"
                              disabled></textarea>
                      </div>

                      <div class="note-wrap"><strong>NOTE: We are supporting quartz expressions. Please use valid
                              expression.</strong></div>
                  </div>
                  <!-- Modal footer -->
                  <div class="modal-footer cron-btn-wrap mt-4">
                      <button type="button" mat-raised-button class="admin-btn-success mr-4" [disabled]="showError"
                          (click)="saveCronExpression()">Save</button>
                      <button type="button" mat-raised-button class="admin-btn-success"
                          (click)="closeModal()">Close</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
