<div *ngIf="hideupload" class="my-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload Order and Result Codes</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <em class="fa fa-chevron-down chevron-align"></em>
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="temp-font-size" (click)="downloadMandatoryFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadAllFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <div >
                    <mat-option class="temp-font-size" (click)="downloadBulkUpdateOrderCode()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update OrderCode
                    </mat-option>
                </div>
                <div >
                    <mat-option class="temp-font-size" (click)="downloadBulkUpdateResultCode()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update ResultCode
                    </mat-option>
                </div>
                <div >
                    <mat-option class="temp-font-size" (click)="downloadBulkUpdateAssociatedResultCodes()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update Associated ResultCodes
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1 mr-1" src="/assets/icons/help.svg" width="15px"
                height="auto" />
            You can download the sample template file to upload the Order and Result Codes.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="help" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid
            [headersVisibility]="'Column'" [frozenColumns]="2" (formatItem)="formatItem(grid, $event)">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="170" [allowDragging]="false"
                    [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: highlightNotes? (cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red'):''}">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><i id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></i></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="excelDataArray.length > 0" class="p-0">
                    <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                        (click)="removeGrid()">Cancel</button>
                    <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload"
                        class="admin-btn-success mr-4" (click)="uploadTestData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>

</div>



