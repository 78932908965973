import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import * as XLSX from 'xlsx';
import { searchFilterPipetemplate } from '../core/utility/tripfilter.pipe';
import { TemplateViewService } from '../core/utility/template-view.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ActivityTrackerService } from '../core/services/activity-tracker.service';
import { LabadminService } from '../labadmin/labadmin.service';
import { ShortenTextPipe } from '../core/utility/shortentext.pipe';
import { StripHtmlPipe } from '../core/utility/striphtml.pipe';

@Component({
  selector: 'app-templates-listing',
  templateUrl: './templates-listing.component.html',
  styleUrls: ['./templates-listing.component.scss'],
  providers: [ searchFilterPipetemplate, ShortenTextPipe, StripHtmlPipe ]
})
export class TemplatesListingComponent implements OnInit {
  searchText: string;
  templatefilter: object = {};
  gridheader: any[];
  GridData: any[];
  backupGridData: any[];
  groupByParam: any[];
  groupdata: any[];
  groupByKeys = [];
  Context: any;
  filename: any;
  ActiveGridData = [];
  InactiveGridData = [];
  ActiveCount = 0
  InactiveCount = 0
  AllCount = 0
  viewScreen: boolean = false
  listScreen: boolean = true
  ViewRowData: any = {}
  oldRowData: any = {}
  organizationId: any;
  createChanged: any;
  editChanged: any;
  customisedJsonData: any;
  recievedObjectFromView: any = {};
  searchPlaceHolder: string;
  selectedButton: string | null = null;
  activityEntity: any;
  filterDropdownList: any = [];
  templateCustomObject: any = [];
  selectedListFilters: any = {};
  isShowActiveTabs = true;
  selectedCaseUser = 'Group Case Comments';
  // enableMigrate = false;
  toolTipText: any;

  @Input() gridHeader
  @Input() context
  @Input() hideDelete = false;
  @Input() hideEdit = false;
  @Input() hideCreate = false;
  @Input() hideExport = false;
  @Input() hideUpload = false;
  @Input() hideCopy = false;
  @Input() groupBy
  @Input() gridData
  @Input() templateData: any;
  @Input() placeHolders: any;
  @Input() subMenuCardModel: any;
  @Input() selectedListOption: any;
  @Input() saveOrEditCompleted: any;
  @Input() showHeaderModule = true;
  @Input() showTopButtons = true;
  @Input() hideSort = false;
  @Input() isUserLevel = false;
  @Input() isAdmin3 = true;
  @Input() hideManage = true;
  @Input() hideMigrate = true;
  @Input() enableMigrate = true;
  @Input() caseTypeUserRoles: any;
  @Input() selectedRole: any;
  @Input() gridwidth: any;
  @Input() isAutoCompleteList = false;

  @Output() closingListTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteTemplateClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveOrEditEventFromListingTriggered = new EventEmitter<any>();
  @Output() copyTemplateEventTriggered = new EventEmitter<any>();
  @Output() uploadTemplateEventTriggered = new EventEmitter<any>();
  @Output() editTemplateClicked = new EventEmitter<any>();
  @Output() CreateTemplateClicked = new EventEmitter<any>();
  @Output() emitFilters = new EventEmitter<any>();
  @Output() manageEventTriggered = new EventEmitter<any>();
  @Output() migrateEventTriggered = new EventEmitter<any>();
  @Output() roleChangedEventTriggered = new EventEmitter<any>();

  constructor(private searchfiltersTemplate: searchFilterPipetemplate,
    private templateViewService: TemplateViewService,
    private commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private VitalHttpServices: VitalHttpServices,
    private changeDetectorRef: ChangeDetectorRef,
    public activityService: ActivityTrackerService,
    private labAdminService : LabadminService) { }

  ngOnInit(): void {
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    // this.templateViewService.globalMasterDataForQueries['CaseType'] = this.templateData?.secondarykeys?.casetype?.toString();
    this.templateViewService.globalMasterDataForQueries['CaseType'] = this.templateData?.secondarykeys?.casetype ? this.templateData?.secondarykeys?.casetype?.toString() : '';
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    let fieldObject = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    this.isShowActiveTabs = fieldObject?.hasStatusColumn;
    if(fieldObject?.['listFilterDropdownFields']) {
      this.getFilterDropDowns(fieldObject?.['listFilterDropdownFields']);
    }
  }

  ngOnChanges() {
    this.GridData = this.gridData?._pgView?.length ? this.gridData?._pgView : [];
    this.filename = this.context
    this.viewScreen = false;
    this.ActiveCount = 0
    this.InactiveCount = 0
    this.AllCount = 0
    this.ActiveGridData = [];
    this.InactiveGridData = [];
    this.backupGridData = this.GridData;
    this.alterGridData();
    this.selectButton('All')
  }

  async getFilterDropDowns(fieldObject) {
    await this.templateViewService.getAllMasterData(null, null, this.subMenuCardModel, this.templateData, null, fieldObject);
    this.templateCustomObject = structuredClone(fieldObject);
  }

  alterGridData() {
    for (let i = 0; i < this.backupGridData?.length; i++) {
      for (const [key, value] of Object.entries(this.backupGridData[i])) {
        if (key === 'Is_Default' || key === 'Default' || key === 'Abnormal' || key === 'IsDefault') {
          if (value == 'Yes' || value == true) {
            this.backupGridData[i][key] = 'Yes'
          } else {
            this.backupGridData[i][key] = 'No'
          }
        }
        if (key === 'IsActive' || key === 'Active' || key === 'Status') {
          if (value == 'Yes' || value == true || value == 'Active') {
            this.backupGridData[i][key] = 'Active';
            this.ActiveGridData.push(this.GridData[i])
          } else {
            this.backupGridData[i][key] = 'Inactive';
            this.InactiveGridData.push(this.GridData[i])
          }
        }
      }
      this.AllCount = this.backupGridData.length
      this.ActiveCount = this.ActiveGridData.length
      this.InactiveCount = this.InactiveGridData.length
    }
  }

  selectButton(buttonId: string): void {
    this.selectedButton = buttonId
    this.GridChange(buttonId)
  }

  displayedColumns(item: any, header: any) {
    return item[header.key]
  }

  GridChange(val) {
    if (val == 'Active') {
      this.GridData = this.ActiveGridData
    } else if (val == 'InActive') {
      this.GridData = this.InactiveGridData
    } else {
      this.GridData = this.backupGridData;
    }
  }


  filtertemplates() {
    for (let i = 0; i < this.gridHeader?.length; i++) {
      this.templatefilter[this.gridHeader[i].key] = this.searchText
    }
  }

  // Export Excel with file Name and sheet name
  ExportExcel() {
    let orgid = sessionStorage.getItem('org_id') && sessionStorage.getItem('org_id') != '' ? sessionStorage.getItem('org_id') : this.labAdminService.organizationId
    let filename = this.filename + '_' + orgid + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.searchfiltersTemplate.transform(this.GridData, this.templatefilter));
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.filename);
    XLSX.writeFile(wb, filename);
  }

  RedirectToView(item) {
    // this.saveOrEditCompleted = undefined;
    // this.createChanged = undefined;
    // this.editChanged = undefined;
    // this.closingListTemplate.emit('closeList');
    // this.listScreen = !this.listScreen
    // this.ViewRowData = item
    // this.oldRowData = structuredClone(item);
    // this.changeDetectorRef.detectChanges();
    let idField = this.templateViewService.getTemplateConfiguration(this.filename)?.idKey;
    this.activityEntity.entityId = item?.[idField]?.toString();
    this.activityService.setActivitySession(this.activityEntity);
    this.getMasterDataFromService('view', item);
  }

  editMethod(value) {
    // if (!['Grossing Templates', 'Revision Reasons', 'Rejection Templates', 'Screening Comments', 'Deletion Templates', 'Categories', 'Diagnosis References', 'Custom Abbreviations', 'Revision Notes', 'Adequacy Codes', 'Microscopic Codes', 'Case Comments', 'Sequester Reasons', 'Correlation Templates', 'Tubetype Templates', 'Nomenclature Templates'].includes(this.filename)) {
    // } else {
    // }
    // this.editTemplateClicked.emit({ action: 'edit', rowData: value, event: Event });
    if(['Default Role', 'Auto Assignment Rule'].includes(this.filename)) {
      this.editTemplateClicked.emit({ action: 'edit', rowData: value, event: Event });
    } else {
      this.getMasterDataFromService('edit', value);
    }
    // this.activityEntity.entityId = value.Id;
    // this.activityService.setActivitySession(this.activityEntity);
  }

  handleViewScreen(val: any) {
    if (val == 'close') {
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.listScreen = !this.listScreen;
      this.closingListTemplate.emit('openList');
      this.changeDetectorRef.detectChanges();
    }
  }

  createClicked() {
    if(['Auto Assignment Rule'].includes(this.filename))
    {
      this.CreateTemplateClicked.emit();
    } else {
      if (['Default Role'].includes(this.filename)) {
        this.CreateTemplateClicked.emit({ action: 'Create', event: Event })
      }
      // this.getAllMasterData('create');
      this.getMasterDataFromService('create');
    }
  }

  async getMasterDataFromService(type: any, value = null) {
    this.saveOrEditCompleted = undefined;
    // Call the service method instead of local method
    let configData: any = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    configData['configurableTemplateFields'] = configData['configurableTemplateFields'].filter((item: any) => {
      if (this.isUserLevel) {
        return (item?.properties?.visibility === 'user' || item?.properties?.visibility === 'both')
      } else {
        return (item?.properties?.visibility === 'org' || (item?.properties?.visibility === 'both' || item?.properties?.showInLabAdmin) || !item?.properties?.visibility)
      }
    });

    await this.templateViewService.getAllMasterData(type, value, this.subMenuCardModel, this.templateData, this.selectedListOption, configData['configurableTemplateFields']);
    if (type === 'create') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.createChanged = Math.floor(Math.random() * 500) + 1;
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen;
      this.changeDetectorRef.detectChanges();
    } else if (type === 'edit') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.createChanged = undefined;
      this.editChanged = Math.floor(Math.random() * 500) + 1;
      this.ViewRowData = value;
      this.oldRowData = structuredClone(value);
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen;
      this.changeDetectorRef.detectChanges();
    } else if (type === 'view') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.saveOrEditCompleted = undefined;
      this.createChanged = undefined;
      this.editChanged = undefined;
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen
      this.ViewRowData = value
      this.oldRowData = structuredClone(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  saveOrEditEventTriggered(object: any) {
    // this.recievedObjectFromView = object
    let obj = { oldData: this.oldRowData, newData: object };
    let fieldObject = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    this.isShowActiveTabs = fieldObject?.hasStatusColumn;
    if(fieldObject?.['listFilterDropdownFields']) {
      this.getFilterDropDowns(fieldObject?.['listFilterDropdownFields']);
    }
    this.saveOrEditEventFromListingTriggered.emit(obj);
    this.searchText = '';
    this.filtertemplates();
  }

  deleteMethod(rowData: any) {
    this.deleteTemplateClicked.emit({ action: 'delete', rowData: rowData, event: Event });
  }

  copyTemplate() {
    this.copyTemplateEventTriggered.emit();
  }

  uploadTemplate() {
    this.uploadTemplateEventTriggered.emit();
  }

  filtersChanged(field, $event) {
    this.selectedListFilters[field?.columnName] = $event.value;
    this.emitFilters.emit(this.selectedListFilters);
    this.searchText = '';
    this.filtertemplates();
  }

  // for autocomplete dropdown start.
  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions.filter((option: any) => option[filterProperty].toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  onAutoCompleteSelectionChange(e, field, particularObject) {
    if (e.source.selected) {
      field.value = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      field.dummyValue['itemId'] = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      field.dummyValue['itemName'] = particularObject[field?.properties?.source?.dataValueToDisplay[0]];
      // this.templateViewService.globalMasterDataForQueries.selectedUserObject = {...field.dummyValue};
    }

    this.searchText = '';
    this.filtertemplates();

    this.selectedListFilters[field?.columnName] = { event: e, object: particularObject };
    this.emitFilters.emit(this.selectedListFilters);

    this.selectedCaseUser = particularObject[field?.properties?.source?.dataValueToDisplay[0]];

    // if(!this.hideMigrate) {
    //   if (particularObject?.AllowUserlvl == 1 || particularObject?.AllowUserlvl == true) {
    //     this.enableMigrate = true
    //   } else {
    //     this.enableMigrate = false
    //   }
    // }
    document.dispatchEvent(new Event('click'));
  }

  migrateClicked() {
    this.migrateEventTriggered.emit();
  }

  manageClicked() {
    this.manageEventTriggered.emit();
  }

  setUserRole($event: any, selectedUser: any) {
    this.roleChangedEventTriggered.emit({ event: $event, user: selectedUser });
  }

  // Clear search bar 
  clearSearch(){
    this.searchText = '';
    this.filtertemplates();
  }

  enableToolTip(e) {
    this.toolTipText = e.scrollWidth > e.clientWidth?e.textContent:'';
  }
}