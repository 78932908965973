<div *ngIf="gridShow">
  <div class="row">
    <div class="col-md-12">
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>{{templateData.menuURL}}</strong>
        </h3>
      </div>
    </div>
  </div>
  <br>
  <div class="col-lg-12 p-0">
    <!-- the grid [ngStyle]="{ 'width.px': gridwidth }"-->
    <wj-flex-grid #flex [allowDragging]="'None'" [headersVisibility]="'Column'" [(itemsSource)]="gridData"
      (initialized)="initializeGrid(flex)" id="theGridTallRows">
      <wj-flex-grid-column [binding]="'RequisitionProfileID'" [visible]="false" [header]="'Order Code'" [isReadOnly]="true"
        [isRequired]="true" [width]="220" [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'OrderCode'" [header]="'Order Code'" [isRequired]="true" [isReadOnly]="true"
        [width]="220" [selectionMode]="'None'" [format]="'n2'" [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'OrderingFacility'" [isRequired]="true" [header]="'Ordering Facility'"
        [isReadOnly]="true" [width]="210" [selectionMode]="'None'" [format]="'n2'" [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'OrderingFacilityLocation'" [header]="'Ordering Facility Location'" [isReadOnly]="true"
        [width]="150" [selectionMode]="'None'" [format]="'n2'" [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'Physician'" [isRequired]="true" [header]="'Physician'" [isReadOnly]="true"
        [width]="230" [selectionMode]="'None'" [format]="'n2'" [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'Status'" class="align-center" [header]="'Status'" [isReadOnly]="editBtn" [width]="140"
        [minWidth]="40"></wj-flex-grid-column>
      <wj-flex-grid-column [header]="'Action'" align="center" [width]="140" [isReadOnly]="true" [minWidth]="40">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRequisitionProfile(flex,row)" [disabled]="deleteBtn"><em
              [title]="'Delete'" class="edit-template important-text p-0 fa fa-trash-o"> </em></button>
        </ng-template>
      </wj-flex-grid-column>

      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="align-center">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
        [byPage]="true" [cv]="gridData">
      </wj-collection-view-navigator>
      <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>
  </div>
  <div class="col-12 mt-4 align-center">
    <div class="row button-align">
      <button mat-raised-button class="admin-btn-success mr-4" [disabled]="exportBtn" type="button" (click)="ExportExcel()">Export</button>
      <button mat-raised-button class="admin-btn-success mr-4" [disabled]="createBtn" type="button" (click)="openCreateScreen()">Create</button>
    </div>
  </div>

</div>
<div class="col-sm-12" *ngIf="noDataFound">
  <div class="row">
    <div class="col-md-12">
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>{{templateData.menuURL}}</strong>
        </h3>
      </div>
    </div>
  </div>
  <br>
  <div class="row container create-btn align-center">
    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>
  <div class="col-12 mt-4 align-center">
    <div class="row button-align">
      <button mat-raised-button class="admin-btn-success mr-4" [disabled]="createBtn" type="button" (click)="openCreateScreen()">Create</button>
    </div>
  </div>
</div>
<div *ngIf="enableInsert">
  <div class="col-sm-12 m-0 p-0">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>Create {{templateData.menuURL}}</strong>
      </h3>
    </div>
    <form [formGroup]="addRequisitonProfile" autocomplete="off">
      <div class="text-wrap-requisitionprofile row col-sm-12 p-0 ml-0">

        <div class="row col-sm-12 mt-2">

          <mat-label class="col-sm-2">
            <strong>Order Code<span class="text-danger">*</span></strong>
          </mat-label>

          <ng-multiselect-dropdown class="col-sm-4 text-xs mt-1" [placeholder]="'Select'" #matSelectOption formControlName="OrderCode"
            [settings]="dropdownSettings" [data]="ordercodeDropdownList" (onSelect)="onCheckChange($event)"
            (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()">
          </ng-multiselect-dropdown>

          <mat-form-field appearance="outline" class="col-sm-4">
            <mat-label><strong>Scope</strong></mat-label>
            <input matInput formControlName="scope">
          </mat-form-field>

          <section class="example-section col-sm-2">
            <mat-checkbox [color]="task.color" class="example-margin" style="margin-bottom: 32px;margin-top: 10px;"
              formControlName="Status">
              Active</mat-checkbox>
          </section>

        </div>

        <div class="col-sm-12 mt-2">
          <mat-label><strong>Ordering Criteria</strong></mat-label>
        </div>

        <div class="row col-sm-12 mt-2">
          <mat-form-field appearance="outline" class="col-sm-4 pr-0">
            <mat-label><strong>Ordering Facility</strong></mat-label>
            <input  #txtInput matInput disableOptionCentering formControlName="OrderingFacility" [matAutocomplete]="org">
            <div matSuffix style="display:flex">
              <button
                  (click)="clearOrderingFacility($event)"
                  *ngIf=" addRequisitonProfile?.value.OrderingFacility!==null && addRequisitonProfile?.value.OrderingFacility!==''"
                  aria-label="Clear" mat-icon-button type="button">
                  <mat-icon style="font-size: 120% !important">clear</mat-icon>
              </button>
          </div>
            <mat-autocomplete #org="matAutocomplete">
              <div *ngFor="let item of fetchOfOrgName(addRequisitonProfile.value.OrderingFacility)">
                <mat-option class="mat-opt-align" (onSelectionChange)="setOrganization($event, item)"  value="{{item.organizationname}}({{item.associatedOrg}})"
                  title="{{item.organizationname}}({{item.associatedOrg}})">
                  <span>{{item.organizationname}}({{item.associatedOrg}}) </span>
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-sm-4 pr-0">
            <mat-label><strong>Ordering Facility Location</strong></mat-label>
            <input  #txtInput matInput disableOptionCentering formControlName="OrderingFacilityLocation" [matAutocomplete]="lab">
            <div matSuffix style="display:flex">
              <button
                  (click)="clearOrderingFacilityLocation($event)"
                  *ngIf=" addRequisitonProfile?.value.OrderingFacilityLocation!==null && addRequisitonProfile?.value.OrderingFacilityLocation!==''"
                  aria-label="Clear" mat-icon-button type="button">
                  <mat-icon style="font-size: 120% !important">clear</mat-icon>
              </button>
          </div>
            <mat-autocomplete #lab="matAutocomplete">
              <div *ngFor="let item of filterOFLocations(addRequisitonProfile.value.OrderingFacilityLocation)">
                <mat-option class="mat-opt-align" (onSelectionChange)="setOF($event, item)" value="{{item.accountname}}({{ item.accountid }})"
                  title="{{item.accountname}}({{ item.accountid }})">
                  <span>{{item.accountname}}({{ item.accountid }})</span>
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-sm-4 pr-0">
            <mat-label><strong>Physician</strong></mat-label>
            <input  #txtInput matInput disableOptionCentering formControlName="Physician" [matAutocomplete]="phy">
            <div matSuffix style="display:flex">
              <button
                  (click)="clearPhysician($event)"
                  *ngIf=" addRequisitonProfile?.value.Physician!==null && addRequisitonProfile?.value.Physician!==''"
                  aria-label="Clear" mat-icon-button type="button">
                  <mat-icon style="font-size: 120% !important">clear</mat-icon>
              </button>
          </div>
            <mat-autocomplete #phy="matAutocomplete">
              <div *ngFor="let item of filterPhysician(addRequisitonProfile.value.Physician)">
                <mat-option class="mat-opt-align"  (onSelectionChange)="setPhysician($event, item)" value="{{item.physicianname}}({{ item.physicianid }})"
                  title="{{item.physicianname}}({{ item.physicianid }})">
                  <span>{{item.physicianname}}({{ item.physicianid }})</span>
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-3">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">
            Back
          </button>
        </div>
        <div class="col-sm-9 button-wrap">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="saveAndClose()">
            Save & Close
          </button>
          <button mat-raised-button class="admin-btn-success" (click)="saveAndCreate()">
            Save & Create
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
