<form *ngIf='showComponent' [formGroup]="myForm">
    <div class="row  mt-4">
        <div class="col-sm-12 upload-wrap">
            <div class="col-sm-12 new-upload-wrap">
                <div class="row">
                    <div class="col-sm-4">
                        <label class="upload-logo">Bulk Upload for Migration</label>
                    </div>
                    <div class="col-sm-4" *ngIf="!btnmigrateuser">
                        <div class="file-height" (fileDropped)="onFileDropped($event)" appDnd>
                            <input type="file" formControlName="frmUserExcel" #fileDropRef id="fileDropRef" [multiple]="false"
                                (change)="onFileChange($event)" hidden />
                            <span>Select file</span>&nbsp;
                            <label class="mb-0 btn-sm btn rounded browse" for="fileDropRef">Browse</label>
                        </div>
                    </div>
                    <div class="col-sm-4 align-center">
                        <button mat-raised-button class="admin-btn-success" [disabled]="btnUpload" (click)="downloadTemplate()">Download
                            Template</button>
                    </div>

                    <div class="col-sm-12 mt-2">

                        <span>
                            <strong>
                                <span>Note:</span> <br>
                                1. Login name-based user(s) can be migrated to Email based login by
                                bulk upload
                                here. <br>
                                2. The downloaded sample template file should be renamed as “Users” before uploading.
                            </strong>
                        </span>
                    </div>
                    <!-- (initialized)="initGrid(grid)" -->
                    <div class="col-sm-12" *ngIf="gridDisplay">
                        <wj-flex-grid class="mt-3" #migUserGrid [ngStyle]="{ 'width.px': gridwidth }" (initialized)="initUserGrid(migUserGrid)" [isReadOnly]="true"
                            [headersVisibility]="'Column'" [frozenColumns]="3" [itemsSource]="gridData">
                            <wj-flex-grid-column *ngIf="!postUpload" [freeze]="true" [fixed]="true" [header]="'Action'"
                                align="center" [width]="'*'" [isReadOnly]="true">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                    <button class="edit-delete-btn" (title)="Delete" (click)="deleteRow(migUserGrid, row)"><em id="deleteButton"
                                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'Status'" [binding]="Status" *ngIf="postUpload"
                                [allowResizing]="true" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                    <span [ngStyle]="{color: cell.item.Status=='Success' ? 'green' : '#ff9800'}">{{cell.item.Status}}
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column *ngFor="let col of columns" [visible]="!(col.header =='Status')"
                                [width]="'*'" [minWidth]="40" [binding]="col.binding" [header]="col.header"
                                [allowResizing]="true" [allowDragging]="false" [format]="'d'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" [cellType]="'Cell'" let-cell="cell"  let-row="row">
                                    <span [ngStyle]="styleComponent(cell,col)">{{cell.item[col.binding]}}
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>

                        </wj-flex-grid>
                        <div class="navigator-fix">
                            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="pagination-align"
                                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                            </wj-collection-view-navigator>
                        </div>
                    </div>
                </div>
                <div *ngIf="gridDisplay" class="d-flex btn-align mt-4">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="resetData()">Clear</button>
                    <button mat-raised-button class="admin-btn-success" *ngIf="!postUpload" [disabled]="disableUpload(userExcelData)" (click)="uploadUserData()">Upload</button>
                </div>
            </div>
        </div>
    </div>
</form>

<div *ngIf='!showComponent' class="nodata-wrap">
    <div class="nodata-design">Bulk upload not allowed in this deployment</div>
</div>