import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-popup',
  templateUrl: './report-builder-popup.component.html',
  styleUrls: ['./report-builder-popup.component.scss'],
  // providers : [ReportBuilderComponent]
})

export class ReportBuilderPopupComponent {
  logoutreportbuilder: any;
  Depkey: any;
  orgid: any;
  email: any;
  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    public _snackbar: MatSnackBar,
    private vitalHttpServices: VitalHttpServices,
  ){
  
  }
  @Input() templateData: any;
  launchReportBuilder:boolean=false;
  organizationName: string;
  endpoint: string
  url: any;
  deploymentKey: string;
  userEmail:string;
  organizationId: string;
  hideReportBuilderBtn: boolean=true;
  ngOnInit(): void {
    this.deploymentKey = sessionStorage.getItem('DeploymentKey');
    this.userEmail=localStorage.getItem('UserEmail');
    this.organizationName=sessionStorage.getItem('Org_Name');
    this.organizationId=sessionStorage.getItem('org_id');
    this.GetButtonDetails();
    this.vitalHttpServices.Encrypt(this.deploymentKey, this.userEmail,this.organizationId).subscribe(result => {
      if(!result.error) {
        if (!this.vitalHttpServices || !this.vitalHttpServices.reporbuilderlaunch) {
          this._snackbar.open("Not able to launch ReportBuilder")
        }
        else { 
        this.endpoint=this.vitalHttpServices.reporbuilderlaunch + 'sso?data=' + result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.endpoint);
        }
        this.endpoint=this.vitalHttpServices.reporbuilderlaunch + 'sso?data=' + result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.endpoint);
      }
    }
    );
  }
  
    close(){
     this.activeModal.close();
     this.launchReportBuilder=true;
     this.vitalHttpServices.reportBuliderLogut();
  }

  

  GetButtonDetails() {

    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
     }
     GetButtonAccess(actionButtonDetails) {
      let data ='';
      // data=this.ReportBuilderComponent.templateData.headerText
       let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
       for (var i = 0; i < seletedMenuPermissions.length; i++) {
         switch (seletedMenuPermissions[i].Button) {
           case 'Launch Report Builder':
             this.hideReportBuilderBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
             break;
         }
       }
     }

}

