import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';

@Component({
  selector: 'app-infodetails',
  templateUrl: './infodetails.component.html',
  styleUrls: ['./infodetails.component.scss']
})

export class InfodetailsComponent {
  @Input() userDetials: any;
  @Input() userRoles = []

  sign: string = '';

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getOrganizationDetails(this.userDetials);
  }

  getOrganizationDetails(userDetails) {
    if (userDetails["SignatureFile"] != null) {
      this.sign = "data:image/jpg;base64," + userDetails["SignatureFile"];
    }
  }

  checkUserDetails() {
    return Object.keys(this.userDetials).length;
  }

}
