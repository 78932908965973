import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { contentHeaders } from './headers';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept (req: HttpRequest<any>, next: HttpHandler) {
    const jsonReq = req.clone({
      headers: contentHeaders
    });
    return next.handle(jsonReq);
  }
}
