<div *ngIf="hideupload" class="my-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong *ngIf="!inData">Upload Group Level Diagnosis Templates</strong>
            <strong *ngIf="inData">Upload User Level Diagnosis Templates</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="temp-font-size" (click)="downloadAllFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadICDFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>ICD Code Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadBulkUpdate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
               
                <!-- <div *ngIf="!inData">
                    <mat-option class="temp-font-size" (click)="downloadBulkUpdate()">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                    </mat-option>
                </div> -->
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1 mr-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Diagnosis Template.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="help" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4 mb-3" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid *ngIf="updateGrid" [isReadOnly]="true" [itemsSource]="gridData" #grid [headersVisibility]="'Column'"
            [frozenColumns]="2" (formatItem)="formatItem(grid, $event)" (initialized)="gridInit(grid)">
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname" [visible]="!(columnname == 'Status') && !(columnname == 'Diagnosis')"
                    [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: highlightNotes? (cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red'):''}">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><i id="deleteButton" [title]="'Delete'"
                            class="p-0 fa fa-trash"></i></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div *ngIf="!updateGrid">
            <wj-flex-grid *ngIf="!multiSheetUpload" [isReadOnly]="true" [itemsSource]="uploadGrid" #grid
                [headersVisibility]="'Column'" [frozenColumns]="2" (formatItem)="addNoteColor(grid, $event,uploadGrid)" (initialized)="gridInit(grid)">
                <div *ngFor="let columnname of uploadHeaders">
                    <wj-flex-grid-column [binding]="columnname" [header]="columnname" [visible]="!(columnname == 'Status')"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                </div>
                <!-- Status -->
                <wj-flex-grid-column [header]="'Status'" [binding]="Status" *ngIf="postUpload" [allowResizing]="true"
                    [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: highlightNotes? (cell.item.Status=='Success' ?'green':cell.item.Status=='Ignored' ? '#ff9800' : 'red'):''}">{{cell.item.Status}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>

                <!-- Action -->
                <wj-flex-grid-column *ngIf="!postUpload" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><i id="deleteButton" [title]="'Delete'"
                                class="p-0 fa fa-trash"></i></button>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            <div class="align-center" *ngIf="uploadGrid?._src.length > 10">
                <div class="mt-4">
                    <wj-collection-view-navigator  class="mr-4" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                        [byPage]="true" [cv]="uploadGrid">
                    </wj-collection-view-navigator>
                    <wj-menu [(value)]="uploadGrid.pageSize" [header]="'Page Size'">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                    </wj-menu>
                </div>
            </div>

            <mat-tab-group *ngIf="multiSheetUpload" [selectedIndex]="selectedIndex" #uploadTabs class="stretched-user-tabs">
                <mat-tab *ngFor="let sheetData of multiSheetData;let i = index" (click)="selectedIndex = i" label="{{sheetData.label}}">
                    <wj-flex-grid [isReadOnly]="true" [itemsSource]="sheetData.data" #grid [headersVisibility]="'Column'"
                        [frozenColumns]="2" (formatItem)="addNoteColor(grid, $event,sheetData.data)" (initialized)="gridInit(grid)">
                        <div *ngFor="let columnname of sheetData.columns">
                            <wj-flex-grid-column [binding]="columnname" [header]="columnname" [visible]="!(columnname == 'Status')"
                                [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                                [format]="'d'">
                            </wj-flex-grid-column>
                        </div>
                        <!-- Status -->
                        <wj-flex-grid-column [header]="'Status'" [binding]="Status" *ngIf="postUpload" [allowResizing]="true"
                            [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                <span [ngStyle]="{color: highlightNotes? (cell.item.Status=='Success' ?'green':cell.item.Status=='Ignored' ? '#ff9800' : 'red'):''}">{{cell.item.Status}}
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>

                        <!-- Action -->
                        <wj-flex-grid-column *ngIf="!postUpload" [header]="'Action'" align="center" [width]="126"
                            [isReadOnly]="true">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                <button class="edit-delete-btn" (click)="deleteRecord(multiSheetData, row,sheetData.label)"><i id="deleteButton"
                                        [title]="'Delete'" class="p-0 fa fa-trash"></i></button>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <div class="align-center" *ngIf="sheetData.data?._src.length > 10">
                        <div class="mt-4">
                            <wj-collection-view-navigator  class="mr-4" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                [byPage]="true" [cv]="sheetData.data">
                            </wj-collection-view-navigator>
                            <wj-menu [(value)]="sheetData.data.pageSize" [header]="'Page Size'">
                                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                                <wj-menu-item [value]="10">10</wj-menu-item>
                                <wj-menu-item [value]="15">15</wj-menu-item>
                                <wj-menu-item [value]="30">30</wj-menu-item>
                                <wj-menu-item [value]="50">50</wj-menu-item>
                            </wj-menu>
                        </div>
                    </div>

                    <div *ngIf="sheetData.invalidColumns" class="m-1 p-0 error-field-wrap">
                        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{sheetData.invalidColumns}} </span>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        
        <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
            <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
        </div>

        <div class="row pl-3 mb-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="showUploadBtns()" class="p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="uploadTestData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid,multiSheetData)">Download</button>
                </div>
            </div>
        </div>
    </div>

    


</div>

<!-- warning popup -->
<div *ngIf="invalidCasetypeFlag" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog1" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header pr-1">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                    <span type="button" id="button_size" data-dismiss="modal" (click)="closeCasetypeModel()">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                            title="Close">
                    </span>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="col-sm-12 align-center">
                        <p>Diagnosis templates case type is not enabled in the destination group. To upload,
                            enable the case type and then try again. <br>Upload failed for the case type:
                            {{repeatedNames}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button mat-raised-button class="admin-btn-success" (click)="closeCasetypeModel()" type="button">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
