export class VDTemplate {
    templateid? : number;
    organizationid? : number;
    context? : string;
    mode? : string;
    contexttype? : string;
    displayname? : string;
    template? : string;
    templatetype? : string;
    modifiedby? : number;
    productname? : string;
    accountid? : number;
    createdby? : number;
}

export class VDLocation {
        accountname?: string;
        accountid?: number;
        displayname?: string;
}