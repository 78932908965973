<div *ngIf="gridShowDiv">
    <div class="modal-header admin-model-header mb-3 mt-3">
        <!-- grid title -->
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>{{headerValue}}</strong>
        </h3>
    </div>

    <div class="modal-content bordernone">
        <wj-flex-grid #grid [isReadOnly]="true" [itemsSource]="gridData" [headersVisibility]="'Column'" (initialized)="initGrid(grid)">

            <div>
                <wj-flex-grid-column [header]="'Actions'" align="center" [width]="165" [isReadOnly]="true">
                    <span class="dis-action-btn">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                            <button class="edit-delete-btn" [disabled]="hideUpdateBtn" (click)="editDisclaimers('edit',grid,$event)"><em
                                    id="editButton" [title]="'Edit'" class="fa fa-edit"></em></button>

                            <button class="edit-delete-btn" [disabled]="hideDeleteBtn" (click)="deleteDisclaimers(grid)"><em
                                    id="deleteButton" [title]="'Delete'" class="fa fa-trash"></em></button>

                            <img id="copyButton" title="Copy" alt="copy" class="ml-1" [disabled]="hideUpdateBtn"
                                (click)="editDisclaimers('copy',grid,$event)" src="/assets/images/copy.svg" />
                        </ng-template>
                    </span>
                </wj-flex-grid-column>

                <wj-flex-grid-column *ngFor="let col of columns" [visible]=" !(col.header =='DisclaimerId') && !(col.header =='Sequence') && !(col.header =='ConditionID') &&
                            !(col.header =='RepeatForEachTest') && !(col.header =='Type') && !(col.header =='Status') && !(col.header =='Disclaimers') && !(col.header =='Condition')"
                    [width]="col.header =='Disclaimer' ? 920 : 270" [binding]="col.binding" [header]="col.header"
                    [fixed]="true" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="navigator-fix align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
        </div>
        <div class="sub-button mt-4">
            <button mat-raised-button class="admin-btn-success" type="button" [disabled]="hideCreateBtn" (click)="getInputData('create', '')">
                Create
            </button>
        </div>
    </div>
</div>

<!-- no data in the grid -->
<div class="col-sm-12 container create-btn mt-3 m-0 p-0" *ngIf="noDataFound">
    <div class="col-sm-12 modal-header admin-model-header mb-4">
        <!-- grid title -->
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>{{headerValue}}</strong>
        </h3>
    </div>

    <div class="col-sm-12 nodata-wrap mt-4">
        <div class="nodata-design">No Data Found</div>
    </div>

    <button mat-raised-button class="admin-btn-success" type="button" [disabled]="hideCreateBtn" (click)="getInputData('create', '')">
        Create
    </button>

</div>

<div *ngIf="saveDisclaimerForm" class="col-sm-12 disclaimer">
    <div class="col-sm-12 modal-header admin-model-header mb-3 mt-3">
        <!-- title -->
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{headerValue}}</strong>
        </h3>
    </div>

    <!-- dynamic form -->
    <h3 class="border-line side-title">Input Parameters</h3>
    <div class="container">
        <h5 class="title font-weight sub-title">Conditions</h5>
        <div class="row col-sm-12 p-1 m-1" *ngFor="let list of DynamicJson;let j = index">
            <ng-container *ngFor="let item of list.Controls;let i=index">
                <span *ngIf="item.type == 'select'" class="col-sm-2 pl-0 pr-2">
                    <mat-form-field class="case-level-desclaimer p-0" *ngIf="item.label == 'Input Type'" appearance="outline">
                        <mat-label>{{item.label}} </mat-label>
                        <mat-select disableOptionCentering title="{{item.value}}" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}"  [formControl]="item.formControl"
                            (closed)="inputSearch.value = ''" (openedChange)="inputSearch.focus()" value="{{item.value}}">
                            <div class="select-container">
                                <mat-form-field class="w-100">
                                    <input #inputSearch autocomplete="off" (keydown)="$event.stopPropagation()"
                                        placeholder="Search" aria-label="Search" matInput autofocus />
                                    <button *ngIf="inputSearch.value" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="inputSearch.value = ''">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-optgroup *ngIf="showNoResult(filterInputValues(item.label,item.data,inputSearch.value))">
                                    <div>No result found!</div>
                                </mat-optgroup>
                                <mat-option class="option-align" [hidden]="getInputs(item,value,j)" *ngFor="let value of filterInputValues(item.label,item.data,inputSearch.value)"
                                    (click)="setInputFormValues(j,value,item.label)" [value]="value" matTooltip={{value.value}}>
                                    {{value}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="case-level-desclaimer p-0" *ngIf="item.label != 'Input Type'" appearance="outline">
                        <mat-label>{{item.label}} </mat-label>
                        <mat-select disableOptionCentering title="{{item.value}}" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}"  [formControl]="item.formControl"
                            value="{{item.value}}">
                            <mat-option class="option-align" [hidden]="getInputs(item,value,j)" *ngFor="let value of item.data.sort()"
                                (click)="setInputFormValues(j,value,item.label)" [value]="value" matTooltip={{value.value}}>
                                {{value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                <!-- normal textbox  -->
                <span *ngIf="item.type == 'text'" class="col-sm-5 pr-2 pl-2">
                    <mat-form-field class="example-additional-selection" appearance="outline">
                        <mat-label>Value</mat-label>
                        <input #matRequistion class="form-control display-ord" title="{{item.value}}" [disabled]="checkFordisabled(j)"
                            *ngIf="checkFordisabled(j)" value="{{item.value}}" (keypress)="AllowOnlyNumberValue($event,$event.target.value)"
                            (input)="setInputFormValues(j,$event.target.value,item.label)" id="exampleInputPassword1"
                            placeholder="Value" type="text" matInput>
                        <input #matRequistion class="form-control display-ord" title="{{item.value}}" [formControl]="item.formControl" [disabled]="checkFordisabled(j)"
                            *ngIf="!checkFordisabled(j)" value="{{item.value}}" (keypress)="AllowOnlyNumberValue($event,$event.target.value)"
                            (input)="setInputFormValues(j,$event.target.value,item.label)" id="exampleInputPassword1"
                            placeholder="Value" type="text" matInput>
                    </mat-form-field>
                </span>

                <!-- single select -->
                <span *ngIf="item.type == 'singleselect'" class="col-sm-5 pr-2 pl-2">
                    <mat-form-field class="case-level-desclaimer" appearance="outline">
                        <mat-label>Value</mat-label>
                        <mat-select disableOptionCentering #matSingleSelect="matSelect" [formControl]="item.formControl"
                            (closed)="searchSingle.value = ''" (openedChange)="searchSingle.focus()" placeholder="Value"
                            title="{{checkData(item)}}" value="{{item.value[0]?.Name}}" matTooltip={{item.value[0]?.Name}}>
                            <div class="select-container">
                                <mat-form-field class="w-100">
                                    <input #searchSingle autocomplete="off" focused="'true'" (keydown)="$event.stopPropagation()"
                                        placeholder="Search" aria-label="Search" matInput autofocus />
                                    <button *ngIf="searchSingle.value" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="searchSingle.value = ''">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-optgroup *ngIf="showNoResult(filterSingleSelectValues(item.data,searchSingle.value))">
                                    <div>No result found!</div>
                                </mat-optgroup>
                                <mat-option *ngFor="let value of filterSingleSelectValues(item.data,searchSingle.value)"
                                    (click)="setInputFormValues(j,value,item.label)" [value]="value.Name" matTooltip={{value.Name}}>
                                    {{value.Name}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </span>

                <!-- multi select with checkbox -->
                <span *ngIf="item.type == 'multiselect'" class="col-sm-5 pr-2 pl-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Value</mat-label>
                        <!-- [formControl]="item.formCtrl" -->
                        <mat-select disableOptionCentering [formControl]="item.formControl"  [(ngModel)]="item.value"
                            #matDisclaimerMultSelect [ngModelOptions]="{standalone: true}" multiple (closed)="searchMult.value = ''"
                            (openedChange)="searchMult.focus()" (selectionChange)="setMultiselectValues(j,$event.value)">
                            <div class="select-container">
                                <mat-form-field class="w-100">
                                    <input #searchMult autocomplete="off" (keydown)="$event.stopPropagation()"
                                        placeholder="Search" aria-label="Search" matInput />
                                    <button *ngIf="searchMult.value" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="searchMult.value = ''">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-optgroup *ngIf="showNoResult(sortedData(item.data,searchMult.value))">
                                    <div>No result found!</div>
                                </mat-optgroup>
                                <mat-checkbox class="mat-opt-align" *ngIf="!showNoResult(sortedData(item.data,searchMult.value))"
                                    [color]="task.color" [indeterminate]="item.value.length > 0 && !isAllSelected(item,searchMult.value)"
                                    [checked]="isAllSelected(item,searchMult.value)" (change)="multSelectAll($event,item.data,searchMult.value,item,j)">
                                    Select All
                                </mat-checkbox>
                                <mat-option matTooltip={{value.Name}} (keydown)="$event.stopPropagation()" class="mat-opt-align"
                                    (click)="optionClick()" *ngFor="let value of sortedData(item.data,searchMult.value)"
                                    [value]="value">
                                    {{value.Name}}</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </span>
            </ng-container>

            <!-- add & delete button -->
            <span class="col-sm-1 d-flex">
                <img class="icon-align" src="/assets/images/plus_icon.svg" alt="plus" (click)="addCondition(j)" *ngIf=" DynamicJson.length>0 &&j == DynamicJson.length-1">
                <img class="icon-align ml-2" src="/assets/images/minus_icon.svg" alt="minus" (click)="deleteCondition(j)"
                    *ngIf="DynamicJson.length>1">
            </span>
        </div>
    </div>
    <form [formGroup]="disclaimerForm">
        <div class="col-sm-12 radio-wrap m-0 p-0">
            <div class="col-sm-6">
                <label for="exampleInputPassword1" class="mr-3 mb-2 font-weight type-font">Type of Disclaimer<span
                        class="error-msg">*</span></label>
                <div class="col-sm-12 radio-wrap m-0 p-0">
                    <div class="form-check form-check-inline ml-2 col-sm-6">
                        <input class="form-check-input" type="radio" value="Case" formControlName="frmdisctype"
                            (change)="onchangedesc('Case')" id="inlineRadio1">
                        <label class="form-check-label p-0" for="inlineRadio1">Case Level</label>
                    </div>
                    <div class="form-check form-check-inline col-sm-6">
                        <input class="form-check-input" checked type="radio" value="Test" formControlName="frmdisctype"
                            id="inlineRadio2" (change)="onchangedesc('Test')">
                        <label class="form-check-label p-0" for="inlineRadio2">Test Level</label>
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-sm-12 align-center">
                        <div class="col-sm-6"></div>
                        <div class="col-sm-6 mr-3">
                            <mat-checkbox [color]="task.color" class="example-margin" value="" formControlName="frmrepeatfortests"
                                [disabled]="setRepeatTest(disclaimerForm.controls.frmdisctype.value)" id="flexCheckDefault">
                                Repeat for each test</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <mat-form-field class="example-additional-selection p-0" appearance="outline">
                    <mat-label>Display Order</mat-label>
                    <input matInput class="form-control display-ord" autocomplete="off" matInput #input formControlName="frmdisplayorder"
                        (keypress)="AllowOnlyNumber($event)" id="exampleInputPassword1" placeholder="Display Order"
                        type="text" />
                    <mat-hint align="start"><strong>
                            <img class="mr-1 mb-1" alt="help" src="../../../assets/icons/help.svg" width="15px" height="auto">
                            <span class="ml-1 txt-color">Enter upto two digits</span>
                        </strong> </mat-hint>
                    <mat-hint align="end">{{input.value.length}} / 2</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <h3 class="border-line side-title">Output</h3>
        <!-- rich text editor -->
        <h5 class="title font-weight sub-title">Disclaimer Text<span class="error-msg">*</span><span class="error-msg" *ngIf="disclaimerError()">  Please enter the Description text!</span></h5> 
        <div class="content">
            <div class="editor">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor required class="rtf-editor" (input)="formSubmit = false" [editor]="editor" [(ngModel)]="html" formControlName="frmDescription"
                    [disabled]="false" [placeholder]="'Type here...'">
                </ngx-editor>
            </div>
        </div>
        <!-- Placeholder -->
        <h5 class="title font-weight sub-title mt-2">Placeholders</h5>
        <div class="row px-3 mt-3 description-area">
            <div class="row col-sm-12 text-xs pl-0">
                <span class="col-sm-4">
                    <mat-form-field appearance="outline" class="example-full-width pl-0 d-flex">
                        <mat-label> <strong> Criteria</strong></mat-label>
                        <!-- <input [formControl]="criteria" type="text" maxlength="200" class="input-align" matInput
                                aria-label="Criteria" [matAutocomplete]="auto"> -->
                        <mat-select [formControl]="criteria" disableOptionCentering (selectionChange)="placeHoldersList($event)">
                            <mat-option [value]="item" class="option-align" *ngFor="let item of placeholderCriteria.sort()">
                                {{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                <span class="col-sm-4" *ngIf="showFlag">
                    <mat-form-field appearance="outline" class="example-full-width pl-0 d-flex">
                        <mat-label> <strong> Value</strong></mat-label>
                        <input matInput aria-label="Value" [matAutocomplete]="auto1" [formControl]="criteriaValues"
                            class="input-align" [(ngModel)]="value" />
                        <!-- <button matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                (click)="fnToggleFilter()"> -->
                        <!-- <mat-icon>close</mat-icon> -->
                        <button matSuffix mat-icon-button style="height: 36px;">
                            <em class="fa fa-chevron-down chevron-align"></em>
                        </button>
                                                                
                        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search" (click)="fnToggleFilter()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="addingDescription($event)">
                            <mat-option class="mat-opt-align" [value]="data.Value" *ngFor="let data of filteredValue | async">
                                <span>{{ data.Value}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </span>
            </div>
        </div>
        <div class="row px-3 description-area">
            <div class="col-sm-12 text-xs pl-0">
                <p>Select a placeholder to add to the Disclaimer Text</p>
            </div>
        </div>
    </form>
    <div class="row sub-button mt-4">
        <div class="col-sm-12 d-flex p-0 form-inline well well-lg">
            <div class="col-sm-6 float-left">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="backtoGrid()">Back</button>
            </div>
            <div class="col-sm-6 button-wrap">
                <div *ngIf="headerValue !='Edit Disclaimer'">
                    <button mat-raised-button class="admin-btn-success mr-4" [disabled]="disableSave" (click)="validateDisclaimer('',disclaimerForm.controls)">Save
                        & Create</button>
                </div>
                <button mat-raised-button class="admin-btn-success" [disabled]="disableSave" (click)="validateDisclaimer('saveandclose',disclaimerForm.controls)">Save
                    & Close</button>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="deleteForm">
    <div class="row">
        <div class="col-12">
            <!-- The Modal -->
            <div class="modal setDisclaimerModal" id="setDisclaimerModal" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog large-model">
                    <div class="modal-content disclimer-model">
                        <!-- Modal Header -->
                        <div class="admin-model-header">
                            <h4 class="modal-title p-1">Deleting Disclaimer</h4>
                        </div>
                        <!-- Modal body -->
                        <div class="modal-body">
                            <span class="title-card">
                                The disclaimer will be permanently deleted from the library. Do you want to continue?
                            </span><br>
                            <label for="message-text" class="mt-2"><span class="error-msg">*</span>Reason:</label>
                            <textarea class="form-control input-size" id="message-text" formControlName="frmdeletereason"></textarea>
                        </div>
                        <!-- Modal footer -->
                        <div class="modal-footer cron-btn-wrap mt-4">
                            <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="confirmDeleteDisc()"
                                [disabled]="
                                   deleteForm.value.frmdeletereason =='' ">Yes</button>
                            <button mat-raised-button class="admin-btn-success" type="button" (click)="closeModal()">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="row">
    <div class="col-12">
        <!-- The Modal -->
        <div class="modal setDisclaimerDupModal" id="setDisclaimerDupModal" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog large-model">
                <div class="modal-content disclimer-model">
                    <!-- Modal Header -->
                    <div class="admin-model-header">
                        <h4 class="modal-title p-1">Confirmation</h4>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <span class="title-card" style="margin-left: 31%;">
                            <p>Disclaimers with the same input criteria already exists. Do you still want to continue
                                creating a duplicate one?</p>
                        </span>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer cron-btn-wrap mt-4">
                        <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="SaveDupDisclaimer(finalFlag,finalSave)">Yes</button>
                        <button mat-raised-button class="admin-btn-success" type="button" (click)="closeDupModal()">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>