import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-filtered-selector',
  templateUrl: './filtered-selector.component.html',
  styleUrls: ['./filtered-selector.component.scss']
})
export class FilteredSelectorComponent implements OnInit,OnChanges {
  @Input() selectedObjects
  @Input() inputSearchPlaceholder: string = 'Type here to search'
  @Input() noRecordsFoundMessage: string = 'No Records Found To Display.'
  @Input() searchByProperty: string
  @Input() displayByProperty: string
  @Input() headingText: string = 'Heading Text'
  @Input() dropDownValues = []
  @Input() showCancel : boolean = false
  @Input() isReadingLocationEnabled : boolean = false
  @Input() isCaseSensitive : boolean = false
  @Output() onCancelClick = new EventEmitter()
  @Output() onEachSelect = new EventEmitter()

  FilteredSelectorSelectionModel = new SelectionModel<number>(true)
  selectionState: 'all' | 'inter' | 'none' = 'none'
  isExpanded: boolean = false
  dropDownValue$: Observable<any>;
  control = new FormControl('');
  uniqueId: string;
  currentSelectedValue = []
  tooTipText=''
  readingLocationObject : any

  constructor() { }
  

  ngOnInit(): void {
    
    this.uniqueId = 'id' + Date.now().toString(36) + Math.random().toString(36).substr(2);
    this.dropDownValues = this.dropDownValues.map((data, i) => {
      let obj = { ...data, filterSelectIdentifier: i }
      if(this.isReadingLocationEnabled){
        if(!obj.hasOwnProperty('isReadingLocationEnabled'))
          obj['isReadingLocationEnabled'] = false
      }
      return obj
    })
    // if(this.selectedObjects.length > 0){
    //   for (let index = 0; index < this.selectedObjects.length; index++) {
    //     const element = this.selectedObjects[index];
    //     const value = this.dropDownValues.findIndex((j) => j[this.displayByProperty].trim().toLowerCase() === element[this.displayByProperty].trim().toLowerCase())

    //     if(value != -1) {
    //       this.FilteredSelectorSelectionModel.select(value)
    //     }
    //   }
    // }
    this.initialSelection()
    this.dropDownValue$ = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isReadingLocationEnabled && !changes.isReadingLocationEnabled.isFirstChange()){
      if(this.isReadingLocationEnabled){
        this.dropDownValues = this.dropDownValues.map((data, i) => {
            // data['isReadingLocationEnabled'] = false
            // return data
            //if(!data.hasOwnProperty('filterSelectIdentifier'))
            let obj = { ...data, filterSelectIdentifier: i }
            if(this.isReadingLocationEnabled){
              if(!obj.hasOwnProperty('isReadingLocationEnabled'))
                obj['isReadingLocationEnabled'] = false
            }
            return obj
        })

        this.initialSelection()
        const value = this.control.value
        this.control.setValue('a')
        this.control.setValue(value)
      }
      else {
        this.dropDownValues = this.dropDownValues.map((data, i) => {
          if(data.hasOwnProperty('isReadingLocationEnabled')) delete data.isReadingLocationEnabled
          return data
        })
      }
      this.addSelectedObjects()
    }
    
    if(changes.selectedObjects){      
      this.initialSelection()
    }
  }

  selectValue(value) {
    this.FilteredSelectorSelectionModel.toggle(value.filterSelectIdentifier)
    this.handleReadingLocation()
    this.addSelectedObjects()
    this.onEachSelect.emit()
    this.markSelectionStatus(this.currentSelectedValue)
  }

  private _filter(value: string) {
    this.currentSelectedValue = this.dropDownValues
    if (value.length && this.dropDownValues.length) {
      const filterValue = this._normalizeValue(value);
      this.currentSelectedValue = this.dropDownValues.filter((dropdown) =>
        this._normalizeValue(dropdown[this.searchByProperty]).includes(filterValue)
      );
      this.markSelectionStatus(this.currentSelectedValue)
      return this.currentSelectedValue
    }
    this.markSelectionStatus(this.dropDownValues)
    return this.dropDownValues
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  selectAll(value){
      const tempvalue = this.currentSelectedValue.map((i) => i.filterSelectIdentifier)
      this.selectionState = value ? 'all' : 'none'
      value ? this.FilteredSelectorSelectionModel.setSelection(...this.FilteredSelectorSelectionModel.selected, ...this.currentSelectedValue.map((j) => j.filterSelectIdentifier)) : this.FilteredSelectorSelectionModel.setSelection(...this.FilteredSelectorSelectionModel.selected.filter((i) => !tempvalue.includes(i)))
      this.addSelectedObjects()
      this.onEachSelect.emit()
      this.handleReadingLocationForSelectAll(value)
  }

  private markSelectionStatus(data){
    const tempSelectionModel = data.filter((i) => this.FilteredSelectorSelectionModel.selected.includes(i.filterSelectIdentifier)).length
    if(tempSelectionModel === 0) this.selectionState = 'none'
    else if(data.length != tempSelectionModel && tempSelectionModel != 0) this.selectionState = 'inter'
    else this.selectionState = 'all'
  }

  private addSelectedObjects(){
    this.selectedObjects.splice(0, this.selectedObjects.length)
    const localCopy = [...this.dropDownValues.map((i) => ({...i}))]
    const value = localCopy.filter((data) => this.FilteredSelectorSelectionModel.selected.includes(data.filterSelectIdentifier)).map((k) => {
      delete k.filterSelectIdentifier
      if(!this.isReadingLocationEnabled) delete k.isReadingLocationEnabled
      return k
    })
    this.selectedObjects.push(... value)
  }

  private handleReadingLocation() {
    if (this.isReadingLocationEnabled) {
      for (let index = 0; index < this.dropDownValues.length; index++) {
        if (this.FilteredSelectorSelectionModel.selected.length === 1) {
          if (this.dropDownValues[index].filterSelectIdentifier === this.FilteredSelectorSelectionModel.selected[0])
            this.dropDownValues[index].isReadingLocationEnabled = true
          else
            this.dropDownValues[index].isReadingLocationEnabled = false
        }
        else{
          if(!this.FilteredSelectorSelectionModel.selected.includes(this.dropDownValues[index].filterSelectIdentifier)){
            this.dropDownValues[index].isReadingLocationEnabled = false
          }
        }
      }
    }
  }

  markAsReadingLocation(value){
    //const index = this.dropDownValues.findIndex((j) => j.filterSelectIdentifier === value.filterSelectIdentifier)
    for (let index = 0; index < this.dropDownValues.length; index++) {
      if(this.dropDownValues[index].filterSelectIdentifier === value.filterSelectIdentifier){
        this.FilteredSelectorSelectionModel.select(index)
        this.dropDownValues[index].isReadingLocationEnabled = true
      }
      else this.dropDownValues[index].isReadingLocationEnabled = false
    }
    this.addSelectedObjects()
    this.markSelectionStatus(this.currentSelectedValue)
  }

  handleReadingLocationForSelectAll(isSelectedAll){
    if (this.isReadingLocationEnabled) {
    if(isSelectedAll){
      const index = this.dropDownValues.findIndex((j) => j.isReadingLocationEnabled)
      if(index === -1) this.dropDownValues[this.FilteredSelectorSelectionModel.selected[0]].isReadingLocationEnabled = true      
    }
    else{
      const tempvalue = this.currentSelectedValue.map((i) => i.filterSelectIdentifier)
      for (let index = 0; index < this.dropDownValues.length; index++) {
        if(tempvalue.includes(this.dropDownValues[index].filterSelectIdentifier)){
          this.dropDownValues[index].isReadingLocationEnabled = false
        }
      }

      if(this.FilteredSelectorSelectionModel.selected.length === 1) this.dropDownValues[this.FilteredSelectorSelectionModel.selected[0]].isReadingLocationEnabled = true
    }
    }
  }

  private initialSelection(){
    if(this.selectedObjects.length > 0){
      for (let index = 0; index < this.selectedObjects.length; index++) {
        const element = this.selectedObjects[index];
        const value = this.isCaseSensitive ? this.dropDownValues.findIndex((j) => j[this.displayByProperty].trim() === element[this.displayByProperty].trim()) : this.dropDownValues.findIndex((j) => j[this.displayByProperty].trim().toLowerCase() === element[this.displayByProperty].trim().toLowerCase())
        if(value != -1) {
          this.FilteredSelectorSelectionModel.select(value)
          if(this.isReadingLocationEnabled && element['isReadingLocationEnabled']){
             this.dropDownValues[value]['isReadingLocationEnabled'] = true
          }
        }
      }
    }
    else{
      this.FilteredSelectorSelectionModel.clear()
    }

    this.markSelectionStatus(this.dropDownValues)
  }
  
  toolTipshow(element){
    this.tooTipText=element.scrollWidth > element.clientWidth?element.textContent:'';
  }

  onExpandClick(event){
    if(event.detail === 1)  this.isExpanded = !this.isExpanded
  }
}
