<div mat-dialog-title class="p-2">
    <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning" class="material-icons md-48">
    <h1 class="header">{{data.header}}</h1>
</div>
<span class="migrate_wrap">
    <div mat-dialog-content>
        <span [innerText]="data.message" class="message"></span>
        <p>{{data.alert}}</p>
        <span class="sub-line" *ngIf="ifExists(data.message)">You cannot revert this action.</span>
    </div>
</span>

<div mat-dialog-actions class="confirm_dia" [ngStyle]="{'justify-content': data.cancel === 'dontshow' ? 'center':''}">

    <button *ngIf="data.cancel !== 'dontshow'" [mat-dialog-close]="data.animal" cdkFocusInitial
        class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs mr-4 cancelButton">
        {{data.cancel}}
    </button>

    <button type="submit" class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs ml-3 confirmButton"
        (click)="onNoClick()">
        {{data.continue}}
    </button>