import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-box',
  template: `
   <div class="flex searchBox" [class.cusror-not-allowed]="isDisabled">
    <div class="mr-2 d-flex" [ngClass]="className">
      <img src="../assets/icons/SearchIcon.png" alt="Close" class="material-icons md-24 icon " >
    </div>
    <input type="text" class="align-items-center" [class.cusror-not-allowed]="isDisabled" [disabled]="isDisabled" [placeholder]="placeHolder" #searchInput (keyup)="keyPressed(searchInput.value)" [value]="searchText">
    </div>
  `,
  styles: [`
  .searchBox{
    display: flex;
    align-items: center;
    border: 1.6px solid #8b87879e;
    border-radius: 3px;
    padding: 2.5px 0px !important;
    font-size: 12px;

    input{
      border:none;
      width:100%;
      padding-left: 2px !important;
    }

    div{
      border-right: 1.6px solid #FB8500;
    }

    img{
      margin: 0px 7px; 
      width: 16px; 
      height: 16px;
    }
  }
  .search-padding{
    padding:5px !important
  }
  
  .cusror-not-allowed{
    cursor : not-allowed;
  }
  `]
})
export class SearchBoxComponent {
  @Input() placeHolder : string = 'Type To Search Here'
  @Input() className : string = ''
  @Input() isDisabled : boolean = false
  @Output() enteredValue=new EventEmitter<string>()
  @Input() searchText : string = ''

  keyPressed(text:string)
  {
    this.enteredValue.emit(text);
  }
}
