export class VDInstallation {
    OrganizationGUID: string;
    DeploymentKey: string;
    VDName: string;
    CustomerName: string;
    VDVersion: string;
    VitalAxisURL: string;
    VitalAxisDataURL: string;
    LoginPage: string;
    LatestVersionAvailable: string;
    IsActive: any;
    IsFacility: boolean;
    PointOfContact: string;
    ContactNumber: string;
    RequestedBy: string;
    OnTimeTicket: string;
    OrganizationID: string;
    SpecificConfig: string;
    CustomerType: string;
    ComputerLocation: string;
    ComputerName: string;
    SystemOS: string;
    SystemIP: string;
    AdminLoginName: string;
    LabelsUsedOnThisComputer: string;
    PrinterType: string;
    SenderAgentId: string;
    ApiKey: string;
    EnvironmentKey: string;
    Comments: string;
    VitalDesktopID: string;
    TimeConsumedForInstallation: any;
    InstallationStatus: any;

    constructor(data: any, DeploymentKey: string, orgId: string, Guid: string, isEditable: boolean) {
        this.OrganizationGUID = Guid;
        this.DeploymentKey = DeploymentKey;
        this.VDName = data.formInput_VDName;
        this.VDVersion = data.formInput_VDVersion;
        this.VitalAxisURL = data.formInput_VitalAxisURL;
        this.VitalAxisDataURL = data.formInput_VitalAxisDataURL;
        this.LoginPage = data.formInput_LoginPage;

        this.IsFacility = data.formInput_IsFacility ? true : false;
        this.PointOfContact = data.formInput_PointOfContact;
        this.ContactNumber = data.formInput_ContactNumber;
        this.RequestedBy = data.formInput_RequestedBy;
        this.OnTimeTicket = data.formInput_OnTimeTicket;
        this.OrganizationID = !data.formInput_orgid ? orgId : data.formInput_orgid;


        this.ComputerLocation = data.formInput_ComputerLocation;
        this.AdminLoginName = data.formInput_AdminLoginName;
        this.PrinterType = data.formInput_PrinterType;
        this.VitalDesktopID = data.VD_Identifier;
        this.InstallationStatus = data.formInput_InstallationStatus;
        if (isEditable) { // for update
            this.IsActive = data.IsActive ? true : false;
            this.VitalAxisURL = data.VitalAxis_URL;
            this.SenderAgentId = data.formInput_SenderAgentID;
            this.EnvironmentKey = data.formInput_EnvKey
            this.ApiKey = data.formInput_APIKey;
            this.ComputerName = data.ComputerName;
            this.SystemOS = data.SystemOS
            this.SystemIP = data.SystemIP
            this.LabelsUsedOnThisComputer = data.formInput_LabelsUsed;
            this.Comments = data.Comments
            this.CustomerName = data.CustomerName;
            this.TimeConsumedForInstallation=data.formInput_InstallationTime
            this.SpecificConfig = data.SpecificConfig;
            this.CustomerType = data.Customer_Type;
            this.LatestVersionAvailable = data.formInput_LatestVersion;
        }
        else { // for create
            this.IsActive = data.formInput_IsActive ? true : false;
            this.VitalAxisURL = data.formInput_VitalAxisURL;
            this.SenderAgentId = data.formInput_senderagentid;
            this.ApiKey = data.formInput_apiKey;
            this.EnvironmentKey = data.formInput_environmentKey;
            this.ComputerName = data.formInput_ComputerName;
            this.SystemOS = data.formInput_SystemOS;
            this.SystemIP = data.formInput_SystemIP;
            this.LabelsUsedOnThisComputer = data.formInput_LabelsUsedOnThisComputer;
            this.CustomerName = data.formInput_CustomerName;
            this.Comments = data.formInput_Comments;
            this.SpecificConfig = data.formInput_SpecificConfig;
            this.CustomerType = data.formInput_CustomerType;
            this.LatestVersionAvailable = data.formInput_LatestVersionAvailable;
        }
    }
}

export class Desktoptemplate {
    action: string;
    templateid: string;
    organizationid: number;
    context: string;
    mode: string;
    displayname: string;
    template: any; // You might want to replace `any` with a more specific type if `template` has a defined structure
    preview: any; // Same here, replace `any` with the appropriate type
    templatetype: string;
    productname: string;
    accountID: string;
    contexttype: string;
    modifiedby: number;

    constructor(
        data,
        actionParam: any,
        template: any,
        templateid: any,
        accountID: string,
        orgid: any
    ) {
        this.action = actionParam;
        this.templateid = templateid || null;
        this.organizationid = orgid;
        this.context = data.Context;
        this.mode = data.Mode;
        this.displayname = data.DisplayName;
        this.template = template;
        this.preview = data.Preview;
        this.templatetype = data.TemplateType;
        this.productname = data.ProductName;
        this.accountID = accountID;
        this.contexttype = data.ContextType;
        this.modifiedby = data.modifiedby;
    }
}

