import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import '@grapecity/wijmo.input';
//import '@grapecity/wijmo.styles/wijmo.css';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Tab } from 'src/app/base/Tab/tab.directive';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { InjectorService } from '../../../core/services/Injectorservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';


@Component({
  selector: 'app-vdtemplateupload',
  templateUrl: './vdtemplateupload.component.html',
  styleUrls: ['./vdtemplateupload.component.scss'],
  providers: [DatePipe]
})
export class VDTemplateUpload extends BaseGridContainerComponent implements OnInit, OnChanges {
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: any;
  custfilter = {
    TemplateID: "",
    Template: "",
    TemplateType: "",
    Preview: "",
    Context: "",
    ContextType: "",
    Mode: "",
    AccountID: "",
    ProductName: "",
    // labaccount: "",
    // TestDetails: ""
  };

  public myDate = new Date();
  gridheader = ['ProductName', 'Preview', 'TemplateType', 'DisplayName', 'ContextType', 'Mode', 'Context', 'Template', 'AccountID', 'TemplateID']
  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;
  public subMenuCardModel: any = "";
  public templatesetData: any = "";
  public templatedata: any = "";
  public isDisabled = false;
  public tabs: Array<Tab> = [];
  public iftemplateUpdate = false;
  public showpop = false;
  public addRad = false;
  public updatRad = false;
  public file;
  public searchtext;
  public arrayBuffer;
  public ifFileUpload = false;
  public ifUpload = true;
  public gridDataArr: any = [];
  public gridExcelArr: any = [];
  public datefor;
  public ifaddGrid = false;
  public currentEditItem;
  public hideUploadBtn: boolean = true;
  public hideDisplayfile: boolean = true;
  public hideEditBtn: boolean = true;
  // public actionButtonDetails:any[];
  filOrText: string;
  form: FormGroup;
  uploadform: FormGroup;
  public source;
  Invalidfilealert = false;
  fileToUpload: File | null = null;
  constructor(private injectorServiceGridContainer: InjectorService, public httpclinet: HttpClientModule,
    private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar,
    private VitalHttpServices: VitalHttpServices, private tabService: VitalAdminTabService,
    public vitalHttpServices: VitalHttpServices, private commonService: CommonService, public datepipe: DatePipe, public formBuilder: FormBuilder, private datashare: DataShareService) {
    super(injectorServiceGridContainer);
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
    this.datefor = this.datepipe.transform(this.myDate, 'yyyy-MM-dd');


  }
  // uploadform = this.formBuilder.group({
  //     fileName: ['']
  //   });

  public theGrid;
  ngOnInit() {
    this.uploadform = this.formBuilder.group({
      fileName: ['']
    });
    this.getGridData();
    this.GetButtondetails();
  }
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    // this.datashare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Display File":
          this.hideDisplayfile = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  // make rows taller to accommodate edit buttons and input controls
  readFile(fileEvent: any) {
    const file = fileEvent.target.files[0];
    let str = file.name;
    if (str.includes('.xlsx')) {
      //call service

    } else {
      this._snackbar.open("Only .xlsx file can be uploaded", "Failure");
      return false;
    }
  }

  cleartext() {
  }

  public searchData;
  getGridData() {
    let queryVariable = { "orgId": sessionStorage.getItem("org_id").toString() };
    let query = this.vitalHttpServices.GetQuery("vdtemplate");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      if (data.data.submenuData.length > 0) {
        this.gridDataArr = data.data.submenuData;
        this.searchData = data.data.submenuData;
      }
      this.AddData();
      this.source = this.getData(this.totalrec.length);
    });
  }

  searchFilter() {
    this.custfilter.AccountID = this.filOrText;
    this.custfilter.Context = this.filOrText;
  }

  ShowPopup(type) {
    this.showpop = true;
    if (type == 'add') {
      this.iftemplateUpdate = false;
      this.ifAddUpdate = true;

    } else {
      this.iftemplateUpdate = true;
      this.ifAddUpdate = false;
      this.getGridData();
      setTimeout(() => {
        this.uploadform = this.formBuilder.group({
          fileName: ['']
        });
      }, 300);

    }
  }


  initializeGrid(flex: wjcGrid.FlexGrid) {
    flex.rows.defaultSize = 40;
    // custom formatter to paint buttons and editors
    flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
      if (e.panel == s.cells) {
        let col = s.columns[e.col],
          item = s.rows[e.row].dataItem;
        if (item == this.currentEditItem) {
          // create editors and buttons for the item being edited
          switch (col.binding) {
            case 'buttons':
              e.cell.innerHTML = document.getElementById('tplBtnEditMode').innerHTML;
              e.cell['dataItem'] = item;
              break;
            case 'Context':
            case 'Mode':
            case 'DisplayName':
            case 'TemplateType':
            case 'Template':
            case 'ProductName':
            case 'ContextType':
              e.cell.innerHTML = '<input class="form-control" ' +
                'id="' + col.binding + '" ' +
                'value="' + s.getCellData(e.row, e.col, true) + '"/>';
              break;
          }
        } else {
          // create buttons for items not being edited
          switch (col.binding) {
            case 'buttons':
              e.cell.innerHTML = document.getElementById('tplBtnViewMode').innerHTML;
              e.cell['dataItem'] = item;
              break;
          }
        }
      }
    });

    // handle button clicks
    flex.addEventListener(flex.hostElement, 'click', (e: MouseEvent) => {
      this.flex = flex;
      let targetBtn: HTMLButtonElement;
      if (e.target instanceof HTMLButtonElement) {
        targetBtn = e.target;
      } else if (e.target instanceof HTMLSpanElement && e.target.classList.contains('glyphicon')) {
        targetBtn = e.target.parentElement as HTMLButtonElement;
      }
      if (targetBtn) {
        // get button's data item
        let item = wjcCore.closest(targetBtn, '.wj-cell')['dataItem'];
        // handle buttons
        switch (targetBtn.id) {
          // start editing this item
          case 'btnEdit':
            this._editItem(item, flex);
            break;
          // remove this item from the collection
          case 'btnDelete':
            this._deleteItem(flex, item);
            break;
          // commit edits
          case 'btnOK':
            this._commitEdit(item);
            break;
          // cancel edits
          case 'btnCancel':
            this._cancelEdit();
            break;
        }
      }
    });

    // exit edit mode when scrolling the grid or losing focus
    flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
    flex.lostFocus.addHandler(this._cancelEdit.bind(this));
  }



  private _editItem(item, flex) {
    this._cancelEdit();
    this.currentEditItem = item;
    flex.invalidate();
  }

  private _commitEdit(item) {
    let tempItem = [];
    this.flex.columns.forEach((col: wjcGrid.Column) => {
      let input = <HTMLInputElement>this.flex.hostElement.querySelector('#' + col.binding);
      if (input) {
        let value = wjcCore.changeType(input.value, col.dataType, col.format);
        if (wjcCore.getType(value) == col.dataType) {
          this.currentEditItem[col.binding] = value;
        }
      }
    })
    if (this.currentEditItem) {
      var obj = {
        "context": this.currentEditItem.Context,
        "mode": this.currentEditItem.Mode,
        "displayname": this.currentEditItem.DisplayName,
        "template": this.currentEditItem.Template,
        "preview": this.currentEditItem.Preview,
        "templatetype": this.currentEditItem.TemplateType,
        "ProductName": this.currentEditItem.ProductName,
        "templateid": this.currentEditItem.TemplateID,
        "AccountID": this.currentEditItem.AccountID,
        "contexttype": this.currentEditItem.ContextType

      }
      this.VitalHttpServices.UpdateTemplate(obj).subscribe(data => {
        if (data.Success) {
          this._snackbar.open("Updated Successfully", "Success");
          this.flex.invalidate();
        } else {
          this._snackbar.open("Failure", "Failed");
          return false;
        }


      });
    }
    this.currentEditItem = null;
    // this.flex.invalidate();
  }

  private _cancelEdit() {
    if (this.currentEditItem) {
      this.currentEditItem = null;
      this.flex.invalidate();
    }
  }

  private _deleteItem(flex, item) {
    var tempid = item.TemplateID;
    this.VitalHttpServices.DeleteTemplate(tempid).subscribe(data => {
      if (data.Success) {
        (<wjcCore.CollectionView>flex.collectionView).remove(item);
        this._snackbar.open("Deleted successfully!", "Success");
        return true;

      } else {
        this._snackbar.open("Something went wrong!!", "Failure");
        return false;
      }
    });

  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  public fileList;
  onFileSelect(event) {
    this.ifUpload = false;
    if (event.target.files.length > 0) {
      let fileList: FileList = event.target.files;
      this.fileList = event.target.files[0];
      //   this.uploadform.get('profile').setValue(fileList);
      this.ifUpload = false;
    }
    //     this.ifUpload= false;
    //     const file = (event.target as HTMLInputElement).files[0];
    // this.form.patchValue({
    //   avatar: file
    // });
    // this.form.get('avatar').updateValueAndValidity()
  }

  onSubmit() {
    const frmData = new FormData();
    frmData.append("file", this.fileList);
    //     const formData = new FormData();
    //     formData.append('file', this.uploadForm.get('profile').value);
    this.VitalHttpServices.UploadFile(frmData).subscribe(data => {
      if (data.Success) {
        this._snackbar.open("Successfully Uploaded", "Success");
        return true;
      } else {
        this._snackbar.open("Failed", "Failure");
        return true;
      }

    });


  }

  getData(count: number) {
    return new wjcCore.CollectionView(this.totalrec, {
      pageSize: 10
    });
  }
  public totalrec;

  AddData() {
    let gridarray = []
    let primary = {}
    if (this.gridDataArr) {
      if (this.gridDataArr.length > 0) {
        for (let i = 0; i < this.gridDataArr.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.gridDataArr[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key.toLowerCase() === this.gridheader[j].toLowerCase()) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
    }
    this.totalrec = gridarray;
    // this.searchData = gridarray;
    this.gridData = new CollectionView(gridarray, { pageSize: 10 })
    this.gridwidth = (170 * this.gridheader.length) + 37;
    if (this.gridwidth > 1300)
      this.gridwidth = 1300;
    this.iftemplateUpdate = true;
  }
  public gridexceldata;
  public gridexcelwidth;
  public gridexcelHeader;
  public ifAddUpdate = false;

  AddDataGrid() {
    let gridarrays = []
    let primarys = {}
    if (this.gridExcelArr) {
      if (this.gridExcelArr.length > 0) {
        for (let i = 0; i < this.gridExcelArr.length; i++) {
          primarys = {}
          for (let [key, value] of Object.entries(this.gridExcelArr[i])) {
            for (let j = 0; j < this.gridexcelHeader.length; j++) {
              if (key.toLowerCase() === this.gridexcelHeader[j].toLowerCase()) {
                primarys[key] = value
              }
            }
          }
          gridarrays.push(primarys)
        }
      }
    }
    this.gridexceldata = new CollectionView(gridarrays, { pageSize: 10 })
    this.gridexcelwidth = (170 * this.gridexcelHeader.length) + 37;
    if (this.gridexcelwidth > 1300)
      this.gridexcelwidth = 1300;
  }

  addDataToGrid() {
    this.gridExcelArr = this.arraylist;
    if (this.arraylist[0].ProductName != undefined && this.arraylist[0].ProductName != null
      && this.arraylist[0].preview != undefined && this.arraylist[0].accountid != undefined
      && this.arraylist[0].templatetype != undefined && this.arraylist[0].templatetype != null
      && this.arraylist[0].template != undefined && this.arraylist[0].template != null
      && this.arraylist[0].displayname != undefined && this.arraylist[0].displayname != null
      && this.arraylist[0].contexttype != undefined && this.arraylist[0].contexttype != null
      && this.arraylist[0].mode != undefined && this.arraylist[0].mode != null
      && this.arraylist[0].context != undefined && this.arraylist[0].context != null && this.OrgAccInterger()
      && this.arraylist[0].organizationid != undefined && this.arraylist[0].organizationid != null) {
      this.ifaddGrid = true;
      this.gridexcelHeader = ['ProductName', 'preview', 'templatetype', 'template', 'displayname', 'contexttype', 'mode', 'context', 'accountid', 'organizationid'];
      this.ifAddUpdate = true;
      this.AddDataGrid();
      this.ifUpload = true;
      this.ifFileUpload = false;
      this.Invalidfilealert = false;
    }
    else {
      this.ifaddGrid = false;
      this.Invalidfilealert = true;
    }
  }
  OrgAccInterger() {
    let isOnlyInt = false;
    for (var i = 0; i < this.arraylist.length; i++) {
      if (this.arraylist[i].organizationid && /^[0-9]+$/.test(this.arraylist[i].organizationid.trim())) {
        isOnlyInt = true;
        isOnlyInt = (this.arraylist[0].accountid && /^[0-9]+$/.test(this.arraylist[0].accountid.trim())) ? true : false;
      } else {
        isOnlyInt = false;
      }
      if (!isOnlyInt)
        break;
    }
    return isOnlyInt;
  }
  closeModal() {
    this.showpop = false;
    this.ifUpload = true;
    this.ifAddUpdate = false;
    this.ifaddGrid = false;
  }
  public arraylist;
  public uniqueGUID;

  generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    this.uniqueGUID = uuid;
  }

  public concatStr = "";
  saveData() {
    for (var i = 0; i < this.arraylist.length; i++) {
      var str = JSON.stringify(this.arraylist[i]);

      str = str.replace(/[':]+/g, "=");
      str = str.replace(/['{]+/g, "");
      str = str.replace(/['}]+/g, "");
      this.concatStr = this.concatStr + "," + str;
      this.concatStr = this.concatStr.replace(/['""]+/g, "");
    }

    let query = this.vitalHttpServices.GetDxUseridBasedOnDeployment(/*sessionStorage.getItem('deploymentKey')*/);
    this.ngxService.start();
    this.vitalHttpServices.GetData(query).subscribe(data => {
      let result = data;
      let DxUserid = result.data.submenuData ? result.data.submenuData : null;
      this.ngxService.stop();
      if (DxUserid && DxUserid.length > 0) {
        var arrcon = {
          "userID": DxUserid[0].userid,// this.commonService.GetDxUseridBasedOnDeployment(sessionStorage.getItem( 'deploymentKey')),
          "entityType": "Templates",
          "entityID": "",
          "actionPerformed": "Added New",
          "actionDetails": this.concatStr,
          "organizationID": sessionStorage.getItem("org_id"),
          "accountID": "",
          "auditDate": this.datefor,
          "entityData": ""
        };
        this.arraylist = this.arraylist.concat(arrcon);
        var resJson = {
          "jsonstring": this.arraylist
        }
        //  return;
        this.vitalHttpServices.AddTemplates(resJson).subscribe(res => {
          if (res.Success) {
            this.ifFileUpload = true;
            this._snackbar.open("Successfully Uploaded", "Success");
          } else {
            this._snackbar.open("File Uploading Failed", "Failure");
          }
        }, error => {
          console.info(error);
        });
      } else {
        this._snackbar.open("User is not authorized for this operation", 'Failed')
      }//end of check if user id is present or not
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed')
    });

  }
  uploadFile(event) {

    this.file = event.target.files[0];
    let substr = ".xlsx";
    if (!this.file.name.includes(substr)) {
      this.ifUpload = true;
      this._snackbar.open("Please upload excel files", "Info");
      return false;
    } else {
      this.ifUpload = false;
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.arraylist = XLSX.utils.sheet_to_json(worksheet, { defval: "", raw: false });
      }
      //    if(first_sheet_name.length>0)
      //    {
      //        if(first_sheet_name.toLowerCase()!='templates'){
      //            this._snackbar.open("Please upload excel sheet named Templates", "Info");
      //            return false;
      //        }
      //    }
    }
  }


}
