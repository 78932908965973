import {  ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CustomSelectComponent implements OnInit, OnChanges{
  @Input() labelValue : string = ''
  @Input() required : boolean = false
  @Input() formcontrolname : string | null = null
  @Input() isFormValid:boolean|null=true
  @Input() isDisabled:boolean=false
  @Input() dropDownValues : any = []
  @Input() dropDownKeyProp : string = ''
  @Input() dropDownValueProp : string = ''
  @Input() defaultOption : string = 'Select Option'
  @ViewChild('customSelect') select
  @ViewChild('cstmpopup') cstmpopupEle;
  value : any
  showDropDown : boolean = false
  selectedItem : string = ''
  contentWidth: number;

  constructor(
    private fcd:  FormGroupDirective
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dropDownValues && this.fcd.control.get(this.formcontrolname).value){
      // const value = this.dropDownValues.find((j) => j[this.dropDownValueProp] === this.fcd.control.get(this.formcontrolname).value)
      // this.selectedItem = value[this.dropDownKeyProp]
      this.selectionHelper()
    }
  }

  ngOnInit(): void {
    if(!this.fcd.control.get(this.formcontrolname).value){
      this.selectedItem = this.defaultOption
    }
    else {
      // const value = this.dropDownValues.find((j) => j[this.dropDownValueProp] === this.fcd.control.get(this.formcontrolname).value)
      // this.selectedItem = value[this.dropDownKeyProp]
      this.selectionHelper()
    }

    this.fcd.control.get(this.formcontrolname).valueChanges.subscribe((value) => {
      if(!value) this.selectedItem = this.defaultOption
      else this.selectionHelper()
    })
  }
  
  addValue(drp){
    this.showDropDown = false
    this.selectedItem = drp[this.dropDownKeyProp]
    this.fcd.control.get(this.formcontrolname).setValue(drp[this.dropDownValueProp])
    this.select.nativeElement.focus();
  }

  showHideDropDown(){
    if(!this.fcd.control.get(this.formcontrolname).disabled)
      {
        this.showDropDown = !this.showDropDown
        this.contentWidth = this.select.nativeElement.clientWidth
        this.arrowkeyLocation=this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)==-1?0:this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)
        this.cstmpopupEle? this.cstmpopupEle.nativeElement.childNodes[this.arrowkeyLocation+1].focus():''
      }
  }

  setToDefault(){
    this.showDropDown = false
    this.selectedItem = this.defaultOption
    this.fcd.control.get(this.formcontrolname).setValue('')
  }

  get form(){
    return this.fcd.control.get(this.formcontrolname)
  }
  arrowkeyLocation=0
  isFirstTimeOpen=false
  keydown(event){
    if(event.key=="Enter")
      {
          this.showDropDown = !this.showDropDown
          this.contentWidth = this.select.nativeElement.clientWidth
          this.isFirstTimeOpen=true
          this.arrowkeyLocation=0
      }
    
    
  }
  focusOnElement()
  {
    this.contentWidth = this.select.nativeElement.clientWidth
    this.arrowkeyLocation=this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)==-1?0:this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)
     this.cstmpopupEle? this.cstmpopupEle.nativeElement.childNodes[this.arrowkeyLocation+1].focus():''
     this.isFirstTimeOpen=false
  }
  pressed(event:any){
  
    if(event.key=='Tab')
      {
        this.showDropDown=false;
        this.select.nativeElement.focus()
        // event.preventDefault();
      }
    else if(event.key=="ArrowDown" )
      {
        if(this.arrowkeyLocation<=this.dropDownValues.length){
        this.cstmpopupEle? this.cstmpopupEle.nativeElement.childNodes[++this.arrowkeyLocation].focus():''
      }
        event.preventDefault();
      }
    else if(event.key=="ArrowUp")
      {
        if(this.arrowkeyLocation>=0){
        this.cstmpopupEle? this.cstmpopupEle.nativeElement.childNodes[--this.arrowkeyLocation].focus():'' 
        }
        event.preventDefault();
      }
    
  }

  private selectionHelper(){
    const value = this.dropDownValues.find((j) => j[this.dropDownValueProp] === this.fcd.control.get(this.formcontrolname).value)
    this.selectedItem = value[this.dropDownKeyProp]
  }
}
