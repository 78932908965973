import { Input, SimpleChanges } from "@angular/core";
import { InjectorService } from "../../../core/services/Injectorservices";

import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component } from "@angular/core";
@Component({
  selector: 'basesetting',
  template: ``
})
export class BaseSettingsTemplate {
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  public template: string = '';
  @Input()
  public submenuData: any;
  @Input()
  labels: any;
  @Input()
  public GroupData: any = {};

  @Input()
  templateData: any;


  public groupByKey: string = "";

  public subHeader: string = "";
  templateGroupedData: any;
  templateGroupedDataKeys: string[];
  public templateLables: any;
  public settingsQuery: string;
  subDataResultList: any;
  public subDataList: { "key": "", "value": 0 }[] = [];
  labelFirstCount: number = 0;


  constructor(public injectorService: InjectorService, public ngxService: NgxUiLoaderService,
    public _snackbar: MatSnackBar) { }

  ngOnInit() { }

  groupBasedOnKey(array: any[], key: string) {
    return array
      .reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key].trim()]: (hash[obj[key].trim()] || []).concat(obj) })
      }, {})
  }

  ngOnChanges(changes: SimpleChanges) {
    /*  let columnChanges: boolean = false;
      for (const propName in changes) {
        if (changes.hasOwnProperty(propName))
        {
          switch(propName){
            case "templateData":
             columnChanges = true;
             break;
             default:columnChanges = false;
          }
        }
       }
         if(columnChanges )
         {

            this.ViewChanged();
         }*/
    this.ViewChanged();
  }

  ViewChanged() {
    if (this.templateData.labels) {
      if (this.templateData.GroupData && this.templateData.GroupData.GroupBy) {
        this.groupByKey = this.templateData.GroupData.GroupBy;
        this.subHeader = this.templateData.GroupData.SubHeader;
      }
      if (this.templateData.GroupData && this.templateData.GroupData.SubHeader) {
        this.subHeader = this.templateData.GroupData.SubHeader;
      }
      if (this.templateData.submenuData != undefined &&
        this.templateData.submenuData != null && this.templateData.submenuData.length > 0) {
        this.templateLables = this.injectorService.commonService.RemoveLabelFromData(this.templateData.submenuData[0], this.groupByKey);
        this.templateLables = this.injectorService.commonService.RemoveLabelFromLabels(this.templateLables, this.subHeader);
        this.templateLables = this.injectorService.commonService.RemoveLabelFromLabels(this.templateLables, "Id");


        this.templateGroupedData = this.groupBasedOnKey(this.templateData.submenuData, this.groupByKey.trim())
        this.templateGroupedDataKeys = Object.keys(this.templateGroupedData);
        this.GetData();
      }
    }
  }

  GetSettingsQuery() {

  }

  GetData() {
    let query = null;
    query = this.GetSettingsQuery();
    if(query && query.length > 0) {
      this.ngxService.start();
      this.injectorService.vitalHttpServices.GetData(this.GetSettingsQuery()).subscribe(result => {
        this.ngxService.stop();
        if (result && !result.errors) {
          this.subDataResultList = result.data;
        } else if (result.errors) {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }
      }, error => {
        console.error(error);
        this.ngxService.stop();
        this._snackbar.open( "An error occurred while processing your request", "Failed");
      });
    }
  }

  AddSubDataList(template: any) {

  }


}
