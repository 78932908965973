import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
//import { CardQueries } from 'src/app/model/card-query';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
//import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalAdminTabService } from '../../tab.service';

@Component({
  selector: 'GridContainerWithCheckBox',
  templateUrl: './GridContainerWithCheckBox.component.html',
  styleUrls: ['./GridContainerWithCheckBox.component.scss']
})
export class GridContainerWithCheckBoxComponent {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;

  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  gridHeader = [];
  UserList = [];

  submenuData: any;
  template: string = "";
  subMenuLabels: string[] = [];

  //to add check boxes to grid
  selectedItems: any[] = [];
  selector: Selector = null;

  @Output()
  SelectedItemsEvent = new EventEmitter<any>();

  constructor(private injectorServiceGridContainer: InjectorService, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar) {
    // super(injectorServiceGridContainer);
  }

  //to add check boxes to grid
  initGrid(gridcb: FlexGrid) {
    this.selector = new Selector(gridcb, {
      itemChecked: () => {
        // this.selectedItems = gridcb.rows.filter(r => r.isSelected);
        this.showCheckedCount(gridcb);
      }
    });
  }

  showCheckedCount(gridcb);
  showCheckedCount(gridcb) {
    this.selectedItems = gridcb.rows.filter(r => r.isSelected);
    // for (let i = 0; i < this.selectedItems.length; i++) {
    //   if (this.selectedItems[i]._data) {
    //     this.selectedItems[i] = this.selectedItems[i]._data;
    //   }
    // }
    this.selectedItems.forEach(function(element, index) { this[index] = element._data; }, this.selectedItems);
    this.SelectedItemsEvent.emit(this.selectedItems)
  }

  ngOnChanges() {
    this.gridHeader = [];
    let removelabel: string = "";
    this.SetSubMenuDataWithLabels(this.templateData.data.submenuData, removelabel);
    if (this.templateData.labels !== undefined) {
      let labels = JSON.parse(JSON.stringify(this.templateData.labels))
      for (let i = 0; i < labels.length; i++) {
        let column: any;
        // label = this.templateData.labels[i];
        this.gridHeader.unshift(labels[i]);
      }
      this.AddGridData()
    }
  }

  //to add labels to grid
  SetSubMenuDataWithLabels(data: any, removelabel: string) {
    this.submenuData = data;
    if (data[0]) {
      let labels = Object.keys(data[0]);
      const index = labels.indexOf(removelabel, 0);
      if (index > -1) {
        labels.splice(index, 1);
      }
      this.subMenuLabels = labels;
      this.templateData.submenuData = this.submenuData;
      this.templateData.labels = this.subMenuLabels;
      // this.templateData.Keyword = this.keyword;
    }
    else {
      this.subMenuLabels = [];
      this.templateData.submenuData = null;
      this.templateData.labels = this.subMenuLabels;
      // this.templateData.Keyword = this.keyword;
    }
  }

  AddGridData() {
    if (this.templateData.submenuData != null) {
      let keyword = this.templateData.Keyword;
      switch (keyword) {
        case "HL7Logs_CaseID":
          this.gridHeader = ['Remote_path', 'Description', 'Status', 'Claim_Number', 'Entity_Name', 'Parent_Log_Id', 'Handler_Data_2', 'Handler_Data', 'Processing_Notes', 'Handler', 'Direction', 'Processing_Date', 'Created_Date', 'Accession_Number', 'Case_Id', 'Log_Id']
          break;
        case "HL7Logs_LogID":
          this.gridHeader = ['Remote_path', 'Description', 'Status', 'Claim_Number', 'Entity_Name', 'Parent_Log_Id', 'Handler_Data_2', 'Handler_Data', 'Processing_Notes', 'Handler', 'Direction', 'Processing_Date', 'Created_Date', 'Accession_Number', 'Case_Id', 'Log_Id']
          break;
        case "HL7Logs_AccessionNum":
          this.gridHeader = ['Remote_path', 'Description', 'Status', 'Claim_Number', 'Entity_Name', 'Parent_Log_Id', 'Handler_Data_2', 'Handler_Data', 'Processing_Notes', 'Handler', 'Direction', 'Processing_Date', 'Created_Date', 'Accession_Number', 'Case_Id', 'Log_Id']
          break;
        case "HL7Logs":
          this.gridHeader = ['Remote_path', 'Description', 'Status', 'Claim_Number', 'Entity_Name', 'Parent_Log_Id', 'Handler_Data_2', 'Handler_Data', 'Processing_Notes', 'Handler', 'Direction', 'Processing_Date', 'Created_Date', 'Accession_Number', 'Case_Id', 'Log_Id']
          break;
        default:
          break;
      }
    }
    let gridarray = []
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      else {
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 0;
      }

      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * (this.gridHeader.length - 1)) + 37;
      // if (this.gridwidth > 1300)
      //   this.gridwidth = 900;
      if (this.gridwidth != 0) {
        this.gridwidth = this.getpercentage(this.gridwidth)
      }
    } else {
      this.gridwidth = 0;
      this.gridData = new CollectionView(gridarray, { pageSize: 10 });
      // this.gridwidth = 0;
    }

  }
  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width;
    let per = screenWidth * 0.75;
    // let per = (pixel / screenWidth) * 100
    // if (per < 80) {
    //   per = per + 8
    // }
    return per;
  }

  exportExcel(flex) {
    var filename = 'HL7 Logs';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      filename = filename + ".xlsx",
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
  }
}
