<div mat-dialog-title class="bulk-upload-header">
    <span>{{config.header}}</span>
    <img class="ml-auto" [autofocus]="true" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content class="bulk-upload">
    <div *ngIf="!uploadedFile; else showFile" class="drop-border py-4" (appFileDragDrop)="onDrop($event)" (click)="triggerFileInput.click()">
        <input #triggerFileInput type="file" (change)="onFileSelected($event)" style="display: none" />
        <div class="upload-icon-text">
            <img class="mr-2" src="/assets/images_vla/Upload.svg" alt="upload">
            <span>UPLOAD TEMPLATE</span>
        </div>
        <span class="upload-help-text">Click to Upload or Drag Drop the Template File</span>
    </div>
    <ng-template #showFile>
        <div class="show-uploaded-file py-3">
            <img src="/assets/images_vla/ExcelIcon_for_Bulk_Upload.svg" alt="bulk upload icon" width="50px">
            <div class="file-name">
                <span appTooltipEllipsis >{{uploadedFile?.name}}</span>
                <pre>Size: {{uploadedFile?.calculatedSize}}</pre>
            </div>
            <div class="delete-selected-file" (click)="clearSelection()">
                <img src="/assets/images_vla/Delete.svg" alt="delete" >
            </div>
        </div>
    </ng-template>    
    <div class="mt-2" *ngIf="errorMessage">
        <span class="bulk-upload-error-message">{{errorMessage}}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="my-2 mx-2 d-flex align-items-center w-100">
        <div class="ml-1">
            <img class="template-xl mr-2" src="/assets/icons/lab_users_icons/excel_upload_white.svg" alt="Excel">
            <a class="download-template-text"  (click)="downloadSampleTemplate()">Click to Download Template</a>
        </div>
        <app-button buttonclass="primary" class="ml-auto bulk-upload-btn mr-1"  [isDisabled]="errorMessage || !uploadedFile" (click)="onUploadClick()">Upload</app-button>
    </div>
</mat-dialog-actions>