import { Component, Input, SimpleChanges } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import { InjectorService } from "../../../core/services/Injectorservices";
@Component({
  selector: 'baseGrid',
  template: ``
})
export class BaseGridContainerComponent {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;

  constructor(private injectorService: InjectorService) {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    {
      this.ViewChanges();
    }
  }

  ViewChanges() {
    let label: string = "";


    if (this.templateData && this.templateData.labels && this.templateData.labels.length > 0) {
      this.columnDefs = [];
      let index: number = 0;
      for (let i = 0; i < this.templateData.labels.length; i++) {
        let column: any;

        label = this.templateData.labels[i];
        if (label !== "OrgICD") {
          this.columnDefs.unshift(label);
        }
      }

      this.AddGridData();

    }
    else {
      this.gridwidth = 0;
    }


  }

  AddGridData() {
    this.gridData = new CollectionView(this.templateData.submenuData, { pageSize: 10 })


    this.gridwidth = (170 * this.templateData.labels.length) + 35;
    if (this.gridwidth > 1300)
      this.gridwidth = 1300;

  }
}
