import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-commondetails',
  templateUrl: './commondetails.component.html',
  styleUrls: ['./commondetails.component.scss']
})
export class CommondetailsComponent {

  userDetails: any = {};
  npiDetails: any = {};
  savedRoles: any = [];
  tabList = []

  @Output() CloseDetials = new EventEmitter<any>();
  @Output() EditUser = new EventEmitter<string>();
  @Output() EditLocation = new EventEmitter<string>();
  @Input() viewType = "small"
  @Input() userInfo = ''
  @Input() fromLocation='';
  selectedTab: string = '';
  userid = ''
  locationDetails:any;

  constructor(private store: Store<{ breadcrum: [] }>, private commonService: CommonService, private labAdminservice: LabadminService, private vitalHttpServices: VitalHttpServices,
  ) {
    store.select('breadcrum').subscribe(data => {
      if (data.length > 0) {
        if (data.length > 1) {
          this.getTabs(data[data.length - 1]['DetailsMenu'])
        }
        else {
          this.getTabs(data[data.length - 1]['SubMenu'][0]['DetailsMenu'])
        }
      }
    })
  }




CloseView(){
  this.CloseDetials.emit(false)
}

  ngOnChanges() {
    if ((this.userInfo !== '' || this.npiDetails !== '') &&  this.fromLocation !== 'location') {
      this.userid = this.userInfo['userid']
      this.getUserDetails(this.userid);
      this.getuserRoles();
    }
    if(this.fromLocation == 'location'){
      this.getLocationDetails();
      this.GetServices();
    }
  }

  getLocationDetails(){
    if(this.userInfo){
      this.labAdminservice.getLocationDetails(this.userInfo?.['ofAccountId'],Number(this.labAdminservice.organizationId), this.labAdminservice.deploymentKey).
      subscribe((data)=>{
        if(data){
          this.userDetails = data;
          this.locationDetails = data;
          this.userDetails.OrganizationID = Number(this.labAdminservice.organizationId);
          this.userDetails.AccountID = this.userInfo?.['ofAccountId'];
          this.locationDetails.OrganizationID = Number(this.labAdminservice.organizationId);
          this.locationDetails.AccountID = this.userInfo?.['ofAccountId'];
        }
      })
    }
  }

  GetServices(){
    this.labAdminservice.GetServices(this.userInfo?.['ofAccountId'],2,Number(this.labAdminservice.organizationId),this.labAdminservice.deploymentKey).
    subscribe((data) =>{
      if(data) {
        const filteredArray = data
          ?.filter((value: string | null | undefined) => value !== null && value !== undefined && value.trim() !== '') // Trim whitespace
          .map((value: string | null | undefined) => value?.toLowerCase()); // Convert to lowercase

        const uniqueArray = [...new Set(filteredArray)]; // Remove duplicates

        const titleCaseArray = uniqueArray.map((value: string | null | undefined) => {
          // Convert each value to title case
          return value!.replace(/\b\w/g, char => char.toUpperCase()); // Use ! to assert that value is not null or undefined
        });

        this.userDetails.serviceDetails = titleCaseArray.join(', ');
        this.locationDetails.serviceDetails = titleCaseArray.join(', ');
      }
    });
  }

  getTabs(menuName: string) {
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'getVitalAdminMenusForDx'
    )[0].Query;
    let OrgId = this.labAdminservice.organizationId
    let queryString = "Organizationid in \"" + OrgId + "\":string[] OR Organizationid in \"-1\":string[]"
    let newQueryVariable = {
      CardName: menuName,
      filtername: queryString
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.vitalHttpServices.GetData(newQueryResult, this.labAdminservice.deploymentKey).subscribe((newResData) => {
      let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
      this.labAdminservice.subMenuPermissions = JSON.parse(newResData.data.submenuData1[0].Permission).MenuItems
      this.tabList = data;
      if (data.length > 0) {
        this.selectedTab = data[0].Htext.toLowerCase();
      }
    });
  }

  getTabData(event) {
    this.selectedTab = event.toLowerCase();
  }

  getUserDetails(userid) {
    this.labAdminservice.getUserProfile(userid, this.labAdminservice.deploymentKey).subscribe(data => {
      data['UserID'] = this.userInfo['userid'];
      this.userDetails = data;
    })
  }

  getuserRoles() {
    this.labAdminservice.getsaveRoles(this.userid, this.labAdminservice.deploymentKey).subscribe(data => {
      this.savedRoles = data;
    })
  }
  redirectoEdit(userId:any)
  {
    this.EditUser.emit(userId)
  }
  editLocation(accountId)
  {
    this.EditLocation.emit(accountId)
  }
}
