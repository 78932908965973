import { Component, OnInit, Inject, ViewChild, NgModule } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA,  MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { VACommonModule } from '../../common.module';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmComponent } from '../confirm/confirm.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-toggle',
  templateUrl: './user-toggle.component.html',
  styleUrls: ['./user-toggle.component.scss'],
})
export class UserToggleComponent implements OnInit {
  displayedColumns: string[] = ['username', 'rolename', 'Allowuserlvl', 'Allowedit'];
  dataSource = new MatTableDataSource<ResponseData>(this.data.obj);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  savedata: any = []
  isuserlvl: boolean;
  disabletoggle: boolean = false
  userFilter = new FormControl('');
  roleFilter = new FormControl('');
  filterValues = {
   username : '',
   rolename : ''
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  constructor(private ngxService: NgxUiLoaderService, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<UserToggleComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.dataSource.data= this.data.obj;
      this.dataSource.filterPredicate = this.applyFilter()
    }

  ngOnInit(): void {
    this.userFilter.valueChanges
    .subscribe(
      username => {
        this.filterValues.username = username;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      }
    )
  this.roleFilter.valueChanges
    .subscribe(
      rolename => {
        this.filterValues.rolename = rolename;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      }
    )
  }

  closeDialog() {
    this.dialogRef.close("closed");
  }
  ELEMENT_DATA: ResponseData[] = this.data.obj;

  //#region Filter users and roles
  applyFilter() : (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.username.toLowerCase().indexOf(searchTerms.username.toString().toLowerCase()) !== -1
        && data.rolename.toString().toLowerCase().indexOf(searchTerms.rolename.toString().toLowerCase()) !== -1

    }
    return filterFunction;
  }

  savetoggleconfiguration(action) {
    if(this.savedata.length ==0){
      this.closeDialog();
    }
    for (let i = 0; i < this.savedata.length; i++) {
      this.savedata[i]["context"] = this.data.context;
    }
    let dep = sessionStorage.getItem('deploymentKey').toUpperCase()
    this.ngxService.start();
    this.VitalHttpServices.editUserlevelcomments(this.savedata, dep).subscribe(response => {
      if (action == 'edit') {
        this._snackbar.open("Saved successfully", "Close");
        for(let i=0 ; i<this.data.obj.length ; i++){
           for(let j=0 ; j<this.savedata.length ;j++){
            if(this.data.obj[i].userid == this.savedata[j].userid && (this.savedata[j].Allowuserlvl)){
              this.data.obj[i].disabletoggle = true
            }
           }
        }
      }
      else {
        this._snackbar.open("Saved successfully", "Close")
        this.closeDialog()
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })

  }

  OntogglechangeOrgToUser(ev, data, action) {
    if (action == "userlvl" && ev.target.checked) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: this.data.context, message: "The template level cannot be switched back to Group Level. Are you sure to make this change?", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result !== undefined) {
            let checkexist = this.savedata.findIndex((va) => va.userid == data.userid)
              if (checkexist == -1) {
                this.savedata.push(data)
              
              } else {
                this.savedata[checkexist] = data
                if (this.savedata[checkexist]["Allowuserlvl"]) {
                  this.savedata[checkexist]["Allowedit"] = true
                }   
              }
                     
              data.Allowedit = true;
          } else {
            data.Allowuserlvl = false;
          }
        })
    } else if(action == "userlvl" && ev.target.checked == false ){
      data.Allowedit = false;
    }
    else {
      let checkexist = this.savedata.findIndex((va) => va.userid == data.userid)
      if (checkexist == -1) {
        this.savedata.push(data)
      } else {

        this.savedata[checkexist] = data
      }
    }
  }

}

export interface ResponseData {
  username: string;
  rolename: string;
  Allowuserlvl: number;
  Allowedit: number;
}


